import { Injectable } from '@angular/core';
import { EletronicAccessAgreementData } from './electronic-access-agreement-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ElectronicAccessAgreementStore extends PageStore<EletronicAccessAgreementData> {

    constructor() {
        super(new EletronicAccessAgreementData());
    }

}
