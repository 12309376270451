import { BaseData } from 'src/app/shared/models/base-data';

export class QuotesWatchListLandingData extends BaseData {
  constructor() {
    super();
    this.input = {
      currentWatchListSelected: '',
      focusElementId: '',
      symbolSelectedId: '',
      accountIndex: 0,
      action: '',
      symbol: '',
      stockHoldingIndex: 0,
      market: '',
      quantity: 0,
      priceMode: '',
      limit: 0,
      stopPrice: 0,
      stopLimit: 0,
      triggerDollarPrice: 0,
      triggerPercentagePrice: 0,
      limitOffsetDollar: 0,
      expiryMode: '',
      settlementIndex: 0,
      dateDay: 0,
      dateMonth: 0,
      watchList: {}
    };
  }
}
export interface QuotesWatchListLandingInputs {
  accountIndex: number;
  action: string;
  symbol: string;
  stockHoldingIndex: number;
  market: string;
  quantity: number;
  priceMode: string;
  limit: number;
  stopPrice: number;
  stopLimit: number;
  triggerDollarPrice: number;
  triggerPercentagePrice: number;
  limitOffsetDollar: number;
  expiryMode: string;
  settlementIndex: number;
  dateDay: number;
  dateMonth: number;
}
export class QuoteReq {
  Symbols: [
    {
      SymbolName: string;
      Market: string;
      SymbolType: string;
      OptionInfo?: {
        OptionType: string;
        StrikeDate: string;
        StrikePrice: {
          Value: string;
        }
      }
    }
  ];
}
