import { Lob, Lang } from './lob.enum';

export namespace _User {
  export interface User {
    lob: Lob;
    status: Status;
    lang: Lang;
    platformInfo: PlatformInfo;
    userlob:any;
    premiumUser: boolean;
  }

  export enum Status {
    AUTHZ = 'authz',
    GUEST = 'guest'
  }

  export enum Mode {
    WEB = 'web',
    APP = 'app',
    INAPPBROWSER = 'inApp'
  }

  export interface PlatformInfo {
    mode: Mode;
    platform: Platform;
    container: string;
    appVersion: string;
    version: string;
    revision: string;
    build: string;
    layout: number;
  }

  export enum Platform {
    IOS = 'ios',
    ANDROID = 'android',
    WEB = 'web'
  }

  export const Version = {
    OLD_APP: '5.0.0'
  }

}

export const PLF_CONST = {
  ios: 0,
  android: 1,
  web: 2
}

export const DCO_ROOT_CTX = '/dcorest/ao';
export const ROOT_CTX = '/mobi';
export const DCO_ROOT_CTX_WITHOUT_AO = '/dcorest';
