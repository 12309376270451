import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { Router } from '@angular/router';
import { ChainReq } from '../data/option-chain-data';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { OptionChainComponent } from '../option-chain.component'
import { PersistenceService } from 'src/app/core/services/persistence.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable()
export class OptionChainService {

  private getAllChainData = new BehaviorSubject<any>([]);
  public chainData: any = {};
  public validChainForm = false;
  public inFlowData;
  @Output() submitOptionChain: EventEmitter<any> = new EventEmitter<any>();
  constructor(private httpService: HttpService, private http: HttpClient, private router: Router, private persistenceService: PersistenceService) {

  }


  getGatewayConfig(){
    return gatewayConfig;
  }

  getVenueIDfromMarkitDigital(symbolName): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("access_token")
      }),
      params: { skiploading: 'true' }
    };
    if((window as any).isEnvPTE) { //simulated data for PTE
      let url = '/assets/sampleJson/venue-'+ symbolName.toLowerCase()+'.json'
      return this.http.get<any>(url, httpOptions);
    } else {
      let url = (window as any).markitdigitalURL + gatewayConfig.APIServices.optionchainGetVenueID.url + (window as any).optionchainGetVenueQueryParam + `&term=` + symbolName;
      return this.http.get<any>(url, httpOptions);
    }
  }


  getOptionChainExpiryDate(venueID): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("access_token")
      }),
      params: { skiploading: 'true' }
    };
    if((window as any).isEnvPTE) { //simulated data for PTE
      let url = '/assets/sampleJson/expirationDates-'+venueID+'.json'
      return this.http.get<any>(url, httpOptions);
    } else {
      let url = (window as any).markitdigitalURL + gatewayConfig.APIServices.optionchainExpirationDates.url + venueID + (window as any).optionchainExpirationDatesQueryParam;
      return this.http.get<any>(url, httpOptions);
    }
  }
  getOptionChainGreeks(venueID, expirationDates, strikeCount): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("access_token")
      }),
      params: { skiploading: 'true' }
    };
    if((window as any).isEnvPTE) { //simulated data for PTE
      let url = '/assets/sampleJson/chainwithgreeks-'+venueID+'-'+expirationDates+'.json'
      return this.http.get<any>(url, httpOptions);
    } else {
      let url = (window as any).markitdigitalURL + gatewayConfig.APIServices.optionchainWithGreeks.url + venueID + (window as any).optionchainWithGreeksQueryParam + `&expirationDates=` + expirationDates + `&strikeCount=` + strikeCount;
      return this.http.get<any>(url, httpOptions);
    }
  }

  getAllData() {
    return this.getAllChainData.asObservable();
  }

  setAllData(data) {
    this.getAllChainData.next(data)
  }

  setInFlow(data) {
    this.inFlowData = data;
  }

}