import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { ForgotSignOnPassReq } from '../data/forgot-signon-pass-data';
import { CommonService } from 'src/app/shared/services/common.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");



@Injectable({
  providedIn: 'root'
})
export class ForgotSignonPassService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService, private commonService: CommonService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ret = [input[0]];
    return ret;
  }

  forgotsignon(data) {
    const req: ForgotSignOnPassReq = new ForgotSignOnPassReq();
    req.UserId = data.userId;
    req.SecurityFieldName = data.nameType.replace(/\s/g, '').toUpperCase();
    req.SecurityFieldValue = data.nameValue;
    req.Lob = this.appStore.state.user.lob.toUpperCase();
    req.Locale = this.appStore.state.user.lang + '-CA';
    // req.Mode = 0; // TODO
    // req.SaveUserId = true;
    req.Flow = 'FORGETSIGNON';
    req.PhoneCountryCode = data.countryCode;
    return this.httpService.post(gatewayConfig.APIServices.forgotPasswordGet.url,
      req
    );
  }

  getOtvcRequest() {
    return this.httpService.post(gatewayConfig.APIServices.OTVCInit.url,
      {}
    );
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }
}

