import { AppStore } from 'src/app/shared/models/app-store';
import { BuyBondsOrderStore } from '../data/buy-bonds-order-store';
import {CalculateByReq, CalculateByType, SettlementAccountsReq, UpdateQuantityReq} from '../../../data/fixed-income-data';
import { HttpService } from 'src/app/core/services/http.service';
import { Injectable } from '@angular/core';
import { PageService } from 'src/app/shared/services/page-service';
import {RouterStateSnapshot, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';

declare let require: any;
const gatewayConfig = require('../../../../../../config/gateway-config.json');


@Injectable({
  providedIn: 'root'
})
export class BuyBondsOrderService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService, private router: Router) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (BuyBondsOrderStore.params) {
      let ser = input[0];
      ser.input = BuyBondsOrderStore.params;
      return input;
    } else {
      this.router.navigate(['/txn/fixed-income/buy-bonds/search']);
    }
  }

  getSettlementAccounts = (data: any): Observable<any> => {
    const req: SettlementAccountsReq = {
      Action: data.Action,
      AccountNumber: data.AccountNumber,
      AccountCurrency: data.AccountCurrency,
      Type: data.Type
    };
    return this.httpService.post(gatewayConfig.APIServices.getBuyBondsSettlementAccounts.url,
        req, { params: { skiploading: 'true' } }
    );
  }

  updateCalculateBy = (data: UpdateQuantityReq): Observable<any> => {
    const req: CalculateByReq = {
      AccountNumber: data.AccountNumber
    };
    if (data.quantityType === CalculateByType.FACE_VALUE) {
      req.ReCalculateAmount = data.quantity;
    } else {
      req.ReCalculateAmountToInvest = data.quantity;
    }
    return this.httpService.post(gatewayConfig.APIServices.buyBondsSubmit.url,
        req,
    );
  }

}
