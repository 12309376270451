import { Component, Input } from '@angular/core';

@Component({
  selector: 'new-message-block',
  templateUrl: './new-message-block.component.html',
  styleUrls: ['./new-message-block.component.scss']
})
export class NewMessageBlockComponent{
  
  @Input() content;
  @Input() new:boolean = true;

}
