import { HomeComponent } from './home/home.component';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, HostListener, AfterViewInit, Inject, Renderer2, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppStore } from 'src/app/shared/models/app-store';
import { Subscription } from 'rxjs';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { EnvService } from 'src/app/env.service'
import { CommonService } from 'src/app/shared/services/common.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';

@Component({
  selector: 'app-pre-txn',
  templateUrl: './pre-txn.component.html',
  styleUrls: ['./pre-txn.component.scss']
})
export class PreTxnComponent implements OnInit, AfterViewInit, OnDestroy {

  breakPoint: number;
  currentRoute: string;
  subscriptions = new Subscription();
  pageTitleAnnoucement: string;

  displayFullPages: any = {
    "#/splash": true,
    "#/onlineRegistration/password": true,
    "#/onlineRegistration/username": true,
    "#/onlineRegistration/mailingOptions": true
  }
  displayPasswordPages: any = {
    "#/signOn/changeSignOnPassword": true,
    "#/signon/createSignonAndTradingPass": true,
    "#/signon/createSignonPass": true,
    "#/signon/createTradingPass": true,
    "#/registerOnline/landing": true,
    "#/registerOnline/createPassword": true
  }
  page: string;
  loggedIn: boolean;
  locale: string;
  isApp: boolean;
  enableFooter: boolean = true;
  enableHeader: boolean = true;
  constructor(
    private router: Router,
    private appStore: AppStore,
    private commonService: CommonService,
    private renderer: Renderer2,
    public globalContent: GlobalContentStore,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    private env: EnvService,
    private persistenceService: PersistenceService
  ) {
  }

  ngOnInit() {
    this.isApp = this.appStore.isApp('app');
    if (this.router.url === '/home') {
      if(this.persistenceService.sessionStoreRetrieve('queryParamLocale')){
        this.persistenceService.removeSessionStoreValue('queryParamLocale');
      }
      if(this.persistenceService.sessionStoreRetrieve('queryParamDco')){
        this.persistenceService.removeSessionStoreValue('queryParamDco');
      }
      if(this.persistenceService.sessionStoreRetrieve('isSourceDCO')){
        this.persistenceService.removeSessionStoreValue('isSourceDCO');
        this.persistenceService.deletecookieStorePersist('MBI_LOB');
      }
      this.router.navigateByUrl('/home/' + this.appStore.lang + '/' + this.appStore.lob);
    }
    if (this.router.url == '/sessionEnd' && this.appStore.isApp('inApp')) {
      this.enableFooter = false;
    } else {
      this.enableFooter = true;
    }
    if (this.router.url == '/newUser') {
      this.enableHeader = false;
    } else {
      this.enableHeader = true;
    }
    // this.setWrapperAppContainer();
    this.setCurrentVals();
    this.navigationEndChanges();
  }

  getLob() { return this.appStore.lob }

  navigationEndChanges() {
    this.subscriptions.add(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!(this.globalContent.text.pageTitleForUrls === undefined)) {
          const pageTitle = this.globalContent.text.pageTitleForUrls[this.currentPage()];
          this.pageTitleAnnoucement = (pageTitle && pageTitle.screenReaderAnnouncement) ? pageTitle.screenReaderAnnouncement : '';
        } else {
          this.pageTitleAnnoucement = '';
        }
        if (event.url == '/sessionEnd' && this.appStore.isApp('inApp')) {
          this.enableFooter = false;
        } else {
          this.enableFooter = true;
        }
        if (this.router.url == '/newUser') {
          this.enableHeader = false;
        } else {
          this.enableHeader = true;
        }
        this.setCurrentVals();

        this.commonService.brazeCustomEvents();
      }
    }));
  }

  ngAfterViewInit() {
    if (!(this.globalContent.text.pageTitleForUrls === undefined)) {
      const pageTitle = this.globalContent.text.pageTitleForUrls[this.currentPage()];
      this.pageTitleAnnoucement = (pageTitle && pageTitle.screenReaderAnnouncement) ? pageTitle.screenReaderAnnouncement : '';
    } else {
      this.pageTitleAnnoucement = '';
    }
    this.cdr.detectChanges();
  }

  // private setWrapperAppContainer() {
  //   const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
  //   if (_iOSDevice && this.appStore.isApp() && this.appStore.state.user.platformInfo.version === '4.0.2') {
  //     this.renderer.addClass(this.document.getElementById('main-app-container'), 'wrapperAppMainAppContainer');
  //   }
  // }

  setCurrentVals() {
    this.page = this.appStore.state.state.currentUrl;
    this.locale = this.appStore.lang;
    this.loggedIn = this.appStore.isLoggedIn();
  }

  isUserLoggedIn() {
    return this.appStore.isLoggedIn();
  }

  isDesktop() {
    return this.commonService.isDesktop()
  }

  currentPage() {
    return this.appStore.state.state.currentUrl;
  }

  currentLocale() {
    return this.appStore.lang;
  }

  displayFull() {
    const loc = window.location.hash;
    if (this.displayFullPages[loc] || loc.startsWith('#/onlineRegistration/verification')) {
      return true;
    }
    return false;
  }

  passwordPage() {
    const loc = window.location.hash;
    if (this.displayPasswordPages[loc]) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
