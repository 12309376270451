import { BaseData } from '../../../../../../shared/models/base-data';

export class CreateCashPlanConfirmData extends BaseData {

}

export class CreateCashPlanConfirmResp {
    OrderID?: string;
    OrderReceivedOn?: string;
    Account?: any;
    NextOrderDate?: string;
    PayFromAccountNumber?: string;
    ValidInfo?: ValidInfo
}

export interface ValidInfo {
    PlanFrequency?: number;
    StartDate?: string;
    Action?: string;
    Amount?: number;
    EndDate?: string;
    SuspendDate?: string;
    UnSuspendDate?: string;
    PayFromAccountNumber?: string;
}

export const No_API_Data = {
    dash: '-',
    registered: 'REG',
    dollar: '$'
}
