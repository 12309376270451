import { Injectable } from '@angular/core';
import { HttpService } from '../../../../../../core/services/http.service';
declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})

export class DropDownDataService {
    constructor(private _http: HttpService) { }


    getCountriesListFromAPI() {
        return this._http.post(gatewayConfig.APIServices.marginAndOptionsCountryDropDown.url + '?silent=1', {});
    }

    getEmploymentStatusFromAPI() {
        return this._http.post(gatewayConfig.APIServices.marginAndOptionsEmploymentDropDown.url + '?silent=1', {});

    }

    getBusinessType() {
        return this._http.post(gatewayConfig.APIServices.marginAndOptionsBusinessDropDown.url + '?silent=1', {});

    }

    getEmploymentOccupation(code) {
        return this._http.post(gatewayConfig.APIServices.marginAndOptionsOccupationDropDown.url + '?silent=1', {
            "OccupationCategoryCode": code
        });

    }

    getOccupationDetails(code, detailsCode) {
        return this._http.post(gatewayConfig.APIServices.marginAndOptionsOccupationDetailsDropDown.url + '?silent=1', {
            "OccupationCategoryCode": code,
            "OccupationDescCode": detailsCode
        });

    }
}