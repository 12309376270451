import { Injectable } from '@angular/core';
import { NicknameAccountsConfirmData } from './nickname-accounts-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class NicknameAccountsConfirmStore extends PageStore<NicknameAccountsConfirmData> {

    constructor() {
        super(new NicknameAccountsConfirmData());
    }

}
