import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { PageService } from 'src/app/shared/services/page-service';
import { cmfHoldingsReq, cmfQuoteReq, cmfSettlementAccountsReq, mplAccountRequest } from "../data/create-mutual-fund-landing-data";
import { CreateMutualFundLandingStore } from "../data/create-mutual-fund-landing-store";
declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class CreateMutualFundLandingService implements PageService {

  constructor(
    private httpService: HttpService
  ) { }
  prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return [input[0]];
  }

  prepareHoldings(input, accNum) {
    const ret = input.input;

    ret.AccountNumber = accNum;

    input.input = ret;
    return input;
  }

  getHoldings(data) {
    const req: cmfHoldingsReq = { AccountNumber: data.AccountNumber, FiType: "M" };
    return this.httpService.post(gatewayConfig.APIServices.getOrderHoldings.url, req, { params: { skiploading: 'true' } }
    );
  }

  getSettlementAccounts(data) {
    const req: cmfSettlementAccountsReq = {
      AccountNumber: data.AccountNumber,
      Action: data.Action
    };
    return this.httpService.post(gatewayConfig.APIServices.getRIPSettlementAccounts.url, req, { params: { skiploading: 'true' } }
    );
  }

  getQuotes(data) {
    const req: cmfQuoteReq = { SymbolName: data.SymbolName };
    return this.httpService.post(gatewayConfig.APIServices.getMutualFundQuote.url, req, { params: { skiploading: 'true' } }
    );
  }

  getBalanceReq(accountRequest: mplAccountRequest) {
    return this.httpService.post(gatewayConfig.APIServices.getAccountBalance.url, accountRequest, { params: { skiploading: 'true' } });
  }
}