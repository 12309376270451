import { BaseData } from 'src/app/shared/models/base-data';

export class QuotesDetailLandingData extends BaseData {
  constructor() {
    super();
    this.input = {

    }
  }
}
export interface QuotesDetailLandingInputs {
  accountIndex: number;
  action: string;
  symbol: string;
  stockHoldingIndex: number;
  market: string;
  quantity: number;
  priceMode: string;
  limit: number;
  stopPrice: number;
  stopLimit: number;
  triggerDollarPrice: number;
  triggerPercentagePrice: number;
  limitOffsetDollar: number;
  expiryMode: string;
  settlementIndex: number;
  dateDay: number;
  dateMonth: number;
}
export class QuoteReq {
  Symbols: [
    {
      SymbolName: string;
      Market: string;
      SymbolType: string;
      OptionInfo?: {
        OptionType: string;
        StrikeDate: string;
        StrikePrice: {
          Value: string;
        }
      }
    }
  ];
}

