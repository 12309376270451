import { Injectable } from '@angular/core';
import { ChangeUserIDLandingData } from './change-userid-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeUserIDLandingStore extends PageStore<ChangeUserIDLandingData> {

    constructor() {
        super(new ChangeUserIDLandingData());
    }

}
