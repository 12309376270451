import { Injectable } from '@angular/core';
import { TradeStocksConfirmData } from './trade-stocks-confirm-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TradeStocksConfirmStore extends PageStore<TradeStocksConfirmData> {

    constructor() {
        super(new TradeStocksConfirmData());
    }

}
