import { Injectable } from '@angular/core';
import { Observable, empty, forkJoin, observable, ObservableInput, Subject } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Lob } from 'src/app/shared/models/lob.enum';
import { ROOT_CTX } from 'src/app/shared/models/user';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SettlementAccountsReq, AccountHoldingsReq, TradeOptionsLandingInputs, BuyingPowerReq, ChainReq, QuoteReq } from '../data/trade-options-landing-data';
import { TradeOptionsLandingStore } from '../data/trade-options-landing-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class TradeOptionsLandingService implements PageService {
  public actionType : string;


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    console.log('input', input);
    if (TradeOptionsLandingStore.savedInfo) {
      let info: TradeOptionsLandingInputs = TradeOptionsLandingStore.savedInfo.inputs;
      let accNum = TradeOptionsLandingStore.savedInfo.AccountNumber;
      let curr = TradeOptionsLandingStore.savedInfo.AccountCurrency;
      let optionInfo = TradeOptionsLandingStore.savedInfo.OptionInfo;
      if (info.action == "BUYTOOPEN") {
        let ret = [input[0],input[1], input[2], input[3], input[4]];
        ret[2] = this.prepareSett(ret[2], accNum, "BUYTOOPEN", curr);
        ret[3] = this.prepareChainDetails(ret[3], info.symbol, info.market, optionInfo.StrikeDate);
        ret[4] = this.prepareQuick(ret[4], info.symbol, info.market, optionInfo);

        return ret;
      } else if (info.action == "BUYTOCLOSE") {
        let ret = input;
        if (info.chooseSymbol && info.optionHoldingIndex != -1) {
          ret = [ret[0], ret[1], ret[2], ret[4], ret[5]];
          ret[2] = this.prepareSett(ret[2], accNum, "BUYTOCLOSE", curr);
          ret[3] = this.prepareQuick(ret[3], info.symbol, info.market, optionInfo);

          ret[4] = this.prepareHoldings(ret[4], accNum, "BUYTOCLOSE");
        } else {
          ret[2] = this.prepareSett(ret[2], accNum, "BUYTOCLOSE", curr);
          ret[3] = this.prepareChainDetails(ret[3], info.symbol, info.market, optionInfo.StrikeDate);
          ret[4] = this.prepareQuick(ret[4], info.symbol, info.market, optionInfo);

          ret[5] = this.prepareHoldings(ret[5], accNum, "BUYTOCLOSE");
        }

        return ret;
      } else if (info.action == "SELLTOOPEN") {
        let ret = [input[0],input[1], input[2], input[3], input[4]];
        ret[2] = this.prepareSett(ret[2], accNum, "SELLTOOPEN", curr);
        ret[3] = this.prepareChainDetails(ret[3], info.symbol, info.market, optionInfo.StrikeDate);
        ret[4] = this.prepareQuick(ret[4], info.symbol, info.market, optionInfo);

        return ret;
      } else if (info.action == "SELLTOCLOSE") {
        let ret = input;
        if (info.chooseSymbol && info.optionHoldingIndex != -1) {
          ret = [ret[0], ret[1], ret[2], ret[4], ret[5]];
          ret[2] = this.prepareSett(ret[2], accNum, "SELLTOCLOSE", curr);
          ret[3] = this.prepareQuick(ret[3], info.symbol, info.market, optionInfo);

          ret[4] = this.prepareHoldings(ret[4], accNum, "SELLTOCLOSE");
        } else {
          ret[2] = this.prepareSett(ret[2], accNum, "SELLTOCLOSE", curr);
          ret[3] = this.prepareChainDetails(ret[3], info.symbol, info.market, optionInfo.StrikeDate);
          ret[4] = this.prepareQuick(ret[4], info.symbol, info.market, optionInfo);

          ret[5] = this.prepareHoldings(ret[5], accNum, "SELLTOCLOSE");
        }

        return ret;
      } else if (info.action == "CLOSE") {
        let ret = [input[0],input[1],input[2], input[3], input[4], input[5],JSON.parse(JSON.stringify(input[5]))];
        let closeActionType = TradeOptionsLandingStore.savedInfo.closeActionType ? TradeOptionsLandingStore.savedInfo.closeActionType : 'BUYTOCLOSE'
        if (info.chooseSymbol && info.optionHoldingIndex != -1) {
          ret = [ret[0], ret[1], ret[2], ret[4], ret[5], ret[6]];
          ret[2] = this.prepareSett(ret[2], accNum, closeActionType, curr);
          ret[3] = this.prepareQuick(ret[3], info.symbol, info.market, optionInfo);

          ret[4] = this.prepareHoldings(ret[4], accNum, 'BUYTOCLOSE');
          ret[5] = this.prepareHoldings(ret[5], accNum, 'SELLTOCLOSE');
        } else {
          ret[2] = this.prepareSett(ret[2], accNum, closeActionType, curr);
          ret[3] = this.prepareChainDetails(ret[3], info.symbol, info.market, optionInfo.StrikeDate);
          ret[4] = this.prepareQuick(ret[4], info.symbol, info.market, optionInfo);

          ret[5] = this.prepareHoldings(ret[5], accNum, 'BUYTOCLOSE');
          ret[6] = this.prepareHoldings(ret[6], accNum, 'SELLTOCLOSE');
        }

        return ret;
      }else {
        let ret = [input[0], input[1]];
        return ret;
      }
    } else if (TradeOptionsLandingStore.crossFlow) {
      let crossFlow = TradeOptionsLandingStore.crossFlow; // {action?, accountNumber, symbol, market}
      let accNum = crossFlow.accountNumber;
      let curr = crossFlow.accountCurrency;
      let optionInfo = crossFlow.optionInfo;
      if (crossFlow.action == "BUYTOOPEN") {
        let ret = [input[0],input[1], input[3], input[4]];
        //ret[1] = this.prepareSett(ret[1], accNum, "BUYTOOPEN", curr);
        ret[2] = this.prepareChainDetails(ret[2], crossFlow.symbol, crossFlow.market, optionInfo.StrikeDate);
        ret[3] = this.prepareQuick(ret[3], crossFlow.symbol, crossFlow.market, optionInfo);

        return ret;
      } else if (crossFlow.action == "BUYTOCLOSE") {
        let ret = [input[0], input[1], input[3], input[4],input[5]];
        //ret[2] = this.prepareSett(ret[2], accNum, "BUYTOCLOSE", curr);
        ret[2] = this.prepareChainDetails(ret[2], crossFlow.symbol, crossFlow.market, optionInfo.StrikeDate);
        ret[3] = this.prepareQuick(ret[3], crossFlow.symbol, crossFlow.market, optionInfo);
        ret[4] = this.prepareHoldings(ret[4], accNum, "BUYTOCLOSE");

        return ret;
      } else if (crossFlow.action == "SELLTOOPEN") {
        let ret = [input[0], input[1], input[3],input[4]];
        //ret[1] = this.prepareSett(ret[1], accNum, "SELLTOOPEN", curr);
        ret[2] = this.prepareChainDetails(ret[2], crossFlow.symbol, crossFlow.market, optionInfo.StrikeDate);
        ret[3] = this.prepareQuick(ret[3], crossFlow.symbol, crossFlow.market, optionInfo);

        return ret;
      } else if (crossFlow.action == "SELLTOCLOSE") {
        let ret = [input[0], input[1], input[3], input[4],input[5]];
        //ret[2] = this.prepareSett(ret[2], accNum, "SELLTOCLOSE", curr);
        ret[2] = this.prepareChainDetails(ret[2], crossFlow.symbol, crossFlow.market, optionInfo.StrikeDate);
        ret[3] = this.prepareQuick(ret[3], crossFlow.symbol, crossFlow.market, optionInfo);
        ret[4] = this.prepareHoldings(ret[4], accNum, "SELLTOCLOSE");

        return ret;
      } else if (crossFlow.action == "CLOSE") {
        let ret = [input[0], input[1],input[3],  input[4], input[5], JSON.parse(JSON.stringify(input[5]))];
        //ret[2] = this.prepareSett(ret[2], accNum, "SELLTOCLOSE", curr);
        ret[2] = this.prepareChainDetails(ret[2], crossFlow.symbol, crossFlow.market, optionInfo.StrikeDate);
        ret[3] = this.prepareQuick(ret[3], crossFlow.symbol, crossFlow.market, optionInfo);
        ret[4] = this.prepareHoldings(ret[4], accNum, "BUYTOCLOSE");
        ret[5] = this.prepareHoldings(ret[5], accNum, "SELLTOCLOSE");

        return ret;
      } else {
        let ret = [input[0], input[1], input[3], input[4]];
        ret[2] = this.prepareChainDetails(ret[2], crossFlow.symbol, crossFlow.market, optionInfo.StrikeDate);
        ret[3] = this.prepareQuick(ret[3], crossFlow.symbol, crossFlow.market, optionInfo);

        return ret;
      }
    } else {
      let ret = [input[0], input[1]];
      return ret;
    }
  }

  prepareHoldings(input, accNum, action) {
    let ret = input.input;

    ret.AccountNumber = accNum;
    ret.Action = action;

    input.input = ret;
    return input;
  }

  prepareSett(input, accNum, action, curr) {
    let ret = input.input;

    ret.AccountNumber = accNum;
    ret.Action = action;
    if (curr) {
      ret.AccountCurrency = curr;
    }

    input.input = ret;
    return input;
  }

  prepareChainDetails(input, symbol, market, date) {
    let ret = input.input;

    ret.SymbolName = symbol;
    ret.Market = market;
    ret.ExpiryDate = date;

    input.input = ret;
    return input;
  }

  prepareQuick(input, symbol, market, optionInfo) {
    let ret = input.input;

    ret.Symbols[0].SymbolName = symbol;
    ret.Symbols[0].Market = market;
    ret.Symbols[0].OptionInfo = optionInfo;

    input.input = ret;
    return input;
  }

  getAccountHoldingsLoad (){
    return this.httpService.post(gatewayConfig.APIServices.holdingsNewLoad.url, {})
  }

  isAccountOptionEnabledOrNot (accNum) {
    const reqAcc = { AccountNumber : accNum }
    return this.httpService.post(gatewayConfig.APIServices.accOptionEnabled.url, reqAcc)
  }


  getSettlementAccounts(data) {
    let req: SettlementAccountsReq = new SettlementAccountsReq();
    req.Action = data.Action;
    req.AccountNumber = data.AccountNumber;
    req.AccountCurrency = data.AccountCurrency;
    req.Type = "OPTIONS";
    return this.httpService.post(gatewayConfig.APIServices.optionsSettlementAccounts.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getAccountHoldings(data) {
    let req: AccountHoldingsReq = new AccountHoldingsReq();
    req.Action = data.Action;
    req.AccountNumber = data.AccountNumber;
    req.Clean = data.Clean;
    req.Refresh = data.Refresh;
    req.SearchOptions = data.SearchOptions;
    req.FiType = "O";
    return this.httpService.post(gatewayConfig.APIServices.getOrderHoldings.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getBuyingPower(data) {
    let req: BuyingPowerReq = new BuyingPowerReq();
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.buyingPowerRetrieve.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getChain(data) {
    let req: ChainReq = new ChainReq();
    req = data;
    return this.httpService.post(gatewayConfig.APIServices.optionsChainDetail.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getQuote(data) {
    let req: QuoteReq = new QuoteReq();
    req.Symbols = [
      data
    ];
    return this.httpService.post(gatewayConfig.APIServices.quoteQuick.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getBalanceReq(data) {
    return this.httpService.post(gatewayConfig.APIServices.getAccountBalance.url, data ,
      { params: { skiploading: 'true'} });
  }

}
