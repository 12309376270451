import { Component, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
    selector: 'app-date-overlay',
    templateUrl: './date-overlay.component.html',
    styleUrls: ['./date-overlay.component.scss'],
})
export class DateOverlayComponent {

    closeOverlay() {

    }

}