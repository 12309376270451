import { Injectable } from '@angular/core';
import { NyAgreementData } from './ny-agreement.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class NyAgreementStore extends PageStore<NyAgreementData> {

    constructor() {
        super(new NyAgreementData());
    }

}
