import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter, Renderer2, Input } from '@angular/core';
import { QuickTradeStocksVerifyStore } from './data/quick-trade-stocks-verify-store';
import { AppStore } from 'src/app/shared/models/app-store';
import { isDefined, openWindow } from 'src/app/shared/services/utils.service';
import { QuickTradeStocksConfirmStore } from '../confirm/data/quick-trade-stocks-confirm-store';
import { ContentStore } from './../../../../shared/store/content-store';
import { ContentType, GlobalContentStore } from './../../../../shared/store/global-content-store';
import { FormGroup } from '@angular/forms';
import { QuickTradeStocksVerifyValidator } from './quick-trade-stocks-verify.validator';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { ContentService } from 'src/app/core/services/content.service';
import _ from 'lodash';
import { get } from "lodash";
import { Subscription } from 'rxjs/internal/Subscription';
import { CommonService } from 'src/app/shared/services/common.service';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { HttpService } from 'src/app/core/services/http.service';
import { Lob } from 'src/app/shared/models/lob.enum';
import { QuickTradeStocksVerifyInputs } from './data/quick-trade-stocks-verify-data';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { OmniturePageDetail } from 'src/app/shared/models/route-config';
import { of } from 'rxjs';
import { IOmnitureTransactionData } from 'src/app/shared/models/omniture.types';
import { TradeStocksVerifyStore } from 'src/app/modules/txn/tradeStocks/verify/data/trade-stocks-verify-store';
import { TradeStocksConfirmStore } from 'src/app/modules/txn/tradeStocks/confirm/data/trade-stocks-confirm-store';

declare let require: any;
const gatewayConfig = require('src/app/config/gateway-config.json');

@Component({
  selector: 'app-quick-trade-stocks-verify',
  templateUrl: './quick-trade-stocks-verify.component.html',
  styleUrls: ['./quick-trade-stocks-verify.component.scss']
})

export class QuickTradeStocksVerifyComponent implements OnInit, OnDestroy {
  @Input() public mode: string = 'quickTrade';
  @Input() public data: any;
  @Input() public fullData: any;
  @Output() submit = new EventEmitter();

  storeArray = {
    "quickTrade": QuickTradeStocksVerifyStore,
    "smallTradeStocks": TradeStocksVerifyStore
  }
  confirmStoreArray = {
    "quickTrade": QuickTradeStocksConfirmStore,
    "smallTradeStocks": TradeStocksConfirmStore
  }

  orderReviewInfo: any;
  estimatedCost: any;
  buyingPower: any;
  buyingPowerContent: any;
  quote: any;
  quoteContent: any;
  token: string;
  isSubmitAllowed = false;
  checkPassword = false;
  inputs: QuickTradeStocksVerifyInputs;
  orderWarnings: any = [];
  activityLabel = '';
  formError: any = {};
  hidePage = false;
  hasError = false;
  colonStr: string;
  get LOB() { return Lob; }

  estimatedBuyingPowerToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  secFeeToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  FTTToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  savePassToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: '',
    title: ''
  };
  moreInfoToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: '',
    title: ''
  };

  // otvc
  otvcPromptDataParam: any;
  modalMode: string;
  verifyurl: string;
  otvcPromptContent: any;
  currentModule = 'quicktradestocksverify';
  uniqueId: string;
  subscriptions = new Subscription();
  currency: string;
  quickTradeContent: ContentType = this.content;
  foreignExchange: boolean = false;
  dash = '-';
  uniqueName = "-quick-trade-big";
  notch: boolean = false;
  isScreenReaderON = false;
  isKeyboardOpen: boolean;

  @ViewChild('quickTradeStockVerifyForm') quickTradeStockVerifyForm: FormGroup;
  @Output() dataLoaded = new EventEmitter<any>();

  constructor(
    private contentService: ContentService,
    public store: QuickTradeStocksVerifyStore,
    public appStore: AppStore,
    public content: ContentStore,
    public globalContent: GlobalContentStore,
    private subscriptionService: ObservableSubscriptionService,
    public modalService: ModalService,
    private httpService: HttpService,
    private commonService: CommonService,
    private persistenceService: PersistenceService,
    private trackingService: TrackingService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.notch = this.commonService.isNotchPhone();
    this.isScreenReaderON = this.appStore.features.isScreenReaderON ? true : false;

    this.subscriptions.add(this.subscriptionService.isKeyboardOpen.subscribe(
      (data) => {
        this.isKeyboardOpen = data;
      }
    ));

    this.subscriptions.add(this.contentService.fetchContent('quicktradestocksverification').subscribe((data) => {
      this.content = data;
    }));
    this.uniqueId = this.appStore.uniqueId;

    this.secFeeToolTip.openBtnLabel = '';
    this.secFeeToolTip.cancel = this.globalContent.text.closeTooltip;
    this.secFeeToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    this.secFeeToolTip.content = '';
    this.secFeeToolTip.title = this.content.text.secFee;

    this.FTTToolTip.openBtnLabel = '';
    this.FTTToolTip.cancel = this.globalContent.text.closeTooltip;
    this.FTTToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    this.FTTToolTip.content = '';
    this.FTTToolTip.title = this.content.text.FTTFee;

    this.savePassToolTip.openBtnLabel = '';
    this.savePassToolTip.cancel = this.globalContent.text.closeTooltip;
    this.savePassToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    this.savePassToolTip.content = this.content.text.savePassToolTip;
    this.savePassToolTip.title = this.content.text.saveTradingPassword;

    this.moreInfoToolTip.openBtnLabel = '';
    this.moreInfoToolTip.cancel = this.globalContent.text.closeTooltip;
    this.moreInfoToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    this.moreInfoToolTip.content = this.content.text.savePassToolTip;
    this.moreInfoToolTip.title = this.content.text.saveTradingPassword;

    if (this.mode == 'quickTrade') {
      if (this.storeArray[this.mode].crossFlow == null || this.storeArray[this.mode].crossFlow === undefined) {
        // session to get on refresh
        const sessionValue = this.persistenceService.sessionStoreRetrieve(this.mode == 'quickTrade' ? 'verifyStoreQTBig' : 'verifyStoreQTSmall');
        if (sessionValue != null && sessionValue != undefined && sessionValue != 'null') {
          const jsonValue = JSON.parse(sessionValue);
          this.storeArray[this.mode].params = jsonValue?.params;
          this.storeArray[this.mode].inFlow = jsonValue?.inFlow;
          const req: any = this.storeArray[this.mode].params;
          this.subscriptionService.setCustomError('RESET');
          this.httpService.post(gatewayConfig.APIServices.stocksVerify.url, req, { params: { skiploading: 'true' } }).subscribe((data: any) => {
            this.storeArray[this.mode].inFlow.Data = data;
            // focus
            this.setVerifyData();
            setTimeout(() => {
              this.dataLoaded.emit(true);
              this.setFocus('closeBtn');
            }, 200);
          }, (err) => {
            this.subscriptionService.setTradeDrawerPosition(1);
            this.subscriptionService.setCustomError(err.error.Exception);
          });
        }
      } else {
        this.setVerifyData();
      }

      this.subscriptions.add(this.subscriptionService.openOrCloseDrawer.subscribe((data) => {
        if (data == 'mobile') {
          if (this.storeArray[this.mode].crossFlow == null || this.storeArray[this.mode].crossFlow == undefined) {
            const sessionValue = this.persistenceService.sessionStoreRetrieve(this.mode == 'quickTrade' ? 'verifyStoreQTBig' : 'verifyStoreQTSmall');
            if (sessionValue != null && sessionValue != undefined && sessionValue != 'null') {
              const jsonValue = JSON.parse(sessionValue);
              this.storeArray[this.mode].params = jsonValue?.params;
              this.storeArray[this.mode].inFlow = jsonValue?.inFlow;
              const req: any = this.storeArray[this.mode].params;
              this.subscriptionService.setCustomError('RESET');
              this.httpService.post(gatewayConfig.APIServices.stocksVerify.url, req, { params: { skiploading: 'true' } }).subscribe((data: any) => {
                this.storeArray[this.mode].inFlow.Data = data;
                // focus
                this.setVerifyData();
                setTimeout(() => {
                  this.dataLoaded.emit(true);
                  this.setFocus('closeBtn');
                }, 200);
              }, (err) => {
                this.subscriptionService.setTradeDrawerPosition(1);
                this.subscriptionService.setCustomError(err.error.Exception);
              });
            }
          }
        }
      }));
    } else {
      this.uniqueName = '-quick-trade-small';
      this.setVerifyData();
    }

    let tempOptions = this.commonService.unfoldJSON(this.commonService.obscureAccount(this.storeArray[this.mode].params));
    tempOptions.platform = "quickTrade";
    tempOptions.AccountTypeDesc = this.orderReviewInfo.Account.AccountTypeDesc;
    this.commonService.logBraze("viewed_trade_stock_verify", tempOptions);
  }

  setVerifyData() {
    if (this.storeArray[this.mode].inFlow?.Data != undefined || this.storeArray[this.mode].inFlow?.Data != null) {
      this.data = this.storeArray[this.mode].inFlow.Data;
    }
    if (this.data) {
      this.orderReviewInfo = this.data.OrderReviewInfo;
      if (this.orderReviewInfo.Symbol.SymbolName.endsWith('.U')) {
        this.orderReviewInfo.Symbol.Currency = "US";
      } else {
        this.orderReviewInfo.Symbol.Currency = this.orderReviewInfo.Symbol.Market;
      }
      this.estimatedCost = this.data.EstimatedCost;
      this.buyingPower = this.data.BuyingPowerValues;
      this.quote = this.data.Quote;
      this.token = this.data.Token;
      this.checkPassword = !this.data.TradingPasswordInSession;
      if (isDefined(this.data.Warnings) && this.data.Warnings.length > 0) {
        this.orderWarnings = this.data.Warnings;
        this.foreignExchange = this.orderWarnings.some(
          warning => {
            return warning.Code === 'UIBUSFI0004';
          });
      }
      this.inputs = this.store.state.input;
      this.inputs.savePassword = false;
      this.inputs.tradingPassword = '';
      this.isSubmitAllowed = this.isSubmitPermission();
      this.subscriptions.add(this.contentService.fetchContent('symbolquote').subscribe((data) => {
        this.quoteContent = data;
      }, (err) => {

      }));

      if (this.buyingPower) {
        this.fetchBuyingPowerContent();
      }

      if (this.orderReviewInfo.Account.AccountType == 'INV_MAR') {
        if (!this.buyingPowerContent) {
          this.fetchBuyingPowerContent();
          this.buyingPower = {};
        }
        if (!this.buyingPower.AsOfDateTime) {
          this.buyingPower.AsOfDateTime = this.storeArray[this.mode].inFlow.TimeStamp;
        }
        this.buyingPower.BuyingPowerConsolidated = this.storeArray[this.mode].inFlow.ConsolidatedBuyingPower;
      }
      this.secFeeToolTip.openBtnLabel = '';
      this.secFeeToolTip.cancel = this.globalContent.text.closeTooltip;
      this.secFeeToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.secFeeToolTip.content = '';

      this.FTTToolTip.openBtnLabel = '';
      this.FTTToolTip.cancel = this.globalContent.text.closeTooltip;
      this.FTTToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.FTTToolTip.content = '';

      this.savePassToolTip.openBtnLabel = '';
      this.savePassToolTip.cancel = this.globalContent.text.closeTooltip;
      this.savePassToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.savePassToolTip.content = '';
      if (this.appStore.lang.toUpperCase() === 'FR') {
        this.colonStr = ' :';
      } else {
        this.colonStr = ':';
      }
      if (this.orderReviewInfo.Activity.OrderType == 'SELL' || this.orderReviewInfo.Activity.OrderType == 'SHORT') {
        this.currency = this.orderReviewInfo.PayTo.Currency;
      } else if (this.orderReviewInfo.Activity.OrderType == 'BUY' || this.orderReviewInfo.Activity.OrderType == 'BUYTOCOVER') {
        this.currency = this.orderReviewInfo.PayFrom.Currency;
      }
      // to recall verify api
      this.storeArray[this.mode].crossFlow = null;
    }
    setTimeout(() => {
      this.dataLoaded.emit(true);
      this.setFocus('closeBtn');
    }, 200);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isSubmitPermission() {
    return (isDefined(this.token) && this.token !== '');
  }

  backClick() {
    this.subscriptionService.setTradeDrawerPosition(1);
  }

  resetTradePassword() {
    this.inputs.tradingPassword = '';
  }

  resetAllFormErrors() {
    this.formError = {};
  }

  next() {
    this.hasError = false;
    let params: any = {};

    QuickTradeStocksVerifyValidator.setValidators(this.quickTradeStockVerifyForm);
    QuickTradeStocksVerifyValidator.validate(this.quickTradeStockVerifyForm);

    if (this.checkPassword) {
      if (false/*!(this.quickTradeStockVerifyForm.valid)*/) {
        this.hasError = true;
      }
      params = {
        TradingPassword: this.inputs.tradingPassword,
        SaveTradingPassword: this.inputs.savePassword
      };
    } else {
      params = {
      };
    }

    if (this.quickTradeStockVerifyForm.valid) {
      this.resetAllFormErrors();

      // submit after entering trade password
      let req: any = {};
      if (params.TradingPassword) {
        req.TradingPassword = params.TradingPassword;
        req.SaveTradingPassword = params.SaveTradingPassword;
      }
      this.subscriptionService.setCustomError('RESET');
      this.subscriptions.add(this.httpService.post(gatewayConfig.APIServices.stocksSubmit.url, req).subscribe((data: any) => {
        if (data) {
          this.confirmStoreArray[this.mode].inFlow = {
            orderReviewInfo: this.orderReviewInfo,
            estimatedCost: this.estimatedCost,
            quote: this.quote,
            foreignExchange: this.foreignExchange,
            orderData: data
          };

          let tempOptions = this.commonService.unfoldJSON(this.commonService.obscureAccount(this.storeArray[this.mode].params));
          tempOptions.platform = "quickTrade";
          tempOptions.AccountTypeDesc = this.orderReviewInfo.Account.AccountTypeDesc;
          this.commonService.logBraze("action_trade_stock_order_placed", tempOptions);

          if (this.mode == 'quickTrade') {
            this.subscriptionService.setTradeDrawerPosition(3);
          } else {
            this.submit.emit(req);
          }
        }
      },
        (err) => {
          this.subscriptionService.setCustomError(err.error.Exception);
          setTimeout(() => {
            this.subscriptionService.enableButton('validation');
          }, 200);
        })
      );

    } else {
      setTimeout(() => {
        this.subscriptionService.enableButton('validation');
      }, 200);
      this.formError = QuickTradeStocksVerifyValidator.resolveValidationError(this.quickTradeStockVerifyForm, this.content.error);
      this.resetTradePassword();
    }
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  forgotPassword() {
    this.closeDrawer();
    this.subscriptions.add(this.forgotTradingPasswordInit().subscribe(
      (data: any) => {
        if (data) {
          const action = data.ACTION;
          if (action === 'OTVCNOTREQUIRED') {
            this.commonService.callNonAPI('txn/userPreferences/forgotTradingPassword/landing');
          } else if (action === 'OTVCREQUIRED') {
            //Fetch content for prompth model and pass it to model
            this.subscriptions.add(this.contentService.fetchContent('otvcpromptmodel').subscribe((data3) => {
              this.otvcPromptContent = data3;
            }, (err) => {
            }));

            this.getOtvcRequest().subscribe(
              (data2: any) => {

                if (data2) {
                  this.modalMode = 'forgotTradingPassword';
                  this.verifyurl = '/v2/auth/userpref/otvc/verify';
                  this.otvcPromptDataParam = data2;
                  this.modalService.open('otvcprompt' + this.uniqueId, 'otvcPromptClose' + this.uniqueId);
                  this.currentModule = 'prompt';
                }
              }
            );
          }
        }
      },
      (err) => { })
    );
  }

  forgotTradingPasswordInit() {
    return this.httpService.post(gatewayConfig.APIServices.forgotTradingPasswordInit.url, null);
  }

  getOtvcRequest() {
    return this.httpService.post(gatewayConfig.APIServices.getOtvcRequest.url, {});
  }

  closeModal() {
    if (this.currentModule === 'prompt') {
      this.currentModule = 'quicktradestocksverify';
      this.modalService.close('otvcprompt' + this.uniqueId);
    }
  }

  getFlag(flagName): string {
    if (flagName === undefined) {
      return null;
    } else {
      let response = '';

      switch (flagName.toLowerCase()) {
        case 'ca':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'cdn':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'usd':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
        case 'us':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
      }
      return response;
    }
  }

  typePass() {
    this.formError.tradingPassword = undefined;
  }

  fetchBuyingPowerContent() {
    this.subscriptions.add(this.contentService.fetchContent('buyingpowertooltip').subscribe((data) => {
      this.buyingPowerContent = data;
      this.estimatedBuyingPowerToolTip.openBtnLabel = '';
      this.estimatedBuyingPowerToolTip.cancel = this.globalContent.text.closeTooltip;
      this.estimatedBuyingPowerToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.estimatedBuyingPowerToolTip.title = this.buyingPowerContent.text.buyingPower;
      this.estimatedBuyingPowerToolTip.content = '';
    }, (err) => { })
    );
  }

  openWindow(url) {
    openWindow(url, this.appStore);
  }

  closeDrawer() {
    //this.moveChatIcon();
    this.subscriptionService.openOrCloseDrawer.next();
  }

  // move chat icon to right
  moveChatIcon() {
    // let chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.removeClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.removeClass(kampyleButton, 'move-kampyle');
    // }
  }

  tooltipClicked(val?) {
    if (val == 'moreInfo') {
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:verify:more-info-icon-tool-tip');
    } else {
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:verify:info-icon-tool-tip');
    }
  }

  setFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 200);

  }

  // Omniture for Confirm Screen after Submit
  omnitureTagging() {
    if ((window as any).TrackingEnabled) {
      const config: OmniturePageDetail = this.getOmnitureConfig();
      const tradeInfo: IOmnitureTransactionData = this.trackingService.tagTransaction(
        config?.data?.transaction,
        QuickTradeStocksConfirmStore.inFlow,
        QuickTradeStocksConfirmStore.inFlow.Data.OrderId
      );
      of(this.trackingService.tagPageActionManually(config, tradeInfo));
    }
  }

  getOmnitureConfig() {
    let config: OmniturePageDetail;
    config = {
      name: 'confirmation',
      url: '/txn/quick-trade-stocks/confirm',
      data: {
        name: 'confirmation',
        hierarchy: 'quick-trade,trading,trade-stocks',
        formInfo: {
          name: 'obr-trade-stocks',
          stepName: 'confirmation',
        },
        events: { pageView: true, formSubmit: true, formStep: true, tradeSubmit: true },
        transaction: {
          mainField: 'inFlow',
          fieldNames: {
            action: 'orderReviewInfo.Activity.OrderType',
            type: 'stocks',
            symbol: 'orderReviewInfo.Symbol.SymbolName',
            market: 'orderReviewInfo.Symbol.Market',
            exchange: 'quote.MarketId',
            quote: 'quote.LastPrice.Data',
            quantity: 'orderReviewInfo.Activity.Quantity.Data',
            dividend: 'na',
            priceType: 'orderReviewInfo.Price.Type',
            expiry: 'orderReviewInfo.Expiry.ExpiryType',
            account: 'orderReviewInfo.Account.AccountTypeDesc',
            serviceFee: 'estimatedCost.EstimatedCommission',
            value: 'estimatedCost.EstimatedOrderValue',
            currency: 'orderReviewInfo.Symbol.Market',
            foreignExchange: 'foreignExchange',
          },
        }
      },
    };
    return config;
  }

  sendOmnitureInteractionData(value?: string) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(value, false, true, false, true));
    }
  }

  // Omniture for Verify Screen before Submit
  omnitureTaggingVerify() {
    if ((window as any).TrackingEnabled) {
      const config: OmniturePageDetail = this.getOmnitureConfigVerify();
      of(this.trackingService.tagPageActionManually(config));
    }
  }

  getOmnitureConfigVerify() {
    let config: OmniturePageDetail;
    config = {
      name: 'verification',
      url: '/txn/quick-trade-stocks/verify',
      data: {
        name: 'verification',
        hierarchy: 'quick-trade,trading,trade-stocks',
        formInfo: {
          name: 'obr-trade-stocks',
          stepName: 'verification',
        },
        events: { pageView: true, formSubmit: true, formStep: true, tradeSubmit: true },
      },
    };
    return config;
  }

  getSummaryActionContent(orderType) {
    if (orderType == 'BUY') {
      return this.content.text.summaryBuy;
    } else if (orderType == 'SELL') {
      return this.content.text.summarySell;
    } else if (orderType == 'BUYTOCOVER') {
      return this.content.text.summaryBuyToCover;
    } else if (orderType == 'SHORT') {
      return this.content.text.summaryShortSell;
    }
  }

  valueOrDashContent(value) {
    if (isDefined(value)) {
      return value.Content;
    }
    return this.dash;
  }

  valueOrDashData(value) {
    if (isDefined(value)) {
      return value.Data;
    }
    return this.dash;
  }

  valueOrDashDataLang(value) {
    if (isDefined(value)) {
      if (this.appStore.lang.toLowerCase() == 'fr') {
        return value.Data.replace(/\./g, ",");
      }
      return value.Data;
    }
    return this.dash;
  }

  valueOrDashLots(value, lotsValue) {
    if (isDefined(value)) {
      if (isDefined(lotsValue)) {
        return (parseFloat(value.Data) * parseFloat(lotsValue.Data)).toString();
      }
      return value.Data;
    }
    return this.dash;
  }

  addCommas(text) {
    if (text.length >= 3 && text != this.dash) {
      if (this.appStore.lang.toLowerCase() == 'en') {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
    } else {
      return text;
    }
  }
}
