import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { OnlineRegisterUsernameData } from './onlineregister-username-data';

@Injectable({ providedIn: 'root' })
export class OnlineRegisterUsernameStore extends PageStore<OnlineRegisterUsernameData> {

    constructor() {
        super(new OnlineRegisterUsernameData());
    }

}
