import { Injectable } from '@angular/core';
import { PageStore } from '../../../../shared/models/page-store';
import { InvestmentFactSheetsData } from './investment-factSheets.data';

@Injectable({ providedIn: 'root' })
export class InvestmentFactSheetsStore extends PageStore<InvestmentFactSheetsData> {

    constructor() {
        super(new InvestmentFactSheetsData());
    }

}
