import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { HttpService } from '../services/http.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { ContentService } from '../services/content.service';
import { SessionExtendTimerService } from '../services/session-extend-timer.service';
import { SsoService } from 'src/app/modules/pre-txn/sso/sso.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { CommonService } from 'src/app/shared/services/common.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({ providedIn: 'root' })
export class TxnGuard  {

  constructor(
    private httpService: HttpService,
    private appStore: AppStore,
    private contentProvider: ContentService,
    private sessionExtendTimerService: SessionExtendTimerService,
    private _subscriptionService: ObservableSubscriptionService,
    private router: Router,
    private commonService: CommonService,
    private ssoService: SsoService
  ) {
  }

  canActivate(): Promise<boolean | UrlTree> | boolean {
    const req: any = {};
    return new Promise((resolve) => {
      this.httpService.post(gatewayConfig.APIServices.getSiteState.url, req).subscribe(res => {
        if (res) {
          if (res.PeBannerData && res.PeBannerData.PeUser) {
            this.appStore.state.user.premiumUser = true;
            this._subscriptionService.passPEUser(res.PeBannerData.PeUser);
          }
          else {
            this.appStore.state.user.premiumUser = false;
            this._subscriptionService.passPEUser({});
          }
          this.appStore.retrieveSessionInfo = res;
          this.appStore.state.user.lob = res.Lob.toLowerCase();
          this._subscriptionService.passUserLobs(res.UserLobs);
          this.appStore.state.user.lang = res.Locale.slice(0, 2).toLowerCase();
          this.contentProvider.fetchGlobalContent();
          this._subscriptionService.passLob(res.Lob);
          this.sessionExtendTimerService.makeWorker();
          this.sessionExtendTimerService.resetTimer();
          if (res.AuthOrigin === 'OBR') {
            this.ssoService.startKeepAliveTimer(this.appStore.currentTimeStamp);
          }
          this.commonService.startBrazeSession(!this.appStore.state.state.refresh).subscribe(() => {
            resolve(true);
          });
        } else {
          resolve(false);
        }
      }, (err) => {
        resolve(false);
        throw err;
      });
    });
  }
}
