import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { BuyGICVerifyStore } from '../data/buy-gic-verify-store';
import { Observable } from 'rxjs';

declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class BuyGICVerifyService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ser = input[0];
    ser.input = BuyGICVerifyStore.params;
    return input;
  }

  pruneInput(input: any): any {
    return input;
  }

  getOtvcRequest = (): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.getOtvcRequest.url,
      {}
    );
  };

  forgotTradingPasswordInit = (): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.forgotTradingPasswordInit.url,
      null
    );
  };

}
