import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
//import { PageStore } from '../../../../../shared/models/page-store';

import {SellGicLandingData} from './sell-gic-landing-data';

@Injectable({ providedIn: 'root' })
export class SellGicLandingStore extends PageStore<SellGicLandingData> {

    constructor() {
        super(new SellGicLandingData());
    }

}
