import { Injectable } from "@angular/core";
import { OmniturePageDetail } from "../models/route-config";
import { AppStore } from "../models/app-store";
import { buildInfo } from "../../config/build-info";
import { ClientVersion } from "../../config/app-versions";
import { omniturePageConfig } from "src/app/config/router-mapping";
import { Router } from "@angular/router";
import { get } from "lodash";
import {
  IOmnitureData,
  IOmnitureEventData,
  IOmnitureErrorData,
  IOmnitureFormData,
  IOmniturePageData,
  IOmnitureSiteData,
  IOmnitureUserData,
  IOmnitureTransactionData,
  IOmnitureAdsData,
  IOmnitureMlsTransactionData,
} from "../models/omniture.types";
import moment from "moment";
import { isDefined } from "src/app/shared/services/utils.service";
import { Lang } from "../models/lob.enum";
import { EnvService } from 'src/app/env.service';
import { GtagService } from "./gtag.service";

interface IWindow extends Window {
  digitalData: IOmnitureData;
}

declare var window: IWindow;
declare var _satellite;

@Injectable({
  providedIn: "root",
})
export class TrackingService {
  private _formId: { [key: string]: string } = {};
  constructor(
    private _appStore: AppStore,
    private _router: Router,
    private env: EnvService,
    private gtagService: GtagService
  ) { }

  public appBuildType() {
    const buildType: string = this.env.production
      ? "production"
      : "development";

    return buildType;
  }

  /**
   * return Omniture Page Data
   * @param pageDetail
   */
  private pageInfo(
    pageDetail: OmniturePageDetail,
    tab?: string,
    input?: any,
    interaction?: boolean
  ): IOmniturePageData {    
    return {
      name: this.getPageName(tab, pageDetail, input),
      nameGA: this.getPageNameGA(tab, pageDetail, input),
      url: window.location.origin + "/#" + pageDetail.url,
      referrer: this._appStore.state.state.currentUrl
        ? window.location.origin + "/#" + this._appStore.state.state.currentUrl
        : this._appStore.state.state.refreshURL
          ? window.location.origin + "/#" + this._appStore.state.state.refreshURL
          : "",
      hierarchy: this.getPageHierarchy(pageDetail, interaction),
      language: this._appStore.lang,
      accessibility: true,
    };
  }

  private getPageHierarchy(pageDetail, interaction) {
    if (interaction) {
      return pageDetail.data.hierarchy
        ? pageDetail.data.hierarchy.split(",").map(val => val.trim()).join(":")
        : [];
    } else {
      return pageDetail.url.indexOf("home") > -1
        ? [this._appStore.lob]
        : pageDetail.data.hierarchy
          ? pageDetail.data.hierarchy.split(",").map(val => val.trim())
          : [];
    }
  }

  private getPageName(tab, pageDetail, input) {
    const dynamicDataUrls = [
      "/txn/markets/detail",
      "/txn/quotesWatchList/detail",
    ];
    if (tab) {
      return tab;
    } else if (input && dynamicDataUrls.includes(pageDetail.url)) {
      const name = this.getFormattedData(input[0].input.Symbols[0].SymbolName.toLowerCase());
      return (
        name +
        "-" +
        pageDetail.data.name
      );
    } else {
      return pageDetail.data.name;
    }
  }

  private getPageNameGA(tab, pageDetail, input) {
    const dynamicDataUrls = [
      "/txn/markets/detail",
      "/txn/quotesWatchList/detail",
    ];
    if (tab) {
      return tab;
    } else if (input && dynamicDataUrls.includes(pageDetail.url)) {
      const name = this.getFormattedData(input[0].input.Symbols[0].SymbolName.toLowerCase());
      return (
        name +
        "-" +
        pageDetail.data.nameGA
      );
    } else {
      return pageDetail.data.nameGA;
    }
  }
  public tagTransaction(config: any, data: any, orderId: string) {
    let tradeInfo : any;
    
    if (config.fieldNames) {
      if (config.fieldNames.type === "cash") {
        tradeInfo = this.getTradeItemsInfo(config, data, orderId);
      } else {
        tradeInfo = this.getTradeInfo(config, data, orderId);
      }
    }
    return tradeInfo;
  }

  private getTradeInfo(config: any, data: any, orderId: string) {
    if(config.fieldNames.leg2Qty){
      return{
        transaction: { ID: orderId },
        trade:{
          account:config.fieldNames.account,
          currency:config.fieldNames.currency,
          expiry:config.fieldNames.expiry,
          optionStrategy:config.fieldNames.optionStrategy,
          action:config.fieldNames.action,
          type:config.fieldNames.type,
          quantity:config.fieldNames.quantity,
          symbol:config.fieldNames.symbol,
          leg2Action:config.fieldNames.leg2Action,
          leg2Type:config.fieldNames.leg2Type,
          leg2Qty:config.fieldNames.leg2Qty,
          leg2Symbol:config.fieldNames.leg2Symbol,
          priceType:config.fieldNames.priceType,
          limitAmount:config.fieldNames.limitAmount,
          tradePosition:config.fieldNames.tradePsition,
          serviceFee:config.fieldNames.serviceFee,
          value:config.fieldNames.value ,
          quote: config.fieldNames.quote,
          market:config.fieldNames.market,
          exchange:config.fieldNames.exchange,
        }
      }
    }else{
    return {
      transaction: { ID: orderId },
      trade: {
        action: this.getTradeData(data, config.fieldNames.action),
        type: this.getTradeType(data, config.fieldNames.type),
        symbol: this.getFormattedData(data, config.fieldNames.symbol),
        market: this.getTradeData(data, config.fieldNames.market),
        exchange: this.getTradeData(data, config.fieldNames.exchange),
        quote: this.getNumericValue(data, config.fieldNames.quote),
        quantity: this.getTradeData(data, config.fieldNames.quantity),
        dividend: this.getTradeData(data, config.fieldNames.dividend),
        priceType: this.getTradeData(data, config.fieldNames.priceType),
        expiry: this.getTradeData(data, config.fieldNames.expiry),
        account: get(data, config.fieldNames.account) ? this.getFormattedData(data, config.fieldNames.account) : get(data, config.fieldNames.account),
        serviceFee: this.getNumericValue(data, config.fieldNames.serviceFee, "0.00"),
        value: this.getNumericValue(data, config.fieldNames.value),
        currency: this.getCurrency(data, config),
        foreignExchange: get(data, config.fieldNames.foreignExchange),
      },
    };
  }
  }

  private getTradeType(data, field) {
    let value = get(data, field);
    if (value) {
      return value.toLowerCase();
    }
    return field.toLowerCase();
  }

  private getCurrency(data, config) {
    let curr = get(data, config.fieldNames.currency)
      ? get(data, config.fieldNames.currency)
      : get(data, config.fieldNames.currencyPayFrom)
        ? get(data, config.fieldNames.currencyPayFrom)
        : get(data, config.fieldNames.currencyPayTo);

    curr = curr.toLowerCase();

    if (curr === 'us') {
      return 'usd';
    } else if (curr === 'ca') {
      return 'cad';
    }

    return curr;
  }

  private getTradeData(data, field, defaultValue?) {
    const defValue = isDefined(defaultValue) ? defaultValue : "na";
    const fieldValue = get(data, field);
    const val = isDefined(fieldValue) ? fieldValue.replace(/_/g, '-') : defValue;
    return val.toLowerCase();
  }

  private getNumericValue(data, field, defaultValue?) {
    const defValue = isDefined(defaultValue) ? defaultValue : "na";

    let value = get(data, field);
    if(typeof(value) == 'number') {
      value = value.toString();
    }
    if (value && value != 'na') {
      if (Lang.FR == this._appStore.lang) {
        value = value.replace(",", ".");
      }
      console.log("numeric value", value.match(/[+-]?\d+(?:\.\d+)?/g).join(""));
      return value.match(/[+-]?\d+(?:\.\d+)?/g).join("");
    } else {
      return defValue;
    }

  }

  private getTradeItemsInfo(config: any, data: any, orderId: string) {
    return {
      transaction: {
        ID: orderId,
        currency: get(data, config.fieldNames.currency),
        items: [
          {
            amount: get(data, config.fieldNames.amount),
            units: config.fieldNames.units,
            serviceFee: this.getNumericValue(data, config.fieldNames.serviceFee, "0.00"),
            frequency: this.getTradeData(data, config.fieldNames.frequency),
            from: this.getTradeHolding(data,
              config.fieldNames.from,
              config.fieldNames.fromAccountType,
              config.fieldNames.currencyPayFrom,
              config.fieldNames.institutionalNameFrom),
            to: this.getTradeHolding(data,
              config.fieldNames.to,
              config.fieldNames.toAccountType,
              config.fieldNames.currencyPayTo,
              config.fieldNames.institutionalNameTo),
          },
        ],
      },
    };
  }

  private getTradeHolding(data, acctDesc, acctType, currency, instName) {
    let obj = {
      holding: '',
      currency: get(data, currency)
    };

    if (get(data, acctDesc)) {
      obj.holding = this.getFormattedData(data, acctDesc);
    } else {
      if (get(data, acctType).toLowerCase() === 'bnk') {
        obj.holding = "bank-" + this.getFormattedData(data, instName);
      } else if (get(data, acctType)) {
        obj.holding = this.getFormattedData(data, acctType);
      }
    }
    return obj;
  }

  public tagInteraction(data: string, isModal?: boolean, isName?: boolean, inputEvent?: any, branded?: boolean, omniOrGA?: string) {
    let pageDetail = omniturePageConfig(this._router.url.split("?")[0]);
    if (pageDetail && Lang.FR !== this._appStore.lang) {
      let pageDetail = omniturePageConfig(this._router.url.split("?")[0]);
      const pageInfo = this.pageInfo(pageDetail, null, null, true);
      let dataArray = [];
      if (branded) {
        dataArray.push(this.siteInfo().brand);
      }
      dataArray.push(this.siteInfo().name);

      if (!isModal) {
        pageInfo?.hierarchy.length ? dataArray.push(pageInfo?.hierarchy) : "";
        if (!isName && pageInfo.name) {
          dataArray.push(pageInfo.name);
        }
      }

      const formatedData = this.getFormattedData(data);
      dataArray.push(formatedData);
      const interaction = dataArray.join(":");

      window.digitalData = {};
      window.digitalData.interaction = {};
      window.digitalData.interaction.name = interaction;
      window.digitalData.events = {};
      if (inputEvent) {
        window.digitalData.events = inputEvent;
      } else {
        window.digitalData.events.siteInteraction = true;
      }
      this.track(omniOrGA);
    }
  }

  public tagHelpInteraction(data, searchObj, omniOrGA?) {
    let pageDetail = omniturePageConfig(this._router.url.split("?")[0]);
    if (pageDetail && Lang.FR !== this._appStore.lang) {
      let pageDetail = omniturePageConfig(this._router.url.split("?")[0]);
      const pageInfo = this.pageInfo(pageDetail, null, null, true);
      let dataArray = [];
      dataArray.push(this.siteInfo().name);
      pageInfo?.hierarchy.length ? dataArray.push(pageInfo?.hierarchy) : "";
      dataArray.push(pageInfo.name);

      dataArray.push(data.toLowerCase());
      const interaction = dataArray.join(":");
      window.digitalData = {
        siteSearch: {
          results: searchObj.results,
          term: searchObj.term,
          currentPage: searchObj.currentPage,
          filters: searchObj.filters,
          categories: searchObj.categories
        },
        interaction: {
          name: interaction,
        },
        events: {
          siteInteraction: true,
          siteSearch: true
        },
      };
      this.track(omniOrGA);
    }
  }

  public tagPageAction(
    url: string,
    tab?: string,
    tradeInfo?: IOmnitureTransactionData,
    input?: any
  ) {
    let pageDetail = omniturePageConfig(url);
    if (pageDetail) {
      window.digitalData = {};
      window.digitalData.events = {};
      window.digitalData.events.pageView = true;
      window.digitalData.site = this.siteInfo();
      window.digitalData.page = this.pageInfo(pageDetail, tab, input);
      window.digitalData.user = this._userInfo();

      this._formInfo(pageDetail.data?.formInfo);
      this._eventInfo(pageDetail.data?.events);
      this._tradeInfo(tradeInfo);

      // check if the form is submit and remove the id
      if (pageDetail.data?.events?.formSubmit) {
        this._formId = {};
      }

      if (!pageDetail.data?.isSubmitDeferred) {
        this.track();
      }
    }
  }

  //Taaging the page action if router mapping doesnot exist
  public tagPageActionManually(config: OmniturePageDetail, tradeInfo?: IOmnitureTransactionData) {
    if (config) {
      if (!isDefined(window.digitalData)) {
        window.digitalData = {};
      }
      if (!isDefined(window.digitalData.events)) {
        window.digitalData.events = {};
      }
      window.digitalData = {
        events: {
          pageView: true,
        },
        site: this.siteInfo(),
        page: this.pageInfo(config),
        user: this._userInfo(),
      };
      this._formInfo(config.data?.formInfo);
      this._eventInfo(config.data?.events);

      if (tradeInfo) {
        this._tradeInfo(tradeInfo);
      }
      this.track();
    }
  }

  public tagPageActionWithAds(ads, adsImpression, isUserInfo) {
    try {
      window.digitalData.events.adImpression = adsImpression;
      window.digitalData.advertising = ads;
      isUserInfo ? window.digitalData.user = this._userInfo() : "";
      console.log(window.digitalData);
      this.track();
    } catch {
      console.log("Omniture Obj issue.")
    }
  }

  public tagPageActionWithTabValue(init, url, tab, gaTab?) {
    try {
      if (init && window.digitalData.page) {
        window.digitalData.page.name = tab;
        if (gaTab) {
          window.digitalData.page.nameGA = gaTab;
        }
        console.log(window.digitalData);
      } else {
        this.tagPageAction(url, tab);
      }
      this.track();
    } catch {
      console.log("Omniture Obj issue.")
    }
  }

  public tagOmnitureAdsAction(data: IOmnitureAdsData, adImpression: boolean, adClick: boolean) {
    let ads: IOmnitureAdsData[] = [];
    window.digitalData = {};
    ads.push(data);
    if (adImpression) {
      window.digitalData.events = { adImpression: adImpression };
    } else if (adClick) {
      window.digitalData.events = { adClick: adClick };
    }
    window.digitalData.advertising = ads;
    console.log("Ads Data ", window.digitalData);
    this.track();
  }

  /**
   * submit data to omniture
   */
  public track(omniOrGA?) {  
    try{
      // google analytics
      if ((window as any).GaTrackEnabled && omniOrGA != 'O') {
        this.gtagService.sendDataToGoogleAnalytics();
      }

      if ((window as any).OmnitureEnabled && omniOrGA != 'G') {
          //Obj deletion before adobe
          this.cleanUpDigitalData();

          // Adobe omniture analytics 
          this.sendDataToOmniture()
      }
    }
    catch{
      console.log("issue in tracking");
    }
  }

  sendDataToOmniture() {
    if ((window as any).TrackingEnabled) {
      console.log(
        "Omniture Page %s, Data %s",
        JSON.stringify(window.digitalData)
      );
      try {
        if (typeof _satellite !== 'undefined') {
          _satellite.track('track');
        }
      } catch {
        console.log("Omniture Library is misssing");
      }
    }
  }

  cleanUpDigitalData() {
    console.log("track obj before", window.digitalData);

    if (window.digitalData?.page?.nameGA || window.digitalData?.page?.nameGA == undefined) {
      delete window.digitalData?.page?.nameGA;
    }
    
    console.log("track obj after", window.digitalData);

  }
  /**
   * This method is used in field-level-error-global-message.component for tracking client side error
   * @param errors
   */
  public tagError(errors: any, type?: string) {
    const errorObject: Array<IOmnitureErrorData> = [];
    if (type !== "page") {
      try {
        Object.keys(errors).map((key) => {
          let error: IOmnitureErrorData = {
            field: "form-field-" + key,
            type: errors[key] ? this.getFormattedData(errors[key].message) : null,
          };
          errorObject.push(error);
        });
      } catch (e) {
        console.log("Omniture PageError")
      }
    } else {
      let error: IOmnitureErrorData = {
        code: errors?.ErrorCode?.toLowerCase(),
        type: errors ? this.getFormattedData(errors.ErrorMessage) : null,
      };
      errorObject.push(error);
    }
    //window.digitalData = {};
    window.digitalData = {
      errors: errorObject,
      events: {
        error: true,
      },
    };
    this.track();
  }

  /**
   * This method is used to track download event
   * @param fileName
   */
  public tagDownloadEvent(name: string, ext?: string) {
    window.digitalData = {
      events: {
        download: true,
      },
      download: {
        filename: name,
        filetype: ext,
      },
    };
    this.track();
  }

  /**
   * return Omniture Site Data
   */
  public siteInfo(): IOmnitureSiteData {
    let date: string = "LocalBuild";
    if (buildInfo.num.length >= 8 && !isNaN(parseInt(buildInfo.num))) {
      date = buildInfo.num.substring(0, 4) + "-" + buildInfo.num.substring(4, 6) + "-" + buildInfo.num.substring(6, 8);
    }
    return {
      brand: "cibc",
      name: "obr",
      type: this._appStore.isApp() ? "mobile" : "desktop",
      environment: this.appBuildType(),
      appVersion: ClientVersion,
      lastBuildDate: date,
    };
  }
  /**
   * return Omniture User Data
   */
  private _userInfo(): IOmnitureUserData {
    const omnitureId = window.sessionStorage.getItem("omnitureId");
    let authType;
    if (omnitureId) {
      this._appStore.omnitureId = omnitureId;
      window.sessionStorage.removeItem("omnitureId");
    }

    if (this._appStore.omnitureAuthType && this._appStore.omnitureId) {
      authType = this._appStore.omnitureAuthType;
      window.digitalData.events.login = true;
      this._appStore.omnitureAuthType = null;
    }
    return {
      authState: this._appStore.isLoggedIn()
        ? "authenticated"
        : "non-authenticated",
      type: this._appStore.lob,
      ID: this._appStore.isLoggedIn()
        ? this._appStore.omnitureId
        : undefined,
      authType: authType
    };
  }
  /**
   * Return Omniture form Info object
   * @param pageDetail
   */
  private _formInfo(formData: IOmnitureFormData): void {
    this._formUniqueIdIdentifier(formData?.name);
    if (formData) {
      let formInfo: IOmnitureFormData = formData;
      formInfo.uniqueID = this._formId[formData?.name];

      // set form object on digital Data
      window.digitalData.form = formInfo;
    }
  }

  private _eventInfo(eventData: IOmnitureEventData): void {
    if (eventData) {
      window.digitalData.events = eventData;
    }
  }

  private _tradeInfo(tradeInfo: IOmnitureTransactionData) {
    if (tradeInfo) {
      window.digitalData.transaction = tradeInfo.transaction;
      window.digitalData.trade = tradeInfo.trade;
    }
  }

  /**
   * Return unique form Id
   */
  private _formUniqueIdGenerator(): string {
    return `OBR123${Date.now()}`;
  }

  private _formUniqueIdIdentifier(formName: string) {
    if (formName) {
      if (!this._formId[formName]) {
        this._formId = { [formName]: this._formUniqueIdGenerator() };
      }
    } else {
      this._formId = {};
    }
  }

  public getFormattedData(data, field?) {
    let str = data;
    let formatted = "";
    if (get(data, field)) {
      str = get(data, field);
    }

    try {
      formatted = str.replace(/[^a-zA-Z 0-9 : -]/g, "").trim();
      console.log("First formatting ", formatted);

      formatted = formatted.replace(/\s+/g, '-').toLowerCase();
      console.log("Secone formatting ", formatted);
    } catch (e) {
      console.log("Formatted data Exception ", e);
    }
    return formatted;
  }
}
