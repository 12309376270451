import { NgModule } from '@angular/core';
import { HeaderComponent, } from './header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { WidgetSharedModule } from '../../widget-shared.module';

@NgModule({
    declarations: [HeaderComponent],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        WidgetSharedModule
    ],
    exports: [
        HeaderComponent,
    ]
})
export class HeaderModule { }
