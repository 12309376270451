<div *ngIf="showIeSpinner" class="spin" id="imageDivId" aria-live='assertive' aria-atomic="true"
    [ngClass]="((loading) ? 'active' : '') + ' ' + ((hideBackground) ? 'hide-background' : '')">
    <div *ngIf="loading" class="loader">
        <span class="sr-only">{{globalContent.text.loading}}</span>
        <img aria-hidden="true" class="spinner" src="assets/obr2/images/icons/spinner.gif" alt="">
    </div>
</div>

<div *ngIf="!showIeSpinner" class="spin" id="imageDivId" aria-live='assertive' aria-atomic="true"
    [ngClass]="((loading) ? 'active' : '') + ' ' + ((hideBackground) ? 'hide-background' : '')">
    <div *ngIf="loading" class="loader">
        <span id="stillProcessTextId" [ngClass]="(showLoadingText) ? 'loading-content' : 'sr-only'">{{showLoadingText?
            (showStillProcessing? spinnerLoadingText : globalContent.text.loadingSpinnerText) :
            globalContent.text.loading}}</span>
        <svg aria-hidden="true" class="spinner" viewBox="0 0 66 66">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="butt" cx="33" cy="33" r="30"></circle>
        </svg>
    </div>
</div>