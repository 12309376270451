import { Injectable } from '@angular/core';
import { TradeStocksVerifyData } from './trade-stocks-verify-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TradeStocksVerifyStore extends PageStore<TradeStocksVerifyData> {

    constructor() {
        super(new TradeStocksVerifyData());
    }

}
