import { Injectable } from '@angular/core';
import { EventsCalendarData } from './events-calendar-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class EventsCalendarStore extends PageStore<EventsCalendarData> {

    constructor() {
        super(new EventsCalendarData());
    }
}
