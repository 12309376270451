import { RouteGroup } from '../shared/models/route-config';

export const OrderStatusConfig: RouteGroup = {
  '/txn/orderStatus': {
    name: 'OrderStatusLanding',
    service: 'OrderStatusService',
    store: 'OrderStatusStore',
    resolveContent: 'orderstatus',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'orderStatusInit',
        get request(): any {
          return {
            PaginationOption: {
              Offset: '0',
              Limit: '50'
            },
            Refresh: true,
            FiTypeCode: 'E'
          };
        }
      }
    ],
    trackingData: {
      hierarchy: 'order-status',
      isSubmitDeferred: true
    }
  }
};
