import { Component, Input, Output, SimpleChanges, OnInit, EventEmitter, ChangeDetectorRef, OnChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { OtvcSetupValidator } from '../OtvcSetupValidator';
import { OtvcVerifyValidator } from '../OtvcVerifyValidator';
import { AppStore } from '../../../models/app-store';
import _ from 'lodash';
import { OtvcPromptModalComponent } from '../../otvc-prompt/otvc-prompt-modal.component';
import { SpinnerService } from 'src/app/core/components/spinner/service/spinner.service';
import { Lang } from '../../../../../app/shared/models/lob.enum';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { SROnlyPipe } from 'src/app/core/pipes/sr-only.pipe';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
  selector: 'app-otvc-verify-form',
  templateUrl: './otvc-verify-form.component.html',
  styleUrls: ['./otvc-verify-form.component.scss']
})
export class OtvcVerifyFormComponent implements OnChanges {
  @Input() compModel: any;
  @Input() modelContent: any;
  @Input() error: any = {};
  @Input() public loading: boolean;
  @Input() public verifySpinner: boolean;

  @Output() sendOtvcRequested = new EventEmitter();
  @Output() verifyRequested = new EventEmitter();
  @Output() promptModelRequested = new EventEmitter();
  @Output() returnError = new EventEmitter();
  // otvcVerifyForm: FormGroup;
  countryList: any;
  loadingText: string;

  public uniqueId: string;

  // @ViewChild('otvcVerifyForm1', { static: true }) otvcVerifyForm1: any;
  // @ViewChild('otvcVerifyForm2', { static: true }) otvcVerifyForm2: any;
  @ViewChild('otvcSendForm') otvcSendForm: any;
  @ViewChild('otvcVerifyForm') otvcVerifyForm: any;


  constructor(
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    public appStore: AppStore,
    public globalStore: GlobalContentStore,
    private _spinnerService: SpinnerService,
    private commonService: CommonService,
    public sronlyPipe: SROnlyPipe
  ) {

    this.countryList = {
      coun146: true
    };

  }


  // ngOnInit() {
  //   this.otvcVerifyForm = OtvcSetupValidator.createForm(this.otvcVerifyForm, this.formBuilder);
  //   console.log("compmodel" + this.compModel);
  // }

  ngOnChanges(changes: SimpleChanges) {
    this.uniqueId = this.appStore.uniqueId;
    // this.otvcVerifyForm = OtvcSetupValidator.createForm(this.otvcVerifyForm, this.formBuilder);
    console.log("compmodel" + this.compModel);
    // if (isDefined(changes.selectDropdown)) {
    //   this.compModel.otvcDetail.newContact.Method = this.compModel.model.otvcType;
    // }
  }

  resetFormError() {
    // this.error.verifyCodeError = undefined;
    this.error.verifyCode = undefined;
  }

  chkVerify(field: any) {
    // this.error.verifyCodeError = undefined;
    this.error.verifyCode = undefined;
    const tmp = field.verifyCode.replace(/[^0-9]/g, '');
    // var tmp = field;
    field.verifyCode = null;
    this.cdRef.detectChanges();
    field.verifyCode = tmp;
    this.cdRef.detectChanges();
    // This is for "242223" inline error
    if (field.verifyCode === this.modelContent.text.CIBCphonenumber
      || field.verifyCode === this.modelContent.text.CIBCphonenumberINT) {
      // this.compModel.errorModel = {
      //   verifyCode: this.modelContent.error.MSGOTV013
      // };
      return field.verifyCode;
    }
  }
  onPaste(event: any) {
    const pattern = /^\d+$/;
    const input = event.clipboardData.getData('Text');
    if (!pattern.test(input)) {
      event.preventDefault();
    }
  }

  // onKeyUp(event: any) {
  //   if (event.which === 8) {
  //     this.compModel.errorModel = {};
  //   }
  //   if (event.which === 13) {
  //     this.chkVerify(this.compModel.model);
  //     if (this.isSubmitEnabled()) {
  //       this.verifyCode();
  //     }
  //   }
  // }


  isSubmitEnabled() {
    return this.compModel.model.verifyCode.length >= 6;
  }

  getModeContent(mode) {
    // return this.compModel.pageContent.text[mode];
    return this.modelContent.text[mode];
  }

  clearCode() {
    this.compModel.model.verifyCode = '';
    this.compModel.errorModel = {};
  }

  // verifyCode() {
  //   if (this.compModel.model.verifyCode == '' || this.compModel.model.verifyCode === undefined) {
  //     this.error.verifyCodeError = this.modelContent.error.blankVerificationCode;
  //   }
  //   //for 242223 error
  //   else if (this.compModel.model.verifyCode === this.modelContent.text.CIBCphonenumber
  //     || this.compModel.model.verifyCode === this.modelContent.text.CIBCphonenumberINT) {
  //     // this.compModel.errorModel = {
  //     //   verifyCode: this.modelContent.error.MSGOTV013
  //     // };
  //     this.error.verifyCodeError = this.modelContent.error.MSGOTV013;
  //   } else {
  //     this.error.verifyCodeError = undefined;
  //     this.verifyRequested.emit();
  //   }
  // }

  verifyCode() {
    OtvcVerifyValidator.setVerifyValidators(this.otvcVerifyForm, this.modelContent.text);
    OtvcVerifyValidator.setVerifyValue(this.otvcVerifyForm);

    if (this.otvcVerifyForm.valid) {
      // this.error.verifyCodeError = undefined;
      // this.error.verifyCode = undefined;
      this.error.selectDropdown = undefined;

      // added below logic for CIBCphonenumber to validator.ts
      // if (this.compModel.model.verifyCode === this.modelContent.text.CIBCphonenumber
      //   || this.compModel.model.verifyCode === this.modelContent.text.CIBCphonenumberINT) {
      //   // this.error.verifyCode = this.modelContent.error.MSGOTV013;
      //   this.error = {};
      //   setTimeout(() => {
      //     this.error.verifyCode = this.modelContent.error.MSGOTV013;
      //   }, 200);
      // } else {
      //   this.verifyRequested.emit();
      // }
      this.verifyRequested.emit();
    } else {
      this.error = OtvcVerifyValidator.resolveValidationError(this.otvcVerifyForm, this.modelContent.error);
      this.returnError.emit(this.error);
      // ForgotSignonPassValidator.emptyValidators(this.changeSignPassForm);
    }

    //for 242223 error
    // else if (this.compModel.model.verifyCode === this.modelContent.text.CIBCphonenumber
    //   || this.compModel.model.verifyCode === this.modelContent.text.CIBCphonenumberINT) {
    //   this.error.verifyCodeError = this.modelContent.error.MSGOTV013;
    // }
    //  else {
    //   this.error.verifyCodeError = undefined;
    //   this.verifyRequested.emit();
    // }
  }


  isNullOrEmpty(value) {
    if (typeof value === 'undefined') {
      return true;
    }

    if (typeof value === 'object') {
      return !value;
    }

    if (typeof value === 'string' && value !== '') {
      return false;
    }

    return true;
  }


  handleSelection() {
    this.error.selectDropdown = undefined;
    console.log("dropdown" + this.compModel.model.otvcType);
    // this.compModel.isSend = (this.compModel.model.otvcType === -1);
    // this.compModel.isSend = (this.compModel.model.otvcType === '');
    this.compModel.isSend = false;

    // if (this.compModel.model.otvcType == -1) {
    //   this.error.selectDropdown = this.modelContent.error.noContactSelected;
    // } else {
    //   this.error.selectDropdown = undefined;
    // }
  }

  // handleSend(message: string) {
  //   // this.loading = true;
  //   // this.loadingText = "Sending";
  //   if (this.compModel.model.otvcType == -1) {
  //     this.error.selectDropdown = this.modelContent.error.MSGOTV025;
  //     // this.loading = false;
  //   } else {
  //     // this._spinnerService.setFocusElement('resendButton');
  //     this.error.verifyCode = undefined;
  //     this.compModel.model.verifyCode = '';
  //     this.error.selectDropdown = undefined;
  //     this.sendOtvcRequested.emit(message);
  //     // this.setFocus('resendButton');
  //   }
  // }

  submit(message: string) {
    if (message === 'verify') {
      this.verifyCode();
    } else {
      this.handleSend(message);
    }
  }

  handleSend(message: string) {
    // if (this.compModel.model.otvcType == -1) {
    //   this.error.selectDropdown = this.modelContent.error.MSGOTV025;
    //   // this.loading = false;
    // } else {
    OtvcVerifyValidator.setSendValidators(this.otvcSendForm);
    OtvcVerifyValidator.setSendValue(this.otvcSendForm);

    if (this.otvcSendForm.valid) {
      this.error.verifyCode = undefined;
      this.error.selectDropdown = undefined;
      this.compModel.model.verifyCode = '';
      this.sendOtvcRequested.emit(message);
      // this.setFocus('resendButton');
    } else {
      this.error = OtvcVerifyValidator.resolveValidationError(this.otvcSendForm, this.modelContent.error);
      this.returnError.emit(this.error);
    }
  }
  // }


  learnmore() {
    const url = unescape(this.compModel.pageContent.text.learnMoreLink);
    if (this.compModel.isWrapper) {
      window.open(url, '_system', 'location=yes');
    } else {
      window.open(url, '_self');
    }
  }

  learnMore() {
    let learnMoreUrl;
    if (this.appStore.lang === Lang.FR) {
      learnMoreUrl = 'https://www.cibc.com/fr/privacy-security/two-step-verification-b.html';
    } else {
      learnMoreUrl = 'https://www.cibc.com/en/privacy-security/two-step-verification-b.html';
    }
    if (this.appStore.isApp()) {
      window.open(learnMoreUrl, '_system', 'location=yes');
    } else {
      window.open(learnMoreUrl, '_blank');
    }
  }

  formatPhoneNum(mode: any) {
    if (this.countryList['coun' + mode.CountryCode] || mode.Method === 'EMAIL') {
      if (mode.Value.length == 10) {
        return mode.Value.substring(0, 3) + '-' + mode.Value.substring(3, 6) + '-' + mode.Value.substring(6, 10);
      }
      return mode.Value;
    }
    return mode.Value;
  }

  displayDalingCode(mode: any) {
    if (!this.countryList['coun' + mode.CountryCode] && mode.DialCode && mode.Method !== 'EMAIL') {
      return mode.DialCode;
    } else if (this.countryList['coun' + mode.CountryCode] || mode.Method === 'EMAIL') {
      return;
    }
  }

  getDropdownAriaLabel(mode) {
    let label = ' ';
    label = label.concat(this.getModeContent(mode.Method))
    const dialcode = this.displayDalingCode(mode);
    if (dialcode) {
      label = label.concat(this.displayDalingCode(mode))
    }
    label = label.concat(this.spaceNumbers(mode.Value));
    return label;
  }

  spaceNumbers(value) {
    const sronly = this.sronlyPipe.spaceNumbers(value);
    // console.log(sronly);
    return sronly;
  }


  isDesktop() {
    return this.commonService.isDesktop();
  }

  cancel() {
    // this.error.verifyCodeError = undefined;
    this.error.verifyCode = undefined;
    this.error.selectDropdown = undefined;
    this.promptModelRequested.emit();
  }

  setFocus(targetElementId) {
    if (targetElementId) {
      const targetElement = document.getElementById(targetElementId);
      window.requestAnimationFrame(() => {
        targetElement.focus();
      });
    }
  }

}
