import { Injectable } from '@angular/core';
import { ForgotTradingPasswordConfirmData } from './forgot-trading-password-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ForgotTradingPasswordConfirmStore extends PageStore<ForgotTradingPasswordConfirmData> {

    constructor() {
        super(new ForgotTradingPasswordConfirmData());
    }

}
