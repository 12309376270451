import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { PageService } from 'src/app/shared/services/page-service';


declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})

export class EventsCalendarService implements PageService {
  prevURL:any;
    constructor(private appStore: AppStore, private httpService: HttpService) { }
    
      
      prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const ret = input[0].input;
        ret.Date = this.getTodayDate();
        input[0].input = ret;
        return input;
      }

      getTodayDate() {
            const date = new Date();
            let dateFormat = 'YYYY-MM-DD';
            return moment(date).format(dateFormat);
      }

      private previousURL: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    
    setPrevURL(preURL:string){
        this.previousURL.next(preURL);
    }
    getPrevURL(){
        const subscription = this.previousURL.subscribe((url)=>{
            this.prevURL = url;
        });
        setTimeout(()=> subscription.unsubscribe(), 1000);
        return  this.prevURL
    }

    getDataBySymbol(symbol) {
        return this.httpService.post(gatewayConfig.APIServices.eventsBySymbol.url, {
          Symbol: symbol
        });
    }

    getDataByDate(date) {
      return this.httpService.post(gatewayConfig.APIServices.eventsByDate.url, {
        Date: date
      });
    }
    
}