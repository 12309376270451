import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { PageService } from 'src/app/shared/services/page-service';
import { ChangeMutualFundConfirmStore } from "../data/change-mutual-fund-confirm-store";

@Injectable({
    providedIn: 'root'
})
export class ChangeMutualFundConfirmService implements PageService {

    constructor() { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        ser.input = ChangeMutualFundConfirmStore.params;
        return input;
    }
}