import { Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { PersistenceService } from '../services/persistence.service';

export let permissionGuard = function checkPermission(...permissions: string[]): Type<{
    canActivate: CanActivateFn;
}> {
  @Injectable({ providedIn: 'root' })
  class RoutePermissionGuard  {

    constructor(
      private _commonService: CommonService,
      private router: Router,
      private persistenceService: PersistenceService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      | Promise<boolean>
      | boolean
      | UrlTree {
      let ret = this.isPermission(permissions)
      console.log(`checking access for ${permissions.join(', ')}. Access allowed? : ${ret}`);
      if (!ret && this.persistenceService.sessionStoreRetrieve("deepLinkRedirect")) {
        this.persistenceService.removeSessionStoreValue("deepLinkRedirect");
        return this.router.parseUrl("/txn/accounts/myaccounts");
      }
      this.persistenceService.removeSessionStoreValue("deepLinkRedirect");
      return ret;
    }

    isPermission(permissions) {
      let ret = false;
      for (let i = 0; i < permissions.length; i++) {
        ret = ret || this._commonService.checkPermissionForRoutes(permissions[i]);
      }
      return ret;
    }
  }
  return RoutePermissionGuard;
};

