<aside>
  <button id="quick_trade_toggleBtn" type="button" class='button quick-trade-style' [ngClass]="openQuickTrade ? 'open' : 'close quick-trade-style'"
    (click)="Menu(true)" id="toggleQuickTradeBtn">
    <span class="icon-Bolt color01 icon-bolt-size"></span>
    <span aria-hidden="true"
      class="quicktrade-style-text">{{globalContent.text.quickTradeText1}}<br />{{globalContent.text.quickTradeText2}}</span>
    <span class="sr-only">{{globalContent.text.quickTradeHiddenText}}</span>
  </button>
  <div id="quick_trade_renderTrade" *ngIf="renderQuickTrade"
    [@sidebarTrigger]="openQuickTrade ? isDesktop() ? 'open' : 'openmobile' : isDesktop() ? 'close' : 'closemobile'"
    class="sidebar-qt" [attr.aria-hidden]="!openQuickTrade" role="dialog" aria-labelledby="quickTradeHeader">
    <div class="quickTradeHeader">
      <button *ngIf="showStep == 2" id="closeBtnBack" class="quickTradeHeaderBackButton icon-Chevron-Left color03"
        (click)="backClick()" attr.aria-label="{{globalContent.text.quickTradeBackToPreviousPage}}" type="button"></button>

      <div id="quick_trade_header" *ngIf="showStep == 1 || showStep == 2" class="quickTradeHeaderText"
        [ngClass]="showStep == 2 ? 'centered' : ''">
        <!-- <span *ngIf="showStep == 1"><i class="icon-Bolt"></i></span> -->
        <h2 class="text-title" id="quickTradeHeader">
          <span *ngIf="showStep == 1">{{globalContent.text.quickTrade}}</span>
          <span *ngIf="showStep == 2">{{globalContent.text.quickTradeOrderReview}}</span>
        </h2>
      </div>

      <div *ngIf="showStep == 3" class="spacer"></div>
      <button *ngIf="openQuickTrade && renderQuickTrade && isDesktop()" id="closeBtn" class="quickTradeHeaderCloseButton" (click)="closeDrawer()" type="button"
        attr.aria-label="{{globalContent.text.quickTradeClose}}">
        <i class="icon-Close"></i>
        <span class="sr-only">{{globalContent.text.quickTradeClose}}</span>
      </button>
    </div>
    <!-- <button *ngIf="openQuickTrade && renderQuickTrade && isDesktop()" id="closeBtn" type="button" (click)="closeDrawer()" class="closeButton-container" attr.aria-label="{{globalContent.text.quickTradeClose}}">
      <span class="icon-Arrow-Right"></span>
    </button> -->
    <div id="quick_trade_main" class="quickTradeMain">
      <app-quick-trade-stocks-landing *ngIf="showStep == 1"
        (dataLoaded)="dataLoaded($event)"></app-quick-trade-stocks-landing>
      <app-quick-trade-stocks-verify *ngIf="showStep == 2"
        (dataLoaded)="dataLoaded($event)"></app-quick-trade-stocks-verify>
      <app-quick-trade-stocks-confirm *ngIf="showStep == 3"
        (dataLoaded)="dataLoaded($event)"></app-quick-trade-stocks-confirm>
    </div>
  </div>
</aside>
<div [ngClass]="openQuickTrade ? 'trade-drawer-mobile-background' : ''"></div>
