import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { BUY_STRUCT_NOTES_CONSTANT } from '../../buy-struct-notes.constant';
import { BuyStructNotesSelectionStore } from '../data/buy-struct-notes-selection-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class BuyStructNotesSelectionService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (BuyStructNotesSelectionStore.crossFlow && BuyStructNotesSelectionStore.crossFlow.productType === BUY_STRUCT_NOTES_CONSTANT.PPN) {
      const ret = [input[1]];
      return ret;
    } else if (BuyStructNotesSelectionStore.savedInfo) {
      if (BuyStructNotesSelectionStore.savedInfo.productType === BUY_STRUCT_NOTES_CONSTANT.PAR) {
        const ret = [input[0]];
        return ret;
      } else {
        const ret = [input[1]];
        return ret;
      }
    }
    else {
      const ret = [input[0]];
      return ret;
    }
  }


  getNotes(req) {
    return this.httpService.post(gatewayConfig.APIServices.notesOfferings.url,
      req
    );
  }
}
