import { BaseData } from '../../../../../../../../src/app/shared/models/base-data';

export class TransferFormsVerifyData extends BaseData {
    static savedInfo: any[];
    constructor() {
        super();
        this.input = {
            signature: null,
        };
    }
}

export interface TransferFormsVerifyInputs {
    signature?: string;
    isRegisteredAccount?: boolean;
}


export interface TransferFormSubmitRequest {
    WebFormName?: string;
    Stage?: string,
    Parameters?: {
        param40: string;
    }
}
export interface VerifyResponse {
    Parameters: {
        accountNumber: number,
        accountType: string,
        fromAccountNumber: string,
        fromAccountTypeName: string,
        institutionName: string,
        transferType: string,
        transferDetailType1: string,
        transferDetailSymbol1: string,
        transferDetailAmt1: string,
        transferDetailAmtUnit1: string,
        transferDetailDate1: string,
        transferDetailType2: string,
        transferDetailSymbol2: string,
        transferDetailAmt2: string,
        transferDetailAmtUnit2: string,
        transferDetailDate2: string,
        transferDetailType3: string,
        transferDetailSymbol3: string,
        transferDetailAmt3: string,
        transferDetailAmtUnit3: string,
        transferDetailDate3: string
    };
};

export enum assetType {
    INKIND = 'Inkind',
    CASH = 'Intype',
    PARTIAL = 'PARTIAL',
    INCASH= "Incash",
    incash="INCASH",
    inkind="INKIND",
    UNIT="UNIT",
    NOTLISTED= 'Institution not listed'

}



export enum ParamsMapping {
    Param1 = 'signature'
}