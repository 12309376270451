import { FormValidatorFactory } from '../../../../shared/services/cibc.formvalidator';
import { isDefined } from 'src/app/shared/services/utils.service';
import { FormGroup, FormControl } from '@angular/forms';

export class TradeOptionsValidator {
  static createForm(form, fb) {
    const grp = {
      accountNumber: [],
      actionMode: [''],
      // marketMode: [''],
      optionHoldingIndex: [],
      symbol: [''],
      quantity: [],
      priceMode: [''],
      expiryMode: [''],
      limit: [],
      stopPrice: [''],
      stopLimitPrice: [''],
      // settlementIndex: [''],
      strikePriceIndex: [],
      strikeDateIndex: [],
      optionType: ['']
    }
    form = fb.group(grp);

    return form;
  }
  static emptyValidators(form, holdings) {

    form.controls['accountNumber'].setValidators([]);
    form.controls['actionMode'].setValidators([]);
    // form.controls['marketMode'].setValidators([]);
    form.controls['optionHoldingIndex'].setValidators([]);
    if (!holdings) {
      form.controls['symbol'].setValidators([]);
      if (form.controls['strikeDateIndex']) {
        form.controls['strikeDateIndex'].setValidators([]);
        form.controls['strikePriceIndex'].setValidators([]);
        form.controls['optionType'].setValidators([]);
      }
    }
    form.controls['quantity'].setValidators([]);
    form.controls['priceMode'].setValidators([]);
    form.controls['expiryMode'].setValidators([]);
    if (form.value.priceMode == 'limitMode') {
      form.controls['limit'].setValidators([]);
    }
    // form.controls['settlementIndex'].setValidators([]);
  }

  static setValidators(form, holdings, symbolNoOption, french) {

    form.controls['accountNumber'].setValidators([FormValidatorFactory.createGreaterThanValidator('MSGOPT0001', -1)]);
    form.controls['actionMode'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGOPT0002')]);
    // form.controls['marketMode'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGOPT0003')]);
    if (holdings) {
      form.controls['optionHoldingIndex'].setValidators([FormValidatorFactory.createGreaterThanValidator('MSGOPT0046', -1)]);
      // form.controls['symbol'].setValidators([]);
      // form.controls['strikeDate'].setValidators([]);
      // form.controls['strikePrice'].setValidators([]);
    } else {
      form.controls['optionHoldingIndex'].setValidators([]);
      if (symbolNoOption) {
        form.controls['symbol'].setValidators([
          FormValidatorFactory.alwaysInvalid('MSGOPT0030'),
          FormValidatorFactory.createNotBlankValidator('MSGOPT0004')
        ]);
      } else {
        form.controls['symbol'].setValidators([
          FormValidatorFactory.createAlphaNumWithSpecialCharsValidator('MSGOPT0030'),
          FormValidatorFactory.createBoundedLengthValidator('MSGOPT0030', 1, 12),
          FormValidatorFactory.createNotBlankValidator('MSGOPT0004')
        ]);
      }
      if (form.controls['strikeDateIndex']) {
        form.controls['strikeDateIndex'].setValidators([FormValidatorFactory.createGreaterThanValidator('MSGOPT0052', -1)]);
        form.controls['strikePriceIndex'].setValidators([FormValidatorFactory.createGreaterThanValidator('MSGOPT0053', -1)]);
        form.controls['optionType'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGOPT0055')]);
      }
    }
    form.controls['quantity'].setValidators(
      [
        FormValidatorFactory.createLessThanValidator('MSGOPT0061', 251),
        FormValidatorFactory.createNotBlankValidator('MSGOPT0005'),
        FormValidatorFactory.createIsNumberValidator('MSGOPT0005', french),
        FormValidatorFactory.createGreaterThanValidator('MSGOPT0005', 0),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGOPT0005', 0)
      ]);
    form.controls['priceMode'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGOPT0009')]);
    form.controls['expiryMode'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGOPT0020')]);
    if (form.value.priceMode == 'limitMode') {
      form.controls['limit'].setValidators([
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGOPT0018', 2),
        FormValidatorFactory.createGreaterThanValidator('MSGOPT0010', 0),
        FormValidatorFactory.createIsNumberValidator('MSGOPT0013', french),
        FormValidatorFactory.createBoundedLengthValidator('MSGOPT0013', 1, 10),
        FormValidatorFactory.createNotBlankValidator('MSGOPT0011')
      ]);
    } else {
      // form.controls['limitInput'].setValidators([]);
    }
    // form.controls['settlementIndex'].setValidators(
    //   [
    //     FormValidatorFactory.createGreaterThanValidator('MSGOPT0044', -1)
    //   ]);

  }

  static setValue(form) {
    let val: any;
    val = {
      accountNumber: form.value.accountNumber,
      actionMode:(form.value.actionMode === undefined) ? '' : form.value.actionMode,
      // marketMode: (form.value.marketMode === undefined) ? '' : form.value.marketMode,
      optionHoldingIndex: (form.value.optionHoldingIndex === undefined) ? -1 : form.value.optionHoldingIndex,
      symbol: form.value.symbol,
      quantity: (form.value.quantity === undefined) ? '' : form.value.quantity,
      priceMode: (form.value.priceMode === undefined) ? '' : form.value.priceMode,
      expiryMode: (form.value.expiryMode === undefined) ? '' : form.value.expiryMode,
      // settlementIndex: form.value.settlementIndex
    }
    if (form.controls['strikePriceIndex']) {
      val.strikePriceIndex = form.value.strikePriceIndex
      val.strikeDateIndex = form.value.strikeDateIndex
      val.optionType = form.value.optionType
    }
    if (form.controls['limit']) {
      val.limit = (form.value.limit === undefined) ? '' : form.value.limit;
    } else if (form.controls['stopPrice']) {
      val.stopPrice = (form.value.stopPrice === undefined) ? '' : form.value.stopPrice;
      val.stopLimitPrice = (form.value.stopLimitPrice === undefined) ? '' : form.value.stopLimitPrice;
    }

    form.setValue(val);
  }

  static validate(form) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validate(control);
      }
    });
  }

  static resolveValidationError(form, errorContent) {
    const messges = [];
    messges.push(
      {
        controlName: 'accountNumber',
        code: form.controls['accountNumber'].errors
      });
    messges.push(
      {
        controlName: 'actionMode',
        code: form.controls['actionMode'].errors
      });
    // messges.push(
    //   {
    //     controlName: 'marketMode',
    //     code: form.controls['marketMode'].errors
    //   });

    messges.push(
      {
        controlName: 'symbol',
        code: form.controls['symbol'].errors
      });
    messges.push(
      {
        controlName: 'quantity',
        code: form.controls['quantity'].errors
      });
    messges.push(
      {
        controlName: 'priceMode',
        code: form.controls['priceMode'].errors
      });
    messges.push(
      {
        controlName: 'expiryMode',
        code: form.controls['expiryMode'].errors
      });
    if (form.controls['limit']) {
      messges.push(
        {
          controlName: 'limit',
          code: form.controls['limit'].errors
        });
    }
    if (form.controls['stopPrice']) {
      messges.push(
        {
          controlName: 'stopPrice',
          code: form.controls['stopPrice'].errors
        });
      messges.push(
        {
          controlName: 'stopLimitPrice',
          code: form.controls['stopLimitPrice'].errors
        });
    }
    // messges.push(
    //   {
    //     controlName: 'settlementIndex',
    //     code: form.controls['settlementIndex'].errors
    //   });

    if (form.controls['strikeDateIndex']) {
      messges.push(
        {
          controlName: 'strikeDateIndex',
          code: form.controls['strikeDateIndex'].errors
        });
      messges.push(
        {
          controlName: 'strikePriceIndex',
          code: form.controls['strikePriceIndex'].errors
        });
      messges.push(
        {
          controlName: 'optionType',
          code: form.controls['optionType'].errors
        });
    }
    messges.push(
      {
        controlName: 'optionHoldingIndex',
        code: form.controls['optionHoldingIndex'].errors
      });
    return this.setErrorMessage(messges, errorContent);
  }

  static setErrorMessage(results, errorContent) {
    const error = {};
    results.forEach(result => {
      if (result.code !== null) {
        if (isDefined(result.code.errorCode)) {
          error[result.controlName] = errorContent[result.code.errorCode];
        }
        console.log(JSON.stringify(result));
      }
    });
    return error;
  }

  static processRestFormErrors(formError) {
    const error = {};
    switch (formError.error.Category) {
      case 'accountNumber':
        error['accountNumber'] = formError.error.Errors[0].ErrorMessage
      case 'actionMode':
        error['actionMode'] = formError.error.Errors[0].ErrorMessage
      // case 'marketMode':
      //   error['marketMode'] = formError.error.Errors[0].ErrorMessage
      case 'optionHoldingIndex':
        error['optionHoldingIndex'] = formError.error.Errors[0].ErrorMessage
      case 'symbol':
        error['symbol'] = formError.error.Errors[0].ErrorMessage
      case 'quantity':
        error['quantity'] = formError.error.Errors[0].ErrorMessage
      case 'priceMode':
        error['priceMode'] = formError.error.Errors[0].ErrorMessage
      case 'expiryMode':
        error['expiryMode'] = formError.error.Errors[0].ErrorMessage
      case 'limitInput':
        error['limitInput'] = formError.error.Errors[0].ErrorMessage
      case 'stopPrice':
        error['stopPrice'] = formError.error.Errors[0].ErrorMessage
      case 'stopLimitPrice':
        error['stopLimitPrice'] = formError.error.Errors[0].ErrorMessage
      // case 'settlementIndex':
      //   error['settlementIndex'] = formError.error.Errors[0].ErrorMessage
    }
    return error;
  }

  static resetErrorMessage(form, fb, errorContent) {
    console.log('RESETTING THE FORM');
    const messges = [];
    messges.push(
      {
        controlName: 'accountNumber',
        code: form.controls['accountNumber'].errors
      });
    messges.push(
      {
        controlName: 'actionMode',
        code: form.controls['actionMode'].errors
      });
    // messges.push(
    //   {
    //     controlName: 'marketMode',
    //     code: form.controls['marketMode'].errors
    //   });
    messges.push(
      {
        controlName: 'optionHoldingIndex',
        code: form.controls['optionHoldingIndex'].errors
      });
    messges.push(
      {
        controlName: 'symbol',
        code: form.controls['symbol'].errors
      });
    messges.push(
      {
        controlName: 'quantity',
        code: form.controls['quantity'].errors
      });
    messges.push(
      {
        controlName: 'priceMode',
        code: form.controls['priceMode'].errors
      });
    messges.push(
      {
        controlName: 'actionMode',
        code: form.controls['actionMode'].errors
      });
    messges.push(
      {
        controlName: 'limitInput',
        code: form.controls['limitInput'].errors
      });
    messges.push(
      {
        controlName: 'stopPrice',
        code: form.controls['stopPrice'].errors
      });
    messges.push(
      {
        controlName: 'stopLimitPrice',
        code: form.controls['stopLimitPrice'].errors
      });
    // messges.push(
    //   {
    //     controlName: 'settlementIndex',
    //     code: form.controls['settlementIndex'].errors
    //   });
    const error = {};
    messges.forEach(result => {

      error[result.controlName] = undefined;
      console.log('ControlName' + result.controlName);

    });
    return error;
  }
}