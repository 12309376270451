import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class DateSelectorService {

  focusableEls: any;
  idEIs: any;
  forEIs: any;
  eachOpenModalModifiedELs: any = [];
  currentBlock: string;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  open() {
    const focusableElementQuery = 'a[href]:not([disabled]),' +
      'button:not([disabled]),textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="password"]:not([disabled]),' +
      'input[type="radio"]:not([disabled]), input[type="number"]:not([disabled]),' +
      'input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="tel"]:not([disabled])';
    this.focusableEls = this.document.querySelectorAll(focusableElementQuery);

    this.idEIs = document.getElementById("page-container").querySelectorAll('[id]');
    this.forEIs = document.getElementById("page-container").querySelectorAll('[for]');

    this.eachOpenModalModifiedELs = {
      focusableElements: []
    };
    this.findFocusableElements(this.focusableEls);
    //if (this.getMobileOperatingSystem() === 'Android' || this.getMobileOperatingSystem() === 'iOS') {
    this.findFocusableElements(this.idEIs);
    this.findFocusableElements(this.forEIs);
    //}
  }

  // update tabIndex -1 for focusable Elements
  findFocusableElements(ele) {
    let index = 0;
    [].forEach.call(ele, (e) => {
      index++;
      if ((!e.hasAttribute('tabindex') || e.id === 'focusElement')
        && !this.getClosest(e, 'app-date-selector')
        // && ((e.id !== 'page-content-container' && e.id !== 'page-content') || e.hasAttribute('for'))
        && (e.id !== 'page-content-container' || e.hasAttribute('for'))
      ) {
        //e.setAttribute('tabindex', '-1');
        e.setAttribute('aria-hidden', 'true');
        this.eachOpenModalModifiedELs.focusableElements.push(e);
      }
    });
  }

  getClosest = (elem, selector) => {
    for (; elem && elem !== this.document; elem = elem.parentNode) {
      if (elem.matches(selector)) {
        return true;
      }
    }
    return null;
  }

  close() {
    if (this.eachOpenModalModifiedELs) {
      this.undoTrapFocus(this.eachOpenModalModifiedELs.focusableElements);
    }
  }

  // removing tabIndex -1
  undoTrapFocus(currentEls: any) {
    for (const property in currentEls) {
      if (currentEls[property] instanceof HTMLElement) {
        //currentEls[property].removeAttribute('tabindex');
        currentEls[property].removeAttribute('aria-hidden');
      }
    }
    if (this.eachOpenModalModifiedELs) {
      delete this.eachOpenModalModifiedELs;
    }
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }
}
