import { Component, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { GlobalContentStore } from '../../store/global-content-store';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import { AppStore } from '../../models/app-store';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'app-global-refresh',
  templateUrl: './global-refresh.component.html',
  styleUrls: ['./global-refresh.component.scss']
})
export class GlobalRefreshComponent implements AfterViewInit, OnDestroy {

  @Input() name: string;
  @Input() type: string;

  public timestamp: string = '';
  public animateRefresh: boolean = false;
  public refreshCount: number = 0;
  public announcement: string = '';
  private subscriptions = new Subscription();

  constructor(
    public globalContent: GlobalContentStore,
    public subscriptionService: ObservableSubscriptionService,
    private appStore: AppStore,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngAfterViewInit() {
    this.setTimestamp();
    this.subscriptions.add(this.subscriptionService.globalRefreshUpdateStart$.subscribe((options: any) => {
      if (options.channel == this.name) {
        this.refreshCount += options.count;
        this.announcement = '';
      }
    }));
    this.subscriptions.add(this.subscriptionService.globalRefreshUpdate$.subscribe((options: any) => {
      if (options.channel == this.name) {
        this.setTimestamp();
        if (this.refreshCount > 0) {
          this.refreshCount -= options.count;
          if (this.refreshCount <= 0) {
            this.refreshCount = 0;
            this.announcement = this.globalContent.text.refreshAnnouncement;
          }
        }

        if (options.focus) {
          let el = document.getElementById('global-refresh-id-' + this.name);
          if (el) {
            setTimeout(() => {
              el.focus();
            }, 100);
          }
        }
      }
    }));

    this.subscriptions.add(this.router.events.subscribe(a => {
      if (a instanceof NavigationEnd) {
        this.refreshCount = 0;
        this.announcement = '';
        this.setTimestamp();
      }
    }));
  }

  setTimestamp() {
    let timeZone = this.appStore.state.user.lang == 'en' ? ' ET' : ' HE';
    let datestart = new Date();
    datestart = new Date(datestart.toLocaleString("en-US", { timeZone: 'US/Eastern' }));
    setTimeout(() => {
      this.timestamp = datestart.getHours() + ":" + datestart.getMinutes().toString().padStart(2, '0') + ":" + datestart.getSeconds().toString().padStart(2, '0') + timeZone;
    }, 10);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  refreshClick() {
    this.subscriptionService.refreshPageContent(this.name);
    this.refreshCount = 0;
    this.animateRefresh = false;
    requestAnimationFrame(() => {
      this.animateRefresh = true;
    })
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }
}
