import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { PreTxnComponent } from './pre-txn.component';
import { SsoComponent } from './sso/sso.component';
import { StateTransitionGuard } from 'src/app/core/guard/state-transition.guard';
import { BuildInfoComponent } from '../../shared/components/build-info/build-info.component';
import { PreTxnGuard } from './../../core/guard/pre-txn.guard';
import { preTxnPermissionGuard } from './../../core/guard/pre-txn-route-permission.guard';
import { lobPermissionGuard } from './../../core/guard/lob.guard';
import { GlobalFixGuard } from './../../core/guard/global-fix.guard';
declare let require: any;

const pretxnConfig = require('../../config/pretxnpage-config.json');


const routes: Routes = [
  { path: 'sso', component: SsoComponent, data: { preload: false } },
  { path: 'error404', loadChildren: () => import('./error404/error404.module').then(m => m.Error404Module), data: { preload: false, isPostSignOnRoute: false } },
  { path: 'error500', loadChildren: () => import('./error500/error500.module').then(m => m.Error500Module), data: { preload: false, isPostSignOnRoute: false } },
  { path: 'pdf-error', loadChildren: () => import('./pdf-error/pdf-error.module').then(m => m.PdfErrorModule), data: { preload: false, isPostSignOnRoute: false } },

  {
    path: 'wrapper-maintenance',
    loadChildren: () => import('./wrapperMaintenance/wrapper-maintenance.module').then(m => m.WrapperMaintenanceModule),
    data: { preload: true, isPostSignOnRoute: false }
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '', component: PreTxnComponent, canActivate: mapToCanActivate([PreTxnGuard]), canActivateChild: [PreTxnGuard],
    children: [
      { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), data: { preload: false, isPostSignOnRoute: false } },
      { path: 'aem', loadChildren: () => import('./aem/aem.module').then(m => m.AemModule), data: { preload: false, isPostSignOnRoute: false } },
      {
        path: 'signon', loadChildren: () => import('./signon/signon.module').then(m => m.SignonModule),
        data: { preload: true, isPostSignOnRoute: false, isClearData: true },
        canActivate: mapToCanActivate([GlobalFixGuard])
      },
      { path: '_app/info', component: BuildInfoComponent },
      { path: 'signOn/otvc/otvcsetup', loadChildren: () => import('../txn/otvc/otvc-security/otvc-security.module').then(m => m.OtvcSecurityModule), data: { preload: false, isPostSignOnRoute: true } },
      {
        path: 'signOn/forgotSignOnPassword',
        loadChildren: () => import('./forgotpassword/forgotsignonpass/forgot-signon-pass.module').then(m => m.ForgotSignonPassModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'signOn/changeSignOnPassword',
        loadChildren: () => import('./forgotpassword/changesignonpass/change-signon-pass.module').then(m => m.ChangeSignonPassModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'signOn/changeSignOnPass/confirm',
        loadChildren: () => import('./forgotpassword/change-signon-pass-confirm/change-signon-pass-confirm.module').then(m => m.ChangeSignonPassConfirmModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'signon/createSignonAndTradingPass',
        loadChildren: () => import('./create-signon-trading-pass/landing/create-signon-trading-pass.module').then(m => m.CreateSignonTradingPassModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, preTxnPermissionGuard(pretxnConfig.permission.CHGSNT), GlobalFixGuard])
      },
      {
        path: 'signon/createSignonAndTradingPass/confirm',
        loadChildren: () => import('./create-signon-trading-pass/confirm/create-signon-trading-pass-confirm.module').then(m => m.CreateSignonTradingPassConfirmModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'signon/createSignonPass',
        loadChildren: () => import('./create-signon-pass/landing/create-signon-pass.module').then(m => m.CreateSignonPassModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, preTxnPermissionGuard(pretxnConfig.permission.CHGSGN), GlobalFixGuard])
      },
      {
        path: 'signon/createSignonPass/confirm',
        loadChildren: () => import('./create-signon-pass/confirm/create-signon-pass-confirm.module').then(m => m.CreateSignonPassConfirmModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])

      },
      {
        path: 'signon/createTradingPass',
        loadChildren: () => import('./create-trading-pass/landing/create-trading-pass.module').then(m => m.CreateTradingPassModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, preTxnPermissionGuard(pretxnConfig.permission.CHGTRD), GlobalFixGuard])
      },
      {
        path: 'signon/createTradingPass/confirm',
        loadChildren: () => import('./create-trading-pass/confirm/create-trading-pass-confirm.module').then(m => m.CreateTradingPassConfirmModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'olbsso/signon',
        loadChildren: () => import('./sso-routing-handler/sso-routing-handler.module').then(m => m.SSORoutingHandlerModule),
        data: { preload: false, isPostSignOnRoute: false },
        // removing guard to fix redirect to home page on second time coming to routing handler
        // canActivate: [StateTransitionGuard]
      },
      {
        path: 'SSOpage',
        loadChildren: () => import('./sso/sso-setup/sso-setup.module').then(m => m.SSOSetupModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'broadcast',
        loadChildren: () => import('../txn/broadcast/broadcast.module').then(m => m.BroadcastModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, preTxnPermissionGuard(pretxnConfig.permission.BRDCST), GlobalFixGuard])

      },
      {
        path: 'electronicAccessAgreement',
        loadChildren: () => import('./electronic-access-agreement/electronic-access-agreement.module').then(m => m.ElectronicAccessAgreementModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'registerOnline/landing',
        loadChildren: () => import('./register-online/landing/register-online.module').then(m => m.RegisterOnlineModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([lobPermissionGuard('IE', 'IIS', 'CFPI', 'DEFAULTIE'), GlobalFixGuard])
      },
      {
        path: 'registerOnline/createPassword',
        loadChildren: () => import('./register-online/createpassword/create-password.module').then(m => m.CreatePasswordModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'registerOnline/confirm',
        loadChildren: () => import('./register-online/confirm/register-online-confirm.module').then(m => m.RegisterOnlineConfirmModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'ssoazure/signon',
        loadChildren: () => import('./sso-azure-routing-handler/sso-azure-routing-handler.module').then(m => m.SSOAzureRoutingHandlerModule),
        data: { preload: false, isPostSignOnRoute: false },
        // removing guard to fix redirect to home page on second time coming to routing handler
        // canActivate: [StateTransitionGuard]
      },
      {
        path: 'sessionEnd',
        loadChildren: () => import('./session-end/session-end.module').then(m => m.SessionEndModule),
        data: { preload: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'olbError',
        loadChildren: () => import('./olb-error/olb-error.module').then(m => m.OLBErrorModule),
        data: { preload: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'newUser',
        loadChildren: () => import('./new-user/new-user.module').then(m => m.NewUserModule),
        data: { preload: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'splash',
        loadChildren: () => import('../txn/splash/splash.module').then(m => m.SplashModule),
        data: { preload: false, isPostSignOnRoute: true },
        canActivate: mapToCanActivate([StateTransitionGuard, preTxnPermissionGuard(pretxnConfig.permission.SPLASH), GlobalFixGuard])
      },
      {
        path: 'onlineRegistration/verification',
        loadChildren: () => import('./wg-online-register/verification/onlineregister-verification.module').then(m => m.OnlineRegisterVerificationModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'onlineRegistration/password',
        loadChildren: () => import('./wg-online-register/password/onlineregister-password.module').then(m => m.OnlineRegisterPasswordModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'onlineRegistration/username',
        loadChildren: () => import('./wg-online-register/create-username/onlineregister-username.module').then(m => m.OnlineRegisterUsernameModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'onlineRegistration/mailingOptions',
        loadChildren: () => import('./wg-online-register/mailing-options/onlineregister-mailing-options.module').then(m => m.OnlineRegisterMailingOptionsModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'onlineRegistration/confirmation',
        loadChildren: () => import('./wg-online-register/confirmation/onlineregister-confirmation.module').then(m => m.OnlineRegisterConfirmationModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, GlobalFixGuard])
      },
      {
        path: 'accountsAgreementsDisclosures',
        loadChildren: () => import('./accounts-agreements-disclosures/accounts-agreements-disclosures.module').then(m => m.AccountsAgreementsDisclosuresModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, lobPermissionGuard('IE'), GlobalFixGuard])
      },
      {
        path: 'feesCommissions',
        loadChildren: () => import('./fees-commissions/fees-commissions.module').then(m => m.FeesCommissionsModule),
        data: { preload: false, isPostSignOnRoute: false },
        canActivate: mapToCanActivate([StateTransitionGuard, lobPermissionGuard('IE'), GlobalFixGuard])
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PreTxnRoutingModule { }
