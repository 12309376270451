import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { OnlineRegisterConfirmationStore } from '../data/onlineregister-confirmation-store';



@Injectable({
  providedIn: 'root'
})
export class OnlineRegisterConfirmationService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (OnlineRegisterConfirmationStore.params) {
      let ser = input[0];
      ser.input = OnlineRegisterConfirmationStore.params;
      return input;
    }
    else {
      return [];
    }  
  }
}
