import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { ContentService } from '../../services/content.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { SiteStore } from 'src/app/shared/models/site-store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { RoutePermissionService } from 'src/app/shared/services/route-permission.service';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');
import { GlobalSignOffService } from '../../services/global-signoff.service';
import { PersistenceService } from '../../services/persistence.service';
import { Lob } from 'src/app/shared/models/lob.enum';

declare let window: any;

@Component({
  selector: 'app-sidebar-launcher',
  templateUrl: './sidebar-launcher.component.html',
  styleUrls: ['./sidebar-launcher.component.scss']
})

export class SidebarLauncherComponent implements OnInit, OnDestroy {
  lob: any;
  lang: any;
  pageContent: any;
  subscriptions = new Subscription();
  isApp: boolean;
  currentUrl: string;
  enableHeaderAndFooter: boolean;
  feedbackAvailable = false;
  feedbackButtonFormId = (window as any).feedbackButtonFormId;
  hideChat;
  feedbackButtonFormIdAndroid = (window as any).feedbackButtonFormIdAndroid;
  feedbackButtonFormIdIos = (window as any).feedbackButtonFormIdIos;
  constructor(
    public appStore: AppStore,
    public globalContent: GlobalContentStore,
    private commonService: CommonService,
    private renderer: Renderer2,
    private subscriptionService: ObservableSubscriptionService,
    private router: Router,
    private persistenceService: PersistenceService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.subscriptionService.openOrCloseDrawer.subscribe(data => {
      if (this.globalContent.list && this.globalContent.list.sidebarItems
        && this.globalContent.list.sidebarItems.top && this.globalContent.list.sidebarItems.top.length) {
        const item = this.globalContent.list.sidebarItems.top.filter(item => item.id == 'toggleQuickTrade')[0];
        if (data == true || data == 'mobile') {
          item.active = true;
        }else if(data == 'close' || !data) {
          item.active = false;
        }
      }
    }));
    this.subscriptions.add(this.subscriptionService.quickOptionsOpenOrCloseDrawer.subscribe(data => {
      if (this.globalContent.list && this.globalContent.list.sidebarItems
        && this.globalContent.list.sidebarItems.top && this.globalContent.list.sidebarItems.top.length) {
        const item = this.globalContent.list.sidebarItems.top.filter(item => item.id == 'toggleOptionQuickTrade')[0];
        if (data == true || data == 'mobile') {
          item.active = true;
        }else if(data == 'close' || !data) {
          item.active = false;
        }
      }
    }));
    this.feedbackAvailable = this.appStore.lob == Lob.IE && !this.appStore.isApp() && window.__env.enableMedallia;
    if(this.feedbackAvailable) {
      // window.addEventListener('MDigital_Submit_Feedback', (e)=>{
      //   console.log(e);
      //   this.handleCloseItem({id: 'nebula_div_btn'});
      // }, false);
      // window.addEventListener('MDigital_Form_Close_No_Submit', (e)=>{
      //   console.log(e);
      //   this.handleCloseItem({id: 'nebula_div_btn'});
      // }, false);
      // window.addEventListener('MDigital_Form_Close_Submit', (e)=>{
      //   console.log(e);
      //   this.handleCloseItem({id: 'nebula_div_btn'});
      // }, false);
    }else {
      // let item = this.globalContent.list.sidebarItems.bottom.filter(item => item.id == 'nebula_div_btn')[0];
      // if(item) {
      //   item.disabled = true;
      // }
    }
    this.subscriptions.add(this.subscriptionService.chatStatusSource$.subscribe(data => {
      if (this.globalContent.list && this.globalContent.list.sidebarItems
        && this.globalContent.list.sidebarItems.bottom && this.globalContent.list.sidebarItems.bottom.length) {
        const item = this.globalContent.list.sidebarItems.bottom.filter(item => item.id == 'genesysChatBtn')[0];
        item.disabled = data;
      }
    }))
    this.retrieveSidebarOnRefresh();
    this.currentPage();
    this.disableGlobalForHelp();
    if (this.globalContent.list && this.globalContent.list.sidebarItems
      && this.globalContent.list.sidebarItems.top && this.globalContent.list.sidebarItems.top.length) {
        this.globalContent.list.sidebarItems.top.forEach(item => {
          item.disabled = !this.checkConfig(item.checkEnvVar) || item.disabled;
        });
    }
    if (this.globalContent.list && this.globalContent.list.sidebarItems
      && this.globalContent.list.sidebarItems.bottom && this.globalContent.list.sidebarItems.bottom.length) {
        this.globalContent.list.sidebarItems.bottom.forEach(item => {
          item.disabled = !this.checkConfig(item.checkEnvVar) || item.disabled;
        });
    }
    this.isApp = this.appStore.isApp('app');
    this.subscriptions.add(
      this.subscriptionService.chathideEvent$.subscribe((val) => {
        this.hideChat = val;
      })
    );
  }

  checkPermission(routeEnum) {
    if (!routeEnum) {
      return true;
    } else {
      return this.commonService.checkPermissionForRoutes(routeEnum);
    }
  }

  retrieveSidebarOnRefresh() {
    if (this.globalContent.list && this.globalContent.list.sidebarItems
      && this.globalContent.list.sidebarItems.top && this.globalContent.list.sidebarItems.top.length) {
      let item = this.globalContent.list.sidebarItems.top.filter(item => item.id == 'toggleQuickTrade')[0];
      if (this.persistenceService.sessionStoreRetrieve('openOrCloseDrawer') == 'true') {
        item.active = true;
        // setTimeout(() => {
        //   this.moveChatIconLeft();
        // }, 4000)
      } else {
        item.active = false;
        // setTimeout(() => {
        //   this.moveChatIconRight();
        // }, 1000)
      }
      item = this.globalContent.list.sidebarItems.top.filter(item => item.id == 'toggleOptionQuickTrade')[0];
      if (this.persistenceService.sessionStoreRetrieve('quickOptionsOpenOrCloseDrawer') == 'true') {
        item.active = true;
        // setTimeout(() => {
        //   this.moveChatIconLeft();
        // }, 4000)
      } else {
        item.active = false;
        // setTimeout(() => {
        //   this.moveChatIconRight();
        // }, 1000)
      }
    }
  }


  checkConfig(key) {
    if (!key) {
      return true;
    } else {
      if(!key.__env) {
        return (window as any)[key];
      }else {
        return (window as any).__env[key.__env]; 
      }
    }
  }

  checkFeedback(item) {
    if (item.id == 'nebula_div_btn') {
      return this.feedbackAvailable;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isDesktop() {
    return this.commonService.isDesktop();
    // return val;
  }

  onClicked(item: any) {
    if (!item.active) {
      console.log("+++++++++ ", item)
      this.handleOpenItem(item);
    } else {
      this.handleCloseItem(item);
    }
  }

  handleOpenItem(item) {
    if (item.id === 'toggleQuickTrade') {
      this.subscriptionService.openOrCloseDrawer.next('mobile');
      item.active = true;
      this.moveChatIconLeft();
      const ie = this.globalContent.list.sidebarItems.top.filter(item => item.id == 'toggleOptionQuickTrade')[0];
      if(ie.active == true)
      {
        this.handleCloseItem(ie, true);
      }


    }
    if (item.id === 'toggleOptionQuickTrade') {
      this.subscriptionService.quickOptionsOpenOrCloseDrawer.next('mobile');
      item.active = true;
      this.moveChatIconLeft();
      const ie = this.globalContent.list.sidebarItems.top.filter(item => item.id == 'toggleQuickTrade')[0];
      if(ie.active == true)
      {
        this.handleCloseItem(ie, true);
      }



    }
    if (item.id == 'nebula_div_btn') {
      this.openFeedback(item.id);
    }
    if(item.id == 'genesysChatBtn') {
      this.subscriptionService.loadStaticChat(true);
    }
  }

  handleCloseItem(item, toggle?) {
    if (item.id === 'toggleQuickTrade') {
      if(!toggle) {
        this.moveChatIconRight();
      }
      this.subscriptionService.openOrCloseDrawer.next();
      item.active = false;
    }
    if (item.id === 'toggleOptionQuickTrade') {
      if(!toggle) {
        this.moveChatIconRight();
      }
      this.subscriptionService.quickOptionsOpenOrCloseDrawer.next();
      item.active = false;
    }
    // if(item.id === 'nebula_div_btn') {
    //   const item = this.globalContent.list.sidebarItems.bottom.filter(item => item.id == 'nebula_div_btn')[0];
    //   if (item) {
    //     item.active = false;
    //   }
    // }
  }

  openFeedback(id) {
    if (!this.appStore.isApp() && window.KAMPYLE_ONSITE_SDK) {
      window.KAMPYLE_ONSITE_SDK.showForm(this.feedbackButtonFormId);
    } else if(((window as any).enableFeedbackMobile === true)){
      const formId = this.appStore.platform.toLowerCase() === "ios" ? this.feedbackButtonFormIdIos : this.feedbackButtonFormIdAndroid;
      this.appStore.features.medalliaDigital.showForm(formId, (success)=>{
        console.log('show sucess' + JSON.stringify(success));
      }, (error) => {
        console.log('show error' + JSON.stringify(error));
      });
    }
  }

  // move chat icon to right
  moveChatIconRight() {
    // let chatIconDivContainer = document.getElementsByClassName('bcFloat')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.removeClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.removeClass(kampyleButton, 'move-kampyle');
    // }
  }

  moveChatIconLeft() {
    // let chatIconDivContainer = document.getElementsByClassName('bcFloat')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.addClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.addClass(kampyleButton, 'move-kampyle');
    // }
  }

  currentPage() {
    return this.appStore.state.state.currentUrl;
  }

  selectedLob() {
    return this.appStore.state.user.lob.toString();
  }

  disableGlobalForHelp() {
    this.currentUrl = this.router.url;
    if (this.currentUrl === "/txn/help" && !this.appStore.isApp('app')) {
      this.enableHeaderAndFooter = false;
    } else {
      this.enableHeaderAndFooter = true;
    }
  }

}
