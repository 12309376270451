import { BaseData } from 'src/app/shared/models/base-data';

export class SellBondLandingData extends BaseData {
  constructor() {
    super();
    this.input = {};
  }
}

export interface SellBondLandingInputs {
  amount: number;
  action: string;
  market: string;
  settlementIndex: number;
  accountIndex: number;
  enteredAmount: number;
}
