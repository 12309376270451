import { Injectable } from '@angular/core';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { SignonStore } from '../data/signon-store';
import { HttpService } from 'src/app/core/services/http.service';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Signon, SignonReq } from '../data/signon-data';
import { ROOT_CTX } from 'src/app/shared/models/user';
import { PageStore } from 'src/app/shared/models/page-store';
import { PersistenceService } from './../../../../core/services/persistence.service';
import { Lob } from './../../../../shared/models/lob.enum';
import { PLF_CONST } from './../../../../shared/models/user';
import { isDefined } from 'src/app/shared/services/utils.service';
import { CommonService } from 'src/app/shared/services/common.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class SignonService implements PageService {


  constructor(
    private appStore: AppStore,
    private store: SignonStore,
    private httpService: HttpService,
    private persistentService: PersistenceService,
    private commonService: CommonService
  ) {
  }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ser = input[0];
    const value = ser.input;
    value.SavedUserIds = this.getLocalStorage();
    value.Profile.Platform = PLF_CONST[this.appStore.platform];
    value.Profile.AccessType = this.appStore.isApp('app') ? 1 : 0;
    value.Locale = this.appStore.state && this.appStore.state.user && this.appStore.state.user.lang ? this.appStore.state.user.lang + '-CA' : '';
    return input;
  }


  signon(data: Signon, editMode) {
    const req: SignonReq = new SignonReq();
    req.UserId = data.userId;
    req.Password = data.password;
    req.EncPassword = data.encPassword;
    req.Mode = data.mode; // TODO
    req.Lob = this.appStore.state.user.lob === Lob.GEN ? Lob.IE.toUpperCase() : this.appStore.state.user.lob.toUpperCase();
    req.Locale = this.appStore.state.user.lang + '-CA';
    if(data.AccountOpen !== undefined && data.AccountOpen == true){
      req.AccountOpen = data.AccountOpen;
    }
    if (editMode) {
      req.SaveUserId = data.rememberPass;
    }
    req.Platform = '0';
    req.SavedUserIds = this.getLocalStorage();
    if(this.isDesktop() && this.appStore.state.user.lob == Lob.IE){
      return this.httpService.post(gatewayConfig.APIServices.signonSubmit.url, req, { params: { skiploading: 'true' } }
    );
    }else{
      return this.httpService.post(gatewayConfig.APIServices.signonSubmit.url, req
    );
    }
   
  }

  getLocalStorage() {
    const savedUserIds = this.persistentService.localStoreRetrieve('MBI_USER_ID');
    if (isDefined(savedUserIds) && savedUserIds !== null && savedUserIds.startsWith('"')) {
      return savedUserIds.substr(1, savedUserIds.length - 2);
    } else if (isDefined(savedUserIds) && savedUserIds !== '') {
      return savedUserIds;
    } else {
      return null;
    }
  }

  getStore(): PageStore<{}> {
    return this.store;
  }

  removeUser(req) {
    return this.httpService.post(gatewayConfig.APIServices.signonRemoveId.url,
      req
    );
  }

  tempPasswordInit() {
    return this.httpService.post(gatewayConfig.APIServices.OTVCTempInit.url,
      {}
    );
  }

  otvcRequestSignOn(mode) {
    if (mode == 'sigonOn') {
      return this.httpService.post(gatewayConfig.APIServices.OTVCInit.url,
        {}
      );
    } else if (mode.startsWith("tempPassword")) {
      return this.httpService.post(gatewayConfig.APIServices.OTVCTempContactDetails.url,
        {}
      );
    }
  }

  initRequest() {
    return {
      url: ROOT_CTX + '/signon/init?v1',
      request: {
        // SavedUserIdsReq:{}
      }
    };
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  signonInit() {
    const req = {
      SavedUserIds: '',
      Profile: {
        Platform: 2,
        AccessType: 0
      }
    };
    return this.httpService.post(gatewayConfig.APIServices.signonInit.url,
      req
    );
  }

  transferSession(payload) {
    return this.httpService.post(gatewayConfig.APIServices.transferSession.url, payload);
  }

  reInitApp(localeData) {
    const payload = {
      SavedUserIds : this.getLocalStorage(),
      Profile: {
        Platform : PLF_CONST[this.appStore.platform],
        AccessType : this.appStore.isApp('app') ? 1 : 0
      },
      Locale: localeData + '-CA'
    }

    return this.httpService.post(gatewayConfig.APIServices.signonInit.url, payload);
  }
}
