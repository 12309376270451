import { DecimalPipe } from '@angular/common';
import { BaseData } from 'src/app/shared/models/base-data';
import { AccountHoldingsStore } from '../../account-holdings/data/account-holdings-store';

export class AccountDetailsData extends BaseData {

}

export interface AccountDetailsRequest {
    IncludeAccountSelector: boolean;
    SelectedAccount?: number;
    SelectedSubAccount?: SelectedSubAccount;
}

export interface SelectedSubAccount {
    SubAccountNumber: number,
    AccountType: string,
    Currency: string
}

export interface AccountDetailResponse {
    AccountOpenDate: string,
    AccountList?: AccountList[],
    SelectedAccount?: string,
    AdvisorLastName?: string,
    AdvisorFirstName?: string,
    SpousalAccount?: number,
    LockedInLegislation?: string,
    CanadianInstitutionTransitAccountList?: string[],
    UsInstitutionTransitAccountList?: string[],
    NameAndAddressLne?: string[],
    AccHoldersHomeTelephone?: string,
    AccHoldersBusniessTelephone?: string,
    Income?: number,
    ShortTermCapitalGains?: number,
    MedTermCapital?: number,
    LongTermCapitalGains?: number,
    LowRiskTolerance?: number,
    MidRiskTolerance?: number,
    HighRiskTolerance?: number,
    LIFPaymentFirstPay?: string,
    PaymentFrequency?: string,
    PaymentAmount?: number,
    YearEndMarketValue?: number,
    MinimumAnnualPayment?: number,
    MaximumAnnualPayment?: number,
    PaymentMethod?: string,
    BankAccount?: string,
    BankTransit?: string,
    PaymentYTD?: string,
    PaymentSelection?: string,
    RSPInformationYTD?: string,
    First60Days?: string,
    CapitalPaymentWithdrawal?: number,
    TotalPlanEducationalAssistance?: number,
    TotalGrants?: number,
    RESPTotalContributionsUnassisted?: number,
    RESPTotalContributionsAssisted?: number,
    beneficiaryList?: BeneficiaryList[],
    TradingAuthorizationDetailsList?: string[],
    Tcp?: any

}

export interface AccountList {
    AccountNumber: number;
    AccountTypeDesc: String;
}

export interface BeneficiaryList {
    FirstName?: string;
    LastName?: string;
    BeneficiaryAllocations?: number,
    ContributionAssistedYTD?: number,
    ContributionAssistedPTD?: number,
    ContributionUnassistedYTD?: number,
    ContributionUnassistedPTD?: number,
    GrantReceivedYTD?: number,
    GrantReceivedPTD?: number,
    EducationalAssistancePmtIncomeYTD?: number,
    EducationalAssistancePmtIncomePTD?: number
}

export enum NoValue {
    ZERO = '0.00'
}

export const legislation = {
    federal: 'FD',
}


