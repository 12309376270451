import { BaseData } from 'src/app/shared/models/base-data';

export class OtvcSecurityData extends BaseData {
    constructor() {
        super();
        this.input = {
            mobilePhone: "",
            homePhone: "",
            businessPhone: "",
            mobilePhoneCountry: -1,
            homePhoneCountry: -1,
            businessPhoneCountry: -1,
            email: "",
            alwaysOtvc: "",
        };
    }
}

export interface OtvcSecurityInputs {
    mobilePhone: string;
    homePhone: string;
    businessPhone: string;
    mobilePhoneCountry: any;
    homePhoneCountry: any;
    businessPhoneCountry: any;
    email: string;
    alwaysOtvc: string;
}
