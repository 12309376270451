import { BaseData } from 'src/app/shared/models/base-data';

export class SignonData extends BaseData {

  constructor() {
    super();
    this.input = { userId: '', password: '', rememberPass: '' };
  }

}

export interface Signon {
  userId: string;
  password: string;
  encPassword: string;
  rememberPass: boolean;
  mode: number;
  AccountOpen?: boolean
}


export class SignonReq {
  UserId: string;
  Password: string;
  EncPassword: string;
  SaveUserId: boolean;
  Mode: number;
  Platform: string;
  Locale: string;
  Lob: string;
  SavedUserIds: string;
  AccountOpen?: boolean
  // EncryptedUserIds: string;
}
