<div>
  <app-quick-trade-stocks *ngIf="checkPermission('TRSTK') && currentUrl !== '/txn/help'"></app-quick-trade-stocks>
  <app-quick-trade-options *ngIf="checkPermission('TROPT') && currentUrl !== '/txn/help'"></app-quick-trade-options>
  <ul class="sidebar-container W10 color03">
    <ng-container *ngFor="let item of globalContent.list.sidebarItems.top">
      <ng-container [ngTemplateOutlet]="itemContainer" [ngTemplateOutletContext]="{item:item}">
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let item of globalContent.list.sidebarItems.bottom;let i = index">
      <ng-container [ngTemplateOutlet]="itemContainer" [ngTemplateOutletContext]="{item:item}">
      </ng-container>
    </ng-container>
  </ul>
</div>
<ng-template let-item="item" #itemContainer>
  <ng-container *ngIf="checkPermission(item.routeEnum) && checkConfig(item.checkEnvVar) && currentUrl !== '/txn/help'">
    <li [ngClass]="{'chat-container': checkConfig('enableGenesysChat'), 'item-container': !checkConfig('enableGenesysChat')}">
      <app-sidebar-item [item]="item" (selectTab)="onClicked($event)">
      </app-sidebar-item>
    </li>
  </ng-container>
</ng-template>