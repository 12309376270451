import { Injectable } from '@angular/core';
import { SSORoutingHandlerData } from './sso-routing-handler.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SSORoutingHandlerStore extends PageStore<SSORoutingHandlerData> {

    constructor() {
        super(new SSORoutingHandlerData());
    }

}
