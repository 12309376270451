import { Injectable } from '@angular/core';
import { PageStore } from '../../../../shared/models/page-store';
import { ContactUsData } from './contact-us-data';

@Injectable({ providedIn: 'root' })
export class ContactUsStore extends PageStore<ContactUsData> {

    constructor() {
        super(new ContactUsData());
    }

}
