<ng-template #suggestionsTplRef>

  <div>

    <ng-container *ngIf="!isDesktop() && notAllowedSpecialChar == false" [ngTemplateOutlet]="fieldErrorTplRef">

    </ng-container>

    <ng-container *ngIf="!isDesktop() && passwordStrengthLabel" [ngTemplateOutlet]="strengthTplRef">
      
    </ng-container>

    <ng-container *ngIf="!isDesktop()" [ngTemplateOutlet]="strengthUserId">
      
    </ng-container>

    <div *ngIf="showPasswordTooltip && isDesktop()" class="tooltip_Parent">
      <div [ngClass]="showAlways ? '' : 'arrow'">
        <div class="arrow-white"></div>
      </div>
      <div class="tooltip">
        <div class="tooltipContainer" [ngClass]="{'tooltipContainerWG' : showAlways}">
          <h3 class="password-criteria-header-label">{{heading}}</h3>
          <!-- <span *ngIf="!pwdTradingHeading "
            class="password-criteria-header-label">{{globalcontent.text.signOnPasswordHeading}}</span>
          <span *ngIf="pwdTradingHeading"
            class="password-criteria-header-label">{{globalcontent.text.tradingPasswordHeading}}</span> -->
          <!-- <span class="tip-x">
            <button type="button" class="icon-Close color03" (click)="handleCloseTooltip()"></button>
          </span> -->
  
  
          <span>
            <ul class="password-critrea-text">
              <li id="criteria_minLength_id" class="password-criteria-value alignFlex">
                  <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="minLength === undefined"></span>
                  <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="minLength ===false"></span> 
                  <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="minLength"></span>
                  <span class="password-criteria-text">{{globalcontent.text.minLength}}</span>           
              <li id="criteria_upperCase_id" class="password-criteria-value alignFlex">
                <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="upperCase === undefined"></span>
                <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="upperCase ===false"></span>
                <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="upperCase"></span>
                <span class="password-criteria-text">{{globalcontent.text.upperCase}}</span>
              </li>
              <li id="criteria_lowerCase_id" class="password-criteria-value alignFlex">
                <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="lowerCase === undefined"></span>
                <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="lowerCase ===false"></span>
                <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="lowerCase"></span>
                <span class="password-criteria-text">{{globalcontent.text.lowerCase}}</span>
              </li>
              <li id="criteria_numberOrSymbol_id" class="password-criteria-value alignFlex">
                <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true"
                  *ngIf="specialChar === undefined && number === undefined"></span>
                <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="specialChar === false && number === false"></span>
                <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true"
                  *ngIf="number || specialChar"></span>
                  <span class="password-criteria-text">{{globalcontent.text.numberOrSymbol}}</span>
              </li>
              <li id="criteria_sequence_id" class="password-criteria-value alignFlex">
                <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="sequencialChar === undefined"></span>
                <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="sequencialChar === true"></span>
                <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true"
                  *ngIf="sequencialChar === false"></span>
                  <span class="password-criteria-text">{{globalcontent.text.sequenceNumber}} {{globalcontent.text.sequenceStart}}{{globalcontent.text.eg}}
                    <span class="strike">{{globalcontent.text.sequence1}}</span>{{globalcontent.text.or}}<span class="strike">{{globalcontent.text.sequence2}}</span>
                    <span>{{globalcontent.text.sequenceEnd}}</span></span>
              </li>
              <li id="criteria_similarChar_id" class="password-criteria-value alignFlex">
                <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="similarChar === undefined"></span>
                <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="similarChar === true"></span>
                <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true"
                  *ngIf="similarChar === false"></span>
                  <span class="password-criteria-text">{{globalcontent.text.similarChar}} {{globalcontent.text.sequenceStart}}{{globalcontent.text.eg}}
                    <span class="strike">{{globalcontent.text.similar1}}</span>{{globalcontent.text.or}}<span  class="strike">{{globalcontent.text.similar2}}</span>
                    <span>{{globalcontent.text.sequenceEnd}}</span></span>
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="showPasswordTooltip && !isDesktop()">
      <ul class="password-critrea-text" [ngClass]="{'styleWG': showAlways}">
        <li id="criteria_notDesktop_minLength_id" class="password-criteria-value alignFlex">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="minLength === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="minLength ===false"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="minLength"></span>
          <span class="password-criteria-text">{{globalcontent.text.minLength}}</span>
        </li>
        <li id="criteria_notDesktop_upperCase_id" class="password-criteria-value alignFlex">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="upperCase === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="upperCase ===false"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="upperCase"></span>
          <span class="password-criteria-text">{{globalcontent.text.upperCase}}</span>
        </li>
        <li id="criteria_notDesktop_lowerCase_id" class="password-criteria-value alignFlex">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="lowerCase === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="lowerCase ===false"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="lowerCase"></span>
          <span class="password-criteria-text">{{globalcontent.text.lowerCase}}</span>
        </li>
        <li id="criteria_notDesktop_number_id"  class="password-criteria-value alignFlex">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="specialChar === undefined && number === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="specialChar === false && number === false"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="number || specialChar"></span>
          <span class="password-criteria-text">{{globalcontent.text.numberOrSymbol}}</span>
        </li>
        <li id="criteria_notDesktop_sequence_id" class="password-criteria-value alignFlex">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="sequencialChar === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="sequencialChar === true"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="sequencialChar === false"></span>
          <span class="password-criteria-text">{{globalcontent.text.sequenceNumber}} {{globalcontent.text.sequenceStart}}{{globalcontent.text.eg}}
            <span class="strike">{{globalcontent.text.sequence1}}</span>{{globalcontent.text.or}}<span class="strike">{{globalcontent.text.sequence2}}</span>
            <span>{{globalcontent.text.sequenceEnd}}</span></span>
        </li>
        <li id="criteria_notDesktop_similar_id" class="password-criteria-value alignFlex">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="similarChar === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="similarChar ===true"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="similarChar === false"></span>
          <span class="password-criteria-text">{{globalcontent.text.similarChar}} {{globalcontent.text.sequenceStart}}{{globalcontent.text.eg}}
            <span class="strike">{{globalcontent.text.similar1}}</span>{{globalcontent.text.or}}<span  class="strike">{{globalcontent.text.similar2}}</span>
            <span>{{globalcontent.text.sequenceEnd}}</span></span>
        </li>
      </ul>
    </div>
  
    <div *ngIf="showUserIdTooltip && isDesktop()" class="tooltip_Parent">
      <div [ngClass]="showAlways ? '' : 'arrow'">
        <div class="arrow-white"></div>
      </div>
      <div class="tooltip">
        <div class="userIdTooltipContainer" [ngClass]="showAlways ? 'userIdTooltipContainerWG' : ''">
          <h3 class="user-criteria-header-label">{{globalcontent.text.userIdHeading}}</h3>
          <!-- <span class="tip-x">
            <button type="button" class="icon-Close color03" (click)="handleCloseTooltip()"></button>
          </span> -->
  
  
          <span>
            <ul class="password-critrea-text">
              <li id="userId_Criteria_length_id"  class="password-criteria-value">
                <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="userIdLength === undefined"></span>
                <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="userIdLength ===false"></span>
                <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="userIdLength"></span>
                &nbsp;{{globalcontent.text.userIdLength}}
              </li>
              <li id="userId_Criteria_letter_id" class="password-criteria-value">
                <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="userIdLetter === undefined"></span>
                <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="userIdLetter ===false"></span>
                <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="userIdLetter"></span>
                &nbsp;{{globalcontent.text.userIdLetter}}
              </li>
              <li id="userId_Criteria_specialChar_id" class="password-criteria-value">
                <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="userIdspecialChar === undefined"></span>
                <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="userIdspecialChar ===true"></span>
                <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true"
                  *ngIf="userIdspecialChar === false"></span>
                &nbsp;{{globalcontent.text.userIdNoSpecialChar}}
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  
    <div *ngIf="showUserIdTooltip && !isDesktop()">
      <ul class="password-critrea-text" [ngClass]="{'styleWG': showAlways}">
        <li id="userId_notDesktop_length_id" class="password-criteria-value">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="userIdLength === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="userIdLength ===false"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="userIdLength"></span>
          &nbsp;{{globalcontent.text.userIdLength}}
        </li>
        <li id="userId_notDesktop_letter_id"  class="password-criteria-value">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="userIdLetter === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="userIdLetter ===false"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true" *ngIf="userIdLetter"></span>
          &nbsp;{{globalcontent.text.userIdLetter}}
        </li>
        <li id="userId_notDesktop_special_id" class="password-criteria-value">
          <span [ngClass]="{'icon-Undefined' : !showAlways, 'icon-Undefined-WG': showAlways}" aria-hidden="true" *ngIf="userIdspecialChar === undefined"></span>
          <span [ngClass]="{'icon-Unchecked' : !showAlways, 'icon-Unchecked-WG': showAlways}" aria-hidden="true" *ngIf="userIdspecialChar ===true"></span>
          <span [ngClass]="{'icon-Checked' : !showAlways, 'icon-Checked-WG': showAlways}" aria-hidden="true"
            *ngIf="userIdspecialChar === false"></span>
          &nbsp;{{globalcontent.text.userIdNoSpecialChar}}
        </li>
      </ul>
    </div>
    
    <ng-container *ngIf="isDesktop() && notAllowedSpecialChar == false" [ngTemplateOutlet]="fieldErrorTplRef">

    </ng-container>

    <ng-container *ngIf="isDesktop() && passwordStrengthLabel" [ngTemplateOutlet]="strengthTplRef">
      
    </ng-container>
    <ng-container *ngIf="isDesktop()" [ngTemplateOutlet]="strengthUserId">
      
    </ng-container>

  </div>
</ng-template>

<ng-template #fieldErrorTplRef>
    <app-field-level-error [error]="formError">
    </app-field-level-error>
</ng-template>

<ng-template #strengthTplRef>
  <label for="password-strength-label" 
      class="password-label">{{globalcontent.text.passwordStrength}}</label>
        <label class="password-strength weakText"
      *ngIf="passwordStrength === 'weak'">{{globalcontent.text.weak}}</label>
      <label class="password-strength moderateText"
        *ngIf="passwordStrength ==='moderate'">{{globalcontent.text.moderate}}</label>
      <label class="password-strength strongText"
        *ngIf="passwordStrength === 'strong'">{{globalcontent.text.strong}}</label>
      <span  class="password-match-section">
        <label *ngIf="passwordStrength ==='moderate' || passwordStrength === 'strong'" id="password-match-text_id" class="password-match-text">
          <span class="icon-Check color20 icon-size" aria-hidden="true"></span>
          {{globalcontent.text.passwordCriteriaMatch}}
        </label>
      </span>
</ng-template>
<ng-template #strengthUserId>
  <span *ngIf="userIdCriteria" class="userId-criteria-box">
    <label id="userId-criteria-text_id" class="userId-criteria-text">
      <span class="icon-Check color20 icon-size" aria-hidden="true"></span>
      {{globalcontent.text.userIdCriteria}}
    </label>
  </span>
</ng-template>