import { Injectable } from '@angular/core';
import { ForgotTradingPasswordLandingData } from './forgot-trading-password-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ForgotTradingPasswordLandingStore extends PageStore<ForgotTradingPasswordLandingData> {

    constructor() {
        super(new ForgotTradingPasswordLandingData());
    }

}
