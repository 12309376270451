import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { PageService } from 'src/app/shared/services/page-service';
import { ChangeCashPlanLandingStore } from "../data/change-cash-plan-landing-store";

@Injectable({
    providedIn: 'root'
})
export class ChangeCashPlanLandingService implements PageService {

    constructor(private httpService: HttpService) { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

        const ser = input[0];
        const req = ser.input;
        req.AccountNumber = ChangeCashPlanLandingStore.params.detailsParam.AccountNumber;
        return [input[0]];

    }

    cashPlanLandingForm(form, fb) {
        form = fb.group({
            selectedAccountType: '',
            amount: '',
            endDate: '0',
            suspendDate: '0',
            resumeDate: '0',
            payFrom: '-1'
        });
        return form;
    }

}