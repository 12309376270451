import { Injectable } from "@angular/core";
import { RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { PageService } from "src/app/shared/services/page-service";
import { AppStore } from "src/app/shared/models/app-store";
import { HttpService } from "src/app/core/services/http.service";
import { SellBondVerifyStore } from "../data/sell-bond-verify-store";
import { Observable } from "rxjs";

declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
  providedIn: "root",
})
export class SellBondVerifyService implements PageService {
  constructor(private httpService: HttpService) {}

  prepareInput(input: any): any {
    const ser = input[0];
    ser.input = SellBondVerifyStore.params;
    return input;
  }

  getOtvcRequest = (): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.getOtvcRequest.url,
      {}
    );
  };

  forgotTradingPasswordInit = (): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.forgotTradingPasswordInit.url,
      null
    );
  };
}
