import { Injectable } from '@angular/core';

import { PageStore } from 'src/app/shared/models/page-store';
import { TransferCentralData } from './tranfer-central-data';

@Injectable({ providedIn: 'root' })
export class TransferCentralStore extends PageStore<TransferCentralData> {

    constructor() {
        super(new  TransferCentralData());
    }
  

}
