<ng-template #strengthMatchTplRef>
<div *ngIf="passwordMatchSection" class="password-match-section">
    <span  *ngIf="passwordMatch"  id="password_match_section_id">
      <i class="icon-Check color20 icon-size paddingRight5" aria-hidden="true"></i>
      <label  class="password-match-text">
        {{globalcontent.text.passwordMatch}}
      </label>
    </span>
    <span *ngIf="!passwordMatch" id="password_donotmatch_section_id">
      <i class="icon-Caution color10 icon-size paddingRight5" aria-hidden="true"></i>
      <label  class="password-donot-match-text">
        {{globalcontent.text.passwordDontMatch}}
      </label>
    </span>
  </div>
</ng-template>