import { BaseData } from 'src/app/shared/models/base-data';

export class CashtransferLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            toAccountList: null,
            fromAccountIndex: -1,
            toAccountIndex: -1,
            selectedDate: null,
            transferAmount: null,
            custDate: undefined,
            showRegularButton: false,
            showSpousalButton: false,
            showFeeButton: false,
            selectedContributionValue: undefined,
            dateMonth: -1,
            dateDay: -1,
            dateYear: -1,
            contributionType: undefined,
            selectedFromAccount: null,
            selectedToAccount: null,
            contributionTypeInput: null
        };
    }
}

export interface CashtransferLandingInputs {

    selectedDate: any;
    fromAccountIndex: number;
    fromAccountList: any;
    toAccountIndex: number;
    toAccountList: any;

    transferAmount: number;
    selectedContributionValue: any;
    showRegularButton: boolean;
    showSpousalButton: boolean;
    showFeeButton: boolean;
    custDate: any;

    dateMonth: number;
    dateDay: number;
    dateYear: number;
    contributionType: any;
    contributionTypeInput: any;

    selectedFromAccount: any;
    selectedToAccount: any;

}

export class ToAccountListReq {
    FromAccount: any;
    CashTransferAcctType: string;
    IncludeBalance: boolean;
}

export class ToAccountDetailReq {
    AccountNumber: any;
    AccountType: string;
    Currency: string;
}