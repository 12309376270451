import { animate, style, transition, trigger } from '@angular/animations';
import { Directive, OnChanges, Input, HostBinding, ElementRef } from '@angular/core';

@Directive({
  selector: '[smoothHeight]',
  host: { '[style.display]': '"block"', '[style.box-sizing]': '"border-box"' }
})
export class SmoothHeightAnimDirective implements OnChanges {
  @Input()
  smoothHeight;
  pulse: boolean;
  startHeight: number;
  overflowValue: string = 'hidden';

  constructor(private element: ElementRef) {}

  @HostBinding('@grow')
  get grow() {
    return { value: this.pulse, params: { startHeight: this.startHeight } };
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges(changes) {
    this.setStartHeight();
    if (changes.smoothHeight.firstChange) {
      this.pulse = !this.pulse;
      this.element.nativeElement.style.overflow = 'hidden';
      setTimeout(() => {
        this.element.nativeElement.style.overflow = 'visible';
      }, 300);
    } else {
      requestAnimationFrame(() => {
        this.pulse = !this.pulse;
        this.element.nativeElement.style.overflow = 'hidden';
        setTimeout(() => {
          this.element.nativeElement.style.overflow = 'visible';
        }, 300);
      });
    }
  }
}
export const smoothHeight = trigger('grow', [
  transition('void <=> *', []),
  transition('* <=> *', [style({ height: '{{startHeight}}px', overflow: 'hidden' }), animate('300ms ease')], {
    params: { startHeight: 0 }
  })
]);