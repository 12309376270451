import { BaseData } from 'src/app/shared/models/base-data';

export class CreatePasswordData extends BaseData {
    constructor() {
        super();
        this.input = {
            userId: null,
            newSignOnPassword: null,
            reEnteredSignOnPassword: null,
            passOption:'',
            newTradingPassword: null,
            reEnteredTradingPassword: null
        };
    }
}

export interface CreatePasswordInputs {
    userId: string;
    newSignOnPassword: string;
    reEnteredSignOnPassword: string;
    passOption: string;
    newTradingPassword: string;
    reEnteredTradingPassword: string;
}