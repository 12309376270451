import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ROOT_CTX } from "src/app/shared/models/user";
declare let require: any;
const gateway = require('../../../../../../config/gateway-config.json');

@Injectable({
    providedIn: 'root'
})

export class MarginAndOptionsConfirmService {
    constructor(private httpClient: HttpClient) {

    }

    getPdfForBrowser(token) {
        return this.httpClient.get(ROOT_CTX + gateway.APIServices.webFormDownload.url + token + '?silent=1', { responseType: 'blob' })
    }

    getPdfForApp(token) {
        return this.httpClient.get(ROOT_CTX + gateway.APIServices.webFormDownload.url + token + '?silent=1', { responseType: 'blob' as 'json' })
    }
}