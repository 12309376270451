import { MyWatchListData } from "./watch-list-data";
import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class MyWatchListStore extends PageStore<MyWatchListData> {

    constructor() {
        super(new MyWatchListData());
    }
}