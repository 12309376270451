import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SellStructNotesVerifyStore } from '../data/sell-struct-notes-verify-store';
import { Observable } from 'rxjs';

declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");


@Injectable({
  providedIn: 'root'
})
export class SellStructNotesVerifyService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let ser = input[0];
    ser.input = SellStructNotesVerifyStore.params;
    return input;
  }

  getOtvcRequest = (): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.getOtvcRequest.url,
      {}
    );
  };

  forgotTradingPasswordInit = (): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.forgotTradingPasswordInit.url,
      null
    );
  };

  getPdfDoc = (DealId, DocumentType): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.notesGetPDF.url,
      {
        DealId,
        DocumentType
      },
      { responseType: 'blob' }
    );
  }
}
