import { Directive, HostListener, ElementRef, OnInit, OnChanges, Renderer2, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';
import { isDefined } from 'src/app/shared/services/utils.service';

@Directive({ selector: '[appPostalCode]' })
export class PostalCodeDirective implements OnInit, OnChanges {

    private el: HTMLInputElement;

    constructor(
        private elementRef: ElementRef,
        private control: NgControl,
    ) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
        // this.el.value = this.currencyPipe.transform(this.el.value);
    }
    ngOnChanges(changes) {
        console.log('on Change merhod ' + changes);
    }

    @HostListener('input', ['$event'])
    onKeyPress(event) {
        if (event) {
            // const regexStr = '^[0-9]*$';
            const regexStr = /^[a-zA-Z]+$/;
            let elValue: string = this.el.value;

            const ch = elValue;
            const regEx = new RegExp(regexStr);
            if (!regEx.test(ch) && isDefined(elValue) && elValue.length === 1) {
                this.control.control.setValue('');
            } else {
                elValue = elValue.replace(/ /g, '');
                elValue = this.GetMaxValue(Array.from(elValue));
                if (isDefined(elValue)) {
                    if (elValue.length > 3) {
                        elValue = elValue.substring(0, 3) + ' ' + elValue.substring(3, elValue.length);
                    }
                    this.control.control.setValue(elValue.toUpperCase());
                }
            }

        }
    }

    private GetMaxValue(input: Array<string>): string {
        const letters = /^[a-zA-Z]+$/;
        if (input.length === 0) {
            return '';
        }
        // eslint-disable-next-line max-len
        const pt = input.reduce(function (prev, curr, currentIndex: number, array: Array<string>) {
            if (currentIndex === 0 || currentIndex === 2 || currentIndex === 4) {
                if (!isNaN(parseInt(curr))) {
                    return prev;
                }

                if (curr.match(letters)) {      // required scenario
                    currentIndex++;
                    return prev + curr;
                }
            }

            if (currentIndex === 1 || currentIndex === 3 || currentIndex === 5) {
                if (curr.match(letters)) {
                    return prev;
                }

                if (!isNaN(parseInt(curr))) {
                    currentIndex++;
                    return prev + curr;
                }
            }

            return prev;
        });

        if (pt != null) {
            return pt;
        } else {
            return '';
        }
    }
}

// @NgModule({
//     declarations: [PostalCodeDirective],
//     exports: [PostalCodeDirective],
// })
// export class PostalCodeDirectiveModule { }
