import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { RegisterOnlineConfirmStore } from '../data/register-online-confirm.store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class RegisterOnlineConfirmService implements PageService {
  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (RegisterOnlineConfirmStore.params) {
      let ser = input[0];
      ser.input = RegisterOnlineConfirmStore.params;
      return input;
    } else {
      return [];
    }
  }

  proceedToSignOn(req) {
    return this.httpService.post(gatewayConfig.APIServices.registerOnlineSignon.url, req);
  }

}
