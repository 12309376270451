import { Injectable } from '@angular/core';
import { CreatePasswordData } from './create-password-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class CreatePasswordStore extends PageStore<CreatePasswordData> {

    constructor() {
        super(new CreatePasswordData());
    }

}
