import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    Input
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatCalendar, MatDatepickerIntl } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import moment from 'moment';

@Component({
    selector: 'date-picker-header-transaction',
    templateUrl: './date-picker-header-transaction.component.html',
    styleUrls: ['./date-picker-header-transaction.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerHeaderTransaction<D> implements OnDestroy {

    private _destroyed = new Subject<void>();
    monthList: any;
    yearList: any;
    fwdFocusShifted: boolean = false;
    prevFocusShifted: boolean = false;
    content: any;

    @Input() selDate: any;

    constructor(
        private _intl: MatDatepickerIntl,
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef,
        public globalContent: GlobalContentStore
    ) {
        this.content = globalContent.getDatePickerHeaderContent();
        _calendar.stateChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get dropdownMonth() {
        const monthStart = this._dateAdapter.getMonth(this._calendar.minDate);
        const monthEnd = this._dateAdapter.getMonth(this._calendar.maxDate);

        if (monthStart <= monthEnd) {
            this.monthList = [];
            for (let i = monthStart; i < monthEnd + 1; i++) {
                this.monthList.push({ key: i, value: this._dateAdapter.getMonthNames('long')[i] });
            }
        } else {
            this.monthList = [];
            for (let i = monthStart; i < 12; i++) {
                this.monthList.push({ key: i, value: this._dateAdapter.getMonthNames('long')[i] });
            }
            for (let i = 0; i < monthEnd + 1; i++) {
                this.monthList.push({ key: i, value: this._dateAdapter.getMonthNames('long')[i] });
            }
        }

        return this.monthList;
    }

    get dropdownYear() {
        const yearStart = this._dateAdapter.getYear(this._calendar.minDate);
        const yearEnd = this._dateAdapter.getYear(this._calendar.maxDate);
        this.yearList = [];

        for (let year = yearStart; year < yearEnd + 1; year++) {
            this.yearList.push({ key: year, value: year });
        }

        return this.yearList;
    }

    dateYearMapping() {
        const monthStart = this._dateAdapter.getMonth(this._calendar.minDate);
        const monthEnd = this._dateAdapter.getMonth(this._calendar.maxDate);
        const yearStart = this._dateAdapter.getYear(this._calendar.minDate);
        const yearEnd = this._dateAdapter.getYear(this._calendar.maxDate);
        let mapping = [];
        if (monthStart <= monthEnd) {

            for (let i = monthStart; i < monthEnd + 1; i++) {
                mapping.push({ month: i, year: yearStart });
            }
        } else {
            this.monthList = [];
            for (let i = monthStart; i < 12; i++) {
                mapping.push({ month: i, year: yearStart });
            }
            for (let i = 0; i < monthEnd + 1; i++) {
                mapping.push({ month: i, year: yearEnd });
            }
        }
        return mapping;
    }

    get backButton(): boolean {
        const minMonth = this._dateAdapter
            .format(this._calendar.minDate, this._dateFormats.display.monthYearLabel);

        const currMonth = this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel);

        const isPrevBtn = minMonth !== currMonth;

        if (!isPrevBtn && !this.prevFocusShifted) {
            this.prevFocusShifted = true;
            const element: any = document.getElementById('tooltipContainer_customDate');
            element.focus();
        }

        if (isPrevBtn && this.prevFocusShifted) {
            this.prevFocusShifted = false;
        }

        return isPrevBtn;
    }

    get forwardButton() {
        const maxMonth = this._dateAdapter
            .format(this._calendar.maxDate, this._dateFormats.display.monthYearLabel);

        const currMonth = this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel);
        const isFwdBtn = maxMonth !== currMonth;

        if (!isFwdBtn && !this.fwdFocusShifted) {
            this.fwdFocusShifted = true;
            const element: any = document.getElementById('tooltipContainer_customDate');
            element.focus();
        }

        if (isFwdBtn && this.fwdFocusShifted) {
            this.fwdFocusShifted = false;
        }

        return isFwdBtn;
    }

    previousClicked(e) {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
        this._monthIndex = this._dateAdapter.getMonth(this._calendar.activeDate);
        const element: any = document.getElementById('prevBtn');

        if (element) {
            element.blur();

            setTimeout(() => {
                if (!this.prevFocusShifted)
                    element.focus();
            })
        }
        e.stopPropagation();
    }

    nextClicked(e) {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
        this._monthIndex = this._dateAdapter.getMonth(this._calendar.activeDate);
        const element: any = document.getElementById('fwdBtn');

        if (element) {
            element.blur();

            setTimeout(() => {
                if (!this.fwdFocusShifted)
                    element.focus();
            })
        }
        e.stopPropagation();
    }

    /** The label for the previous button. */
    get prevButtonLabel(): string {
        const prevMonth = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
        const month = this._dateAdapter.getMonth(prevMonth);
        return this._dateAdapter.getMonthNames('long')[month] + this._dateAdapter.getYearName(prevMonth);
    }

    get nextButtonLabel(): string {
        const nextMonth = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
        const month = this._dateAdapter.getMonth(nextMonth);
        return this._dateAdapter.getMonthNames('long')[month] + this._dateAdapter.getYearName(nextMonth);
    }

    changeMonth() {
        let date = this._dateAdapter.getDate(this._calendar.activeDate);
        const currMonthDays = moment(this._calendar.activeDate, "YYYY-MM").daysInMonth();
        const month = this._monthIndex >= 9 ? this._monthIndex + 1 : "0" + (this._monthIndex + 1);
        if (!this._yearIndex) {
            this._yearIndex = this._dateAdapter.getYear(this._calendar.activeDate);
        }
        const selMonthDays = moment(this._yearIndex + "-" + month, "YYYY-MM").daysInMonth();

        if (selMonthDays < currMonthDays) {
            date = selMonthDays;
        }
        this._calendar.activeDate = this._dateAdapter.createDate(this._yearIndex, this._monthIndex, date);
    }

    changeYear() {
        const activeYear = this._dateAdapter.getYear(this._calendar.activeDate);
        const date = this._dateAdapter.getDate(this._calendar.activeDate);

        if (activeYear < this._yearIndex) {
            this._calendar.activeDate = this._dateAdapter.createDate(this._yearIndex, this._monthIndex, date);
        } else if (activeYear > this._yearIndex) {
            this._calendar.activeDate = this._dateAdapter.createDate(this._yearIndex, this._monthIndex, date);
        }
    }

    get monthSel() {
        return this._dateAdapter.getMonth(this._calendar.activeDate);
    }
    set monthSel(value) {
        this._monthIndex = value;
    }
    private _monthIndex: any;

    get yearSel() {
        return this._dateAdapter.getYear(this._calendar.activeDate);
    }
    set yearSel(value) {
        this._yearIndex = value;
    }
    private _yearIndex: any;
}
