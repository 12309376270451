import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageLevelErrorComponent } from './components/page-level-error/page-level-error.component';
import { AppErrorComponent } from './../core/components/error/app-error.component';
import { CustomAppErrorComponent } from './../core/components/error/custom-error/custom-app-error.component';
import { AppDirective } from './directive/app-directive';
import { WebDirective } from './directive/web-directive';
import { SkipToMainContentComponent } from './components/skip-to-main-content/skip-to-main-content.component';
import { SROnlyPipe } from 'src/app/core/pipes/sr-only.pipe';
import { NewMessageBlockComponent } from './components/new-message-block/new-message-block.component'
import { PipeSharedModule } from './pipe-shared.module';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SwipeDirective } from './directive/swipe.directive';
import { SmoothHeightAnimDirective } from './directive/smooth-height-directive';

@NgModule({
    declarations: [
        ModalComponent,
        PageLevelErrorComponent,
        AppErrorComponent,
        AppDirective,
        WebDirective,
        SkipToMainContentComponent,
        SROnlyPipe,
        NewMessageBlockComponent,
        CustomAppErrorComponent,
        CarouselComponent,
        SwipeDirective,
        SmoothHeightAnimDirective
    ],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, PipeSharedModule],
    exports: [
        ModalComponent,
        PageLevelErrorComponent,
        AppErrorComponent,
        AppDirective,
        WebDirective,
        SkipToMainContentComponent,
        SROnlyPipe,
        NewMessageBlockComponent,
        CustomAppErrorComponent,
        CarouselComponent,
        SwipeDirective,
        SmoothHeightAnimDirective
    ],
    providers: [SROnlyPipe, DatePipe],
})
export class SharedModule { }
