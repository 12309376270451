import { Injectable } from '@angular/core';
import { TradeStocksLandingData } from './trade-stocks-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TradeStocksLandingStore extends PageStore<TradeStocksLandingData> {

    constructor() {
        super(new TradeStocksLandingData());
    }

}
