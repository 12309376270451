import { RouteGroup } from "../shared/models/route-config";

export const TradeStocksConfig: RouteGroup = {
  "/txn/tradeStock/landing": {
    name: "TradeStocksLanding",
    service: "TradeStocksLandingService",
    store: "TradeStocksLandingStore",
    resolveContent: "tradestockslanding",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "stocksInit",
        get request(): any {
          return {
            IncludeBalance: true,
          };
        },
      },
      {
        id: "accInit",
        restUrl: "holdingsNewLoad",
        get request(): any {
          return {};
        },
      },
      {
        id: "holdings",
        restUrl: "getOrderHoldings",
        optional: true,
        get request(): any {
          return {
            FiType: "E"
          };
        },
      },
      {
        id: "settlmentAccounts",
        restUrl: "stocksSettlementAccounts",
        optional: true,
        get request(): any {
          return {
            Type: "STOCKS",
          };
        },
      },
      {
        id: "quickQuote",
        restUrl: "quoteQuick",
        optional: true,
        get request(): any {
          return {
            Symbols: [
              {
                SymbolType: "EQUITY",
              },
            ],
          };
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,trade-stocks",
      name: "details",
      nameGA: "Trade Stocks and ETFs",
      formInfo: { name: "obr-trade-stocks", stepName: "details" },
      events: { formStep: true, formView: true, pageView: true },
    },
  },
  "/txn/tradeStock/verify": {
    name: "TradeStocksVerify",
    service: "TradeStocksVerifyService",
    store: "TradeStocksVerifyStore",
    resolveContent: "tradestocksverification",
    policy: {
      navFrom: {
        url: ["/txn/tradeStock/landing"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/tradeStock/landing",
    },
    restService: [
      {
        id: "init",
        restUrl: "stocksVerify",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,trade-stocks",
      name: "verification",
      nameGA: "Trade Stocks and ETFs - Verification",
      formInfo: { name: "obr-trade-stocks", stepName: "verification" },
      events: { formStep: true, pageView: true },
    }
  },
  "/txn/tradeStock/confirm": {
    name: "TradeStocksConfirm",
    service: "TradeStocksConfirmService",
    store: "TradeStocksConfirmStore",
    resolveContent: "tradestocksconfirmation",
    policy: {
      navFrom: {
        url: ["/txn/tradeStock/verify"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "submit",
        restUrl: "stocksSubmit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,trade-stocks",
      name: "confirmation",
      nameGA: "Trade Stocks and ETFs - Confirmation",
      formInfo: { name: "obr-trade-stocks", stepName: "confirmation" },
      events: { formStep: true, formSubmit: true, pageView: true, tradeSubmit: true },
      transaction: {
        mainField: "inFlow",
        fieldNames: {
          action: "orderReviewInfo.Activity.OrderType",
          type: "stocks",
          symbol: "orderReviewInfo.Symbol.SymbolName",
          market: "orderReviewInfo.Symbol.Market",
          exchange: "quote.MarketId",
          quote: "quote.LastPrice.Data",
          quantity: "orderReviewInfo.Activity.Quantity.Data",
          dividend: "na",
          priceType: "orderReviewInfo.Price.Type",
          expiry: "orderReviewInfo.Expiry.ExpiryType",
          account: "orderReviewInfo.Account.AccountTypeDesc",
          serviceFee: "estimatedCost.EstimatedCommission",
          value: "estimatedCost.EstimatedOrderValue",
          currency: "orderReviewInfo.Symbol.Market",
          foreignExchange: "foreignExchange",
        },
      },
    },
  },
};
