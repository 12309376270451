import { Injectable } from '@angular/core';
import { BuyStructNotesSelectionData } from './buy-struct-notes-selection-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class BuyStructNotesSelectionStore extends PageStore<BuyStructNotesSelectionData> {

  constructor() {
    super(new BuyStructNotesSelectionData());
  }
}
