import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CancelCashPlanLandingData } from './cancel-cash-plan-landing-data';

@Injectable({ providedIn: 'root' })
export class CancelCashPlanLandingStore extends PageStore<CancelCashPlanLandingData>  {

    constructor() {
        super(new CancelCashPlanLandingData());
    }



}