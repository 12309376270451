import { RouteGroup } from "../shared/models/route-config";

export const MarketsMobiConfig: RouteGroup = {
  "/txn/marketsLanding": {
    name: "marketsLanding",
    service: "MarketsLandingService",
    store: "MarketsLandingStore",
    resolveContent: "markets",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "markets",
        restUrl: "getMarketQuote",
        optional: true,
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "quotes-research,markets",
      name: "market-data",
      nameGA: "Market Data",
    },
  },
  "/txn/markets/detail": {
    name: "MarketsDetails",
    service: "MarketsDetailService",
    store: "MarketsDetailStore",
    resolveContent: "marketsdetail",
    policy: {
      navFrom: {
        url: ["/txn/marketsLanding"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "marketdetail",
        restUrl: "getMarketQuoteDetails",
        optional: true,
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "quotes-research,markets,market-data",
      name: "detail",
      nameGA: "Market Detail",
    },
  },
};
