import { HostListener, Directive, Input } from '@angular/core';

enum eventType {
  CUT = 'cut',
  COPY = 'copy',
  PASTE = 'paste',
  ALL = 'all'
}

@Directive({
  selector: '[appBlockCopyPaste]',
})

export class BlockCopyPasteDirective {

  @Input('appBlockCopyPaste') type: string;

  constructor() {
  }

  // We will bind PASTE by default for now in any CUT COPY ALL scenario
  @HostListener('paste', ['$event'])
  blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event'])
  blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event'])
  blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
