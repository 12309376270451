import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ModalService } from '../modal/services/modal.service';
import { GlobalContentStore } from '../../store/global-content-store';
import { CurrencyFormatterPipe } from 'src/app/core/pipes/currency-formatter.pipe';
import { AppStore } from '../../models/app-store';
import { isDefined } from 'src/app/shared/services/utils.service';
import { HttpService } from 'src/app/core/services/http.service';
import { openWindow } from '../../services/utils.service';
import { Lob } from '../../models/lob.enum';
import { Router } from '@angular/router';
import { isNumber } from 'util';
import { CashtransferLandingStore } from 'src/app/modules/txn/cashtransfer/landing/data/cashtransfer-landing-store';
import { CommonService } from '../../services/common.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");


@Component({
  selector: 'app-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: ['./account-card.component.scss']
})
export class AccountCardComponent implements OnInit, OnChanges {

  @Input() public account: any;
  @Input() public content: any;
  @Input() public mode: string;
  @Input() public displayTimeStamp = false;
  @Input() public fontMode: string;
  @Input() public loading;
  @Input() public buyingPowerModeType: string;
  @Input() public isBuyingPowerIncrease; // Only needed for Verificatiin Page
  @Input() public displayLink = false;
  @Input() public displayHeader = true;
  @Input() public selectable = false; // Also using this to check if it's inside account-selector or not
  @Input() public index = -1;
  @Input() public titleId: string;
  @Input() public showBalance = true;
  @Input() public displayBuyingPower = true;
  @Input() public displayMargin = true;
  @Output() selectAccount = new EventEmitter();
  get LOB() { return Lob; }

  balanceLoading: boolean;

  toolTipContent: any = {
    openBtnLabel: '',
    content: '',
    cancel: '',
    btnHiddenText: '',
    title: ''
  };

  estimatedBuyingPowerToolTip: any = {
    openBtnLabel: '',
    content: '',
    cancel: '',
    btnHiddenText: '',
    title: ''
  };


  uniqueId: string;
  colonStr: string;
  cashToggle: boolean = false;

  constructor(
    private router: Router,
    public modalservice: ModalService,
    public globalContent: GlobalContentStore,
    public appStore: AppStore,
    private currencyFormatterPipe: CurrencyFormatterPipe,
    private httpService: HttpService,
    private currPipe: CurrencyFormatterPipe,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.uniqueId = this.appStore.uniqueId;
    if (this.appStore.lang.toUpperCase() === 'FR') {
      this.colonStr = ' :';
    } else {
      this.colonStr = ':';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.content) {
      this.toolTipContent.openBtnLabel = '';
      this.toolTipContent.cancel = this.globalContent.text.closeTooltip;
      this.toolTipContent.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.toolTipContent.title = this.content.text.buyingPower;
      this.toolTipContent.content = '';

      this.estimatedBuyingPowerToolTip.openBtnLabel = '';
      this.estimatedBuyingPowerToolTip.cancel = this.globalContent.text.closeTooltip;
      this.estimatedBuyingPowerToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.estimatedBuyingPowerToolTip.title = '';
      this.estimatedBuyingPowerToolTip.content = '';
    }
    if (changes.account) {
      this.cashToggle = false;
      if (this.mode == 'BuyingPowerSubBalances') {
        this.getBalanceReq();
      }
    }
  }

  toggleCash() {
    this.cashToggle = !this.cashToggle;
  }

  openModal() {
    this.modalservice.open('buyingPower' + this.uniqueId, '');
  }

  closeModal() {
    this.modalservice.close('buyingPower' + this.uniqueId, '');
  }

  returnValue(val) {
    if (this.loading) {
      // nothing to return as Spinner will show
    } else if (val) {
      return this.currencyFormatterPipe.transform(val, 'CAD', 'format-default');
    } else {
      return this.content.text.buyingPowerCannotDisplay;
    }
  }

  bracketVal(val) {
    return '(' + val + ')';
  }

  select(i: number) {
    if ((this.mode === 'Trade') || (this.mode === 'ExchangeCurrency')) {
      // if account balances not available in the init response, Get account Balances
      if (!this.showBalance) {
        const request = { AccountNumber: this.account.AccountNumber };
        this.account.AccountBalances = [{ "AccountType": "CSH", "Currency": "CAD" }, { "AccountType": "CSH", "Currency": "USD" }]
        this.getRealTimeBalance(request).subscribe(
          (data: any) => {
            if (data) {
              this.account.AccountBalances = data.AccountBalances;
            }
          },
          () => {
          });
      }
    }
    this.selectAccount.emit(i);
  }

  blockClasses() {
    let classes = '';
    if (this.selectable) {
      classes += 'hover-block ';
    }
    if (!this.displayHeader) {
      classes += 'no-top ';
    }
    if (this.mode == "BuyingPowerSubBalances") {
      classes += 'simple-block ';
    }
    if(this.mode == 'ExchangeCurrency' && this.account.disabled) {
      classes += ' disabledFX ';
    }
    if(this.account.disabled) {
      classes += ' hover-none no-top ';
    }
    if(!this.account.disabled) {
      classes += ' hover-block ';
    }
    return classes;
  }

  isLastSubAccount(i) {
    if (this.account.AccountBalances.length == i + 1) {
      if (this.account.AccountBalances[i].AccountType == 'MRL') {
        return '';
      } else {
        return 'last-sub-account';
      }
    } else if (this.account.AccountBalances.length == i + 2) {
      if (this.account.AccountBalances[i + 1].AccountType == 'MRL') {
        return 'last-cash-sub-account';
      } else {
        return '';
      }
    }
  }

  getRealTimeBalance(data) {
    return this.httpService.post(gatewayConfig.APIServices.getAccountBalance.url,
      data, { params: { skiploading: 'true' } }
    );
  }

  getAccountBalance(data) {
    return this.httpService.post(gatewayConfig.APIServices.getAccountBalance.url, data,
      { params: { skiploading: 'true' } });
  }

  getBalanceReq() {
    this.balanceLoading = true;
    if (this.account) {
      let payload = {};
      payload = { AccountNumber: this.account.AccountNumber };
      this.getAccountBalance(payload).subscribe(
        (data: any) => {
          this.balanceLoading = false;
          if (data.AccountType) {
            this.account.isRegistered = data.AccountType == 'REG' ? true : false;
          }
          if (data && data.AccountBalances && data.AccountBalances.length) {
            this.account.AccountBalances = data.AccountBalances;
          }
        },
        (err) => {
          this.balanceLoading = false;
        });
    }
  }

  openWindow(url) {
    openWindow(url, this.appStore);
  }

  cashIsUnavailable() {
    let result = false;

    for (let i = 0; i < this.account.AccountBalances.length; i++) {
      result = result || (this.account.AccountBalances[i].Amount && (this.account.AccountBalances[i].AccountType != 'MRL' || this.displayMargin));
    }

    return result;
  }

  navigateToExLanding(account) {
    CashtransferLandingStore.crossFlow = {
      AccountNumber: account.AccountNumber,
      Currency: "CAD"
    }
    this.router.navigate(['/txn/cashTransfer/landing']);
 }

 currOrDash(order, val, num) {
  if (val == 0 || val) {
    if (isNumber(val)) {
      return this.currPipe.transform(this.localFormat(val), order.Currency, 'format-currency-sr', 'accounts');
    }
    return this.currPipe.transform(val, order.Currency, 'format-currency-sr', 'accounts');
  }
  return '-';
}

localFormat(val, num=2) {
  return val.toLocaleString(this.appStore.lang, { style: 'decimal', maximumFractionDigits: num, minimumFractionDigits: num });
}

hasPermission(perm) {
  return this.commonService.checkPermissionForRoutes(perm);
}
 
}
