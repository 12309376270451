import { BaseData } from 'src/app/shared/models/base-data';

export class ForgotTradingPasswordLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            newTradingPassword: null,
            reEnteredTradingPassword: null
        };
    }
}

export interface ForgotTradingPasswordLandingInputs {
    newTradingPassword: string;
    reEnteredTradingPassword: string;
}