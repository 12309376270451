import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { PageService } from 'src/app/shared/services/page-service';
import { cmfQuoteReq } from "../data/change-mutual-fund-plan-landing-data";
import { ChangeMutualFundPlanLandingStore } from "../data/change-mutual-fund-plan-landing-store";
declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})
export class ChangeMutualFundPlanLandingService implements PageService {

    constructor(private httpService: HttpService) { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        const req = ser.input;
        req.AccountNumber = ChangeMutualFundPlanLandingStore.params.detailsParam.AccountNumber;
        return [input[0]];
    }

    mutualFundPlanLandingForm(form, fb) {
        form = fb.group({
            selectedAccountType:'',
            amount: '',
            endDate: '0',
            suspendDate: '0',
            resumeDate: '0',
            payFrom: '-1'
        });
        return form;
    }

    getQuotes(data) {
        const req: cmfQuoteReq = { SymbolName: data.SymbolName };
        return this.httpService.post(gatewayConfig.APIServices.getMutualFundQuote.url, req, { params: { skiploading: 'true' } }
        );
    }

}