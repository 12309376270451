import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { Router } from '@angular/router';
import { QuoteReq } from '../data/symbol-quote-data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({ providedIn: 'root' })
export class SymbolQuoteService {

  constructor(private httpService: HttpService, private router: Router) {

  }

  getQuote(data) {
    return this.httpService.post(gatewayConfig.APIServices.quoteQuick.url,
      data, { params: { skiploading: 'true' } }
    );
  }

}
