import { BaseData } from 'src/app/shared/models/base-data';

export class SellGicConfirmData extends BaseData {
  constructor() {
    super();
  }
}

interface PayFrom {
  SettlementType: string,
  Currency: string
}

interface Account {  
  AccountNumber: string,
  AccountTypeDesc: string,
  AccountOwnerName?: any,
  Fullname: string,
  AccountFriendlyName?: string;
  AccountBalances: any,
  IncludeShort?: boolean,
  BuyingPowerValueType?: string
}

export interface Warning {
  Message: string;
  Code: string;
}

export interface GIC {
  Account: Account,
  Amount: string,
  IssuerDescription: string,
  Term: string,
  InterestRateOrEffectiveYield:string,
  InterestPaymentFrequency: string,
  MaturityDate: string,
  SettlementDate: string,
  PayFrom: PayFrom
}

export interface OrderConfirmInfo{
  OrderId: string,
  OrderTimestamp: string
}