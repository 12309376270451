import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { ContentService } from "src/app/core/services/content.service";
import { HttpService } from "src/app/core/services/http.service";
import { ModalService } from "../modal/services/modal.service";
import { isDefined } from "../../services/utils.service";
import { CommonService } from "../../services/common.service";
import { AppStore } from "../../models/app-store";
import { TradingPasswordFormValidator } from "./trading-password-form.validator";
import { FormGroup } from "@angular/forms";

declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');


@Component({
    selector: 'app-trading-password-form',
    templateUrl: './trading-password-form.component.html',
    styleUrls: ['./trading-password-form.component.scss']
})
export class TradingPasswordFormComponent implements OnInit {

    @Input() globalContent: any
    @Input() otvcUniqueId: string;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

    otvcPromptDataParam: any;
    mode: string;
    verifyurl: string;
    otvcPromptContent: any;
    currentModule = "tradingpassqordverify";
    subscriptions = new Subscription();
    formError: any = {};
    tradingPassword: string;
    isInputFieldError: boolean
    tradingPassError: any = {};
    unexpectedException: string;
    uniqueId: string;

    @ViewChild('tradingPasswordForm') tradingPasswordForm: FormGroup;

    constructor(
        private contentService: ContentService,
        private httpService: HttpService,
        private modalService: ModalService,
        private _commonService: CommonService,
        public appStore: AppStore,
    ) { }

    ngOnInit(): void {
    this.uniqueId = this.appStore.uniqueId;
    }


    forgotPassword() {
        //Fetch content for prompth model and pass it to model
        this.subscriptions.add(this.contentService.fetchContent('otvcpromptmodel').subscribe((data3) => {
            this.otvcPromptContent = data3;
        }));

        this.getOtvcRequest().subscribe(
            (data2: any) => {
                if (data2) {
                    this.mode = 'forgotTradingPassword';
                    this.verifyurl = gatewayConfig.APIServices.userPrefOTVCVerify.url;
                    this.otvcPromptDataParam = data2;
                    this.modalService.open('otvcprompt' + this.otvcUniqueId, 'otvcPromptClose' + this.otvcUniqueId);
                    this.currentModule = 'prompt';
                    // this.hidePage = true;
                }
            }
        );
    }

    getOtvcRequest() {
        return this.httpService.post(gatewayConfig.APIServices.getOtvcRequest.url, {});
    }

    closeModal() {
        if (this.currentModule === 'prompt') {
            this.currentModule = 'cashtransferverify';
            this.modalService.close('otvcprompt' + this.otvcUniqueId);
        }
    }

    resetTradePassword = (): void => {
        this.tradingPassword = '';
      }

    verifyTradingPwd() {
        TradingPasswordFormValidator.setValidators(this.tradingPasswordForm);
        TradingPasswordFormValidator.validate(this.tradingPasswordForm);

        if (this.tradingPasswordForm.valid) {
            this.httpService
                .post(gatewayConfig.APIServices.verifyTradingPassword.url, { TradingPassword: this.tradingPassword })
                .subscribe((res) => {
                    if (res) {
                        if (!this.appStore.isApp()) {
                            this._commonService.openWindowWithPost(
                                res.Url,
                                res.SessionToken,
                                '_blank'
                            );
                        }
                    }
                    this.closeMTPModal()
                },
                    (err) => {
                        this.handleError(err);
                    });
        } else {
            this.formError = TradingPasswordFormValidator.resolveValidationError(this.tradingPasswordForm, this.globalContent.error);
            this.resetTradePassword();
            let htmlElement = document.getElementsByClassName("mlptpinputfieldm")[0];
                (htmlElement as HTMLElement).focus();
        }

    }

    input() {
        this.formError.tradingPassword = undefined
    }

    handleError(err) {
        let displayCode = ""
        let errorMessage = ""
        let errors = err.error.Exception;
        if (Array.isArray(errors)) {
            errors.filter(e => e.Category == 'TradingPassword')[0]?.Errors.forEach(element => {
                errorMessage = errorMessage + element.ErrorMessage
                displayCode = element.ErrorCode
            });
            this.formError.tradingPassword = {
                message: errorMessage,
                displayCode: displayCode
            }
        } else {
            if (isDefined(err.error.Exception.ErrorCode) &&
                (err.error.Exception.ErrorCode === 'UIBUSSC0001') || (err.error.Exception.ErrorCode === 'UIUSRSC0034')) {
                this.unexpectedException = err.error.Exception.ErrorMessage
                // throw err
            } else {
                this.unexpectedException = undefined
                this.formError.tradingPassword = {
                    message: errors.ErrorMessage,
                    displayCode: errors.ErrorCode
                }
            }
        }

        setTimeout(() => { 
            let htmlElement = document.getElementsByClassName("mlptpinputfieldm")[0];
                        (htmlElement as HTMLElement).focus();
        }, 100)
    }

    closeMTPModal() {
        this.formError.tradingPassword = undefined
        this.tradingPassword = '';
        this.close.emit(true)
    }

}