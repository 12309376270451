import { BaseData } from '../../../../../../shared/models/base-data';

export class EnableMarginAndOptionsTradingLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountNumber: '',
            accountCustomization: '',
            optionsTradingLevel: '',
            investingYearsExp: '',
            isBorrowedFund: '',
            isRevolvingLoan: '',
            totalLoanAmount: '',
            monthlyPayment: '',
            averageInterestRate: '',
            emailAddress: '',
            employmentStatus: '',
            businessType: '',
            occupation: '',
            detailedOccupation: '',
            empName: '',
            empAddress: '',
            empCity: '',
            empProvince: '',
            unempIncome: '',
            unempIncomeSource: '',
            liquidAssets: '',
            fixedAssets: '',
            estimatedLiabilities: '',
            estimatedNetWorth: '',
            annualIncome: '',
            workingRemotely: '',
            empCountry: '',
            signature: ''
        };
    }
}

export interface EnableMarginAndOptionsTradingAccountInputs {
    accountNumber: string;
    accountCustomization: string;
    optionsTradingLevel: string;
    investingYearsExp: string;
    isBorrowedFund: string;
    isRevolvingLoan: string;
    totalLoanAmount: string;
    monthlyPayment: string;
    averageInterestRate: string;
    emailAddress: string;
}

export interface EnableMarginAndOptionsTradingEmploymentInputs {
    employmentStatus: string;
    businessType: string;
    occupation: string;
    detailedOccupation: string;
    empName: string;
    empAddress: string;
    empCity: string;
    empProvince: string;
    workingRemotely: string;
    unempIncome: string;
    unempIncomeSource: string;
    empCountry: string;
}

export interface EnableMarginAndOptionsTradingFinancesInputs {
    liquidAssets: string;
    fixedAssets: string;
    estimatedLiabilities: string;
    estimatedNetWorth: string;
    annualIncome: string;
}

export interface EnableMarginAndOptionsTradingReviewInputs {
    signature: string
}

export interface EnableMarginOptionsTradingWebform {
    WebFormName: string,
    Stage: string,
    Parameters?: {
        param1?: string, //AccountNumber
        param2?: string, //AccountFeature, MARGINTRADING/MARGINOPTTIONSTRADING
        param3?: string, //OPTTIONSTRADING
        param4?: string, //Tire 1
        param5?: string, // Tire2
        param6?: string, // Tire3
        param7?: string, //Tire4
        param8?: string, //Investment Exp. yrs
        param9?: string, //Investment Exp. with options yrs
        param10?: string, //Borrow Fund to invest YES/NO
        param11?: string, // Is it Revolvin loan  YES/NO
        param12?: string, //Total loan amt
        param13?: string, //Monthly Loan payment
        param14?: string, //Avergage Loan interest rate
        param15?: string, //EmailAddress
        param16?: string, //EmploymentStatus
        param17?: string,//Employment Status Short Form
        param18?: string, //BusinessType
        param19?: string, //Occupation
        param20?: string, //DetailedOccupation
        param21?: string, //EmployerName
        param22?: string, //Address
        param23?: string, //City
        param24?: string, //Province
        param26?: string, //Country
        param27?: string, //countryCode(1=Canada, for other 2)
        param28?: string, //Working Remotely Y/N
        param29?: string, //EstimatedLiquidAsset
        param30?: string, //EstimatedFixedAsset
        param31?: string //EstimatedLiabilities
        param32?: string //EstimatedNetWorth
        param33?: string //Annual Income
        param34?: string //Source of income
        param35?: string //Occupation code
        param36?: string //Signature
    }
}

export enum ParamsMapping {
    Param1 = "Param1",
    Param2 = "Param2",
    Param3 = "Param3",
    Param4 = "Param4",
    Param5 = "Param5",
    Param6 = "Param6",
    Param7 = "Param7",
    Param8 = "Param8",
    Param9 = "Param9",
    Param10 = "Param10",
    Param11 = "Param11",
    Param12 = "Param12",
    Param13 = "Param13",
    Param14 = "Param14",
    Param15 = "Param15",
    Param16 = "Param16",
    Param17 = "Param17",
    Param18 = "Param18",
    Param19 = "Param19",
    Param20 = "Param20",
    Param21 = "Param21",
    Param22 = "Param22",
    Param23 = "Param23",
    Param24 = "Param24",
    Param26 = "Param26",
    Param27 = "Param27",
    Param28 = "Param28",
    Param29 = "Param29",
    Param30 = "Param30",
    Param31 = "Param31",
    Param32 = "Param32",
    Param33 = "Param33",
    Param34 = "Param34",
    Param35 = "Param35",
    Param36 = "Param36"
}

export const CONSTANTS = {
    ACCOUNT: 'account',
    EMPLOYMENT: 'employment',
    FINANCES: 'finances',
    REVIEW: 'review',
    COMPLETED: 'completed',
    PARTIALY_COMPLETED: 'partially_completed',
    MARGIN: 'MARGINTRADING',
    OPTIONS_TRADING: 'OPTIONSTRADING',
    MARGIN_OPTIONS_TRADING: 'MARGINOPTIONSTRADING',
    VERIFY: 'VERIFY',
    SUBMIT: 'SUBMIT',
    TIER1: 'TIER1',
    TIER2: 'TIER2',
    TIER3: 'TIER3',
    TIER4: 'TIER4',
    YES: 'YES',
    NO: 'NO',
    EMPFT: 'EMPFT',
    EMPPT: 'EMPPT',
    SLFEMP: 'SLFEMP',
    EMP: 'EMP',
    UNEMP: 'UNEMP',
    STDNT: 'STDNT',
    RET: 'RET',
    HM: 'HM',
    UNEMP_INCOME_SOURCE_OTHER_EN: 'Other',
    UNEMP_INCOME_SOURCE_OTHER_FR: 'Autre',
    CANADA: 'CANADA',
    COUNTRY_CA: "CAN",
    COUNTRY_US_EN: "U.S.A.",
    COUNTRY_US_FR: "ÉTATS-UNIS D'AMÉRIQUE"
}
