import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { ChangeSignOnPassReq } from '../data/change-signon-pass-data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class ChangeSignonPassService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ret = [input[0]];
    return ret;
  }

  changeSignonPass(data) {
    const req: ChangeSignOnPassReq = new ChangeSignOnPassReq();
    req.NewPassword = data.newPass;
    req.ConfirmNewPassword = data.confirmPass;
    return this.httpService.post(gatewayConfig.APIServices.forgotPasswordSave.url,
      req
    );
  }
}
