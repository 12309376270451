import { BaseData } from 'src/app/shared/models/base-data';
import {AccountInfo} from '../../../../fixed-income/data/fixed-income-data';

export class BuyStructNotesConfirmData extends BaseData {
  constructor() {
    super();
  }
}

export interface OrderReviewInfo {
  Account: AccountInfo;
  'Activity': {
    'OrderType': 'BUY',
    'AmountType': 'DOLLAR',
    'AmountDollars': string
  };
  'PayFrom': {
    'SettlementType': 'bnkuf',
    'Currency': 'CAD',
    'AccountType': 'Investment'
  };
}
