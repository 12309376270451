import { Injectable } from '@angular/core';
import { ExchangeCurrencyLandingData } from './exchange-currency-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ExchangeCurrencyLandingStore extends PageStore<ExchangeCurrencyLandingData> {

    constructor() {
        super(new ExchangeCurrencyLandingData());
    }

}
