import { BaseData } from 'src/app/shared/models/base-data';

export class OnlineRegisterMailingOptionsData extends BaseData {
    constructor() {
        super();
        this.input = { 
          accountStatements: false,
          tradeConfirmation: false,
          taxDocuments: false
        };
    }
}

export interface MailingOptionsInputs {
  accountStatements: boolean;
  tradeConfirmation: boolean;
  taxDocuments: boolean;
}