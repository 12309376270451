import { Injectable } from '@angular/core';
import { RelateBrokerageAccountsLandingData } from './relate-brokerage-accounts-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class RelateBrokerageAccountsLandingStore extends PageStore<RelateBrokerageAccountsLandingData> {

    constructor() {
        super(new RelateBrokerageAccountsLandingData());
    }

}
