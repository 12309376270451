import { BaseData } from '../../../../../../../../src/app/shared/models/base-data';

export class TransferFormsConfirmData extends BaseData {

    constructor() {
        super();
    }
}








