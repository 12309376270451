import { Injectable } from '@angular/core';
import { ExchangeAgreementsLandingData } from './exchange-agreements-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ExchangeAgreementsLandingStore extends PageStore<
  ExchangeAgreementsLandingData
> {
  constructor() {
    super(new ExchangeAgreementsLandingData());
  }
}
