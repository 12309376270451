import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { FormValidatorFactory } from '../../services/cibc.formvalidator';
import { isDefined } from 'src/app/shared/services/utils.service';

export class TradingPasswordFormValidator {

  static createForm = (form: FormGroup, fb: FormBuilder): FormGroup => {
    form = fb.group({
      tradingPassword: ['']
    });
    return form;
  }

  static setValidators = (form: FormGroup): void => {
    if (form.controls['tradingPassword']) {
      form.controls['tradingPassword'].setValidators(
        [
          // FormValidatorFactory.createBoundedLengthValidator('MSGFIBB0031', 1, 12),
          // FormValidatorFactory.createAlphaNumValidator('MSGFIBB0031'),
          FormValidatorFactory.createNotBlankValidator('tradingPasswordRequired')
        ]
      );
    }
  }

  static validate = (form: FormGroup): void => {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        TradingPasswordFormValidator.validate(control);
      }
    });
  }

  static resolveValidationError = (form: FormGroup, errorContent: any): any => {
    let messges = [];
    if (form.controls['tradingPassword']) {
      messges.push(
        {
          controlName: 'tradingPassword',
          code: form.controls['tradingPassword'].errors
        });
    }
    return TradingPasswordFormValidator.setErrorMessage(messges, errorContent);
  }

  static setErrorMessage = (results, errorContent): any => {
    let error = {};
    results.forEach(result => {
      if (result.code !== null) {
        if (isDefined(result.code.errorCode)) {
          error[result.controlName] = errorContent[result.code.errorCode];
        }
        console.log("Trading Password Validator - Error message is: " + JSON.stringify(result));
      }
    });
    return error;
  }
}
