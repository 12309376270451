import { Injectable } from '@angular/core';
import { ChangeMailingOptionsConfirmData } from './change-mailing-options-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeMailingOptionsConfirmStore extends PageStore<ChangeMailingOptionsConfirmData> {

    constructor() {
        super(new ChangeMailingOptionsConfirmData());
    }

}
