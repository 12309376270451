import { Injectable } from '@angular/core';
import { EdocumentsData } from './edocuments-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class EdocumentsStore extends PageStore<EdocumentsData> {

    constructor() {
        super(new EdocumentsData());
    }

}
