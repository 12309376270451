import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnChanges } from '@angular/core';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { AppStore } from '../../models/app-store';
import { Lob, Lang } from 'src/app/shared/models/lob.enum';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';

@Component({
  selector: 'app-account-selector-dropdown-trade-drawer',
  templateUrl: './account-selector-dropdown-trade-drawer.component.html',
  styleUrls: ['./account-selector-dropdown-trade-drawer.component.scss']
})
export class AccountSelectorDropdownTradeDrawerComponent implements OnInit, OnChanges {

  open = false;
  accountList: any[];
  accountDisplayName: string;
  subAccountDisplayName: string;
  lob: string;
  selectedAccountNumber: any;
  displayAccountNumber: string;
  account: any;
  subAccount: any;
  pageContent: any;
  singleAccountList: any[];
  highlightedElement: any;
  accountDisplayCurrency: any;
  firstFocusableEl: any;
  lastFocusableEl: any;
  triggerValue = true;
  selected: any;
  @Input() paramSelectedAccountNumber: any;
  @Input() paramPageContent: any;
  @Input() paramAccountList: any;
  @Input() public error: string;
  @Input() hideCombinedText: boolean = false;
  @Input() regularDropdown = false;
  @Input() hideSubAccounts = false;
  @Input() onlySubAccounts = false;
  @Input() dropdownFullWidth = false;
  @Input() chooseOne = false;
  @Input() mode: string = '';
  @Input() styling: string = '';
  @Input() id: string = '';
  @Input() describedbyAttr = ''

  @Output() selectedAccount: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDropDownState(false);
  }

  constructor(
    public globalcontent: GlobalContentStore,
    private appStore: AppStore,
    private subscriptionService: ObservableSubscriptionService
  ) {
  }

  ngOnChanges() {
    this.accountList = this.paramAccountList;
    this.singleAccountList = [];
    this.createSingleList();
    if (this.paramSelectedAccountNumber && this.paramSelectedAccountNumber.AccountNumber !== '') {
      this.selectedAccountNumber = this.fetchAccountFromSingleList(this.paramSelectedAccountNumber);
      this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
    } else {
      this.selectedAccountNumber = this.singleAccountList[0];
      this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
    }
    //this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
    document.addEventListener('visibilitychange', (e: Event) => { this.setDropDownState(false); });
  }

  ngOnInit() {
    this.lob = this.appStore.lob;
    this.pageContent = this.paramPageContent;
    this.accountList = this.paramAccountList;
    this.singleAccountList = [];
    this.createSingleList();

    if (!this.chooseOne) {
      if (this.paramSelectedAccountNumber && this.paramSelectedAccountNumber.AccountNumber !== '') {
        console.log("PARAM Seledcted ACC num" , this.paramSelectedAccountNumber);
        this.selectedAccountNumber = this.fetchAccountFromSingleList(this.paramSelectedAccountNumber);
        console.log("this.selectedAccountNumber in IF",this.selectedAccountNumber);
      } else {
        this.selectedAccountNumber = this.singleAccountList[0];
        console.log("this.selectedAccountNumber in ELSE",this.selectedAccountNumber);
      }
      this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
    } else {
      this.accountDisplayName = this.globalcontent.text.chooseOne;
      console.log("this.acc DISPLAY NAME",this.accountDisplayName);
    }
    document.addEventListener('visibilitychange', (e: Event) => { this.setDropDownState(false); });
  }
  setFocus(targetElementId) {
    if (targetElementId) {
      const targetElement = document.getElementById(targetElementId);
      window.requestAnimationFrame(() => {
        if (targetElement) {
          targetElement.focus();
        }
      });
    }
  }

  setDropDownState(state) {
    if (state) {
      setTimeout(() => {
        let id = 'ASDD' + this.selectedAccountNumber.Id + this.mode + this.id;
        let targetElement = document.getElementById(id);
        let nextId = '';
        if (targetElement && targetElement.classList.contains('disabled')) {
          if (targetElement.nextElementSibling) {
            nextId = targetElement.nextElementSibling.id;
            if (nextId) {
              targetElement = document.getElementById(nextId);
              id = nextId;
            }
          }
        }
        if (targetElement) {
          this.setFocus(id);
          this.highlightedElement = document.getElementById(id);
        }
      }, 200);
    } else {
      if (this.open) {
        // this.setFocus('ddButton' + this.mode);
        const detectMobileIOS = this.getMobileOperatingSystem();
        if (detectMobileIOS !== 'iOS') {
          // console.log('test not ios')
          this.setFocus('ddButton' + this.mode + this.id)
          // this.setVoiceOverFocus('ddButton' + this.mode);
        }
      }
    }
    this.open = state;
  }
  onMouseEnter(e,) {
    this.highlightedElement = e.currentTarget;
    this.setFocus(e.currentTarget.id);
  }
  handleBlur(e) {
    if (e.relatedTarget) {
      return !e.relatedTarget || (e.relatedTarget && e.relatedTarget.id.indexOf('ASDD') !== 0 && e.relatedTarget.id.indexOf('ddButton') !== 0);
    } else {
      return !document.activeElement || (document.activeElement && document.activeElement.id.indexOf('ASDD') !== 0 && document.activeElement.id.indexOf('ddButton') !== 0);
    }
  }
  onBlur(e) {
    if (this.handleBlur(e)) {
      this.setDropDownState(false);
      e.preventDefault();
    }
  }
  onKeyDown(e, pAccount) {
    if (e.keyCode === 9) {
      this.setDropDownState(false);
      e.preventDefault();
    }
  }
  onKeyUp(e, pAccount) {
    if (e.keyCode === 38) {
      if (this.highlightedElement !== this.firstFocusableEl && this.highlightedElement.previousElementSibling) {
        if (this.highlightedElement.previousElementSibling.classList.contains('disabled')) {
          let prevElement = this.highlightedElement.previousElementSibling.previousElementSibling;
          if (prevElement) {
            prevElement.focus();
            this.highlightedElement = prevElement;
          }
        } else {
          this.highlightedElement.previousElementSibling.focus();
          this.highlightedElement = this.highlightedElement.previousElementSibling;
        }
      } else {
        this.highlightedElement.focus();
      }

    } else if (e.keyCode === 40) {
      if (this.highlightedElement !== this.lastFocusableEl && this.highlightedElement.nextElementSibling) {
        if (this.highlightedElement.nextElementSibling.classList.contains('disabled')) {
          let nextElement = this.highlightedElement.nextElementSibling.nextElementSibling;
          if (nextElement) {
            nextElement.focus();
            this.highlightedElement = nextElement;
          }
        } else {
          this.highlightedElement.nextElementSibling.focus();
          this.highlightedElement = this.highlightedElement.nextElementSibling;
        }

      } else {
        this.highlightedElement.focus();
      }
    } else if (e.keyCode === 13) {
      if ((this.mode == 'quicktrade' || this.mode == 'quicktradeOptions')  && !pAccount.IsSubAccount) {
        return false;
      }
      let arrId = this.highlightedElement.id.split('ASDD');
      const accountNum = Number(arrId[1].split(this.mode)[0]);
      this.selectedAccountNumber = this.fetchAccountFromSingleListByID(accountNum);
      this.selectedAccount.emit(this.selectedAccountNumber);
      this.generateDisplayName(pAccount.Currency, this.selectedAccountNumber);
      this.setDropDownState(false);
    } else if (e.keyCode === 27) {
      this.setDropDownState(false);
      e.preventDefault();
    }
  }
  accountSelected(pAccount) {
    if ((this.mode == 'quicktrade' || this.mode == 'quicktradeOptions') && !pAccount.IsSubAccount) {
      return false;
    }
    this.setDropDownState(false);
    this.selectedAccountNumber = pAccount;
    this.generateDisplayName(pAccount.Currency, this.selectedAccountNumber);
    this.selectedAccount.emit(this.selectedAccountNumber);
  }
  createSingleList() {
    let id = 0;
    this.accountList.forEach(val => {
      if (!this.onlySubAccounts) {
        this.singleAccountList.push({
          Id: id,
          AccountNumber: val.AccountNumber ? val.AccountNumber : '',
          AccountTypeDesc: val.AccountTypeDesc ? val.AccountTypeDesc : '',
          AccountFriendlyName: val.AccountFriendlyName ? val.AccountFriendlyName : '',
          AccountName: val.AccountName ? val.AccountName : '',
          HypothecationInd: val.HypothecationInd ? ' (' + this.pageContent.text.hypo + ') ' : ' ',
          IsSubAccount: false,
          spousal: val.Spousal ? val.Spousal : '',
          registered: val.Registered ? val.Registered : '',
          Fullname: val.Fullname ? val.Fullname : '',
          accountGroup: this.getAccountGroup(val)
        });
        id++;
      }
      if (val.Subaccounts && !this.hideSubAccounts) {
        val.Subaccounts.forEach((obj, i) => {
          if (Lob.WG === this.lob) {
            this.singleAccountList.push({
              Id: id,
              AccountFriendlyName: val.AccountFriendlyName ? val.AccountFriendlyName : this.getAccountTypeDesc(val),
              AccountNumber: val.AccountNumber ? val.AccountNumber : '',
              AccountType: obj.AccountType ? obj.AccountType : '',
              Currency: obj.Currency ? obj.Currency : '',
              SubAccountNumber: obj.SubAccountNumber ? obj.SubAccountNumber : '',
              SubAccountName: obj.SubAccountName ? obj.SubAccountName : '',
              IsSubAccount: true
            });
          } else if (Lob.CPIC === this.lob) {
            this.singleAccountList.push({
              Id: id,
              AccountFriendlyName: val.AccountFriendlyName ? val.AccountFriendlyName : '',
              AccountNumber: val.AccountNumber ? val.AccountNumber : '',
              Currency: obj.Currency ? obj.Currency : '',
              SubAccountNumber: obj.SubAccountNumber ? obj.SubAccountNumber : '',
              SubAccountFriendlyName: obj.AccountFriendlyName ? obj.AccountFriendlyName : '',
              IsSubAccount: true
            });
          } else {
            if(!(obj.AccountType == 'MRS' && this.mode == 'quicktradeOptions')) {
            this.singleAccountList.push({
              Id: id,
              AccountNumber: val.AccountNumber ? val.AccountNumber : '',
              AccountType: obj.AccountType ? obj.AccountType : '',
              Currency: obj.Currency ? obj.Currency : '',
              IsSubAccount: true,
              spousal: val.Spousal ? val.Spousal : '',
              registered: val.Registered ? val.Registered : '',
              accountDetail: this.getAccountDetail(val, obj),
              srText: this.getSRText(val, i)
            });
          }
          }
          id++;
        });
      }
    });
  }
  fetchAccountFromSingleListByID(id) {
    let account;
    this.singleAccountList.forEach(val => {
      if (!account && val.Id === id) {
        account = val;
      }
    });
    return account;
  }
  fetchAccountFromSingleList(pAccount) {
    let account;
    this.singleAccountList.forEach(val => {
      if (Lob.CPIC !== this.lob) {
        if (!account) {
          if (pAccount.Currency) {
            if (Lob.WG === this.lob) {
              if (pAccount.AccountNumber === val.AccountNumber && pAccount.Currency === val.Currency && pAccount.AccountType === val.AccountType && pAccount.SubAccountNumber === val.SubAccountNumber) {
                account = val;
              }
            } else {
              if (pAccount.AccountNumber === val.AccountNumber && pAccount.Currency === val.Currency && pAccount.AccountType === val.AccountType) {
                account = val;
              }
            }
          } else {
            if (pAccount.AccountNumber === val.AccountNumber) {
              account = val;
            }
          }
        }
      } else {
        if (!account) {
          if (pAccount.Currency) {
            if (pAccount.AccountNumber === val.AccountNumber && pAccount.Currency === val.Currency && pAccount.SubAccountNumber === val.SubAccountNumber) {
              account = val;
            }
          } else {
            if (pAccount.AccountNumber === val.AccountNumber) {
              account = val;
            }
          }
        }
      }
    });
    return account;
  }
  generateDisplayName(isSubAccount, account) {
    if (this.mode == 'quicktrade' || this.mode == 'quicktradeOptions') {

      this.accountDisplayName = account.accountDetail;
    }
    else if (Lob.IE === this.lob || Lob.IIS === this.lob || Lob.CFPI === this.lob) {
      if (isSubAccount) {
        this.accountDisplayName = this.getAccountType(account) + ' (' + account.AccountNumber + ') - ';
        this.accountDisplayCurrency = account.Currency;
      } else if (!this.hideCombinedText) {
        this.accountDisplayName = ((account.AccountFriendlyName) ? account.AccountFriendlyName : this.getAccountTypeDesc(account)) + account.HypothecationInd + '(' + account.AccountNumber + ') - ' + this.pageContent.text.combined;
      } else {
        this.accountDisplayName = ((account.AccountFriendlyName) ? account.AccountFriendlyName : this.getAccountTypeDesc(account)) + account.HypothecationInd + '(' + account.AccountNumber + ')';
      }
    } else if (Lob.WG === this.lob) {
      let accountName;
      if (account.AccountFriendlyName) {
        accountName = account.AccountFriendlyName;
      } else if (account.AccountType) {
        accountName = this.getAccountType(account);
      } else {
        accountName = this.getAccountTypeDesc(account);
      }
      if (!this.hideCombinedText) {
        this.accountDisplayName = accountName + ' (' + account.AccountNumber + ')' + (!account.SubAccountNumber ? ' - ' + this.pageContent.text.combined : '');
      }
      else {
        this.accountDisplayName = accountName + ' (' + account.AccountNumber + ')';
      }
      this.subAccountDisplayName = '';
      if (isSubAccount) {
        this.subAccountDisplayName = '(' + account.SubAccountNumber + ') ' + this.getAccountType(account) + ' - ';
        this.accountDisplayCurrency = account.Currency;
      }
    } else {
      this.accountDisplayName = account.AccountFriendlyName; // + ' - ' + account.AccountNumber;
      this.subAccountDisplayName = '';
      if (isSubAccount) {
        this.subAccountDisplayName = account.SubAccountFriendlyName;
      }
    }
  }

  getAccountTypeDesc(account) {
    return account.AccountTypeDesc ? account.AccountTypeDesc : '';
  }
  getAccountType(account) {
    return this.globalcontent.list.accountType[account.AccountType] ? this.globalcontent.list.accountType[account.AccountType] : '';
  }
  getAccountGroup(account) {
    let accountGroup = '';
    let accountType;

    //account type
    if (account.AccountFriendlyName) {
      accountType = account.AccountFriendlyName;
    } else if (account.AccountType) {
      accountType = this.getAccountType(account);
    } else if (account.AccountTypeDesc) {
      accountType = this.getAccountTypeDesc(account);
    }

    if (accountType == 'Margin' || accountType == 'Cash' || accountType == 'Sur marge' || accountType == 'Au comptant') {
      accountType = this.appStore.lang == 'en' ? 'Investment' : 'Placement';
    }
    accountGroup += accountType ? accountType + ' ' : '';

    //account number
    accountGroup += account.AccountNumber + ' ';

    // account holder name
    if (account.Fullname) {
      accountGroup += account.Fullname ? account.Fullname : '';
    } else {
      const fname = (account.AccountOwnerName && account.AccountOwnerName.FirstName) ?
        account.AccountOwnerName.FirstName : '';
      const lname = (account.AccountOwnerName && account.AccountOwnerName.LastName) ?
        account.AccountOwnerName.LastName : '';
      const fullName = fname ? fname + ' ' + lname : lname;
      if (fullName) {
        accountGroup += fullName;
      }
    }


    return accountGroup;
  }

  getAccountDetail(account, subAccount?) {
    let accountDetail = '';
    //account type
    let accountType = this.getAccountType(subAccount ? subAccount : account);
    // if account has both CSH and MRL sub accounts show  Investment
    if (subAccount.Currency && account.hasBothMarginCash[subAccount.Currency]) {
      accountType = this.appStore.lang == 'en' ? 'Investment' : 'Placement';
    }
    //position type
    if ((accountType == 'Investment' || accountType == 'Placement') && (subAccount.AccountType == 'MRS')) {
      accountType = this.appStore.lang == 'en' ? accountType + ' ' + 'Short' : accountType + ' ' + 'Découvert';
    }
    // if(friendlyName) {
    //     accountType = account.AccountFriendlyName ? account.AccountFriendlyName : accountType;
    // }
    accountDetail += accountType + ' ';

    //account number
    accountDetail += account.AccountNumber + ' ';

    //currency
    accountDetail += ' (' + subAccount.Currency + ')';

    return accountDetail;
  }

  getSRText(account, index) {
    let srText = '';
    if (index == 0) {
      srText = this.globalcontent.text.group ? this.globalcontent.text.group : '';
      srText += ' ' + this.getAccountGroup(account);
    }
    return srText;
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }
}