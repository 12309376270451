<button [id]="id + '_' + index" (click)="next()" class="transfer-card-btn">
  <div class="transfer-card-container">
    <div class="transfer-card-container-text" [ngClass]="{'third-party-banks-container': cardContent && cardContent.image === 'third-party-banks'}">
      <ng-container *ngIf="cardContent">
        <span *ngIf="titleIcon" class="transfer-card-title-icon" [ngClass]="'icon-' + titleIcon" attr.aria-hidden="true"></span>
        <img *ngIf="cardContent.image" [ngClass]="{'third-party-banks-icon': cardContent.image === 'third-party-banks'}" class="transfer-card-image-bank-icon" [src]="'./assets/images/bankIcons/bank-icon-' + cardContent.image + '.svg'" [alt]="cardContent.imageContent ? cardContent.imageContent : ''" [attr.aria-hidden]="cardContent.imageContent && cardContent.imageContent != '' ? false : true">
        <div class="transfer-card-title-body" [ngClass]="titleIcon ? ('title-icon-' + titleIcon) : ''">
          <div class="transfer-card-title" [ngClass]="cardContent.content? 'paddingBottom4': ''">
            {{cardContent.title}}
          </div>
          <div *ngIf="cardContent.content" class="transfer-card-content">
            {{cardContent.content}}
          </div>
          <div *ngIf="cardContent.content2" class="transfer-card-content">
            {{cardContent.content2}}
          </div>
          <span class="sr-only">
            {{cardContent.hdnTxt}}
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="account">
        <div class="transfer-card-title" [ngClass]="account.balance? 'paddingBottom4': ''">
          <span tabindex="-1">
            <ng-container *ngIf="account.AccountType == 'BNK' && account.BankAccountInfo; else displayAccType">
              <img class="transfer-card-bank-icon" [src]="'./assets/images/bankIcons/bank-icon-' + account.BankAccountInfo.IconName + '.svg'" alt="" attr.aria-hidden="true">
              {{account.BankAccountInfo.InstitutionName}}
            </ng-container>
            <ng-template #displayAccType>
              {{globalContent.accountNickname(account)}}
            </ng-template>
            <span [innerHTML]="account.AccountNumber | srOnly:true"></span>
            <span *ngIf="account.Currency"
              [innerHtml]='" ("+globalContent.getCurrencyContent(account.Currency)+")"'></span>
          </span>
        </div>
        <div *ngIf="account.balance" class="transfer-card-content">
          <span>{{account.balance}}</span>
        </div>
      </ng-container>
    </div>
    <span aria-hidden="true" class="icon-Chevron-Right"></span>
  </div>
</button>