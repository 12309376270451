import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { AccountSettingsData } from './account-settings-page-data';

@Injectable({ providedIn: 'root' })
export class AccountSettingsStore extends PageStore<AccountSettingsData>  {

    constructor() {
        super(new AccountSettingsData());
    }

}
