import { Injectable } from '@angular/core';
import { TransferFormsVerifyData } from './transfer-forms-verify.data';
import { PageStore } from '../../../../../../../../src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TransferFormsVerifyStore extends PageStore<TransferFormsVerifyData> {

    constructor() {
        super(new TransferFormsVerifyData());
    }

}
