import { RouteGroup } from "../shared/models/route-config";

export const QuotesConfig: RouteGroup = {
  "/txn/quotesWatchList": {
    name: "QuotesWatchList",
    service: "QuotesWatchListService",
    store: "QuotesWatchListLandingStore",
    resolveContent: "quoteswatchlist",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "watchList",
        restUrl: "getWatchlistNames",
        optional: true,
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "quotes-research,quotes-watch-list",
      name: "watch-lists",
      nameGA: "Watch Lists",
    },
    brazeID: "viewed_app_watch_list"
  },
  "/txn/quotesWatchList/detail": {
    name: "QuoteDetails",
    service: "QuotesDetailService",
    store: "QuotesDetailStore",
    resolveContent: "quotesdetail",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "quotedetail",
        restUrl: "quoteDetail",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "quotes-research,quotes-watch-list,market-data",
      name: "details",
      nameGA: "Watch Lists - Details"
    },
  },
};
