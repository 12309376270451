import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { _User } from '../../models/user';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { AppStore } from '../../models/app-store';

@Component({
  selector: 'app-field-level-error',
  templateUrl: './field-level-error.component.html',
  styleUrls: ['./field-level-error.component.scss']
})
export class FieldLevelErrorComponent {

  @Input() error;
  @Input() Id;
  @Input() rightAlign = false;
  @Input() compact = false;
  @Input() alert = false;

  constructor(
    public globalContent: GlobalContentStore,
    private appStore: AppStore
  ) {

  }
}
