import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    EventEmitter,
    Output,
    Input,
    OnChanges,
} from '@angular/core';
import { _User } from '../../models/user';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { AppStore } from '../../models/app-store';
import { isDefined } from 'src/app/shared/services/utils.service';
import { TrackingService } from '../../services/tracking.service';
import { of } from 'rxjs';

declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');

@Component({
    selector: 'app-field-level-error-global-message',
    templateUrl: './field-level-error-global-message.component.html',
    styleUrls: ['./field-level-error-global-message.component.scss'],
})
export class FieldLevelErrorGlobalMessageComponent
    implements OnInit, OnChanges {
    @Input() errorList;
    @Input() identifier;

    errorContent1: string;
    errorContent2: string;
    savedErrorCount = 0;

    constructor(
        public globalContent: GlobalContentStore,
        public appStore: AppStore,
        private _trackingService: TrackingService
    ) { }

    ngOnInit() {
        if (this.appStore.lang.toUpperCase() === 'EN') {
            this.errorContent1 = 'This form could not be submitted because of ';
            this.errorContent2 =
                ' error(s). Please make the correction(s) and resubmit.';
        } else {
            this.errorContent1 =
                "Ce formulaire n'a pas pu être soumis car il y avait ";
            this.errorContent2 =
                ' erreur(s). Veuillez effectuer la ou les corrections et les soumettre à nouveau.';
        }
    }

    ngOnChanges() {
        let i = 0;
        for (const error in this.errorList) {
            if ((this.errorList[error] === undefined)) {
                // if value is undefined = no error = no need to count
            } else {
                i++;
            }
        }
        this.savedErrorCount = 0;
        setTimeout(() => {
            this.savedErrorCount = i;
        }, 1);
        if (i > 0) {
            //Omniture tracking for field level error.
            if ((window as any).TrackingEnabled) {
                of(this._trackingService.tagError(this.errorList));
            }
            setTimeout(() => {
                if (i === 1) {
                    if (this.appStore.lang.toUpperCase() === 'EN') {
                        this.errorContent2 = ' error.';
                    } else {
                        this.errorContent2 = ' erreur.';
                    }
                } else {
                    if (this.appStore.lang.toUpperCase() === 'EN') {
                        this.errorContent2 =
                            ' error(s). Please make the correction(s) and resubmit.';
                    } else {
                        this.errorContent2 =
                            ' erreur(s). Veuillez effectuer la ou les corrections et les soumettre à nouveau.';
                    }
                }
                // document.getElementById("field-level-error-global-message").focus();
                let id = '';
                if (this.identifier) {
                    id = '-' + this.identifier;
                }
                let allinput = document.getElementsByTagName('input');
                for (let j = 0; j < allinput.length; j++) {
                    let el = allinput.item(j);
                    if (
                        isDefined(el.getAttribute('aria-describedby')) &&
                        el
                            .getAttribute('aria-describedby')
                            .includes('field-level-error-global-message' + id)
                    ) {
                        const setAriaDescBy = el
                            .getAttribute('aria-describedby')
                            .replace(
                                'field-level-error-global-message' + id + ' ',
                                ''
                            );
                        if (setAriaDescBy == '') {
                            el.setAttribute('aria-describedby', null);
                        } else {
                            el.setAttribute('aria-describedby', setAriaDescBy);
                        }
                    }
                }
                let allinputSelect = document.getElementsByTagName('select');
                for (let j = 0; j < allinputSelect.length; j++) {
                    let el = allinputSelect.item(j);
                    if (
                        isDefined(el.getAttribute('aria-describedby')) &&
                        el
                            .getAttribute('aria-describedby')
                            .includes('field-level-error-global-message' + id)
                    ) {
                        const setAriaDescBy = el
                            .getAttribute('aria-describedby')
                            .replace(
                                'field-level-error-global-message' + id + ' ',
                                ''
                            );
                        if (setAriaDescBy == '') {
                            el.setAttribute('aria-describedby', null);
                        } else {
                            el.setAttribute('aria-describedby', setAriaDescBy);
                        }
                    }
                }
                if (
                    document.getElementsByClassName('has-field-error').length >
                    0
                ) {
                    let len = document.getElementsByClassName('has-field-error')
                        .length;
                    let i = 0;
                    let element: HTMLElement = document
                        .getElementsByClassName('has-field-error')
                        .item(i) as HTMLElement;
                    while (
                        i < len &&
                        (element.offsetParent == null ||
                            element.offsetParent === document.body ||
                            element.hidden)
                    ) {
                        i++;
                        if (i < len) {
                            element = document
                                .getElementsByClassName('has-field-error')
                                .item(i) as HTMLElement;
                        }
                    }
                    if (i < len) {
                        if (
                            !isDefined(element.getAttribute('aria-describedby'))
                        ) {
                            element.setAttribute(
                                'aria-describedby',
                                'field-level-error-global-message' + id + ' '
                            );
                        } else if (
                            !element
                                .getAttribute('aria-describedby')
                                .includes(
                                    'field-level-error-global-message' + id
                                )
                        ) {
                            const setAriaDescBy =
                                'field-level-error-global-message' +
                                id +
                                ' ' +
                                element.getAttribute('aria-describedby');
                            element.setAttribute(
                                'aria-describedby',
                                setAriaDescBy
                            );
                        }
                        setTimeout(() => {
                            if (
                                isDefined(element) &&
                                isDefined(
                                    element.getAttribute('aria-describedby')
                                ) &&
                                element
                                    .getAttribute('aria-describedby')
                                    .includes(
                                        'field-level-error-global-message' + id
                                    )
                            ) {
                                const setAriaDescBy = element
                                    .getAttribute('aria-describedby')
                                    .replace(
                                        'field-level-error-global-message' +
                                        id +
                                        ' ',
                                        ''
                                    );
                                if (setAriaDescBy == '') {
                                    element.setAttribute(
                                        'aria-describedby',
                                        null
                                    );
                                } else {
                                    element.setAttribute(
                                        'aria-describedby',
                                        setAriaDescBy
                                    );
                                }
                            }
                        }, 5000);
                        element.focus();
                    }
                }
            }, 100);
        }
    }
}
