import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CreateCashPlanVerifyData } from './create-cash-plan-verify-data';

@Injectable({ providedIn: 'root' })
export class CreateCashPlanVerifyStore extends PageStore<CreateCashPlanVerifyData>  {

    constructor() {
        super(new CreateCashPlanVerifyData());
    }

}
