import { HeaderModule } from './../../shared/components/header/header.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForceUpgradeComponent } from './force-upgrade.component';
import { ForceUpgradeRoutingModule } from './force-upgrade-routing.module';
import { FormsModule } from '@angular/forms';
import { FormSharedModule } from './../../shared/form-shared.module';
import { PreHeaderComponent } from './../../shared/components/pre-header/pre-header.component';
import { PreFooterComponent } from './../../shared/components/pre-footer/pre-footer.component';
import { ChatSharedModule } from '../../shared/chat-shared.module';

@NgModule({
  declarations: [
    ForceUpgradeComponent,
  ],
  imports: [
    CommonModule,
    ForceUpgradeRoutingModule,
    FormsModule
  ]
})
export class ForceUpgradeModule { }

