import { Injectable } from '@angular/core';
import { TradeMLSVerifyData } from './trade-MLS-verify-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TradeMLSVerifyStore extends PageStore<TradeMLSVerifyData> {

    constructor() {
        super(new TradeMLSVerifyData());
    }

}
