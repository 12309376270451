import { BaseData } from '../../../../../../shared/models/base-data';

export class CreateMutualFundLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountIndex: -1,
            action: '',
            symbol: "",
            symbolIndex: -1,
            amount: undefined,
            startDate: "",
            endDate: "",
            suspendDate: "",
            resumeDate: "",
            regContrType: "",
            planFreq: -1,
            dividendIndicator: "INVEST",
            settlementIndex: -1,
            selectedAccountType: ""
        };
    }
}

export interface cmfLandingInputs {
    accountIndex: number;
    action: string;
    symbol: string;
    symbolIndex: number;
    amount: string;
    planFreq: number;
    startDate: string;
    endDate: string;
    suspendDate: string;
    resumeDate: string;
    regContrType: number;
    dividendIndicator: string;
    settlementIndex: number;
    selectedAccountType: string;
}


export interface cmfSettlementAccountsReq {
    AccountNumber: number;
    Action: string;
}

export interface cmfSettlementAccountsResp {
    SettlementAccounts?: settlementAccounts[];
    RegisteredContributionTypeList?: registeredContributionTypeList[];
}

export interface settlementAccounts {
    AccountNumber: number
    AccountType?: string
    Currency?: string
    SettlementType?: string
}

export interface registeredContributionTypeList {
    RegisteredContributionTypeList: {}
}

export interface cmfHoldingsReq {
    AccountNumber: string;
    FiType: string;
}

export interface mplAccountRequest {
    AccountNumber: number;
}

export interface cmfValidateReq {
    AccountNumber?: number,
    Amount?: string,
    Action?: string,
    Frequency?: number,
    StartDate?: string,
    PayFrom?: number,
    DividendInstruction?: string,
    Symbol?: string,
    EndDate?: string,
    SuspendDate?: string,
    UnSuspendDate?: string,
    RegisteredContributionType?: registeredContributionTypeList
}

export interface registeredContributionTypeList {
    RegisteredContributionTypeList: {}
}

export interface cmfPageData {
    AccountList: cmfAccountList[],
    AsOfDate: string
}
export interface cmfAccountList {
    AccountBalances?: accountBalances[],
    AccountNumber: number,
    AccountOwnerName: string,
    AccountTypeDesc: string,
    BuyingPowerValueType: string,
    Fullname: string,
    IncludeShort: boolean
}
export interface accountBalances {
    AccountType: string,
    Amount: number,
    Currency: string
}

export interface cmfSettlementAccounts {
    AccountNumber?: number,
    AccountType?: string,
    Currency?: string,
    SettlementType?: string
}

export interface mutualFundHoldingList {
    Symbol?: mfSymbol,
    Quantity?: {
        Data: string,
        Content: string
    },
    CurrentValue?: {
        Data: string,
        Content: string
    },
    AccountCurrency?: string
}

export interface cmfQuoteList {
    MutualFundResultList: mutualFundResultList[];
}

export interface mutualFundResultList {
    LastPrice: { Data: string, Content: string },
    LastPriceDate: string,
    Symbol: mfSymbol;
}

export interface mfSymbol {
    CompanyCode: string,
    FundCategoryName: string,
    FundCode: number,
    FundCompany: string,
    FundCurrency: string,
    FundGroupName: string,
    FundName: string,
    InitialMinimum: number,
    IsiTradingEligibleInd: string,
    LoadType: string,
    RrspEligibilityInd: string,
    SegFlag: boolean,
    StandingOrderEligibleInd: number,
    SubsequentMinimum: number,
    SymbolName: string
}

export interface cmfQuoteReq {
    SymbolName: string;
}

export interface amountToolTip {
    openBtnLabel: string;
    cancel: string;
    content: string;
    btnHiddenText: string;
}

export const cmfGlobalCalendar = {
    minDateYearInput: 0,
    minDateMonthInput: 0,
    maxDateYearInput: 2,
    maxDateMonthInput: 0,
    minSetYear: 0,
    minSetMonth: 0,
    minSetDay: 1,
    maxSetYear: 2,
    maxSetMonth: 11,
    maxSetDay: 31
}

export const cmf_calendar_const = {
    customDate: "customDate",
    endCustomDate: "endCustomDate",
    suspendCustomDate: "suspendCustomDate",
    resumeCustomDate: "resumeCustomDate"
}

export const cmf_form_control_const = {
    errorValue: -1,
    nonErrorValue: 0,
    valueOne: 1,
    moduleStoreName: 'CreateMutualFundLandingStore',
    dollar: "$"
}

export enum cmf_Action {
    BUY = 'BD',
    SELL = 'SD',
};

export enum cmf_Dividend {
    INV = "R",
    CASH = "C"
}

export enum cmf_pltInput {
    Number = 'number',
    Text = 'text'
}