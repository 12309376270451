import { BaseData } from 'src/app/shared/models/base-data';

export class CashtransferConfirmData extends BaseData {
    constructor() {
        super();
    }
}

export class ToOrderStatusReq {
}
