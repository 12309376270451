import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class ElectronicAccessAgreementService implements PageService {

  private path = 'assets/content/pages/';
  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    const ret = undefined;
    return ret;
  }

  transactionToken() {
    return this.httpService.post(gatewayConfig.APIServices.transactionToken.url, {});
  }

  accept(request) {
    return this.httpService.post(gatewayConfig.APIServices.acceptTermsAndConditions.url, request);
  }

  getEaaContent(lang) {
    const url = this.path + 'electronicAccessAgreement' + '-' + lang + '.json';
    return this.httpService.getResource(url);
  }
}
