import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { FeesCommissionsData } from './fees-commissions-data';

@Injectable({ providedIn: 'root' })
export class FeesCommissionsStore extends PageStore<FeesCommissionsData> {

  constructor() {
    super(new FeesCommissionsData());
  }

}
