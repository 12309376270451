import { BaseData } from 'src/app/shared/models/base-data';

export class PerformanceReportingData extends BaseData {

}
export enum CONST {
    MIN_DATE = '20160101',
    NEW_MIN_DATE = '20160102',
    DDBUTTON = 'ddButton',
    PERFREPOSUBMIT = 'perfRepoSubmit',
    DAYS = 'days',
    NUMBER = 'number',
    TEXT = 'text',
    CUMULATIVE_LAST_REQUEST = 'cumulativeLastRequest',
    CALENDAR_LAST_REQUEST = 'calendarLastRequest'
}
export enum CONST_HTML {
    PERCENT = '%',
    HYPHEN = '-',
    PERFREPO = "PerfRepo",
    DATERANGE_PREFIX = 'DR_'
}

export enum weightedType {
    TIME_WEIGHTED = 'TW',
    MONEY_WEIGHTED = 'MW',
}
export enum dateRange {
    CHOOSE_ONE = 'CO',
    LAST_MONTH = 'M',
    THREE_MONTHS = 'Q',
    HALF_YEAR = 'H',
    YEARLY = 'Y',
    YEAR_TO_DATE = 'YTD',
    MAX_AVAILABLE = 'MX',
    CUSTOM = 'CU',
    EMPTY = ''
}
export enum interval {
    MONTHLY = 'M',
    QUARTERLY = 'Q',
    YEARLY = 'Y',
    EMPTY = ''
}
export enum RORType {
    CUMULATIVE = 'CM',
    CALENDAR = 'CL'
}
export enum tabType {
    RATE_OF_RETURN = 'RR',
    BALANCE_TREND = 'BT'
}
export enum chartType {
    LINE = 'Line',
    BAR = 'Bar',
    BALANCE_TREND = 'balance-trend'
}
export enum dateDropdownType {
    FULL = 'F',
    MONTHLY = 'M',
    QUARTERLY = 'Q',
    YEARLY = 'Y'
}
export enum errorCode {
    UIUSRPR0100 = 'UIUSRPR0100',
    UIUSRPR0101 = 'UIUSRPR0101',
    UIUSRPR0102 = 'UIUSRPR0102',
    UIUSRPR0103 = 'UIUSRPR0103',
    PR0100_TRACK = 'PR0100_TRACK',
    NOERROR = "NOERROR"
}
export enum actionType {
    TAB = 'tab',
    ACCOUNT = 'account'
}
export interface reportResponse {
    AccountOpenDate: string,
    AccountSelectorAccountList: [],
    AnnualPerformanceIndicator: string,
    DefaultFromCustomDate: string,
    LastBusinessDate: string,
    ReportMetrics: ReportMetrics[],
    SummaryReportMetrics: SummaryReportMetrics,
    SelectedAccountDetails?: SelectedAccountDetails,
    UiDisplayFromDate: string,
    UiDisplayToDate: string,
    Error?: Error
}
export interface selectedAccountNumber {
    AccountNumber: string,
    AccountType?: string,
    Currency?: string,
    SubAccountNumber?: string
}
export interface SelectedAccountDetails {
    AccountNumber: string,
    AccountOwnerName: AccountOwnerName,
    AccountType: string,
    SelectedSubAccount?: string
}
export interface ReportMetrics {
    EndDate: string,
    StartDate: string,
    NetIRR: Performance,
    NetROI: Performance,
    RequestedPeriodStartDate: string,
    RequestedPeriodEndDate: string,
    NetInvestment: string,
    ChangeMarketVal: string,
    EndingValue: string,
    CalculationCurrency?: string
}
export interface SummaryReportMetrics {
    EndDate: string,
    StartDate: string,
    NetIRR: Performance,
    NetROI: Performance,
    RequestedPeriodStartDate: string,
    RequestedPeriodEndDate: string,
    NetInvestment: string,
    ChangeMarketVal: string,
    EndingValue: string,
    CalculationCurrency?: string
}
export interface Performance {
    NonAnnualizedPerformance: string,
    Performance: string
}

export interface AccountOwnerName {
    FirstName: string,
    LastName: string
}
export interface accountSelectorResponse {
    Id: number,
    AccountNumber: string,
    AccountType: string,
    Currency: string,
    IsSubAccount?: boolean
}
export interface reportRequest {
    SelectedTab: string,
    ReportType: RORType | tabType,
    ReportDateRange: reportDateRangeRequest,
    SelectedAccount?: selectedAccountRequest,
    FirstTimeInd?: boolean
}

export interface selectedAccountRequest {
    PrimaryAcctNum: string,
    SubAcctType?: string,
    SubAcctCurr?: string
}
export interface reportDateRangeRequest {
    ReportDateRangeType: dateRange | interval,
    FromDate?: string,
    ToDate?: string
}
export interface subAccountsRequest {
    AccountNumber: string,
    AccountType: string,
    Currency: string
}
export interface lastCumulativeSubmittedRequest {
    fromDate: string,
    toDate: string,
    isDefault?: boolean,
    intervalType?: dateRange | interval,
}
export interface lastCalendarSubmittedRequest {
    fromDate: string,
    toDate: string,
    isDefault?: boolean,
    intervalType?: interval
}
export interface lastSearchedDates {
    cumulativeDates: lastCumulativeSubmittedRequest,
    calendarDates: lastCalendarSubmittedRequest
}
export interface fixedDates {
    AccountOpenDate: string,
    fromDate: string,
    toDate: string,
    LastBusinessDate: string,
    isDefault: boolean
}
export interface displayDate {
    fromDate: string,
    toDate: string
}
export type tab = {
    [tab in tabType]: reportRequest
}
export interface BTSummary {
    EndingValue: string,
    ChangeMarketVal: string,
    BeginningValue: string,
    Inflows: string,
    Outflows: string,
    NetInvestment: string,
    ChangeBal: string,
    RequestedPeriodStartDate: string,
    RequestedPeriodEndDate: string,
    CalculationCurrency: string
}
export interface customDates {
    from: string,
    to: string
}
export interface annualTargetInputs {
    annualTarget: number
}
export interface chartConfig {
    type: chartType,
    intervalType?: interval
}
export interface chartValue {
    endDate: string,
    data?: string,
    netDeposits?: string,
    changeInValue?: string,
    endingBalance?: string,
    calculationCurrency?: string
}
export interface chartOptions {
    chartValue: chartValue[],
    annualTarget: Number[],
    toggleAccessibility?:boolean
}
export interface Error {
    ErrorCode: string,
    DisplayErrorCode: string,
    ErrorMessage: string
}
export interface firstTimeError {
    rateOfReturn: boolean,
    balanceTrend: boolean
}