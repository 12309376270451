import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { OnlineRegisterVerificationData } from './onlineregister-verification-data';

@Injectable({ providedIn: 'root' })
export class OnlineRegisterVerificationStore extends PageStore<OnlineRegisterVerificationData> {

    constructor() {
        super(new OnlineRegisterVerificationData());
    }

}
