import { BaseData } from 'src/app/shared/models/base-data';

export class ChangeMailingOptionsLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountList: null,
            accountListLength: null,
            originalAccountList: [],
            mailTypeList: [],
            changedList: [],
            allOneType: 'false'
        };
    }
}

export interface ChangeMailingOptionsLandingInputs {
    accountList: any;
    accountListLength: number;
    originalAccountList: any;
    mailTypeList: any;
    changedList: any;
    allOneType: any;
}