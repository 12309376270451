import { RouteGroup } from '../shared/models/route-config';

export const AboutMyInvestmentCounsellorConfig: RouteGroup = {

    '/txn/aboutmyinvestmentcounsellor': {
        name: 'aboutmyinvestmentcounsellor',
        service: 'AboutMyInvestmentCounsellorService',
        servicePath:'txn/about-my-investment-counsellor/service/about-my-investment-counsellor',
        store: 'AboutMyInvestmentCounsellorStore',
        resolveContent: 'aboutmyInvestmentCounsellor',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [{
            id: 'mycounsellors',
            restUrl: 'getMyCouncellors',
            get request(): any {
                return {
                };
            }
        }
        ],
        trackingData: {
            hierarchy: "accounts",
            name: "about-my-investment-counsellor",
            nameGA: "About My Investment Counsellor",
          }
    }
};
