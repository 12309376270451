import { Injectable } from '@angular/core';
import { MutualFundsConfirmData } from './mutual-funds-confirm-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class MutualFundsConfirmStore extends PageStore<MutualFundsConfirmData> {

    constructor() {
        super(new MutualFundsConfirmData());
    }
}
