<form #otvcSetupForm="ngForm" (ngSubmit)="next(otvcSetupForm)" class="main-content-one-col otvc-form-setup">

  <section class="page-top -ms-page-top-one-col">
    <section *ngIf="model.mode == 'SignOn'">
      <h1 id="otvc_setup_header" class="page-header-title signonHeader">
        {{model.pageContent.text.header}}
      </h1>
      <span class='paragraph1'>
        <p class='paragraphtext1' [innerHtml]="model.pageContent.text.paragraph1 + ' '"></p>
        <a id="otvc_setup_learnMore2" role="link" class='underlinelink paddingRight5' (click)="learnMore()"
          href="javascript:void(0);">{{model.pageContent.text.learnMore}}</a>
        <span class='sr-only'>{{model.pageContent.text.openNewWindow}}</span>
        <span aria-hidden="true" class="icon-Out-of-App color03"></span>
      </span>

      <section class="marginTop20">
        <!-- <app-page-level-error *ngIf="hasNoContact" [error]="noContactError.message" [mode]="'error'">
                </app-page-level-error> -->
        <!-- <app-page-level-error [active]="currentModule == 'otvcsetup'" [error]="unexpectedError"
                    [mode]="'error'">
                </app-page-level-error> -->
        <!-- <div *ngIf="currentModule == 'otvcsetup'">
                    <app-error></app-error>
                </div> -->
        <div *ngIf="refreshAppError">
          <app-error [active]="currentModule == 'otvcsetup'"></app-error>
        </div>
      </section>
      <section class="page-top-divider -ms-page-top-divider-one-col marginTop30 marginBottom20">
      </section>
      <section class='header2'>
        <h2 class="paddingRight7">{{model.pageContent.text.header2}}</h2>
        <!-- <span aria-hidden='true' class="icon-Info-Circle"></span> -->
        <app-tooltip id="otvcsignon-tooltip" openIconName="icon-Info-Circle" [tooltipContent]="otvcSignonTooltip"
          [targetEle]="info-list-item1">
          <p>{{model.pageContent.list.otvcTooltipContent.content1}}</p>
          <p>{{model.pageContent.list.otvcTooltipContent.content2}}</p>
          <p>{{model.pageContent.list.otvcTooltipContent.content3}}</p>
        </app-tooltip>
      </section>
      <ul class="bulletpoint info-list" id="nfo-list-item1" tabindex="-1">
        <li>
          {{model.pageContent.text.list1}}
        </li>
        <li>
          {{model.pageContent.text.list2}}
        </li>
        <li>
          {{model.pageContent.text.list3}}
        </li>
        <li *ngIf="lob!=='CPIC'">
          {{model.pageContent.text.list4}}
          <a id="otvc_setup_contactus2_link" role="link" class="register-link paddingRight5" (click)="contactUsLinkClicked()"
            href="javascript:void(0);">
            {{model.pageContent.text.contactUs}}</a>
          <span class='sr-only'>{{model.pageContent.text.openNewWindow}}</span>
          <span aria-hidden="true" class="icon-Out-of-App color03"></span>
        </li>
        <li *ngIf="lob==='CPIC'">
          <span [innerHTML]="model.pageContent.text.list4_cpic | srOnly:true"></span>
        </li>

      </ul>

    </section>
    <section *ngIf="model.mode == 'PostSignOn'">
      <h1 class="signonHeader" [ngClass]="isDesktop() ? 'page-header-title' : 'sr-only'">
        {{model.pageContent.text.userPrefHeader}}
      </h1>
      <section class="div-steps marginBottom30">
        <app-page-step-indicator [total]="2" [current]="1" [label]="model.pageContent.text.pageStepLabel">
        </app-page-step-indicator>
      </section>
      <!-- <p class="S1">{{model.pageContent.text.cc6}}
                <a class="underlinelink sameWindow newWindow" (click)="learnmore()"
                    role="link">{{model.pageContent.text.learnMore}}</a>
            </p>
            <p class="S1" [innerHTML]="model.pageContent.text.cc12"></p> -->
      <span class='paragraph1'>
        <p class='paragraphtext' [innerHtml]="model.pageContent.text.cc6 + ' '"></p>
        <a id="otvc_setup_learnMore" role="link" class='underlinelink paddingRight5' (click)="learnMore()"
          href="javascript:void(0);">{{model.pageContent.text.learnMore}}</a>
        <span class='sr-only'>{{model.pageContent.text.openNewWindow}}</span>
        <span aria-hidden="true" class="icon-Out-of-App color03"></span>
      </span>
      <p class='paragraph1 marginTop10' [innerHtml]="model.pageContent.text.cc12"></p>
      <p class="paragraph1 marginTop10" [innerHTML]="model.pageContent.text.cc14"></p>
      <!-- <p class="paragraphtext" [innerHTML]="model.pageContent.text.cc8"></p> -->
      <section class="marginTop20">
        <!-- <app-page-level-error *ngIf="hasNoContact" [error]="noContactError.message" [mode]="'error'">
                </app-page-level-error> -->
        <!-- <app-page-level-error [active]="currentModule == 'otvcsetup'" [error]="unexpectedError"
                    [mode]="'error'">
                </app-page-level-error> -->
        <!-- <div *ngIf="currentModule == 'otvcsetup'"> -->
        <div *ngIf="refreshAppError">
          <app-error [active]="currentModule == 'otvcsetup'"></app-error>
        </div>
        <!-- <app-page-level-error [error]="noContactError" [mode]="'error'">
                </app-page-level-error> -->


      </section>
      <section class="page-top-divider -ms-page-top-divider-one-col marginTop30 marginBottom20">
      </section>
      <section class='security-label-heading'>
        <label class="paddingRight7">{{model.pageContent.text.header2}}</label>
        <!-- <span aria-hidden='true' class="icon-Info-Circle"></span> -->
        <app-tooltip [id]="'otvc-tooltip'" openIconName="icon-Info-Circle" [tooltipContent]="otvcTooltip"
          [targetEle]="info-list-item1">
          <p>{{model.pageContent.list.otvcTooltipContent.content1}}</p>
          <p>{{model.pageContent.list.otvcTooltipContent.content2}}</p>
          <p>{{model.pageContent.list.otvcTooltipContent.content3}}</p>
        </app-tooltip>
      </section>
      <ul class="bulletpoint info-list">
        <li id="info-list-item1" tabindex="-1">
          {{model.pageContent.text.pslist1}}
        </li>
        <li>
          {{model.pageContent.text.pslist2}}
        </li>
        <li>
          {{model.pageContent.text.pslist3}}
        </li>
        <li *ngIf="lob!=='CPIC'">
          {{model.pageContent.text.list4}}
          <a id="otvc_setup_contactus_link" role="link" class="register-link paddingRight5" (click)="contactUsLinkClicked_PostSignon()"
            href="javascript:void(0);">
            {{model.pageContent.text.contactUs}}</a>
          <span class='sr-only'>{{model.pageContent.text.openNewWindow}}</span>
          <span aria-hidden="true" class="icon-Out-of-App color03"></span>
        </li>
        <li *ngIf="lob==='CPIC'">
          <span [innerHTML]="model.pageContent.text.list4_cpic | srOnly:true"></span>
        </li>
      </ul>
    </section>
  </section>
  <!-- <section class="page-top-divider -ms-page-top-divider-one-col">
    </section> -->
  <section class="left-col-bright contactContent">
    <!-- <app-field-level-error-global-message [errorList]="error"></app-field-level-error-global-message> -->
    <div class="hidden-accessibility-container">
      <app-field-level-error-global-message [errorList]="error"></app-field-level-error-global-message>
    </div>
    <section id="otvc_setup_mobileSection" class="contact-info-three-col">
      <section class="contact-info-left">
        <section class="info-label ">
          <label class="info-label-text marginTop25">{{model.pageContent.text.mobileText}}</label>
        </section>
      </section>
      <section class="contact-info-centre">
        <section class="info-label ">
          <label for="mobilePhoneCountry" class="select-label-text">{{model.pageContent.text.countryText}}</label>
        </section>
        <section class='select-item field-input'>
          <select class='inner-text' id="mobilePhoneCountry" (change)="changeCountry('M')" name="mobilePhoneCountry"
            #mobilePhoneCountry="ngModel" [(ngModel)]="inputs.mobilePhoneCountry">
            <option *ngFor="let x of model.countryList let i=index" [value]="i"
              [selected]='inputs.mobilePhoneCountry===i'>
              {{x.Desc[appStore.lang.toLowerCase()]}}
            </option>
          </select>
          <!-- <span class="select-icon" aria-hidden="true"></span> -->
          <span class=" select-icon icon-Chevron-Down color03" aria-hidden="true"></span>
        </section>
      </section>
      <section class="contact-info-right">
        <section class="info-label ">
          <label for="mobilePhone" class="select-label-text">{{model.pageContent.text.numberText}}</label>
        </section>
        <section class="input-group inner-text ">
          <span class="prefix" aria-hidden="true" [ngClass]="error.mobilePhone ? 'red-border' : ''">
            {{model.countryList[inputs.mobilePhoneCountry]?.DialCode ? ("(+"+model.countryList[inputs.mobilePhoneCountry]?.DialCode+")"):
            ""}}
          </span>

          <span class="hidden-accessibility-container" role="presentation">
            <span class="hidden-accessibility"
              role="presentation">{{model.pageContent.text.sht3}}{{"+" +model.countryList[inputs.mobilePhoneCountry].DialCode}}{{model.pageContent.text.sht2}}{{model.countryList[inputs.mobilePhoneCountry].Desc[model.lang]}}
            </span>
          </span>
          <input type="tel" id="mobilePhone" [attr.aria-describedby]="'error-mobilePhone'+ uniqueId" name="mobilePhone"
            [(ngModel)]="inputs.mobilePhone"
            [attr.maxlength]="model.countryList[inputs.mobilePhoneCountry]?.CountryCode == '146' ? 14 : 12"
            (input)="inputs.mobilePhone = toViewFormat(inputs.mobilePhone, phoneTypeEnum.MOBILE_PHONE);"
            #mobilePhone="ngModel" (keypress)="preventChar($event)" [inputErrorDirective]="error.mobilePhone" />
        </section>
        <app-field-level-error [Id]="'error-mobilePhone'+ uniqueId" [error]="error.mobilePhone">
        </app-field-level-error>
      </section>
    </section>

    <section id="otvc_setup_homePhoneSection" class="contact-info-three-col">
      <section class="contact-info-left">
        <section class="info-label ">
          <label class="info-label-text marginTop25">{{model.pageContent.text.homeText}}</label>
        </section>
      </section>
      <section class="contact-info-centre">
        <section class="info-label ">
          <label for="homePhoneCountry" class="select-label-text">{{model.pageContent.text.countryText}}</label>
        </section>
        <section class='select-item field-input'>
          <select id="homePhoneCountry" class='inner-text' (change)="changeCountry('H')" name="homePhoneCountry"
            #homePhoneCountry="ngModel" [(ngModel)]="inputs.homePhoneCountry">
            <option *ngFor="let x of model.countryList let i=index" [value]="i"
              [selected]='inputs.homePhoneCountry===i'>
              {{x.Desc[appStore.lang.toLowerCase()]}}
            </option>
          </select>
          <span class=" select-icon icon-Chevron-Down color03" aria-hidden="true"></span>
        </section>
      </section>
      <section  class="contact-info-right">
        <section class="info-label ">
          <label for="homePhone" class="select-label-text">{{model.pageContent.text.numberText}}</label>
        </section>
        <section class="input-group inner-text ">
          <span class="prefix" [ngClass]="error.homePhone ? 'red-border' : ''" aria-hidden="true">
            {{model.countryList[inputs.homePhoneCountry]?.DialCode ? ("(+"+model.countryList[inputs.homePhoneCountry]?.DialCode+")"):
            ""}}
          </span>
          <!-- new -->
          <span class="hidden-accessibility-container" role="presentation">
            <span class="hidden-accessibility"
              role="presentation">{{model.pageContent.text.sht4}}{{"+" +model.countryList[inputs.homePhoneCountry].DialCode}}{{model.pageContent.text.sht2}}{{model.countryList[inputs.homePhoneCountry].Desc[model.lang]}}</span>
          </span>
          <input type="tel" id="homePhone" [attr.aria-describedby]="'error-homePhone'+ uniqueId" name="homePhone"
            [(ngModel)]="inputs.homePhone"
            [attr.maxlength]="model.countryList[inputs.homePhoneCountry]?.CountryCode == '146' ? 14 : 12"
            (input)="inputs.homePhone = toViewFormat(inputs.homePhone, phoneTypeEnum.HOME_PHONE);" #homePhone="ngModel"
            (keypress)="preventChar($event)" [inputErrorDirective]="error.homePhone" />
        </section>
        <!-- <app-field-level-error [Id]="'error-homePhone'" [error]="model.formError.homePhone">
                </app-field-level-error> -->
        <app-field-level-error [Id]="'error-homePhone'+ uniqueId" [error]="error.homePhone">
        </app-field-level-error>
      </section>
    </section>
    <section class="contact-info-three-col">
      <section class="contact-info-left">
        <section class="info-label ">
          <label class="info-label-text marginTop25">{{model.pageContent.text.businessText}}
          </label>
        </section>
      </section>
      <section id="otvc_setup_phoneSection" class="contact-info-centre">
        <section class="info-label">
          <label for="businessPhoneCountry" class="select-label-text">{{model.pageContent.text.countryText}}</label>
        </section>
        <section class='select-item field-input'>
          <select id="businessPhoneCountry" class='inner-text' (change)="changeCountry('B')"
            #businessPhoneCountry="ngModel" name="businessPhoneCountry" [(ngModel)]="inputs.businessPhoneCountry">
            <option *ngFor="let x of model.countryList let i=index" [value]="i"
              [selected]='inputs.businessPhoneCountry===i'>
              {{x.Desc[appStore.lang.toLowerCase()]}}
            </option>
          </select>
          <span class="select-icon icon-Chevron-Down color03" aria-hidden="true"></span>
        </section>
      </section>
      <section id="otvc_setup_numberSection" class="contact-info-right">
        <section class="info-label ">
          <label for="businessPhone" class="select-label-text">{{model.pageContent.text.numberText}}</label>
        </section>
        <section class="input-group inner-text ">
          <span class="prefix" [ngClass]="error.businessPhone ? 'red-border' : ''" aria-hidden="true">
            {{model.countryList[inputs.businessPhoneCountry].DialCode ? ("(+"+model.countryList[inputs.businessPhoneCountry].DialCode+")"):
            ""}}
          </span>
          <!-- new -->
          <span class="hidden-accessibility-container" role="presentation">
            <span class="hidden-accessibility"
              role="presentation">{{model.pageContent.text.sht5}}{{"+" +model.countryList[inputs.businessPhoneCountry].DialCode}}{{model.pageContent.text.sht2}}{{model.countryList[inputs.businessPhoneCountry].Desc[model.lang]}}</span>
          </span>
          <input type="tel" id="businessPhone" [attr.aria-describedby]="'error-businessPhone'+ uniqueId"
            name="businessPhone" [(ngModel)]="inputs.businessPhone"
            [attr.maxlength]="model.countryList[inputs.businessPhoneCountry]?.CountryCode == '146' ? 14 : 12"
            (input)="inputs.businessPhone = toViewFormat(inputs.businessPhone, phoneTypeEnum.BUSINESS_PHONE);"
            #businessPhone="ngModel" (keypress)="preventChar($event)" [inputErrorDirective]="error.businessPhone" />
        </section>
        <!-- <app-field-level-error [Id]="'error-businessPhone'" [error]="model.formError.businessPhone">
                </app-field-level-error> -->
        <app-field-level-error [Id]="'error-businessPhone'+ uniqueId" [error]="error.businessPhone">
        </app-field-level-error>
      </section>
    </section>

    <!-- <section class="contact-info-two-col">
            <section class="contact-info-left padding-email">
                <div class="email-heading">
                    <label for="currentSignonPassword" class="input-label">{{model.pageContent.text.emailText}}</label>
                </div>
            </section>
        </section> -->

    <!-- 
        email -->

    <section id="otvc_setup_emailSection" class="contact-info-three-col">
      <section class="contact-info-left">
        <section class="info-label ">
          <label class="info-label-text marginTop20" for="email">{{model.pageContent.text.emailText}}</label>
        </section>
      </section>

      <section class="contact-info-centre">
        <section class="email-special-top inner-text width280">
          <input type="text" id="email" [attr.aria-describedby]="'error-email' + uniqueId" name="email"
            [ngModel]="inputs.email" (input)="resetEmailError()" (ngModelChange)="inputs.email=$event.trim()"
            maxlength="40" #email="ngModel" [inputErrorDirective]="error.email" />
        </section>
        <app-field-level-error [error]="error.email" [Id]="'error-email' + uniqueId">
        </app-field-level-error>
      </section>
      <section class="contact-info-right">
        <section class="email-heading">
          <p>{{model.pageContent.text.emailPrargraph}}</p>
        </section>
      </section>
    </section>





    <!-- <section class="contact-info-two-col">
            <section class="contact-info-left">
                <div class="email-heading">
                    <p>{{model.pageContent.text.emailPrargraph}}</p>
                </div>
            </section>
            <section class="contact-info-right">
                <div class="inner-text width280">
                    <input type="text" id="email" name="email" [ngModel]="inputs.email"
                        (ngModelChange)="inputs.email=$event.trim()" maxlength="40" #email="ngModel" />
                </div>
                 <app-field-level-error [Id]="'error-email'" [error]="model.formError.email">
                </app-field-level-error> 
            </section>
    </section>-->

    <section id="otvc_setup_contactSection" class="contact-info-two-col emailPara">
      <section class="contact-info-left">
        <section class="security-section">
          <p class='para1'>
            {{model.pageContent.text.questionText}}</p>
          <p>{{model.pageContent.text.questionInfo}}</p>
        </section>
      </section>
      <section class="contact-info-right toggleStyle">
        <fieldset class="toggle-group width250">
          <section role="presentation" class="field-input">
            <input type="radio" id="yes" [attr.aria-describedby]="'error-alwaysOtvc' + uniqueId" #alwaysOtvc="ngModel"
              [(ngModel)]='inputs.alwaysOtvc' value="Y" (change)="onAlwaysChange()" name="alwaysOtvc"
              [inputErrorDirective]="error.alwaysOtvc">
            <!-- <label for="yes" class="" role="button">{{model.pageContent.text.yes}}</label> -->
            <label for="yes" class="">{{model.pageContent.text.yes}}</label>
            <input type="radio" id="no" [attr.aria-describedby]="'error-alwaysOtvc' + uniqueId" #alwaysOtvc="ngModel"
              [(ngModel)]='inputs.alwaysOtvc' value="N" (change)="onAlwaysChange()" name="alwaysOtvc"
              [inputErrorDirective]="error.alwaysOtvc">
            <!-- <label for="no" class="" role="button">{{model.pageContent.text.no}}</label> -->
            <label for="no" class="">{{model.pageContent.text.no}}</label>
          </section>
        </fieldset>
        <app-field-level-error [Id]="'error-alwaysOtvc' + uniqueId" [error]="error.alwaysOtvc">
        </app-field-level-error>
      </section>
    </section>
  </section>
  <section class="page-bottom-divider -ms-page-bottom-divider-one-col"></section>
  <section class="page-bottom -ms-page-bottom-one-col">
    <section class="page-bottom-button-container">
      <div *ngIf="model.mode == 'SignOn'" class="page-bottom-right-button-group-reversing">
        <button id="contButton" class="button primary marginBottom10-small"
          type="submit">{{model.pageContent.text.continueBtn}}
        </button>
      </div>
      <div *ngIf="model.mode == 'PostSignOn'" class="page-bottom-right-button-group-reversing">
        <button id="nextButton" class="button primary marginBottom10-small"
          type="submit">{{model.pageContent.text.nextButton}}
        </button>
      </div>
      <div *ngIf="model.mode == 'SignOn'" class="page-bottom-left-button-group">
        <button id="signOffButton" class="button secondary" type="button"
          (click)="signOff()">{{model.pageContent.text.signoffBtn}}
        </button>
      </div>
      <div *ngIf="model.mode == 'PostSignOn'" class="page-bottom-left-button-group">
        <button id="signOffButton" class="button secondary" type="button"
          (click)="goToUserPreferences()">{{model.pageContent.text.cancel}}
        </button>
      </div>
    </section>
  </section>
</form>


<!-- <app-modal id="tooltip" style='display: none;' isActive={{!hidePage}}>
    <div class='gridModal' role='presentation' aria-atomic="true" aria-controls="tooltip">
        <div id='modal-close'>
            <button (click)='closeModal("tooltip")' class="clear close-button" role="button"
                aria-label="close"></button>
        </div>
        <div class="container-selectionContent">
            <div [ngClass]="">
                <section class="container-selectionContent">
                    <section class="marginBottom1em" *ngFor="let message of messageList">
                        {{message}}
                    </section>
                </section>
            </div>
            <button class='clear' style="background-color:transparent;border:0px; box-shadow: none; text-align: left"
                class='fs1'></button>
        </div>
    </div>
</app-modal> -->