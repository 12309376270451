import { BuyBondsVerifyData } from './buy-bonds-verify-data';
import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../../shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class BuyBondsVerifyStore extends PageStore<BuyBondsVerifyData> {

    constructor() {
        super(new BuyBondsVerifyData());
    }

}
