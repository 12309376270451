import { Component, OnInit, OnDestroy, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { isDefined } from '../../services/utils.service';


@Component({
  selector: 'app-connected-radio',
  templateUrl: './connected-radio.component.html',
  styleUrls: ['./connected-radio.component.scss']
})
export class ConnectedRadioComponent implements OnInit, OnDestroy, OnChanges {


  @Input() public contentArray: any; // [..{label:string,value?:any,color?:string,backgroundColor?:string}..]
  @Input() public disabledArray: any; //[..boolean..]
  @Input() public selectedValue!: any;
  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() public dynamicMode: boolean = false;
  @Input() public uniqueName: string;
  @Input() public labelledbyID: string = null;
  @Input() public label: string = null;

  @Input() public error: string;
  @Input() pagename;
  public position: number = -1;
  public resize_ob: ResizeObserver;
  public focusTimeout: any;

  constructor() { }

  get document() {
    return document;
  }

  ngOnInit() {
    let self = this;

    setTimeout(() => {
      self.resize_ob = new ResizeObserver(function (e) {
        self.setIndicatorStyle();
      });
      let container = this.document.getElementById('connected-radio-' + this.uniqueName);
      if (container) {
        self.resize_ob.observe(container);
      }
    }, 10);
  }

  ngOnDestroy(): void {
    let container = this.document.getElementById('connected-radio-' + this.uniqueName);
    if (this.resize_ob) {
    if (container) {
      this.resize_ob.unobserve(container);
    } else {
      this.resize_ob.disconnect();
    }
  }
  }

  setIndicatorStyle() {
    let indic = document.getElementById('indicator-' + this.uniqueName);

    if (indic) {
      if (this.position > -1) {
        indic.style['display'] = 'block';
        if (this.contentArray[this.position].backgroundColor) {
          indic.style['background-color'] = this.contentArray[this.position].backgroundColor;
          indic.style['border'] = 'none';
        } else {
          indic.style['background-color'] = null;
          indic.style['border'] = null;
        }
        indic.style['left'] = document.getElementById('connectedRadio-' + this.uniqueName + '-' + this.position)?.parentElement.offsetLeft + 'px';
        indic.style['width'] = document.getElementById('connectedRadio-' + this.uniqueName + '-' + this.position)?.clientWidth + 'px';
      } else {
        indic.style['display'] = 'none';
      }
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (((isDefined(simpleChanges.selectedValue) && this.pagename != 'TradeMLS') || (this.pagename == 'TradeMLS' && isDefined(simpleChanges.selectedValue) && simpleChanges.selectedValue.currentValue !== "")))  {
      for (let i = 0; i < this.contentArray.length; i++) {
        if (this.contentArray[i].value == simpleChanges.selectedValue.currentValue || simpleChanges.selectedValue.currentValue == i.toString()) {
          this.position = i;
          setTimeout(() => {
            this.setIndicatorStyle();
          }, 10);
        } 
      }
    }
    else if (this.pagename == 'TradeMLS') {
      this.position = -1;
      setTimeout(() => {
          this.setIndicatorStyle();
      }, 10);
    }
  }

  handleChange(evt, i) {
    var target = evt.target;
    if (target.checked) {
      this.position = i;
      this.setIndicatorStyle();
      this.selectedValueChange.emit(this.selectedValue);
    }
  }

  focusEvent(ev, i) {
    clearTimeout(this.focusTimeout);
    this.focusTimeout = setTimeout(() => {
      if (!(ev.relatedTarget && ev.relatedTarget.parentElement.classList.contains('connectedRadio')) && i != this.position) {
        let el = this.document.getElementById("connectedRadio-" + this.uniqueName + "-" + this.position);
        if (isDefined(el)) {
          el.focus();
        }
      }
    });
  }
}
