import { Injectable } from '@angular/core';
import { PageStore } from '../../../../shared/models/page-store';
import { ExchangeCurrencyData } from './exchange-currency-data';

@Injectable({ providedIn: 'root' })
export class ExchangeCurrencyStore extends PageStore<ExchangeCurrencyData> {

  constructor() {
    super(new ExchangeCurrencyData());
  }

}
