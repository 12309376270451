import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { AccountsAgreementsDisclosuresData } from './accounts-agreements-disclosures-data';

@Injectable({ providedIn: 'root' })
export class AccountsAgreementsDisclosuresStore extends PageStore<AccountsAgreementsDisclosuresData> {

  constructor() {
    super(new AccountsAgreementsDisclosuresData());
  }

}
