import { BuyBondsConfirmStore } from '../data/buy-bonds-confirm-store';
import { Injectable } from '@angular/core';
import { PageService } from '../../../../../../shared/services/page-service';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BuyBondsConfirmService implements PageService {


  constructor() { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (BuyBondsConfirmStore.params) {
      let ser = input[0];
      ser.input = BuyBondsConfirmStore.params;
      return input;
    } else {
      return [];
    }
  }

}
