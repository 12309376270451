import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { ChangeSignonPasswordReq } from '../data/change-signon-password-landing.data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class ChangeSignOnPasswordLandingService implements PageService {


  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    return input;
  }

  changeSignonPassword(data) {
    const req: ChangeSignonPasswordReq = new ChangeSignonPasswordReq();
    req.CurrentPassword = data.CurrentPassword;
    req.NewPassword = data.NewPassword;
    req.ConfirmPassword = data.ConfirmPassword;
    return this.httpService.post(gatewayConfig.APIServices.userPrefUpdateSignOnPassword.url, req);
  }
}
