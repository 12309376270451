import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { SsoService } from './sso.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { _User } from 'src/app/shared/models/user';
import { CommonService } from 'src/app/shared/services/common.service';
import { ContentService } from 'src/app/core/services/content.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { Subscription } from 'rxjs';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");


@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html'
})
export class SsoComponent implements OnInit, OnDestroy {
  Target: string;
  Mnum: string;
  Lob: string;
  Locale: string;
  error: any;
  Query: any;
  isWrapperApp: any;
  subscriptions = new Subscription();


  constructor(
    private appStore: AppStore,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,
    private ssoService: SsoService,
    private contentService: ContentService,
    private _subscriptionService: ObservableSubscriptionService,
    private commonservice: CommonService
  ) {
  }

  // can be changed based on the req from OBR

  ngOnInit() {
    this.subscriptions.add(this.route.queryParams.subscribe((params: Params) => {

      // OBR
      if (params.target) {
        this.Target = params.target;
        this.Mnum = params.mnum;
        this.Query = params.query;
        this.ssoSubmit();
        // refactor after signon to app-init-service
      } else {
        // refactor after signon to app-init-service
        // detecting wrapper app for mobi
        // if (params['isWrapper']) {
        //   this.appStore.channel = _User.Channel.OBW;
        //   this.isWrapperApp = params['isWrapper'];
        //   // alert('wrapper is on' + this.isWrapperApp);
        //   this.router.navigate(['/txn/accounts/myaccounts']);

        // } else {
        //   // alert ('not a awrapper');
        //   this.router.navigate(['/txn/accounts/myaccounts);
        // }
      }
    }, err => {
      if (err) {
        this.error = err;
        console.log(err);
      }
    }));
  }

  ssoSubmit() {
    let req: any = new Object;
    req.Target = this.Target;
    req.Mnum = this.Mnum;
    req.Query = this.Query;
    this.subscriptions.add(this.httpService.post(gatewayConfig.APIServices.getSiteStateSSO.url,
      req
    ).subscribe((res: any) => {
      if (res.InTimestamp) {
        const initialTimeStamp = res.InTimestamp;
        this.appStore.state.user.lob = res.Lob ? res.Lob.toLowerCase() : '';
        this.appStore.state.user.lang = res.Locale ? res.Locale.slice(0, 2).toLowerCase() : '';
        this.contentService.fetchGlobalContent();
        // this.contentService.fetchCurerencyContent();
        this.commonservice.updateLanguage();
        this._subscriptionService.passLob(res.Lob);
        this.ssoService.storeTimeStamp(initialTimeStamp);
        this.router.navigate(['/txn/accounts/myaccounts']);
        console.log('data transfer information temporary', res.dQuery);
      }
    }));
    // refactor and remove temporary code till we get the OBR-2 login.
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

  }
}
