import { Injectable } from '@angular/core';
import { CreateTradingPassData } from './create-trading-pass-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({providedIn:'root'})
export class CreateTradingPassStore extends PageStore<CreateTradingPassData> {

    constructor () {
        super(new CreateTradingPassData());
    }
    
}
