import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { SiteMapData } from './site-map-data';
@Injectable({ providedIn: 'root' })
export class SiteMapStore extends PageStore<SiteMapData> {

    constructor() {
        super(new SiteMapData());
    }
}
