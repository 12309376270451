import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { OtvcPromptModalComponent } from 'src/app/shared/components/otvc-prompt/otvc-prompt-modal.component';
import { OtvcVerifyFormComponent } from 'src/app/shared/components/otvc/otvc-verifyForm/otvc-verify-form.component';
import { OtvcSetupFormComponent } from 'src/app/shared/components/otvc/otvc-setupForm/otvc-setup-form.component';
import { CountryInfoComponent } from 'src/app/shared/components/country-info/country-info.component';
import { FormSharedModule } from 'src/app/shared/form-shared.module';
import { FlowWidgetSharedModule } from 'src/app/shared/flow-widget-shared.module';
import { WidgetSharedModule } from 'src/app/shared/widget-shared.module';
import { SROnlyPipe } from 'src/app/core/pipes/sr-only.pipe';

@NgModule({
  declarations: [
    CountryInfoComponent,
    OtvcSetupFormComponent,
    OtvcVerifyFormComponent,
    OtvcPromptModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FormSharedModule,
    FlowWidgetSharedModule,
    WidgetSharedModule
  ],
  exports: [
    CountryInfoComponent,
    OtvcSetupFormComponent,
    OtvcVerifyFormComponent,
    OtvcPromptModalComponent
  ]
})
export class OtcvSharedModule { }
