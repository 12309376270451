import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';
import { PageService } from 'src/app/shared/services/page-service';

@Injectable({
    providedIn: 'root'
})
export class AccountSettingsService implements PageService {
    prevURL:any;
    dataMap: any = {
        en : {
        'cibc': 'CIBC',
        'simplii': 'Simplii Financial',
        'others': 'Other Institutions'
        },
        fr : {
        'cibc': 'CIBC',
        'simplii': 'Financière Simplii',
        'others': 'Autres institutions',
        }
    }

    private profileInfoSubject = new BehaviorSubject<string>('');
    profileInfoObs = this.profileInfoSubject.asObservable();

    private accountNumberSubject = new BehaviorSubject<string>('');
    accountNumberObs = this.accountNumberSubject.asObservable();

    private prevUrlCrossFlowSubject = new BehaviorSubject<string>('');
    prevUrlCrossFlowObs = this.prevUrlCrossFlowSubject.asObservable();

    private accountListSubject = new BehaviorSubject<string[]>([]);
    accountListObs = this.accountListSubject.asObservable();
    
    constructor(private httpService: HttpService) { }

    formatPhoneNumber(phoneNumber: string): string {
        if (phoneNumber?.length > 0) {
          let cleanNumber = phoneNumber.replace(/\D/g, "");
          let formattedNumer = cleanNumber.slice(0, 3);
          formattedNumer += "-" + cleanNumber.slice(3, 6);
          formattedNumer += "-" + cleanNumber.slice(6, 10);
          if (cleanNumber.length > 10) {
            formattedNumer += "-" + cleanNumber.slice(10);
          }
          return formattedNumer;
        }
        return 'Not provided';
      }

    formatDatafromBE(data: string, lang?: string): string {
        if(lang == 'en'){
            let normalizedData = data.trim().toLowerCase();
            return this.dataMap.en[normalizedData] || normalizedData;
        } else if(lang == 'fr'){
            let normalizedData = data.trim().toLowerCase();
            return this.dataMap.fr[normalizedData] || normalizedData;
        } else {
            let normalizedData = data.trim().toLowerCase();
            return this.dataMap.en[normalizedData] || normalizedData;
        }
    }

    capitalizeFirstChar(data: string): any {
        let streetNumber = '';
        let name = data;
        if(!data) return {streetNumber:'',name:''};
        data = data.trim();
        let match = data.match(/^(\d+)\s+/);
        if(match) {
            streetNumber = match[1];
            name = data.substring(match[0].length);
        } 
        name = name?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
        return { streetNumber, name };
    }

    formatDataTitleCase(data: string): any {
        let name = data?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
        return name;
    }

    formatZipCode(data: string): string {
        return data.slice(0,3) + ' ' + data.slice(3);
    }

    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return input;
    }
    private previousURL: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    
    setPrevURL(preURL:string){
        this.previousURL.next(preURL);
    }
    getPrevURL(){
        const subscription = this.previousURL.subscribe((url)=>{
            this.prevURL = url;
        });
        setTimeout(()=> subscription.unsubscribe(), 1000);
        return  this.prevURL
    }

    setAccountNumberObs(value: any) {
        this.accountNumberSubject.next(value);
    }

    clearAccountNumberObs() {
        this.accountNumberSubject.next(null);
    }

    setProfileInfoObs(value: any) {
        this.profileInfoSubject.next(value);
      }

    setPrevUrlCrossFlowObs(value: any) {
        this.prevUrlCrossFlowSubject.next(value);
    }

    setAccountListObs(value: string[]) {
        this.accountListSubject.next(value);
    }
    

}