import { Injectable } from "@angular/core";
import { PageService } from "../../../../../../shared/services/page-service";
import { SellBondConfirmStore } from "../data/sell-bond-confirm-store";

@Injectable({
  providedIn: "root",
})
export class SellBondConfirmService implements PageService {
  constructor() {}

  prepareInput(input: any): any {
    if (SellBondConfirmStore.params) {

      let ser = input[0];
      ser.input = SellBondConfirmStore.params;
      return input;
    }
    return [];
  }
}
