import { Component, Input, OnInit } from '@angular/core';
import { AppStore } from '../../models/app-store';
import { CommonService } from '../../services/common.service';
import { GlobalContentStore } from '../../store/global-content-store';

@Component({
  selector: 'app-page-step-indicator',
  templateUrl: './page-step-indicator.component.html',
  styleUrls: ['./page-step-indicator.component.scss']
})
export class PageStepIndicatorComponent implements OnInit {

  totalcollection: number[];

  @Input() current: number;
  @Input() total: number;
  @Input() label: string;
  @Input() isDynamicForm = false;

  constructor(
    private appStore: AppStore,
    public globalContent: GlobalContentStore,
    private commonService: CommonService
  ) {
  }

  ngOnInit() {
    this.totalcollection = Array(this.total).fill(0);
  }

  positionPicker(i) {
    if (i + 1 === this.current) {
      return 'selected';
    } else if (i + 1 > this.current) {
      return 'after';
    }
  }


  isDesktop() {
    return this.commonService.isDesktop();
  }
}
