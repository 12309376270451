import { BaseData } from 'src/app/shared/models/base-data';

export class SellStructNotesLandingData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1,
      note: "",
      noteIndex: -1,
      amountType: "",
      amount: "",
      settlementIndex: -1
    };
  }
}

export interface SellStructNotesLandingInputs {
  accountIndex: number;
  note: string;
  noteIndex: number;
  amountType: string;
  amount: string;
  settlementIndex: number;
}

export interface SettlementAccount {
  SettlementType: string;
  Currency: string;
  AccountType?: string;
}

export interface AccountDetail {
  AccountType: string;
  AccountNumber: number;
  AccountTypeDesc: string;
  AccountOwnerName?: AccountOwnerName;
  Fullname: string;
  AccountFriendlyName?: string;
  AccountBalances: AccountBalance[];
  IncludeShort?: string;
  BuyingPowerValueType?: BuyingPowerValueType;
}

export interface AccountOwnerName {
  FirstName: string;
  LastName: string;
}

export interface AccountBalance {
  AccountType: string;
  Currency: string;
  Amount: number;
}

enum BuyingPowerValueType {
  COMBINED = "COMBINED",
  SHOW_CAD_AND_USD = "SHOW_CAD_AND_USD",
  SHOW_CAD = "SHOW_CAD"
}

export interface SettlementAccountsReq {
  AccountNumber: string;
  Action: string;
  AccountCurrency?: string;
}

export interface Note {
  AccountCurrency: string;
  CurrentValue: AmountHeld;
  Quantity: AmountHeld;
  Symbol: Symbol;
}

export interface Symbol {
  FundName: string;
  SymbolName: string;
}

export interface AmountHeld {
  Content: string;
  Data: string;
}
