import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from '../../../../../../shared/services/page-service';
import { AppStore } from '../../../../../../shared/models/app-store';
import { HttpService } from '../../../../../../core/services/http.service';
import { BuyGICConfirmStore } from '../data/buy-gic-confirm-store';

@Injectable({
  providedIn: 'root'
})
export class BuyGICConfirmService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (BuyGICConfirmStore.params) {
      let ser = input[0];
      ser.input = BuyGICConfirmStore.params;
      return input;
    } else {
      return [];
    }
  }

  pruneInput(input: any): any {
    return input;
  }

}
