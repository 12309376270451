import { BaseData } from 'src/app/shared/models/base-data';

export class MutualFundsVerifyData extends BaseData {
  constructor() {
    super();
    this.input = {
      tradingPassword: '',
      savePassword: false,
      fundFactsAgreementCheckIndicator: false,
      agreeProceed: false
    };
  }
}

export interface MutualFundsVerifyInputs {
  tradingPassword: string;
  savePassword: boolean;
  fundFactsAgreementCheckIndicator: boolean;
  agreeProceed: boolean;
}
  
export enum OmnitureValues {
  REINVEST = "reinvest",
  DEPOSIT = "deposit",
  NOTAPPLICABLE = "na"
};

