import { BaseData } from 'src/app/shared/models/base-data';

export class OptionsCentralData extends BaseData {
  constructor() {
    super();
    this.input = {
      market: ""
    };
  }
}

export class ChainReq {
  SymbolName: string;
  Market: string;
  StrikePrice?: string;
  ExpiryDate?: string;
}

export class QuoteReq {
  Symbols: [
    {
      SymbolName: string;
      Market: string;
      SymbolType: string;
      OptionInfo?: {
        OptionType: string;
        StrikeDate: string;
        StrikePrice: {
          Value: string;
        }
      }
    }
  ];
}