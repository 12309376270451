<div *ngIf="!isSourceDCO">
  <div *ngIf="appStore.lob.toLowerCase() != 'gen' && appStore.lob.toLowerCase() != 'ie'" class="footer"
  [ngClass]="{'signon-space': isHome(), 'global-footer-padding': displayGlobalFooter()}">
  <div *ngIf="displayFull()" class="footerColumns">
    <!-- <div class="globalFooterColumn" *ngFor="let footerLink of globalContent.list.globalFooterLinks; let i = index"> -->
    <div class="globalFooterColumn" *ngFor="let footerLink of globalContent.list.preSignonFooterLinks; let i = index">
      <h3 id="pre_footer_header-{{i}}" class="W16m color01" *ngIf="footerLink.header">{{footerLink.header}}</h3>
      <ul [ngClass]="isDesktop() ? 'A12' : 'A14'">
        <!-- <li *ngFor="let subLinks of footerLink.content"><a href="javascript:void(0);">{{subLinks.text}}</a>
                </li> -->
                <ng-container *ngFor="let subLinks of footerLink.content; let j = index">
                <li *ngIf="(isApp && subLinks.oneTrustMobile) || (!isApp && subLinks.oneTrustWeb) || (!subLinks.oneTrustMobile && !subLinks.oneTrustWeb)">
                  <a id="pre_footer_sublinks_linkto-{{i}}" (click)="clickLink(subLinks.linkTo)" href="javascript:void(0);"
                    [innerHtml]="subLinks.text + openNewWindowText"></a>
                </li>
                </ng-container>

      </ul>
    </div>
  </div>
  <ng-container *ngIf="displayGlobalFooter()">
    <div class="footerColumns paddingBottom10 globalFooterColumns" *ngIf="globalContent">
      <div class="globalFooterColumn" *ngFor="let footerLink of globalContent.list.globalFooterLinks; let i = index">
        <div class="W16m color01 globalFooterColumnHeaders" *ngIf="footerLink.header">{{footerLink.header}}</div>
        <ul [ngClass]="isDesktop() ? 'A12' : 'A14'">
          <ng-container *ngFor="let subLinks of footerLink.content; let j = index">
          <li *ngIf="(isApp && subLinks.oneTrust) || (isApp && subLinks.oneTrustMobile) || (!isApp && subLinks.oneTrustWeb) || (!subLinks.oneTrust && !subLinks.oneTrustMobile && !subLinks.oneTrustWeb)">
              <a href="javascript:void(0);" *ngIf="!subLinks.obrCpic" [id]="subLinks.linkTo === 'dco'? 'dcoAppLink':''"
              (click)="openExternalLink(subLinks)" [innerHtml]="subLinks.text + openNewWindowText"></a>
              <a id="pre_footer_investmentCounsellor-{{j}}" routerLink="/txn/aboutmyinvestmentcounsellor" *ngIf="subLinks.obrCpic"
                [innerHtml]="subLinks.text + openNewWindowText"></a>
          </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div id="pre_footer_disclosure" class="A12 color01 global-footer-disclosure">
      <img class="cibc-img"
        [src]="isDesktop() ? 'assets/images/logos/cibc_'+ appStore.lob.toLowerCase() +'_'+ appStore.lang +'_rev_desktop.svg' : 'assets/images/logos/cibc_'+appStore.lob.toLowerCase() +'_'+ appStore.lang +'_rev_mobile.svg'"
        [alt]="globalContent.text.logo">
      <div class="global-footer-content">
        <div>{{globalContent.text.disclosureNotes}}</div>
        <div *ngIf="appStore.lob.toLowerCase() === 'cpic' || appStore.lob.toLowerCase() === 'wg'">
          <br>{{globalContent.text.disclosureNotes2}}
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!displayGlobalFooter()" class="A12 color01 footer-disclosure"
    [ngClass]="(displayFull() && appStore.lob.toLowerCase() != 'cpic') ? 'with-image' : ''">
    <!-- <div class="footer-content">{{globalContent.text.disclosureNotes}}</div> -->
    <div id="pre_footer_content" class="footer-content" [ngClass]="isDesktop() ? 'A12' : 'A14'">
      <div>
        {{globalContent.text.disclosureNotesPreSignon}}
      </div>
      <div *ngIf="appStore.lob.toLowerCase() === 'cpic' || appStore.lob.toLowerCase() === 'wg'">
        <br>{{globalContent.text.disclosureNotesPreSignon2}}
      </div>
    </div>
    <div *ngIf="displayFull() && appStore.lob.toLowerCase() != 'cpic'" class="footer-images">
      <a id="pre_footer_image1_link" class="footer-images-1" (click)="clickLink(globalContent.list.preFooter.CIPFHref)" href="javascript:void(0);">
        <img [src]="'assets/images/logos/CIPF_' + appStore.lang.toUpperCase() + '.svg'"
          [alt]="globalContent.list.preFooter.CIPFText">
      </a>
      <a id="pre_footer_image2_link" [ngClass]="'footer-images-2' + appStore.lang.toUpperCase()" (click)="clickLink(globalContent.list.preFooter.CIROHref)" href="javascript:void(0);">
       <img [src]="'assets/images/logos/CIRO_colour_' + appStore.lang.toUpperCase() + '.svg'"
          [alt]="globalContent.list.preFooter.CIROText">
      </a>
    </div>
  </div>
  <div *ngIf="displayFull()" class="footer-bottom">
    <a id="pre_footer_launguageSwitch_link" class="color05 footer-locale-swap" [ngClass]="isDesktop() ? 'A12' : 'A14'" href="javascript:void(0);"
      (click)="swapLocale()" [attr.lang]="appStore.lang.toLowerCase() == 'en' ? 'fr' : 'en'">
      {{globalContent.text.languageSwitch}}
    </a>
    <div class="footer-bottom-group">
      <div id="pre_footer_copyrightgroup" class="footer-copyright">
        <img class="footer-copyright-image" src="assets/images/logos/cibc_reverse.svg" alt="logo" aria-hidden="true">
        <div class="color01 footer-copyright-text" [ngClass]="isDesktop() ? 'A12' : 'A14'">
          {{globalContent.list.preFooter.copyRight}}</div>
      </div>
      <!-- <div class="footer-connect">
        <div class="A14 color01 connect-text">Connect with us:</div>
        <div class="connect-icons">ICONS</div>
      </div> -->
    </div>
    <div id="pre_footer_buildInfoEnable" *ngIf="isBuildInfoEnable()" class="footer-content build-info color01">
      <!-- <p>{{buildInfo_Server}}</p> -->
      <p [ngClass]="isDesktop() ? 'A12' : 'A14'">{{buildInfo_Client}}<span *ngIf="isApp" class="inline">
          {{buildInfo_App}}</span></p>
    </div>
  </div>
</div>

<div *ngIf="appStore.lob.toLowerCase() == 'gen'" class="gen-footer" [ngClass]="isHome() ? 'signon-space' : ''">
</div>
<div *ngIf="appStore.lob.toLowerCase() == 'ie'" class="ie-footer" [ngClass]="{'signon-space': isHome()}">
  <ng-container *ngIf="isDesktop(); else ieSmallFooter">
    <div *ngIf="displayFull()" class="footer-container">
      <div class="footer-link-container">
        <div *ngFor="let link of globalContent.list.preSignonModernFooterLink.content">
          <a (click)="openInNewTab(link.linkTo)" href="javascript:void(0);" [attr.aria-label]="link.text + link.hdnTxt">{{link.text}}</a>
        </div>
      </div>
      <div class="footer-bottom-container"> 
        <div class="footer-right">
          <img class="cibc-footer-logo" src="assets/images/logos/cibc-footer-ie.svg" alt="logo" aria-hidden="true">
          <div class="cibc-copyright">
            {{globalContent.list.preSignonModernFooterLink.copyRight}}
          </div>
        </div>
        <div class="bigFooterExtraGrow"></div>
        <div class="vendor">
          <a class="CIPFIcon" (click)="clickLink(globalContent.list.preFooter.CIPFHref)" href="javascript:void(0);">
            <img [src]="'assets/images/logos/CIPF_grey_' + appStore.lang.toUpperCase() + '.svg'"
              [alt]="globalContent.list.preFooter.CIPFText">
          </a>
          <a class="CIROIcon" (click)="clickLink(globalContent.list.preFooter.CIROHref)" href="javascript:void(0);">
            <img [src]="'assets/images/logos/CIRO_grey_' + appStore.lang.toUpperCase() + '.svg'"
              [alt]="globalContent.list.preFooter.CIROText">
          </a>
        </div>
        <div class="wealth-app">
          <div class="wealth-app-text">
            <a class="wealth-app-link" href="javascript:void(0);" (click)="openInNewTab(globalContent.list.preSignonModernFooterLink.wealthAppLink)" [attr.aria-label]="globalContent.list.preSignonModernFooterLink.download + globalContent.list.preSignonModernFooterLink.wealthApp + globalContent.list.preSignonModernFooterLink.wealthAppHdnTxt" >
              {{globalContent.list.preSignonModernFooterLink.download}}
              <br />
              <span class="underline">
                {{globalContent.list.preSignonModernFooterLink.wealthApp}}
              </span>
            </a>
          </div>
          <div class="qr-code">
            <qr-code value={{qrCodeString}} size="72" errorCorrectionLevel="M" [attr.aria-label]="globalContent.list.preSignonModernFooterLink.qrCodeHdnTxt">
            </qr-code>
          </div>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="isSSOPages()" class="footer-container">
      <div class="W10m color03">
        {{globalContent.list.postSignonModernFooterLink.copyRight}}
      </div>
    </div> -->
    <!-- <div *ngIf="displayFull()" class="bigFooterContainer" [ngClass]="'bigFooterContainer' + appStore.lang.toUpperCase()">
      <div class="bigFooterColumns">
        <div class="bigFooterColumn" *ngFor="let footerLink of globalContent.list.preSignonFooterLinks; let i = index">
          <h3 *ngIf="footerLink.header">{{footerLink.header}}</h3>
          <ul>
            <li *ngFor="let subLinks of footerLink.content">
              <a (click)="clickLink(subLinks.linkTo)" href="javascript:void(0);" [innerHtml]="subLinks.text  + ' ' + openNewWindowText"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="bigFooterApp">
        <div class="mobile-wealth-content">
          <div>{{globalContent.text.getCIBCMobileWealthApp}}</div>
        </div>
        <div class="mobile-image-content">
          <a (click)="getAppleForMobileWealthApp()">
            <img class="apple-img"
              [src]="isDesktop() ? 'assets/images/icons/mobile_'+appStore.lob.toLowerCase()+'_'+appStore.lang.toLowerCase()+'_apple_store.svg' : 'assets/images/icons/mobile_'+appStore.lob.toLowerCase()+'_'+appStore.lang.toLowerCase()+'_apple_store.svg'"
              [alt]="globalContent.text.newAppleWindow"></a>
          <a (click)="getGooglePlayForMobileWealthApp()">
            <img class="google-play-img"
              [src]="isDesktop() ? 'assets/images/icons/mobile_'+appStore.lob.toLowerCase()+'_'+appStore.lang.toLowerCase()+'_google_play_store.svg' : 'assets/images/icons/mobile_'+appStore.lob.toLowerCase()+'_'+appStore.lang.toLowerCase()+'_google_play_store.svg'"
              [alt]="globalContent.text.newGooglePlayWindow"></a>
        </div>
      </div>
    </div>
    <div class="bigFooterExtra">
      <div class="bigFooterExtraCopyRight">
        <img class="footer-copyright-image" src="assets/images/logos/cibc_reverse.svg" alt="logo" aria-hidden="true">
        <div class="footer-copyright-text">
          {{globalContent.list.preFooter.copyRight}}</div>
      </div>
      <div *ngIf="displayFull()" class="bigFooterExtraGrow"></div>
      <div *ngIf="displayFull()">
        <div class="bigFooterExtraVendors" [ngClass]="'bigFooterExtraVendors' + appStore.lang.toUpperCase()">
          <a class="vendorIcon1" (click)="clickLink(globalContent.list.preFooter.CIPFHref)" href="javascript:void(0);">
            <img [src]="'assets/images/logos/CIPF_' + appStore.lang.toUpperCase() + '_IE.svg'"
              [alt]="globalContent.list.preFooter.CIPFText">
          </a>
          <a class="vendorIcon2" (click)="clickLink(globalContent.list.preFooter.IIROCHref)" href="javascript:void(0);">
            <img [src]="'assets/images/logos/IIROC_' + appStore.lang.toUpperCase() + '_IE.svg'"
              [alt]="globalContent.list.preFooter.IIROCText">
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="isBuildInfoEnable()" class="footer-content build-info color05">
      <p class="W12m">{{buildInfo_Client}}<span *ngIf="isApp" class="inline">
          {{buildInfo_App}}</span></p>
    </div> -->
  </ng-container>
  <ng-template #ieSmallFooter>
    <div *ngIf="displayFull()" class="vendorIcons">
      <a class="vendorIcon1" (click)="clickLink(globalContent.list.preFooter.CIPFHref)" href="javascript:void(0);">
        <img [src]="'assets/images/logos/CIPF_black_' + appStore.lang.toUpperCase() + '.svg'"
          [alt]="globalContent.list.preFooter.CIPFText">
      </a>
      <a class="vendorIcon2" (click)="clickLink(globalContent.list.preFooter.CIROHref)" href="javascript:void(0);">
        <img [src]="'assets/images/logos/CIRO_black_' + appStore.lang.toUpperCase() + '.svg'"
          [alt]="globalContent.list.preFooter.CIROText">
      </a>
    </div>
    <div *ngIf="displayFull()" class="footer-links">
      <a (click)="swapLocale()" href="javascript:void(0);">
        <span>{{globalContent.text.languageSwitch}}</span>
      </a>
      <a id="pre-legal-open-button" (click)="openLegal()" href="javascript:void(0);">
        <span>{{globalContent.text.legal}}</span>
        <span class="sr-only">{{globalContent.text.legalSR}}</span>
      </a>
    </div>
  </ng-template>
</div>

<app-modal id="pre-legal" style="text-align: center; overflow-x: hidden; display: none;">
  <div *ngIf="globalContent.list.preSignonFooterLegalLinks" class="pre-legal-container">
    <div class="pre-legal-header">
      <button id="pre-legal-close-button" type="button" (click)="closeLegal()" class="icon-Close"
        [attr.aria-label]="globalContent.text.legalCloseSR"></button>
    </div>
    <div class="pre-legal-body-container">
      <div class="pre-legal-body">
        <div class="pre-legal-body-header">{{globalContent.list.preSignonFooterLegalLinks.header}}</div>
        <ng-container  *ngFor="let legalLink of globalContent.list.preSignonFooterLegalLinks.content; let i = index">
        <a class="pre-legal-body-link" *ngIf="(isApp && legalLink.oneTrustMobile) || (!isApp && legalLink.oneTrustWeb) || (!legalLink.oneTrustMobile && !legalLink.oneTrustWeb)"
          (click)="clickLink(legalLink.linkTo)" href="javascript:void(0);">
          <span class="pre-legal-body-link-text" [innerHtml]="legalLink.text"></span>
          <span class="sr-only">
            <span>{{globalContent.list.preSignonFooterLegalLinks.srStart}}</span>
            <span [innerHtml]="legalLink.text"></span>
            <span>{{globalContent.list.preSignonFooterLegalLinks.srEnd}}</span>
          </span>
          <span class="icon-Chevron-Right"></span>
        </a>
        </ng-container>
      </div>
      <div class="pre-legal-spacer"></div>
      <div class="pre-legal-footer">
        <div class="pre-legal-vendors">
          <a class="vendorIcon1" (click)="clickLink(globalContent.list.preFooter.CIPFHref)" href="javascript:void(0);">
            <img [src]="'assets/images/logos/CIPF_black_' + appStore.lang.toUpperCase() + '.svg'"
              [alt]="globalContent.list.preFooter.CIPFText">
          </a>
          <a class="vendorIcon2" (click)="clickLink(globalContent.list.preFooter.CIROHref)" href="javascript:void(0);">
            <img [src]="'assets/images/logos/CIRO_black_' + appStore.lang.toUpperCase() + '.svg'"
              [alt]="globalContent.list.preFooter.CIROText">
          </a>
        </div>
        <div class="pre-legal-disclosure">{{globalContent.text.disclosureNotesPreSignon}}</div>
      </div>
      <div class="pre-legal-site-label">
        <img [src]="'assets/images/logos/CIBC_CR_2C_RGB.svg'" alt="C I B C">
        <div>
          {{globalContent.list.preFooter.copyRight}}
        </div>
      </div>
    </div>
  </div>
</app-modal>
</div>