import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CreateMutualFundConfirmData } from './create-mutual-fund-confirm-data';

@Injectable({ providedIn: 'root' })
export class CreateMutualFundConfirmStore extends PageStore<CreateMutualFundConfirmData>  {

    constructor() {
        super(new CreateMutualFundConfirmData());
    }

}
