import { BaseData } from 'src/app/shared/models/base-data';

export class ContactInfoUpdateLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountNumber: null,
            email: null,
            canadianResident: null,
            homeAddressApartment: null,
            homeAddressAddressLine: null,
            homeAddressCity: null,
            homeAddressProvince: null,
            homeAddressPostalCode: null,
            mailingAddressApartment: null,
            mailingAddressAddressLine: null,
            mailingAddressCity: null,
            mailingAddressProvince: null,
            mailingAddressPostalCode: null,
            homeAddressCheckbox: null,
            mailingAddressCheckbox: null,
        };
    }
}

export interface ContactInfoUpdateLandingInputs {
    accountNumber: string;
    email: string;
    canadianResident: string;
    homeAddressApartment: string;
    homeAddressAddressLine: string;
    homeAddressCity: string;
    homeAddressProvince: string;
    homeAddressPostalCode: string;
    mailingAddressApartment: string;
    mailingAddressAddressLine: string;
    mailingAddressCity: string;
    mailingAddressProvince: string;
    mailingAddressPostalCode: string;
    homeAddressCheckbox: boolean;
    mailingAddressCheckbox: boolean;
}

export enum ParamsMapping {
    Param1 = '',
    Param3 = 'homeAddressAddressLine',
    Param4 = 'homeAddressCity',
    Param5 = 'homeAddressProvince',
    Param6 = 'homeAddressPostalCode',
    Param9 = 'mailingAddressAddressLine',
    Param10 = 'mailingAddressCity',
    Param11 = 'mailingAddressProvince',
    Param12 = 'mailingAddressPostalCode',
    Param14 = 'email'
}
