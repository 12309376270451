import { Injectable } from '@angular/core';
import { AppStore } from '../../../../shared/models/app-store';
import { HttpService } from '../../../../core/services/http.service';
import { PageService } from '../../../../shared/services/page-service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");
@Injectable({
  providedIn: 'root'
})
export class OptionCentreService implements PageService {

  constructor(private appStore: AppStore, private httpService: HttpService) {
  }
  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return input;
  }

  getHttpOptionsWithToken() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
      })
    };
  }

  public retriveMarketMoversData(payload: any): Observable<any> {
    if ((window as any).mdsURL) {
      const url = (window as any).mdsURL + gatewayConfig.APIServices.retrieveTodaysMarketMovers.url;
      const body = JSON.stringify(payload);
      return this.httpService.postToExternalURL(url, body, this.getHttpOptionsWithToken()).pipe(timeout(60000)); 
    }
  }
}