import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { AccountHoldingsStore } from '../data/account-holdings-store';
import { NavParamService } from 'src/app/core/services/nav-param.service';
import { BuyingPowerReq } from '../data/account-holdings-page-data';

import { AppStore } from 'src/app/shared/models/app-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class AccountHoldingService implements PageService {

  constructor(private store: AccountHoldingsStore,
    private httpService: HttpService,
    private navParam: NavParamService,
    private appSore: AppStore) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    const ser = input[0];
    const req = ser.input;
    req.AccountNumberInfo.AccountPortfolioId = AccountHoldingsStore.params.accountNumber.AccountNumber ? AccountHoldingsStore.params.accountNumber.AccountNumber : '';
    req.AccountNumberInfo.Currency = AccountHoldingsStore.params.accountNumber.Currency ? AccountHoldingsStore.params.accountNumber.Currency : '';
    if (AccountHoldingsStore.params.accountNumber.AccountNumber === AccountHoldingsStore.params.accountNumber.SubAccountNumber || AccountHoldingsStore.params.accountNumber.SubAccountNumber === '') {
      req.AccountNumberInfo.AccountNumber = AccountHoldingsStore.params.accountNumber.AccountType ? AccountHoldingsStore.params.accountNumber.AccountType : ''
    } else {
      req.AccountNumberInfo.AccountNumber = AccountHoldingsStore.params.accountNumber.SubAccountNumber;
    }
    req.SelectedTab = this.appSore.lob === 'wg' ? 'L' : 'T';

    return input;
  }

  holdings(req, url) {
    return this.httpService.post(url, req);
  }

  holdingsMob(req, url, setParams) {
    return this.httpService.post(url, req, setParams);
  }

  token(req, tokenurl) {
    return this.httpService.post(tokenurl, req)
  }
  extendSession() {
    return this.httpService.post(gatewayConfig.APIServices.getSiteStateExtendSession.url, {});
  }

  renewToken() {
    return this.httpService.post(gatewayConfig.APIServices.chartsTokenReset.url, {});
  }
  getBuyingPower(data) {
    let req: BuyingPowerReq = new BuyingPowerReq();
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.buyingPowerRetrieve.url,
      req, { params: { skiploading: 'true' } }
    );
  }
  download(url: string, header) {
    return this.httpService.post(url, {}, header);
  }
}
