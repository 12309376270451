import { Injectable } from '@angular/core';
import { OtvcSecurityData } from './otvc-security-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class OtvcSecurityStore extends PageStore<OtvcSecurityData> {

    constructor() {
        super(new OtvcSecurityData());
    }

}
