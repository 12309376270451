<div class="marginTop5 paddingBottom5">
    <app-page-level-error aria-live="polite" [error]="unexpectedException" [mode]="'error'"> </app-page-level-error>
</div>
<form (ngSubmit)="verifyTradingPwd()" #tradingPasswordForm="ngForm">
    <div class="dis-flex marginTop20 tp-body">
        <label id="mltpinput-label" for="tradingPasswordInput" class="input-label">{{globalContent?.text?.tradingPasswordLabel}}</label>
        <div class="paddingLeft10 max-width66p">
            <input [id]="'tradingPasswordInput' + uniqueId"  tabindex="0" class="tp-width mlptpinputfieldm" (input)="input()"
                [(ngModel)]="tradingPassword" type="password" aria-labelledby="mltpinput-label" name="tradingPassword"
                aria-describedby="mlptradingPassword-error" autocomplete="off"
                [inputErrorDirective]="formError.tradingPassword" focusInputDirective>
                <app-field-level-error aria-live="polite" [error]="formError.tradingPassword" [Id]="'mlptradingPassword-error'">
                </app-field-level-error>
            <a href="javascript:void(0)" [id]="'forgetPass_id' + uniqueId" class="forgetPass" (click)="forgotPassword();closeMTPModal()">
                <span class="A14u">{{globalContent?.text.forgotTradingPassword}}</span>
                <span class="sr-only">{{globalContent?.text?.openIdentityModal}}</span>
            </a>
        </div>
    </div>
    <div class="marginTop20 tp-footer">
        <section class="float-left">
            <button type="button" [id]="'password_cancel_button_id' + uniqueId" class="button secondary" (click)="closeMTPModal()">
                {{globalContent?.text.cancelLabel}}
                <span class="sr-only">{{globalContent?.text?.closeModal}}</span>
            </button>
        </section>
        <section class="float-right">
            <button type="submit" [id]="'password_continue_button_id' + uniqueId" class="button primary">
                {{globalContent?.text.continueLabel}}
                <span class="sr-only">{{globalContent?.text?.OpenMarketLens}}</span>
            </button>
        </section>
    </div>
</form>

<app-otvc-prompt-modal [uniqueId]="otvcUniqueId" [otvcPromptData]="otvcPromptDataParam" [mode]="mode"
    [PromptContent]="otvcPromptContent" [verifyUrl]="verifyurl" [currentModule]="currentModule"
    (modalCancelRequest)="closeModal()">
</app-otvc-prompt-modal>