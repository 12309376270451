import { NgModule, ModuleWithProviders } from '@angular/core';
import { SymbolQuoteComponent } from './symbol-quote.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { PipeSharedModule } from '../../pipe-shared.module';
import { WidgetSharedModule } from '../../widget-shared.module';

@NgModule({
  declarations: [SymbolQuoteComponent],
  imports: [
    CommonModule,
    SharedModule,
    PipeSharedModule,
    WidgetSharedModule
  ],
  exports: [SymbolQuoteComponent],
  providers: []
})

export class SymbolQuoteModule {
}
