import { BaseData } from 'src/app/shared/models/base-data';

export class NicknameAccountsLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            nickNameList: []
        };
    }
}

export interface NicknameAccountsLandingInputs {
    nickNameList: any;
}