import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { PageService } from 'src/app/shared/services/page-service';

@Injectable({
  providedIn: 'root'
})
export class TransferCentralService implements PageService{

  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }
}
