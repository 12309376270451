
import { FormValidatorFactory } from '../../../shared/services/cibc.formvalidator';
import { isDefined } from 'src/app/shared/services/utils.service';
import { FormControl, FormGroup } from '@angular/forms';

export class OtvcVerifyValidator {

    static createForm(form, fb) {
        form = fb.group({
            verifyCode: [''],
            // selectDropdown: ['']
        });

        return form;
    }

    static setSendValidators(form) {
        form.controls['selectDropdown'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGOTV025')]);
    }

    static setVerifyValidators(form, modelContent = null) {
        if (modelContent) {
            form.controls['verifyCode'].setValidators([
                FormValidatorFactory.createStringNotEqualsValidator('MSGOTV013', modelContent.CIBCphonenumber),
                FormValidatorFactory.createStringNotEqualsValidator('MSGOTV013', modelContent.CIBCphonenumberINT),
                FormValidatorFactory.createNotBlankValidator('MSGCTP0001')
            ]);
        } else {
            form.controls['verifyCode'].setValidators([
                FormValidatorFactory.createNotBlankValidator('MSGCTP0001')
            ]);
        }
    }

    static setVerifyValue(form) {
        let val: any = {};
        val = {
            // verifyCode: form.value.verifyCode,
            'verifyCode': form.value.verifyCode || "",
            // 'selectDropdown': form.value.selectDropdown || ""

        }
        form.setValue(val);
    }

    static setSendValue(form) {
        let val: any = {};
        val = {
            // verifyCode: form.value.verifyCode,
            'selectDropdown': form.value.selectDropdown || "",
            // 'selectDropdown': form.value.selectDropdown || ""

        }
        form.setValue(val);
    }


    static resolveValidationError(form, errorContent) {
        const messges = [];
        Object.keys(form.controls).forEach(key => {
            messges.push(
                {
                    controlName: key,
                    code: form.controls[key].errors
                });
        });
        return this.setErrorMessage(messges, errorContent);
    }

    static resolveServerValidationError(form, errorMessage, field) {
        const error = {};
        const message = {
            message: errorMessage
        };
        if (field === 'verifyCode') {
            error['verifyCode'] = message;
        }
        return error;
    }


    static setErrorMessage(results, errorContent) {
        const error = {};
        results.forEach(result => {
            if (result.code !== null) {
                if (isDefined(result.code.errorCode)) {
                    error[result.controlName] = errorContent[result.code.errorCode];
                }
                console.log(JSON.stringify(result));
            }
        });
        return error;
    }

    static processRestFormErrors(formError) {
        const error = {};
        switch (formError.error.Category) {
            case 'verifyCode':
                error['verifyCode'] = formError.error.Errors[0].ErrorMessage;
                break;
        }
        return error;
    }

    static resetErrorMessage(controlName: any) {
        const messges = [];
        const error = {};
        if (typeof controlName === 'string') {
            error[controlName] = undefined;
            return error[controlName];

        } else {
            messges.push(
                {
                    controlName: 'verifyCode',
                    // code: controlName.controls.verifyCode.errors
                    code: controlName.controls['verifyCode'].errors
                });
            messges.forEach(result => {
                error[result.controlName] = undefined;

            });
            return error;
        }
    }
}
