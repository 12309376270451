import { Injectable } from '@angular/core';
import { CreateTradingPassConfirmData } from './create-trading-pass-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class CreateTradingPassConfirmStore extends PageStore<CreateTradingPassConfirmData> {

    constructor() {
        super(new CreateTradingPassConfirmData());
    }

}
