import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { SidebarLauncherComponent } from './sidebar-launcher.component';
import {SidebarItemModule} from "./sidebar-item/sidebar-item.module";
import { QuickTradeSharedModule } from 'src/app/shared/quick-stock-trade-shared.module';
import { ChatSharedModule } from 'src/app/shared/chat-shared.module';
import { QuickTradeOptionsSharedModule } from 'src/app/shared/quick-trade-options-shared.module';

@NgModule({
    declarations: [SidebarLauncherComponent],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        SidebarItemModule,
        QuickTradeSharedModule,
        ChatSharedModule,
        QuickTradeOptionsSharedModule
    ],
    exports: [
        SidebarLauncherComponent
    ]
})
export class SidebarLauncherModule { }
