<ng-container *ngIf="data && quickTradeContent.name == 'quicktradestockslanding'">
  <form #quickTradeStockForm="ngForm" (ngSubmit)="nextdr()" id="{{'overlayContent'+uniqueName}}"
    class="overlay-content">
    <app-global-refresh *ngIf="mode == 'quickTrade'" type="refresh" [name]="'quickTrade'"></app-global-refresh>
    <div class="overlay-body" id="overlay-body{{uniqueName}}">
      <div class="overlay-container">

        <div id="quick_trade_errorCode" class="page-top-invisible-divider -ms-page-top-divider-two-col error-alert error-section">
          <custom-app-error (formErrorPass)="handleRunTimeErrors($event)" [identifier]="'custom-error'+uniqueName" (errorAlert)="logError($event)">
          </custom-app-error>
          <app-error *ngIf="mode != 'quickTrade'" (formErrorPass)="handleRunTimeErrors($event)" (errorAlert)="logError($event)"></app-error>
        </div>

        <div class="hidden-accessibility-container">
          <app-field-level-error-global-message [errorList]="formError"
            [identifier]="'field-level-global-qt'+uniqueName">
          </app-field-level-error-global-message>
        </div>

        <div class="trade-background">
          <div class="container">
            <div class="form-bright-zebra paddingTop0 mobileview">
              <div class="col col-100 col-100-small account">
                <label id="{{'account-label-0-qt'+uniqueName}}" tabindex="-1" class="field-label sr-only">
                  {{quickTradeContent.text.account}}
                </label>
                <div *ngIf="!singleAccount; else hasSignleAccount">
                  <div class="accountAreaRow">
                    <div id="quick_trade_accountNumber" class="accountLeftCol">
                      <input type='hidden' #accountNumber="ngModel" name="accountNumber"
                        [(ngModel)]='inputs.accountIndex'>
                      <app-account-selector-dropdown-trade-drawer *ngIf="loadAccountSelector"
                        [paramAccountList]="accountList" [paramPageContent]="quickTradeContent"
                        [paramSelectedAccountNumber]="selectedAccount" (selectedAccount)="AccountListReturn($event)"
                        [error]="formError.accountNumber" [chooseOne]="inputs.accountIndex == -1" [mode]="'quicktrade'"
                        [id]="uniqueName" [styling]="'quickTrade'">
                      </app-account-selector-dropdown-trade-drawer>
                    </div>
                    <div id="quick_trade_accountNumber2" class="accountRightCol">
                      <app-tooltip [id]="'tooltipmarketBox1'+uniqueName" [tooltipContent]="accountToolTip"
                        openIconName="icon-Info-Circle" [mode]="'modal-soft'"
                        (clickTooltip)="tooltipClicked(quickTradeContent.text.accountTooltipTitle)" [largeHitBox]="true"
                        targetEle="{{'account-label-0-qt'+uniqueName}}">
                        <div id="quick_trade_tooltip1" class="tooltip-lightbox">
                          <p>
                            {{quickTradeContent.list.accountTooltip.line1}}
                          </p>
                          <p class="bb-2">
                            <strong>{{quickTradeContent.list.accountTooltip.line2bold}}</strong>
                            {{quickTradeContent.list.accountTooltip.line2}}
                          </p>
                          <p>
                            <strong>{{quickTradeContent.list.accountTooltip.line3bold}}</strong>
                            {{quickTradeContent.list.accountTooltip.line3}}
                          </p>
                        </div>
                      </app-tooltip>
                    </div>
                  </div>
                  <div *ngIf="this.inputs.accountIndex !== -1" class="accountAreaRow">
                    <div class="accountLeftCol">
                      <ng-container *ngIf="!buyingPowerLoading && !balanceLoading">
                        <div id="quick_trade_buying"  class="buying" *ngIf="this.inputs.accountIndex !== -1">
                          <div class="buying-po">
                            <div class="col share paddingRight5 color02" for="{{'buyingPowerTooltip'+uniqueName}}">
                              <label class="buying-power-text"
                                *ngIf="accountList[this.inputs.accountIndex]?.isRegistered">
                                {{quickTradeContent.list.trailingTooltipList.buyingPowerText}}</label>
                              <label class="buying-power-text"
                                *ngIf="!accountList[this.inputs.accountIndex]?.isRegistered">
                                {{quickTradeContent.list.trailingTooltipList.combinedBuyingPower}}</label>
                            </div>
                          </div>
                          <ng-container *ngIf="accountList[this.inputs.accountIndex]?.isRegistered">
                            <div class="buying-po"
                              *ngIf="accountList[this.inputs.accountIndex]?.BuyingPowerCAD && settleAmountCurrency == 'CAD'">
                              <span [innerHtml]="accountList[this.inputs.accountIndex]?.BuyingPowerCAD.replace('
                                                 $', '' ).replace('$', '' ) |
                                                 currencyFormatter:settleAmountCurrency:'format-currency-sr'"></span>
                            </div>
                            <div class="buying-po"
                              *ngIf="accountList[this.inputs.accountIndex]?.BuyingPowerUSD && settleAmountCurrency == 'USD'">
                              <span
                                [innerHtml]="accountList[this.inputs.accountIndex]?.BuyingPowerUSD.replace('
                                                      $', '' ).replace('$', '' ) |
                                                      currencyFormatter:settleAmountCurrency:'format-currency-sr'"></span>

                            </div>
                          </ng-container>
                          <ng-container *ngIf="!accountList[this.inputs.accountIndex]?.isRegistered">
                            <div class="buying-po"
                              *ngIf="accountList[this.inputs.accountIndex]?.ConsolidatedBuyingPower">
                              <span [innerHtml]="accountList[this.inputs.accountIndex]?.ConsolidatedBuyingPower.replace('
                                                      $', '' ).replace('$', '' ) |
                                                      currencyFormatter:'CAD':'format-currency-sr'"></span>
                            </div>
                          </ng-container>
                        </div>
                        <div id="quick_trade_cashing" class="cashing" *ngIf="this.inputs.accountIndex !== -1">
                          <div class="cash-bl">
                            {{quickTradeContent.text.cashBalance}}
                          </div>
                          <div class="cash-bl" *ngIf="accountList[this.inputs.accountIndex]?.accountBalanceAmount">
                            <span
                              [innerHtml]="accountList[this.inputs.accountIndex]?.accountBalanceAmount.replace(' $', '').replace('$', '') | currencyFormatter:settleAmountCurrency:'format-currency-sr'"></span>
                          </div>
                        </div>
                      </ng-container>
                      <div id="quick_trade_buyingPowerLoading" *ngIf="buyingPowerLoading || balanceLoading" aria-hidden="true">
                        <div class="new-skeleton-row">
                          <div class="new-skeleton-value-title halved skeleton-animation">
                            <div></div>
                            <div></div>
                          </div>
                          <div class="new-skeleton-value halved skeleton-animation">
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="quick_trade_buyingPowertooltip" class="accountRightCol">
                      <app-tooltip *ngIf="quickTradeContent && !buyingPowerLoading && !balanceLoading"
                        [id]="'buyingPowerTooltip'+uniqueName" [tooltipContent]="buyingPowerToolTip"
                        openIconName="icon-Info-Circle"
                        (clickTooltip)="tooltipClicked(quickTradeContent.list.trailingTooltipList.buyingPowerText)"
                        [mode]="'modal-soft'" [largeHitBox]="true" targetEle="{{'buyingPowerTooltip'+uniqueName}}">
                        <div id="quick_trade_tooltip2" class="tooltip-lightbox">
                          <p>
                            {{quickTradeContent.list.trailingTooltipList.buyingPowerTooltipPara1}}
                          </p>
                          <h3>
                            {{quickTradeContent.list.trailingTooltipList.buyingPowerTooltipSubheading1}}
                          </h3>
                          <div class="box-buying-power">
                            <ul>
                              <li class="bb-3 paddingBottom8">
                                {{quickTradeContent.list.trailingTooltipList.cashBalances}}
                              </li>
                              <li class="bb-3 paddingTop8 paddingBottom8">
                                {{quickTradeContent.list.trailingTooltipList.marginableLoanValueIfHeld}}
                              </li>
                              <li class="bb-3 paddingTop8 paddingBottom8">
                                {{quickTradeContent.list.trailingTooltipList.pendingCashTransfersOut}}
                              </li>
                              <li class="bb-3 paddingTop8 paddingBottom8">
                                {{quickTradeContent.list.trailingTooltipList.openOrdersDecreaseBuyingPower}}
                              </li>
                              <li class="paddingTop8">
                                {{quickTradeContent.list.trailingTooltipList.filledOrdersPriorSettlement}}
                              </li>
                            </ul>
                          </div>
                          <p>
                            {{quickTradeContent.list.trailingTooltipList.buyingPowerTooltipPara2}}
                          </p>
                          <p>
                            {{quickTradeContent.list.trailingTooltipList.buyingPowerTooltipPara3}}
                          </p>
                          <a href="javascript:void(0);"
                            (click)="openWindow(quickTradeContent.list.trailingTooltipList.buyingPowerTooltipLinkURL)">{{quickTradeContent.list.trailingTooltipList.buyingPowerTooltipLink}}
                          </a>
                        </div>
                      </app-tooltip>
                    </div>
                  </div>


                  <app-field-level-error [error]="formError.accountNumber"
                    [Id]="'error-accountNumber-quick-trade'+uniqueName">
                  </app-field-level-error>
                </div>
                <ng-template #hasSignleAccount>
                  <div class='select-item field-input'>
                    <span
                      class="one-account uppercase">{{accountList[0].AccountTypeDesc}}{{accountList[0].AccountNumber}}</span>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="form-bright-zebra" id="quick-trade-scroll-pos-1">
              <div class="col col-100 col-100-small account">
                <div id="quick_trade_action" class="padding-bottom">
                  <fieldset class="actionToggleGroup">
                    <label id="{{'quick-trade-action-label'+uniqueName}}" tabindex="-1" class="sr-only">
                      {{quickTradeContent.text.action}}
                    </label>
                    <div [ngClass]="hasBuyToCover ? 'buyToCoverAction': ''">
                      <app-connected-radio [contentArray]="actionArray" [(selectedValue)]="inputs.action"
                        [uniqueName]="'QuickTradeActionToggle'+uniqueName"
                        (change)="changeActionMode(inputs.action, true)"
                        [labelledbyID]="'quick-trade-action-label'+uniqueName"></app-connected-radio>
                    </div>
                    <div *ngIf="hasBuyToCover" class="buyToCoverActionTooltip">
                      <ng-container *ngIf="inputs.action == 'BUYTOCOVER'; else elseTemplate">
                        <app-tooltip [id]="'tooltipmarketBox2'+uniqueName" [tooltipContent]="buyToCoverToolTip"
                          openIconName="icon-Info-Circle" [mode]="'modal-soft'"
                          (clickTooltip)="tooltipClicked(globalContent.text.buyToCover)" [largeHitBox]="true"
                          targetEle="{{'tooltipmarketBox2'+uniqueName}}">
                          <div id="quick_trade_tooltip3" class="tooltip-lightbox">
                            <p>
                              {{quickTradeContent.text.buyToCoverToolTip}}
                            </p>
                          </div>
                        </app-tooltip>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <app-tooltip *ngIf="inputs.action == 'SHORT'" [id]="'tooltipmarketBox3'+uniqueName"
                          [tooltipContent]="shortSellToolTip" openIconName="icon-Info-Circle" [mode]="'modal-soft'"
                          (clickTooltip)="tooltipClicked(globalContent.text.shortSell)" [largeHitBox]="true"
                          targetEle="{{'tooltipmarketBox2'+uniqueName}}">
                          <div id="quick_trade_tooltip4" class="tooltip-lightbox paddingTop16">
                            <div>
                              <p><span [innerHTML]="quickTradeContent.text.shortSellToolTip"></span></p>
                              <a href="javascript:void(0);" id="quick_trade_shortSell_link"
                                (click)="openWindow(quickTradeContent.text.shortSellTooltipLink)">{{quickTradeContent.text.shortSellLinkText}}.
                                <span class='sr-only'>{{quickTradeContent.text.newWindowText}}</span></a>
                            </div>
                          </div>
                        </app-tooltip>
                      </ng-template>
                    </div>
                  </fieldset>
                  <div>
                    <input type="hidden" #actionMode="ngModel" name="actionMode" [(ngModel)]='inputs.action'>
                    <input type="hidden" #expiryMode="ngModel" name="expiryMode" [(ngModel)]='inputs.expiryMode'>
                  </div>
                </div>

                <div class="sr-only">
                  <label id="{{'symbol-label-qt'+uniqueName}}"
                    *ngIf="stockHoldings.length == 0 || !chooseSymbol">{{quickTradeContent.text.symbol}}</label>
                  <label id="{{'symbol-label-qt'+uniqueName}}"
                    *ngIf="stockHoldings.length > 0 && chooseSymbol">{{quickTradeContent.text.security}}</label>
                </div>
                <div id="quick_trade_predictiveSearch" class="field-input field-predictive-search"
                  [ngClass]="inputs.action == 'BUY' || inputs.action == 'SHORT' || inputs.action == '' || symbolLoading || chooseSymbol ? 'marginBottom0' : ''">
                  <app-predictive-search [placeholder]="quickTradeContent.text.searchByCompany" [query]="query"
                    [contents]='quickTradeContent' [pagename]="'TradeStocks'" [styling]="'quickTrade'"
                    (selectedSymbol)="updateSymbol($event)" (changeQuery)="ChangeSymbolName($event)"
                    [formError]="formError.symbol" [labelledbyAttr]="'symbol-label-qt'+uniqueName"
                    [describedbyAttr]="'symbol-error-qt'+uniqueName"
                    [ngClass]="inputs.action == 'BUY' || inputs.action == 'SHORT' || inputs.action == '' || symbolLoading || chooseSymbol ? 'hiddenControl' : ''">
                  </app-predictive-search>
                  <input type='hidden' (keydown)="clearManualQuote($event)" [(ngModel)]='inputs.symbol'
                    #symbol="ngModel" name="symbol" id="{{'stockHoldingInput-qt'+uniqueName}}"
                    [inputErrorDirective]="formError.symbol" aria-labelledby="{{'symbol-label-qt'+uniqueName}}"
                    [attr.aria-describedby]="inputs.action == 'BUY' || inputs.action == 'SHORT' || inputs.action == '' || symbolLoading || chooseSymbol ? null : ('symbol-error-qt'+uniqueName)"
                    [ngClass]="inputs.action == 'BUY' || inputs.action == 'SHORT' || inputs.action == '' || symbolLoading || chooseSymbol  ? 'hiddenControl soft' : ' soft'">
                  <app-field-level-error
                    *ngIf="inputs.action != 'BUY' && inputs.action != 'SHORT' && inputs.action != '' && !symbolLoading && !chooseSymbol"
                    [error]="formError.symbol" [Id]="'symbol-error-qt'+uniqueName"></app-field-level-error>
                  <button *ngIf="stockHoldings.length > 0 && !chooseSymbol" class="choose-symbol-toggle color02"
                    type="button" (click)="toggleHoldingChoose()">{{quickTradeContent.text.symbolDropdown}}</button>
                </div>

                <div id="quick_trade_symbolLoading" class="field-input symbolLoadingBar" *ngIf='symbolLoading' aria-hidden="true">
                  <div class="symbolLoadingSpinnerContainer">
                    <app-general-spinner></app-general-spinner>
                  </div>
                </div>
                <div id="quick_trade_predictiveSearch2" class="field-input field-predictive-search"
                  *ngIf="(inputs.action=='BUY' || inputs.action=='SHORT' || inputs.action=='' ) && !symbolLoading">
                  <app-predictive-search [placeholder]="quickTradeContent.text.searchByCompany" [query]="query"
                    [contents]='quickTradeContent' [pagename]="'TradeStocks'" [styling]="'quickTrade'"
                    [labelledbyAttr]="'symbol-label-qt'+uniqueName" [describedbyAttr]="'symbol-error-qt'+uniqueName"
                    (selectedSymbol)="updateSymbol($event)" (changeQuery)="ChangeSymbolName($event)"
                    [formError]="formError.symbol || formError.marketMode">
                  </app-predictive-search>
                  <app-field-level-error [error]="formError.symbol || formError.marketMode" [Id]="'symbol-error-qt'+uniqueName">
                  </app-field-level-error>
                </div>
                <div id="quick_trade_accountDropdown" [hidden]="stockHoldings.length < 1 || symbolLoading || !chooseSymbol ? true : null"
                  class="select-item field-input">
                  <app-dropdown-list *ngIf="this.stockHoldings.length > 0" [id]="'stockHoldingDropdown-qt'+uniqueName" [paramOptions]="stockHoldings"
                    (selectedAccount)="updateSelectedHolding($event)" [paramPageContent]="quickTradeContent"
                    [chooseOne]="inputs.stockHoldingIndex === -1" [paramSelectedIndex]="inputs.stockHoldingIndex"
                    [mode]="'holdings'" [style]="'soft'" [formError]="formError.symbol">
                  </app-dropdown-list>

                  <input type='hidden' #stockHoldingIndex="ngModel" name="stockHoldingIndex"
                    [(ngModel)]='inputs.stockHoldingIndex'>
                  <app-field-level-error [error]="formError.stockHoldingIndex " [Id]="'holding-error-qt'+uniqueName">
                  </app-field-level-error>
                </div>

                <div *ngIf="errorQuote && !formError.symbol">
                  <app-symbol-quote [titleId]="'redirectTargetQuote1'"
                    [mode]="this.appStore.lob == LOB.IE ? 'new-full' : 'full-large'" [contents]="quoteContent"
                    [selectedSymbol]="selectedSymbol" [(quote)]="quote" [orderType]="inputs.action"
                    (errorCallback)="recieveSymbolError($event)" (quoteCallBack)="getQuote($event)"
                    [fromQuickTrade]="true">
                  </app-symbol-quote>
                </div>
                <!-- Quote details -->
                <div *ngIf="quoteFromChildComponent">
                  <div class="security-details" *ngIf="!loadingQuote">
                    <div id="quick_trade_currencyFlag" class="title-bar">
                      <div> <img *ngIf="quoteFromChildComponent.Symbol?.Market === 'US'; else CAFlag"
                          src="../../../../../assets/images/icons/icon-USD-flag.svg" alt=""
                          class="flag paddingRight8" />
                        <ng-template #CAFlag>
                          <img src="assets/images/icons/icon-CDN-flag.svg" alt="" class="flag paddingRight8" />
                        </ng-template>
                      </div>
                      <span class="new-symbol-name" *ngIf="quoteFromChildComponent.Symbol"
                        [ngClass]="quoteFromChildComponent.Symbol.SymbolLongName.length > 48 ? 'new-symbol-small-name' : ''">
                        {{quoteFromChildComponent.Symbol.SymbolLongName}} </span>
                    </div>
                    <button id="quick_trade_lastPrice" type="button" (click)="selectLimit(quoteFromChildComponent.LastPrice)"
                      class="new-symbol-price-change">
                      <span *ngIf="!quoteFromChildComponent.LastPrice" class="new-symbol-price">-</span>
                      <div *ngIf="quoteFromChildComponent.LastPrice" class="new-symbol-price"
                        [innerHtml]="removeDollarSign(quoteFromChildComponent.LastPrice.Content) | currencyFormatter:quoteFromChildComponent.Currency:'format-symboless-sr'">
                      </div>
                      <div class="new-symbol-currency" aria-hidden="true">
                        {{quoteFromChildComponent.Currency}}
                      </div>
                      <div class="new-symbol-change"
                        [ngClass]="{ backgroundUp : quoteFromChildComponent.ChangeIndicator == 'U', backgroundDown : quoteFromChildComponent.ChangeIndicator == 'D' }">
                        <span
                          [innerHtml]="(valueOrDashDataLang(quoteFromChildComponent.PriceChange) + ' (' + valueOrDash(quoteFromChildComponent.PriceChangePercent) + ')') | changeFormatter:quoteFromChildComponent.ChangeIndicator:'wedge'"></span>
                      </div>
                    </button>
                    <div class="divTable">
                      <div class="divTableBody">
                        <div class="table-row paddingTop8">
                          <button id="quick_trade_bidShares" (click)="selectLimit(quoteFromChildComponent.BidPrice)" type="button"
                            class="divTableRow bidContainer">
                            <div class="nondivTableCell fix-width bid-ask-title">
                              {{quickTradeContent.text.bidShares}}
                            </div>
                            <div class="nondivTableCell fix-width price-bid-ask">
                              <span *ngIf="!quoteFromChildComponent.BidPrice">-</span>
                              <span *ngIf="quoteFromChildComponent.BidPrice"
                                [innerHtml]="removeDollarSign(quoteFromChildComponent.BidPrice.Content) | currencyFormatter:quoteFromChildComponent.Currency:'format-symboless-sr'"></span>
                              &nbsp;/&nbsp;
                              {{addCommas(valueOrDashLots(quoteFromChildComponent.BidSize, quoteFromChildComponent.LotSize))}}
                            </div>
                          </button>
                          <button id="quick_trade_askShares" (click)="selectLimit(quoteFromChildComponent.AskPrice)" type="button"
                            class="divTableRow askContainer">
                            <div class="nondivTableCell bid-ask-title">
                              {{quickTradeContent.text.askShares}}</div>
                            <div class="nondivTableCell price-bid-ask">
                              <span *ngIf="!quoteFromChildComponent.AskPrice">-</span>
                              <span *ngIf="quoteFromChildComponent.AskPrice"
                                [innerHtml]="removeDollarSign(quoteFromChildComponent.AskPrice.Content) | currencyFormatter:quoteFromChildComponent.Currency:'format-symboless-sr'"></span>
                              &nbsp;/&nbsp;
                              {{addCommas(valueOrDashLots(quoteFromChildComponent.AskSize, quoteFromChildComponent.LotSize))}}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="quote-toggle-{{isQuoteExpanded}}"
                      [attr.aria-hidden]="isQuoteExpanded == 2 ? null : true">
                      <div class="divTable">
                        <div class="divTableBody">
                          <div class="table-row">
                            <div id="quick_trade_volume" class="divTableRow">
                              <div class="nondivTableCell expanded-quote-sub-heading">
                                {{quickTradeContent.text.Volume}}
                              </div>
                              <div class="nondivTableCell expanded-quote-sub-value">
                                {{addCommas(valueOrDashData(quoteFromChildComponent.Volume))}}
                              </div>
                            </div>
                            <div id="quick_trade_open" class="divTableRow">
                              <div class="nondivTableCell expanded-quote-sub-heading">
                                {{quickTradeContent.text.Open}}
                              </div>
                              <div class="nondivTableCell expanded-quote-sub-value">
                                {{valueOrDashContent(quoteFromChildComponent.Open)}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="divTable">
                        <div class=" divTableBody">
                          <div class="table-row">
                            <div id="quick_trade_dayRange" class="divTableRow">
                              <div class="nondivTableCell expanded-quote-sub-heading">
                                {{quickTradeContent.text.DayRange}}
                              </div>
                              <div class="nondivTableCell expanded-quote-sub-value">
                                {{valueOrDashContent(quoteFromChildComponent.Low)}}
                                -
                                {{valueOrDashContent(quoteFromChildComponent.High)}}
                              </div>
                            </div>
                            <div id="quick_trade_week52range" class="divTableRow">
                              <div class="nondivTableCell expanded-quote-sub-heading">
                                {{quickTradeContent.text.Week52Range}}
                              </div>
                              <div class="nondivTableCell expanded-quote-sub-value">
                                {{valueOrDashContent(quoteFromChildComponent.Week52Low)}}
                                -
                                {{valueOrDashContent(quoteFromChildComponent.Week52High)}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button id="quick_trade_expandStatsBtn" type="button" *ngIf="isQuoteExpanded == 0" (click)="handleQuoteExpansion(true)"
                      class="quote-toggle-button">
                      <span class="quote-toggle-text">{{quickTradeContent.text.expandStats}}</span>
                      <span class="sr-only">{{quickTradeContent.text.expandStatsSR}}</span>
                      <span aria-hidden="true" class="down-triangle"></span>
                    </button>
                    <button id="quick_trade_hideStatsBtn" type="button" *ngIf="isQuoteExpanded != 0" (click)="handleQuoteExpansion(false)"
                      class="quote-toggle-button">
                      <span class="quote-toggle-text">{{quickTradeContent.text.hideStats}}</span>
                      <span class="sr-only">{{quickTradeContent.text.hideStatsSR}}</span>
                      <span aria-hidden="true" class="up-triangle"></span>
                    </button>
                  </div>
                </div>
                <div id="quick_trade_quoteLoading" *ngIf="loadingQuote" class="quote-skeleton">
                  <div class="new-skeleton-name skeleton-animation"></div>
                  <div class="new-skeleton-price skeleton-animation"></div>
                  <ng-container *ngIf="isQuoteExpanded != 0">
                    <div class="new-skeleton-row">
                      <div class="new-skeleton-combined halved skeleton-animation">
                        <div></div>
                      </div>
                    </div>
                    <div class="new-skeleton-row">
                      <div class="new-skeleton-combined-big halved skeleton-animation">
                        <div></div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div>
                <input type="hidden" #marketMode="ngModel" name="marketMode" [(ngModel)]='inputs.market'>
              </div>
            </div>
          </div>
        </div>
        <div class="order-background">
          <div class="container">
            <div class="form-bright-zebra">
              <div class="col col-100 col-100-small account">
                <fieldset class="order-cont">
                  <div id="quick_trade_price" class="order-legend">
                    <legend>
                      <div class="order-price-style">
                        <label class="field-label col share" id="{{'price'+uniqueName}}"
                          tabindex="-1">{{quickTradeContent.text.price}}</label>
                      </div>
                    </legend>
                    <span>
                      <app-tooltip *ngIf="inputs.priceMode != ''" [id]="'tooltipmarketBox4'+uniqueName"
                        [tooltipContent]="priceToolTip" openIconName="icon-Info-Circle" [mode]="'modal-soft'"
                        (clickTooltip)="tooltipClicked(quickTradeContent.text.price)" [largeHitBox]="true"
                        targetEle="{{'price'+uniqueName}}">
                        <div id="quick_trade_tooltip5" class="tooltip-lightbox">
                          <p class="bb-2">
                            <strong>{{quickTradeContent.text.marketPriceTitle}}</strong>{{quickTradeContent.text.marketPriceDesc}}
                          </p>
                          <p class="bb-2">
                            <strong>{{quickTradeContent.text.limitPriceTitle}}</strong>{{quickTradeContent.text.limitPriceTitleDesc}}
                          </p>
                          <p class="marginBottom8">
                            <strong>{{quickTradeContent.text.stopLimitTitle}}</strong><span [innerHTML]="quickTradeContent.text.stopLimitTitleDesc"></span>
                          </p>
                          <ul class='tooltipList bb-2'>
                            <li>
                              <span class="tooltips-style-text"
                                [innerHTML]="quickTradeContent.text.stopLimitBuyOrders"></span>

                            </li>
                            <li class="marginBottom8">
                              <span class="tooltips-style-text"
                                [innerHTML]="quickTradeContent.text.stopLimitSellOrders"></span>

                            </li>
                            <li class="marginBottom8">
                              <span class="tooltips-style-text"
                                [innerHTML]="quickTradeContent.text.stopLimitOrders"></span>

                            </li>
                          </ul>
                          <p>
                            <strong>{{quickTradeContent.text.trailingStopLimitTitle}}</strong><span [innerHTML]="quickTradeContent.text.trailingStopLimitTitleDesc"></span>
                          </p>
                          <a href="javascript:void(0);"
                            (click)="openWindow(quickTradeContent.list.trailingTooltipList.l6_url)">{{quickTradeContent.list.trailingTooltipList.l6}}
                          </a>
                        </div>
                      </app-tooltip>
                    </span>

                  </div>

                  <div id="quick_trade_priceMode" class="order-type-box">
                    <app-connected-radio [contentArray]="priceArray" [(selectedValue)]="inputs.priceMode"
                      [uniqueName]="'QuickTradePriceToggle'+uniqueName"
                      (change)="changePriceMode(inputs.priceMode, true)" [labelledbyID]="'price'+uniqueName"
                      [dynamicMode]="true"></app-connected-radio>
                    <input type='hidden' #priceMode="ngModel" name="priceMode" [(ngModel)]='inputs.priceMode'>
                    <app-field-level-error [error]="formError.priceMode" [Id]="'price-error-qt'+uniqueName">
                    </app-field-level-error>
                  </div>

                  <!-- Limit mode -->
                  <div class="price-subfields" *ngIf="inputs.priceMode === 'limitMode'">
                    <div class="label-overlay-input">
                      <div>
                        <label for="{{'limit-input'+uniqueName}}">{{quickTradeContent.text.priceLimit}}</label>
                        <input [(ngModel)]="inputs.limit" id="{{'limit-input'+uniqueName}}" type="number"
                          autocomplete='off' (input)="changeLimitInput()"
                          aria-describedby="{{'limit-error'+uniqueName}}" [appMaxlength]='10' [isNumRegExApply]='true'
                          maxlength='10' #limitInput="ngModel" name="limitInput"
                          [inputErrorDirective]="formError.limitInput" class="soft"
                          [placeholder]="quickTradeContent.text.numberPlaceholder">
                      </div>
                      <app-field-level-error [error]="formError.limitInput" [Id]="'limit-error'+uniqueName"
                        class="err-small-290">
                      </app-field-level-error>
                    </div>
                  </div>

                  <!-- Stop Mode -->
                  <div class="price-subfields" *ngIf="inputs.priceMode === 'stopMode'">
                    <div class="label-overlay-input marginBottom8">
                      <div>
                        <label for="{{'stop-price-input'+uniqueName}}">{{quickTradeContent.text.stop}}</label>
                        <input [(ngModel)]="inputs.stopPrice" id="{{'stop-price-input'+uniqueName}}"
                          type="number" autocomplete='off' (input)="changeStopPrice()"
                          aria-describedby="{{'stop-price-error'+uniqueName}}" [appMaxlength]='10'
                          [isNumRegExApply]='true' maxlength='10' #stopPrice="ngModel" name="stopPrice"
                          [inputErrorDirective]="formError.stopPrice" class="soft"
                          [placeholder]="quickTradeContent.text.numberPlaceholder">
                      </div>
                      <app-field-level-error [error]="formError.stopPrice" [Id]="'stop-price-error'+uniqueName"
                        class="err-small-290">
                      </app-field-level-error>
                    </div>
                    <div class="label-overlay-input">
                      <div>
                        <label for="{{'stop-limit-input'+uniqueName}}">{{quickTradeContent.text.stopLimit}}</label>
                        <input [(ngModel)]="inputs.stopLimit" id="{{'stop-limit-input'+uniqueName}}"
                          aria-describedby="{{'stop-limit-error'+uniqueName}}" type="number"
                          [appMaxlength]='10' [isNumRegExApply]='true' maxlength='10' formnovalidate="formnovalidate"
                          autocomplete='off' (input)="changeStopLimitPrice()" #stopLimitPrice="ngModel"
                          name="stopLimitPrice" [inputErrorDirective]="formError.stopLimitPrice" class="soft"
                          [placeholder]="quickTradeContent.text.numberPlaceholder">
                      </div>
                      <app-field-level-error [error]="formError.stopLimitPrice" [Id]="'stop-limit-error'+uniqueName"
                        class="err-small-290">
                      </app-field-level-error>
                    </div>
                  </div>

                  <!-- Trailing Stop Dollar Mode -->
                  <div *ngIf="inputs.priceMode === 'trailingStopMode'" class="trailing-stop">
                    <div class="dtl-tgl-btn">
                      <span class="group-btn">
                        <button id="quick_trade_trailingDollar" type="button" [attr.aria-label]="'$'" (click)="handleTrailingStopToggleButton('dollar')"
                          [ngClass]="inputs.trailingMode == 'dollar' ? 'gb-item selected' : 'gb-item unselected'">
                          $
                        </button>
                        <button id="quick_trade_trailingPercent" type="button" [attr.aria-label]="'%'"
                          (click)="handleTrailingStopToggleButton('percent')"
                          [ngClass]="inputs.trailingMode == 'percent' ? 'gb-item selected' : 'gb-item unselected'">
                          %
                        </button>
                        <div class="gb-selector"
                          [ngClass]="inputs.trailingMode == 'dollar' ? 'first-pos' : 'second-pos'"></div>
                      </span>
                    </div>
                    <div class="trigger-delta-box" *ngIf="inputs.trailingMode == 'dollar'; else TrailingStopPercent">
                      <div class="field-input">
                        <div class="field-and-tooltip">
                          <div class="label-overlay-input field-and-tooltip-left">
                            <label
                              for="{{'trigger-delta-dollar-input'+uniqueName}}">{{quickTradeContent.text.triggerDelta}}</label>
                            <input [(ngModel)]="inputs.triggerDollarPrice"
                              id="{{'trigger-delta-dollar-input'+uniqueName}}"
                              aria-describedby="{{'estimated-trigger-price-label'+uniqueName + ' trigger-delta-dollar-error'+uniqueName}}"
                              type="number" autocomplete='off' formnovalidate="formnovalidate"
                              (input)="changeTrailingdollarPrice()" #triggerDollarPrice="ngModel"
                              name="triggerDollarPrice" [appMaxlength]='10' [isNumRegExApply]='true' maxlength='10'
                              [inputErrorDirective]="formError.triggerDollarPrice" class="soft"
                              [placeholder]="quickTradeContent.text.numberPlaceholder">
                          </div>
                          <app-tooltip [id]="'triggerToolTipQuickTrade'+uniqueName" [tooltipContent]="triggerDeltaToolTip"
                            openIconName="icon-Info-Circle" [mode]="'modal-soft'"
                            (clickTooltip)="tooltipClicked (quickTradeContent.text.triggerDelta)" [largeHitBox]="true"
                            targetEle="{{'trigger-delta-dollar-input'+uniqueName}}">
                            <div id="quick_trade_tooltip6" class="tooltip-lightbox">
                              <p>
                                {{quickTradeContent.list.trailingTooltipList.triggerTooltip1}}
                              </p>
                              <a id="quick_trade_trailing_learnmore" *ngIf="!appStore.isApp()" href="javascript:void(0);"
                                (click)="IRPriceTooltipLink()">{{quickTradeContent.list.trailingTooltipList.learnMore}}
                                {{quickTradeContent.list.trailingTooltipList.triggerTooltip2}}<span
                                  class='sr-only'>{{quickTradeContent.text.newWindowText}}</span></a>
                              <a id="quick_trade_trailing_leanMore2" *ngIf="appStore.isApp()" href="javascript:void(0);" (click)="IRPriceTooltipLink()"
                                [target]="undefined"
                                routerLink='/txn/help'>{{quickTradeContent.list.trailingTooltipList.learnMore}}
                                {{quickTradeContent.list.trailingTooltipList.triggerTooltip2}}<span
                                  class='sr-only'>{{quickTradeContent.text.newWindowText}}</span></a>
                            </div>
                          </app-tooltip>
                        </div>
                        <div id="quick_trade_triggerPrice" class="field-desc">
                          <div class="trigger-label" id="{{'estimated-trigger-price-label'+uniqueName}}">
                            {{quickTradeContent.text.estimatedTriggerPrice}}
                            <span *ngIf="isNum(estimatedTriggerPrice)" class='nowrap'>
                              {{estimatedTriggerPrice |
                              currency:'CAD':'symbol-narrow':'1.1-4':this.appStore.lang}}
                            </span>
                            <span *ngIf="!isNum(estimatedTriggerPrice)" class='nowrap'>
                              {{estimatedTriggerPrice}}
                            </span>
                          </div>
                        </div>
                        <app-field-level-error [error]="formError.triggerDollarPrice"
                          [Id]="'trigger-delta-dollar-error'+uniqueName" class="err-small-290">
                        </app-field-level-error>
                      </div>
                    </div>

                    <ng-template #TrailingStopPercent>
                      <div class="trigger-delta-box">
                        <div class="field-input">
                          <div class="field-and-tooltip">
                            <div class="label-overlay-input field-and-tooltip-left">
                              <label
                                for="{{'trigger-delta-percent-input'+uniqueName}}">{{quickTradeContent.text.triggerDeltaPercent}}</label>
                              <input [(ngModel)]="inputs.triggerPercentagePrice"
                                id="{{'trigger-delta-percent-input'+uniqueName}}"
                                aria-describedby="{{'estimated-trigger-price-label'+uniqueName + ' trigger-delta-percent-error'+uniqueName}}"
                                type="number" autocomplete='off' formnovalidate="formnovalidate"
                                (input)="changeTrailingPercentagerPrice()" [appMaxlength]='10' [isNumRegExApply]='true'
                                maxlength='10' #triggerPercentagePrice="ngModel" name="triggerPercentagePrice"
                                [inputErrorDirective]="formError.triggerPercentagePrice" class="soft"
                                [placeholder]="quickTradeContent.text.numberPlaceholder">
                            </div>
                            <app-tooltip [id]="'triggerToolTipdeltapercentQuickTrade'+uniqueName"
                              [tooltipContent]="triggerDeltaPercentToolTip" openIconName="icon-Info-Circle" [mode]="'modal-soft'"
                              targetEle="{{'trigger-delta-percent-input'+uniqueName}}"
                              (clickTooltip)="tooltipClicked (quickTradeContent.text.triggerDeltaPercent)">
                              <div id="quick_trade_tooltip7" class="tooltip-lightbox">
                                <p>
                                  {{quickTradeContent.list.trailingTooltipList.triggerTooltip1}}
                                </p>
                                <a *ngIf="!appStore.isApp()" href="javascript:void(0);"
                                  (click)="IRPriceTooltipLink()">{{quickTradeContent.list.trailingTooltipList.learnMore}}
                                  {{quickTradeContent.list.trailingTooltipList.triggerTooltip2}}<span
                                    class='sr-only'>{{quickTradeContent.text.newWindowText}}</span></a>
                                <a *ngIf="appStore.isApp()" href="javascript:void(0);" (click)="IRPriceTooltipLink()"
                                  [target]="undefined"
                                  routerLink='/txn/help'>{{quickTradeContent.list.trailingTooltipList.learnMore}}
                                  {{quickTradeContent.list.trailingTooltipList.triggerTooltip2}}<span
                                    class='sr-only'>{{quickTradeContent.text.newWindowText}}</span></a>
                              </div>
                            </app-tooltip>
                          </div>
                          <div id="quick_trade_estimatedTriggerPrice" class="field-desc">
                            <div class="trigger-label" id="{{'estimated-trigger-price-label'+uniqueName}}">
                              {{quickTradeContent.text.estimatedTriggerPrice}}
                              <span *ngIf="isNum(estimatedTriggerPrice)" class='nowrap'>
                                {{estimatedTriggerPrice |
                                currency:'CAD':'symbol-narrow':'1.1-4':this.appStore.lang}}
                              </span>
                              <span *ngIf="!isNum(estimatedTriggerPrice)" class='nowrap'>
                                {{estimatedTriggerPrice}}
                              </span>
                            </div>
                          </div>
                          <app-field-level-error [error]="formError.triggerPercentagePrice"
                            [Id]="'trigger-delta-percent-error'+uniqueName" class="err-small-290">
                          </app-field-level-error>
                        </div>
                      </div>
                    </ng-template>
                  </div>

                  <!-- Trailing Stop Dollar/Percent Mode Limit offset-->
                  <div class="price-subfields" *ngIf="inputs.priceMode === 'trailingStopMode'">
                    <div class="field-input">
                      <div class="field-and-tooltip">
                        <div class="label-overlay-input field-and-tooltip-left">
                          <label
                            for="{{'limit-offset-input'+uniqueName}}">{{quickTradeContent.text.limitOffset}}</label>
                          <input [(ngModel)]="inputs.limitOffsetDollar" id="{{'limit-offset-input'+uniqueName}}"
                            aria-describedby="{{'estimated-limit-price-label'+uniqueName + ' limit-offset-error'+uniqueName}}"
                            type="number" autocomplete='off' formnovalidate="formnovalidate"
                            (input)="changeLimitOffSetPrice()" #limitOffsetDollar="ngModel" name="limitOffsetDollar"
                            [appMaxlength]='10' [isNumRegExApply]='true' maxlength='10'
                            [inputErrorDirective]="formError.limitOffsetDollar" class="soft"
                            [placeholder]="quickTradeContent.text.numberPlaceholder">
                        </div>
                        <app-tooltip [id]="'limitOffsetToolTipQuickTrade'+uniqueName" [tooltipContent]="limitOffsetToolTip"
                          openIconName="icon-Info-Circle" [mode]="'modal-soft'" [largeHitBox]="true"
                          (clickTooltip)="tooltipClicked(quickTradeContent.text.limitOffset)"
                          targetEle="{{'limit-offset-input'+uniqueName}}">
                          <div id="quick_trade_tooltip8"  class="tooltip-lightbox">
                            <p>{{quickTradeContent.list.trailingTooltipList.limitTooltip1}}</p>
                            <a *ngIf="!appStore.isApp()" href="javascript:void(0);" id="quick_trade_trailing_leanMore3"
                              (click)="IRPriceTooltipLink()">{{quickTradeContent.list.trailingTooltipList.learnMore}}
                              {{quickTradeContent.list.trailingTooltipList.limitTooltip2}}<span
                                class='sr-only'>{{quickTradeContent.text.newWindowText}}</span></a>
                            <a *ngIf="appStore.isApp()" href="javascript:void(0);" (click)="IRPriceTooltipLink()"
                              [target]="undefined" id="quick_trade_trailing_leanMore4"
                              routerLink='/txn/help'>{{quickTradeContent.list.trailingTooltipList.learnMore}}
                              {{quickTradeContent.list.trailingTooltipList.limitTooltip2}}<span
                                class='sr-only'>{{quickTradeContent.text.newWindowText}}</span></a>
                          </div>
                        </app-tooltip>
                      </div>
                      <div class="field-desc">
                        <div id="{{'estimated-limit-price-label'+uniqueName}}">
                          {{quickTradeContent.text.estimatedLimitPrice}}
                          <span *ngIf="isNum(estimatedLimitPrice)" class='nowrap'>
                            {{estimatedLimitPrice |
                            currency:'CAD':'symbol-narrow':'1.1-4':this.appStore.lang}}
                          </span>
                          <span *ngIf="!isNum(estimatedLimitPrice)" class='nowrap'>
                            {{estimatedLimitPrice}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <app-field-level-error [error]="formError.limitOffsetDollar" [Id]="'limit-offset-error'+uniqueName"
                      class="err-small-290">
                    </app-field-level-error>
                  </div>

                  <div class="quantity-container">
                    <div>
                      <div class="quantity-shares-container label-overlay-input">
                        <label for="{{'quantity-shares-input-qt'+uniqueName}}">{{quickTradeContent.text.shares}}</label>
                        <input id="{{'quantity-shares-input-qt'+uniqueName}}" class="right-align soft" #number_input
                          aria-describedby="{{'quantity-held-qt'+uniqueName + ' quantity-error-qt'+uniqueName}}"
                          type='number' min='0' maxlength='7' [appMaxlength]='7' autocomplete='off'
                          [(ngModel)]="inputs.quantity" (input)='changeQuantity(inputs.quantity)' #quantity="ngModel"
                          name="quantity" [inputErrorDirective]="formError.quantity"
                          [placeholder]="quickTradeContent.text.numberPlaceholder"
                          [attr.aria-description]="quickTradeContent.text.quantityInstructional">
                      </div>
                      <div class="quantity-middle" [attr.aria-hidden]="true">
                        <div></div>
                      </div>
                      <div class="quantity-amount-container label-overlay-input">
                        <label for="{{'quantity-amount-input-qt'+uniqueName}}">$</label>
                        <input id="{{'quantity-amount-input-qt'+uniqueName}}" class="right-align soft"
                          aria-describedby="{{'quantity-error-qt'+uniqueName}}" type='number'
                          autocomplete='off' [(ngModel)]="inputs.quantityValue" maxlength='13' [appMaxlength]='13'
                          [isNumRegExApply]='true' (input)='changeQuantityValue(inputs.quantityValue)'
                          name="quantityAmount" [inputErrorDirective]="formError.quantity"
                          [placeholder]="quickTradeContent.text.numberPlaceholder">
                      </div>
                    </div>
                    <div class="field-desc"
                      *ngIf="(this.inputs.action === 'BUYTOCOVER' || this.inputs.action === 'SELL') && (this.inputs.stockHoldingIndex > -1 && this.stockHoldings.length > 0)">
                      <div id="{{'quantity-held-qt'+uniqueName}}">
                        {{this.stockHoldings[this.inputs.stockHoldingIndex].Quantity.Data}}
                        <span> {{quickTradeContent.text.sharesHeld}}</span>
                      </div>
                    </div>
                    <div class="field-desc" *ngIf="this.inputs.action === 'BUY' && maxQuantitySlider() > 0">
                      <div id="{{'quantity-held-qt'+uniqueName}}">
                        {{quickTradeContent.text.maxShares}}
                        <span> {{maxQuantitySlider()}}</span>
                      </div>
                    </div>
                    <app-field-level-error [error]="formError.quantity" [Id]="'quantity-error-qt'+uniqueName"
                      class="err-small-290">
                    </app-field-level-error>
                  </div>
                </fieldset>

                <div class="expiry">
                  <fieldset class="day-custom-expiry" [ngClass]="isDesktop() ? '': 'marginBottom0'">
                    <legend id="{{'expiry-label-qt'+uniqueName}}" class="field-label">
                      {{quickTradeContent.text.expiry}}</legend>
                    <app-connected-radio [contentArray]="expiryArray" [disabledArray]="disabledExpiryArray"
                      [(selectedValue)]="inputs.expiryMode" [uniqueName]="'QuickTradeExpiryToggle'+uniqueName"
                      (change)="changeExpiryMode($event)"
                      [labelledbyID]="'expiry-label-qt'+uniqueName"></app-connected-radio>
                    <app-field-level-error [error]="formError.expiryMode" [Id]="'expiry-error-qt'+uniqueName">
                    </app-field-level-error>
                  </fieldset>
                  <div>
                    <input type="hidden" #expiryMode="ngModel" name="expiryMode" [(ngModel)]='inputs.expiryMode'>
                  </div>
                </div>
                <div class="paddingTop8" *ngIf="inputs.expiryMode == 'customDate'">
                  <div class="col paddingLeft0-small">
                    <div class="month-selector">
                      <label id="{{'month-label'+uniqueName}}"
                        class="field-label">{{quickTradeContent.text.month}}</label>
                      <div class="select-item">
                        <select [(ngModel)]='inputs.dateMonth' name="dateMonth" class="custom-date-style soft"
                          (change)="calcMonthDays(inputs.dateMonth); setDateParams();"
                          aria-labelledby="'expiry-label-qt'+uniqueName + ' month-label'+uniqueName"
                          aria-describedby="{{'selected-date-error-qt'+uniqueName}}"
                          [ngModelOptions]="{standalone: true}" [inputErrorDirective]="formError.selectedDate">
                          <option *ngFor="let month of monthList" [ngValue]='month.key'
                            [selected]="inputs.dateMonth == month.key">
                            {{month.value}}</option>
                        </select>
                        <span class="select-icon icon-Chevron-Down color03" aria-hidden="true">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col paddingLeft15 paddingLeft10-small">
                    <div class="day-selector">
                      <label id="{{'day-label'+uniqueName}}" class="field-label">{{quickTradeContent.text.day}}</label>
                      <div class="select-item">
                        <select [(ngModel)]='inputs.dateDay' name="dateDay" class="custom-date-style soft"
                          aria-labelledby="'expiry-label-qt'+uniqueName + ' day-label'+uniqueName"
                          aria-describedby="{{'selected-date-error-qt'+uniqueName}}"
                          [ngModelOptions]="{standalone: true}" [inputErrorDirective]="formError.selectedDate"
                          (change)="setDateParams();">
                          <option *ngFor=" let day of dayList" [ngValue]='day.Day'
                            [selected]="inputs.dateDay == day.Day" [attr.disabled]="day.Disabled ? true:null">
                            {{day.Day}}</option>
                        </select>
                        <span class="select-icon icon-Chevron-Down color03" aria-hidden="true">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col paddingLeft15" *ngIf="inputs.expiryMode == 'customDate'">
                    <div class="calendar-selector">
                      <app-date-selector [id]="'customDate-qt'+uniqueName" openIconName="icon-Calendar"
                        [targetEle]="'day-label'+uniqueName" [dateParams]="dateSelectorParam.dateParam"
                        [content]="dateSelectorParam.content" (dateSelectedByUser)='setSelectedDate($event)'
                        boundingContainer="overlay-body{{uniqueName}}">
                      </app-date-selector>
                    </div>
                  </div>
                  <div>
                    <app-field-level-error [error]="formError.selectedDate" [Id]="'selected-date-error-qt'+uniqueName">
                    </app-field-level-error>
                  </div>
                </div>
                <div class="paddingTop8" *ngIf="showExtendedHours">
                  <input id="{{'extended-hours-quick-trade-checkbox'+uniqueName}}" class="eh-checkbox" type="checkbox"
                    name="extendedHours" [(ngModel)]='inputs.extendedHours'
                    aria-describedby="{{'extended-hours-quick-trade-label'+uniqueName}}"
                    (click)="extendedHoursUpdate()">
                  <label id="{{'extended-hours-quick-trade-label'+uniqueName}}" class="eh-label"
                    for="{{'extended-hours-quick-trade-checkbox'+uniqueName}}">{{quickTradeContent.text.extendedHours}}</label>
                  <app-tooltip [id]="'ehQTToolTip'+uniqueName" [tooltipContent]="ehToolTip"
                    openIconName="icon-Info-Circle" [largeHitBox]="true" [mode]="'popup'"
                    targetEle="{{'extended-hours-quick-trade-checkbox'+uniqueName}}">
                    <div>
                      <p>
                        <span
                          [innerHtml]="quickTradeContent.list.extendedHoursTooltipList.extendedHoursTooltipText"></span>
                      </p>
                    </div>
                    <br> {{quickTradeContent.list.extendedHoursTooltipList.learnMoreAbout}}
                    <a class="tooltipLink" href="javascript:void(0);" id="quick_trade_learnMoreAbout"
                      (click)="openWindow(quickTradeContent.list.extendedHoursTooltipList.extendedHoursTooltipUrl)">{{quickTradeContent.list.extendedHoursTooltipList.extendedHoursTooltipTrading}}</a>
                    <span class='sr-only'>{{quickTradeContent.text.newWindowText}}</span>.
                  </app-tooltip>
                </div>
                <div *ngIf="contentCards.length > 1" class="carousel-div">
                  <carousel [carouselContent]="carouselContent" carouselStyle="grey" [total]="contentCards.length" [current]="1" [margin]="16"
                    [id]="'tradeStocksCarousel'" (focusToSlideCard)="focusBacktoCard($event)" #small_carousel>
                    <div *ngFor="let card of contentCards let i=index" class="carousel-wrapper">
                      <ng-container *ngTemplateOutlet="contentCardTemplate;context:{card: card}"></ng-container>
                    </div>
                  </carousel>
                </div>
                <div *ngIf="contentCards.length == 1" class="carousel-div-single">
                  <ng-container *ngTemplateOutlet="contentCardTemplate;context:{card: contentCards[0]}"></ng-container>
                </div>
                <ng-template #contentCardTemplate let-card="card">
                  <a *ngIf="card.url" class="trade-stocks-content-card" [attr.style]="'background: ' + card.extras.backgroundColor" (click)="contentCardClick(card.url, card)" 
                  (focus)="focusIndividualCarouselCard(i+1)" href='javascript:void(0);'>
                    <img *ngIf="card.imageUrl" class="trade-stocks-content-card-img" [src]="card.imageUrl" [attr.aria-hidden]="true" alt=""/>
                    <div>
                      <div *ngIf="card.title" class="trade-stocks-content-card-title" [attr.style]="card.extras.titleFontColor ? 'color: ' + card.extras.titleFontColor : ''" [innerHtml]="card.title"></div>
                      <div *ngIf="card.description && card.description != 'null'" class="trade-stocks-content-card-description" [attr.style]="card.extras.bodyFontColor ? 'color: ' + card.extras.bodyFontColor : ''" [innerHtml]="card.description"></div>
                      <div class="trade-stocks-content-card-link" [attr.style]="card.extras.linkFontColor ? 'color: ' + card.extras.linkFontColor : ''">
                        <span [innerHtml]="card.linkText"></span>
                        <span *ngIf="!card.url.startsWith('#') && !card.url.startsWith('/')" class="sr-only">{{globalContent.text.newWindowText}}</span>
                        <span *ngIf="card.url.startsWith('#') || card.url.startsWith('/')" class="sr-only">{{globalContent.text.sameWindowText}}</span>
                        <span class="icon-Chevron-Right" [attr.aria-hidden]="true"></span>
                      </div>
                    </div>
                  </a>
                  <div *ngIf="!card.url" class="trade-stocks-content-card" [attr.style]="'background: ' + card.extras.backgroundColor">
                    <img *ngIf="card.imageUrl" class="trade-stocks-content-card-img" [src]="card.imageUrl" [attr.aria-hidden]="true" alt=""/>
                    <div>
                      <div *ngIf="card.title" class="trade-stocks-content-card-title" [attr.style]="card.extras.titleFontColor ? 'color: ' + card.extras.titleFontColor : ''" [innerHtml]="card.title"></div>
                      <div *ngIf="card.description && card.description != 'null'" class="trade-stocks-content-card-description" [attr.style]="card.extras.bodyFontColor ? 'color: ' + card.extras.bodyFontColor : ''" [innerHtml]="card.description"></div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="information">
              <div class="container">
                <div class="form-bright-zebra more-section">
                  <div class="col col-100 col-100-small">
                    <div class="more">
                      <div class="moreinfo" tabindex="-1" id="{{'more-info'+uniqueName}}">
                        {{quickTradeContent.text.moreInformation}} </div>
                      <app-tooltip *ngIf="quickTradeContent" [id]="'moreInformationtooltip'+uniqueName"
                        [tooltipContent]="moreInfoToolTip" openIconName="icon-Info-Circle"
                        (clickTooltip)="tooltipClicked(quickTradeContent.text.moreInformationTitle)"
                        [mode]="'modal-soft'" [largeHitBox]="true" targetEle="{{'more-info'+uniqueName}}">
                        <div id="quick_trade_tooltip9" class="tooltip-lightbox">
                          <h3>{{quickTradeContent.list.moreInformationList.para1[0]}}</h3>
                          <p class="bb-2">{{quickTradeContent.list.moreInformationList.para1[1]}}</p>
                          <h3>{{quickTradeContent.list.moreInformationList.para2[0]}}</h3>
                          <p class="bb-2">{{quickTradeContent.list.moreInformationList.para2[1]}}</p>
                          <h3>{{quickTradeContent.list.moreInformationList.para3[0]}}</h3>
                          <p class="marginBottom8">{{quickTradeContent.list.moreInformationList.para3[1]}}</p>
                          <ul class="tooltipList">
                            <li *ngFor="let item of quickTradeContent.list.moreInformationList.para3[2]">{{item}}</li>
                          </ul>
                          <p>{{quickTradeContent.list.moreInformationList.para3[3]}}</p>
                          <p>{{quickTradeContent.list.moreInformationList.para3[4]}}</p>
                          <p class="tooltipList bb-2">{{quickTradeContent.list.moreInformationList.para3[5]}}</p>
                          <h3>{{quickTradeContent.list.moreInformationList.para4[0]}}</h3>
                          <p>{{quickTradeContent.list.moreInformationList.para4[1]}}</p>
                        </div>
                      </app-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom-alert"
          [ngClass]="{'bottom-spacing' : mode != 'quickTrade', 'notch': notch, 'screenReader': isScreenReaderON}"
          [attr.aria-hidden]="isKeyboardOpen ? true : false" [hidden]="isKeyboardOpen">
          <div class="container" *ngIf="selectedSymbol && selectedSymbol.SymbolName">
            <div
              [ngClass]="inputs.action == 'BUY'|| inputs.action == '' || inputs.action == 'BUYTOCOVER' ? 'box-alert-buy' : 'box-alert-sell'">
              <div id="quick_trade_paragraph" class="paragraph">
                <span>
                  <span class="text-box-navigation-bold">{{getSummaryActionContent(inputs.action)}}</span>
                  <span *ngIf="inputs.quantity == undefined" class="text-box-navigation-bold">
                    {{quickTradeContent.text.zero}}
                  </span>
                  <span *ngIf="inputs.quantity != undefined" class="text-box-navigation-bold"> {{inputs.quantity |
                    number:"1.0-0"}}
                  </span>
                  <span class="text-box-navigation-bold">{{quickTradeContent.text.sharesOf}}</span>
                  <span *ngIf="selectedSymbol.SymbolName != undefined" class="text-box-navigation-bold">
                    <span>{{selectedSymbol.SymbolName}}</span>
                    <span *ngIf="inputs.priceMode == 'marketMode'">:{{selectedSymbol.Market}}</span>
                  </span>
                  <span class="text-box-navigation-medium" [attr.aria-label]="quickTradeContent.text.atSignSR">
                    {{quickTradeContent.text.atSign}} </span>
                  <span *ngIf="inputs.priceMode == 'marketMode'"
                    class="text-box-navigation-medium">{{quickTradeContent.text.summaryPriceMarket}},</span>
                  <span *ngIf="inputs.priceMode == 'limitMode'" class="text-box-navigation-medium">
                    <span>{{quickTradeContent.text.summaryPriceLimit}} </span>
                    <span *ngIf="!isNum(inputs.limit)">{{inputs.limit}}</span>
                    <span *ngIf="isNum(inputs.limit)" [innerHtml]="valueOrZero(inputs.limit) |
                    currencyFormatter:selectedSymbol.Currency:'format-symboless-sr'"></span>
                    <span aria-hidden="true">
                      {{selectedSymbol.Currency}}D</span>,
                  </span>
                  <span *ngIf="inputs.priceMode=='stopMode'" class="text-box-navigation-medium">
                    <span>{{quickTradeContent.text.summaryPriceStop}} </span>
                    <span *ngIf="isNum(inputs.stopPrice)" [innerHtml]="valueOrZero(inputs.stopPrice) |
                    currencyFormatter:selectedSymbol.Currency:'format-symboless-sr'"></span>
                    <span *ngIf="!isNum(inputs.stopPrice)">{{inputs.stopPrice}}</span>
                    <span aria-hidden="true">
                      {{selectedSymbol.Currency}}D</span>
                    <span> {{quickTradeContent.text.summaryPriceStopLimit}} </span>
                    <span *ngIf="isNum(inputs.stopLimit)" [innerHtml]="valueOrZero(inputs.stopLimit) |
                    currencyFormatter:selectedSymbol.Currency:'format-symboless-sr'"></span>
                    <span *ngIf="!isNum(inputs.stopLimit)">{{inputs.stopLimit}}</span>
                    <span aria-hidden="true">
                      {{selectedSymbol.Currency}}D</span>,
                  </span>
                  <span *ngIf="inputs.priceMode=='trailingStopMode' && inputs.trailingMode == 'dollar'"
                    class="text-box-navigation-medium">
                    {{quickTradeContent.text.summaryTrailingStopTriggerDelta}}
                    <span *ngIf="isNum(inputs.triggerDollarPrice)" [innerHtml]="valueOrZero(inputs.triggerDollarPrice) |
                    currencyFormatter:selectedSymbol.Currency:'format-symboless-sr'"></span>
                    <span *ngIf="!isNum(inputs.triggerDollarPrice)">{{inputs.triggerDollarPrice}}</span>
                    <span aria-hidden="true">
                      {{selectedSymbol.Currency}}D</span>
                    {{quickTradeContent.text.summaryTrailingStopLimitOffset}}
                    <span *ngIf="isNum(inputs.limitOffsetDollar)" [innerHtml]="valueOrZero(inputs.limitOffsetDollar) |
                    currencyFormatter:selectedSymbol.Currency:'format-symboless-sr'"></span>
                    <span *ngIf="!isNum(inputs.limitOffsetDollar)">{{inputs.limitOffsetDollar}}</span>
                    <span aria-hidden="true">
                      {{selectedSymbol.Currency}}D</span>,
                  </span>
                  <span *ngIf="inputs.priceMode=='trailingStopMode' && inputs.trailingMode == 'percent'"
                    class="text-box-navigation-medium">
                    {{quickTradeContent.text.summaryTrailingStopTriggerDelta}}
                    <span>{{valueOrFixed(inputs.triggerPercentagePrice)}}</span>
                    <span>{{appStore.lang == 'fr' ? ' %' : '%'}}</span>
                    {{quickTradeContent.text.summaryTrailingStopLimitOffset}}
                    <span *ngIf="isNum(inputs.limitOffsetDollar)" [innerHtml]="valueOrZero(inputs.limitOffsetDollar) |
                    currencyFormatter:selectedSymbol.Currency:'format-symboless-sr'"></span>
                    <span *ngIf="!isNum(inputs.limitOffsetDollar)">{{inputs.limitOffsetDollar}}</span>
                    <span aria-hidden="true">
                      {{selectedSymbol.Currency}}D</span>,
                  </span>
                  <span class="text-box-navigation-medium">
                    {{quickTradeContent.text.expiring}}
                  </span>
                  <span *ngIf="inputs.expiryMode=='day'"
                    class="text-box-navigation-medium">{{quickTradeContent.text.expiringDay}}{{globalContent.getNewExpiryContent('DAY'+(inputs.extendedHours ?
                    '_PLUS' : ''),calculateDate(),'MMMM D, YYYY','D MMMM YYYY',appStore.lang, false)}}.
                  </span>
                  <span *ngIf="inputs.expiryMode==='customDate'"
                    class="text-box-navigation-medium">{{quickTradeContent.text.expiringCustom}}{{globalContent.getNewExpiryContent('CUSTOM',calculateDate(),'MMMM D, YYYY','D MMMM YYYY',appStore.lang, false)}}.
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="bottom-button">
            <div class="container">
              <div class="button-background">
                <button id="quick_trade_clearBtn" class="button grey clear" type="button"
                  (click)="clearPage(); taggingClear();">{{quickTradeContent.text.clear}}</button>
                <button class="button submit"
                  [ngClass]="inputs.action == 'BUY'|| inputs.action == '' || inputs.action==='BUYTOCOVER' ? 'green' : 'orange'"
                  (click)="nextdr()" type="button" buttonDisable
                  id="{{'submitTradeDrawer'+uniqueName}}">{{quickTradeContent.text.reviewOrder}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>