import { BaseData } from 'src/app/shared/models/base-data';

export class OnlineRegisterVerificationData extends BaseData {
    constructor() {
        super();
        this.input = { 
          userId: '', 
          detailType: '', 
          postalCode: '',
          dateMonth: -1,
          dateDay: 0,
          dateYear: 0,
          selectedDate: null
        };  
    }
}

export interface OnlineRegisterVerifyInputs {
  userId: string;
  detailType: string;
  postalCode: string;
  dateMonth: number;
  dateDay: number;
  dateYear: number;
  selectedDate: any;
}