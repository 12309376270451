import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { OnlineRegisterPasswordData } from './onlineregister-password-data';

@Injectable({ providedIn: 'root' })
export class OnlineRegisterPasswordStore extends PageStore<OnlineRegisterPasswordData> {

    constructor() {
        super(new OnlineRegisterPasswordData());
    }

}
