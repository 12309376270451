import { Injectable } from '@angular/core';
import { ChangeMailingOptionsVerifyData } from './change-mailing-options-verify.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeMailingOptionsVerifyStore extends PageStore<ChangeMailingOptionsVerifyData> {

    constructor() {
        super(new ChangeMailingOptionsVerifyData());
    }

}
