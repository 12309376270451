import { Component, NgZone, OnInit, OnChanges, Input, SimpleChanges, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-country-info',
  templateUrl: './country-info.component.html',
  styleUrls: ['./country-info.component.scss']
})

// export class CountryInfoComponent extends BaseModalPage {
export class CountryInfoComponent implements OnInit, OnChanges {
  message: any;
  isDataAvailable = false;
  headerObj: any;
  lob: string;
  lang: string;
  pageContent: any;
  countryList: any;
  // returnFn: any = this.navParams.get('returnFn');
  countryObj: any;
  @Input() public pageContentParam: any;
  @Input() public countryListParam: any;
  @Input() public countryObjectParam: any;
  @Output() public selectedCountry = new EventEmitter();

  // @Input () public  lobParam:any;
  // @Input() public langParam: any;



  constructor(
    public zone: NgZone
  ) {
    this.isDataAvailable = true;
  }


  ngOnInit() { }


  ngOnChanges(changes: SimpleChanges) {
    const countries = this.countryListParam || [];
    this.countryListParam = countries;
  }


  selectCountry(countryObject: any) {
    //   if (!(this.returnFn === undefined)) {
    //     this.returnFn(countryObject);
    //   }
    //   super.modalDismissed(countryObject);
    // }

    this.selectedCountry.emit(countryObject);
  }
}
