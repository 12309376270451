import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePickerComponent } from './date-picker.component';
import { DatePickerHeader } from './date-picker-header/date-picker-header.component';
import { DatePickerHeaderTransaction } from './date-picker-header-transaction/date-picker-header-transaction.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [DatePickerComponent, DatePickerHeader, DatePickerHeaderTransaction],
    imports: [
        MatDatepickerModule,
        MatNativeDateModule,
        CommonModule,
        FormsModule,
    ],
    exports: [
        DatePickerComponent,
        DatePickerHeader,
        DatePickerHeaderTransaction
    ],
    providers: [
        MatDatepickerModule,
        A11yModule
    ]
})
export class DatePickerModule {
}
