import { Injectable } from '@angular/core';
import { MutualFundsTradableListData } from './mutual-funds-tradable-list-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class MutualFundsTradableListStore extends PageStore<MutualFundsTradableListData> {

  constructor() {
    super(new MutualFundsTradableListData());
  }
}
