import { BaseData } from 'src/app/shared/models/base-data';

export class BuyBondsSearchData extends BaseData {
  constructor() {
    super();
    this.input = {
      type: 'BD',
      yieldRange: 'ALL',
      couponRange: 'ALL',
      FaceValueAvl: null,
      sector: 'ALL',
      payment: 'ALL',
      termRange: '',
      creditRating: 'ALL',
      currency: 'CAD'
    };
  }
}

export interface BuyBondsSearchInputs {
  type: string;
  yieldRange: string;
  couponRange: string;
  FaceValueAvl: string;
  sector: string;
  payment: string;
  termRange: string;
  creditRating: string;
  currency: string;
}

export interface SearchReq {
  Refresh?: boolean;
  FixedIncomeType?: string;
  FaceValue?: string;
  TermRange?: string;
  YieldRange?: string;
  Sector?: string;
  CreditRating?: string;
  CouponRange?: string;
  PaymentFrequency?: string;
  Currency?: string;
  PaginationOption?: {
    Offset: number,
    Limit: number
  }
  SortOption?: {
    SortColumn: string,
    SortType: string
  }
}

export interface IssuerDetails {
  Bond: {
    Issuer: string,
    CouponRate: string,
    MaturityDate: string,
    FiType: any,
    CusipId: string,
    CreditRatingList: any,
    Yield: any,
    Currency: string,
    ParValue: any,
    Price: string,
    AccruedInterestAmount: string,
  }
  BondOrderData: {
    ExchangeRate: number,
    SettlementInfo: any,
  }
}

export interface columnArray {
  index: number;
  asc: boolean;
  names: string[];
  text: string[];
  sorting: string[];
  srText: string[];
}

export interface sortArray {
  index: number;
  asc: boolean;
  names: string[];
  text: string[];
}

export interface buyBondsList {
  ResultList: [
    {
      CouponPaymentFrequency?: {
        CodeValue: string
      },
      CouponRate?: string,
      CreditRatingList?: [
        {
          RatingAgency?: {
            CodeValue: string
          },
          RatingValue?: {
            CodeValue: string
          }
        }
      ],
      Currency?: string,
      CusipId?: string,
      FiInstrumentToken?: string,
      FiType?: {
        CodeValue: string
      },
      Issuer?: string,
      MaturityDate?: any,
      ParValue?: {
        Content: string,
        Data: string
      },
      Price?: string,
      QuantityAvailableToBuy?: string,
      SecurityDescription?: string,
      Yield?: [
        {
          YieldType?: {
            CodeValue: string
          },
          YieldValue?: string
        }
      ]
    }
  ]
}
