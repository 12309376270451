<div class="tooltip tooltip_{{id}}">
  <ng-container *ngIf="calendarBtnLabel; else iconBtn">
    <button class="calendarBtn" (click)="handleOpenAndPosition($event)" type="button" [attr.aria-label]="isCalendarOpen ? content?.text?.calendarBtnOpenHdnTxt : content?.text?.calendarBtnCloseHdnTxt"
    id="openButton_{{id}}" [attr.aria-haspopup]="true" (keydown)="handleKeyDownOpen($event)">
      <span class="calendarBtnLabel">{{calendarBtnLabel}}</span>
      <span [id]="'calendarIcon_' + id" class="calendarIcon tooltipIcon_{{id}} {{openIconName}}"></span>
    </button>
    <span class="sr-only">{{isCalendarOpen ? content?.text?.calendarHdnHideTxt : content?.text?.calendarHdnShowTxt}}</span>
    <span *ngIf="calendarBtnLabel" class="sr-only">{{currentSelectedDateHdnTxt}}</span>
  </ng-container>
  <ng-template #iconBtn>
    <button class="tooltipIcon tooltipIcon_{{id}} {{openIconName}}" (click)="handleOpenAndPosition($event)" type="button"
      id="openButton_{{id}}" attr.aria-label="{{content?.text?.dateButtonAlt}}" [attr.aria-haspopup]="true"
      (keydown)="handleKeyDownOpen($event)">
    </button>
  </ng-template>

  <div id="tooltipParent_{{id}}" style="display: none" class="tooltipParent">
    <div class="tooltipContainer" [class]="tooltipClass" id="tooltipContainer_{{id}}"
      (keydown.esc)="handleCloseTooltip($event)" tabindex="-1">

      <div class='container' id="container" *ngIf="isDatePicker">
        <app-date-picker #datePicker [pageName]="pageName" [selectedWeek]="selectedWeek" [minDate]='min' [maxDate]='max' [TransactionMinDate]='transactionMin'
          [TransactionMaxDate]='transactionMax' [startDate]='startDate' [content]='content'
          (dateSelected)="dateSelected($event)" (hideErrorMessageEvent)="hideErrorMessage($event)"></app-date-picker>
      </div>

      <div class="cancelDiv">
        <div class="errorBlock">
          <app-field-level-error [error]="invalidDate" [Id]="'invalid-date-error'+id">
          </app-field-level-error>
        </div>
        <div *ngIf="!hideCloseBtn" class="cancelBtnDiv">
          <button class='button primary cancelBtn' type="button" role="button" (click)="handleCloseTooltip($event)"
            [id]="'closeButton'+id" attr.aria-label="{{content?.text?.closeButton}}"
            (keydown)="handleKeyDownClose($event)"
            (keydown.esc)="handleCloseTooltip($event)">{{content?.text?.closeButton}}</button>
        </div>
      </div>
    </div>
  </div>
</div>