import { Injectable } from '@angular/core';
import { MutualFundsLandingData } from './mutual-funds-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class MutualFundsLandingStore extends PageStore<MutualFundsLandingData> {

    constructor() {
        super(new MutualFundsLandingData());
    }
}
