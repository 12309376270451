import { BuyBondsOrderData } from './buy-bonds-order-data';
import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class BuyBondsOrderStore extends PageStore<BuyBondsOrderData> {

    constructor() {
        super(new BuyBondsOrderData());
    }

}
