import { Injectable } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { Lob } from '../../../../../shared/models/lob.enum';

@Injectable({ providedIn: 'root' })
export class DownloadHoldings {

  constructor(private appStore: AppStore) { }

  generateRowsColumns(itemFormatted, holdings, currentTab) {
    let counter = 0;
    if (currentTab === 'T') {
      counter = holdings.CashHoldings.HoldingList.length + 20;
    } else {
      counter = holdings.HoldingsBreakDownList.length + 20;
    }
    if (holdings.HoldingsList) {
      holdings.HoldingsList.forEach(element => {
        counter += element.HoldingList.length;
      });
    }
    let i = 0;
    while (i < counter) {
      itemFormatted.push({
        col1: '', col2: '', col3: '', col4: '', col5: '',
        col6: '', col7: '', col8: '', col9: '', col10: '', col11: '', col12: '',
        col13: '', col14: '', col15: '', col16: ''
      });
      i++;
    }
  }
  getExchangeRates(list: any) {
    let exchangeList = '';
    list.forEach((element, i) => {
      const nextLine = (i === (list.length - 1)) ? '' : '\r\n';
      exchangeList += '1 ' + element.SourceCurrency.Currency + ' = ' + this.getNumberValue(element.SourceCurrency.Value) + ' CAD' + nextLine;
    });
    return exchangeList;
  }
  getName(primaryAccount, lob, pageContent) {
    const fname = (primaryAccount.AccountOwnerName.FirstName) ?
      primaryAccount.AccountOwnerName.FirstName : '';
    const lname = (primaryAccount.AccountOwnerName.LastName) ?
      primaryAccount.AccountOwnerName.LastName : '';
    const fullName = fname ? fname + ' ' + lname : lname;
    if (lob === 'wg' && primaryAccount.WgProductName) {
      return pageContent.list.productName[primaryAccount.WgProductName] + ' - ' + fullName;
    } else {
      return fullName;
    }
  }
  getAccountType(holdings, pageContent, globalContent) {
    return holdings.PrimaryAccount.AccountFriendlyName !== 'null' ? holdings.PrimaryAccount.AccountFriendlyName
      : globalContent.list.accountType[holdings.PrimaryAccount.AccountType];
  }
  getTimeStamp(holdings) {
    return holdings.RefreshBy ? holdings.RefreshBy : ''
  }
  getSubAccountDetails(pageContent, selectedAccountNumber, globalContent) {
    if (this.appStore.lob === 'wg' && selectedAccountNumber.AccountNumber !== selectedAccountNumber.SubAccountNumber) {
      return selectedAccountNumber.SubAccountNumber + ' ' + globalContent.list.accountType[selectedAccountNumber.AccountType] + ' ' +
        selectedAccountNumber.Currency
    } else {
      return globalContent.list.accountType[selectedAccountNumber.AccountType] + ' ' +
        selectedAccountNumber.Currency
    }
  }

  getCashHoldings(pageContent, element, globalContent) {
    if (this.appStore.lob === 'wg') {
      return globalContent.list.accountType[element.SubAccountType.Data] + ' ' + element.Currency + ' ' + element.SubAccountNumber
    } else {
      return globalContent.list.accountType[element.SubAccountType.Data] + ' ' + element.Currency
    }
  }

  public downloadHoldings(currentTab, holdings, selectedAccountNumber, isSubAccount, pageContent, globalContent) {
    if (holdings !== undefined) {
      const itemFormatted: any = [];
      let fileTitle = '';
      let index = 0;
      if (currentTab === 'T' || currentTab === 'P') {
        fileTitle = currentTab === 'T' ? pageContent.text.fileNameMR : pageContent.text.fileNameLBD;
        this.generateRowsColumns(itemFormatted, holdings, 'T');
        itemFormatted[0].col1 = selectedAccountNumber.AccountNumber + ' ' +
          this.getAccountType(holdings, pageContent, globalContent) + ' - ' +
          (isSubAccount ? this.getSubAccountDetails(pageContent, selectedAccountNumber, globalContent) : pageContent.text.combinedHoldings);
        itemFormatted[1].col1 = this.getName(holdings.PrimaryAccount, this.appStore.lob, pageContent);
        index = 2;
        if (holdings.RefreshBy) {
          itemFormatted[index].col1 = this.getTimeStamp(holdings);
          index++;
        }
        if (currentTab === 'P') {
          itemFormatted[index].col1 = pageContent.text.previousClose;
          index++;
        }
        index += 2;
        if (holdings.ExchangeRates) {
          itemFormatted[index] = {
            col1: pageContent.text.exchangeRate,
            col2: this.getExchangeRates(holdings.ExchangeRates.SourceCurrencyList)
          };
          index += 3;
        }
        if (currentTab === 'T') {
          itemFormatted[index] = { col1: isSubAccount ? pageContent.text.todaysMktValOfSecurities : pageContent.text.totalMktValOfSecurities, col2: this.getNumberValue(holdings.Securities) };
          index++;
          itemFormatted[index] = { col1: isSubAccount ? pageContent.text.todayCshBal : pageContent.text.todayCmbndCashBal, col2: this.getNumberValue(holdings.CashBalance) };
          index++;
          itemFormatted[index] = { col1: isSubAccount ? pageContent.text.todaysTtlVal : pageContent.text.todaysTtlValCAD, col2: this.getNumberValue(holdings.TotalValue) };
          index++;
        } else {
          itemFormatted[index] = { col1: isSubAccount ? pageContent.text.lbdMktValOfSecuritiesSub : pageContent.text.lbdMktValOfSecurities, col2: this.getNumberValue(holdings.Securities) };
          index++;
          itemFormatted[index] = { col1: isSubAccount ? pageContent.text.lbdCshBalSub : pageContent.text.lbdCmbdCashBal, col2: this.getNumberValue(holdings.CashBalance) };
          index++;
          itemFormatted[index] = { col1: isSubAccount ? pageContent.text.lbdTotalValueSub : pageContent.text.lbdTotalValue, col2: this.getNumberValue(holdings.TotalValue) };
          index++;
        }
        let cshHldsIndex = index;
        const cashHoldings = holdings.CashHoldings.HoldingList;
        if (cashHoldings && !isSubAccount) {
          cshHldsIndex += 2;
          itemFormatted[cshHldsIndex] = {
            col1: pageContent.text.cashAccount,
            col2: pageContent.text.cashAmount,
            col3: pageContent.text.cnvtAmtCad
          };
          cshHldsIndex++;
          cashHoldings.forEach((element, i) => {
            itemFormatted[cshHldsIndex] = {
              col1: this.getValue(this.getCashHoldings(pageContent, element, globalContent)),
              col2: this.getNumberValue(element.Value),
              col3: this.getNumberValue(element.CadEquavalentAmount)
            };
            cshHldsIndex++;
          });
        }
        let holdingsIndex = cshHldsIndex + 2;
        const holdingsList = holdings.HoldingsList;
        if (holdingsList) {
          itemFormatted[holdingsIndex] = {
            col1: pageContent.list.sortTypeList_L.ASSET,
            col2: pageContent.list.sortTypeList_L.CURRENCY,
            col3: pageContent.text.symbol,
            col4: pageContent.text.market,
            col5: pageContent.list.sortTypeList_L.DESCRIPTION,
            col6: pageContent.text.quantity,
            col7: currentTab === 'T' ? pageContent.list.sortTypeList_T.LAST_PRICE : pageContent.list.sortTypeList_P.LAST_PRICE,
            col8: pageContent.list.sortTypeList_T.PRICE_CHANGE_AMOUNT,
            col9: pageContent.list.sortTypeList_T.PRICE_CHANGE_PERCENTAGE,
            col10: currentTab === 'T' ? pageContent.list.sortTypeList_T.MARKET_VALUE : pageContent.list.sortTypeList_P.MARKET_VALUE,
            col11: currentTab === 'T' ? pageContent.text.marketValueChange + ' $' : pageContent.text.closingValueChange + ' $',
          };
          holdingsIndex++;
          holdingsList.forEach((element, i) => {
            element.HoldingList.forEach((ele, j) => {
              itemFormatted[holdingsIndex] = {
                col1: this.getValue(pageContent.list.assetValue[ele.FiType]),
                col2: this.getValue(ele.Currency),
                col3: this.getSymbol(ele.FiType, ele.Symbol),
                col4: this.getMarket(ele.FiType, ele.Symbol),
                col5: this.getDescription(ele.FiType, ele.Symbol),
                col6: this.getNumberValue(ele.Quantity),
                col7: this.getNumberValue(ele.Price),
                col8: this.getNumberValue(ele.IntraPriceChange),
                col9: this.removePercent(ele.IntraPricePercentageChange),
                col10: this.getNumberValue(ele.Value),
                col11: this.getNumberValue(ele.DollarChangeValue),
              };
              holdingsIndex++;
            });
          });
        }
      } else {
        fileTitle = pageContent.text.fileNameBV;
        this.generateRowsColumns(itemFormatted, holdings, 'L');
        itemFormatted[0].col1 = selectedAccountNumber.AccountNumber + ' ' +
          this.getAccountType(holdings, pageContent, globalContent) + ' - ' +
          (isSubAccount ? this.getSubAccountDetails(pageContent, selectedAccountNumber, globalContent) : pageContent.text.combinedHoldings);
        itemFormatted[1].col1 = this.getName(holdings.PrimaryAccount, this.appStore.lob, pageContent);
        index = 2;
        if (holdings.RefreshBy) {
          itemFormatted[index].col1 = this.getTimeStamp(holdings);
          index++;
        }
        itemFormatted[index].col1 = pageContent.text.previousClose;
        index += 3;

        if (holdings.ExchangeRates) {
          itemFormatted[index] = {
            col1: pageContent.text.exchangeRate,
            col2: this.getExchangeRates(holdings.ExchangeRates.SourceCurrencyList)
          };
          index += 3;
        }
        itemFormatted[index] = {
          col1: pageContent.text.securitiesHeldIn,
          col2: pageContent.text.securitiesClosingValue,
          col3: pageContent.text.securitiesBookValue,
          col4: pageContent.list.sortTypeList_L.GAIN_LOSS,
          col5: pageContent.list.sortTypeList_L.GAIN_LOSS_PERCENTAGE
        };
        index++;
        let currencyIndex = index;
        if (holdings.HoldingsBreakDownList) {
          holdings.HoldingsBreakDownList.forEach(element => {
            itemFormatted[currencyIndex] = {
              col1: element.Currency,
              col2: this.getNumberValue(element.TotalNativeClosingValue),
              col3: this.getNumberValue(element.TotalNativeBookValue),
              col4: this.getNumberValue(element.TotalNativeGainLossValue),
              col5: this.removePercent(element.TotalNativeGainLossPercentage)
            };
            currencyIndex++;
          });
        }
        let holdingsIndex = currencyIndex + 2;
        const holdingsList = holdings.HoldingsList;
        if (holdingsList) {
          if (this.appStore.lob == Lob.IE) {
            itemFormatted[holdingsIndex] = {
              col1: pageContent.list.sortTypeList_L_IE.ASSET,
              col2: pageContent.list.sortTypeList_L_IE.CURRENCY,
              col3: pageContent.text.symbol,
              col4: pageContent.text.market,
              col5: pageContent.list.sortTypeList_L_IE.DESCRIPTION,
              col6: pageContent.text.quantity,
              col7: pageContent.list.sortTypeList_L_IE.AVERAGE_COST,
              col8: pageContent.list.sortTypeList_L_IE.LAST_PRICE,
              col9: pageContent.list.sortTypeList_L_IE.CLOSING_VALUE,
              col10: pageContent.list.sortTypeList_L_IE.BOOK_VALUE,
              col11: pageContent.list.sortTypeList_L_IE.GAIN_LOSS,
              col12: pageContent.list.sortTypeList_L_IE.GAIN_LOSS_PERCENTAGE,
              col13: pageContent.list.sortTypeList_L_IE.PORTFOLIO_PERCENTAGE,
            };
            holdingsIndex++;
            holdingsList.forEach((element, i) => {
              element.HoldingList.forEach((ele, j) => {
                itemFormatted[holdingsIndex] = {
                  col1: this.getValue(pageContent.list.assetValue[ele.FiType]),
                  col2: this.getValue(ele.Currency),
                  col3: this.getSymbol(ele.FiType, ele.Symbol),
                  col4: this.getMarket(ele.FiType, ele.Symbol),
                  col5: this.getDescription(ele.FiType, ele.Symbol),
                  col6: this.getNumberValue(ele.Quantity),
                  col7: this.getNumberValue(ele.AvgCost),
                  col8: this.getNumberValue(ele.Price),
                  col9: this.getNumberValue(ele.Value),
                  col10: this.getNumberValue(ele.BookValue),
                  col11: this.getNumberValue(ele.GainLossValue),
                  col12: this.removePercent(ele.GainLossPctg),
                  col13: this.removePercent(ele.PctgOfPortfolio),
                };
                holdingsIndex++;
              });
            });
          } else {
            itemFormatted[holdingsIndex] = {
              col1: pageContent.list.sortTypeList_L.ASSET,
              col2: pageContent.list.sortTypeList_L.CURRENCY,
              col3: pageContent.text.symbol,
              col4: pageContent.text.market,
              col5: pageContent.list.sortTypeList_L.DESCRIPTION,
              col6: pageContent.text.quantity,
              col7: pageContent.list.sortTypeList_L.CLOSING_VALUE,
              col8: pageContent.list.sortTypeList_L.BOOK_VALUE,
              col9: pageContent.list.sortTypeList_L.GAIN_LOSS,
              col10: pageContent.list.sortTypeList_L.GAIN_LOSS_PERCENTAGE,
              col11: pageContent.list.sortTypeList_L.PORTFOLIO_PERCENTAGE,
            };
            holdingsIndex++;
            holdingsList.forEach((element, i) => {
              element.HoldingList.forEach((ele, j) => {
                itemFormatted[holdingsIndex] = {
                  col1: this.getValue(pageContent.list.assetValue[ele.FiType]),
                  col2: this.getValue(ele.Currency),
                  col3: this.getSymbol(ele.FiType, ele.Symbol),
                  col4: this.getMarket(ele.FiType, ele.Symbol),
                  col5: this.getDescription(ele.FiType, ele.Symbol),
                  col6: this.getNumberValue(ele.Quantity),
                  col7: this.getNumberValue(ele.Value),
                  col8: this.getNumberValue(ele.BookValue),
                  col9: this.getNumberValue(ele.GainLossValue),
                  col10: this.removePercent(ele.GainLossPctg),
                  col11: this.removePercent(ele.PctgOfPortfolio),
                };
                holdingsIndex++;
              });
            });
          }
        }
      }
      const fileName = this.exportExcelFile(itemFormatted, fileTitle);

      return fileName;
    }
  }

  public exportExcelFile(items, fileTitle) {
    const jsonObject = JSON.stringify(items);
    const csv = '\ufeff' + this.convertToCSV(jsonObject);
    const exportedFileName = fileTitle + '.csv';
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    return exportedFileName;

  }

  public convertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < array.length; i++) {
      let line = '';
      // eslint-disable-next-line guard-for-in
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        }
        if (array[i][index] === null || array[i][index] === '') {
          array[i][index] = '';
        }
        array[i][index] = '"' + array[i][index] + '"';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public getSymbol(symbolType, symbol) {
    if (symbol && symbol.SymbolName && symbolType === 'E' || symbolType === 'D' || symbolType === 'M') {
      return symbol.SymbolName;
    } else {
      return '';
    }
  }
  public getMarket(symbolType, symbol) {
    if (symbol && symbol.Market && symbolType === 'E' || symbolType === 'D' || symbolType === 'M') {
      return symbol.Market;
    } else {
      return '';
    }
  }

  public getNumberValue(value) {
    if (!value) {
      return '';
    } else {
      let newVal = value;
      if (this.appStore.lang === 'fr') {
        newVal = value.replace(',', '.').replace(/\s/g, ',')
      }
      return newVal;
    }
  }
  public getValue(value) {
    return value ? value : '';
  }
  public trimExtraSpace(val) {
    return val.replace(/\s+/g, ' ')
  }
  public getDescription(symbolType, symbol) {
    let symbolName = '';
    let symbolLongName = '';
    if (symbol) {
      if (symbolType === 'O' || symbolType === 'CE' || symbolType === 'F') {
        if (symbol && symbol.SymbolName) {
          symbolName = this.trimExtraSpace(symbol.SymbolName);
        }
        if (symbol && symbol.SymbolLongName) {
          symbolLongName = this.trimExtraSpace(symbol.SymbolLongName);
        }
        return symbolName + symbolLongName;
      } else {
        if (symbol.SymbolLongName) {
          return this.trimExtraSpace(symbol.SymbolLongName);
        } else {
          return '';
        }
      }
    } else {
      return '';
    }
  }
  public removePercent(val) {
    return val ? this.getNumberValue(val.slice(0, -1).trim()) : '';
  }
  getAccount(isSubAccount, selectedAccountNumber, pageContent, AccountType) {
    if (isSubAccount) {
      return selectedAccountNumber.AccountNumber + ' ' +
        pageContent.text[AccountType] + ' - ' +
        pageContent.text[selectedAccountNumber.AccountType] + ' ' +
        selectedAccountNumber.Currency;
    } else {
      return selectedAccountNumber.AccountNumber + ' ' +
        pageContent.text[AccountType] + ' - ' + pageContent.text.combinedHoldings;
    }
  }
}

