import { NgModule } from "@angular/core";
import { OtcvSharedModule } from "./otvc-shared.module";
import { TradingPasswordFormComponent } from "./components/trading-password-form/trading-password-form.component";
import { FormSharedModule } from "./form-shared.module";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "./shared.module";
import { CommonModule } from "@angular/common";



@NgModule({
    declarations: [
      TradingPasswordFormComponent
    ],
    imports: [
        OtcvSharedModule,
        FormsModule,
        FormSharedModule,
        CommonModule,
        SharedModule,
    ],
    exports: [
        TradingPasswordFormComponent
    ]
  })
  export class TradingPasswordFormSharedModule { }
  