import { BaseData } from '../../../../../../shared/models/base-data';

export class CancelCashPlanLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            tradingPassword: "",
            savePassword: false
        };
    }

}


export interface CancelCashPlanVerifyInputs {
    tradingPassword: string;
    savePassword: boolean;
}

export interface CancePlanDetailDataResp {
    Account?: Account;
    ValidateInfo?: ValidateInfo
}

export interface ValidateInfo {
    Action?: string;
    Amount?: number;
    EndDate?: string;
    NextOrderDate?: string;
    PayFromAccountNumber?: string;
    PlanFrequency?: number;
    StartDate?: string;
    SuspendDate?: string;
    UnSuspendDate?: string;
}

export interface Account {
    AccountNumber?: string;
    AccountType?: string;
    AccountFriendlyName?: string;
    RegistrationInfo?: RegistrationInfo;
}

export interface RegistrationInfo {
    RegType?: string;
}

export interface savePwdToolTip {
    openBtnLabel: string;
    cancel: string;
    content: string;
    btnHiddenText: string;
}

export const otvc_const = {
    currModule: 'cancelcashplanlanding',
    forgotTradingUrl: 'txn/userPreferences/forgotTradingPassword/landing',
    mode: 'forgotTradingPassword',
    nonReqAction: 'OTVCNOTREQUIRED',
    otvcprompt: 'otvcprompt',
    otvcpromptmodel: 'otvcpromptmodel',
    otvcPromptClose: 'otvcPromptClose',
    prompt: 'prompt',
    reqAction: 'OTVCREQUIRED'
}

export interface forgot_pwd_otvc_resp {
    ACTION: string;
}

export interface otvc_resp {
    OtvcDetail: contact[],
    LastUsed: string
    AlwaysOtvc: string,
    Skip: number
}

export interface contact {
    Value: number,
    Method: string,
    BlackListed: boolean,
    DialCode: number,
    CountryCode: number,
    CountryDesc: countryDesc
}

export interface countryDesc {
    en: string,
    fr: string
};


export const No_API_Data = {
    dash: '-',
    registered: 'REG',
    dollar: '$'
}