import { Injectable } from '@angular/core';
import { OtvcConfirmData } from './otvc-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class OtvcConfirmStore extends PageStore<OtvcConfirmData> {

    constructor() {
        super(new OtvcConfirmData());
    }

}
