import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { PageService } from 'src/app/shared/services/page-service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class QuotesResearchStrategyBuilderService implements PageService {
  constructor(private httpService: HttpService) { }


  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return input;
  }

  retrieveToken(page) {
    return this.httpService.post(gatewayConfig.APIServices.getTradingCentralToken.url, {
      "Values": {
        "page": page
      }
    }, { params: { skiploading: 'true' } });
  }

  extendSession() {
    return this.httpService.post(gatewayConfig.APIServices.getSiteStateExtendSession.url, {}, { params: { skiploading: 'true' } });
  }
}
