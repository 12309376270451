import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PageService } from 'src/app/shared/services/page-service';
import { CancelMutualFundPlanLandingStore } from "../data/cancel-mutual-fund-plan-landing-store";


@Injectable({
    providedIn: 'root'
})
export class CancelMutualFundPlanLandingService implements PageService {

    constructor() { }


    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        const req = ser.input;
        req.AccountNumber = CancelMutualFundPlanLandingStore.params.detailsParam.AccountNumber;

        return input;
    }

}