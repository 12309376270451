import { RouteGroup } from '../shared/models/route-config';

export const PerformanceReportingConfig: RouteGroup = {
    '/txn/performancereporting': {
        name: 'PerformanceReporting',
        service: 'PerformanceReportingService',
        servicePath: 'txn/performanceReporting/service/performance-reporting',
        store: 'PerformanceReportingStore',
        resolveContent: 'performancereporting',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'perfomanceReporting',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            isSubmitDeferred: true
        }
    }
}
