import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalHeaderComponent } from './global-header.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { FormSharedModule } from 'src/app/shared/form-shared.module';
import { TradingPasswordFormSharedModule } from 'src/app/shared/trading-password.module';

@NgModule({
    declarations: [GlobalHeaderComponent],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        FormsModule,
        FormSharedModule,
        TradingPasswordFormSharedModule
    ],
    exports: [
        GlobalHeaderComponent
    ]
})
export class GlobalHeaderModule { }
