/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable space-before-function-paren */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
/* eslint-disable radix */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { Component, OnInit, Inject, Renderer2, OnDestroy, HostListener } from '@angular/core';
import { GlobalContentStore } from '../../store/global-content-store';
import { AppStore } from '../../models/app-store';
import { CommonService } from '../../services/common.service';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import { TrackingService } from '../../services/tracking.service';
import { DOCUMENT } from '@angular/common';
import { of, Subscription } from 'rxjs';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { OmniturePageDetail } from '../../models/route-config';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';

declare let require: any;
const gatewayConfig = require('src/app/config/gateway-config.json');

@Component({
  selector: 'app-quick-trade-stocks',
  templateUrl: './quick-trade-stocks.component.html',
  styleUrls: ['./quick-trade-stocks.component.scss'],
  animations: [
    trigger('sidebarTrigger', [
      // To define animations based on trigger actions
      state('open', style({ transform: 'translateX(0%)' })),
      state('close', style({ transform: 'translateX(130%)' })),
      transition('open => close', [
        animate('200ms ease-in')
      ]),
      transition('close => open', [
        animate('250ms ease-out')
      ]),
      state('openmobile', style({ transform: 'translate(-50%, 0%)' })),
      state('closemobile', style({ transform: 'translate(-50%, 130%)' })),
      transition('openmobile => closemobile', [
        animate('200ms ease-in')
      ]),
      transition('closemobile => openmobile', [
        animate('250ms ease-out')
      ])
    ])
  ]
})
export class QuickTradeStocksComponent implements OnInit, OnDestroy {
  showStep = 1;
  openQuickTrade: boolean = false;
  focusableElments: { overlayContent: { focusableElements: any[] } };
  desktopContainer: HTMLElement;
  headerContainer: HTMLElement;
  footerContainer: HTMLElement;
  pageHeader: HTMLElement;
  pagefooter: HTMLElement;
  chatButton: HTMLElement;
  thirdLevelNavigation: HTMLElement;
  thirdLevelNavDropdown: HTMLElement;
  globalNav: HTMLElement;
  pageTitle: HTMLElement;
  subscriptions = new Subscription();
  renderQuickTrade;
  firstOpen: boolean = true;

  constructor(
    public globalContent: GlobalContentStore,
    private appStore: AppStore,
    private commonService: CommonService,
    public subscriptionService: ObservableSubscriptionService,
    private trackingService: TrackingService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private persistenceService: PersistenceService,
    private modalService: ModalService
  ) {

  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.openQuickTrade && document.body.clientWidth < 900) {
      //this.moveChatIconBack();
      this.Menu();
    }
  }

  ngOnInit() {
    this.retrieveStore();

    this.subscriptions.add(this.subscriptionService.tradeDrawerCrossFlow.subscribe(() => {
      if (this.showStep != 1) {
        this.showStep = 1;
        this.omnitureTagging();
      }
      if (!this.openQuickTrade) {
        this.Menu(true);
      }
    }));

    this.subscriptions.add(this.subscriptionService.tradeDrawerPosition.subscribe(pos => {
      this.showStep = pos;
      this.omnitureTagging();
    })
    );
    this.subscriptions.add(this.subscriptionService.openOrCloseDrawer.subscribe(data => {
      if (!data || data == 'close') {
        this.Menu();
      } else if (data == 'mobile') {
        this.Menu(true);
      }

    })
    );

    this.renderQuickTrade = true;
      
  }

  retrieveStore() {
    const drawPos = this.persistenceService.sessionStoreRetrieve('tradeDrawerPosition');
    if (drawPos) {
      this.showStep = parseInt(drawPos);
      this.subscriptionService.setTradeDrawerPosition(parseInt(drawPos));
    }
    //if (this.persistenceService.sessionStoreRetrieve('openOrCloseDrawer') == 'true') {
    this.renderQuickTrade = true;
    //}
    setTimeout(() => {
      const openClose = this.persistenceService.sessionStoreRetrieve('openOrCloseDrawer');
      if (openClose) {
        this.openQuickTrade = (openClose === 'true');
        // set trade drawer open/close value in session
        this.persistenceService.sessionStorePersist('openOrCloseDrawer', this.openQuickTrade);
      }
      if (this.openQuickTrade) {
        this.trapFocus();
        // setTimeout(() => {
        //   this.moveChatIcon();
        // }, 1000);
      }
    }, 300);

  }
  sendOmnitureInteractionData(value?: string, gaOrOmni?: string) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(value, false, true, false, true, gaOrOmni));
    }
  }
  getOmnitureConfig() {
    let config: OmniturePageDetail;
    config = {
      name: this.showStep == 1 ? 'details' : this.showStep == 2 ? 'verification' : 'confirmation',
      url: this.showStep == 1 ? '/txn/quick-trade-stocks/landing' :
        this.showStep == 2 ? '/txn/quick-trade-stocks/verify' : '/txn/quick-trade-stocks/confirm',
      data: {
        name: this.showStep == 1 ? 'details' : this.showStep == 2 ? 'verification' : 'confirmation',
        nameGA: this.showStep == 1 ? 'Quick Trade Stocks - details' : this.showStep == 2 ? 'Quick Trade Stocks - verification' : 'Quick Trade Stocks - confirmation',
        hierarchy: 'quick-trade,trading,trade-stocks',
        formInfo: {
          name: 'obr-trade-stocks',
          stepName: this.showStep == 1 ? 'details' : this.showStep == 2 ? 'verification' : 'confirmation',
        },
        events: { pageView: true, formSubmit: true, formStep: true, tradeSubmit: true },
      },
    };

    return config;
  }
  omnitureTagging() {
    if ((window as any).TrackingEnabled) {
      const config: OmniturePageDetail = this.getOmnitureConfig();
      of(this.trackingService.tagPageActionManually(config));
    }
  }

  // move bold chat to left when trade drawer open
  moveChatIcon() {
    // let chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.addClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.addClass(kampyleButton, 'move-kampyle');
    // }
  }

  // open trade drawer
  Menu(fromHtml?) {
    let config: OmniturePageDetail = this.getOmnitureConfig();
    if (!this.renderQuickTrade) {
      this.renderQuickTrade = true;
      this.trapFocus();
      this.sendOmnitureInteractionData(config.name + ':quick-trade:expand', 'O');
      this.sendOmnitureInteractionData(config.name + ':quick-trade-stocks:expand', 'G');
      setTimeout(() => {
        this.openQuickTrade = !this.openQuickTrade;
        this.persistenceService.sessionStorePersist('openOrCloseDrawer', this.openQuickTrade);
        if (fromHtml && this.openQuickTrade) {
          //this.moveChatIcon();
          this.subscriptionService.openOrCloseDrawer.next(true);
        }
      }, 300);
      this.omnitureTagging();
    } else {
      this.openQuickTrade = !this.openQuickTrade;
      this.persistenceService.sessionStorePersist('openOrCloseDrawer', this.openQuickTrade);
      if (fromHtml && this.openQuickTrade) {
        //this.moveChatIcon();
        this.subscriptionService.openOrCloseDrawer.next(true);
      }

      if (this.openQuickTrade) {
        this.sendOmnitureInteractionData(config.name + ':quick-trade:expand', 'O');
        this.sendOmnitureInteractionData(config.name + ':quick-trade-stocks:expand', 'G');
        this.trapFocus();
        if (this.firstOpen && this.showStep == 1) {
          this.firstOpen = false;
          this.commonService.logBraze("viewed_trade_stock_details", { platform: "quickTrade"} );
        }
      } else {
        this.sendOmnitureInteractionData(config.name + ':quick-trade:collapse', 'O');
        this.sendOmnitureInteractionData(config.name + ':quick-trade-stocks:collapse', 'G');
        this.undoTrapFocus();
      }
    }

    if (!this.openQuickTrade) {
      this.closeFunction();
      this.setAttributeTabIndex();
    } else {
      this.removeAttributeTabIndex();
    }
  }

  dataLoaded(e) {
    this.removeAttributeTabIndex();
    this.setFocus('closeBtn');
  }

  trapFocus() {
    // only for IOS, Safari
    const detectMobileIOS = this.getMobileOperatingSystem();
    if ((detectMobileIOS === 'Android' || detectMobileIOS === 'iOS') && this.isDesktop()) {
      this.modalService.trappedQT = true;
      this.document.body.style.overflow = 'hidden';
      this.modalService.addLog(Date.now().toString() + " : QT Open::");
      const closeButton = this.document.getElementById('submitTradeDrawer');
      if (closeButton) {
        closeButton.addEventListener('blur', function () {
          setTimeout(() => {
            document.getElementById('closeBtn').focus();
          }, 50);
        });
      }
      const logoQT = this.document.getElementById('closeBtnBack');
      if (logoQT) {
        logoQT.addEventListener('blur', function () {
          setTimeout(() => {
            document.getElementById('closeBtnBack').focus();
          }, 50);
        });
      }

      this.trapFocusForHamburgerElements();
      this.setHamburgerAriaAttribute();

      if (this.commonService.isNotchPhone()) {
        if (this.document.getElementById('overlay-content')) {
          this.renderer.addClass(this.document.getElementById('overlay-content'), 'overlay-content-ios');
          this.renderer.addClass(this.document.getElementById('overlay-footer'), 'overlay-footer-ios');
        }
      }

    }
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }

  trapFocusForHamburgerElements() {
    this.focusableElments = { 'overlayContent': { focusableElements: [] } };
    let selector = '';
    if (this.showStep == 1) {
      selector = 'app-quick-trade-stocks-landing';
    } else if (this.showStep == 2) {
      selector = 'app-quick-trade-stocks-verify';
    } else {
      selector = 'app-quick-trade-stocks-confirm';
    }
    this.commonService.setTabIndex('overlayContent', '', this.focusableElments, '', selector, '');
  }

  setHamburgerAriaAttribute() {
    this.desktopContainer = this.document.getElementById('page-content-container');
    this.headerContainer = this.document.getElementById('global-header');
    this.pageHeader = this.document.getElementById('page-header');
    this.globalNav = this.document.getElementById('global-nav');
    this.chatButton = this.document.querySelector('.genesys-container');
    this.thirdLevelNavigation = this.document.querySelector('#third-level-nav-dropdown option');
    this.thirdLevelNavDropdown = this.document.getElementById('third-level-nav-dropdown');
    this.pagefooter = this.document.getElementById('page-footer-wrapper');
    this.pageTitle = this.document.getElementById('focusElement');
    this.desktopContainer ? this.desktopContainer.setAttribute('aria-hidden', 'true') : '';
    this.headerContainer ? this.headerContainer.setAttribute('aria-hidden', 'true') : '';
    this.pageHeader ? this.pageHeader.setAttribute('aria-hidden', 'true') : '';
    this.thirdLevelNavDropdown ? this.thirdLevelNavDropdown.setAttribute('aria-hidden', 'true') : '';
    this.thirdLevelNavDropdown ? this.thirdLevelNavDropdown.setAttribute('tabindex', '-1') : '';
    this.globalNav ? this.globalNav.setAttribute('aria-hidden', 'true') : '';
    this.chatButton ? this.chatButton.setAttribute('aria-hidden', 'true') : '';
    this.pagefooter ? this.pagefooter.setAttribute('aria-hidden', 'true') : '';
    this.thirdLevelNavigation ? this.thirdLevelNavigation.setAttribute('aria-hidden', 'true') : '';
    this.pageTitle ? this.pageTitle.setAttribute('aria-hidden', 'true') : '';
  }

  removeAttributeTabIndex() {
    const focusableElementQuery =
      'a[href]:not([disabled]),' +
      'button:not([disabled]),textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="password"]:not([disabled]),' +
      'input[type="radio"]:not([disabled]), input[type="number"]:not([disabled]),' +
      'input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="tel"]:not([disabled])';
    const elementsQuickTrade = this.document.getElementsByClassName('sidebar-qt');
    if (elementsQuickTrade && elementsQuickTrade.length) {
      const focusableEls = elementsQuickTrade[0].querySelectorAll(focusableElementQuery);
      for (let i = 0; i < focusableEls.length; i++) {
        focusableEls[i].removeAttribute('tabindex');
      }
    }
  }

  undoTrapFocus() {
    const detectMobileIOS = this.getMobileOperatingSystem();
    if ((detectMobileIOS === 'Android' || detectMobileIOS === 'iOS') && this.isDesktop()) {
      const id = 'overlayContent';
      // untrap focus for hamburger
      this.modalService.trappedQT = false;
      this.commonService.undoTrapFocus(this.focusableElments[id].focusableElements, id);
      this.document.body.style.overflow = 'auto';
      this.modalService.addLog(Date.now().toString() + " : QT Close::");
      this.unsetHamBurgerAriaAttribute();
    }
  }

  closeFunction() {
    let targetEl = 'toggleQuickTrade';
    if (!this.isDesktop()) {
      targetEl = 'toggleQuickTradeLink';
    }
    const focusElement = document.getElementById(targetEl);
    if (focusElement) {
      setTimeout(() => {
        focusElement.focus();
      }, 200);
    }
  }

  unsetHamBurgerAriaAttribute() {
    this.desktopContainer ? this.desktopContainer.setAttribute('aria-hidden', 'false') : '';
    this.headerContainer ? this.headerContainer.setAttribute('aria-hidden', 'false') : '';
    this.footerContainer ? this.footerContainer.setAttribute('aria-hidden', 'false') : '';
    this.pageHeader ? this.pageHeader.setAttribute('aria-hidden', 'false') : '';
    this.thirdLevelNavDropdown ? this.thirdLevelNavDropdown.setAttribute('aria-hidden', 'false') : '';
    this.pagefooter ? this.pagefooter.setAttribute('aria-hidden', 'false') : '';
    this.globalNav ? this.globalNav.setAttribute('aria-hidden', 'false') : '';
    this.chatButton ? this.chatButton.setAttribute('aria-hidden', 'false') : '';
    this.thirdLevelNavigation ? this.thirdLevelNavigation.setAttribute('aria-hidden', 'false') : '';
    this.pageTitle ? this.pageTitle.setAttribute('aria-hidden', 'false') : '';
  }

  setAttributeTabIndex() {
    const focusableElementQuery =
      'a[href]:not([disabled]),' +
      'button:not([disabled]),textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="password"]:not([disabled]),' +
      'input[type="radio"]:not([disabled]), input[type="number"]:not([disabled]),' +
      'input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="tel"]:not([disabled])';
    const elementsQuickTrade = this.document.getElementsByClassName('sidebar-qt');
    if (elementsQuickTrade && elementsQuickTrade.length) {
      const focusableEls = elementsQuickTrade[0].querySelectorAll(focusableElementQuery);
      for (let i = 0; i < focusableEls.length; i++) {
        focusableEls[i].setAttribute('tabindex', '-1');
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  closeDrawer() {
    //this.moveChatIconBack();
    this.subscriptionService.openOrCloseDrawer.next('close');
  }

  //move chat icon to right
  moveChatIconBack() {
    // let chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.removeClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.removeClass(kampyleButton, 'move-kampyle');
    // }
  }

  backClick() {
    this.subscriptionService.setTradeDrawerPosition(1);
  }

  setFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 200);
  }
}