import { Injectable } from '@angular/core';
import { UpdatePasswordConfirmData } from './update-password-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class UpdatePasswordConfirmStore extends PageStore<UpdatePasswordConfirmData> {

    constructor() {
        super(new UpdatePasswordConfirmData());
    }

}
