// replaced dyamically during build by jenkins

export interface BuildInfo {
    id: string;
    num: string;
    branch: string;
    tag: string;
}

export const buildInfo: BuildInfo = {
    id: '181173',
    num: '20250331.4',
    branch: 'ticker_tape_event_calendar',
    tag: '1.0.364-snapshot-sit-both'
};

