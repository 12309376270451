import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
//import { PageService } from '../../../../../shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
//import { AppStore } from '../../../../../shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
//import { HttpService } from '../../../../../core/services/http.service'
import {SellGicVerifyStore} from '../data/sell-gic-verify-store';
import { Observable } from 'rxjs';
import { isDefined } from 'src/app/shared/services/utils.service';

declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class SellGicVerifyService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    
    let ser = input[0];
    ser.input = isDefined(SellGicVerifyStore.inFlow) ? SellGicVerifyStore.inFlow.params : {};
    return input;
  }

  getOtvcRequest = (): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.getOtvcRequest.url,
      {}
    );
  };

  forgotTradingPasswordInit = (): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.forgotTradingPasswordInit.url,
      null
    );
  };

}
