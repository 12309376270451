import { Injectable } from '@angular/core';
import { Observable, empty, forkJoin, observable, ObservableInput } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Lob } from 'src/app/shared/models/lob.enum';
import { ROOT_CTX } from 'src/app/shared/models/user';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { PageStore } from 'src/app/shared/models/page-store';
import { routeConfig } from 'src/app/config/router-mapping';
import { RelateBrokerageAccountsLandingStore } from '../data/relate-brokerage-accounts-landing.store';



@Injectable({
  providedIn: 'root'
})
export class RelateBrokerageAccountsLandingService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    return input;
  }

}
