import { BaseData } from 'src/app/shared/models/base-data';

export class QuickTradeStocksLandingData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1,
      action: "",
      symbol: "",
      stockHoldingIndex: -1,
      market: "",
      quantity: undefined,
      quantityValue: undefined,
      trailingMode: "",
      priceMode: "",
      limit: undefined,
      stopPrice: undefined,
      stopLimit: undefined,
      triggerDollarPrice: undefined,
      triggerPercentagePrice: undefined,
      limitOffsetDollar: undefined,
      expiryMode: undefined,
      extendedHours: false,
      settlementIndex: -1,
      dateDay: 1,
      dateMonth: 0
    };
  }
}

export interface QuickTradeStocksLandingInputs {
  accountIndex: number;
  action: string;
  symbol: string;
  stockHoldingIndex: number;
  market: string;
  quantity: number;
  quantityValue: number,
  trailingMode: string;
  priceMode: string;
  limit: number;
  stopPrice: number;
  stopLimit: number;
  triggerDollarPrice: number;
  triggerPercentagePrice: number;
  limitOffsetDollar: number;
  expiryMode: string;
  extendedHours: boolean;
  settlementIndex: number;
  dateDay: number;
  dateMonth: number;
}

export class SettlementAccountsReq {
  AccountNumber: string;
  Action: string;
  AccountCurrency: string;
  Type: string;
}

export class AccountHoldingsReq {
  AccountNumber: string;
  Action: string;
  Refresh: boolean;
  Clean: boolean;
  SearchOptions: {
    StartRow: number;
    NumberOfResult: number;
    SearchFilter: string;
  };
}

export class BuyingPowerReq {
  AccountNumber: string;
}
