import { Injectable } from "@angular/core";
import { PageStore } from "src/app/shared/models/page-store";
import { W8BenVerifyData } from "./w8Ben-verify.data";


@Injectable({providedIn: 'root'})
export class W8BenVerifyStore extends PageStore<W8BenVerifyData> {
    constructor() {
        super(new W8BenVerifyData())
    }
}