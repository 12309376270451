import { RouteGroup } from '../shared/models/route-config';

export const EDocumentsConfig: RouteGroup = {

    '/txn/edocuments': {
        name: 'edocuments',
        service: 'EdocumentsService',
        servicePath: "txn/edocuments/services/edocuments",
        store: 'EdocumentsStore',
        resolveContent: 'edocuments',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [{
                id: 'init',
                restUrl: 'eDocInit',
                get request(): any {
                    return {
                    };
                }
        }],
        trackingData: {
            name: 'account-statements',
            nameGA: 'Account Statements',
            hierarchy: "accounts,edocuments",
            events: { pageView: true }
        }
    }
};
