import { Injectable } from '@angular/core';
import { OptionChainData } from './option-chain-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable()
export class OptionChainStore extends PageStore<OptionChainData> {

    constructor() {
        super(new OptionChainData());
    }

}
