import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PageService } from 'src/app/shared/services/page-service';
import { ChangeCashPlanVerifyStore } from "../data/change-cash-plan-verify-store";

@Injectable({
    providedIn: 'root'
})
export class ChangeCashPlanVerifyService implements PageService {

    constructor() { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        ser.input = ChangeCashPlanVerifyStore.params;
        return input;
    }
    cashPlanVerifyForm(form, fb) {
        form = fb.group({
            agreeProceed: false,
            tradingPassword: "",
            saveTradingPassword: false
        });
        return form;
    }


}