import { FormControl, FormGroup } from "@angular/forms";
import { FormValidatorFactory } from "../../services/cibc.formvalidator";
import { isDefined } from "../../services/utils.service";

export class OptionChainValidator {
    static createForm(form, fb) {
      const grp = {
        toggleChart : [''],
        optionStrikePriceIndex: [],
        optionStrikeDateIndex: [],
        strikeOption: [''],
        optionType: [''],
        manualOptionType: ['']
      }
      form = fb.group(grp);
  
      return form;
    }

    static emptyValidators(form) {
        if(form.controls['optionStrikePriceIndex']) {
          form.controls['optionStrikePriceIndex'].setValidators([]);
        }
        // form.controls['toggleChart'].setValidators([]);
        if(form.controls['optionStrikeDateIndex']) {
          form.controls['optionStrikeDateIndex'].setValidators([]);
        }
        if(form.controls['manualOptionType']) {
          form.controls['manualOptionType'].setValidators([]);
        }
        // form.controls['strikeOption'].setValidators([]);
        // form.controls['optionType'].setValidators([]);
    }

    static setValidators(form)  {

        // optionStrikeDateIndex
        // optionStrikePriceIndex
        // manualOptionType
        if(form.controls['optionStrikeDateIndex']) {
          form.controls['optionStrikeDateIndex'].setValidators([FormValidatorFactory.createGreaterThanValidator('MSGOPT0052', -1)]);
        }
        if(form.controls['optionStrikePriceIndex']) {
          form.controls['optionStrikePriceIndex'].setValidators([FormValidatorFactory.createGreaterThanValidator('MSGOPT0053', -1)]);
        }
        if(form.controls['manualOptionType']) {
          form.controls['manualOptionType'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGOPT0055')]);
        }
    }

    static validate(form) {
        Object.keys(form.controls).forEach(field => {
          const control = form.controls[field];
          if (control instanceof FormControl) {
            control.updateValueAndValidity();
          } else if (control instanceof FormGroup) {
            this.validate(control);
          }
        });
    }

    static resolveValidationError(form, errorContent) {
        const messges = [];
        // messges.push(
        //   {
        //     controlName: 'accountNumber',
        //     code: form.controls['accountNumber'].errors
        //   });
        // if (form.controls['strikeDateIndex']) {
          messges.push(
            {
              controlName: 'optionStrikeDateIndex',
              code: form.controls['optionStrikeDateIndex'].errors
            });
          messges.push(
            {
              controlName: 'optionStrikePriceIndex',
              code: form.controls['optionStrikePriceIndex'].errors
            });
          messges.push(
            {
              controlName: 'manualOptionType',
              code: form.controls['manualOptionType'].errors
            });
        // }
        return this.setErrorMessage(messges, errorContent);
      }

      static setErrorMessage(results, errorContent) {
        const error = {};
        results.forEach(result => {
          if (result.code !== null) {
            if (isDefined(result.code.errorCode)) {
              error[result.controlName] = errorContent[result.code.errorCode];
            }
            console.log(JSON.stringify(result));
          }
        });
        return error;
      }

      static processRestFormErrors(formError) {
        // const error = {};
        // switch (formError.error.Category) {
        //   case 'accountNumber':
        //     error['accountNumber'] = formError.error.Errors[0].ErrorMessage
        //   case 'actionMode':
        //     error['actionMode'] = formError.error.Errors[0].ErrorMessage
        //   case 'marketMode':
        //     error['marketMode'] = formError.error.Errors[0].ErrorMessage
        //   case 'optionHoldingIndex':
        //     error['optionHoldingIndex'] = formError.error.Errors[0].ErrorMessage
        // }
        // return error;
      }


  static resetErrorMessage(form, fb, errorContent) {
    console.log('RESETTING THE FORM');
    const messges = [];
    messges.push(
      {
        controlName: 'optionStrikeDateIndex',
        code: form.controls['optionStrikeDateIndex'].errors
      });
    messges.push(
     {
          controlName: 'optionStrikeDateIndex',
          code: form.controls['optionStrikeDateIndex'].errors
      });
    messges.push(
      {
        controlName: 'manualOptionType',
        code: form.controls['manualOptionType'].errors
      });
    const error = {};
    messges.forEach(result => {

      error[result.controlName] = undefined;
      console.log('ControlName' + result.controlName);

    });
    return error;
  }
}