import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { RegularInvestmentPlanData } from './regular-investment-plan-data';

@Injectable({ providedIn: 'root' })
export class RegularInvestmentPlanStore extends PageStore<RegularInvestmentPlanData>  {

    constructor() {
        super(new RegularInvestmentPlanData());
    }

}
