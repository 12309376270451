import { Injectable } from '@angular/core';
import { LinkBankAccountsConfirmData } from './link-bank-accounts-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class LinkBankAccountsConfirmStore extends PageStore<LinkBankAccountsConfirmData> {

    constructor() {
        super(new LinkBankAccountsConfirmData());
    }

}
