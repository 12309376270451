import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SellBondLandingStore } from '../data/sell-bond-landing-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class SellBondLandingService implements PageService {

  constructor(private httpService: HttpService) { }

  prepareInput(input: any): any {
    let ser = input[0];
    if (SellBondLandingStore.savedInfo) {
      ser.input = SellBondLandingStore.savedInfo;
    } else {
      ser.input = SellBondLandingStore.inFlow;
    }
    return input;
  }

  getSettlementAccounts(req) {
    return this.httpService.post(gatewayConfig.APIServices.getBuyBondsSettlementAccounts.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  updateCalculateBy(req) {
    return this.httpService.post(gatewayConfig.APIServices.buyBondsSubmit.url,
      req,
    );
  }

}
