import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SettlementAccountsReq, AccountHoldingsReq, TradeStocksLandingInputs, BuyingPowerReq } from '../data/trade-stocks-landing-data';
import { TradeStocksLandingStore } from '../data/trade-stocks-landing-store';
import { HttpHeaders } from '@angular/common/http';
import { TradeStocksConfirmStore } from '../../confirm/data/trade-stocks-confirm-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class TradeStocksLandingService implements PageService {

  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (TradeStocksLandingStore.savedInfo) {
      const info: TradeStocksLandingInputs = TradeStocksLandingStore.savedInfo.inputs;
      const accNum = TradeStocksLandingStore.savedInfo.accountNumber;
      const curr = TradeStocksLandingStore.savedInfo.accountCurrency;
      if (info.action === 'BUY' || info.action === 'SHORT') {
        const ret = [input[0], input[1], input[3], input[4]];
        ret[2] = this.prepareSett(ret[2], accNum, info.action, curr);
        ret[3] = this.prepareQuick(ret[3], info.symbol, info.market);

        return ret;
      } else if (info.action === 'SELL' || info.action === 'BUYTOCOVER') {
        const ret = input;
        ret[2] = this.prepareHoldings(ret[2], accNum, info.action);
        ret[3] = this.prepareSett(ret[3], accNum, info.action, curr);
        ret[4] = this.prepareQuick(ret[4], info.symbol, info.market);

        return ret;
      } else {
        const ret = [input[0], input[1]];
        return ret;
      }
    } else if (TradeStocksLandingStore.crossFlow) {
      const crossFlow = TradeStocksLandingStore.crossFlow; // {action?, accountNumber, symbol, market}
      if (crossFlow.action) {
        if (crossFlow.action === 'BUY' || crossFlow.action === 'SHORT') {
          const ret = [input[0], input[1], input[4]];
          //ret[1] = this.prepareSett(ret[1], crossFlow.accountNumber, 'BUY', undefined);
          ret[2] = this.prepareQuick(ret[2], crossFlow.symbol, crossFlow.market);

          return ret;
        } else if (crossFlow.action === 'SELL') {
          const ret = [input[0], input[1], input[2], input[4]];
          ret[2] = this.prepareHoldings(ret[2], crossFlow.accountNumber, 'SELL');
          //ret[2] = this.prepareSett(ret[2], crossFlow.accountNumber, 'SELL', crossFlow.currency);
          ret[3] = this.prepareQuick(ret[3], crossFlow.symbol, crossFlow.market);

          return ret;
        } else if (crossFlow.action === 'BUYTOCOVER') {
          const ret = [input[0], input[1], input[2], input[4]];
          ret[2] = this.prepareHoldings(ret[2], crossFlow.accountNumber, 'BUYTOCOVER');
          //ret[2] = this.prepareSett(ret[2], crossFlow.accountNumber, 'BUYTOCOVER', crossFlow.currency);
          ret[3] = this.prepareQuick(ret[3], crossFlow.symbol, crossFlow.market);

          return ret;
        } else {
          const ret = [input[0], input[1]];
          return ret;
        }
      } else {
        const ret = [input[0], input[1], input[4]];
        ret[2] = this.prepareQuick(ret[2], crossFlow.symbol, crossFlow.market);

        return ret;
      }
    } else {
      const ret = [input[0], input[1]];
      return ret;
    }
  }

  prepareHoldings(input, accNum, action) {
    const ret = input.input;

    ret.AccountNumber = accNum;
    ret.Action = action;

    input.input = ret;
    return input;
  }

  prepareSett(input, accNum, action, curr) {
    const ret = input.input;

    ret.AccountNumber = accNum;
    ret.Action = action;
    if (curr) {
      ret.AccountCurrency = curr;
    }

    input.input = ret;
    return input;
  }

  prepareQuick(input, symbol, market) {
    const ret = input.input;

    ret.Symbols[0].SymbolName = symbol;
    ret.Symbols[0].Market = market;

    input.input = ret;
    return input;
  }

  prepareConfirm(input, token, pass?, save?) {
    const ret = input.input;
    if (pass) {
      ret.TradingPassword = pass;
      ret.SaveTradingPassword = save;
    }
    ret.Token = token;

    input.input = ret;
    return input;
  }

  getSettlementAccounts(data) {
    const req: SettlementAccountsReq = new SettlementAccountsReq();
    req.Action = data.Action;
    req.AccountNumber = data.AccountNumber;
    req.AccountCurrency = data.AccountCurrency;
    req.Type = data.Type;
    return this.httpService.post(gatewayConfig.APIServices.stocksSettlementAccounts.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getAccountHoldings(data) {
    const req: AccountHoldingsReq = new AccountHoldingsReq();
    req.Action = data.Action;
    req.AccountNumber = data.AccountNumber;
    req.Clean = data.Clean;
    req.Refresh = data.Refresh;
    req.SearchOptions = data.SearchOptions;
    req.FiType = "E";
    return this.httpService.post(gatewayConfig.APIServices.getOrderHoldings.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getBuyingPower(data) {
    const req: BuyingPowerReq = new BuyingPowerReq();
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.buyingPowerRetrieve.url,
      req, { params: { skiploading: 'true' } }
    );
  }
}
