import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "src/app/core/services/http.service";
import { ROOT_CTX } from "src/app/shared/models/user";
declare let require: any;
const gateway = require("../../../../../../config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})

export class W8BenConfirmService {
    constructor(private _http: HttpService, private httpClient: HttpClient) { }

    pdfEmail() {
        return this._http.get(gateway.APIServices.w8BenPDFEmail.url)
    }

    getPdf(token) {
        return this.httpClient.get(ROOT_CTX + gateway.APIServices.webFormDownload.url + token + '?silent=1', { responseType: 'blob' });
    }
}