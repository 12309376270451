import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CancelMutualFundPlanLandingData } from './cancel-mutual-fund-plan-landing-data';

@Injectable({ providedIn: 'root' })
export class CancelMutualFundPlanLandingStore extends PageStore<CancelMutualFundPlanLandingData>  {

    constructor() {
        super(new CancelMutualFundPlanLandingData());
    }



}