import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { ContentService } from '../../../../core/services/content.service';
import { Lob } from '../../../models/lob.enum';
import { AppStore } from '../../../models/app-store';
import { Subscription, of } from 'rxjs';
declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");
@Injectable({
  providedIn: 'root'
})
export class CommonChatService implements OnDestroy {
  subscriptions = new Subscription();

  constructor(
    private httpService: HttpService,
    private contentService: ContentService,
    private appStore: AppStore
  ) { }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  chatMetadata(url, req) {
    return this.httpService.post(url, req, { params: { skiploading: 'true' } });
  }

  updateAccessibilityText() {
    this.subscriptions.add(this.contentService.fetchContent('chat').subscribe((chatContent) => {
      if (chatContent) {
        if ((window as any).isiChatConfig) {
          (window as any).isiChatConfig.accessibility.chatOnline = chatContent.accessibilityChatOnline;
          (window as any).isiChatConfig.accessibility.chatOffline = chatContent.accessibilityChatOffline;
        }
      }
    }));
  }

  isChatCallEligible() {
    return (window as any).enableGenesysChat && 
      !this.appStore.isApp('app') && // Not a wrapped app
      this.appStore.state.user.lob === Lob.IE && // IE lob only
      window.location.href.indexOf('/help') < 0 && // Ignore help URL as chat is not applicable as per business rules
      window.location.href.indexOf('/aem') < 0 && // Ignore aem urls as it is a transition url, not an actual page
      window.location.href.indexOf('/sso') < 0 && // Ignore sso urls as it is a transition url, not an actual page
      window.location.href.indexOf('/olbsso') < 0 && // Ignore sso urls as it is a transition url, not an actual page
      this.appStore.isLoggedIn();
  }

  hideAccessibilityForModal(hideAccessibility: boolean) {
    //TODO: Update class name for geneysys
    const chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    if (chatIconDivContainer) {
      if (hideAccessibility) {
        (chatIconDivContainer as any as HTMLElement).setAttribute('tabindex', '-1');
        (chatIconDivContainer as any as HTMLElement).setAttribute('aria-hidden', 'true');
      } else {
        (chatIconDivContainer as any as HTMLElement).removeAttribute('tabindex');
        (chatIconDivContainer as any as HTMLElement).removeAttribute('aria-hidden');
      }
    }
  }

  genesysPreSignonChatSetup(req) {
    //return of({LandingUrl:'https://www.google.com', EncryptedChatToken: 'xyz'});
    return this.httpService.post(gatewayConfig.APIServices.getChatGuestMetadata.url, req, { params: { skiploading: 'true' } });
  }
  genesysPostSignonChatSetup(req) {
    //return of({LandingUrl:'https://www.google.com', EncryptedChatToken: 'xyz'});
    return this.httpService.post(gatewayConfig.APIServices.getChatUserMetadata.url, req, { params: { skiploading: 'true' } });
  }

  extendSession() {
    return this.httpService.post(gatewayConfig.APIServices.getSiteStateExtendSession.url, {});
  }
}