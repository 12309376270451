<div class="overlay"></div>
<div *ngIf="navigationObject && content" class="overlay-content" id="overlayContent">
  <div class="overlay-header" [ngClass]="(Lob === 'ie' && PEEnabled) ? 'PE-Banner' : ''">
    <img class="cibcLogo" id="CIBC-logo" *ngIf="Lob != 'ie'"
      [src]="(Lob === 'wg' || Lob === 'cpic') ? 'assets/images/logos/cibc_'+Lob+'_'+lang+'.svg' : 'assets/images/logos/cibc_'+Lob+'_'+lang+'_mobile.svg'"
      [alt]="globalContent.text.logo" tabindex="-1">
    <img class="cibcLogo" id="CIBC-logo" *ngIf="Lob == 'ie'"
      [src]="(Lob === 'ie' && PEEnabled) ? (lang === 'en') ? 'assets/images/logos/Premium Edge_REV_'+lang+'.svg' : 'assets/images/logos/Premium Edge_REV_FRE.svg' : 'assets/images/logos/cibc_'+Lob+'_'+lang+'_mobile.svg'"
      [alt]="globalContent.text.logo" tabindex="-1">
    <button class="closeButton" id="close-button" (click)="closeFunction('')"
      attr.aria-label="{{globalContent.text.close}}">
      <div class="icon-Close closeIcon" [ngClass]="(Lob === 'ie' && PEEnabled) ? 'color01' : ''" aria-hidden="true">
      </div>
    </button>
  </div>

  <div class="overlay-body" [ngClass]="{'ie-overlay': checkLob('IE')}">
    <div class="switchLobContainer" [ngClass]="(Lob === 'ie' && PEEnabled) ? 'PE-Banner' : ''">
      <ng-container *ngIf="sessionInfo.UserLobs.length > 1">
        <div class="A14" [ngClass]="(Lob === 'ie' && PEEnabled) ? 'color01' : 'color03'">
          {{globalContent.text.switchLobLabel}}</div>
        <div class="select-item switchGlobalLob A14b color02">
          <select id="hamburger_lobList_id" attr.aria-label="{{globalContent.text.switchLobAlt}}" (change)="SwitchGlobalLob($event.target.value)">
            <option value="{{loblist}}" [selected]="loblist == currentLob" *ngFor="let loblist of sessionInfo.UserLobs"
              attr.aria-label="{{content.text[loblist+'Label']}}">
              {{content.text[loblist]}}</option>
          </select>
          <span class="select-icon icon-Arrow-Down color03" aria-hidden="true">
          </span>
        </div>
      </ng-container>
      <a id="hamburger_webOnly_id" *webOnly href="javascript:void(0)" class="onlineBankingLink" (click)="goToOnlineBanking()">
        <span class="A14u"
          [ngClass]="(Lob === 'ie' && PEEnabled) ? 'color01' : 'color02'">{{globalContent.text.onlineBanking}}</span>
        <span class="icon-Chevron-Right chevronRightBanking"
          [ngClass]="(Lob === 'ie' && PEEnabled) ? 'color06' : 'color03'"></span>
      </a>

      <div id="hamburger_notificationMastHead_id" class="notificationMastHead" *ngIf="!isApp && appStore.globalAlert.edocs && !checkLob('IE')">
        <img src="/assets/obr2/images/icons/New_Edocs.svg" class="eDocs-img" alt="Edocs" aria-hidden="true">
        <div class="eDocumentsHeader">
          <a routerLink="/txn/edocuments" class="A12 color02">{{globalContent.text.eDocs}}</a>
        </div>
      </div>
    </div>

    <div *ngFor="let menuContent of navigationObject; let i = index">
      <ng-container *ngIf="menuContent.secondaryLinks.length <= 1 || !checkLob('IE')">
        <a id="hamburger_mainNavInteraction_id-{{i}}" *ngIf="menuContent.secondaryLinks[0].externalLink" href="javascript:void(0);"
          (click)="openWindow(menuContent.secondaryLinks[0]);closeFunction(menuContent.url);mainNavInteraction(menuContent.omniName)"
          class="primaryNavLink alignFlex" routerLinkActive [ngClass]="{'active-link': menuContent.isActive}">
          <div aria-hidden="true" class='{{menuContent.icon}} iconFont'
            [ngClass]="{'activeFontColor': menuContent.isActive, 'defaultFontColor': !menuContent.isActive}"></div>
          <div class="W16m" style='text-decoration:none' attr.aria-label="{{menuContent.category}}">
            <span>{{menuContent.category}}</span>
            <span *ngIf="menuContent.showNewIcon && menuContent.showNewIcon === 'true'" aria-hidden="true"
              class='new-content-red-box'>{{moreContent.text.new}}</span>
          </div>
        </a>
        <a id="hamburger_primaryNavLink_id-{{i}}" *ngIf="!menuContent.secondaryLinks[0].externalLink"
          [routerLink]="[menuContent.secondaryLinks[0].childrenUrls[0]]"
          (click)="closeFunction(menuContent.url);mainNavInteraction(menuContent.omniName)"
          class="primaryNavLink alignFlex" routerLinkActive [ngClass]="{'active-link': menuContent.isActive}">
          <div aria-hidden="true" class='{{menuContent.icon}} iconFont'
            [ngClass]="{'activeFontColor': menuContent.isActive, 'defaultFontColor': !menuContent.isActive}"></div>
          <div class="W16m" style='text-decoration:none' attr.aria-label="{{menuContent.category}}">
            <span>{{menuContent.category}}</span>
            <span *ngIf="menuContent.showNewIcon && menuContent.showNewIcon === 'true'" aria-hidden="true"
              class='new-content-red-box'>{{moreContent.text.new}}</span>
          </div>
        </a>
      </ng-container>
      <button id="hamburger_current_dropdown_id-{{i}}" *ngIf="menuContent.secondaryLinks.length > 1 && checkLob('IE')" (click)="primaryClick(i, menuContent.url)"
        class="primaryNavLink alignFlex" [ngClass]="{'active-link': menuContent.isActive}"
        attr.aria-expanded="{{i == currentDropDown}}">
        <div aria-hidden="true" class='{{menuContent.icon}} iconFont'
          [ngClass]="{'activeFontColor': menuContent.isActive, 'defaultFontColor': !menuContent.isActive}"></div>
        <div class="W16m" style='text-decoration:none'
          attr.aria-label="{{menuContent.category}} {{i == currentDropDown ? content.text.collapse : content.text.expand}}">
          <span>{{menuContent.category}}</span>
          <span *ngIf="menuContent.showNewIcon && menuContent.showNewIcon === 'true'" aria-hidden="true"
            class='new-content-red-box'>{{moreContent.text.new}}</span>
        </div>
        <div class="navLinkSpacer" aria-hidden="true"></div>
        <div *ngIf="checkLob('IE')" [attr.id]="'hamburger-chevron-' + i" aria-hidden="true"
          class="animatedChevron icon-Chevron-Up"></div>
      </button>
      <ul *ngIf="menuContent.secondaryLinks.length > 1  && i == currentDropDown">
        <ng-container *ngFor="let subLevelMenuItem of menuContent.secondaryLinks; let j = index">
          <li class="secondaryNavLink alignFlex" routerLinkActive="sub-nav-container-active-link"
          *ngIf="subLevelMenuItem.externalLink || !(!showOptionCentre && subLevelMenuItem.childrenUrls[0] === '/txn/quotesResearch/optionCentre')">
          <button *ngIf="checkLob('IE') && subLevelMenuItem.thirdLevelNavigation; else noThirdLevelNav" class="color02"
            [ngClass]="{'sub-nav-active-link': subLevelMenuItem.isSecondaryLinkActive && checkLob('IE'), 'A14': !checkLob('IE'), 'W14m': checkLob('IE')}"
              routerLinkActive attr.aria-label="{{subLevelMenuItem.displayName}}" (click)="closeFunction()"
              routerLink="{{subLevelMenuItem.childrenUrls[0]}}">
              <span>{{subLevelMenuItem.displayName}}</span>
              <span *ngIf="subLevelMenuItem.indicator && appStore.globalAlert[subLevelMenuItem.indicator]" attr.aria-hidden="true" class="red-indicator"></span>
              <div class="navLinkSpacer" aria-hidden="true"></div>
            </button>
            <ng-template #noThirdLevelNav>
              <ng-container *ngIf="!subLevelMenuItem.externalLink; else externalHamburger">
                <a *ngIf="!(subLevelMenuItem.showNewIcon || subLevelMenuItem.showNewIcon === 'true')" class="color02"
                  [ngClass]="{'sub-nav-active-link': subLevelMenuItem.isSecondaryLinkActive && checkLob('IE'), 'A14': !checkLob('IE'), 'W14m': checkLob('IE')}"
                  routerLink="{{subLevelMenuItem.childrenUrls[0]}}" routerLinkActive
                  attr.aria-label="{{subLevelMenuItem.displayName}}"
                  (click)="closeFunction();secondLevelNavInteraction(menuContent.omniName,subLevelMenuItem.omniName)">
                  <span>{{subLevelMenuItem.displayName}}</span>
                  <span *ngIf="subLevelMenuItem.indicator && appStore.globalAlert[subLevelMenuItem.indicator]"
                    attr.aria-hidden="true" class="red-indicator"></span>
                </a>
                <a *ngIf="subLevelMenuItem.showNewIcon || subLevelMenuItem.showNewIcon === 'true'" class="color02"
                  [ngClass]="{'sub-nav-active-link': subLevelMenuItem.isSecondaryLinkActive && checkLob('IE'), 'A14': !checkLob('IE'), 'W14m': checkLob('IE')}"
                  routerLink="{{subLevelMenuItem.childrenUrls[0]}}" routerLinkActive
                  attr.aria-label="{{subLevelMenuItem.displayName+' '+moreContent.text.new}}"
                  (click)="closeFunction();secondLevelNavInteraction(menuContent.omniName,subLevelMenuItem.omniName)">
                  <span>{{subLevelMenuItem.displayName}}</span>
                  <span aria-hidden="true" class='new-content-red-box'>{{moreContent.text.new}}</span>
                </a>
              </ng-container>
              <ng-template #externalHamburger>
                <a *ngIf="!(subLevelMenuItem.showNewIcon || subLevelMenuItem.showNewIcon === 'true')" class="color02"
                  [ngClass]="{'sub-nav-active-link': subLevelMenuItem.isSecondaryLinkActive && checkLob('IE'), 'A14': !checkLob('IE'), 'W14m': checkLob('IE')}"
                  href="javascript:void(0)" routerLinkActive attr.aria-label="{{subLevelMenuItem.displayName}}"
                  (click)="openWindow(subLevelMenuItem);closeFunction();secondLevelNavInteraction(menuContent.omniName,subLevelMenuItem.omniName)">
                  <span>{{subLevelMenuItem.displayName}}</span>
                  <span *ngIf="subLevelMenuItem.indicator && appStore.globalAlert[subLevelMenuItem.indicator]"
                    attr.aria-hidden="true" class="red-indicator"></span>
                </a>
                <a *ngIf="subLevelMenuItem.showNewIcon || subLevelMenuItem.showNewIcon === 'true'" class="color02"
                  [ngClass]="{'sub-nav-active-link': subLevelMenuItem.isSecondaryLinkActive && checkLob('IE'), 'A14': !checkLob('IE'), 'W14m': checkLob('IE')}"
                  href="javascript:void(0)" routerLinkActive
                  attr.aria-label="{{subLevelMenuItem.displayName+' '+moreContent.text.new}}"
                  (click)="openWindow(subLevelMenuItem);closeFunction();secondLevelNavInteraction(menuContent.omniName,subLevelMenuItem.omniName)">
                  <span>{{subLevelMenuItem.displayName}}</span>
                  <span aria-hidden="true" class='new-content-red-box'>{{moreContent.text.new}}</span>
                </a>
              </ng-template>
            </ng-template>
          </li>
          <ng-container *ngIf="checkLob('IE') && subLevelMenuItem.thirdLevelNavigation">
            <ng-container *ngFor="let thirdLevelNavItem of subLevelMenuItem.thirdLevelNavigation;">
              <li class="secondaryNavLink alignFlex" routerLinkActive="sub-nav-container-active-link" [routerLinkActiveOptions]="{exact: true}">
                <a class="color02 W14m" routerLink="{{thirdLevelNavItem.url}}" routerLinkActive="sub-nav-active-link" attr.aria-label="{{thirdLevelNavItem.displayName}}" [routerLinkActiveOptions]="{exact: true}"
                (click)="closeFunction();secondLevelNavInteraction(menuContent.omniName,subLevelMenuItem.omniName)">
                  <span class="paddingLeft20">{{thirdLevelNavItem.displayName}}</span>
                  <span *ngIf="thirdLevelNavItem.showNewIcon || thirdLevelNavItem.showNewIcon === 'true'" aria-hidden="true" class='new-content-red-box'>{{moreContent.text.new}}</span>
                </a>
              </li>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>
    </div>
    <div *ngIf="moreContent.list.dcoMenuItem">
      <a id="hamburger_moreContent_link_id" href="javascript:void(0);"
        (click)="openWindow(moreContent.list.dcoMenuItem);closeFunction(moreContent.list.dcoMenuItem.url);mainNavInteraction(moreContent.list.dcoMenuItem.omniName)"
        class="primaryNavLink alignFlex" routerLinkActive
        [ngClass]="{'active-link': moreContent.list.dcoMenuItem.isActive}">
        <div aria-hidden="true" class='{{moreContent.list.dcoMenuItem.icon}} iconFont'
          [ngClass]="{'activeFontColor': moreContent.list.dcoMenuItem.isActive, 'defaultFontColor': !moreContent.list.dcoMenuItem.isActive}">
        </div>
        <div class="W16m" style='text-decoration:none' attr.aria-label="{{moreContent.list.dcoMenuItem.category}}">
          <span>{{moreContent.list.dcoMenuItem.category}}</span>
        </div>
      </a>
    </div>

    <div class="hamburgerMoreLinks alignFlex">
      <div class="pd-hamburgerLinks" *ngIf="Lob == 'ie'">
        <a id="hamburger_openNews_link_id" class="W16m color03" (click)="closeFunction(); openNews()"
          href="javascript:void(0);">{{globalContent.text.whatsNew}} <span
            class="sr-only">{{globalContent.text.whatsNewAria}}</span></a>
      </div>
      <span *ngIf="userMode == 'PROD'">
        <ul *ngFor="let menuListItem of moreContent.list.moreLinks[0].secondaryLinksOld let j = index">
          <li class="pd-hamburgerLinks">
            <a id="hamburger_close_fnc_link_id-{{j}}" class="W16m color03" (click)="closeFunction()" routerLink="{{menuListItem.url}}"
              *ngIf="menuListItem.url !== 'outgoing' && !menuListItem.external && menuListItem.url !== '/txn/userPreferences'"
              attr.aria-label="{{menuListItem.displayName}} + {{menuListItem.srText}}">
              {{menuListItem.displayName}}
              <div aria-hidden="true" *ngIf="menuListItem.icon" class='{{menuListItem.icon}} iconFontMore'></div>
            </a>
            <a id="hamburger_navUserPref_link_id-{{j}}" class="W16m color03" (click)="navUserPref();closeFunction()"
              *ngIf="menuListItem.url !== 'outgoing' && !menuListItem.external && menuListItem.url === '/txn/userPreferences'"
              href="javascript:void(0)" attr.aria-label="{{menuListItem.displayName}} + {{menuListItem.srText}}">
              {{menuListItem.displayName}}
              <div aria-hidden="true" *ngIf="menuListItem.icon" class='{{menuListItem.icon}} iconFontMore'></div>
            </a>
            <a id="hamburger_advisor_link_id-{{j}}" class="W16m color03" (click)="goToAdvisorPage()" *ngIf="menuListItem.url === 'outgoing'"
              href="javascript:void(0)" attr.aria-label="{{menuListItem.displayName}} + {{menuListItem.srText}} + {{menuListItem.srText}}">
              {{menuListItem.displayName}}
              <div aria-hidden="true" *ngIf="menuListItem.icon" class='{{menuListItem.icon}} iconFontMore'></div>
            </a>
            <a id="hamburger_close_fnc_id-{{j}}" class="W16m color03" (click)="openWindow(menuListItem);closeFunction()" *ngIf="menuListItem.external"
              href="javascript:void(0)" attr.aria-label="{{menuListItem.displayName}} + {{menuListItem.srText}} + {{menuListItem.srText}}">
              {{menuListItem.displayName}}
              <div aria-hidden="true" *ngIf="menuListItem.icon" class='{{menuListItem.icon}} iconFontMore'></div>
            </a>
          </li>
        </ul>
      </span>

      <span *ngIf="userMode == 'PILOT'">
        <ul *ngFor="let menuListItem of moreContent.list.moreLinks[0].secondaryLinks let j = index">
          <li class="pd-hamburgerLinks">
            <a id="hamburger_close_fnc_link_id-{{j}}" class="W16m color03" (click)="closeFunction()" routerLink="{{menuListItem.url}}"
              *ngIf="menuListItem.url !== 'outgoing' && !menuListItem.external && menuListItem.url !== '/txn/userPreferences'"
              attr.aria-label="{{menuListItem.displayName}} + {{menuListItem.srText}}">
              {{menuListItem.displayName}}
              <div aria-hidden="true" *ngIf="menuListItem.icon" class='{{menuListItem.icon}} iconFontMore'></div>
            </a>
            <a id="hamburger_navUserPref_link_id-{{j}}" class="W16m color03" (click)="navUserPref();closeFunction()"
              *ngIf="menuListItem.url !== 'outgoing' && !menuListItem.external && menuListItem.url === '/txn/userPreferences'"
              href="javascript:void(0)" attr.aria-label="{{menuListItem.displayName}} + {{menuListItem.srText}}">
              {{menuListItem.displayName}}
              <div aria-hidden="true" *ngIf="menuListItem.icon" class='{{menuListItem.icon}} iconFontMore'></div>
            </a>
            <a id="hamburger_advisor_link_id-{{j}}" class="W16m color03" (click)="goToAdvisorPage()" *ngIf="menuListItem.url === 'outgoing'"
              href="javascript:void(0)" attr.aria-label="{{menuListItem.displayName}} + {{menuListItem.srText}} + {{menuListItem.srText}}">
              {{menuListItem.displayName}}
              <div aria-hidden="true" *ngIf="menuListItem.icon" class='{{menuListItem.icon}} iconFontMore'></div>
            </a>
            <a id="hamburger_close_fnc_id-{{j}}" class="W16m color03" (click)="openWindow(menuListItem);closeFunction()" *ngIf="menuListItem.external"
              href="javascript:void(0)" attr.aria-label="{{menuListItem.displayName}} + {{menuListItem.srText}} + {{menuListItem.srText}}">
              {{menuListItem.displayName}}
              <div aria-hidden="true" *ngIf="menuListItem.icon" class='{{menuListItem.icon}} iconFontMore'></div>
            </a>
          </li>
        </ul>
      </span>

      <div class="pd-hamburgerLinks" *ngIf="Lob !== 'ie'">
        <a id="hamburger_siteMap_link_id" class="W16m color03" (click)="closeFunction()" routerLink="/txn/sitemap">{{globalContent.text.siteMap}}</a>
      </div>
      <!-- <div *ngIf="!appStore.isApp() && Lob === 'ie' && isPermission('MKTLN')" class="pd-hamburgerLinks">
        <a class="W16m color03" href="javascript:void(0);"
            (click)="openWindowGuide(globalContent.text.marketLensGuideHref)">{{globalContent.text.marketLensguide}} 
            <span aria-hidden="true" class="icon-PDF iconFontMore pdfIcon color03"></span>
        </a>
      </div> -->
      <div *ngIf='isPermission("IRHLP")' class="pd-hamburgerLinks">
        <a id="hamburger_app_link_id" *appOnly class="helpButton W16m color03" (click)="closeFunction()"
          routerLink='/txn/help'>{{globalContent.text.help}}</a>
        <a id="hamburger_webApp_link_id" *webOnly class="helpButton W16m color03" href="javascript:void(0);"
          (click)="closeFunction(); openHelp()">{{globalContent.text.help}}</a>
      </div>
      <div class="pd-hamburgerLinks" *ngIf="(!this.appStore.isApp() || (this.enableFeedbackMobile === true))">
        <a class="fbbutton W16m color03" href="javascript:void(0);" (click)="closeFunction();openFeedback()">
          {{globalContent.text.feedbackButtonlabel}}
        <span class="sr-only">{{globalContent.text.feedbackButtonSRCopy}}</span></a>
      </div>
      <ng-container *ngIf="!isAnyApp && Lob === 'ie' && isPermission('MKTLN')">
        <div class="pd-hamburgerLinks">
          <a class="W16m color03" href="javascript:void(0);" (click)="goToMarketLens()">{{moreContent.text.marketLens}}
            <span class="sr-only">{{moreContent.text.marketLensSR}}</span>
          </a>
        </div>
        <div class="pd-hamburgerLinks">
          <a class="W16m color03" href="javascript:void(0);"
            (click)="openWindowGuide(globalContent.text.marketLensGuideHref)">{{globalContent.text.marketLensguide}}
            <span class="sr-only">{{globalContent.text.guideSRText}}</span><span aria-hidden="true"
              class="icon-PDF iconFontMore pdfIcon color03"></span>
          </a>
        </div>
      </ng-container>
      <div class="pd-hamburgerLinks" *ngIf="Lob == 'ie'">
        <a class="W16m color03" href="javascript:void(0);"
          (click)="openNewsLetter(); closeFunction();">{{moreContent.text.newsLetter}}
          <span class="sr-only">{{moreContent.text.newsLetterSR}}</span>
        </a>
      </div>
      <div class="pd-hamburgerLinks" *ngIf="Lob == 'ie'">
        <a class="W16m color03" href="javascript:void(0);"
          (click)="openLegal(); closeFunction();">{{moreContent.text.legal}}
          <span class="sr-only">{{moreContent.text.legalSR}}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="overlay-footer">
    <button class="signOffButton W18m color01" [ngClass]="'signOffButton-' + appStore.lob" id="sign-off-button"
      (click)="signOff()" [innerHtml]="isInApp ? globalContent.text.backToOLB : globalContent.text.signOut"></button>
  </div>
</div>

<app-modal [attr.id]="'dcoAppHamBar'" [id]="'dcoAppHamBar'" [header]="'dcoAppModalHeaderHam'" style='display: none;'
  targetElement="dcoAppHamLink">
  <div class="cibc-account-selector-modal-body" aria-controls="dcoApp">
    <div class="cibc-account-selector-modal-content">
      <div class="removeHeader allignCentre" id="dcoAppModalHeaderHam" tabindex="-1">
        {{content?.text?.dcoAPPModalHeader}}
      </div>
      <div class="marginTop30">
        <section [ngClass]="true?'float-right':'page-bottom-left-button-group'">
          <button type="submit" class='button primary marginBottom10-small'>
            {{content?.text?.continueLabel}}
          </button>
        </section>
        <section [ngClass]="true?'':'page-bottom-right-button-group'">
          <button type="button" class='button secondary'>
            {{content?.text?.cancelLabel}}
          </button>
        </section>
      </div>
    </div>
  </div>
</app-modal>

<app-modal [attr.id]="'marketlensTredingPwdModalHam'" [id]="'marketlensTredingPwdModalHam'"
  [header]="'marketlensTredingPwdModalHeaderHam'" style='display: none;' (keydown.esc)="closeMTPModal()">
  <div class="market-lens-modal-body" aria-controls="dcoApp">
    <div class="market-lens-modal-content">
      <div class="removeHeader" id="marketlensTredingPwdModalHeaderHam" tabindex="-1">
        {{globalContent.text.tradingPasswordModalHeader}}
      </div>
      <app-trading-password-form [globalContent]="globalContent" [otvcUniqueId]="uniqueId" (close)="closeMTPModal()">
      </app-trading-password-form>
    </div>
  </div>
</app-modal>