import { Injectable } from '@angular/core';
import { PageStore } from '../../../../shared/models/page-store';
import { OLBErrorData } from './olb-error-data';

@Injectable({ providedIn: 'root' })
export class OLBErrorStore extends PageStore<OLBErrorData> {

  constructor() {
    super(new OLBErrorData());
  }

}
