import { Injectable } from '@angular/core';
import { QuotesResearchTechnicalInsightsData } from './quotes-research-technical-insights-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class QuotesResearchTechnicalInsightsStore extends PageStore<QuotesResearchTechnicalInsightsData> {

  constructor() {
    super(new QuotesResearchTechnicalInsightsData());
  }
}
