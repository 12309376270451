import { Injectable } from '@angular/core';
import { MarketsDetailData } from './markets-detail-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class MarketsDetailStore extends PageStore<MarketsDetailData> {

    constructor() {
        super(new MarketsDetailData());
    }
}
