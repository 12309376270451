import { RouteGroup } from "../shared/models/route-config";

export const ContactUsConfig: RouteGroup = {
  "/txn/contactus": {
    name: "contactus",
    service: "",
    store: "ContactUsStore",
    resolveContent: "contactus",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      name: "contact-us",
      nameGA: "Contact Us",
    },
    brazeID: "viewed_contact_us"
  },
};
