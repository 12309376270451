import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import {
  OrderStatusInputs, OrderStatusSearchReq, OrderStatusDetailsReq, OrderStatusChangeStockInitReq,
  OrderStatusChangeStockVerifyReq, OrderStatusChangeOptionVerifyReq, OrderStatusChangeOptionInitReq,
  OrderStatusChangeCashInitReq, OrderStatusChangeStockConfirmReq, OrderStatusCancelInitReq, OrderStatusChangeCashVerifyReq,
  OrderStatusChangeOptionConfirmReq, OrderStatusChangeCashConfirmReq, OrderStatusCancelConfirmReq, BuyingPowerReq, QuoteReq
} from '../data/order-status-data';
import { OrderStatusStore } from '../data/order-status-store';
import { isDefined } from 'src/app/shared/services/utils.service';
import { ToCancelFxOrderReq } from '../../../exchange-currency/landing/data/exchange-currency-landing-data';
import { CommonService } from 'src/app/shared/services/common.service';
declare let require: any;

const gatewayConfig = require('../../../../../config/gateway-config.json');

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService, private commonService: CommonService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    // if (this.appStore.lob.toUpperCase() === 'IE') {
    //   input[0].input.FiTypeCode = 'A';
    // }
    if (this.appStore.lob.toUpperCase() === 'WG') {
      input[0].input.FiTypeCode = 'C';
    }
    if (OrderStatusStore.crossFlow && OrderStatusStore.crossFlow.FiTypeCode) {
      input[0].input.FiTypeCode = OrderStatusStore.crossFlow.FiTypeCode;
    }
    return input;
  }

  getOrderStatusSearch(data, skip?) {
    const req: OrderStatusSearchReq = new OrderStatusSearchReq();
    req.Refresh = data.Refresh;
    req.AccountNumber = data.AccountNumber;
    req.FiTypeCode = data.FiTypeCode;
    req.PaginationOption = data.PaginationOption;
    req.Sorting = data.Sorting;
    if (req.FiTypeCode === 'E' || req.FiTypeCode === 'O') {
      req.Market = data.Market;
      req.TimePeriod = data.TimePeriod;
      req.OrderStatus = data.OrderStatus;
      req.SymbolName = data.SymbolName;
      req.OptionOrderType = data.OptionOrderType;
    } else if (req.FiTypeCode === 'M') {
      req.Action = data.Action;
      req.Type = data.Type;
    } else if (req.FiTypeCode === 'F') {
      req.Currency = data.Currency;
      req.InstrumentType = data.InstrumentType;
    }
    return this.httpService.post(gatewayConfig.APIServices.orderStatusSearch.url,
      req, { params: { skiploading: (isDefined(skip) ? 'true' : 'false') } }
    );
  }

  getOrderStatusDetails(data) {
    const req: OrderStatusDetailsReq = new OrderStatusDetailsReq();
    req.OrderId = data.OrderId;
    req.FiTypeCode = data.FiTypeCode;
    let params = undefined;
    if (this.isDesktop()) {
      params = { params: { skiploading: 'true' } };
    }
    return this.httpService.post(gatewayConfig.APIServices.orderStatusDetails.url,
      req, params
    );
  }

  getOrderStatusChangeStockInit(data) {
    const req: OrderStatusChangeStockInitReq = new OrderStatusChangeStockInitReq();
    req.OrderId = data.OrderId;
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.stocksChangeInit.url,
      req
    );
  }

  getOrderStatusChangeStockVerify(data) {
    const req: OrderStatusChangeStockVerifyReq = new OrderStatusChangeStockVerifyReq();
    req.OrderId = data.OrderId;
    req.AccountNumber = data.AccountNumber;
    req.Quantity = data.Quantity;
    if (data.PriceMode === 'LIMIT') {
      req.Price = {
        Type: 'LIMIT',
        LimitAmount: data.LimitAmount
      };
    } else if (data.PriceMode === 'STOP') {
      req.Price = {
        Type: 'STOP',
        LimitAmount: data.StopPriceLimit,
        Amount: data.StopPrice
      };
    } else if (data.PriceMode === 'TRAILINGSTOPLMTDOLLAR') {
      req.Price = {
        Type: 'TRAILINGSTOPLMTDOLLAR',
        TrailingDelta: data.TrailingDelta,
        LimitOffset: data.LimitOffset,
        TriggerPrice: data.EstTriggerPrice,
        EstLimitPrice: data.EstLimitPrice,
      };
    } else if (data.PriceMode === 'TRAILINGSTOPLMTPERCENTAGE') {
      req.Price = {
        Type: 'TRAILINGSTOPLMTPERCENTAGE',
        TrailingDelta: data.TrailingDelta,
        LimitOffset: data.LimitOffset,
        TriggerPrice: data.EstTriggerPrice,
        EstLimitPrice: data.EstLimitPrice,
      };
    } else {
      req.Price = {
        Type: 'MKT'
      };
    }
    return this.httpService.post(gatewayConfig.APIServices.stocksChangeVerify.url,
      req
    );
  }

  getOrderStatusChangeStockSubmit(data) {
    const req: OrderStatusChangeStockConfirmReq = new OrderStatusChangeStockConfirmReq();
    if (data.TradingPassword) {
      req.TradingPassword = data.TradingPassword;
      req.SaveTradingPassword = data.SaveTradingPassword;
    }
    return this.httpService.post(gatewayConfig.APIServices.stocksChangeSubmit.url,
      req
    );
  }

  getOrderStatusChangeOptionInit(data) {
    const req: OrderStatusChangeOptionInitReq = new OrderStatusChangeOptionInitReq();
    req.OrderId = data.OrderId;
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.optionsChangeInit.url,
      req
    );
  }

  getMLSChangeInit(data) {
    const req: OrderStatusChangeOptionInitReq = new OrderStatusChangeOptionInitReq();
    req.OrderId = data.OrderId;
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.MLSChangeInit.url,
      req
    );
  }

  getOrderStatusChangeOptionVerify(data) {
    const req: OrderStatusChangeOptionVerifyReq = new OrderStatusChangeOptionVerifyReq();
    req.OrderId = data.OrderId;
    req.AccountNumber = data.AccountNumber;
    req.Quantity = data.Quantity;
    if (data.PriceMode === 'LIMIT') {
      req.Price = {
        Type: 'LIMIT',
        LimitAmount: data.LimitAmount
      };
    } else {
      req.Price = {
        Type: 'MKT'
      };
    }
    return this.httpService.post(gatewayConfig.APIServices.optionsChangeVerify.url,
      req
    );
  }

  getOrderStatusChangeMLSVerify(req) {
    return this.httpService.post(gatewayConfig.APIServices.MLSChangeVerify.url,
      req
    );
  }

  getOrderStatusChangeOptionSubmit(data) {
    const req: OrderStatusChangeOptionConfirmReq = new OrderStatusChangeOptionConfirmReq();
    if (data.TradingPassword) {
      req.TradingPassword = data.TradingPassword;
      req.SaveTradingPassword = data.SaveTradingPassword;
    }
    return this.httpService.post(gatewayConfig.APIServices.optionsChangeSubmit.url,
      req
    );
  }

  getOrderStatusChangeMLSSubmit(data) {
    const req: OrderStatusChangeOptionConfirmReq = new OrderStatusChangeOptionConfirmReq();
    if (data.TradingPassword) {
      req.TradingPassword = data.TradingPassword;
      req.SaveTradingPassword = data.SaveTradingPassword;
    }
    return this.httpService.post(gatewayConfig.APIServices.MLSChangeSubmit.url,
      req
    );
  }

  getOrderStatusChangeCashInit(data) {
    const req: OrderStatusChangeCashInitReq = new OrderStatusChangeCashInitReq();
    req.OrderId = data.OrderId;
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.cashtransferChangeInit.url,
      req
    );
  }

  getOrderStatusChangeCashVerify(data) {
    const req: OrderStatusChangeCashVerifyReq = new OrderStatusChangeCashVerifyReq();
    req.OrderId = data.OrderId;
    req.Amount = data.Amount;
    return this.httpService.post(gatewayConfig.APIServices.cashtransferChangeVerify.url,
      req
    );
  }

  getOrderStatusChangeCashSubmit(data) {
    const req: OrderStatusChangeCashConfirmReq = new OrderStatusChangeCashConfirmReq();
    if (data.TradingPassword) {
      req.TradingPassword = data.TradingPassword;
      req.SaveTradingPassword = data.SaveTradingPassword;
    }
    return this.httpService.post(gatewayConfig.APIServices.cashtransferChangeSubmit.url,
      req,
    );
  }

  getOrderStatusCancelInit(data, orderType?) {
    const req: OrderStatusCancelInitReq = new OrderStatusCancelInitReq();
    let url: string;
    req.OrderId = data.OrderId;
    req.AccountNumber = data.AccountNumber;
    switch (orderType) {
      case 'E':
        url = gatewayConfig.APIServices.stocksCancelVerify.url;
        break;
      case 'O':
        url = gatewayConfig.APIServices.optionsCancelVerify.url;
        break;
      case 'C':
        url = gatewayConfig.APIServices.cashtransferCancelVerify.url;
        break;
      default:
        break;
    }
    return this.httpService.post(url, req);
  }

  getMLSCancelVerify(data) {
    const req: OrderStatusCancelInitReq = new OrderStatusCancelInitReq();
    let url: string;
    req.OrderId = data.OrderId;
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.MLSCancelVerify.url, req);
  }

  getOrderStatusCancelSubmit(data, orderType?) {
    const req: OrderStatusCancelConfirmReq = new OrderStatusCancelConfirmReq();
    let url: string;
    if (data.TradingPassword) {
      req.TradingPassword = data.TradingPassword;
      req.SaveTradingPassword = data.SaveTradingPassword;
    }
    switch (orderType) {
      case 'E':
        url = gatewayConfig.APIServices.stocksCancelSubmit.url;
        break;
      case 'O':
        url = gatewayConfig.APIServices.optionsCancelSubmit.url;
        break;
      case 'C':
        url = gatewayConfig.APIServices.cashtransferCancelSubmit.url;
        break;
      default:
        break;
    }
    return this.httpService.post(url, req );
  }

  getMLSCancelSubmit(data) {
    const req: OrderStatusCancelConfirmReq = new OrderStatusCancelConfirmReq();
    let url: string;
    if (data.TradingPassword) {
      req.TradingPassword = data.TradingPassword;
      req.SaveTradingPassword = data.SaveTradingPassword;
    }
    return this.httpService.post(gatewayConfig.APIServices.MLSCancelConfirm.url, req);
  }

  getTradeAlerts() {
    return this.httpService.post(gatewayConfig.APIServices.getSavedTradedAlerts.url, {}, { params: { skiploading: 'true' } });
  }

  getBuyingPower(data) {
    const req: BuyingPowerReq = new BuyingPowerReq();
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.buyingPowerRetrieve.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  includeParams(): any {
    if (this.isDesktop()) {
      // return nothing
    } else {
      return { params: { skiploading: 'true' } };
    }
  }


  getCancelFxOrder(data) {
    const req: ToCancelFxOrderReq = new ToCancelFxOrderReq();
    req.OrderId = data;
    return this.httpService.post(gatewayConfig.APIServices.cancelFxOrder.url, req);
  }

  getQuote(data, legType) {
    let req: QuoteReq = new QuoteReq();
    if (legType == 'OPTION') {
      req.Symbols = [
        data
      ];
      return this.httpService.post(gatewayConfig.APIServices.quoteQuick.url,
        req, { params: { skiploading: 'true' } }
      );
    }
    else {
      return this.httpService.post(gatewayConfig.APIServices.quoteQuick.url,
        data, { params: { skiploading: 'true' } }
      );
    }

  }
}
