import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { QRNotifyUserActivityData } from '../data/quotes-research-data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class QuotesResearchService implements PageService {
  constructor(private appStore: AppStore, private httpService: HttpService) { }

  
  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return input;
  }

  extendSession() {
    return this.httpService.post(gatewayConfig.APIServices.getSiteStateExtendSession.url, {}, { params: { skiploading: 'true' } });
  }

  renewToken() {
    return this.httpService.post(gatewayConfig.APIServices.getMarkitResetToken.url, {});
  }

  getToken() {
    return this.httpService.post(gatewayConfig.APIServices.getMarkitToken.url, {});
  }

  notifyUserActivity(name: string) {
    const req : QRNotifyUserActivityData = new QRNotifyUserActivityData();
    req.ModuleName = name;
    return this.httpService.post(gatewayConfig.APIServices.getMarkitNotify.url, req, {});
  }
}
