import { RouteGroup } from "../shared/models/route-config";

export const ACCOUNTS = "accounts";
export const ACCOUNTS_HOLDINGS = "accounts,account-holdings";

export const AccountsConfig: RouteGroup = {
  "/txn/accounts/myaccounts": {
    name: "MyAccounts",
    service: "AccountInfoService",
    store: "AccountInfoStore",
    resolveContent: "myaccounts",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "getAccountInit",
        get request(): any {
          return {
            MyAccountsReq: {},
          };
        },
      },
    ],
    trackingData: {
      hierarchy: ACCOUNTS,
      name: "my-accounts",
      nameGA: "My Accounts",
      isSubmitDeferred: true
    },
    brazeID: "viewed_my_accounts"
  },
  "/txn/accounts/accountholdings": {
    name: "AccountHoldings",
    service: "AccountHoldingService",
    store: "AccountHoldingsStore",
    resolveContent: "accountholdings",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "holdingsLoad",
        get request(): any {
          return {
            AccountNumberInfo: {
              AccountPortfolioId: "",
              AccountNumber: "",
              Currency: "",
            },
            IncludeSubaccount: true,
          };
        },
      },
    ],
    trackingData: {
      hierarchy: ACCOUNTS_HOLDINGS,
      name: "intraday",
      nameGA: "Holdings - Intraday",
    }
  },
};
