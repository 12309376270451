import { BaseData } from 'src/app/shared/models/base-data';

export class SellStructNotesConfirmData extends BaseData {
  constructor() {
    super();
  }
}

export interface OrderConfirmInfo {
  OrderTimestamp: string;
  OrderId: string;
}