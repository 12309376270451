import { Injectable } from '@angular/core';
import { ChangeSignonPassData } from './change-signon-pass-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeSignonPassStore extends PageStore<ChangeSignonPassData> {

    constructor() {
        super(new ChangeSignonPassData());
    }
}
