import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { OnlineRegisterUsernameStore } from '../data/onlineregister-username-store';

import gatewayConfig from "src/app/config/gateway-config.json";


@Injectable({
  providedIn: 'root'
})
export class OnlineRegisterUsernameService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (OnlineRegisterUsernameStore.params) {
      let ser = input[0];
      ser.input = OnlineRegisterUsernameStore.params;
      return input;
    }
    else {
      return [];
    }
  }

  updateUserID(param){
    return this.httpService.post(gatewayConfig.APIServices.wgOnlineRegisterUpdateUserID.url, param)
  }
}
