import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { AccountHoldingsData } from './account-holdings-page-data';

@Injectable({ providedIn: 'root' })
export class AccountHoldingsStore extends PageStore<AccountHoldingsData>  {

    constructor() {
        super(new AccountHoldingsData());
    }

    public static paramsAvailable(): boolean {
        if (!this.params) {
            this.params = { accountPortfolioId: "", accountNumber: "", currency: "" };
        }
        return true;
    }

}
