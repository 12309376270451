import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { PageService } from 'src/app/shared/services/page-service';
import { cplAccountRequest } from "../data/create-cash-plan-landing-data";
import { CreateCashPlanLandingStore } from "../data/create-cash-plan-landing-store";
declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})
export class CreateCashPlanLandingService implements PageService {

    constructor(private httpService: HttpService) { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return input;
    }

    cashPlanLandingForm(form, fb) {
        form = fb.group({
            selectedAccount: '-1',
            selectedAccountType: "",
            amount: '',
            planFrequency: '-1',
            startDate: '0',
            endDate: '0',
            suspendDate: '0',
            resumeDate: '0',
            payFrom: '-1',
            contributionType: '0'
        });
        return form;
    }

    selectedAccountReq(accountRequest: cplAccountRequest) {
        return this.httpService.post(gatewayConfig.APIServices.getRIPSettlementAccounts.url, accountRequest);
    }

    getBalanceReq(accountRequest: cplAccountRequest) {
        return this.httpService.post(gatewayConfig.APIServices.getAccountBalance.url, accountRequest, { params: { skiploading: 'true' } });
    }

}