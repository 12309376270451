import { BaseData } from 'src/app/shared/models/base-data';

export class QuickTradeOptionsLandingData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1,
      action: "",
      actionClose: "",
      symbol: "",
      optionHoldingIndex: -1,
      market: "",
      quantity: undefined,
      priceMode: "",
      limit: undefined,
      // stopPrice: undefined,
      // stopLimit: undefined,
      expiryMode: undefined,
      settlementIndex: -1,
      strikePriceIndex: -1,
      strikeDateIndex: -1,
      optionType: "",
      chooseSymbol: false,
      dateDay: 1,
      dateMonth: 0
    };
  }
}

export interface QuickTradeOptionsLandingInputs {
  accountIndex: number;
  action: string;
  actionClose: string;
  symbol: string;
  optionHoldingIndex: number;
  market: string;
  quantity: number;
  priceMode: string;
  limit: number;
  // stopPrice: number;
  // stopLimit: number;
  expiryMode: string;
  settlementIndex: number;
  strikePriceIndex: number;
  strikeDateIndex: number;
  optionType: string;
  chooseSymbol: boolean;
  dateMonth: number;
  dateDay: number;
}

export class SettlementAccountsReq {
  AccountNumber: string;
  Action: string;
  AccountCurrency: string;
  Type?: string;
}

export class AccountHoldingsReq {
  AccountNumber: string;
  Action: string;
  Refresh: boolean;
  Clean: boolean;
  SearchOptions: {
    StartRow: number;
    NumberOfResult: number;
    SearchFilter: string
  };
  FiType: string;
}

export class BuyingPowerReq {
  AccountNumber: string;
}

export class ChainReq {
  SymbolName: string;
  Market: string;
  StrikePrice?: string;
  ExpiryDate?: string;
}

export class QuoteReq {
  Symbols: [
    {
      SymbolName: string;
      Market: string;
      SymbolType: string;
      OptionInfo?: {
        OptionType: string;
        StrikeDate: string;
        StrikePrice: {
          Value: string;
        }
      }
    }
  ];
}

export class InFlowSymbolData {
  Symbol: {
    Market: string;
    OptionEnabled: boolean;
    SymbolName: string;
    SymbolType: string;
  }
}