import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppError, ErrorConstant } from '../../../models/app-error';
import { Router } from '@angular/router';
import { isString, isArray } from 'util';
import { ObservableSubscriptionService } from './../../../../shared/services/observable-subscription.service';
import { AppErrorHandlerService } from './../services/app-error-handler.service';
import { GlobalSignOffService } from '../../../services/global-signoff.service';
import { CommonService } from '../../../../shared/services/common.service';
import { GlobalContentStore } from '../../../../shared/store/global-content-store';
import { AppStore } from '../../../../shared/models/app-store';
import { PersistenceService } from '../../../services/persistence.service';
import { of } from 'rxjs';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { typeOf } from '../../../../config/type-mapper';

declare let require: any;
const gatewayConfig = require('../../../../config/gateway-config.json');


@Injectable({ providedIn: 'root' })
export class CustomAppErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    public subscriptionService: ObservableSubscriptionService,
    private service: AppErrorHandlerService,
    private globalSignOffService: GlobalSignOffService,
    private router: Router,
    private commonService: CommonService,
    private globalContent: GlobalContentStore,
    private persistanceService: PersistenceService,
    private appstore: AppStore,
    private trackingService: TrackingService
  ) {
  }

  errorArr = [499, 404, 500, 401, 406, 409, 498, 529, 555];

  handleError(error: Error | HttpErrorResponse) {
    const router = this.injector.get(Router);
    if (error instanceof HttpErrorResponse) {
      if (this.errorArr.includes(error.status) && error.error) {
        this.omnitureTagError(error.error.Exception);
      }
      switch (error.status) {
        case 499: {
          // For HTTP 499 Error : We are swalloing the error and ignoring it
          // Nothing to do.
          if (this.commonService.isUserSignedOn()) {
            this.service.sendException(error).then((status) => {
            });
          }
          break;
        }
        case 404: {
          if (error.error.Exception) {
            console.log(error.error.Exception.ErrorCode);
          } else if (isString(error.error)) {
            console.log(error.error);
          } else {
            console.log('ERROR : ' + error.message);
          }
          if (this.commonService.isUserSignedOn()) {
            this.service.sendException(error).then((status) => {
            });
          }
          break;
        }
        case 500: {
          if (error.error.Exception) {
            console.log(error.error.Exception.ErrorCode);
          } else if (isString(error.error)) {
            console.log(error.error);
          } else {
            console.log('ERROR : ' + error.message);
          }
          if (this.commonService.isUserSignedOn()) {
            this.service.sendException(error).then((status) => {
            });
          }
          break;
        }
        // Refactor can be changed on getting the error reason directly from server side instead of filtering on client side
        case 401: {
          if (error.error.Exception.ErrorCode === ErrorConstant.SESSIONTIMEOUT) {
            console.log('timed out');
            this.ngZone.run(() => {
              this.globalSignOffService.timeOut();
            });
          } else {
            this.ngZone.run(() => {
              this.subscriptionService.setCustomError(error.error.Exception);
            });
          }
          if (this.commonService.isUserSignedOn()) {
            this.service.sendException(error).then((status) => {
            });
          }
          break;
        }
        case 406: {
          if (error.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
            this.ngZone.run(() => {
              this.router.navigate(['/txn/userPreferences/exchangeAgreements/landing']);
            });
          } else {
            this.ngZone.run(() => {
              this.subscriptionService.setCustomError(error.error.Exception);
            });
          }
          break;
        }
        case 409: {
          if (error.error instanceof ErrorEvent) {
            alert('Its CLIENT-side error');
          } else {
            this.ngZone.run(() => {
              this.subscriptionService.setCustomError(error.error.Exception);
            });
          }
          break;
        }
        case 498:
        case 529:
        case 555: {
          this.ngZone.run(() => {
            this.subscriptionService.setCustomError(error.error.Exception);
          });
          if (this.commonService.isUserSignedOn()) {
            this.service.sendException(error).then((status) => {
            });
          }
          break;
        }
        default: {
          if (error.message.indexOf('/html/error500.html') != -1) {
            this.ngZone.run(() => {
              this.router.navigate(['/error500']);
            });
          } else {
            console.log('Error Status Code : ' + error.status + 'Error Message : ' + error.message);
            if (this.commonService.isUserSignedOn()) {
              this.service.sendException(error).then((status) => {
              });
            }
          }
        }

      }
      console.log('It happens HttpErrorResponse: ', error);
    } else if (error instanceof AppError) {
      this.omnitureTagError(error);
      if (error.code === ErrorConstant.TE100) {
        window.alert('Pls upgrade app');
      } else {
        console.error('It happens AppError: ', error.code);
      }
      if (this.commonService.isUserSignedOn()) {
        this.service.sendException(error).then((status) => {
        });
      }
    } else {
      console.error('It happens ErrorEvent: ', error);
      if (error.name !== 'TimeoutError') {
        if (this.commonService.isUserSignedOn()) {
          this.service.sendException(error).then((status) => {
          });
        }
      } else {
        const errorObject = error;
        // putting ErrorMessage property to work with existing error component logic
        errorObject['ErrorMessage'] = this.globalContent.error.connectionTimeout.message;
        this.subscriptionService.setCustomError(errorObject);
      }
    }
  }

  omnitureTagError(errorObj) {
    if ((window as any).TrackingEnabled && !isArray(errorObj)) {
      of(this.trackingService.tagError(errorObj, "page"));
    }
  }
}
