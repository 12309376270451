import { BaseData } from '../../../../shared/models/base-data';

export class OptionCentreData extends BaseData {
  constructor() {
    super();
    this.input = {

    };
  }

  
}