import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnChanges, TemplateRef } from '@angular/core';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { AppStore } from '../../models/app-store';
import { Lob, Lang } from 'src/app/shared/models/lob.enum';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss']
})
export class DropdownListComponent implements OnInit, OnChanges {

  open = false;
  options: Option[];
  accountDisplayName: any;
  subAccountDisplayName: string;
  lob: string;
  selectedOption: any;
  displayAccountNumber: string;
  account: any;
  subAccount: any;
  pageContent: any;
  singleAccountList: any[];
  highlightedElement: any;
  accountDisplayCurrency: any;
  firstFocusableEl: any;
  lastFocusableEl: any;
  triggerValue = true;

  fontNormal = "A16";
  fontBold = "A16b";

  @Input() paramSelectedIndex: any;
  @Input() paramPageContent: any;
  @Input() paramOptions: any;
  @Input() hideCombinedText: boolean = false;
  @Input() regularDropdown = true;
  @Input() hideSubAccounts = false;
  @Input() onlySubAccounts = false;
  @Input() dropdownFullWidth = false;
  @Input() chooseOne = false;
  @Input() formError;
  @Input() disabled;
  @Input() id: string = '';
  @Input() public error: string;
  @Input() mode: string;
  @Input() optionTemplate: TemplateRef<any>;
  @Input() optionTemplateButton: TemplateRef<any>;
  // @Input() error;
  @Input() style = "normal";
  @Input() listStyle;
  @Output() selectedAccount: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDropDownState(false);
  }

  constructor(
    public globalcontent: GlobalContentStore,
    private appStore: AppStore,
    private subscriptionService: ObservableSubscriptionService,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnChanges() {
    this.options = this.paramOptions;
    this.singleAccountList = [];
    this.createSingleList();
    if (this.paramSelectedIndex && this.paramSelectedIndex >= 0) {
      this.selectedOption = this.singleAccountList[this.paramSelectedIndex];
    } else {
      this.selectedOption = this.singleAccountList[0];
    }
    this.generateDisplayName(this.selectedOption);
    document.addEventListener('visibilitychange', (e: Event) => { this.setDropDownState(false); });
  }

  ngOnInit() {
    this.lob = this.appStore.lob;
    this.pageContent = this.paramPageContent;
    this.options = this.paramOptions;
    this.singleAccountList = [];
    this.createSingleList();
    if (!this.chooseOne) {
      if (this.paramSelectedIndex) {
        this.selectedOption = this.singleAccountList[this.paramSelectedIndex];
      } else {
        this.selectedOption = this.singleAccountList[0];
      }
      this.generateDisplayName(this.selectedOption);
    } else {
      this.accountDisplayName = {
        name: this.globalcontent.text.chooseOne
      }
    }
    document.addEventListener('visibilitychange', (e: Event) => { this.setDropDownState(false); });

    if (this.style == 'soft') {
      this.fontNormal = 'W16m';
      this.fontBold = 'W16sb';
    }
  }
  setFocus(targetElementId) {
    if (targetElementId) {
      const targetElement = document.getElementById(targetElementId);
      window.requestAnimationFrame(() => {
        if (targetElement) {
          targetElement.focus();
        }
      });
    }
  }

  setDropDownState(state) {
    if (state) {
      setTimeout(() => {
        this.setFocus('ASDD' + this.selectedOption.Id + this.id);
        this.highlightedElement = document.getElementById('ASDD' + this.selectedOption.Id + this.id);
      }, 200);
    } else {
      if (this.open) {
        this.setFocus('ddButton' + this.id);
      }
    }
    this.open = state;
  }
  onMouseEnter(e) {
    this.setFocus(e.currentTarget.id);
  }
  handleBlur(e) {
    if (e.relatedTarget) {
      return !e.relatedTarget || (e.relatedTarget && e.relatedTarget.id.indexOf('ASDD') !== 0);
    } else {
      return !document.activeElement || (document.activeElement && document.activeElement.id.indexOf('ASDD') !== 0);
    }
  }
  onBlur(e) {
    if (this.handleBlur(e)) {
      this.setDropDownState(false);
      e.preventDefault();
    }
  }
  onKeyDown(e, pAccount) {
    if (e.keyCode === 9) {
      this.setDropDownState(false);
      e.preventDefault();
    }
  }
  onKeyUp(e, pAccount) {
    if (e.keyCode === 38) {
      if (this.highlightedElement !== this.firstFocusableEl && this.highlightedElement.previousElementSibling) {
        this.highlightedElement.previousElementSibling.focus();
        this.highlightedElement = this.highlightedElement.previousElementSibling;
      } else {
        this.highlightedElement.focus();

      }
    } else if (e.keyCode === 40) {
      if (this.highlightedElement !== this.lastFocusableEl && this.highlightedElement.nextElementSibling) {
        this.highlightedElement = this.highlightedElement.nextElementSibling;
        this.highlightedElement.focus();
      } else {
        this.highlightedElement.focus();
      }
    } else if (e.keyCode === 13) {
      let arrId = this.highlightedElement.id.split('ASDD');
      const index = Number(arrId[1].split(this.id)[0]);
      if (!pAccount.isFooter) {
        this.selectedOption = this.singleAccountList[index];
        this.generateDisplayName(this.selectedOption);
      }
      this.selectedAccount.emit(index);
      this.setDropDownState(false);
    } else if (e.keyCode === 27) {
      this.setDropDownState(false);
      e.preventDefault();
    }
  }
  optionSelected(pAccount, index) {
    this.setDropDownState(false);
    if (!pAccount.isFooter) {
      this.selectedOption = pAccount;
      this.generateDisplayName(this.selectedOption);
    }
    this.selectedAccount.emit(index);
  }
  createSingleList() {
    //only for holdings
    if (this.mode == 'holdings') {
      let id = 0;
      this.paramOptions.forEach(val => {
        this.singleAccountList.push({
          ...val,
          Id: id,
          name: (val.Symbol.SymbolName ? val.Symbol.SymbolName : '') + ':' + val.Symbol.Market ?? '',
          icon: val.Symbol.Market ?? null,
          suffix: val.Symbol.SymbolLongName ?? ''
        });
        id++;
      });

      //footer
      this.singleAccountList.push({
        Id: id,
        name: this.pageContent ? this.pageContent.text.typeSymbol : '',
        icon: 'icon-Search',
        isFooter: true,
        iconAlt: this.pageContent?.text.typeSymbolSR ? this.pageContent.text.typeSymbolSR : ''
      });
      id++;
    }
    else if (this.mode == 'holdings-mls') {
      let id = 0;
      this.paramOptions.forEach(val => {
        this.singleAccountList.push({
          ...val,
          Id: id,
          name: this.optionMlsDisplay(val),
          icon: val.Symbol.Market ?? null,
          suffix: val.Symbol.SymbolLongName ?? ''
        });
        id++;
      });

      //footer
      this.singleAccountList.push({
        Id: id,
        name: this.pageContent ? this.safeHtml(this.pageContent.text.note) : '',
        icon: null,
        isFooter: true,
        disabled: true
      });


      id++;
      this.singleAccountList.push({
        Id: id,
        name: this.pageContent ? this.safeHtml(this.pageContent.text.optionLink) : '',
        icon: null,
        isFooter: true
      });
      id++;
    }
    else {
      let id = 0;
      this.paramOptions.forEach(val => {
        this.singleAccountList.push({
          ...val,
          Id: id,
        });
        id++;
      });
    }
    console.log(this.singleAccountList);
  }
  generateDisplayName(option) {
    if (option && option.name) {
      this.accountDisplayName = {
        name: option.name ?? '',
        icon: option.icon ?? null,
        suffix: option.suffix ?? ''
      };
    }
  }

  getAccountTypeDesc(account) {
    return this.globalcontent.list.accountType[account.AccountTypeDesc] ? this.globalcontent.list.accountType[account.AccountTypeDesc] : '';
  }
  getAccountType(account) {
    return this.globalcontent.list.accountType[account.AccountType] ? this.globalcontent.list.accountType[account.AccountType] : '';
  }
  setFlag(market) {
    if (market === 'US') {
      return 'assets/images/icons/icon-us-flag.svg';
    }
    if (market === 'CA') {
      return 'assets/images/icons/icon-can-flag.svg';
    }
  }

  optionMlsDisplay(val) {
    if (this.appStore.lang == Lang.FR) {
      return this.globalcontent.getSymbolContent(val.Symbol.SymbolName, val.Symbol.Market) + ' ' + this.formatDate(val.Symbol.OptionInfo.StrikeDate, "MMMM DD, YYYY", "DD MMMM YYYY")
        + " " + val.Symbol.OptionInfo.StrikePrice.Value + " $ " + this.getOptionTypeString(val.Symbol.OptionInfo.OptionType);
    } else {
      return this.globalcontent.getSymbolContent(val.Symbol.SymbolName, val.Symbol.Market) + ' ' + this.formatDate(val.Symbol.OptionInfo.StrikeDate, "MMMM DD, YYYY", "DD MMMM YYYY")
        + " $" + val.Symbol.OptionInfo.StrikePrice.Value + " " + this.getOptionTypeString(val.Symbol.OptionInfo.OptionType);
    }
  }

  formatDate(date, formaten, formatfr) {
    const momentLang = moment(date).locale(this.appStore.lang);
    if (date === undefined) {
      return "";
    }
    if (this.appStore.lang === Lang.EN) {
      return momentLang.format(formaten);
    } else {
      return momentLang.format(formatfr);
    }
  }

  getOptionTypeString(val) {
    if (val === 'C') {
      if (this.appStore.lang == "fr") {
        return 'Achat'
      } else {
        return 'Call';
      }

    } else if (val === 'P') {
      if (this.appStore.lang == "fr") {
        return 'Vente'
      } else {
        return 'Put';
      }
    } else {
      return '';
    }
  }

  safeHtml(url) {
    if (typeof (url) === 'string') {
      return this.domSanitizer.bypassSecurityTrustHtml(url);
    } else {
      return url;
    }
  }
}

export interface Option {
  name: string;
  value?: any;
  icon?: string;
  suffix?: string;
  isFooter?: boolean;
  disabled?: boolean;
  Id?: any;
}