import { Injectable } from '@angular/core';
import { MutualFundsSearchData } from './mutual-funds-search-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class MutualFundsSearchStore extends PageStore<MutualFundsSearchData> {

  constructor() {
    super(new MutualFundsSearchData());
  }
}
