import { BaseData } from '../../../../../../shared/models/base-data';

export class CreateCashPlanVerifyData extends BaseData {
}
export interface cpvPageData {
    Account: account;
    ValidateInfo: validateInfo;
    Warnings?: warnings[];
}

export interface account {
    AccountNumber: number,
    AccountType: string,
    AccountFriendlyName: string,
    AccountBalances: accountBalances[],
    IncludeShort: boolean,
    RegistrationInfo?: registrationInfo;
}

export interface registrationInfo {
    RegType: string;
}

export interface accountBalances {
    AccountType: string,
    Currency: string,
    Amount: number
}

export interface validateInfo {
    Action: string,
    Amount: number,
    PlanFrequency: number,
    StartDate: string,
    PayFromAccountNumber: number,
    TradingPasswordInSession: boolean,
    TradingPasswordRequired: boolean,
    Symbol: cpvSymbol,
    DividendInstruction: string,
    FundFactsLinkRequired: string
}


export interface cpvSymbol {
    SymbolName: string,
    FundName: string
}

export interface cpvPageParams {
    TradingPassword?: string;
    SaveTradingPassword?: boolean;
    AcceptedWarningMessages?: boolean;
}

export interface savePwdToolTip {
    openBtnLabel: string;
    cancel: string;
    content: string;
    btnHiddenText: string;
}
export const otvc_const = {
    currModule: 'createcashplanverify',
    forgotTradingUrl: 'txn/userPreferences/forgotTradingPassword/landing',
    mode: 'forgotTradingPassword',
    nonReqAction: 'OTVCNOTREQUIRED',
    otvcprompt: 'otvcprompt',
    otvcpromptmodel: 'otvcpromptmodel',
    otvcPromptClose: 'otvcPromptClose',
    prompt: 'prompt',
    reqAction: 'OTVCREQUIRED'
}

export const No_API_Data = {
    dash: '-',
    registered: 'REG',
    dollar: '$'
}

export interface warnings {
    Code?: string,
    Message?: string,
    ConsentRequired?: boolean;
}
export interface forgot_pwd_otvc_resp {
    ACTION: string;
}

export interface otvc_resp {
    OtvcDetail: contact[],
    LastUsed: string
    AlwaysOtvc: string,
    Skip: number
}

export interface contact {
    Value: number,
    Method: string,
    BlackListed: boolean,
    DialCode: number,
    CountryCode: number,
    CountryDesc: countryDesc
}

export interface countryDesc {
    en: string,
    fr: string
};

export enum OmnitureValues {
    REINVEST = "reinvest",
    DEPOSIT = "deposit",
    NOTAPPLICABLE = "na"
};