import { Component, OnInit } from '@angular/core';
import { isDefined } from 'src/app/shared/services/utils.service';
import { AppStore } from '../../models/app-store';

@Component({
  selector: 'app-general-spinner',
  templateUrl: './general-spinner.component.html',
  styleUrls: ['./general-spinner.component.scss']
})
export class GeneralSpinnerComponent implements OnInit {

  loadingText: string;

  constructor(public appStore: AppStore) {

  }

  ngOnInit() {
    if (this.appStore.lang.toUpperCase() == "EN") {
      this.loadingText = "Loading";
    } else {
      this.loadingText = "Chargement";
    }
  }

}
