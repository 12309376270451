import { Injectable } from '@angular/core';
import { ContactInfoUpdateConfirmData } from './contact-info-update-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ContactInfoUpdateConfirmStore extends PageStore<ContactInfoUpdateConfirmData> {

    constructor() {
        super(new ContactInfoUpdateConfirmData());
    }

}
