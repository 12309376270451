import { Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AppStore } from 'src/app/shared/models/app-store';
import { Lob } from 'src/app/shared/models/lob.enum';
import { RegisterOnlineStore } from 'src/app/modules/pre-txn/register-online/landing/data/register-online-store';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';

export let lobPermissionGuard = function checkPermission(...permissions: string[]): Type<{
    canActivate: CanActivateFn;
}> {

    @Injectable({ providedIn: 'root' })
    class LobGuard  {

        constructor(
            private appstore: AppStore,
            private subscriptionService: ObservableSubscriptionService
        ) { }

        canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
            | Promise<boolean>
            | boolean {
            let ret = this.isPermission(permissions);
            console.log(`checking access for ${permissions.join(', ')}. Access allowed? : ${ret}`);
            return ret;
        }

        isPermission(name: string[]) {

            if (RegisterOnlineStore.crossFlow) {
                return true;
            }

            for (let i = 0; i < name.length; i++) {
                if (name[i] === this.appstore.lob.toUpperCase()) {
                    return true;
                } else if (name[i] === 'DEFAULTIE') {
                    this.subscriptionService.passLob(Lob.IE);
                    return true;
                }
            }
            return false;
        }
    }
    return LobGuard;
};

