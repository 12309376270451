// Import the core angular services.
import { HostListener, Directive, ElementRef, Input, Renderer2, OnInit, EventEmitter, Output } from '@angular/core';
import { GlobalContentStore } from '../store/global-content-store';
import { AppStore } from '../models/app-store';

@Directive({
  selector: '[appMaxlength]',
})

export class MaxlengthDirective implements OnInit {
  // Allow decimal numbers. The \. is only allowed once to occur
  private regex: RegExp = new RegExp(/^[0-9]*(\.)?[0-9]*$/g);
  // Allow decimal numbers. The \. is only allowed once to occur
  private frRegex: RegExp = new RegExp(/^[0-9]*(\.|\,)?[0-9]*$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private notDashRegex: RegExp = new RegExp(/^(?!.*-).*$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  private uniqueID: string;

  private listener: any;

  @Input() isNumRegExApply: boolean;
  @Input() isAccountNumExceptionApply: boolean;
  @Input('appMaxlength') maxlength: number;
  @Output() scrubValue: EventEmitter<any> = new EventEmitter<any>();;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private globalContent: GlobalContentStore,
    private appStore: AppStore
  ) {
  }

  ngOnInit() {
    this.uniqueID = this.appStore.uniqueId;
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: any) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    // Allow arrow keys in most situations
    if (event.keyCode >= 37 && event.keyCode <= 40) {
      if ((event.keyCode == 38 || event.keyCode == 40) && this.el.nativeElement.type == "number") {
        event.preventDefault();
        return;
      } else {
        return;
      }
    }

    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const current: string = this.el.nativeElement.value;
    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    let key = event.key;
    if (key === 'Decimal') {
      key = '.';
    }
    const next: string = current.concat(key);
    if (this.isNumRegExApply && next && !String(next).match(this.appStore.state.user.lang === 'fr' ? this.frRegex : this.regex)) {
      event.preventDefault();
    } else if ((event.keyCode == 38 || event.keyCode == 40) && this.el.nativeElement.type == "number") {
      event.preventDefault();
    }

    if (this.isAccountNumExceptionApply && next && !String(next).match(this.notDashRegex)) {
      event.preventDefault();
      return;
    }

    this.checkMaxLength(event);
  }

  // Note: For desktop browsers as ngModal value get 11th digit if use KeyUp only - And KeyDown not working fine in Mobile WrapperApps
  // So having both event listerners.
  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    // Allow arrow keys in most situations
    if (event.keyCode >= 37 && event.keyCode <= 40) {
      if ((event.keyCode == 38 || event.keyCode == 40) && this.el.nativeElement.type == "number") {
        event.preventDefault();
        return;
      } else {
        return;
      }
    }

    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const current: string = this.el.nativeElement.value;
    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    let key = event.key;
    if (key === 'Decimal') {
      key = '.';
    }
    const next: string = current.concat(key);
    if (this.isNumRegExApply && next && !String(next).match(this.appStore.state.user.lang === 'fr' ? this.frRegex : this.regex)) {
      event.preventDefault();
    } else if ((event.keyCode == 38 || event.keyCode == 40) && this.el.nativeElement.type == "number") {
      event.preventDefault();
    }

    if (this.isAccountNumExceptionApply && next && !String(next).match(this.notDashRegex)) {
      event.preventDefault();
      return;
    }

    this.checkMaxLength(event);
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (this.isAccountNumExceptionApply && event.inputType == 'insertFromPaste') {
      this.el.nativeElement.value = this.el.nativeElement.value.replaceAll('-', '');
      this.scrubValue.emit(this.el.nativeElement.value);
    }
  }
  // @HostListener('keyup', ['$event'])
  // onKeyUp(event: any) {
  //     if (event.currentTarget.value.length > this.maxlength) {
  //         event.currentTarget.value = event.currentTarget.value.slice(0, this.maxlength);
  //     }
  // }

  @HostListener('focus', ['$event'])
  onFocus(event: any) {
    if (event.currentTarget.value === '') {
      event.currentTarget.value = '';
    }
    if (this.listener === undefined && this.el.nativeElement.type == "number") {
      this.listener = function (e) {
        e.preventDefault();
      }
      this.el.nativeElement.addEventListener("wheel", this.listener);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    if (!(this.listener === undefined)) {
      this.el.nativeElement.removeEventListener("wheel", this.listener);
      this.listener = undefined;
    }
  }

  checkMaxLength(event: any) {
    if ((event.currentTarget.value.length > this.maxlength || event.currentTarget.value.length === this.maxlength)
      && event.key !== 'Backspace' &&
      event.key !== 'Delete' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight' &&
      !(event.key === 'x' && event.ctrlKey) &&
      !(event.key === 'c' && event.ctrlKey) &&
      !(event.key === 'v' && event.ctrlKey) &&
      !(event.key === 'a' && event.ctrlKey)) {
      event.preventDefault();
      event.currentTarget.value = event.currentTarget.value.slice(0, this.maxlength);
      const limitReachEl = this.renderer.createElement('span');
      if (this.el.nativeElement.parentNode.contains(document.getElementById('limitReachEl' + this.uniqueID))) {
        // nothing to do as element already
        this.renderer.removeChild(this.el.nativeElement.parentNode, document.getElementById('limitReachEl' + this.uniqueID));
      }
      this.renderer.setAttribute(limitReachEl, 'id', 'limitReachEl' + this.uniqueID);
      // this.renderer.setAttribute(limitReachEl, 'aria-live', 'assertive');
      this.renderer.setAttribute(limitReachEl, 'role', 'alert');
      this.renderer.setAttribute(limitReachEl, 'class', 'sr-only');

      const maxLimitReachedMsg = this.globalContent.text.maxLimitReached;
      const limitReachMsg = this.renderer.createText(maxLimitReachedMsg);
      this.renderer.appendChild(limitReachEl, limitReachMsg);
      // this.renderer.setProperty(limitReachEl, 'innerHTML', maxLimitReachedMsg);

      this.renderer.appendChild(this.el.nativeElement.parentNode, limitReachEl);

      // this.el.nativeElement.innerHTML = '<h1>Hello World</h1>';
    } else {
      if (this.el.nativeElement.parentNode.contains(document.getElementById('limitReachEl' + this.uniqueID))) {
        // nothing to do as element already
        this.renderer.removeChild(this.el.nativeElement.parentNode, document.getElementById('limitReachEl' + this.uniqueID));
      }
    }
  }
}