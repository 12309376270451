import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
//import { PageStore } from '../../../../../shared/models/page-store';

import {SellGicVerifyData} from './sell-gic-verify-data';

@Injectable({ providedIn: 'root' })
export class SellGicVerifyStore extends PageStore<SellGicVerifyData> {

    constructor() {
        super(new SellGicVerifyData());
    }

}
