import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CancelMutualFundPlanConfirmData } from './cancel-mutual-fund-plan-confirm-data';

@Injectable({ providedIn: 'root' })
export class CancelMutualFundPlanConfirmStore extends PageStore<CancelMutualFundPlanConfirmData>  {
    static params: any;

    constructor() {
        super(new CancelMutualFundPlanConfirmData());
    }

    public static paramsAvailable(): boolean {
        return true;
    }

}