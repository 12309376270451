import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Injectable } from '@angular/core';

@Injectable()
export class DatePickerAdapter extends MomentDateAdapter {

  getFirstDayOfWeek(): number {
    return 0;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style == 'long') {
      return super.getDayOfWeekNames('long');
    }
    const shortNamesArray = [];
    const shortName = super.getDayOfWeekNames('short');
    for (const name of shortName) {
      shortNamesArray.push(name.split('.').join(''));
    }
    return shortNamesArray;
  }

}