import { BaseData } from 'src/app/shared/models/base-data';

export class AccountsAgreementsDisclosuresData extends BaseData {

  constructor() {
    super();
    this.input = {
    };
  }

}