export const BUY_GIC_CONSTANT = {
    ANN: "ANNUALLY",
    ANNUALLY: "Annually",
    MAT: "At Maturity",
    SM: "Semi-annually",
    MT: "Monthly",
    CIBCM: "CIBCM",
    CIBCT: "CIBCT",
    CIBC: "C",
    THIRDPARTY: "T",
    FLEXGIC: "FlexDetails",
    CASHGIC: "CashableDetails",
    MARKETLINKED: "M",
    SEARCH: "search",
    SORT: "sort",
    ASC: "asc",
    DESC: "desc",
    NOTENAME: "noteName",
    MLGICPRODUCT: "mlgic",
    MLGICTAB: "mlgics",
    MLGIC: "MLGIC",
    TP: "thirdparty",
    TPGIC: "third-party-gics",
    CIBCGIC: "cibcgic",
    CIBCGICTAB: "cibc-gics",
    NOPRODUCT: "product-not-available"

}