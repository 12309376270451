import { Injectable } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class NasdaqAgreementService {

  constructor(private appStore: AppStore, private httpService: HttpService) {

  }
  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }
  accept(request) {
    return this.httpService.post(gatewayConfig.APIServices.exchangeAgreementsAccept.url, request);
  }
}

