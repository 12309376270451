import { BaseData } from 'src/app/shared/models/base-data';

export class ManageTrustedContactPersonLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountNumber: null,
            tcpActionType: null,
            firstName: null,
            lastname: null,
            phoneNumber: null,
            includeEmail: null,
            contactEmail: null,
            includeAddress: null,
            contactCountry: null,
            countryDropdown: 0,
            unit: null,
            contactAddress: null,
            city: null,
            province: null,
            prefixInput: '+1',
            postalCode: null,
            relationShip: -1,
            email: null,
        };

    }
}

export interface ManageTrustedContactPersonLandingInputs {
    accountNumber: string;
    tcpActionType: string;
    firstName: string;
    lastname: string;
    phoneNumber: number;
    includeEmail: boolean;
    contactEmail: string;
    includeAddress: boolean;
    contactCountry: string;
    countryDropdown: number;
    unit: string;
    contactAddress: string;
    city: string;
    province: string;
    postalCode: string;
    relationShip: string;
    email: string;
    prefixInput: any;
}

export enum ParamsMapping {
    Param1 = 'accountNumber',
    Param2 = 'email',
    Param3 = 'tcpActionType'
}

export const trusted_inputs_const = {
    ActionRadio: 'action-radio-add',
    DeclineRadio: 'action-radio-decline',
    PhoneNumber: 'phone-number',
    emailAddress: 'email-address',
    address: 'address',
    rdbCanada: 'rdb-country-canada',
    rdbOther: 'rdb-country-other'
}