import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, ContentChildren, QueryList, ElementRef } from '@angular/core';
import { ModalService } from '../modal/services/modal.service';
import { isDefined } from 'src/app/shared/services/utils.service';
import { GlobalContentStore } from '../../store/global-content-store';
import { AppStore } from '../../models/app-store';

@Component({
  selector: 'app-tab-control',
  templateUrl: './tab-control.component.html',
  styleUrls: ['./tab-control.component.scss']
})
export class TabControlComponent implements OnInit {


  @Input() public tabArray: any; // [..{name:string,label:string}..]
  @Input() public styling: string;
  @Input() public selectedTabValue: number = 0;
  @Input() public newMessage: string;
  @Input() public tabPadding: number = 30;
  @ContentChildren('myComponentContent') content: QueryList<ElementRef>;

  @Output() selectTab = new EventEmitter();

  constructor(public modalservice: ModalService, public globalStore: GlobalContentStore, public appStore: AppStore) { }

  ngOnInit() {

  }

  clickTab(i) {
    this.selectedTabValue = i;
    this.selectTab.emit(i);
  }

  keyDown(e, i) {
    const key = e.keyCode;
    switch (key) {
      case 37: // left
        if (i == 0) {
          document.getElementById(this.tabArray[this.tabArray.length - 1].name).focus();
        } else {
          document.getElementById(this.tabArray[i - 1].name).focus();
        }
        break;
      case 39: // right
        if (i == this.tabArray.length - 1) {
          document.getElementById(this.tabArray[0].name).focus();
        } else {
          document.getElementById(this.tabArray[i + 1].name).focus();
        }
        break;

      case 13: // enter
      case 32: // space
        e.preventDefault();
        this.clickTab(i);
        break;
    }
  }

  tabPageClass() {
    if (this.styling == "OrderStatus") {
      return "order-status-styling"
    } else if (this.styling == "simple") {
      return "simple-styling"
    } else {
      return "generic-styling"
    }
  }
}
