import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { QuickTradeStocksConfirmData } from './quick-trade-stocks-confirm-data';

@Injectable({ providedIn: 'root' })
export class QuickTradeStocksConfirmStore extends PageStore<QuickTradeStocksConfirmData> {

    constructor() {
        super(new QuickTradeStocksConfirmData());
    }

}
