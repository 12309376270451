import { Injectable } from '@angular/core';
import { Observable, empty, forkJoin, observable, ObservableInput } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Lob } from 'src/app/shared/models/lob.enum';
import { ROOT_CTX } from 'src/app/shared/models/user';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { PageStore } from 'src/app/shared/models/page-store';
import { routeConfig } from 'src/app/config/router-mapping';
import { CashtransferConfirmStore } from '../data/cashtransfer-confirm-store';
import { ToOrderStatusReq } from '../data/cashtransfer-confirm-data';



@Injectable({
  providedIn: 'root'
})
export class CashtransferConfirmService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (CashtransferConfirmStore.params) {
      const ser = input[0];
      const req = ser.input;
      if (CashtransferConfirmStore.params.TradingPassword) {
        req.TradingPassword = CashtransferConfirmStore.params.TradingPassword;
        req.SaveTradingPassword = CashtransferConfirmStore.params.SaveTradingPassword;
      }
      // req.Token = CashtransferConfirmStore.params.Token;

      return input;
    }

    return [];
  }

  ToOrderStatus(data) {
    let req: ToOrderStatusReq = new ToOrderStatusReq();
    req = data;
    return this.httpService.post('/orderstatus/init', req);
  }

}
