import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CreateMutualFundVerifyData } from './create-mutual-fund-verify-data';

@Injectable({ providedIn: 'root' })
export class CreateMutualFundVerifyStore extends PageStore<CreateMutualFundVerifyData>  {

    constructor() {
        super(new CreateMutualFundVerifyData());
    }

}
