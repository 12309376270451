import { BaseData } from '../../../../../../shared/models/base-data';

export class BuyBondsConfirmData extends BaseData {
  constructor() {
    super();
    this.input = {
      tradingPassword: '',
      savePassword: false
    };
  }
}

export interface BuyBondsConfirmInputs {
  tradingPassword: string;
  savePassword: boolean;
}

export interface OrderConfirmInfo {
  OrderTimeStamp: string;
  ProcessTimeStamp: number;
  OrderID: string;
}
