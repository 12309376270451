import { BaseData } from 'src/app/shared/models/base-data';

export class RemoveBrokerageAccountsLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountList: [],
            accountsToRemoveList: []
        };
    }
}

export interface RemoveBrokerageAccountsLandingInputs {
    accountList: any;
    accountsToRemoveList: any;
}

export class GetUserBrokerageAccounts {

}