import { BaseData } from 'src/app/shared/models/base-data';

export class RegisterOnlineConfirmData extends BaseData {
    constructor() {
        super();
        this.input = {
            Password: "",
            savePassword: false
        };
    }
}

export interface RegisterOnlineConfirmInputs {
    tradingPassword: string;
    savePassword: boolean;
}
