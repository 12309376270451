import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { BiometricVerify, BiometricLog } from '../data/touchid-confirm.data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class TouchIDConfirmService implements PageService {


  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  biometricInit() {
    return this.httpService.post(gatewayConfig.APIServices.biometricInit.url, null);
  }

  biometricVerify(data) {
    const req: BiometricVerify = new BiometricVerify();
    req.Password = data.Password;
    return this.httpService.post(gatewayConfig.APIServices.biometricVerify.url, req);
  }

  biometricLog(data) {
    const req: BiometricLog = new BiometricLog();
    req.Status = data.Status;
    return this.httpService.post(gatewayConfig.APIServices.biometricLog.url, req);
  }

}
