import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../../shared/models/page-store';
import { EnableMarginAndOptionsTradingLandingData } from "./enable-margin-and-options-trading-landing.data";


@Injectable({ providedIn: 'root' })
export class EnableMarginAndOptionsTradingLandingStore extends PageStore<EnableMarginAndOptionsTradingLandingData> {
    constructor() {
        super(new EnableMarginAndOptionsTradingLandingData());
    }
}