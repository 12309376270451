import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { AppStore } from 'src/app/shared/models/app-store';
import { routeConfig } from 'src/app/config/router-mapping';
import { Location } from '@angular/common';
import { typeOf } from 'src/app/config/type-mapper';
import { isDefined } from 'src/app/shared/services/utils.service';
import { CommonService } from 'src/app/shared/services/common.service';
// import { State, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class RestoreGuard  {

  constructor(
    private appstore: AppStore,
    private router: Router,
    private location: Location,
    private _commonService: CommonService,
    private activeRoute: ActivatedRoute,
    // private storeNg: Store<any>,
    // private stateNg: State<any>,
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!isDefined(this.router.url) || this.router.url == "/") {
      if (!((window.performance.navigation && window.performance.navigation.type === 1) ||
        window.performance.getEntriesByType('navigation').map((nav: any) => nav.type).includes("reload")) && this.appstore.isLoggedIn() &&
        !(window.opener && window.opener.location.origin == window.location.origin)) {
        this._commonService.clearPostSignOnSession();
        if (this.appstore.isApp("inapp")) {
          window.location.href = "cibcbanking://relaybacksso";
        } else {
          this.router.navigate(['/signon']);
        }
        return false;
      }
    }

    return true;
  }

}
