import { AppStore } from 'src/app/shared/models/app-store';
import { BuyBondsVerifyStore } from '../data/buy-bonds-verify-store';
import { HttpService } from 'src/app/core/services/http.service';
import { Injectable } from '@angular/core';
import { PageService } from 'src/app/shared/services/page-service';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {Observable} from "rxjs";

declare let require: any;
const gatewayConfig = require('../../../../../../config/gateway-config.json');


@Injectable({
  providedIn: 'root'
})
export class BuyBondsVerifyService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ser = input[0];
    ser.input = BuyBondsVerifyStore.params;
    return input;
  }

  getOtvcRequest = (): Observable<any> => {
    return this.httpService.post(
        gatewayConfig.APIServices.getOtvcRequest.url,
        {}
    );
  };

  forgotTradingPasswordInit = (): Observable<any> => {
    return this.httpService.post(
        gatewayConfig.APIServices.forgotTradingPasswordInit.url,
        null
    );
  }
}
