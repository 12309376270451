import { RouteGroup } from "../shared/models/route-config";

export const HelpConfig: RouteGroup = {
  "/txn/help": {
    name: "Help",
    service: "HelpService",
    store: "HelpStore",
    resolveContent: "help",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "helpinit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "",
      name: "help",
    },
  },
};
