declare const window: any;

export function createFile(fileName: string, successHandler, errorHandler) {
  let type = window.TEMPORARY;
  let size = 5*1024*1024;
  window.requestFileSystem(type, size, successCallback, errorCallback);

  function successCallback(fs) {
    fs.root.getFile(fileName, {create: true, exclusive: false}, function(fileEntry) {
        successHandler(fileEntry.toURL());
    }, errorCallback);
  }

  function errorCallback(error) {
    errorHandler(error);
  }
}

export function writeFile(fileName: string, fileData: Blob, successHandler, errorHandler) {
    let type = window.TEMPORARY;
    let size = 5*1024*1024;
    window.requestFileSystem(type, size, successCallback, errorCallback);

    function successCallback(fs) {
        fs.root.getFile(fileName, {create: true}, function(fileEntry) {
  
            fileEntry.createWriter(function(fileWriter) {
                fileWriter.onwriteend = function(e) {
                    successHandler();
                };
  
                fileWriter.onerror = function(e) {
                    errorCallback(e);
                };
  
                fileWriter.write(fileData);
  
            }, errorCallback);
        }, errorCallback);
    }

    function errorCallback(error) {
        errorHandler(error);
    }
}

export function triggerFileOpen(fullPath: string, mimeType: string, successHandler, errorHandler) {
    //window.cordova.plugins.fileOpener2.showOpenWithDialog(
    window.cordova.plugins.fileOpener2.open(
        fullPath,
        mimeType,
        {
            error : function(e) {
                errorHandler(e);
            },
            success : function () {
                successHandler();
            },
            position : [0, 0]
        }
    );
}

export function removeFile(fileName: string, successHandler, errorHandler) {
    var type = window.TEMPORARY;
    var size = 5*1024*1024;
    window.requestFileSystem(type, size, successCallback, errorCallback)
 
    function successCallback(fs) {
       fs.root.getFile(fileName, {create: false}, function(fileEntry) {
            fileEntry.remove(function() {
                successHandler();
          }, errorCallback);
        }, errorCallback);
    }
 
    function errorCallback(error) {
        errorHandler(error);
    }
 }