import { RouteGroup } from '../shared/models/route-config';

export const AccountSettingsConfig: RouteGroup = {

    '/txn/accountsettings': {
        name: 'accountsettings',
        service: 'AccountSettingsService',
        servicePath: 'txn/account-settings/service/account-settings',
        store: 'AccountSettingsStore',
        resolveContent: 'accountsettings',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            
        ],
        trackingData: {
            hierarchy: 'txn',
            name: 'account-settings',
            events: { pageView: true }
        },
        brazeID: "viewed_account_details"
    },

    '/txn/accountdetail':{
        name: 'accountdetail',
        service: 'AccountDetailService',
        servicePath: 'txn/account-detail/service/account-detail',
        store: 'AccountDetailStore',
        resolveContent: 'accountdetail',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            
        ],
       
    }
};
