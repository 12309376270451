import { BaseData } from 'src/app/shared/models/base-data';

export class CegAgreementData extends BaseData {
    constructor() {
        super();
        this.input = {
            AgreementName: undefined,
            UserData: {
                AcceptAgreement: undefined
            }
        };
    }
}
export interface CegAgreementInputs {
    AgreementName: string;
    UserData: UserData;
}

export interface UserData {
    AcceptAgreement: boolean;
}




