import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { ChainReq, QuoteReq } from '../data/options-central-data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class OptionsCentralService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return;
  }

  getChain(data) {
    let req: ChainReq = new ChainReq();
    req = data;
    return this.httpService.post(gatewayConfig.APIServices.optionsChainDetail.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getQuote(data) {
    let req: QuoteReq = new QuoteReq();
    req.Symbols = [
      data
    ];
    return this.httpService.post(gatewayConfig.APIServices.quoteQuick.url,
      req, { params: { skiploading: 'true' } }
    );
  }
}
