import { BaseData } from 'src/app/shared/models/base-data';

export class TradeMLSVerifyData extends BaseData {
  constructor() {
    super();
    this.input = {
      tradingPassword: "",
      savePassword: false
    };
}
}

export interface TradeOptionsVerifyInputs {
  tradingPassword: string;
  savePassword: boolean;
}