import { RouteGroup } from '../shared/models/route-config';

export const AccountDetailsConfig: RouteGroup = {

    '/txn/accountdetails': {
        name: 'accountdetails',
        service: 'AccountDetailsService',
        servicePath: 'txn/accounts/account-details/service/account-details',
        store: 'AccountDetailsStore',
        resolveContent: 'accountdetails',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'accountDetails',
                get request(): any {
                    return {
                        IncludeAccountSelector: true
                    }
                }
            }
        ],
        trackingData: {
            hierarchy: 'accounts',
            name: 'account-details',
            nameGA: 'Account Details',
            events: { pageView: true }
        },
        brazeID: "viewed_account_details"
    }
};
