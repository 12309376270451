import { Component, OnInit, EventEmitter, Output, HostListener, AfterViewInit, Input, Inject, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectCIBCOnlineLandingService } from 'src/app/modules/txn/userPreferences/connect-cibc-online/landing/services/connect-cibc-online-landing.service';
import { isDefined, openNewWindow } from 'src/app/shared/services/utils.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { SsoService } from '../../../modules/pre-txn/sso/sso.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { ContentService } from 'src/app/core/services/content.service';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SignonStore } from '../../../modules/pre-txn/signon/data/signon-store';
import { DOCUMENT } from '@angular/common';
import { _User } from 'src/app/shared/models/user';
import { GlobalSignOffService } from '../../services/global-signoff.service';
import { of, Subscription } from 'rxjs';
import { AccountInfoService } from 'src/app/modules/txn/accounts/account-info/service/accounts-info.service';
import { GlobalHeaderService } from '../global-header/service/global-header.service';
import { AccountHoldingsStore } from 'src/app/modules/txn/accounts/account-holdings/data/account-holdings-store';
import { Lob } from '../../../shared/models/lob.enum';
import { BroadcastStore } from '../../../modules/txn/broadcast/data/broadcast-store';
import { TrackingService } from "src/app/shared/services/tracking.service";
declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');
const pretxnConfig = require('../../../config/pretxnpage-config.json');
declare let window: any;


@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss']
})
export class HamburgerMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() navigationObject: any;
  @Input() moreContent: any;
  @Input() userMode: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  connectOnlineUrl: any;
  content: any;
  sessionInfo: any;
  Lob: string;
  currentLob: string;
  hamburgerMoreListLength: any;
  focusableElments: { overlayContent: { focusableElements: any[]; }; };
  desktopContainer: HTMLElement;
  headerContainer: HTMLElement;
  footerContainer: HTMLElement;
  pageHeader: HTMLElement;
  pagefooter: HTMLElement;
  lang: string;
  thirdLevelNavDropdown: HTMLElement;
  subscriptions = new Subscription();
  PEEnabled: boolean = false;
  isApp: boolean;
  isInApp: boolean;
  isAnyApp: boolean;
  currentDropDown: number = null;
  currentThirdlevelDropDown: number = null;
  uniqueId: string = 'tphumbergerotvc'
  feedbackButtonFormId = (window as any).feedbackButtonFormId;
  feedbackButtonFormIdIos = (window as any).feedbackButtonFormIdIos;
  feedbackButtonFormIdAndroid = (window as any).feedbackButtonFormIdAndroid;
  enableFeedbackMobile = (window as any).enableFeedbackMobile;

  showOptionCentre = true
  @HostListener('click', ['$event'])
  onClick(e) {
    if (e.target.className === 'overlay') {
      this.closeFunction('');
    }
  }
  constructor(
    private router: Router,
    private connectCIBCService: ConnectCIBCOnlineLandingService,
    private persistanceService: PersistenceService,
    private ssoService: SsoService,
    private contentService: ContentService,
    public modalService: ModalService,
    public appStore: AppStore,
    private accountInfoService: AccountInfoService,

    private httpService: HttpService,
    private _subscriptionService: ObservableSubscriptionService,
    public globalContent: GlobalContentStore,
    private _commonService: CommonService,
    private globalSignOffService: GlobalSignOffService,
    private renderer: Renderer2,
    private globalHeaderService: GlobalHeaderService,
    private trackingService: TrackingService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.document.body.style.overflow = 'hidden';
    this.modalService.addLog(Date.now().toString() + ": Ham Open::");
  }

  ngOnInit() {
    this.currentLob = this.appStore.state.user.lob.toUpperCase();
    this.isApp = this.appStore.isApp('app');
    this.isInApp = this.appStore.isApp('inapp');
    this.isAnyApp = this.appStore.isApp();
    this.Lob = (this.currentLob.toLowerCase() === 'cfpi') ? 'iis' : this.currentLob.toLowerCase();
    if (this.Lob === Lob.IE) {
      this.PEEnabled = this.appStore.state.user.premiumUser;
    }
    else {
      this.PEEnabled = false;
    }
    this.subscriptions.add(this.contentService.fetchContent('navigation').subscribe((data) => {
      if (data) {
        this.lang = this.appStore.state.user.lang;
        this.content = data;
      }
    }, (err) => {
    }));
    this.sessionInfo = this.appStore.retrieveSessionInfo ? this.appStore.retrieveSessionInfo : '';

    let self = this;
    this.appStore.backButtonFn = () => {
      self.closeFunction('');
    };
    this.showOptionCentre = (window as any).showOptionCentre

    for (let i = 0; i < this.navigationObject.length; i++) {
      if (this.navigationObject[i].isActive) {
        this.currentDropDown = i;
      }
    }
  }

  ngOnDestroy() {
    this.closeFunction('');
    this.subscriptions.unsubscribe();
  }

  outgoing() {
    this.subscriptions.add(this.connectCIBCService.getConnectOnlineURL().subscribe(
      (data: any) => {
        if (data) {
          this.connectOnlineUrl = data.ssourl;
          window.location.href = this.connectOnlineUrl;
        }
        // this.loadingService.dismiss();
      },
      (err) => {
        // super.handleError(err);
        if (isDefined(err.error) && isDefined(err.error.Exception) && err.error.Exception !== null &&
          isDefined(err.error.Exception.ErrorCode) && err.error.Exception.ErrorCode === 'OLBSSO406') {
          window.location.href = err.error.Exception.OlbRedirecturl;
        } else {
          // Should be External Signon - AEM page For now redirecting to OBR2 Signon
          // this.router.navigate(['/signon']);
          throw err;
        }
      }));
  }

  checkLob(l: string) {
    return this.appStore.lob == Lob[l];
  }

  primaryClick(i, url?) {
    let chev = document.getElementById("hamburger-chevron-" + i);
    if (i == this.currentDropDown) {
      this.currentDropDown = null;
      if (chev) {
        chev.className = "animatedChevron icon-Chevron-Up rotateDown";
      }

      if (this.navigationObject[i].omniName) {
        let data = this.navigationObject[i].omniName + ":main-nav-small-layout-collapse";
        if ((window as any).TrackingEnabled) {
          of(this.trackingService.tagInteraction(data, true, true, false, true));
        }
      }
    } else {
      if (this.currentDropDown != null) {
        let chev2 = document.getElementById("hamburger-chevron-" + this.currentDropDown);
        if (chev2) {
          chev2.className = "animatedChevron icon-Chevron-Up rotateDown";
        }
      }
      this.currentDropDown = i;
      if (chev) {
        chev.className = "animatedChevron icon-Chevron-Up rotateUp";
      }

      if (this.navigationObject[i].omniName) {
        let data = this.navigationObject[i].omniName + ":main-nav-small-layout-expand";
        if ((window as any).TrackingEnabled) {
          of(this.trackingService.tagInteraction(data, true, true, false, true));
        }
      }
    }
  }

  closeFunction(url?) {
    this.appStore.backButtonFn = undefined;

    if (url === 'outgoing') {
      this.outgoing();
    }
    this.undoTrapFocus();
    const detectIOS = this.getMobileOperatingSystem();
    if (detectIOS !== 'iOS') {
      this.unsetHamBurgerAriaAttribute();
    }
    this.document.body.style.overflow = 'auto';
    this.modalService.addLog(Date.now().toString() + ": Ham Close Fn::");
    this.close.emit(true);
  }

  navUserPref() {
    this._commonService.callNonAPI('/txn/userPreferences');
  }

  signOff() {
    this.document.body.style.overflow = 'auto';
    this.modalService.addLog(Date.now().toString() + ": Ham Signoff::");
    this.close.emit(true);
    this.globalSignOffService.signOff();
  }

  isPermission(routeEnum) {
    return this._commonService.checkPermissionForRoutes(routeEnum);
  }

  SwitchGlobalLob(lob) {
    this.Lob = lob.toLowerCase();
    this.currentLob = lob;
    const detectIOS = this.getMobileOperatingSystem();
    if (detectIOS !== 'iOS') {
      this.unsetHamBurgerAriaAttribute();
    }
    this.document.body.style.overflow = 'auto';
    this.modalService.addLog(Date.now().toString() + ": Ham Switch LOB::");
    this.undoTrapFocus();
    const switchLobRequest = { TargetLob: lob.toUpperCase() };
    const switchLobUrl = gatewayConfig.APIServices.switchLob.url;
    this.accountInfoService.myAccountsPageReq(switchLobRequest, switchLobUrl).subscribe(
      (data: any) => {
        if (data) {
          this._subscriptionService.hideChat();
          if (this.Lob != Lob.IE) {
            this.appStore.state.user.premiumUser = false;
            this.PEEnabled = false;
          }
          else {
            this.subscriptions.add(this._subscriptionService.premiumUser.subscribe(
              (data) => {
                if (data) {
                    let userData:any = {};
                    userData = data;
                    if (userData.UserId) {
                      this.appStore.state.user.premiumUser = true;
                      this.PEEnabled = true;
                    }
                }
              }
            ));
            this._subscriptionService.showChat();
          }
          this._subscriptionService.passLob(data.TargetLob);
          this._commonService.startBrazeSession().subscribe(() => {});
          this.setAccountHoldingParams('');
          if (data.NextPageName && (data.NextPageName === 'BROADCAST')) {
            this.httpService.post('/v2/auth/broadcast', {}).subscribe((data) => {
              if (this._commonService.compareBroadcastHashes(data.Message)) {
                this.contentService.fetchGlobalContent();
                if (this.router.url === '/txn/accounts/myaccounts') {
                  this._subscriptionService.passGlobalLob(this.Lob);
                } else {
                  this.router.navigate(['/txn/accounts/myaccounts']);
                }
              } else {
                if (this.Lob === Lob.IE) {
                  this.appStore.state.user.premiumUser = false;
                }
                BroadcastStore.inFlow = data;
                this.appStore.preTxnPagePermission = pretxnConfig.permission.BRDCST;
                this._commonService.authorize('/broadcast');
              }
            }, (err) => {
              throw err;
            });
          }
          else if (data.NextPageName && (data.NextPageName === 'txn.MYACCOUNTS' || data.NextPageName === 'MYACCOUNTS')) {
            this.contentService.fetchGlobalContent();
            // this.contentService.fetchCurerencyContent();
            if (this.router.url === '/txn/accounts/myaccounts') {
              this._subscriptionService.passGlobalLob(this.Lob);
            } else {
              this.router.navigate(['/txn/accounts/myaccounts']);
            }
          } else if (data.NextPageName && (data.NextPageName === 'CAMPAIGN')) {
            this._commonService.handleNextPage(data.NextPageName, this);
          }
        }
      },
      err => {
        throw err;
      });
    // } else {
    //   event.preventDefault();
    // }
    // }
    this.close.emit(true);
  }

  setAccountHoldingParams(accountnumber, accountType?, currency?, subAccountNumber?) {
    const accountNumberObj = {
      AccountNumber: accountnumber,
      AccountType: accountType ? accountType : '',
      Currency: currency ? currency : '',
      SubAccountNumber: subAccountNumber ? subAccountNumber : ''
    };
    AccountHoldingsStore.params = { accountNumber: accountNumberObj };
  }

  goToMarketLens() {
    if (!this._commonService.isDesktop()) {
      window.open(this.globalContent?.text?.linkURL, '_system', 'location=yes, resizable=yes, scrollbars=yes');
      return
    }
    this.httpService.post(gatewayConfig.APIServices.marketlensSettings.url, {})
      .subscribe((res) => {
        if (res.TradingPasswordValidation) {
          this.openMTPAppModal()
        } else {
          this._commonService.goToMarketlensWithoutPassword()
        }
      },
        (err) => {
          this.appStore.showErrorInPopup = true;
          throw err;
        });
  }

  openMTPAppModal() {
    this.modalService.open('marketlensTredingPwdModalHam', 'marketlensTredingPwdModalHeaderHam')
  }

  closeMTPModal() {
    this.modalService.close('marketlensTredingPwdModalHam', 'marketlensTredingPwdModalHeaderHam')
  }

  trapFocusForHamburgerElements() {
    this.focusableElments = { 'overlayContent': { focusableElements: [] } };
    this._commonService.setTabIndex('overlayContent', '', this.focusableElments, '', 'app-hamburger-menu', '');
    this._commonService.isHamburgerOpen = true;
  }

  setHamburgerAriaAttribute() {
    this.desktopContainer = this.document.getElementById('page-content-container');
    this.headerContainer = this.document.getElementById('global-header');
    this.pageHeader = this.document.getElementById('page-header');
    this.thirdLevelNavDropdown = this.document.getElementById('third-level-nav-dropdown');
    this.pagefooter = this.document.getElementById('page-footer-wrapper');
    this.desktopContainer ? this.desktopContainer.setAttribute('aria-hidden', 'true') : '';
    this.headerContainer ? this.headerContainer.setAttribute('aria-hidden', 'true') : '';
    this.pageHeader ? this.pageHeader.setAttribute('aria-hidden', 'true') : '';
    this.thirdLevelNavDropdown ? this.thirdLevelNavDropdown.setAttribute('aria-hidden', 'true') : '';

    this.pagefooter ? this.pagefooter.setAttribute('aria-hidden', 'true') : '';
  }

  undoTrapFocus() {
    const id = 'overlayContent';
    // untrap focus for hamburger
    this._commonService.undoTrapFocus(this.focusableElments[id].focusableElements, id);
    this._commonService.isHamburgerOpen = false;
  }

  unsetHamBurgerAriaAttribute() {
    this.desktopContainer ? this.desktopContainer.setAttribute('aria-hidden', 'false') : '';
    this.headerContainer ? this.headerContainer.setAttribute('aria-hidden', 'false') : '';
    this.footerContainer ? this.footerContainer.setAttribute('aria-hidden', 'false') : '';
    this.pageHeader ? this.pageHeader.setAttribute('aria-hidden', 'false') : '';
    this.thirdLevelNavDropdown ? this.thirdLevelNavDropdown.setAttribute('aria-hidden', 'false') : '';
    this.pagefooter ? this.pagefooter.setAttribute('aria-hidden', 'false') : '';
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }

  ngAfterViewInit() {
    // only for IOS, Safari
    const detectIOS = this.getMobileOperatingSystem();
    if (detectIOS === 'iOS') {
      const signoffBtn = this.document.getElementById('sign-off-button');
      signoffBtn.addEventListener('blur', function () {
        setTimeout(() => {
          if (document.activeElement.id === 'cibcLogo') {
            document.getElementById('CIBC-logo').focus();
          }
        }, 50);
      });
    }

    setTimeout(() => {
      const closeButton = this.document.getElementById('close-button');
      window.requestAnimationFrame(() => {
        if (closeButton) {
          closeButton.focus();
        } else {
          document.getElementById('CIBC-logo').focus();
        }
      });
      this.trapFocusForHamburgerElements();
      const detectIOS = this.getMobileOperatingSystem();
      if (detectIOS !== 'iOS') {
        this.setHamburgerAriaAttribute();
      }
    }, 100);
    if (this._commonService.isNotchPhone()) {
      if (this.document.getElementById('overlay-content')) {
        this.renderer.addClass(this.document.getElementById('overlay-content'), 'overlay-content-ios');
        this.renderer.addClass(this.document.getElementById('overlay-footer'), 'overlay-footer-ios');
      }
    }
    for (let i = 0; i < this.navigationObject.length; i++) {
      if (this.navigationObject[i].isActive) {
        this.currentDropDown = i;
        for (let j = 0; j < this.navigationObject[i].secondaryLinks.length; j++) {
          if (isDefined(this.navigationObject[i].secondaryLinks[j].thirdLevelNavigation) && this.navigationObject[i].secondaryLinks[j].isSecondaryLinkActive) {
            this.currentThirdlevelDropDown = j;
            setTimeout(() => {
              let thirdLevelChev = document.getElementById("hamburger-third-level-chevron-" + j);
              if (thirdLevelChev) {
                thirdLevelChev.className = "animatedChevron icon-Chevron-Up ";
              }
            }, 10)
          }
        }
      }
      let chev = document.getElementById("hamburger-chevron-" + i);
      if (chev && i !== this.currentDropDown) {
        chev.className = "animatedChevron icon-Chevron-Up pointDown";
      }
    }
  }

  openHelp() {
    this._commonService.openHelpWindow();
  }
  goToOnlineBanking() {
    this.getConnectOnlineURL().subscribe(
      (data: any) => {
        if (data) {
          const url = data.ssourl;
          window.location.href = url;
        }
        // this.loadingService.dismiss();
      },
      (err) => {
        // super.handleError(err);
        if (isDefined(err.error.Exception) && err.error.Exception !== null && isDefined(err.error.Exception.ErrorCode) &&
          err.error.Exception.ErrorCode === 'OLBSSO406') {
          window.location.href = err.error.Exception.OlbRedirecturl;
        } else {
          // Should be External Signon - AEM page For now redirecting to OBR2 Signon
          // this.router.navigate(['/signon']);
          throw err;
        }
      });
  }
  getConnectOnlineURL() {
    const currentURL = window.location.hash.slice(1);
    this.persistanceService.sessionStorePersist('LastVisitedURLBeforeOLB', currentURL);
    return this.httpService.post(gatewayConfig.APIServices.goToBanking.url, {});
  }
  goToAdvisorPage() {

    this.subscriptions.add(this.globalHeaderService.getAdvisorData().subscribe(
      (data: any) => {
        if (data && data.Url && data.HashRRCode) {
          let url = data.Url + data.HashRRCode;

          if (this.appStore.isApp()) {
            window.open(url, '_system', 'location=yes');
          } else {
            window.open(url, '_blank', 'location=yes, resizable=yes, scrollbars=yes');
          }
        }
      },
      (err) => {
        console.log('myAdvisor error = ');
        console.log(err);
      }));
  }
  openWindow(moreListItem) {
    if (moreListItem.url === 'dco') {
      this.openDCOAppModal();
    } else {
      if (moreListItem.tab && !this.appStore.isApp('app')) {
        window.open(moreListItem.url);
      } else {
        window.open(moreListItem.url, '_system', 'location=yes, resizable=yes, scrollbars=yes');
      }
    }
  }

  openWindowUrl(url) {
    window.open(url, '_system', 'location=yes, resizable=yes, scrollbars=yes');
  }

  openWindowGuide(moreListItem) {
    window.open(moreListItem, '_system', 'location=yes, resizable=yes, scrollbars=yes');
  }

  openDCOAppModal() {
    this.modalService.open('dcoAppNavBar', 'dcoAppModalHeaderNav');
  }

  submitDCOApp() {
    this._commonService.goToDCOApplication().subscribe(
      (data) => {
        console.log("Success details", data);
        this.dcoFormSubmit(data);
      },
      (err) => {
        console.log("Error details", err);
        this.dcoFormSubmit();
      }
    )
  }
  dcoFormSubmit(data?) {
    this.modalService.close('dcoAppNavBar', 'moreLink');
    let domain = "https://";
    if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
      domain += 'm.onlinebrokerage.cibc.com';
    } else {
      domain += window.location.host;
      //enable for local testing depending on the environment u are connected to change the domain to either sit, dit
      //domain += 'm.sit4.onlinebrokerage.cibc.com';
    }
    const target = this.appStore.isApp() ? "_system" : "_self";
    const token = data?.DcoAcctOpenJwtToken;
    const lang = this.appStore.lang;
    let url = domain + "/dco/html/registerOnline.html#" + lang;
    if (token) {
      url = url + "/" + encodeURIComponent(token);
    }
    window.open(url, target);
    if (this.appStore.isApp('app')) {
      this.globalSignOffService.obr2DCOWrapperSignoff();
    } else if (this.appStore.isApp('inapp')) {
      this.globalSignOffService.signOff(true, true);
    }
  }

  closeDCOModal() {
    this.modalService.close('dcoAppNavBar', 'moreLink');
  }

  isDesktop() {
    return this._commonService.isDesktop();
  }

  mainNavInteraction(primaryName) {
    if (primaryName) {
      let data = "main-nav-small-layout:" + primaryName;
      if ((window as any).TrackingEnabled) {
        of(this.trackingService.tagInteraction(data, true, true, false, true));
      }
    }
  }

  secondLevelNavInteraction(primaryName, subName) {
    if (primaryName && subName) {
      let data = primaryName + ":second-nav-small-layout:" + subName;
      if ((window as any).TrackingEnabled) {
        of(this.trackingService.tagInteraction(data, true, true, false, true));
      }
    }
  }

  displayThirdLevelNav(j) {
    let chev = document.getElementById("hamburger-third-level-chevron-" + j);
    if (j == this.currentThirdlevelDropDown) {
      this.currentThirdlevelDropDown = null;
      if (chev) {
        chev.className = "animatedChevron icon-Chevron-Up rotateDown";
      }
    } else {
      if (this.currentThirdlevelDropDown != null) {
        let chev2 = document.getElementById("hamburger-third-level-chevron-" + this.currentThirdlevelDropDown);
        if (chev2) {
          chev2.className = "animatedChevron icon-Chevron-Up rotateDown";
        }
      }
      this.currentThirdlevelDropDown = j;
      if (chev) {
        chev.className = "animatedChevron icon-Chevron-Up rotateUp";
      }
    }
  }

  openNews() {
    this.modalService.open('HeaderNewsModal', 'header-news-modal-title');
    let modal = document.getElementById("HeaderNewsModal");
    if (modal) {
      let mainBody = modal.getElementsByClassName("header-news-modal-body")[0];
      if (mainBody) {
        mainBody.className = "header-news-modal-body opening";
      }
    }
  }

  openLegal() {
    this.modalService.open("post-legal", "post-legal-close-button");
  }

  openNewsLetter() {
    if (this.isApp) {
      this.openWindowUrl(this.moreContent.text.newsLetterURL);
    } else {
      this.modalService.open('signupNewsletterModal', 'signupNewsletterModalHeader');
    }
  }

  openFeedback() {
    if (!this.appStore.isApp() && window.KAMPYLE_ONSITE_SDK) {
      window.KAMPYLE_ONSITE_SDK.showForm(this.feedbackButtonFormId);
    } else if(this.enableFeedbackMobile === true){
      const formId = this.appStore.platform.toLowerCase() === "ios" ? this.feedbackButtonFormIdIos : this.feedbackButtonFormIdAndroid;
      this.appStore.features.medalliaDigital.showForm(formId, (success)=>{
        console.log('show sucess' + JSON.stringify(success));
      }, (error) => {
        console.log('show error' + JSON.stringify(error));
      });
    }
  }

}
