import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable, Injector } from "@angular/core";
import { ContentService } from "../services/content.service";
import { Observable, forkJoin, of, throwError, observable } from "rxjs";
import { PageService } from "src/app/shared/services/page-service";
import { typeOf } from "src/app/config/type-mapper";
import { routeConfig } from "src/app/config/router-mapping";
import { RouteConfig } from "src/app/shared/models/route-config";
import { HttpService } from "../services/http.service";
import { PageStore } from "src/app/shared/models/page-store";
import { AppStore } from "src/app/shared/models/app-store";
import { ContentStore } from "src/app/shared/store/content-store";
import { TrackingService } from "src/app/shared/services/tracking.service";
import { GlobalContentStore } from "../../shared/store/global-content-store";
import { ObservableSubscriptionService } from "../../shared/services/observable-subscription.service";

import { get } from "lodash";
import { IOmnitureTransactionData } from "../../shared/models/omniture.types";

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({ providedIn: "root" })
export class AppRouteResolve  {
  constructor(
    private appStrore: AppStore,
    private injector: Injector,
    private contentProvider: ContentService,
    private httpService: HttpService,
    private appStore: AppStore,
    private globalContent: GlobalContentStore,
    private trackingService: TrackingService,
    private subscriptionService: ObservableSubscriptionService
  ) { }

  prepareInput(config: RouteConfig): any[] {
    return config.restService.map((item) => {
      return {
        id: item.id,
        restUrl: item.restUrl,
        input: item.request,
        optional: item.optional,
        critical: item.critical,
      };
    });
  }

  handleServiceRequest(
    input: any[],
    reqURL: string,
    config: RouteConfig
  ): Observable<any> {
    const resp: Observable<any>[] = [];
    const optionalList: boolean[] = [];
    let pageStoreClass;
    if (input) {
      resp.push(
        ...input.map((api) =>
          this.httpService.post(
            gatewayConfig.APIServices[api.restUrl].url,
            api.input,
            this.checkCriticalAPIs(api.restUrl) ? { params: { critical: "true" } } : null
          )
        )
      );
      optionalList.push(...input.map((api) => (api.optional ? true : false)));
    }
    if (config.resolveContent) {
      console.log("Resolving Content [", config.resolveContent, "]");
      resp.push(this.contentProvider.fetchContent(config.resolveContent));
      optionalList.push(false);
    }
    if (resp && resp.length > 0) {
      const res = this.optionalJoin(resp, optionalList); // .pipe(catchError(error=>of(error)));
      return Observable.create((observer) => {
        const processResp = (data?: any[], err?: any) => {
          if (data) {
            this.appStore.crossFlow = false;

            let ps: PageStore<{}>;
            if (config.store) {
              ps = this.injector.get(typeOf(config.store));
              if (config.trackingData?.transaction) {
                pageStoreClass = typeOf(config.store);
              }
            }
            const pageData: any[] = [];
            data.forEach((dataItem) => {
              if (ps) {
                if (dataItem.name === undefined) {
                  pageData.push(dataItem);
                } else {
                  const v = this.injector.get(ContentStore);
                  Object.assign(v, dataItem);

                  if (v && v.text && v.text.pageHeader) {
                    document.title =
                      v.text.pageHeader + " | " + this.globalContent.text.title;
                  } else {
                    document.title = this.globalContent.text.title;
                  }
                }
              }
            });
            if (pageData.length > 0) {
              ps.setdata(pageData);
            }
            observer.next(data);
            // console.log('Uma --> Navigating to Next Page %s', JSON.stringify(config));
            // Omniture setup to track page navigation
            if (
              (window as any).TrackingEnabled &&
              config.trackingData
            ) {
              let tradeInfo: IOmnitureTransactionData;
              if (config.trackingData?.transaction) {
                let transactionConfig = config.trackingData.transaction;

                let omnitureStoreData = transactionConfig.mainField
                  ? get(pageStoreClass, transactionConfig.mainField)
                  : undefined;

                if (omnitureStoreData) {
                  const orderId = this.getOrderID(omnitureStoreData, pageData[0]);
                  tradeInfo = this.trackingService.tagTransaction(
                    transactionConfig,
                    omnitureStoreData,
                    orderId
                  );
                }
              }
              of(
                this.trackingService.tagPageAction(reqURL, "", tradeInfo, input)
              );
            }
            this.subscriptionService.enableButton("false");
            observer.complete();
          } else {
            console.log("Error, failed to load ", config.name, " blocked ");
            this.subscriptionService.enableButton("true");
            // alert('Error Occured !!! On Page Load');
            // observer.error(err);
            observer.complete();
            throw err;
          }
        };
        res.subscribe(
          (data) => processResp(data, null),
          (err) => processResp(null, err)
        );
      });
    }
    return of({});
  }

  getOrderID(storeData, pageData) {
    if (storeData.orderConfirmInfo) {
      if (storeData.orderConfirmInfo.OrderId) {
        return storeData.orderConfirmInfo.OrderId;
      } else {
        storeData.orderConfirmInfo.OrderID;
      }
    } else if (pageData.OrderId) {
      return pageData.OrderId;
    } else {
      return pageData.OrderID;
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.appStore.isWrappedBack = false;

    let paramSplit = state.url.split('?');
    let newState = paramSplit[0];

    const config = routeConfig(newState);
    if (config) {
      // this.checkPolicy(config,state)
      let service: PageService;
      let input: any[];
      if (config.service) {
        input = this.prepareInput(config);
        if (config.service !== "use-proxy") {
          if (typeOf(config.service)) {
            // console.log(
            //   "Change config for this Service --- " + typeOf(config.service)
            // );
            service = this.injector.get(typeOf(config.service));
            input = service.prepareInput(input, route, state);
          } else {
            //cant give the entire path as a variable. as webpack wont be anle to resolve and throw an error "cannot find module"
            console.log("Serive Path", config.servicePath);
            const m = require("src/app/modules/" +
              config.servicePath +
              ".service");
            console.log("Dynamically injected Service ", m[config.service]);
            service = this.injector.get(m[config.service]);
            input = service.prepareInput(input, route, state);
          }
        }
      }
      return this.handleServiceRequest(input, newState, config);
    } else {
      return of({}); // always return success if no data/content to process
    }
  }
  checkPolicy(config: RouteConfig, state: RouterStateSnapshot): void {
    if (config.policy) {
      console.log(
        "Navigativng from [",
        this.appStrore.state.state.currentUrl,
        "] -> [",
        state.url,
        "]"
      );
      if (!config.policy.navFrom.url["*"]) {
        console.log("Navigation Restricted");
        window.alert("Navigation Restricted");
        // throw Observable.throw({data:'Navigation Restricted'});
      } else {
        console.log("Navigation Permitted  ");
      }
    }
  }

  checkCriticalAPIs(api) {
    if( Array.isArray((window as any).criticalAPIs)) {
      if((window as any).criticalAPIs.indexOf(api) > -1) {
        return true
      }
    }
    return false;
  }

  optionalJoin(resp, optionalList): Observable<any[]> {
    const obs = new Observable<any[]>((observable) => {
      if (resp.length === 1) {
        resp[0].subscribe(
          (data) => {
            observable.next([data]);
          },
          (err) => {
            if (optionalList[0]) {
              observable.next([{ isOptionalError: true, error: err }]);
            } else {
              observable.error(err);
            }
          }
        );
      } else {
        let doneOne = false;
        let pass: any;
        resp[resp.length - 1].subscribe(
          (data) => {
            if (doneOne) {
              const ret = pass;
              ret.push(data);
              observable.next(ret);
            } else {
              pass = data;
              doneOne = true;
            }
          },
          (err) => {
            if (optionalList[optionalList.length - 1]) {
              if (doneOne) {
                const ret = pass;
                ret.push({ isOptionalError: true, error: err });
                observable.next(ret);
              } else {
                pass = { isOptionalError: true, error: err };
                doneOne = true;
              }
            } else {
              observable.error(err);
            }
          }
        );

        this.optionalJoin(
          resp.slice(0, resp.length - 1),
          optionalList.slice(0, optionalList.length - 1)
        ).subscribe(
          (data: any[]) => {
            if (doneOne) {
              const ret = data;
              ret.push(pass);
              observable.next(ret);
            } else {
              pass = data;
              doneOne = true;
            }
          },
          (err) => {
            observable.error(err);
          }
        );
      }
    });
    return obs;
  }
}
