import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { PageService } from 'src/app/shared/services/page-service';
import { ripAccountRequest } from "../data/regular-investment-plan-data";
declare let require: any;
const gatewayConfig = require("../../../../config/gateway-config.json");


@Injectable({
    providedIn: 'root'
})
export class RegularInvestmentPlanService implements PageService {

    constructor(private httpService: HttpService) { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return input;
    }

    selectedAccountReq(ripRequest: ripAccountRequest) {
        return this.httpService.post(gatewayConfig.APIServices.regularInvestmentPlan.url,
            ripRequest
        );
    }
}