import { isNumber, isString } from 'util';
import { AppStore } from '../models/app-store';
import { _User } from 'src/app/shared/models/user';
declare let require: any;
const gatewayConfig = require("../../config/gateway-config.json");
declare let angular: any;


export function isDefined(obj: any): boolean {
  if (isNumber(obj)) {
    return true;
  } else {
    return (obj); // angular.isDefined(obj);
  }
}

export function openNewWindow(obj: any) {
  if (isDefined(obj.height) && isDefined(obj.width)) {
    window.open(obj.url, '_blank', "height=" + obj.height + ",width=" + obj.width);
  }
}

export function openWindow(url: string, appstore, document: boolean = false) {
  if (url.startsWith('/')) {
    url = window.location.origin + url;
  }
  if (isString(url) && (url.substr(url.length - 4, 4) == '.pdf' || url.indexOf('/mobi' + gatewayConfig.APIServices.DocsSecured.url) != -1 || url.indexOf('/common/documentPreview.jsp') != -1 || document)) {
    if (appstore.isApp('inapp')) {
      window.open(url, '_inapp');
    } else if ((appstore.isApp('app') && appstore.platform === _User.Platform.ANDROID) || navigator.userAgent.toLowerCase().indexOf(_User.Platform.ANDROID) != -1) {
      // if (url.indexOf('/common/documentPreview.jsp') == -1) {
      //   url = 'https://docs.google.com/viewerng/viewer?url=' + url;
      // }
      window.open(url, '_system', 'location=yes');
    } else {
      window.open(url, '_blank', 'location=yes, resizable=yes, scrollbars=yes');
    }
  } else {
    window.open(url, '_system', 'location=yes, resizable=yes, scrollbars=yes');
  }
}

