import { BaseData } from 'src/app/shared/models/base-data';

export class LinkBankAccountsLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountNumber: null,
            transitNumCAD: null,
            accountNumCAD: null,
            transitNumUSD: null,
            accountNumUSD: null,
            email: null,
            bankOption: null,
            account: null,
        };
    }
}

export interface LinkBankAccountsLandingInputs {
    accountNumber: string;
    transitNumCAD: string;
    accountNumCAD: string;
    transitNumUSD: string;
    accountNumUSD: string;
    email: string;
    bankOption: string;
    account: string
}

export enum ParamsMapping {
    Param1 = '',
    Param2 = 'email',
    Param3 = 'transitNumCAD',
    Param4 = 'accountNumCAD',
    Param5 = 'transitNumUSD',
    Param6 = 'accountNumUSD'
}
