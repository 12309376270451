import { Injectable } from "@angular/core";
import { PageStore } from "../../../../../../shared/models/page-store";
import { SellBondConfirmData } from "./sell-bond-confirm-data";

@Injectable({ providedIn: "root" })
export class SellBondConfirmStore extends PageStore<SellBondConfirmData> {
  constructor() {
    super(new SellBondConfirmData());
  }
}
