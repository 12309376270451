import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { OnlineRegisterConfirmationData } from './onlineregister-confirmation-data';

@Injectable({ providedIn: 'root' })
export class OnlineRegisterConfirmationStore extends PageStore<OnlineRegisterConfirmationData> {

    constructor() {
        super(new OnlineRegisterConfirmationData());
    }

}
