import { BaseData } from 'src/app/shared/models/base-data';

export class CreateSignonTradingPassData extends BaseData {
    constructor() {
        super();
        this.input = {
            inputtedCurrentSignOnPassword: null,
            newSignOnPassword: null,
            reEnteredSignOnPassword: null,
            inputtedCurrentTradingPassword: null,
            passOption:'',
            newTradingPassword: null,
            reEnteredTradingPassword: null
        };
    }
}

export interface CreateSignOnTradingPassInputs {
    inputtedCurrentSignOnPassword: string;
    newSignOnPassword: string;
    reEnteredSignOnPassword: string;
    inputtedCurrentTradingPassword: string;
    passOption: string;
    newTradingPassword: string;
    reEnteredTradingPassword: string;
}