import { BaseData } from 'src/app/shared/models/base-data';

export class MarketsLandingData extends BaseData {
  constructor() {
    super();
    this.input = {

    }
  }
}
export interface MarketsLandingInputs {

}
