import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxnComponent } from './txn.component';
import { TxnRoutingModule } from './txn-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { StickyHeaderModule } from './../../shared/sticky-header.module';
import {ChatSharedModule} from '../../shared/chat-shared.module';
import {QuickTradeSharedModule} from '../../shared/quick-stock-trade-shared.module'
import { QrCodeModule } from 'ng-qrcode';
import { QuickTradeOptionsSharedModule } from 'src/app/shared/quick-trade-options-shared.module';

@NgModule({
  declarations: [
    TxnComponent
  ],
  imports: [
    CommonModule,
    TxnRoutingModule,
    SharedModule,
    CoreModule,
    StickyHeaderModule,
    ChatSharedModule,
    QuickTradeSharedModule,
    QuickTradeOptionsSharedModule,
    QrCodeModule
  ]
})
export class TxnModule { }
