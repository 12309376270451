import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { AppStore } from "./shared/models/app-store";
import { typeOf } from "src/app/config/type-mapper";
import { routeConfig } from "src/app/config/router-mapping";
import { CommonService } from "./shared/services/common.service";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { isDefined } from "src/app/shared/services/utils.service";

import "what-input";
import { _User } from "./shared/models/user";
import { PersistenceService } from "./core/services/persistence.service";
import { ObservableSubscriptionService } from "./shared/services/observable-subscription.service";

declare let require: any;
const pretxnConfig = require('src/app/config/pretxnpage-config.json');
const gatewayConfig = require("src/app/config/gateway-config.json");

export let browserRefresh = false;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  // useful can be used if the browser tab is closed
  //   @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
  //     const app = this;
  //     console.log("Processing beforeunload...");
  //     event.returnValue = false;
  //     window.sessionStorage.setItem('refreshTime', JSON.stringify({ time: Date.now() }));
  //     if (app.store.state.state.currentUrl) {
  //       window.sessionStorage.setItem('refreshURL', app.store.state.state.currentUrl);
  //     } else {
  //       window.sessionStorage.setItem('refreshURL', '');
  //     }
  //     typeOf(routeConfig(app.store.state.state.currentUrl).store).saveToSessionStorage();
  // }

  subscription: Subscription;
  activatedRouterSubscription: Subscription;
  xDown = null;
  yDown = null;
  isApp: boolean;
  subscriptions: Subscription[] = [];
  hideChat;

  constructor(
    private store: AppStore,
    private commonService: CommonService,
    private router: Router,
    private location: Location,
    private appStore: AppStore,
    private activatedRoute: ActivatedRoute,
    private persistenceService: PersistenceService,
    private subscriptionService: ObservableSubscriptionService,
  ) {
    const app = this;
    window.onunload = (event) => {
      window.sessionStorage.setItem(
        "refreshTime",
        JSON.stringify({ time: Date.now() })
      );
      if ((window as any).TrackingEnabled && this.appStore.omnitureId) {
        window.sessionStorage.setItem("omnitureId", this.appStore.omnitureId);
      }
      if (app.store.state.state.currentUrl) {
        window.sessionStorage.setItem(
          "refreshURL",
          app.store.state.state.currentUrl
        );
        // } else {
        //   window.sessionStorage.setItem('refreshURL', '');
      }
      if (
        isDefined(routeConfig(app.store.state.state.currentUrl)) &&
        isDefined(typeOf(routeConfig(app.store.state.state.currentUrl).store))
      ) {
        typeOf(
          routeConfig(app.store.state.state.currentUrl).store
        ).saveToSessionStorage();
      }
      this.commonService.cleanWebOnlyCode();
    };

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.store.state.state.refresh = browserRefresh = !router.navigated;
      }
    });

    this.activatedRouterSubscription = activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      const next = params.next;
      const tempPasswordFlag = params.tempPasswordFlag;
      const omnitureToken = params.omnitureToken;
      const biometricType = params.biometricType;
      this.handlePageChange(next, tempPasswordFlag, omnitureToken, biometricType);
    })
  }
  ngOnInit() {
    this.commonService.updateLanguage();
    this.isApp = this.appStore.isApp('app');
    this.subscriptions.push(
      this.subscriptionService.chathideEvent$.subscribe((val) => {
        this.hideChat = val;
      })
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (document.body.clientWidth < 900) {
      this.store.layout = 0;
    } else {
      this.store.layout = 1;
    }
  }

  @HostListener("touchstart", ["$event"])
  @HostListener("touchmove", ["$event"])
  handleTouch(event) {
    const touch = event.touches[0] || event.changedTouches[0];
    // check the events
    if (event.type === "touchstart") {
      this.xDown = touch.clientX;
      this.yDown = touch.clientY;
    } else if (event.type === "touchmove") {
      if (!this.xDown || !this.yDown) {
        return;
      }

      const xUp = event.touches[0].clientX;
      const yUp = event.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;
      const mxDiff = Math.abs(xDiff);
      if (mxDiff > 18 && mxDiff > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe */
        } else {
          this.rightSwipeEvent(event);
          /* right swipe */
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    }
  }

  /*
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];

    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaY = touch.pageY - this.defaultTouch.y;
      let deltaTime = event.timeStamp - this.defaultTouch.time;

      // simulte a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < 250) {
        // touch movement lasted less than 500 ms, changed from 80 to 150 for less sensitivity
        if (Math.abs(deltaX) > 150 && Math.abs(deltaY) < 40) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.rightSwipeEvent(event);
          } else {
            //this.doSwipeLeft(event);
          }
        }

        if (Math.abs(deltaY) > 60) {
          // delta y is at least 60 pixels
          if (deltaY > 0) {
            //this.doSwipeDown(event);
          } else {
            //this.doSwipeUp(event);
          }
        }
      }
    }
  }*/

  rightSwipeEvent(event) {
    const split = window.location.hash.split("/");
    if (
      this.appStore.isApp("app") &&
      this.appStore.platform.toLowerCase() === "ios" &&
      !(
        split.length > 1 &&
        (split[1] === "home" || split[1] === "wrapper-maintenance")
      )
    ) {
      // No Minimizing App for ios on Home Page unlike Android
      if (this.appStore.backButtonFn) {
        const fn = this.appStore.backButtonFn;
        fn();
      } else {
        this.appStore.isWrappedBack = true;
        this.location.back();
      }
    }
  }

  authorize() {
    this.persistenceService.sessionStorePersist('isSignedOn', true);
    this.appStore.state.user.status = _User.Status.AUTHZ;
  }

  handlePageChange(next, tempPasswordFlag, omnitureToken, biometricType) {
    if (omnitureToken) {
      this.appStore.omnitureId = encodeURIComponent(omnitureToken);
    }
    if (this.appStore.isApp("app")) {
      if (biometricType === "FACE") {
        this.appStore.omnitureAuthType = "face-id";
      } else if (biometricType === "FINGER") {
        this.appStore.omnitureAuthType = "touch-id";
      } else {
        this.appStore.omnitureAuthType = "biometric";
      }
    } else {
      this.appStore.omnitureAuthType = "user-pass";
    }
    if (next === 'BROADCAST') {
      this.appStore.preTxnPagePermission = pretxnConfig.permission.BRDCST;
      this.authorize();
    } else if (next === 'OTVC_SETUP') {
      this.authorize();
    } else if (next === 'OTVC_PROMPT') {
      // if (isDefined(component.otvcPrompt)) {
      //   component.otvcPrompt();
      // }
    } else if (next === 'MYACCOUNTS') {
      this.authorize();
    } else if (next === 'TOUCHID_SETUP') {
      this.persistenceService.sessionStorePersist('isSignedOn', true);
      this.appStore.state.user.status = _User.Status.AUTHZ;
      // this.callNonAPI('txn/userPreferences/touchid/landing');
    } else if (next === 'TEMP_PASSWORD') {
      if (tempPasswordFlag === 'B') {
        this.appStore.preTxnPagePermission = pretxnConfig.permission.CHGSNT;
        this.authorize();
      } else if (tempPasswordFlag === 'S') {
        this.appStore.preTxnPagePermission = pretxnConfig.permission.CHGSGN;
        this.authorize();
      } else if (tempPasswordFlag === 'T') {
        this.appStore.preTxnPagePermission = pretxnConfig.permission.CHGTRD;
        this.authorize();
      }
    } else if (next === 'EXTERNAL_AGREEMENT') {
      console.log('pradeep EAA');
      // alert('EXTERNAL_AGREEMENT TBD');
      this.authorize();
    } else if (next === 'REGISTER') {
      // this.callNonAPI('registerOnline/landing');
    } else {
      console.log('Scenario Not Handled');
    }
  }

  currentPage() {
    return this.appStore.state.state.currentUrl;
  }
  
  selectedLob() {
    return this.appStore.state.user.lob.toString();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub=>{
      sub.unsubscribe();
    })
  }
}
