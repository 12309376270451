import { Injectable, Injector } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from './http.service';
import { ContentStore } from '../../shared/store/content-store';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { Observable, of, combineLatest } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import _ from 'lodash';
import { isDefined } from 'src/app/shared/services/utils.service';

declare var require: any;

const contentConfig = require('../../config/content-config.json');

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private appStore: AppStore, private httpService: HttpService, private injector: Injector) { }

  private path = 'assets/content/pages/';

  fetchContent(page: string) {
    console.log('page name ', page);
    const lob: string = (this.appStore.state.user.lob === 'cfpi') ? 'iis' : this.appStore.state.user.lob;
    const genericContent = this.httpService.getResource(this.path + page + '-' + this.appStore.state.user.lang + '.json');

    if (isDefined(contentConfig.Pages[page]) && contentConfig.Pages[page].indexOf(lob.toUpperCase()) !== -1 ) {
      const lobContent = this.httpService.getResource(this.path + page + '-' + lob + '-' + this.appStore.state.user.lang + '.json');
      const a = combineLatest([genericContent, lobContent]).pipe(
        (map((res) => {
          if (res[0] && res[1]) {
            const mergedContent = this._mergeRecursively(res[0], res[1]); // merged the content lob and generic.
            return mergedContent;
          } else {
            console.log('**** Generic Content File is Missing ****');
            return res[1]; // lob specific content. back ward comptiblity same as today.
          }
        })
        ));
      console.log('Merged content', a);
      return a;
    } else {
      console.log('**** Content Fetch logic changed - Please check you have proper content files and also check content-config.json ****');
      const a = combineLatest([genericContent]).pipe(
        (map((res) => {
          res[0].lob = lob;
          return res[0]; // lob specific content. back ward comptiblity same as today.
        })
        ));
      return a;
    }
  }


  _mergeRecursively(list1, list2, deepLevel?: boolean) {
    const list2Keys = Object.keys(list2);
    const listx = Object.assign({}, list1);
    return list2Keys.reduce((list, field) => {
      if (list[field]) {
        if (_.isPlainObject(list2[field]) && !deepLevel) {
          list[field] = this._mergeRecursively(
            list[field],
            list2[field],
            true
          );
        } else {
          list[field] = list2[field];
        }
      } else {
        list[field] = list2[field];
      }
      return list;
    }, listx);
  }

  fetchGlobalContent() {
    const page = 'global';
    const lob: string = (this.appStore.state.user.lob === 'cfpi') ? 'iis' : this.appStore.state.user.lob;
    this.httpService.getResource(this.path + page + '-' + lob + '-' + this.appStore.state.user.lang + '.json').subscribe((data) => {
      const v = this.injector.get(GlobalContentStore);
      Object.assign(v, data);
    }, (error) => {
      console.error('Global JSON Loading Error: ' + error);
    });
  }

  loadGlobalContent(): Observable<any> {
    const page = 'global';
    const lob: string = (this.appStore.state.user.lob === 'cfpi') ? 'iis' : this.appStore.state.user.lob;
    return this.httpService.getResource(this.path + page + '-' + lob + '-' + this.appStore.state.user.lang + '.json');
  }

  // fetchCurerencyContent() {
  //   const page = 'currency';
  //   const lob: string = (this.appStore.state.user.lob === 'cfpi') ? 'iis' : this.appStore.state.user.lob;
  //   this.httpService.getResource(this.path + page + '-' + lob + '-' + this.appStore.state.user.lang + '.json').subscribe((data) => {
  //     const v = this.injector.get(CurrencyContentStore);
  //     Object.assign(v, data);
  //   }, (error) => {
  //     console.error('Currency Content JSON Loading Error: ' + error);
  //   });
  // }

}
