import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PageService } from 'src/app/shared/services/page-service';
import { CancelCashPlanConfirmStore } from "../data/cancel-cash-plan-confirm-store";

@Injectable({
    providedIn: 'root'
})
export class CancelCashPlanConfirmService implements PageService {

    constructor() { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        if (CancelCashPlanConfirmStore.params) {
            const ser = input[0];
            const req = ser.input;
            if (CancelCashPlanConfirmStore.params.TradingPassword) {
                req.TradingPassword = CancelCashPlanConfirmStore.params.TradingPassword;
                req.SaveTradingPassword = CancelCashPlanConfirmStore.params.SaveTradingPassword;
            }
            return input;
        }

        return [];
    }

}