import { BaseData } from 'src/app/shared/models/base-data';

export class MutualFundsLandingData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1,
      action: '',
      symbol: "",
      symbolIndex: -1,
      amountType: undefined,
      amount: undefined,
      dividendIndicator: "INVEST",
      settlementIndex: -1
    };
  }
}

export interface MutualFundsLandingInputs {
  accountIndex: number;
  action: string;
  symbol: string;
  symbolIndex: number;
  amountType: string;
  amount: string;
  dividendIndicator: string;
  settlementIndex: number;
}


export interface SettlementAccountsReq {
  AccountNumber: string;
  Action: string;
  AccountCurrency?: string;
  Type: string;
}

export interface BuyingPowerReq {
  AccountNumber: string;
}

export interface HoldingsReq {
  AccountNumber: string;
  FiType: string;
}

export interface QuoteReq {
  SymbolName: string;
}
