import { Component, OnInit, ElementRef, ChangeDetectorRef, ViewContainerRef, ViewChild, Input, EventEmitter, Output, OnDestroy, SimpleChanges, ViewEncapsulation, HostListener, Inject } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { TrackingService } from "src/app/shared/services/tracking.service";
import { ModalService } from '../modal/services/modal.service';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { ContentStore } from '../../store/content-store';
import { Lang, Lob } from 'src/app/shared/models/lob.enum';
import { OptionChainStore } from './data/option-chain-store';
import { isDefined } from '../../services/utils.service';
// import { ErrorConstant } from 'src/app/core/models/app-error';
// import { nasdaqSymbolsArray } from './data/option-chain-data';
import { OptionChainService } from './services/option-chain.service';
import { OptionChainInputs } from './data/option-chain-data';
import { of, Subscription } from 'rxjs';
import moment from 'moment';
import { OptionChainValidator } from './option-chain.validator';
import { FormGroup } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SpinnerService } from 'src/app/core/components/spinner/service/spinner.service';
import { AppErrorHandlerService } from 'src/app/core/components/error/services/app-error-handler.service';
import { Router } from '@angular/router';
import { OmniturePageDetail } from '../../models/route-config';


// declare let require: any;
declare let window: any;

@Component({
  selector: 'app-option-chain',
  templateUrl: './option-chain.component.html',
  styleUrls: ['./option-chain.component.scss'],
  animations: [
    trigger("glossaryAnimation", [
      state(
        "void",
        style({
          height: "0",
          opacity: 0,
          overflow: "hidden",
        })
      ),
      state(
        "open",
        style({
          height: "*",
          opacity: 1,
          overflow: "hidden",
        })
      ),
      transition("void => open", animate("400ms ease-in")),
      transition("open => void", animate("400ms ease-out")),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class OptionChainComponent implements OnInit, OnDestroy {
  inputs: OptionChainInputs;
  @Input() mode: string;
  @Input() selectedSymbol: any;
  @Input() contents: any;
  @Input() customRefresh: boolean;
  @Input() orderType: string;
  @Input() orderTypeClose: string;
  @Input() titleId = '';
  @Input() optionQuote;
  @Input() underlyingQuote;
  @Input() quoteError;
  @Input() optionQuoteError
  @Input() fromQuickTrade: boolean;
  @Input() selectedOptionHolding;
  @Input() manuallySearchAndClose = false;
  @Input() inputValues: any
  @Input() componentStore;
  @Input() parentMode;
  @Input() pagename;
  @Input() timestamp;


  @Output() getManualQuote = new EventEmitter<any>(); // only called when all 3 fields are selected.
  @Output() getChainedQuote = new EventEmitter<any>(); // only called when field in chain table is selected
  @Output() selectedChainData = new EventEmitter<any>();
  @Output() selectedGreekData = new EventEmitter<any>();
  @Output() landingSubmitted = new EventEmitter<any>();
  @Output() venueIdError = new EventEmitter<any>();
  @Output() refreshBtnClicked = new EventEmitter<any>();
  @Output() optionGlossaryClicked = new EventEmitter<any>();
  
  @Output() refresh = new EventEmitter<any>();

  @ViewChild('table', { static: true }) ElementRef;
  @ViewChild('optionStrikeDateIndex') optionStrikeDateIndex: ElementRef;
  @ViewChild('optionStrikePriceIndex') optionStrikePriceIndex: ElementRef;
  @ViewChild('optionChainForm') optionChainForm: FormGroup;

  get LOB() { return Lob; };
  markitAPILoading = false;
  dateChangeLoading = false;
  chartType = 'Quotes>Quote List>Quote Detail Equity';
  dash = '-';
  hasError = false;
  errorDisplay = '';
  isExpanded = false;
  noResults = false;
  errObject: any;
  formError: any = {};
  openIdAria = true;
  EAAErrorFlag = false;
  venueID;
  quoteSubscriber: any;
  showSharesLabel: boolean = false;
  selectionFlag: string;
  subscriptions = new Subscription();
  optionTooltipExpandedOptChain: any = [false, false, false, false];
  quoteLoading = false;
  optionStrikePriceList: any = [];
  optionStrikeDateList: any = [];
  formattedOptionStrikeDateList: any = [];
  selectedIndex: number | null = null;
  selectedColumn: any;
  optionStrikePriceCallList: any = [];
  optionStrikePricePutList: any = [];
  selectedManualOptionType: any;
  selectedManualStrikePrice: any;
  selectedExpiryDate: any;
  strikeDatedetail;
  selectedCallIndex: any;
  selectedPutIndex: any;
  startIndex: number;
  endIndex: number;
  strikePriceCallInTheMoney: any = [];
  strikePricePutInTheMoney: any = [];
  strikePriceCallOutTheMoney: any = [];
  strikePricePutOutTheMoney: any = [];
  strikePriceCallNearTheMoney: any = [];
  filterMoneyOptions: any;
  strikeValue: any;
  noContentValue = "---"
  tradeOptionsPositionArray: any = [];
  tradeChainManualEntryArray: any = [];
  uniqueName = "-trade-options";
  filterStrikePrice: any;
  selectedLimitPrice: any;
  dateChange;
  date;
  quotePrice: any;
  scrollToTopOrBottom = '';
  strikeTop;
  strikeBottom;
  strikeBottomPrevious;
  optionQuoteErrorList: any[] = Array(10);
  venueError: boolean = false;
  venueErrorMessage;
  optionQuoteMessage;

  glossaryTooltip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  submitted = false;
  countCallCount = -1;
  retainLoad = false;
  constructor(public modalservice: ModalService, public appStore: AppStore, private trackingService: TrackingService,
    private cdr: ChangeDetectorRef,
    public store: OptionChainStore,
    public globalcontent: GlobalContentStore,
    public contentStore: ContentStore,
    public service: OptionChainService,
    public commonService: CommonService,
    public spinnerService: SpinnerService, private errorHandlerService: AppErrorHandlerService,
    @Inject(DOCUMENT) private document: Document,
    public subscriptionService: ObservableSubscriptionService,
    private router: Router) {
  }

  ngOnInit() {

    this.defineTooltips();
    this.setTradePositionArray();
    this.setChainArray();
    this.service.submitOptionChain.subscribe(data => {
      this.formSubmitted();
    })
    // only for GA
    this.omnitureDetail()
  }
  ngOnDestroy() {
    if (isDefined(this.quoteSubscriber)) {
      this.quoteSubscriber.unsubscribe();
    }

    if (isDefined(this.subscriptions)) {
      this.subscriptions.unsubscribe();
    }
    this.inputs = undefined;
    this.service.setInFlow( {
      "selectedIndex": this.selectedIndex,
      "selectedColumn": this.selectedColumn,
      "selectedCallIndex": this.selectedCallIndex,
      "selectedPutIndex": this.selectedPutIndex
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {

    if (this.inputs === undefined) {
      this.inputs = this.store.state.input;
    }
    if (isDefined(simpleChanges.selectedSymbol)) {
      this.inputs.optionStrikePriceIndex = -1,
      this.inputs.optionStrikeDateIndex = -1,
      this.inputs.strikeOption = 'ALL',
      this.inputs.optionType = 'BOTH',
      this.inputs.manualOptionType = '',
      this.inputs.strikeShown = 20;
      this.selectedIndex = null;
      this.selectedColumn = undefined;
      this.selectedManualOptionType = undefined;
      this.selectedManualStrikePrice = undefined;
      this.selectedExpiryDate = undefined;
      this.strikeDatedetail = undefined
      this.selectedCallIndex = undefined;
      this.selectedPutIndex = undefined;
      this.startIndex = null;
      this.endIndex = null;
      this.scrollToTopOrBottom = '';
      if (this.orderType === 'CLOSE' && !this.manuallySearchAndClose) {
        this.inputs.toggleChart = 'MANUALENTRY'
      } else if ((this.orderTypeClose === 'BUYTOCLOSE' || this.orderTypeClose ==='SELLTOCLOSE') && this.manuallySearchAndClose) {
        this.inputs.toggleChart = 'CHAIN'
      } else {
        this.inputs.toggleChart = 'CHAIN'
      }
    }
    
    if (isDefined(simpleChanges.customRefresh) && this.customRefresh) {
        this.getOptionChainGreeks(this.formatDate(this.inputs.optionStrikeDateIndex, 'YYYY-MM-DD', 'YYYY-MM-DD'))
      this.customRefresh = false;
    } else if (isDefined(simpleChanges.optionQuote) && isDefined(this.optionQuote)) {
      if (this.orderType === 'CLOSE' || this.componentStore.savedInfo || OptionChainStore.crossFlow || (this.isDesktop() && this.pagename != 'OptionCentral') || this.venueError == true) {
        this.setOptionInfoValues();
      }
    } else if (isDefined(simpleChanges.underlyingQuote) && isDefined(this.underlyingQuote)) {
      if (this.venueError == true) {
        this.setOptionInfoValues();
      }
      // if (this.underlyingQuote.MarketId) {
      //   this.showSharesLabel = (nasdaqSymbolsArray.indexOf(this.underlyingQuote.MarketId) !== -1);
      // }
      this.setOptionInfoValues();
    } else if (isDefined(simpleChanges.quoteError) && isDefined(this.quoteError)) {

    } else if (isDefined(simpleChanges.optionQuoteError) && isDefined(this.optionQuoteError)) {

    }
  }
  roundOfPrice(selectedManualStrikePrice) {
    if (typeof(this.selectedManualStrikePrice) == 'string') {
      return parseInt(selectedManualStrikePrice)?.toFixed(2)
    } else {
      return selectedManualStrikePrice?.toFixed(2)
    }
  }
  setOptionInfoValues() {

    if (this.componentStore && this.componentStore.savedInfo) {
      let savedInfo = this.componentStore.savedInfo;
      console.log("savedInfosavedInfosavedInfosavedInfo to OptionChain", JSON.stringify(this.componentStore.savedInfo));
      if (savedInfo["chainInputs"]) {
        this.inputs = savedInfo["chainInputs"]
      }
      let inFlow = OptionChainStore.inFlow
      console.log(inFlow, "<=inFlowinFlowinFlow");
      if(inFlow) {
        this.selectedCallIndex = inFlow["selectedCallIndex"]
        this.selectedPutIndex = inFlow["selectedPutIndex"]
        this.selectedIndex = inFlow["selectedIndex"];
        this.selectedColumn = inFlow["selectedColumn"];
      } else if(savedInfo["inFlowData"]) {
        if(savedInfo["inFlowData"]["selectedCallIndex"]) {
          this.selectedCallIndex = savedInfo["inFlowData"]["selectedCallIndex"];
        }
        if(savedInfo["inFlowData"]["selectedPutIndex"]) {
          this.selectedPutIndex = savedInfo["inFlowData"]["selectedPutIndex"];
        }
        if(savedInfo["inFlowData"]["selectedIndex"]) {
          this.selectedIndex = savedInfo["inFlowData"]["selectedIndex"];
        }
        if(savedInfo["inFlowData"]["selectedColumn"]) {
          this.selectedColumn = savedInfo["inFlowData"]["selectedColumn"];
        }
      }
      if (this.orderType !== 'CLOSE' && isDefined(savedInfo["chainInputs"])) {
        this.selectedManualStrikePrice = savedInfo["chainInputs"]["optionStrikePriceIndex"]
        this.selectedExpiryDate = savedInfo["chainInputs"]["optionStrikeDateIndex"]
      } else if (isDefined(this.componentStore.savedInfo.OptionInfo)) {
        let optionInfo = this.componentStore.savedInfo.OptionInfo;
        this.inputs.optionStrikePriceIndex = optionInfo.StrikePrice.Value;
        this.selectedManualStrikePrice = optionInfo.StrikePrice.Value
        this.strikeValue = optionInfo.StrikePrice.Value
        const expDate = optionInfo.StrikeDate.substring(0, 4) + '-' + optionInfo.StrikeDate.substring(4, 6) + '-' + optionInfo.StrikeDate.substring(6,);
        this.selectedExpiryDate = expDate;
        this.inputs.optionStrikeDateIndex = expDate;
        optionInfo.OptionType === 'C' ? (this.inputs.manualOptionType = 'CALLS') : optionInfo.OptionType === 'P' ? (this.inputs.manualOptionType = 'PUTS') : (this.inputs.manualOptionType = undefined);
      }
      // set selected Row
      // this.selectRow(inFlow["selectedIndex"], inFlow["fieldName"])
      this.selectedChainData.emit({ "Inputs": this.inputs, "closeModal": false });

      this.countCallCount = 0;
      // get the greek data
      let greek = { "CALLS": this.selectedCallIndex, "PUTS": this.selectedPutIndex };
      this.selectedGreekData.emit(greek);
      //this.componentStore.savedInfo = undefined;
    } else {
      const quote = this.optionQuote ? this.optionQuote : this.underlyingQuote;

      if (quote.Symbol) {
        this.quotePrice = quote.LastPrice.Data
        if (quote.Symbol.OptionInfo) {
          if (quote.Symbol.OptionInfo.OptionType) {
            quote.Symbol.OptionInfo.OptionType === 'C' ? (this.inputs.manualOptionType = 'CALLS') : quote.Symbol.OptionInfo.OptionType === 'P' ? (this.inputs.manualOptionType = 'PUTS') : (this.inputs.manualOptionType = undefined);
            quote.Symbol.OptionInfo.OptionType === 'C' ? (this.inputs.optionType = 'CALLS', this.selectedColumn = 'callStrike') : quote.Symbol.OptionInfo.OptionType === 'P' ? (this.inputs.optionType = 'PUTS', this.selectedColumn = 'putStrike') : (this.inputs.optionType = 'BOTH', this.selectedColumn = 'bothStrike');
          }
          if (quote.Symbol.OptionInfo.StrikePrice) {
            const strikeprice = parseFloat(quote.Symbol.OptionInfo.StrikePrice.Value.replace("$", "").replace(",", "."))
            this.inputs.optionStrikePriceIndex = strikeprice;
            this.selectedManualStrikePrice = strikeprice
            this.strikeValue = strikeprice
          }
          if (quote.Symbol.OptionInfo.StrikeDate) {
            const expDate = quote.Symbol.OptionInfo.StrikeDate.substring(0, 4) + '-' + quote.Symbol.OptionInfo.StrikeDate.substring(4, 6) + '-' + quote.Symbol.OptionInfo.StrikeDate.substring(6,);
            this.inputs.optionStrikeDateIndex = expDate;
            this.selectedExpiryDate = expDate;
            if (!OptionChainStore.crossFlow || (this.orderType == 'CLOSE' && !this.manuallySearchAndClose)) {
              // this.getOptionChainGreeks(expDate);
            } else {
              this.selectedChainData.emit({ "Inputs": this.inputs, "closeModal": false });
              this.service.chainData = { "Inputs": this.inputs};
            }
          }
        } else if (this.inputValues) {
          if (this.inputValues.StrikeDate) {
            const expDate = this.inputValues.StrikeDate.substring(0, 4) + '-' + this.inputValues.StrikeDate.substring(4, 6) + '-' + this.inputValues.StrikeDate.substring(6,);
            this.inputs.optionStrikeDateIndex = expDate;
            this.selectedExpiryDate = expDate;
          } if (this.inputValues.StrikePrice) {
            this.inputs.optionStrikePriceIndex = this.inputValues.StrikePrice;
            this.strikeValue = this.inputValues.StrikePrice
          } if (this.inputValues.OptionType) {
            this.inputValues.OptionType === 'C' ? (this.inputs.optionType = 'CALLS', this.selectedColumn = 'callStrike') : this.inputValues.OptionType === 'P' ? (this.inputs.optionType = 'PUTS', this.selectedColumn = 'putStrike') : (this.inputs.optionType = 'BOTH', this.selectedColumn = 'bothStrike');
          } else {
            this.inputs.optionType = 'BOTH', this.selectedColumn = 'bothStrike'
          }
          console.log(this.inputValues)
        }
      }
    }

    this.selectedSymbol = this.optionQuote ? this.optionQuote.Symbol : this.underlyingQuote.Symbol;

    if(this.optionQuote && this.underlyingQuote) {
      if(this.optionQuote.Symbol && this.underlyingQuote.Symbol){
        if(this.optionQuote.Symbol.SymbolName !== this.underlyingQuote.Symbol.SymbolName) {
          this.getVenueID(this.underlyingQuote.Symbol.SymbolName);
          return;
        }
      }
    }

    this.getVenueID();
  }

  getVenueID(symbolName?) {
    const symbol = symbolName? symbolName : this.selectedSymbol.SymbolName
    this.markitAPILoading = true;
    this.subscriptions.add(this.service.getVenueIDfromMarkitDigital(symbol).subscribe((data: any) => {
      if (data.hasOwnProperty('data')) {
        if (data.data.hasOwnProperty('items')) {
          const allItems = data.data.items;
          const selectedItem = allItems.filter(item => (item.symbol === symbol && item.exchange.countryIso === this.selectedSymbol.Market))
          if (Array.isArray(selectedItem)) {
            if (selectedItem.length > 0) {
              this.venueID = selectedItem[0].xids.venue
              if (this.orderType !== 'CLOSE' || this.manuallySearchAndClose) {
                this.getOptionChainExpiryDate();
              } else {
                this.getOptionChainGreeks(this.selectedExpiryDate)
              }
            }
          }
        }
      }
      this.markitAPILoading = false;
    }, (err: HttpErrorResponse) => {
      if(err){
        this.markitAPILoading = false;
        this.venueErrorMessage = this.contents.text.chainErrorMessage
        this.venueError = true;
        this.quoteError = true;
        this.errorHandlerService.sendException({
          message: JSON.stringify(err),
          stack: (window as any).markitdigitalURL + this.service.getGatewayConfig().APIServices.optionchainGetVenueID.url + (window as any).optionchainGetVenueQueryParam + `&term=` + symbol
        });
      }
    }))
  }

  getOptionChainExpiryDate() {
    this.markitAPILoading = true;
    this.subscriptions.add(this.service.getOptionChainExpiryDate(this.venueID).subscribe((data: any) => {
      if (data) {
        this.optionStrikeDateList = data.data.dates.map(date => date.expirationDate)
        if (this.inputs.optionStrikeDateIndex !== undefined && this.inputs.optionStrikeDateIndex !== -1) {
          this.getOptionChainGreeks(this.inputs.optionStrikeDateIndex)

        } else {
          if((window as any).isEnvPTE) { //simulated data for PTE
            this.inputs.optionStrikeDateIndex = '2026-12-18'
            this.getOptionChainGreeks('2026-12-18');
          } else {
            this.inputs.optionStrikeDateIndex = this.optionStrikeDateList[0]
            this.getOptionChainGreeks(this.optionStrikeDateList[0]);
          }
        }
      }
      this.markitAPILoading = false;
    }, (err: HttpErrorResponse) => {
      if(err){
        this.markitAPILoading = false;
        this.venueErrorMessage = this.contents.text.chainErrorMessage
        this.venueError = true;
        this.errorHandlerService.sendException({
          message: JSON.stringify(err),
          stack: (window as any).markitdigitalURL + this.service.getGatewayConfig().APIServices.optionchainExpirationDates.url + this.venueID + (window as any).optionchainExpirationDatesQueryParam
        });
      }
    }))

  }

  getOptionChainGreeks(expiryDate, strikeShown?, setAnchor?) {
    if (!isDefined(expiryDate) || !isDefined(this.venueID)) {
      this.markitAPILoading = false;
      this.dateChangeLoading = false;
      return
    }
    this.inputs.strikeShown = strikeShown ? strikeShown : 20
    if (!this.isDesktop() && this.scrollToTopOrBottom !== '') {
      if (this.inputs.strikeShown < 160) {
        this.inputs.strikeShown = this.inputs.strikeShown * 2
      } else {
        this.inputs.strikeShown = 'all'
      }
    }
    if(this.inputs.toggleChart == 'CHAIN') {
      this.dateChangeLoading = true;
    }
    this.subscriptions.add(this.service.getOptionChainGreeks(this.venueID, expiryDate, this.inputs.strikeShown).subscribe((data: any) => {
      if (data) {

        this.optionStrikePriceList = data.data.items[0].options.map(option => option.strikePrice)
        this.optionStrikePriceCallList = data.data.items[0].options
        this.optionStrikePricePutList = data.data.items[0].options


        const quote = this.optionQuote ? this.optionQuote : this.underlyingQuote;
        if (quote) {
          if (quote.Symbol.OptionInfo && quote.UnderlyingSymbolQuote) {
            this.quotePrice = quote.UnderlyingSymbolQuote.LastPrice.Data
          } else {
            this.quotePrice = quote.LastPrice.Data
          }

          this.filterMoneyOptions = this.optionStrikePriceList.map((price, index) => ({ price, index })).filter(item => this.quotePrice > item.price);
          this.startIndex = 0;
          this.endIndex = this.filterMoneyOptions.length;
          // if (((this.orderType === 'CLOSE' || OptionChainStore.crossFlow) && !this.manuallySearchAndClose)) {
          if (this.strikeValue) {
            if (this.optionStrikePriceCallList.find(object => object.strikePrice == this.strikeValue) === undefined && (this.inputs.strikeShown != 'all')) {
              if (this.inputs.strikeShown < 160) {
                strikeShown = this.inputs.strikeShown * 2
              } else {
                strikeShown = 'all'
              }
              return this.getOptionChainGreeks(expiryDate, strikeShown)
            }
            if (quote.Symbol.OptionInfo) {
              quote.Symbol.OptionInfo.OptionType === 'C' ? this.selectedIndex = this.optionStrikePriceCallList.findIndex(object => object.strikePrice == this.strikeValue) : quote.Symbol.OptionInfo.OptionType === 'P' ? this.selectedIndex = this.optionStrikePricePutList.findIndex(object => object.strikePrice == this.strikeValue) : this.selectedIndex;
              this.selectedCallIndex = this.optionStrikePriceCallList.find(object => object.strikePrice == this.strikeValue)?.call
              this.selectedPutIndex = this.optionStrikePricePutList.find(object => object.strikePrice == this.strikeValue)?.put
              let greek = { "CALLS": this.selectedCallIndex, "PUTS": this.selectedPutIndex };
              this.selectedGreekData.emit(greek);
            } else if (this.inputValues && this.strikeValue) {
              this.selectedIndex = this.optionStrikePriceCallList.findIndex(object => object.strikePrice == this.strikeValue)
            }
          }
        }
        if ((this.orderType !== 'CLOSE' || this.manuallySearchAndClose)) {
          if (this.inputs.toggleChart == 'CHAIN') {
            let self = this
            setTimeout(() => {
              if (this.scrollToTopOrBottom === 'top') {
                console.log('top');
                this.scrollChainById(this.valueOrDashTable(this.strikeTop));
                //this.document.getElementById().parentElement.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' })
              } else if (this.scrollToTopOrBottom === 'bottom') {
                console.log('bottom');
                this.scrollChainById(this.valueOrDashTable(this.strikeBottom));
                //this.document.getElementById().parentElement.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'start' })
              } else if (this.document.getElementById('option-chain-' + this.pagename).getElementsByClassName('strike-text').length > 1) {
                this.scrollChainByClassName('strike-text', 1);
                //this.document.getElementsByClassName().parentElement.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' })
              } else if (this.document.getElementById('option-chain-' + this.pagename).getElementsByClassName('strike-price-data-border').length > 0) {
                this.scrollChainByClassName('strike-price-data-border', 0);
                //this.document.getElementsByClassName()[0].parentElement.previousElementSibling.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' })
              }
              setTimeout(() => {
                this.scrollToTopOrBottom = '';
              }, 2)
              if(!setAnchor) {
                this.setAnchor();
              }
              if (this.optionStrikePriceList !== undefined) {
                this.strikeTop = this.optionStrikePriceList[0]
                this.strikeBottom = this.optionStrikePriceList[this.optionStrikePriceList.length - 1]
              }
            }, 800)
          }
        }
      }
      this.markitAPILoading = false;
      this.dateChangeLoading = false;
      OptionChainStore.crossFlow = undefined;

    }, (err: HttpErrorResponse) => {
      OptionChainStore.crossFlow = undefined;
      if(err){
        this.dateChangeLoading = false;
        this.markitAPILoading = false;
        this.venueErrorMessage = this.contents.text.chainErrorMessage
        this.venueError = true;

        this.errorHandlerService.sendException({
          message: JSON.stringify(err),
          stack:(window as any).markitdigitalURL + this.service.getGatewayConfig().APIServices.optionchainWithGreeks.url + this.venueID + (window as any).optionchainWithGreeksQueryParam + `&expirationDates=` + expiryDate + `&strikeCount=` + this.inputs.strikeShown
        });
        }
      }))
  }

  onExpiryStrikeShown(strikeShown) {
    this.getOptionChainGreeks(this.inputs.optionStrikeDateIndex, strikeShown);
  }

  getOptionShow(strikeOption) {
    this.selectedIndex = null;
    this.selectedColumn = null;
    if (strikeOption === 'ALL') {
      this.optionStrikePriceCallList
      this.optionStrikePricePutList
    } else if (strikeOption == 'INTHEMONEY') {
      this.strikePriceCallInTheMoney = this.optionStrikePriceCallList.slice(this.startIndex, this.endIndex)
      this.strikePricePutInTheMoney = this.optionStrikePricePutList.slice(this.endIndex, this.optionStrikePricePutList.length)
    }
    else if (strikeOption == 'OUTTHEMONEY') {
      this.strikePriceCallOutTheMoney = this.optionStrikePriceCallList.slice(this.endIndex, this.optionStrikePriceCallList.length)
      this.strikePricePutOutTheMoney = this.optionStrikePricePutList.slice(this.startIndex, this.endIndex)
    }
    else if (strikeOption == 'NEARTHEMONEY') {
      this.strikePriceCallNearTheMoney = this.optionStrikePriceCallList.slice(this.endIndex - 5, this.endIndex + 5)
    }
  }

  onExpiryStrikeDate(event) {
    this.dateChangeLoading = true;
    this.dateChange = this.formatDate(event.target.value, 'YYYY-MM-DD', 'YYYY-MM-DD')
    this.optionStrikePriceCallList;
    this.optionStrikePricePutList;
    this.getOptionChainGreeks(this.dateChange, null, true);
    this.inputs.optionStrikeDateIndex = this.dateChange
    this.inputs.optionStrikePriceIndex = -1;
    this.selectedIndex = null;
    this.selectedColumn = '';
    this.inputs.strikeShown = 20;

    // this.inputs.manualOptionStrikeDateIndex = this.inputs.optionStrikeDateIndex
  }

  updateManualStrikeDate(event) {
    this.dateChange = this.formatDate(event.target.value, 'YYYY-MM-DD', 'YYYY-MM-DD')
    this.optionStrikePriceCallList;
    this.optionStrikePricePutList;
    this.selectedExpiryDate = this.dateChange
    this.getOptionChainGreeks(this.dateChange);
    this.inputs.optionStrikeDateIndex = this.dateChange;
    this.service.chainData['optionStrikeDateIndex'] = this.inputs.optionStrikeDateIndex;
    this.inputs.optionStrikePriceIndex = -1
    this.inputs.optionType = 'BOTH'
    this.inputs.manualOptionType = ''
    this.selectedIndex = null;
    this.selectedColumn = ''
    console.log("---------------------------->", this.inputs);
  }

  updateManualStrikePrice(event) {
    this.formError.optionStrikePriceIndex = undefined;
    if (this.inputs.optionStrikePriceIndex === -1) {
      this.selectedManualStrikePrice = undefined;
      this.inputs.manualOptionType = '';
      console.log(this.inputs.optionStrikePriceIndex)
    } else if (
      this.inputs.optionStrikePriceIndex !== -1) {
      this.strikeValue = event.target.value;
      this.filterStrikePrice = this.optionStrikePriceList.map((strikePrice, index) => ({ strikePrice, index })).filter(object => object.strikePrice == this.strikeValue);
      setTimeout(() => {
        this.selectedCallIndex = this.optionStrikePriceCallList.find(object => object.strikePrice == this.strikeValue).call
        this.selectedPutIndex = this.optionStrikePricePutList.find(object => object.strikePrice == this.strikeValue).put
        this.selectedManualStrikePrice = parseFloat(this.strikeValue + '.00')
        this.service.chainData['optionStrikePriceIndex'] = this.inputs.optionStrikePriceIndex;
        this.selectedExpiryDate = this.inputs.optionStrikeDateIndex;
        if (this.inputs.manualOptionType == "CALLS") {
          this.selectedIndex = this.filterStrikePrice[0].index
          this.selectedColumn = 'callStrike'
          this.inputs.optionType = this.inputs.manualOptionType
        }
        else if (this.inputs.manualOptionType == "PUTS") {
          this.selectedIndex = this.filterStrikePrice[0].index
          this.selectedColumn = 'putStrike'
          this.inputs.optionType = this.inputs.manualOptionType
        }
      }, 1000);
      this.inputs.optionType = 'BOTH'
      this.inputs.manualOptionType = ''
    }
  }


  updateManualOptionType(event) {
    this.formError.manualOptionType = undefined;
    if (this.inputs.manualOptionType == '') {
      this.selectedManualOptionType = undefined
    } else if (
      this.inputs.manualOptionType != '') {
      this.selectedExpiryDate = this.inputs.optionStrikeDateIndex
      this.selectedManualOptionType = event.target.value;

      this.filterStrikePrice = this.optionStrikePriceList.map((strikePrice, index) => ({ strikePrice, index })).filter(item => this.inputs.optionStrikePriceIndex == item.strikePrice);
      this.inputs.optionType = this.inputs.manualOptionType
      if (this.inputs.manualOptionType == "CALLS") {
        this.selectedCallIndex = this.optionStrikePriceCallList.find(object => object.strikePrice == this.inputs.optionStrikePriceIndex).call
        this.selectedIndex = this.filterStrikePrice[0].index
        this.selectedColumn = 'callStrike'
      }
      else if (this.inputs.manualOptionType == "PUTS") {
        this.selectedPutIndex = this.optionStrikePricePutList.find(object => object.strikePrice == this.inputs.optionStrikePriceIndex).put
        this.selectedIndex = this.filterStrikePrice[0].index
        this.selectedColumn = 'putStrike'
      }
      this.service.chainData['manualOptionType'] = this.inputs.manualOptionType;
      let greek = { "CALLS": this.selectedCallIndex, "PUTS": this.selectedPutIndex };
      this.selectedGreekData.emit(greek);
      this.selectedChainData.emit({ "Inputs": this.inputs });
    }
    this.getFullQuote();
  }

  getFullQuote() {
    if (this.inputs.optionStrikeDateIndex !== -1 && this.inputs.manualOptionType !== '' && this.inputs.optionStrikePriceIndex !== -1) {
      this.getManualQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice: this.inputs.optionStrikePriceIndex, optionType: this.inputs.manualOptionType });
    }
  }

  formatDate(StrikeDate, formaten, formatfr) {
    const momentLang = moment(StrikeDate).locale(this.appStore.lang);
    if (this.appStore.lang === Lang.EN) {
      return momentLang.format(formaten);
    } else {
      return momentLang.format(formatfr);
    }
  }

  isAllManualInputsGiven() {
    if (this.selectedCallIndex === undefined && this.selectedPutIndex === undefined) {
      return false
    }
    if (this.orderType === 'CLOSE' && !this.manuallySearchAndClose) {
      return this.inputs.optionStrikeDateIndex && this.inputs.optionStrikePriceIndex && this.inputs.manualOptionType

    }
    if (this.orderType !== 'CLOSE' && this.inputs.optionStrikeDateIndex !== -1 && this.inputs.manualOptionType !== '' && this.inputs.optionStrikePriceIndex !== -1 ) {
      return true

    }
    if (this.inputs.optionStrikeDateIndex !== -1 && this.inputs.manualOptionType !== '' && this.inputs.optionStrikePriceIndex !== -1) {
      return isDefined(this.optionQuote)
    }
  }


  isDesktop() {
    if(!this.fromQuickTrade){
      return this.commonService.isDesktop();
    }else{
      return false;
    }
    
  }

  openWindowTab(url) {
    window.open(url);
  }

  defineTooltips() {

    this.glossaryTooltip.openBtnLabel = '';
    this.glossaryTooltip.cancel = this.globalcontent.text.closeTooltip;
    this.glossaryTooltip.content = '';
    this.glossaryTooltip.btnHiddenText = this.globalcontent.text.tooltipHiddenText;
  }

  resetOptionTooltip() {
    for (let i = 0; i < this.optionTooltipExpandedOptChain.length; i++) {
      this.optionTooltipExpandedOptChain[i] = false;
      let chev = document.getElementById("option-tooltip-drawer-chevron-optChain-" + i);
      let bar = document.getElementById("icon-bar-" + i);
      if (chev) {
        chev.className = "animatedChevron icon-Chevron-Up pointDown";
      }
      if (bar && bar.classList.contains("icon-bar")) {
        bar.classList.remove("icon-bar");
      }
    }
  }

  optionsTooltipExpandCollapseOptChain(i: number) {
    let chev = document.getElementById("option-tooltip-drawer-chevron-optChain-" + i);
    let bar = document.getElementById("icon-bar-" + i);
    if (!this.optionTooltipExpandedOptChain[i]) {
      this.optionTooltipExpandedOptChain[i] = true;
      if (chev && bar) {
        chev.className = "animatedChevron icon-Chevron-Up rotateUp";
        bar.classList.add("icon-bar");
      }
    } else {
      this.optionTooltipExpandedOptChain[i] = false;
      if (chev) {
        chev.className = "animatedChevron icon-Chevron-Up rotateDown";
        bar.classList.remove("icon-bar");
      }
    }
  }

  addCommas(text) {
    if (text.length >= 3 && text != this.dash) {
      if (this.appStore.lang.toLowerCase() == 'en') {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    } else {
      return text;
    }
  }

  toggleSize() {
    if (!(this.isExpanded)) {
      this.isExpanded = true;
      this.sendOmnitureInteractionData('option-trade:trading:trade-option:details:quotes-details-expanded');
    } else {
      this.isExpanded = false;
      this.sendOmnitureInteractionData('option-trade:trading:trade-option:details:quotes-details-collapsed');
    }

  }

  sendOmnitureInteractionData(value?: string, pageView?: boolean) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(value));
      if (pageView) {
        let config = this.getOmniConfig()
        of(this.trackingService.tagPageActionManually(config));
      }
    }
  }

  valueOrDash(value) {
    if (isDefined(value)) {
      return value
    }
    return this.dash;
  }
  valueOrDashTable(value) {
    if (isDefined(value)) {
      return value.toFixed(2);
    }
    return this.dash;
  }

  valueOrDashQuote(value) {
    if (isDefined(value)) {
      return value.toFixed(4);
    }
    return this.dash;
  }
  valueOrDashContent(value) {
    if (isDefined(value)) {
      return value.Content;
    }
    return this.dash;
  }
  valueOrDashData(value) {
    if (isDefined(value)) {
      return value.Data;
    }
    return this.dash;
  }
  openInterestValueFormat(value){
    if (isDefined(value) && value < 1000) {
      return value.toString();
    } else if(isDefined(value) && value > 1000){
      return (Math.round(value / 100) / 10).toFixed(1) + 'K';
    }
    return this.dash;
  }

  getFlag(flagName): string {
    if (flagName === undefined) {
      return null;
    } else {
      let response = '';

      switch (flagName.toLowerCase()) {
        case 'ca':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'cdn':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'usd':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
        case 'us':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
      }

      return response;
    }
  }

  nullFlag(flagName) {
    return !(flagName === undefined);
  }

  isWhite(mode) {
    return mode === 'compact-small-order' || mode === 'compact-large-order';
  }

  isDisplay(mode) {
    return mode === 'full-small' || mode === 'full-large' || mode === 'compact-small' || mode === 'compact-large' || mode === 'compact-small-order' || mode === 'compact-large-order';
  }

  isTopDisplay(mode) {
    return mode === 'full-small' || mode === 'full-large' || mode === 'compact-small' || mode === 'compact-large';
  }

  isDynamic(mode) {
    return mode == 'compact-small' || mode == 'compact-large' || mode == 'compact-small-order' || mode == 'compact-large-order'
  }

  isDisplayAll(mode) {
    return (mode === 'full-small' && this.isExpanded) || mode === 'full-large';
  }

  isNew(mode) {
    return mode == 'new-full' || mode == 'new-full-collapse' || mode == 'new-compact'
  }

  isNewDisplayBottom(mode) {
    return mode == 'new-full' || (mode == 'new-full-collapse' && this.isExpanded);
  }

  isNewExpandable(mode) {
    return mode == 'new-full-collapse';
  }



  changeToggleChart(toggleMode) {
    setTimeout(() => {
      // this.inputs.toggleChart = toggleMode;
      if (this.inputs.toggleChart === 'CHAIN') {
        if (this.document.getElementsByClassName('strike-text').length > 1) {
          this.scrollChainByClassName('strike-text', 1);
          //this.document.getElementsByClassName('strike-text')[1].parentElement.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' })
        } else if (this.document.getElementsByClassName('strike-price-data-border').length > 0) {
          this.scrollChainByClassName('strike-price-data-border', 0);
          //this.document.getElementsByClassName().parentElement.previousElementSibling.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' })
        }
        this.setAnchor();
      } else {
        this.setAnchor();
      }
    }, 1000);
  }

  formatRawData(amount) {
    if (isDefined(amount)) {
      if (this.appStore.lang.toUpperCase() === 'FR') {
        amount = amount.replace(',', '.').replace(/\s/g, '');
      } else {
        amount = amount.replace(/,/g, '');
      }
    }
    return amount;
  }

  forFrenchFormat(amount) {
    if (isDefined(amount)) {
      if (this.appStore.lang.toUpperCase() === 'FR') {
        amount = amount.replace('.', ',').replace(/\s/g, '');
      }
    }
    return amount;
  }


  bothSelection() {
    if (this.inputs.optionType == 'BOTH' && this.selectedColumn.includes('bothPut')) {
      this.inputs.manualOptionType = 'PUTS'
      this.selectedManualOptionType = this.inputs.manualOptionType
    } else if (this.inputs.optionType == 'BOTH' && this.selectedColumn.includes('bothCall')) {
      this.inputs.manualOptionType = 'CALLS'
      this.selectedManualOptionType = this.inputs.manualOptionType
    }
    else if (this.inputs.optionType == 'BOTH' && (this.selectedColumn == 'bothStrike')) {
      this.inputs.manualOptionType = ''
      this.selectedManualOptionType = this.inputs.manualOptionType
    }
  }

  limitPricePopulate() {
    if (isDefined(this.dateChange)) {
      this.date = this.dateChange
    } else {
      this.date = this.inputs.optionStrikeDateIndex
    }
    let greek = { "CALLS": this.selectedCallIndex, "PUTS": this.selectedPutIndex };
    if (this.selectedColumn.includes('Strike')) {
      this.bothSelection()
      this.selectedGreekData.emit(greek)
      this.selectedChainData.emit({ "Inputs": this.inputs });
      if ((this.inputs.optionType == 'CALLS' && this.inputs.strikeOption === 'ALL') || 
      (this.inputs.optionType == 'PUTS' && this.inputs.strikeOption === 'ALL') ) {
        this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice:  this.optionStrikePriceList[this.selectedIndex], optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
      }
    }
    else if (this.selectedColumn.includes('Bid')) {
      this.bothSelection()
      if (this.inputs.optionType === "CALLS") {
        this.selectedLimitPrice = this.selectedCallIndex.quote.bid.price.toFixed(2)
      } else if (this.inputs.optionType === "PUTS") {
        this.selectedLimitPrice = this.selectedPutIndex.quote.bid.price.toFixed(2)
      }
      else if (this.inputs.optionType == 'BOTH' && this.selectedColumn.includes('bothCall')) {
        this.selectedLimitPrice = this.selectedCallIndex.quote.bid.price.toFixed(2)
      }
      else if (this.inputs.optionType == 'BOTH' && this.selectedColumn.includes('bothPut')) {
        this.selectedLimitPrice = this.selectedPutIndex.quote.bid.price.toFixed(2)
      }  if ((this.inputs.optionType == 'CALLS' && this.inputs.strikeOption === 'ALL') || 
      (this.inputs.optionType == 'PUTS' && this.inputs.strikeOption === 'ALL') ) {
        this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice:  this.optionStrikePriceList[this.selectedIndex], optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
      }
      if (this.inputs.optionType == 'BOTH' && this.inputs.strikeOption !== 'NEARTHEMONEY')  {
        this.bothSelection();
        this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice:  this.optionStrikePriceList[this.selectedIndex], optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
      }
    }
    else if (this.selectedColumn.includes('Ask')) {
      this.bothSelection()
      if (this.inputs.optionType === "CALLS") {
        this.selectedLimitPrice = this.selectedCallIndex.quote.ask.price.toFixed(2)
      } else if (this.inputs.optionType === "PUTS") {
        this.selectedLimitPrice = this.selectedPutIndex.quote.ask.price.toFixed(2)
      } else if (this.inputs.optionType == 'BOTH' && this.selectedColumn.includes('bothCall')) {
        this.selectedLimitPrice = this.selectedCallIndex.quote.ask.price.toFixed(2)
      }
      else if (this.inputs.optionType == 'BOTH' && this.selectedColumn.includes('bothPut')) {
        this.selectedLimitPrice = this.selectedPutIndex.quote.ask.price.toFixed(2)
      }  if ((this.inputs.optionType == 'CALLS' && this.inputs.strikeOption === 'ALL') || 
      (this.inputs.optionType == 'PUTS' && this.inputs.strikeOption === 'ALL') ) {
        this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice:  this.optionStrikePriceList[this.selectedIndex], optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
      }
      if (this.inputs.optionType == 'BOTH' && this.inputs.strikeOption !== 'NEARTHEMONEY')   {
        this.bothSelection();
        this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice:  this.optionStrikePriceList[this.selectedIndex], optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
      }
    }
    else if (this.selectedColumn.includes('Last')) {
      this.bothSelection()
      if (this.inputs.optionType === "CALLS") {
        this.selectedLimitPrice = this.selectedCallIndex.quote.lastTrade.last.toFixed(2)
      } else if (this.inputs.optionType === "PUTS") {
        this.selectedLimitPrice = this.selectedPutIndex.quote.lastTrade.last.toFixed(2)
      }
      else if (this.inputs.optionType == 'BOTH' && this.selectedColumn.includes('bothCall')) {
        this.selectedLimitPrice = this.selectedCallIndex.quote.lastTrade.last.toFixed(2)
      }
      else if (this.inputs.optionType == 'BOTH' && this.selectedColumn.includes('bothPut')) {
        this.selectedLimitPrice = this.selectedPutIndex.quote.lastTrade.last.toFixed(2)
      }
      if ((this.inputs.optionType == 'CALLS' && this.inputs.strikeOption === 'ALL') || 
      (this.inputs.optionType == 'PUTS' && this.inputs.strikeOption === 'ALL') ) {
        this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice:  this.optionStrikePriceList[this.selectedIndex], optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
      }
      if (this.inputs.optionType == 'BOTH' && this.inputs.strikeOption !== 'NEARTHEMONEY')  {
        this.bothSelection();
        this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice:  this.optionStrikePriceList[this.selectedIndex], optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
      }
    }
    this.selectedGreekData.emit(greek)

    if (this.isDesktop()) {
      this.selectedChainData.emit({ "Inputs": this.inputs, "Price": this.selectedLimitPrice, "ExpiryDate": this.date });
    } else {
      this.selectedChainData.emit({ "Inputs": this.inputs, "Price": this.selectedLimitPrice });
    }
  }

  selectRow(index: number, fieldName: string) {

    if (!this.isDesktop() || this.pagename === 'OptionCentral') {
      this.formError.optionStrikePriceIndex = undefined;
      this.formError.manualOptionType = undefined;
      this.service.chainData = this.inputs
      if (this.selectedIndex === index && this.selectedColumn === fieldName) {
        this.selectedIndex = null;
        this.selectedColumn = null
      } else {
        this.selectedIndex = index;
        this.selectedColumn = fieldName;
        this.inputs.optionStrikePriceIndex = this.optionStrikePriceList[this.selectedIndex]
        this.selectedExpiryDate = this.inputs.optionStrikeDateIndex
        this.selectedCallIndex = this.optionStrikePriceCallList[this.selectedIndex].call;
        this.selectedPutIndex = this.optionStrikePricePutList[this.selectedIndex].put;
        this.selectedManualStrikePrice = this.inputs.optionStrikePriceIndex
        this.inputs.manualOptionType = this.inputs.optionType
        this.selectedManualOptionType = this.inputs.optionType
        setTimeout(() => {
          if (this.pagename !== 'OptionCentral') {
            this.inputs.toggleChart = 'MANUALENTRY';
            this.setAnchor()
          } else {
            this.scrollToOptionQuote();
          }
          this.bothSelection()
        }, 2000);
        if (this.inputs.strikeOption === 'NEARTHEMONEY') {
          this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePriceCallNearTheMoney[this.selectedIndex].strikePrice === object)
          this.selectedCallIndex = this.strikePriceCallNearTheMoney[this.selectedIndex].call
          this.selectedPutIndex = this.strikePriceCallNearTheMoney[this.selectedIndex].put
          this.selectedManualStrikePrice = parseFloat(this.inputs.optionStrikePriceIndex + '.00')
          this.bothSelection()
          this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice: this.inputs.optionStrikePriceIndex, optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
          // this.limitPricePopulate()
        }
        else if (this.inputs.strikeOption === 'OUTTHEMONEY' && this.inputs.optionType == "CALLS") {
          this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePriceCallOutTheMoney[this.selectedIndex].strikePrice === object)
          this.selectedManualStrikePrice = parseFloat(this.inputs.optionStrikePriceIndex + '.00')
          this.selectedCallIndex = this.optionStrikePriceCallList.find(object => object.strikePrice == this.selectedManualStrikePrice).call
          this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice: this.inputs.optionStrikePriceIndex, optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
        } else if (this.inputs.strikeOption === 'OUTTHEMONEY' && this.inputs.optionType == "PUTS") {
          this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePricePutOutTheMoney[this.selectedIndex].strikePrice === object)
          this.selectedManualStrikePrice = parseFloat(this.inputs.optionStrikePriceIndex + '.00')
          this.selectedPutIndex = this.optionStrikePricePutList.find(object => object.strikePrice == this.selectedManualStrikePrice).put
          this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice: this.inputs.optionStrikePriceIndex, optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
        }
        else if (this.inputs.strikeOption === 'INTHEMONEY' && this.inputs.optionType == "PUTS") {
          this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePricePutInTheMoney[this.selectedIndex].strikePrice === object)
          this.selectedManualStrikePrice = parseFloat(this.inputs.optionStrikePriceIndex + '.00')
          this.selectedPutIndex = this.optionStrikePricePutList.find(object => object.strikePrice == this.selectedManualStrikePrice).put
          this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice: this.inputs.optionStrikePriceIndex, optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
        }
        else if (this.inputs.strikeOption === 'INTHEMONEY' && this.inputs.optionType == "CALLS") {
          this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePriceCallInTheMoney[this.selectedIndex].strikePrice === object)
          this.selectedManualStrikePrice = parseFloat(this.inputs.optionStrikePriceIndex + '.00')
          this.selectedCallIndex = this.optionStrikePriceCallList.find(object => object.strikePrice == this.selectedManualStrikePrice).call
          this.getChainedQuote.emit({ strikeDate: this.inputs.optionStrikeDateIndex, strikePrice: this.inputs.optionStrikePriceIndex, optionType: this.inputs.optionType, manualOptionType: this.inputs.manualOptionType });
        }
        this.limitPricePopulate()
      }
    } else if (this.isDesktop()) {
      this.selectedIndex = index;
      this.selectedColumn = fieldName;
      if (this.inputs.strikeOption === 'ALL') {
        this.inputs.optionStrikePriceIndex = this.optionStrikePriceList[this.selectedIndex]
        this.selectedCallIndex = this.optionStrikePriceCallList[this.selectedIndex].call;
        this.selectedPutIndex = this.optionStrikePricePutList[this.selectedIndex].put;
      }
      else if (this.inputs.strikeOption === 'NEARTHEMONEY') {
        this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePriceCallNearTheMoney[this.selectedIndex].strikePrice === object)
        this.selectedCallIndex = this.strikePriceCallNearTheMoney[this.selectedIndex].call
        this.selectedPutIndex = this.strikePriceCallNearTheMoney[this.selectedIndex].put
      }
      else if (this.inputs.strikeOption === 'OUTTHEMONEY' && this.inputs.optionType == "CALLS") {
        this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePriceCallOutTheMoney[this.selectedIndex].strikePrice === object)
        this.selectedCallIndex = this.optionStrikePriceCallList.find(object => object.strikePrice == this.inputs.optionStrikePriceIndex).call
      } else if (this.inputs.strikeOption === 'OUTTHEMONEY' && this.inputs.optionType == "PUTS") {
        this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePricePutOutTheMoney[this.selectedIndex].strikePrice === object)
        this.selectedPutIndex = this.optionStrikePricePutList.find(object => object.strikePrice == this.inputs.optionStrikePriceIndex).put
      }
      else if (this.inputs.strikeOption === 'INTHEMONEY' && this.inputs.optionType == "PUTS") {
        this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePricePutInTheMoney[this.selectedIndex].strikePrice === object)
        this.selectedPutIndex = this.optionStrikePricePutList.find(object => object.strikePrice == this.inputs.optionStrikePriceIndex).put
      }
      else if (this.inputs.strikeOption === 'INTHEMONEY' && this.inputs.optionType == "CALLS") {
        this.inputs.optionStrikePriceIndex = this.optionStrikePriceList.filter(object => this.strikePriceCallInTheMoney[this.selectedIndex].strikePrice === object)
        this.selectedCallIndex = this.optionStrikePriceCallList.find(object => object.strikePrice == this.inputs.optionStrikePriceIndex).call
      }
      this.limitPricePopulate();
    }
  }

  changeActionMode() {
    // this.inputs.manualOptionType = this.inputs.optionType
    setTimeout(() => {
      if (this.document.getElementsByClassName('strike-text').length > 1) {
        this.scrollChainByClassName('strike-text', 1);
        //this.document.getElementsByClassName('strike-text')[1].parentElement.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' })
      } else if (this.document.getElementsByClassName('strike-price-data-border').length > 0) {
        this.scrollChainByClassName('strike-price-data-border', 0);
        //this.document.getElementsByClassName('strike-price-data-border')[0].parentElement.previousElementSibling.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' })
      }
      //this.setAnchor();
    }, 100)
  }

  setChainArray() {
    this.tradeChainManualEntryArray = [
      {
        label: this.contents.text.chain,
        color: '#383B3E',
        backgroundColor: '',
        value: 'CHAIN'
      },
      {
        label: this.contents.text.manualEntry,
        color: '#383B3E',
        backgroundColor: '',
        value: 'MANUALENTRY'
      }
    ]
  }

  setTradePositionArray() {
    this.tradeOptionsPositionArray = [
      {
        label: this.contents.text.calls,
        color: '#383B3E',
        backgroundColor: '',
        value: 'CALLS'
      },
      {
        label: this.contents.text.both,
        color: '#383B3E',
        backgroundColor: '',
        value: 'BOTH'
      },
      {
        label: this.contents.text.puts,
        color: '#383B3E',
        backgroundColor: '',
        value: 'PUTS'
      }
    ]
  }

  formSubmitted() {
    if (!this.isDesktop()) {
      console.log("formSubmitted()", this.inputs);
      if (this.orderType !== 'CLOSE' || this.manuallySearchAndClose) {
        OptionChainValidator.setValidators(this.optionChainForm);
      }
      OptionChainValidator.validate(this.optionChainForm);
      OptionChainStore.inFlow = {
        "selectedIndex": this.selectedIndex,
        "selectedColumn": this.selectedColumn,
        "selectedCallIndex": this.selectedCallIndex,
        "selectedPutIndex": this.selectedPutIndex
      }
      if (this.optionChainForm.valid) {
        console.log("Form Valid");
        this.service.validChainForm = true;
      } else {
        this.service.validChainForm = false;
        this.formError = OptionChainValidator.resolveValidationError(this.optionChainForm, this.contents.error);
        console.log('Form Error --- ' + JSON.stringify(this.formError));
        OptionChainValidator.emptyValidators(this.optionChainForm);
        console.log("Form IN Valid");
  
        console.log('Form Error --- ' + JSON.stringify(this.formError));
      }
    } else {
      OptionChainStore.inFlow = {
        "selectedIndex": this.selectedIndex,
        "selectedColumn": this.selectedColumn,
        "selectedCallIndex": this.selectedCallIndex,
        "selectedPutIndex": this.selectedPutIndex
      }
    }

  }

  onTableScroll(event: any) {
    if (!this.isDesktop() && this.inputs.strikeShown != 'all' && this.inputs.strikeOption === 'ALL' && this.optionStrikePriceList.length > 39) {
      if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) && (this.strikeBottom != this.strikeBottomPrevious)) {
        this.scrollToTopOrBottom = 'bottom';
        this.getOptionChainGreeks(this.inputs.optionStrikeDateIndex, this.inputs.strikeShown, true);
        this.strikeBottomPrevious = this.strikeBottom;
      } else if (event.target.scrollTop == 0) {
        if (this.scrollToTopOrBottom === '') {
          this.scrollToTopOrBottom = 'top';
          this.getOptionChainGreeks(this.inputs.optionStrikeDateIndex, this.inputs.strikeShown, true)
        }
      }
    }
  }

  tooltipClicked(title) {
    this.glossaryTooltip.title = title;
    if (title == 'Options trading guide' || title == 'Guide sur les opérations sur options') {
      this.sendOmnitureInteractionData('trade-options:trading:trade-options:details:more-info-icon-tool-tip');
    } else {
      this.sendOmnitureInteractionData('trade-options:trading:trade-options:details:more-info-icon-tool-tip');
    }
  }

  setAnchor() {
    let el:any = this.document.getElementById('anchoring-top');
    const scroller = document.getElementById('quick-options-form');
    //scroller.scroll(0, child.offsetTop - 55);
    if(el && scroller && this.parentMode == 'quickTrade') {
      //el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
      //scroller.scroll(0, el.parentNode.offsetTop - 5);
      //el.parentNode.scrollTop = el.offsetTop - el.parentNode.offsetTop;
      scroller.scrollTo({
        top: el.parentNode.offsetTop - 5,
        behavior: "smooth",
      });
    } else if(el){
      //window.scroll(0, el.parentNode.offsetTop + 125);

      var rect = el.parentNode.getBoundingClientRect();
      if(rect.top > 0) {
        window.scrollTo({
          top: el.parentNode.offsetTop + 150,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: el.parentNode.offsetTop + 100,
          behavior: "smooth",
        });
      }
      var rect = el.parentNode.getBoundingClientRect();
    }
  }

  scrollChainById(id) {
    let el:any = document.getElementById(id);
    let scroller = document.getElementById('chain-table-' + this.pagename);
    if(el && scroller) {
      //scroller.scroll(0, el.parentNode.offsetTop - 220);
      scroller.scrollTo({
        top: el.parentNode.offsetTop - 220,
        behavior: "smooth",
      });
    }
  }

  scrollChainByClassName(id, index) {
    let ele:any = document.getElementById('option-chain-' + this.pagename).getElementsByClassName(id);
    if(ele && ele.length && ele.length > 0) {
      let el:any = document.getElementById('option-chain-' + this.pagename).getElementsByClassName(id)[index];
      let scroller = document.getElementById('chain-table-' + this.pagename);
      if(el && scroller) {
        //scroller.scroll(0, el.parentNode.offsetTop - 220);
        scroller.scrollTo({
          top: el.parentNode.offsetTop - 220,
          behavior: "smooth",
        });
      }
    }
  }

  optionGlossaryClick() {
    this.optionGlossaryClicked.emit();
  }

  scrollToOptionQuote() {
    let el:any = this.document.getElementById('optionQuoteInfo-'+ this.pagename);
    let scroller;
    if (this.pagename === 'OptionCentral') {
      scroller = document.getElementById('optionChain-modal-content');
    }
    if(el && scroller) {
      scroller.scrollTo({
        top: el.parentNode.offsetTop - 5,
        behavior: "smooth",
      });
    }
  }

  refreshAll() {
    this.refresh.emit();
  }

  omnitureDetail() {
    if (this.router.url == '/txn/optionsCentral') {
        this.sendOmnitureInteractionData('option-central:option-chain', true);
      } else if (this.router.url == '/txn/optionsCentral/singleLeg/landing') {
        this.sendOmnitureInteractionData('option-central:single-leg:option-chain', true);
      } else if (this.router.url == '/txn/optionsCentral/MultiLeg/landing') {
        this.sendOmnitureInteractionData('option-central:multi-leg:option-chain', true);
      }
  }

  getOmniConfig() {
    let config: OmniturePageDetail;
    config = {
      name: "OptionChainLanding",
      url: this.router.url,
      data: {
        name: "Option Chain",
        events: { pageView: true},
      },
    };
    return config;
  }
}
