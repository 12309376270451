import { RouteGroup } from '../shared/models/route-config';

export const sso_config: RouteGroup = {

  '/SSOpage': {
    name: 'SSOSetup',
    service: 'SSOSetupService',
    store: 'SSOSetupStore',
    resolveContent: 'singlesignon',
    policy: {
      navFrom: {
        url: ['/olbsso/signon', '/signOn/forgotSignOnPassword', '/signOn/changeSignOnPass/confirm'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      // {
      //     id: 'init',
      //     restUrl: '/userPreferences/init',
      //     get request(): any {
      //         return {
      //             // PageContentReq: {
      //             //     PageName: "UserPreferences"
      //             // }
      //         };
      //     }
      // }
    ],
    trackingData: {
      name: 'sso-signon',
      nameGA: 'SSO Signon',
      isSubmitDeferred: true
    }
  },
  '/olbsso/signon': {
    name: 'SSORoutingHandler',
    service: 'SSORoutingHandlerService',
    store: 'SSORoutingHandlerStore',
    resolveContent: '',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
    ],
    trackingData: {
      name: 'olb-sso-routing-handler',
      isSubmitDeferred: true
    }
  },
  '/ssoazure/signon': {
    name: 'SSOAzureRoutingHandler',
    service: 'SSOAzureRoutingHandlerService',
    store: 'SSOAzureRoutingHandlerStore',
    resolveContent: '',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
    ]
  },
  "/sessionEnd": {
    name: "sessionend",
    service: "",
    store: "SessionEndStore",
    resolveContent: "sessionend",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      name: "session-end",
      nameGA: "Session End",
    },
  },
  "/olbError": {
    name: "olberror",
    service: "",
    store: "OLBErrorStore",
    resolveContent: "olberror",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      name: "password-error",
      nameGA: "Password Error"
    },
  },
  "/newUser": {
    name: "newuser",
    service: "",
    store: "NewUserStore",
    resolveContent: "newuser",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      name: "investing",
      hierarchy: "mobile-sso"
    },
  },
};
