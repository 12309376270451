import { Component, OnChanges, OnInit, SimpleChanges, Input, OnDestroy, Renderer2, SecurityContext, HostListener } from '@angular/core';
import moment from 'moment';
import { CommonChatService } from '../../services/common-chat.service';
import { Subscription } from 'rxjs';
import { ObservableSubscriptionService } from '../../../../services/observable-subscription.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { ContentService } from 'src/app/core/services/content.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppErrorHandler } from 'src/app/core/components/error/app-error-handler';
import { SessionExtendTimerService } from 'src/app/core/services/session-extend-timer.service';
import { isDefined } from 'src/app/shared/services/utils.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Component({
  selector: 'app-genesys-postsignon-chat',
  templateUrl: './genesys-postsignon-chat.component.html',
  styleUrls: ['./genesys-postsignon-chat.component.scss']

})
export class GenesysPostSignonChatComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentPage: string;
  @Input() selectedLob: string;
  isChatUserMetadataInitialized: boolean;
  chatMetaDataUrl;
  private subscriptions: Subscription[] = [];
  listener: any;
  baseUrl: string = 'null'; 
  chatContent;
  chatVisible;
  iframeHeight;
  iframeWidth;
  chatButtonType;
  chatIconClosed: boolean = true;
  initWidth = 0;
  initHeight = 0;
  maxWidth = 0;
  maxHeight = 0;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if(!this.isDesktop()) {
      this.moveChatIconRight();
    } else {
      if (this.service.isChatCallEligible()) {
          if (!this.isChatUserMetadataInitialized) {
            this.isChatUserMetadataInitialized = true;
            this.postSignOnChatSetup(); // chat metadata load is required
          } else {
            this.subscriptionService.setChatIconStatus(this.persistenceService.sessionStoreRetrieve('chatOpen') == "true" ? true : false);
            this.subscriptionService.chatVisibilityShow();
          }
      } else {
          this.removeChatButtons();
      }
    }
  }
  
  constructor(
    private service: CommonChatService,
    private subscriptionService: ObservableSubscriptionService,
    private renderer: Renderer2,
    private persistenceService: PersistenceService,
    private appStore: AppStore,
    private contentService: ContentService,
    private domSanitizer: DomSanitizer,
    private commonService: CommonService,
    private router: Router,
    private route : ActivatedRoute,
    private errorHandler: AppErrorHandler,
    private sessionExtendTimerService: SessionExtendTimerService,
  ) {
    this.isChatUserMetadataInitialized = false;
  }

  ngOnInit() {
    this.subscriptions.push(this.subscriptionService.modalTrapFocusEvent$.subscribe(
      (id) => {
        if (id === 'page-container') {
          this.service.hideAccessibilityForModal(true);
        }
      }));

    this.subscriptions.push(this.subscriptionService.modalUnTrapFocusEvent$.subscribe(
      (id) => {
        if (id === 'page-container') {
          this.service.hideAccessibilityForModal(false);
        }
      }));
    this.subscriptions.push(this.contentService.fetchContent('chat').subscribe((chatContent) => {
      if (chatContent) {
        this.chatContent = chatContent;
      }
    }));
    this.subscriptions.push(this.subscriptionService.loadStaticChat$.subscribe((load) => {
      if(load) {
        this.sendEventListener('triggerLiveChatWindow');
      }
    }));
    this.subscriptionService.chatVisibilityHidden();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.service.isChatCallEligible()) {
      if (changes.currentPage || changes.selectedLob) { // Listen for changes in current page URL or LOB change and invoke the chat call
        this.setChatButtonType(this.router.url);
        if (!this.isChatUserMetadataInitialized) {
          this.isChatUserMetadataInitialized = true;
          this.postSignOnChatSetup(); // chat metadata load is required
        }
      }
    } else {
      //if ((window as any).isiChatConfig) {
        this.removeChatButtons(); // Remove any chat buttons which was previously loaded in browser memory
      //}
    }
  // const openTradeDrawer = this.persistenceService.sessionStoreRetrieve("openOrCloseDrawer");
    // if (openTradeDrawer == 'true') {
    //   setTimeout(() => {
    //     //this.moveChatIconLeft();
    //   }, 1000)
    // }
    // else {
    //   setTimeout(() => {
    //     this.moveChatIconRight();
    //   }, 1000)
    // }
    // const openOptionsTradeDrawer = this.persistenceService.sessionStoreRetrieve("quickOptionsOpenOrCloseDrawer");
    // if (openOptionsTradeDrawer == 'true') {
    //   setTimeout(() => {
    //     //this.moveChatIconLeft();
    //   }, 1000)
    // }
    // else {
    //   setTimeout(() => {
    //     this.moveChatIconRight();
    //   }, 1000)
    // }
  }

  moveChatIconLeft() {
    // let chatIconDivContainer = document.getElementsByClassName("genesys-container")[0];
    // if (chatIconDivContainer) {
    //   this.renderer.addClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.addClass(kampyleButton, 'move-kampyle');
    // }
  }

  moveChatIconRight() {
    // let chatIconDivContainer = document.getElementsByClassName("genesys-container")[0];
    // if (chatIconDivContainer) {
    //   this.renderer.removeClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.removeClass(kampyleButton, 'move-kampyle');
    // }
  }

  postSignOnChatSetup() {
    let req = {
      "SourceUrl": window.location.host
    }
    this.service.genesysPostSignonChatSetup(req).subscribe((chatMetadata: any) => {
      if (chatMetadata) {
        if (null == chatMetadata.LandingUrl || chatMetadata.EncryptedChatToken === '') {
          console.error('Error ..Chat Token is empty in the server call. Removing chat buttons if any');
          this.removeChatButtons();
        } else {
          this.isChatUserMetadataInitialized = true;
          this.loadPostSignOnChat(chatMetadata);

        }
      }else {
        console.error('Error ..Chat Token is empty in the server call. Removing chat buttons if any');
        this.removeChatButtons();
      }
    },
      err => {
        console.error('error in chat. Removing chat buttons if any ' + err.message);
        this.removeChatButtons(); // Remove any chat buttons which was previously loaded in browser memory
      });
  }

  loadPostSignOnChat(chatMetadata?) {
    if(chatMetadata) {
      this.chatButtonType = 'floating';
      this.chatMetaDataUrl = chatMetadata.LandingUrl + "?locale=" + this.appStore.lang + "&brand=cibc" + "&lob=cibcisi" + "&ott=" + chatMetadata.EncryptedChatToken + "&chatButtonType=" + this.chatButtonType;
    }
    //this.chatVisible = true;
    //this.subscriptionService.chatVisibilityShow();
    setTimeout(() => {
      const frame = document.getElementById("iframe-postchat-genesys");
      if (frame) {
        frame.setAttribute('src', this.domSanitizer.sanitize(SecurityContext.URL, this.chatMetaDataUrl));
      }
    }, 200);
  }

  removeChatButtons() {
    window.removeEventListener('message',
      this.listener, false
    );
    setTimeout(() => {
      const frame = document.getElementById("iframe-postchat-genesys");
      if (frame) {
        frame.setAttribute('src', '');
      }
      this.chatVisible = false;
      this.isChatUserMetadataInitialized = false;
      this.chatMetaDataUrl = undefined;
      this.subscriptionService.chatVisibilityHidden();
      this.subscriptionService.setChatIconStatus(false);
    }, 50);
  }

  chatIframeLoaded(event) {
    this.setupMessageListener();
    event.preventDefault();
  }

  setupMessageListener() {
    const parent = this;
    this.listener = function (e) {
      parent.messageListener(e);
    };
    window.addEventListener('message',
      this.listener
    );
  }

  messageListener(event: MessageEvent) {
    if (event && event.isTrusted) {
      if (event.origin && event.origin.indexOf("cibc.com") > -1) {
        this.baseUrl = event.origin;
        this.receiveChatsMessage(event);
      } else {
        return;
      }
    } else {
      return;
    }
  }

  receiveChatsMessage(event: MessageEvent) {
    if(!event || !event.data)
      return;
    try{
      JSON.parse(event.data)
    }
    catch(e){
      return;
    }
    const action = JSON.parse(event.data);
    if (action.from == 'enterprise-live-chat-ui') {
      switch (action.name) {
        case 'clientIsActive':
          this.extendSession();
          break;
        case 'notifyLiveChatContentViewUpdated':
          this.chatVisible = true;
          this.subscriptionService.chatVisibilityShow();
          this.updateSize(action.contentWindowSize);
          break;
        case 'notifyLiveChatWindowLoaded':
          this.chatVisible = true;
          this.subscriptionService.chatVisibilityShow();
          this.updateSize(action.contentWindowSize);
          break;
        case 'notifyLiveChatUIClosed':
          this.subscriptionService.setChatIconStatus(false);
          this.removeChatButtons();
          this.postSignOnChatSetup();
          break;
      }
    }
  }

  sendChatsMessage(eventName: string) {
    try {
      let myframe: any;
          myframe = document.getElementById('iframe-postchat-genesys');
          if (isDefined(myframe) && myframe != null) {
            const message = {
              from: 'cibcisi',
              name: eventName
            }
            myframe.contentWindow.postMessage(JSON.stringify(message), this.baseUrl);
          }
    }
    catch (error) {
      console.error('Error sending event message')
    }
  }

  sendEventListener(type) {
    this.sendChatsMessage(type);
  }

  updateSize(params) {
    this.iframeWidth = params.width;
    this.iframeHeight = params.height;
    if(this.initWidth == 0 && (params.width.split('px')[0] > this.initWidth)) {
      this.initWidth = params.width.split('px')[0];
    }
    if(this.initHeight == 0 && (params.height.split('px')[0] > this.initHeight)) {
      this.initHeight = params.height.split('px')[0];
    }
    if(params.width.split('px')[0] > this.maxWidth) {
      this.maxWidth = params.width.split('px')[0];
    }
    if(params.height.split('px')[0] > this.maxHeight) {
      this.maxHeight = params.height.split('px')[0];
    }

    if((this.iframeWidth == this.initWidth + 'px') && (this.iframeHeight == this.initHeight + 'px')) {
      this.chatIconClosed = true;
      this.subscriptionService.setChatIconStatus(false);
      setTimeout(() => {
        const iframe = document.getElementsByClassName('genesys-container')[0] as HTMLElement;
        if(iframe.style && iframe.style.top && iframe.style.left) {
          iframe.style.top = null;
          iframe.style.left = null;
        }
      }, 200);
      
    }else {
      this.chatIconClosed = false;
      this.subscriptionService.setChatIconStatus(true);
    }
  }

  extendSession() {
    this.sessionExtendTimerService.resetTimer();
  }

  isChatEligible() {
    return this.service.isChatCallEligible() && this.chatMetaDataUrl && this.chatVisible;
  }

  ngOnDestroy() {
    this.removeChatButtons();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  setChatButtonType(event: string) {
    if(!this.isDesktop()){
      switch(event){
        case '/txn/contactus':
          console.log('static button');
          break;
        default:
          console.log('floating button');
          break; 
      }
    }
    else {
      switch(event){
        case '/txn/contactus':
          console.log('static button');
          break;
        default:
          console.log('floating button');
          break; 
      }
    }
  }

}
