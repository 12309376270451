import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class GlobalHeaderService {

  constructor(private httpService: HttpService) { }

  getAdvisorData() {
    return this.httpService.post(gatewayConfig.APIServices.getAdvisor.url, {});
  }

  getmasterNotificationURL() {
    return this.httpService.post(gatewayConfig.APIServices.eDocMasterNotification.url,
      {},
      { params: { skiploading: 'true' } }
    );
  }
}
