import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AccountCardComponent } from 'src/app/shared/components/account-card/account-card.component';
import { AccountSelectorDropdownComponent } from 'src/app/shared/components/account-selector-dropdown/account-selector-dropdown.component';
import { AccountSelectorModalComponent } from 'src/app/shared/components/account-selector-modal/account-selector-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormSharedModule } from 'src/app/shared/form-shared.module';
import { PipeSharedModule } from 'src/app/shared/pipe-shared.module';
import { WidgetSharedModule } from 'src/app/shared/widget-shared.module';
import { AccountSelectorDropdownTradeDrawerComponent } from 'src/app/shared/components/account-selector-dropdown-trade-drawer/account-selector-dropdown-trade-drawer.component';
import { TransferCardComponent } from './components/transfer-card/transfer-card.component';
@NgModule({
  declarations: [
    AccountCardComponent,
    AccountSelectorModalComponent,
    AccountSelectorDropdownComponent,
    AccountSelectorDropdownTradeDrawerComponent,
    TransferCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FormSharedModule,
    PipeSharedModule,
    WidgetSharedModule
  ],
  exports: [
    AccountCardComponent,
    AccountSelectorModalComponent,
    AccountSelectorDropdownComponent,
    AccountSelectorDropdownTradeDrawerComponent,
    TransferCardComponent
  ]
})
export class AccountSharedModule { }
