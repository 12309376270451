import { BaseData } from '../../../../../../shared/models/base-data';

export class CreateCashPlanLandingData extends BaseData {
}
export interface cplPageData {
    AccountList: cplAccountList[],
    AsOfDate: string
}
export interface cplAccountList {
    AccountBalances?: accountBalances[],
    AccountNumber: number,
    AccountOwnerName: string,
    AccountTypeDesc: string,
    BuyingPowerValueType: string,
    Fullname: string,
    IncludeShort: boolean
}
export interface accountBalances {
    AccountType: string,
    Amount: number,
    Currency: string
}

export interface amountToolTip {
    openBtnLabel: string;
    cancel: string;
    content: string;
    btnHiddenText: string;
}

export const globalCalendar = {
    minDateYearInput: 0,
    minDateMonthInput: 0,
    maxDateYearInput: 2,
    maxDateMonthInput: 0,
    minSetYear: 0,
    minSetMonth: 0,
    minSetDay: 1,
    maxSetYear: 2,
    maxSetMonth: 11,
    maxSetDay: 31
}

export const cpl_calendar_const = {
    customDate: "customDate",
    endCustomDate: "endCustomDate",
    suspendCustomDate: "suspendCustomDate",
    resumeCustomDate: "resumeCustomDate"
}

export const cpl_form_control_const = {
    errorValue: "-1",
    nonErrorValue: "0",
    valueOne: 1,
    dollar: "$"
}
export interface settlementAccountsResp {
    SettlementAccounts?: settlementAccounts[];
    RegisteredContributionTypeList?: registeredContributionTypeList[];
}

export interface settlementAccounts {
    AccountNumber: number
    AccountType?: string
    Currency?: string
    SettlementType?: string
}

export interface registeredContributionTypeList {
    RegisteredContributionTypeList: {}
}

export interface cplAccountRequest {
    AccountNumber: number;
}

export interface cplValidateReq {
    AccountNumber?: number,
    Amount?: number,
    Frequency?: number,
    StartDate?: string,
    PayFrom?: number,
    EndDate?: string,
    SuspendDate?: string,
    UnSuspendDate?: string,
    RegisteredContributionType?: registeredContributionTypeList
}

export interface cplSubmitReq {
    AccountNumber: number,
    Amount: number,
    Frequency: number,
    StartDate: number,
    PayFrom: number,
    EndDate?: number,
    SuspendDate?: number,
    ResumeDate?: number
}