import { RouteGroup, RouteConfig } from '../shared/models/route-config';
import { InitRequest } from '../modules/pre-txn/home/model/init-req';


export const PRE_TXN_URL = {
  // Combination of lang and lob is required only for home page as this will determine in picking lob/locale
  HOME: '/home',
  // Signon
  SIGNON: '/signon',
  FORGOTSIGNONPASS: '/signOn/forgotSignOnPassword',
  CHANGESIGNONPASS: '/signOn/changeSignOnPassword',
  CHANGESIGNONCONFIRMPASS: '/signOn/changeSignOnPass/confirm',

  CREATESIGNONTRADPASS: '/signon/createSignonAndTradingPass',
  CREATESIGNONTRADCONFIRMPASS: '/signon/createSignonAndTradingPass/confirm',

  CREATESIGNONPASS: '/signon/createSignonPass',
  CREATESIGNONCONFIRMPASS: '/signon/createSignonPass/confirm',

  CREATETRADPASS: '/signon/createTradingPass',
  CREATETRADCONFIRMPASS: '/signon/createTradingPass/confirm',
  AEM: '/aem',
  ELECTRONICACCESSAGREEMENT: '/electronicAccessAgreement',
  REGISTERONLINELANDING: '/registerOnline/landing',
  REGISTERONLINECONFIRM: '/registerOnline/confirm',
  CREATEPASSWORD: '/registerOnline/createPassword',

  WGONLINEREGISTERVERIFICATION: '/onlineRegistration/verification',
  WGCREATEPASSWORD: '/onlineRegistration/password',
  WGCREATEUSERNAME: '/onlineRegistration/username',
  WGCREATEMAILINGOPTIONS: '/onlineRegistration/mailingOptions',
  WGCONFIRMATION: '/onlineRegistration/confirmation',

  ACCOUNTSAGREEMENTSDISCLOSURES: '/accountsAgreementsDisclosures',
  FEESCOMMISSIONS: '/feesCommissions'
};

export const PRE_TXN_HIERARCHY = 'pre-txn';

export const PreTxnConfig: RouteGroup = {
  [PRE_TXN_URL.HOME]: {
    name: 'Home',
    service: 'HomeService', // use-proxy
    store: 'use-generic', // User use-generic for default
    resolveContent: 'home',
    restService: [
      {
        id: 'init',
        restUrl: '/presignon/init?silent=1',
        get request(): any {
          return new InitRequest();
        }
      }
    ],
    trackingData: {
      name: "home",
      nameGA: "Home",
      isSubmitDeferred: true
    }
  },
  [PRE_TXN_URL.AEM]: {
    name: 'AEM',
    // service: 'HomeService', // use-proxy
    store: 'use-generic', // User use-generic for default
    resolveContent: 'aem',
    restService: [],
    trackingData: {
      name: "sign-on",
      nameGA: "Sign On"
    }
  },
  [PRE_TXN_URL.SIGNON]: {
    name: 'Signon',
    service: 'SignonService',
    store: 'SignonStore',
    resolveContent: 'signon',
    restService: [
      {
        id: 'init',
        restUrl: 'signonInit',
        get request(): any {
          return {
            SavedUserIds: '',
            Profile: {
              Platform: 2,
              AccessType: 0
            }
          };
        }
      }
    ],
    trackingData: {
      //hierarchy: PRE_TXN_HIERARCHY, // you can give , as well
      name: "sign-on",
      nameGA: "Sign On"
    }
  },
  [PRE_TXN_URL.FORGOTSIGNONPASS]: {
    name: 'ForgetSignonPassword',
    service: 'ForgotSignonPassService',
    store: 'ForgotSignonPassStore',
    resolveContent: 'forgotsignonpassword',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'forgotPasswordInit',
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "reset-password",
      name: "verification",
      nameGA: "Forgot Password Verification",
      formInfo: {
        name: "obr-reset-password",
        stepName: "verification",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  [PRE_TXN_URL.CHANGESIGNONPASS]: {
    name: 'ChangeSignonPass',
    // service: 'ChangeSignonPassService',
    store: 'ChangeSignonPassStore',
    resolveContent: 'changesignonpassword',
    policy: {
      navFrom: {
        url: ['/signOn/forgotSignOnPassword'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/signOn/forgotSignOnPassword'
    },
    restService: [],
    trackingData: {
      hierarchy: "reset-password",
      name: "new-password",
      nameGA: "Change Forgot Password",
      formInfo: {
        name: "obr-reset-password",
        stepName: "new-password",
      },
      events: { formStep: true, pageView: true },
    }

  },

  [PRE_TXN_URL.CHANGESIGNONCONFIRMPASS]: {
    name: 'ChangeSignonPassConfirm',
    service: 'ChangeSignonPassConfirmService',
    store: 'ChangeSignonPassConfirmStore',
    resolveContent: 'changeSignonPassConfirm',
    policy: {
      navFrom: {
        url: ['/signOn/changeSignOnPassword'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/signOn/forgotSignOnPassword'
    },
    restService: [
      {
        id: 'changeSignonPassword',
        restUrl: 'forgotPasswordSave',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "reset-password",
      name: "confirmation",
      nameGA: "Change Forgot Password Confirmation",
      formInfo: {
        name: "obr-reset-password",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    }
  },

  [PRE_TXN_URL.CREATESIGNONTRADPASS]: {
    name: 'CreateSignonTradingPass',
    service: 'CreateSignonTradingPassService',
    store: 'CreateSignonTradingPassStore',
    resolveContent: 'createsignontradingpass',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "online-account-setup,create-signon-trading-password",
      name: "details",
      nameGA: "Create Signon Trading Password",
      formInfo: {
        name: "obr-create-signon-trading-password",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },

  [PRE_TXN_URL.CREATESIGNONTRADCONFIRMPASS]: {
    name: 'CreateSignonTradingPassConfirm',
    service: 'CreateSignonTradingPassConfirmService',
    store: 'CreateSignonTradingPassConfirmStore',
    resolveContent: 'createsignontradingpassconfirm',
    policy: {
      navFrom: {
        url: ['/signon/createSignonAndTradingPass'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/signon'
    },
    restService: [],
    trackingData: {
      hierarchy: "online-account-setup,create-signon-trading-password",
      name: "confirmation",
      nameGA: "Create Signon Trading Password confirmation",
      formInfo: {
        name: "obr-create-signon-trading-password",
        stepName: "confirmation",
	
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    }
  },
  [PRE_TXN_URL.CREATESIGNONPASS]: {
    name: 'CreateSignonPass',
    service: 'CreateSignonPassService',
    store: 'CreateSignonPassStore',
    resolveContent: 'createsignonpass',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "online-account-setup,create-signon-password",
      name: "details",
      nameGA: "Create Trading Password",
      formInfo: {
        name: "obr-create-signon-password",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },

  [PRE_TXN_URL.CREATESIGNONCONFIRMPASS]: {
    name: 'CreateSignonPassConfirm',
    service: 'CreateSignonPassConfirmService',
    store: 'CreateSignonPassConfirmStore',
    resolveContent: 'createsignonpassconfirm',
    policy: {
      navFrom: {
        url: ['/signon/createSignonPass'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/signon'
    },
    restService: [],
    trackingData: {
      hierarchy: "online-account-setup,create-signon-password",
      name: "confirmation",
      nameGA: "Create Trading Password Confirmation",
      formInfo: {
        name: "obr-create-signon-password",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    }
  },
  [PRE_TXN_URL.CREATETRADPASS]: {
    name: 'CreateTradingPass',
    service: 'CreateTradingPassService',
    store: 'CreateTradingPassStore',
    resolveContent: 'createtradingpass',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "online-account-setup,create-trading-password",
      name: "details",
      nameGA: "Create Trading Password",
      formInfo: {
        name: "obr-create-trading-password",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },

  [PRE_TXN_URL.CREATETRADCONFIRMPASS]: {
    name: 'CreateTradingPassConfirm',
    service: 'CreateTradingPassConfirmService',
    store: 'CreateTradingPassConfirmStore',
    resolveContent: 'createtradingpassconfirm',
    policy: {
      navFrom: {
        url: ['/signon/createTradingPass'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/signon'
    },
    restService: [],
    trackingData: {
      hierarchy: "online-account-setup,create-trading-password",
      name: "confirmation",
      nameGA: "Create Trading Password Confirmation",
      formInfo: {
        name: "obr-create-trading-password",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    }
  },

  [PRE_TXN_URL.ELECTRONICACCESSAGREEMENT]: {
    name: 'ElectronicAccessAgreement',
    service: 'ElectronicAccessAgreementService',
    store: 'ElectronicAccessAgreementStore',
    resolveContent: 'electronicAccessAgreement',
    policy: {
      navFrom: {
        url: ['/signon', '/signon/createSignonAndTradingPass/confirm', '/signon/createSignonPass/confirm',
          '/signOn/changeSignOnPass/confirm', '/signon/createTradingPass/confirm', '/signOn/otvc/otvcsetup', '/olbsso/signon'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/signon'
    },
    restService: [],
    trackingData: {
      hierarchy: "online-account-setup,agreement",
      name: "details",
      nameGA: "Electronic Access Agreement",
      formInfo: {
        name: "obr-account-setup-agreement",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  [PRE_TXN_URL.REGISTERONLINELANDING]: {
    name: 'RegisterOnline',
    service: 'RegisterOnlineService',
    store: 'RegisterOnlineStore',
    resolveContent: 'registeronline',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "online-registration",
      name: "personal-details",
      nameGA: "Register for online access",
      formInfo: {
        name: "obr-online-registration",
        stepName: "personal-details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },

  [PRE_TXN_URL.CREATEPASSWORD]: {
    name: 'CreatePassword',
    service: 'CreatePasswordService',
    store: 'CreatePasswordStore',
    resolveContent: 'registeronlinecreatepassword',
    policy: {
      navFrom: {
        url: ['/registerOnline/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/registerOnline/landing'
    },
    restService: [
      {
        id: 'init',
        restUrl: 'registerOnlineVerify',
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "online-registration",
      name: "create-user-id",
      nameGA: "Create User Id",
      formInfo: {
        name: "obr-online-registration",
        stepName: "create-user-id",
      },
      events: { formStep: true, pageView: true },
    }
  },


  [PRE_TXN_URL.REGISTERONLINECONFIRM]: {
    name: 'RegisterOnlineConfirm',
    service: 'RegisterOnlineConfirmService',
    store: 'RegisterOnlineConfirmStore',
    resolveContent: 'registeronlineconfirm',
    policy: {
      navFrom: {
        url: ['/registerOnline/createPassword'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'submit',
        restUrl: 'registerOnlineSubmit',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "online-registration",
      name: "confirmation",
      nameGA: "Register Online Confirmation",
      formInfo: {
        name: "obr-online-registration",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true, onlineAccount: true },
    }
  },
  [PRE_TXN_URL.WGONLINEREGISTERVERIFICATION]: {
    name: 'OnlineRegisterVerification',
    service: 'OnlineRegisterVerificationService',
    store: 'OnlineRegisterVerificationStore',
    resolveContent: 'wgonlineregisterverification',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'forgotPasswordInit',
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "register",
      name: "identity-verification",
      nameGA: "Register Online verification",
      formInfo: {
        name: "register-obr",
        stepName: "identy-verification",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  [PRE_TXN_URL.WGCREATEPASSWORD]: {
    name: 'OnlineRegisterPassword',
    service: 'OnlineRegisterPasswordService',
    store: 'OnlineRegisterPasswordStore',
    resolveContent: 'wgonlineregistercreatepassword',
    policy: {
      navFrom: {
        url: [PRE_TXN_URL.WGONLINEREGISTERVERIFICATION],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
    ],
    trackingData: {      
      hierarchy: "register",
      name: "password-setup",
      nameGA: "Online Register Password",
      formInfo: {
        name: "register-obr",
        stepName: "password-setup",
      },
      events: { formStep: true, pageView: true },
    }
  },
  [PRE_TXN_URL.WGCREATEUSERNAME]: {
    name: 'OnlineRegisterUsername',
    service: 'OnlineRegisterUsernameService',
    store: 'OnlineRegisterUsernameStore',
    resolveContent: 'wgonlineregisterusername',
    policy: {
      navFrom: {
        url: [PRE_TXN_URL.WGCREATEPASSWORD],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'savePwd',
        restUrl: 'wgOnlineRegisterSavePwd',
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "register",
      name: "change-user-id",
      nameGA: "Change User ID",
      formInfo: {
        name: "register-obr",
        stepName: "change-user-id",
      },
      events: { formStep: true, pageView: true },
    }
  },
  [PRE_TXN_URL.WGCREATEMAILINGOPTIONS]: {
    name: 'OnlineRegisterMailingOptions',
    service: 'OnlineRegisterMailingOptionsService',
    store: 'OnlineRegisterMailingOptionsStore',
    resolveContent: 'wgonlineregistermailingoptions',
    policy: {
      navFrom: {
        url: [PRE_TXN_URL.WGCREATEUSERNAME],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
    ],
    trackingData: {
      hierarchy: "register",
      name: "mailing-preferences",
      nameGA: "Register Mailing Options",
      formInfo: {
        name: "register-obr",
        stepName: "mailing-preferences",
      },
      events: { formStep: true, pageView: true },
    }
  },
  [PRE_TXN_URL.WGCONFIRMATION]: {
    name: 'OnlineRegisterConfirmation',
    service: 'OnlineRegisterConfirmationService',
    store: 'OnlineRegisterConfirmationStore',
    resolveContent: 'wgonlineregisterconfirmation',
    policy: {
      navFrom: {
        url: [PRE_TXN_URL.WGCREATEMAILINGOPTIONS],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'mailingOptions',
        restUrl: 'wgOnlineRegisterMailingOptions',
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "register",
      name: "confirmation",
      nameGA: "Register Mailing Options Confirmation",
      formInfo: {
        name: "register-obr",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    }
  },
  [PRE_TXN_URL.ACCOUNTSAGREEMENTSDISCLOSURES]: {
    name: 'AccountsAgreementsDisclosures',
    service: 'AccountsAgreementsDisclosuresService',
    store: 'AccountsAgreementsDisclosuresStore',
    resolveContent: 'accountsagreementsdisclosures',
    restService: [],
    trackingData: {
    }
  },
  [PRE_TXN_URL.FEESCOMMISSIONS]: {
    name: 'FeesCommissions',
    service: 'FeesCommissionsService',
    store: 'FeesCommissionsStore',
    resolveContent: 'iefeescommissions',
    restService: [],
    trackingData: {
    }
  }
};
