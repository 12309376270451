import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from '../../../../shared/services/page-service';
import { AppStore } from '../../../../shared/models/app-store';
import { HttpService } from '../../../../core/services/http.service';
import { CommonService } from 'src/app/shared/services/common.service';
declare var require: any;
const gatewayConfig = require('../../../../config/gateway-config.json');

@Injectable({
  providedIn: 'root'
})
export class IposAndNewIssuesService implements PageService {
  constructor(private appStore: AppStore, private httpService: HttpService, private commonService: CommonService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ser = input[0];
    return input;
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  iposDetailService(IpoId: string) {
    const url = gatewayConfig.APIServices.getIPODetails.url;
    const request = {
      "IpoId": IpoId
    };
    return this.httpService.post(url, request);
  }

  iposListService(req) {
    const url = gatewayConfig.APIServices.getIPOList.url;
    return this.httpService.post(url, req);
  }
}