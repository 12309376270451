import { BaseData } from 'src/app/shared/models/base-data';

export class CreateTradingPassConfirmData extends BaseData {
    constructor() {
        super();
        this.input = {
            tradingPassword: "",
            savePassword: false
        };
    }
}

export interface CreateTradingPassConfirmInputs {
    tradingPassword: string;
    savePassword: boolean;
}