import { BaseData } from 'src/app/shared/models/base-data';

export class SSOSetupData extends BaseData {
    constructor() {
        super();
        this.input = {
            userID: null,
            password: null,

        };
    }
}

export interface SSOSetupInputs {
    userID: string;
    password: string;
}

export class AssociateAccounts {
    UserId: string;
    Password: string;
    Ssolocale: string;
    CustomerType: string;
    SsoAvailable: string;
}