import { BaseData } from 'src/app/shared/models/base-data';

export class SSORoutingHandlerData extends BaseData {
    constructor() {
        super();
        this.input = {

        };
    }
}

export interface SSORoutingHandlerInputs {

}

export class OlbSSOIncomingReq {
    Ssolocale: string;
    CustomerType: string;
    SsoUnAvailable: string;
}
