import { Directive, HostListener, Renderer2, ElementRef, Input, OnChanges, HostBinding } from '@angular/core';
import { isDefined } from 'src/app/shared/services/utils.service';

@Directive({
  selector: '[stickyHeaderDirective]'
})
export class StickyHeaderDirective {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  @Input() stickyHeaderDirective: any;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    let stickies: any = this.el.nativeElement.getElementsByClassName("app-sticky-" + this.stickyHeaderDirective);
    let i = -1;
    while (i + 1 < stickies.length && event.target.scrollingElement.scrollTop > stickies[i + 1].offsetTop - 0) {
      i++;
    }
    for (let j = 0; j < stickies.length; j++) {
      if (i > -1 && i == j) {
        if (stickies[j].dataset.stick == 'false') {
          stickies[j].dataset.stick = 'true';
          stickies[j].setAttribute("style", "height: " + window.getComputedStyle(stickies[j]).height + ";");

          let body = stickies[j].getElementsByClassName("div-body");
          if (body.length > 0) {
            body[0].classList.add("sticky");
          }
        }
      } else {
        if (stickies[j].dataset.stick == 'true') {
          stickies[j].dataset.stick = 'false';
          setTimeout(() => {
            stickies[j].removeAttribute("style");

            let body = stickies[j].getElementsByClassName("div-body");
            if (body.length > 0) {
              body[0].classList.remove("sticky");
            }
          }, 100);
        }
      }
    }
  }
}