import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { SellFixedIncomeData } from './sell-fixed-income-data';

@Injectable({ providedIn: 'root' })
export class SellFixedIncomeStore extends PageStore<SellFixedIncomeData> {

    constructor() {
        super(new SellFixedIncomeData());
    }

}
