import { Injectable } from '@angular/core';
import { ForgotSignonPassData } from './forgot-signon-pass-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ForgotSignonPassStore extends PageStore<ForgotSignonPassData> {

    constructor() {
        super(new ForgotSignonPassData());
    }
}
