<app-field-level-error-global-message [errorList]="error" [identifier]="'otvc-verify'">
</app-field-level-error-global-message>
<form #otvcSendForm="ngForm">
  <!-- <section *ngIf="compModel.pageLoaded"> -->
  <!-- <section> -->
  <section>
    <span class="sr-only">{{globalStore.text.dynamicForm}}</span>
    <p class="paragraph-content">{{modelContent.text.pgInstructions1}}
      <!-- <span>
        <a role="link" href="javascript:void(0);" class='paddingRight5'
          (click)="learnMore()">{{modelContent.text.learnMore}}</a>
        <span class='sr-only'>{{modelContent.text.openNewWindow}}</span>
        <span aria-hidden="true" class="icon-Out-of-App color03"></span>
      </span> -->
    </p>

    <p class="paragraph-content marginTop20">{{modelContent.text.pgInstructions2}}</p>
    <!-- <p class="paragraph-content marginTop20">{{modelContent.text.pgInstructions3}}</p> -->
    <p class="paragraph-content marginTop20 marginBottom20 bold">{{modelContent.text.pgInstructions3}}</p>
  </section>
  <!-- <app-field-level-error-global-message [errorList]="error"></app-field-level-error-global-message> -->


  <!-- <label id="contactLabel" for="contactMethod" class="input-label">Your Contact Method</label>
  <div>
    <div *ngIf="compModel.otvcDetail.Contact.length == 1" [attr.disabled]="true" class='select-item select inline'>
      <select id="contactMethod" [(ngModel)]="compModel.model.otvcType" focusInputDirective>
        <option *ngFor=" let mode of compModel.otvcDetail.Contact" [ngValue]="mode.Method" selected>
          {{getModeContent(mode.Method)}}&nbsp;{{displayDalingCode(mode)}}{{mode.Value}}
        </option>
      </select>
      <span class="select-icon icon-Chevron-Down" aria-hidden="true"></span>
    </div>

    <div *ngIf="compModel.otvcDetail.Contact.length > 1" class='select-item select inline'>
      <select id="contactMethod" [(ngModel)]='compModel.model.otvcType'
        (change)='handleSelection(compModel.model.otvcType)' [attr.disabled]="compModel.isResendEnable ? true : null"
        [inputErrorDirective]="error.selectDropdown">
        <option value='-1' selected='compModel.otvcType.value === -1'>{{modelContent.text.chooseOne}}</option>
        <option *ngFor='let mode of compModel.otvcDetail.newContact;' [ngValue]="mode.Method"
          [selected]="mode.Method == compModel.otvcDetail.LastUsed">
          {{getModeContent(mode.Method)}}&nbsp;{{displayDalingCode(mode)}}{{mode.Value}}
        </option>
      </select>
      <span class="select-icon icon-Chevron-Down" aria-hidden="true"></span>
    </div>

    <div class="inline -ms-page-bottom-two-col" *ngIf="compModel.showSend">
      <div class="page-bottom-button-container sendButton">
        <button id="sendButton" type="button" class='button primary apply-button  marginBottom20'
          [attr.disabled]="compModel.isSend ? true : null" (click)="handleSend('send')">
          Send Code
        </button>
      </div>
    </div>


    <div class="inline -ms-page-bottom-two-col" [ngStyle]="{'display': compModel.showSend ? 'none':''}">
      <div class="page-bottom-button-container sendButton">
        <button id='resendButton' class='button primary apply-button  marginBottom20'
          (click)="compModel.model.verifyCode = '';handleSend('resend')" type="button" manual-focus
          [attr.disabled]="compModel.isResendEnable ? true : null" role="button">
          Resend Code
        </button>
      </div>
    </div>
  </div> 
  <app-field-level-error [error]="error.selectDropdown" [Id]="'selectDropdown-error' + uniqueId">
  </app-field-level-error> -->

  <!-- new grid start-->
  <section class="contact-info-two-col" [ngClass]="error.selectDropdown? 'selectCustom':''">
    <section class="contact-info-left">
      <div class="info-label">
        <label id="contactLabel" for="contactMethod" class="input-label">{{modelContent.text.ycMethod}}</label>
      </div>

      <div *ngIf="compModel.otvcDetail.Contact.length == 1" [attr.disabled]="true" class='select-item select inline'>
        <select id="contactMethod" [atr.aria-describedby]="'selectDropdown-error' + uniqueId"
          [(ngModel)]="compModel.model.otvcType" name="selectDropdown" #selectDropdown="ngModel"
          [inputErrorDirective]="error.selectDropdown" [attr.disabled]="compModel.isResendEnable ? true : null"
          focusInputDirective>
          <option value='' selected="compModel.model.otvcType === ''">{{modelContent.text.chooseOne}}</option>
          <option *ngFor=" let mode of compModel.otvcDetail.Contact" [ngValue]="mode.Method" selected>
            {{getModeContent(mode.Method)}}&nbsp;{{displayDalingCode(mode)}}{{formatPhoneNum(mode)}}
          </option>
        </select>
        <span class="select-icon icon-Chevron-Down color03" aria-hidden="true"></span>
      </div>

      <div *ngIf="compModel.otvcDetail.Contact.length > 1" class='select-item select inline'>
        <select id="contactMethod" [attr.aria-describedby]="'selectDropdown-error' + uniqueId"
          [(ngModel)]='compModel.model.otvcType' name="selectDropdown" #selectDropdown="ngModel"
          (change)='handleSelection(compModel.model.otvcType)' [attr.disabled]="compModel.isResendEnable ? true : null"
          [inputErrorDirective]="error.selectDropdown">
          <option value='' [selected]="compModel.model.otvcType === ''">{{modelContent.text.chooseOne}}</option>
          <option [attr.aria-label]="getDropdownAriaLabel(mode)" *ngFor='let mode of compModel.otvcDetail.newContact;'
            [ngValue]="mode.Method" [selected]="mode.Method == compModel.otvcDetail.LastUsed">
            {{getModeContent(mode.Method)}}&nbsp;{{displayDalingCode(mode)}}{{formatPhoneNum(mode)}}
          </option>
        </select>
        <span class="select-icon icon-Chevron-Down color03" aria-hidden="true"></span>
      </div>
      <!-- <app-field-level-error [error]="error.selectDropdown" [Id]="'selectDropdown-error' + uniqueId"
          [ngClass]="fieldErrorCustome">
        </app-field-level-error> -->
      <app-field-level-error [error]="error.selectDropdown" [Id]="'selectDropdown-error' + uniqueId">
      </app-field-level-error>
    </section>

    <section class="contact-info-right">
      <div class="inline -ms-page-bottom-two-col" *ngIf="compModel.showSend && !loading">
        <div class="page-bottom-button-container sendButton">
          <button id="sendButton" type="button" class='button primary apply-button'
            [attr.disabled]="compModel.isSend ? true : null" (click)="submit('send')">
            {{modelContent.text.sCodeButton}}
          </button>
        </div>
      </div>

      <div *ngIf="loading" class="inline -ms-page-bottom-two-col">
        <div class="spinner-centering">
          <span class="spinner-container">
            <app-general-spinner></app-general-spinner>
          </span>
          <span class="spinner-text">{{modelContent.text.sending}}</span>
        </div>
      </div>


      <div *ngIf="!loading" class="inline -ms-page-bottom-two-col"
        [ngStyle]="{'display': compModel.showSend ? 'none':''}">
        <div class="page-bottom-button-container sendButton">
          <button id='resendButton' class='button primary apply-button'
            (click)="compModel.model.verifyCode = '';submit('resend')" type="button" manual-focus
            [attr.disabled]="compModel.isResendEnable ? true : null" role="button"
            [ngClass]="compModel.isResendEnable ? 'disabled' : ''">
            {{modelContent.text.rCodeButton}}
          </button>
        </div>
      </div>
    </section>
  </section>
</form>

<!-- new grid end-->

<form #otvcVerifyForm="ngForm">

  <div id="verify" [ngStyle]="{'display': compModel.showSend ? 'none' : ''}">
    <div aria-hidden="true" class="col confirmPic content-header-icon icon-Success-Fill color20">
    </div>
    <div id="sendSuccessfull" tabindex="-1" class="col verifyText">
      <p class="verification-header bold"> {{modelContent.text.verifyMessage1}}</p>
      <p class="verification-content marginBottom20">{{modelContent.text.verifyMessage2}}</p>
    </div>

    <label class="input-label " for="verifyCode">{{modelContent.text.vCode}}</label>

    <div class="field-input">
      <input type="text" [attr.aria-describedby]="'verifyCode-error' + uniqueId" class="col-filter-input marginBottom20"
        id="verifyCode" name="verifyCode" [(ngModel)]='compModel.model.verifyCode' name="verifyCode" autocomplete="one-time-code"
        #verifyCode="ngModel" (ngModelChange)="chkVerify(compModel.model)" maxlength='6' (paste)="onPaste($event)"
        [inputErrorDirective]="error.verifyCode" [ngClass]="error.verifyCode? 'codeCustom':''" focusInputDirective>

      <app-field-level-error [error]="error.verifyCode" [Id]="'verifyCode-error' + uniqueId">
      </app-field-level-error>
    </div>

  </div>
  <!-- </section> -->
  <!-- </section> -->

  <!-- <hr class="divider" aria-hidden="true"> -->
  <div class="page-bottom-divider -ms-page-bottom-divider-two-col"></div>

  <div>

    <section [ngClass]="(isDesktop())?'float-right':'page-bottom-left-button-group'">
      <button id="submit" (click)="submit('verify')" *ngIf="!compModel.showSend && !verifySpinner"
        class='button primary marginTop20 marginBottom10-small'>
        {{modelContent.text.nextButton}}
      </button>
    </section>

    <section id="otvc_verify_spinner" *ngIf="verifySpinner" [ngClass]="(isDesktop())?'float-right':'page-bottom-left-button-group'">
      <div class="spinner-centering">
        <span class="spinner-verify-container">
          <app-general-spinner></app-general-spinner>
        </span>
        <span class="spinner-verify-text">{{modelContent.text.verifyingCode}}</span>
      </div>
    </section>
    <section>

    </section>
    <section [ngClass]="(isDesktop())?'':'page-bottom-right-button-group'">
      <button id="cancel" type="button" class='button secondary marginTop20-small cancelStyle' (click)="cancel()"
        [ngClass]="compModel.showSend?'cancelStyle1':''">
        {{modelContent.text.cancelButton}}
      </button>
    </section>
  </div>
</form>