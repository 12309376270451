import { markitPageActions } from "../data/markit-data";
import { of } from 'rxjs/internal/observable/of';
import { QuotesResearchBridgeStore } from "../../modules/txn/quotesResearch/bridge/data/quotes-research-bridge-store";
import { isDefined } from 'src/app/shared/services/utils.service';
import { ErrorConstant } from 'src/app/core/models/app-error';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

declare let window: any;

export function buildMarkitPageActions(router, httpService, trackingService, observableSubscriptionService) {
  window.mdPageActions = new markitPageActions;

  let gettingToken = false;
  let retryCount = 0;

  window.mdPageActions.obtainToken = () => {
    return new Promise<boolean | string>((resolve, reject) => {
      if (gettingToken) {
        observableSubscriptionService.tokenReturned.subscribe((res) => {
          if (res) {
            resolve(res);
          } else {
            resolve(false);
          }
        })
      } else {
        gettingToken = true;
        httpService.post("/v2/markit/token/get/all", {}, { params: { skiploading: 'true' } }).subscribe((res) => {
          gettingToken = false;
          retryCount = 0;
          if (res) {
            observableSubscriptionService.returnMarkitToken(res.Token);
            resolve(res.Token);
            localStorage.setItem('access_token', res.Token);
          } else {
            observableSubscriptionService.returnMarkitToken(null);
            resolve(false);
          }
        }, (err) => {
          gettingToken = false;
          if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
            reject("EAA")
          } else if (retryCount < 2) {
            retryCount++;
            window.mdPageActions.obtainToken().then((res) => {
              resolve(res);
            });
          } else {
            retryCount = 0;
            observableSubscriptionService.returnMarkitToken(null);
            resolve(false);
          }
        })
      }
    })
  };

  window.mdPageActions.navigateOBR = (id: string, params?: any) => {
    if (isDefined(params)) {
      QuotesResearchBridgeStore.crossFlow = params;
    } else {
      QuotesResearchBridgeStore.crossFlow = {};
    }
    switch (id) {
      case "get-pdf":
        QuotesResearchBridgeStore.crossFlow.action = 'getPdf';
        break;
      case "stocks-snapshot":
        QuotesResearchBridgeStore.crossFlow.action = 'stockSnap';
        break;
      case "fundcentre-snapshot":
        QuotesResearchBridgeStore.crossFlow.action = 'fundSnap';
        break;
      case "etfcentre-snapshot":
        QuotesResearchBridgeStore.crossFlow.action = 'etfSnap';
        break;
      case "etf-portfolio":
        QuotesResearchBridgeStore.crossFlow.action = 'etfPortfolio';
        break;
      case "analyst-ratings":
        QuotesResearchBridgeStore.crossFlow.action = 'eqAnalysts';
        break;
      case "watch-lists":
        QuotesResearchBridgeStore.crossFlow.action = 'watchlists';
        break;
      case "watch-list-edit":
        QuotesResearchBridgeStore.crossFlow.action = 'watchlistsEdit';
        break;
      case "watch-list-detail":
        QuotesResearchBridgeStore.crossFlow.action = 'watchlistsDetails';
        break;
      case "my-alerts-watch-list":
        QuotesResearchBridgeStore.crossFlow.action = 'alertsWatchList';
        break;
      default:
        QuotesResearchBridgeStore.crossFlow.action = 'default';
        break;
    }
    router.navigate(['/txn/bridge/quotesResearch'], {
      skipLocationChange: true,
    });
  }

  window.mdPageActions.omnitureAction = (action: string) => {
    if ((window as any).TrackingEnabled) {
      of(trackingService.tagInteraction(action, false, true, false, true));
    }
  }
}

