import { Injectable } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import moment from 'moment';
import { isDefined } from 'src/app/shared/services/utils.service';


export interface ContentType {
  name: any;
  lob: any;
  lang: any;
  text?: any;
  error?: any;
  list?: any;
  dropdown?: any;
}

@Injectable({ providedIn: 'root' })
export class GlobalContentStore implements ContentType {
  name: any;
  lob: any;
  lang: any;
  text?: any;
  error?: any;
  list?: any;
  dropdown?: any;

  constructor(
    private appStore: AppStore
  ) {
    moment.updateLocale('en', {
      monthsShort: [
        "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
        "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
      ]
    });
  }

  public getIssuerDescription(type) {
    if (type === 'cibc_ce') {
      return this.text.cibc_ce;
    } else if (type === 'cibc_nce') {
      return this.text.cibc_nce;
    } else if (type === 'cibcm_lt') {
      return this.text.cibcm_lt;
    } else if (type === 'cibc_flx') {
      return this.text.cibc_flx;
    } else if (type === 'cibct_lt') {
      return this.text.cibct_lt;
    }
  }

  public getBalanceContent(type, mode?) {
    if (type === 'CSH') {
      return mode == 'ExchangeCurrency' ? this.text.cashBalanceExchangeCurrency : this.text.cashBalance;
    } else if (type === 'MRL') {
      return this.text.MarginAvailable;
    }
  }

  public getPayAccount(type, bankAccNumber) {
    if (type === 'Investment') {
      return this.text.invAccount;
    } else if (type === 'IPP') {
      return this.text.ippAccount;
    } else if (type === 'LIF') {
      return this.text.lifAccount;
    } else if (type === 'LIRA') {
      return this.text.liraAccount;
    } else if (type === 'LRIF') {
      return this.text.lrifAccount;
    } else if (type === 'PRIF') {
      return this.text.prifAccount;
    } else if (type === 'PRSP') {
      return this.text.prspAccount;
    } else if (type === 'RDSP') {
      return this.text.rdspAccount;
    } else if (type === 'RESP') {
      return this.text.respAccount;
    } else if (type === 'RRIF') {
      return this.text.rrifAccount;
    } else if (type === 'RRSP') {
      return this.text.rrspAccount;
    } else if (type === 'TFSA') {
      return this.text.tfsaAccount;
    } else if (type === 'FHSA') {
      return this.text.fhsaAccount;
    } else if (type === 'Bank') {
      return this.text.bankAccount + ' ' + bankAccNumber;
    } else if (type === 'bnkmm') { // money market fund
      return this.text.CIBCMoneyMarketFund;
    } else if (type === 'brkrg') {
      return this.text.brkrgAccount
    }
    else {
      return this.text.regAccount;
    }
  }

  public getAccountType(type) {
    if (type === 'Investment') {
      return this.text.investment;
    } else if (type === 'IPP') {
      return this.text.ipp;
    } else if (type === 'LIF') {
      return this.text.lif;
    } else if (type === 'LIRA') {
      return this.text.lira;
    } else if (type === 'LRIF') {
      return this.text.lrif;
    } else if (type === 'PRIF') {
      return this.text.prif;
    } else if (type === 'PRSP') {
      return this.text.prsp;
    } else if (type === 'RDSP') {
      return this.text.rdsp;
    } else if (type === 'RESP') {
      return this.text.resp;
    } else if (type === 'RRIF') {
      return this.text.rrif;
    } else if (type === 'RRSP') {
      return this.text.rrsp;
    } else if (type === 'TFSA') {
      return this.text.tfsa;
    } else if (type === 'FHSA') {
      return this.text.fhsa;
    }
    else if (type === 'Registered') {
      return this.text.registered;
    } else if (type === 'USIvestmentAccount') {
      return this.text.usInvestmentAccount;
    } else if (type === 'marginLong') {
      return this.text.marginLong;
    } else if (type === 'Bank') {
      return this.text.bankAccount;
    }
  }

  public getFixedIncomeType(type) {
    if (this.list.typeFi[type]) {
      return this.list.typeFi[type];
    } else {
      return type;
    }
  }

  public getIntrumentClass(instVal) {
    if (this.list.instrumentClass[instVal]) {
      return this.list.instrumentClass[instVal];
    } else {
      return instVal;
    }
  }

  public getReviewerNote(note) {
    if (this.list.reviewerNote[note]) {
      return this.list.reviewerNote[note];
    } else {
      return note;
    }
  }

  public getOrderStateContent(FiType, state) {
    if (this.list.status[FiType] && this.list.status[FiType][state]) {
      return this.list.status[FiType][state];
    }

    return state;
  }

  public getSymbolContent(symbol, market) {
    let sym = symbol;
    if (symbol === undefined) {
      sym = "";
    }
    if (market === 'CA') {
      return sym + ':CDN';
    } else if (market === 'US') {
      return sym + ':US';
    } else {
      return sym;
    }
  }

  public getCurrencyContent(currency) {
    if (currency === 'CAD') {
      return this.text.canadianDollar;
    } else if (currency === 'USD') {
      return this.text.USDollar;
    }
  }

  public capitalizeString(str: string): string {
    if (str[0] >= 'a' && str[0] <= 'z') {
      return String.fromCharCode(str.charCodeAt(0) + 'A'.charCodeAt(0) - 'a'.charCodeAt(0)) + str.substring(1);
    } else {
      return str;
    }
  }

  public getNewExpiryContent(expiry, expiryVal, formatEN, formatFR, lang: string, capitalize?: boolean) {
    if (expiry === 'DAY') {
      let ret = this.text.endOfDay;
      return capitalize ? this.capitalizeString(ret) : ret;
    } else if (expiry === 'DAY_PLUS' || expiry === 'DAY+') {
      let ret = this.text.endOfDayPlusExtHours;
      return capitalize ? this.capitalizeString(ret) : ret;
    } else {
      if (lang.toUpperCase() === 'EN') {
        const mome = moment(expiryVal);
        const form = mome.format(formatEN);
        return moment(expiryVal).format(formatEN);
      } else if (lang.toUpperCase() === 'FR') {
        return moment(expiryVal).locale('FR').format(formatFR);
      }
    }
  }

  public getExpiryContent(expiry, expiryVal, formatEN, formatFR, lang: string, extendedHours?: boolean) {
    if (expiry === 'DAY') {
      return this.text.goodForDay;
    } else if (expiry === 'DAY_PLUS' || expiry === 'DAY+') {
      if (extendedHours) {
        return this.text.dayPlusExtHours;
      } else {
        return this.text.dayPlus;
      }
    } else {
      if (lang.toUpperCase() === 'EN') {
        const mome = moment(expiryVal);
        const form = mome.format(formatEN);
        return moment(expiryVal).format(formatEN);
      } else if (lang.toUpperCase() === 'FR') {
        return moment(expiryVal).locale('FR').format(formatFR);
      }
    }
  }

  public getExpiryShortContent(expiry, expiryVal, formatEN, formatFR, lang: string) {
    if (expiry === 'DAY') {
      return this.text.day;
    } else if (expiry === 'DAY_PLUS' || expiry === 'DAY+') {
      return this.text.dayPlus;
    } else {
      if (lang.toUpperCase() === 'EN') {
        const mome = moment(expiryVal);
        const form = mome.format(formatEN);
        return moment(expiryVal).format(formatEN);
      } else if (lang.toUpperCase() === 'FR') {
        return moment(expiryVal).locale('FR').format(formatFR);
      }
    }
  }

  public getActionContent(action, options: any = {}) {
    if (action === 'BUY' || action === 'CXRBUY' || action === 'CXLBUY' || action === 'B') {
      return this.text.buy;
    } else if (action === 'SELL' || action === 'CXRSELL' || action === 'CXLSELL' || action === 'S') {
      return this.text.sell;
    } else if (action === 'SELLALL') {
      return this.text.sellAll;
    } else if (action === 'SWITCHALL') {
      return this.text.switchAll;
    } else if (action === 'BUYTOCOVER') {
      return this.text.buyToCover;
    } else if (action === 'SWITCH' || action === 'X') {
      return this.text.switch;
    } else if (action === "BUY_MORE_HOLDINGS") {
      return this.text.buyMore;
    } else if (action === 'SHORT' || action === 'SHORTSELL') {
      return this.text.shortSell;
    } else if (action === 'BUYTOOPEN') {
      return this.text.buyToOpen;
    } else if (action === 'BUYTOCLOSE') {
      return this.text.buyToClose;
    } else if (action === 'SELLTOOPEN') {
      if (options.covered) {
        return this.text.sellToOpenCovered;
      } else {
        return this.text.sellToOpen;
      }
    } else if (action === 'SELLTOCLOSE') {
      return this.text.sellToClose;
    } else if (action === 'CXRBUYTOOPEN') {
      return this.text.changeBuyToOpen;
    } else if (action === 'CXRBUYTOCLOSE') {
      return this.text.changeBuyToClose;
    } else if (action === 'CXRSELLTOOPEN') {
      return this.text.changeSellToOpen;
    } else if (action === 'CXRSELLTOCLOSE') {
      return this.text.changeSellToClose;
    } else if (action === 'CXLBUYTOOPEN') {
      return this.text.cancelBuyToOpen;
    } else if (action === 'CXLBUYTOCLOSE') {
      return this.text.cancelBuyToClose;
    } else if (action === 'CXLSELLTOOPEN') {
      return this.text.cancelSellToOpen;
    } else if (action === 'CXLSELLTOCLOSE') {
      return this.text.cancelSellToClose;
    }
  }

  public getDatePickerHeaderContent() {
    if (this.appStore.lang.toUpperCase() === 'FR') {
      return {
        "month": "Mois",
        "year": "Année"
      };
    } else {
      return {
        "month": "Month",
        "year": "Year"
      };
    }
  }

  public accountNickname(account: any) {
    if (isDefined(account.AccountFriendlyName)) {
      return account.AccountFriendlyName;
    } else {
      return account.AccountTypeDesc;
    }
  }

  public getFixedIncomeYieldType(code) {
    if (code === "1") {
      return this.text.annual;
    } else if (code === "2") {
      return this.text.semiAnnual;
    } else if (code === "4") {
      return this.text.qtr;
    } else if (code === "12") {
      return this.text.mth;
    } else if (code === "365") {
      return this.text.dly;
    } else if (code === "-2") {
      return this.text.annual;
    } else if (code === "-3") {
      return this.text.dSlashR;
    } else if (code === "-4") {
      return this.text.annual;
    } else if (code === "-5") {
      return this.text.annual;
    } else if (code === "-6") {
      return this.text.annual;
    } else if (code === "360") {
      return this.text.mmy;
    } else if (code === "52") {
      return this.text.wkl;
    }
    return '';
  }


  /**
   * 
   * @param creditRating 
   * @returns return en/fr text for credit Rating
   */
  public getCreditRating(creditRating) {
    if (creditRating) {
      if (this.list.CreditRating[creditRating]) {
        return this.list.CreditRating[creditRating];
      } else {
        return creditRating;
      }
    }
  }

}
