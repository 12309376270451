<div *ngIf="data && quickTradeContent.name == 'quicktradeoptionsconfirmation'" class="body-container">
    <app-confirmation [content]="quickTradeContent" [orderConfirmInfo]="orderConfirmInfo" [type]="'!two-col'" [mode]="'quickOptions'">

        <div *ngIf="!isDesktop()" class="div-body">
            <article class="content-header">
              <object class="new-confirm-modal-image" aria-hidden="true" type="image/svg+xml"
                data="assets/images/airplane.svg">
              </object>
              <div class="title">
                <span  *ngIf='orderReviewInfo.Activity.OrderType =="BUYTOOPEN" || orderReviewInfo.Activity.OrderType =="SELLTOOPEN"'>{{quickTradeContent.text.orderPlaced}}</span>
                <span  *ngIf='orderReviewInfo.Activity.OrderType =="BUYTOCLOSE" || orderReviewInfo.Activity.OrderType =="SELLTOCLOSE"'>{{quickTradeContent.text.confirmation}}</span>
              </div>
              </article>
              <article class="content-body">
                <div [ngClass]="orderReviewInfo.Activity.OrderType === 'BUYTOOPEN' || orderReviewInfo.Activity.OrderType === 'BUYTOCLOSE'
                  ? 'description-1'
                  : 'description-2'" *ngIf='orderReviewInfo.Price.Type==="MKT"'>
                 <span class="green">{{globalContent.getActionContent(orderReviewInfo.Activity.OrderType)}}</span>&nbsp;
                 <span class="green">{{orderReviewInfo.Activity.Quantity.Content}} {{quickTradeContent.text.contracts}} </span>
                 <span> {{quickTradeContent.text.at}}</span>
                 <span>{{quickTradeContent.text.market}}</span>
              </div>
              <div [ngClass]="orderReviewInfo.Activity.OrderType === 'BUYTOOPEN' || orderReviewInfo.Activity.OrderType === 'BUYTOCLOSE'
              ? 'description-1'
              : 'description-2'" *ngIf='orderReviewInfo.Price.Type==="LIMIT"'>
                <span class="green">{{globalContent.getActionContent(orderReviewInfo.Activity.OrderType)}}</span>&nbsp;
                <span class="green">{{orderReviewInfo.Activity.Quantity.Content}} {{quickTradeContent.text.contracts}} </span>
                <span> {{quickTradeContent.text.at}}</span>
                 <span>{{quickTradeContent.text.limit}} {{orderReviewInfo.Price.LimitAmount.Content}}</span>
             </div>
                <div [ngClass]="orderReviewInfo.Activity.OrderType === 'BUYTOOPEN' || orderReviewInfo.Activity.OrderType === 'BUYTOCLOSE'
                ? 'description-1'
                : 'description-2'">
                  <span>{{getDateFormat(orderReviewInfo.Symbol.OptionInfo.StrikeDate, 'MMM DD, YYYY', 'DD MMM, YYYY')}}</span>&nbsp;
                  <span>{{orderReviewInfo.Symbol.OptionInfo.StrikePrice.Value}}</span>
                  <span> {{formatOptionType(orderReviewInfo.Symbol.OptionInfo.OptionType) | uppercase}}</span> 
                  </div>
      
                  <div [ngClass]="orderReviewInfo.Activity.OrderType === 'BUYTOOPEN' || orderReviewInfo.Activity.OrderType === 'BUYTOCLOSE'
                  ? 'description-1'
                  : 'description-2'">
                        <img class="underlyingquote-flag" height="12" width="18" aria-hidden="true" alt=""
                          [src]="getFlag(orderReviewInfo.Symbol.Market)" />
                        <span class="underlyingquote-name">{{
                          globalContent.getSymbolContent(
                            orderReviewInfo.Symbol.SymbolName,
                            orderReviewInfo.Symbol.Market
                          )
                          }}</span>
                      </div>
        
                <div id="exchangeContnr" *ngIf='orderReviewInfo.ExchangeRate || orderReviewInfo.CadEquivalent'>
                  <div *ngIf='orderReviewInfo.ExchangeRate'>
                    <span>
                      <label>
                        <span>{{quickTradeContent.text.exchangeRate}}:</span>
                        <span>1&nbsp;
                          <span attr.aria-label="{{orderReviewInfo.ExchangeRate.SourceCurrency.Accessibility}}">
                            {{orderReviewInfo.ExchangeRate.SourceCurrency.Content}}
                          </span> &nbsp;=&nbsp; {{orderReviewInfo.ExchangeRate.Rate}}&nbsp;
                          <span attr.aria-label="{{orderReviewInfo.ExchangeRate.TargetCurrency.Accessibility}}">
                            {{orderReviewInfo.ExchangeRate.TargetCurrency.Content}}
                          </span>
                        </span>
                      </label>
                    </span>
                  </div>
                  <div *ngIf='orderReviewInfo.CadEquivalent'>
                    <span>
                      <label>
                        <span>{{quickTradeContent.text.canadianEquivalent}}&nbsp;</span>
                        <span>{{orderReviewInfo.CadEquivalent}}</span>
                      </label>
                    </span>
                  </div>
                </div>
        
              </article>
      
              <div class="order-summary">
                <div class="orderExpiry">{{quickTradeContent.text.orderExpiry}}</div>
                <div class="orderExpiryDetails">
                  <span>{{globalContent.getNewExpiryContent(orderReviewInfo.Expiry.ExpiryType,orderReviewInfo.Expiry.ExpiryDate,'MMM
                  DD, YYYY','D
                  MMM YYYY',appStore.lang)}}</span>
                </div>
      
                <div class="received">{{quickTradeContent.text.receivedC}}</div>
                <div class="receivedAt">
                  {{orderConfirmInfo.OrderTimestamp}}
                </div>
                <div *ngIf='orderReviewInfo.Activity.OrderType =="BUYTOCLOSE" || orderReviewInfo.Activity.OrderType =="SELLTOCLOSE"' class="proceeds">{{quickTradeContent.text.estimatedProceeds}}</div>
                <div  *ngIf='orderReviewInfo.Activity.OrderType =="BUYTOOPEN" || orderReviewInfo.Activity.OrderType =="SELLTOOPEN"' class="proceeds">{{quickTradeContent.text.estimatedCosts}}</div>
                  <div class="proceedsDetails" *ngIf="orderReviewInfo.Symbol.Market ==='US' || orderReviewInfo.Symbol.SymbolName.endsWith('.U')"
                  [innerHtml]="estimatedCost.EstimatedTotalCost.replace(' $', '').replace('$', '') | currencyFormatter:'USD':'format-currency-sr' | removeDollar:'html'">
                </div>
                <div class="proceedsDetails" *ngIf="!(orderReviewInfo.Symbol.Market ==='US' || orderReviewInfo.Symbol.SymbolName.endsWith('.U'))"
                  [innerHtml]="estimatedCost.EstimatedTotalCost.replace(' $', '').replace('$', '') | currencyFormatter:'CAD':'format-currency-sr' | removeDollar:'html'">
                </div>
                 <div class="empty"></div>
                 <div
                 class="proceedEstimation"> {{quickTradeContent.text.tradeValueMobile}} {{estimatedCost.EstimatedTotalCost}} , <br />
                 {{quickTradeContent.text.estimatedCommissionMobile}} {{estimatedCost.EstimatedCommission}} </div> 
                <div class="orderId">{{quickTradeContent.text.trackingNumberC}}</div>
                <div class="orderIdDetails" [innerHTML]="orderConfirmInfo.OrderId | srOnly:true"></div>
      
                <div class="account">{{quickTradeContent.text.account}}</div>
                <div class="accountDetails">{{globalContent.getAccountType(orderReviewInfo.Account.AccountTypeDesc)}} {{(orderReviewInfo.Account.AccountNumber)}}
                 - {{(orderReviewInfo.Activity.OrderType == "SELLTOCLOSE" || orderReviewInfo.Activity.OrderType == "SELLTOOPEN") ? orderReviewInfo.PayTo.Currency : orderReviewInfo.PayFrom.Currency}}

                </div>
              </div>
            </div> 
      
          <div class="div-bottom">
            <div class="page-bottom-button-container">
              <div class="page-bottom-right-button-group">
                <button class="button primary bottom-button-size" type="button"
                  (click)="next()">{{quickTradeContent.text.viewOrderStatusButton}}</button>
              </div>
              <div class="page-bottom-left-button-group">
                <button class="button secondary bottom-button-size" type="button"
                  (click)="newOrder()">{{quickTradeContent.text.placeNewOrderButton}}</button>
              </div>
            </div>
            <!-- <app-disclaimers identifier='TradeOptionsConfirm'></app-disclaimers> -->
          </div>
      
        </app-confirmation>
</div>
