import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { BuyGICOrderData } from './buy-gic-order-data';

@Injectable({ providedIn: 'root' })
export class BuyGICOrderStore extends PageStore<BuyGICOrderData> {

    constructor() {
        super(new BuyGICOrderData());
    }

}
