import { Injectable } from '@angular/core';
import { NicknameAccountsLandingData } from './nickname-accounts-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class NicknameAccountsLandingStore extends PageStore<NicknameAccountsLandingData> {

    constructor() {
        super(new NicknameAccountsLandingData());
    }

}
