import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavParamService {

  constructor() { }
  private param: any;

  get navData(): any {
    return this.param;
  }

   setNavData(param: any) {
    this.param = param;
  }

}
