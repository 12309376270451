import { BaseData } from 'src/app/shared/models/base-data';

export class MutualFundsSearchData extends BaseData {
  constructor() {
    super();
    this.input = {
      query: ''
    };
  }
}

export interface MutualFundsSearchInputs {
  query: string;
}

export interface SearchReq {
  Refresh?: boolean;
  SymbolName?: string;
  PaginationOption?: {
    Offset: number,
    Limit: number
  }
  SortOption?: {
    SortColumn: string,
    SortType: string
  }
}

export interface FundFactReq {
  SymbolName: string;
}
