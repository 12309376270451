import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { PageService } from 'src/app/shared/services/page-service';
import { cmfvQuoteReq } from "../data/change-mutual-fund-verify-data";
import { ChangeMutualFundVerifyStore } from "../data/change-mutual-fund-verify-store";
declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})
export class ChangeMutualFundVerifyService implements PageService {

    constructor(private httpService: HttpService) { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        ser.input = ChangeMutualFundVerifyStore.params;
        return input;
    }
    mutualFundVerifyForm(form, fb) {
        form = fb.group({
            agreeProceed: false,
            tradingPassword: "",
            saveTradingPassword: false
        });
        return form;
    }

    getQuotes(data) {
        const req: cmfvQuoteReq = { SymbolName: data.SymbolName };
        return this.httpService.post(gatewayConfig.APIServices.getMutualFundQuote.url, req, { params: { skiploading: 'true' } }
        );
    }

}