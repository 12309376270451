import { Store } from '../../core/store/store';
import { SiteData } from './site-data';
import { BehaviorSubject } from 'rxjs';

export class SiteStore extends Store<SiteData> {
    public dataUpdated: any = new BehaviorSubject<boolean>(false);
    constructor() {
        super(new SiteData());
    }
    setNaviagationObject(obj: any) {
        this.setState({
            ...this.state,
            ...{ navigationObject: obj }
        });
        this.dataUpdated.next(true);
    }
    setglobalFooterObject(obj: any) {
        this.setState({
            ...this.state,
            ...{ globalFooterObject: obj }
        });
        this.dataUpdated.next(true);
    }
    get getnavigationObject() {
        return this.state.navigationObject;
    }
    get getglobalFooterObject() {
        return this.state.globalFooterObject;
    }
    isUpdated() {
        return this.dataUpdated.asObservable();
    }
}
