import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CreditPolicyData } from './credit-policy-data';

@Injectable({ providedIn: 'root' })
export class CreditPolicyStore extends PageStore<CreditPolicyData> {

    constructor() {
        super(new CreditPolicyData());
    }

}
