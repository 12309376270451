import { HeaderModule } from './../../shared/components/header/header.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreTxnComponent } from './pre-txn.component';
import { PreTxnRoutingModule } from './pre-txn-routing.module';
import { FormsModule } from '@angular/forms';
import { HomeModule } from './home/home.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SsoComponent } from './sso/sso.component';
import { FormSharedModule } from './../../shared/form-shared.module';
import { PreHeaderComponent } from './../../shared/components/pre-header/pre-header.component';
import { PreFooterComponent } from './../../shared/components/pre-footer/pre-footer.component';
import {ChatSharedModule} from '../../shared/chat-shared.module';
import { QrCodeModule } from 'ng-qrcode';

@NgModule({
  declarations: [
    PreTxnComponent,
    SsoComponent,
    PreHeaderComponent,
    PreFooterComponent
  ],
  imports: [
    CommonModule,
    PreTxnRoutingModule,
    FormsModule,
    HomeModule,
    SharedModule,
    FormSharedModule,
    HeaderModule,
    ChatSharedModule,
    QrCodeModule
  ]
})
export class PreTxnModule { }

