import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PageService } from 'src/app/shared/services/page-service';
import { CreateCashPlanConfirmStore } from "../data/create-cash-plan-confirm-store";

@Injectable({
    providedIn: 'root'
})
export class CreateCashPlanConfirmService implements PageService {

    constructor() { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        ser.input = CreateCashPlanConfirmStore.params;
        return input;
    }
}