import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { PageStepIndicatorComponent } from 'src/app/shared/components/page-step-indicator/page-step-indicator.component';

@NgModule({
  declarations: [
    ConfirmationComponent,
    PageStepIndicatorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    ConfirmationComponent,
    PageStepIndicatorComponent
  ]
})
export class FlowWidgetSharedModule { }
