import { Injectable } from '@angular/core';
import { PageStore } from '../models/page-store';
import { GenericData } from '../data/generic-data';

@Injectable({ providedIn: 'root' })
export class GenericStore extends PageStore<GenericData> {
    constructor() {
        super(new GenericData());
    }
}
