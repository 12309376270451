import { BaseData } from 'src/app/shared/models/base-data';

export class ChangeSignOnPasswordLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            inputtedCurrentSignOnPassword: null,
            newSignOnPassword: null,
            reEnteredSignOnPassword: null
        };
    }
}

export interface ChangeSignOnPasswordLandingInputs {
    inputtedCurrentSignOnPassword: string;
    newSignOnPassword: string;
    reEnteredSignOnPassword: string
}

export class ChangeSignonPasswordReq {
    CurrentPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
}