import { RouteGroup } from "../shared/models/route-config";

export const FixedIncomeConfig: RouteGroup = {
  "/txn/fixed-income": {
    name: "FixedIncome",
    service: "FixedIncomeService",
    servicePath: "txn/fixed-income/services/fixed-income",
    store: "FixedIncomeStore",
    resolveContent: "fixedincome",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "trading,fixed-income",
      name: "quick-picks",
      nameGA: "GICs and Bonds - Quick Picks",
      events: { pageView: true },
    },
    brazeID: "viewed_fixed_income"
  },
  "/txn/fixed-income/buy-bonds/search": {
    name: "BuyBondsSearch",
    service: "BuyBondsSearchService",
    servicePath: "txn/fixed-income/buyBonds/search/services/buy-bonds-search",
    store: "BuyBondsSearchStore",
    resolveContent: "buybondssearch",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [],
    trackingData: {
      hierarchy: "trading,fixed-income,buy-bonds",
      name: "search",
      nameGA: "GICs and Bonds - Search",
      events: { pageView: true },
    },
    brazeID: "viewed_buy_bonds_search"
  },
  "/txn/fixed-income/buy-bonds/order": {
    name: "BuyBondsOrder",
    service: "BuyBondsOrderService",
    servicePath: "txn/fixed-income/buyBonds/order/services/buy-bonds-order",
    store: "BuyBondsOrderStore",
    resolveContent: "buybondsorder",
    policy: {
      navFrom: {
        url: [
          "/txn/fixed-income/buy-bonds/search",
          "/txn/fixed-income/buy-bonds/verify",
        ],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income/buy-bonds/search",
    },
    restService: [
      {
        id: "detail",
        restUrl: "buyBondsSubmit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,buy-bonds",
      name: "details",
      nameGA: "GICs and Bonds - Details",
      formInfo: { name: "obr-buy-bonds", stepName: "details" },
      events: { formStep: true, pageView: true, formView: true },
    },
    brazeID: "viewed_buy_bonds_details"
  },
  "/txn/fixed-income/buy-bonds/verify": {
    name: "BuyBondsVerify",
    service: "BuyBondsVerifyService",
    servicePath: "txn/fixed-income/buyBonds/verify/services/buy-bonds-verify",
    store: "BuyBondsVerifyStore",
    resolveContent: "buybondsverify",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income/buy-bonds/order"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income",
    },
    restService: [
      {
        id: "init",
        restUrl: "getBondsValidate",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,buy-bonds",
      name: "verification",
      nameGA: "GICs and Bonds - Verification",
      formInfo: { name: "obr-buy-bonds", stepName: "verification" },
      events: { formStep: true, pageView: true }
    }
  },
  "/txn/fixed-income/buy-bonds/confirm": {
    name: "BuyBondsConfirm",
    service: "BuyBondsConfirmService",
    servicePath: "txn/fixed-income/buyBonds/confirm/services/buy-bonds-confirm",
    store: "BuyBondsConfirmStore",
    resolveContent: "buybondsconfirm",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income/buy-bonds/verify"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income",
    },
    restService: [
      {
        id: "submit",
        restUrl: "getBondsSubmit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,buy-bonds",
      name: "confirmation",
      nameGA: "GICs and Bonds - Confirmation",
      formInfo: { name: "obr-buy-bonds", stepName: "confirmation" },
      events: { pageView: true, formSubmit: true, formStep: true, tradeSubmit: true },
      transaction: {
        mainField: "inFlow",
        fieldNames: {
          action: "omnitureParam.Action",
          type: "omnitureParam.Type",
          symbol: "orderReviewInfo.Bond.CusipId",
          market: "na",
          exchange: "na",
          quote: "omnitureParam.value",
          quantity: "omnitureParam.Quantity",
          dividend: "na",
          priceType: "omnitureParam.priceType",
          expiry: "na",
          serviceFee: "orderReviewInfo.EstimatedCost.EstimatedCommission",
          value: "omnitureParam.value",
          currency: "orderReviewInfo.PayFrom.Currency",
          account: "orderReviewInfo.Account.AccountTypeDesc",
          foreignExchange: "foreignExchange",
        },
      }
    }
  },
  "/txn/fixed-income/buy-gic/landing": {
    name: "BuyGicLanding",
    service: "BuyGicLandingService",
    servicePath: "txn/fixed-income/buyGics/landing/services/buy-gic-landing",
    store: "BuyGicLandingStore",
    resolveContent: "buygiclanding",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income", "/txn/accounts/myaccounts", "/txn/accounts/accountholdings", "/txn/buy-structured-notes/selection", "/txn/buy-structured-notes/entry", "/txn/buy-structured-notes/verify", "/txn/buy-structured-notes/confirm", "/txn/orderStatus"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income",
    },
    restService: [
      {
        id: "init",
        restUrl: "getGICSearch",
        get request(): any {
          return {};
        },
      },
      {
        id: "mlgic",
        restUrl: "notesOfferings",
        get request(): any {
          return {
            ProductType: "MLGIC",
          };
        },
      },
      {
        id: "thirdparty",
        restUrl: "GICThirdPartSearch",
        get request(): any {
          return {};
        },
      }
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,buy-gics",
      name: "cibc-gics",
      nameGA: "GICs and Bonds - CIBC GICS",
      events: { pageView: true },
      isSubmitDeferred: true
    }
  },
  "/txn/fixed-income/buy-gic/order": {
    name: "BuyGICOrder",
    service: "BuyGICOrderService",
    servicePath: "txn/fixed-income/buyGics/order/services/buy-gic-order",
    store: "BuyGICOrderStore",
    resolveContent: "buygicorder",
    policy: {
      navFrom: {
        url: [
          "/txn/fixed-income/buy-gic/landing",
          "/txn/fixed-income/buy-gic/verify",
        ],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income/buy-gic/landing",
    },
    restService: [
      {
        id: "init",
        restUrl: "buyGICInit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,buy-gics",
      name: "details",
      nameGA: "GICs and Bonds - Details",
      formInfo: { name: "obr-buy-gics", stepName: "details" },
      events: { formStep: true, pageView: true, formView: true },
    },
    brazeID: "viewed_buy_gics_details"
  },
  "/txn/fixed-income/buy-gic/verify": {
    name: "BuyGICVerify",
    service: "BuyGICVerifyService",
    servicePath: "txn/fixed-income/buyGics/verify/services/buy-gic-verify",
    store: "BuyGICVerifyStore",
    resolveContent: "buygicverify",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income/buy-gic/order"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income",
    },
    restService: [
      {
        id: "init",
        restUrl: "buyGICValidate",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,buy-gics",
      name: "verification",
      nameGA: "GICs and Bonds - Verification",
      formInfo: { name: "obr-buy-gics", stepName: "verification" },
      events: { formStep: true, pageView: true }
    },
    brazeID: "viewed_buy_gics_verify"
  },
  "/txn/fixed-income/buy-gic/confirm": {
    name: "BuyGICConfirm",
    service: "BuyGICConfirmService",
    servicePath: "txn/fixed-income/buyGics/confirm/services/buy-gic-confirm",
    store: "BuyGICConfirmStore",
    resolveContent: "buygicconfirm",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income/buy-gic/verify"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income",
    },
    restService: [
      {
        id: "init",
        restUrl: "buyGICSubmit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,buy-gics",
      name: "confirmation",
      nameGA: "GICs and Bonds - Confirmation",
      formInfo: { name: "obr-buy-gics", stepName: "confirmation" },
      events: { pageView: true, formSubmit: true, formStep: true, tradeSubmit: true },
      transaction: {
        mainField: "inFlow",
        fieldNames: {
          action: "omnitureParam.Action",
          type: "omnitureParam.Type",
          symbol: "orderReviewInfo.Gic.IssuerDescription",
          market: "na",
          exchange: "na",
          quote: "orderReviewInfo.Amount",
          quantity: "omnitureParam.Quantity",
          dividend: "na",
          priceType: "omnitureParam.PriceType",
          expiry: "na",
          serviceFee: "na",
          value: "orderReviewInfo.Amount",
          currency: "orderReviewInfo.PayFrom.Currency",
          account: "orderReviewInfo.Account.AccountTypeDesc",
          foreignExchange: "foreignExchange",
        },
      }
    }
  },
  "/txn/fixed-income/sell/gic/landing": {
    name: "SellGicLanding",
    service: "SellGicLandingService",
    servicePath: "txn/fixed-income/sell-gic/landing/services/sell-gic-landing",
    store: "SellGicLandingStore",
    resolveContent: "sellgiclanding",
    policy: {
      navFrom: {
        url: [
          "/txn/fixed-income/sell",
          "/txn/fixed-income/sell/gic/verify",
        ],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "sellGICInit",
        get request(): any {
          return {};
        },
      },
      {
        id: "settlement",
        restUrl: "getBuyGicSettlementAccounts",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,sell-gics",
      name: "details",
      nameGA: "GICs and Bonds - Details",
      formInfo: { name: "obr-sell-gics", stepName: "details" },
      events: { formStep: true, pageView: true, formView: true },
    },
    brazeID: "viewed_sell_gics_details"
  },
  "/txn/fixed-income/sell/gic/verify": {
    name: "SellGicVerify",
    service: "SellGicVerifyService",
    servicePath: "txn/fixed-income/sell-gic/verify/services/sell-gic-verify",
    store: "SellGicVerifyStore",
    resolveContent: "sellgicverify",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income/sell/gic/landing"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income/sell",
    },
    restService: [
      {
        id: "validate",
        restUrl: "sellGICValidate",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income, sell-gics",
      name: "verification",
      nameGA: "GICs and Bonds - Verification",
      formInfo: { name: "obr-sell-gics", stepName: "verification" },
      events: { formStep: true, pageView: true },
    },
    brazeID: "viewed_sell_gics_verify"
  },
  "/txn/fixed-income/sell/gic/confirm": {
    name: "SellGicConfirm",
    service: "SellGicConfirmService",
    servicePath: "txn/fixed-income/sell-gic/confirm/services/sell-gic-confirm",
    store: "SellGicConfirmStore",
    resolveContent: "sellgicconfirm",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income/sell/gic/verify"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income/sell",
    },
    restService: [
      {
        id: "submit",
        restUrl: "sellGICSubmit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,sell-gics",
      name: "confirmation",
      nameGA: "GICs and Bonds - Confirmation",
      formInfo: { name: "obr-sell-gics", stepName: "confirmation" },
      events: { formStep: true, formSubmit: true, pageView: true, tradeSubmit: true },
      transaction: {
        mainField: "inFlow",
        fieldNames: {
          action: "omnitureParam.Action",
          type: "omnitureParam.Type",
          symbol: "orderReviewInfo.Gic.IssuerDescription",
          market: "na",
          exchange: "na",
          quote: "orderReviewInfo.Amount",
          quantity: "omnitureParam.Quantity",
          dividend: "na",
          priceType: "omnitureParam.PriceType",
          expiry: "na",
          serviceFee: "na",
          value: "orderReviewInfo.Amount",
          currency: "orderReviewInfo.PayFrom.Currency",
          account: "orderReviewInfo.Account.AccountTypeDesc",
          foreignExchange: "foreignExchange",
        },
      },
    }
  },
  "/txn/fixed-income/sell": {
    name: "SellFixedIncome",
    service: "SellFixedIncomeService",
    servicePath:
      "txn/fixed-income/sell-fixed-income/services/sell-fixed-income",
    store: "SellFixedIncomeStore",
    resolveContent: "sellfixedincome",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "fixedIncomeHoldings",
        get request(): any {
          return {
            AccountList: true,
            SelectedTab: "T",
            PaginationOption: {
              Limit: 50,
              Offset: 0,
            },
            Refresh: true,
            ActionType: "SEL",
            Initialised: false
          };
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,sell-fixed-income",
      name: "view-holdings",
      nameGA: "GICs and Bonds - View Holdings",
      events: { pageView: true },
    },
    brazeID: "viewed_sell_gics_bonds_holdings"
  },
  "/txn/fixed-income/sell/bond/landing": {
    name: "SellBondLanding",
    service: "SellBondLandingService",
    servicePath:
      "txn/fixed-income/sell-bond/landing/services/sell-bond-landing",
    store: "SellBondLandingStore",
    resolveContent: "sellbondlanding",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "detail",
        restUrl: "buyBondsSubmit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,sell-bonds",
      name: "details",
      nameGA: "GICs and Bonds - Details",
      formInfo: { name: "obr-sell-bonds", stepName: "details" },
      events: { formStep: true, pageView: true, formView: true },
    },
    brazeID: "viewed_sell_bonds_details"
  },
  "/txn/fixed-income/sell/bond/verify": {
    name: "SellBondVerify",
    service: "SellBondVerifyService",
    servicePath: "txn/fixed-income/sell-bond/verify/services/sell-bond-verify",
    store: "SellBondVerifyStore",
    resolveContent: "sellbondverify",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income/sell/bond/landing"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income/sell",
    },
    restService: [
      {
        id: "verify",
        restUrl: "getBondsValidate",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,sell-bonds",
      name: "verification",
      nameGA: "GICs and Bonds - Verification",
      formInfo: { name: "obr-sell-bonds", stepName: "verification" },
      events: { formStep: true, pageView: true },
    }
  },
  "/txn/fixed-income/sell/bond/confirm": {
    name: "SellBondConfirm",
    service: "SellBondConfirmService",
    servicePath:
      "txn/fixed-income/sell-bond/confirm/services/sell-bond-confirm",
    store: "SellBondConfirmStore",
    resolveContent: "sellbondconfirm",
    policy: {
      navFrom: {
        url: ["/txn/fixed-income/sell/bond/verify"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/fixed-income/sell",
    },
    restService: [
      {
        id: "submit",
        restUrl: "getBondsSubmit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,fixed-income,sell-bonds",
      name: "confirmation",
      nameGA: "GICs and Bonds - Confirmation",
      formInfo: { name: "obr-sell-bonds", stepName: "confirmation" },
      events: { formStep: true, formSubmit: true, pageView: true, tradeSubmit: true },
      transaction: {
        mainField: "inFlow",
        fieldNames: {
          action: "omnitureParam.Action",
          type: "omnitureParam.Type",
          symbol: "orderReviewInfo.Bond.CusipId",
          market: "na",
          exchange: "na",
          quote: "omnitureParam.value",
          quantity: "omnitureParam.Quantity",
          dividend: "na",
          priceType: "omnitureParam.priceType",
          expiry: "na",
          serviceFee: "estimatedCost.EstimatedCommission",
          value: "omnitureParam.value",
          currency: "orderReviewInfo.PayTo.Currency",
          account: "orderReviewInfo.Account.AccountTypeDesc",
          foreignExchange: "foreignExchange",
        },
      },
    }
  },
};
