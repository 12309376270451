import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { of } from 'rxjs';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import {GlobalContentStore} from '../../../../shared/store/global-content-store';
import { Subscription } from 'rxjs';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { PersistenceService } from 'src/app/core/services/persistence.service';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss']
})

export class SidebarItemComponent implements OnInit{
  @Output() selectTab = new EventEmitter();
  @Input() item: any;
  hideChat = true;
  chatOpen;
  subscriptions = new Subscription();
  isApp: boolean;

  constructor(
      public globalContent: GlobalContentStore,
      private subscriptionService: ObservableSubscriptionService,
      private appStore: AppStore,
      private persistenceService: PersistenceService,
      public trackingService: TrackingService
) { }

  ngOnInit() {
    this.subscriptions.add(
      this.subscriptionService.chatVisibilityEvent$.subscribe((val) => {
        this.hideChat = !val;
      })
    );
    this.subscriptions.add(
      this.subscriptionService.chatStatusSource$.subscribe((val) => {
        this.chatOpen = val;
      })
    );
    this.isApp = this.appStore.isApp('app');
    let chatVisibilityFromSession = this.persistenceService.sessionStoreRetrieve('chatVisibility');
    this.hideChat = chatVisibilityFromSession == "true" ? false : true;
    // this.chatOpen = this.persistenceService.sessionStoreRetrieve('chatOpen');
    // if(this.chatOpen == 'true' && this.item.id == 'genesysChatBtn') {
    //   this.item.disabled = true;
    // }
  }

  // clickTab() {
  //   this.selectTab.emit(this.item);
  // }
  itemClick(item) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction('"accounts:feedback"', false, true, false, true));
    }
    this.selectTab.emit(this.item);
  }

  currentPage() {
    return this.appStore.state.state.currentUrl;
  }

  selectedLob() {
    return this.appStore.state.user.lob.toString();
  }

  ngOnDestroy() {
    this.persistenceService.sessionStorePersist('chatVisibility', this.hideChat);
    this.persistenceService.sessionStorePersist('chatOpen', this.chatOpen);
    this.subscriptions.unsubscribe();
  }
}
