import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { BuyGICOrderStore } from '../data/buy-gic-order-store';
import { Observable } from 'rxjs';
import { SettlementAccountsReq } from "../../../data/fixed-income-data";
import { UpdateReq } from "../data/buy-gic-order-data";

declare let require: any;
const gatewayConfig = require('../../../../../../config/gateway-config.json');


@Injectable({
  providedIn: 'root'
})
export class BuyGICOrderService implements PageService {


  constructor(private appStore: AppStore, private router: Router, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (BuyGICOrderStore.params) {
      let ser = input[0];
      ser.input = BuyGICOrderStore.params;
      return input;
    } else {
      this.router.navigate(['/txn/fixed-income/buy-gic/landing']);
    }
  }

  pruneInput(input: any): any {
    return input;
  }

  getSettlementAccounts = (data: any): Observable<any> => {
    const req: SettlementAccountsReq = {
      Action: data.Action,
      AccountNumber: data.AccountNumber,
      AccountCurrency: data.AccountCurrency,
      Type: data.Type
    };
    return this.httpService.post(gatewayConfig.APIServices.getBuyGicSettlementAccounts.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  updateReq = (data: UpdateReq): Observable<any> => {
    const req = data;
    return this.httpService.post(gatewayConfig.APIServices.buyGICInit.url,
      req,
    );
  }

}
