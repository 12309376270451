import { BaseData } from 'src/app/shared/models/base-data';

export class BuyStructNotesVerifyData extends BaseData {
  constructor() {
    super();
  }
}

export interface BuyStructNotesVerifyInputs {
  agreeProceed: boolean;
  savePassword: boolean;
  tradingPassword: string;
  fundFactsAgreementCheckIndicator: boolean;
}
