import { Injectable } from '@angular/core';
import { PageStore } from '../../../../shared/models/page-store';
import { NewUserData } from './new-user-data';

@Injectable({ providedIn: 'root' })
export class NewUserStore extends PageStore<NewUserData> {

  constructor() {
    super(new NewUserData());
  }

}
