import { BaseData } from '../../../../../shared/models/base-data';

export class RegularInvestmentPlanDetailsData extends BaseData {

}


export interface soOrderList {
    Amount?: number;
    OrderSymbol?: string;
    NextOrderDate: string;
    Action: string;
    FundName: string;
    Frequency: number;
    OrderStatus: string;
    StartDate: string;
    EndDate: string;
    SettlementAccountNumber: string;
    FiTypeCode: string
}

export interface ripDetailDataResp {
    AccountBalances: AccountBalances[];
    AccountNumber: String,
    AccountOwnerName: String,
    SoOrderList?: soOrderList[];
    Account?: Account;


}

export interface AccountBalances {
    AccountType: string;
    Currency: string;
    Amount: number
}

export interface Account {
    AccountType?: string;
    AccountNumber?: string;
    AccountFriendlyName?: string
    RegistrationInfo?: RegistrationInfo;
}

export interface RegistrationInfo {
    RegType?: string;
}

export enum FundType {
    CASH = 'C',
    MUTUAL = 'M'
}

export enum OrderStatus {
    ACTIVE = 'A',
    REJECTED = 'R',
    SUSPENDED = 'S',
    CANCELLED = 'C'
}


export const No_API_Data = {
    dash: '-',
    registered: 'REG',
    dollar: '$',
    type: 'brkrg',
    buy: 'Buy',
    achat: 'Achat'
}