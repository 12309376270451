import { BaseData } from '../models/base-data';
export class MarkitData extends BaseData {
  constructor() {
    super();
  }
}

export class markitPageActions {
  getArticle: (id: string) => Promise<string>;
  obtainToken: () => Promise<string | boolean>;
  navigateOBR: (id: string, params?: any) => void;
  openNewsModal: (content: string, articles: [string], index: number) => void;
  displayButtons: (symbolName: string, market: string) => void;
}