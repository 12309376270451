<aside>
    <button id="quick_options_toggleBtn" type="button" class='button quick-trade-style' [ngClass]="openQuickOption ? 'open' : 'close quick-trade-style'"
    (click)="Menu(true)" id="toggleQuickOptionBtn">
    <span class="icon-Bolt color01 icon-bolt-size"></span>
    <span aria-hidden="true"
      class="quicktrade-style-text">{{globalContent.text.quickTradeText1}}<br />{{globalContent.text.qucikOptionsText}}</span>
    <span class="sr-only">{{globalContent.text.quickTradeHiddenText}}</span>
  </button>
  <div id="quick_options_renderTrade" *ngIf="renderQuickTrade"
    [@sidebarTrigger]="openQuickOption ? isDesktop() ? 'open' : 'openmobile' : isDesktop() ? 'close' : 'closemobile'"
    class="sidebar-qt-options" [attr.aria-hidden]="!openQuickOption" role="dialog" aria-labelledby="quickTradeHeader">
    <div class="quickTradeHeader">
      <button *ngIf="showStep == 2" id="closeBtnBack" class="quickTradeHeaderBackButton icon-Chevron-Left color03"
        (click)="backClick()" attr.aria-label="{{globalContent.text.backToPreviousPage}}" type="button"></button>

      <div id="quick_options_header" *ngIf="showStep == 1 || showStep == 2" class="quickTradeHeaderText"
        [ngClass]="showStep == 2 ? 'centered' : ''">
        <!-- <span *ngIf="showStep == 1"><i class="icon-Bolt"></i></span> -->
        <h2 class="text-title" id="quickOptionsHeader">
          <span *ngIf="showStep == 1">{{globalContent.text.tradeOptions}}</span>
          <span *ngIf="showStep == 2">{{globalContent.text.quickTradeOrderReview}}</span>
        </h2>
      </div>

      <div *ngIf="showStep == 3" class="spacer"></div>
      <button id="closeBtn" *ngIf="openQuickOption && renderQuickTrade && isDesktop()" class="quickTradeHeaderCloseButton" (click)="closeDrawer()" type="button"
        attr.aria-label="{{globalContent.text.quickTradeClose}}">
        <i class="icon-Close"></i>
        <span class="sr-only">{{globalContent.text.quickTradeClose}}</span>
      </button>
      <!-- <button *ngIf="openQuickOption && renderQuickTrade && isDesktop()" id="closeBtn" type="button" (click)="closeDrawer()" class="closeButton-container" attr.aria-label="{{globalContent.text.quickTradeClose}}">
        <span class="icon-Arrow-Right"></span>
      </button> -->
    </div>
    <div id="quick_options_main" class="quickTradeMain">
      <app-quick-trade-options-landing *ngIf="showStep == 1"
        (dataLoaded)="dataLoaded($event)"></app-quick-trade-options-landing>
      <app-quick-trade-options-verify *ngIf="showStep == 2"
        (dataLoaded)="dataLoaded($event)"></app-quick-trade-options-verify>
      <app-quick-trade-options-confirm *ngIf="showStep == 3"
        (dataLoaded)="dataLoaded($event)"></app-quick-trade-options-confirm>
    </div>
  </div>
</aside>
<div [ngClass]="openQuickOption ? 'trade-drawer-mobile-background' : ''"></div>