import { Injectable } from '@angular/core';
import { RelateBrokerageAccountsConfirmData } from './relate-brokerage-accounts-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class RelateBrokerageAccountsConfirmStore extends PageStore<RelateBrokerageAccountsConfirmData> {

    constructor() {
        super(new RelateBrokerageAccountsConfirmData());
    }

}
