import { Injectable } from '@angular/core';
import { CashtransferLandingData } from './cashtransfer-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class CashtransferLandingStore extends PageStore<CashtransferLandingData> {

    constructor() {
        super(new CashtransferLandingData());
    }

}
