import { RouteGroup } from '../shared/models/route-config';

export const quotesAndWatchListsMarketsConfig: RouteGroup = {

    '/txn/quoteandwatchlistsmarkets': {
        name: 'quoteandwatchlistsmarkets',
        service: '',
        store: '',
        resolveContent: '',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: []
    }
};
