export enum Lob {
    IE = 'ie',
    IIS = 'iis',
    WG = 'wg',
    CPIC = 'cpic',
    CFPI = 'cfpi',
    GEN = 'gen'
}

export enum Lang {
    EN = 'en',
    FR = 'fr'
}
