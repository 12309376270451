import { BaseData } from 'src/app/shared/models/base-data';

export class TransactionHistoryData extends BaseData {

}

export class TxnHistInitRequest {
    Refresh: boolean;
    SearchOptions?: {
        StartRow?: number;
        NumberOfResult?: number;
    };
    SelectedAccount?: {
        AccountNumber: number;
        Subaccounts?: [{ AccountType: string, Currency: string }];
    };
    DateRange?: {
        RangeType: string;
    };
}

export class TxnHistTuneRequest {
    SortBy?: {
        SortByFieldName: string,
        SortByOrder?: string
    };
    FilterCriterion?: {
        FilterType: string,
        FilterValue?: number
    };
}
