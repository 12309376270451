import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { ContentStore } from 'src/app/shared/store/content-store';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { QuickTradeOptionsConfirmStore } from './data/quick-trade-options-confirm-store';
import { QuickTradeOptionsLandingStore } from '../landing/data/quick-trade-options-landing-store';
import { OrderStatusStore } from 'src/app/modules/txn/orderStatus/landing/data/order-status-store';
import moment from 'moment';
import { Lang } from 'src/app/shared/models/lob.enum';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TradeOptionsLandingStore } from 'src/app/modules/txn/tradeOptions/landing/data/trade-options-landing-store';
import { TradeOptionsConfirmStore } from 'src/app/modules/txn/tradeOptions/confirm/data/trade-options-confirm-store';
import { ContentService } from 'src/app/core/services/content.service';

@Component({
    selector: 'app-quick-trade-options-confirm',
    templateUrl: './quick-trade-options-confirm.component.html',
    styleUrls: ['./quick-trade-options-confirm.component.scss']
  })

  export class QuickTradeOptionsConfirmComponent implements OnInit {
    
    @Input() public mode: string = 'quickTrade';
    @Input() public data: any;
    @Output() orderStatus = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    @Output() dataLoaded = new EventEmitter<any>();

    storeArray = {
      "quickTrade": QuickTradeOptionsLandingStore,
      "smallTradeOptions": TradeOptionsLandingStore
    }
    confirmStoreArray = {
      "quickTrade": QuickTradeOptionsConfirmStore,
      "smallTradeOptions": TradeOptionsConfirmStore
    }

    orderReviewInfo: any;
    estimatedCost: any;
    orderConfirmInfo: any;
    formError: any = {};
    hidePage = false;
    hasError = false;
    subscriptions = new Subscription();
    quickTradeContent = this.content;
  
    constructor(
      private router: Router,
      public store: QuickTradeOptionsConfirmStore,
      public appStore: AppStore,
      public content: ContentStore,
      public globalContent: GlobalContentStore,
      private subscriptionService: ObservableSubscriptionService,
    private commonService: CommonService,
    private renderer: Renderer2,
    private contentService: ContentService
    ) {
  
    }

    ngOnDestroy(): void {
      this.clearValues(1);
      this.subscriptions.unsubscribe();
      this.subscriptionService.setQuickOptionsPosition(1);
    }
  
    ngOnInit() {
      if (this.confirmStoreArray[this.mode].inFlow?.Data != undefined || this.confirmStoreArray[this.mode].inFlow?.Data != null) {
        this.data = this.confirmStoreArray[this.mode].inFlow.Data;
      }
      this.subscriptions.add(this.contentService.fetchContent('quicktradeoptionsconfirmation').subscribe((data) => {
        this.quickTradeContent = data;
      }));
      if(this.mode == 'quickTrade'){
      if(this.data) {
        if ((this.data === undefined) || this.data.length === 0) {
          this.orderReviewInfo = this.confirmStoreArray[this.mode].inFlow.orderReviewInfo;
          this.orderConfirmInfo = this.confirmStoreArray[this.mode].inFlow.orderData;
          this.estimatedCost = this.confirmStoreArray[this.mode].inFlow.estimatedCost
        } else {
          this.orderReviewInfo = this.confirmStoreArray[this.mode]?.inFlow?.orderReviewInfo;
          this.estimatedCost = this.confirmStoreArray[this.mode]?.inFlow?.estimatedCost;
          this.orderConfirmInfo = this.data[0];
    
          this.confirmStoreArray[this.mode].inFlow.orderConfirmInfo = this.orderConfirmInfo;
          this.confirmStoreArray[this.mode].params = undefined;
        }
        this.storeArray[this.mode].crossFlow = undefined;
        this.storeArray[this.mode].savedInfo = undefined;
        this.dataLoaded.emit(true);
      }else {
        this.clearValues(1);
      }
    } else {
      this.orderReviewInfo = this.data.orderReviewInfo;
      this.orderConfirmInfo = this.data.orderData;
      this.estimatedCost = this.data.estimatedCost;
    }
      this.subscriptions.add(this.subscriptionService.quickOptionsOpenOrCloseDrawer.subscribe((data) => {
        if (data) {
          this.newOrder();
        }
      }));
    }

    clearValues(pos?) {
      this.commonService.clearQuickTradeOptionsSession();
      if (pos) {
        this.subscriptionService.setQuickOptionsPosition(pos);
        if (pos == 1 && this.mode == 'quickTrade') {
          this.commonService.logBraze("viewed_trade_options_details", { platform: "quickTrade"} );
        }
      }
    }
  
    next() {
      if (this.mode == 'quickTrade') {
        //if order status is the current route
        if (this.router.url === '/txn/orderStatus') {
          this.subscriptionService.refreshOrder.next(this.orderReviewInfo.Account);
        }
        this.moveChatIcon();
        this.subscriptionService.quickOptionsOpenOrCloseDrawer.next();
        this.clearValues(1);
        OrderStatusStore.crossFlow = { FiTypeCode: 'O' };
        this.router.navigate(['/txn/orderStatus']);
      } else {
        this.orderStatus.emit();
      }
    }
  
    newOrder() {
      this.confirmStoreArray[this.mode].inFlow = undefined;
      if (this.mode == 'quickTrade') {
        this.clearValues(1);
      } else {
        this.close.emit();
      }
    }
  
    getOmnitureValue(action) {
      let value;
      switch (action) {
        case 'BUYTOOPEN':
          value = 'Buy To Open';
          break;
        case 'BUYTOCLOSE':
          value = 'Buy To Close';
          break;
        case 'SELLTOOPEN':
          value = 'Sell To Open';
          break;
        case 'SELLTOCLOSE':
          value = 'Sell To Close';
          break;
      }
      return value;
    }
  
    getActivity(action) {
      let value;
      switch (action) {
        case 'BUYTOOPEN':
          value = this.content.text.btOpen;
          break;
        case 'BUYTOCLOSE':
          value = this.content.text.btClose;
          break;
        case 'SELLTOOPEN':
          value = this.content.text.stOpen;
          break;
        case 'SELLTOCLOSE':
          value = this.content.text.stClose;
          break;
      }
      return value;
    }
  

    getDateFormat(date, formaten, formatfr) {
      const momentLang = moment(date).locale(this.appStore.lang);
      if (this.appStore.lang === Lang.EN) {
        return momentLang.format(formaten);
      } else {
        return momentLang.format(formatfr);
      }
    }

    closeDrawer() {
      this.moveChatIcon();
      this.subscriptionService.quickOptionsOpenOrCloseDrawer.next();
    }
  
    formatOptionType(type) {
      if (type === 'C') {
        return this.quickTradeContent.text.call;
      } else if (type === 'P') {
        return this.quickTradeContent.text.put;
      }
    }

    // move chat icon to right
  moveChatIcon() {
    // let chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.removeClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.removeClass(kampyleButton, 'move-kampyle');
    // }
  }

    setFocus(id) {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.focus();
        }
      }, 200);
    }
  
    isDesktop() {
      return false;
    }
  
    getFlag(flagName): string {
      if (flagName === undefined) {
        return null;
      } else {
        let response = '';
  
        switch (flagName.toLowerCase()) {
          case 'ca':
            response = './assets/images/icons/icon-CDN-flag.svg';
            break;
          case 'cdn':
            response = './assets/images/icons/icon-CDN-flag.svg';
            break;
          case 'usd':
            response = './assets/images/icons/icon-USD-flag.svg';
            break;
          case 'us':
            response = './assets/images/icons/icon-USD-flag.svg';
            break;
        }
  
        return response;
      }
    }
  }