import { BaseData } from 'src/app/shared/models/base-data';

export class OpraAgreementData extends BaseData {
    constructor() {
        super();
        this.input = {
            AgreementName: undefined,
            UserData: {
                AcceptNotifyVendor: undefined,
                AcceptAgreement: undefined,
                Subscriber: {
                    Name: '',
                    Address: ''
                },
                Pro: undefined
            }
        };
    }
}
export interface OpraAgreementInputs {
    AgreementName: string;
    UserData: UserData;
}

export interface UserData {
    Subscriber: Subscriber;
    NaturalPerson?: boolean;
    AcceptNotifyVendor: boolean;
    PersonalUse?: boolean;
    AcceptAgreement: boolean;
    AcceptNotPro?: boolean;
    Pro?: boolean;
}
export interface Subscriber {
    Name: string;
    Address: string;
}
