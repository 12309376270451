import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { CommonService } from 'src/app/shared/services/common.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class OtvcSecurityService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService, private commonService: CommonService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let ret = [input[0]];
    return ret;
  }

  verifyOtvcRequest(req) {
    return this.httpService.post(gatewayConfig.APIServices.userPrefOTVCValidate.url,
      req
    );
  }

  verifyOtvcRequestSignOn(req) {
    return this.httpService.post(gatewayConfig.APIServices.userPrefOTVCFValidate.url,
      req
    );
  }

  ConfirmOtvc() {
    return this.httpService.post(gatewayConfig.APIServices.userPrefOTVCInit.url,
      {}
    );
  }

  skip(req) {
    return this.httpService.post(gatewayConfig.APIServices.userPrefOTVCFSkip.url,
      req
    );
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

}
