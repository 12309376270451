import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeDiff',
    pure: true
  })

export class TimeDifferencePipe implements PipeTransform {

    constructor(private datePipe: DatePipe) {}
    transform(time: any): any {
      const date1 = new Date();
      const date2 = new Date(time);
      var difference = date1.getTime() - date2.getTime();
      if (difference) {
        var daysDifference = Math.floor(difference/1000/60/60/24);
        difference -= daysDifference*1000*60*60*24;
        if (daysDifference >= 1) {
          return daysDifference + " day ago";
        }
        var hoursDifference = Math.floor(difference/1000/60/60);
        difference -= hoursDifference*1000*60*60;
        if (hoursDifference > 0) {
          return hoursDifference + " hour ago";
        }
        var minutesDifference = Math.floor(difference/1000/60);
        difference -= minutesDifference*1000*60;
        if (minutesDifference > 0) {
          return minutesDifference + " minute ago";
        }
        var secondsDifference = Math.floor(difference/1000);
        if (secondsDifference > 0) {
          return secondsDifference + " second ago";
        }
      } else {
        return this.datePipe.transform(time, 'MMM d, y, h:mm a');
      }
    }
   }
