import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { ChangeMutualFundVerifyData } from './change-mutual-fund-verify-data';

@Injectable({ providedIn: 'root' })
export class ChangeMutualFundVerifyStore extends PageStore<ChangeMutualFundVerifyData>  {

    constructor() {
        super(new ChangeMutualFundVerifyData());
    }

}
