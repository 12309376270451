import { Injectable } from '@angular/core';
import { RemoveBrokerageAccountsVerifyData } from './remove-brokerage-accounts-verify.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class RemoveBrokerageAccountsVerifyStore extends PageStore<RemoveBrokerageAccountsVerifyData> {

    constructor() {
        super(new RemoveBrokerageAccountsVerifyData());
    }

}
