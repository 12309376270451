import { BaseData } from 'src/app/shared/models/base-data';

export class IposAndNewIssuesData extends BaseData {
    constructor() {
        super();
        this.input = {

        }
    }
}

export interface ipoResponse {
    searchResults: SearchResults;
}

export interface SearchResults {
    documents: Documents[];
}

export interface Documents {
    IpoId: string;
    IpoType: string;
    Issuer: string;
    Lang: string;
    PostedDate: string;
    Status: string;
}

export interface DetailData {
    issueDetais: IssueDetails;
}

export interface IssueDetails {
    IpoId: string;
    IpoType: string;
    Issuer: string;
    PostedDate: string;
    PriceRange: { Data: string; Content: string; }
    Rating: string;
    RrspEligible: boolean;
    SettlementDate: string;
    Status: string;
}

export interface IposListRequest {
    SearchCriteria: Search;
}

export interface Search {
    FilterBy?: Filter[];
    GroupBy?: Group[];
    OrderBy?: Group[];
}

export interface Filter {
    Column: string,
    Value: any
}
export interface Group {
    Column: string;
    Ordering: string;
}

export enum ordering {
    Ascending = 'A',
    Descending = 'D'
}