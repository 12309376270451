import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { HttpService } from 'src/app/core/services/http.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { AccountHoldingsReq, BuyingPowerReq, ChainReq, QuickTradeOptionsLandingInputs, QuoteReq, SettlementAccountsReq } from '../data/quick-trade-options-landing-data';
import { QuickTradeOptionsLandingStore } from '../data/quick-trade-options-landing-store';
import { TradeOptionsLandingStore } from 'src/app/modules/txn/tradeOptions/landing/data/trade-options-landing-store';


declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
    providedIn: 'root'
  })
export class QuickTradeOptionsLandingService{
  public actionType : string;
  constructor(private appStore: AppStore, private httpService: HttpService) { }
  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ret = [input[0]];
      return ret;
  }

  getAccountHoldingsLoad (){
    return this.httpService.post(gatewayConfig.APIServices.holdingsNewLoad.url, {})
  }
  
  getSettlementAccounts(data) {
    let req: SettlementAccountsReq = new SettlementAccountsReq();
    req.Action = data.Action;
    req.AccountNumber = data.AccountNumber;
    req.AccountCurrency = data.AccountCurrency;
    req.Type = "OPTIONS";
    return this.httpService.post(gatewayConfig.APIServices.optionsSettlementAccounts.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getAccountHoldings(data) {
    let req: AccountHoldingsReq = new AccountHoldingsReq();
    req.Action = data.Action;
    req.AccountNumber = data.AccountNumber;
    req.Clean = data.Clean;
    req.Refresh = data.Refresh;
    req.SearchOptions = data.SearchOptions;
    req.FiType = "O";
    return this.httpService.post(gatewayConfig.APIServices.getOrderHoldings.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getBuyingPower(data) {
    let req: BuyingPowerReq = new BuyingPowerReq();
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.buyingPowerRetrieve.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getChain(data) {
    let req: ChainReq = new ChainReq();
    req = data;
    return this.httpService.post(gatewayConfig.APIServices.optionsChainDetail.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  isAccountOptionEnabledOrNot (accNum) {
    const reqAcc = { AccountNumber : accNum }
    return this.httpService.post(gatewayConfig.APIServices.accOptionEnabled.url, reqAcc)
  }

  getQuote(data) {
    let req: QuoteReq = new QuoteReq();
    req.Symbols = [
      data
    ];
    return this.httpService.post(gatewayConfig.APIServices.quoteQuick.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getBalanceReq(data) {
    return this.httpService.post(gatewayConfig.APIServices.getAccountBalance.url, data ,
      { params: { skiploading: 'true' } });
  }
}