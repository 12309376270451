import { FormValidatorFactory } from '../../../../shared/services/cibc.formvalidator';
import { isDefined} from '../../../services/utils.service';
import { FormControl, FormGroup } from '@angular/forms';

export class QuickTradeStockValidator {
  static createForm(form, fb) {
    const grp = {
      accountNumber: [],
      actionMode: [''],
      marketMode: [''],
      stockHoldingIndex: [''],
      symbol: [''],
      quantity: [],
      priceMode: [''],
      expiryMode: [''],
      limitInput: [''],
      stopPrice: [''],
      stopLimitPrice: [''],
      settlementIndex: [''],
      triggerDollarPrice: [''],
      triggerPercentagePrice: [''],
      limitOffsetDollar: ['']
    };
    form = fb.group(grp);

    return form;
  }

  static emptyValidators(form, trailingMode) {
    form.controls['accountNumber'].setValidators([]);
    form.controls['actionMode'].setValidators([]);
    form.controls['marketMode'].setValidators([]);
    form.controls['stockHoldingIndex'].setValidators([]);
    form.controls['symbol'].setValidators([]);
    form.controls['quantity'].setValidators([]);
    form.controls['priceMode'].setValidators([]);
    form.controls['expiryMode'].setValidators([]);
    if (form.value.priceMode == 'limitMode') {
      form.controls['limitInput'].setValidators([]);
    }
    if (form.value.priceMode == 'stopMode') {
      form.controls['stopPrice'].setValidators([]);
      form.controls['stopLimitPrice'].setValidators([]);
    }

    // Trigger Delta Dollar
    if (form.value.priceMode === 'trailingStopMode' && trailingMode == 'dollar') {
      form.controls['triggerDollarPrice'].setValidators([]);
      form.controls['limitOffsetDollar'].setValidators([]);
    } else if (form.value.priceMode == 'trailingStopPercentMode' && trailingMode == 'percent') {
      form.controls['triggerPercentagePrice'].setValidators([]);
      form.controls['limitOffsetDollar'].setValidators([]);
    }

  }

  static setValidators(form, modalObject, french, trailingMode) {

    form.controls['accountNumber'].setValidators([FormValidatorFactory.createGreaterThanValidator('MSGEQT0001', -1)]);
    form.controls['actionMode'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGEQT0002')]);
    form.controls['marketMode'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGEQT0003')]);
    if (form.value.actionMode != '' && form.value.actionMode != 'BUY' && form.value.actionMode != 'SHORT' && modalObject.chooseSymbol) {
      form.controls['stockHoldingIndex'].setValidators([FormValidatorFactory.createGreaterThanValidator('MSGEQT0046', -1)]);
    } else {
      form.controls['stockHoldingIndex'].setValidators([]);
    }
    form.controls['symbol'].setValidators([
      FormValidatorFactory.createBoundedLengthValidator('MSGEQT0030', 1, 12),
      FormValidatorFactory.createNotBlankValidator('MSGEQT0004')
    ]);
    form.controls['quantity'].setValidators(
      [
        FormValidatorFactory.createNotBlankValidator('MSGEQT0073'),
        FormValidatorFactory.createIsNumberValidator('MSGEQT0073', french),
        FormValidatorFactory.createBoundedLengthValidator('MSGEQT0073', 1, 7),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0073', 0),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0073', 0)
      ]);
    form.controls['priceMode'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGEQT0009')]);
    form.controls['expiryMode'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGEQT0020')]);
    if (form.value.priceMode == 'limitMode') {
      form.controls['limitInput'].setValidators([
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0010', 0),
        FormValidatorFactory.createIsNumberValidator('MSGEQT0013', french),
        FormValidatorFactory.createBoundedLengthValidator('MSGEQT0013', 1, 10),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0018', 4),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0013')
      ]);
    } else {
      // form.controls['limitInput'].setValidators([]);
    }
    if (form.value.priceMode == 'stopMode') {
      form.controls['stopPrice'].setValidators([
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0015', 0),
        FormValidatorFactory.createIsNumberValidator('MSGEQT0017', french),
        FormValidatorFactory.createBoundedLengthValidator('MSGEQT0017', 1, 10),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0018', 4),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0017')
      ]);
      if (form.value.actionMode == 'BUY') {
        form.controls['stopLimitPrice'].setValidators([
          FormValidatorFactory.createGreaterThanValidator('MSGEQT0010', 0),
          FormValidatorFactory.createIsNumberValidator('MSGEQT0013', french),
          FormValidatorFactory.createBoundedLengthValidator('MSGEQT0013', 1, 10),
          FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0018', 4),
          FormValidatorFactory.createNotBlankValidator('MSGEQT0013'),
          FormValidatorFactory.createGreaterThanOrEqualToValidator('MSGEQT0054',
            parseFloat(isDefined(form.value.stopPrice) ? form.value.stopPrice : 0))
        ]);
      } else if (form.value.actionMode == 'SELL') {
        form.controls['stopLimitPrice'].setValidators([
          FormValidatorFactory.createGreaterThanValidator('MSGEQT0010', 0),
          FormValidatorFactory.createIsNumberValidator('MSGEQT0013', french),
          FormValidatorFactory.createBoundedLengthValidator('MSGEQT0013', 1, 10),
          FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0018', 4),
          FormValidatorFactory.createNotBlankValidator('MSGEQT0013'),
          FormValidatorFactory.createLessThanOrEqualToValidator('MSGEQT0057',
            parseFloat(isDefined(form.value.stopPrice) ? form.value.stopPrice : 0))
        ]);
      } else {
        form.controls['stopLimitPrice'].setValidators([
          FormValidatorFactory.createGreaterThanValidator('MSGEQT0010', 0),
          FormValidatorFactory.createIsNumberValidator('MSGEQT0013', french),
          FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0018', 4),
          FormValidatorFactory.createNotBlankValidator('MSGEQT0013')
        ]);
      }
    }
    else {
      // form.controls['stopPrice'].setValidators([]);
      // form.controls['stopLimitPrice'].setValidators([]);
    }

    // Trigger Delta Dollar
    if (form.value.priceMode === 'trailingStopMode' && trailingMode == 'dollar') {
      form.controls['triggerDollarPrice'].setErrors(null);
      form.controls['limitOffsetDollar'].setErrors(null);
      form.controls['triggerDollarPrice'].setValidators([
        FormValidatorFactory.createIsNumberValidator('MSGEQT0092', french),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0092', 4),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0089', 0),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0091')
      ]);

      form.controls['limitOffsetDollar'].setValidators([
        FormValidatorFactory.createIsNumberValidator('MSGEQT0088', french),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0088', 4),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0089', 0),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0087')
      ]);
      // If Trigger Dollar value is valid check for estimated Trigger delta value whether it is positive
      form.controls['triggerDollarPrice'].updateValueAndValidity();
      form.controls['limitOffsetDollar'].updateValueAndValidity();
      if (form.controls['triggerDollarPrice'].valid) {
        console.log('Trigger Price is Valid');
        form.controls['triggerDollarPrice'].setValidators([
          FormValidatorFactory.isPositive('MSGEQT0100', 0, modalObject.estimatedTriggerPrice),
        ]);
      }
      if (form.controls['triggerDollarPrice'].valid && form.controls['limitOffsetDollar'].valid) {
        console.log('Trigger Price  & limit off set both are  Valid');
        form.controls['limitOffsetDollar'].setValidators([
          FormValidatorFactory.isPositive('MSGEQT0101', 0, modalObject.estimatedLimitPrice),
        ]);
      }
    } else if (form.value.priceMode == 'trailingStopPercentMode' && trailingMode == 'percent') {
      form.controls['triggerPercentagePrice'].setErrors(null);
      form.controls['limitOffsetDollar'].setErrors(null);
      form.controls['triggerPercentagePrice'].setValidators([
        FormValidatorFactory.createIsNumberValidator('MSGEQT0092', french),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0092', 4),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0089', 0),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0091')
      ]);

      form.controls['limitOffsetDollar'].setValidators([
        FormValidatorFactory.createIsNumberValidator('MSGEQT0088', french),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0088', 4),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0089', 0),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0087')
      ]);
      // Estimated trigger price and estimated limit off set validation
      form.controls['triggerPercentagePrice'].updateValueAndValidity();
      form.controls['limitOffsetDollar'].updateValueAndValidity();
      // Add below additonal validation only when price is valid.
      if (form.controls['triggerPercentagePrice'].valid) {
        console.log('Trigger Price is Valid');
        form.controls['triggerPercentagePrice'].setValidators([
          FormValidatorFactory.isPositive('MSGEQT0100', 0, modalObject.estimatedTriggerPrice),
        ]);
      }
      if (form.controls['triggerPercentagePrice'].valid && form.controls['limitOffsetDollar'].valid) {
        console.log('Trigger Price  & limit off set both are  Valid');
        form.controls['limitOffsetDollar'].setValidators([
          FormValidatorFactory.isPositive('MSGEQT0101', 0, modalObject.estimatedLimitPrice),
        ]);
      }
    } else {
      // form.controls['triggerPercentagePrice'].setValidators([]);
      // form.controls['limitOffsetDollar'].setValidators([]);
    }

  }

  static validate(form) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validate(control);
      }
    });
  }

  static setValue(form, trailingMode) {
    let val: any;
    val = {
      accountNumber: form.value.accountNumber,
      actionMode: (form.value.actionMode === undefined) ? '' : form.value.actionMode,
      marketMode: (form.value.marketMode === undefined) ? '' : form.value.marketMode,
      stockHoldingIndex: (form.value.stockHoldingIndex === undefined) ? '' : form.value.stockHoldingIndex,
      symbol: (form.value.symbol === undefined) ? '' : form.value.symbol,
      quantity: (form.value.quantity === undefined) ? '' : form.value.quantity,
      priceMode: (form.value.priceMode === undefined) ? '' : form.value.priceMode,
      expiryMode: (form.value.expiryMode === undefined) ? '' : form.value.expiryMode,
      settlementIndex: form.value.settlementIndex
    };
    if (form.value.priceMode == 'limitMode') {
      val.limitInput = (form.value.limitInput === undefined) ? '' : form.value.limitInput;
    }
    if (form.value.priceMode == 'stopMode') {
      val.stopPrice = (form.value.stopPrice === undefined) ? '' : form.value.stopPrice;
      val.stopLimitPrice = (form.value.stopLimitPrice === undefined) ? '' : form.value.stopLimitPrice;
    }
    if (form.value.priceMode == 'trailingStopMode' && trailingMode == 'dollar') {
      val.triggerDollarPrice = (form.value.triggerDollarPrice === undefined) ? '' : form.value.triggerDollarPrice;
      val.limitOffsetDollar = (form.value.limitOffsetDollar === undefined) ? '' : form.value.limitOffsetDollar;
    }
    if (form.value.priceMode == 'trailingStopPercentMode' && trailingMode == 'percent') {
      val.triggerDollarPrice = (form.value.triggerDollarPrice === undefined) ? '' : form.value.triggerDollarPrice;
      val.triggerPercentagePrice = (form.value.triggerPercentagePrice === undefined) ? '' : form.value.triggerPercentagePrice;
    }
    form.setValue(val);
  }


  static setLimitOffSetValidators(form, priceMode, french) {
    // Trigger Delta Dollar
    if (priceMode === 'dollar') {
      form.controls['limitOffsetDollar'].setValidators([
        FormValidatorFactory.createIsNumberValidator('MSGEQT0088', french),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0088', 4),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0089', 0),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0087')

      ]);
    } else if (priceMode === 'percent') {
      form.controls['limitOffsetDollar'].setValidators([
        FormValidatorFactory.createIsNumberValidator('MSGEQT0088', french),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0088', 4),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0089', 0),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0087')
      ]);
    }
  }

  static setTrailingStopValidators(form, priceMode, french) {
    // Trigger Delta Dollar
    if (priceMode === 'dollar') {
      form.controls['triggerDollarPrice'].setValidators([
        FormValidatorFactory.createIsNumberValidator('MSGEQT0092', french),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0092', 4),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0089', 0),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0091')

      ]);
    }
    if (priceMode === 'percent') {
      form.controls['triggerPercentagePrice'].setValidators([
        FormValidatorFactory.createIsNumberValidator('MSGEQT0092', french),
        FormValidatorFactory.createNumAllowedDecimalValidator('MSGEQT0092', 4),
        FormValidatorFactory.createGreaterThanValidator('MSGEQT0089', 0),
        FormValidatorFactory.createNotBlankValidator('MSGEQT0091')

      ]);
    }
  }

  static resolveValidationError(form, errorContent) {
    const messges = [];
    Object.keys(form.controls).forEach(key => {
      messges.push(
        {
          controlName: key,
          code: form.controls[key].errors
        });
    });
    return this.setErrorMessage(messges, errorContent);
  }

  static setErrorMessage(results, errorContent) {
    const error = {};
    results.forEach(result => {
      if (result.code !== null) {
        if (isDefined(result.code.errorCode)) {
          error[result.controlName] = errorContent[result.code.errorCode];
        }
        console.log(JSON.stringify(result));
      }
    });
    return error;
  }

  static processRestFormErrors(formError) {
    const error = {};
    switch (formError.error.Category) {
      case 'accountNumber':
        error['accountNumber'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'actionMode':
        error['actionMode'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'marketMode':
        error['marketMode'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'stockHoldingIndex':
        error['stockHoldingIndex'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'symbol':
        error['symbol'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'quantity':
        error['quantity'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'priceMode':
        error['priceMode'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'expiryMode':
        error['expiryMode'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'limitInput':
        error['limitInput'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'stopPrice':
        error['stopPrice'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'stopLimitPrice':
        error['stopLimitPrice'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'triggerDollarPrice':
        error['triggerDollarPrice'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'triggerPercentagePrice':
        error['triggerPercentagePrice'] = formError.error.Errors[0].ErrorMessage;
        break;
      case 'limitOffsetDollar':
        error['limitOffsetDollar'] = formError.error.Errors[0].ErrorMessage;
        break;
    }
    return error;
  }

  static resetErrorMessage(form, fb, errorContent) {
    console.log('RESETTING THE FORM');
    const messges = [];
    const error = {};
    // Go Through each element and set form element error as undefined
    Object.keys(form.controls).forEach(key => {
      messges.push(
        {
          controlName: key,
          code: form.controls[key].errors
        });
      error[key] = undefined;
      console.log('ControlName' + key);
    });
    return error;
  }
}
