import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ForceUpgradeComponent } from './force-upgrade.component';
import { StateTransitionGuard } from 'src/app/core/guard/state-transition.guard';
import { BuildInfoComponent } from '../../shared/components/build-info/build-info.component';
import { PreTxnGuard } from './../../core/guard/pre-txn.guard';


const routes: Routes = [
  {
    path: 'forceupgrade', component: ForceUpgradeComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ForceUpgradeRoutingModule { }
