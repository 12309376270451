import { Injectable } from '@angular/core';
import { BuyStructNotesEntryData } from './buy-struct-notes-entry-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class BuyStructNotesEntryStore extends PageStore<BuyStructNotesEntryData> {

  constructor() {
    super(new BuyStructNotesEntryData());
  }
}
