import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, Inject, TemplateRef, Injector, ComponentRef, OnDestroy, ViewContainerRef } from '@angular/core';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { Moment } from 'moment';
import moment from 'moment';
import { DateSelectorService } from './date-selector.service';
import { DOCUMENT } from '@angular/common';
import { DateOverlayComponent } from './date-overlay.component';
import { isDefined } from '../../services/utils.service';
import { Lang } from '../../models/lob.enum';
import { AppStore } from '../../models/app-store';
import { GlobalContentStore } from '../../store/global-content-store';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('datePicker', { static: true })
  datePicker: DatePickerComponent;

  @ViewChild('overlay', { static: true }) overlay: TemplateRef<any>;


  @Input() public id;
  @Input() public openIconName;
  @Input() public targetEle;
  @Input() public hideCloseBtn: boolean = false;
  @Input() public pageName: any;
  @Input() public currentWeek: any;
  @Input() public calendarBtnLabel: any;

  selectedWeek: any;

  public showTopTooptip = false;
  public mode = 'popup';
  isKeyPressed = false;
  public tooltipClass;
  public isDatePicker = false;
  public invalidDate = undefined;
  private component: any;
  public isCalendarOpen = false;
  public currentSelectedDateHdnTxt: any;

  @Input() public dateParams: any;
  @Input() public transactionDateParams: any;
  @Input() public content: any;
  @Input() public currentDate: any;

  @Input() public boundingContainer;

  @Output() closeModal = new EventEmitter();

  @Output()
  dateSelectedByUser: EventEmitter<Moment> = new EventEmitter();

  min: any;
  max: any;
  startDate: any;
  selectedDate = moment();
  showError = false;
  transactionMin: Date;
  transactionMax: Date;

  constructor(
    private _service: DateSelectorService,
    private _injector: Injector,
    private viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private _document: Document,
    private _appStore: AppStore,
    private globalContentStore: GlobalContentStore
  ) { }

  keyCodes = () => {
    return {
      enter: 13,
      space: 32,
      left: 37,
      up: 38,
      right: 39,
      down: 40,
      esc: 27,
      tab: 9
    };
  }

  ngOnInit() {
    this.setIndividualClass();
  }

  ngOnDestroy() {
    const ele = this._document.getElementById('page-container');
    if (ele.querySelector("#date-overlay") != null) {
      this._document.getElementById('page-container').removeChild(this.component.location.nativeElement);
    }
  }

  ngOnChanges() {
    this.isDatePicker = false;
    if (this.dateParams) {
      this.min = new Date(moment(this.dateParams.MinGTDate).format('YYYY-MM-DD').replace(/-/g, '\/'));
      this.max = new Date(moment(this.dateParams.MaxGTDate).format('YYYY-MM-DD').replace(/-/g, '\/'));
      this.startDate = new Date(moment(this.dateParams.DefaultGTDate).format('YYYY-MM-DD').replace(/-/g, '\/'));
      this.selectedDate = moment(this.startDate);
    }
    if (this.transactionDateParams) {
      this.transactionMin = this.transactionDateParams.MinGTDate;
      this.transactionMax = this.transactionDateParams.MaxGTDate;
    }
    if (this.currentWeek){
      this.selectedWeek = this.currentWeek;
      
      if (this._appStore.lang === Lang.EN){
        if (this.startDate && this.selectedWeek[0] <= this.startDate && this.startDate <= this.selectedWeek[6]) {
          this.currentSelectedDateHdnTxt =  " " + this.startDate.toLocaleString(this._appStore.lang + '-CA', { month: 'long' }) + ' ' + this.startDate.getDate() + ", " + this.startDate.getFullYear() + this.content?.text?.selected;
        } else if (this.currentWeek[0].getFullYear() === this.currentWeek[6].getFullYear() && 
                   this.currentWeek[0].getMonth() === this.currentWeek[6].getMonth()) {
          this.currentSelectedDateHdnTxt = this.content?.text?.from + this.currentWeek[0].toLocaleString(this._appStore.lang + '-CA', { month: 'long' }) + ' ' + this.currentWeek[0].getDate() 
                                         + this.content?.text?.to + this.currentWeek[6].getDate() + ", " + this.currentWeek[0].getFullYear() + this.content?.text?.selected;
        } else if (this.currentWeek[0].getFullYear() === this.currentWeek[6].getFullYear() && 
                   this.currentWeek[0].getMonth() !== this.currentWeek[6].getMonth()) {
          this.currentSelectedDateHdnTxt = this.content?.text?.from + this.currentWeek[0].toLocaleString(this._appStore.lang + '-CA', { month: 'long' }) + ' ' + this.currentWeek[0].getDate() 
                                         + this.content?.text?.to + this.currentWeek[6].toLocaleString(this._appStore.lang + '-CA', { month: 'long' }) + ' ' + this.currentWeek[6].getDate() + ", " + this.currentWeek[0].getFullYear() + this.content?.text?.selected;
        } else {
          this.currentSelectedDateHdnTxt = this.content?.text?.from + this.currentWeek[0].toLocaleString(this._appStore.lang + '-CA', { month: 'long' }) + ' ' + this.currentWeek[0].getDate() + ' ' + this.currentWeek[0].getFullYear() 
                                         + this.content?.text?.to + this.currentWeek[6].toLocaleString(this._appStore.lang + '-CA', { month: 'long' }) + ' ' + this.currentWeek[6].getDate() + ", " + this.currentWeek[6].getFullYear() + this.content?.text?.selected;
        }
      } else {
        if (this.startDate && this.selectedWeek[0] <= this.startDate && this.startDate <= this.selectedWeek[6]) {
          this.currentSelectedDateHdnTxt = this.content?.text?.date + this.startDate.getDate() + ' ' + this.globalContentStore.capitalizeString(this.startDate.toLocaleString(this._appStore.lang + '-CA', { month: 'long' })) + ', ' + this.startDate.getFullYear() + this.content?.text?.selected;
        } else if (this.currentWeek[0].getFullYear() === this.currentWeek[6].getFullYear() && 
                   this.currentWeek[0].getMonth() === this.currentWeek[6].getMonth()) {
          this.currentSelectedDateHdnTxt = this.content?.text?.from + this.currentWeek[0].getDate() 
                                         + this.content?.text?.to + this.currentWeek[6].getDate() + " " + this.globalContentStore.capitalizeString(this.currentWeek[0].toLocaleString(this._appStore.lang + '-CA', { month: 'long' })) + " " + this.currentWeek[0].getFullYear() + this.content?.text?.selected;
        } else if (this.currentWeek[0].getFullYear() === this.currentWeek[6].getFullYear() && 
                   this.currentWeek[0].getMonth() !== this.currentWeek[6].getMonth()) {
          this.currentSelectedDateHdnTxt = this.content?.text?.from + this.currentWeek[0].getDate() + ' ' + this.globalContentStore.capitalizeString(this.currentWeek[0].toLocaleString(this._appStore.lang + '-CA', { month: 'long' })) + " " 
                                         + this.content?.text?.to + this.currentWeek[6].getDate() + ' ' + this.globalContentStore.capitalizeString(this.currentWeek[6].toLocaleString(this._appStore.lang + '-CA', { month: 'long' })) + " " + this.currentWeek[0].getFullYear() + this.content?.text?.selected;
        } else {
          this.currentSelectedDateHdnTxt = this.content?.text?.from + this.currentWeek[0].getDate() + ' ' + this.globalContentStore.capitalizeString(this.currentWeek[0].toLocaleString(this._appStore.lang + '-CA', { month: 'long' })) + " " + this.currentWeek[0].getFullYear() 
                                         + this.content?.text?.to + this.currentWeek[6].getDate() + ' ' + this.globalContentStore.capitalizeString(this.currentWeek[6].toLocaleString(this._appStore.lang + '-CA', { month: 'long' })) + " " + this.currentWeek[0].getFullYear() + this.content?.text?.selected;
        }
      }
    }
  }

  dateSelected(value) {
    this.showError = false;
    this.selectedDate = value.date;
    if (value.valid) {
      this.dateSelectedByUser.emit(this.selectedDate);
      this.handleCloseTooltip(value);
    } else {
      this.invalidDate = {
        message: this.content.text.unselectedDate
      };
      this.showError = true;
    }
  }

  hideErrorMessage(event) {
    this.invalidDate = undefined;
  }

  setIndividualClass() {
    this.tooltipClass = this.id;
    if (this.id && this.id.indexOf('__') > -1) {
      this.tooltipClass = this.id.substring(0, this.id.indexOf('__'));
    }
  }

  handleOpenAndPosition = (e) => {

    if (this.openIconName === 'icon-Chevron-Down') {
      this.rotateChevronIcon('up');
    }
    this.openOverlay();
    const targetButton = e.target;
    const btnDistance = targetButton.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const tooltipBox: any = document.getElementById(`tooltipContainer_${this.id}`);
    const tooltipParent: any = document.getElementById(`tooltipParent_${this.id}`);

    this._service.open();
    //tooltipParent.setAttribute('aria-hidden', false);

    if (this.boundingContainer) {
      tooltipParent.style.display = 'block';

      tooltipBox.style.left = -233 + 'px';

      const boundingRect = document.getElementById(this.boundingContainer).getBoundingClientRect();
      const tooltipRect = tooltipBox.getBoundingClientRect();
      const parentRect = tooltipParent.getBoundingClientRect();

      if (tooltipRect.left < boundingRect.left + 16) {
        tooltipBox.style.left = -233 + (boundingRect.left + 16 - tooltipRect.left) + 'px';
      }

      if (boundingRect.bottom - parentRect.bottom < 327) {
        tooltipBox.style.top = 'auto';
        tooltipBox.style.bottom = 22 + 'px'
      } else {
        tooltipBox.style.top = 27 + 'px';
        tooltipBox.style.bottom = 'auto'
      }

      this.isDatePicker = true;
    } else {
      tooltipParent.style.display = 'block';

      tooltipBox.style.left = -233 + 'px';

      if (screenWidth > 899) {
        tooltipBox.style.left = -267 + 'px';
      }

      if (isDefined(this.calendarBtnLabel)){
        let el = document.getElementById('openButton_' + this.id);
        tooltipBox.style.left = parseInt(tooltipBox.style.left, 10) + el.getBoundingClientRect().width - 24 + 'px';
      }

      const tooltipDistance = tooltipBox.getBoundingClientRect();
      const offsetLeft = tooltipBox.offsetLeft;
      let whiteSpaceleft;

      if (screenHeight - tooltipDistance.bottom < screenHeight / 3) {
        tooltipBox.style.top = 'auto';
        tooltipBox.style.bottom = 22 + 'px'
      } else {
        tooltipBox.style.top = 27 + 'px';
        tooltipBox.style.bottom = 'auto'
      }
      if (screenWidth > 600 && screenWidth < 900) {
        whiteSpaceleft = (screenWidth - 600) / 2;
      } else if (screenWidth > 1280) {
        whiteSpaceleft = (screenWidth - 1280) / 2;
      }
      let parentIsModal = tooltipParent;
      while (parentIsModal.nodeName != "BODY") {
        parentIsModal = parentIsModal.parentElement;
      }
      // this case because of white space padding on both sides of screen otherwise following cases will suffice
      if (((screenWidth > 600 && screenWidth < 900) || screenWidth > 1280) && (whiteSpaceleft > tooltipDistance.left)) {
        tooltipBox.style.left = -btnDistance.left + whiteSpaceleft - (targetButton.offsetWidth / 2) + 19 + 'px';
      } else if (tooltipDistance.right > screenWidth) { // positioning the tooltip if not much room available towards right of screen
        tooltipBox.style.left = tooltipBox.offsetLeft - (tooltipDistance.right - screenWidth) - 16 + 'px';
      } else if (tooltipDistance.left < 0) {  // dynamically positioning the tooltip if not much room available towards left of screen
        tooltipBox.style.left = offsetLeft - tooltipDistance.left + 15 + 'px';
      } else {
        tooltipBox.style.left = offsetLeft + 'px';
      }
      this.isDatePicker = true;
    }


  }

  setFocusOnFirstItem() {
    const element: any = document.getElementById(`tooltipContainer_${this.id}`);
    element.focus();
  }

  handleKeyDownOpen = (e) => {
    const keys = this.keyCodes();
    const tooltipBox: any = document.getElementById(`tooltipParent_${this.id}`);
    if (tooltipBox.style.display === 'block') {
      if (e.shiftKey) {
        return true;
      }
      if (e.keyCode === keys.tab) {
        this.setFocusOnFirstItem();
      }
      e.preventDefault();
      if (e.stopPropagation) {
        e.stopPropagation();
      }
    }
  }

  openOverlay() {
    this.component = this.viewContainerRef.createComponent(DateOverlayComponent);
    this.component.instance.closeOverlay = () => { this.removeTooltip() };
    // const compFactory = this._compResolver.resolveComponentFactory(DateOverlayComponent);
    // this._compRef = compFactory.create(this._injector);
    // this._compRef.instance.closeOverlay = () => { this.removeTooltip() };
    this._document.getElementById('page-container').appendChild(this.component.location.nativeElement);
  }

  removeTooltip() {

    if (this.openIconName === 'icon-Chevron-Down') {
      this.rotateChevronIcon('down');
    }
    this.invalidDate = undefined;
    const tooltipBox: any = document.getElementById(`tooltipParent_${this.id}`);
    //tooltipBox.setAttribute('aria-hidden', true);
    tooltipBox.style.display = 'none';
    this._document.getElementById('page-container').removeChild(this.component.location.nativeElement);
    this.isDatePicker = false;
    this._service.close();

    let openBtn = document.getElementById(`openButton_${this.id}`);
    const userAgent = navigator.userAgent || navigator.vendor;
    if ((/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream)) {
      openBtn = document.getElementById(`openButton_${this.id}`);
    }
    setTimeout(() => {
      openBtn.focus();
    }, 300);
  }

  handleCloseTooltip = (e) => {
    this.invalidDate = undefined;
    this.removeTooltip();
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  }

  handleKeyDownClose = (e) => {
    const keys = this.keyCodes();
    if (e.keyCode === keys.enter || e.keyCode === keys.space || e.keyCode === keys.esc) {
      this.handleCloseTooltip(e);
      e.preventDefault();
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      return true;
    }
    const tooltipBox: any = document.getElementById(`tooltipParent_${this.id}`);
    if (tooltipBox.style.display === 'block') {
      if (e.keyCode === keys.tab) {
        this.setFocusOnFirstItem();
        e.preventDefault();
        if (e.stopPropagation) {
          e.stopPropagation();
        }
      } else {
        return true;
      }
    }
  }

  rotateChevronIcon(rotatePos) {
    let el = document.getElementById('calendarIcon_' + this.id);
    if (el && rotatePos === 'up') {
      el.className = this.openIconName + " calendarIcon animatedChevron rotateDown tooltipIcon_" + this.id;
      this.isCalendarOpen = true;
    } else if (el && rotatePos === 'down') {
      el.className = this.openIconName + " calendarIcon animatedChevron rotateUp tooltipIcon_" + this.id;
      this.isCalendarOpen = false;
    }
  }
}
