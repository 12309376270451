import { BaseData } from 'src/app/shared/models/base-data';

export class RemoveBrokerageAccountsVerifyData extends BaseData {
    constructor() {
        super();
        this.input = {
            currentTradingPassword: null,
            newTradingPassword: null,
            reEnteredTradingPassword: null
        };
    }
}

export interface RemoveBrokerageAccountsVerifyInputs {
    currentTradingPassword: string;
    newTradingPassword: string;
    reEnteredTradingPassword: string;
}