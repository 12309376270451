export const BUY_STRUCT_NOTES_CONSTANT = {

    omniAction: "buy",
    omniQuantity: "1",
    SEARCH: "search",
    SORT: "sort",
    ASC: "asc",
    DESC: "desc",
    DROPDOWN: "dropdown",
    HEADER: "header",
    RISK: "R",
    PROTECTED: "P",
    PAR: "PAR",
    PPN: "PPN",
    MLGIC: "MLGIC",
    NOTENAME: "noteName",
    OMNIPAR: "pars",
    OMNIPPN: "ppns",
    NOPRODUCT: "product-not-available"
}