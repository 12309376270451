import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { ChangeCashPlanVerifyData } from './change-cash-plan-verify-data';

@Injectable({ providedIn: 'root' })
export class ChangeCashPlanVerifyStore extends PageStore<ChangeCashPlanVerifyData>  {

    constructor() {
        super(new ChangeCashPlanVerifyData());
    }

}