<div [ngStyle]="{'z-index':attrZIndex}"
  [ngClass]="{'cibc-modal': !sessionExtension, 'internal-overflow': internalOverflow }" id="modal-{{id}} "
  class="cibc-modal-body-{{id}} page-lob-{{getLob()}} " [attr.aria-labelledby]="header"
  [attr.aria-modal]="ariaModalAttribute" role="dialog">
  <!-- <div [ngClass]="{'cibc-modal-body': !sessionExtension, 'cibc-session-extension-modal-body' : sessionExtension}"> -->
  <div role="main">
    <ng-content></ng-content>
  </div>
  <!-- </div> -->
</div>
<div id="cibc-modal-background_id" [ngStyle]="{'z-index':attrModalBackgroundZIndex, 'opacity': opacity}" class="cibc-modal-background"></div>