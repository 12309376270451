import { FormValidatorFactory } from '../../../../shared/services/cibc.formvalidator';
import { isDefined } from 'src/app/shared/services/utils.service';
import { FormGroup, FormControl } from '@angular/forms';

declare let require: any;
const gatewayConfig = require('src/app/config/gateway-config.json');

export class QuickTradeOptionsVerifyValidator {
  static createForm(form, fb) {
    form = fb.group({
      'tradingPassword': [""]
    });
    return form;
  }
  static setValidators(form) {
    if((window as any).enableAlphanumericValidation === false){
      if (form.controls['tradingPassword']) {
        form.controls['tradingPassword'].setValidators(
          [
            FormValidatorFactory.createBoundedLengthValidator('MSGOPT0031', 1, 12),
            FormValidatorFactory.createAlphaNumValidator('MSGOPT0031'),
            FormValidatorFactory.createNotBlankValidator('MSGOPT0032')
          ]
        )
      }
    }
        else{
          if (form.controls['tradingPassword']) {
          form.controls['tradingPassword'].setValidators(
            [
              FormValidatorFactory.createBoundedLengthValidator('MSGOPT0031', 1, 32),
              //FormValidatorFactory.createAlphaWithEitherNumOrSpecialCharsValidator('MSGOPT0031'),
              FormValidatorFactory.createNotBlankValidator('MSGOPT0032'),
              //FormValidatorFactory.createNoNumericSequenceValidator('MSGOPT0032'),
              //FormValidatorFactory.createNoRepeatingCharacterValidator('MSGOPT0032'),
            ]
          )
        }
      }
  }

  static validate(form) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validate(control);
      }
    });
  }

  static resolveValidationError(form, errorContent) {
    let messges = [];
    if (form.controls['tradingPassword']) {
      messges.push(
        {
          controlName: 'tradingPassword',
          code: form.controls['tradingPassword'].errors
        });
    }
    return this.setErrorMessage(messges, errorContent);
  }

  static setErrorMessage(results, errorContent) {
    let error = {};
    results.forEach(result => {
      if (result.code !== null) {
        if (isDefined(result.code.errorCode)) {
          error[result.controlName] = errorContent[result.code.errorCode];
        }
        console.log("Trading Password Validator - Error message is: " + JSON.stringify(result));
      }
    });
    return error;
  }
}
