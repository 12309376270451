<div class="custom-header">
  <div class='width10 divPrev'>
    <button *ngIf="backButton" class="prevBtnCls" [attr.aria-label]="prevButtonLabel" (click)="previousClicked($event)"
      role="button" type="button" id="prevBtn" attr.aria-live='polite'></button>
  </div>


  <div class="custom-header-label">
    <div class='monthDropdown'>
      <label id="dp-month-label">{{content.month}}</label>
      <select name="monthList" [(ngModel)]="monthSel" (change)="changeMonth()" id="monthSel" class="width-month"
        aria-labelledby="dp-month-label">
        <option [selected]='monthSel === month.key' *ngFor="let month of dropdownMonth" [ngValue]="month.key">
          {{month.value}}
        </option>
      </select>
    </div>
    <div class='yearDropdown'>
      <label id="dp-year-label">{{content.year}}</label>
      <select name="yearList" [(ngModel)]="yearSel" (change)="changeYear()" id="yearSel" class="width-year"
        aria-labelledby="dp-year-label">
        <option [selected]=' yearSel===year.key' *ngFor="let year of dropdownYear" [ngValue]="year.key">
          {{year.value}}
        </option>
      </select>
    </div>
  </div>

  <div class='width10 divFwd'>
    <button *ngIf="forwardButton" class="fwdBtnCls" [attr.aria-label]="nextButtonLabel" (click)="nextClicked($event)"
      role="button" type="button" id="fwdBtn" attr.aria-live='polite'></button>
  </div>
</div>