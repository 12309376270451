import { BaseData } from '../../../../../../shared/models/base-data';


export class ChangeMutualFundPlanLandingData extends BaseData {
}
export interface cmfPageData {
    Account: cmfAccountList,
    AsOfDate: string,
    ValidateInfo: ValidateInfo,
    SettlementAccountDetails: settlementAccountsResp
}
export interface cmfAccountList {
    AccountBalances?: accountBalances[],
    AccountNumber: number,
    AccountOwnerName: string,
    AccountTypeDesc: string,
    BuyingPowerValueType: string,
    Fullname: string,
    IncludeShort: boolean
}
export interface accountBalances {
    AccountType: string,
    Amount: number,
    Currency: string
}

export interface amountToolTip {
    openBtnLabel: string;
    cancel: string;
    content: string;
    btnHiddenText: string;
};

export interface cmfQuoteList {
    MutualFundResultList: mutualFundResultList[];
}

export const payFrom_Constant = {
    buy: 'Buy',
    achat: 'Achat'
}

export interface mutualFundResultList {
    LastPrice: { Data: string, Content: string },
    LastPriceDate: string,
    Symbol: mfSymbol;
}

export interface mfSymbol {
    CompanyCode: string,
    FundCategoryName: string,
    FundCode: number,
    FundCompany: string,
    FundCurrency: string,
    FundGroupName: string,
    FundName: string,
    InitialMinimum: number,
    IsiTradingEligibleInd: string,
    LoadType: string,
    RrspEligibilityInd: string,
    SegFlag: boolean,
    StandingOrderEligibleInd: number,
    SubsequentMinimum: number,
    SymbolName: string
}

export interface cmfQuoteReq {
    SymbolName: string;
}

export const globalCalendar = {
    minDateYearInput: 0,
    minDateMonthInput: 0,
    maxDateYearInput: 2,
    maxDateMonthInput: 0,
    minSetYear: 0,
    minSetMonth: 0,
    minSetDay: 1,
    maxSetYear: 2,
    maxSetMonth: 11,
    maxSetDay: 31
}

export const cmf_calendar_const = {
    customDate: "customDate",
    endCustomDate: "endCustomDate",
    suspendCustomDate: "suspendCustomDate",
    resumeCustomDate: "resumeCustomDate"
}

export const cmf_form_control_const = {
    errorValue: "-1",
    nonErrorValue: "0",
    valueOne: 1,
    dollar: "$"
}
export interface settlementAccountsResp {
    SettlementAccounts?: settlementAccounts[];
    RegisteredContributionTypeList?: registeredContributionTypeList[];
}

export interface settlementAccounts {
    AccountNumber: number
    AccountType?: string
    Currency?: string
    SettlementType?: string
}

export interface registeredContributionTypeList {
    RegisteredContributionTypeList: {}
}

export interface cmfAccountRequest {
    AccountNumber: number;
}

export interface cmfValidateReq {
    AccountNumber?: number,
    Amount?: number,
    Frequency?: number,
    StartDate?: string,
    PayFrom?: any,
    EndDate?: string,
    SuspendDate?: string,
    UnSuspendDate?: string,
    RegisteredContributionType?: string;
    UpdateFlow?: boolean;
}

export interface cmfSubmitReq {
    AccountNumber: number,
    Amount: number,
    Frequency: number,
    StartDate: number,
    PayFrom: number,
    EndDate?: number,
    SuspendDate?: number,
    ResumeDate?: number
}

export interface ValidateInfo {
    Action?: string;
    Amount?: number;
    NextOrderDate?: string;
    PayFromAccountNumber?: string;
    PlanFrequency?: number;
    RawStartDate?: string;
    StartDate?: string;
    EndDate?: string;
    RawEndDate?: string;
    RawSuspendDate?: string;
    RawUnSuspendDate?: string;
    SuspendDate?: string;
    UnSuspendDate?: string;
    Symbol: cmfSymbol;
    TradingPasswordInSession: boolean;
    TradingPasswordRequired: boolean;
    ContributionType: string;
}

export interface cmfSymbol {
    FundName: string;
    SymbolName: string;
}