import { BaseData } from '../../../../../../shared/models/base-data';

export class BuyGICVerifyData extends BaseData {
  constructor() {
    super();
    this.input = {
      tradingPassword: '',
      savePassword: false,
      FHSAcheckbox: false
    };
  }
}

export interface BuyGICVerifyInputs {
  tradingPassword: string;
  savePassword: boolean;
  FHSAchecbox: boolean;
}
