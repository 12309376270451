import { RouteGroup } from '../shared/models/route-config';

export const TransactionHistoryConfig: RouteGroup = {
    '/txn/transactionhistory': {
        name: 'transactionhistory',
        service: 'TransactionHistoryService',
        store: 'TransactionHistoryStore',
        resolveContent: 'transactionhistory',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'txnHistoryInit',
                get request(): any {
                    return {
                        TransactionHistoryReq: {
                            "Refresh": true
                        }
                    };
                },
            },
        ],
        trackingData: {
            hierarchy: 'accounts',
            name: 'transaction-history',
            nameGA: 'Transaction History'
        },
        brazeID: "viewed_transaction_history"
    },
};
