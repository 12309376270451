<app-modal [attr.id]="'otvcprompt' + uniqueId" [id]="'otvcprompt' + uniqueId" style='display: none;'
  (closefn)="cancel()" [header]="compModel.isDataAvailable ? 'otvcPrompt_pageHeader' + uniqueId : null">
  <div class="cibc-account-selector-modal-body" [attr.aria-controls]="'otvcprompt' + uniqueId">
    <div class="cibc-account-selector-modal-title-bar">
      <div id="otvcPrompt_modalClose" class='modal-close'>
        <button [attr.id]="'otvcPromptClose' + uniqueId" type="button" (click)="cancel()"
          class="close-button icon-Close color04"[attr.aria-label]="globalContentStore?.text?.closeModal">
        </button>
      </div>
      <div [attr.id]="'otvcPrompt_pageHeader' + uniqueId" *ngIf="compModel.isDataAvailable" class="selectMessage color02">
        <h1>{{PromptContent.text.pageHeader}}</h1>
      </div>
    </div>
    <div *ngIf="compModel.isDataAvailable" class="cibc-account-selector-modal-content">
      <article class="postSignOn-Content">
        <section id="otvcPrompt_postSignOnContent" class="noPadding postSignOnContent">
          <app-page-level-error [error]="compModel.unexpectedException" [mode]="'error'"> </app-page-level-error>
          <div *ngIf="currentModule == 'prompt'">
            <app-error [active]="currentModule == 'prompt'" [identifier]="'otvc-prompt'"></app-error>
          </div>
          <app-otvc-verify-form [compModel]="compModel" [error]="formError" [modelContent]="PromptContent"
            [loading]="loading" [verifySpinner]="verifySpinner" (sendOtvcRequested)="handleSend($event)"
            (verifyRequested)="verify()" (promptModelRequested)="cancel()" (returnError)="changeErrorEvent($event)">
          </app-otvc-verify-form>
        </section>
      </article>
    </div>
  </div>
</app-modal>