import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { AccountInfoData } from './account-info-page-data';

@Injectable({ providedIn: 'root' })
export class AccountInfoStore extends PageStore<AccountInfoData>  {

    constructor() {
        super(new AccountInfoData());
    }

}
