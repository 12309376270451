import { BaseData } from 'src/app/shared/models/base-data';

export class OrderStatusData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1,
      orderType: 0,
      filter: {
        E: {
          market: '',
          time: '',
          status: '',
          symbol: ''
        },
        O: {
          orderType: '',
          market: '',
          time: '',
          status: '',
          symbol: ''
        },
        M: {
          action: '',
          type: ''
        },
        F: {
          currency: '',
          type: ''
        },
        C: {},
        A: {}
      }
    };
  }
}

export interface OrderStatusInputs {
  accountIndex: number;
  selectedOrderType: number;
  filter: {
    E: {
      market: string;
      time: string;
      status: string;
      symbol: string;
    },
    O: {
      orderType: string;
      market: string;
      time: string;
      status: string;
      symbol: string;
    },
    M: {
      action: string;
      type: string;
    },
    F: {
      currency: string;
      type: string;
    },
    C: {},
    A: {}
  };
}

export class OrderStatusSearchReq {
  Refresh: boolean;
  AccountNumber: string;
  FiTypeCode: string;
  PaginationOption: {
    Offset: string;
    Limit: string;
  };
  Sorting: [
    {
      SortFiTypeCode: string;
      SortColumn: string;
      SortType: string;
    }
  ];

  Market: string;
  TimePeriod: string;
  OrderStatus: string;
  SymbolName: string;

  Action: string;
  Type: string;

  Currency: string;
  InstrumentType: string;
  OptionOrderType: string;
}

export class OrderStatusDetailsReq {
  OrderId: string;
  FiTypeCode: string;
}

export class OrderStatusChangeStockInitReq {
  OrderId: string;
  AccountNumber: string;
}

export class OrderStatusChangeStockVerifyReq {
  OrderId: string;
  AccountNumber: string;
  Quantity: number;
  Price: {
    Type: string;
    Amount?: number;
    LimitAmount?: number;
    TrailingDelta?: number;
    LimitOffset?: number;
    TriggerPrice?: number;
    EstLimitPrice?: number;
  };
}

export class OrderStatusChangeStockConfirmReq {
  TradingPassword?: string;
  SaveTradingPassword?: string;
}

export class OrderStatusChangeOptionInitReq {
  OrderId: string;
  AccountNumber: string;
}

export class OrderStatusChangeOptionVerifyReq {
  OrderId: string;
  AccountNumber: string;
  Quantity: number;
  Price: {
    Type: string;
    LimitAmount?: number;
  };
}

export class OrderStatusChangeOptionConfirmReq {
  TradingPassword?: string;
  SaveTradingPassword?: string;
}

export class OrderStatusChangeCashInitReq {
  OrderId: string;
  AccountNumber: string;
}

export class OrderStatusChangeCashVerifyReq {
  OrderId: string;
  Amount: number;
}

export class OrderStatusChangeCashConfirmReq {
  TradingPassword?: string;
  SaveTradingPassword?: string;
}

export class OrderStatusCancelInitReq {
  OrderId: string;
  AccountNumber: string;
}

export class OrderStatusCancelConfirmReq {
  TradingPassword?: string;
  SaveTradingPassword?: string;
}

export class BuyingPowerReq {
  AccountNumber: string;
}

export class QuoteReq {
  Symbols: [
      {
          SymbolName: string;
          Market: string;
          SymbolType: string;
          OptionInfo?: {
              OptionType: string;
              StrikeDate: string;
              StrikePrice: {
                  Value: string;
              }
          }
      }
  ];
}
