import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { SellBondLandingData } from './sell-bond-landing-data';

@Injectable({ providedIn: 'root' })
export class SellBondLandingStore extends PageStore<SellBondLandingData> {

    constructor() {
        super(new SellBondLandingData());
    }

}
