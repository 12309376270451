import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccountSharedModule } from './account-shared.module';
import { SymbolQuoteModule } from './components/symbol-quote/symbol-quote.module';
import { DateSelectorSharedModule } from './date-selector-shared.module';
import { FlowWidgetSharedModule } from './flow-widget-shared.module';
import { FormSharedModule } from './form-shared.module';
import { PipeSharedModule } from './pipe-shared.module';
import { SharedModule } from './shared.module';
import { WidgetSharedModule } from './widget-shared.module';
import { OtcvSharedModule } from 'src/app/shared/otvc-shared.module';
import { CurrencyFormatterPipe } from '../core/pipes/currency-formatter.pipe';
import { QuickTradeOptionsConfirmComponent } from './components/quick-trade-options/confirm/quick-trade-options-confirm.component';
import { QuickTradeOptionsLandingComponent } from './components/quick-trade-options/landing/quick-trade-options-landing.component';
import { QuickTradeOptionsComponent } from './components/quick-trade-options/quick-trade-options.component';
import { QuickTradeOptionsVerifyComponent } from './components/quick-trade-options/verify/quick-trade-options-verify.component';
import { OptionChainModule } from './components/option-chain/option-chain.module';
import { OptionChainService } from './components/option-chain/services/option-chain.service';
import { OptionChainStore } from './components/option-chain/data/option-chain-store';
// import { TradeOptionsConfirmModule } from '../modules/txn/tradeOptions/confirm/trade-options-confirm.module';


@NgModule({
  declarations: [
    QuickTradeOptionsComponent,
    QuickTradeOptionsLandingComponent,
    QuickTradeOptionsConfirmComponent,
    QuickTradeOptionsVerifyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    PipeSharedModule,
    AccountSharedModule,
    FormSharedModule,
    WidgetSharedModule,
    FlowWidgetSharedModule,
    SymbolQuoteModule,
    DateSelectorSharedModule,
    OtcvSharedModule,
    OptionChainModule
  ],
  providers: [
    CurrencyFormatterPipe,
    OptionChainService,
    OptionChainStore
  ],
  exports: [
    QuickTradeOptionsComponent,
    QuickTradeOptionsLandingComponent,
    QuickTradeOptionsConfirmComponent,
    QuickTradeOptionsVerifyComponent
  ]
})
export class QuickTradeOptionsSharedModule { }
