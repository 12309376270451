import { NgModule, ModuleWithProviders } from '@angular/core';
import { OptionChainComponent } from './option-chain.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { PipeSharedModule } from 'src/app/shared/pipe-shared.module';
import { WidgetSharedModule } from 'src/app/shared/widget-shared.module';
import { FormSharedModule } from '../../form-shared.module';

@NgModule({
  declarations: [OptionChainComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    PipeSharedModule,
    WidgetSharedModule,
    FormSharedModule
  ],
  exports: [OptionChainComponent],
  providers: []
})

export class OptionChainModule {
}
