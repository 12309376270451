import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { _User } from '../../models/user';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { isDefined } from '../../services/utils.service';
import { AppStore } from '../../models/app-store';

@Component({
  selector: 'app-page-level-error',
  templateUrl: './page-level-error.component.html',
  styleUrls: ['./page-level-error.component.scss']
})
export class PageLevelErrorComponent implements AfterViewInit, OnChanges {

  @Input() error;
  @Input() htmlError = false;
  @Input() code;
  @Input() mode = 'error';
  @Input() identifier = undefined;
  @Input() margin = '';
  @Input() canClose = false;
  @Input() customClose = false;
  @Input() multipleErrors = [];

  @Output() closePass: EventEmitter<any> = new EventEmitter<any>()

  imageContent: string;
  closed = false;

  constructor(
    public globalContent: GlobalContentStore,
    private appStore: AppStore
  ) {

  }

  ngAfterViewInit() {
    if (this.mode === 'error') {
      const element: HTMLElement = (document.getElementById('app-page-level-error-container') as HTMLElement);
      setTimeout(() => {
        if (isDefined(element)) {
          element.setAttribute('tabindex', '-1');
          element.focus();
        }
      }, 300);
    }
  }

  ngOnChanges() {
    this.closed = false;
  }

  close() {
    if (this.customClose) {
      this.closePass.emit();
    } else {
      this.closed = true;
    }
  }
}
