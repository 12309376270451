import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CreateCashPlanConfirmData } from './create-cash-plan-confirm-data';

@Injectable({ providedIn: 'root' })
export class CreateCashPlanConfirmStore extends PageStore<CreateCashPlanConfirmData>  {

    constructor() {
        super(new CreateCashPlanConfirmData());
    }

}
