import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../../shared/models/page-store';
import { EnableMarginAndOptionsTradingConfirmData } from "./enable-margin-and-options-trading-confirm.data";


@Injectable({ providedIn: 'root' })
export class EnableMarginAndOptionsTradingConfirmStore extends PageStore<EnableMarginAndOptionsTradingConfirmData> {
    constructor() {
        super(new EnableMarginAndOptionsTradingConfirmData());
    }
}