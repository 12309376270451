import { Injectable } from '@angular/core';
import { QuotesDetailLandingData } from './quotes-detail-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class QuotesDetailStore extends PageStore<QuotesDetailLandingData> {

    constructor() {
        super(new QuotesDetailLandingData());
    }
}
