import { BaseData } from 'src/app/shared/models/base-data';

export class UpdatePasswordConfirmData extends BaseData {
    constructor() {
        super();
        this.input = {
            signOnPassword: null,
            tradingPassword: null
        };
    }
}

export interface UpdatePasswordConfirmInputs {
    signOnPassword: string;
    tradingPassword: string;
}