import { NgModule } from '@angular/core';
import { NavigationComponent } from './navigation.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HamburgerMenuModule } from '../hamburger-menu/hamburger-menu.module';
import { HeaderModule } from './../../../shared/components/header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormSharedModule } from 'src/app/shared/form-shared.module';
import { FormsModule } from '@angular/forms';
import { OtcvSharedModule } from 'src/app/shared/otvc-shared.module';
import { TradingPasswordFormSharedModule } from 'src/app/shared/trading-password.module';
import { WidgetSharedModule } from 'src/app/shared/widget-shared.module';

@NgModule({
    declarations: [NavigationComponent],
    imports: [
        RouterModule,
        CommonModule,
        HamburgerMenuModule,
        HeaderModule,
        SharedModule,
        FormSharedModule,
        FormsModule,
        OtcvSharedModule,
        TradingPasswordFormSharedModule,
        WidgetSharedModule
    ],
    exports: [
        NavigationComponent
    ]
})
export class NavigationModule { }
