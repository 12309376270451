import { Injectable } from '@angular/core';
import { ChangeTradingPasswordConfirmData } from './change-trading-password-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeTradingPasswordConfirmStore extends PageStore<ChangeTradingPasswordConfirmData> {

    constructor() {
        super(new ChangeTradingPasswordConfirmData());
    }

}
