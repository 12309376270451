import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import _ from 'lodash';

@Directive({
  selector: '[appCashNumber]'
})
export class CashNumberDirective {
  @Input() cashNumberLang: string;
  @Input() cashMaxLength: number;


  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private control: NgControl
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  onKeyDown(event) {
    if (event) {
      let num: string = this.el.value;
      num = num.replace(/[^0-9\.,]/g, '');
      if (this.cashNumberLang == 'en') {
        num = num.replace(/,/g, '');
        let i = num.indexOf('.');
        if (i != -1) {
          let split = num.split('.');
          num = split[0] + '.' + split[1];
        }
      } else {
        num = num.replace(/\./g, '');
        let i = num.indexOf(',');
        if (i != -1) {
          let split = num.split(',');
          num = split[0] + ',' + split[1];
        }
      }
      let result = num;
      if (num.length > this.cashMaxLength) {
        result = num.substr(0, this.cashMaxLength);
      }
      if (num.length > 0) {
        const num1 = num;
        if (this.cashNumberLang == 'en') {
          const compiled = _.template('$<%= num1 %>');
          result = compiled({ num1 });
        } else {
          const compiled = _.template('<%= num1 %> $');
          result = compiled({ num1 });
        }
      }
      this.control.control.setValue(result);
    }
  }
}


