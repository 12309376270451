import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { BuyStructNotesConfirmStore } from '../data/buy-struct-notes-confirm-store';

@Injectable({
  providedIn: 'root'
})
export class BuyStructNotesConfirmService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (BuyStructNotesConfirmStore.params) {
      const ser = input[0];
      ser.input = BuyStructNotesConfirmStore.params;
      return input;
    } else {
      return [];
    }
  }
}
