import { Injectable } from '@angular/core';
import { ChangeSignonPassConfirmData } from './change-signon-pass-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeSignonPassConfirmStore extends PageStore<ChangeSignonPassConfirmData> {

    constructor() {
        super(new ChangeSignonPassConfirmData());
    }

}
