export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public production = false;
  public marketLenURL = "";
  public region = "none";

  // Whether or not to enable debug mode

  constructor() { }
}