import { BaseData } from 'src/app/shared/models/base-data';

export class ForgotSignonPassData extends BaseData {

    constructor() {
        super();
        // this.input = { userId: '', nameType: '', nameValue: '', phone: '', phoneCountry: '' };
        this.input = {
            userId: '',
            nameType: '',
            first: '',
            last: '',
            email: '',
            phone: '',
            phoneCountry: ''
        };
    }

}

export interface ForgotSignOnPass {
    // phone: string;
    // phoneCountry: any;
    // userId: string;
    // nameType: string;
    // nameValue: string;
    userId: string;
    nameType: string;
    phoneCountry: any;
    first: string;
    last: string;
    email: string;
    phone: any;
}

export class ForgotSignOnPassReq {
    UserId: string;
    SecurityFieldName: string;
    SecurityFieldValue: string;
    Locale: string;
    Lob: string;
    // Mode: number;
    // SaveUserId: boolean;
    Flow: string;
    PhoneCountryCode: string;

}
