import { DOCUMENT } from '@angular/common';
import { Component, OnInit, HostListener, AfterViewInit, Inject, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppStore } from 'src/app/shared/models/app-store';

@Component({
  selector: 'app-force-upgrade',
  templateUrl: './force-upgrade.component.html',
  styleUrls: ['./force-upgrade.component.scss']
})
export class ForceUpgradeComponent implements OnInit, AfterViewInit {

  breakPoint: number;
  currentRoute: string;

  constructor(
    private router: Router,
    public appStore: AppStore,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  upgrade() {
    if (this.appStore.platform.toLowerCase() == "android") {
      window.open("https://play.google.com/store/apps/details?id=com.mobilebrokerage.CIBC", "_system");
    } else if (this.appStore.platform.toLowerCase() == "ios") {
      window.open("https://appstore.com/cibc/cibcmobilebrokerage", "_system");
    }
  }
}
