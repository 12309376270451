import { Injectable } from '@angular/core';
import { QuotesWatchListLandingData } from './quotes-watchlist-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class QuotesWatchListLandingStore extends PageStore<QuotesWatchListLandingData> {

    constructor() {
        super(new QuotesWatchListLandingData());
    }
}
