import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paginate',
    pure: false,
})
export class PaginationPipe implements PipeTransform {

  constructor() { }

  transform(records, startIndx, endIndx, pagesize) {
      if ( records.length > pagesize  && startIndx > 0) {
        return records.slice (startIndx - 1, endIndx);
      } else {
        return records;
      }
  }
}
