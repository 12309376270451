import { Injectable } from '@angular/core';
import { EnableUSMarketTradingConfirmData } from './enable-US-market-trading-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class EnableUSMarketTradingConfirmStore extends PageStore<EnableUSMarketTradingConfirmData> {

    constructor() {
        super(new EnableUSMarketTradingConfirmData());
    }

}
