import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PageService } from 'src/app/shared/services/page-service';
import { CancelCashPlanLandingStore } from "../data/cancel-cash-plan-landing-store";

@Injectable({
    providedIn: 'root'
})
export class CancelCashPlanLandingService implements PageService {

    constructor() { }


    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        const req = ser.input;
        req.AccountNumber = CancelCashPlanLandingStore.params.detailsParam.AccountNumber;

        return input;
    }

}