import { Component, Inject, OnInit, ViewChild, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subscription, forkJoin, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import moment from 'moment';
import _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Time } from 'highcharts';

import { ContentService } from 'src/app/core/services/content.service';
import { QuickTradeOptionsLandingStore } from './data/quick-trade-options-landing-store';
import { AppStore } from 'src/app/shared/models/app-store';
import { ModalService } from '../../modal/services/modal.service';
import { QuickTradeOptionsLandingService } from './services/quick-trade-options-landing.service';
import { ContentStore } from 'src/app/shared/store/content-store';
import { ContentType, GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { CustomAppErrorHandler } from 'src/app/core/components/error/custom-error/custom-app-error-handler';
import { SpinnerService } from 'src/app/core/components/spinner/service/spinner.service';
import { CurrencyFormatterPipe } from 'src/app/core/pipes/currency-formatter.pipe';
import { HttpService } from 'src/app/core/services/http.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { HelpService } from 'src/app/modules/txn/help/services/help.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { SymbolQuoteService } from '../../symbol-quote/services/symbol-quote.service';
import { OptionChainService } from '../../option-chain/services/option-chain.service';
import { _User } from 'src/app/shared/models/user';
import { QuickTradeOptionsLandingInputs, QuoteReq } from './data/quick-trade-options-landing-data';
import { isDefined, openWindow } from 'src/app/shared/services/utils.service';
import { OptionChainStore } from '../../option-chain/data/option-chain-store';
import { ErrorConstant } from 'src/app/core/models/app-error';
import { TradeOptionsLandingStore } from 'src/app/modules/txn/tradeOptions/landing/data/trade-options-landing-store';
import { TradeOptionsValidator } from 'src/app/modules/txn/tradeOptions/landing/trade-options-landing.validator';
import { QuickTradeOptionsVerifyStore } from '../verify/data/quick-trade-options-verify-store';

import { Lang, Lob } from 'src/app/shared/models/lob.enum';
import { TradeOptionsVerifyStore } from 'src/app/modules/txn/tradeOptions/verify/data/trade-options-verify-store';
import { QuickTradeOptionsValidator } from './quick-trade-options-landing.validator';
import { EnableMarginAndOptionsTradingLandingStore } from 'src/app/modules/txn/userPreferences/enableMarginAndOtionsTrading/landing/data/enable-margin-and-options-trading-landing.store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Component({
    selector: 'app-quick-trade-options-landing',
    templateUrl: './quick-trade-options-landing.component.html',
    styleUrls: ['./quick-trade-options-landing.component.scss'],
    animations: [
      trigger('fadeOutIn', [
        transition('void => *', [
          style({ opacity: 0, transform: 'translateX(130%)' }),
          animate('400ms ease-in-out')
        ]),
  
        transition('* => void', [animate('400ms ease-in-out'), style({ opacity: 1, transform: 'translateX(130%)' })]),
      ])
    ]
  })

export class QuickTradeOptionsLandingComponent implements OnInit {

  @Input() public mode: string = 'quickTrade';
  @Input() public data: any;
  @Input() public fullData: any;
  @Output() submit = new EventEmitter();
  @Output() dataLoaded = new EventEmitter();

  storeArray = {
    "quickTrade": QuickTradeOptionsLandingStore,
    "smallTradeOptions": TradeOptionsLandingStore
  }

  verifyStoreArray = {
    "quickTrade": QuickTradeOptionsVerifyStore,
    "smallTradeOptions": TradeOptionsVerifyStore
  }
  // data: any;
  accData: any;
  inputs: QuickTradeOptionsLandingInputs;
  isAnyApp: boolean;
  pltInputType: string;
  uniqueDateId: string;
  uniqueId: any;
  subscriptions = new Subscription();
  // content;
  cantfindOptionsClose: boolean = false;
  cantfindOptionsOpen: boolean = false;
  cantfindoptionsdropdown: boolean = false;
  uniqueName = "-quick-trade-options-big";
  underlyingQuote: any;
  optionQuote: any;
  manuallySearchAndClose = false;
  hasBuyToCover: boolean;
  selectedSymbol: any;
  symbolNoOption: boolean;
  query: string;
  symbolName: string;
  optionInfo: any = {};
  optionHoldings: any = [];
  dateParam: any;
  isPayFrom: boolean;
  settlementAccountsList: any = [];
  oldOptionType = '';
  formError: any = {};
  priceArray: any = [];
  expiryArray: any = [];
  disabledExpiryArray: any = [];
  actionArray: any = [];
  accountList: any = [];
  actionCloseArray: any = [];
  marketArray: any = [];
  optionHoldingsBuy: any = [];
  optionHoldingsSell: any = [];
  strikeDateList: any = [];
  strikePriceList: any = [];
  strikeDateLoading = false;
  strikePriceLoading = false;
  chainSubscription: any;
  selectedOptionHolding;
  isBalanceAvailable = false;
  readonly: boolean;
  pagePermission: any;
  datePageContent: any;
  monthList: any;
  dateSelectorParam: any;
  quoteError: any;
  optionQuoteError: any;
  oldActionMode = 'BUYTOOPEN';
  customRefresh = false;
  account;
  selectedAccount;
  settleAmountCurrency = '';
  quoteLoading = false;
  EAAErrorFlag = false;
  quoteHasError = false;
  symbolLoading: boolean = false;
  chainDetailsNoLoad = false;
  chainDateSelected: boolean = false;
  chainStrikePrice;
  chainExpiryDate;
  callGreekValues: any
  putGreekValues: any;
  hidePage: boolean;
  showOptionModal = false;
  buyingPowerLoading = false;
  dayList: any;
  isAccountEligibleForTrading: boolean;
  errorContentForAcc: any;
  idx = 0;
  balanceLoading = false;
  optionQuoteLoading = false;
  buyingPowerContent: any;
  selectionFlag: string;
  quoteSubscriber: any;
  quoteContent: any;
  displayNewMessageBlock = false;
  greekValues: any = {};
  singleAccount = false;
  chooseSymbol: boolean;
  errorQuote: boolean = false;
  sessionStoreEnable = true;
  sessionStore = { inFlow: {}, savedInfo: {} };
  selectedIndexForSell: any;
  quotesData: any;
  formSubmitted = false;
  selectedChainData: any;
  // selectedSymbolForStore: any;
  openTradeConfirmInterceptModal: boolean;
  doScrollUpForApp;
  settlementAccountListData: any;
  symbolFormValues: any;
  optionTooltipExpanded = [];
  quoteFromChildComponent: any;
  timestamp: any;
  get LOB() { return Lob; }
  priceToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  accountToolTipMobile: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  moreInfoTooltip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  actionToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  @ViewChild('quicktradeOptionForm') tradeOptionForm: any;
  showNav: boolean = true;
  lastScrollPos: number;
  notch: boolean;
  isScreenReaderON = false;
  isKeyboardOpen: boolean;
  // isOptionTradeDisabled: boolean;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.showNav = this.lastScrollPos > event.target.scrollingElement.scrollTop;
    if (event.target.scrollingElement.scrollTop <= 48) {
      this.showNav = true;
    }

    this.lastScrollPos = event.target.scrollingElement.scrollTop;
  }

  constructor(
    private router: Router,
    private contentService: ContentService,
    public store: QuickTradeOptionsLandingStore,
    public appStore: AppStore,
    public modalservice: ModalService,
    private service: QuickTradeOptionsLandingService,
    public content: ContentStore,
    public globalContent: GlobalContentStore,
    private subscriptionService: ObservableSubscriptionService,
    private commonService: CommonService,
    private currencyFormatterPipe: CurrencyFormatterPipe,
    private _helpService: HelpService,
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    private errorHandler: CustomAppErrorHandler,
    private quoteService: SymbolQuoteService,
    private persistenceService: PersistenceService,
    private trackingService: TrackingService,
    private optionChainService: OptionChainService,
    private modalService: ModalService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnDestroy() {
    this.storeArray[this.mode].crossFlow = undefined;
    this.storingOndestroy();
    this.subscriptions.unsubscribe();
    
   
  }

  storingOndestroy() {
    if (this.inputs) {
      if (this.mode == 'quickTrade') {
        if (this.formSubmitted) {
          this.storeArray[this.mode].inFlow = {
            quotesData: this.quotesData,
            optionHoldings: this.optionHoldings,
            selectedAccount: this.selectedAccount,
            settlementAccountListData: this.settlementAccountListData,
            selectedSymbol: this.selectedSymbol,
            inputs: JSON.parse(JSON.stringify(this.inputs)),
            settlementIndex: this.inputs.settlementIndex,
            selectedIndexForSell: this.selectedIndexForSell
          };
          const tempSymbol: any = { Market: this.inputs.market, SymbolName: this.inputs.symbol.toUpperCase(), SymbolType: 'OPTION' };
          if (this.inputs.optionHoldingIndex > -1) {
            const info = this.optionHoldings[this.inputs.optionHoldingIndex].Symbol.OptionInfo;
            tempSymbol.OptionInfo = { OptionType: info.OptionType, StrikeDate: info.StrikeDate, StrikePrice: { Value: parseFloat(this.formatRawData(info.StrikePrice.Value)) } }
          } else if (this.optionChainService.chainData["Inputs"]) {
            if (!this.isDesktop()) {
              // Mobile Change
              if (this.optionChainService.chainData["Inputs"] && this.optionChainService.chainData["Inputs"]['manualOptionType'] == "CALLS") {
                this.inputs.optionType = "C";
              } else if (this.optionChainService.chainData["Inputs"] && this.optionChainService.chainData["Inputs"]['manualOptionType'] == "PUTS") {
                this.inputs.optionType = "P";
              }
              tempSymbol.OptionInfo = { OptionType: this.inputs.optionType, StrikeDate: this.rawDateFormat(this.optionChainService.chainData["Inputs"]["optionStrikeDateIndex"]), StrikePrice: { Value: parseFloat(this.optionChainService.chainData["Inputs"]["optionStrikePriceIndex"]) } };
            } else {
              tempSymbol.OptionInfo = { OptionType: this.inputs.optionType, StrikeDate: this.strikeDateList[this.inputs.strikeDateIndex].Key, StrikePrice: { Value: parseFloat(this.formatRawData(this.strikePriceList[this.inputs.strikePriceIndex].Value.Data)) } };
            }
          }
          this.storeArray[this.mode].savedInfo = {
            inputs: JSON.parse(JSON.stringify(this.inputs)),
            selectedAccount: this.selectedAccount,
            quotesData: this.quotesData,
            optionHoldings: this.optionHoldings,
            settlementAccountListData: this.settlementAccountListData,
            selectedSymbol: this.selectedSymbol,
            settlementIndex: this.inputs.settlementIndex,
            selectedIndexForSell: this.selectedIndexForSell,
            chainInputs: null,
            symbolFormValues: this.symbolFormValues,
            AccountNumber: null,
            AccountCurrency: undefined,
            OptionInfo: tempSymbol.OptionInfo,
            symbol: this.inputs.symbol.toUpperCase(),
            market: this.inputs.market,
            account: this.account
          };
          if (isDefined(this.optionChainService.chainData["Inputs"])) {
            this.storeArray[this.mode].savedInfo["chainInputs"] = JSON.parse(JSON.stringify(this.optionChainService.chainData["Inputs"]))
          }
          if (this.inputs.action === "CLOSE" && this.inputs.actionClose == '' && this.inputs.optionHoldingIndex > -1) {
            this.storeArray[this.mode].savedInfo.closeActionType = this.optionHoldings[this.inputs.optionHoldingIndex].Action
          }
          if (this.inputs.action === "CLOSE" && this.inputs.actionClose != '') {
            this.storeArray[this.mode].savedInfo.closeActionType = this.inputs.actionClose
          }
          if (isDefined(this.optionChainService.inFlowData)) {
            this.storeArray[this.mode].savedInfo["inFlowData"] = JSON.parse(JSON.stringify(this.optionChainService.inFlowData))
          }
        } else {
          this.storeArray[this.mode].inFlow = {
            quotesData: this.quotesData,
            optionHoldings: this.optionHoldings,
            selectedAccount: this.selectedAccount,
            settlementAccountListData: this.settlementAccountListData,
            selectedSymbol: this.selectedSymbol,
            inputs: JSON.parse(JSON.stringify(this.inputs)),
            settlementIndex: this.inputs.settlementIndex,
            selectedIndexForSell: this.selectedIndexForSell
          };
          const tempSymbol: any = { Market: this.inputs.market, SymbolName: this.inputs.symbol.toUpperCase(), SymbolType: 'OPTION' };
          if (this.inputs.optionHoldingIndex > -1) {
            const info = this.optionHoldings[this.inputs.optionHoldingIndex].Symbol.OptionInfo;
            tempSymbol.OptionInfo = { OptionType: info.OptionType, StrikeDate: info.StrikeDate, StrikePrice: { Value: parseFloat(this.formatRawData(info.StrikePrice.Value)) } }
          } else if (this.optionChainService.chainData["Inputs"]) {
            if (!this.isDesktop()) {
              // Mobile Change
              if (this.optionChainService.chainData["Inputs"] && this.optionChainService.chainData["Inputs"]['manualOptionType'] == "CALLS") {
                this.inputs.optionType = "C";
              } else if (this.optionChainService.chainData["Inputs"] && this.optionChainService.chainData["Inputs"]['manualOptionType'] == "PUTS") {
                this.inputs.optionType = "P";
              }
              tempSymbol.OptionInfo = { OptionType: this.inputs.optionType, StrikeDate: this.rawDateFormat(this.optionChainService.chainData["Inputs"]["optionStrikeDateIndex"]), StrikePrice: { Value: parseFloat(this.optionChainService.chainData["Inputs"]["optionStrikePriceIndex"]) } };
            } else {
              tempSymbol.OptionInfo = { OptionType: this.inputs.optionType, StrikeDate: this.strikeDateList[this.inputs.strikeDateIndex].Key, StrikePrice: { Value: parseFloat(this.formatRawData(this.strikePriceList[this.inputs.strikePriceIndex].Value.Data)) } };
            }
          }
          this.storeArray[this.mode].savedInfo = {
            inputs: JSON.parse(JSON.stringify(this.inputs)),
            selectedAccount: this.selectedAccount,
            quotesData: this.quotesData,
            optionHoldings: this.optionHoldings,
            settlementAccountListData: this.settlementAccountListData,
            selectedSymbol: this.selectedSymbol,
            settlementIndex: this.inputs.settlementIndex,
            selectedIndexForSell: this.selectedIndexForSell,
            chainInputs: null,
            symbolFormValues: this.symbolFormValues,
            AccountNumber: null,
            AccountCurrency: undefined,
            OptionInfo: tempSymbol.OptionInfo,
            symbol: null,
            market: this.inputs.market,
            account: this.account
          };
          if (this.accountList[this.inputs.accountIndex]) {
            this.storeArray[this.mode].savedInfo.AccountNumber = this.accountList[this.inputs.accountIndex].AccountNumber;
          }
          if (this.inputs.symbol) {
            this.storeArray[this.mode].savedInfo.symbol = this.inputs.symbol.toUpperCase();
          }
          if (isDefined(this.optionChainService.chainData["Inputs"])) {
            this.storeArray[this.mode].savedInfo["chainInputs"] = JSON.parse(JSON.stringify(this.optionChainService.chainData["Inputs"]))
          }
          if (this.inputs.action === "CLOSE" && this.inputs.actionClose == ''  && this.inputs.optionHoldingIndex > -1) {
            this.storeArray[this.mode].savedInfo.closeActionType = this.optionHoldings[this.inputs.optionHoldingIndex].Action
          }
          if (this.inputs.action === "CLOSE" && this.inputs.actionClose != '') {
            this.storeArray[this.mode].savedInfo.closeActionType = this.inputs.actionClose
          }
          if (isDefined(this.optionChainService.inFlowData)) {
            this.storeArray[this.mode].savedInfo["inFlowData"] = JSON.parse(JSON.stringify(this.optionChainService.inFlowData))
          }
        }
        if (this.sessionStoreEnable) {
          this.sessionStore.inFlow = this.storeArray[this.mode].inFlow;
          console.log(this.sessionStore.inFlow);
          this.sessionStore.savedInfo = this.storeArray[this.mode].savedInfo;
          this.persistenceService.sessionStorePersist('quickOptionsLandingStore', JSON.stringify(this.sessionStore));
        }
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unLoadHandler($event) {
    this.storingOndestroy();
  }

  ngOnInit() {
    this.isAnyApp = this.appStore.isApp();
    this.uniqueDateId = this.appStore.uniqueId;
    this.uniqueId = this.appStore.uniqueId;

    if (this.appStore.platform === _User.Platform.IOS) {
      this.pltInputType = 'number';
    } else {
      this.pltInputType = 'text';
    }
    this.subscriptions.add(this.contentService.fetchContent('quicktradeoptionslanding').subscribe((data) => {
      this.content = data;
      console.log(this.content);

    }));


    this.subscriptions.add(this.contentService.fetchContent('buyingpowertooltip').subscribe((data) => {
      this.buyingPowerContent = data;
    }, () => {

    }));

    this.subscriptions.add(this.contentService.fetchContent('optionchain').subscribe((data) => {
      this.quoteContent = data;
    }, (err) => {

    }));

    if (this.mode == 'quickTrade') {
      // clear verify session
      this.persistenceService.removeSessionStoreValue('quickOptionsVerifyStore');
      // session to get on refresh
      const sessionValue = this.persistenceService.sessionStoreRetrieve('quickOptionsLandingStore');
      if (sessionValue != null && sessionValue != undefined && sessionValue != 'null') {
        console.log(JSON.parse(sessionValue));
        const jsonValue = JSON.parse(sessionValue);
        this.storeArray[this.mode].inFlow = jsonValue?.inFlow;
        this.storeArray[this.mode].savedInfo = jsonValue?.savedInfo;
      }

      const req: any = { IncludeBalance: true };
      this.spinnerService.setFocusElement(undefined);

      this.subscriptions.add(forkJoin({
        data: this.httpService.post(gatewayConfig.APIServices.optionsInit.url, req, { params: { skiploading: 'true' } }),
        accData: this.httpService.post(gatewayConfig.APIServices.holdingsNewLoad.url, {}, { params: { skiploading: 'true' } })
      }).subscribe(response => {
        const data = response.data;
        const accData = response.accData;
        this.pageSetup(data, accData);
      }, (err) => {
        this.accountList = [];
        this.inputs = this.store.state.input;
        this.inputs.action = '';
        this.inputs.symbol = '';
        this.inputs.optionHoldingIndex = -1;
        this.setActionArray();
        setTimeout(() => {
          this.dataLoaded.emit(true);
          this.subscriptionService.setCustomError(err.error.Exception);
          this.setFocus('closeBtn');
        }, 200);
      }));
    } else {
      this.uniqueName = '-quick-trade-options-small';
      this.pageSetup(this.data[0], this.data[1], this.data)
    }

    
    let self = this;
    this.subscriptions.add(this.subscriptionService.globalRefreshEvent$.subscribe((channel) => {
      if (this.mode == 'quickTrade') {
        if (channel == 'tradeOptions') {
          self.refreshQuote(true);
          this.customRefresh = false
        }
      } else {
        if (channel == 'header') {
          self.refreshQuote(true);
          this.customRefresh = false
        }
      }
    }));

    this.subscriptions.add(this.subscriptionService.quickOptionsCrossFlow.subscribe(() => {
      this.pageSetup(this.data, this.accData);
    }));

    this.subscriptions.add(this.subscriptionService.quickOptionsOpenOrCloseDrawer.subscribe((data) => {
      if (data == 'mobile') {
        if (isDefined(this.selectedSymbol) && isDefined(this.selectedSymbol.SymbolName)) {
          this.refreshQuote(true);
          setTimeout(() => {
            this.customRefresh = false
          }, 3000);
        }
        if (this.inputs && this.inputs.accountIndex > -1) {
          this.getBuyingPowerForSelectedAccount(this.inputs.accountIndex);
        }
        this.setFocus('closeBtn');
      }
    }));

    this.notch = this.commonService.isNotchPhone();
    this.isScreenReaderON = this.appStore.features.isScreenReaderON ? true : false;
    this.subscriptions.add(this.subscriptionService.isKeyboardOpen.subscribe(
      (data) => {
        this.isKeyboardOpen = data;
      }
    ));

    if (this.mode !== 'quickTrade') {
      this.commonService.logBraze("viewed_trade_options_details", { platform: "quickTrade"} );
    }
  }

  pageSetup(data, accData, fullData?) {
    if (data && accData) {
      this.data = data;
      this.accData = accData;
      this.accountList = this.mergeAccountDetails(data.AccountList, accData.AccountList);
      this.pagePermission = this.data.PermissionFlags;
      this.inputs = this.store.state.input;
      if (this.inputs.action === '') {
        this.inputs.action = 'BUYTOOPEN'
        this.service.actionType = this.inputs.action
      }
      if (this.inputs.action == 'CLOSE' && this.optionHoldings.length == 0) {
        this.setActionCloseArray();
      }
      this.inputs.optionHoldingIndex = -1;
      this.defineToolTips();
      this.setPriceArray();
      this.setExpiryArray();
      this.setActionArray();
      this.setMarketArray();
      this.setActionCloseArray();

    }

    if (isDefined(this.accountList)) {
      // Identify whether account balances available in Init Response. if not available dont show on account picker.
      for (const account of this.accountList) {
        if (isDefined(account.AccountBalances)) {
          this.isBalanceAvailable = true;
          this.accountList
          break;
        }
      }
      this.readonly = this.isAccountSelectionReadonly(this.accountList);
    }


    if (this.readonly && this.accountList.length === 1) {
      this.inputs.accountIndex = 0;
    } else {
      this.inputs.accountIndex = -1;
    }

    this.dateParam = this.data.DatePickerAdditionalInfo;
    this.datePageContent = this.data.DatePickerPageContentResp;

    const monthStart = moment(this.dateParam.MinGTDate).month();
    const monthEnd = moment(this.dateParam.MaxGTDate).month();

    if (monthStart <= monthEnd) {
      this.monthList = [];
      for (let i = monthStart; i < monthEnd + 1; i++) {
        this.monthList.push({ key: i, value: moment().locale(this.appStore.lang).localeData().months()[i] });
      }
    } else {
      this.monthList = [];
      for (let i = monthStart; i < 12; i++) {
        this.monthList.push({ key: i, value: moment().locale(this.appStore.lang).localeData().months()[i]});
      }
      for (let i = 0; i < monthEnd + 1; i++) {
        this.monthList.push({ key: i, value: moment().locale(this.appStore.lang).localeData().months()[i]});
      }
    }
    this.inputs.dateDay = moment(this.dateParam.DefaultGTDate).date();
    this.inputs.dateMonth = moment(this.dateParam.DefaultGTDate).month();


    this.dateSelectorParam = {
      dateParam: this.dateParam
    };

    this.subscriptions.add(this.contentService.fetchContent('dateselector').subscribe((data) => {
      this.dateSelectorParam.content = data;
    }, (err) => {

    }));
    if (isDefined(this.storeArray[this.mode].crossFlow) && this.storeArray[this.mode].crossFlow != null) {
      this.clearPage();
      OptionChainStore.crossFlow = true;
      if (this.storeArray[this.mode].crossFlow.action) {
        this.inputs.action = this.storeArray[this.mode].crossFlow.action;
        if ((this.storeArray[this.mode].crossFlow.action === "SELLTOCLOSE" || this.storeArray[this.mode].crossFlow.action === "BUYTOCLOSE") && (!this.isDesktop())) {
          this.inputs.action = "CLOSE"
        }
      } else if (!this.isDesktop()) {
        this.inputs.action = "BUYTOOPEN"
      }

      if (this.storeArray[this.mode].crossFlow.accountNumber) {
        // if (this.isDesktop()) {
        // this.inputs.accountIndex = this.setCarryOverAccount(TradeOptionsLandingStore.crossFlow.accountNumber);
        // } else {

        if (this.storeArray[this.mode].crossFlow.subAccountType === undefined) {
          let selectedAccount;
          for (let i = 0; i < this.accountList.length; i++) {
            if (this.accountList[i].AccountNumber === this.storeArray[this.mode].crossFlow.accountNumber) {
              const subaccount = [];
              for (let j = 0; j < this.accountList[i].Subaccounts.length; j++) {
                console.log(this.accountList[i].Subaccounts[j].AccountType, this.accountList[i].Subaccounts[j].Currency, this.storeArray[this.mode].crossFlow.currency)
                if (this.accountList[i].Subaccounts[j].Currency === this.storeArray[this.mode].crossFlow.currency &&
                  this.accountList[i].Subaccounts[j].AccountType != 'MRS') {
                  subaccount.push(this.accountList[i].Subaccounts[j]);
                }
              }
              if (subaccount.length == 1) {
                selectedAccount = {
                  AccountNumber: this.storeArray[this.mode].crossFlow.accountNumber,
                  AccountType: subaccount[0].AccountType,
                  Currency: subaccount[0].Currency
                }
              }
            }
          }
          if (selectedAccount) {
            this.AccountListReturn(selectedAccount, true);
          }
        } else {
          if (this.storeArray[this.mode].crossFlow.subAccountType == 'CSH') {
            this.storeArray[this.mode].crossFlow.subAccountType = 'CSHMRL'
          }
          let acc = {
            AccountNumber: this.storeArray[this.mode].crossFlow.accountNumber,
            AccountType: this.storeArray[this.mode].crossFlow.subAccountType,
            Currency: this.storeArray[this.mode].crossFlow.currency
          };
          this.AccountListReturn(acc, true)
        }
      }
      // }
      this.inputs.symbol = this.storeArray[this.mode].crossFlow.symbol;
      this.inputs.market = this.storeArray[this.mode].crossFlow.market;
      this.selectedSymbol = { SymbolName: this.inputs.symbol, Market: this.inputs.market };
      if (this.storeArray[this.mode].crossFlow.optionInfo) {
        this.selectedSymbol.OptionInfo = this.storeArray[this.mode].crossFlow.optionInfo;
      }
      if (this.storeArray[this.mode].crossFlow.selectedChainData && this.storeArray[this.mode].crossFlow.selectedChainData.Price) {
        this.inputs.priceMode = 'limitMode';
        this.inputs.limit = this.storeArray[this.mode].crossFlow.selectedChainData.Price
      }

      //this.updateSymbol(this.selectedSymbol);
      setTimeout(() => {
        this.customRefresh = false
      }, 3000);
      //fetch quote details
      setTimeout(() => {
        this.query = this.inputs.symbol.toUpperCase();
      }, 100);
      this.symbolNoOption = false;

      this.isPayFrom = (this.storeArray[this.mode].crossFlow.action != 'SELLTOOPEN' && this.storeArray[this.mode].crossFlow.action != 'SELLTOCLOSE');
      this.setActionArray();

      if (this.mode == 'quickTrade') {
        let crossFlow = this.storeArray[this.mode].crossFlow; // {action?, accountNumber, symbol, market}
        let accNum = crossFlow.accountNumber;
        let optionInfo = crossFlow.optionInfo;
        //this.optionInfo = crossFlow.OptionInfo;
        crossFlow.action = this.inputs.action;
        if (crossFlow.action == "SELLTOCLOSE" || crossFlow.action == "BUYTOCLOSE" || crossFlow.action == "CLOSE") {
          this.symbolLoading = true;
          const buyRequest = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: "BUYTOCLOSE", Refresh: false, Clean: false, SearchOptions: { StartRow: 0, NumberOfResult: 100, SearchFilter: '' } };
          const sellRequest = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: "SELLTOCLOSE", Refresh: false, Clean: false, SearchOptions: { StartRow: 0, NumberOfResult: 100, SearchFilter: '' } };

          forkJoin({
            chainDetail: this.service.getChain({ SymbolName: this.selectedSymbol.SymbolName, Market: this.selectedSymbol.Market }),
            quickQuote: this.service.getQuote(this.selectedSymbol),
            holdingsBuy: this.service.getAccountHoldings(buyRequest),
            holdingsSell: this.service.getAccountHoldings(sellRequest)
          })
            .subscribe((response) => {
              this.strikeDateList = response.chainDetail.ExpiryDate;
              this.strikePriceList = response.chainDetail.StrikePrice;

              this.inputs.optionType = crossFlow.optionInfo.OptionType;
              let strikeDate = crossFlow.optionInfo.StrikeDate;
              let strikeDateValue = this.strikeDateList.findIndex(object => (object.Key == strikeDate));
              this.inputs.strikeDateIndex = strikeDateValue;
              this.chainStrikePrice = crossFlow.optionInfo.StrikePrice;
              let strikePriceValue = this.strikePriceList.findIndex(object => (+object.Value.Data == +this.chainStrikePrice.Value))
              this.inputs.strikePriceIndex = strikePriceValue;
              if (this.inputs.strikeDateIndex > -1 && this.inputs.strikePriceIndex > -1 && this.inputs.optionType != '') {
                this.getOptionQuote();
              }

              this.symbolLoading = false;
              if (crossFlow.action == "CLOSE") {
                const buyData = response.holdingsBuy;
                const sellData = response.holdingsSell;


                if (buyData) {
                  if (buyData.SearchInfo.TotalNumberOfResult > 0) {
                    this.optionHoldingsBuy = buyData.Holdings.map(obj => ({ ...obj, Action: 'BUYTOCLOSE' }))
                  }
                }

                if (sellData) {
                  if (sellData.SearchInfo.TotalNumberOfResult > 0) {
                    this.optionHoldingsSell = sellData.Holdings.map(obj => ({ ...obj, Action: 'SELLTOCLOSE' }))
                  }
                }

                this.optionHoldings = this.optionHoldingsBuy.concat(this.optionHoldingsSell)
                this.inputs.chooseSymbol = this.inputs.symbol === '';
                if (this.optionHoldings.length > 0) {
                  this.inputs.chooseSymbol = true;
                  this.chainDetailsNoLoad = false;
                  this.resetFields();
                }

                if (this.optionHoldings.length === 0) {
                  this.cantfindoptionsdropdown = true
                }

                if (this.inputs.chooseSymbol && this.optionHoldings.length === 1) {
                  this.inputs.optionHoldingIndex = 0;
                  this.updateSelectedHolding(0);
                } else {
                  this.inputs.optionHoldingIndex = -1;
                  this.getSettlementAccount(crossFlow.action, '');
                }
              } else {
                if (response.holdingsBuy.Holdings) {
                  this.optionHoldings = response.holdingsBuy.Holdings.map(obj => ({ ...obj, Action: crossFlow.action }));
                }
                if (response.holdingsSell.Holdings) {
                  this.optionHoldings = response.holdingsSell.Holdings.map(obj => ({ ...obj, Action: crossFlow.action }));
                }
                if (this.optionHoldings === undefined) {
                  this.optionHoldings = [];
                }
              }

              if (this.optionHoldings && this.optionHoldings.length > 0) {
                let quant = crossFlow.quantity;
                if (isDefined(quant)) {
                  quant = quant.replace(/[^\d]/g, "");
                }
                this.inputs.optionHoldingIndex = _.findIndex(this.optionHoldings, (o: any) => {
                  return (o.Symbol.SymbolName === crossFlow.symbol) &&
                    o.Symbol.Market === crossFlow.market &&
                    o.Quantity.Data === quant &&
                    o.Symbol.OptionInfo.OptionType == crossFlow.optionInfo.OptionType &&
                    o.Symbol.OptionInfo.StrikeDate == crossFlow.optionInfo.StrikeDate &&
                    parseFloat(this.formatRawData(o.Symbol.OptionInfo.StrikePrice.Value)) == parseFloat(this.formatRawData(crossFlow.optionInfo.StrikePrice));
                });
                if (this.inputs.optionHoldingIndex > -1) {
                  this.inputs.market = this.optionHoldings[this.inputs.optionHoldingIndex].Symbol.Market;
                  this.optionHoldings[this.inputs.optionHoldingIndex].Action = crossFlow.action
                  this.selectedOptionHolding = this.optionHoldings[this.inputs.optionHoldingIndex]
                } else {
                  this.optionQuote = undefined;
                }

                if (this.inputs.optionHoldingIndex === -1) {
                  this.getSettlementAccount(this.inputs.action, '');
                  this.inputs.chooseSymbol = false;
                } else {
                  this.getSettlementAccount(this.inputs.action, this.optionHoldings[this.inputs.optionHoldingIndex].AccountCurrency);
                  this.inputs.chooseSymbol = true;
                }
              } else {
                this.optionQuote = undefined;
              }
              this.storeArray[this.mode].crossFlow = undefined;
            })
        } else if (crossFlow.action == "BUYTOOPEN" || crossFlow.action == "SELLTOOPEN") {
          this.optionHoldings = [];
          this.quoteLoading = true;
          this.EAAErrorFlag = false;
          this.subscriptions.add(
            forkJoin({
              chainDetail: this.service.getChain({ SymbolName: this.selectedSymbol.SymbolName, Market: this.selectedSymbol.Market }),
              quickQuote: this.service.getQuote(this.selectedSymbol)
            }).subscribe((response) => {
              this.strikeDateList = response.chainDetail.ExpiryDate;
              this.strikePriceList = response.chainDetail.StrikePrice;

              this.inputs.optionType = crossFlow.optionInfo.OptionType;
              let strikeDate = crossFlow.optionInfo.StrikeDate;
              let strikeDateValue = this.strikeDateList.findIndex(object => (object.Key == strikeDate));
              this.inputs.strikeDateIndex = strikeDateValue;
              this.chainStrikePrice = crossFlow.optionInfo.StrikePrice;
              let strikePriceValue = this.strikePriceList.findIndex(object => (+object.Value.Data == +this.chainStrikePrice))
              this.inputs.strikePriceIndex = strikePriceValue;
              if (this.inputs.strikeDateIndex > -1 && this.inputs.strikePriceIndex > -1 && this.inputs.optionType != '') {
                this.getOptionQuote();
              }  else if (this.selectedSymbol && this.selectedSymbol.hasOwnProperty('SymbolName')) {
                this.underlyingQuote = response.quickQuote.Quotes[0];
              }
              if (this.inputs.accountIndex != -1) {
                this.getSettlementAccount(this.inputs.action, '');
              }

              this.storeArray[this.mode].crossFlow = undefined;
              this.quoteLoading = false;
            }));
        }
      } else {
        if (this.storeArray[this.mode].crossFlow.action === 'SELLTOCLOSE' || this.storeArray[this.mode].crossFlow.action === 'BUYTOCLOSE' || this.storeArray[this.mode].crossFlow.action === 'CLOSE') {
          if (this.storeArray[this.mode].crossFlow.action === 'CLOSE') {
            let buyData;
            let sellData;
            buyData = fullData[4];
            sellData = fullData[5];

            if (buyData) {
              if (buyData.SearchInfo.TotalNumberOfResult > 0) {
                this.optionHoldingsBuy = buyData.Holdings.map(obj => ({ ...obj, Action: 'BUYTOCLOSE' }))
              }
            }

            if (sellData) {
              if (sellData.SearchInfo.TotalNumberOfResult > 0) {
                this.optionHoldingsSell = sellData.Holdings.map(obj => ({ ...obj, Action: 'SELLTOCLOSE' }))
              }
            }

            this.optionHoldings = this.optionHoldingsBuy.concat(this.optionHoldingsSell)

          } else {
            if (fullData[4].isOptionalError) {
              setTimeout(() => {
                // super.handleError(responses.extraRequests.holding.resp.error);
              }, 1);
            } else {
              this.optionHoldings = fullData[4].Holdings.map(obj => ({ ...obj, Action: this.storeArray[this.mode].crossFlow.action }));
              if (this.optionHoldings === undefined) {
                this.optionHoldings = [];
              }
            }
          }

          if (fullData[2].isOptionalError) {
            if (fullData[2].error && fullData[2].error.error && fullData[2].error.error.Exception) {
              this.quoteError = fullData[2].error.error.Exception.ErrorMessage;
            }
          } else {
            this.strikeDateList = fullData[2].ExpiryDate;
            this.strikePriceList = fullData[2].StrikePrice;
            if (fullData[3].isOptionalError) {
              this.getUnchainedQuote();
              if (fullData[3].error && fullData[3].error.error && fullData[3].error.error.Exception) {
                this.optionQuoteError = fullData[3].error.error.Exception.ErrorMessage;
              }
            } else {
              this.optionQuote = fullData[3].Quotes[0];
              this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
            }

            this.setCarryOverOptionInfo(this.storeArray[this.mode].crossFlow.optionInfo);

            if (this.optionHoldings && this.optionHoldings.length > 0) {
              let quant = this.storeArray[this.mode].crossFlow.quantity;
              if (isDefined(quant)) {
                quant = quant.replace(/[^\d]/g, "");
              }
              this.inputs.optionHoldingIndex = _.findIndex(this.optionHoldings, (o: any) => {
                return (o.Symbol.SymbolName === this.storeArray[this.mode].crossFlow.symbol) &&
                  o.Symbol.Market === this.storeArray[this.mode].crossFlow.market &&
                  o.Quantity.Data === quant &&
                  o.Symbol.OptionInfo.OptionType == this.storeArray[this.mode].crossFlow.optionInfo.OptionType &&
                  o.Symbol.OptionInfo.StrikeDate == this.storeArray[this.mode].crossFlow.optionInfo.StrikeDate &&
                  parseFloat(this.formatRawData(o.Symbol.OptionInfo.StrikePrice.Value)) == parseFloat(this.formatRawData(this.storeArray[this.mode].crossFlow.optionInfo.StrikePrice));
              });
              if (this.inputs.optionHoldingIndex > -1) {
                this.inputs.market = this.optionHoldings[this.inputs.optionHoldingIndex].Symbol.Market;
                this.optionHoldings[this.inputs.optionHoldingIndex].Action = this.storeArray[this.mode].crossFlow.action
                this.selectedOptionHolding = this.optionHoldings[this.inputs.optionHoldingIndex]
              } else {
                this.optionQuote = undefined;
              }

              if (this.inputs.optionHoldingIndex === -1) {
                this.getSettlementAccount(this.inputs.action, '');
                this.inputs.chooseSymbol = false;
              } else {
                this.getSettlementAccount(this.inputs.action, this.optionHoldings[this.inputs.optionHoldingIndex].AccountCurrency);
                this.inputs.chooseSymbol = true;
              }
            } else {
              this.optionQuote = undefined;
            }
          }

          // if (this.data[2].isOptionalError) {

          // } else {
          //   this.settlementAccountsList = this.data[2].SettlementAccounts;
          // }
        } else if (this.inputs.action === 'SELLTOOPEN' || this.inputs.action === 'BUYTOOPEN') {
          this.optionHoldings = [];

          if (fullData[2].isOptionalError) {
            if (fullData[2].error && fullData[2].error.error && fullData[2].error.error.Exception) {
              this.quoteError = fullData[2].error.error.Exception.ErrorMessage;
            }
          } else {
            this.strikeDateList = fullData[2].ExpiryDate;
            this.strikePriceList = fullData[2].StrikePrice;
            if (fullData[3].isOptionalError) {
              this.getUnchainedQuote();
              if (fullData[3].error && fullData[3].error.error && fullData[3].error.error.Exception) {
                this.optionQuoteError = fullData[3].error.error.Exception.ErrorMessage;
              }
            } else {
              this.optionQuote = fullData[3].Quotes[0];
              this.underlyingQuote = fullData[3].Quotes[0].UnderlyingSymbolQuote;
            }

            this.setCarryOverOptionInfo(this.storeArray[this.mode].crossFlow.optionInfo);
          }
          this.getSettlementAccount(this.inputs.action, '');

          // if (fullData[1].isOptionalError) {

          // } else {
          //   this.settlementAccountsList = fullData[1].SettlementAccounts;
          // }
        } else {
          if (fullData[2].isOptionalError) {
            if (fullData[2].error && fullData[2].error.error && fullData[2].error.error.Exception) {
              this.quoteError = fullData[2].error.error.Exception.ErrorMessage;
            }
          } else {
            this.strikeDateList = fullData[2].ExpiryDate;
            this.strikePriceList = fullData[2].StrikePrice;
            if (fullData[3].isOptionalError) {
              this.getUnchainedQuote();
              if (fullData[3].error && fullData[3].error.error && fullData[3].error.error.Exception) {
                this.quoteError = fullData[3].error.error.Exception.ErrorMessage;
              }
            } else {
              this.optionQuote = fullData[3].Quotes[0];
              this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
            }

            this.setCarryOverOptionInfo(this.storeArray[this.mode].crossFlow.optionInfo);
          }
          if (this.inputs.accountIndex != -1 && this.inputs.action) {
            this.getSettlementAccount(this.inputs.action, '');
          }
        }
      }

    }
    else if (isDefined(this.storeArray[this.mode].savedInfo) && this.storeArray[this.mode].savedInfo != null) {
      this.inputs = JSON.parse(JSON.stringify(this.storeArray[this.mode].savedInfo.inputs));

      if (this.inputs.symbol) {
        setTimeout(() => {
          this.query = this.inputs.symbol.toUpperCase();
        }, 100);
        this.symbolNoOption = false;
      }
      
      const selectedValueOfSymbol = this.storeArray[this.mode].savedInfo?.selectedSymbol;
      if (selectedValueOfSymbol !== undefined && selectedValueOfSymbol !== null && selectedValueOfSymbol.SymbolName) {
        if(this.storeArray[this.mode].savedInfo?.inputs?.action !== 'CLOSE') {
          this.selectedSymbol = selectedValueOfSymbol;
          this.updateSymbol(selectedValueOfSymbol, true);
        }
      }

      // const actionFromStore = this.storeArray[this.mode].savedInfo.inputs?.action;
      // if (actionFromStore !== undefined && actionFromStore !== null && actionFromStore !== '') {
      //   this.inputs.action = actionFromStore;
      //   console.log(this.inputs.action);
      //   if (this.inputs.action == 'SELLTOCLOSE' || this.inputs.action == "BUYTOCLOSE") {
      //     this.inputs.action = 'CLOSE';
      //   }
      //   this.changeActionMode(this.inputs.action, true);
      // } else {
      //   this.changeActionMode('BUYTOOPEN', true);
      // }

      this.isPayFrom = (this.inputs.action != 'SELLTOOPEN' && this.inputs.action != 'SELLTOCLOSE');
      if (this.storeArray[this.mode].savedInfo.selectedAccount) {
        this.selectedAccount = this.storeArray[this.mode].savedInfo.selectedAccount;
        this.AccountListReturn(this.selectedAccount, true);
      }

      const settlementIndexFromStore = this.storeArray[this.mode].savedInfo.settlementIndex;
      if (settlementIndexFromStore !== undefined && settlementIndexFromStore !== null) {
        this.settlementAccountListData = this.storeArray[this.mode].savedInfo.settlementAccountListData;
        if(this.settlementAccountListData) {
          this.settlementAccountsList = this.settlementAccountListData.SettlementAccounts;
        }
        this.inputs.settlementIndex = settlementIndexFromStore;
      }

      const actionFromStore = this.storeArray[this.mode].savedInfo.inputs?.action;
      if (actionFromStore !== undefined && actionFromStore !== null && actionFromStore !== '') {
        this.inputs.action = actionFromStore;
      } else {
        this.changeActionMode('BUYTOOPEN');
      }


      const priceModeFromStore = this.storeArray[this.mode].savedInfo.inputs?.priceMode;
      if (priceModeFromStore == undefined || priceModeFromStore == null || priceModeFromStore == '') {
        if (this.selectedAccount !== undefined && this.selectedAccount == null) {
          this.changePriceMode('marketMode');
        }
      }

      if (this.mode == 'quickTrade') {
        if (isDefined(this.storeArray[this.mode].savedInfo["inFlowData"])) {
          this.optionChainService.setInFlow(this.storeArray[this.mode].savedInfo["inFlowData"]);
        }
        const savedInfo = this.storeArray[this.mode].savedInfo;
        // if (this.selectedSymbol && isDefined(savedInfo.chainInputs)) {
        //     this.subscriptions.add(
        //     forkJoin({
        //       chainDetail: this.service.getChain({ SymbolName: this.selectedSymbol.SymbolName, Market: this.selectedSymbol.Market })
        //     }).subscribe((response) => {
        //       this.strikeDateList = response.chainDetail.ExpiryDate;
        //       this.strikePriceList = response.chainDetail.StrikePrice;
        //       this.getOptionQuoteTriggeredFromManualEntry({ strikeDate: savedInfo.chainInputs.optionStrikeDateIndex, strikePrice: savedInfo.chainInputs.optionStrikePriceIndex, optionType: savedInfo.chainInputs.manualOptionType });
        //     }));
          
        // }
        if (savedInfo.inputs.action == "SELLTOCLOSE" || savedInfo.inputs.action == "BUYTOCLOSE" || savedInfo.inputs.action == "CLOSE") {
          this.symbolLoading = true;
          const buyRequest = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: "BUYTOCLOSE", Refresh: false, Clean: false, SearchOptions: { StartRow: 0, NumberOfResult: 100, SearchFilter: '' } };
          const sellRequest = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: "SELLTOCLOSE", Refresh: false, Clean: false, SearchOptions: { StartRow: 0, NumberOfResult: 100, SearchFilter: '' } };
          
          forkJoin({
            holdingsBuy: this.service.getAccountHoldings(buyRequest),
            holdingsSell: this.service.getAccountHoldings(sellRequest)
          })
            .subscribe((response) => {
              this.symbolLoading = false;
              if (savedInfo.inputs.action == "CLOSE") {
                const buyData = response.holdingsBuy;
                const sellData = response.holdingsSell;


                if (buyData) {
                  if (buyData.SearchInfo.TotalNumberOfResult > 0) {
                    this.optionHoldingsBuy = buyData.Holdings.map(obj => ({ ...obj, Action: 'BUYTOCLOSE' }))
                  }
                }

                if (sellData) {
                  if (sellData.SearchInfo.TotalNumberOfResult > 0) {
                    this.optionHoldingsSell = sellData.Holdings.map(obj => ({ ...obj, Action: 'SELLTOCLOSE' }))
                  }
                }

                this.optionHoldings = this.optionHoldingsBuy.concat(this.optionHoldingsSell);
                this.selectedOptionHolding = this.optionHoldings[this.inputs.optionHoldingIndex];
              } else {
                if (response.holdingsBuy.Holdings) {
                  this.optionHoldings = response.holdingsBuy.Holdings.map(obj => ({ ...obj, Action: savedInfo.inputs.action }));
                }
                if (response.holdingsSell.Holdings) {
                  this.optionHoldings = response.holdingsSell.Holdings.map(obj => ({ ...obj, Action: savedInfo.inputs.action }));
                }
                if (this.optionHoldings === undefined) {
                  this.optionHoldings = [];
                }
              }
              
              this.inputs.chooseSymbol = this.inputs.symbol === '';

              if (this.optionHoldings.length > 0) {
                this.inputs.chooseSymbol = true;
                this.chainDetailsNoLoad = false;
                this.resetFields();
                const optionHoldingIndexFromStore = this.storeArray[this.mode].savedInfo.inputs.optionHoldingIndex;
                if (optionHoldingIndexFromStore !== undefined && optionHoldingIndexFromStore !== null) {
                  this.inputs.optionHoldingIndex = optionHoldingIndexFromStore;
                  this.updateSelectedHolding(this.inputs.optionHoldingIndex, true);
                }
                if (this.inputs.chooseSymbol && this.optionHoldings.length === 1) {
                  this.inputs.optionHoldingIndex = 0;
                  this.updateSelectedHolding(0, true);
                }
              }
              if (this.optionHoldings.length === 0) {
                this.cantfindoptionsdropdown = true;
                this.inputs.optionHoldingIndex = -1;
              }
              //  else {
              //   this.getSettlementAccount(this.inputs.action, '');
              // }
              const settlementAccountListData = this.storeArray[this.mode].savedInfo.settlementAccountListData;
              if (settlementAccountListData !== undefined && settlementAccountListData !== null) {
                this.settlementAccountsList = settlementAccountListData;
                if (this.settlementAccountsList === undefined) {
                  this.settlementAccountsList = [];
                }
                if (this.settlementAccountsList.length === 1) {
                  this.inputs.settlementIndex = 0;
                  this.updateSelectedPaymentAccount();
                }
                if (this.settlementAccountsList.length > 1) {
                  this.inputs.settlementIndex = this.settlementAccountsList.findIndex(setAcc => setAcc.Currency == this.settleAmountCurrency);
                }
              }
            });
        } 
        else if (savedInfo.inputs.action == "BUYTOOPEN" || savedInfo.inputs.action == "SELLTOOPEN") {
          this.optionHoldings = [];
          console.log(this.optionChainService.chainData);
          if (!this.selectedSymbol) {
            return;
          }
          this.subscriptions.add(
            forkJoin({
              chainDetail: this.service.getChain({ SymbolName: this.selectedSymbol.SymbolName, Market: this.selectedSymbol.Market }),
              quickQuote: this.service.getQuote(this.selectedSymbol)
            }).subscribe((response) => {
              this.strikeDateList = response.chainDetail.ExpiryDate;
              this.strikePriceList = response.chainDetail.StrikePrice;

              this.inputs.optionType = savedInfo.OptionInfo.OptionType;
              let strikeDate = savedInfo.OptionInfo.StrikeDate;
              let strikeDateValue = this.strikeDateList.findIndex(object => (object.Key == strikeDate));
              this.inputs.strikeDateIndex = strikeDateValue;
              this.chainStrikePrice = savedInfo.OptionInfo.StrikePrice;
              let strikePriceValue = this.strikePriceList.findIndex(object => (+object.Value.Data == +this.chainStrikePrice.Value))
              this.inputs.strikePriceIndex = strikePriceValue;
              if (this.inputs.strikeDateIndex > -1 && this.inputs.strikePriceIndex > -1 && this.inputs.optionType != '') {
                this.getOptionQuote();
              }
            }));
        }

      } else {

        if (this.inputs.action === 'SELLTOCLOSE' || this.inputs.action === 'BUYTOCLOSE' || this.inputs.action === 'CLOSE') {
          if (this.inputs.action === 'CLOSE') {
            let buyData;
            let sellData;
            if (this.inputs.chooseSymbol && this.inputs.optionHoldingIndex !== -1) {
              buyData = fullData[4];
              sellData = fullData[5];
            } else {
              buyData = fullData[5];
              sellData = fullData[6];
            }

            if (buyData) {
              if (buyData.SearchInfo.TotalNumberOfResult > 0) {
                this.optionHoldingsBuy = buyData.Holdings.map(obj => ({ ...obj, Action: 'BUYTOCLOSE' }))
              }
            }

            if (sellData) {
              if (sellData.SearchInfo.TotalNumberOfResult > 0) {
                this.optionHoldingsSell = sellData.Holdings.map(obj => ({ ...obj, Action: 'SELLTOCLOSE' }))
              }
            }

            this.optionHoldings = this.optionHoldingsBuy.concat(this.optionHoldingsSell)
            this.selectedOptionHolding = this.optionHoldings[this.inputs.optionHoldingIndex]

          } else {
            if (this.inputs.chooseSymbol && this.inputs.optionHoldingIndex !== -1) {
              if (fullData[4] && fullData[4].isOptionalError) {

              } else if(fullData[4]){
                this.optionHoldings = fullData[4].Holdings.map(obj => ({ ...obj, Action: this.inputs.action }));
                if (this.optionHoldings === undefined) {
                  this.optionHoldings = [];
                }
              }
            } else {
              if (fullData[5] && fullData[5].isOptionalError) {

              } else if(fullData[5]){
                this.optionHoldings = fullData[5].Holdings;
                if (this.optionHoldings === undefined) {
                  this.optionHoldings = [];
                }
              }
            }
          }

          if (this.inputs.chooseSymbol && this.inputs.optionHoldingIndex !== -1) {
            if (fullData[3] && fullData[3].isOptionalError) {
              if (fullData[3].error && fullData[3].error.error && fullData[3].error.error.Exception) {
                this.quoteError = fullData[3].error.error.Exception.ErrorMessage;
              }
            } else {
              this.optionQuote = fullData[3].Quotes[0];
              this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
            }
          } else {
            if (fullData[3] && fullData[3].isOptionalError) {
              if (fullData[3].error && fullData[3].error.error && fullData[3].error.error.Exception) {
                this.quoteError = fullData[3].error.error.Exception.ErrorMessage;
              }
            } else if(fullData[3]){
              this.strikeDateList = fullData[3].ExpiryDate;


              this.strikePriceList = fullData[3].StrikePrice;
              if (fullData[4] && fullData[4].isOptionalError) {
                this.getUnchainedQuote();
                if (fullData[4].error && fullData[4].error.error && fullData[4].error.error.Exception) {
                  this.quoteError = fullData[4].error.error.Exception.ErrorMessage;
                }
              } else if(fullData[4]){
                this.optionQuote = fullData[4].Quotes[0];
                this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
              }
            }
          }

          if (fullData[2] && fullData[2].isOptionalError) {

          } else if(fullData[2]){
            this.settlementAccountsList = fullData[2].SettlementAccounts;
          }
        } else {
          this.optionHoldings = [];

          if (this.inputs.chooseSymbol && this.inputs.optionHoldingIndex !== -1) {
            if (fullData[3] && fullData[3].isOptionalError) {
              if (fullData[3].error && fullData[3].error.error && fullData[3].error.error.Exception) {
                this.quoteError = fullData[3].error.error.Exception.ErrorMessage;
              }
            } else if(fullData[3]){
              this.optionQuote = fullData[3].Quotes[0];
              this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
            }
          } else {
            if (fullData[3] && fullData[3].isOptionalError) {
              if (fullData[3].error && fullData[3].error.error && fullData[3].error.error.Exception) {
                this.quoteError = fullData[2].error.error.Exception.ErrorMessage;
              }
            } else if(fullData[3]){
              this.strikeDateList = fullData[3].ExpiryDate;

              this.strikePriceList = fullData[3].StrikePrice;
              if (fullData[4] && fullData[4].isOptionalError) {
                this.getUnchainedQuote();
                if (fullData[3].error && fullData[4].error.error && fullData[4].error.error.Exception) {
                  this.optionQuoteError = fullData[4].error.error.Exception.ErrorMessage;
                }
              } else if(fullData[4]) {
                this.optionQuote = fullData[4].Quotes[0];
                this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
              }
            }
          }

          if (fullData[2] && fullData[2].isOptionalError) {

          } else if(fullData[2]){
            this.settlementAccountsList = fullData[2].SettlementAccounts;
          }
        }

        if (this.optionHoldings && this.optionHoldings.length > 0) {

          if (this.inputs.optionHoldingIndex === -1) {
            this.chooseSymbol = false;
          } else {
            this.chooseSymbol = true;
          }
        } else {
          this.optionHoldings = [];
          this.inputs.optionHoldingIndex = -1;
          this.chooseSymbol = false;
        }
        if (this.settlementAccountsList && this.settlementAccountsList.length === 1) {
          this.inputs.settlementIndex = 0;
          this.updateSelectedPaymentAccount();
        }
      }

      if (this.storeArray[this.mode].savedInfo["chainInputs"] !== undefined && this.storeArray[this.mode].savedInfo["chainInputs"] !== null) {
        this.optionChainService.chainData["Inputs"] = this.storeArray[this.mode].savedInfo["chainInputs"]
      }

      if (isDefined(this.inputs.action)) {
        this.oldActionMode = this.inputs.action;
        console.log(this.oldActionMode);
      }

      if (!this.isDesktop()) {
        this.prevPageRetain();
      }
    }


    this.calcMonthDays(this.inputs.dateMonth);

    if (this.accountList.length === 1) {
      this.getBuyingPowerForSelectedAccount(0);
    } else if (this.inputs.accountIndex !== -1) {
      this.getBuyingPowerForSelectedAccount(this.inputs.accountIndex);
    }

    setTimeout(() => {
      this.dataLoaded.emit(true);
      this.setFocus('closeBtn');
    }, 200);
  }

  prevPageRetain() {
    let savedInfo = this.storeArray[this.mode].savedInfo;
    console.log("savedInfosavedInfosavedInfosavedInfo", savedInfo);

    // account selector
    if (isDefined(savedInfo) && isDefined(savedInfo["selectedAccount"])) {
      this.selectedAccount = savedInfo["selectedAccount"];
      this.settleAmountCurrency = this.selectedAccount["Currency"];
      this.account = savedInfo["account"];

      this.selectedSymbol = savedInfo["selectedSymbol"];
    }
  }

  defineToolTips() {
    this.priceToolTip.openBtnLabel = '';
    this.priceToolTip.cancel = this.globalContent.text.closeTooltip;
    this.priceToolTip.content = '';
    this.priceToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;

    this.accountToolTipMobile.openBtnLabel = '';
    this.accountToolTipMobile.cancel = this.globalContent.text.closeTooltip;
    this.accountToolTipMobile.content = '';
    this.accountToolTipMobile.btnHiddenText = this.globalContent.text.tooltipHiddenText;

    this.actionToolTip.openBtnLabel = '';
    this.actionToolTip.cancel = this.globalContent.text.closeTooltip;
    this.actionToolTip.content = '';
    this.actionToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;

    this.moreInfoTooltip.openBtnLabel = '';
    this.moreInfoTooltip.cancel = this.globalContent.text.closeTooltip;
    this.moreInfoTooltip.content = '';
    this.moreInfoTooltip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
  }

  clearPage() {
    this.inputs.accountIndex = -1;
    this.selectedAccount = undefined;
    this.inputs.action = 'BUYTOOPEN'
    this.inputs.priceMode = 'marketMode';
    this.inputs.actionClose = '';
    this.cantfindOptionsClose = false;
    this.cantfindOptionsOpen = false;
    this.cantfindoptionsdropdown = false;
    this.optionQuote = undefined;
    this.underlyingQuote = undefined;
    this.manuallySearchAndClose = false
    this.selectedChainData = undefined;
    this.selectedOptionHolding = undefined;
    this.hasBuyToCover = false;
    this.selectedSymbol = {};
    this.symbolNoOption = true;
    this.query = this.inputs.symbol;
    this.inputs.symbol = '';
    this.EAAErrorFlag = false;
    this.quoteError = undefined;
    this.optionQuoteError = undefined;
    this.quoteHasError = false;
    setTimeout(() => {
      this.query = '';
    }, 10);
    this.symbolName = '';
    this.optionInfo = {};
    this.optionHoldings = [];
    this.inputs.optionHoldingIndex = -1;
    this.inputs.chooseSymbol = false;
    this.inputs.market = '';
    this.inputs.quantity = undefined;
    this.inputs.limit = undefined;
    this.inputs.expiryMode = 'day';
    this.inputs.actionClose = '';
    if (this.dateParam) {
      this.inputs.dateDay = moment(this.dateParam.DefaultGTDate).date();
      this.inputs.dateMonth = moment(this.dateParam.DefaultGTDate).month();
    }
    this.isPayFrom = true;
    this.settlementAccountsList = [];
    this.inputs.strikePriceIndex = -1;
    this.inputs.strikeDateIndex = -1;
    this.inputs.optionType = '';
    this.oldOptionType = '';
    this.formError = {};
    this.cantfindOptionsClose = false;
    this.cantfindOptionsOpen = false;
    this.cantfindoptionsdropdown = false;
    this.setActionArray();
    this.setPriceArray();
    this.setExpiryArray();
    this.setMarketArray();
    // super.clearError();
    this.selectedAccount = null;
    // this.selectedSymbolForStore = null;
    this.storeArray[this.mode].inFlow = null;
    this.storeArray[this.mode].savedInfo = null;
    this.subscriptionService.setCustomError('RESET');
    this.subscriptionService.setError('RESET');
    this.persistenceService.removeSessionStoreValue('quickOptionsLandingStore');
  }

  isAccountSelectionReadonly(accountList) {
    return accountList.length === 1 || accountList.length === 0;
  }
  //not using below
  setCarryOverAccount(accountNum) {
    for (let i = 0; i < this.accountList.length; i++) {
      if (this.accountList[i].AccountNumber == accountNum) {
        return i;
      }
    }

    return -1;
  }

  setCarryOverOptionInfo(optionInfo) {
    this.inputs.strikeDateIndex = -1;
    this.inputs.strikePriceIndex = -1;
    if (isDefined(this.strikeDateList)) {
      for (let i = 0; i < this.strikeDateList.length; i++) {
        if (optionInfo.StrikeDate == this.strikeDateList[i].Key) {
          this.inputs.strikeDateIndex = i;
        }
      }
    }
    if (isDefined(this.strikePriceList)) {
      for (let i = 0; i < this.strikePriceList.length; i++) {
        if (parseFloat(optionInfo.StrikePrice) == parseFloat(this.strikePriceList[i].Value.Data)) {
          this.inputs.strikePriceIndex = i;
        }
      }
    }
    this.inputs.optionType = optionInfo.OptionType;
  }


  AccountListReturn(account, crossFlow?) {

    this.subscriptionService.setCustomError('RESET');
    this.subscriptionService.setError('RESET');
    if (this.account !== undefined) {
      if (this.account.AccountNumber !== account.AccountNumber) {
        this.checkOptionTradingEnabled(account.AccountNumber);
      }
    } else {
      this.checkOptionTradingEnabled(account.AccountNumber);
    }
    this.account = account;
    const index = this.accountList.findIndex(acc => acc.AccountNumber == account.AccountNumber);
    if (isDefined(index)) {
      this.formError.accountNumber = undefined;
      if (this.inputs.accountIndex > -1 && this.inputs.optionHoldingIndex > -1) {
        this.resetFields();
        // super.clearError();
        this.subscriptionService.setError('RESET');
      }

      // this.formError.accountNumber = undefined;

      // this.checkOptionTradingEnabled(account.AccountNumber);
      
      this.inputs.accountIndex = index;
      this.selectedAccount = account;
      this.settleAmountCurrency = account.Currency;
      this.selectedOptionHolding = undefined;
      this.cantfindOptionsOpen = false;
      this.cantfindOptionsClose = false;
      this.cantfindoptionsdropdown = false
      
      // if (!crossFlow) {
      //   this.inputs.action = 'BUYTOOPEN';
      //   this.inputs.expiryMode = 'day';
      //   this.inputs.limit = undefined;
      //   this.inputs.actionClose = '';
      //   this.inputs.quantity = undefined;
      //   if (this.isDesktop()) {
      //     this.inputs.priceMode = '';
      //   } else {
      //     this.inputs.priceMode = 'marketMode';
      //   }
      // }

      this.manuallySearchAndClose = false;
      if (this.dateParam && !crossFlow) {
        this.inputs.dateDay = moment(this.dateParam.DefaultGTDate).date();
        this.inputs.dateMonth = moment(this.dateParam.DefaultGTDate).month();
      }
      if (!crossFlow) {
        this.getBuyingPowerForSelectedAccount(index);
      }
      const detectMobileIOS = this.getMobileOperatingSystem();
      if (detectMobileIOS === 'iOS') {
        this.setActionArray();
        this.setFocus('TradeOptionsLandingactionToggle' + this.uniqueName + '-1');
        this.setIOSDropdownFocus('ddButtonquicktradeOptions');
      }
      if (this.inputs.action !== '') {
        if ((this.inputs.action === 'BUYTOCLOSE' || this.inputs.action === 'SELLTOCLOSE' || this.inputs.action === 'CLOSE') && !crossFlow) {
          if (this.inputs.action === 'CLOSE') {
            this.getoptionholdings('CLOSE');
          } else {
            this.getoptionholdings(this.inputs.action);
          }
        } else if(!crossFlow){
          this.inputs.settlementIndex = -1;  
          this.getSettlementAccount(this.inputs.action, '');
        }
      }
    }
  }

  checkOptionTradingEnabled(AccountNumber) {
    this.formError.accountNumber = undefined;
    this.service.isAccountOptionEnabledOrNot(AccountNumber).subscribe((data) => {
      this.formError.accountNumber = undefined;
      this.isAccountEligibleForTrading = true;
    }, (err: HttpErrorResponse) => { 
      if (err.status === 406) {

        if (err.error.Exception.ErrorCode === ErrorConstant.OPTIONTRADEDISABLED) {
          this.formError.accountNumber = { message: err.error.Exception.ErrorMessage };
          this.errorContentForAcc = { message: err.error.Exception.ErrorMessage };
        }
        else if (err.error.Exception.ErrorCode === ErrorConstant.TRADEDISABLED) {
          this.formError.accountNumber = this.content.error.MSGOPT0021;
          this.errorContentForAcc = this.content.error.MSGOPT0021;
          console.log('account check', this.formError.accountNumber, this.errorContentForAcc)
        } else{
        this.isAccountEligibleForTrading = false
        this.formError.accountNumber = this.content.error.MSGOPT0019
        }
        this.tradeOptionForm.controls['accountNumber'].setErrors({ 'incorrect': true })
          this.tradeOptionForm.controls['accountNumber'].markAsTouched();
          TradeOptionsValidator.setErrorMessage([{
            controlName: 'accountNumber',
            code: this.tradeOptionForm.controls['accountNumber'].errors
          }], err.error.Exception
          )
      }
    });
  }
  //we are not using below
  getAccountType(account) {
    return this.globalContent.list.accountType[account.AccountType] ? this.globalContent.list.accountType[account.AccountType] : '';
  }

  updateSymbol(symbol, extras?) {
    this.selectedSymbol = symbol
    this.query = '';
    setTimeout(() => {
      this.query = (symbol.SymbolName).toUpperCase();
    }, 10);
    this.inputs.market = symbol.Market;
    this.formError.marketMode = undefined;
    this.formError.strikeDateIndex = undefined;
    this.formError.strikePriceIndex = undefined;
    this.formError.optionType = undefined;
    this.inputs.optionType = '';
    if(!extras) {
      this.inputs.limit = undefined;
    }
    this.oldOptionType = '';
    this.inputs.strikePriceIndex = -1;
    this.inputs.strikeDateIndex = -1;
    this.handleGetQuote(symbol, extras);
    //this.storeArray[this.mode].savedInfo = undefined;
  }

  updateCantfindSymbol(event) {
    if (event) {
      this.cantfindOptionsOpen = true;
    }
  }

  handleGetQuote(symbol, extras) {
    console.log("symbol, extras,symbol, extras", symbol, extras);

    // @ifdef DEBUG 
    this.symbolNoOption = false;
    this.selectionFlag = undefined;
    if (extras === 'optionQuote') {
      symbol.SymbolType = 'OPTION';
      this.inputs.symbol = symbol.SymbolName;
      this.selectedSymbol = { SymbolName: (symbol.SymbolName).toUpperCase(), Market: symbol.Market, SymbolType: 'OPTION', OptionInfo: symbol.OptionInfo };
      this.inputs.market = symbol.Market;
      this.underlyingQuote = undefined;
      this.getOptionQuote();
    } else {
      symbol.SymbolType = 'EQUITY';
      this.optionInfo = symbol.OptionInfo;
      this.inputs.symbol = symbol.SymbolName;
      this.selectedSymbol = { SymbolName: (symbol.SymbolName).toUpperCase(), Market: symbol.Market, SymbolType: 'EQUITY' };
      console.log(this.selectedSymbol);
      this.inputs.market = symbol.Market;
      this.getChainedQuote();
    }
  }

    refreshQuote(refreshOption?) {
      this.quoteError = undefined;
      this.optionQuoteError = undefined;
      if (this.inputs.strikeDateIndex > -1 && this.inputs.strikePriceIndex > -1 && this.inputs.optionType !== '') {
        let req: any = {};
        req = {
          SymbolName: this.selectedSymbol.SymbolName,
          Market: this.selectedSymbol.Market,
          SymbolType: 'OPTION',
          OptionInfo: {
            OptionType: this.inputs.optionType,
            StrikeDate: this.strikeDateList[this.inputs.strikeDateIndex].Key,
            StrikePrice: {
              Value: parseFloat(this.formatRawData(this.strikePriceList[this.inputs.strikePriceIndex].Value.Data))
            }
          }
        };
        this.quoteLoading = true;
        this.optionQuoteLoading = true;
        this.chainDetailsNoLoad = true;
        this.EAAErrorFlag = false;
        this.service.getQuote(req).subscribe((data: any) => {
          this.quotesData = data
          if (refreshOption) {
            this.customRefresh = true;
            if (this.mode == 'quickTrade') {
              this.subscriptionService.updateRefreshTime({ channel: 'tradeOptions', count: 1 });
              this.subscriptionService.startRefreshTime({ channel: 'tradeOptions', count: 1 });
            } else {
              this.subscriptionService.updateRefreshTime({ channel: 'header', count: 1 });
              this.subscriptionService.startRefreshTime({ channel: 'header', count: 1 });
            }
          }
          this.optionQuote = data.Quotes[0];
          this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
          console.log('Quote' + JSON.stringify(this.optionQuote));
          // if (data2.quote === undefined) {
          //   this.noResults = true;
          // } else {
          //   this.noResults = false;
          // }
          this.optionQuoteLoading = false;
          this.quoteLoading = false;
          this.chainDetailsNoLoad = false;
  
        },
          (err: HttpErrorResponse) => {
            // && err.error.Exception.ErrorCode === "UISYSFR0011"
            this.optionQuote = undefined;
            this.optionQuoteLoading = false;
            this.quoteLoading = false;
            this.chainDetailsNoLoad = false;
            if (err.status === 406) {
              if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
                this.EAAErrorFlag = true;
                this.quoteError = err.error.Exception.ErrorMessage;
              } else {
                this.subscriptionService.setCustomError(err.error.Exception);
              }
            } else if (isDefined(err.error)) {
              if (isDefined(this.underlyingQuote)) {
                this.optionQuoteError = err.error.Exception.ErrorMessage;
              } else {
                this.quoteError = err.error.Exception.ErrorMessage;
              }
            }
          });
      } else if (this.optionHoldings.length > 0 && this.inputs.optionHoldingIndex > -1 && this.inputs.chooseSymbol) {
        this.quoteLoading = true;
        this.optionQuoteLoading = true;
        this.EAAErrorFlag = false;
        this.service.getQuote(this.selectedSymbol).subscribe((data: any) => {
          
          if (refreshOption) {
            this.customRefresh = true;
            if (this.mode == 'quickTrade') {
              this.subscriptionService.updateRefreshTime({ channel: 'tradeOptions', count: 1 });
              this.subscriptionService.startRefreshTime({ channel: 'tradeOptions', count: 1 });
            } else {
              this.subscriptionService.updateRefreshTime({ channel: 'header', count: 1 });
              this.subscriptionService.startRefreshTime({ channel: 'header', count: 1 });
            }
          }
          this.optionQuote = data.Quotes[0];
          this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
          console.log('Quote' + JSON.stringify(this.underlyingQuote));
          // if (data2.quote === undefined) {
          //   this.noResults = true;
          // } else {
          //   this.noResults = false;
          // }
          this.quoteLoading = false;
          this.optionQuoteLoading = false;
  
        },
          (err: HttpErrorResponse) => {
            this.underlyingQuote = undefined;
            this.optionQuote = undefined;
            this.quoteLoading = false;
            this.optionQuoteLoading = false;
            if (err.status === 406) {
              if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
                this.EAAErrorFlag = true;
                this.quoteError = err.error.Exception.ErrorMessage;
              } else {
                this.subscriptionService.setCustomError(err.error.Exception);
              }
            } else if (isDefined(err.error)) {
              console.log('Quote' + JSON.stringify(this.underlyingQuote));
              this.quoteError = err.error.Exception.ErrorMessage;
            }
          });
      } else if (this.selectedSymbol && this.selectedSymbol.hasOwnProperty('SymbolName')) {
        this.quoteLoading = true;
        this.EAAErrorFlag = false;
        this.service.getQuote(this.selectedSymbol).subscribe((data: any) => {
          if (refreshOption) {
            this.customRefresh = true;
            if (this.mode == 'quickTrade') {
              this.subscriptionService.updateRefreshTime({ channel: 'tradeOptions', count: 1 });
              this.subscriptionService.startRefreshTime({ channel: 'tradeOptions', count: 1 });
            } else {
              this.subscriptionService.updateRefreshTime({ channel: 'header', count: 1 });
              this.subscriptionService.startRefreshTime({ channel: 'header', count: 1 });
            }
          }
         
          this.underlyingQuote = data.Quotes[0];
          console.log('Quote' + JSON.stringify(this.underlyingQuote));
          // if (data2.quote === undefined) {
          //   this.noResults = true;
          // } else {
          //   this.noResults = false;
          // }
          this.quoteLoading = false;
  
        },
          (err: HttpErrorResponse) => {
            this.quoteLoading = false;
            this.underlyingQuote = undefined;
            if (err.status === 406) {
              if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
                this.EAAErrorFlag = true;
                this.quoteError = err.error.Exception.ErrorMessage;
              } else {
                this.subscriptionService.setCustomError(err.error.Exception);
              }
            } else if (isDefined(err.error)) {
              console.log('Quote' + JSON.stringify(this.underlyingQuote));
              this.quoteError = err.error.Exception.ErrorMessage;
            }
          });
      }
    }

    getChainedQuote() {
      this.quoteError = undefined;
      this.quoteHasError = false
      this.optionQuoteError = undefined;
      this.quoteLoading = true;
      this.EAAErrorFlag = false;
      this.chainDetailsNoLoad = false;
      this.service.getChain({ SymbolName: this.selectedSymbol.SymbolName, Market: this.selectedSymbol.Market }).subscribe((data) => {
        if (data) {
          this.strikeDateList = data.ExpiryDate;
          this.strikePriceList = data.StrikePrice;
          // this.sendQuoteOmniture();
          if (isDefined(this.strikeDateList) && this.strikeDateList.length !== 0
            && isDefined(this.strikePriceList) && this.strikePriceList.length !== 0) {
            this.chainDetailsNoLoad = true;
            if (this.isDesktop()) {
              this.optionQuote = undefined;
            }
            this.service.getQuote(this.selectedSymbol).subscribe((data2: any) => {
              this.underlyingQuote = data2.Quotes[0];
              console.log('Quote' + JSON.stringify(this.underlyingQuote));
              // if (data2.quote === undefined) {
              //   this.noResults = true;
              // } else {
              //   this.noResults = false;
              // }
              this.quoteLoading = false;
  
            },
              (err: HttpErrorResponse) => {
                // this.symbolNoOption = true;
                this.quoteLoading = false;
                if (err.status === 406) {
                  console.log(err.error.Exception.ErrorCode)
                  this.quoteHasError = true;
                  if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
                    this.EAAErrorFlag = true;
                    this.symbolNoOption = false;
                    this.quoteError = err.error.Exception.ErrorMessage;
                  } else if (this.chainDetailsNoLoad && err.error.Exception.ErrorCode === 'UIUSRBR0009') {
                    // Bypassing Error in this case as we can go ahead without quote data when chaindetails is present
                    // i.e. case for underlying quotes i.e. ATD => Equity quote = ATD.B
                  } else {
                    this.subscriptionService.setCustomError(err.error.Exception);
                  }
                } else if (isDefined(err.error)) {
                  console.log('Quote' + JSON.stringify(this.underlyingQuote));
                  this.quoteError = err.error.Exception.ErrorMessage;
                }
              });
          } else {
            this.quoteLoading = false;
            // let err: HttpErrorResponse = new HttpErrorResponse({
            //   error: {
            //     Exception: {
            //       message: this.content.error.MSGOPT0060,
            //       errorCode: "UIBUSFI0013"
            //     }
            //   },
            //   status: 406
            // });
            this.formError.symbol = this.content.error.MSGOPT0060;
            this.symbolNoOption = true;
            // throw err;
          }
        }
      },
        (err: HttpErrorResponse) => {
          this.symbolNoOption = true;
          this.quoteLoading = false;
          if (err.status === 406) {
            console.log(err.error.Exception.ErrorCode)
            if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
              this.EAAErrorFlag = true;
              this.symbolNoOption = false;
              this.quoteError = err.error.Exception.ErrorMessage;
            } else {
              this.subscriptionService.setCustomError(err.error.Exception);
            }
          } else if (isDefined(err.error)) {
            const exception = err.error.Exception;
            if (isDefined(exception)) {
              if (isDefined(exception[0])) {
                this.quoteError = exception[0].ErrorMessage;
              } else {
                this.quoteError = exception.ErrorMessage;
              }
            } else {
              this.symbolNoOption = true;
            }
          } else {
            this.symbolNoOption = true;
          }
        });
    }

    getUnchainedQuote() {
      this.quoteError = undefined;
      this.quoteLoading = true;
  
      if (isDefined(this.strikeDateList) && this.strikeDateList.length !== 0 && isDefined(this.strikePriceList) && this.strikePriceList.length !== 0) {
        this.optionQuote = undefined;
        this.EAAErrorFlag = false;
        this.service.getQuote(this.selectedSymbol).subscribe((data2: any) => {
          this.underlyingQuote = data2.Quotes[0];
          console.log('Quote' + JSON.stringify(this.underlyingQuote));
          this.quoteLoading = false;
  
        },
          (err: HttpErrorResponse) => {
            this.symbolNoOption = true;
            this.optionQuoteError = undefined;
            this.quoteLoading = false;
            if (err.status === 406) {
              console.log(err.error.Exception.ErrorCode)
              this.quoteHasError = true;
              if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
                this.EAAErrorFlag = true;
                this.symbolNoOption = false;
                this.quoteError = err.error.Exception.ErrorMessage;
              } else {
                this.subscriptionService.setCustomError(err.error.Exception);
              }
            } else if (isDefined(err.error)) {
              console.log('Quote' + JSON.stringify(this.underlyingQuote));
              this.quoteError = err.error.Exception.ErrorMessage;
            }
          });
      } else {
        this.optionQuoteError = undefined;
        this.quoteLoading = false;
        this.formError.symbol = this.content.error.MSGOPT0060;
        this.symbolNoOption = true;
      }
    }

  getChain(req) {
    if (this.selectionFlag === 'expiry') {
      req.ExpiryDate = this.strikeDateList[this.inputs.strikeDateIndex].Key;
      this.strikePriceLoading = true;
    } else if (this.selectionFlag === 'price') {
      req.StrikePrice = parseFloat(this.formatRawData(this.strikePriceList[this.inputs.strikePriceIndex].Value.Data));
      this.strikeDateLoading = true;
    }

    if (this.chainSubscription) {
      this.chainSubscription.unsubscribe();
      this.chainSubscription = undefined;
    }
    this.chainSubscription = this.service.getChain(req).subscribe((data) => {
      if (this.selectionFlag === 'expiry') {
        this.strikePriceList = data.StrikePrice;
        this.inputs.strikePriceIndex = -1;
      } else if (this.selectionFlag === 'price') {
        this.strikeDateList = data.ExpiryDate;

        this.inputs.strikeDateIndex = -1;
      }
      this.strikeDateLoading = false;
      this.strikePriceLoading = false;
    }, () => {

    });
  }

    getOptionQuote() {
      let req: any = {};
      this.quoteHasError = false;
      if (this.inputs.strikeDateIndex > -1 && this.inputs.strikePriceIndex > -1 && this.inputs.optionType != '') {
        this.quoteError = undefined;
        this.optionQuoteError = undefined;
        req = {
          SymbolName: this.selectedSymbol.SymbolName,
          Market: this.selectedSymbol.Market,
          SymbolType: 'OPTION',
          OptionInfo: {
            OptionType: this.inputs.optionType,
            StrikeDate: this.strikeDateList[this.inputs.strikeDateIndex].Key,
            StrikePrice: {
              Value: parseFloat(this.formatRawData(this.strikePriceList[this.inputs.strikePriceIndex].Value.Data))
            }
          }
        };
        this.optionQuoteLoading = true;
        this.EAAErrorFlag = false;
  
        if (isDefined(this.quoteSubscriber)) {
          this.quoteSubscriber.unsubscribe();
        }
        this.quoteSubscriber = this.service.getQuote(req).subscribe((data: any) => {
          this.optionQuote = data.Quotes[0];
          this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
          console.log('Quote' + JSON.stringify(this.optionQuote));
          // if (data2.quote === undefined) {
          //   this.noResults = true;
          // } else {
          //   this.noResults = false;
          // }
          this.optionQuoteLoading = false;
          this.quoteLoading = false;
  
        },
          (err: HttpErrorResponse) => {
            this.optionQuoteLoading = false;
            this.quoteLoading = false;
            if (err.status === 406) {
              this.quoteHasError = true;
              if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
                this.EAAErrorFlag = true;
                this.quoteError = err.error.Exception.ErrorMessage;
              } else {
                this.subscriptionService.setCustomError(err.error.Exception);
              }
            } else if (isDefined(err.error)) {
              this.optionQuoteError = err.error.Exception.ErrorMessage;
            }
          });
      } else if (isDefined(this.optionHoldings) && this.optionHoldings.length > 0 && this.inputs.chooseSymbol) {
        this.quoteError = undefined;
        this.optionQuoteError = undefined;
        const sym = this.optionHoldings[this.inputs.optionHoldingIndex].Symbol;
        req = {
          SymbolName: sym.SymbolName,
          Market: sym.Market,
          SymbolType: 'OPTION',
          OptionInfo: {
            OptionType: sym.OptionInfo.OptionType,
            StrikeDate: sym.OptionInfo.StrikeDate,
            StrikePrice: {
              Value: parseFloat(this.formatRawData(sym.OptionInfo.StrikePrice.Value))
            }
          }
        };
        this.quoteLoading = true;
        this.EAAErrorFlag = false;
        if (isDefined(this.quoteSubscriber)) {
          this.quoteSubscriber.unsubscribe();
        }
        this.quoteSubscriber = this.service.getQuote(req).subscribe((data: any) => {
          this.optionQuote = data.Quotes[0];
          this.underlyingQuote = this.optionQuote.UnderlyingSymbolQuote;
          console.log('Quote' + JSON.stringify(this.optionQuote));
          // if (data2.quote === undefined) {
          //   this.noResults = true;
          // } else {
          //   this.noResults = false;
          // }
          this.optionQuoteLoading = false;
          this.quoteLoading = false;
  
        },
          (err: HttpErrorResponse) => {
            this.optionQuoteLoading = false;
            this.quoteLoading = false;
            if (err.status === 406) {
              this.quoteHasError = true
              if (err.error.Exception.ErrorCode === ErrorConstant.QUOTEEAAERROR) {
                this.EAAErrorFlag = true;
                this.quoteError = err.error.Exception.ErrorMessage;
              } else {
                this.subscriptionService.setCustomError(err.error.Exception);
              }
            } else if (isDefined(err.error)) {
              this.quoteError = err.error.Exception.ErrorMessage;
            }
          });
      }
    }

  getOptionQuoteTriggeredFromManualEntry(event) {
    this.inputs.optionType = event.optionType.charAt(0);
    let strikeDate = this.rawDateFormat(event.strikeDate)
    let strikeDateValue = this.strikeDateList.findIndex(object => (object.Key == strikeDate))
    this.inputs.strikeDateIndex = strikeDateValue
    this.chainStrikePrice = event.strikePrice;
    let strikePriceValue = this.strikePriceList.findIndex(object => (object.Value.Data == this.chainStrikePrice))
    this.inputs.strikePriceIndex = strikePriceValue
    if (this.inputs.strikeDateIndex > -1 && this.inputs.strikePriceIndex > -1 && this.inputs.optionType != '') {
      this.getOptionQuote();
    }
  }

  getOptionQuoteTriggeredFromChain(event) {
    let type = event.optionType.charAt(0)
    if (type == 'B' && event.manualOptionType == "CALLS") {
      this.inputs.optionType = 'C'
    } else if (type == 'B' && event.manualOptionType == "PUTS") {
      this.inputs.optionType = 'P'
    } else if (type == 'B' && event.manualOptionType == "") {
      this.inputs.optionType = ''
    } else {
      this.inputs.optionType = event.optionType.charAt(0);
    }
    let strikeDate = this.rawDateFormat(event.strikeDate)
    let strikeDateValue = this.strikeDateList.findIndex(object => (object.Key == strikeDate))
    this.inputs.strikeDateIndex = strikeDateValue
    this.chainStrikePrice = event.strikePrice;
    let strikePriceValue = this.strikePriceList.findIndex(object => (object.Value.Data == this.chainStrikePrice))
    this.inputs.strikePriceIndex = strikePriceValue
    if (this.inputs.strikeDateIndex > -1 && this.inputs.strikePriceIndex > -1 && this.inputs.optionType != '') {
      this.getOptionQuote();
    }
  }

  updateStrikePrice(e) {
    this.optionInfo.strikePrice = e;
  }
  updateStrikeDate(e) {
    if (e) {
      this.optionInfo.strikeDate = this.rawDateFormat(e)
    }
  }
  updateOptionType(e) {
    console.log('-----------', e);
    this.setSavedOptionInfo(e);
  }

  setSavedOptionInfo(e, price?, marketMode?) {
    this.symbolFormValues = {};
    if (e.optionStrikeDateIndex !== -1) {
      this.symbolFormValues.StrikeDate = e.optionStrikeDateIndex;
    }
    if (e.optionType !== '') {
      this.symbolFormValues.OptionType = e.optionType;
    }
    if (e.optionStrikePriceIndex !== -1) {
      this.symbolFormValues.StrikePrice = e.optionStrikePriceIndex;
    }
  }

  sendQuoteOmniture() {
    // RootService.omniturePageName("Trade Options>Trade Options Get Quote");
  }

  ChangeSymbolName(name) {
    if (name != this.inputs.symbol) {
      this.inputs.symbol = name;
      this.inputs.market = '';
      this.selectedSymbol = {};
      this.strikeDateList = undefined;
      this.strikePriceList = undefined;
      this.inputs.strikeDateIndex = -1;
      this.inputs.strikePriceIndex = -1;
      this.underlyingQuote = undefined;
      this.optionQuote = undefined;
      this.quoteError = undefined;
      this.optionQuoteError = undefined;
      this.EAAErrorFlag = false;
      this.quoteHasError = false;
      this.formError.symbol = undefined;
      this.symbolNoOption = true;
      this.inputs.quantity = undefined;
      this.inputs.limit = undefined;
      this.inputs.priceMode = 'marketMode';
      this.inputs.expiryMode = 'day';
      this.storeArray[this.mode].savedInfo = undefined;
    }
  }

  clearManualQuote(e) {
    if (e.keyCode === 9)/*Tab*/ {
      //do nothing
    } else {
      this.selectedSymbol = {};
      this.strikeDateList = undefined;
      this.strikePriceList = undefined;
      this.inputs.strikeDateIndex = -1;
      this.inputs.strikePriceIndex = -1;
      this.underlyingQuote = undefined;
      this.optionQuote = undefined;
      this.quoteError = undefined;
      this.optionQuoteError = undefined;
      this.formError.symbol = undefined;
      this.symbolNoOption = true;
      this.storeArray[this.mode].savedInfo = undefined;
    }
  }

  resetMarket() {
    this.inputs.market = '';
    this.underlyingQuote = undefined;
    this.optionQuote = undefined;
    this.quoteError = undefined;
    this.optionQuoteError = undefined;
  }

  changeMarketMode(selectedMarket) {
    this.inputs.market = selectedMarket;
    this.formError.symbol = undefined;
    this.formError.marketMode = undefined;
    this.formError.strikeDateIndex = undefined;
    this.formError.strikePriceIndex = undefined;
    this.formError.optionType = undefined;
    this.query = this.inputs.symbol;
    this.inputs.symbol = this.inputs.symbol.trim();
    let symbol = {
      SymbolName: this.inputs.symbol.toUpperCase(),
      SymbolType: 'EQUITY',
      Market: selectedMarket
    };
    this.underlyingQuote = undefined;
    this.optionQuote = undefined;
    this.quoteError = undefined;
    this.optionQuoteError = undefined;

    setTimeout(() => {
      this.query = this.inputs.symbol.toUpperCase();
    }, 100);

    if (this.inputs.symbol !== '') {
      this.inputs.optionType = '';
      this.oldOptionType = '';
      this.inputs.strikePriceIndex = -1;
      this.inputs.strikeDateIndex = -1;
      this.handleGetQuote(symbol, 'market');
    }
  }

    changeActionMode(actionMode, crossFlow?) {
      let clearForm = true;
      if ((this.oldActionMode === undefined) || this.oldActionMode === '') {
        clearForm = false;
      }
      if (clearForm && this.inputs.optionHoldingIndex > -1) {
        this.resetFields();
      }
      if (isDefined(this.oldActionMode)) {
        if (this.oldActionMode.indexOf('OPEN') > -1 && actionMode.indexOf('CLOSE') > -1) {
          this.optionQuote = undefined;
          this.underlyingQuote = undefined
          this.query = '';
          this.symbolName = '';
        }
      }
      this.formError.actionMode = undefined;
      this.inputs.actionClose = undefined
      this.inputs.action = actionMode;
      this.oldActionMode = actionMode;
      this.service.actionType = this.inputs.action
      this.isPayFrom = (actionMode != 'SELLTOOPEN' && actionMode != 'SELLTOCLOSE');
      this.setExpiryCustomDisabled();
      this.setActionArray();
      this.cantfindOptionsOpen = false;
      this.cantfindOptionsClose = false;
      this.cantfindoptionsdropdown = false;
      this.selectedOptionHolding = undefined;
      if (!this.isDesktop()) {
        this.hideOrderPrice();
      }
      if (this.inputs.accountIndex > -1 && isDefined(this.accountList[this.inputs.accountIndex].AccountNumber)) {
        const restActionMode = actionMode;
        this.manuallySearchAndClose = false;
        if (actionMode === 'BUYTOCLOSE' || actionMode === 'SELLTOCLOSE' || this.inputs.action === 'CLOSE') {
          this.getoptionholdings(restActionMode);
        } else {
          this.optionHoldings = [];
          if (restActionMode === '' || (restActionMode === undefined)) {
            this.settlementAccountsList = [];
          }
          else {
            this.getSettlementAccount(restActionMode, '');
            
          }
        }
  
  
        // this.modelObj.selSettlementAccount = undefined;
      }
      if (!crossFlow) {
        this.storeArray[this.mode].savedInfo = undefined;
      }
    }

  changeActionCloseMode(actionMode) {
    let clearForm = true;
    if ((this.oldActionMode === undefined) || this.oldActionMode === '') {
      clearForm = false;
    }
    if (clearForm && this.inputs.optionHoldingIndex > -1) {
      this.resetFields();
    }
    if (isDefined(this.oldActionMode)) {
      if (actionMode.indexOf('CLOSE') > -1) {
        //this.optionQuote = undefined;
        //this.underlyingQuote = undefined
      }
    }
    this.formError.actionMode = undefined;
    this.inputs.action = this.inputs.actionClose;
    this.oldActionMode = this.inputs.action;
    this.service.actionType = this.inputs.action
    this.isPayFrom = (actionMode != 'SELLTOOPEN' && actionMode != 'SELLTOCLOSE');
    this.setExpiryCustomDisabled()
    this.selectedOptionHolding = undefined;
    this.hideOrderPrice();
    this.optionHoldings = [];

    if (this.inputs.accountIndex > -1 && isDefined(this.accountList[this.inputs.accountIndex].AccountNumber)) {
      this.manuallySearchAndClose = true;
      this.getSettlementAccount(actionMode, '')
    }
    this.storeArray[this.mode].savedInfo = undefined;
  }

  changePriceMode(priceMode) {
    this.formError.priceMode = undefined;
    this.setExpiryCustomDisabled();
    this.formError.limit = undefined;
    this.inputs.dateDay = moment(this.dateParam.DefaultGTDate).date();
    this.inputs.dateMonth = moment(this.dateParam.DefaultGTDate).month();
    this.inputs.priceMode = priceMode;

    if (priceMode === 'marketMode' || priceMode === '') {
      this.changeExpiryMode('day');
      this.inputs.limit = undefined;
    }

    if (priceMode === 'marketMode') {
      this.priceToolTip.content = '';
      this.priceToolTip.title = '';
    } else if (priceMode === 'limitMode') {
      this.priceToolTip.content = '';
      this.priceToolTip.title = '';
    }
    this.storeArray[this.mode].savedInfo = undefined;
  }

  changeExpiryMode(expiryMode) {
    if (expiryMode === 'customDate' && this.showCustomDate()) {
      this.inputs.expiryMode = expiryMode;
      this.formError.expiryMode = undefined;
      this.formError.selectedDate = undefined;
    }
    if (expiryMode === 'day') {
      this.inputs.dateDay = moment(this.dateParam.DefaultGTDate).date();
      this.inputs.dateMonth = moment(this.dateParam.DefaultGTDate).month();
      this.calcMonthDays(this.inputs.dateMonth);
      this.inputs.expiryMode = expiryMode;
      this.formError.expiryMode = undefined;
      this.formError.selectedDate = undefined;
    }
    this.storeArray[this.mode].savedInfo = undefined;
  }

  callQuote() {
    if (this.inputs.symbol && this.inputs.market ) {
      this.changeMarketMode(this.inputs.market)
    }
  }

  getSettlementAccount(restActionMode, currency) {

    if (restActionMode === 'CLOSE') {
      if (this.selectedOptionHolding) {
        if (this.selectedOptionHolding.Action) {
          restActionMode = this.selectedOptionHolding.Action
        } else {
          restActionMode = "BUYTOCLOSE"
        }
      } else {
        restActionMode = "BUYTOCLOSE"
      }
    }
    let request: any;
    if (currency === '') {
      request = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: restActionMode };
    } else {
      request = { AccountCurrency: currency, AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: restActionMode };
    }


    this.service.getSettlementAccounts(request).subscribe(
      (data: any) => {
        if (data) {
          this.settlementAccountListData = data;
          this.settlementAccountsList = data.SettlementAccounts;
          if (this.settlementAccountsList === undefined) {
            this.settlementAccountsList = [];
          }
          if (this.settlementAccountsList.length === 1) {
            this.inputs.settlementIndex = 0;
            this.updateSelectedPaymentAccount();
          }
          if (this.settlementAccountsList.length > 1) {
            this.inputs.settlementIndex = this.settlementAccountsList.findIndex(setAcc => setAcc.Currency == this.settleAmountCurrency);
          }

        }
      },
      (err) => { })
  }

  getoptionholdings(restActionMode) {
    let m = this.optionHoldings.length > 0;
    this.optionHoldings = this.optionHoldingsSell = this.optionHoldingsBuy = [];
    // if (restActionMode == 'CLOSE') {
      this.symbolLoading = true;
      const buyRequest = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: "BUYTOCLOSE", Refresh: false, Clean: false, SearchOptions: { StartRow: 0, NumberOfResult: 100, SearchFilter: '' } };
      const sellRequest = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: "SELLTOCLOSE", Refresh: false, Clean: false, SearchOptions: { StartRow: 0, NumberOfResult: 100, SearchFilter: '' } };
      this.subscriptions.add(forkJoin({
        buyData: this.service.getAccountHoldings(buyRequest),
        sellData: this.service.getAccountHoldings(sellRequest)
      }).subscribe(response => {
        const buyData = response.buyData;
        const sellData = response.sellData;
        this.symbolLoading = false;

        if (buyData) {
          if (buyData.SearchInfo.TotalNumberOfResult > 0) {
            this.optionHoldingsBuy = buyData.Holdings.map(obj => ({ ...obj, Action: 'BUYTOCLOSE' }))
          }
        }

        if (sellData) {
          if (sellData.SearchInfo.TotalNumberOfResult > 0) {
            this.optionHoldingsSell = sellData.Holdings.map(obj => ({ ...obj, Action: 'SELLTOCLOSE' }))
          }
        }

        this.optionHoldings = this.optionHoldingsBuy.concat(this.optionHoldingsSell)
        this.inputs.chooseSymbol = this.inputs.symbol === '';
        if (this.optionHoldings.length > 0) {
          this.inputs.chooseSymbol = true;
          this.chainDetailsNoLoad = false;
          this.resetFields();
        }

        if (this.optionHoldings.length === 0) {
          // this.manuallySearchAndClose = true;
          // this.cantfindOptionsClose = true;
          // if(this.inputs.actionClose == ''){
          //   this.inputs.actionClose = 'BUYTOCLOSE'
          // }
          // console.log(this.inputs.action);
          // this.inputs.action = this.inputs.actionClose;
          // console.log(this.inputs.action);
          this.cantfindoptionsdropdown = true
        }
        if (this.inputs.chooseSymbol && this.optionHoldings.length === 1) {
          this.inputs.optionHoldingIndex = 0;
          this.updateSelectedHolding(0);
        } else {
          this.inputs.optionHoldingIndex = -1;
          if(this.optionHoldings.length > 0) {
            this.getSettlementAccount(restActionMode, '');
          }
        }

        if (m && this.optionHoldings.length === 0) {
          this.formError.optionHoldingIndex = undefined;
          this.formError.symbol = undefined;
        } else if (!m && this.optionHoldings.length > 0) {
          this.formError.optionHoldingIndex = undefined;
          this.formError.symbol = undefined;
        }

      }, (err) => {

      }));
    // } else {
    //   this.symbolLoading = true;
    //   const request = { AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber, Action: restActionMode, Refresh: false, Clean: false, SearchOptions: { StartRow: 0, NumberOfResult: 100, SearchFilter: '' } };
    //   this.service.getAccountHoldings(request).subscribe(
    //     (data: any) => {
    //       if (data) {
    //         this.symbolLoading = false;
    //         if (data.SearchInfo.TotalNumberOfResult > 0) {
    //           this.optionHoldings = data.Holdings.map(obj => ({ ...obj, Action: restActionMode }));
    //         } else {
    //           this.optionHoldings = [];
    //         }
    //         this.inputs.chooseSymbol = this.inputs.symbol === '';
    //         if (this.optionHoldings.length > 0) {
    //           this.inputs.chooseSymbol = true;
    //           this.chainDetailsNoLoad = false;
    //           this.resetFields();
    //         }

    //         if (this.optionHoldings.length === 0) {
    //           // this.manuallySearchAndClose = true;
    //           // this.cantfindoptionsdropdown = true;
    //         }
            
    //         if (this.inputs.chooseSymbol && this.optionHoldings.length === 1) {
    //           this.inputs.optionHoldingIndex = 0;
    //           this.updateSelectedHolding(0);
    //         } else {
    //           this.inputs.optionHoldingIndex = -1;
    //           if(this.optionHoldings.length > 0) {
    //             this.getSettlementAccount(restActionMode, '');
    //           }
    //         }

    //         if (m && this.optionHoldings.length === 0) {
    //           this.formError.optionHoldingIndex = undefined;
    //           this.formError.symbol = undefined;
    //         } else if (!m && this.optionHoldings.length > 0) {
    //           this.formError.optionHoldingIndex = undefined;
    //           this.formError.symbol = undefined;
    //         }
    //       } else {
    //         console.log('no data');
    //         this.symbolLoading = false;
    //         this.inputs.chooseSymbol = false;
    //         this.getSettlementAccount(restActionMode, '');
    //       }
    //     },
    //     () => {
    //       this.getSettlementAccount(restActionMode, '');
    //       this.symbolLoading = false;
    //       // super.handleError(err);
    //     });
    // }
  }

  //cant find security : When close has nil optionHoldings display empty dropdown with cant find options.
  updateCantFindOptions(){
    this.cantfindoptionsdropdown = false;
    this.manuallySearchAndClose = true;
    this.cantfindOptionsClose = true;
    this.inputs.actionClose = 'BUYTOCLOSE';
    this.setMarketArray();
    this.resetFields();
  }


  updateSelectedHolding(index, crossFlow?) {

    // cant find security : last option selected from list
    if (index == this.optionHoldings.length) {
      this.toggleHoldingChoose();
      return;
    }
    this.manuallySearchAndClose = false;
    this.formError.symbol = undefined;
    this.formError.optionHoldingIndex = undefined;
    this.underlyingQuote = undefined;
    this.optionQuote = undefined;
    this.inputs.limit = undefined;
    if(!crossFlow) {
      this.storeArray[this.mode].savedInfo = undefined;
    }
    
    if (index !== -1 && index !== null && index !== undefined) {
      this.inputs.optionHoldingIndex = index;
      this.selectedIndexForSell = index;
      const symbol = this.optionHoldings[index].Symbol;
      this.inputs.optionType = '';
      this.oldOptionType = '';
      this.inputs.strikePriceIndex = -1;
      this.inputs.strikeDateIndex = -1;
      this.setCarryOverOptionInfo(symbol.OptionInfo)
      this.handleGetQuote(symbol, 'optionQuote');
      this.inputs.settlementIndex = -1;
      this.getSettlementAccount(this.optionHoldings[index]['Action'], this.optionHoldings[index].AccountCurrency);
      this.selectedOptionHolding = this.optionHoldings[index];
      this.inputs.actionClose = this.optionHoldings[index]['Action'];
    } else {
      this.optionQuoteError = undefined;
      this.quoteError;
    }
  }

  closeActionType(type) {
    if (this.selectedOptionHolding === undefined && type === 'BUYTOCLOSE') { return true }
    else if (this.selectedOptionHolding === undefined) { return false }
    return this.selectedOptionHolding.Action === type;
  }

  hideOrderPrice() {
    if (this.inputs.action !== "CLOSE" || this.manuallySearchAndClose) {
      return this.selectedChainData === null || this.selectedChainData === undefined
    } else {
      return this.selectedOptionHolding === undefined || this.quoteHasError
    }
  }

  toggleHoldingChoose(focusElement?) {
    this.formError.optionHoldingIndex = undefined;
    this.formError.symbol = undefined;
    this.inputs.chooseSymbol = !this.inputs.chooseSymbol;
    this.manuallySearchAndClose = true;
    this.cantfindOptionsClose = true;
    // this.cantfindoptionsdropdown = true;
    this.query = '';
    this.inputs.symbol = '';
    this.inputs.market = '';
    this.selectedSymbol = {};
    this.underlyingQuote = undefined;
    this.optionQuote = undefined;
    this.quoteError = undefined;
    this.optionQuoteError = undefined;
    this.inputs.optionHoldingIndex = -1;
    this.formError.symbol = undefined;
    this.strikeDateList = [];
    this.strikePriceList = [];
    this.inputs.strikeDateIndex = -1;
    this.inputs.strikePriceIndex = -1;
    // actionClose
    if (this.inputs.action == 'CLOSE') {
      this.inputs.actionClose = 'BUYTOCLOSE'
    }

    if (this.inputs.chooseSymbol) {
      this.inputs.settlementIndex = -1;
      this.settlementAccountsList = [];
    } else {
      this.inputs.settlementIndex = -1;
      // this.modelObj.selSettlementAccount = undefined;
      this.getSettlementAccount(this.inputs.action, '');
    }

    if (this.inputs.chooseSymbol && this.optionHoldings.length === 1) {
      this.inputs.optionHoldingIndex = 0;
      const symbol = this.optionHoldings[0].Symbol;
      this.handleGetQuote(symbol, 'optionQuote');
      this.inputs.settlementIndex = -1;
      // this.modelObj.selSettlementAccount = undefined;
      this.getSettlementAccount(this.inputs.action, this.optionHoldings[0].AccountCurrency);
    }
    if (focusElement) {
      const focusElm: HTMLElement = document.getElementById(focusElement);
      window.requestAnimationFrame(() => {
        focusElm.focus();
      });
    }
    this.storeArray[this.mode].savedInfo = undefined;
  }

  isQuantityAvailable() {
    return (this.inputs.action === 'SELLTOCLOSE' || this.inputs.action === 'BUYTOCLOSE' || this.inputs.action === 'CLOSE')
      && this.inputs.chooseSymbol && this.inputs.optionHoldingIndex != -1;
  }

  quantityChange() {
    this.formError.quantity = undefined;
  }

  updateSelectedPaymentAccount() {
    this.formError.settlementIndex = undefined;
    // this.modelObj.selSettlementAccount = this.modelObj.settlementAccounts[settlementIndex];
    // console.log(this.modelObj.selSettlementAccount);
  }

  formatRawData(amount) {
    if (isDefined(amount)) {
      if (this.appStore.lang.toUpperCase() === 'FR') {
        amount = amount.replace(',', '.').replace(/\s/g, '');
      } else {
        amount = amount.replace(/,/g, '');
      }
    }
    return amount;
  }
  //not using below
  dateDisplay(custDate) {
    if (custDate === undefined) {
      return this.content.text.customDate;
    } else {
      let dateList = [custDate.slice(0, 4), custDate.slice(4, 6), custDate.slice(6, 8)];
      let month = this.dateParam.MonthList[parseInt(dateList[1]) - 1].Value.toUpperCase().substr(0, 3);
      let dateStr = '';

      if (this.appStore.lang.toUpperCase() === 'EN') {
        dateStr += month;
        dateStr += ' ';

        if (dateList[2].charAt(0) === '0') {
          dateStr += dateList[2].charAt(1);
        } else {
          dateStr += dateList[2];
        }

        dateStr += ', ' + dateList[0];
      } else if (this.appStore.lang.toUpperCase() === 'FR') {
        if (dateList[2].charAt(0) === '0') {
          dateStr += dateList[2].charAt(1);
        } else {
          dateStr += dateList[2];
        }

        dateStr += ' ';
        dateStr += month;

        dateStr += ' ' + dateList[0];
      }

      return dateStr;
    }
  }
  //not using below
  // updateStrikePrice() {
  //   this.inputs.optionType = '';
  //   this.optionQuote = undefined;
  //   this.oldOptionType = '';
  //   this.formError.optionType = undefined;
  //   this.formError.strikePriceIndex = undefined;
  //   if (this.selectionFlag === undefined) {
  //     this.selectionFlag = 'price';
  //   }
  //   if (this.selectionFlag === 'price' && this.inputs.strikePriceIndex > -1) {
  //     this.getChain({ SymbolName: this.selectedSymbol.SymbolName, Market: this.selectedSymbol.Market });
  //     this.formError.strikeDateIndex = undefined;
  //   }
  //   TradeOptionsLandingStore.savedInfo = undefined;
  // }
  // //not using below
  // updateStrikeDate() {
  //   this.inputs.optionType = '';
  //   this.optionQuote = undefined;
  //   this.oldOptionType = '';
  //   this.formError.optionType = undefined;
  //   this.formError.strikeDateIndex = undefined;
  //   if (this.selectionFlag === undefined) {
  //     this.selectionFlag = 'expiry';
  //   }
  //   if (this.selectionFlag === 'expiry' && this.inputs.strikeDateIndex > -1) {
  //     this.getChain({ SymbolName: this.selectedSymbol.SymbolName, Market: this.selectedSymbol.Market });
  //     this.formError.strikePriceIndex = undefined;;
  //   }
  //   TradeOptionsLandingStore.savedInfo = undefined;
  // }
  // //not using below
  // updateOptionType(data) {
  //   if (this.inputs.optionType != this.oldOptionType) {
  //     this.formError.optionType = undefined;
  //     this.oldOptionType = data;
  //     this.getOptionQuote();
  //   }
  //   TradeOptionsLandingStore.savedInfo = undefined;
  // }
  //not using below
  updateSymbolError(data) {
    this.formError.symbol = data;
    this.symbolNoOption = true;
  }

  changeLimitInput() {
    this.formError.limit = undefined;
  }
  //not using below
  resetError(data) {
    if (data === 'all') {
      this.formError.strikeDateIndex = undefined;
      this.formError.strikePriceIndex = undefined;
      this.formError.optionType = undefined;
    } else {
      this.formError[data] = undefined;
    }
  }

  resetAllFormErrors() {
    this.formError = {};
  }

  resetFields() {
    this.query = this.inputs.symbol;
    setTimeout(() => {
      this.query = '';
    }, 10);
    this.inputs.symbol = '';
    this.selectedSymbol = {};
    this.inputs.market = '';
    this.inputs.optionHoldingIndex = -1;
    this.strikeDateList = undefined;
    this.strikePriceList = undefined;
    this.inputs.strikeDateIndex = -1;
    this.inputs.strikePriceIndex = -1;
    this.underlyingQuote = undefined;
    this.optionQuote = undefined;
    this.quoteError = undefined;
    this.optionQuoteError = undefined;
    this.EAAErrorFlag = false;
    this.quoteHasError = false;
    this.formError.strikeDateIndex = undefined;
    this.formError.strikePriceIndex = undefined;
    this.formError.optionType = undefined;
    this.formError.optionHoldingIndex = undefined;
    this.formError.symbol = undefined;
    this.selectedOptionHolding = undefined;
    // this.storeArray[this.mode].savedInfo = undefined;
  }

  getOptionChain() {
    // QuotesResearchBridgeStore.crossFlow = {
    //   action: 'qlOptions'
    // };
    // this.router.navigate(['/txn/bridge/quotesResearch'], {
    //   skipLocationChange: true,
    // });
    this.symbolFormValues = {};
    if (this.inputs.strikeDateIndex > -1) {
      this.symbolFormValues.StrikeDate = this.strikeDateList[this.inputs.strikeDateIndex].Key
    }
    if (this.inputs.optionType !== '') {
      this.symbolFormValues.OptionType = this.inputs.optionType
    }
    if (this.inputs.strikePriceIndex > -1) {
      this.symbolFormValues.StrikePrice = parseFloat(this.formatRawData(this.strikePriceList[this.inputs.strikePriceIndex].Value.Data))
    }
    this.showOptionModal = true;
      this.showModalIFrame()
  }

  EnableTradeAccount() {
    EnableMarginAndOptionsTradingLandingStore.crossFlow = {
      accountNumber: this.accountList[this.inputs.accountIndex].AccountNumber
    }
    this.router.navigate(['/txn/userPreferences/enable-margin-and-options-trading/landing']);
  }

  contactUs() {
    this.router.navigate(['/txn/contactus']);
  }

  next() {

    console.log("this.inputs", this.inputs);
    console.log("this.account", this.account);
    QuickTradeOptionsValidator.setValidators(this.tradeOptionForm, this.optionHoldings.length > 0 && this.inputs.chooseSymbol, this.symbolNoOption, this.appStore.lang.toLowerCase() == "fr");
    QuickTradeOptionsValidator.validate(this.tradeOptionForm);
    if (this.isAccountEligibleForTrading == false) {
      this.formError.accountNumber = this.errorContentForAcc;
    }
    if (this.tradeOptionForm.valid) {
      if (!this.isDesktop()) {
        this.optionChainService.submitOptionChain.emit('');
        if (this.optionChainService.validChainForm) {
          this.proceedToReview();
        } else {
          // Check whether needed
          setTimeout(() => {
            this.subscriptionService.enableButton("validation");
          }, 200);
        }
      } else {
        this.optionChainService.submitOptionChain.emit('');
        this.proceedToReview();
      }
    }
    else {
      setTimeout(() => {
        this.subscriptionService.enableButton("validation");
      }, 200);
      this.formError = QuickTradeOptionsValidator.resolveValidationError(this.tradeOptionForm, this.content.error);
      if (this.isAccountEligibleForTrading == false) {
        this.formError.accountNumber = this.errorContentForAcc;
      }
      console.log('Form Error --- ' + JSON.stringify(this.formError));
      QuickTradeOptionsValidator.emptyValidators(this.tradeOptionForm, this.optionHoldings.length > 0 && this.inputs.chooseSymbol);
      if (!this.isDesktop() && this.selectedSymbol) {
        this.optionChainService.submitOptionChain.emit('');
      }
      if (this.formError.accountNumber) {
        setTimeout(() => {
          this.setFocus('ddButtonquickTrade' + this.uniqueName);
        }, 200);
      }
    }

    // } 
  }

  proceedToReview() {

    this.resetAllFormErrors();
    let restActionMode = this.inputs.action;
    // var restActionMode1;
    // if (this.manuallySearchAndClose) {
    //   restActionMode = "BUYTOCLOSE"
    //   restActionMode1 = "SELLTOCLOSE"
    // }

    if (!this.isDesktop()) {
      if (this.inputs.action == "CLOSE" && (this.inputs.actionClose == 'BUYTOCLOSE' || this.inputs.actionClose == 'SELLTOCLOSE')) {
        restActionMode = this.inputs.actionClose
      }
    }
    // RootService.omniturePageName("Trade Options-" + this.getOmnitureValue(restActionMode) + ">Step 1 of 3");
    const settlement = { SettlementType: this.settlementAccountsList[this.inputs.settlementIndex].SettlementType, Currency: this.settlementAccountsList[this.inputs.settlementIndex].Currency };
    let expiryVal = {};
    if (this.inputs.expiryMode === 'customDate') {
      expiryVal = { ExpiryType: 'CUSTOM', ExpiryDate: this.calculateDate() };
    } else {
      expiryVal = { ExpiryType: 'DAY' };
    }
    let priceval = {};
    if (this.inputs.priceMode === 'limitMode') {
      // if (isNaN(this.inputs.limit)) {
      // priceval = { Type: 'LIMIT', LimitAmount: this.inputs.limit.toString().replace(',', '.') };
      // } else {
      priceval = { Type: 'LIMIT', LimitAmount: this.inputs.limit };
      // }
    } else {
      priceval = { Type: 'MKT' };
    }
    const tempSymbol: any = { Market: this.inputs.market, SymbolName: this.inputs.symbol.toUpperCase(), SymbolType: 'OPTION' };
    if (this.inputs.optionHoldingIndex > -1) {
      const info = this.optionHoldings[this.inputs.optionHoldingIndex].Symbol.OptionInfo;
      //this.optionInfo = tempSymbol.OptionInfo;
      tempSymbol.OptionInfo = { OptionType: info.OptionType, StrikeDate: info.StrikeDate, StrikePrice: { Value: parseFloat(this.formatRawData(info.StrikePrice.Value)) } };
      if (!this.isDesktop()) {
        if (this.inputs.action == "CLOSE" && this.inputs.actionClose == '') {
          restActionMode = this.optionHoldings[this.inputs.optionHoldingIndex].Action
        }
        if (this.inputs.action == "CLOSE" && this.inputs.actionClose != '') {
          restActionMode = this.inputs.actionClose
        }
      }
    } else {
      if (!this.isDesktop()) {
        // Mobile Change
        if (this.optionChainService.chainData["Inputs"]['manualOptionType'] == "CALLS") {
          this.inputs.optionType = "C";
        } else if (this.optionChainService.chainData["Inputs"]['manualOptionType'] == "PUTS") {
          this.inputs.optionType = "P";
        }
        console.log("Submit data to verify", this.optionChainService.chainData["Inputs"]);

        tempSymbol.OptionInfo = { OptionType: this.inputs.optionType, StrikeDate: this.rawDateFormat(this.optionChainService.chainData["Inputs"]["optionStrikeDateIndex"]), StrikePrice: { Value: parseFloat(this.optionChainService.chainData["Inputs"]["optionStrikePriceIndex"]) } };
      } else {
        tempSymbol.OptionInfo = { OptionType: this.inputs.optionType, StrikeDate: this.strikeDateList[this.inputs.strikeDateIndex].Key, StrikePrice: { Value: parseFloat(this.formatRawData(this.strikePriceList[this.inputs.strikePriceIndex].Value.Data)) } };
      }
    }
    //this.optionInfo = tempSymbol.OptionInfo;
    const request = {
      Action: restActionMode, AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber,
      Symbol: tempSymbol,
      Quantity: this.inputs.quantity, Price: priceval,
      Expiry: expiryVal,
      SettlementAccount: settlement
    };

    this.verifyStoreArray[this.mode].params = request;
    this.storeArray[this.mode].savedInfo = undefined;
    if (!this.isDesktop()) {
      this.storeArray[this.mode].savedInfo = {
        inputs: JSON.parse(JSON.stringify(this.inputs)),
        AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber,
        AccountCurrency: undefined,
        OptionInfo: tempSymbol.OptionInfo,
        symbol: this.inputs.symbol.toUpperCase(),
        market: this.inputs.market,
        selectedSymbol: this.selectedSymbol,
        account: this.account,
        selectedAccount: this.selectedAccount
      };
      if (isDefined(this.optionChainService.chainData["Inputs"])) {
        this.storeArray[this.mode].savedInfo["chainInputs"] = JSON.parse(JSON.stringify(this.optionChainService.chainData["Inputs"]))
      }
      if (this.inputs.action === "CLOSE" && this.inputs.actionClose == '') {
        this.storeArray[this.mode].savedInfo.closeActionType = this.optionHoldings[this.inputs.optionHoldingIndex].Action
      }
      if (this.inputs.action === "CLOSE" && this.inputs.actionClose != '') {
        this.storeArray[this.mode].savedInfo.closeActionType = this.inputs.actionClose
      }

    } else {
      this.storeArray[this.mode].savedInfo = {
        inputs: JSON.parse(JSON.stringify(this.inputs)),
        AccountNumber: this.accountList[this.inputs.accountIndex].AccountNumber,
        AccountCurrency: undefined,
        OptionInfo: tempSymbol.OptionInfo,
        symbol: this.inputs.symbol.toUpperCase(),
        market: this.inputs.market,
        selectedSymbol: this.selectedSymbol
      };
    }

    if (this.inputs.optionHoldingIndex > -1) {
      this.storeArray[this.mode].savedInfo.AccountCurrency = this.optionHoldings[this.inputs.optionHoldingIndex].AccountCurrency;
    }
    this.verifyStoreArray[this.mode].inFlow = {};
    if (this.accountList[this.inputs.accountIndex].BuyingPowerValueType == "COMBINED") {

      if (this.accountList[this.inputs.accountIndex].ConsolidatedBuyingPower) {
        this.verifyStoreArray[this.mode].inFlow.ConsolidatedBuyingPower = this.currencyFormatterPipe.transform(this.accountList[this.inputs.accountIndex].ConsolidatedBuyingPower, 'CAD', 'format-default');
      }
      this.verifyStoreArray[this.mode].inFlow.CombinedBuyingPower = true;
      this.verifyStoreArray[this.mode].inFlow.TimeStamp = this.accountList[this.inputs.accountIndex].TimeStamp;
    }

    // Mobile for greek
    if (!this.isDesktop()) {
      if (this.inputs.optionType == 'C') {
        this.verifyStoreArray[this.mode].inFlow.greek = this.greekValues['CALLS']
      } else {
        this.verifyStoreArray[this.mode].inFlow.greek = this.greekValues['PUTS']
      }
    }
    if (this.mode == "quickTrade") {
      const req: any = this.verifyStoreArray[this.mode].params;
      this.spinnerService.setFocusElement(undefined);
      this.subscriptions.add(this.httpService.post(gatewayConfig.APIServices.optionsVerify.url, req).subscribe((data: any) => {
        this.verifyStoreArray[this.mode].inFlow.Data = data;
        this.verifyStoreArray[this.mode].crossFlow = 'Verify';
        const sessionStore = { params: '', inFlow: '', crossFlow: '' };
        sessionStore.params = this.verifyStoreArray[this.mode].params;
        sessionStore.inFlow = this.verifyStoreArray[this.mode].inFlow;
        sessionStore.crossFlow = this.verifyStoreArray[this.mode].crossFlow;
        this.persistenceService.sessionStorePersist('quickOptionsVerifyStore', JSON.stringify(sessionStore));
        this.subscriptionService.setQuickOptionsPosition(2);
      }, (err) => {
        // this.errorHandler.handleError(err);
        this.subscriptionService.setCustomError(err.error.Exception);
        setTimeout(() => {
          this.subscriptionService.enableButton('validation');
        }, 200);
      })
      );
      this.formSubmitted = true;
    } else {
      this.submit.emit();
    }
    // this.router.navigate(['/txn/optionsCentral/singleLeg/verify']);
  }

  logError(err) {
    let tempOptions = JSON.parse(JSON.stringify(err));
    tempOptions["platform"] = "quickTrade";
    this.commonService.logBraze("viewed_trade_options_error", tempOptions);
  }

  handleRunTimeErrors(err) {
    console.log(err);
    this.formError = {};

    err.forEach((element) => {
      if (element.Category === 'Price.Amount') {
        this.formError.limit = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Price.Type') {
        this.formError.priceMode = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Quantity') {
        this.formError.quantity = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Action') {
        this.formError.actionMode = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Symbol') {
        this.formError.symbol = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'Expiry') {
        this.formError.selectedDate = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'AccountNumber') {
        this.formError.accountNumber = { message: element.Errors[0].ErrorMessage };
      } else if (element.Category === 'SettlementAccount') {
        this.formError.settlementIndex = { message: element.Errors[0].ErrorMessage };
      }
    });
  }
  //not using below
  showPopupAlert() {
    let errorContent: any = {};
    errorContent.title = '';
    errorContent.subTitle = '';
    errorContent.message = this.content.error.unexpectedException.message;
    errorContent.buttons = [{
      // OK
      text: this.content.text.okButton
    }];
    errorContent.cssClass = 'alert-error-popup';
    errorContent.enableBackdropDismiss = false;
    // this.popupservice.presentAlertWithCss(errorContent);
  }

  getOmnitureValue(action) {
    let value;
    switch (action) {
      case 'BUYTOOPEN':
        value = 'Buy To Open';
        break;
      case 'BUYTOCLOSE':
        value = 'Buy To Close';
        break;
      case 'SELLTOOPEN':
        value = 'Sell To Open';
        break;
      case 'SELLTOCLOSE':
        value = 'Sell To Close';
        break;
      case 'CLOSE':
        value = 'Close';
        break;
    }
    return value;
  }

  getBuyingPowerForSelectedAccount(index) {
    this.getBalanceReq(index);
    // this.idx = index;
    this.buyingPowerLoading = true;
    if (index > -1) {
      const account = this.accountList[index];
      if (isDefined(account.BuyingPowerValueType)) {
        let payload = {};
        payload = { AccountNumber: account.AccountNumber };
        this.service.getBuyingPower(payload).subscribe(
          (data: any) => {
            if (data) {
              if (data.BuyingPowerBalances[0].ConsolidatedBuyingPower) {
                account.ConsolidatedBuyingPower = data.BuyingPowerBalances[0].ConsolidatedBuyingPower;
              } else {
                account.BuyingPowerCAD = data.BuyingPowerBalances[0].BuyingPowerCAD;
                account.BuyingPowerUSD = data.BuyingPowerBalances[0].BuyingPowerUSD;
              }
              account.TimeStamp = data.BuyingPowerBalances[0].TimeStamp;
              account.MarginAccount = data.BuyingPowerBalances[0].MarginAccount;
              this.buyingPowerLoading = false;
            }
          },
          () => {
            // resolve(true);
            this.buyingPowerLoading = false;
            console.log('Buying power service failed for account: ' + account.AccountNumber);
          });
      }
    }
  }

  calculateDate() {
    let year = this.dateParam.MinGTDate.slice(0, 4);
    const month = this.dateParam.MinGTDate.slice(4, 6);
    const day = this.dateParam.MinGTDate.slice(6, 8);
    if (this.inputs.dateMonth < parseInt(month) - 1 ||
      (this.inputs.dateMonth === parseInt(month) - 1 && this.inputs.dateDay < parseInt(day))
    ) {
      year = (parseInt(year) + 1).toString();
    }

    if (this.inputs.dateMonth < 9) {
      if (this.inputs.dateDay < 10) {
        return year + '0' + (this.inputs.dateMonth + 1) + '0' + this.inputs.dateDay;
      } else {
        return year + '0' + (this.inputs.dateMonth + 1) + this.inputs.dateDay;
      }
    } else {
      if (this.inputs.dateDay < 10) {
        return year + (this.inputs.dateMonth + 1) + '0' + this.inputs.dateDay;
      } else {
        return year + (this.inputs.dateMonth + 1) + this.inputs.dateDay;
      }
    }
  }

  calcMonthDays(month) {
    let minDay = 1;
    let maxDay = this.calcMonthLength(month);

    let year = moment(this.dateParam.MinGTDate).year();
    if (month < moment(this.dateParam.MinGTDate).month()) {
      year++;
    }

    if (moment(this.dateParam.MinGTDate).month() === month) {
      minDay = moment(this.dateParam.MinGTDate).date();
    }
    if (moment(this.dateParam.MaxGTDate).month() === month) {
      maxDay = moment(this.dateParam.MaxGTDate).date();
    }

    if (this.inputs.dateDay < minDay) {
      setTimeout(() => {
        this.inputs.dateDay = minDay;
      }, 100);
    } else if (this.inputs.dateDay > maxDay) {
      setTimeout(() => {
        this.inputs.dateDay = maxDay;
      }, 100);
    }

    const dayList = this.makeDayObj(minDay, maxDay);
    const parsedDayList = [];

    for (const day of dayList) {
      parsedDayList.push(day);
    }

    if (this.inputs.dateDay > maxDay) {
      setTimeout(() => {
        this.inputs.dateDay = maxDay;
      }, 100);
    }

    this.dayList = parsedDayList;
  }

  calcMonthLength(month) {
    switch (month) {
      case 0:
      case 2:
      case 4:
      case 6:
      case 7:
      case 9:
      case 11:
        return 31;
        break;
      case 3:
      case 5:
      case 8:
      case 10:
        return 30;
        break;
      default:
        let year = moment(this.dateParam.MinGTDate).year();
        if (moment(this.dateParam.MinGTDate).month() > 2) {
          year++;
        }
        if (year % 4 === 0 && (year % 100 != 0 || year % 400 === 0)) {
          return 29;
        } else {
          return 28;
        }
        break;
    }
  }

  makeDayObj(minDay, maxDay) {
    const obj = [];
    for (let i = minDay; i < maxDay + 1; i++) {
      obj.push({ Day: i });
    }
    return obj;
  }

  showCustomDate() {
    return this.inputs.priceMode === 'limitMode';
  }

  isDesktop() {
    // if (this.inputs) {
    //   if ( (this.inputs.action === 'BUYTOCLOSE' || this.inputs.action === 'SELLTOCLOSE')) {
    //     this.inputs.action = "CLOSE"
    //   }else if(this.inputs.action ==="") {
    //     this.inputs.action = 'BUYTOOPEN'
    //   }
    // }
    // return this.commonService.isDesktop();
    return false;
  }

  //not using below
  addCommas(text) {
    if (text.length >= 3 && text != '-') {
      if (this.appStore.lang.toLowerCase() == 'en') {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    } else {
      return text;
    }
  }

  //not using below
  valueOrDash(val) {
    if (val === undefined || val === null || val === '') {
      return '-';
    } else {
      return val;
    }
  }

  //not using below
  getFlag(flagName): string {
    if (flagName === undefined) {
      return null;
    } else {
      let response = '';

      switch (flagName.toLowerCase()) {
        case 'ca':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'cdn':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'usd':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
        case 'us':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
      }

      return response;
    }
  }

  //not using below
  getOptionTypeString(val) {
    if (val === 'C') {
      return this.content.text.call.toUpperCase();
    } else if (val === 'P') {
      return this.content.text.put.toUpperCase();
    } else {
      return '-';
    }
  }


  formatDate(date) {
    moment.locale(this.appStore.lang)
    const momentLang = moment(date, "MMMM")
    if (this.appStore.lang === Lang.EN) {
    return momentLang.format("MMM")
    } else {
      return momentLang.format("MMM")
    }
  }

  //not using below
  getDateFormat() {
    let datestart = new Date();
    setTimeout(() => {
      this.timestamp = datestart.getHours() + ":" + datestart.getMinutes().toString().padStart(2, '0');
    }, 10);
  }

  setSelectedDate(value) {
    this.inputs.dateDay = moment(value).date();
    this.inputs.dateMonth = moment(value).month();
    this.calcMonthDays(this.inputs.dateMonth);
    this.setDateParams();
  }

  setDateParams() {
    let minDay = moment(this.dateParam.MinGTDate).date();
    let maxDay = moment(this.dateParam.MaxGTDate).date();
    let defDate = this.calculateDate();
    if (moment(this.dateParam.MinGTDate).month() === moment(defDate).month()) {
      if (moment(defDate).date() < minDay) {
        defDate = this.createDate(moment(this.dateParam.MinGTDate).year(), moment(defDate).month(), minDay);
      }
    }
    if (moment(this.dateParam.MaxGTDate).month() === moment(defDate).month()) {
      if (moment(defDate).date() > maxDay) {
        defDate = this.createDate(moment(this.dateParam.MaxGTDate).year(), moment(defDate).month(), maxDay);
      }
    }

    this.dateSelectorParam.dateParam = {
      MinGTDate: this.dateParam.MinGTDate,
      MaxGTDate: this.dateParam.MaxGTDate,
      DefaultGTDate: defDate
    };
  }

  createDate(year, month, day) {
    if (month < 9) {
      if (day < 10) {
        return year.toString() + '0' + (month + 1).toString() + '0' + day.toString();
      } else {
        return year.toString() + '0' + (month + 1).toString() + day.toString();
      }
    } else {
      if (day < 10) {
        return year.toString() + (month + 1).toString() + '0' + day.toString();
      } else {
        return year.toString() + (month + 1).toString() + day.toString();
      }
    }
  }
  //not using below
  skipLink(id) {
    let el = document.getElementById(id);
    if (isDefined(el)) {
      el.focus();
    }
  }

  //not using below
  spaceNumbers(value) {
    const reg = /[0-9](?=[0-9])/g;
    if (value === undefined) {
      return undefined;
    } else if (value.search(reg) != -1) {
      return value.replace(reg, '$& ');
    } else {
      return value;
    }
  }

  openWindow(url) {
    openWindow(url, this.appStore);
  }

  goToOptionsCentral() {
    this.router.navigate(['/txn/optionsCentral']);
  }


  mergeAccountDetails(accListTrade, accListHoldings) {
    const accList = [];
    //if both MRL and CSH are available, merge into 1 row
    for (let i = 0; i < accListTrade.length; i++) {
      for (let j = 0; j < accListHoldings.length; j++) {
        if (accListTrade[i].AccountNumber == accListHoldings[j].AccountNumber) {
          accListTrade[i].hasBothMarginCash = { 'USD': false, 'CAD': false };
          const filteredSubAccountsUSD = accListHoldings[j].Subaccounts.filter(acc => acc.Currency == 'USD');
          const filteredSubAccountsCAD = accListHoldings[j].Subaccounts.filter(acc => acc.Currency == 'CAD');
          let requiredSubAccountsCAD = [];
          let requiredSubAccountsUSD = [];
          if (filteredSubAccountsUSD.some(acc => acc.AccountType == 'MRL') &&
            filteredSubAccountsUSD.some(acc => acc.AccountType == 'CSH')) {
            requiredSubAccountsUSD = filteredSubAccountsUSD.filter(acc => acc.AccountType == 'MRL' || acc.AccountType == 'MRS');
            accListTrade[i].hasBothMarginCash['USD'] = true;
          } else {
            requiredSubAccountsUSD = filteredSubAccountsUSD;
          }
          if (filteredSubAccountsCAD.some(acc => acc.AccountType == 'MRL') &&
            filteredSubAccountsCAD.some(acc => acc.AccountType == 'CSH')) {
            requiredSubAccountsCAD = filteredSubAccountsCAD.filter(acc => acc.AccountType == 'MRL' || acc.AccountType == 'MRS');
            accListTrade[i].hasBothMarginCash['CAD'] = true;
          } else {
            requiredSubAccountsCAD = filteredSubAccountsCAD;
          }
          accListTrade[i].Subaccounts = requiredSubAccountsUSD.concat(requiredSubAccountsCAD);
          accListTrade[i].Subaccounts = accListTrade[i].Subaccounts.sort((a, b) => {
            if (a.Currency > b.Currency) {
              return 1;
            } if (a.Currency < b.Currency) {
              return -1;
            }
            return 0;
          });
          break;
        }
      }
      accList.push(accListTrade[i]);
    }
    console.log(accList);
    return accList;
  }

  initRangeEl(fromQuantity?) {
    var rangeEl: any = document.getElementById('myInput');
    if (!rangeEl) {
      return;
    }
    /**
     * IE/Older Edge FIX
     * On IE/Older Edge the height of the <input type="range" />
     * is the whole element as oposed to Chrome/Moz
     * where the height is applied to the track.
     *
     */
    if (this.isOlderEdgeOrIE()) {
      rangeEl.style.height = '20px';
      // IE 11/10 fires change instead of input
    } else {
      this.updateRangeEl(rangeEl, fromQuantity);
      rangeEl.addEventListener('input', (e) => {
        this.updateRangeEl(e.target);
        // this.loadingSlider = true;
        let rangeV: any = document.getElementById('rangeV');
        if (rangeV) {
          let newValue = Number((rangeEl.value - rangeEl.min) * 100 / (rangeEl.max - rangeEl.min));
          let newPosition = 12 - (newValue * 0.2);
          rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
        }
      });
    }
  }

  updateRangeEl(rangeEl, fromQuantity?, fromChangeQuantity?) {
    if (rangeEl.max == '' || !this.inputs.quantity) {
      rangeEl.value = 0;
      if (fromChangeQuantity) {
        this.inputs.quantity = undefined;
      }
    }
    var ratio = this.valueTotalRatio(fromQuantity ?? rangeEl.value, rangeEl.min, rangeEl.max);
    rangeEl.style.backgroundImage = this.getLinearGradientCSS(ratio, '#2779b0', '#dddddd');
    if (rangeEl.max != '' && this.inputs.quantity !== undefined) {
      this.inputs.quantity = fromQuantity ?? rangeEl.value;
    }
    if (fromQuantity) {
      rangeEl.value = fromQuantity;
    }
  }

  isOlderEdgeOrIE() {
    return (
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
      window.navigator.userAgent.indexOf('Edge') > -1
    );
  }

  valueTotalRatio(value, min, max) {
    return ((value - min) / (max - min)).toFixed(2);
  }

  getLinearGradientCSS(ratio, leftColor, rightColor) {
    return [
      '-webkit-gradient(',
      'linear, ',
      'left top, ',
      'right top, ',
      'color-stop(' + ratio + ', ' + leftColor + '), ',
      'color-stop(' + ratio + ', ' + rightColor + ')',
      ')'
    ].join('');
  }

  getBalanceReq(index) {
    this.idx = index;
    this.balanceLoading = true;
    if (index != -1) {
      const account = this.accountList[index];
      let payload = {};
      payload = { AccountNumber: account.AccountNumber };
      this.spinnerService.setFocusElement(undefined);
      this.subscriptions.add(this.service.getBalanceReq(payload).subscribe(
        (data: any) => {
          this.balanceLoading = false;
          if (data.AccountType) {
            account.isRegistered = data.AccountType == 'REG' ? true : false;
          }
          if (data && data.AccountBalances && data.AccountBalances.length) {
            if (data.AccountBalances[0].Amount) {
              if (this.settleAmountCurrency == 'CAD') {
                account.accountBalanceAmount = data.AccountBalances[0].Amount;
              } else {
                account.accountBalanceAmount = data.AccountBalances[1].Amount;
              }
              this.initRangeEl(this.inputs.quantity);
            }
          }
        },
        (err) => {
          this.balanceLoading = false;
        })
      );
    }
  }

  tooltipClicked(title, type?) {
    if(type == 'account') {
      this.accountToolTipMobile.title = title;
    } else if(type == 'Buying power'){
      this.priceToolTip.title = title;
    } else {
      this.moreInfoTooltip.title = title;
    }
    if (title == 'More Information' || title == 'Plus de renseignements') {
      this.sendOmnitureInteractionData('trade-options:trading:trade-options:details:more-info-icon-tool-tip');
    } else {
      this.sendOmnitureInteractionData('trade-options:trading:trade-options:details:info-icon-tool-tip');
    }
  }

  sendOmnitureInteractionData(value?: string) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(value, false, true, false, true));
    }
  }

  //not using below
  recieveSymbolError(data) {
    if (isDefined(data)) {
      if (isDefined(data.Exception)) {
        if (isDefined(data.Exception[0])) {
          this.formError.symbol = { message: data.Exception[0].ErrorMessage };
        } else {
          this.formError.symbol = { message: data.Exception.ErrorMessage };
        }
      } else {
        this.formError.symbol = { message: data.ErrorMessage };
      }
    } else if (data === undefined) {
    }
    this.quoteFromChildComponent = undefined;
    // this.refreshEstimatedTriggerValue();
  }
  //not using below
  getValues() {
    console.log(this.inputs);
  }

  getSelectedDataForChain(data) {

    if (data['closeModal'] === false) {
      this.selectedChainData = data
      return
    }
    setTimeout(() => {
      this.optionChainService.chainData = data;
      this.selectedChainData = data;
      console.log('---------', this.selectedChainData.Inputs);
      this.inputs.strikePriceIndex = data["Inputs"]['optionStrikePriceIndex'];
      if (data["Inputs"]['manualOptionType'] === 'CALLS') {
        this.inputs.optionType = 'C'
        // this.greekValues = greek;
      } else if (data["Inputs"]['manualOptionType'] === 'PUTS') {
        this.inputs.optionType = 'P'
      } else if (data["Inputs"]['optionType'] === 'BOTH') {
        this.inputs.optionType = ''
      }
      if (!this.isDesktop()) {
        if (this.selectedChainData.Price != undefined) {
          this.inputs.priceMode = 'limitMode'
          this.inputs.limit = this.selectedChainData.Price
          this.changePriceMode(this.inputs.priceMode)
          this.changeExpiryMode('day')
        } else {
          this.inputs.priceMode = 'marketMode'
          this.changePriceMode(this.inputs.priceMode)
          this.changeExpiryMode('day')

        }
        this.setSavedOptionInfo(data.Inputs, this.selectedChainData.Price, this.inputs.priceMode);
      }
      if (this.isDesktop()) {
        if (this.selectedChainData.Price != undefined) {
          this.inputs.priceMode = 'limitMode'
          this.inputs.limit = this.selectedChainData.Price
          this.chainExpiryDate = this.selectedChainData.ExpiryDate
          let strikeDate = this.rawDateFormat(this.chainExpiryDate)
          let strikeDateValue = this.strikeDateList.findIndex(object => (object.Key == strikeDate))

          this.inputs.strikeDateIndex = strikeDateValue;
          this.chainStrikePrice = this.selectedChainData.Inputs.optionStrikePriceIndex;
          let strikePriceValue = this.strikePriceList.findIndex(object => (object.Value.Data == this.chainStrikePrice))
          this.inputs.strikePriceIndex = strikePriceValue

          if (strikeDateValue == -1 || strikePriceValue == -1) {
            this.inputs.strikeDateIndex = -1;
            this.inputs.strikePriceIndex = -1;
            this.inputs.optionType = ''
            this.inputs.priceMode = "";
            this.inputs.limit = -1;
            this.chainDateSelected = undefined
          }
        } else {
          this.inputs.limit = this.selectedChainData.Price
          this.chainExpiryDate = this.selectedChainData.ExpiryDate
          let strikeDate = this.rawDateFormat(this.chainExpiryDate)
          let strikeDateValue = this.strikeDateList.findIndex(object => (object.Key == strikeDate))

          this.inputs.strikeDateIndex = strikeDateValue;
          this.chainStrikePrice = this.selectedChainData.Inputs.optionStrikePriceIndex;
          let strikePriceValue = this.strikePriceList.findIndex(object => (object.Value.Data == this.chainStrikePrice))
          this.inputs.strikePriceIndex = strikePriceValue
          this.inputs.priceMode = 'marketMode'
          this.changePriceMode(this.inputs.priceMode)
          this.changeExpiryMode('day')
        }
      }
    }, 100);
    setTimeout(() => {
      this.closeModal();
    }, 2000)
  }

  getSelectedDataForGreek(data) {

    setTimeout(() => {
      if (data && data['CALLS'] && data['PUTS']) {
        this.greekValues = data;
        this.callGreekValues = data['CALLS'].quote;
        this.putGreekValues = data['PUTS'].quote;
      }
    }, 100);
    console.log("getSelectedDataForGreek", data);
  }

  rawDateFormat(date) {
    if (isDefined(date)) {
      date = date.replaceAll('-', '');
    }
    return date;
  }

  setActionArray() {

    this.actionArray = [
      {
        label: this.content.list.optionTooltipContent[0].header,
        color: "#FFFFFF",
        backgroundColor: "#1F8561",
        value: "BUYTOOPEN"
      },
      {
        label: this.content.list.optionTooltipContent[2].header,
        color: "#FFFFFF",
        backgroundColor: "#CC4B18",
        value: "SELLTOOPEN"
      },
      {
        label: this.content.text.close,
        color: "#FFFFFF",
        backgroundColor: "#7b6e74",
        value: "CLOSE"

      }
    ]
  }

  setActionCloseArray() {
    this.actionCloseArray = [
      {
        label: this.content.list.optionTooltipContent[1].header,
        color: "#FFFFFF",
        backgroundColor: "#1F8561",
        value: "BUYTOCLOSE"
      },
      {
        label: this.content.list.optionTooltipContent[3].header,
        color: "#FFFFFF",
        backgroundColor: "#CC4B18",
        value: "SELLTOCLOSE"
      }
    ]
  }

  setPriceArray() {
    this.priceArray = [
      {
        label: this.content.text.marketButton,
        value: "marketMode"
      },
      {
        label: this.content.text.limitButton,
        value: "limitMode"
      },
    ];
  }

  setExpiryArray() {
    this.expiryArray = [
      {
        label: this.content.text.dayButton,
        value: "day"
      },
      {
        label: this.content.text.customDate,
        value: "customDate"
      }
    ];
    this.disabledExpiryArray = [
      false,
      true
    ]
  }

  setMarketArray() {
    this.marketArray = [
      {
        label: this.content.text.ca,
        value: "CA"
      },
      {
        label: this.content.text.us,
        value: "US"
      }
    ];
  }


  setExpiryCustomDisabled() {
    this.disabledExpiryArray[1] = !this.showCustomDate();
    if (this.disabledExpiryArray[1]) {
      this.changeExpiryMode('day');
    }
  }

  //not using below
  refreshAll() {
    this.spinnerService.setFocusElement(undefined);
    let buttons = document.getElementsByClassName('new-quote-refresh-button-qt');
    let button;
    if (buttons && buttons.length) {
      button = buttons[0];
    }
    if (button) {
      button.className = 'new-quote-refresh-button-qt icon-Reload2';
      requestAnimationFrame(() => {
        button.className = 'new-quote-refresh-button-qt icon-Reload2 refresh-animate';
      });
    }

    if (this.isDesktop()) {
      this.getDateFormat()
    }
    this.refreshQuote(true);
    setTimeout(() => {
      this.customRefresh = false
    }, 3000);
  }

  //not using below
  closeEsignModal() {
    this.modalservice.close('optionChainModal');
    this.closeModal()
  }

  closeModal() {
    this.hidePage = false;
    this.showOptionModal = false;
    window.requestAnimationFrame(() => {
      this.modalService.close('optionChainModal', 'optionChainModalClose');
    });
  }

  //not using below
  showModalIFrame() {
    this.modalservice.open('optionChainModal', 'optionChainModalHeaderContainer');
    this.hidePage = true;
    this.getDateFormat()
    // this.chainDateSelected = true;
  }
  returnConfirmTargetElement() {
    return "optionChainModalHeader";
  }

  closeEsignConfirmModal() {
    this.modalservice.close('tradeOptionsConfirmModal');
    this.closeModal()
  }

  closeConfirmModal() {
    this.openTradeConfirmInterceptModal = false;
    this.clearPage();
    window.requestAnimationFrame(() => {
      this.modalService.close('tradeOptionsConfirmModal', 'tradeOptionsConfirmModalClose');
    });
  }


  returnConfirmComponentTargetElement() {
    return "tradeOptionsConfirmModalHeader";
  }



  setFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 200);
  }

  scrollUpForApp() {
    this.doScrollUpForApp = true;
  }

  scrollbackForApp() {
    this.doScrollUpForApp = false
  }

  addExtraheightinBottom() {
    if(this.mode =='smallTradeOptions' && this.doScrollUpForApp) {
      return 'page-bottom-info page-extra-margin-bottom '
    }
    if(this.mode =='smallTradeOptions' && (this.underlyingQuote === undefined)) {
      return 'page-bottom-info page-margin-bottom';
    }
    return 'page-bottom-info'
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }

  setIOSDropdownFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 210);
  }

}
