<button *ngIf="type == 'refresh'" type="button" class="global-refresh-container" (click)="refreshClick()"
  id="global-refresh-id-{{name}}" [ngClass]="{'optionCentral-btn': name === 'OptionCentral' && isDesktop()}" >
  <div aria-hidden="true" class="global-refresh-button icon-Reload2" 
    [ngClass]="{'refresh-animate':animateRefresh , 'optionCentral-refreshIcon': name === 'OptionCentral' && isDesktop()}">
  </div>
  <span class="sr-only">{{globalContent.text.refreshAria}}</span>
  <div class="global-refresh-timestamp" [ngClass]="{'optionCentral-timestamp': name === 'OptionCentral' && isDesktop()}">
    <span>{{globalContent.text.asOf}} </span>
    <span>{{timestamp}}</span>
  </div>
</button>
<div *ngIf="type == 'time'" class="global-refresh-container" id="global-refresh-id-{{name}}">
  <div class="global-refresh-timestamp">
    <span>{{globalContent.text.asOf}} </span>
    <span>{{timestamp}}</span>
  </div>
</div>
<div class="sr-only" aria-live="assertive">{{announcement}}</div>