import { Directive, HostListener, Renderer2, ElementRef, Input, OnChanges, HostBinding } from '@angular/core';
import { isDefined } from 'src/app/shared/services/utils.service';

@Directive({
  selector: '[inputErrorDirective]'
})
export class InputErrorDirective implements OnChanges {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  @Input() inputErrorDirective: any;
  // @HostBinding('class')
  // className = '';

  ngOnChanges() {
    if (isDefined(this.inputErrorDirective) && this.inputErrorDirective !== null) {
      // this.className = "has-field-error";
      this.renderer.addClass(this.el.nativeElement, "has-field-error");
      this.renderer.setAttribute(this.el.nativeElement, "aria-invalid", "true");
    } else {
      // this.className = "";
      this.renderer.removeClass(this.el.nativeElement, "has-field-error");
      this.renderer.removeAttribute(this.el.nativeElement, "aria-invalid");
    }
  }
}