import { _User } from '../../shared/models/user';
export class AppData {
  user: _User.User;
  state: {
    refresh: boolean;
    backOrForwardNav: boolean;
    currentUrl?: string;
    refreshURL?: string;
  };
  crossFlow?: boolean;
  globalAlert: {
    edocs?: boolean
  };
  refreshTime: number;
  currentTimeStamp: any;
  retrieveSessionInfo: any;
  isTradeOptionsEnabled: boolean;
  uniqueId: number;
  backButtonFn: any;
  isWrappedBack: boolean;
  headerBackFn: any;
  isNotch: boolean;
  connectionStatus?: boolean;
  showErrorInPopup?: boolean;
  omnitureId?: string;
  omnitureAuthType?: string;
  brazeUserSession?: boolean;
  brazeInAppMessage?: any;
  scrollToValue?: string;

  midTransURL: string;

  features: {
    BrazePlugin?: boolean;
    appRate?: any;
    touchId?: any;
    splashScreen?: any;
    keyboard?: any;
    isScreenReaderON?: boolean;
    OneTrust?: any;
    geolocation? : any;
    medalliaDigital?: any;
  };

  device: {
    height?: any;
    width?: any;
    orientation?: any;
  };

  version: {
    number: number;
    time: number;
  };

  preTxnPagePermission: string;

  constructor() {
    this.state = { refresh: false, backOrForwardNav: false };
    this.features = {};
    this.uniqueId = 0;
    this.brazeUserSession = false;
  }
}
