<div>
  <label [attr.id]="'sortLabel' + uniqueId" class="field-label">{{globalStore.text.sortedBy}} </label>
  <!-- <button type="button" (click)="openModal()" [attr.aria-labelledby]="'sortLabel' + uniqueId + ' sort-action' + uniqueId" [attr.id]="'sortSelectorButton' + uniqueId"
    #returnRef class="sort-link">
    <span [attr.id]="'sort-action' + uniqueId" class="sr-only">{{globalStore.text.sortSelectorClose}} {{sortList[sortIndex]}}
    </span>
    <span [attr.id]="'sort-value' + uniqueId" class="placeholder">{{sortList[sortIndex]}}</span>
  </button> -->
  <div class="sort-flex-box">
    <div class="select-item sort-dropdown">
      <select [attr.id]="'sort-list' + uniqueId" [attr.aria-labelledby]="'sortLabel' + uniqueId" [(ngModel)]="sortIndex" (change)="selectSort()">
        <option *ngFor="let sort of sortList; let i = index" [ngValue]="i">{{sort}}</option>
      </select>
      <span class=" select-icon icon-Chevron-Down color03" aria-hidden="true"></span>
    </div>
    <button [attr.id]="'sort-toggle' + uniqueId" type="button" (click)="toggleAsc()" class="toggle-icon" [attr.aria-label]="ascending ? globalStore.text.sortAscendingToggle : globalStore.text.sortDescendingToggle">
      <img src="assets/images/icons/toggle-icon.svg" height="15" [ngClass]="ascending ? '' : 'twist'" alt="">
    </button>
  </div>
</div>

<!-- <app-modal [header]="'sortSelectorModalHeader' + uniqueId" (keydown.esc)="closeModal()" [attr.id]="'sortSelector' + uniqueId"
  [id]="'sortSelector' + uniqueId" style='display: none;'>
  <div class="cibc-sort-selector-modal-body" aria-atomic="true" attr.aria-controls="{{'sortSelector' + uniqueId}}">
    <div class="cibc-sort-selector-modal-title-bar">
      <div class='modal-close'>
        <button [attr.id]="'sortSelectorClose' + uniqueId" type="button" (click)='closeModal()' class="clear close-button icon-Close color04"
          [attr.aria-label]="globalStore.text.sortSelectorOpen">
        </button>
      </div>
      <div [attr.id]="'sortSelectorModalHeader' + uniqueId" class="selectMessage color02">
        <label>{{globalStore.text.sortedBy}}</label>
      </div>
    </div>
    <div class="cibc-sort-selector-modal-content">
      <div *ngFor="let sort of sortList let i = index">
        <button type="button" (click)="selectSort(i)" class="cibc-sort-selector-item" [ngClass]="i == sortIndex ? 'selected-item' : ''">{{sort}}</button>
      </div>
    </div>
  </div>
</app-modal> -->