import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneNumFormatter',
    pure: true
  })

export class phoneNumFormatPipe implements PipeTransform {
    
        private readonly PHONE_REGEX = /^(\d{1})(\d{3})(\d{3})(\d{4})$/;
        private readonly PHONE_REGEX_10 = /^(\d{3})(\d{3})(\d{4})$/
        private readonly SPACES = /\s+/g;

        transform(value: string): string {
            let trimmed = value.replace(this.SPACES, '');
            let matches;
            if(value.length == 11){
                matches = trimmed.match(this.PHONE_REGEX);
            }else if(value.length == 10) {
                matches = trimmed.match(this.PHONE_REGEX_10);   
            }    
            if (!matches) {
                return value;
            }

            const phoneParts = matches.slice(1,);
            return phoneParts.join('-');
        }
}