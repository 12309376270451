<div class="main-page">
  <div class="force-wrapper">
    <div class="force-title">New Version Available</div>
    <div class="force-content">A newer version of the CIBC Mobile Wealth App is available. We've added new features and made it easier for you to view
      and manage your accounts. To continue using the CIBC Mobile Wealth App, select Upgrade.</div>
    <button class="button primary" (click)="upgrade()">Upgrade</button>
    <div class="force-title">Nouvelle version accessible</div>
    <div class="force-content">Une nouvelle version de l'appli Gestion mobile des avoirs CIBC est accessible. Nous avons ajouté de nouvelles fonctionnalités
      et fait en sorte qu'il soit plus facile pour vous de consulter et de gérer vos comptes. Pour continuer à utiliser l'appli
      Gestion mobile des avoirs CIBC, sélectionnez Mise à jour.</div>
    <button class="button primary" (click)="upgrade()">Mise à jour</button>
  </div>
</div>