import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { TxnHistInitRequest, TxnHistTuneRequest } from '../data/transaction-history-data';
import { AppStore } from 'src/app/shared/models/app-store';
import { QuotesResearchBridgeStore } from '../../quotesResearch/bridge/data/quotes-research-bridge-store';
import { QuotesDetailStore } from '../../quotesWatchList/quoteDetails/data/quotes-detail-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");


@Injectable({
    providedIn: 'root'
})
export class TransactionHistoryService implements PageService {

    constructor(private httpService: HttpService,
        private appStore: AppStore,
        private router: Router
    ) { }

    createTxnHistForm(form, fb) {
        form = fb.group({
            dateRangeSelection: 'DAYS_45',
            filterByTxnType: '0',
            filterBySecurityType: '0',
            filterBySymbol: '0',
            filterByCurrency: '0',
            sortByFilter: 'TXN_DATE'
        });
        return form;
    }

    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return input;
    }

    filterByDateRange(dateRangeReq) {
        const req: TxnHistInitRequest = new TxnHistInitRequest();
        req.Refresh = true;
        req.DateRange = dateRangeReq.DateRange;
        return this.httpService.post(gatewayConfig.APIServices.txnHistoryInit.url,
            req
        );
    }

    filterBySelectionReq(filterSelectionReq) {
        const req: TxnHistTuneRequest = new TxnHistTuneRequest();
        req.FilterCriterion = filterSelectionReq.FilterCriterion;
        return this.httpService.post(gatewayConfig.APIServices.txnHistoryTune.url,
            req
        );
    }

    sortByFilterSelectionReq(sortByFilterSelectionReq) {
        const req: TxnHistTuneRequest = new TxnHistTuneRequest();
        req.SortBy = sortByFilterSelectionReq.SortBy;
        return this.httpService.post(gatewayConfig.APIServices.txnHistoryTune.url,
            req
        );
    }

    clearFilterSelectionReq(clearFilterSelectionReq) {
        const req: TxnHistTuneRequest = new TxnHistTuneRequest();
        req.SortBy = clearFilterSelectionReq.SortBy;
        req.FilterCriterion = clearFilterSelectionReq.FilterCriterion;
        return this.httpService.post(gatewayConfig.APIServices.txnHistoryTune.url,
            req
        );
    }

    selectedAccountReq(accountRequest) {
        const req: TxnHistInitRequest = new TxnHistInitRequest();
        req.Refresh = true;
        req.SelectedAccount = accountRequest.SelectedAccount;
        return this.httpService.post(gatewayConfig.APIServices.txnHistoryInit.url,
            req
        );
    }

    navigateToQuotesAndResearch(fiType: string, symbol: any) {
        if (!this.appStore.isApp()) {
            const stockcentrePage = 'eqQuotes';
            const optionsPage = 'opQuotes';
            const mutualFundsPage = 'mfSnapshot';
            let quotesAndResearchTargetPage = '';
            switch (fiType) {
                case 'E': {
                    quotesAndResearchTargetPage = stockcentrePage;
                    this.goToQuotesAndResearch(quotesAndResearchTargetPage, symbol);
                    break;
                }
                case 'D': {
                    quotesAndResearchTargetPage = optionsPage;
                    this.goToQuotesAndResearch(quotesAndResearchTargetPage, symbol);
                    break;
                }
                case 'M': {
                    quotesAndResearchTargetPage = mutualFundsPage;
                    this.goToQuotesAndResearch(quotesAndResearchTargetPage, symbol);
                    break;
                }
                default: {
                }
            }
        } else if (this.appStore.isApp()) {
            switch (fiType) {
                case 'E': {
                    this.sendToQuotesWatchList(symbol);
                    break;
                }
                case 'D': {
                    this.sendToQuotesWatchList(symbol);
                    break;
                }
                default: {
                }
            }
        }
    }

    // navigates to Q&R module for equities/options/MF
    goToQuotesAndResearch(page, symbol) {
        let marketSymbol = '';
        if (symbol.Market) {
            marketSymbol = (symbol.Market === 'US') ? 'US' : 'CA';
        } else {
            marketSymbol = 'CA';
        }
        QuotesResearchBridgeStore.crossFlow = {
            action: page,
            symbol: symbol.SymbolName,
            market: marketSymbol
        };
        this.router.navigate(['/txn/bridge/quotesResearch'], {
            skipLocationChange: true,
        });
    }

    // navigates to watchlist module for wrapper App
    sendToQuotesWatchList(symbol) {
        QuotesDetailStore.inFlow = {
            Symbols: [{
                SymbolName: symbol?.SymbolName,
                Market: symbol.Market === 'CDN' ? 'CA' : 'US'
            }]
        };
        this.router.navigate(['/txn/quotesWatchList/detail']);
    }

}
