import { BaseData } from '../../../../../../shared/models/base-data';

export class BuyBondsOrderData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1
    };
  }
}

export interface BuyBondsOrderInputs {
  quantity: number;
  action: string;
  accountIndex: number;
  market: string;
  caculateBy: string;
  settlementIndex: number;
}
