import { Injectable } from '@angular/core';
import { Observable, empty, forkJoin, observable, ObservableInput } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Lob } from 'src/app/shared/models/lob.enum';
import { ROOT_CTX } from 'src/app/shared/models/user';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { PageStore } from 'src/app/shared/models/page-store';
import { routeConfig } from 'src/app/config/router-mapping';
import { TradeStocksConfirmStore } from '../data/trade-stocks-confirm-store';



@Injectable({
  providedIn: 'root'
})
export class TradeStocksConfirmService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (TradeStocksConfirmStore.params) {
      let ser = input[0];
      let req = ser.input;
      if (TradeStocksConfirmStore.params.TradingPassword) {
        req.TradingPassword = TradeStocksConfirmStore.params.TradingPassword;
        req.SaveTradingPassword = TradeStocksConfirmStore.params.SaveTradingPassword;
      }
      req.Token = TradeStocksConfirmStore.params.Token;

      return input;
    }

    return [];
  }
  // getSettlementAccounts(data){
  //   let req:SettlementAccountsReq = new SettlementAccountsReq();
  //   req.Action = data.Action;
  //   req.AccountNumber = data.AccountNumber;
  //   req.AccountCurrency = data.AccountCurrency;
  //   return this.httpService.post( "/trade/stocks/settlementaccounts",
  //     req
  //   );
  // }

}
