import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { ChangeCashPlanLandingData } from './change-cash-plan-landing-data';

@Injectable({ providedIn: 'root' })
export class ChangeCashPlanLandingStore extends PageStore<ChangeCashPlanLandingData>  {

    constructor() {
        super(new ChangeCashPlanLandingData());
    }

}