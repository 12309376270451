import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Renderer2,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AppStore } from "src/app/shared/models/app-store";
import { SessionExtendTimerService } from "src/app/core/services/session-extend-timer.service";
import { ModalService } from "src/app/shared/components/modal/services/modal.service";
import { DialogService } from "src/app/shared/components/dialog/services/dialog.service";
import { GlobalContentStore } from "src/app/shared/store/global-content-store";
import { CommonService } from "src/app/shared/services/common.service";
import { DOCUMENT } from "@angular/common";
import { EnvService } from 'src/app/env.service';
import { of, Subscription } from "rxjs";
import { ObservableSubscriptionService } from "../../shared/services/observable-subscription.service";
import { TrackingService } from "src/app/shared/services/tracking.service";
import { SpinnerService } from "src/app/core/components/spinner/service/spinner.service";
import { PersistenceService } from "src/app/core/services/persistence.service";
import { Lang, Lob } from 'src/app/shared/models/lob.enum';
import { OmniturePageDetail } from "src/app/shared/models/route-config";
import { isDefined, openWindow } from "src/app/shared/services/utils.service";
import { filter } from "rxjs/operators";
import { IOmnitureAdsData } from "src/app/shared/models/omniture.types";
import { CarouselComponent } from "src/app/shared/components/carousel/carousel.component";
declare let window: any;
declare let require: any;
declare let StatusBar: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Component({
  selector: "app-txn",
  templateUrl: "./txn.component.html",
  styleUrls: ["./txn.component.scss"]
})
export class TxnComponent implements OnInit, AfterViewInit, OnDestroy {
  private chevronList = {
    "/txn/tradeStock/verify": true,
    "/txn/optionsCentral/singleLeg/verify": true,
    "/txn/cashTransfer/verify": true,
    "/txn/mutualFunds/verify": true,
    "/txn/mutualFunds/search": true,
    "/txn/userPreferences/nasdaqAgreement": true,
    "/txn/userPreferences/nyAgreement": true,
    "/txn/userPreferences/opraAgreement": true,
    "/txn/userPreferences/cegAgreement": true,
    "/txn/quotesWatchList/detail": true,
    "/txn/markets/detail": true,
    "/txn/userPreferences/otvc/otvcsetup": true,
    "/txn/userPreferences/otvc/confirm": true,
    "/txn/userPreferences/userIDChange/landing": true,
    "/txn/userPreferences/userIDChange/confirm": true,
    "/txn/userPreferences/changeSignOnPassword/landing": true,
    "/txn/userPreferences/changeSignOnPassword/confirm": true,
    "/txn/userPreferences/changeTradingPassword/landing": true,
    "/txn/userPreferences/changeTradingPassword/confirm": true,
    "/txn/userPreferences/forgotTradingPassword/landing": true,
    "/txn/userPreferences/forgotTradingPassword/confirm": true,
    "/txn/userPreferences/setUpTradeFillAlert/landing": true,
    "/txn/userPreferences/setUpTradeFillAlert/confirm": true,
    "/txn/userPreferences/relateBrokerageAccounts/landing": true,
    "/txn/userPreferences/relateBrokerageAccounts/confirm": true,
    "/txn/userPreferences/removeBrokerageAccounts/landing": true,
    "/txn/userPreferences/removeBrokerageAccounts/verify": true,
    "/txn/userPreferences/removeBrokerageAccounts/confirm": true,
    "/txn/userPreferences/nicknameAccounts/landing": true,
    "/txn/userPreferences/nicknameAccounts/confirm": true,
    "/txn/userPreferences/connectCIBCOnline/landing": true,
    "/txn/userPreferences/connectCIBCOnline/verify": true,
    "/txn/userPreferences/connectCIBCOnline/confirm": true,
    "/txn/userPreferences/changeMailingOptions/landing": true,
    "/txn/userPreferences/changeMailingOptions/verify": true,
    "/txn/userPreferences/changeMailingOptions/confirm": true,
    "/txn/fixed-income/sell": true,
    "/txn/fixed-income/sell/gic/landing": true,
    "/txn/fixed-income/sell/gic/verify": true,
    "/txn/fixed-income/buy-bonds/search": true,
    "/txn/fixed-income/buy-bonds/order": true,
    "/txn/fixed-income/buy-bonds/verify": true,
    "/txn/fixed-income/buy-gic/landing": true,
    "/txn/fixed-income/buy-gic/order": true,
    "/txn/fixed-income/buy-gic/verify": true,
    "/txn/fixed-income/sell/bond/landing": true,
    "/txn/fixed-income/sell/bond/verify": true,
    "/txn/buy-structured-notes/entry": true,
    "/txn/buy-structured-notes/verify": true,
    "/txn/buy-structured-notes/confirm": true,
    "/txn/sell-structured-notes/landing": true,
    "/txn/sell-structured-notes/verify": true,
    "/txn/sell-structured-notes/confirm": true,
    "/txn/regularinvestmentplan/createcashplan/landing": true,
    "/txn/regularinvestmentplan/createcashplan/verify": true,
    "/txn/regularinvestmentplan/createmutualfund/landing": true,
    "/txn/regularinvestmentplan/createmutualfund/verify": true,
    "/txn/regularinvestmentplan/cancelcashplan/landing": true,
    "/txn/regularinvestmentplan/cancelmutualfundplan/landing": true,
    "/txn/regularinvestmentplan/details": true,
    "/txn/regularinvestmentplan/changecashplan/landing": true,
    "/txn/regularinvestmentplan/changecashplan/verify": true,
    "/txn/regularinvestmentplan/changemutualfundplan/landing": true,
    "/txn/regularinvestmentplan/changemutualfundplan/verify": true,
    "/txn/userPreferences/transfer-forms/verify": true,
    "/txn/userPreferences/enable-margin-and-options-trading/landing": true,
    "/txn/userPreferences/enable-margin-and-options-trading/confirm": true,
    "/txn/accountdetail":true
  };

  noFooterRoute = [
    "/txn/tradeOption/landing",
    "/txn/tradeOption/confirm",
    "/txn/tradeOption/verify"
  ];

  private element: any;
  breakPoint: any;
  isMenuOpen: boolean;
  lang: any;
  subscriptions = new Subscription();
  stopCounter: number;
  keepAliveTimerTriggered: boolean = null;
  pageTitleAnnoucement: string;
  h1Title: string;
  showGlobalNav: boolean;
  showChevron: boolean;
  hideChat: boolean = false;
  newsItemsRead: any;
  activeNewsItem: number = -1;
  isApp: boolean;
  isInApp: boolean;
  isAnyApp: boolean;
  uniqueId: string;
  dispFXMarketing:boolean = window.dispFXMarketing;
  openMyaccountInterceptModal: boolean = true;
  openInterceptModal: boolean = true;
  openTradeIdeasInterceptModal: boolean = true;
  openCommissionFreeInterceptModal: boolean = true;
  PEEnabled: boolean = false;
  Lob: string;

  optionsTradingCarouselContent: any = {
    leftHdnText: '',
    rightHdnText: '',
    circleHdnText1: '',
    circleHdnText2: ''
  };

  learnCardCarouselContent: any = {
    leftHdnText: '',
    rightHdnText: '',
    circleHdnText1: '',
    circleHdnText2: '',
    circleHdnText3: ''
  };

  @ViewChild("target", { read: ElementRef }) targetElement: ElementRef;
  @ViewChild('optionsCard') optionsCardCarousel: CarouselComponent;
  currentUrl: string;
  enableHeaderAndFooter: boolean;
  showQuickTrade = (window as any).showQuickTrade;
  showInterceptModalFlag = (window as any).showInterceptModal;
  showMyaccountInterceptModalFlag = (window as any).showMyaccountInterceptModal;
  showTradeIdeasInterceptModalFlag = (window as any).showTradeIdeasInterceptModal;
  showCommissionFreeInterceptModalFlag = (window as any).showCommissionFreeInterceptModal;
  qrCodeString: string = '';
  showQuickTradeOption = (window as any).showQuickTradeOption;

  whatsNewContentCards = [];
  currentWhatsNewContentCards = [];
  whatsNewUnreadCards = false;
  whatsNewActiveName = undefined;
  isDesktopCurrently;


  @HostListener("document:keydown.escape", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    this.isMenuOpen = false;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.isDesktop() != this.isDesktopCurrently) {
      this.isDesktopCurrently = this.isDesktop();
      let self = this;
      self.commonService.getCachedBrazeContentCards(true).then((cards) => {
        self.handleNewContentCards(cards);
      });
    }
  }

  constructor(
    public globalContent: GlobalContentStore,
    private router: Router,
    private appStore: AppStore,
    private sessionExtendTimerService: SessionExtendTimerService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private commonService: CommonService,
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    public subscriptionService: ObservableSubscriptionService,
    private trackingService: TrackingService,
    private spinnerService: SpinnerService,
    private env: EnvService,
    private persistanceService: PersistenceService
  ) {
    this.element = el.nativeElement;
    this.qrCodeString = 'https://' + window.location.host + '/html/redirectToStore.html'
  }

  ngOnInit() {
    this.isDesktopCurrently = this.isDesktop();
    this.isApp = this.appStore.isApp('app');
    this.isInApp = this.appStore.isApp('inapp');
    this.isAnyApp = this.appStore.isApp();
    this.Lob = this.appStore.state.user.lob;
    if (this.Lob == Lob.IE) {
      this.PEEnabled = this.appStore.state.user.premiumUser
    }
    if(this.appStore.isApp("app") && isDefined(StatusBar)) {
      if(this.appStore.state.user.premiumUser) {
        StatusBar.backgroundColorByHexString('#383B3E');
      } else {
        StatusBar.styleDefault();
      }
    }
    this.lang = this.appStore.lang.toLowerCase();

    this.commonService.refreshBrazeContentCards();

    this.startSessionExtensionTimer();
    this.uniqueId = this.appStore.uniqueId;
    if (this.router.url === "/home") {
      this.router.navigateByUrl(
        "/home/" + this.appStore.lang + "/" + this.appStore.lob
      );
    }
    window.sessionStorage.removeItem("refreshURL");
    console.log("TxnComponent", this.router.url);
    this.lang = this.appStore.state.user.lang;
    if (!(this.globalContent.text.pageTitleForUrls == undefined)) {
      const h1PageTitle = this.globalContent.text.pageTitleForUrls[
        this.currentPage()
      ];
      this.h1Title =
        h1PageTitle && h1PageTitle.displayName
          ? h1PageTitle.displayName
          : undefined;
    } else {
      this.h1Title = undefined;
    }
    this.showChevron = this.chevronList[this.currentPage()] ? true : false;
    this.disableGlobalForHelp();
    this.navigationEndChanges();
    this.subscriptions.add(
      this.subscriptionService.chathideEvent$.subscribe((val) => {
        this.hideChat = val;
      })
    );
    this.subscriptions.add(
      this.subscriptionService.universalLinkSource.subscribe((data) => {
        if (data && data != '') {
          this.handleDeepLinks(data);
          this.commonService.deepLinkAuthorized();
          if (this.persistanceService.sessionStoreRetrieve("deepLinkQuickTrade")) {
            this.persistanceService.removeSessionStoreValue("deepLinkQuickTrade");
            setTimeout(() => {
              this.openQuickTrade();
            }, 300);
          }
        }
      })
    );
    this.openMyaccountInterceptModal = this.persistanceService.localStoreRetrieve("myAccountsInterceptModalLatest") != this.globalContent.text.accInterceptID;
    if (this.openMyaccountInterceptModal) {
      requestAnimationFrame(() => {
        if (this.document.getElementById('confirmAccInterceptModal' + this.uniqueId)) {
          this.modalService.open('confirmAccInterceptModal' + this.uniqueId, 'confirmAccInterceptModalHeader' + this.uniqueId);
          this.persistanceService.localStorePersist("myAccountsInterceptModalLatest", JSON.stringify(this.globalContent.text.accInterceptID));
        }
      });
      this.uniqueId = this.appStore.uniqueId;
      // this.modalOmnitureTagging("my-account-modal");
    }

    this.openTradeIdeasInterceptModal = this.persistanceService.localStoreRetrieve("tradeIdeasInterceptModalLatest") != this.globalContent.text.tiInterceptID;
    if (this.openTradeIdeasInterceptModal && this.showTradeIdeasInterceptModalFlag && !this.isAnyApp) {
      requestAnimationFrame(() => {
        if (this.document.getElementById('confirmTIInterceptModal' + this.uniqueId)) {
          if (this.isDesktopCurrently) {
            let sidebar: any = document.getElementsByClassName('sidebar-container');
            if (sidebar && sidebar.length && sidebar.length > 0) {
              let el: any = document.getElementsByClassName('sidebar-container')[0];
              el.style['z-index'] = 801
            }
            let tradeDrawer: any = document.getElementsByClassName('sidebar-qt-options');
            if (tradeDrawer && tradeDrawer.length && tradeDrawer.length > 0) {
              let el: any = document.getElementsByClassName('sidebar-qt-options')[0];
              el.style['z-index'] = 801
            }
          }
          this.modalService.open('confirmTIInterceptModal' + this.uniqueId, 'confirmTIInterceptModalHeader' + this.uniqueId);
          this.persistanceService.localStorePersist("tradeIdeasInterceptModalLatest", JSON.stringify(this.globalContent.text.tiInterceptID));
        }
      });
      this.uniqueId = this.appStore.uniqueId;
    }

    this.openCommissionFreeInterceptModal = this.persistanceService.localStoreRetrieve("commissionFreeInterceptModalLatest") != this.globalContent.text.cfInterceptID;
    if (this.openCommissionFreeInterceptModal && this.showCommissionFreeInterceptModalFlag && !this.isAnyApp) {
      requestAnimationFrame(() => {
        if (this.document.getElementById('confirmCFInterceptModal' + this.uniqueId)) {
          if (this.isDesktop()) {
            let sidebar: any = document.getElementsByClassName('sidebar-container');
            if (sidebar && sidebar.length && sidebar.length > 0) {
              let el: any = document.getElementsByClassName('sidebar-container')[0];
              el.style['z-index'] = 801
            }
            let tradeDrawer: any = document.getElementsByClassName('sidebar-qt-options');
            if (tradeDrawer && tradeDrawer.length && tradeDrawer.length > 0) {
              let el: any = document.getElementsByClassName('sidebar-qt-options')[0];
              el.style['z-index'] = 801
            }
          }
          this.modalService.open('confirmCFInterceptModal' + this.uniqueId, 'confirmCFInterceptModalHeader' + this.uniqueId);
          this.persistanceService.localStorePersist("commissionFreeInterceptModalLatest", JSON.stringify(this.globalContent.text.cfInterceptID));
        }
      });
      this.uniqueId = this.appStore.uniqueId;
    }

    this.openInterceptModal = this.commonService.showInterceptModal;
    if (this.openInterceptModal) {
      requestAnimationFrame(() => {
        if (this.document.getElementById('confirmInterceptModal' + this.uniqueId)) {
          this.modalService.open('confirmInterceptModal' + this.uniqueId, 'confirmInterceptModalHeader' + this.uniqueId);
        }
      });
      this.uniqueId = this.appStore.uniqueId;
      this.modalOmnitureTagging("quick-trade-modal");
    }

    if (this.persistanceService.sessionStoreRetrieve("deepLinkQuickTrade")) {
      this.persistanceService.removeSessionStoreValue("deepLinkQuickTrade");
      setTimeout(() => {
        this.openQuickTrade();
      }, 300);
    }

    let self = this;
    this.subscriptions.add(this.subscriptionService.brazeContentCardSubscriber.subscribe((cards) => {
      self.commonService.getCachedBrazeContentCards(true).then((cards) => {
        self.handleNewContentCards(cards);
      });
    }));
    self.commonService.getCachedBrazeContentCards(true).then((cards) => {
      self.handleNewContentCards(cards);
    });
  }

  getLob() { return this.appStore.lob }

  disableGlobalForHelp() {
    this.currentUrl = this.router.url;
    if (this.currentUrl === "/txn/help" && !this.appStore.isApp('app')) {
      this.enableHeaderAndFooter = false;
    } else {
      this.enableHeaderAndFooter = true;
    }
  }

  // starts the timer for session extension overlay if the counter value is less than '10'
  startSessionExtensionTimer() {
    this.subscriptions.add(
      this.sessionExtendTimerService.sessionExtensionTimer.subscribe(
        (value) => {
          if (value === "sessionTimeout") {
            console.log(
              "session extension modal shown at = " +
              new Date().toLocaleTimeString()
            );
            if (
              window.location.hash == "#/txn/help" &&
              !this.appStore.isApp()
            ) {
              window.close();
            } else {
              this.modalOmnitureTagging("session-extend-overlay:warning");
              this.modalService.open("extendsession", "clockImage");
            }
          } else if (value === "sessionEnd") {
            this.signOff(true);
          }
        }
      )
    );
  }

  openMenu() {
    this.isMenuOpen = true;
    this.document.getElementById("main-app-container").style.overflowY =
      "hidden";
  }

  closeMenu() {
    this.isMenuOpen = false;
    this.document.getElementById("main-app-container").style.overflowY =
      "scroll";
    const focusElement = this.document.getElementById("hamburger-menu");
    window.requestAnimationFrame(() => {
      if (focusElement) {
        focusElement.focus();
      }
    });
  }

  // dialog counts the click and increment by 1 every time with initial '0'
  extendSessionClickCount() {
    this.stopCounter = this.dialogService.getClickCounter();
    this.stopCounter += 1;
    this.dialogService.setClickCounter(this.stopCounter);

    document.scrollingElement.scrollTo({ top: 0 });
    setTimeout(() => {
      this.modalService.setFocus("cibcLogo");
    }, 1000);
  }

  extendSession() {
    this.modalOmnitureTagging("session-extend-overlay:extend-session");
    this.modalService.extendSession();
    this.extendSessionClickCount();
  }

  signOff(sessionTimeout?: boolean) {
    this.spinnerService.setHideBackground();
    this.modalOmnitureTagging("session-extend-overlay:sign-out");
    this.modalService.modalSignOff(sessionTimeout);
  }

  isPermission(routeEnum) {
    return this.commonService.checkPermissionForRoutes(routeEnum);
  }

  navigationEndChanges() {
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.isMenuOpen) {
            this.isMenuOpen = false;
          }
          if (!(this.globalContent.text.pageTitleForUrls == undefined)) {
            const pageTitle = this.globalContent.text.pageTitleForUrls[
              this.currentPage()
            ];
            const h1PageTitle = this.globalContent.text.pageTitleForUrls[
              this.currentPage()
            ];
            this.pageTitleAnnoucement =
              pageTitle && pageTitle.screenReaderAnnouncement
                ? pageTitle.screenReaderAnnouncement
                : "";
            this.h1Title =
              h1PageTitle && h1PageTitle.displayName
                ? h1PageTitle.displayName
                : undefined;
          } else {
            this.pageTitleAnnoucement = "";
            this.h1Title = undefined;
          }
          this.showChevron = this.chevronList[this.currentPage()]
            ? true
            : false;

          this.commonService.brazeCustomEvents();

          if (isDefined(this.appStore.scrollToValue)) {
            setTimeout(() => {
              let elementId = document.getElementById(this.appStore.scrollToValue);
              if (elementId) {
                elementId.scrollIntoView({ 
                  behavior: 'smooth' 
                });
                elementId.focus({preventScroll: true});
              }
              this.appStore.scrollToValue = undefined;
            }, 200);
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    if (!(this.globalContent.text.pageTitleForUrls == undefined)) {
      const pageTitle = this.globalContent.text.pageTitleForUrls[
        this.currentPage()
      ];
      this.pageTitleAnnoucement =
        pageTitle && pageTitle.screenReaderAnnouncement
          ? pageTitle.screenReaderAnnouncement
          : "";
    } else {
      this.pageTitleAnnoucement = "";
    }
    this.showChevron = this.chevronList[this.currentPage()] ? true : false;
    this.cdr.detectChanges();
    this.commonService.brazeCustomEvents();
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  currentPage() {
    return this.appStore.state.state.currentUrl;
  }

  selectedLob() {
    return this.appStore.state.user.lob.toString();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.unsubscribe();
    this.subscriptionService.setUniversalLink('');
    this.persistanceService.sessionStorePersist('chatOpen', false);
    this.persistanceService.sessionStorePersist('chatVisibility', false);
  }

  signOffConfirmResponse(event) {
    this.subscriptionService.modalSend(event);
  }

  modalOmnitureTagging(data) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(data, true));
    }
  }

  closeNews() {
    this.modalService.close('HeaderNewsModal', 'headerNewsLink');
    let modal = document.getElementById("HeaderNewsModal");
    if (modal) {
      let mainBody = modal.getElementsByClassName("header-news-modal-body")[0];
      if (mainBody) {
        mainBody.className = "header-news-modal-body";
      }
    }
    let el = document.getElementById("HeaderNewsModal").getElementsByClassName("header-news-modal-body-2")[0];
    if (el) {
      el.className = "header-news-modal-body-2";
    }
    this.activeNewsItem = -1;
  }

  readNews(i) {
    this.activeNewsItem = i;
    this.whatsNewActiveName = this.currentWhatsNewContentCards[this.activeNewsItem].extras.activityName;
    this.commonService.logContentCardImpressions([this.currentWhatsNewContentCards[i]]);
    let el = document.getElementById("HeaderNewsModal").getElementsByClassName("header-news-modal-body-2")[0];
    if (el) {
      el.className = "header-news-modal-body-2 opening";
    }
    setTimeout(() => {
      this.areCardsUnread();
      let el2 = document.getElementById('headerNewsModalDrawerButton');
      if (el2) {
        el2.focus();
      }
    }, 100);
  }

  closeNewsItem(immediate:boolean = false) {
    this.whatsNewActiveName = undefined;
    let el = document.getElementById("HeaderNewsModal").getElementsByClassName("header-news-modal-body-2")[0];
    if (el) {
      el.className = "header-news-modal-body-2";
    }
    if (immediate) {
      this.activeNewsItem = -1;
    } else {
      setTimeout(() => {
        this.activeNewsItem = -1;
      }, 500);
    }
    let el2 = document.getElementById('header-news-modal-option-' + this.activeNewsItem);
    if (el2) {
      el2.focus();
    }
  }
  
  handleNewContentCards(cards) {
    this.whatsNewContentCards = this.commonService.filterCardsForLocation(cards, "whats_new");

    // this.whatsNewContentCards = [
    //   {
    //     description: "test",
    //     extras: {
    //       section1Heading: "Heading 1",
    //       section1Link1PreText: "Pre",
    //       section1Link1Text: "Link 1",
    //       section1Link1URL: "#/txn/accounts/myaccounts",
    //       section1Link1AppURL: "https://www.youtube.com",
    //       section1Link1ScrollTo: "newsTab",
    //       section1Link2PostText: "<strong>Post</strong>",
    //       section1Link2Text: "Link 2",
    //       section1Link2URL: "#/txn/accounts/myaccounts",
    //       section1Link2ScrollTo: "newsTab",
    //       section1Link2AppURL: "https://www.youtube.com",
    //       section1Body: "This <strong>is</strong> the Body",
    //       section1List1Item1: "List1 Item1",
    //       section1List1Item2: "List1 Item2",
    //       section1List1Item3: "List1 Item3",
    //       section1List1Item4: "List1 Item4",
    //       section1List1Item6: "List1 Item6",
    //       section1List2Item1: "List1 Item1",
    //       section1List2Item2: "List1 Item2",
    //       section1List2Item3: "List1 Item3",
    //       section2Heading: "Heading 2",
    //       section2Link1PostText: "Post",
    //       section2Link1Text: "<b>Link 3</b>",
    //       section2Link1URL: "#/txn/accounts/myaccounts",
    //       section2Link1ScrollTo: "newsTab",
    //       section2Link1AppURL: "https://www.youtube.com",
    //       section2Link2PreText: "Pre",
    //       section2Link2Text: "Link 4",
    //       section2Link2URL: "#/txn/accounts/myaccounts",
    //       section2Link2ScrollTo: "newsTab",
    //       section2Link2AppURL: "https://www.youtube.com",
    //       section2Body: "This is the Body",
    //       section2List1Item1: "List1 Item1",
    //       section2List1Item2: "List1 Item2",
    //       section2List1Item3: "List1 Item3",
    //       section2List1Item4: "List1 Item4",
    //       section2List1Item5: "List1 Item5",
    //       section2List2Item1: "List2 Item1",
    //       section2List2Item2: "List2 Item2",
    //       section2List2Item3: "List2 Item3",
    //       primaryButtonText: "button",
    //       primaryButtonURL: "#/txn/accounts/myaccounts",
    //       primaryButtonScrollTo: "newsTab",
    //       primaryButtonAppURL: "https://www.youtube.com",
    //       bottomLink1Text: "bottom <b>Link</b> 1",
    //       bottomLink1PreText: "Pre",
    //       bottomLink1URL: "#/txn/accounts/myaccounts",
    //       bottomLink1ScrollTo: "newsTab",
    //       bottomLink1AppURL: "https://www.youtube.com",
    //       bottomLink2Text: "bottom Link 2",
    //       bottomLink2PostText: "<b>Post</b>",
    //       bottomLink2URL: "#/txn/accounts/myaccounts",
    //       bottomLink2ScrollTo: "newsTab",
    //       bottomLink2AppURL: "https://www.youtube.com"
    //     }
    //   }
    // ];

    for(let i = 0; i < this.whatsNewContentCards.length; i++) {
      if (this.appStore.isApp('app')) {
        Object.keys(this.whatsNewContentCards[i].extras).forEach((key) => {
          if (key.endsWith('AppURL')) {
            let newKey = key.substring(0,key.length - 6) + "URL";
            this.whatsNewContentCards[i].extras[newKey] = this.whatsNewContentCards[i].extras[key];
          }
        });
      }
      let extras:any = {};
      Object.keys(this.whatsNewContentCards[i].extras).forEach((key) => {
        if (key.startsWith('section')) {
          let index = parseFloat(key.substring(7,key.length));
          let section = key.substring(0,7) + index;
          let newKey = key.substring(section.length,key.length);
          if (!extras.sections) {
            extras.sections = [];
          }
          if (!extras.sections[index]) {
            extras.sections[index] = {}
          }
          extras.sections[index][newKey] = this.whatsNewContentCards[i].extras[key];
        } else if (key.startsWith('bottomLink')) {
          let index = parseFloat(key.substring(10,key.length));
          let link = key.substring(0,10) + index;
          let newKey = key.substring(link.length,key.length);
          if (!extras.bottomLinks) {
            extras.bottomLinks = [];
          }
          if (!extras.bottomLinks[index]) {
            extras.bottomLinks[index] = {}
          }
          extras.bottomLinks[index][newKey] = this.whatsNewContentCards[i].extras[key];
        } else {
          extras[key] = this.whatsNewContentCards[i].extras[key];
        }
      });
      this.whatsNewContentCards[i].extras = extras;
      for (let k = 0; k < this.whatsNewContentCards[i].extras.sections.length; k++) {
        if (this.whatsNewContentCards[i].extras.sections[k]?.List1Item1) {
          let tempList = [];
          let j = 1;
          while(this.whatsNewContentCards[i].extras.sections[k]['List1Item' + j]) {
            tempList.push(this.whatsNewContentCards[i].extras.sections[k]['List1Item' + j]);
            j++;
          }
          this.whatsNewContentCards[i].extras.sections[k].list1 = tempList;
        }
        if (this.whatsNewContentCards[i].extras.sections[k]?.List2Item1) {
          let tempList = [];
          let j = 1;
          while(this.whatsNewContentCards[i].extras.sections[k]['List2Item' + j]) {
            tempList.push(this.whatsNewContentCards[i].extras.sections[k]['List2Item' + j]);
            j++;
          }
          this.whatsNewContentCards[i].extras.sections[k].list2 = tempList;
        }
      }
    }

    this.setCurrentContentCards(this.isDesktopCurrently);
  }

  setCurrentContentCards(isDesktop) {
    if (isDesktop) {
      console.log(this.currentWhatsNewContentCards);
      this.currentWhatsNewContentCards = this.commonService.getBigCards(this.whatsNewContentCards);
    } else {
      this.currentWhatsNewContentCards = this.commonService.getSmallCards(this.whatsNewContentCards);
    }

    if (this.activeNewsItem >= this.currentWhatsNewContentCards.length || (this.activeNewsItem > -1 && this.whatsNewActiveName != this.currentWhatsNewContentCards[this.activeNewsItem].extras.activityName)) {
      let newactive = -1;
      for (let i = 0; i < this.currentWhatsNewContentCards.length; i++) {
        if (this.whatsNewActiveName == this.currentWhatsNewContentCards[i].extras.activityName) {
          newactive = i;
        }
      }
      if (newactive > -1) {
        this.activeNewsItem = newactive;
      } else {
        this.closeNewsItem(true);
      }
    }

    this.areCardsUnread();
  }

  areCardsUnread() {
    this.whatsNewUnreadCards = false;

    for (let i = 0; i < this.currentWhatsNewContentCards.length; i++) {
      if (this.currentWhatsNewContentCards[i].viewed) {
        //Nothing
      } else {
        this.whatsNewUnreadCards = true;
        break;
      }
    }
  }

  whatsNewLink(url: string, card, scrollTo?) {
    if (isDefined(url)) {
      this.commonService.logContentCardClick(card);
      if (url.startsWith("#") || url.startsWith("/")) {
        if(isDefined(scrollTo)) {
          if (this.router.url == url.replace(/^#/,"")) {
            this.closeNews();
            setTimeout(() => {
              let elementId = document.getElementById(scrollTo);
              if (elementId) {
                elementId.scrollIntoView({ 
                  behavior: 'smooth' 
                });
                elementId.focus({preventScroll: true});
              }
            }, 200);
          } else {
            this.closeNews();
            this.appStore.scrollToValue = scrollTo;
            this.router.navigate([url.replace(/^#/,"")]);
          }
        } else {
          this.closeNews();
          this.router.navigate([url.replace(/^#/,"")]);
        }
      } else {
        openWindow(url, this.appStore);
      }
    }

  }

  get LOB() { return Lob; }

  closeAccInterceptModal() {
    this.openMyaccountInterceptModal = false;
    this.modalOmnitureTagging("my-account-modal:close");
    this.modalService.close('confirmAccInterceptModal' + this.uniqueId);
  }

  closeTIInterceptModal() {
    this.openTradeIdeasInterceptModal = false;
    this.modalService.close('confirmTIInterceptModal' + this.uniqueId);
    if (this.isDesktopCurrently) {
      let sidebar: any = document.getElementsByClassName('sidebar-container');
      if (sidebar && sidebar.length && sidebar.length > 0) {
        let el: any = document.getElementsByClassName('sidebar-container')[0];
        el.style['z-index'] = 903
      }
      let tradeDrawer: any = document.getElementsByClassName('sidebar-qt-options');
      if (tradeDrawer && tradeDrawer.length && tradeDrawer.length > 0) {
        let el: any = document.getElementsByClassName('sidebar-qt-options')[0];
        el.style['z-index'] = 903
      }
    }
  }

  closeCFInterceptModal() {
    this.openCommissionFreeInterceptModal = false;
    //this.modalOmnitureTagging("commission-free-modal:close");
    this.modalService.close('confirmCFInterceptModal' + this.uniqueId);
  }

  // close intercept modal
  closeInterceptModal() {
    this.openInterceptModal = false;
    this.modalOmnitureTagging("quick-trade-modal:close");
    this.modalService.close('confirmInterceptModal' + this.uniqueId);
  }

  openQuickTrade() {
    this.openMyaccountInterceptModal = false;
    //this.modalOmnitureTagging("quick-trade-modal:try-it-now");
    this.modalService.close('confirmAccInterceptModal' + this.uniqueId);
    if (this.isDesktopCurrently) {
      this.subscriptionService.openOrCloseDrawer.next('mobile');
    } else {
      this.router.navigate(['/txn/tradeStock/landing']);
    }
  }

  whatsNewSkipLink() {
    if (this.isDesktopCurrently) {
      let id = 'header-news-modal-option-' + this.activeNewsItem;
      const el = document.getElementById(id);
      if (isDefined(el)) {
        el.focus();
      }
    } else {
      this.closeNewsItem()
    }
  }

  goToOptionsCentral() {
    this.closeNews();
    this.router.navigate(['/txn/optionsCentral']);
  }

  goToPreferences() {
    this.closeNews();
    this.router.navigate(['/txn/userPreferences']);
  }

  // whatsNewLink(url, internal, omni, scrollTo?) {
  //   console.log(url);
  //   console.log(internal);
  //   if (internal) {
  //     this.router.navigate([url]);
  //     this.closeNews();
  //   } else {
  //     if (omni) {
  //       let data: IOmnitureAdsData = this.getAdsData(omni);

  //       this.adsOmnitureTagging(data, false, true);
  //     }
  //     openWindow(url, this.appStore);
  //   }
  //   if (scrollTo) {
  //     setTimeout(() => {
  //       let elementId = document.getElementById(scrollTo);
  //       if (elementId) {
  //         elementId.scrollIntoView({ 
  //           behavior: 'smooth' 
  //         });
  //         elementId.focus({preventScroll: true});
  //       }
  //     }, 200);
     
  //   }
  // }

  openTradeDrawerOption() {
    this.closeNews();
    if (this.isDesktopCurrently) {
      this.subscriptionService.quickOptionsOpenOrCloseDrawer.next('mobile');
    } else {
      this.router.navigate(['/txn/optionsCentral/singleLeg/landing']);
    }
  }

  adsOmnitureTagging(data: IOmnitureAdsData, isImpression: boolean, isClick: boolean) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagOmnitureAdsAction(data, isImpression, isClick));
    }
  }

  getAdsData(omni) {
    let data: IOmnitureAdsData = {
      trackingCode: "",
      location: "",
      type: ""
    };
    data['trackingCode'] = omni;
    data['location'] = "whats-new";
    data['type'] = "mass-ad";

    return data;
  }
  // adding modal service to open newsletterModal
  openSignupNewsletterModal() {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction('whats-new:quotes-research-newsletter-catalogue', false));
    }
    this.modalService.open('signupNewsletterModal', 'signupNewsletterModalHeader');
  }

  // adding modal service to close newsletterModal
  closeSignupNewsletterModal() {
    this.modalService.close('signupNewsletterModal', 'SignupNewsletterBtn');
  }

  focusBacktoCard(id) {
    let el = document.getElementById(id);
    if (isDefined(el)) {
      el.focus();
    }
  }

  focusIndividualOptionsCard(i) {
    this.optionsCardCarousel.selectedItem(i);
  }

  handleDeepLinks(universalLink, isUniversal?) {
    let universalLinkSub = '/deepLink/?universalLink';
    let deepLink = universalLink.substring(universalLink.indexOf(universalLinkSub) + universalLinkSub.length);
    let newUrl = universalLink.substring(0, universalLink.indexOf(universalLinkSub)) + '/?deepLink' + deepLink;
    //window.location.href = newUrl;
    const url = new URL(newUrl);

    if (url.search != "") {
      let deepLink = url.searchParams.get("deepLink");
      if (deepLink) {
        this.persistanceService.sessionStorePersist("deepLink", deepLink);

        if (this.commonService.getDeepLinks()[deepLink] && this.commonService.getDeepLinks()[deepLink].lob) {
          this.subscriptionService.passLob(this.commonService.getDeepLinks()[deepLink].lob);
        }
        let extras = this.commonService.getDeepLinks()[deepLink]?.extraParams;
        if (extras) {
          let deepLinkExtras = {};
          for (let i = 0; i < extras.length; i++) {
            if (url.searchParams.has(extras[i])) {
              deepLinkExtras[extras[i]] = url.searchParams.get(extras[i]);
            }
          }
          this.persistanceService.sessionStorePersist("deepLinkExtras", JSON.stringify(deepLinkExtras));
        }
      }
      let dynamicDeepLink = url.searchParams.get("dynamicDeepLink");
      if (dynamicDeepLink) {
        this.persistanceService.sessionStorePersist("deepLink", dynamicDeepLink);

        if (url.searchParams.get("dynamicLOB")) {
          this.persistanceService.sessionStorePersist("deepLinkLOB", url.searchParams.get("dynamicLOB"));
          this.subscriptionService.passLob(url.searchParams.get("dynamicLOB"));
        }
      }
    }
  }
        
}

