import { Injectable } from '@angular/core';
import { SignonData } from './signon-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SignonStore extends PageStore<SignonData> {

  constructor() {
    super(new SignonData());
  }

}
