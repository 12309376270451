import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppPreloadingStrategy implements PreloadingStrategy {

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      // add the route path to the preloaded module array
      // log the route path to the console
      //console.log('Preloading Modules :' + route.path, route.loadChildren);
      return load();
    } else {
      //console.log('On Demand Modules : ' + route.path, route.loadChildren);
      return of(null);
    }
  }
}
