import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class GlobalFixGuard  {

  // This Guard is mainly for some global level fix for all modules or component for PROD issues
  // for example : we have scrolling stuck issue once modal opens and then sometime scrollbar won't come back and many user got stuck
  // so for that issue we are putting scrolling auto back (similiar to when modal close) in every router navigation so atleast when
  // user navigate to other page it will be fine. so this kind of global fixes will go in this guard.
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  canActivate(): boolean {
    this.document.body.style.overflow = 'auto';
    return true;
  }

}
