import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { AppRouteResolve } from 'src/app/core/strategies/app-route-resolve'


const routes: Routes = [
  {
    path: 'en/gen',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/gen',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/ie',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/ie',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/iis',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/iis',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/cfpi',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/cfpi',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/wg',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/wg',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/cpic',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/cpic',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  //
  {
    path: 'en/gen/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/gen/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/ie/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/ie/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/iis/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/iis/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/cfpi/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/cfpi/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/wg/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/wg/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/cpic/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/cpic/ios/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },

  //

  {
    path: 'en/gen/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/gen/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/ie/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/ie/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/iis/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/iis/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/cfpi/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/cfpi/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/wg/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/wg/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'en/cpic/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  },
  {
    path: 'fr/cpic/android/:ver',
    component: HomeComponent,
    resolve: { pageData: AppRouteResolve }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule { }
