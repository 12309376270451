import { Injectable } from '@angular/core';
import { NasdaqAgreementData } from './nasdaq-agreement.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class NasdaqAgreementStore extends PageStore<NasdaqAgreementData> {

    constructor() {
        super(new NasdaqAgreementData());
    }

}

