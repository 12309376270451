import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { StickyHeaderDirective } from 'src/app/shared/directive/sticky-header-directive';
import { StickyHeaderComponent } from 'src/app/shared/components/sticky-header/sticky-header.component';

@NgModule({
  declarations: [
    StickyHeaderComponent,
    StickyHeaderDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    StickyHeaderComponent,
    StickyHeaderDirective
  ]
})
export class StickyHeaderModule { }
