import { BaseData } from 'src/app/shared/models/base-data';

export class BuyStructNotesSelectionData extends BaseData {
  constructor() {
    super();
  }
}

export interface ColumnArray {
  index: number;
  asc: boolean;
  names: string[];
  text: string[];
  sorting: string[];
  srText: string[];
}

export interface SortArray {
  index: number;
  asc: boolean;
  names: string[];
  text: string[];
}

export interface BuyStructNotesList {
  AvailableUntil: string;
  CblCode: string;
  Currency: string;
  Dealid: string;
  MarketingDocSize: string;
  NoteName: string;
  NoteWebsiteURL: string;
  OfferingDocSize: string;
  Description: string;
  Term: string;
}

export interface NotesReq {
  ProductType?: string;
  SortOption?: {
    SortColumn: string,
    SortType: string
  }
}
