import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';
import { AppStore } from '../models/app-store';

@Directive({ selector: '[webOnly]' })
export class WebDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private appStore: AppStore) {
  }

  ngOnInit() {
    if (!this.appStore.isApp()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
