import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { ChangeCashPlanConfirmData } from './change-cash-plan-confirm-data';

@Injectable({ providedIn: 'root' })
export class ChangeCashPlanConfirmStore extends PageStore<ChangeCashPlanConfirmData>  {

    constructor() {
        super(new ChangeCashPlanConfirmData());
    }

}