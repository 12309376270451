import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { ToAccountListReq, ToAccountDetailReq } from '../data/cashtransfer-landing-data';
import { HttpHeaders } from '@angular/common/http';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class CashtransferLandingService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  getToAccountList(data) {
    const req: ToAccountListReq = new ToAccountListReq();
    req.FromAccount = data.FromAccount;
    req.CashTransferAcctType = data.CashTransferAcctType;
    req.IncludeBalance = data.IncludeBalance;
    return this.httpService.post(gatewayConfig.APIServices.cashtransferGetAccountList.url, req);
  }

  getToAccountDetail(data) {
    const req: ToAccountDetailReq = new ToAccountDetailReq();
    req.AccountNumber = data.AccountNumber;
    req.AccountType = data.AccountType;
    req.Currency = data.Currency;
    return this.httpService.post(gatewayConfig.APIServices.cashtransferGetToAccountDetail.url, req);
  }
  getAdvisorData() {
    return this.httpService.post(gatewayConfig.APIServices.getAdvisor.url, {});
  }
  /* START : FX Cash Transfer Test link */
  getFxData() {
    return this.httpService.post(gatewayConfig.APIServices.cashtransferGetFx.url, {}, { params: { skiploading: 'true' } });
  }

  invokeASGAjaxCall1(tokenUrl: string, clientToken: string) {
    console.log("service invokeASGAjaxCall1");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Basic " + clientToken
      }),
      params: { skiploading: 'true' }
    };
    let data = '{"grant_type":"client_credentials"}';
    return this.httpService.postToExternalURL(tokenUrl, data, httpOptions);
  }

  invokeASGAjaxCall2(dataUrl: string, accessToken: string, clientData: string) {
    console.log("service invokeASGAjaxCall2");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessToken
      }),
      params: { skiploading: 'true' }
    };
    let data = '{"data":"' + clientData + '"}';
    return this.httpService.postToExternalURL(dataUrl, data, httpOptions);
  }
  /* END : FX Cash Transfer Test link */

}
