import { Injectable } from '@angular/core';
import { TradeOptionsLandingData } from './trade-options-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TradeOptionsLandingStore extends PageStore<TradeOptionsLandingData> {

    constructor() {
        super(new TradeOptionsLandingData());
    }

}
