import { BaseData } from '../../../../shared/models/base-data';

export class RegularInvestmentPlanData extends BaseData {

}

export interface ripAccountRequest {
    AccountNumber: number;
    SortBy?: {
        SortByFieldName: string;
        SortByOrder: string;
    }
}

export interface accountList {
    AccountNumber: number,
    AccountTypeDesc: string,
    AccountFriendlyName?: string
}

export interface soOrderList {
    NextOrderDate: string;
    FiTypeCode: string;
    Action: string;
    Amount?: number;
    OrderSymbol?: string;
    FundName: string;
    Frequency: number;
    orderStatus: string;
}

export interface ripDataResp {
    Account: Account;
    BrokerNumber: string,
    Sequence: string,
    AccountList?: accountList[];
    SoOrderList?: soOrderList[]
}

export interface Account {
    AccountNumber: number;
    AccountType: string;
    AccountFriendlyName: string;
}

export interface sortArray {
    index: number,
    asc: boolean,
    names: string[],
    text: string[],
    columnIndex: number[]
}

export interface columnArray {
    index: number,
    asc: boolean,
    sortByList: sortByList[];
}

export interface sortByList {
    displayDescription: string,
    index: number,
    name: string,
    value: string
}

export const sort = {
    asc: 'A',
    dsc: 'D'
}