import { Injectable } from '@angular/core';
import { QuickTradeStocksVerifyData } from './quick-trade-stocks-verify-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class QuickTradeStocksVerifyStore extends PageStore<QuickTradeStocksVerifyData> {

    constructor() {
        super(new QuickTradeStocksVerifyData());
    }

}
