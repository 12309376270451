import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogService } from 'src/app/shared/components/dialog/services/dialog.service';
import { SsoService } from 'src/app/modules/pre-txn/sso/sso.service';
import { AppStore } from 'src/app/shared/models/app-store';

@Injectable({
  providedIn: 'root'
})

export class SessionExtendTimerService {
  timer: any;
  modalTimer: any;
  maximumTimeOut: number = null;
  maximumExtendSessionCounter = 8;
  getSessionExtendCount: number;
  sessionExtensionTimer: Subject<string>;
  heartBeatTimer: any;
  newTimerValue: number = null;
  retrieveSessionInfo: any;
  worker: any;
  fxTimer: Subject<any>;
  logOutTimer: Subject<string>;

  constructor(
    private dialogService: DialogService,
    private ssoService: SsoService,
    private appstore: AppStore
  ) {
    this.sessionExtensionTimer = new Subject<string>();
    this.fxTimer = new Subject<any>();
    this.logOutTimer = new Subject<any>();
  }

  makeWorker() {
    if (typeof Worker !== 'undefined') {
      // Create a new
      this.deleteWorker();

      this.worker = new Worker('./assets/scripts/workers/app.worker.js?version=' + this.appstore.version.number);
      this.worker.onmessage = ({ data }) => {
        if (data == "modal") {
          this.sendTimerupdate();
          this.clearHeartBeatTimer();
        } else if (data == "signoff") {
          this.sendTimerupdate(true);
        }else if(data == "fxTimerOff"){
          this.fxTimer.next('fxTimerOff')
        }else if(data == "fxTimerOn"){
          this.fxTimer.next("fxTimerOn");
        } 
        else if(data == "logOutTimerOff"){
          this.logOutTimer.next('logOutTimerOff')
        }else if(data === "logOutTimerOn"){
          this.logOutTimer.next("logOutTimerOn");
        }
      };
    }
  }

  deleteWorker() {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  startWorker(data) {
    if (this.worker) {
      this.worker.postMessage(data);
    }
  }


  // timer for 9 minutes
  startTimer() {
    this.retrieveSessionInfo = this.appstore.retrieveSessionInfo;
    if (this.retrieveSessionInfo) {
      this.getSessionExtendCount = this.dialogService.getClickCounter();
      if (this.dialogService.getClickCounter() <= this.maximumExtendSessionCounter) {
        this.newTimerValue = this.retrieveSessionInfo.SessionOverlayDelta * 1000;
        this.startWorker({
          message: 'start',
          timeout: this.retrieveSessionInfo.SessionTimeout,
          delta: this.retrieveSessionInfo.SessionOverlayDelta
        })
      } else {
        this.newTimerValue = this.retrieveSessionInfo.SessionTimeout * 1000;
        this.clearHeartBeatTimer();
      }
    }
  }

  clearHeartBeatTimer() {
    // console.log('new timer value', this.newTimerValue);
    if (this.ssoService.getPersistenceTimeStamp()) {
      this.heartBeatTimer = setTimeout(() => {
        this.ssoService.clearSsoHeartbeattimer();
      }, this.newTimerValue);
    }
  }

  sendTimerupdate(end: boolean = false) {
    if (end) {
      this.sessionExtensionTimer.next('sessionEnd');
    } else {
      this.sessionExtensionTimer.next('sessionTimeout');
    }
  }

  // resets the timer to '0'
  resetTimer() {
    if (this.worker) {
      this.worker.postMessage({message: 'clear'});
    }
    clearTimeout(this.heartBeatTimer);
    this.startTimer();
  }

  resetFxTimer() {
    if (this.worker) {
      //console.log('resetFxTimer');
      this.worker.postMessage({message: 'fxTimerClear'});
    }
  }

  startFxTimer(time?) {
    //console.log('startFxTimer');
    this.startWorker({
      message: 'fxTimerStart',
      timeout: time ?? 300000
    })
  }

  resetLogOutTimer() {
    if (this.worker) {
      this.worker.postMessage({message: 'logoutTimerClear'});
    }
  }

  startLogOutTimer(time?) {
    this.startWorker({
      message: 'startlogoutTimer',
      timeout: time ?? 43200000
    })
  }

}
