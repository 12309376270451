import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AppStore } from '../models/app-store';
import { isDefined } from '../services/utils.service';
import { Lob } from '../models/lob.enum';


@Directive({
  selector: '[appUserIdCriteria]'
})

export class userIdCriteriaDirective {

  private userIdlength = false;
  private userIdletter = false;
  private userIdspecialChar = false;

  @Input() showOnBlur;
  @Output() userIdTooltipDialog = new EventEmitter<boolean>();
  @Output() userIdStrengthDetails = new EventEmitter<{
    userIdLength: boolean;
    userIdletter: boolean;
    userIdspecialChar: boolean;
  }>();
  @Output() userIdCriteriaMatch = new EventEmitter<boolean>()
  constructor(
    private element: ElementRef,
    private appStore: AppStore
  ) { }
  private lowercaseRegex = /[a-zA-Z]/;
  private specialCharRegex = /[^a-zA-Z0-9\s]/;
  private spaceRegex = /\s/;

  ngOnInit() {
    if(this.appStore.lob == Lob.WG) {
      this.userIdTooltipDialog.emit(true);
      let userIddetails = {
        userIdLength: undefined,
        userIdletter: undefined,
        userIdspecialChar: undefined,
      };
      this.userIdStrengthDetails.emit(userIddetails);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event) {
    if(!event.relatedTarget || (event.relatedTarget.type != "submit")) {
      if(!this.showOnBlur) {
        this.userIdTooltipDialog.emit(false);
      }
    //this.userIdTooltipDialog.emit(false);
  }
}

  @HostListener('focus',['$event'])
  onFocus(){
    this.userIdTooltipDialog.emit(true);
  }

  @HostListener('input')
  checkUserIdCriteria() {
    const userId = this.element.nativeElement.value;
    let userIddetails = {
      userIdLength: undefined,
      userIdletter: undefined,
      userIdspecialChar: undefined,
    };
    if(!isDefined(userId)) {
      this.userIdStrengthDetails.emit(userIddetails)
      this.userIdCriteriaMatch.emit(false)
    } else {
    this.userIdlength = userId.length > 5 && userId.length < 26 && !this.spaceRegex.test(userId);
    this.userIdletter = this.lowercaseRegex.test(userId);
    this.userIdspecialChar = this.specialCharRegex.test(userId);


    if (this.userIdlength == true && this.userIdletter == true && this.userIdspecialChar == false) {
      this.userIdCriteriaMatch.emit(true);
    } else {
      this.userIdCriteriaMatch.emit(false);
    }
    const userIddetails = {
      userIdLength: this.userIdlength,
      userIdletter: this.userIdletter,
      userIdspecialChar: this.userIdspecialChar,
    };
    this.userIdStrengthDetails.emit(userIddetails)
  }
}

}
