import { BaseData } from 'src/app/shared/models/base-data';

export class OptionChainData extends BaseData {
  constructor() {
    super();
    this.input= {
      toggleChart : 'CHAIN',
      optionStrikePriceIndex: -1,
      optionStrikeDateIndex: -1,
      strikeOption: 'ALL',
      optionType:'BOTH',
      manualOptionType:'',
      strikeShown : 20
    }
  }
}
export interface OptionChainInputs {
  toggleChart:string;
  optionStrikePriceIndex: number;
  optionStrikeDateIndex: any;
  strikeOption:string;
  optionType:string;
  manualOptionType:string;
  strikeShown: any;
}

export class QuoteReq {
  Symbols: [
    {
      SymbolName: string;
      Market: string;
      SymbolType: string;
    }
  ];
}

export class ChainReq {
  SymbolName: string;
  Market: string;
  StrikePrice?: string;
  ExpiryDate?: string;
}

export const nasdaqSymbolsArray = [
  "NXB",
  "NBN",
  "NBA"
];
    