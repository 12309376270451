import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { PageService } from 'src/app/shared/services/page-service';
import { CreateMutualFundVerifyStore } from "../data/create-mutual-fund-verify-store";
declare let require: any;
const gatewayConfig = require("../../../../../../config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})
export class CreateMutualFundVerifyService implements PageService {

    constructor(private httpService: HttpService) { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        const quote = input[1];
        ser.input = CreateMutualFundVerifyStore.params;
        quote.input = { SymbolName: CreateMutualFundVerifyStore.params.Symbol };
        return input;
    }
    cashPlanVerifyForm(form, fb) {
        form = fb.group({
            agreeProceed: false,
            tradingPassword: "",
            saveTradingPassword: false,
            fundFactAck: false
        });
        return form;
    }

    getFundFacts() {
        return this.httpService.post(gatewayConfig.APIServices.getFundFactAck.url, {}, { params: { skiploading: 'true' } }
        );
    }

}