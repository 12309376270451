<app-global-refresh *ngIf="mode == 'quickTrade' && quickTradeContent && quickTradeContent.name =='quicktradeoptionsverification'" type="time" [name]="'tradeOptions'"></app-global-refresh>
<div class="bg-mobile" *ngIf="quickTradeContent && quickTradeContent.name =='quicktradeoptionsverification'">
    <form *ngIf='data' (ngSubmit)="next()" #quickTradeOptionVerifyForm="ngForm">
      
      <div class="page-top-invisible-divider -ms-page-top-divider-two-col field-notify" [ngClass]="{'small-header': this.mode != 'quickTrade'}">
        <custom-app-error class="marginBottom10" (formErrorPass)="resetTradePassword($event)"
            [identifier]="'custom-error'+uniqueName"></custom-app-error>
        <div *ngFor="let warning of orderWarnings let j = index">
          <app-page-level-error *ngIf="(warning !== undefined)" [identifier]="'trade-options-verify-alert-' + j" [error]="warning.Message"
            [mode]="'alert'">
          </app-page-level-error>
        </div>
      </div>
      <!-- Mobile Design Starts -->
      <div class="overlay-body" >
        <div class="account-details">
          <!-- <div class="hidden-accessibility-container">
            <app-field-level-error-global-message [errorList]="formError"></app-field-level-error-global-message>
          </div> -->
          <div class="container">
            <div class="account-detail">
              <div class="divTable">
                <div class="divTableBody">
  
                  <div class="divTableRow">
                    <div class="divTableCell title">{{quickTradeContent.text.activity}}</div>
                    <div class="divTableCell expiry-date">
                      {{globalContent.getActionContent(orderReviewInfo.Activity.OrderType)}} <span>
                        {{orderReviewInfo.Activity.Quantity.Content}}</span> {{quickTradeContent.text.contracts}} {{quickTradeContent.text.specialCharacterOne}}
                      <span *ngIf="orderReviewInfo.Price.Type == 'MKT'">{{quickTradeContent.text.market}}</span>
                      <span *ngIf="orderReviewInfo.Price.Type=='LIMIT'">{{quickTradeContent.text.limit}}
                        {{orderReviewInfo.Price.LimitAmount.Content}}</span>
                    </div>
                  </div>
                  <div class="divTableRow">
                    <div class="divTableCell title">{{quickTradeContent.text.optionandsymbol}}</div>
                    <div class="divTableCell"> 
                      <span class="description-title">{{formatDate(orderReviewInfo.Symbol.OptionInfo.StrikeDate, "MMM DD, YYYY", "DD MMM, YYYY")}}</span>
                      <span class="description-title">  {{orderReviewInfo.Symbol.OptionInfo.StrikePrice.Value}}</span>
                      <span class="description-title">  {{getOptionTypeString(orderReviewInfo.Symbol.OptionInfo.OptionType)}}</span>
                      <div class="company-name">
                        <img class="quote-flag quote-flag-mbl" width="20" aria-hidden="true" alt=""
                        [src]='getFlag(orderReviewInfo.Symbol.Market)' /><span class="com-title title-desc description-title">
                        {{globalContent.getSymbolContent(orderReviewInfo.Symbol.SymbolName,orderReviewInfo.Symbol.Market)}}</span>
                      <span class="new-symbol-name paddingLeft6" *ngIf="quote && quote.Symbol && quote.Symbol.SymbolLongName"
                            [ngClass]="quote.Symbol.SymbolLongName.length > 48 ? 'new-symbol-small-name' : ''" >{{quote.Symbol.SymbolLongName}}
                          </span>
                      </div>
  
                    </div>
                  </div>
                  <div class="divTableRow">
                    <div class="divTableCell title">{{quickTradeContent.text.expiry}}</div>
                    <div class="divTableCell title-desc">
                      <span class="expiry-date">
                      {{globalContent.getNewExpiryContent(orderReviewInfo.Expiry.ExpiryType, orderReviewInfo.Expiry.ExpiryDate, "MMMM D, YYYY",'D MMMM YYYY',appStore.lang)}}
                      </span><br />
                      <span class="expiry-date">
                        {{formatDate(orderReviewInfo.Symbol.OptionInfo.StrikeDate, "MMM DD, YYYY", "DD MMM, YYYY")}}                        </span>
                    </div>
                  </div>
  
                  
                  <div class="divTableRow">
                    <div class="divTableCell title" *ngIf='orderReviewInfo.Activity.OrderType==="BUYTOOPEN" || orderReviewInfo.Activity.OrderType ==="BUYTOCLOSE"'>
                      {{quickTradeContent.text.estimatedTotal}}
                    </div>
                    <div class="divTableCell title" *ngIf='orderReviewInfo.Activity.OrderType==="SELLTOOPEN" || orderReviewInfo.Activity.OrderType === "SELLTOCLOSE"'>
                      {{quickTradeContent.text.estimatedProceeds}}
                    </div>
                    <div *ngIf="estimatedCost.EstimatedTotalCost" class="divTableCell">
                      <!-- {{(estimatedCost.EstimatedTotalCost | currencyFormatter:'USD':'format-currency-sr')  | removeDollar:'html'}} -->
                      <label class="expiry-date" *ngIf="orderReviewInfo.Symbol.Market ==='US' || orderReviewInfo.Symbol.SymbolName.endsWith('.U')"
                        [innerHtml]="(estimatedCost.EstimatedTotalCost.replace(' $', '').replace('$', ''))"></label> 
                        <label class="expiry-date marginLeft2"  *ngIf="orderReviewInfo.Symbol.Market ==='US' || orderReviewInfo.Symbol.SymbolName.endsWith('.U')">{{quickTradeContent.text.USDollar}}</label>
                      <label class="expiry-date" *ngIf="!(orderReviewInfo.Symbol.Market ==='US' || orderReviewInfo.Symbol.SymbolName.endsWith('.U'))"
                        [innerHtml]="(estimatedCost.EstimatedTotalCost.replace(' $', '').replace('$', ''))"></label>
                         <label class="expiry-date marginLeft2"  *ngIf="!(orderReviewInfo.Symbol.Market ==='US' || orderReviewInfo.Symbol.SymbolName.endsWith('.U'))">{{quickTradeContent.text.canadianDollar}}</label>
                      <br /><span
                        class="optionDescription">
                        <!-- {{quickTradeContent.text.includes}}  -->
                        {{quickTradeContent.text.tradeValueMobile}} {{estimatedCost.EstimatedOrderValue}}<br> 
                        {{quickTradeContent.text.commissionMobile}} {{estimatedCost.EstimatedCommission}}<br> 
                        <!-- Need to check whether to remove or not -->
                        <!-- <span *ngIf="estimatedCost.EstimatedSecFee">{{quickTradeContent.text.and}}
                          {{estimatedCost.EstimatedSecFee}}
                          {{quickTradeContent.text.sec}}
                        <app-tooltip [id]="'estimatedSecFee'" [tooltipContent]="secFeeToolTip"
                          openIconName="icon-Info-Circle" [largeHitBox]="true" targetEle="submit-order" [mode]="'modal'"
                          (click)="tooltipClicked(quickTradeContent.text.secFee)"
                          attr.aria-label="{{quickTradeContent.text.secFeeLabelText}}">
                          <div class="tooltip-lightbox">
                            <p class="tooltipTextStyle tooltips-style-text paddingTop16">
                              {{quickTradeContent.text.secFeeToolTipLine1}}
                            </p>
                            <p class="tooltipTextStyle tooltips-style-text paddingTop16">
                              {{quickTradeContent.text.secFeeToolTipLine2}}
                            </p>
                            <p class="tooltipTextStyle tooltips-style-text paddingTop16">
                              {{quickTradeContent.text.secFeeToolTipLine3}}
                            </p>
                          </div>
                        </app-tooltip>
                        </span> -->
                        <!-- Need to check whether to remove or not -->
                        <!-- <span *ngIf="estimatedCost.EstimatedFTTFee" class="description">{{quickTradeContent.text.and}}
                          {{estimatedCost.EstimatedFTTFee}}
                          {{quickTradeContent.text.FTT}}
                        <app-tooltip [id]="'estimatedFttFee'" [tooltipContent]="FTTToolTip"
                          openIconName="icon-Info-Circle" [largeHitBox]="true" targetEle="submit-order" [mode]="'modal'"
                          (click)="tooltipClicked(quickTradeContent.text.FTTFee)"
                          attr.aria-label="{{quickTradeContent.text.FTTFeeLabelText}}">
                          <div class="tooltip-lightbox">
                            <p class="tooltipTextStyle tooltips-style-text paddingTop16">
                              {{quickTradeContent.text.fttFeeToolTipLine1}} {{quickTradeContent.text.fttFeeToolTipLine2}}
                            </p>
                          </div>
                        </app-tooltip>
                        </span> -->
                      </span>
                    </div>
                    <div *ngIf="!estimatedCost.EstimatedTotalCost" class="divTableCell">-</div>
                  </div> 
                  <div class="divTableRow">
                    <div class="divTableCell title non-border-bottom">{{quickTradeContent.text.buyingPowerAfter}}</div>
                    <div *ngIf="buyingPower.EstimatedNetBuyingPowerCAD" class="divTableCell non-border-bottom">
                      <label class="description-title-normal" *ngIf="orderReviewInfo.Account.AccountType != 'REG'" [innerHtml]="(buyingPower.EstimatedNetBuyingPowerCAD.replace(' $', '').replace('$', '') | currencyFormatter:'CAD':'format-currency-sr') | removeDollar:'html'"></label>
                      <label class="description-title-normal" *ngIf="orderReviewInfo.Account.AccountType == 'REG'" [innerHtml]="(buyingPower.EstimatedNetBuyingPowerCAD.replace(' $', '').replace('$', '') | currencyFormatter:currency:'format-currency-sr') | removeDollar:'html'"></label>
                    </div>
                    <div *ngIf="!buyingPower.EstimatedNetBuyingPowerCAD" class="divTableCell non-border-bottom">-</div>
                  </div>
                  <div class="divTableRow" >
                    <div class="divTableCell title" >
                      <span *ngIf="!(buyingPower.BuyingPowerIncrease)">{{quickTradeContent.text.lessBuyingPower}}</span>
                      <span *ngIf="(buyingPower.BuyingPowerIncrease)"></span>
                    </div>
                    <div class="divTableCell title-desc expiry-date description-title-normal">
                      <!-- {{valueOrDash(buyingPower.EstimatedUsedBuyingPowerCAD)}} -->
                      <ng-container *ngIf="!(buyingPower.BuyingPowerIncrease)">
                        <label class="description-title-normal" *ngIf="buyingPowerContent">
                            {{valueOrDash(buyingPower.EstimatedUsedBuyingPowerCAD).replace('$', '')}} 
                            <span *ngIf="buyingPower.EstimatedUsedBuyingPowerCAD" [innerHtml]="''+(orderReviewInfo.Account.AccountType != 'REG'? globalContent.getCurrencyContent('CAD'):globalContent.getCurrencyContent(currency)) +'' "></span>
                        </label>
                      </ng-container>
                      <div class="paddingBottom10 total" *ngIf="(buyingPower.BuyingPowerIncrease)">
                        <div class="col key color02 A12">
                          <span [innerHTML]='quickTradeContent.text.BuyingPowerIncreaseMessage'></span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div class="divTableRow ">
                    <div class="divTableCell title paddingTop16 width40">{{quickTradeContent.text.account}}</div>
                    <div class="divTableCell title-desc paddingTop16 description-title-normal">{{globalContent.accountNickname(orderReviewInfo.Account)}}
                      <span [innerHTML]="orderReviewInfo.Account.AccountNumber | srOnly:true"></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="new-right-col-module-special-message" *ngIf="buyingPower.BuyingPowerIncrease">
                <span [innerHTML]='quickTradeContent.text.BuyingPowerIncreaseMessage'></span>
              </div> -->
            </div>
          </div>
        </div>
  
          <!-- <div *ngIf="quote && quote.Symbol && !isDesktop()" class="account-details full-width paddingBottom0"> -->
            <div class="divider-line"></div>
            <div class="account-details full-width paddingBottom0">
  
            <div class="container paddingBottom0">
              <div class="company-details">
                <div class="margin-left collapse-alg" >
                  <span *ngIf="!isQuoteExpanded" class="expandStats color02">{{quickTradeContent.text.expandOptionText}}</span>
                  <span *ngIf="isQuoteExpanded" class="expandStats color02">{{quickTradeContent.text.collapseOptionText}}</span>
                  <!-- <button type="button" [attr.aria-label]="quickTradeContent.text.showMore" *ngIf="isQuoteExpanded" (click)="handleQuoteExpansion(false)">
                          <span class="collapse-but-text color02">{{quickTradeContent.text.showMore}}</span>
                          <span aria-hidden="true"
                                class="dwn-icon icon-Arrow-Down color04 iconDown collapse-icon"></span>
                  </button> -->
                  <button type="button" [attr.aria-label]="quickTradeContent.text.expandOptionText" class="but-animate" *ngIf="isQuoteExpanded" (click)="handleQuoteExpansion(false)">
                    <div class="margin-left">
                        <span class="sr-only">{{quickTradeContent.text.expandOptionText}}</span>
                        <span aria-hidden="true"
                              class="up-icon  icon-Chevron-Up color04 iconDown"></span>
                    </div>
                </button>
                <button type="button" [attr.aria-label]="quickTradeContent.text.collapseOptionText" class="but-animate-reverse" *ngIf="!isQuoteExpanded" (click)="handleQuoteExpansion(true)">
                    <div class="margin-left collapse-alg">
                        <span class="sr-only">{{quickTradeContent.text.collapseOptionText}}</span>
                        <span aria-hidden="true"
                              class="dwn-icon icon-Chevron-Down color04 iconUp"></span>
                    </div>
                </button>
              </div>
                <!-- <div class="collapse" *ngIf="!isQuoteExpanded"> -->
                  <div class="collapse">
                    <!-- // TODO: Need to add qoute error -->
                    <div *ngIf="quote && quote.Symbol; else quoteError" >
                      <div class="company-name">
                        <img class="quote-flag img-flag" aria-hidden="true" alt=""
                          [src]='getFlag(orderReviewInfo.Symbol.Market)' />
                          <span class="new-symbol-name ">
                            {{orderReviewInfo.Symbol.SymbolName}}:
                            <!-- {{globalContent.getSymbolContent(orderReviewInfo.Symbol.SymbolName,orderReviewInfo.Symbol.Market)}} -->
                          </span>
                        <span class="new-symbol-name paddingLeft6" *ngIf="quote && quote.Symbol && quote.Symbol.SymbolLongName"
                              [ngClass]="quote.Symbol.SymbolLongName.length > 48 ? 'new-symbol-small-name' : ''" >
                          {{quote.Symbol.SymbolLongName}} </span>
                      </div>
          
                      <div class="new-symbol-price-change">
                        <div class="new-symbol-price">
                          {{quote.UnderlyingSymbolQuote?.LastPrice?.Content}}
                        </div>
                        <div class="new-symbol-change"
                          [ngClass]="{ backgroundUp : quote.UnderlyingSymbolQuote?.ChangeIndicator == 'U', backgroundDown : quote.UnderlyingSymbolQuote?.ChangeIndicator == 'D' }">
          
                          <span
                            [innerHtml]="(quote.UnderlyingSymbolQuote?.PriceChange?.Content) + '(' + (quote.UnderlyingSymbolQuote?.PriceChangePercent ) + ')'| changeFormatter:quote.UnderlyingSymbolQuote?.ChangeIndicator:'wedge'"></span>
                        </div>
                      </div> 
                      <div class="devFlex-group">
                        <div class="divFlex-1">
                            <div class="bid-ask-display  paddingTop8">
                              <div class="nondivTableCell fix-width bid-ask-title">
                                {{quickTradeContent.text.bidShares}}
                              </div>
                              <div class="nondivTableCell fix-width price-bid-ask" *ngIf="quote">
                                {{forFrenchFormat(valueOrDashData(quote?.BidPrice.Data))}}/{{forFrenchFormat(valueOrDashData(quote?.BidSize.Data))}}
                              </div>
                            </div>
                        </div>
                        <div class="divFlex-2">
                          <div class="bid-ask-display paddingTop8">
                            <div class="nondivTableCell bid-ask-title">{{quickTradeContent.text.askShares}}</div>
                            <div class="nondivTableCell price-bid-ask" *ngIf="quote && quote.Symbol" 
                            [ngClass]="(!quote.BidPrice && !quote.BidSize) ? 'ask-price-width' : ''">
                              {{forFrenchFormat(valueOrDashData(quote?.AskPrice.Data))}}/{{forFrenchFormat(valueOrDashData(quote?.AskSize.Data))}}
                              </div>
                          </div>
                        </div>
                        <div class="divFlex-3">
                          <div class="bid-ask-display paddingTop8">
                            <div class="nondivTableCell bid-ask-title">{{quickTradeContent.text.volumeQouteDetail}}</div>
                            <div class="nondivTableCell price-bid-ask" *ngIf="quote && quote.Symbol" 
                            [ngClass]="(!quote.BidPrice && !quote.BidSize) ? 'ask-price-width' : ''">
                              {{forFrenchFormat(valueOrDashContent(quote?.Volume))}}
                              </div>
                          </div>
                        </div>
                        <div class="divFlex-4">
                          <div class="bid-ask-display  paddingTop8">
                            <div class="nondivTableCell fix-width bid-ask-title">
                              {{quickTradeContent.text.openInterestQuoteDetail}}
                            </div>
                            <div class="nondivTableCell fix-width price-bid-ask" *ngIf="quote">
                              {{forFrenchFormat(valueOrDashContent(quote?.OpenInterest))}} 
                            </div> 
                          </div>
                        </div>
                        <div class="divFlex-5">
                          <div class="bid-ask-display  paddingTop8">
                            <div class="nondivTableCell fix-width bid-ask-title">
                              {{quickTradeContent.text.lastQoutesDetial}}
                            </div>
                            <div class="nondivTableCell fix-width price-bid-ask" *ngIf="quote">
                              {{forFrenchFormat(valueOrDashData(quote?.LastPrice.Data))}}&nbsp;
                              <span [ngClass]="{ colorUp: quote.PriceChange.Data > 0, colorDown: quote.PriceChange.Data <= 0 }" 
                            [innerHtml]=" forFrenchFormat(valueOrDashData(quote.PriceChange.Data))"></span>        
                            </div>
                          </div>
                        </div>
                        <div class="divFlex-6">
                          <div class="bid-ask-display  paddingTop8">
                            <div class="nondivTableCell fix-width bid-ask-title">
                              {{quickTradeContent.text.impliedVolatility}}
                            </div>
                            <div class="nondivTableCell fix-width price-bid-ask" *ngIf="quote">
                              <!-- {{valueOrDashData(greekQuote?.greeks.impliedVolatility.toFixed(4))}}  -->
                              {{forFrenchFormat(valueOrDashTwoDigits(greekQuote?.greeks.impliedVolatility))}} 
                              <!-- {{greekQuote?.greeks.impliedVolatility}}  -->
                            </div> 
                          </div>
                        </div>
                        <div class="divFlex-7">
                          <div class="bid-ask-display  paddingTop8">
                            <div class="nondivTableCell fix-width bid-ask-title">
                              {{quickTradeContent.text.delta}}
                            </div>
                            <div class="nondivTableCell fix-width price-bid-ask" *ngIf="quote ">
                              {{forFrenchFormat(valueOrDashGreeks(greekQuote?.greeks.delta))}} 
                              <!-- {{greekQuote?.greeks.delta}}  -->
                            </div> 
                          </div>
                        </div>
                        <div class="divFlex-8">
                          <div class="bid-ask-display  paddingTop8">
                            <div class="nondivTableCell fix-width bid-ask-title">
                              {{quickTradeContent.text.gamma}}
                            </div>
                            <div class="nondivTableCell fix-width price-bid-ask" *ngIf="quote">
                              {{forFrenchFormat(valueOrDashGreeks(greekQuote?.greeks.gamma))}} 
                              <!-- {{greekQuote?.greeks.gamma}}  -->
                            </div> 
                          </div>
                        </div>
                        <div class="divFlex-9">
                          <div class="bid-ask-display  paddingTop8">
                            <div class="nondivTableCell fix-width bid-ask-title">
                              {{quickTradeContent.text.theta}}
                            </div>
                            <div class="nondivTableCell fix-width price-bid-ask" *ngIf="quote">
                              {{forFrenchFormat(valueOrDashGreeks(greekQuote?.greeks.theta))}} 
                              <!-- {{greekQuote?.greeks.theta}}  -->
                            </div> 
                          </div>
                        </div>
                      </div>
                  
                    <div class="time">{{quickTradeContent.text.delayedQoute}}</div>
                      <!-- <div class="time">{{quickTradeContent.text.asOf}} {{valueOrDashData(quote.LastTradeDate)}}</div> -->
                      <!-- <div class="margin-left paddingTop8 collapse-alg-2 ">
                        <button type="button" [attr.aria-label]="quickTradeContent.text.showLess" *ngIf="!isQuoteExpanded" (click)="handleQuoteExpansion(true)">
                              <span class="collapse-but-text color02">{{quickTradeContent.text.showLess}}</span>
                              <span aria-hidden="true"
                                    class="up-icon icon-Arrow-Up color04 iconUp collapse-icon"></span>
                      </button>
                    </div> -->
                    </div>
                    <ng-template #quoteError>
                      <div aria-hidden="true" class="field-level-error-image icon-Error-Warning-Fill color10"></div>
                      <span class="quote-error">{{quote.Errors!.ErrorMessage}} </span> 
                    </ng-template>
                  </div>
                </div>
            </div>
            <div class="divider-line"></div>
          </div>  
        
          <div class="account-details full-width trading-password" >
            <div class="container">
              <div *ngIf="checkPassword" class="company-trading company-details">
                <label for='trading-Password-input' class="sr-only">
                  {{quickTradeContent.text.tradingPassword}}
                </label>
                <div>
                  <input type="password" id="trading-Password-input=option" #tradingPassword="ngModel" autocomplete="off"
                    name="tradingPassword" [(ngModel)]='inputs.tradingPassword' [appMaxlength]='32'
                    aria-labelledby="trade-pass-label" aria-describedby="tradingPassword-error"
                    [inputErrorDirective]="formError.tradingPassword" (change)="typePass()"
                    placeholder="{{quickTradeContent.text.tradingPassword}}" class="soft">
                </div>
                <app-field-level-error class="tradePassError" [error]="formError.tradingPassword"
                  [Id]="'tradingPassword-error'">
                </app-field-level-error>
                <div class="save-remember">
                  <div style="width: 40%;">
                    <input type="checkbox" name="saveTradePassword" [(ngModel)]='inputs.savePassword'
                      aria-labelledby="save-trade-pass-label">
                    <span id="save-trade-pass-label"
                      class="save-title">{{quickTradeContent.text.saveTradingPasswordMobile}}</span>
                      <app-tooltip  [id]="'savePasswordTooltipMobile'" [tooltipContent]="savePasswordTooltipMobile"
                      openIconName="icon-Info-Circle" [largeHitBox]="true" targetEle="submit-order" [mode]="'modal-soft'"
                      (clickTooltip)="tooltipClicked(quickTradeContent.text.saveTradingPasswordMobile)"    
                      attr.aria-label="{{quickTradeContent.text.savePassLabelText}}">
                      <div class="tooltip-title">
                        <span>{{ quickTradeContent.savePasswordTooltipMobile.title }}</span>
                      </div>
                      <div class="tooltip-lightbox">
                        {{ quickTradeContent.savePasswordTooltipMobile.content }}
                      </div>  
                    </app-tooltip>
                  </div>
                  <div class="cant-remember"> <a (click)="forgotPassword()"
                    href="javascript:void(0);">{{quickTradeContent.text.forgotTradePassMobile}}</a>
                    <span class="sr-only">{{quickTradeContent.text.newWindowText}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider-line"></div>
          </div>
  
          <div class="form-bright-zebra full-width paddingTop0 paddingBottom0 " [ngClass]="{'mobileview': this.mode == 'quickTrade'}">
            <div class="col col-100 col-100-small account paddingRight24">
              <div class="page-bottom-info">
                <label class="understanding-options-link-quick-options" href="javascript:void(0);">
                  <span class="more-info-label">{{ quickTradeContent.text.moreInformation }}</span>
                  <span class="sr-only">{{ quickTradeContent.text.newWindowText }}</span>
                  <span>
                    <app-tooltip [id]="'moreInformationtooltip'" [tooltipContent]="moreInfoTooltip"
                    openIconName="icon-Info-Circle" [mode]="'modal-soft'"
                    (clickTooltip)="tooltipClicked(quickTradeContent.text.moreInformation)"
                    [largeHitBox]="true"
                    targetEle="price-input-qt" attr.aria-label="{{ quickTradeContent.text.moreInformation }}">
                                <div class="tooltip-title">
                                  <span>{{ quickTradeContent.moreInfoTooltip.title }}</span>
                                </div>
                                <div class="tooltip-lightbox">
                                  <ul class="A12 bulletpoint">
                                    <li class="bb-2 more-info-tooltip-text  paddingBottom8" [innerHtml]="quickTradeContent.moreInfoTooltip.line1">
                                    </li>
                                  <li class="bb-2 more-info-tooltip-text  paddingBottom8">
                                    {{ quickTradeContent.moreInfoTooltip.line2 }}
                                  </li>
                                  <li class="bb-2 more-info-tooltip-text  paddingBottom8">
                                    {{ quickTradeContent.moreInfoTooltip.line3 }}
                                  </li>
                                  <li class="bb-2 more-info-tooltip-text  paddingBottom8">
                                    {{ quickTradeContent.moreInfoTooltip.line4 }}
                                  </li>
                                  <li class="bb-2 more-info-tooltip-text  paddingBottom8">
                                    {{ quickTradeContent.moreInfoTooltip.line5 }}
                                  </li>
                                  </ul>
        
                                  <div class="paddingTop16 refintiv">
                                    <span class="more-info-tooltip-last-para"
                                          [innerHTML]="quickTradeContent.moreInfoTooltip.marketData"></span>
                                    <span> <a href="{{quickTradeContent.moreInfoTooltip.refintivLink}}"
                                              class="link-style more-info-tooltip-last-para"
                                              target="_blank">{{quickTradeContent.moreInfoTooltip.refintiv}}</a></span>
                                </div>
                                <div><img src="./assets/images/refinitiv_logo.png" alt="refinitiv_logo"
                                  class="marginTop10" width="96px" height="24px"></div>
                                </div>
                    </app-tooltip>
                  </span>
                </label>
              </div>
            </div>
          </div>
  
      </div>
      <!-- Mobile Design Ends -->
      <!-- mobile sticky starts -->
      <div [ngClass]="{'page-bottom-mbl-custom' : mode !== 'quickTrade', 'page-bottom-mbl-custom-quick-trade' : mode == 'quickTrade', 'notch': notch, 'screenReader': isScreenReaderON}" [attr.aria-hidden]="isKeyboardOpen ? true : false" [hidden]="isKeyboardOpen">
          <div [ngClass]="{' trade-option-bottom' : mode !== 'quickTrade', ' quickTrade-bottom' : mode == 'quickTrade', 'button-mobile-grp-inner': 1==1 }" >
            <button type="submit" class='button primary' [ngClass]="{'button-primary-open': (orderReviewInfo.Activity.OrderType==='BUYTOOPEN' || orderReviewInfo.Activity.OrderType==='BUYTOCLOSE'), 'button-primary-sell': (orderReviewInfo.Activity.OrderType==='SELLTOOPEN' || orderReviewInfo.Activity.OrderType==='SELLTOCLOSE')}" buttonDisable id="submit-order">
              {{quickTradeContent.text.submit}}
            </button>
          </div>
      </div>         
      <!-- mobile sticky ends -->
    </form>
  </div>
  
  <app-otvc-prompt-modal [uniqueId]="uniqueId" [otvcPromptData]="otvcPromptDataParam" [mode]="mode" [PromptContent]="otvcPromptContent"
    [verifyUrl]="verifyurl" [currentModule]="currentModule" (modalCancelRequest)="closeModal()">
  </app-otvc-prompt-modal>
