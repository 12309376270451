import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import _ from 'lodash';

@Directive({ selector: '[appPhoneNumber]' })
export class PhoneNumberDirective {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private control: NgControl,
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  onKeyDown(event) {
    if (event) {
      let phoneNumber: string = this.el.value;
      phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
      let result = phoneNumber;
      if (phoneNumber.length > 10) {
        result = phoneNumber.substr(0, 10);
      }
      if (phoneNumber.length > 3 && phoneNumber.length <= 6) {
        const area = phoneNumber.substr(0, 3);
        const num1 = phoneNumber.substr(3);
        const compiled = _.template('(<%= area %>) <%= num1 %>');
        result = compiled({ area, num1 });
      } else if (phoneNumber.length > 6) {
        // e.g. 14165817080
        const area = phoneNumber.substr(0, 3);
        const num1 = phoneNumber.substr(3, 3);
        const num2 = phoneNumber.substr(6, 4);
        const compiledPhone = _.template('(<%= area %>) <%= num1 %>-<%= num2 %>');
        result = compiledPhone({ area, num1, num2 });
      }
      this.control.control.setValue(result);
    }
  }
}


