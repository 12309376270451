import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../../shared/models/page-store';
import { BuyGICVerifyData } from './buy-gic-verify-data';

@Injectable({ providedIn: 'root' })
export class BuyGICVerifyStore extends PageStore<BuyGICVerifyData> {

    constructor() {
        super(new BuyGICVerifyData());
    }

}
