import { BaseData } from 'src/app/shared/models/base-data';

export class TradeMLSLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountIndex: -1,
            tradePositionType: "",
            chooseSymbolLeg1: false,
            chooseSymbolLeg2: false,
            symbolLeg1: "",
            symbolLeg2: "",
            market: "",
            optionStrategy: "",
            optionStrateyDisplayName: "",
            action: "",
            strikeDateIndex: -1,
            strikePriceIndex: -1,
            optionType: "",
            optionHoldingIndexleg1: -1,
            settlementIndexleg1: -1,
            quantity: undefined,
            optionHoldingQtyLeg1: -1,
            actionl2: "",
            strikeDateIndexl2: -1,
            strikePriceIndexl2: -1,
            optionHoldingIndexleg2: -1,
            settlementIndexleg2: -1,
            optionHoldingQtyLeg2: -1,
            optionTypel2: "",
            quantityl2: undefined,
            orderPriceType: "",
            price: undefined,
            netPrice: { Price: undefined, PriceType: undefined },
            midPrice: { Price: undefined, PriceType: undefined }
        };
    }

}

export enum CONST {
    NUMBER = 'number',
    TEXT = 'text',
    EMPTY = '',
    NULL = 'null'
}

export enum TradePositionType {
    OPEN = 'O',
    CLOSE = 'C',
    ROLLOVER = 'R'
}

export enum SymbolType {
    OPTION = 'OPTION',
    EQUITY = 'EQUITY'
}

export enum OptionStrategy {
    BUYWRITE = 'buyWrite',
    PROTECTED = 'protected',
    SPREAD = 'spread',
    LONGSTRANGLE = 'longStrangle',
    LONGSTRADDLE = 'longStraddle',
    SHORTSTRANGLE = 'shortStrangle',
    SHORTSTRADDLE = 'shortStraddle'
}

export enum OptionType {
    CALL = 'CALL',
    PUT = 'PUT'
}

export enum ActionType {
    SELLTOOPEN = 'SELLTOOPEN',
    BUYTOOPEN = 'BUYTOOPEN',
    BUYTOCLOSE = 'BUYTOCLOSE',
    SELLTOCLOSE = 'SELLTOCLOSE',
    SELL = 'sell',
    BUY = 'buy'
}

export enum Leg2Type {
    OPTION = 'option',
    EQUITY = 'equity'
}

export enum NetPriceType {
    CREDIT = 'Credit',
    DEBIT = 'Debit'
}


export interface TradeMLSLandingInputs {
    accountIndex: number;
    tradePositionType: string;
    chooseSymbolLeg1: boolean;
    chooseSymbolLeg2: boolean;
    symbolLeg1: string;
    symbolLeg2: string;
    market: string;
    optionStrategy: string;
    optionStrateyDisplayName: string;
    action: string;
    strikeDateIndex: number;
    strikePriceIndex: number;
    optionType: string;
    optionHoldingIndexleg1: number;
    settlementIndexleg1: number;
    quantity: number;
    optionHoldingQtyLeg1: number;
    actionl2: string;
    strikeDateIndexl2: number;
    strikePriceIndexl2: number;
    optionTypel2: string;
    optionHoldingIndexleg2: number;
    settlementIndexleg2: number;
    quantityl2: number;
    optionHoldingQtyLeg2: number;
    orderPriceType: string;
    price: number;
    netPrice: { Price: number, PriceType: string };
    midPrice: { Price: number, PriceType: string };
}
export class BuyingPowerReq {
    AccountNumber: string;
}
export class QuoteReq {
    Symbols: [
        {
            SymbolName: string;
            Market: string;
            SymbolType: string;
            OptionInfo?: {
                OptionType: string;
                StrikeDate: string;
                StrikePrice: {
                    Value: string;
                }
            }
        }
    ];
}

export class ChainReq {
    SymbolName: string;
    Market: string;
    StrikePrice?: string;
    ExpiryDate?: string;
}

export class SettlementAccountsReq {
    AccountNumber: string;
    Action: string;
    AccountCurrency: string;
    Type?: string;
}

export class AccountHoldingsReq {
    AccountNumber: string;
    Action: string;
    Refresh: boolean;
    Clean: boolean;
    SearchOptions: {
        StartRow: number;
        NumberOfResult: number;
        SearchFilter: string
    };
    FiType: string;
}