import { Component, OnInit, Input, Output, EventEmitter, HostListener, AfterViewInit, Inject, Renderer2, OnDestroy, ViewChild } from '@angular/core';
import { ContentService } from 'src/app/core/services/content.service';
import { Router, NavigationEnd } from '@angular/router';
import { AppStore } from 'src/app/shared/models/app-store';
import { CommonService } from 'src/app/shared/services/common.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { DOCUMENT } from '@angular/common';
import { SiteStore } from 'src/app/shared/models/site-store';
import { of, Subject, Subscription } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { GlobalSignOffService } from '../../services/global-signoff.service';
import { TrackingService } from "src/app/shared/services/tracking.service";
import { Lob } from '../../../shared/models/lob.enum';
import { isDefined } from 'src/app/shared/services/utils.service';
import { ExpansionStates } from 'src/app/shared/models/general.enum';
import { HttpService } from '../../services/http.service';
import { PersistenceService } from '../../services/persistence.service';

declare let require: any
const gatewayConfig = require("src/app/config/gateway-config.json");

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() tabIndexValue: number;
  @Input() openMenu: any;
  @Input() showChevron: boolean;
  @Input() unreadNews: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() menuClicked: EventEmitter<any> = new EventEmitter<any>();

  focusableElments: { 'page-footer-wrapper': { focusableElements: any[]; }; };
  subLevelMenu: any;
  subLevelMenuIndex: number;
  content: any;
  subscriptions = new Subscription();
  PEEnable: boolean = false;
  siteCallResponse: any;
  navigationObject: any;
  bottomNavObject: any;
  bottomSubNav: number = -1;
  bottomSubNavOpen: boolean = false;
  currentBottomSubNavItem: number = 0;
  serverResponse: any;
  targetLob: any;
  dropdownMenuTimer: any;
  currentDropdownMenu: number;
  currentSubItem: number;
  closeDropdownMenu: boolean = false;
  stickyNavDropdownMenuTimer: any;
  showTwolevelsNavigation: boolean;
  moreLinksnavigationObject: any;
  showMorelinkPrimary: boolean;
  moreLinkDisplayName: string;
  currentUrl: string;
  selectedUrl: string;
  enableNavigation: boolean;
  stringLobSubscription: any;
  moreItemsUrlArray: any;
  filteredMoreLinks: any;
  isScreenReaderON = false;
  isKeyboardOpen: boolean;
  showOptionCentre = true;
  noMoreLobs = ['cpic', 'wg', 'ie'];
  isAnyApp: boolean;
  notched: boolean;
  showQuickTrade = (window as any).showQuickTrade;
  isTradeDrawerOpen = false;
  greyedOut: ExpansionStates = this.EXPAN.CONTRACTED;
  showGlobalRefresh: string = null;
  displaySecondNavDropdown: boolean = false;
  currentSecondNav: number;
  currentSecondSubItem: number;
  SecondDropdownTimer: any;
  currentSecondDropDown: number = null;
  disableSecondaryNav: boolean = false;
  thirdLevelNavDropDownOpen: boolean = false;
  thirdLevelNavDisplayValue: string = '';
  thirdLevelNavDropDownHighlightedElement: any;
  selectedOptionId: any;
  showQuickTradeOption = (window as any).showQuickTradeOption;

  uniqueId: string = 'tpnavigationotvc'
  firstFocusableEl: any;
  lastFocusableEl: any;
  userMode: string;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.twoLevelNavigation();
    if (document.body.clientWidth > 899) {
      this.closeMenu();
      this.bottomSubNavOpen = false;
    }
  }

  showNav: boolean = true;
  lastScrollPos: number;
  lastScrollHeight: number;

  closeSearch: Subject<any> = new Subject();

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.showNav = this.lastScrollPos > event.target.scrollingElement.scrollTop && this.lastScrollHeight == event.target.scrollingElement.scrollHeight;
    if (event.target.scrollingElement.scrollTop <= 48) {
      this.showNav = true;
    }

    this.lastScrollPos = event.target.scrollingElement.scrollTop;
    this.lastScrollHeight = event.target.scrollingElement.scrollHeight;
  }

  get EXPAN() { return ExpansionStates; }

  constructor(
    private contentService: ContentService,
    private _subscriptionService: ObservableSubscriptionService,
    private commonService: CommonService,
    public appStore: AppStore,
    private _commonService: CommonService,
    private router: Router,
    private renderer: Renderer2,
    private siteStore: SiteStore,
    private modalService: ModalService,
    private globalSignOffService: GlobalSignOffService,
    private trackingService: TrackingService,
    private httpService: HttpService,
    private persistanceService: PersistenceService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.routerEvents();
  }

  ngOnInit() {
    this.isAnyApp = this.appStore.isApp();
    this.notched = this.commonService.isNotchPhone();
    this.siteCallResponse = this.appStore.retrieveSessionInfo;
    this.targetLob = this.appStore.lob;
    this.buildNavigationObject();
    this.isScreenReaderON = this.appStore.features.isScreenReaderON ? true : false;
    if (this.targetLob === Lob.IE) {
      this.PEEnable = this.appStore.state.user.premiumUser;
    }
    else {
      this.PEEnable = false;
    }
    this.subscriptions.add(this._subscriptionService.isKeyboardOpen.subscribe(
      (data) => {
        this.isKeyboardOpen = data;
      }
    ));
    this.showOptionCentre = (window as any).showOptionCentre;
    this.isOpenQuickTrade();
    this.userMode = this.persistanceService.cookieStoreRetrieve('CP_USER_MODE');

    console.log("this.userMode",this.userMode);
  }

  checkLob(val) {
    return this.targetLob == Lob[val];
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  routerEvents() {
    const helpPageUrl = '/txn/help';
    const siteMapUrl = '/txn/sitemap';
    const sessionEndUrl = '/sessionEnd';
    const globalRefreshList = [
      '/txn/tradeStock/landing',
      '/txn/quotesWatchList/detail',
      '/txn/optionsCentral/singleLeg/landing'
    ]
    const globalRefreshDisabledList = [
      '/txn/tradeStock/verify',
      '/txn/optionsCentral/singleLeg/verify',
      '/txn/optionsCentral/singleLeg/confirm'
    ]
    this.subscriptions.add(this.router.events.subscribe(a => {
      if (a instanceof NavigationEnd) {
        this.currentUrl = a.url;
        this.showGlobalRefresh = null;
        if (globalRefreshList.includes(this.currentUrl)) {
          this.showGlobalRefresh = 'refresh';
        } else if (globalRefreshDisabledList.includes(this.currentUrl)) {
          this.showGlobalRefresh = 'time';
        }
        if (this.currentUrl === sessionEndUrl && this.appStore.isApp('inapp')) {
          this.enableNavigation = false;
          this.firstLevelNavActiveRoute(this.currentUrl);
          this.twoLevelNavigation();
        } else if (this.currentUrl === helpPageUrl && !this.appStore.isApp()) {
          this.enableNavigation = false;
        } else {
          this.enableNavigation = true;
          this.firstLevelNavActiveRoute(this.currentUrl);
          this.twoLevelNavigation();
        }
        if (this.currentUrl === siteMapUrl) {
          this.setSiteMapData();
        }
      }
    }));
  }
  twoLevelNavigation() {
    const myAccountsUrl = '/txn/accounts/myaccounts';
    if (this.currentUrl === myAccountsUrl && !this.isDesktop()) {
      this.showTwolevelsNavigation = true;
    } else {
      this.showTwolevelsNavigation = false;
    }
  }

  buildNavigationObject() {
    // lob change triggers to rebuild the navigation object
    this.subscriptions.add(this._subscriptionService.stringLob.subscribe(
      (lobData) => {
        if (lobData) {
          this.subscriptions.add(this.contentService.fetchContent('navigation').subscribe((data) => {
            this.content = data;
            this.targetLob = this.appStore.lob === 'cfpi' ? 'iis' : this.appStore.lob;
            if (this.targetLob === Lob.IE) {
              this.PEEnable = this.appStore.state.user.premiumUser;
            }
            else {
              this.PEEnable = false;
            }
            this.serverResponse = this.siteCallResponse.LobLinksMap[this.targetLob.toUpperCase()];
            // deepcopying the Array
            this.bottomNavObject = this.content.list.bottomStickySubAccounts ? JSON.parse(JSON.stringify(this.content.list.bottomStickySubAccounts)) : null;
            this.navigationObject = JSON.parse(JSON.stringify(this.content.list.subAccounts));
            this.moreLinksnavigationObject = JSON.parse(JSON.stringify(this.content.list.moreLinks ? this.content.list.moreLinks : ''));
            // removing non-permissioned pages
            for (const [i, val] of this.content.list.subAccounts.entries()) {
              const filteredSecondaryLinks = val.secondaryLinks.filter(e => {
                if(e.childrenUrls && e.childrenUrls.length && e.childrenUrls[0] == '/txn/exchangeCurrency/landing'
                  && this.serverResponse.includes(e.enumValue)) {
                  return !this.serverResponse.includes('FXALD');
                }else {
                  if((this.serverResponse.includes(e.enumValue) || !e.enumValue)) {
                    if(e.thirdLevelNavigation) {
                      const thirdLevelNavigation = e.thirdLevelNavigation.filter(v => (this.serverResponse.includes(v.enumValue) || !v.enumValue))
                      e.thirdLevelNavigation = thirdLevelNavigation;
                    }
                  }
                  return (this.serverResponse.includes(e.enumValue) || !e.enumValue)
                }
              });
              this.navigationObject[i].secondaryLinks = filteredSecondaryLinks;
            }
            if (this.moreLinksnavigationObject) {
              const filteredSecondaryLinks = this.moreLinksnavigationObject.filter(e => (this.serverResponse.includes(e.enumValue) || !e.enumValue));
              this.moreLinksnavigationObject = filteredSecondaryLinks;
            }
            if (this.bottomNavObject) {
              for (let i = 0; i < this.bottomNavObject.length; i++) {
                const filteredSecondaryLinks = this.bottomNavObject[i].childUrls.filter(e => (this.serverResponse.includes(e.enumValue) || !e.enumValue));
                this.bottomNavObject[i].childUrls = filteredSecondaryLinks;
              }
              for (let i = 0; i < this.bottomNavObject.length; i++) {
                if (this.bottomNavObject[i].subNav) {
                  const filteredSecondaryLinks = this.bottomNavObject[i].subNav.filter(e => (this.serverResponse.includes(e.enumValue) || !e.enumValue));
                  this.bottomNavObject[i].subNav = filteredSecondaryLinks;
                }
              }
            }
            // removing empty array modules
            this.navigationObject = this.navigationObject.filter(value => (value.secondaryLinks.length > 0));
            // filter array for wrapper
            if (this.appStore.isApp()) {
              if (this.navigationObject && this.navigationObject.length > 0) {
                for (let i = 0; i < this.navigationObject.length; i++) {
                  if ((this.navigationObject[i].browserOnly)) {
                    this.navigationObject.splice(i, 1);
                    i--;
                  }
                }
              }
              if (this.bottomNavObject && this.bottomNavObject.length > 0) {
                for (let i = 0; i < this.bottomNavObject.length; i++) {
                  if ((this.bottomNavObject[i].browserOnly)) {
                    this.bottomNavObject.splice(i, 1);
                    i--;
                  }
                }
              }
              for (let i = 0; i < this.navigationObject.length; i++) {
                const wrapperFilterItems = this.content.list.wrapperFilter;
                if (this.navigationObject[i] && this.navigationObject[i].secondaryLinks) {
                  let filteredSecondaryLinks = this.navigationObject[i].secondaryLinks.filter(e => (wrapperFilterItems.includes(e.enumValue) || !e.enumValue));
                  if (this.appStore.isOldApp()) {
                    filteredSecondaryLinks = filteredSecondaryLinks.filter(e => e.enumValue !== 'EDOCS');
                  }
                  this.navigationObject[i].secondaryLinks = filteredSecondaryLinks;
                  if (filteredSecondaryLinks.length === 0) {
                    this.navigationObject.splice(i, 1);
                    i--;
                  }
                }
              }
              if (this.bottomNavObject && this.bottomNavObject.length > 0) {
                for (let i = 0; i < this.bottomNavObject.length; i++) {
                  const wrapperFilterItems = this.content.list.wrapperFilter;
                  if (this.bottomNavObject[i] && this.bottomNavObject[i].childUrls) {
                    let filteredSecondaryLinks = this.bottomNavObject[i].childUrls.filter(e => (wrapperFilterItems.includes(e.enumValue) || !e.enumValue));
                    if (this.appStore.isOldApp()) {
                      filteredSecondaryLinks = filteredSecondaryLinks.filter(e => e.enumValue !== 'EDOCS');
                    }
                    this.bottomNavObject[i].childUrls = filteredSecondaryLinks;
                    if (filteredSecondaryLinks.length === 0) {
                      this.navigationObject.splice(i, 1);
                      i--;
                    }
                  }
                }
              }
            } else {
              if (this.navigationObject && this.navigationObject.length > 0) {
                for (let i = 0; i < this.navigationObject.length; i++) {
                  if ((this.navigationObject[i].appOnly)) {
                    this.navigationObject.splice(i, 1);
                    i--;
                  }
                }
              }
              if (this.bottomNavObject && this.bottomNavObject.length > 0) {
                for (let i = 0; i < this.bottomNavObject.length; i++) {
                  if ((this.bottomNavObject[i].appOnly)) {
                    this.bottomNavObject.splice(i, 1);
                    i--;
                  }
                }
              }
            }
            this.firstLevelNavActiveRoute(this.router.url);
          }, (err) => {
          }));
        }
      }
    ));
  }

  firstLevelNavActiveRoute(url) {
    if (this.navigationObject && this.navigationObject?.length > 0) {
      // common functionality for wrapper and web
      for (let i = 0, length = this.navigationObject.length; i < length; i++) {
        this.navigationObject[i].isActive = false;
        for (let j = 0, len = this.navigationObject[i]?.secondaryLinks.length; j < len; j++) {
          if (this.navigationObject[i].secondaryLinks[j].childrenUrls) {
            for (let k = 0, childrenLength = this.navigationObject[i].secondaryLinks[j].childrenUrls.length; k < childrenLength; k++) {
              this.navigationObject[i].secondaryLinks[j].isSecondaryLinkActive = false;
              if (url.indexOf(this.navigationObject[i].secondaryLinks[j].childrenUrls[k]) !== -1) {
                this.navigationObject[i].isActive = true;
                this.showMorelinkPrimary = false;
                console.log('navigation links', this.navigationObject[i]);
                this.subLevelMenu = this.navigationObject[i].secondaryLinks;
                this.subLevelMenuIndex = i;
                this.navigationObject[i].secondaryLinks[j].isSecondaryLinkActive = true;
                this.selectedUrl = this.navigationObject[i].secondaryLinks[j].childrenUrls[0];
                this.thirdLevelNavDisplayValue = this.navigationObject[i].secondaryLinks[j].displayName;
                this.selectedOptionId = j;
                // this.isActiveRoutePermissioned(this.navigationObject[i].secondaryLinks[j].enumValue);
                if (this.navigationObject[i].secondaryLinks.length === 1) {
                  this.showMorelinkPrimary = true;
                  this.moreLinkDisplayName = this.navigationObject[i].secondaryLinks[j].displayName;
                }
                break;
              }
            }
          }
        }
      }
      if (this.router.url === '/txn/sitemap') {
        this.setSiteMapData();
      }
    }
    if (this.bottomNavObject && this.bottomNavObject.length > 0) {
      for (let i = 0; i < this.bottomNavObject.length; i++) {
        this.bottomNavObject[i].isActive = false;
        for (let j = 0; j < this.bottomNavObject[i].childUrls.length; j++) {
          for (let k = 0; k < this.bottomNavObject[i].childUrls[j].urls.length; k++) {
            if (this.bottomNavObject[i].childUrls[j].urls[k] == url) {
              this.bottomNavObject[i].isActive = true;
            }
          }
        }
      }
    }

    if (this.content) {
      this.filteredMoreLinks = this.content.list.moreItemsUrlArray.filter(e => e.url === url);
    }

    // user selects any link from the more menu
    if (this.filteredMoreLinks?.length > 0) {
      if (!(this.filteredMoreLinks[0].displaySecondNav && this.filteredMoreLinks[0].displaySecondNav === true)) {
        this.subLevelMenu = [];
        this.subLevelMenuIndex = this.navigationObject.length;
      }
      this.showMorelinkPrimary = true;
      this.moreLinkDisplayName = this.filteredMoreLinks[0].displayName;
    }
  }

  setSiteMapData() {
    if (this.navigationObject && this.moreLinksnavigationObject) {
      const navObj = [... this.navigationObject, ...this.moreLinksnavigationObject[0].secondaryLinks];
      this.siteStore.setNaviagationObject(navObj);
    }
  }

  isPermission(routeEnum) {
    return this.commonService.checkPermissionForRoutes(routeEnum);
  }

  sortSubAccountsList(selectedTab) {
    let i = 0;
    for (const val of this.navigationObject) {
      if (selectedTab === val.category) {
        this.subLevelMenu = val.secondaryLinks;
        this.disableSecondaryNav = val.disableSecondaryNav;
        break;
      }
      i++;
    }
    this.subLevelMenuIndex = i;
  }

  navigateTo(url) {
    if (url) {
      this.router.navigate([url]);
    }
  }

  mouseOver(category: number) {
    if (category != this.currentDropdownMenu) {
      this.currentDropdownMenu = category;
      this.closeDropdownMenu = false;
      this.currentSubItem = -1;
    }
    clearTimeout(this.dropdownMenuTimer);
  }

  mouseOut(category: number) {
    this.dropdownMenuTimer = setTimeout(() => {
      if (category == this.currentDropdownMenu) {
        this.currentDropdownMenu = null;
        this.closeDropdownMenu = false;
        this.closeSecondDropdown();
      }
    }, 200);
  }

  focusOut(category: number) {
    this.dropdownMenuTimer = setTimeout(() => {
      if (category == this.currentDropdownMenu) {
        this.currentDropdownMenu = null;
        this.closeDropdownMenu = false;
      }
    }, 200);
  }

  focusIn(category: number) {
    if (category != this.currentDropdownMenu) {
      setTimeout(() => {
        this.currentDropdownMenu = category;
        this.closeDropdownMenu = false;
      }, 50);
    }
    clearTimeout(this.dropdownMenuTimer);
  }

  focusIndividual(subLink: number) {
    this.currentSubItem = subLink;
  }

  navKeys(event) {
    //restrict to IE
    if (this.appStore.lob == Lob.IE) {
      //escape
      if (event.keyCode == 27) {
        this.closeDropdownMenu = true;
        let el = document.getElementById("navigation-link-" + this.currentDropdownMenu);
        if (el) {
          el.focus();
        }
      }
      // left
      if (event.keyCode == 37) {
        if (this.currentDropdownMenu == 0) {
          if (!this.noMoreLobs.includes(this.targetLob)) {
            this.currentDropdownMenu = this.navigationObject.length;
          } else {
            this.currentDropdownMenu = this.navigationObject.length - 1;
          }
        } else {
          this.currentDropdownMenu -= 1;
        }
        this.closeDropdownMenu = false;
        this.currentSubItem = -1;
        this.currentSecondSubItem = -1;
        let el = document.getElementById("navigation-link-" + this.currentDropdownMenu);
        if (el) {
          el.focus();
        }
        event.preventDefault();
      }
      // right
      if (event.keyCode == 39) {
        if (!this.noMoreLobs.includes(this.targetLob)) {
          if (this.currentDropdownMenu == this.navigationObject.length) {
            this.currentDropdownMenu = 0;
          } else {
            this.currentDropdownMenu += 1;
          }
        } else {
          if (this.currentDropdownMenu == this.navigationObject.length - 1) {
            this.currentDropdownMenu = 0;
          } else {
            this.currentDropdownMenu += 1;
          }
        }
        this.closeDropdownMenu = false;
        this.currentSubItem = -1;
        this.currentSecondSubItem = -1;
        let el = document.getElementById("navigation-link-" + this.currentDropdownMenu);
        if (el) {
          el.focus();
        }
        event.preventDefault();
      }
      // up
      if (event.keyCode == 38) {
        if (this.currentDropdownMenu == this.navigationObject.length && !this.closeDropdownMenu) {
          //For More section
          if (this.currentSubItem <= 0) {
            if (this.targetLob === 'ie' && this.isPermission('MKTLN')) {
              this.currentSubItem = this.content.list.moreLinks[0].secondaryLinks.length;
            } else {
              this.currentSubItem = this.content.list.moreLinks[0].secondaryLinks.length - 1;
            }
          } else {
            this.currentSubItem -= 1;
          }
          let el = document.getElementById("navigation-link-" + this.currentDropdownMenu + "-" + this.currentSubItem);
          if (el) {
            el.focus();
          }
        } else if (this.navigationObject.length > 1 && (!this.navigationObject[this.currentDropdownMenu] || !this.navigationObject[this.currentDropdownMenu].isActive) && !this.closeDropdownMenu) {
          //For sections with subnav
          if (this.currentSubItem <= 0) {
            this.currentSubItem = this.navigationObject[this.currentDropdownMenu].secondaryLinks.length - 1;
          } else {
            this.currentSubItem -= 1;
            if (this.currentSecondSubItem != -1) {
              this.currentSubItem += 1;
            }
            if (this.navigationObject[this.currentDropdownMenu].secondaryLinks[this.currentSubItem].thirdLevelNavigation) {
              if (this.currentSecondSubItem == -1) {
                this.currentSecondSubItem = this.navigationObject[this.currentDropdownMenu].secondaryLinks[this.currentSubItem].thirdLevelNavigation.length - 1;
              } else {
                this.currentSecondSubItem -= 1;
              }
            }
          }
          let el;
          if (this.currentSecondSubItem != -1 && this.navigationObject[this.currentDropdownMenu].secondaryLinks[this.currentSubItem].thirdLevelNavigation) {
            el = document.getElementById("navigation-link-" + this.currentDropdownMenu + "-" + this.currentSubItem + "-" + this.currentSecondSubItem);
          } else {
            el = document.getElementById("navigation-link-" + this.currentDropdownMenu + "-" + this.currentSubItem)
          }
          if (el) {
            el.focus();
          }
        }
        event.preventDefault();
      }
      // down
      if (event.keyCode == 40) {
        if (this.currentDropdownMenu == this.navigationObject.length && !this.closeDropdownMenu) {
          //For More section
          if (this.targetLob === 'ie' && this.isPermission('MKTLN')) {
            if (this.currentSubItem == this.content.list.moreLinks[0].secondaryLinks.length || this.currentSubItem == -1) {
              this.currentSubItem = 0;
            } else {
              this.currentSubItem += 1;
            }
          } else {
            if (this.currentSubItem == this.content.list.moreLinks[0].secondaryLinks.length - 1 || this.currentSubItem == -1) {
              this.currentSubItem = 0;
            } else {
              this.currentSubItem += 1;
            }
          }
          let el = document.getElementById("navigation-link-" + this.currentDropdownMenu + "-" + this.currentSubItem);
          if (el) {
            el.focus();
          }
        } else if (this.navigationObject.length > 1 && (!this.navigationObject[this.currentDropdownMenu] || !this.navigationObject[this.currentDropdownMenu].isActive) && !this.closeDropdownMenu) {
          //For sections with subnav
          if (this.currentSubItem == this.navigationObject[this.currentDropdownMenu].secondaryLinks.length - 1 || this.currentSubItem == -1) {
            this.currentSubItem = 0;
          } else {
            if (this.navigationObject[this.currentDropdownMenu].secondaryLinks[this.currentSubItem].thirdLevelNavigation) {
              if (this.currentSecondSubItem == this.navigationObject[this.currentDropdownMenu].secondaryLinks[this.currentSubItem].thirdLevelNavigation.length - 1) {
                this.currentSecondSubItem = -1;
                this.currentSubItem += 1;
              } else {
                this.currentSecondSubItem += 1;
              }
            }
            else {
              this.currentSubItem += 1;
            }
          }
          let el;
          if (this.currentSecondSubItem != -1 && this.navigationObject[this.currentDropdownMenu].secondaryLinks[this.currentSubItem].thirdLevelNavigation) {
            el = document.getElementById("navigation-link-" + this.currentDropdownMenu + "-" + this.currentSubItem + "-" + this.currentSecondSubItem);
          } else {
            el = document.getElementById("navigation-link-" + this.currentDropdownMenu + "-" + this.currentSubItem)
          }
          if (el) {
            el.focus();
          }
        }
        event.preventDefault();
      }
    }
  }

  goToHoverMenu(category: number) {
    this.currentDropdownMenu = category;
    this.closeDropdownMenu = false;
    requestAnimationFrame(() => {
      this.currentSubItem = 0;
      let el = document.getElementById("navigation-link-" + this.currentDropdownMenu + "-" + this.currentSubItem);
      if (el) {
        el.focus();
      }
    })
  }

  categoryDashFilter(category: String) {
    return category.replace(/\s/g, '');
  }

  stickyNavToggleDropdown(event) {
    const dropdownElement = this.document.getElementById('sticky-nav-dropdown-content');
    const el = dropdownElement.className;
    if (dropdownElement.className === 'stickyNavDropdownContent') {
      dropdownElement.className = 'stickyNavDropdownContent open';
      dropdownElement.setAttribute('aria-expanded', 'true');
    } else {
      dropdownElement.className = 'stickyNavDropdownContent';
      dropdownElement.setAttribute('aria-expanded', 'false');
    }
    event.preventDefault();
    return false;
  }

  stickyNavMouseOver() {
    this.document.getElementById('sticky-nav-dropdown-content').className = 'stickyNavDropdownContent open';
    clearTimeout(this.stickyNavDropdownMenuTimer);
  }

  stickyNavMouseOut() {
    this.stickyNavDropdownMenuTimer = setTimeout(() => {
      this.document.getElementById('sticky-nav-dropdown-content').className = 'stickyNavDropdownContent';
    }, 1000);
  }

  stickyNavFocusOut(event) {
    setTimeout(() => {
      if (this.document.activeElement.parentElement.id === 'sticky-nav-dropdown-links') {
        this.document.getElementById('sticky-nav-dropdown-content').className = 'stickyNavDropdownContent open';
      } else {
        this.document.getElementById('sticky-nav-dropdown-content').className = 'stickyNavDropdownContent';
      }
    }, 100);
  }

  openMenuClicked() {
    this.menuClicked.emit(true);
  }

  closeMenu() {
    this.close.emit(true);
  }

  closeMoreMenu(category: number) {
    if (category == this.currentDropdownMenu) {
      this.currentDropdownMenu = null;
      this.closeSecondDropdown();
    }
  }

  navUserPref() {
    this.commonService.callNonAPI('/txn/userPreferences');
  }


  openMTPAppModal() {
    this.modalService.open('marketlensTredingPwdAppModalNav', 'marketlensTredingPwdAppModalHeaderNav')
  }

  closeMTPModal() {
    this.modalService.close('marketlensTredingPwdAppModalNav', 'marketlensTredingPwdAppModalHeaderNav')
  }


  closeStickyNavMoreMenu() {
    this.document.getElementById('sticky-nav-dropdown-content').className = 'stickyNavDropdownContent';
  }

  goToMarketLens() {
    if (!this._commonService.isDesktop()) {
      window.open(this.content?.text?.linkURL, '_system', 'location=yes, resizable=yes, scrollbars=yes');
      return
    }
    this.httpService
      .post(gatewayConfig.APIServices.marketlensSettings.url, {})
      .subscribe((res) => {
        if (res.TradingPasswordValidation) {
          this.openMTPAppModal()
        } else {
          this._commonService.goToMarketlensWithoutPassword()
        }
      },
        (err) => {
          this.appStore.showErrorInPopup = true;
          throw err;
        });
  }

  ngAfterViewInit() {
    this.notched = this.commonService.isNotchPhone();
    // if (this.commonService.isNotchPhone()) {
    //   if (this.document.getElementById('page-footer-wrapper')) {
    //     this.renderer.addClass(this.document.getElementById('page-footer-wrapper'), 'pageFooterWrapperIos');
    //   }
    // }
  }
  openWindow(moreListItem) {
    if (moreListItem.url === 'dco') {
       this.openDCOAppModal();
      //this. submitDCOApp();
    } else {
      if (moreListItem.tab && !this.appStore.isApp('app')) {
        window.open(moreListItem.url);
      } else {
        window.open(moreListItem.url, '_system', 'location=yes, resizable=yes, scrollbars=yes');
      }
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isQuoteResearch() {
    if (document.location.hash.indexOf('#/txn/quotesResearch/') == 0 && document.location.hash.indexOf('factSheets') == -1
     && document.location.hash.indexOf('technicalInsights') == -1 && document.location.hash.indexOf('tradeIdeas') == -1
      && !this.appStore.isApp()) {
      return document.location.hash.indexOf('#/txn/quotesResearch/optionCentre') != 0;
    }
    return false;
  }

  openDCOAppModal() {
    this.modalService.open('dcoAppNavBar', 'dcoAppModalHeaderNav');
  }

  submitDCOApp() {
    this.commonService.goToDCOApplication().subscribe(
      (data) => {
        console.log("Success details", data);
        this.dcoFormSubmit(data);
      },
      (err) => {
        console.log("Error details", err);
        this.dcoFormSubmit();
      }
    )
  }
  dcoFormSubmit(data?) {
    this.modalService.close('dcoAppNavBar', 'moreLink');
    let domain = "https://";
    if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
      domain += 'm.onlinebrokerage.cibc.com';
    } else {
      domain += window.location.host;
    //enable for local testing depending on the environment u are connected to change the domain to either sit, dit
    //domain += 'm.sit4.onlinebrokerage.cibc.com';
    }
    const target = this.appStore.isApp() ? "_system" : "_self";
    const token = data?.DcoAcctOpenJwtToken;
    const lang = this.appStore.lang;
    let url = domain + "/dco/html/registerOnline.html#"+lang;
    if (token) {
      url = url + "/" + encodeURIComponent(token);
    }
    window.open(url, target);
    if (this.appStore.isApp('app')) {
      this.globalSignOffService.obr2DCOWrapperSignoff();
    } else if (this.appStore.isApp('inapp')) {
      this.globalSignOffService.signOff(true, true);
    }
  }

  closeDCOModal() {
    this.modalService.close('dcoAppNavBar', 'moreLink');
  }

  mainNavInteraction(primaryName) {
    if (primaryName) {
      let data = "main-nav:" + primaryName;
      if ((window as any).TrackingEnabled) {
        of(this.trackingService.tagInteraction(data, true, true, false, true));
      }
    }
  }

  secondLevelNavInteraction(primaryName, subName) {
    if (primaryName && subName) {
      let data = primaryName + ":second-nav:" + subName;
      if ((window as any).TrackingEnabled) {
        of(this.trackingService.tagInteraction(data, true, true, false, true));
      }
    }
  }

  hoverNavInteraction(primaryName, subName) {
    if (primaryName && subName) {
      let data = primaryName + ":flyout-nav:" + subName;
      if ((window as any).TrackingEnabled) {
        of(this.trackingService.tagInteraction(data, true, true, false, true));
      }
    }
  }

  openQuickTrade() {
    this._subscriptionService.openOrCloseDrawer.next('mobile');
  }

  isOpenQuickTrade() {
    this.subscriptions.add(this._subscriptionService.openOrCloseDrawer.subscribe(res => {
      if (res) {
        this.isTradeDrawerOpen = true;
      } else {
        this.isTradeDrawerOpen = false;
      }
    }))
  }

  closeBottomSubNav() {
    this.bottomSubNavOpen = false;
    this.setSubNavPos(false);
    this.undoTrapFocus();
  }

  toggleBottomSubNav(index) {
    if (index == this.bottomSubNav) {
      this.bottomSubNavOpen = !this.bottomSubNavOpen;
      if (this.bottomSubNavOpen) {
        this.trapFocus();
        this.currentBottomSubNavItem = 0;
        this.focusBottomSubNav();
      } else {
        this.undoTrapFocus();
      }
      if (this.bottomSubNavOpen) {
        this.setSubNavPos(true);
      } else {
        this.setSubNavPos(false);
      }
    } else {
      if (this.bottomSubNav == -1) {
        this.bottomSubNav = index;
        setTimeout(() => {
          this.setSubNavPos(false);
          this.trapFocus();
          setTimeout(() => {
            this.bottomSubNavOpen = true;
            this.setSubNavPos(true);
            this.currentBottomSubNavItem = 0;
            this.focusBottomSubNav();
          }, 50);
        }, 50);
      } else {
        this.bottomSubNav = index;
        this.currentBottomSubNavItem = 0;
        this.focusBottomSubNav();
      }
    }
  }

  displayThirdLevelNav(j) {
    this.currentSecondSubItem = -1;
    let chev = document.getElementById("second-level-dropdown-chevron-" + j);
    if (j == this.currentSecondDropDown) {
      this.currentSecondDropDown = null;
      if (chev) {
        chev.className = "animatedChevron icon-Chevron-Up rotateDown";
      }
    } else {
      if (this.currentSecondDropDown != null) {
        let chev2 = document.getElementById("second-level-dropdown-chevron-" + this.currentSecondDropDown);
        if (chev2) {
          chev2.className = "animatedChevron icon-Chevron-Up rotateDown";
        }
      }
      this.currentSecondDropDown = j;
      if (chev) {
        setTimeout(() => {
          this.currentSecondSubItem = 0;
          let el = document.getElementById("navigation-link-" + this.currentDropdownMenu + "-" + this.currentSubItem + "-" + this.currentSecondSubItem);
          if (el) {
            el.focus();
          }
        }, 100);
        chev.className = "animatedChevron icon-Chevron-Up rotateUp";
      }
    }
  }

  trapFocus() {
    this.focusableElments = { 'page-footer-wrapper': { focusableElements: [] } };
    this.commonService.setTabIndex('page-footer-wrapper', '', this.focusableElments, '', 'app-bottom-sub-nav', '');
  }

  undoTrapFocus() {
    const id = 'page-footer-wrapper';
    if (this.focusableElments && this.focusableElments[id]) {
      this.commonService.undoTrapFocus(this.focusableElments[id].focusableElements, id);
    }
  }

  setSubNavPos(open) {
    let el = document.getElementById("bottomSubNav-container");
    if (el) {
      if (open) {
        el.style['bottom'] = '0px';
      } else {
        el.style['bottom'] = (0 - el.clientHeight) + 'px';
      }
    }
  }

  bottomSubNavKeyDown(event) {
    //escape
    if (event.keyCode == 27) {
      this.bottomSubNavOpen = false;
      this.undoTrapFocus();
      this.setSubNavPos(false);
      this.focusBottomNav();
    }
    // up
    if (event.keyCode == 38) {
      if (this.currentBottomSubNavItem == 0) {
        this.currentBottomSubNavItem = this.bottomNavObject[this.bottomSubNav].subNav.length - 1;
      } else {
        this.currentBottomSubNavItem--;
      }
      this.focusBottomSubNav();
      event.preventDefault();
    }
    // down
    if (event.keyCode == 40) {
      if (this.currentBottomSubNavItem == this.bottomNavObject[this.bottomSubNav].subNav.length - 1) {
        this.currentBottomSubNavItem = 0;
      } else {
        this.currentBottomSubNavItem++;
      }
      this.focusBottomSubNav();
      event.preventDefault();
    }
  }

  focusBottomSubNav() {
    let el = document.getElementById("bottomSubNav-link-" + this.currentBottomSubNavItem);
    if (el) {
      el.focus();
    }
  }

  focusBottomNav() {
    let el = document.getElementById("bottomNav-" + this.bottomSubNav);
    if (el) {
      el.focus();
    }
  }

  greyOut(val) {
    this.greyedOut = val;
  }

  mouseOverSecondaryNav(i) {
    if (i != this.currentSecondNav) {
      this.displaySecondNavDropdown = true;
      if (isDefined(this.currentSecondNav)) {
        let chev = document.getElementById("second-level-nav-chevron-" + this.currentSecondNav);
        if (chev) {
          chev.className = "icon-Chevron-Down color03 thirdLevelNav-icon animateUp";
        }
      }
      this.currentSecondNav = i;
      this.currentSecondSubItem = -1;
      let chev = document.getElementById("second-level-nav-chevron-" + i);
      if (chev) {
        chev.className = "icon-Chevron-Down color03 thirdLevelNav-icon animateDown";
      }
    }
    clearTimeout(this.SecondDropdownTimer);
  }

  mouseOutSecondaryNav(i) {
    this.SecondDropdownTimer = setTimeout(() => {
      if (i == this.currentSecondNav) {
        this.displaySecondNavDropdown = false;
        this.currentSecondNav = null;
        let chev = document.getElementById("second-level-nav-chevron-" + i);
        if (chev) {
          chev.className = "icon-Chevron-Down color03 thirdLevelNav-icon animateUp";
        }
      }
    }, 200);
  }

  focusInSecondNav(category) {
    if (category != this.currentSecondNav) {
      setTimeout(() => {
        this.displaySecondNavDropdown = true;
        this.currentSecondNav = category;
      }, 50);
    }
    clearTimeout(this.SecondDropdownTimer);
  }

  focusOutSecondNav(category) {
    this.dropdownMenuTimer = setTimeout(() => {
      if (category == this.currentSecondNav) {
        this.displaySecondNavDropdown = false;
        this.currentSecondNav = null;
      }
    }, 200);
  }

  focusThirdNav(subLink: number) {
    this.currentSecondSubItem = subLink;
  }

  closeSecondDropdown() {
    this.currentSecondDropDown = null;
  }

  closeSymbolSearch() {
    this.closeSearch.next();
  }

  sendBottomSubNavOmni(omni) {
    let val = 'quick-trade:trading:trade-stocks:flyout-nav:' + omni
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(val, true, true, false, true));
    }
  }


  setFocus(targetElementId) {
    if (targetElementId) {
      const targetElement = document.getElementById(targetElementId);
      window.requestAnimationFrame(() => {
        if (targetElement) {
          targetElement.focus();
        }
      });
    }
  }

  setDropDownState(state) {
    if (state) {
      setTimeout(() => {
        this.setFocus('ASSD' + this.selectedOptionId);
        this.thirdLevelNavDropDownHighlightedElement = document.getElementById('ASSD' + this.selectedOptionId);
         
      }, 200);
    } else {
      if (this.thirdLevelNavDropDownOpen) {
        this.setFocus('ddButton');
      }
    }
    
    this.thirdLevelNavDropDownOpen = state;
  }

  optionSelected(option,i) {
      this.thirdLevelNavDisplayValue = option.displayName;
      this.router.navigate([option.childrenUrls[0]]);
      this.setDropDownState(false);
  }

  onMouseEnter(e) {
    this.setFocus(e.currentTarget.id);
}
handleBlur(e) {
    if (e.relatedTarget) {
        return !e.relatedTarget || (e.relatedTarget && e.relatedTarget.id.indexOf('ASSD') !== 0);
    } else {
        return !document.activeElement || (document.activeElement && document.activeElement.id.indexOf('ASSD') !== 0);
    }
}
onBlur(e) {
    if (this.handleBlur(e)) {
        this.setDropDownState(false);
        e.preventDefault();
    }
}
onKeyDown(e) {
    if (e.keyCode === 9) {
        this.setDropDownState(false);
        e.preventDefault();
    }

}

onKeyUp(e,option) {
  if (e.keyCode === 38) {
      if (this.thirdLevelNavDropDownHighlightedElement !== this.firstFocusableEl && this.thirdLevelNavDropDownHighlightedElement.previousElementSibling) {
          this.thirdLevelNavDropDownHighlightedElement.previousElementSibling.focus();
          this.thirdLevelNavDropDownHighlightedElement = this.thirdLevelNavDropDownHighlightedElement.previousElementSibling;
      } else {
          this.thirdLevelNavDropDownHighlightedElement.focus();

      }
  } else if (e.keyCode === 40) {
      if (this.thirdLevelNavDropDownHighlightedElement !== this.lastFocusableEl && this.thirdLevelNavDropDownHighlightedElement.nextElementSibling) {
          this.thirdLevelNavDropDownHighlightedElement = this.thirdLevelNavDropDownHighlightedElement.nextElementSibling;
          this.thirdLevelNavDropDownHighlightedElement.focus();
      } else {
          this.thirdLevelNavDropDownHighlightedElement.focus();
      }
  } else if (e.keyCode === 13) {
      this.setDropDownState(false);
      this.router.navigate([option.childrenUrls[0]])

  } else if (e.keyCode === 27) {
      this.setDropDownState(false);
      e.preventDefault();
  }
}

}


