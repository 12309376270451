import { RouteGroup } from '../shared/models/route-config';

export const QuotesResearchBridgeConfig: RouteGroup = {
  '/txn/bridge/quotesResearch': {
    name: 'QuotesResearchBridge',
    service: 'QuotesResearchBridgeService',
    store: 'QuotesResearchBridgeStore',
    resolveContent: 'quotesresearch',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: []
  }
};
