<ng-container *ngIf="content.name == 'quicktradestocksverification'">
  <form *ngIf='data' (ngSubmit)="next()" id="{{'overlayContent' + uniqueName}}" class="overlay-content"
    #quickTradeStockVerifyForm="ngForm">
    <app-global-refresh *ngIf="mode == 'quickTrade'" type="time" [name]="'quickTrade'"></app-global-refresh>
    <div class="overlay-body">
      <div class="overlay-container">
        <div id="quick_trade_cnf_errorCode"
          class="page-top-invisible-divider -ms-page-top-divider-two-col -ms-page-top-divider-one-col bg-error error-box">
          <custom-app-error class="marginBottom10" (formErrorPass)="resetTradePassword($event)"
            [identifier]="'custom-error'"
            [active]="currentModule == 'quicktradestocksverify' || currentModule == 'prompt'"></custom-app-error>
          <div *ngFor="let warning of orderWarnings let j = index">
            <app-page-level-error *ngIf="(warning !== undefined)" [identifier]="'quick-trade-stocks-verify-alert-' + j"
              [error]="warning.Message" [mode]="'alert'">
            </app-page-level-error>
          </div>
        </div>
        <div class="account-details top-details">
          <div class="hidden-accessibility-container">
            <app-field-level-error-global-message [errorList]="formError"></app-field-level-error-global-message>
          </div>
          <div class="container">
            <div class="account-detail">
              <div class="divTable">
                <div class="divTableBody">
                  <div class="divTableRow">
                    <div class="divTableCell title">{{content.text.activity}}</div>
                    <div id="quick_trade_cnf_orderInfo" class="divTableCell title-desc">
                      {{globalContent.getActionContent(orderReviewInfo.Activity.OrderType)}} <span>
                        {{orderReviewInfo.Activity.Quantity.Content}}</span> {{content.text.shares}}
                      <span *ngIf="orderReviewInfo.Price.Type == 'MKT'" [attr.aria-label]="content.text.specialAtOneAU">{{content.text.specialCharacterOne}} </span>
                      <span *ngIf="orderReviewInfo.Price.Type != 'MKT'" [attr.aria-label]="content.text.specialAtOne">{{content.text.specialCharacterOne}} </span>
                      <span *ngIf="orderReviewInfo.Price.Type == 'MKT'">{{content.text.marketPrice}}</span>
                      <span *ngIf="orderReviewInfo.Price.Type=='LIMIT'">{{content.text.limitPriceOf}}
                        <span
                        [innerHtml]="orderReviewInfo.Price.LimitAmount.Data | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                        <span class="new-symbol-price-market" aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                      </span>
                      <span *ngIf="orderReviewInfo.Price.Type=='STOP'">{{content.text.stopLimitPriceOf}}
                        <span
                        [innerHtml]="orderReviewInfo.Price.Amount.Data | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                        <span class="new-symbol-price-market" aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                        {{content.text.stopPriceOf}}
                        <span
                        [innerHtml]="orderReviewInfo.Price.LimitAmount.Data | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                        <span class="new-symbol-price-market" aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                      </span>
                      <span *ngIf="orderReviewInfo.Price.Type=='TRAILINGSTOPLMTDOLLAR'">{{content.text.triggerDelta}}
                        <span
                        [innerHtml]="orderReviewInfo.Price.TrailingDelta.Data | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                        <span class="new-symbol-price-market" aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                        {{content.text.limitOffset}}
                        <span
                        [innerHtml]="orderReviewInfo.Price.LimitOffset.Data | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                        <span class="new-symbol-price-market" aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                      </span>
                      <span
                        *ngIf="orderReviewInfo.Price.Type=='TRAILINGSTOPLMTPERCENTAGE'">{{content.text.triggerDelta}}
                        {{orderReviewInfo.Price.TrailingDelta.Content}} {{content.text.limitOffset}}
                        <span
                        [innerHtml]="orderReviewInfo.Price.LimitOffset.Data | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                        <span class="new-symbol-price-market" aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                      </span>
                    </div>
                  </div>
                  <div id="quick_trade_cnf_symbol" class="divTableRow">
                    <div class="divTableCell title">{{content.text.symbol}}</div>
                    <div class="divTableCell title-desc"> <img class="symbol-flag" aria-hidden="true" alt=""
                        [src]='getFlag(orderReviewInfo.Symbol.Market)' />
                      <span class=" title-desc">
                        <span>{{orderReviewInfo.Symbol.SymbolName}}</span>
                        <span class="sr-only">{{orderReviewInfo.Symbol.Market}}</span>
                      </span>
                      <span class="new-symbol-name" *ngIf="quote && quote.Symbol && quote.Symbol.SymbolLongName"
                        [ngClass]="quote.Symbol.SymbolLongName.length > 48 ? 'new-symbol-small-name' : ''">{{quote.Symbol.SymbolLongName}}</span>
                    </div>
                  </div>
                  <div id="quick_trade_cnf_expiry" class="divTableRow">
                    <div class="divTableCell title">{{content.text.expiry}}</div>
                    <div class="divTableCell title-desc">
                      {{globalContent.getNewExpiryContent(orderReviewInfo.Expiry.ExpiryType,
                      orderReviewInfo.Expiry.ExpiryDate,
                      "MMMM D, YYYY",'D
                      MMMM YYYY',appStore.lang, true)}}</div>
                  </div>
                  <div id="quick_trade_cnf_estimatedCost2" class="divTableRow">
                    <div class="divTableCell title"
                      *ngIf='orderReviewInfo.Activity.OrderType==="BUY" || orderReviewInfo.Activity.OrderType =="BUYTOCOVER"'>
                      {{content.text.estimatedCost}}
                    </div>
                    <div class="divTableCell title"
                      *ngIf='orderReviewInfo.Activity.OrderType=="SELL" || orderReviewInfo.Activity.OrderType == "SHORT"'>
                      {{content.text.estimatedProceeds}}
                    </div>
                    <div *ngIf="estimatedCost && estimatedCost.EstimatedTotalCost" class="divTableCell title-desc">
                      <span
                        [innerHtml]="estimatedCost.EstimatedTotalCost.replace(' $', '').replace('$', '') | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'">
                      </span>
                      <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                      <div id="quick_trade_cnf_description" class="description">
                        <div *ngIf="estimatedCost.EstimatedOrderValue">{{content.text.tradeValue}}
                          {{estimatedCost.EstimatedOrderValue}}</div>
                        <div *ngIf="estimatedCost.EstimatedCommission">{{content.text.commission}}
                          {{estimatedCost.EstimatedCommission}}</div>
                        <div *ngIf="estimatedCost.EstimatedSecFee">
                          <div class="description-tooltip-margin">{{content.text.secFeeColon}}
                            {{estimatedCost.EstimatedSecFee}}</div>
                          <app-tooltip [id]="'estimatedSecFee'+uniqueName" [tooltipContent]="secFeeToolTip"
                            openIconName="icon-Info-Circle" [largeHitBox]="true" targetEle="submit-order"
                            [mode]="'modal-soft'" (click)="tooltipClicked()"
                            attr.aria-label="{{content.text.secFeeLabelText}}">
                            <div class="tooltip-lightbox">
                              <p>
                                {{content.text.secFeeToolTipLine1}}
                              </p>
                              <p>
                                {{content.text.secFeeToolTipLine2}}
                              </p>
                              <p>
                                {{content.text.secFeeToolTipLine3}}
                              </p>
                            </div>
                          </app-tooltip>
                        </div>
                        <div id="quick_trade_cnf_fttfeecolon" *ngIf="estimatedCost.EstimatedFTTFee" class="description">
                          <div class="description-tooltip-margin">{{content.text.FTTFeeColon}}
                            {{estimatedCost.EstimatedFTTFee}}</div>
                          <app-tooltip [id]="'estimatedFttFee'+uniqueName" [tooltipContent]="FTTToolTip"
                            openIconName="icon-Info-Circle" [largeHitBox]="true" targetEle="submit-order"
                            [mode]="'modal-soft'" (click)="tooltipClicked()"
                            attr.aria-label="{{content.text.FTTFeeLabelText}}">
                            <div class="tooltip-lightbox">
                              <p>
                                {{content.text.fttFeeToolTipLine1}} {{content.text.fttFeeToolTipLine2}}
                              </p>
                            </div>
                          </app-tooltip>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!estimatedCost || !estimatedCost.EstimatedTotalCost" class="divTableCell">-</div>
                  </div>
                  <div id="quick_trade_cnf_buyingPower" class="divTableRow">
                    <div class="divTableCell title">{{content.text.buyingPowerAfterThisOrder}}</div>
                    <div *ngIf="buyingPower.EstimatedNetBuyingPowerCAD" class="divTableCell title-desc">
                      <span class="expiry-date" *ngIf="orderReviewInfo.Account.AccountType != 'REG'"
                        [innerHtml]="buyingPower.EstimatedNetBuyingPowerCAD.replace(' $', '').replace('$', '') | currencyFormatter:'CAD':'format-symboless-sr'"></span>
                      <span class="expiry-date" *ngIf="orderReviewInfo.Account.AccountType == 'REG'"
                        [innerHtml]="buyingPower.EstimatedNetBuyingPowerCAD.replace(' $', '').replace('$', '') | currencyFormatter:currency:'format-symboless-sr'"></span>
                      <span aria-hidden="true"> {{orderReviewInfo.Account.AccountType != 'REG' ? 'CAD' :
                        currency}}</span>
                    </div>
                    <div *ngIf="!buyingPower.EstimatedNetBuyingPowerCAD" class="divTableCell">-</div>
                  </div>
                  <div id="quick_trade_cnf_accDetails" class="divTableRow ">
                    <div class="divTableCell title">{{content.text.accountDetails}}</div>
                    <div class="divTableCell title-desc">
                      {{globalContent.accountNickname(orderReviewInfo.Account)}}
                      (<span [innerHTML]="orderReviewInfo.Account.AccountNumber | srOnly:true"></span>)
                      -
                      <span>{{currency}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="new-right-col-module-special-message" *ngIf="buyingPower.BuyingPowerIncrease">
                <span [innerHTML]='content.text.BuyingPowerIncreaseMessage'></span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="quote && quote.Symbol" class="account-details">
          <div class="container paddingBottom0">
            <div class="company-details">
              <div id="quick_trade_cnf_vcompanyName" class="company-name">
                <img class="quote-flag" aria-hidden="true" alt="" [src]='getFlag(orderReviewInfo.Symbol.Market)' />
                <span class="new-symbol">
                  <span>{{orderReviewInfo.Symbol.SymbolName}}</span>
                  <span class="sr-only">{{orderReviewInfo.Symbol.Market}}</span>
                </span>
                <span class="new-symbol-name" *ngIf="quote && quote.Symbol && quote.Symbol.SymbolLongName"
                  [ngClass]="quote.Symbol.SymbolLongName.length > 48 ? 'new-symbol-small-name' : ''">{{quote.Symbol.SymbolLongName}}
                </span>
              </div>

              <div id="quick_trade_cnf_lastPrice" class="new-symbol-price-change">
                <div class="new-symbol-price" *ngIf="quote.LastPrice">
                  <span
                    [innerHtml]="valueOrDashDataLang(quote.LastPrice) | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                  <span class="new-symbol-price-market" aria-hidden="true">{{orderReviewInfo.Symbol.Currency}}D</span>
                </div>
                <div class="new-symbol-change"
                  [ngClass]="{ backgroundUp : quote.ChangeIndicator == 'U', backgroundDown : quote.ChangeIndicator == 'D' }">

                  <span
                    [innerHtml]="valueOrDashDataLang(quote.PriceChange) + ' (' + (quote.PriceChangePercent ) + ')'| changeFormatter:quote.ChangeIndicator:'wedge'"></span>
                </div>
              </div>
              <div id="quick_trade_cnf_bidShares" class="bid-ask-container">
                <div class="bid-ask-block">
                  <div class="bid-ask-title">
                    {{content.text.bidShares}}
                  </div>
                  <div class="bid-ask-price" *ngIf="quote && quote.Symbol">
                    {{valueOrDashContent(quote.BidPrice)}} / {{addCommas(valueOrDashLots(quote.BidSize, quote.LotSize))}}
                  </div>

                </div>
                <div id="quick_trade_cnf_askShares" class="bid-ask-block">
                  <div class="bid-ask-title">{{content.text.askShares}}</div>
                  <div class="bid-ask-price" *ngIf="quote && quote.Symbol"
                    [ngClass]="(!quote.BidPrice && !quote.BidSize) ? 'ask-price-width' : ''">
                    {{valueOrDashContent(quote.AskPrice)}} / {{addCommas(valueOrDashLots(quote.AskSize, quote.LotSize))}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="quick_trade_cnf_checkPass" *ngIf="checkPassword" class="account-details">
          <div class="container">
            <div class="company-trading company-details">
              <label for='trading-Password-input' class="sr-only">
                {{content.text.tradingPassword}}
              </label>
              <div>
                <input type="password" id="{{'trading-Password-input' + uniqueName}}" #tradingPassword="ngModel"
                  name="tradingPassword" [(ngModel)]='inputs.tradingPassword' [appMaxlength]='32'
                  aria-labelledby="trade-pass-label" aria-describedby="tradingPassword-error"
                  [inputErrorDirective]="formError.tradingPassword" (change)="typePass()"
                  placeholder="{{content.text.tradingPassword}}" class="soft">
              </div>
              <app-field-level-error class="tradePassError" [error]="formError.tradingPassword"
                [Id]="'tradingPassword-error'+uniqueName">
              </app-field-level-error>
              <div id="quick_trade_cnf_save_remember" class="save-remember">
                <div style="width: 40%;">
                  <input type="checkbox" name="saveTradePassword" [(ngModel)]='inputs.savePassword'
                    [attr.aria-labelledby]="'save-trade-pass-label' + uniqueName">
                  <span id="{{'save-trade-pass-label' + uniqueName}}"
                    class="save-title">{{content.text.saveTradingPassword}}</span>
                  <app-tooltip [id]="'savePassToolTipQuickTrade'+uniqueName" [tooltipContent]="savePassToolTip"
                    openIconName="icon-Info-Circle" [largeHitBox]="true" targetEle="submit-order" [mode]="'modal-soft'"
                    (click)="tooltipClicked()" attr.aria-label="{{content.text.savePassLabelText}}">
                    <div class="tooltip-lightbox">
                      <p>
                        {{content.text.savePassToolTip}}
                      </p>
                    </div>
                  </app-tooltip>
                </div>
                <div id="quick_trade_cnf_forgetPassword" class="cant-remember"> <a (click)="forgotPassword()"
                    href="javascript:void(0);">{{content.text.forgotTradePass}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="information">
          <div id="quick_trade_cnf_More" class="more">
            <p class="more-info-label">{{content.text.moreinformationHeader}}</p>
            <app-tooltip [id]="'moreInfoToolTipVerify'+uniqueName" [tooltipContent]="moreInfoToolTip"
              openIconName="icon-Info-Circle" [largeHitBox]="true" targetEle="submit-order" [mode]="'modal-soft'"
              (click)="tooltipClicked('moreInfo')" attr.aria-label="{{content.text.moreinformationLabelText}}">
              <div class="tooltip-lightbox">
                <p class="bb-2" [innerHTML]="content.text.moreInfoToolTipLine1">
                </p>
                <p>
                  {{content.text.moreInfoToolTipLine2}}
                </p>
              </div>
            </app-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-alert"
      [ngClass]="{'bottom-spacing' : mode != 'quickTrade', 'notch': notch, 'screenReader': isScreenReaderON}"
      [attr.aria-hidden]="isKeyboardOpen ? true : false" [hidden]="isKeyboardOpen">
      <div class="container">
        <div
          [ngClass]="orderReviewInfo.Activity.OrderType == 'BUY'|| orderReviewInfo.Activity.OrderType == '' || orderReviewInfo.Activity.OrderType == 'BUYTOCOVER' ? 'box-alert-buy' : 'box-alert-sell'">
          <div id="quick_trade_cnf_bottomAlert" class="paragraph">
            <span>
              <span
                class="text-box-navigation-bold">{{getSummaryActionContent(orderReviewInfo.Activity.OrderType)}}</span>
              <span *ngIf="orderReviewInfo.Activity.Quantity.Content == undefined" class="text-box-navigation-bold">
                {{content.text.zero}}
              </span>
              <span *ngIf="orderReviewInfo.Activity.Quantity.Content != undefined" class="text-box-navigation-bold">
                {{orderReviewInfo.Activity.Quantity.Content}}
              </span>
              <span class="text-box-navigation-bold">{{content.text.sharesOf}} </span>
              <span *ngIf="orderReviewInfo.Symbol.SymbolName == undefined"
                class="text-box-navigation-bold">{{content.text.chosenSecurity}}</span>
              <span *ngIf="orderReviewInfo.Symbol.SymbolName != undefined" class="text-box-navigation-bold">
                <span>{{orderReviewInfo.Symbol.SymbolName}}</span>
                <span *ngIf="orderReviewInfo.Price.Type == 'MKT'">:{{orderReviewInfo.Symbol.Market}}</span>
              </span>
              <span class="text-box-navigation-medium" [attr.aria-label]="content.text.atSignSR">
                {{content.text.atSign}}
              </span>
              <span *ngIf="orderReviewInfo.Price.Type == 'MKT'"
                class="text-box-navigation-medium">{{content.text.summaryPriceMarket}},</span>
              <span id="quick_trade_cnf_summaryLimit" *ngIf="orderReviewInfo.Price.Type == 'LIMIT'" class="text-box-navigation-medium">
                <span>{{content.text.summaryPriceLimit}} </span>
                <span [innerHtml]="orderReviewInfo.Price.LimitAmount.Data |
                currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>,
              </span>
              <span id="quick_trade_cnf_summaryPrice" *ngIf=" orderReviewInfo.Price.Type=='STOP'" class="text-box-navigation-medium">
                <span>{{content.text.summaryPriceStop}} </span>
                <span [innerHtml]="orderReviewInfo.Price.Amount.Data |
                currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                <span> {{content.text.summaryPriceStopLimit}} </span>
                <span [innerHtml]="orderReviewInfo.Price.LimitAmount.Data |
                currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>,
              </span>
              <span id="quick_trade_cnf_trailingLimit" *ngIf="orderReviewInfo.Price.Type=='TRAILINGSTOPLMTDOLLAR'" class="text-box-navigation-medium">
                {{content.text.summaryTrailingStopTriggerDelta}}
                <span [innerHtml]="orderReviewInfo.Price.TrailingDelta.Data |
                currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                {{content.text.summaryTrailingStopLimitOffset}}
                <span [innerHtml]="orderReviewInfo.Price.LimitOffset.Data |
                currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>,
              </span>
              <span id="quick_trade_cnf_trailingStop" *ngIf="orderReviewInfo.Price.Type=='TRAILINGSTOPLMTPERCENTAGE'" class="text-box-navigation-medium">
                {{content.text.summaryTrailingStopTriggerDelta}}
                <span>{{orderReviewInfo.Price.TrailingDelta.Content}}</span>
                {{content.text.summaryTrailingStopLimitOffset}}
                <span [innerHtml]="orderReviewInfo.Price.LimitOffset.Data |
                currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
                <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>,
              </span>
              <span class="text-box-navigation-medium">
                {{content.text.expiring}}
              </span>
              <span *ngIf="orderReviewInfo.Expiry.ExpiryType == 'DAY' || orderReviewInfo.Expiry.ExpiryType == 'DAY+' || orderReviewInfo.Expiry.ExpiryType == 'DAY_PLUS'"
                class="text-box-navigation-medium">{{quickTradeContent.text.expiringDay}}</span>
              <span *ngIf="orderReviewInfo.Expiry.ExpiryType != 'DAY' && orderReviewInfo.Expiry.ExpiryType != 'DAY+' && orderReviewInfo.Expiry.ExpiryType != 'DAY_PLUS'"
                class="text-box-navigation-medium">{{quickTradeContent.text.expiringCustom}}</span>
              <span
                class="text-box-navigation-medium">{{globalContent.getNewExpiryContent(orderReviewInfo.Expiry.ExpiryType,
                orderReviewInfo.Expiry.ExpiryDate,
                "MMMM D, YYYY",'D
                MMMM YYYY',appStore.lang, false)}}.</span>
            </span>
          </div>
        </div>
      </div>
      <div class="bottom-button">
        <div class="container">
          <div class="button-background">
            <button class="submit button next-button"
              [ngClass]="orderReviewInfo.Activity.OrderType == 'BUY' || orderReviewInfo.Activity.OrderType == 'BUYTOCOVER' ? 'green' : 'orange'"
              (click)="next()" type="button" id="{{'submitTradeDrawer' + uniqueName}}"
              buttonDisable>{{content.text.submitOrder}}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<app-otvc-prompt-modal [uniqueId]="uniqueId" [otvcPromptData]="otvcPromptDataParam" [mode]="modalMode"
  [PromptContent]="otvcPromptContent" [verifyUrl]="verifyurl" [currentModule]="currentModule"
  (modalCancelRequest)="closeModal()">
</app-otvc-prompt-modal>