import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'integrity-check',
    templateUrl: './integrity-check.component.html',
    styleUrls: ['./integrity-check.component.scss']
  })

  export class IntegrityCheckComponent implements OnInit {

    constructor(
) {
    }

    ngOnInit() {
    }
  
  }