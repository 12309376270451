import { Component, OnInit, Input, OnChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ContentService } from 'src/app/core/services/content.service';
import { AppStore } from '../../models/app-store';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';
import { HelpService } from '../../../modules/txn/help/services/help.service';
import { Router } from '@angular/router';
import { DocType } from '../../../modules/txn/edocuments/data/edocuments-data';
import { Lob } from '../../models/lob.enum';


@Component({
  selector: 'app-disclaimers',
  templateUrl: './disclaimers.component.html',
  styleUrls: ['./disclaimers.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DisclaimersComponent implements OnChanges, OnDestroy {

  @Input() contents;
  @Input() identifier: string;
  @Input() myAccount = false;
  @Input() isParagraphContent = false;
  disclaimersList: any;
  expandDisclaimer: boolean;
  disclaimersContent: any;
  breakPoint: number;
  showDisclaimer = true;

  lob: string;
  subscriptions = new Subscription();

  constructor(
    private _contentService: ContentService,
    private _appstore: AppStore,
    private _subscriptionService: ObservableSubscriptionService,
    private _helpService: HelpService,
    private commonService: CommonService,
    private router: Router
  ) {
  }

  ngOnChanges() {
    if ((this.contents === undefined) && (this.identifier === undefined)) {
      this.showDisclaimer = false;
    }
    this.lob = this._appstore.lob.toLowerCase();
    this.subscriptions.add(this._subscriptionService.stringLob.subscribe((lob) => {
      if (this.lob || lob) {
        this.subscriptions.add(this._contentService.fetchContent('disclaimers').subscribe((data) => {
          if (data) {
            this.disclaimersContent = data;
            if (this.identifier === undefined || this.identifier === '') {
              this.disclaimersList = this.contents;
            } else {
              this.disclaimersList = this.getPageDisclaimersList(this.identifier);
            }
            this.showDisclaimer = !(this.disclaimersList === undefined);
          }
        }));
        // this.disclaimersContent = this._contentService.fetchMyContent('disclaimers');
        // if (this.identifier === undefined || this.identifier === '') {
        //   this.disclaimersList = this.contents;
        // } else {
        //   this.disclaimersList = this.getPageDisclaimersList(this.identifier);
        // }
      }
    }));
    this.decideExpansion();
  }

  decideExpansion() {
    this.expandDisclaimer = this.commonService.isDesktop() && this._appstore.lob !== Lob.IE;
  }

  toggleDisclaimer() {
    this.expandDisclaimer = !this.expandDisclaimer;
  }

  makeID() {
    if (this.identifier === undefined || this.identifier === '') {
      return 'disclaimer-container';
    } else {
      return 'disclaimer-container-' + this.identifier;
    }
  }
  isPermission(routeEnum) {
    return this.commonService.checkPermissionForRoutes(routeEnum);
  }

  getPageDisclaimersList(pageName) {
    if (pageName === 'TradeStocksLanding') {
      return this.disclaimersContent.list.StockTradingList;
    } else if (pageName === 'TradeStocksVerify') {
      return this.disclaimersContent.list.StockTradingVerifyList;
    } else if (pageName === 'TradeOptionsLanding') {
      return this.disclaimersContent.list.OptionTradingList;
    } else if (pageName === 'TradeOptionsVerify') {
      return this.disclaimersContent.list.OptionTradingVerifyList;
    } else if (pageName === 'OrderStatusStock'
      || pageName === 'StockCancelVerify' || pageName === 'StockCancelConfirm'
      || pageName === 'StockChangeModify' || pageName === 'StockChangeVerify' || pageName === 'StockChangeConfirm') {
      return this.disclaimersContent.list.StockCFOList;
    } else if (pageName === 'OrderStatusOption'
      || pageName === 'OptionCancelVerify' || pageName === 'OptionCancelConfirm'
      || pageName === 'OptionChangeModify' || pageName === 'OptionChangeVerify' || pageName === 'OptionChangeConfirm') {
      return this.disclaimersContent.list.OptionCFOList;
    } else if (pageName === "OrderStatusMutualFund") {
      return this.disclaimersContent.list.OrderStatusMutualFund;
    } else if (pageName === 'OrderStatusCashTransfer'
      || pageName === 'CashTransferCancelVerify' || pageName === 'CashTransferCancelConfirm'
      || pageName === 'CashTransferChangeModify' || pageName === 'CashTransferChangeVerify' || pageName === 'CashTransferChangeConfirm') {
      return this.disclaimersContent.list.CashTransferCFOList;
    } else if (pageName === 'QuoteAndWatchLists' || pageName === 'QuoteDetails'
      || pageName === 'MarketDetails' || pageName === 'Markets') {
      return this.disclaimersContent.list.QuoteAndWatchLists;
    } else if (pageName === 'MarketDetails') {
      pageName = 'Markets';
    } else if (pageName === 'MyAccounts') {
      return this.disclaimersContent.list.MyAccountsList;
    } else if (this._appstore.lob === 'cpic' && pageName === 'AccountHoldings') {
      pageName = 'PortfolioHoldings';
    } else if (pageName === 'AccountHoldingDetails') {
      pageName = 'HoldingDetails';
    } else if ((this._appstore.lob === 'ie') && (pageName === 'CashTransferLanding')) {
      return this.disclaimersContent.list.CashTransferListLandingIE;
    } else if ((this._appstore.lob === 'ie') && (pageName === 'CashTransferVerify' || pageName === 'CashTransferConfirm')) {
      return this.disclaimersContent.list.CashTransferList;
    } else if ((this._appstore.lob === 'iis' || this._appstore.lob === 'cfpi') && (pageName === 'CashTransferLanding' || pageName === 'CashTransferVerify' || pageName === 'CashTransferConfirm')) {
      return this.disclaimersContent.list.CashTransferList;
    } else if (this._appstore.lob === 'wg' && (pageName === 'CashTransferLanding' || pageName === 'CashTransferVerify' || pageName === 'CashTransferConfirm')) {
      return this.disclaimersContent.list.CashTransferListWG;
    } else if (this._appstore.lob === 'ie' && pageName === 'AccountHoldingBookValue') {
      return this.disclaimersContent.list.AccountHoldingBookValueIE;
    } else if (this._appstore.lob === 'ie' && pageName === 'AccountHoldingBookValueNew') {
      return {
        ...this.disclaimersContent.list.AccountHoldingBookValueIE,
        ...this.disclaimersContent.list.AccountHoldingMarkit
      };
    } else if (pageName === 'AccountHoldingBookValue') {
      return this.disclaimersContent.list.AccountHoldingBookValue;
    } else if (this._appstore.lob === 'ie' && pageName === 'AccountHoldingLastBusinessDay') {
      return this.disclaimersContent.list.AccountHoldingLastBusinessDayIE;
    } else if (this._appstore.lob === 'ie' && pageName === 'AccountHoldingLastBusinessDayNew') {
      return {
        ...this.disclaimersContent.list.AccountHoldingLastBusinessDayIE,
        ...this.disclaimersContent.list.AccountHoldingMarkit
      };
    } else if (this._appstore.lob === 'wg' && pageName === 'AccountHoldingLastBusinessDay') {
      return this.disclaimersContent.list.AccountHoldingLastBusinessDayWG;
    } else if ((this._appstore.lob === 'iis' || this._appstore.lob === 'cfpi') && pageName === 'AccountHoldingLastBusinessDay') {
      return this.disclaimersContent.list.AccountHoldingLastBusinessDayIISCFPI;
    } else if (this._appstore.lob === 'ie' && pageName === 'AccountHoldingMostRecent') {
      return this.disclaimersContent.list.AccountHoldingMostRecentIE;
    } else if (this._appstore.lob === 'ie' && pageName === 'AccountHoldingMostRecentNew') {
      return {
        ...this.disclaimersContent.list.AccountHoldingMostRecentIE,
        ...this.disclaimersContent.list.AccountHoldingMarkit
      };
    } else if ((this._appstore.lob === 'iis' || this._appstore.lob === 'cfpi') && pageName === 'AccountHoldingMostRecent') {
      return this.disclaimersContent.list.AccountHoldingMostRecentIIS;
    } else if (this._appstore.lob === 'wg' && pageName === 'AccountHoldingMostRecent') {
      return this.disclaimersContent.list.AccountHoldingMostRecentWG;
    } else if (this._appstore.lob === 'cpic' && pageName === 'AccountHoldingCPIC') {
      return this.disclaimersContent.list.AccountHoldingCPIC;
    } else if (this._appstore.lob === 'wg' && pageName === 'transactionHistory') {
      return this.disclaimersContent.list.transactionHistoryWG;
    } else if (this._appstore.lob !== 'wg' && pageName === 'transactionHistory') {
      return this.disclaimersContent.list.transactionHistory;
    } else if (pageName === 'SetupTradeFillAlerts') {
      return this.disclaimersContent.list.SetupTradeFillAlerts;
    } else if (pageName == 'MutualFundsLanding') {
      return this.disclaimersContent.list.MutualFundsLanding;
    } else if (pageName == 'MutualFundsVerify') {
      return this.disclaimersContent.list.MutualFundsVerify;
    } else if (pageName == 'MutualFundsVerifyBackEnd') {
      return this.disclaimersContent.list.MutualFundsVerifyBackEnd;
    } else if (pageName === 'FixedIncomeBuyBondsOrder') {
      return this.disclaimersContent.list.FixedIncomeBuyBondsOrder;
    } else if (pageName === 'FixedIncomeBuyBondsVerify') {
      return this.disclaimersContent.list.FixedIncomeBuyBondsVerify;
    } else if (pageName === 'FixedIncomeSellFixedIncome') {
      return this.disclaimersContent.list.FixedIncomeSellFixedIncome;
    } else if (pageName === 'FixedIncomeSellGicLanding') {
      return this.disclaimersContent.list.FixedIncomeSellGicLanding;
    } else if (pageName === 'FixedIncomeSellGicVerify') {
      return this.disclaimersContent.list.FixedIncomeSellGicVerify;
    } else if (pageName === 'FixedIncomeSellBondLanding') {
      return this.disclaimersContent.list.FixedIncomeSellBondLanding;
    } else if (pageName === 'FixedIncomeSellBondVerify') {
      return this.disclaimersContent.list.FixedIncomeSellBondVerify;
    } else if (this._appstore.lob === 'ie' && pageName === 'FixedIncomeBuyGicOrder') {
      return this.disclaimersContent.list.FixedIncomeBuyGicOrderIE;
    } else if ((this._appstore.lob === 'iis' || this._appstore.lob === 'cfpi') && pageName === 'FixedIncomeBuyGicOrder') {
      return this.disclaimersContent.list.FixedIncomeBuyGicOrderIISCFPI;
    } else if (pageName === 'FixedIncomeBuyGicVerify') {
      return this.disclaimersContent.list.FixedIncomeBuyGicVerify;
    } else if (pageName === 'PerfRepoRR') {
      return this.disclaimersContent.list.PerformanceReportingRateOfReturn;
    } else if (pageName === 'PerfRepoBT') {
      return this.disclaimersContent.list.PerformanceReportingBalanceTrend;
    } else if (pageName === DocType[0] || pageName === DocType[1] ||
      pageName === DocType[2] || pageName === DocType[3]) {
      return this.disclaimersContent.list[pageName];
    }
    else if (pageName === 'InvestmentCounsellor') {
      return this.disclaimersContent.list.InvestmentCounsellor;
    }
    else if (pageName === 'CreateMutualFundLanding') {
      return this.disclaimersContent.list.CreateMutualFundLanding;
    }
    else if (pageName === 'CreateMutualFundVerify') {
      return this.disclaimersContent.list.CreateMutualFundVerify;
    } else if (pageName == 'CreateMutualFundVerifyBackEnd') {
      return this.disclaimersContent.list.MutualFundsVerifyBackEnd;
    }
    else if (pageName === 'ChangeMutualFundVerify') {
      return this.disclaimersContent.list.ChangeMutualFundVerify;
    }
    else if (pageName === 'ChangeMutualFundVerifyBackEnd') {
      return this.disclaimersContent.list.ChangeMutualFundVerifyBackEnd;
    }
    else if (pageName === 'FixedIncomeBuyGicProd') {
      return this.disclaimersContent.list.FixedIncomeBuyGicProd;
    } else if (pageName === 'BuyStructNotesEntry') {
      return this.disclaimersContent.list.BuyStructNotesEntry;
    } else if (pageName === 'BuyStructNotesVerify') {
      return this.disclaimersContent.list.BuyStructNotesVerify;
    } else if (pageName === 'SellStructNotesLanding') {
      return this.disclaimersContent.list.SellStructNotesLanding;
    } else if (pageName === 'SellStructNotesVerify') {
      return this.disclaimersContent.list.SellStructNotesVerify;
    } else if (pageName === 'BuyStructuredNotes') {
      return this.disclaimersContent.list.BuyStructuredNotes;
    } else if (pageName === 'BuyMlgic') {
      return this.disclaimersContent.list.BuyMlgic;
    }
    else if(pageName === 'exchangeCurrency') {
      return this.disclaimersContent.list.exchangeCurrency;
    }
    else if(pageName === 'exchangeCurrencyCancel') {
      return this.disclaimersContent.list.CashTransferCFOListFx;
    } else if (pageName === 'eventsCalendar') {
      return this.disclaimersContent.list.eventsCalendar;
    }
    else {
      return undefined;
    }
  }

  linkClicked(page, extra) {
    switch (page) {
      case 'help':
        this._helpService.setQuestion(extra.question).subscribe((data) => {
        }, (err) => {
          throw err;
        });
        if (!this._appstore.isApp()) {
          this.commonService.openHelpWindow();
        } else {
          this.router.navigate(["/txn/help"]);
        }
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
