import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PersistenceService } from '../../../core/services/persistence.service';
import { AppStore } from 'src/app/shared/models/app-store';

@Injectable({
    providedIn: 'root'
})
export class SsoService {
    navigateFromObr: Subject<string>;
    keepAliveTimer: any = null;
    maximumTimeOut: number;
    keepAliveTimeOut: number;
    initialTimeStamp: any;
    ObrSessionTimeout = null; // will change to 25 minutes(kept 5 min for testing)
    updateTimeStamp: any;
    clearTimeout: boolean;
    retrieveSessionInfo: any;

    constructor(private persistanceService: PersistenceService, private appStore: AppStore) {
        this.navigateFromObr = new Subject<string>();
    }

    // start the timer with initial 25 minutes and starts after every 25 minutes.
    // refresh is also handled with considering delta value.
    // updates the timestamp after 25 minutes to the new time in session storage.

    startKeepAliveTimer(currentTimestamp) {
        console.log('current time stamp', currentTimestamp);
        clearTimeout(this.keepAliveTimer);
        this.retrieveSessionInfo = this.appStore.retrieveSessionInfo;
        this.initialTimeStamp = this.getPersistenceTimeStamp();
        this.ObrSessionTimeout = (this.retrieveSessionInfo.ObrSessionTimeout * 1000) - (this.retrieveSessionInfo.ObrHeartBeatDelta * 1000)
        this.keepAliveTimeOut = (this.ObrSessionTimeout - (currentTimestamp - this.initialTimeStamp));
        console.log('keepalivetimeout', this.keepAliveTimeOut);

        this.keepAliveTimer = setTimeout(() => {
            if (!this.clearTimeout) {
                console.log('heart beat request to OBR is invoked', this.keepAliveTimeOut);
                const image = document.createElement('img');
                image.src = this.retrieveSessionInfo.OriginHost + this.retrieveSessionInfo.ObrHeartBeatUrl;
                document.getElementById('imageDivId').appendChild(image);
                this.updateTimeStamp = + this.initialTimeStamp + this.ObrSessionTimeout;
                this.setPersistanceInitialTimeStamp(this.updateTimeStamp);
                this.startKeepAliveTimer(this.updateTimeStamp);
            }
        }, this.keepAliveTimeOut);
    }

    clearSsoHeartbeattimer() {
        this.clearTimeout = true;
        // clearTimeout(this.keepAliveTimer);
        // test this scenario after implementing
        // clearTimeout(this.keepAliveTimer);
        this.persistanceService.removeSessionStoreValue('KeepAliveTImeStamp');
        console.log('heart beat cleared');
    }

    storeTimeStamp(timeStamp) {
        this.persistanceService.sessionStorePersist('KeepAliveTImeStamp', timeStamp);
    }

    getPersistenceTimeStamp() {
        return this.persistanceService.sessionStoreRetrieve('KeepAliveTImeStamp');
    }

    setPersistanceInitialTimeStamp(newIntialTimeStamp) {
        this.persistanceService.sessionStorePersist('KeepAliveTImeStamp', newIntialTimeStamp);
    }
}
