import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { QuotesDetailStore } from '../data/quotes-detail-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class QuotesDetailService implements PageService {
  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ser = input[0];
    QuotesDetailStore.loadFromSessionStorage();
    input[0].input = { Symbols: QuotesDetailStore.inFlow.Symbols };
    return input;
  }

  deleteQuotes(watchListName: string, quotes: any[]) {
    const request = {
      Symbols: [],
      Name: watchListName
    };
    for (let i = 0; i < quotes.length; i++) {
      request.Symbols.push({
        SymbolName: quotes[i].Symbol.SymbolName,
        Market: quotes[i].Symbol.Market
      });
    }

    return this.httpService.post(gatewayConfig.APIServices.getWatchlistSymbolsRemove.url, request);

  }

  getQuoteDetails(quote: any) {
    const request = {
      Symbols: [{
        SymbolName: quote.Symbol.SymbolName,
        Market: quote.Symbol.Market
      }]
    };
    return this.httpService.post(gatewayConfig.APIServices.quoteDetail.url, request);
  }

  getQuoteDetailsQuick(quote: any) {
    const request = {
      Symbols: [{
        SymbolName: quote.Symbol.SymbolName,
        Market: quote.Symbol.Market,
        SymbolType: quote.Symbol.SymbolType
      }]
    };
    return this.httpService.post('/v2/quote/quick?skiploading=true', request);
  }
}
