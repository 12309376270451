import { Store } from 'src/app/core/store/store';

export class PageStore<T> extends Store<T> {
  public static params: any;
  public static inFlow: any;
  public static crossFlow: any;
  public static savedInfo: any;

  protected myFlowData: any;

  public static saveToSessionStorage() {
    window.sessionStorage.setItem('refreshInfo', JSON.stringify({ params: this.params, inFlow: this.inFlow }));
  }

  public static loadFromSessionStorage() {
    const info = JSON.parse(window.sessionStorage.getItem('refreshInfo'));
    window.sessionStorage.removeItem('refreshInfo');
    if (info) {
      this.params = info.params;
      this.inFlow = info.inFlow;
      // this.crossFlow = info.crossFlow;
      // this.savedInfo = info.savedInfo;
    }
  }

  public static paramsAvailable(): boolean {
    return true;
  }

  setcontent(content: any) {
    this.setState({
      ...this.state,
      content
    });
  }

  setdata(data: any) {
    this.setState({
      ...this.state,
      data
    });
  }

  seterror(error: any) {
    this.setState({
      ...this.state,
      error
    });
  }

  setinput(input: any) {
    this.setState({
      ...this.state,
      input
    });
  }
}
