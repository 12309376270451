import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalFooterComponent } from './global-footer.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { QrCodeModule } from 'ng-qrcode';

@NgModule({
    declarations: [GlobalFooterComponent],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        QrCodeModule
    ],
    exports: [
        GlobalFooterComponent
    ]
})
export class GlobalFooterModule { }
