import { BaseData } from "../../../../../shared/models/base-data";

export class MyWatchListData extends BaseData {
    constructor() {
      super();
      // this.input = {
  
      // };
    }
  
    
  }