import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { ContentService } from '../../services/content.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { SiteStore } from 'src/app/shared/models/site-store';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { buildInfo } from 'src/app/config/build-info';
import { CommonService } from 'src/app/shared/services/common.service';
import { RoutePermissionService } from 'src/app/shared/services/route-permission.service';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
declare let require: any;
const gatewayConfig = require('../../../config/gateway-config.json');
import { GlobalSignOffService } from '../../services/global-signoff.service';
import { Lang } from '../../../shared/models/lob.enum';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { openWindow } from 'src/app/shared/services/utils.service';

declare let window: any;

@Component({
  selector: 'app-global-footer',
  templateUrl: './global-footer.component.html',
  styleUrls: ['./global-footer.component.scss']
})

export class GlobalFooterComponent implements OnInit, OnDestroy {
  lob: any;
  lang: any;
  pageContent: any;
  disclosure: any;
  disclosure2: any;
  subscriptions = new Subscription();
  buildInfo_Client: string;
  buildInfo_Server: string
  buildInfo_App: string;
  mobileWealthApp: any;
  isApp: boolean;
  isAnyApp: boolean;
  isQuickTrade: boolean = false;
  qrCodeString: string = '';

  constructor(
    public appStore: AppStore,
    public globalContent: GlobalContentStore,
    private _subscriptionService: ObservableSubscriptionService,
    private contentService: ContentService,
    private httpService: HttpService,
    private commonService: CommonService,
    private _routePermissionService: RoutePermissionService,
    private siteStore: SiteStore,
    private router: Router,
    private modalService: ModalService,
    private globalSignOffService: GlobalSignOffService,
    private trackingService: TrackingService
  ) { 
    this.qrCodeString = 'https://' + window.location.host + '/html/redirectToStore.html'
  }

  ngOnInit() {
    const list = [
      '/txn/tradeStock/landing',
      '/txn/tradeStock/verify'
    ]
    this.subscriptions.add(this.router.events.subscribe(a => {
      if (a instanceof NavigationEnd) {
        this.isQuickTrade = list.includes(a.url);
      }
    }));
    this.isApp = this.appStore.isApp('app');
    this.isAnyApp = this.appStore.isApp();
    this.lang = this.appStore.lang.toLowerCase();
    this.getBuildInfo();
    this.subscriptions.add(this.router.events.subscribe(a => {
      if (a instanceof NavigationEnd) {
        this.setSiteMapData();
      }
    }));
    this.subscriptions.add(this._subscriptionService.stringLob.subscribe((lob) => {
      if (lob) {
        this.lob = (this.appStore.state.user.lob === 'cfpi') ? 'iis' : this.appStore.state.user.lob;
        this.subscriptions.add(this.contentService.loadGlobalContent().subscribe((globalData) => {
          if (globalData) {
            this.pageContent = globalData;
            if (this.appStore.isApp('app')) {
              this.pageContent.list.globalFooterLinks =
                this.pageContent.list.globalFooterLinks.filter(obj => {
                  const subD = obj;
                  subD.content = subD.content.filter(el => (el.linkTo !== '/txn/agreementanddisclosures'));
                  return subD;
                });
            }
            this.disclosure = globalData.text.disclosureNotes;
            if (this.appStore.state.user.lob === 'cpic' || this.appStore.state.user.lob === 'wg') {
              this.disclosure2 = globalData.text.disclosureNotes2;
            }
            this.mobileWealthApp = globalData.text.getCIBCMobileWealthApp;
            this.setSiteMapData();
          }
        }));
      }
    }));
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let el = document.getElementById("ost-legal")
    if (el && el.style['display'] != 'none' && this.commonService.isDesktop()) {
      this.closeLegal();
    }
  }

  setSiteMapData() {
    if (this.router.url === '/txn/sitemap') {
      this.siteStore.setglobalFooterObject(this.pageContent.list.globalFooterLinks);
    }
  }

  isBuildInfoEnable() {
    return this._routePermissionService.enableBuildInfo;
  }

  getBuildInfo() {
    this.buildInfo_Client = 'cVersion: ' + buildInfo.num + ' tag: ' + buildInfo.tag;
    if (this.appStore.isApp('app')) {
      this.buildInfo_App = 'aVersion: ' + this.appStore.platformInfo.appVersion + '-'
        + this.appStore.platformInfo.revision;
      // + this.appStore.platformInfo.build + '-'
    }
    this.buildServerInfoAPI().subscribe(
      (data: any) => {
        if (data) {
          let sNum = data.num;
          if (sNum && sNum.length && sNum !== '<BUILD_NUMBER>') {
            sNum = sNum.substring(sNum.length - 8).toUpperCase();
          }
          this.buildInfo_Server = data.ServerId + ' [Release ' + data.Release + ']' + ' sVersion: ' + sNum;
        }
      },
      (err) => {
        console.error('Error : ' + err.message);
      });
  }

  buildServerInfoAPI() {
    return this.httpService.post(gatewayConfig.APIServices.getServerInfo.url, {}, { params: { skiploading: 'true' } });
  }
  openExternalLink(subLinks, modal?) {
    if (subLinks.external === 'Y') {
      if (subLinks.linkTo === 'MarketLens') {
        window.open(subLinks.href, '_system', 'location=yes');
      } else if (subLinks.linkTo === 'dco') {
        this.openDCOAppModal();
      } else {
        if (subLinks.tab) {
          if (this.isApp) {
            window.open(subLinks.linkTo, "_system");
          } else {
            window.open(subLinks.linkTo);
          }
        } else {
          window.open(subLinks.linkTo, '_system', 'location=yes, resizable=yes, scrollbars=yes');
        }
      }
    } else if (subLinks.modal === "Y") {
      if ((window as any).TrackingEnabled && subLinks.omni) {
        of(this.trackingService.tagInteraction('footer:' + subLinks.omni, false));
      }
      if (this.isApp && subLinks.modalAppAlt) {
        window.open(subLinks.modalAppAlt);
      } else {
        this.modalService.open(subLinks.linkTo, subLinks.target);
      }
    } else if(subLinks.oneTrust) {
      this.commonService.openOneTrustUI();
    } else {
      if (modal) {
        this.closeLegal();
      }
      if (subLinks.isApiCall) {
        this.router.navigate([subLinks.linkTo]);
      } else {
        this.commonService.callNonAPI(subLinks.linkTo);
      }
    }
  }
  getAppleForMobileWealthApp() {
    if (this.appStore.lang === Lang.FR) {
      window.open('https://apps.apple.com/ca/app/cibc-mobile-wealth/id441129412?l=fr', '_system', 'location=yes,scrollbars=yes');
    } else {
      window.open('https://apps.apple.com/ca/app/cibc-mobile-wealth/id441129412', '_system', 'location=yes,scrollbars=yes');
    }
  }
  getGooglePlayForMobileWealthApp() {
    if (this.appStore.lang === Lang.FR) {
      window.open('https://play.google.com/store/apps/details?id=com.mobilebrokerage.CIBC&hl=fr', '_system', 'location=yes,scrollbars=yes');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.mobilebrokerage.CIBC&hl=en', '_system', 'location=yes,scrollbars=yes');
    }
  }
  openDCOAppModal() {
    this.modalService.open('dcoApp', 'dcoAppModalHeader');
  }

  submitDCOApp() {
    this.commonService.goToDCOApplication().subscribe(
      (data) => {
        console.log("Success details", data);
        this.dcoFormSubmit(data);
      },
      (err) => {
        console.log("Error details", err);
        this.dcoFormSubmit();
      }
    )
  }
  dcoFormSubmit(data?) {
    this.modalService.close('dcoApp', 'dcoAppLink');
    let domain = "https://";
    if (window.location.host == 'm2.onlinebrokerage.cibc.com') {
      domain += 'm.onlinebrokerage.cibc.com';
    } else {
      domain += window.location.host;
    }
    const target = this.appStore.isApp() ? "_system" : "_self";
    const token = data?.DcoAcctOpenJwtToken;
    const lang = this.appStore.lang;
    let url = domain + "/dco/html/registerOnline.html#" + lang;
    if (token) {
      url = url + "/" + encodeURIComponent(token);
    }
    window.open(url, target);
    if (this.appStore.isApp('app')) {
      this.globalSignOffService.obr2DCOWrapperSignoff();
    } else if (this.appStore.isApp('inapp')) {
      this.globalSignOffService.signOff(true, true);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  closeDCOModal() {
    this.modalService.close('dcoApp', 'dcoAppLink');
  }

  isDesktop() {
    const val = this.commonService.isDesktop();
    return val;
  }

  isPermission(perm) {
    return this.commonService.checkPermissionForRoutes(perm);
  }

  closeLegal() {
    this.modalService.close("post-legal");
  }

  clickLink(url) {
    window.open(url, "_system");
  }

  openInNewTab(url, isInternal?) {
    if (isInternal) {
      this.router.navigate([url]);
    } else {
      window.open(url, "_blank");
    }
  }
  
}
