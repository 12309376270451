import { BaseData } from 'src/app/shared/models/base-data';

export class RegisterOnlineData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountToReg: null,
            firstName: null,
            lastName: null,
            address: null,
            suite: null,
            city: null,
            province: '',
            postalCode: null,

            selectedDate: null,
            custDate: undefined,
            dateMonth: -1,
            dateDay: 0,
            dateYear: 0
        };
    }
}

export interface RegisterOnlineInputs {
    accountToReg: number;
    firstName: string;
    lastName: string;
    address: string;
    suite: string;
    city: string;
    province: string;
    postalCode: string;

    selectedDate: any;
    custDate: any;
    dateMonth: number;
    dateDay: number;
    dateYear: number;
}
