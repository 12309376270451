<app-modal id="extendsession" [sessionExtension]="true" style="text-align: center; overflow-x: hidden; display: none;"
  [opacity]="1">
  <div class="cibc-session-extension-modal-body" id="session-extension-modal-new">
    <div class="imageIcon" id="image-icon">
      <img class="clockImage" src="assets/obr2/images/icons/Clock.svg" id='clockImage'
        alt="{{globalContent.text.sessionExtendAlt}}">
    </div>
    <div class="modal-header W20m color02">{{globalContent.text.stillhere}}</div>
    <div id="txn_endSessionInapp" class="modal-body A16 color03">
      {{isInApp ? globalContent.text.endSessionInApp : globalContent.text.endSession}}
    </div>
    <div class="modal-footer">
      <div>
        <button id="txn_appSignOffBtn" class="Pseudo-Button clearSessionButton A16u color02" (click)="signOff()">
          {{isInApp ? globalContent.text.signOutInApp : globalContent.text.signOut}}</button>
      </div>
      <div>
        <button id="txn_iamHereBtn" class="primary-button extendSessionButton W18m color01" (click)="extendSession()">
          {{globalContent.text.iamhere}}
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal id="signOffConfirm" style="text-align: center; overflow-x: hidden; display: none;"
  (closefn)="signOffConfirmResponse('no')">
  <div class="cibc-session-extension-modal-body">
    <div id="txn_modalHeaderAreYouSure" class="modal-header">{{globalContent.text.areYouSure}}</div>
    <div class="modal-footer">
      <div class="areYouSureSpacing">
        <button id="txn_signOffBtn" class="button secondary" (click)="signOffConfirmResponse('yes')">
          {{globalContent.text.signOff}}</button>
      </div>
      <div>
        <button id="signOffConfirmNo" class="button primary" (click)="signOffConfirmResponse('no')">
          {{globalContent.text.stay}}
        </button>
      </div>
    </div>
  </div>
</app-modal>

<div class="mainAppContainer" id="main-app-container" [stickyHeaderDirective]="'main-page'"
  [ngClass]="'page-lob-' + getLob()">
  <div id="page-container" class="pageContainer">
    <div id="focusElement" class="sr-only" tabindex="-1" [attr.aria-label]="pageTitleAnnoucement">
      {{pageTitleAnnoucement}}
    </div>
    <header id="global-header" *ngIf="this.isDesktopCurrently && enableHeaderAndFooter">
      <app-global-header
        [unreadNews]="whatsNewUnreadCards"></app-global-header>
    </header>
    <nav id="global-nav" class="globalNav">
      <app-navigation [openMenu]="isMenuOpen" (menuClicked)="openMenu()" (close)="closeMenu()"
        [showChevron]="showChevron" [unreadNews]="whatsNewUnreadCards">
      </app-navigation>
    </nav>
    <aside *ngIf="getLob() === LOB.IE && this.isDesktopCurrently && currentUrl !== '/txn/help' && globalContent.lob == LOB.IE">
      <app-sidebar-launcher></app-sidebar-launcher>
    </aside>
    <div *ngIf="getLob() == LOB.IE && isPermission('TRSTK') && showQuickTrade && currentUrl !== '/txn/help' ">
      <!-- <app-quick-trade-stocks></app-quick-trade-stocks> -->
      <!--My account intercept modal start-->
      <div *ngIf="showMyaccountInterceptModalFlag && openMyaccountInterceptModal">
        <app-modal [id]="'confirmAccInterceptModal' + uniqueId" (keydown.esc)="closeAccInterceptModal()"
          [header]="'confirmAccInterceptModalHeader' + uniqueId" [attr.id]="'confirmAccInterceptModal' + uniqueId"
          [moduleName]="'interceptModal'">
          <div class="acc-intercept-modal">
            <div class="acc-intercept-modal-layout">
              <button [attr.id]="'confirmAccInterceptModalClose' + uniqueId" type="button"
                (click)='closeAccInterceptModal()' class="acc-intercept-modal-close-button icon-Close"
                [attr.aria-label]="globalContent.text.whatsNewClose">
                <span class="sr-only">{{globalContent.text.accInterceptWindowClose}}</span>
              </button>
              <ng-container *ngIf="this.isDesktopCurrently; else smallQTInterceptor">
                <div class="acc-intercept-modal-big-container">
                  <div class="carousel-title">{{ globalContent.text.carouselTitle }}</div>
                  <carousel [carouselContent]="optionsTradingCarouselContent" [total]="2" [current]="1" [margin]="16"
                    [id]="'desktopCarousel'" (focusToSlideCard)="focusBacktoCard($event)" #optionsCard>
                    <div class="options-slides">
                      <div>
                        <div class="options">
                          <div class="flex-container">
                            <img draggable="false" style="height: 180px;"
                              src="assets/images/Phone-GlobalSearch-{{appStore.lang}}.png" alt="" />
                            <img draggable="false" style="height: 180px;"
                              src="assets/images/Phone-Discreet-{{appStore.lang}}.png" alt="" />
                          </div>
                          <div class="flex-container">
                            <div class="options-body">{{ globalContent.text.firstSlideLeftImage }}</div>
                            <div class="options-body">{{ globalContent.text.firstSlideRightImage }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="options-slides">
                      <div>
                        <div class="options">
                          <div class="flex-container">
                            <img draggable="false" style="height: 180px;"
                              src="assets/images/Phone-Nav-{{appStore.lang}}.png" alt="" />
                            <div class="QR-box">
                              <div class="QR-title">{{ globalContent.text.qrBoxTitle }}</div>
                              <div class="qrcodeImage">
                                <qr-code value={{qrCodeString}} size="88" errorCorrectionLevel="M" margin="4">
                                </qr-code>
                              </div>
                            </div>
                          </div>
                          <div class="flex-container">
                            <div class="options-body">{{ globalContent.text.secondSlideLeftImage }}</div>
                            <div class="options-body"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </carousel>
                </div>
              </ng-container>
              <ng-template #smallQTInterceptor>
                <div class="acc-intercept-modal-small-container">
                  <div class="options-carousel-cards">
                    <div class="carousel-title">{{ globalContent.text.carouselTitle }}</div>
                    <carousel [carouselContent]="optionsTradingCarouselContent" [total]="3" [current]="1" [margin]="16"
                      [id]="'mobileCarousel'" (focusToSlideCard)="focusBacktoCard($event)" #optionsCard>
                      <div class="options-slides">
                        <div class="options">
                          <img draggable="false" style="height: 194px;"
                            src="assets/images/Phone-GlobalSearch-{{appStore.lang}}.png" alt="" />
                          <div class="options-body"><span tabindex="-1">{{ globalContent.text.firstSlideLeftImage
                              }}</span></div>
                        </div>
                      </div>
                      <div class="options-slides">
                        <div class="options">
                          <img draggable="false" style="height: 194px;"
                            src="assets/images/Phone-Discreet-{{appStore.lang}}.png" alt="" />
                          <div class="options-body"><span tabindex="-1">{{ globalContent.text.firstSlideRightImage
                              }}</span></div>
                        </div>
                      </div>
                      <div class="options-slides">
                        <div class="options">
                          <img draggable="false" style="height: 194px;"
                            src="assets/images/Phone-Nav-{{appStore.lang}}.png" alt="" />
                          <div class="options-body"><span tabindex="-1">{{ globalContent.text.secondSlideLeftImage
                              }}</span></div>
                        </div>
                      </div>
                    </carousel>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </app-modal>
      </div>
      <!--My account intercept modal end-->

      <!--Commission Free intercept modal start-->
      <div *ngIf="showCommissionFreeInterceptModalFlag && openCommissionFreeInterceptModal && !isAnyApp">
        <app-modal [id]="'confirmCFInterceptModal' + uniqueId" (keydown.esc)="closeCFInterceptModal()"
          [header]="'confirmCFInterceptModalHeader' + uniqueId" [attr.id]="'confirmCFInterceptModal' + uniqueId"
          [moduleName]="'interceptModal'">
          <div class="acc-intercept-modal">
            <img [src]="globalContent.text.commissionFreeImage" name="ad-commissionFree" class="commissionFreeAd" alt="">
            <div class="acc-intercept-modal-layout cf-intercept-modal-layout">
              <button [attr.id]="'confirmCFInterceptModalClose' + uniqueId" type="button"
                (click)='closeCFInterceptModal()' class="acc-intercept-modal-close-button icon-Close"
                [attr.aria-label]="globalContent.text.whatsNewClose">
                <span class="sr-only">{{globalContent.text.accInterceptWindowClose}}</span>
              </button>
                <div class="acc-intercept-modal-big-container">
                  <div class="acc-intercept-modal-side">
                  <div class="cf-intercept-modal-title">{{ globalContent.text.CommissionFreeTitle }}</div>
                </div>
                  <div class="cf-label-content marginTop8" >
                    {{ globalContent.text.CFSubtitle }}
                  </div>

                </div>
                <div class="bottom-container">
                  <div class="button-container">
                    <button class="button_link" id="txn_link-op"
                      (click)="closeCFInterceptModal();whatsNewLink(globalContent.text.cfLinkURL , false, false)">{{ globalContent.text.startCFbutton }}
                      <span class="sr-only">{{ globalContent.text.startCFbuttonSR }}</span> </button>
                  </div>
                </div>
            </div>
          </div>
        </app-modal>
      </div>
      <!--Commission Free intercept modal end-->

      <!--Trade Ideas intercept modal start-->
      <div *ngIf="showTradeIdeasInterceptModalFlag && openTradeIdeasInterceptModal && !isAnyApp">
        <app-modal [id]="'confirmTIInterceptModal' + uniqueId" (keydown.esc)="closeTIInterceptModal()"
          [header]="'confirmTIInterceptModalHeader' + uniqueId" [attr.id]="'confirmTIInterceptModal' + uniqueId"
          [moduleName]="'interceptModal'">
          <div class="acc-intercept-modal">
            <img [src]="globalContent.text.tradeIdeasImage" name="ad-tradeIdeas" alt="" style="width: -webkit-fill-available; width: -moz-available;">
            <div class="acc-intercept-modal-layout ti-intercept-modal-layout">
              <button [attr.id]="'confirmTIInterceptModalClose' + uniqueId" type="button"
                (click)='closeTIInterceptModal()' class="acc-intercept-modal-close-button icon-Close"
                [attr.aria-label]="globalContent.text.whatsNewClose">
                <span class="sr-only">{{globalContent.text.accInterceptWindowClose}}</span>
              </button>
                <div class="acc-intercept-modal-big-container">
                  <div class="acc-intercept-modal-side">
                  <div class="acc-intercept-modal-title">{{ globalContent.text.OptionCarouselTitle }}</div>
                </div>
                  <ul  class="ul-Filled-check">
                    <li *ngFor="let listItem of globalContent.text.listItems" [innerHTML]="listItem" class="label-content">
                    </li>
                  </ul>
                  <div class="label-content marginTop8" >
                    {{ globalContent.text.subtitle }}
                  </div>

                </div>
                <div class="bottom-container">
                  <div class="button-container">
                    <button class="button_link" id="txn_link-op"
                      (click)="closeTIInterceptModal();whatsNewLink(globalContent.text.LinkURL , true, false)">{{
                      globalContent.text.startTIbutton }}
                      <span class="sr-only">{{ globalContent.text.startTIbuttonSR }}</span> </button>
                  </div>
                </div>
            </div>
          </div>
        </app-modal>
      </div>
      <!--Trade Ideas intercept modal end-->


      <!-- QUICK TRADE MARKETING MODAL START-->
      <div *ngIf="showInterceptModalFlag && openInterceptModal">
        <app-modal [id]="'confirmInterceptModal' + uniqueId" (keydown.esc)="closeInterceptModal()"
          [header]="'confirmInterceptModalHeader' + uniqueId" [attr.id]="'confirmInterceptModal' + uniqueId"
          [moduleName]="'interceptModal'">
          <div class="intercept-modal">
            <div [ngClass]="appStore.lang == 'en'?'intercept-modal-layout':'intercept-modal-layout-fr'">
              <div class='close-btn-container'>
                <button [attr.id]="'confirmInterceptModal' + uniqueId" type="button" (click)='closeInterceptModal()'
                  class="close-button icon-Close close-vector color03" id="txn_closeInterceptModalBtn"
                  [attr.aria-label]="globalContent.text.closeInterceptModal">
                  <span class="sr-only">{{globalContent.text.closeInterceptModal}}</span>
                </button>
              </div>
              <div [ngClass]="appStore.lang == 'en'?'content-layout':'content-layout-fr'">
                <div [ngClass]="appStore.lang == 'en'?'intercept-heading':'intercept-heading-fr'">
                  <div [ngClass]="appStore.lang == 'en'?'new-feature':'new-feature-fr'">
                    <div [ngClass]="appStore.lang == 'en'?'new-feature-flag':'new-feature-flag-fr'">
                      <div class="new-feature-image"><img alt="" src='assets/images/icons/star.svg' /></div>
                      <div id="txn_newFeature2"
                        [ngClass]="appStore.lang == 'en'?'new-feature-label':'new-feature-label-fr'">
                        {{globalContent.text.newfeature}}</div>
                    </div>
                    <div [ngClass]="appStore.lang == 'en'?'content-layout':'content-layout-fr'">
                      <div [ngClass]="appStore.lang == 'en'?'intercept-heading':'intercept-heading-fr'">
                        <div [ngClass]="appStore.lang == 'en'?'new-feature':'new-feature-fr'">
                          <div [ngClass]="appStore.lang == 'en'?'new-feature-flag':'new-feature-flag-fr'">
                            <div class="new-feature-image"><img alt="" src='assets/images/icons/star.svg' /></div>
                            <div id="txn_newFeature"
                              [ngClass]="appStore.lang == 'en'?'new-feature-label':'new-feature-label-fr'">
                              {{globalContent.text.newfeature}}</div>
                          </div>
                        </div>
                        <div id="txn_interceptModalHeading"
                          [ngClass]="appStore.lang == 'en'?'intercept-confirmation':'intercept-confirmation-fr'">
                          {{globalContent.text.interceptheading}}
                        </div>
                        <div [ngClass]="appStore.lang == 'en'?'intercept-content-body':'intercept-content-body-fr'">
                          <div [ngClass]="appStore.lang == 'en'?'intercept-content-list':'intercept-content-list-fr'">
                            <ul id="txn_interceptModal_content" class="tooltiplist">
                              <div [ngClass]="appStore.lang == 'en'?'content-spacing-line':'content-spacing-line1-fr'">
                                <li>{{globalContent.text.interceptcontent1}}</li>
                              </div>
                              <div [ngClass]="appStore.lang == 'en'?'content-spacing':'content-spacing-line2-fr'">
                                <li>{{globalContent.text.interceptcontent2}}</li>
                              </div>
                              <div [ngClass]="appStore.lang == 'en'?'content-spacing':'content-spacing-line3-fr'">
                                <li>{{globalContent.text.interceptcontent3}}</li>
                              </div>
                              <div [ngClass]="appStore.lang == 'fr'?'content-spacing-line4-fr':''">
                                <li>{{globalContent.text.interceptcontent4}}</li>
                              </div>
                            </ul>
                          </div>
                          <div id="txn_interceptModalImage2"
                            [ngClass]="appStore.lang == 'en'?'intercept-content-image':'intercept-content-image-fr'"
                            *ngIf="this.isDesktopCurrently ; else mobile">
                            <img *ngIf="appStore.lang == 'en'; else frImageDesktop"
                              src="assets/obr2/images/icons/QT-desktop-{{lang}}.gif" name="ad-quicktrade" alt=""
                              [ngClass]="appStore.lang == 'en'?'intercept-content-image':'intercept-content-image-fr'">
                            <ng-template #frImageDesktop>
                              <img src="assets/obr2/images/icons/QT-desktop-{{lang}}.gif" name="ad-quicktrade" alt=""
                                [ngClass]="appStore.lang == 'en'?'intercept-content-image':'intercept-content-image-fr'">
                            </ng-template>
                          </div>
                          <ng-template #mobile>
                            <div id="txn_interceptModalImage"
                              [ngClass]="appStore.lang == 'en'?'intercept-content-image':'intercept-content-image-fr'">
                              <img *ngIf="appStore.lang == 'en'; else frImageMobile"
                                src="assets/obr2/images/icons/QT-Mobi-{{lang}}.gif" name="ad-quicktrade" alt=""
                                [ngClass]="appStore.lang == 'en'?'intercept-content-image':'intercept-content-image-fr'">
                              <ng-template #frImageMobile>
                                <img src="assets/obr2/images/icons/QT-Mobi-{{lang}}.gif" name="ad-quicktrade" alt=""
                                  [ngClass]="appStore.lang == 'en'?'intercept-content-image':'intercept-content-image-fr'">
                              </ng-template>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div *ngIf="!this.isDesktopCurrently else desktopPageContainer" class="sticky-page-container">
                        <div [ngClass]="appStore.lang == 'en'?'try-btn-container':'try-btn-container-fr'">
                          <button [ngClass]="appStore.lang == 'en'?'try-btn-class':'try-btn-class-fr'"
                            class="button tryItNow" (click)="openQuickTrade()" id="txn_openQuickTradeBtn"
                            [attr.aria-label]="globalContent.text.openInterceptQuickTrade">
                            <span [ngClass]="appStore.lang == 'en'?'try-btn-label':'try-btn-label-fr'">
                              {{globalContent.text.tryItNow}}</span>
                            <span class="sr-only">{{globalContent.text.openInterceptQuickTrade}}</span>
                          </button>
                          <div>
                          </div>
                        </div>
                      </div>
                      <ng-template #desktopPageContainer>
                        <div [ngClass]="appStore.lang == 'en'?'try-btn-container':'try-btn-container-fr'">
                          <button [ngClass]="appStore.lang == 'en'?'try-btn-class':'try-btn-class-fr'"
                            class="button tryItNow" (click)="openQuickTrade()" id="txn_openInterceptModalBtn"
                            [attr.aria-label]="globalContent.text.openInterceptQuickTrade">
                            <span [ngClass]="appStore.lang == 'en'?'try-btn-label':'try-btn-label-fr'">
                              {{globalContent.text.tryItNow}}</span>
                            <span class="sr-only">{{globalContent.text.openInterceptQuickTrade}}</span>
                          </button>
                          <div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-modal>
      </div>
      <!-- QUICK TRADE MARKETING MODAL END-->
    </div>
    <!-- <div *ngIf="getLob() == LOB.IE && isPermission('TROPT') && showQuickTrade && currentUrl !== '/txn/help' ">
       <app-quick-trade-options></app-quick-trade-options>
     </div> -->
    <div id="page-content-container" class="pageContentContainer">
      <main [ngClass]="{'pageContentContainerIE': getLob() === LOB.IE}">
        <!-- <div *ngIf="isBoldChatEnabled() && !hideChat" id="chatContainer" hidden>
          <div id="chatToken" hidden></div>
          <div id="chatExpiration" hidden></div>
          <div id="chatExpirationOffset" hidden></div>
          <app-postsignon-chat *ngIf="!isApp" [currentPage]="currentPage()" [selectedLob]="selectedLob()">
          </app-postsignon-chat>
        </div> -->
        <router-outlet></router-outlet>
      </main>
    </div>
    <div class="page-container-spacer"></div>
    <footer id="global-footer" class="footer-container footer-container-{{getLob()}}" *ngIf="enableHeaderAndFooter">
      <app-global-footer></app-global-footer>
    </footer>
  </div>
</div>

<app-modal [attr.id]="'HeaderNewsModal'" [id]="'HeaderNewsModal'"
  style='display: none;' (keydown.esc)="closeNews()" targetElement="headerNewsLink" [internalOverflow]="true"
  [moduleName]="'whatsNewModal'" header="header-news-modal-title">
  <div class="header-news-modal-body">
    <div class="header-news-modal-head">
      <div id="header-news-modal-title" tabindex="-1">
        {{globalContent.text.whatsNew}}
      </div>
      <button id="txn_whatsnewcloseBtn" type="button" (click)="closeNews()" class="icon-Close"
        [attr.aria-label]="globalContent.text.whatsNewClose"></button>
    </div>
    <div class="header-news-modal-options">
      <ng-container *ngFor="let newsItem of currentWhatsNewContentCards; let i = index">
        <ng-container *ngIf="(newsItem.uniqueDate == '08232024' && dispFXMarketing) || newsItem.uniqueDate != '08232024'">
        <button type="button"
          class="header-news-modal-option" (click)="readNews(i)" [attr.id]="'header-news-modal-option-' + i"
          [ngClass]="(activeNewsItem == i ? 'active ' : '') + (newsItem.viewed ? 'read' : '')"
          attr.aria-expanded="{{activeNewsItem == i}}">
          <img [src]="newsItem.extras.iconImage" alt="" aria-hidden="true" class="header-news-modal-option-icon" />
          <div id="txn_header_newsModal_option_title-{{i}}" class="header-news-modal-option-title">
            <h1>{{newsItem.description}}</h1>
          </div>
          <span class="sr-only">{{globalContent.text.whatsNewItemAria}}</span>
        </button>
      </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="header-news-modal-body-2">
    <div class="header-news-modal-head">
      <button id="headerNewsModalDrawerButton" type="button" (click)="closeNewsItem()" class="icon-Minimize2"
        [attr.aria-label]="globalContent.text.whatsNewClose"></button>
    </div>
    <div class="header-news-modal-body-scroll" *ngIf="activeNewsItem > -1">
      <div *ngIf="currentWhatsNewContentCards[activeNewsItem].extras.bodyIconImage || currentWhatsNewContentCards[activeNewsItem].extras.bodyTitle">
        <div class="modalBodyTitleContainer">
          <img  *ngIf="currentWhatsNewContentCards[activeNewsItem].extras.bodyIconImage" [src]="currentWhatsNewContentCards[activeNewsItem].extras.bodyIconImage" alt="" aria-hidden="true" class="header-news-modal-body-option-icon" />
          <div class="bodyTitle" *ngIf="currentWhatsNewContentCards[activeNewsItem].extras.bodyTitle">
            {{currentWhatsNewContentCards[activeNewsItem].extras.bodyTitle}}
          </div>
        </div>
      </div>
      <img class="header-news-modal-normal-image" *ngIf="currentWhatsNewContentCards[activeNewsItem].imageUrl" [src]="currentWhatsNewContentCards[activeNewsItem].imageUrl" alt="" aria-hidden="true" />
      <div class="header-news-modal-normal-container">
        <h2>{{currentWhatsNewContentCards[activeNewsItem].description}}</h2>
        <ng-container *ngFor="let section of currentWhatsNewContentCards[activeNewsItem].extras.sections">
          <ng-container *ngIf="section">
            <h3 *ngIf="section.Heading" [innerHtml]="section.Heading"></h3>
            <ul *ngIf="section.list1">
              <li *ngFor="let item of section.list1" [innerHtml]="item"></li>
            </ul>
            <p *ngIf="section.Body" [innerHtml]="section.Body"></p>
            <p *ngIf="section.Link1Text">
              <span *ngIf="section.Link1PreText" [innerHtml]="section.Link1PreText + '&nbsp;'"></span>
              <a *ngIf="section.Link1URL" (click)="whatsNewLink(section.Link1URL, currentWhatsNewContentCards[activeNewsItem], section.Link1ScrollTo)" href="javascript:void(0);">
                <span [innerHtml]="section.Link1Text"></span>
                <span *ngIf="!section.Link1URL.startsWith('#') && !section.Link1URL.startsWith('/')" class="sr-only">{{globalContent.text.newWindowText}}</span>
                <span *ngIf="section.Link1URL.startsWith('#') || section.Link1URL.startsWith('/')" class="sr-only">{{globalContent.text.sameWindowText}}</span>
              </a>
              <span *ngIf="section.Link1PostText" [innerHtml]="'&nbsp;' + section.Link1PostText"></span>
            </p>
            <ul *ngIf="section.list2">
              <li *ngFor="let item of section.list2" [innerHtml]="item"></li>
            </ul>
            <p *ngIf="section.Link2Text">
              <span *ngIf="section.Link2PreText" [innerHtml]="section.Link2PreText + '&nbsp;'"></span>
              <a (click)="whatsNewLink(section.Link2URL, currentWhatsNewContentCards[activeNewsItem], section.Link2ScrollTo)" href="javascript:void(0);">
                <span [innerHtml]="section.Link2Text"></span>
                <span *ngIf="!section.Link2URL.startsWith('#') && !section.Link2URL.startsWith('/')" class="sr-only">{{globalContent.text.newWindowText}}</span>
                <span *ngIf="section.Link2URL.startsWith('#') || section.Link2URL.startsWith('/')" class="sr-only">{{globalContent.text.sameWindowText}}</span>
              </a>
              <span *ngIf="section.Link2PostText" [innerHtml]="'&nbsp;' + section.Link2PostText"></span>
            </p>
          </ng-container>
        </ng-container>
        <div *ngIf="currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonText || currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonText"
          class="header-news-modal-normal-button-container">
          <button *ngIf="currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonText" class="button primary" type="button" 
            (click)="whatsNewLink(currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonURL, currentWhatsNewContentCards[activeNewsItem], 
              currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonScrollTo)">
            {{currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonText}}
            <span *ngIf="!currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonURL.startsWith('#') && !currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonURL.startsWith('/')" class="sr-only">{{globalContent.text.newWindowText}}</span>
            <span *ngIf="currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonURL.startsWith('#') || currentWhatsNewContentCards[activeNewsItem].extras.primaryButtonURL.startsWith('/')" class="sr-only">{{globalContent.text.sameWindowText}}</span>
          </button>
          <button *ngIf="currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonText" class="button secondary" type="button" 
            (click)="whatsNewLink(currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonURL, currentWhatsNewContentCards[activeNewsItem], 
              currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonScrollTo)">
            {{currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonText}}
            <span *ngIf="!currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonURL.startsWith('#') && !currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonURL.startsWith('/')" class="sr-only">{{globalContent.text.newWindowText}}</span>
            <span *ngIf="currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonURL.startsWith('#') || currentWhatsNewContentCards[activeNewsItem].extras.secondaryButtonURL.startsWith('/')" class="sr-only">{{globalContent.text.sameWindowText}}</span>
          </button>
        </div>
        <ng-container *ngFor="let link of currentWhatsNewContentCards[activeNewsItem].extras.bottomLinks">
          <ng-container *ngIf="link">
            <div class="header-news-modal-normal-bottom-link">
              <span *ngIf="link.PreText" [innerHtml]="link.PreText + '&nbsp;'"></span>
              <a (click)="whatsNewLink(link.URL, currentWhatsNewContentCards[activeNewsItem], link.ScrollTo)" href="javascript:void(0);">
                <span [innerHtml]="link.Text"></span>
                <span *ngIf="!link.URL.startsWith('#') && !link.URL.startsWith('/')" class="sr-only">{{globalContent.text.newWindowText}}</span>
                <span *ngIf="link.URL.startsWith('#') || link.URL.startsWith('/')" class="sr-only">{{globalContent.text.sameWindowText}}</span>
              </a>
              <span *ngIf="link.PostText" [innerHtml]="'&nbsp;' + link.PostText"></span>
            </div>
          </ng-container>
        </ng-container>
     
     
      <a href="javascript:void(0);" class="sr-only sr-only-focusable" id="txn_whatsnewLink"
        (click)="whatsNewSkipLink()">{{globalContent.text.whatsNewSkip}}</a>
    </div>
  </div>
  </div>
</app-modal>
<!-- adding "signup newsletter modal" start -->
<app-modal *ngIf="!isApp" (keydown.esc)="closeSignupNewsletterModal()"
  [attr.id]="'signupNewsletterModal'" [id]="'signupNewsletterModal'" style='display: none;'>
  <div class="signup-newsletter-modal-body">
    <div class="signup-newsletter-modal-container">
      <button type="button" class="signup-newsletter-modal-close-button icon-Close color03" id="txn_modal_closeBtn"
        (click)="closeSignupNewsletterModal()">
        <span class="sr-only">close</span>
      </button>
      <div id="txn-iframe-container" class="iframe-modal-container">
        <iframe *ngIf="this.lang ==='en'; else fr_link" class="iframe-modal"
          sandbox="allow-scripts allow-popups allow-presentation allow-same-origin allow-forms"
          src='https://newsletters.tradingcentral.com/cibc/newsletter_signup.aspx'></iframe>
        <ng-template #fr_link>
          <iframe class="iframe-modal"
            sandbox="allow-scripts allow-popups allow-presentation allow-same-origin allow-forms"
            src='https://newsletters.tradingcentral.com/cibc/newsletter_signup.aspx?lang=fr-fr'></iframe>
        </ng-template>
      </div>
    </div>
  </div>
</app-modal>
<!-- adding "signup newsletter modal" end -->