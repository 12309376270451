import { Injectable } from '@angular/core';
import { SellStructNotesLandingData } from './sell-struct-notes-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SellStructNotesLandingStore extends PageStore<SellStructNotesLandingData> {

  constructor() {
    super(new SellStructNotesLandingData());
  }
}
