import { BaseData } from 'src/app/shared/models/base-data';

export class UpdatePasswordLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            newSignOnPassword : null,
            reEnteredSignOnPassword: null,
            passOption: '',
            newTradingPassword: null,
            reEnteredTradingPassword: null
        };
    }
}

export interface UpdatePasswordLandingInputs {
    newSignOnPassword: string;
    reEnteredSignOnPassword: string;
    passOption:string;
    newTradingPassword: string;
    reEnteredTradingPassword: string;
}