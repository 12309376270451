import { AccountDetailsStore } from "../modules/txn/accounts/account-details/data/account-details-store";
import { AccountHoldingService } from "../modules/txn/accounts/account-holdings/service/account-holdings.service";
import { AccountHoldingsStore } from "../modules/txn/accounts/account-holdings/data/account-holdings-store";
import { AccountInfoService } from "../modules/txn/accounts/account-info/service/accounts-info.service";
import { AccountInfoStore } from "../modules/txn/accounts/account-info/data/account-info-store";
import { AgreementAndDisclosuresStore } from "../modules/txn/agreements-and-disclosures/data/agreements-and-disclosures-store";
import { BroadcastService } from "../modules/txn/broadcast/services/broadcast.service";
import { BroadcastStore } from "../modules/txn/broadcast/data/broadcast-store";
import { BuyBondsConfirmStore } from "../modules/txn/fixed-income/buyBonds/confirm/data/buy-bonds-confirm-store";
import { BuyBondsOrderStore } from "../modules/txn/fixed-income/buyBonds/order/data/buy-bonds-order-store";
import { BuyBondsSearchStore } from "../modules/txn/fixed-income/buyBonds/search/data/buy-bonds-search-store";
import { BuyBondsVerifyStore } from "../modules/txn/fixed-income/buyBonds/verify/data/buy-bonds-verify-store";
import { BuyGICConfirmStore } from "../modules/txn/fixed-income/buyGics/confirm/data/buy-gic-confirm-store";
import { BuyGicLandingStore } from "../modules/txn/fixed-income/buyGics/landing/data/buy-gic-landing-store";
import { BuyGICOrderStore } from "../modules/txn/fixed-income/buyGics/order/data/buy-gic-order-store";
import { BuyGICVerifyStore } from "../modules/txn/fixed-income/buyGics/verify/data/buy-gic-verify-store";
import { CancelCashPlanConfirmStore } from '../modules/txn/regular-investment-plan/cancel-cash-plan/confirm/data/cancel-cash-plan-confirm-store';
import { CancelCashPlanLandingStore } from '../modules/txn/regular-investment-plan/cancel-cash-plan/landing/data/cancel-cash-plan-landing-store';
import { CancelMutualFundPlanConfirmStore } from "../modules/txn/regular-investment-plan/cancel-mutual-fund-plan/confirm/data/cancel-mutual-fund-plan-confirm-store";
import { CancelMutualFundPlanLandingStore } from '../modules/txn/regular-investment-plan/cancel-mutual-fund-plan/landing/data/cancel-mutual-fund-plan-landing-store';
import { CashtransferConfirmService } from "../modules/txn/cashtransfer/confirm/services/cashtransfer-confirm.service";
import { CashtransferConfirmStore } from "../modules/txn/cashtransfer/confirm/data/cashtransfer-confirm-store";
import { CashtransferLandingService } from "../modules/txn/cashtransfer/landing/services/cashtransfer-landing.service";
import { CashtransferLandingStore } from "../modules/txn/cashtransfer/landing/data/cashtransfer-landing-store";
import { CashtransferVerifyService } from "../modules/txn/cashtransfer/verify/services/cashtransfer-verify.service";
import { CashtransferVerifyStore } from "../modules/txn/cashtransfer/verify/data/cashtransfer-verify-store";
import { ExchangeCurrencyService } from "../modules/txn/exchange-currency/services/exchange-currency.service";
import { ExchangeCurrencyStore } from "../modules/txn/exchange-currency/data/exchange-currency-store";
import { CegAgreementService } from "../modules/txn/userPreferences/exchangeAgreements/cegAgreement/services/ceg-agreement.service";
import { CegAgreementStore } from "../modules/txn/userPreferences/exchangeAgreements/cegAgreement/data/ceg-agreement.store";
import { ChangeMailingOptionsConfirmService } from "../modules/txn/userPreferences/changeMailingOptions/confirm/services/change-mailing-options-confirm.service";
import { ChangeMailingOptionsConfirmStore } from "../modules/txn/userPreferences/changeMailingOptions/confirm/data/change-mailing-options-confirm.store";
import { ChangeMailingOptionsLandingService } from "../modules/txn/userPreferences/changeMailingOptions/landing/services/change-mailing-options-landing.service";
import { ChangeMailingOptionsLandingStore } from "../modules/txn/userPreferences/changeMailingOptions/landing/data/change-mailing-options-landing.store";
import { ChangeMailingOptionsVerifyService } from "../modules/txn/userPreferences/changeMailingOptions/verify/services/change-mailing-options-verify.service";
import { ChangeMailingOptionsVerifyStore } from "../modules/txn/userPreferences/changeMailingOptions/verify/data/change-mailing-options-verify.store";
import { ChangeSignonPassConfirmService } from "../modules/pre-txn/forgotpassword/change-signon-pass-confirm/services/change-signon-pass-confirm.service";
import { ChangeSignonPassConfirmStore } from "../modules/pre-txn/forgotpassword/change-signon-pass-confirm/data/change-signon-pass-confirm.store";
import { ChangeSignonPassService } from "../modules/pre-txn/forgotpassword/changesignonpass/services/change-signon-pass.service";
import { ChangeSignonPassStore } from "../modules/pre-txn/forgotpassword/changesignonpass/data/change-signon-pass-store";
import { ChangeSignOnPasswordConfirmService } from "../modules/txn/userPreferences/changeSignOnPassword/confirm/services/change-signon-password-confirm.service";
import { ChangeSignOnPasswordConfirmStore } from "../modules/txn/userPreferences/changeSignOnPassword/confirm/data/change-signon-password-confirm.store";
import { ChangeSignOnPasswordLandingService } from "../modules/txn/userPreferences/changeSignOnPassword/landing/services/change-signon-password-landing.service";
import { ChangeSignOnPasswordLandingStore } from "../modules/txn/userPreferences/changeSignOnPassword/landing/data/change-signon-password-landing.store";
import { ChangeTradingPasswordConfirmService } from "../modules/txn/userPreferences/changeTradingPassword/confirm/services/change-trading-password-confirm.service";
import { ChangeTradingPasswordConfirmStore } from "../modules/txn/userPreferences/changeTradingPassword/confirm/data/change-trading-password-confirm.store";
import { ChangeTradingPasswordLandingService } from "../modules/txn/userPreferences/changeTradingPassword/landing/services/change-trading-password-landing.service";
import { ChangeTradingPasswordLandingStore } from "../modules/txn/userPreferences/changeTradingPassword/landing/data/change-trading-password-landing.store";
import { ChangeUserIDConfirmService } from "../modules/txn/userPreferences/changeUserID/confirm/services/change-userid-confirm.service";
import { ChangeUserIDConfirmStore } from "../modules/txn/userPreferences/changeUserID/confirm/data/change-userid-confirm.store";
import { ChangeUserIDLandingService } from "../modules/txn/userPreferences/changeUserID/landing/services/change-userid-landing.service";
import { ChangeUserIDLandingStore } from "../modules/txn/userPreferences/changeUserID/landing/data/change-userid-landing.store";
import { ConnectCIBCOnlineConfirmService } from "../modules/txn/userPreferences/connect-cibc-online/confirm/services/connect-cibc-online-confirm.service";
import { ConnectCIBCOnlineConfirmStore } from "../modules/txn/userPreferences/connect-cibc-online/confirm/data/connect-cibc-online-confirm.store";
import { ConnectCIBCOnlineLandingService } from "../modules/txn/userPreferences/connect-cibc-online/landing/services/connect-cibc-online-landing.service";
import { ConnectCIBCOnlineLandingStore } from "../modules/txn/userPreferences/connect-cibc-online/landing/data/connect-cibc-online-landing.store";
import { ConnectCIBCOnlineVerifyService } from "../modules/txn/userPreferences/connect-cibc-online/verify/services/connect-cibc-online-verify.service";
import { ConnectCIBCOnlineVerifyStore } from "../modules/txn/userPreferences/connect-cibc-online/verify/data/connect-cibc-online-verify.store";
import { ContactUsStore } from "../modules/txn/contact-us/data/contact-us-store";
import { ContentStore } from "../shared/store/content-store";
import { CreateCashPlanConfirmStore } from "../modules/txn/regular-investment-plan/create-cash-plan/confirm/data/create-cash-plan-confirm-store";
import { CreateCashPlanLandingStore } from "../modules/txn/regular-investment-plan/create-cash-plan/landing/data/create-cash-plan-landing-store";
import { CreateCashPlanVerifyStore } from "../modules/txn/regular-investment-plan/create-cash-plan/verify/data/create-cash-plan-verify-store";
import { ChangeCashPlanVerifyStore } from "../modules/txn/regular-investment-plan/change-cash-plan/verify/data/change-cash-plan-verify-store";
import { CreateMutualFundLandingStore } from "../modules/txn/regular-investment-plan/create-mutual-fund-plan/landing/data/create-mutual-fund-landing-store";
import { CreateMutualFundVerifyStore } from "../modules/txn/regular-investment-plan/create-mutual-fund-plan/verify/data/create-mutual-fund-verify-store";
import { CreateMutualFundConfirmStore } from "../modules/txn/regular-investment-plan/create-mutual-fund-plan/confirm/data/create-mutual-fund-confirm-store";
import { CreatePasswordService } from "../modules/pre-txn/register-online/createpassword/services/create-password.service";
import { CreatePasswordStore } from "../modules/pre-txn/register-online/createpassword/data/create-password-store";
import { CreateSignonPassConfirmService } from "../modules/pre-txn/create-signon-pass/confirm/services/create-signon-pass-confirm.service";
import { CreateSignonPassConfirmStore } from "../modules/pre-txn/create-signon-pass/confirm/data/create-signon-pass-confirm.store";
import { CreateSignonPassService } from "../modules/pre-txn/create-signon-pass/landing/services/create-signon-pass.service";
import { CreateSignonPassStore } from "../modules/pre-txn/create-signon-pass/landing/data/create-signon-pass-store";
import { CreateSignonTradingPassConfirmService } from "../modules/pre-txn/create-signon-trading-pass/confirm/services/create-signon-trading-pass-confirm.service";
import { CreateSignonTradingPassConfirmStore } from "../modules/pre-txn/create-signon-trading-pass/confirm/data/create-signon-trading-pass-confirm.store";
import { CreateSignonTradingPassService } from "../modules/pre-txn/create-signon-trading-pass/landing/services/create-signon-trading-pass.service";
import { CreateSignonTradingPassStore } from "../modules/pre-txn/create-signon-trading-pass/landing/data/create-signon-trading-pass-store";
import { CreateTradingPassConfirmService } from "../modules/pre-txn/create-trading-pass/confirm/services/create-trading-pass-confirm.service";
import { CreateTradingPassConfirmStore } from "../modules/pre-txn/create-trading-pass/confirm/data/create-trading-pass-confirm.store";
import { CreateTradingPassService } from "../modules/pre-txn/create-trading-pass/landing/services/create-trading-pass.service";
import { CreateTradingPassStore } from "../modules/pre-txn/create-trading-pass/landing/data/create-trading-pass-store";
import { CreditPolicyStore } from "../modules/txn/creditPolicy/data/credit-policy-store";
import { EdocumentsStore } from "../modules/txn/edocuments/data/edocuments-store";
import { ElectronicAccessAgreementService } from "../modules/pre-txn/electronic-access-agreement/services/electronic-access-agreement.service";
import { ElectronicAccessAgreementStore } from "../modules/pre-txn/electronic-access-agreement/data/electronic-access-agreement-store";
import { ExchangeAgreementsLandingService } from "../modules/txn/userPreferences/exchangeAgreements/landing/services/exchange-agreements-landing.service";
import { ExchangeAgreementsLandingStore } from "../modules/txn/userPreferences/exchangeAgreements/landing/data/exchange-agreements-landing.store";
import { FeesCommissionStore } from "../modules/txn/fees-commission/data/fees-commission-store";
import { FixedIncomeStore } from "./../modules/txn/fixed-income/data/fixed-income-store";
import { ForgotSignonPassService } from "../modules/pre-txn/forgotpassword/forgotsignonpass/services/forgot-signon-pass.service";
import { ForgotSignonPassStore } from "../modules/pre-txn/forgotpassword/forgotsignonpass/data/forgot-signon-pass-store";
import { ForgotTradingPasswordConfirmService } from "../modules/txn/userPreferences/forgotTradingPassword/confirm/services/forgot-trading-password-confirm.service";
import { ForgotTradingPasswordConfirmStore } from "../modules/txn/userPreferences/forgotTradingPassword/confirm/data/forgot-trading-password-confirm.store";
import { ForgotTradingPasswordLandingService } from "../modules/txn/userPreferences/forgotTradingPassword/landing/services/forgot-trading-password-landing.service";
import { ForgotTradingPasswordLandingStore } from "../modules/txn/userPreferences/forgotTradingPassword/landing/data/forgot-trading-password-landing.store";
import { GenericStore } from "../shared/store/generic-store";
import { GlobalContentStore } from "../shared/store/global-content-store";
import { HelpService } from "../modules/txn/help/services/help.service";
import { HelpStore } from "../modules/txn/help/data/help-store";
import { HomeService } from "src/app/modules/pre-txn/home/services/home.service";
import { IposAndNewIssuesStore } from "../modules/txn/ipos-and-newissues/data/ipos-and-newissues-store";
import { MarketsDetailService } from "../modules/txn/markets/details/services/markets-detail.service";
import { MarketsDetailStore } from "../modules/txn/markets/details/data/markets-detail-store";
import { MarketsLandingService } from "../modules/txn/markets/landing/services/markets-landing.service";
import { MarketsLandingStore } from "../modules/txn/markets/landing/data/markets-landing-store";
import { MutualFundsConfirmStore } from "../modules/txn/mutualFunds/confirm/data/mutual-funds-confirm-store";
import { MutualFundsLandingStore } from "../modules/txn/mutualFunds/landing/data/mutual-funds-landing-store";
import { MutualFundsSearchStore } from "../modules/txn/mutualFunds/search/data/mutual-funds-search-store";
import { MutualFundsVerifyStore } from "../modules/txn/mutualFunds/verify/data/mutual-funds-verify-store";
import { NasdaqAgreementService } from "../modules/txn/userPreferences/exchangeAgreements/nasdaqAgreement/services/nasdaq-agreement.service";
import { NasdaqAgreementStore } from "../modules/txn/userPreferences/exchangeAgreements/nasdaqAgreement/data/nasdaq-agreement.store";
import { NewsAndNotesStore } from "../modules/txn/accounts/news-and-notes/data/news-and-notes-store";
import { NicknameAccountsConfirmService } from "../modules/txn/userPreferences/nicknameAccounts/confirm/services/nickname-accounts-confirm.service";
import { NicknameAccountsConfirmStore } from "../modules/txn/userPreferences/nicknameAccounts/confirm/data/nickname-accounts-confirm.store";
import { NicknameAccountsLandingService } from "../modules/txn/userPreferences/nicknameAccounts/landing/services/nickname-accounts-landing.service";
import { NicknameAccountsLandingStore } from "../modules/txn/userPreferences/nicknameAccounts/landing/data/nickname-accounts-landing.store";
import { NyAgreementService } from "../modules/txn/userPreferences/exchangeAgreements/nyAgreement/services/ny-agreement.service";
import { NyAgreementStore } from "../modules/txn/userPreferences/exchangeAgreements/nyAgreement/data/ny-agreement.store";
import { OpraAgreementService } from "../modules/txn/userPreferences/exchangeAgreements/opraAgreement/services/opra-agreement.service";
import { OpraAgreementStore } from "../modules/txn/userPreferences/exchangeAgreements/opraAgreement/data/opra-agreement.store";
import { OrderStatusService } from "../modules/txn/orderStatus/landing/services/order-status.service";
import { OrderStatusStore } from "../modules/txn/orderStatus/landing/data/order-status-store";
import { OtvcConfirmService } from "../modules/txn/otvc/confirm/services/otvc-confirm.service";
import { OtvcConfirmStore } from "../modules/txn/otvc/confirm/data/otvc-confirm.store";
import { OtvcSecurityService } from "../modules/txn/otvc/otvc-security/services/otvc-security.service";
import { OtvcSecurityStore } from "../modules/txn/otvc/otvc-security/data/otvc-security-store";
import { PerformanceReportingStore } from "../modules/txn/performanceReporting/data/performance-reporting-store";
import { QuotesDetailService } from "../modules/txn/quotesWatchList/quoteDetails/services/quotes-detail.service";
import { QuotesDetailStore } from "../modules/txn/quotesWatchList/quoteDetails/data/quotes-detail-store";
import { QuotesResearchBridgeService } from "../modules/txn/quotesResearch/bridge/services/quotes-research-bridge.service";
import { QuotesResearchBridgeStore } from "../modules/txn/quotesResearch/bridge/data/quotes-research-bridge-store";
import { QuotesResearchService } from "../modules/txn/quotesResearch/services/quotes-research.service";
import { QuotesResearchStore } from "../modules/txn/quotesResearch/data/quotes-research-store";
import { QuotesResearchTechnicalInsightsService } from "../modules/txn/quotesResearch/technicalInsights/services/quotes-research-technical-insights.service";
import { QuotesResearchTechnicalInsightsStore } from "../modules/txn/quotesResearch/technicalInsights/data/quotes-research-technical-insights-store";
import { QuotesResearchStrategyBuilderService } from "../modules/txn/quotesResearch/strategyBuilder/services/quotes-research-strategy-builder.service";
import { QuotesResearchStrategyBuilderStore } from "../modules/txn/quotesResearch/strategyBuilder/data/quotes-research-strategy-builder-store";
import { QuotesWatchListLandingStore } from "../modules/txn/quotesWatchList/landing/data/quotes-watchlist-landing-store";
import { QuotesWatchListService } from "../modules/txn/quotesWatchList/landing/services/quotes-watchlist.service";
import { RegisterOnlineConfirmService } from "../modules/pre-txn/register-online/confirm/services/register-online-confirm.service";
import { RegisterOnlineConfirmStore } from "../modules/pre-txn/register-online/confirm/data/register-online-confirm.store";
import { RegisterOnlineService } from "../modules/pre-txn/register-online/landing/services/register-online.service";
import { RegisterOnlineStore } from "../modules/pre-txn/register-online/landing/data/register-online-store";
import { RegularInvestmentPlanDetailsStore } from "../modules/txn/regular-investment-plan/details/data/regular-investment-plan-details-store";
import { RegularInvestmentPlanStore } from "../modules/txn/regular-investment-plan/data/regular-investment-plan-store";
import { RelateBrokerageAccountsConfirmService } from "../modules/txn/userPreferences/relateBrokerageAccounts/confirm/services/relate-brokerage-accounts-confirm.service";
import { RelateBrokerageAccountsConfirmStore } from "../modules/txn/userPreferences/relateBrokerageAccounts/confirm/data/relate-brokerage-accounts-confirm.store";
import { RelateBrokerageAccountsLandingService } from "../modules/txn/userPreferences/relateBrokerageAccounts/landing/services/relate-brokerage-accounts-landing.service";
import { RelateBrokerageAccountsLandingStore } from "../modules/txn/userPreferences/relateBrokerageAccounts/landing/data/relate-brokerage-accounts-landing.store";
import { RemoveBrokerageAccountsConfirmService } from "../modules/txn/userPreferences/removeBrokerageAccounts/confirm/services/remove-brokerage-accounts-confirm.service";
import { RemoveBrokerageAccountsConfirmStore } from "../modules/txn/userPreferences/removeBrokerageAccounts/confirm/data/remove-brokerage-accounts-confirm.store";
import { RemoveBrokerageAccountsLandingService } from "../modules/txn/userPreferences/removeBrokerageAccounts/landing/services/remove-brokerage-accounts-landing.service";
import { RemoveBrokerageAccountsLandingStore } from "../modules/txn/userPreferences/removeBrokerageAccounts/landing/data/remove-brokerage-accounts-landing.store";
import { RemoveBrokerageAccountsVerifyService } from "../modules/txn/userPreferences/removeBrokerageAccounts/verify/services/remove-brokerage-accounts-verify.service";
import { RemoveBrokerageAccountsVerifyStore } from "../modules/txn/userPreferences/removeBrokerageAccounts/verify/data/remove-brokerage-accounts-verify.store";
import { SellBondConfirmStore } from "../modules/txn/fixed-income/sell-bond/confirm/data/sell-bond-confirm-store";
import { SellBondLandingStore } from "../modules/txn/fixed-income/sell-bond/landing/data/sell-bond-landing-store";
import { SellBondVerifyStore } from "../modules/txn/fixed-income/sell-bond/verify/data/sell-bond-verify-store";
import { SellFixedIncomeStore } from "../modules/txn/fixed-income/sell-fixed-income/data/sell-fixed-income-store";
import { SellGicConfirmStore } from "../modules/txn/fixed-income/sell-gic/confirm/data/sell-gic-confirm-store";
import { SellGicLandingStore } from "../modules/txn/fixed-income/sell-gic/landing/data/sell-gic-landing-store";
import { SellGicVerifyStore } from "../modules/txn/fixed-income/sell-gic/verify/data/sell-gic-verify-store";
import { SellStructNotesConfirmStore } from "../modules/txn/structured-notes/sell-structured-notes/confirm/data/sell-struct-notes-confirm-store";
import { SellStructNotesLandingStore } from "../modules/txn/structured-notes/sell-structured-notes/landing/data/sell-struct-notes-landing-store";
import { SellStructNotesVerifyStore } from "../modules/txn/structured-notes/sell-structured-notes/verify/data/sell-struct-notes-verify-store";
import { SessionEndStore } from "../modules/pre-txn/session-end/data/session-end-store";
import { OLBErrorStore } from "../modules/pre-txn/olb-error/data/olb-error-store";
import { NewUserStore } from "../modules/pre-txn/new-user/data/new-user-store";
import { SetUpTradeFillAlertsConfirmService } from "../modules/txn/userPreferences/setUpTradeFillAlerts/confirm/services/setup-trade-fill-alerts-confirm.service";
import { SetUpTradeFillAlertsConfirmStore } from "../modules/txn/userPreferences/setUpTradeFillAlerts/confirm/data/setup-trade-fill-alerts-confirm.store";
import { SetUpTradeFillAlertsLandingService } from "../modules/txn/userPreferences/setUpTradeFillAlerts/landing/services/setup-trade-fill-alerts-landing.service";
import { SetUpTradeFillAlertsLandingStore } from "../modules/txn/userPreferences/setUpTradeFillAlerts/landing/data/setup-trade-fill-alerts-landing.store";
import { SignonService } from "../modules/pre-txn/signon/services/signon.service";
import { SignonStore } from "../modules/pre-txn/signon/data/signon-store";
import { SiteMapStore } from "../modules/txn/siteMap/data/site-map-store";
import { SplashService } from "../modules/txn/splash/services/splash.service";
import { SplashStore } from "../modules/txn/splash/data/splash-store";
import { SSORoutingHandlerService } from "../modules/pre-txn/sso-routing-handler/services/sso-routing-handler.service";
import { SSORoutingHandlerStore } from "../modules/pre-txn/sso-routing-handler/data/sso-routing-handler.store";
import { SSOSetupService } from "../modules/pre-txn/sso/sso-setup/services/sso-setup.service";
import { SSOSetupStore } from "../modules/pre-txn/sso/sso-setup/data/sso-setup.store";
import { SSOAzureRoutingHandlerService } from "../modules/pre-txn/sso-azure-routing-handler/services/sso-azure-routing-handler.service";
import { TouchIDConfirmService } from "../modules/txn/userPreferences/touchid/submit/services/touchid-confirm.service";
import { TouchIDConfirmStore } from "../modules/txn/userPreferences/touchid/submit/data/touchid-confirm.store";
import { TouchIDLandingService } from "../modules/txn/userPreferences/touchid/landing/services/touchid-landing.service";
import { TouchIDLandingStore } from "../modules/txn/userPreferences/touchid/landing/data/touchid-landing.store";
import { TradeOptionsConfirmService } from "../modules/txn/tradeOptions/confirm/services/trade-options-confirm.service";
import { TradeOptionsConfirmStore } from "../modules/txn/tradeOptions/confirm/data/trade-options-confirm-store";
import { OptionCentreService } from "../modules/txn/option-centre/services/option-centre.services";
import { OptionCentreStore } from "../modules/txn/option-centre/data/option-centre-store";
import { TradeOptionsLandingService } from "../modules/txn/tradeOptions/landing/services/trade-options-landing.service";
import { TradeOptionsLandingStore } from "../modules/txn/tradeOptions/landing/data/trade-options-landing-store";
import { OptionsCentralService } from "../modules/txn/tradeOptions/optionsCentral/services/options-central.service";
import { OptionsCentralStore } from "../modules/txn/tradeOptions/optionsCentral/data/options-central-store";
import { TradeOptionsVerifyService } from "../modules/txn/tradeOptions/verify/services/trade-options-verify.service";
import { TradeOptionsVerifyStore } from "../modules/txn/tradeOptions/verify/data/trade-options-verify-store";
import { TradeStocksConfirmService } from "../modules/txn/tradeStocks/confirm/services/trade-stocks-confirm.service";
import { TradeStocksConfirmStore } from "../modules/txn/tradeStocks/confirm/data/trade-stocks-confirm-store";
import { TradeStocksLandingService } from "../modules/txn/tradeStocks/landing/services/trade-stocks-landing.service";
import { TradeStocksLandingStore } from "../modules/txn/tradeStocks/landing/data/trade-stocks-landing-store";
import { TradeStocksVerifyService } from "../modules/txn/tradeStocks/verify/services/trade-stocks-verify.service";
import { TradeStocksVerifyStore } from "../modules/txn/tradeStocks/verify/data/trade-stocks-verify-store";
import { TransactionHistoryService } from "../modules/txn/transactionHistory/service/transaction-history-service";
import { TransactionHistoryStore } from "../modules/txn/transactionHistory/data/transaction-history-store";
import { UserPreferencesService } from "../modules/txn/userPreferences/landing/services/userPreferences-landing.service";
import { UserPreferencesStore } from "../modules/txn/userPreferences/landing/data/userPreferences-landing.store";
import { InvestmentFactSheetsStore } from "../modules/txn/investment-factsheets/data/investment-factSheets.store"
import { AboutMyInvestmentCounsellorStore } from "../modules/txn/about-my-investment-counsellor/data/about-my-investment-counsellor.store";
import { EnableUSMarketTradingLandingStore } from '../modules/txn/userPreferences/enableUSMarketTrading/landing/data/enable-US-market-trading-landing.store';
import { EnableUSMarketTradingConfirmStore } from '../modules/txn/userPreferences/enableUSMarketTrading/confirm/data/enable-US-market-trading-confirm.store';
import { EnableMarginAndOptionsTradingLandingStore } from '../modules/txn/userPreferences/enableMarginAndOtionsTrading/landing/data/enable-margin-and-options-trading-landing.store'
import { LinkBankAccountsLandingStore } from '../modules/txn/userPreferences/linkBankAccounts/landing/data/link-bank-accounts-landing.store';
import { LinkBankAccountsConfirmStore } from '../modules/txn/userPreferences/linkBankAccounts/confirm/data/link-bank-accounts-confirm.store';
import { ManangeTrustedContactPersonLandingStore } from '../modules/txn/userPreferences/manageTrustedContactPerson/landing/data/manage-trusted-contact-person-landing.store';
import { ContactInfoUpdateLandingStore } from '../modules/txn/userPreferences/contactInfoUpdate/landing/data/contact-info-update-landing.store';
import { ContactInfoUpdateConfirmStore } from '../modules/txn/userPreferences/contactInfoUpdate/confirm/data/contact-info-update-confirm.store';
import { RRSPWithdrawalLandingStore } from '../modules/txn/userPreferences/rrspWithdrawal/landing/data/rrsp-withdrawal-landing.store';
import { RRSPWithdrawalConfirmStore } from '../modules/txn/userPreferences/rrspWithdrawal/confirm/data/rrsp-withdrawal-confirm.store';
import { TransferFormsLandingStore } from '../modules/txn/userPreferences/transferForms/landing/data/transfer-forms-landing.store';
import { TransferFormsVerifyStore } from '../modules/txn/userPreferences/transferForms/verify/data/transfer-forms-verify.store';
import { TransferFormsConfirmStore } from '../modules/txn/userPreferences/transferForms/confirm/data/transfer-forms-confirm.store';
import { CurrencyTransferLandingStore } from '../modules/txn/userPreferences/currencyTransfer/landing/data/currency-transfer-landing.store';
import { CurrencyTransferConfirmStore } from '../modules/txn/userPreferences/currencyTransfer/confirm/data/currency-transfer-confirm.store';
import { ChangeCashPlanLandingStore } from '../modules/txn/regular-investment-plan/change-cash-plan/landing/data/change-cash-plan-landing-store';
import { ChangeMutualFundPlanLandingStore } from '../modules/txn/regular-investment-plan/change-mutual-fund-plan/landing/data/change-mutual-fund-plan-landing-store';

import { ChangeCashPlanConfirmStore } from '../modules/txn/regular-investment-plan/change-cash-plan/confirm/data/change-cash-plan-confirm-store';

import { ChangeMutualFundVerifyStore } from '../modules/txn/regular-investment-plan/change-mutual-fund-plan/verify/data/change-mutual-fund-verify-store';
import { ChangeMutualFundConfirmStore } from '../modules/txn/regular-investment-plan/change-mutual-fund-plan/confirm/data/change-mutual-fund-confirm-store';
import { BuyStructNotesSelectionStore } from '../modules/txn/structured-notes/buy-structured-notes/selection/data/buy-struct-notes-selection-store';
import { BuyStructNotesEntryStore } from '../modules/txn/structured-notes/buy-structured-notes/entry/data/buy-struct-notes-entry-store';
import { BuyStructNotesVerifyStore } from '../modules/txn/structured-notes/buy-structured-notes/verify/data/buy-struct-notes-verify-store';
import { BuyStructNotesConfirmStore } from '../modules/txn/structured-notes/buy-structured-notes/confirm/data/buy-struct-notes-confirm-store';

import { ManageTrustedContactPersonConfirmStore } from "../modules/txn/userPreferences/manageTrustedContactPerson/confirm/data/manage-trusted-contact-person-confirm.store";
import { DripsFormLandingStore } from '../modules/txn/userPreferences/dripsForm/landing/data/drips-form-landing.store';
import { DripsFormConfirmStore } from "../modules/txn/userPreferences/dripsForm/confirm/data/drips-form-confirm.store";
import { EnableMarginAndOptionsTradingConfirmStore } from '../modules/txn/userPreferences/enableMarginAndOtionsTrading/confirm/data/enable-margin-and-options-trading-confirm.store';
import { MyWatchListStore } from "../modules/txn/option-centre/my-watchlists/data/watch-list-store";
import { MyWatchlistService } from "../modules/txn/option-centre/my-watchlists/services/my-watchlist.service";
import { ExchangeCurrencyLandingService } from "../modules/txn/exchange-currency/landing/services/exchange-currency-landing.service";
import { ExchangeCurrencyLandingStore } from "../modules/txn/exchange-currency/landing/data/exchange-currency-landing-store";
import { OnlineRegisterVerificationService } from "../modules/pre-txn/wg-online-register/verification/services/onlineregister-verification.service";
import { OnlineRegisterVerificationStore } from "../modules/pre-txn/wg-online-register/verification/data/onlineregister-verification-store";
import { OnlineRegisterUsernameService } from "../modules/pre-txn/wg-online-register/create-username/services/onlineregister-username.service";
import { OnlineRegisterUsernameStore } from "../modules/pre-txn/wg-online-register/create-username/data/onlineregister-username-store";
import { OnlineRegisterMailingOptionsService } from "../modules/pre-txn/wg-online-register/mailing-options/services/onlineregister-mailing-options.service";
import { OnlineRegisterMailingOptionsStore } from "../modules/pre-txn/wg-online-register/mailing-options/data/onlineregister-mailing-options-store";
import { OnlineRegisterConfirmationService } from "../modules/pre-txn/wg-online-register/confirmation/services/onlineregister-confirmation.service";
import { OnlineRegisterConfirmationStore } from "../modules/pre-txn/wg-online-register/confirmation/data/onlineregister-confirmation-store";
import { OnlineRegisterPasswordStore } from "../modules/pre-txn/wg-online-register/password/data/onlineregister-password-store";
import { OnlineRegisterPasswordService } from "../modules/pre-txn/wg-online-register/password/services/onlineregister-password.service";
import { TradeMLSLandingStore } from "../modules/txn/tradeMLS/landing/data/trade-MLS-landing-store";
import { TradeMLSLandingService } from "../modules/txn/tradeMLS/landing/service/trade-MLS-landing.service";
import { TradeMLSVerifyService } from "../modules/txn/tradeMLS/verify/services/trade-MLS-verify.service";
import { W8BenLandingStore } from '../modules/txn/userPreferences/w8Ben/landing/data/w8Ben-landing.store';
import { W8BenVerifyStore } from '../modules/txn/userPreferences/w8Ben/verify/data/w8Ben-verify.store';
import { W8BenConfirmStore } from '../modules/txn/userPreferences/w8Ben/confirm/data/w8Ben-confirm.store';
import { UpdatePasswordLandingService } from "../modules/txn/userPreferences/updatePassword/landing/services/update-password-landing.service";
import { UpdatePasswordLandingStore } from "../modules/txn/userPreferences/updatePassword/landing/data/update-password-landing.store";
import { UpdatePasswordConfirmStore } from "../modules/txn/userPreferences/updatePassword/confirm/data/update-password-confirm.store";
import { UpdatePasswordConfirmService } from "../modules/txn/userPreferences/updatePassword/confirm/services/update-password-confirm.service";
import { TradeMLSVerifyStore } from "../modules/txn/tradeMLS/verify/data/trade-MLS-verify-store";
import { TransferCentralService } from "../modules/txn/cashtransfer/transferCentral/services/transfer-central.service";
import { TransferCentralStore } from "../modules/txn/cashtransfer/transferCentral/data/tranfer-central-store";
import { ContactInfoUpdateLandingService } from "../modules/txn/userPreferences/contactInfoUpdate/landing/services/contact-info-update-landing.service";
import { MutualFundsTradableListStore } from "../modules/txn/mutualFunds/tradableList/data/mutual-funds-tradable-list-store";
import { AccountsAgreementsDisclosuresService } from "../modules/pre-txn/accounts-agreements-disclosures/services/accounts-agreements-disclosures.service";
import { AccountsAgreementsDisclosuresStore } from "../modules/pre-txn/accounts-agreements-disclosures/data/accounts-agreements-disclosures-store";
import { FeesCommissionsService } from "../modules/pre-txn/fees-commissions/services/fees-commissions.service";
import { FeesCommissionsStore } from "../modules/pre-txn/fees-commissions/data/fees-commissions-store";
import { EventsCalendarService } from "../modules/txn/quotesResearch/EventsCalendar/services/events-calendar.service";
import { EventsCalendarStore } from "../modules/txn/quotesResearch/EventsCalendar/data/events-calendar-store";
import { AccountDetailStore } from "../modules/txn/account-detail/data/account-detail-store";
import { AccountDetailService } from "../modules/txn/account-detail/service/account-detail.service";
import { AccountSettingsStore } from "../modules/txn/account-settings/data/account-settings-store";
import { AccountSettingsService } from "../modules/txn/account-settings/service/account-settings.service";

const SERVICE_LIST: any = {
  AccountsAgreementsDisclosuresService,
  AccountHoldingService,
  AccountInfoService,
  BroadcastService,
  CashtransferConfirmService,
  CashtransferLandingService,
  CashtransferVerifyService,
  CegAgreementService,
  ChangeMailingOptionsConfirmService,
  ChangeMailingOptionsLandingService,
  ChangeMailingOptionsVerifyService,
  ChangeSignonPassConfirmService,
  ChangeSignonPassService,
  ChangeSignOnPasswordConfirmService,
  ChangeSignOnPasswordLandingService,
  ChangeTradingPasswordConfirmService,
  ChangeTradingPasswordLandingService,
  ChangeUserIDConfirmService,
  ChangeUserIDLandingService,
  ConnectCIBCOnlineConfirmService,
  ConnectCIBCOnlineLandingService,
  ConnectCIBCOnlineVerifyService,
  CreatePasswordService,
  CreateSignonPassConfirmService,
  CreateSignonPassService,
  CreateSignonTradingPassConfirmService,
  CreateSignonTradingPassService,
  CreateTradingPassConfirmService,
  CreateTradingPassService,
  ElectronicAccessAgreementService,
  ExchangeAgreementsLandingService,
  ExchangeCurrencyService,
  FeesCommissionsService,
  ForgotSignonPassService,
  ForgotTradingPasswordConfirmService,
  ForgotTradingPasswordLandingService,
  HelpService,
  HomeService,
  MarketsDetailService,
  MarketsLandingService,
  NasdaqAgreementService,
  NicknameAccountsConfirmService,
  NicknameAccountsLandingService,
  NyAgreementService,
  OnlineRegisterVerificationService,
  OnlineRegisterUsernameService,
  OnlineRegisterMailingOptionsService,
  OnlineRegisterConfirmationService,
  OptionCentreService,
  OpraAgreementService,
  OrderStatusService,
  OtvcConfirmService,
  OtvcSecurityService,
  OnlineRegisterPasswordService,
  QuotesDetailService,
  QuotesResearchBridgeService,
  QuotesResearchService,
  QuotesResearchTechnicalInsightsService,
  QuotesResearchStrategyBuilderService,
  QuotesWatchListService,
  RegisterOnlineConfirmService,
  RegisterOnlineService,
  RelateBrokerageAccountsConfirmService,
  RelateBrokerageAccountsLandingService,
  RemoveBrokerageAccountsConfirmService,
  RemoveBrokerageAccountsLandingService,
  RemoveBrokerageAccountsVerifyService,
  SetUpTradeFillAlertsConfirmService,
  SetUpTradeFillAlertsLandingService,
  SignonService,
  SplashService,
  SSORoutingHandlerService,
  SSOSetupService,
  SSOAzureRoutingHandlerService,
  TouchIDConfirmService,
  TouchIDLandingService,
  TradeOptionsConfirmService,
  TradeOptionsLandingService,
  TradeOptionsVerifyService,
  TradeMLSLandingService,
  TradeMLSVerifyService,
  OptionsCentralService,
  TradeStocksConfirmService,
  TradeStocksLandingService,
  TradeStocksVerifyService,
  TransactionHistoryService,
  UserPreferencesService,
  ContactInfoUpdateLandingService,
  MyWatchlistService,
  ExchangeCurrencyLandingService,
  UpdatePasswordLandingService,
  UpdatePasswordConfirmService,
  TransferCentralService,
  EventsCalendarService,
  AccountSettingsService,
  AccountDetailService
};

export const Post_Signon_Store_List = {

  AboutMyInvestmentCounsellorStore,
  AccountDetailsStore,
  AccountHoldingsStore,
  AccountInfoStore,
  AgreementAndDisclosuresStore,
  BuyBondsConfirmStore,
  BuyBondsOrderStore,
  BuyBondsSearchStore,
  BuyBondsVerifyStore,
  BuyGICConfirmStore,
  BuyGicLandingStore,
  BuyGICOrderStore,
  BuyGICVerifyStore,
  BuyStructNotesSelectionStore,
  BuyStructNotesEntryStore,
  BuyStructNotesVerifyStore,
  BuyStructNotesConfirmStore,
  CancelCashPlanConfirmStore,
  CancelCashPlanLandingStore,
  CancelMutualFundPlanConfirmStore,
  CancelMutualFundPlanLandingStore,
  CashtransferConfirmStore,
  CashtransferLandingStore,
  CashtransferVerifyStore,
  ExchangeCurrencyStore,
  CegAgreementStore,
  ChangeMailingOptionsConfirmStore,
  ChangeMailingOptionsLandingStore,
  ChangeMailingOptionsVerifyStore,
  ChangeCashPlanLandingStore,
  ChangeMutualFundPlanLandingStore,
  ChangeMutualFundVerifyStore,
  ChangeMutualFundConfirmStore,
  ChangeSignonPassConfirmStore,
  ChangeSignOnPasswordConfirmStore,
  ChangeSignOnPasswordLandingStore,
  ChangeTradingPasswordConfirmStore,
  ChangeTradingPasswordLandingStore,
  ChangeCashPlanConfirmStore,
  ChangeUserIDConfirmStore,
  ChangeUserIDLandingStore,
  ConnectCIBCOnlineConfirmStore,
  ConnectCIBCOnlineLandingStore,
  ConnectCIBCOnlineVerifyStore,
  ContactInfoUpdateLandingStore,
  ContactInfoUpdateConfirmStore,
  ChangeCashPlanVerifyStore,
  ContactUsStore,
  ContentStore,
  CreateCashPlanConfirmStore,
  CreateCashPlanLandingStore,
  CreateCashPlanVerifyStore,
  CreateMutualFundLandingStore,
  CreateMutualFundVerifyStore,
  CreateMutualFundConfirmStore,
  CreateSignonPassConfirmStore,
  CreateSignonPassStore,
  CreateSignonTradingPassConfirmStore,
  CreateSignonTradingPassStore,
  CreateTradingPassConfirmStore,
  CreateTradingPassStore,
  CreditPolicyStore,
  CurrencyTransferLandingStore,
  CurrencyTransferConfirmStore,
  DripsFormLandingStore,
  DripsFormConfirmStore,
  EdocumentsStore,
  ElectronicAccessAgreementStore,
  EnableUSMarketTradingLandingStore,
  EnableUSMarketTradingConfirmStore,
  ExchangeAgreementsLandingStore,
  FeesCommissionStore,
  FixedIncomeStore,
  ForgotTradingPasswordConfirmStore,
  ForgotTradingPasswordLandingStore,
  GlobalContentStore,
  HelpStore,
  InvestmentFactSheetsStore,
  IposAndNewIssuesStore,
  LinkBankAccountsLandingStore,
  LinkBankAccountsConfirmStore,
  ManangeTrustedContactPersonLandingStore,
  ManageTrustedContactPersonConfirmStore,
  MarketsDetailStore,
  MarketsLandingStore,
  MutualFundsConfirmStore,
  MutualFundsLandingStore,
  MutualFundsSearchStore,
  MutualFundsTradableListStore,
  MutualFundsVerifyStore,
  NasdaqAgreementStore,
  NewsAndNotesStore,
  NicknameAccountsConfirmStore,
  NicknameAccountsLandingStore,
  NyAgreementStore,
  OptionCentreStore,
  OpraAgreementStore,
  OrderStatusStore,
  OtvcConfirmStore,
  OtvcSecurityStore,
  PerformanceReportingStore,
  QuotesDetailStore,
  QuotesResearchBridgeStore,
  QuotesResearchStore,
  QuotesResearchTechnicalInsightsStore,
  QuotesResearchStrategyBuilderStore,
  QuotesWatchListLandingStore,
  RegularInvestmentPlanDetailsStore,
  RegularInvestmentPlanStore,
  RelateBrokerageAccountsConfirmStore,
  RelateBrokerageAccountsLandingStore,
  RemoveBrokerageAccountsConfirmStore,
  RemoveBrokerageAccountsLandingStore,
  RemoveBrokerageAccountsVerifyStore,
  SellBondConfirmStore,
  SellBondLandingStore,
  SellBondVerifyStore,
  SellFixedIncomeStore,
  SellGicConfirmStore,
  SellGicLandingStore,
  SellGicVerifyStore,
  SellStructNotesConfirmStore,
  SellStructNotesLandingStore,
  SellStructNotesVerifyStore,
  SessionEndStore,
  OLBErrorStore,
  NewUserStore,
  RRSPWithdrawalLandingStore,
  RRSPWithdrawalConfirmStore,
  TransferFormsLandingStore,
  TransferFormsVerifyStore,
  TransferFormsConfirmStore,
  SetUpTradeFillAlertsConfirmStore,
  SetUpTradeFillAlertsLandingStore,
  SiteMapStore,
  TouchIDConfirmStore,
  TouchIDLandingStore,
  TradeOptionsConfirmStore,
  OptionsCentralStore,
  TradeOptionsLandingStore,
  TradeOptionsVerifyStore,
  TradeMLSLandingStore,
  TradeMLSVerifyStore,
  TradeStocksConfirmStore,
  TradeStocksLandingStore,
  TradeStocksVerifyStore,
  TransactionHistoryStore,
  UserPreferencesStore,
  MyWatchListStore,
  ExchangeCurrencyLandingStore,
  W8BenLandingStore,
  W8BenVerifyStore,
  W8BenConfirmStore,
  UpdatePasswordLandingStore,
  UpdatePasswordConfirmStore,
  EnableMarginAndOptionsTradingLandingStore,
  EnableMarginAndOptionsTradingConfirmStore,
  TransferCentralStore,
  EventsCalendarStore,
  AccountSettingsStore,
  AccountDetailStore
};

export const Pre_Signon_Store_List = {
  "use-generic": GenericStore,
  AccountsAgreementsDisclosuresStore,
  BroadcastStore,
  ChangeSignonPassStore,
  CreatePasswordStore,
  FeesCommissionsStore,
  ForgotSignonPassStore,
  OnlineRegisterVerificationStore,
  OnlineRegisterUsernameStore,
  OnlineRegisterMailingOptionsStore,
  OnlineRegisterConfirmationStore,
  OnlineRegisterPasswordStore,
  RegisterOnlineConfirmStore,
  RegisterOnlineStore,
  SignonStore,
  SplashStore,
  SSORoutingHandlerStore,
  SSOSetupStore
};

export function typeOf(name: string) {
  return SERVICE_LIST[name]
    ? SERVICE_LIST[name]
    : Post_Signon_Store_List[name]
      ? Post_Signon_Store_List[name]
      : Pre_Signon_Store_List[name];
}
