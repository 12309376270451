import { Injectable } from '@angular/core';
import { Observable, empty, forkJoin, observable, ObservableInput } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Lob } from 'src/app/shared/models/lob.enum';
import { ROOT_CTX } from 'src/app/shared/models/user';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { PageStore } from 'src/app/shared/models/page-store';
import { routeConfig } from 'src/app/config/router-mapping';
import { TradeStocksVerifyStore } from '../data/trade-stocks-verify-store';



@Injectable({
  providedIn: 'root'
})
export class TradeStocksVerifyService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let ser = input[0];
    ser.input = TradeStocksVerifyStore.params;
    return input;
  }

  pruneInput(input: any): any {
    return input;
  }

  // getSettlementAccounts(data){
  //   let req:SettlementAccountsReq = new SettlementAccountsReq();
  //   req.Action = data.Action;
  //   req.AccountNumber = data.AccountNumber;
  //   req.AccountCurrency = data.AccountCurrency;
  //   return this.httpService.post( "/trade/stocks/settlementaccounts",
  //     req
  //   );
  // }

}
