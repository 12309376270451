import {
  Component,
  ElementRef,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  HostListener,
  Output,
  EventEmitter,
} from "@angular/core";
import { ModalService } from "./services/modal.service";
import { AppStore } from "src/app/shared/models/app-store";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() header: string = null;
  @Input() isActive: boolean;
  @Input() depth = 1000;
  @Input() sessionExtension: boolean;
  @Input() messageAnnounced: string;
  @Input() targetElement: string; // set focus to the element in the parent
  @Input() opacity: number = 0.75;
  @Input() internalOverflow: boolean = false;
  @Input() moduleName: string;

  private element: any;
  attrZIndex = 902;
  attrModalBackgroundZIndex = 901;
  ariaModalAttribute = false;
  backButtonFn: any;
  defaultTouch = { x: 0, y: 0, time: 0 };

  @Output() closefn: EventEmitter<any> = new EventEmitter<any>();
  @Output() openfn: EventEmitter<any> = new EventEmitter<any>();

  @HostListener("window:keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape" || event.key === "Esc") {
      if (this.element.style.display == "block") {
        if (this.closefn.observers.length === 0) {
          this.modalService.close(this.id, this.targetElement);
        } else {
          this.closefn.emit();
        }
      }
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    // to setTabIndex -1 if layout has been changed
    if (
      this.modalService.currentModal === this.id &&
      (!this.modalService.initialWindowWidth ||
        (this.modalService.initialWindowWidth > 899 &&
          window.innerWidth < 899) ||
        (this.modalService.initialWindowWidth < 899 && window.innerWidth > 899))
    ) {
      this.modalService.setTabIndex(this.id, "", false);
      this.modalService.initialWindowWidth = window.innerWidth;
    }
  }

  // onblur(event: MouseEvent){
  //     this.close()
  // }
  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private appStore: AppStore,
  ) {
    this.element = el.nativeElement;
  }

  getLob() {
    return this.appStore.lob;
  }

  ngOnInit(): void {
    // const modal = this;
    // ensure id attribute exists
    if (!this.id) {
      console.error("modal must have an id");
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);
    // close modal on background click
    // refactor, defect, on blur event is triggering, which is not required.
    // this.element.addEventListener('click', function (e: any) {
    //     if (e.target.className === 'cibc-modal') {
    //         modal.close();
    //     }
    // });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    // giving error on IE
    // this.element.remove();
  }

  resetBackButtonFn() {
    this.appStore.backButtonFn = this.backButtonFn;
  }

  // open modal
  open(): void {
    let self = this;
    this.backButtonFn = () => {
      if (self.closefn.observers.length === 0) {
        self.modalService.close(self.id, self.targetElement);
      } else {
        self.closefn.emit();
      }
    };

    this.appStore.backButtonFn = this.backButtonFn;

    // this.element.classList.add('cibc-modal');
    this.element.style.display = 'block';
    if (this.moduleName === 'interceptModal') {
      this.attrZIndex = 802;
    }
    else if (this.moduleName === 'whatsNewModal') {
      this.attrZIndex = 904;
    }
    else {
      this.attrZIndex = this.modalService.getZIndexValue();
    }
    this.attrModalBackgroundZIndex = this.attrZIndex - 1;

    // this.element.style.background = "#FFF";
    // this.element.style.position= "absolute";
    // this.element.style.top="0px";
    // this.element.style.width="100%";

    document.body.classList.add("cibc-modal-open");

    this.openfn.emit();
  }

  // close modal
  close(): void {
    if (this.appStore.backButtonFn == this.backButtonFn) {
      this.appStore.backButtonFn = undefined;
    }
    this.backButtonFn = undefined;

    this.element.style.display = "none";
    if (
      this.moduleName === "interceptModal" ||
      this.moduleName === "whatsNewModal"
    ) {
      this.attrZIndex = 801;
    } else {
      this.attrZIndex = this.modalService.getZIndexValue();
    }

    document.body.classList.remove("cibc-modal-open");
  }

  @HostListener("touchstart", ["$event"])
  @HostListener("touchend", ["$event"])
  @HostListener("touchcancel", ["$event"])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];

    // check the events
    if (event.type === "touchstart") {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === "touchend") {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaY = touch.pageY - this.defaultTouch.y;
      let deltaTime = event.timeStamp - this.defaultTouch.time;

      // simulte a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < 250) {
        // touch movement lasted less than 500 ms
        if (Math.abs(deltaX) > 80 && Math.abs(deltaY) < 40) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.rightSwipeEvent(event);
          } else {
            //this.doSwipeLeft(event);
          }
        }

        if (Math.abs(deltaY) > 60) {
          // delta y is at least 60 pixels
          if (deltaY > 0) {
            //this.doSwipeDown(event);
          } else {
            //this.doSwipeUp(event);
          }
        }
      }
    }
  }

  rightSwipeEvent(event) {
    let split = window.location.hash.split("/");
    if (
      this.appStore.isApp("app") &&
      this.appStore.platform.toLowerCase() == "ios"
    ) {
      if (this.id != "signOffConfirm") {
        // Close modal
        if (this.closefn.observers.length === 0) {
          this.modalService.close(this.id, this.targetElement);
        } else {
          this.closefn.emit();
        }
      }
    }
  }
}
