import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrityCheckComponent } from './integrity-check.component';
import { IntegrityCheckRoutingModule } from './integrity-check-routing.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    IntegrityCheckComponent,
  ],
  imports: [
    CommonModule,
    IntegrityCheckRoutingModule,
    FormsModule
  ]
})
export class IntegrityCheckModule { }