import { Injectable } from '@angular/core';
import { SetUpTradeFillAlertsConfirmData } from './setup-trade-fill-alerts-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SetUpTradeFillAlertsConfirmStore extends PageStore<SetUpTradeFillAlertsConfirmData> {

    constructor() {
        super(new SetUpTradeFillAlertsConfirmData());
    }

}
