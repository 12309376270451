import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../../src/app/shared/models/page-store';
import { FeesCommissionData } from './fees-commission-data';

@Injectable({ providedIn: 'root' })
export class FeesCommissionStore extends PageStore<FeesCommissionData> {

    constructor() {
        super(new FeesCommissionData());
    }

}
