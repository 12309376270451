import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpService } from '../services/http.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { isDefined } from '../../shared/services/utils.service';

@Pipe({
  name: 'changeFormatter',
  pure: true
})
export class ChangeFormatterPipe implements PipeTransform {

  lang: string = this.appStore.state.user.lang;

  constructor(
    private sanitizer: DomSanitizer,
    public httpService: HttpService,
    public appStore: AppStore,
    public globalContent: GlobalContentStore
  ) {
    // console.log('Initializeing Currency Formatter ######');
  }

  transform(value: string, indicator: string, mode?: string): any {
    if (isDefined(mode)) {
      if (indicator === 'U') {
        return this.formatupdown(value, '<span class="icon-Up-Trend up-trend"></span>' + value, this.globalContent.text.up, 'color30');
      } else if (indicator === 'D') {
        return this.formatupdown(value, '<span class="icon-Down-Trend down-trend"></span>' + value, this.globalContent.text.down, 'color31');
      } else {
        return value;
      }
    } else {
      if (indicator === 'U') {
        return this.formatupdown(value, '+' + value, this.globalContent.text.up, 'color30');
      } else if (indicator === 'D') {
        return this.formatupdown(value, '-' + value, this.globalContent.text.down, 'color31');
      } else {
        return value;
      }
    }
  }

  formatupdown(accessContent: string, val: string, updown: string, className: string, mode?: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      '<span class="sr-only">' + updown + ' , ' + accessContent +
      '</span><span aria-hidden="true" class="' + className + '" >' + val + '</span>');
  }
}
