import { Injectable } from '@angular/core';
import { MutualFundsVerifyData } from './mutual-funds-verify-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class MutualFundsVerifyStore extends PageStore<MutualFundsVerifyData> {

    constructor() {
        super(new MutualFundsVerifyData());
    }

}
