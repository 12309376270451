import { Injectable, EventEmitter } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isLoading = false;
  focusElementId = 'focusElement'; // default
  private hideBackground: boolean = false;
  onLoadingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  private requests: HttpRequest<any>[] = [];
  displayLoadingText = false;

  constructor() { }

  public setFocusElement(focusElementId: string) { // method to pass target element id for focus after loading is over
    this.focusElementId = focusElementId;
  }

  // Metods for makign the spinner background completely opaque for a single load.
  public setHideBackground() {
    this.hideBackground = true;
  }

  public setShowBackground() {
    this.hideBackground = false;
  }

  public getHideBackground() {
    return this.hideBackground
  }

  onStarted(req?: HttpRequest<any>): void {
    if (req) {
      this.requests.push(req);
      this.notify();
    }
    else {
      this.isLoading = true;
      this.notify(true);
    }
  }
  onFinished(req?: HttpRequest<any>): void {
    if (req) {
      const index = this.requests.indexOf(req);
      if (index !== -1) {
        this.requests.splice(index, 1);
      }
      if (this.requests.length === 1) {
        // to trigger aria-live on the next spin.
        // document.getElementById('imageDivId').setAttribute('aria-label', '');
      }
    }
    this.isLoading = false;
    this.notify();
  }
  private notify(flag?: boolean): void {
    if (flag) {
      this.onLoadingChanged.emit(flag);
    } else {
      this.onLoadingChanged.emit(this.requests.length !== 0);
    }
  }
  showLoadingText(displayLoading: boolean){
    this.displayLoadingText = displayLoading;
  }

}
