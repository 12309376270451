import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subscription, of } from 'rxjs';
import { ContentService } from 'src/app/core/services/content.service';
import { HttpService } from 'src/app/core/services/http.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { Lob, Lang } from 'src/app/shared/models/lob.enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { isDefined, openWindow } from 'src/app/shared/services/utils.service';
import { ContentStore } from 'src/app/shared/store/content-store';
import { ContentType, GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { ModalService } from '../../modal/services/modal.service';
import { QuickTradeOptionsVerifyInputs } from './data/quick-trade-options-verify-data';
import { QuickTradeOptionsConfirmStore } from '../confirm/data/quick-trade-options-confirm-store';
import { QuickTradeOptionsLandingStore } from '../landing/data/quick-trade-options-landing-store';
import { QuickTradeOptionsVerifyStore } from './data/quick-trade-options-verify-store';
import { QuickTradeOptionsVerifyValidator } from './quick-trade-options-verify.validator';
import { QuickTradeOptionsVerifyService } from './services/quick-trade-options-verify.service';
import { Location } from '@angular/common';
import { TradeOptionsVerifyStore } from 'src/app/modules/txn/tradeOptions/verify/data/trade-options-verify-store';
import { TradeOptionsConfirmStore } from 'src/app/modules/txn/tradeOptions/confirm/data/trade-options-confirm-store';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { IOmnitureTransactionData } from 'src/app/shared/models/omniture.types';
import { get } from "lodash";
import { TradeOptionsLandingStore } from 'src/app/modules/txn/tradeOptions/landing/data/trade-options-landing-store';

declare let require: any;
const gatewayConfig = require('../../../../config/gateway-config.json');

@Component({
  selector: 'app-quick-trade-options-verify',
  templateUrl: './quick-trade-options-verify.component.html',
  styleUrls: ['./quick-trade-options-verify.component.scss']
})

export class QuickTradeOptionsVerifyComponent implements OnInit {
  @Input() public mode: string = 'quickTrade';
  @Input() public data: any;
  @Input() public fullData: any;

  storeArray = {
    "quickTrade": QuickTradeOptionsVerifyStore,
    "smallTradeOptions": TradeOptionsVerifyStore
  }
  confirmStoreArray = {
    "quickTrade": QuickTradeOptionsConfirmStore,
    "smallTradeOptions": TradeOptionsConfirmStore
  }
  // accountBalance: any;
  orderReviewInfo: any;
  estimatedCost: any;
  buyingPower: any;
  quote: any;
  token: string;
  isSubmitAllowed = false;
  checkPassword = false;
  inputs: QuickTradeOptionsVerifyInputs;
  optionType: string;
  isSecFeeApplicable = false;
  actionMode: string;
  orderWarnings: any = [];
  activityLabel = '';
  formError: any = {};
  hidePage = false;
  hasError = false;
  buyingPowerContent: any;
  colonStr: string;
  get LOB() { return Lob; }

  estimatedBuyingPowerToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  secFeeToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  FTTToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  savePassToolTip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  savePasswordTooltipMobile: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };
  moreInfoTooltip: any = {
    openBtnLabel: '',
    cancel: '',
    content: '',
    btnHiddenText: ''
  };

  // otvc
  otvcPromptDataParam: any;
  verifyurl: string;
  otvcPromptContent: any;
  currentModule = 'quicktradeoptionsverify';
  uniqueId: string;
  subscriptions = new Subscription();
  currency: any;
  quickTradeContent: ContentType;
  foreignExchange: boolean = false;
  dash = '-';
  isQuoteExpanded = false;
  greekQuote: any = {};
  uniqueName = "-quick-trade-big";
  @ViewChild('quickTradeOptionVerifyForm') quickTradeOptionVerifyForm: FormGroup;
  isKeyboardOpen: boolean = false;
  @Output() dataLoaded = new EventEmitter<any>();
  notch: boolean = false;
  isScreenReaderON = false;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private contentService: ContentService,
    public store: QuickTradeOptionsVerifyStore,
    public appStore: AppStore,
    private service: QuickTradeOptionsVerifyService,
    private location: Location,
    public content: ContentStore,
    public globalContent: GlobalContentStore,
    private subscriptionService: ObservableSubscriptionService,
    private commonService: CommonService,
    public modalService: ModalService,
    private trackingService: TrackingService,
    private elementRef: ElementRef,
    private persistenceService: PersistenceService,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    this.notch = this.commonService.isNotchPhone();
    this.isScreenReaderON = this.appStore.features.isScreenReaderON ? true : false;
    this.uniqueId = this.appStore.uniqueId;

    this.subscriptions.add(this.subscriptionService.isKeyboardOpen.subscribe(
      (data) => {
        this.isKeyboardOpen = data;
      }
    ));

    this.subscriptions.add(this.contentService.fetchContent('quicktradeoptionsverification').subscribe((data) => {
      this.quickTradeContent = data;

      this.savePassToolTip.openBtnLabel = '';
      this.savePassToolTip.cancel = this.globalContent.text.closeTooltip;
      this.savePassToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.savePassToolTip.content = this.quickTradeContent.text.savePassToolTip;

      this.savePasswordTooltipMobile.openBtnLabel = '';
      this.savePasswordTooltipMobile.cancel = this.globalContent.text.closeTooltip;
      this.savePasswordTooltipMobile.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.savePasswordTooltipMobile.content = this.quickTradeContent.text.savePassToolTipMobile;
    }));

    this.secFeeToolTip.openBtnLabel = '';
    this.secFeeToolTip.cancel = this.globalContent.text.closeTooltip;
    this.secFeeToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    this.secFeeToolTip.content = '';

    this.FTTToolTip.openBtnLabel = '';
    this.FTTToolTip.cancel = this.globalContent.text.closeTooltip;
    this.FTTToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    this.FTTToolTip.content = '';


    if (this.appStore.lang.toUpperCase() === 'FR') {
      this.colonStr = ' :';
    } else {
      this.colonStr = ':';
    }

    if (this.mode == 'quickTrade') {
      if (this.storeArray[this.mode].crossFlow == null || this.storeArray[this.mode].crossFlow === undefined) {
        // session to get on refresh
        const sessionValue = this.persistenceService.sessionStoreRetrieve('quickOptionsVerifyStore');
        if (sessionValue != null && sessionValue != undefined && sessionValue != 'null') {
          const jsonValue = JSON.parse(sessionValue);
          this.storeArray[this.mode].params = jsonValue?.params;
          this.storeArray[this.mode].inFlow = jsonValue?.inFlow;
          const req: any = this.storeArray[this.mode].params;
          this.subscriptionService.setCustomError('RESET');
          this.httpService.post(gatewayConfig.APIServices.optionsVerify.url, req, { params: { skiploading: 'true' } }).subscribe((data: any) => {
            this.storeArray[this.mode].inFlow.Data = data;
            // focus
            this.setVerifyData();
            setTimeout(() => {
              this.dataLoaded.emit(true);
              this.setFocus('closeBtn');
            }, 200);
          }, (err) => {
            this.subscriptionService.setQuickOptionsPosition(1);
            this.subscriptionService.setCustomError(err.error.Exception);
          });
        }
      } else {
        this.setVerifyData();
      }

      this.subscriptions.add(this.subscriptionService.quickOptionsOpenOrCloseDrawer.subscribe((data) => {
        if (data == 'mobile') {
          if (this.storeArray[this.mode].crossFlow == null || this.storeArray[this.mode].crossFlow == undefined) {
            const sessionValue = this.persistenceService.sessionStoreRetrieve('quickOptionsVerifyStore');
            if (sessionValue != null && sessionValue != undefined && sessionValue != 'null') {
              const jsonValue = JSON.parse(sessionValue);
              this.storeArray[this.mode].params = jsonValue?.params;
              this.storeArray[this.mode].inFlow = jsonValue?.inFlow;
              const req: any = this.storeArray[this.mode].params;
              this.subscriptionService.setCustomError('RESET');
              this.httpService.post(gatewayConfig.APIServices.optionsVerify.url, req, { params: { skiploading: 'true' } }).subscribe((data: any) => {
                this.storeArray[this.mode].inFlow.Data = data;
                // focus
                this.setVerifyData();
                setTimeout(() => {
                  this.dataLoaded.emit(true);
                  this.setFocus('closeBtn');
                }, 200);
              }, (err) => {
                this.subscriptionService.setQuickOptionsPosition(1);
                this.subscriptionService.setCustomError(err.error.Exception);
              });
            }
          }
        }
      }));
    } else {
      this.uniqueName = '-quick-trade-small';
      this.setVerifyData();
    }

    let tempOptions = this.commonService.unfoldJSON(this.commonService.obscureAccount(this.storeArray[this.mode].params));
    tempOptions.platform = "quickTrade";
    tempOptions.AccountTypeDesc = this.orderReviewInfo.Account.AccountTypeDesc;
    this.commonService.logBraze("viewed_trade_options_verify", tempOptions);
  }

  setVerifyData() {
    if (this.storeArray[this.mode].inFlow?.Data != undefined || this.storeArray[this.mode].inFlow?.Data != null) {
      this.data = this.storeArray[this.mode].inFlow.Data;
    }
    if(this.data) {
      this.inputs = this.store.state.input;
      this.inputs.savePassword = false;
      this.inputs.tradingPassword = '';
      // this.accountBalance = this.data[0].AccountBalanace;
      this.orderReviewInfo = this.data.OrderReviewInfo;
      console.log(this.orderReviewInfo)
      this.estimatedCost = this.data.EstimatedCost;
      this.buyingPower = this.data.BuyingPowerValues;
      this.quote = this.data.Quote;
      if (isDefined(this.data.Warnings) && this.data.Warnings.length > 0) {
        this.orderWarnings = this.data.Warnings;
        this.foreignExchange = this.orderWarnings.some(
          warning => {
            return warning.Code === "UIBUSFI0004";
          });
      }
      this.optionType = this.orderReviewInfo.Symbol.OptionInfo.OptionType.toUpperCase();
      this.token = this.data.Token;
      this.activityLabel = this.orderReviewInfo.Activity.OrderType.Content;
      if (isDefined(this.estimatedCost)) {
        this.isSecFeeApplicable = isDefined(this.estimatedCost.EstimatedSecFee) ? true : false;
      }
      this.checkPassword = !this.data.TradingPasswordInSession;
      this.actionMode = this.orderReviewInfo.Activity.OrderType;
      this.isSubmitAllowed = true;
  
      // RootService.omniturePageName("Trade Options-" + this.getOmnitureValue(this.actionMode) + ">Step 2 of 3");
      this.subscriptions.add(this.subscriptionService.isKeyboardOpen.subscribe(
        (data) => {
          this.isKeyboardOpen = data;
        }
      ));
      if (this.buyingPower) {
        this.fetchBuyingPowerContent();
      }
  
      if (this.orderReviewInfo.Account.AccountType == "INV_MAR") {
        if (!this.buyingPowerContent) {
          this.fetchBuyingPowerContent();
          this.buyingPower = {};
        }
        if (!this.buyingPower.AsOfDateTime) {
          this.buyingPower.AsOfDateTime = this.storeArray[this.mode].inFlow.TimeStamp;
        }
        this.buyingPower.BuyingPowerConsolidated = this.storeArray[this.mode].inFlow.ConsolidatedBuyingPower;
      }
  
      if (this.orderReviewInfo.Activity.OrderType == "SELLTOCLOSE" || this.orderReviewInfo.Activity.OrderType == "SELLTOOPEN") {
        this.currency = this.orderReviewInfo.PayTo.Currency;
      } else if (this.orderReviewInfo.Activity.OrderType == "BUYTOOPEN" || this.orderReviewInfo.Activity.OrderType == "BUYTOCLOSE") {
        this.currency = this.orderReviewInfo.PayFrom.Currency
      }
      this.defineSavePasswordTooltips();
      this.defineToolTips();
      this.getGreekValues();
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.openOrCloseQouteDetails();
  }
  setFlag(market) {
    if (market === 'US') {
      return 'us';
    }
    if (market === 'CA') {
      return 'can';
    }
  }
  setActivityLabel(activityType) {
    if (activityType === 'BUYTOOPEN') {
      return this.quickTradeContent.text.buy;
    } else if (activityType === 'BUYTOCLOSE') {
      return this.quickTradeContent.text.sell;
    } else if (activityType === 'SELLTOOPEN') {
      return this.quickTradeContent.text.buyToCover;
    } else if (activityType === 'SELLTOCLOSE') {
      return this.quickTradeContent.text.buyToCover;
    }
    return '';
  }

  backClick() {
    this.location.back();
  }

  cancel() {
    QuickTradeOptionsLandingStore.crossFlow = undefined;
    QuickTradeOptionsLandingStore.savedInfo = undefined;

    this.location.back();
  }

  onTradePasswordSave(save) {
    this.inputs.savePassword = save;
  }


  resetTradePassword() {
    this.inputs.tradingPassword = '';
  }

  resetAllFormErrors() {
    this.formError = {};
  }

  next() {
    this.hasError = false;
    let params: any = {};

    QuickTradeOptionsVerifyValidator.setValidators(this.quickTradeOptionVerifyForm);
    QuickTradeOptionsVerifyValidator.validate(this.quickTradeOptionVerifyForm);

    if (this.checkPassword && this.appStore.lob !== 'wg') {
      params = {
        TradingPassword: this.inputs.tradingPassword,
        SaveTradingPassword: this.inputs.savePassword,
        Token: this.token
      };
    } else {
      params = {
        Token: this.token
      };
    }

    if (this.quickTradeOptionVerifyForm.valid) {
      this.resetAllFormErrors();
      let req: any = {};
      if (params.TradingPassword) {
        req.TradingPassword = params.TradingPassword;
        req.SaveTradingPassword = params.SaveTradingPassword;
      }
      req.Token = params.Token;
      this.subscriptionService.setCustomError('RESET');
      this.httpService.post(gatewayConfig.APIServices.optionsSubmit.url, req).subscribe((data) => {

        this.confirmStoreArray[this.mode].inFlow = {
          orderReviewInfo: this.orderReviewInfo,
          estimatedCost: this.estimatedCost,
          quote: this.quote,
          foreignExchange: this.foreignExchange,
          orderData: data,
          quantity: (parseInt(this.orderReviewInfo.Activity.Quantity.Data) * 100).toString(),
          quoteValue: this.getQuoteValue(this.orderReviewInfo, this.quote),
          Data: [data]
        }

        let tempOptions = this.commonService.unfoldJSON(this.commonService.obscureAccount(this.storeArray[this.mode].params));
        tempOptions.platform = "quickTrade";
        tempOptions.AccountTypeDesc = this.orderReviewInfo.Account.AccountTypeDesc;
        this.commonService.logBraze("action_trade_options_order_placed", tempOptions);

        this.storeArray[this.mode].crossFlow = undefined;
        this.storeArray[this.mode].savedInfo = undefined;
        
        QuickTradeOptionsLandingStore.crossFlow = undefined;
        QuickTradeOptionsLandingStore.savedInfo = undefined;
        QuickTradeOptionsLandingStore.inFlow = undefined;
        
        TradeOptionsLandingStore.crossFlow = undefined;
        TradeOptionsLandingStore.inFlow = undefined;
        TradeOptionsLandingStore.savedInfo = undefined;

        if (this.mode == 'quickTrade') {
          this.subscriptionService.setQuickOptionsPosition(3);
        } else {
          this.omniAndGo(req)
        }

    
      }, (err) => {
        this.subscriptionService.setCustomError(err.error.Exception);
        setTimeout(() => {
          this.subscriptionService.enableButton("validation");
        }, 200);
      });
      
   } else {
      setTimeout(() => {
        this.subscriptionService.enableButton("validation");
      }, 200);
      this.formError = QuickTradeOptionsVerifyValidator.resolveValidationError(this.quickTradeOptionVerifyForm, this.quickTradeContent.error);
      this.resetTradePassword();
      // if (this.hasError) {
      //   super.clearError();
      // this.subscriptionService.setError('RESET');
      // }
      // super.scrollToTopError();
    }
  }

  omniAndGo(req) {
    if ((window as any).TrackingEnabled) {
      let tradeInfo: IOmnitureTransactionData;
      let transactionConfig = {
        mainField: "inFlow",
        fieldNames: {
          action: "orderReviewInfo.Activity.OrderType",
          type: "options",
          symbol: "orderReviewInfo.Symbol.SymbolName",
          market: "orderReviewInfo.Symbol.Market",
          exchange: "quote.UnderlyingSymbolQuote.MarketId",
          quote: "quoteValue",
          quantity: "quantity",
          dividend: "na",
          priceType: "orderReviewInfo.Price.Type",
          expiry: "orderReviewInfo.Expiry.ExpiryType",
          account: "orderReviewInfo.Account.AccountTypeDesc",
          serviceFee: "estimatedCost.EstimatedCommission",
          value: "estimatedCost.EstimatedOrderValue",
          currency: "orderReviewInfo.Symbol.Market",
          foreignExchange: "foreignExchange",
        },
      };

      let omnitureStoreData = get(TradeOptionsConfirmStore, transactionConfig.mainField);

      if (omnitureStoreData) {
        const orderId = this.data.OrderId;
        tradeInfo = this.trackingService.tagTransaction(
          transactionConfig,
          omnitureStoreData,
          orderId
        );
      }
      of(
        this.trackingService.tagPageAction(
          "/txn/optionsCentral/singleLeg/confirm",
          "",
          tradeInfo,
          [{
            id: "submit",
            restUrl: "/v2/trade/options/submit",
            input: req,
            critical: true,
            optional: undefined
          }])
      );
    }

    this.router.navigate(['/txn/optionsCentral/singleLeg/landing']);
  }

  getQuoteValue(reviewInfo, quote) {
    if (reviewInfo.Price.Type === 'LIMIT') {
      return reviewInfo.Price.LimitAmount.Data;
    }
    if (reviewInfo.Activity.OrderType === 'BUYTOOPEN' || reviewInfo.Activity.OrderType === 'BUYTOCLOSE') {
      return quote.AskPrice.Data;
    } else {
      return quote.BidPrice.Data;
    }
  }

  getOmnitureValue(action) {
    let value;
    switch (action) {
      case 'BUYTOOPEN':
        value = 'Buy To Open';
        break;
      case 'BUYTOCLOSE':
        value = 'Buy To Close';
        break;
      case 'SELLTOOPEN':
        value = 'Sell To Open';
        break;
      case 'SELLTOCLOSE':
        value = 'Sell To Close';
        break;
    }
    return value;
  }

  isDesktop() {
    if(this.mode == 'quickTrade') {
      return false;
    } else {
      return this.commonService.isDesktop();
    }
  }

  formatDate(date, formaten, formatfr) {
    const momentLang = moment(date).locale(this.appStore.lang);
    if (this.appStore.lang === Lang.EN) {
      return momentLang.format(formaten);
    } else {
      return momentLang.format(formatfr);
    }
  }

  getOptionTypeString(val) {
    if (val === 'C') {
      return this.quickTradeContent.text.call;
    } else if (val === 'P') {
      return this.quickTradeContent.text.put;
    } else {
      return '-';
    }
  }

  getFlag(flagName): string {
    if (flagName === undefined) {
      return null;
    } else {
      let response = '';

      switch (flagName.toLowerCase()) {
        case 'ca':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'cdn':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'usd':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
        case 'us':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
      }

      return response;
    }
  }

  valueOrDash(val) {
    if ((val === undefined) || val == null) {
      return this.buyingPowerContent.text.buyingPowerCannotDisplay;
    } else {
      return val;
    }
  }

  closeDrawer() {
    this.moveChatIcon();
    this.subscriptionService.quickOptionsOpenOrCloseDrawer.next();
  }

  // move chat icon to right
  moveChatIcon() {
    // let chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.removeClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.removeClass(kampyleButton, 'move-kampyle');
    // }
  }

  forgotPassword() {
    this.closeDrawer();
    this.forgotTradingPasswordInit().subscribe(
      (data: any) => {
        if (data) {
          const action = data.ACTION;
          if (action === 'OTVCNOTREQUIRED') {
            // this.router.navigate(['txn/userPreferences/forgotTradingPassword/landing']);
            this.commonService.callNonAPI("txn/userPreferences/forgotTradingPassword/landing");
          } else if (action === 'OTVCREQUIRED') {
            // otvc
            // OtvcSecurityVerifyStore.crossFlow = {
            //   'fromForgotTradingPassword': 'true'
            // };
            // this.router.navigate(['txn/userPreferences/forgotTradingPassword/otvc']);

            //Fetch content for prompth model and pass it to model
            this.subscriptions.add(this.contentService.fetchContent('otvcpromptmodel').subscribe((data3) => {
              this.otvcPromptContent = data3;
            }, (err) => {
            }));

            this.getOtvcRequest().subscribe(
              (data2: any) => {

                if (data2) {
                  this.mode = 'forgotTradingPassword';
                  this.verifyurl = gatewayConfig.APIServices.userPrefOTVCVerify.url;
                  this.otvcPromptDataParam = data2;
                  this.modalService.open('otvcprompt' + this.uniqueId, 'otvcPromptClose' + this.uniqueId);
                  this.currentModule = 'prompt';
                  // this.hidePage = true;

                }
              }
            );
          }
        }
        // this.loadingService.dismiss();
      },
      (err) => {
        // super.handleError(err);
      });
  }

  forgotTradingPasswordInit() {
    return this.httpService.post(gatewayConfig.APIServices.forgotTradingPasswordInit.url, null);
  }

  getOtvcRequest() {
    return this.httpService.post(gatewayConfig.APIServices.getOtvcRequest.url, {});
  }

  closeModal() {
    if (this.currentModule === 'prompt') {
      this.currentModule = 'quicktradeoptionsverify';
      this.modalService.close('otvcprompt' + this.uniqueId);
    }
  }

  typePass() {
    this.formError.tradingPassword = undefined;
  }

  skipLink(id) {
    let el = document.getElementById(id);
    if (isDefined(el)) {
      el.focus();
    }
  }
  fetchBuyingPowerContent() {
    this.contentService.fetchContent('buyingpowertooltip').subscribe((data) => {
      this.buyingPowerContent = data;

      this.estimatedBuyingPowerToolTip.openBtnLabel = '';
      this.estimatedBuyingPowerToolTip.cancel = this.globalContent.text.closeTooltip;
      this.estimatedBuyingPowerToolTip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
      this.estimatedBuyingPowerToolTip.title = this.buyingPowerContent.text.buyingPower;
      this.estimatedBuyingPowerToolTip.content = '';
    }, (err) => {

    });
  }
  openWindow(url) {
    openWindow(url, this.appStore);
  }

  valueOrDashContent(value) {
    if (isDefined(value)) {
      return value.Content;
    }
    return this.dash;
  }

  valueOrDashData(value) {
    if (isDefined(value)) {
      return value;
    }
    return this.dash;
  }

  valueOrDashGreeks(value) {
    if (isDefined(value)) {
      return value.toFixed(4);
    }
    return this.dash;
  }

  valueOrDashTwoDigits(value) {
    if (isDefined(value)) {
      return value.toFixed(2);
    }
    return this.dash;
  }

  tooltipClicked(title?) {
    this.secFeeToolTip.title = title;
    this.FTTToolTip.title = title;
    this.savePassToolTip.title = title;
    this.savePasswordTooltipMobile.title = title;
    this.moreInfoTooltip.title = title;
    if (title == 'More information' || title == 'Plus de renseignements') {
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:verify:more-info-icon-tool-tip');
    } else {
      this.sendOmnitureInteractionData('quick-trade:trading:trade-stocks:verify:info-icon-tool-tip');
    }
  }


  sendOmnitureInteractionData(value?: string) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(value, false, true, false, true));
    }
  }

  handleQuoteExpansion(value) {
    this.isQuoteExpanded = value;
    if (!this.isQuoteExpanded) {
      this.sendOmnitureInteractionData('option-trade:trading:trade-option:details:quotes-details-collapsed');
    }
    else {
      this.sendOmnitureInteractionData('option-trade:trading:trade-option:details:quotes-details-expanded');
    }
    let panel = this.elementRef.nativeElement.querySelector('.collapse');
    if (!value) {
      panel.style!.maxHeight = null;
    } else {
      panel.style!.maxHeight = panel.scrollHeight + "px";
    }

  }

  openWindowTab(url) {
    window.open(url);
  }
  defineToolTips() {

    this.moreInfoTooltip.openBtnLabel = '';
    this.moreInfoTooltip.cancel = this.globalContent.text.closeTooltip;
    this.moreInfoTooltip.content = '';
    this.moreInfoTooltip.btnHiddenText = this.globalContent.text.tooltipHiddenText;
  }

  defineSavePasswordTooltips(){
    
    this.savePasswordTooltipMobile.openBtnLabel = '';
    this.savePasswordTooltipMobile.cancel = this.globalContent.text.closeTooltip;
    this.savePasswordTooltipMobile.btnHiddenText = this.globalContent.text.tooltipHiddenText;
    if(this.quickTradeContent && this.quickTradeContent.text) {
      this.savePasswordTooltipMobile.content = this.quickTradeContent.text.savePassToolTipMobile;
    }
  }

  tooltipClickedInfo(title) {
    this.moreInfoTooltip.title = title;
    if (title == 'More Information' || title == 'Plus de renseignements') {
      this.sendOmnitureInteractionData('trade-options:trading:trade-options:details:more-info-icon-tool-tip');
    } else {
      this.sendOmnitureInteractionData('trade-options:trading:trade-options:details:more-info-icon-tool-tip');
    }
  }

  openOrCloseQouteDetails() {
    if (!this.isDesktop()) {
      let panel = this.elementRef.nativeElement.querySelector('.collapse');
      if(!panel) {
        return;
      }
      if (this.isQuoteExpanded) {
        panel.style!.maxHeight = null;
      } else {
        panel.style!.maxHeight = panel.scrollHeight + "px";
      }
    }
  }

  forFrenchFormat(amount) {
    if (isDefined(amount)) {
      if (this.appStore.lang.toUpperCase() === 'FR') {
        amount = amount.replace('.', ',').replace(/\s/g, '');
      }
    }
    return amount;
  }

  removeDollar(html) {
    console.log(html);
  }
  getGreekValues() {
    this.greekQuote = this.storeArray[this.mode].inFlow?.greek;
  }

  setFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 200);
  }
} 
