import { BaseData } from '../../../../../../../app/shared/models/base-data';

export class DripsFormLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            selectedAccount: null,
            email: null,
            DividendPaymentOption: '',
            termsNCondition: null
        };

    }
}

export enum DividendPaymentOption {
    ENROLL = 'E',
    CANCEL = 'C'
}

export interface DripsFormLandingInputs {
    termsNCondition: boolean;
    selectedAccount: any;
    email: string;
    DividendPaymentOption: any;
    WebFormName?: string;
}

export interface DripsFormSubmitParams {
    WebFormName?: string;
    Stage?: string;
    Parameters?: {
        param1: string;
        param2: string;
        param3: string;
        param4: string;
        param5: string;
    };
}

