import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { Observable } from 'rxjs';
import { SettlementAccountsReq } from '../../../../fixed-income/data/fixed-income-data';
import { BuyStructNotesEntryStore } from '../data/buy-struct-notes-entry-store';

declare let require: any;
const gatewayConfig = require('src/app/config/gateway-config.json');

@Injectable({
  providedIn: 'root'
})
export class BuyStructNotesEntryService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService, private router: Router) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (BuyStructNotesEntryStore.params) {
      const ser = input[0];
      ser.input = BuyStructNotesEntryStore.params;
      return input;
    }
  }
  getSettlementAccounts = (data: any): Observable<any> => {
    const req: SettlementAccountsReq = {
      Action: data.Action,
      AccountNumber: data.AccountNumber,
      AccountCurrency: data.AccountCurrency,
      Type: data.Type
    };
    return this.httpService.post(gatewayConfig.APIServices.notesSettlementAccounts.url,
      req, { params: { skiploading: 'true' } }
    );
  }
}
