import { Injectable } from '@angular/core';
import { ChangeUserIDConfirmData } from './change-userid-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeUserIDConfirmStore extends PageStore<ChangeUserIDConfirmData> {

    constructor() {
        super(new ChangeUserIDConfirmData());
    }

}
