import { ContentType } from './../../../store/global-content-store';
import { Component, OnInit, EventEmitter, Output, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { QuickTradeStocksConfirmStore } from './data/quick-trade-stocks-confirm-store';
import { AppStore } from 'src/app/shared/models/app-store';
import _ from 'lodash';
import { ContentStore } from './../../../../shared/store/content-store';
import { GlobalContentStore } from './../../../../shared/store/global-content-store';
import { Subscription } from 'rxjs/internal/Subscription';
import { ContentService } from 'src/app/core/services/content.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { QuickTradeStocksVerifyStore } from '../verify/data/quick-trade-stocks-verify-store';
import { OrderStatusStore } from 'src/app/modules/txn/orderStatus/landing/data/order-status-store';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-quick-trade-stocks-confirm',
  templateUrl: './quick-trade-stocks-confirm.component.html',
  styleUrls: ['./quick-trade-stocks-confirm.component.scss']
})

export class QuickTradeStocksConfirmComponent implements OnInit {

  @Input() public mode: string = 'quickTrade';
  @Input() public fullData: any;
  @Output() orderStatus = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() dataLoaded = new EventEmitter<any>();

  data: any;
  orderReviewInfo: any;
  orderConfirmInfo: any;
  quickTradeContent: ContentType = this.content;
  estimatedCostValues: any;
  formError: any = {};
  hidePage = false;
  hasError = false;
  subscriptions = new Subscription();

  constructor(
    private router: Router,
    public store: QuickTradeStocksConfirmStore,
    public appStore: AppStore,
    public content: ContentStore,
    public globalContent: GlobalContentStore,
    private contentService: ContentService,
    private subscriptionService: ObservableSubscriptionService,
    private commonService: CommonService,
    private renderer: Renderer2
  ) { }

  ngOnDestroy(): void {
    this.clearValues(1);
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.contentService.fetchContent('quicktradestocksconfirmation').subscribe((data) => {
      this.content = data;
    }));

    if (this.mode == 'quickTrade') {
      if (QuickTradeStocksConfirmStore.inFlow.orderData != undefined || QuickTradeStocksConfirmStore.inFlow.orderData != null) {
        this.data = QuickTradeStocksConfirmStore.inFlow.orderData;
        this.orderReviewInfo = QuickTradeStocksConfirmStore.inFlow.orderReviewInfo;
        if (this.orderReviewInfo.Symbol.SymbolName.endsWith('.U')) {
          this.orderReviewInfo.Symbol.Currency = "US";
        } else {
          this.orderReviewInfo.Symbol.Currency = this.orderReviewInfo.Symbol.Market;
        }
        if ((this.data === undefined) || this.data.length === 0) {
          this.data = [];
          this.orderConfirmInfo = QuickTradeStocksConfirmStore.inFlow.orderConfirmInfo;
        } else {
          this.orderConfirmInfo = this.data;
          QuickTradeStocksConfirmStore.inFlow.orderConfirmInfo = this.orderConfirmInfo;
          QuickTradeStocksConfirmStore.params = undefined;
        }

        this.estimatedCostValues = QuickTradeStocksConfirmStore.inFlow.estimatedCost;
        this.clearValues();

        setTimeout(() => {
          this.dataLoaded.emit(true);
          this.setFocus('closeBtn');
        }, 200);
      }

      this.subscriptions.add(this.subscriptionService.openOrCloseDrawer.subscribe((data) => {
        if (data) {
          this.newOrder();
        }
      }));
    } else {
      this.orderReviewInfo = this.fullData.orderReviewInfo;
      if (this.orderReviewInfo.Symbol.SymbolName.endsWith('.U')) {
        this.orderReviewInfo.Symbol.Currency = "US";
      } else {
        this.orderReviewInfo.Symbol.Currency = this.orderReviewInfo.Symbol.Market;
      }
      this.orderConfirmInfo = this.fullData.orderData;
      this.estimatedCostValues = this.fullData.estimatedCost;
      this.clearValues();
    }
  }

  clearValues(pos?) {
    this.commonService.clearTradeDrawerSession();
    if (pos) {
      this.subscriptionService.setTradeDrawerPosition(pos);
      if (pos == 1 && this.mode == 'quickTrade') {
        this.commonService.logBraze("viewed_trade_stock_details", { platform: "quickTrade"} );
      }
    }
  }

  newOrder() {
    if (this.mode == 'quickTrade') {
      this.clearValues(1);
    } else {
      this.close.emit();
    }
  }

  goToOrderStatus() {
    if (this.mode == 'quickTrade') {
      //if order status is the current route
      if (this.router.url === '/txn/orderStatus') {
        this.subscriptionService.refreshOrder.next(this.orderReviewInfo.Account);
      }
      //this.moveChatIcon();
      this.subscriptionService.openOrCloseDrawer.next();
      this.clearValues(1);
      OrderStatusStore.crossFlow = { FiTypeCode: 'E' };
      this.router.navigate(['/txn/orderStatus']);
    } else {
      this.orderStatus.emit();
    }
  }

  closeDrawer() {
    //this.moveChatIcon();
    this.subscriptionService.openOrCloseDrawer.next();
  }

  // move chat icon to right
  moveChatIcon() {
    // let chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.removeClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.removeClass(kampyleButton, 'move-kampyle');
    // }
  }

  setFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 200);
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }
  getSummaryActionContent(orderType) {
    if (orderType == 'BUY' || orderType == '') {
      return this.content.text.summaryBuy;
    } else if (orderType == 'SELL') {
      return this.content.text.summarySell;
    } else if (orderType == 'BUYTOCOVER') {
      return this.content.text.summaryBuyToCover;
    } else if (orderType == 'SHORT') {
      return this.content.text.summaryShortSell;
    }
  }
  
  getFlag(flagName): string {
    if (flagName === undefined) {
      return null;
    } else {
      let response = '';
      switch (flagName.toLowerCase()) {
        case 'ca':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'cdn':
          response = './assets/images/icons/icon-CDN-flag.svg';
          break;
        case 'usd':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
        case 'us':
          response = './assets/images/icons/icon-USD-flag.svg';
          break;
      }
      return response;
    }
  }
}
