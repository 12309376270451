import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../shared/models/page-store';
import { QuickTradeStocksLandingData } from './quick-trade-stocks-landing-data';

@Injectable({ providedIn: 'root' })
export class QuickTradeStocksLandingStore extends PageStore<QuickTradeStocksLandingData> {

    constructor() {
        super(new QuickTradeStocksLandingData());
    }

}
