import { BaseData } from '../../../../../../shared/models/base-data';
import { SettlementAccount } from '../../../data/fixed-income-data';

export class BuyGICOrderData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1,
    };
  }
}

export interface BuyGICOrderInputs {
  quantity: number;
  action: string;
  accountIndex: number;
  settlementIndex: number;
}

export interface SubmitReq {
  AccountNumber: number;
  SettlementAccount?: SettlementAccount;
  Amount: string;
  Action: string;
  Currency: string;
}

export interface GicDetails {
  SettlementDate: string;
  InterestPaymentFrequency: string;
  InterestRateOrEffectiveYield: string;
  MaturityDate: string;
  Term: number;
  IssuerDescription: string;
  MinimumPurchase: string;
}

export interface IntRate {
  year: string;
  rate: string;
}

export interface UpdateReq {
  AccountNumber: number;
  Action: string;
  Amount: string;
  Currency: string;
  InitiateBuyToken: string;
  RecalculationFlag: string;
}








