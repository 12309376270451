import { Injectable } from '@angular/core';
import { PageStore } from '../../../../shared/models/page-store';
import { SessionEndData } from './session-end-data';

@Injectable({ providedIn: 'root' })
export class SessionEndStore extends PageStore<SessionEndData> {

  constructor() {
    super(new SessionEndData());
  }

}
