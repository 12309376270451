import { Injectable } from '@angular/core';
import { BuyStructNotesConfirmData } from './buy-struct-notes-confirm-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class BuyStructNotesConfirmStore extends PageStore<BuyStructNotesConfirmData> {

  constructor() {
    super(new BuyStructNotesConfirmData());
  }
}
