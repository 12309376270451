import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from "src/app/core/services/http.service";
import { RegularInvestmentPlanDetailsStore } from "../data/regular-investment-plan-details-store";

@Injectable({
    providedIn: 'root'
})
export class RegularInvestmentPlanDetailsService implements PageService {

    constructor(private httpService: HttpService) { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        const req = ser.input;
        req.AccountNumber = RegularInvestmentPlanDetailsStore.params.detailsParam.AccountNumber;
        req.Sequence = RegularInvestmentPlanDetailsStore.params.detailsParam.Sequence;
        req.BrokerNumber = RegularInvestmentPlanDetailsStore.params.detailsParam.BrokerNumber;
        req.NextOrderTimeStamp = RegularInvestmentPlanDetailsStore.params.detailsParam.NextOrderTimeStamp;
        return input;
    }

}