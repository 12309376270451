import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from '../../../../shared/services/page-service'
@Injectable({
  providedIn: 'root'
})
export class InvestmentFactSheetsService implements PageService {

  constructor() {}
  
    prepareInput(
        input: any,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): any {
        return input;
    }
}
