import { BaseData } from 'src/app/shared/models/base-data';

export class AccountInfoData extends BaseData {

}
export interface reportRequest {
  SelectedTab: string,
  ReportType: RORType | tabType,
  ReportDateRange: reportDateRangeRequest,
  SelectedAccount?: selectedAccountRequest,
  FirstTimeInd?: boolean
}
export enum RORType {
  CUMULATIVE = 'CM',
  CALENDAR = 'CL'
}
export enum tabType {
  RATE_OF_RETURN = 'RR',
  BALANCE_TREND = 'BT'
}
export interface selectedAccountRequest {
  PrimaryAcctNum: string,
  SubAcctType?: string,
  SubAcctCurr?: string
}
export interface reportDateRangeRequest {
  ReportDateRangeType: dateRange | interval,
  FromDate?: string,
  ToDate?: string
}
export enum dateRange {
  CHOOSE_ONE = 'CO',
  LAST_MONTH = 'M',
  THREE_MONTHS = 'Q',
  HALF_YEAR = 'H',
  YEARLY = 'Y',
  YEAR_TO_DATE = 'YTD',
  MAX_AVAILABLE = 'MX',
  CUSTOM = 'CU',
  EMPTY = ''
}
export enum interval {
  MONTHLY = 'M',
  QUARTERLY = 'Q',
  YEARLY = 'Y',
  EMPTY = ''
}
export interface chartValue {
  endDate: string,
  data?: string,
  netDeposits?: string,
  changeInValue?: string,
  endingBalance?: string,
  calculationCurrency?: string
}
export enum CONST {
  MIN_DATE = '20160101',
  NEW_MIN_DATE = '20160102'
}