import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PageService } from 'src/app/shared/services/page-service';
import { ChangeCashPlanConfirmStore } from "../data/change-cash-plan-confirm-store";

@Injectable({
    providedIn: 'root'
})
export class ChangeCashPlanConfirmService implements PageService {

    constructor() { }
    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const ser = input[0];
        ser.input = ChangeCashPlanConfirmStore.params;
        return input;
    }
}