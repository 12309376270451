import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SellStructNotesLandingStore } from '../data/sell-struct-notes-landing-store';
import { SettlementAccountsReq } from '../data/sell-struct-notes-landing-data';
import { Observable } from "rxjs";


declare let require: any;
const gatewayConfig = require('../../../../../../config/gateway-config.json');

@Injectable({
  providedIn: 'root'
})
export class SellStructNotesLandingService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    if (SellStructNotesLandingStore.crossFlow) {
      console.log(SellStructNotesLandingStore.crossFlow);
      const acct = SellStructNotesLandingStore.crossFlow.accountNumber;
      const symb = SellStructNotesLandingStore.crossFlow.symbol;
      const currency = SellStructNotesLandingStore.crossFlow.currency;
      const ret = [input[0], input[1], input[2]];
      // console.log(input);
      ret[0] = this.prepareQuote(ret[0], symb);
      ret[1] = this.prepareHoldings(ret[1], acct);
      ret[2] = this.prepareSett(ret[2], acct, "SELL", currency);

      return ret;

    } else {
      let ser = input[0];
      ser.input = {
        Action: "SELL"
      }
      return [input[0]];
    }
  }

  prepareHoldings(input, accNum) {
    const ret = input.input;

    ret.AccountNumber = accNum;

    input.input = ret;
    return input;
  }

  prepareSett(input, accNum, action, currency?) {
    const ret = input.input;
    ret.AccountNumber = accNum;
    ret.Action = action;
    ret.AccountCurrency = currency;

    input.input = ret;
    return input;
  }

  prepareQuote(input, symb) {
    const ret = input.input;

    ret.Action = "SELL";
    ret.CblCode = symb;

    input.input = ret;
    return input;
  }

  getHoldings(data) {
    const req: any = { AccountNumber: data.AccountNumber };
    return this.httpService.post(gatewayConfig.APIServices.notesHoldings.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getSettlementAccounts(AccountNumber, AccountCurrency) {
    const req: SettlementAccountsReq = {
      Action: "SELL",
      AccountNumber: AccountNumber,
      AccountCurrency
    };
    return this.httpService.post(gatewayConfig.APIServices.notesSettlementAccounts.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getQuote(code) {
    const req: any = { Action: "SELL", CblCode: code }
    return this.httpService.post(gatewayConfig.APIServices.notesInit.url, req);
  }

  getPdfDoc = (DealId, DocumentType): Observable<any> => {
    return this.httpService.post(
      gatewayConfig.APIServices.notesGetPDF.url,
      {
        DealId,
        DocumentType
      },
      { responseType: 'blob' }
    );
  }
}
