import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class OtvcPromptModalSevice {
  constructor(private httpService: HttpService) { }


  sendOtvcRequested(req, url: string) {
    return this.httpService.post(url,
      req, { params: { skiploading: 'true' } }
    );
  }

  verifyOtvc(url, req) {
    let params: any = { skiploading: 'true' };
    return this.httpService.post(url,
      req, { params: params }
    );
  }

  tempPasswordInit() {
    return this.httpService.post(gatewayConfig.APIServices.OTVCTempInit.url,
      {}
    );
  }
}
