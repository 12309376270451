import { Component, OnInit, OnDestroy, ViewChild, Input, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { Subject, Subscription } from 'rxjs';
import { PasswordCriteriaDirective } from '../../directive/password-criteria-directive';
import { ModalService } from '../modal/services/modal.service';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { userIdCriteriaDirective } from '../../directive/userId-criteria-directive';
import { CommonService } from '../../services/common.service';

declare let require: any;

@Component({
  selector: '[appPasswordTooltip]',
  templateUrl: './password-tooltip.component.html',
  styleUrls: ['./password-tooltip.component.scss']
})
export class PasswordTooltipComponent implements OnInit, OnDestroy {

  lowerCase;
  upperCase;
  number;
  minLength;
  similarChar;
  specialChar;
  sequencialChar;
  test;
  userIdspecialChar;
  userIdLetter;
  userIdLength;
  userIdCriteriaMatch: boolean = false;
  tradingPasswordHeading;
  pwdTradingHeading;
  subscriptions = new Subscription();
  @Input() heading;
  @Input() showAlways;
  passwordStrength;
  passwordStrengthLabel;
  passwordMatch:boolean;
  notAllowedSpecialChar: boolean;
  formError: any = {
   
  };


  changingValue: Subject<any> = new Subject();
  @ViewChild('suggestionsTplRef', { static: true }) suggestionsTplRef;
  @ViewChild('strengthTplRef', { static: true }) strengthTplRef;
  @ViewChild('fieldErrorTplRef', { static: true }) fieldErrorTplRef;

  showPasswordTooltip: boolean = false;
  showUserIdTooltip: boolean = false;
  userIdCriteria;

  constructor(public modalservice: ModalService, public appStore: AppStore,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    public globalcontent: GlobalContentStore,
    private pwdDirective: PasswordCriteriaDirective,
    private userIdDirective: userIdCriteriaDirective,
    private commonService: CommonService) { 
  }
  ngOnInit() {
    if(this.showAlways == 'wgPassword') {
      this.showPasswordTooltip = true;
    }
    if(this.showAlways == 'wgUserId') {
      this.showUserIdTooltip = true;
    }
    this.subscriptions.add(this.pwdDirective.passwordStrength.subscribe(pwdStrength => {
      this.passwordStrength = pwdStrength
    }));
    this.subscriptions.add(this.pwdDirective.passwordStrengthLabel.subscribe(pwdStrengthLabel => {
      this.passwordStrengthLabel = pwdStrengthLabel
    }));
    this.subscriptions.add(this.pwdDirective.passwordStrengthDetails.subscribe(pwdCriteria => {
      this.lowerCase = pwdCriteria.lowerCase;
      this.upperCase = pwdCriteria.upperCase;
      this.minLength = pwdCriteria.minLength;
      this.number = pwdCriteria.number;
      this.specialChar = pwdCriteria.specialChar;
      this.similarChar = pwdCriteria.similarChar;
      this.sequencialChar = pwdCriteria.sequencialChar;
    }));
    this.subscriptions.add(this.pwdDirective.pwdTooltipDialog.subscribe(pwdComponent => {
      this.showPasswordTooltip = pwdComponent
    }));
    this.subscriptions.add(this.userIdDirective.userIdStrengthDetails.subscribe(userIdCriteria => {
      this.userIdLength = userIdCriteria.userIdLength;
      this.userIdLetter = userIdCriteria.userIdletter;
      this.userIdspecialChar = userIdCriteria.userIdspecialChar

    }));
    this.subscriptions.add(this.userIdDirective.userIdTooltipDialog.subscribe(userIdComponent => {
      this.showUserIdTooltip = userIdComponent
    }));
    this.subscriptions.add(this.userIdDirective.userIdCriteriaMatch.subscribe(userIdComponent => {
      this.userIdCriteria = userIdComponent
    }));
    if(!this.heading) {
      this.heading = this.globalcontent.text.signOnPasswordHeading;
    }
    this.subscriptions.add(this.pwdDirective.notAllowedSpecialCharLabel.subscribe(obj => {
          this.notAllowedSpecialChar = obj.toggle;
          if(obj.charList && obj.charList.length) {
            if (this.notAllowedSpecialChar == false) {
              this.formError.message = this.globalcontent.text.passwordNotallowedText.replace('< >', obj.charList.join(' '));
            }
          }
        }));
    this.renderTemplate();
  }

  renderTemplate() {
    this.viewContainer.createEmbeddedView(this.suggestionsTplRef);
    this.cdr.markForCheck();
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }


}
