import { BaseData } from 'src/app/shared/models/base-data';

export class TouchIDConfirmData extends BaseData {
    constructor() {
        super();
        this.input = {
            password: ''
        };
    }
}

export interface TouchIDConfirmInputs {
    password: string;
}

export class BiometricVerify {
    Password: string;
}

export class BiometricLog {
    Status: string;
}