<div class="{{style}}" [class.overflowDiv]="isStickeyHeader" tabindex="0">
    <table cellspacing="0" cellpadding="0">
        <thead *ngIf="displayHeader" [class.sticky]="isStickeyHeader">
            <tr>
                <th *ngFor="let column of tableMeta; let i = index" [class.first-th]="i === 0"
                    [ngClass]="lang == 'en'? 'th-en' : 'th-fr'">
                    <div class="column-outside">
                        <button class="sortable-header" (click)="sortColumn(column)" *ngIf="column.isSortable; else defaultHeader">
                            <div class="column_name_container"><span class="column_name">{{column.headerName}}</span></div>
                            <div *ngIf="toggledColumn && toggledColumn.headerName == column.headerName">
                                <span class="aerrow color03" [ngClass]="{
                                'icon-Arrow-Down': currentSortOrder == 'DESC',
                                'icon-Arrow-Up': currentSortOrder == 'ASCE'
                            }" (click)="sortColumn(column)" role="button"
                                    aria-label="pageContent.text.sort_aria_label">
                                    <span class="sr-only">{{pageContent.text.sort_hidden_text}}
                                        {{column.headerName}}</span>
                                </span>
                            </div>
                        </button>
                        <ng-template #defaultHeader>
                            <div><span class="column_name">{{column.headerName}}</span></div>
                        </ng-template>
                        
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="isMissingInput == true">
                <tr>
                    <td class="centerText" colspan="100%">
                        <div class="centerMsg">
                            
                            <div>
                                {{pageContent.text.noDateHeader}} <br *ngIf="pageContent.text.noDateHeader"> {{pageContent.text.noDateText}}
                            </div>
                            
                        </div>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="isMissingInput == false">
                <tr *ngFor="let row of tableData.slice(startIdx,endIndx)">
                    <ng-container *ngFor="let col of tableMeta">
                        <td *ngIf="col.dataName != 'Action'">
                            <ng-container *ngIf="col.properties; else defaultCell">
                                <ng-container *ngIf="col.properties.isClickable">
                                    <a href="javascript:void(0);" (click)="symbolClicked(row)">{{row[col.dataName] |
                                        uppercase}}</a>
                                </ng-container>
                                <ng-container *ngIf="col.properties.dateFormat">
                                    <span *ngIf="col.dateFormat != 'none'">{{row[col.dataName] | date:
                                        col.dateFormat}}</span>
                                    <span *ngIf="col.dateFormat == 'none'">{{valueOrDash(row[col.dataName])}}</span>
                                </ng-container>
                                <ng-container *ngIf="col.properties.isContentField">
                                    <span
                                        *ngIf="row[col.dataName] && row[col.dataName].Content">{{valueOrDash(row[col.dataName].Content)}}</span>
                                    <span *ngIf="row[col.dataName] && !row[col.dataName].Content">{{row[col.dataName] |
                                        currency}}</span>
                                    <span *ngIf="!row[col.dataName]">-</span>
                                </ng-container>
                                <ng-container *ngIf="col.properties.currFormat">
                                    <span [innerHTML]="row[col.dataName] | currencyFormatter:US:col.currFormat"></span>
                                </ng-container>
                                <ng-container *ngIf="col.properties.dataName2">
                                    <span *ngIf="isDefined(row[col.dataName2] + row[col.dataName])">
                                        {{pageContent.text.Q}}{{row[col.properties.dataName2]}} {{row[col.dataName]}} 
                                    </span>
                                    <span *ngIf="!isDefined(row[col.properties.dataName2] + row[col.dataName])">
                                        -
                                    </span>
                                </ng-container>
                                <ng-container
                                    *ngIf="col.properties.ellipsis">
                                    <span class="company-text" [attr.title]="row[col.dataName]"> {{valueOrDash(row[col.dataName])}}</span>
                                </ng-container>
                            </ng-container>
                            <ng-template #defaultCell>
                                <span class="cell-text"> {{valueOrDash(row[col.dataName])}}</span>
                            </ng-template>
                        </td>
                        <td *ngIf="col.dataName == 'Action'">
                            <div class="trade-icon">
                                <span class="icon-Trade icon-size" (click)="trade(row)" role="button" tabindex="0"
                                    aria-label="Trade">
                                </span>
                            </div>
                        </td>
                        <td *ngIf="optionalColTemplate">
                            <ng-container [ngTemplateOutlet]="optionalColTemplate">
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>

    </table>
</div>

<section class="pagination" *ngIf="isPagination && recordsEachPage && tableData.length > 0">
    <button *ngIf="curPage > 1" (click)="goToPrevPg()" [attr.aria-label]="pageContent.text.leftArrowHdnTxt">
        <span class="icon-Chevron-Left arrowIconLeft color03" aria-hidden="true"></span>
    </button>
    <span class="page-details">{{curPage}}{{pageContent.text.of}}{{totalPage}}</span>
    <button *ngIf="curPage !== totalPage" (click)="goToNextPg()" [attr.aria-label]="pageContent.text.RightArrowHdnTxt">
        <span class="icon-Chevron-Right arrowIconRight color03" aria-hidden="true"></span>
    </button>
</section>