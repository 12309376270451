<div class="item-container" *ngIf="!item.placeholder; else placeholder">
    <button type="button" class="launch-item"
        [ngClass]="{'launch-item-active': item.active, 'launch-item-inactive': !item.active, 'launch-item-disabled': item.disabled,
            'hidden-chat' : (hideChat && item.id == 'genesysChatBtn')
        }"
        (click)=" itemClick(item)" [attr.disabled]="item.disabled ? 'true' : null" [attr.id]="item.id">
        <div class="launch-item-container">
            <i class="launch-item-i" *ngIf="item.iconName;else imagePath" class="{{item.icon}}"></i>
            <ng-template #imagePath>
                <img class="launch-item-img" *ngIf="!item.active && item.imagePath" src="{{item.imagePath}}" alt="">
                <img class="launch-item-img" *ngIf="item.active && item.imagePathActive" src="{{item.imagePathActive}}"
                    alt="">
            </ng-template>
            <span class="item-icon {{item.iconName}}"
                [ngClass]="{'icon-active': item.active, 'icon-inactive': !item.active, 'hidden-chat': (!chatOpen || chatOpen == 'false') && item.id == 'genesysChatBtn'}" alt=""></span>
            <span [ngClass]="item.active ? 'launch-item-span active' : 'launch-item-span'">{{item.label}}</span>
        </div>
    </button>
    <div *ngIf="item.active" class="active-container">
        <div class="active-bar"></div>
    </div>
</div>
<ng-template #placeholder>
    <div class="item-container">
        <div class="launch-item" [ngClass]="{'launch-item-active': item.active, 'launch-item-inactive': !item.active, 'launch-item-disabled': item.disabled}">
            <div class="launch-item-container placholder-container">
                <ng-container *ngIf="item.chat">
                    <i class="launch-item-i" *ngIf="item.iconName;else imagePath" class="{{item.icon}}"></i>
                    <span class="item-icon {{item.iconName}}"
                        [ngClass]="{'icon-active': item.active, 'icon-inactive': !item.active}" alt=""></span>
                    <span [ngClass]="item.active ? 'launch-item-span active' : 'launch-item-span'">{{item.label}}</span>
                    <!-- <div id="genesys-chat-container" *ngIf="!isApp && !hideChat">
                        <app-genesys-postsignon-chat  [currentPage]="currentPage()" [selectedLob]="selectedLob()"></app-genesys-postsignon-chat>
                    </div> -->
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
