import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { RegularInvestmentPlanDetailsData } from './regular-investment-plan-details-data';

@Injectable({ providedIn: 'root' })
export class RegularInvestmentPlanDetailsStore extends PageStore<RegularInvestmentPlanDetailsData>  {

    constructor() {
        super(new RegularInvestmentPlanDetailsData());
    }

    public static paramsAvailable(): boolean {
        return true;
    }
}
