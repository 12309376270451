import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SpinnerService } from './service/spinner.service';
import { isDefined } from 'src/app/shared/services/utils.service';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit , OnDestroy{

  loading = false;
  showIeSpinner = false;
  subscription: any;
  showLoadingText = false;
  intervalFunction: any;
  showStillProcessing = false;
  spinnerLoadingText: any;
  hideBackground: boolean = false;

  constructor(
    private spinnerService: SpinnerService,
    public globalContent: GlobalContentStore
  ) {
    this.subscription = this.spinnerService
      .onLoadingChanged
      .subscribe(isLoading => {
        // document.getElementById('imageDivId').setAttribute('aria-label', 'loading');
        if (this.hideBackground && !isLoading) {
          setTimeout(() => {
            this.loading = isLoading;
          }, 2000);
        } else {
          this.loading = isLoading;
        }
        this.showLoadingText = this.spinnerService.displayLoadingText;
        if (!isLoading) {
          
          if (this.intervalFunction){
            this.spinnerService.displayLoadingText = false;
            this.showStillProcessing = false;
            clearInterval(this.intervalFunction);
            this.intervalFunction = undefined;
          }

          window.requestAnimationFrame(() => {
            if (isDefined(this.spinnerService.focusElementId)) { // focus element Id lasts for only one load then it resets to default
              const sp = document.getElementById(this.spinnerService.focusElementId);
              if (sp != null) {
                sp.focus();
              }
            }
            this.spinnerService.setFocusElement('focusElement');
            this.spinnerService.setShowBackground();
          });
        }
        else {
          this.hideBackground = this.spinnerService.getHideBackground();
          if(this.showLoadingText){
            setTimeout(() => {
              this.intervalFunction = setInterval(() => { 
                this.showStillProcessing = true;
                this.spinnerLoadingText = globalContent.text.stillProcessingSpinnerText;
                let el = document.getElementById("stillProcessTextId");
                if (isDefined(el)) {
                  el.setAttribute('tabindex', '0');
                }
                setTimeout(() => {
                  this.spinnerLoadingText = "";
                }, 1000);
              }, 3000);
            },1000);
          }
        }
      });
  }

  ngOnInit() {
    this.showIeSpinner = this.isIE();
  }

  isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11
    return (msie > 0 || trident > 0);
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
