import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SettlementAccountsReq, BuyingPowerReq, HoldingsReq, QuoteReq } from '../data/mutual-funds-landing-data';
import { AccountHoldingsReq } from '../../../tradeOptions/landing/data/trade-options-landing-data';
import { MutualFundsLandingStore } from '../data/mutual-funds-landing-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class MutualFundsLandingService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (MutualFundsLandingStore.crossFlow) {
      const acct = MutualFundsLandingStore.crossFlow.accountNumber;
      if (acct) {
        const ret = [input[0], input[1]];
        ret[1] = this.prepareHoldings(ret[1], acct);
        //ret[2] = this.prepareSett(ret[2], acct, MutualFundsLandingStore.crossFlow.action, MutualFundsLandingStore.crossFlow.accountCurrency);

        return ret;
      }
    } else if (MutualFundsLandingStore.savedInfo) {
      const acct = MutualFundsLandingStore.savedInfo.accountNumber;
      if (acct) {
        const ret = input;
        ret[1] = this.prepareHoldings(ret[1], acct);
        ret[2] = this.prepareSett(ret[2], acct, MutualFundsLandingStore.savedInfo.inputs.action, MutualFundsLandingStore.savedInfo.accountCurrency);

        return ret;
      }
    }
    return [input[0]];
  }

  prepareHoldings(input, accNum) {
    const ret = input.input;

    ret.AccountNumber = accNum;

    input.input = ret;
    return input;
  }

  prepareSett(input, accNum, action, curr) {
    const ret = input.input;

    ret.AccountNumber = accNum;
    ret.Action = action;
    if (curr) {
      ret.AccountCurrency = curr;
    }

    input.input = ret;
    return input;
  }

  getSettlementAccounts(data) {
    const req: SettlementAccountsReq = {
      Action: data.Action,
      AccountNumber: data.AccountNumber,
      Type: data.Type
    }
    req.AccountCurrency = data.AccountCurrency;
    return this.httpService.post(gatewayConfig.APIServices.getMutualFundSettlementAccount.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getBuyingPower(data) {
    const req: BuyingPowerReq = { AccountNumber: data.AccountNumber };
    return this.httpService.post(gatewayConfig.APIServices.getMutualFundBuyingPower.url,
      req
    );
  }
  getHoldings(data) {
    const req: HoldingsReq = { AccountNumber: data.AccountNumber, FiType: "M" };
    return this.httpService.post(gatewayConfig.APIServices.getOrderHoldings.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getQuotes(data) {
    const req: QuoteReq = { SymbolName: data.SymbolName };
    return this.httpService.post(gatewayConfig.APIServices.getMutualFundQuote.url,
      req, { params: { skiploading: 'true' } }
    );
  }
}
