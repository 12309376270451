import { Component, OnInit } from '@angular/core';
import { buildInfo } from 'src/app/config/build-info';
import { EnvService } from 'src/app/env.service'

@Component({
  selector: 'app-build-info',
  templateUrl: './build-info.component.html'
})
export class BuildInfoComponent {
  buildDetails = buildInfo;
  constructor(
    private env: EnvService
  ) {
  }

  getRegion() {
    return this.env.region;
  }

}
