/** @format */

import { RouteGroup } from "../shared/models/route-config";

export const CashtransferConfig: RouteGroup = {
  "/txn/transferHub": {
    name: "transferHub",
    service: "TransferCentralService",
    store: "TransferCentralStore",
    resolveContent: "transferCentral",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "cashtransferInit",
        get request(): any {
          return {
            CashTransferAcctType: "FROM",
            IncludeBalance: true,
          };
        },
      },
      {
        id: "init",
        restUrl: "stocksInit",
        get request(): any {
          return {
            IncludeBalance: true,
          };
        },
      },
    ],
    trackingData: {
      hierarchy: "transfer-assist",
      name: "landing",
      nameGA: "Transfer Hub",
      events: { pageView: true },
    },
  },
  "/txn/cashTransfer/landing": {
    name: "CashtransferLanding",
    service: "CashtransferLandingService",
    store: "CashtransferLandingStore",
    resolveContent: "cashtransferlanding",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "cashtransferInit",
        get request(): any {
          return {
            // DatePickerAdditionalInfoReq: {},
            // DatePickerPageContentReq: {
            //     PageName: "CustomDatePicker"
            // },
            // FromAccountListReq: {
            //     CashTransferAcctType: "FROM",
            //     IncludeBalance: true,
            // },
            // InitPageReq: {},
            // PageContentReq: {
            //     PageName: "CashTransferLanding"
            // },
            // PermissionInfoReq: {},
            CashTransferAcctType: "FROM",
            IncludeBalance: true,
          };
        },
      },
    ],
    trackingData: {
      hierarchy: "cash-transfers",
      name: "details",
      nameGA: "Cash Transfer",
      formInfo: { name: "obr-cash-transfers", stepName: "details" },
      events: { formStep: true, formView: true, pageView: true },
    },
    brazeID: "viewed_cash_transfer_details"
  },
  "/txn/cashTransfer/verify": {
    name: "CashtransferVerify",
    service: "CashtransferVerifyService",
    store: "CashtransferVerifyStore",
    resolveContent: "cashtransferverification",
    policy: {
      navFrom: {
        url: ["/txn/cashTransfer/landing"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
      fallbackRoute: "/txn/cashTransfer/landing",
    },
    restService: [
      {
        id: "verify",
        restUrl: "cashtransferVerify",
        get request(): any {
          return {
            // CashOrderReq:{
            //     OrderDetails:{
            //         Amount: 50,
            //         Date: "20190712",
            //         FromAccount:{
            //             AccountNumber: "57000011",
            //             AccountType: "INV",
            //             Currency: "CAD"
            //         },
            //         ToAccount:{
            //             AccountNumber: "57112624",
            //             AccountType: "INV",
            //             Currency: "CAD"
            //         }
            //     }
            // },
            CheckSavedTradingPasswordReq: {},
            // PageContentReq:{
            //    PageName:'CashTransferVerification'
            //  }
          };
        },
      },
    ],
    trackingData: {
      hierarchy: "cash-transfers",
      name: "verification",
      nameGA: "Cash Transfer - Verification",
      formInfo: { name: "obr-cash-transfers", stepName: "verification" },
      events: { formStep: true, pageView: true },
    },
  },
  "/txn/cashTransfer/confirm": {
    name: "CashtransferConfirm",
    service: "CashtransferConfirmService",
    store: "CashtransferConfirmStore",
    resolveContent: "cashtransferconfirmation",
    policy: {
      navFrom: {
        url: ["/txn/cashTransfer/verify"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "submit",
        restUrl: "cashtransferSubmit",
        get request(): any {
          return {
            // PageContentReq: {
            //     PageName: 'CashTransferConfirmation'
            // },
            //  SubmitOrderReq: {
            //    TradingPassword: "56785678",
            //    SaveTradingPassword: false
            //  }
            // Token: this.token
          };
        },
      },
    ],
    trackingData: {
      hierarchy: "cash-transfers",
      name: "confirmation",
      nameGA: "Cash Transfer - Confirmation",
      formInfo: { name: "obr-cash-transfers", stepName: "confirmation" },
      events: {
        formStep: true,
        formSubmit: true,
        pageView: true,
        transactionComplete: true,
      },
      transaction: {
        mainField: "inFlow",
        fieldNames: {
          type: "cash",
          amount: "Amount.Data",
          units: "1",
          serviceFee: "",
          frequency: "",
          currency: "FromAccount.Currency",
          from: "FromAccount.AccountTypeDesc",
          to: "ToAccount.AccountTypeDesc",
          fromAccountType: "FromAccount.AccountType",
          toAccountType: "ToAccount.AccountType",
          currencyPayFrom: "FromAccount.Currency",
          currencyPayTo: "ToAccount.Currency",
          institutionalNameFrom: "FromAccount.BankAccountInfo.InstitutionName",
          institutionalNameTo: "ToAccount.BankAccountInfo.InstitutionName",
        },
      },
    },
  },
  "/txn/exchangeCurrency/landing": {
    name: "ExchangeCurrency",
    service: "use-proxy",
    store: "ExchangeCurrencyStore",
    resolveContent: "exchangecurrency",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "cashtransferInit",
        get request(): any {
          return {
            IncludeBalance: false,
          };
        },
      },
    ],
    trackingData: {
      hierarchy: "exchange-currency",
      name: "landing",
      nameGA: "Exchange Currency",
      formInfo: { name: "obr-exchange-currency", stepName: "landing" },
      events: { pageView: true },
    },
    brazeID: "viewed_exchange_currency"
  },
};
