<div [attr.id]="makeID()">
  <section class="errorContainer" [ngClass]="spacing" [attr.style]="!hasError ? 'display: none !important' : ''">
    <div aria-hidden="true" class="message-graphic">
      <i class="icon-Error-Warning-Fill"></i>
    </div>
    <div class="message-text" aria-live="assertive">
      <div [innerHtml]="message"></div>
      <div *ngIf="errorCode" class="errorCode">{{ '{' }}{{globalContent.text.result}} #{{errorCode}}{{ '}' }}</div>
    </div>
  </section>
</div>
<app-modal isActive={{!hidePage}} [attr.id]="'crossflow-error' + uniqueId" [id]="'crossflow-error' + uniqueId" style='display: none;'
  (keydown.esc)="closeModal('crossflow-error')" [targetElement]="">
  <div class="cibc-crossflow-error-modal-body" aria-controls="crossflow-error">
    <div class="cibc-crossflow-error-modal-content">
      <div class="errorTitle align-center" [attr.id]="'crossFlowErrorModalHeader' + uniqueId" tabindex="-1">{{crossFlowModalCode}}
      </div>
      <div class="errorMessage marginTop10 align-center">{{crossFlowModalMessage}}</div>
      <div class="marginTop30">
        <section [ngClass]="">
          <button type="button" id="'customErrorButton' + uniqueId" class='button primary marginBottom10-small' (click)="closeModal('crossflow-error')">
            {{globalContent.text.OK}}
          </button>
        </section>
      </div>

    </div>
  </div>
</app-modal>