<div *ngIf="disclaimersContent && showDisclaimer" class="disclaimerContainer marginBottom20 marginTop20" [attr.id]="makeID()">
  <div [ngClass]="{'disclaimerBorderBottom' : expandDisclaimer}">
    <button id="disclaimer_btn_id" type="button" class="disclaimerButton" (click)="toggleDisclaimer()"
      attr.aria-expanded="{{expandDisclaimer}}"
      attr.aria-label="{{expandDisclaimer ? disclaimersContent.text.collapse : disclaimersContent.text.expand}}">
      <span [ngClass]="{'icon-Plus': !expandDisclaimer, 'icon-Minus': expandDisclaimer}" aria-hidden="true"></span>
      <span class="disclaimerLabel W16m color02" aria-hidden="true">{{disclaimersContent.text.disclaimer}}</span>
    </button>
    <div [ngClass]="{'hide-list': !expandDisclaimer}" class="color03 disclaimerDescription ">
      <div class="disclaimerDescriptionValue">
        <ng-container *ngIf="disclaimersList && !disclaimersList[0].hasOwnProperty('isList') else otherItems ">
          <ul class="A12">
            <ng-container *ngFor="let line of disclaimersList | keyvalue">
              <li *ngIf="!line.value.hasOwnProperty('type')" [innerHtml]="line.value | safe:'html'"></li>
              <li *ngIf="line.value.hasOwnProperty('type') && line.value.type == 'link'">
                <a class="underline" href="javascript:void(0);" (click)="linkClicked(line.value.page, line.value.extra)"
                  [innerHtml]="line.value.text | safe:'html'"></a>
              </li>
              <li *ngIf="line.value.hasOwnProperty('type') && line.value.type == 'permission' && isPermission(line.value.permission)"
               [innerHtml]="line.value.text | safe:'html'">
              </li>
            </ng-container>
          </ul>
        </ng-container>
        <ng-template #otherItems>
          <ng-container *ngFor="let line of disclaimersList | keyvalue" class="A12">
            <ng-container *ngIf="line.value.isList else notList">
              <ul>
                <li class="A12" *ngFor="let item of line.value.html" [innerHtml]="item | safe:'html'">
                </li>
              </ul>
            </ng-container>
            <ng-template #notList>
              <div class="A12 paddingBottom10" [innerHtml]="line.value.html | safe:'html'">
              </div>
            </ng-template>
          </ng-container>
        </ng-template>
      </div>
      <ng-content *ngIf="myAccount"></ng-content>
    </div>
  </div>
</div>