import { BaseData } from 'src/app/shared/models/base-data';

export class QuickTradeStocksVerifyData extends BaseData {
  constructor() {
    super();
    this.input = {
      tradingPassword: "",
      savePassword: false
    };
  }
}

export interface QuickTradeStocksVerifyInputs {
  tradingPassword: string;
  savePassword: boolean;
}