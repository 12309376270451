import { RouteGroup } from '../shared/models/route-config';

export const IposAndNewIssuesConfig: RouteGroup = {

    '/txn/iposandnewissues': {
        name: 'IposAndNewIssues',
        service: 'IposAndNewIssuesService',
        servicePath: "txn/ipos-and-newissues/services/ipos-and-newissues",
        store: 'IposAndNewIssuesStore',
        resolveContent: 'iposandnewissues',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [{
                id: 'init',
                restUrl: 'getIPOInit',
                get request(): any {
                    return {
                    };
                }
        }],
        trackingData: {
            hierarchy: "trading,ipos-and-new-issues",
            name: "view-all",
            nameGA: "IPOs and New Issues",
        },
        brazeID: "viewed_ipos_and_new_issues"
    }
};
