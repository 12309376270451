import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { HelpData } from './help-data';

@Injectable({ providedIn: 'root' })
export class HelpStore extends PageStore<HelpData> {

    constructor() {
        super(new HelpData());
    }

}
