import { Injectable } from '@angular/core';
import { PageStore } from '../../../../shared/models/page-store';
import { AboutMyInvestmentCounsellorData } from './about-my-investment-counsellor.data';

@Injectable({ providedIn: 'root' })
export class AboutMyInvestmentCounsellorStore extends PageStore<AboutMyInvestmentCounsellorData> {

    constructor() {
        super(new AboutMyInvestmentCounsellorData());
    }

}
