import { HostListener, Directive, Input, OnInit, EventEmitter, ElementRef, AfterViewInit, ViewChild, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appIFrameDragDirective]'
})
export class IframeDragDirective implements OnInit {

    // Drag operations using HTML drag events
    initialMouseX;
    initialMouseY;
    constructor(public element: ElementRef,
        private renderer: Renderer2) {}

    @HostListener('dragstart', ['$event'])
    onDragStart(event: DragEvent) {
        this.initialMouseX = event.clientX - this.element.nativeElement.offsetWidth / 2;
        this.initialMouseY = event.clientY - this.element.nativeElement.offsetHeight / 2;
        event.dataTransfer.setData('text/plain', 'Drag iframe');
        // event.dataTransfer.setDragImage(this.element.nativeElement, this.initialMouseX, this.initialMouseY)
    }

    @HostListener('document:dragover', ['$event'])
    onDragOver(event: DragEvent) {
        event.preventDefault();
    }

    @HostListener('document:drop', ['$event'])
    onDrop(event: any) {
        event.preventDefault();
        const dragEl = event.dataTransfer.getData("text/plain");
        if(dragEl != 'Drag iframe') {
            return false;
        }
        const dropX = event.clientX
        const dropY = event.clientY
        this.renderer.setStyle(this.element.nativeElement, 'top', `${dropY}px`, RendererStyleFlags2.Important);
        this.renderer.setStyle(this.element.nativeElement, 'left', `${dropX}px`, RendererStyleFlags2.Important);
        //console.log('<--- Check if parent element is being dropped --->', this.element.nativeElement.parentElement)
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.renderer.removeStyle(this.element.nativeElement, 'top');
        this.renderer.removeStyle(this.element.nativeElement, 'left');
    }

    ngOnInit(){

    }

    // Another way of doing drag operations using mouse events


    // mouseup = new EventEmitter<MouseEvent>();
    // mousedown = new EventEmitter<MouseEvent>();
    // mousemove = new EventEmitter<MouseEvent>();
    // @ViewChild('iframe') iframe: ElementRef;

    // mousedrag: Observable<{ top, left }>;
    // initialOffset: { top: number, left: number }

    // @HostListener('mouseup', ['$event'])
    // onMouseup(event: MouseEvent) {
    //     const iframe = this.element.nativeElement.querySelector('iframe');
    //     if(iframe){
    //         iframe.style.overflow = 'auto';
    //         this.initialOffset = null
    //     }
    // }

    // @HostListener('mousedown', ['$event'])
    // onMousedown(event: MouseEvent) {
    //     this.mousedown.emit(event);
    //     event.preventDefault();
    //     event.stopPropagation();
    //     this.initialOffset = {
    //         top: event.clientY - this.element.nativeElement.getBoundingClientRect().top,
    //         left: event.clientX - this.element.nativeElement.getBoundingClientRect().left,
    //     }
    // }

    // @HostListener('document:mousemove', ['$event'])
    // onMousemove(event: MouseEvent) {
    //     if(this.initialOffset){
    //     this.mousemove.emit(event);
    //     event.preventDefault();
    //     event.stopPropagation();
    //     }
    // }

    // onIframeLoad(event: MouseEvent) {
    //     const iframe = event.target as HTMLIFrameElement;
    //     iframe.contentWindow.document.addEventListener('mousedown', (event) =>{
    //         event.preventDefault();
    //     })
    // }

    // constructor(public element: ElementRef,
    //     private renderer: Renderer2) {
    // this.element.nativeElement.style.position = 'absolute';
    // this.element.nativeElement.style.cursor = 'pointer';

    // this.mousedrag = this.mousedown.pipe(map(event => ({
    //         top: event.clientY - this.element.nativeElement.getBoundingClientRect().top,
    //         left: event.clientX - this.element.nativeElement.getBoundingClientRect().left,

    // })),
    // switchMap(

    //     () => this.mousemove.pipe(map(pos => ({
    //         top: pos.clientY - this.initialOffset.top,
    //         left: pos.clientX - this.initialOffset.left,
    //     })),
    //     takeUntil(this.mouseup)
    // )));

    // const iframe = this.element.nativeElement.querySelector('iframe');
    // if(iframe){
    //     iframe.addEventListener('load', this.onIframeLoad.bind(this));
    // }
    // }

    // ngOnInit() {
    //     this.mousedrag.subscribe({
    //         next: pos => {
    //             this.element.nativeElement.style.top = pos.top + 'px';
    //             this.element.nativeElement.style.left = pos.left + 'px';
    //         }
    //     });
    // }
    // ngAfterViewInit() {
    //      this.iframe.nativeElement.addEventListener('load', this.onIframeLoad)
    // }
}
