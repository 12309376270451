import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../modal/services/modal.service';
import { isDefined, openWindow } from 'src/app/shared/services/utils.service';
import { AccountSelectorModalSevice } from './services/account-selector-modal.service';
import { GlobalContentStore } from '../../store/global-content-store';
import { AppStore } from '../../models/app-store';
import { Lob } from '../../models/lob.enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { TrackingService } from '../../services/tracking.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';

@Component({
  selector: 'app-account-selector-modal',
  templateUrl: './account-selector-modal.component.html',
  styleUrls: ['./account-selector-modal.component.scss']
})
export class AccountSelectorModalComponent implements OnInit {

  @Input() public accountList: any;
  @Input() public content: any;
  @Input() public buyingPowerContent: any;
  @Input() public mode: string;
  @Input() public mobileContained = false;
  @Input() public buyingPowerLoading: boolean;
  @Input() public placeholder: string;
  @Input() public labelId: string;
  @Input() public error: string;
  @Input() public combinedAccount: any;
  @Input() public showBalance: boolean;
  @Input() public displayMargin = true;
  @Input()
  get accountIndex() {
    return this.accountIndexValue;
  }

  set accountIndex(i) {
    this.accountIndexValue = i;
  }
  @Input()
  get subAccountIndex() {
    return this.subAccountIndexValue;
  }

  set subAccountIndex(i) {
    this.subAccountIndexValue = i;
  }

  @Output() returnAccount: EventEmitter<any> = new EventEmitter<any>();
  @Output() otherSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() flinksSelection: EventEmitter<any> = new EventEmitter<any>();

  public selectMessage: string;
  public accountIndexValue: number;
  public subAccountIndexValue: number;
  public loading = true;
  @ViewChild("returnRef") private returnRef: ElementRef<HTMLElement>

  public uniqueId;

  constructor(
    public router: Router,
    public modalservice: ModalService,
    public service: AccountSelectorModalSevice,
    public globalStore: GlobalContentStore,
    public appStore: AppStore,
    private commonService: CommonService,
    private trackingService: TrackingService,
    private persistenceService: PersistenceService
  ) { }

  ngOnInit() {
    this.uniqueId = this.appStore.uniqueId;

    switch (this.mode) {
      case 'CashTransferFrom':
        this.selectMessage = this.content.text.pleaseSelectTransferCashFrom;
        break;
      case 'CashTransferTo':
        this.selectMessage = this.content.text.pleaseSelectTransferCashTo;
        break;
      default:
        this.selectMessage = this.content.text.pleaseSelectAccount;
        break;
    }
  }

  checkLob(l: string) {
    return this.appStore.lob == Lob[l];
  }

  selectAccount(i) {
    this.accountIndex = i;
    this.returnAccount.emit(i);
    this.closeModal();
  }

  selectSubTypeAccount(i, j) {

    // account.subaccountDisplay=balance.AccountTypeDesc;
    // account.AccountType = "";
    // if(this.lob!="cpic") {
    //   account.AccountType = balance.AccountType;
    //   account.Currency=balance.Currency;
    // } else {
    //   account.AccountType = balance.AccountToken;
    //   account.Currency.Data=balance.Currency.Data;
    // }



    //account.Currency = balance.Currency;

    this.accountIndex = i;
    this.subAccountIndex = j;
    if (this.mode == "Trade" || this.mode == "CashTransferFrom" || this.mode == "CashTransferTo") {
      //this.returnAccount.emit(i);
    } else {
      this.returnAccount.emit({ accountIndex: i, subAccountIndex: j });
    }
    this.closeModal();
  }

  openModal() {
    this.modalservice.open('accountSelector' + this.uniqueId, 'accountSelectorClose' + this.uniqueId);

    let pageName: string;
    switch (this.mode) {
      case "Trade":
      case "BuyingPowerSubBalances":
        pageName = 'OrderEntry';
        break;
      case "OrderStatus":
        pageName = 'OrderStatus';
        break;
      case "CashTransferFrom":
        pageName = 'CashTransfer';
        break;
      case "CashTransferTo":
        pageName = 'CashTransfer';
        break;
    }
    // if (pageName != null && this.accountList != null && isDefined(this.accountList) && this.mode !== "CashTransferFrom" && this.mode !== "CashTransferTo" ) {
    //   let payload = {};
    //   payload = { ModuleId: pageName };
    //   this.loading = true;
    //   let acctListObject = new Map((this.accountList).map(accountObj => [accountObj.AccountNumber + '-' + (accountObj.Currency != null ? accountObj.Currency.Data : ''), accountObj]));
    //   this.service.getBuyingPowerList(payload).subscribe(
    //     (data: any) => {
    //       if (data) {
    //         let buyPowerListObject = data.BuyingPowerBalances.reduce(function (obj, item) {
    //           return obj[item.AccountId] = item, obj;
    //         }, {});

    //         let tempaccountList = [];

    //         for (let entry of Array.from(acctListObject.entries())) {
    //           let key = String(entry[0]);
    //           let newObj: any = entry[1];
    //           let accountId = key.substring(0, key.indexOf('-'));
    //           let bp = buyPowerListObject[accountId];
    //           if (bp != null) {
    //             if (bp.ConsolidatedBuyingPower) {
    //               newObj.ConsolidatedBuyingPower = bp.ConsolidatedBuyingPower;
    //             } else {
    //               newObj.BuyingPowerCAD = bp.BuyingPowerCAD;
    //               newObj.BuyingPowerUSD = bp.BuyingPowerUSD;
    //             }
    //             newObj.TimeStamp = bp.TimeStamp;
    //           }
    //           tempaccountList.push(newObj);

    //         }
    //         this.accountList = tempaccountList;
    //         this.loading = false;
    //       }
    //     },
    //     (err) => {
    //     });
    // }
  }

  closeModal() {
    this.modalservice.close('accountSelector' + this.uniqueId, 'accountSelectorButton' + this.uniqueId);
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }
  
  goTo(link, external?) {
    if(external){ 
      openWindow(link, this.appStore);
    }else{
      this.router.navigate([link]);
    }
  }
    

  goToURL(url) {
    if (url === '/txn/userPreferences/link-bank-accounts/landing') {
      if (this.persistenceService.cookieStoreRetrieve("CP_LINKS") != 'FLNKS' || this.appStore.lob != Lob.IE || this.appStore.isApp()) {
        if (this.mode == 'CashTransferFrom') {
          this.sendOmniture('transfers:transfer-cash:from-account-modal:link-bank-account');
        }
        this.router.navigate([url]);
      } else {
        this.closeModal();
        this.flinksSelection.emit(this.uniqueId);
      }
    } else {
      if ( this.mode == 'CashTransferFrom' && url === '/txn/userPreferences/transfer-forms/landing' ) {
        this.sendOmniture('transfers:transfer-cash:from-account-modal:external-brokerage');
      }
      this.router.navigate([url]);
    }
  }

  isPermission(routeEnum) {
    return this.commonService.checkPermissionForRoutes(routeEnum);
  }

  sendOmniture(data: string) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagInteraction(data, true, false));
    }
  }
}
