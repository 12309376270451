import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../modal/services/modal.service';
import { isDefined } from 'src/app/shared/services/utils.service';
import { GlobalContentStore } from '../../store/global-content-store';
import { AppStore } from '../../models/app-store';

@Component({
  selector: 'app-sort-selector-modal',
  templateUrl: './sort-selector-modal.component.html',
  styleUrls: ['./sort-selector-modal.component.scss']
})
export class SortSelectorModalComponent implements OnInit {

  @Input() public sortList: any;
  @Input() public content: any;
  @Input()
  get sortIndex() {
    return this.sortIndexValue;
  }

  set sortIndex(i) {
    this.sortIndexValue = i;
  }
  @Input()
  get ascending() {
    return this.ascendingValue;
  }

  set ascending(b) {
    this.ascendingValue = b;
  }

  @Output() returnSort: EventEmitter<any> = new EventEmitter<any>();
  @Output() returnSortToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortIdRet: EventEmitter<any> = new EventEmitter<any>();

  public sortIndexValue: number;
  public ascendingValue: boolean;
  @ViewChild("returnRef") private returnRef: ElementRef<HTMLElement>

  public uniqueId;

  constructor(public modalservice: ModalService, public globalStore: GlobalContentStore, public appStore: AppStore) { }

  ngOnInit() {
    this.uniqueId = this.appStore.uniqueId;
  }

  selectSort() {
    //this.sortIdRet.emit('sort-list' + this.uniqueId);
    this.returnSort.emit(this.sortIndex);
  }

  toggleAsc() {
    this.ascending = !this.ascending;
    this.sortIdRet.emit('sort-toggle' + this.uniqueId);
    this.returnSortToggle.emit(this.sortIndex);
    //this.closeModal();
  }

  // openModal() {
  //   this.modalservice.open('sortSelector' + this.uniqueId, 'sortSelectorClose' + this.uniqueId);
  // }

  // closeModal() {
  //   this.modalservice.close('sortSelector' + this.uniqueId, 'sortSelectorButton' + this.uniqueId);
  //   window.requestAnimationFrame(() => {
  //     this.returnRef.nativeElement.focus();
  //   });
  // }
}
