import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
//import { PageStore } from '../../../../../shared/models/page-store';

import {SellGicConfirmData} from './sell-gic-confirm-data';

@Injectable({ providedIn: 'root' })
export class SellGicConfirmStore extends PageStore<SellGicConfirmData> {

    constructor() {
        super(new SellGicConfirmData());
    }

}
