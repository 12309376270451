import { Component, OnInit, Inject } from '@angular/core';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { isDefined } from 'src/app/shared/services/utils.service';
import { AppStore } from '../../models/app-store';
import { DOCUMENT } from '@angular/common';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-skip-to-main-content',
  templateUrl: './skip-to-main-content.component.html',
  styleUrls: ['./skip-to-main-content.component.scss']
})
export class SkipToMainContentComponent implements OnInit {
  currentUrl: any;
  constructor(
    public globalContent: GlobalContentStore, 
    private appStore: AppStore,
    private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
  }

  scrollToMainContainer() {
    if (this.isHome()) {
      const focusEl = document.getElementById("floating-sign-on-link");
      if (isDefined(focusEl)) {
        focusEl.blur();
        setTimeout(() => {
          focusEl.tabIndex = -1;
          focusEl.focus();
        }, 100);
      }
    } else if (this.isQnR()) {
      const focusEl = document.getElementById("md-iframe");
      if (isDefined(focusEl)) {
        focusEl.blur();
        setTimeout(() => {
          focusEl.tabIndex = -1;
          focusEl.focus();
        }, 100);
      }
    } else {
      if (this.appStore.isApp('app')) {
        document.getElementById('page-content-container') ? document.getElementById('page-content-container').scrollIntoView() : '';
        document.getElementById('page-content-container').scrollTop -= 10;
      }
      const el = document.getElementById('page-content-container');
      const focusEl = el.getElementsByTagName('h1')[0];
      if (isDefined(focusEl)) {
        focusEl.blur();
        setTimeout(() => {
          focusEl.tabIndex = -1;
          focusEl.focus();
        }, 100);
      }
    }
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  isHome() {
    let split = window.location.hash.split('/');
    return split.length > 1 && split[1] == 'home';
  }

  isQnR() {
    if (this.appStore.state.state.currentUrl.indexOf('/txn/quotesResearch/') == 0 && this.appStore.state.state.currentUrl.indexOf('/txn/quotesResearch/factSheets') == -1
      && this.appStore.state.state.currentUrl.indexOf('/txn/quotesResearch/eventCalendar') == -1) {
      return this.appStore.state.state.currentUrl.indexOf('/txn/quotesResearch/optionCentre') != 0;
    }
    return false;
  }
}
