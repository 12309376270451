import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AppStore } from 'src/app/shared/models/app-store';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { ObservableSubscriptionService } from '../../shared/services/observable-subscription.service';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { CommonService } from '../../shared/services/common.service';
import { Location } from '@angular/common';
import { GlobalSignOffService } from '../services/global-signoff.service';
import { Post_Signon_Store_List, typeOf } from 'src/app/config/type-mapper';
import { ContentService } from './../services/content.service';
import { SessionExtendTimerService } from '../services/session-extend-timer.service';

declare let require: any;

@Injectable({ providedIn: 'root' })
export class PreTxnGuard  {

  private Subscription: any;

  constructor(
    private appStore: AppStore,
    private persistenceService: PersistenceService,
    public subscriptionService: ObservableSubscriptionService,
    private router: Router,
    private location: Location,
    private modalService: ModalService,
    private commonService: CommonService,
    private globalSignOffService: GlobalSignOffService,
    private contentService: ContentService,
    private sessionTimer: SessionExtendTimerService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
      let self = this;
    return new Promise((resolve) => {
      this.contentService.fetchGlobalContent();
      if (!this.appStore.isApp() && this.appStore.state.state.backOrForwardNav) {
        if (this.commonService.isUserSignedOn()) {
          this.globalSignOffService.signOff();
        } else {
          this.router.navigate(['/signon']);
        }
        resolve(false);
      }
      if (this.appStore.isApp('app') && this.router.url.indexOf('/txn/') !== -1 && this.appStore.isWrappedBack) {
        //this.location.forward();
        this.appStore.isWrappedBack = false;
        this.modalService.open('signOffConfirm', 'signOffConfirmNo');
        this.Subscription = this.subscriptionService.modalSendEvent$.subscribe((value) => {
          if (value === 'yes') {
            this.Subscription.unsubscribe();
            this.modalService.close('signOffConfirm');
            this.globalSignOffService.signOff();
          } else if (value === 'no') {
            this.Subscription.unsubscribe();
            this.modalService.close('signOffConfirm');
          }
        });
        resolve(false);
      } else if (this.appStore.isApp('inapp') && this.router.url.indexOf('/txn/') !== -1 && (this.appStore.state.state.backOrForwardNav || this.appStore.isWrappedBack)) {
        window.location.href = "cibcbanking://relaybacksso";

        resolve(false);
      }
      this.sessionTimer.deleteWorker();
      if (!state.url.startsWith("/olbsso/signon")) {
        this.commonService.startBrazeSession().subscribe(() => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
    return new Promise((resolve) => {
      if (next.data && !next.data.isPostSignOnRoute) { // If not a post sign on route, then clear the session
        this.appStore.preTxnPagePermission = undefined;
        this.commonService.clearPostSignOnSession();
        if (next.data.isClearData) {
          this.clearTxnStore();
        }
      }
      resolve(true);
    });
  }

  private clearTxnStore() {
    for (const key of Object.keys(Post_Signon_Store_List)) {
      typeOf(key).inFlow = undefined;
    }
  }
}
