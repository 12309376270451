import { FormValidatorFactory } from '../../../shared/services/cibc.formvalidator';
import { isDefined } from 'src/app/shared/services/utils.service';
import { FormControl, FormGroup } from '@angular/forms';

export class OtvcSetupValidator {

    static createForm(form, fb) {
        form = fb.group({
            'mobilePhone': [""],
            'mobilePhoneCountry': [],
            'homePhoneCountry': [],
            'businessPhoneCountry': [],
            'homePhone': [""],
            'businessPhone': [""],
            'email': [""],
            'alwaysOtvc': [""]
        });

        return form;
    }

    static setValidators(form, formBuilder, mcountryCode, hcountryCode, bcountryCode) {
        form.controls['mobilePhone'].setValidators([FormValidatorFactory.createCustomPhoneNumberValidator('MSGOTV008', "M", mcountryCode)]);
        form.controls['homePhone'].setValidators([FormValidatorFactory.createCustomPhoneNumberValidator('MSGOTV008', "H", hcountryCode)]);
        form.controls['businessPhone'].setValidators([FormValidatorFactory.createCustomPhoneNumberValidator('MSGOTV008', "B", bcountryCode)]);
        form.controls['alwaysOtvc'].setValidators([FormValidatorFactory.createNotBlankValidator('MSGOTV016')]);
        form.controls['email'].setValidators([FormValidatorFactory.createCustomEmailValidator('MSGCUI0002')]);
    }

    static setValue(form) {
        form.setValue({
            'mobilePhone': form.value.mobilePhone || "",
            'mobilePhoneCountry': form.value.mobilePhoneCountry || 0,
            'homePhoneCountry': form.value.homePhoneCountry || 0,
            'businessPhoneCountry': form.value.businessPhoneCountry || 0,
            'homePhone': form.value.homePhone || "",
            'businessPhone': form.value.businessPhone || "",
            'email': form.value.email || "",
            'alwaysOtvc': form.value.alwaysOtvc || ""
        });
    }

    static resolveValidationError(form, errorContent) {
        let messges = [];
        messges.push({
            controlName: 'mobilePhone',
            code: form.controls['mobilePhone'].errors
        });
        messges.push({
            controlName: 'homePhone',
            code: form.controls['homePhone'].errors
        });
        messges.push({
            controlName: 'businessPhone',
            code: form.controls['businessPhone'].errors
        });
        messges.push({
            controlName: 'alwaysOtvc',
            code: form.controls['alwaysOtvc'].errors
        });
        messges.push({
            controlName: 'email',
            code: form.controls['email'].errors
        });

        return this.setErrorMessage(messges, errorContent);
    }

    static resolveServerValidationError(form, errorMessage, field) {
        const error = {};
        const message = {
            message: errorMessage
        };
        if (field === 'email') {
            error['email'] = message;
        }
        return error;
    }

    static setErrorMessage(results, errorContent) {
        let error = {};
        results.forEach(result => {
            if (result.code !== null) {
                //if (isDefined(result.code.errorCode)) {
                if (result.code.errorCode) {
                    error[result.controlName] = errorContent[result.code.errorCode];
                }
                console.log(JSON.stringify(result));
            }
        });
        return error;
    }

    static processRestFormErrors(formError) {
        let error = {};
        if (formError.error.Category === 'homePhone') {
            error['homePhone'] = formError.error.Errors[0].ErrorMessage
        }

        if (formError.error.Category === 'mobilePhone') {
            error['mobilePhone'] = formError.error.Errors[0].ErrorMessage
        }

        if (formError.error.Category === 'businessPhone') {
            error['businessPhones'] = formError.error.Errors[0].ErrorMessage
        }
        if (formError.error.Category === 'alwaysOtvc') {
            error['alwaysOtvc'] = formError.error.Errors[0].ErrorMessage
        }
        if (formError.error.Category === 'email') {
            error['email'] = formError.error.Errors[0].ErrorMessage
        }
        return error;
    }

    static resetErrorMessage(controlName) {
        let messges = [];
        let error = {};
        if (typeof controlName === "string") {
            error[controlName] = undefined;
            return error[controlName];
        } else {
            messges.push({
                controlName: 'businessPhone',
                code: controlName.controls['businessPhone'].errors
            });
            messges.push({
                controlName: 'homePhone',
                code: controlName.controls['homePhone'].errors
            });
            messges.push({
                controlName: 'mobilePhone',
                code: controlName.controls['mobilePhone'].errors
            });
            messges.push({
                controlName: 'alwaysOtvc',
                code: controlName.controls['alwaysOtvc'].errors
            });
            messges.push({
                controlName: 'email',
                code: controlName.controls['email'].errors
            });
            messges.forEach(result => {
                error[result.controlName] = undefined;
            });
            return error;
        }
    }
}