<div class="field-input">
  <button id="open_modal_btn_id" type="button" *ngIf="content && accountList.length > 1" (click)="openModal()" class="selector-button"
    [attr.aria-labelledby]="labelId + ' account-value' + uniqueId + ' account-action' + uniqueId"
    [attr.aria-describedby]="'account-error' + uniqueId" [inputErrorDirective]="error"
    [attr.id]="'accountSelectorButton' + uniqueId" [ngClass]="mode === 'ExchangeCurrency' ? 'selector-button-fx' : ''">
    <span [attr.id]="'account-action' + uniqueId" class="sr-only">{{globalStore.text.accountSelectorOpen}}</span>
    <span class="select-icon icon-Chevron-Right color03" aria-hidden="true"></span>
    <span [attr.id]="'account-value' + uniqueId" #returnRef *ngIf="accountIndex == -1"
      class="placeholder color03">{{placeholder}}</span>
    <div #returnRef [attr.id]="'account-value' + uniqueId" *ngIf="accountIndex != -1">
      <div id="account_list_id" class="account color02" role="text">
        <span *ngIf="globalStore.accountNickname(accountList[accountIndex]) === 'FHSA'" class="sr-only">
          F H S A
        </span>
        <span *ngIf="globalStore.accountNickname(accountList[accountIndex]) === 'FHSA'" aria-hidden="true">
          {{globalStore.accountNickname(accountList[accountIndex])}}
        </span>
        <span *ngIf="globalStore.accountNickname(accountList[accountIndex]) !== 'FHSA'">
          {{globalStore.accountNickname(accountList[accountIndex])}}
        </span>
        <span [innerHTML]="accountList[accountIndex].AccountNumber | srOnly:true"></span>
        <span *ngIf="mode=='CashTransferFrom' || mode=='CashTransferTo'">
          {{globalStore.getCurrencyContent(accountList[accountIndex].Currency)}}</span>
      </div>
      <div *ngIf="mobileContained && !isDesktop()" class="A12 color03"
        [innerHTML]="accountList[accountIndex].Fullname | srOnly:true">
      </div>
    </div>
  </button>
  <div *ngIf="content && accountList.length <= 1" class="selector-button-disabled"
    [ngClass]="mobileContained ? 'contained' : ''" [attr.aria-labelledby]="labelId + ' account-value' + uniqueId"
    [attr.aria-describedby]="'account-error' + uniqueId" [inputErrorDirective]="error">
    <div #returnRef [attr.id]="'account-value' + uniqueId">
      <div id="account_nickname_id" class="account color02" role="text">
        <span *ngIf="globalStore.accountNickname(accountList[0]) === 'FHSA'" class="sr-only">
          F H S A
        </span>
        <span *ngIf="globalStore.accountNickname(accountList[0]) === 'FHSA'" aria-hidden="true">
          {{globalStore.accountNickname(accountList[0])}}
        </span>
        <span *ngIf="globalStore.accountNickname(accountList[0]) !== 'FHSA'">
          {{globalStore.accountNickname(accountList[0])}}
        </span>
        <span [innerHTML]="accountList[0].AccountNumber | srOnly:true"></span>
        <span *ngIf="mode=='CashTransferFrom' || mode=='CashTransferTo'"> {{accountList[accountIndex]?.Currency}}</span>
      </div>
      <div id="account_fullname_id" *ngIf="mobileContained && !isDesktop()" class="A12 color03"
        [innerHTML]="accountList[0].Fullname | srOnly:true">
      </div>
      <div class="account-line"></div>
    </div>
  </div>
  <div *ngIf="content && checkLob('IE') && accountIndex != -1 && isDesktop() && mode != 'ExchangeCurrency'" class="under-button-fullname">
    {{accountList[accountIndex].Fullname}}</div>
  <div
    *ngIf="accountIndex != -1 && mobileContained && !isDesktop()  && mode != 'CashTransferFrom' && mode != 'CashTransferTo'">
    <app-account-card [account]="accountList[accountIndex]" [content]="buyingPowerContent" [mode]="mode"
      [loading]="buyingPowerLoading" [buyingPowerModeType]="accountList[accountIndex].BuyingPowerValueType"
      [displayTimeStamp]="true" [displayHeader]="false" [displayLink]="true"
      [displayMargin]="displayMargin"></app-account-card>
  </div>
  <div
    *ngIf="accountIndex != -1 && mobileContained && !isDesktop() && (mode == 'CashTransferFrom' || mode == 'CashTransferTo') && combinedAccount">
    <app-account-card [account]="combinedAccount" [content]="buyingPowerContent" [mode]="mode"
      [loading]="buyingPowerLoading" [displayBuyingPower]="false" [displayTimeStamp]="true" [displayHeader]="false"
      [displayLink]="true" [displayMargin]="displayMargin"></app-account-card>
  </div>
  <app-field-level-error [error]="error" [Id]="'account-error' + uniqueId"></app-field-level-error>
</div>

<app-modal [header]="'accntSelectorModalHeader' + uniqueId" (keydown.esc)="closeModal()"
  [attr.id]="'accountSelector' + uniqueId" [id]="'accountSelector' + uniqueId" style='display: none;'
  [targetElement]="'accountSelectorButton' + this.uniqueId">
  <div class="cibc-account-selector-modal-body" aria-atomic="true"
    attr.aria-controls="{{'accountSelector' + uniqueId}}">
    <div class="cibc-account-selector-modal-title-bar">
      <div class='modal-close'>
        <button [attr.id]="'accountSelectorClose' + uniqueId" type="button" (click)='closeModal()'
          class="clear close-button icon-Close color04" [attr.aria-label]="globalStore.text.accountSelectorClose">
        </button>
      </div>
      <div [attr.id]="'accntSelectorModalHeader' + uniqueId" class="selectMessage color02">
        <label>{{selectMessage}}</label>
      </div>
    </div>
    <div class="cibc-account-selector-modal-content">
      <div *ngFor="let account of accountList let i = index">
        <div
          *ngIf="mode == 'Trade' || mode == 'OrderStatus' || mode == 'CashTransferFrom' || mode == 'CashTransferTo' || mode == 'BuyingPowerSubBalances' || mode == 'ExchangeCurrency'">
          <!-- <app-account-card (selectAccount)="selectAccount($event)" [account]="account" [content]="buyingPowerContent" [mode]="mode"
            [loading]="loading" [buyingPowerModeType]="account.BuyingPowerValueType" [selectable]="true" [index]="i" [showBalance]="showBalance"></app-account-card> -->
          <app-account-card (selectAccount)="selectAccount($event)" [account]="account" [content]="buyingPowerContent"
            [mode]="mode == 'BuyingPowerSubBalances' ? 'Trade' : mode" [loading]="loading"
            [buyingPowerModeType]="account.BuyingPowerValueType" [selectable]="true" [index]="i"
            [showBalance]="showBalance" [displayBuyingPower]="false" [displayMargin]="displayMargin"></app-account-card>
        </div>
      </div>
      <div *ngIf="mode == 'ExchangeCurrency'" class="help-text-exchange-currency">
        <div class="help-container-box marginBottom20">
          <button id="goToUrl_link_btn" type="button" (click)="goTo(content.text.linkToPreferences)" [attr.title]="content.text.preferences">
            <div class="account-balance-header">
              <span class="header-label"><span class="select-icon color10 align-right icon-Add"></span>
              <span class="gotoText">{{content.text.goTo}}{{content.text.preferences}}</span>
              </span>
            </div>
          </button>
        </div>
        <div>{{content.text.noteNotAvailableAccounts}}</div>
      </div>
      <div *ngIf="(mode == 'CashTransferFrom' || mode == 'CashTransferTo') && checkLob('IE')">
        <a id="goToUrl_link_id" *ngIf="isPermission('WFLBA')" class="extra-bottom-link" href="javascript:void(0);"
          (click)="goToURL('/txn/userPreferences/link-bank-accounts/landing')">
          <span class="icon-Add" aria-hidden="true"></span>
          <span class="extra-bottom-link-text">{{content.text.linkBankAcc}}</span> <span class="sr-only">{{content.text.openInSameWindowHdnTxt}}</span>
          <span class="icon-Chevron-Right" aria-hidden="true"></span>
        </a>
        <a id="otherBrokerage_link_id" class="extra-bottom-link" href="javascript:void(0);" (click)="goToURL('/txn/userPreferences/transfer-forms/landing')">
          <span class="icon-Add" aria-hidden="true"></span>
          <span class="extra-bottom-link-text">
            <span>{{content.text.otherBrokerage}}<span class="sr-only">{{content.text.openInSameWindowHdnTxt}}</span></span>
          </span>
          <span class="icon-Chevron-Right" aria-hidden="true"></span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
