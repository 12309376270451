import { Component, OnDestroy, Output, EventEmitter, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { ObservableSubscriptionService } from './../../../shared/services/observable-subscription.service';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { isDefined } from 'src/app/shared/services/utils.service';
import { Subscription } from 'rxjs';
import { AppStore } from 'src/app/shared/models/app-store';
import { ModalService } from 'src/app/shared/components/modal/services/modal.service';
import { PersistenceService } from '../../services/persistence.service';

@Component({
  selector: 'app-error',
  templateUrl: './app-error.component.html',
  styleUrls: ['./app-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppErrorComponent implements OnDestroy {
  @Input() active = true;
  @Input() identifier = '';
  @Input() spacing = '';

  @Output() formErrorPass: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorAlert: EventEmitter<any> = new EventEmitter<any>();

  private _subscription: Subscription;
  hasError = false;
  message: any;
  errorCode: any;

  //crossflow error modal
  hidePage = false;
  crossFlowModalCode: any;
  crossFlowModalMessage: any;
  uniqueId: string;

  constructor(
    public store: AppStore,
    private subscriptionService: ObservableSubscriptionService,
    public globalContent: GlobalContentStore,
    private persistanceService: PersistenceService,
    public modalService: ModalService
  ) {
    this.uniqueId = this.store.uniqueId;
    this.setSubscription(this.subscriptionService.setErrorEvent$.subscribe(
      (value) => {
        if (this.active) {
          switch (value) {
            case 'RESET':
              this.hasError = false;
              break;
            default:
              this.showError(value);
          }
        }
      })
    );

    // let unexpectedReload = this.persistanceService.sessionStoreRetrieve("hasUnexpectedError");
    // this.persistanceService.removeSessionStoreValue("hasUnexpectedError");
    // if (isDefined(unexpectedReload) && unexpectedReload) {
    //   this.showError({ "ErrorMessage": this.globalContent.error.unexpectedException.message });
    // }
  }

  private setSubscription(s) {
    if (isDefined(this._subscription)) {
      this.unsetSubscription();
    }
    this._subscription = s;
  }

  private unsetSubscription() {
    this._subscription.unsubscribe();
  }

  private showError(data): void {
    // The second parameter is the text in the button.
    // In the third, we send in the css class for the snack bar.
    if (data instanceof Array && isDefined(data[0].Category)) {
      this.store.crossFlow = false;
      this.formErrorPass.emit(data);
    } else if (this.store.crossFlow || this.store.showErrorInPopup) {
      this.store.crossFlow = false;
      // window.alert('crossFlow error');
      if (data.ErrorCode) {
        this.crossFlowModalCode = data.ErrorCode;
      }
      if (data.ErrorMessage) {
        this.crossFlowModalMessage = data.ErrorMessage;
      }
      this.modalService.open('crossflow-error' + this.uniqueId, 'crossFlowErrorModalHeader' + this.uniqueId);
      this.hidePage = true;
      if (this.store.showErrorInPopup) {
        this.store.showErrorInPopup = false;
      }
    } else {
      this.errorAlert.emit(data);
      this.message = data.ErrorMessage;
      if (data.ErrorCode) {
        this.errorCode = data.ErrorCode;
      }
      const element: HTMLElement = (document.getElementById(this.makeID()) as HTMLElement);
      const self = this;
      setTimeout(() => {
        this.hasError = true;
        if (element == null) {
          console.log(self.makeID());
        } else {
          element.setAttribute('tabindex', '-1');
          element.focus();
        }
      }, 200);
    }
  }

  closeModal(id) {
    this.modalService.close(id + this.uniqueId, '');
    this.hidePage = false;
  }

  private resetError(data): void {
    this.store.crossFlow = false;
    this.hasError = false;
  }

  makeID() {
    if (this.identifier === '') {
      return 'app-error'
    } else {
      return 'app-error-' + this.identifier;
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.unsetSubscription();
  }
}
