import { BaseData } from '../../../../../../shared/models/base-data';

export class BuyBondsVerifyData extends BaseData {
  constructor() {
    super();
    this.input = {
      tradingPassword: '',
      savePassword: false
    };
  }
}

export interface BuyBondsVerifyInputs {
  tradingPassword: string;
  savePassword: boolean;
}
