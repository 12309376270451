import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppInitService } from './core/services/app-init.service';
import { FormsModule } from '@angular/forms';
import { PreTxnModule } from './modules/pre-txn/pre-txn.module';
import { TxnModule } from './modules/txn/txn.module';
import { ObservableSubscriptionService } from './shared/services/observable-subscription.service';
import { NotificationService } from './core/services/notification.service';
import { ForceUpgradeModule } from './modules/force-upgrade/force-upgrade.module';
import { IntegrityCheckModule } from './modules/integrity-check/integrity-check.module';
import { EnvServiceProvider } from './env.service.provider'
import { ChatSharedModule } from './shared/chat-shared.module';

export function appInitServiceFactory(provider: AppInitService) {
  return () => provider.initApp();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    HttpClientXsrfModule,
    AppRoutingModule,
    FormsModule,
    ForceUpgradeModule,
    IntegrityCheckModule,
    PreTxnModule,
    TxnModule,
    ChatSharedModule
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitServiceFactory,
      multi: true,
      deps: [AppInitService, NotificationService]
    },
    ObservableSubscriptionService,
    EnvServiceProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
