import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ROOT_CTX, DCO_ROOT_CTX, DCO_ROOT_CTX_WITHOUT_AO } from 'src/app/shared/models/user';
import { SessionExtendTimerService } from './session-extend-timer.service';
import { tap, catchError } from 'rxjs/operators';
import { buildInfo } from './../../config/build-info';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public responseCache = new Map();
  constructor(
    private http: HttpClient,
    private sessionExtendTimerService: SessionExtendTimerService
  ) { }

  post(url: string, req: any, params?: any): Observable<any> {
    if(params && params.params && params.params.avoidSessionExtension) {}
    else {
      this.sessionExtendTimerService.resetTimer();
    }
    if (params) {
      return this.http
        .post<any>(ROOT_CTX + url, req, params);
    } else {
      return this.http
        .post<any>(ROOT_CTX + url, req);
    }
  }

  postDCO(url: string, req: any): Observable<any> {
    //this.sessionExtendTimerService.resetTimer();
    return this.http.post<any>(DCO_ROOT_CTX + url, req);

  }





  get(url: string): Observable<any> {
    return this.http
      .get<any>(ROOT_CTX + url);
  }

  getBlob(url: string): Observable<any> {
    return this.http
      .get<any>(url, { responseType: 'blob' as 'json' })
  }

  postToExternalURL(url: string, req: any, params?: any): Observable<any> {
    this.sessionExtendTimerService.resetTimer();
    if (params) {
      return this.http
        .post<any>(url, req, params);
    } else {
      return this.http
        .post<any>(url, req);
    }
  }

  getToExternalURL(url: string, params?: any): Observable<any> {
    this.sessionExtendTimerService.resetTimer();
    if (params) {
      return this.http
        .get<any>(url, params);
    } else {
      return this.http
        .get<any>(url);
    }
  }

  getResource(url: string): Observable<any> {
    const resourceCache = this.responseCache.get(url);
    if (resourceCache) {
      return of(resourceCache);
    } else {
      return this.http.get<any>(url, { params: { skiploading: 'true', v: buildInfo.num } }).pipe(tap((data) => {
        this.responseCache.set(url, data);
        return of(data);
      }), catchError((err) => {
        return of(null);
      }));
    }
  }

  getVersion(): Observable<any> {
    return this.http.get<any>('assets/content/version.json', { params: { skiploading: 'true', v: buildInfo.num } }).pipe(tap((data) => {
      return of(data);
    }), catchError((err) => {
      return of(null);
    }));
  }
}
