import { Injectable } from '@angular/core';
import { CreateSignonTradingPassConfirmData } from './create-signon-trading-pass-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class CreateSignonTradingPassConfirmStore extends PageStore<CreateSignonTradingPassConfirmData> {

    constructor() {
        super(new CreateSignonTradingPassConfirmData());
    }

}
