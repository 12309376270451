import { Injectable } from '@angular/core';
import { TradeMLSLandingData } from './trade-MLS-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TradeMLSLandingStore extends PageStore<TradeMLSLandingData> {

    constructor() {
        super(new TradeMLSLandingData());
    }

}
