import { RouteGroup } from '../shared/models/route-config';

export const FeesCommissionConfig: RouteGroup = {

    '/txn/feescommission': {
        name: 'feescommission',
        service: '',
        store: 'FeesCommissionStore',
        resolveContent: 'feescommission',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [],
        trackingData: {
            name: "fees-and-commissions",
            nameGA: "Fees and Commissions",
            formInfo: {
                name: "obr-reset-password",
                stepName: "confirmation",
            },
            events: { formStep: true, pageView: true },
        }
    }
};
