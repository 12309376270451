import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { TransactionHistoryData } from './transaction-history-data';

@Injectable({ providedIn: 'root' })
export class TransactionHistoryStore extends PageStore<TransactionHistoryData> {

    constructor() {
        super(new TransactionHistoryData());
    }

}
