import { RouteGroup } from '../shared/models/route-config';

export const CreditPolicyConfig: RouteGroup = {

    '/txn/creditpolicy': {
        name: 'creditpolicy',
        service: '',
        store: 'CreditPolicyStore',
        resolveContent: 'creditpolicy',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [],
        trackingData: {
            name: "credit-policy",
            nameGA: "Credit Policy",
            events: { pageView: true },
        }
    }
};