import { BaseData } from '../../../../../../../../src/app/shared/models/base-data';

export class TransferFormsLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountNumber: null,
            email: null,
        };
    }
}

export interface TransferFormsLandingInputs {
    selectedDate: any;
    dODelivery: any;
    delayDeliveryDateRange: any;
    settlementIndex: number;
    Amount: string;
    SignRadio: any;
    WebFormName?: String;
    InitData?: InitDataObject;
    institutionToggle?: string;
    province?: string;
    fromAccountNumber?: string;
    toAccountNumber?: string;
    TransferType?: any;
    email?: string
    confirmEmail?: string;
    transferFrom: any;
    SIN: string;
    AssetType?: string;
    transferDetailAssetType?: string[];
    transferDetailType?: string[];   // Inkind-InCash
    transferDetailSymbol?: string[]; // symbol
    transferDetailAmt?: string[];    // amount
    transferDetailAmtUnit?: string[]; // shares
    transferDetailDesc?: string[]; // desc
    transferDetailDate?: any[];
    delayDelivery?: boolean[];
    dob: string;
    selectedAccount: any;
    deliveryDate: string;
    dateMonth: any[];
    dateDay: any[];
    countryToggle?: string;
    institutonName?: string;
    postalCode?: string;
    city?: string;
    address?: string;
    spouseFirstName?: string;
    spousalLastName?: string;
    spouseName?: string;
    pleaseSpecify?: string;
    isRegisteredAccount?: boolean;
    fromAccountType?:string;
}
export interface InitDataObject {
    [x: string]: any;
    provinceList?: provinceList;
    externalIntitutions?: externalIntitutionsArray[];
    regCibcIntitutions?: regCibcList[];
    accountList?: AccountListArray[];
    nonRegCibcIntitutions?: NonCibcInstitutionList[];
    registeredTypes?: RegisteredTypes[];
    nonRegisteredTypes?: NonRegisteredTypes[];
    delayDeliveryDateRange?: any;
}


export interface provinceList {
    provinceList: [string]
}

export interface externalIntitutionsArray {
    Id: String,
    Name: String,
    DisplayName: String,
    AllowedForm: String
}
export interface regCibcList {
    Id: String,
    Name: String,
    DisplayName: String,
    AllowedForm: String
}
export interface AccountListArray {
    AccountNumber: String,
    AccountTypeDesc: String,
    AccountHolder: HolderDetails,
    Joint: boolean
}
export interface HolderDetails {
    Address: {
        AddressLine1: String,
        AddressLine2: String,
        City: String,
        Country: String,
        PostalCode: String,
        Province: String
    },
    Name: {
        FirstName: String,
        LastName: String
    },
    Phone: {
        PhoneNo: string
    }
}

export interface NonCibcInstitutionList {
    Id: String,
    Name: String,
    DisplayName: String,
    AllowedForm: String
}

export enum Institution {
    CIBC = "CibC",
    OTHER = "Other",
    EXTERNAL = "EXTERNAL"
}

export enum CountryRadio {
    CANADA = "Canada",
    OTHER = "Other",

}
export enum InstitutionToggle {
    OTHER = "other",
    CIBC = "cibc"
}

export enum CountryToggle {
    CAD = 'cad',
    OTHER = 'other'
}

export enum SignRadio {
    INCASH = "C",
    ELECTRONOCALLY = "E",

}

export enum TransferType {
    INKIND = "Inkind",
    CASH = "Incash",
    MIXED = "Mixed",
    PARTIAL = "Partial"
}

export const globalCalendar = {
    minDateYearInput: -120,
    minDateMonthInput: -120,
    maxDateYearInput: 0,
    maxDateMonthInput: 0,
    minSetYear: -120,
    minSetMonth: 0,
    minSetDay: 1,
    maxSetYear: 0,
    maxSetMonth: 11,
    maxSetDay: 31

}

export enum AssetType {
    CASH = "cash",
    INVESTMENTS = "investments"
}

export const deliveryCalendar = {
    minDateYearInput: 0,
    minDateMonthInput: 0,
    maxDateYearInput: 0,
    maxDateMonthInput: 1,
    minSetYear: 0,
    minSetMonth: new Date().getMonth(),
    minSetDay: new Date().getDay() + 1,
    maxSetYear: 0,
    maxSetMonth: new Date().getMonth() + 1,
    maxSetDay: 31
}

export const calendar_const = {
    customDate: "customDate",
    dateofDelayDelivery: "dateofDelayDelivery"
}

export const page_constant = {
    other: "ExternalOtherFirm"
}

export interface TransferFormSubmitRequest {
    WebFormName?: string;
    Stage?: string,
    Parameters?: {
        param2?: string,
        param3?: string,
        param4?: string,
        param1?: string,
        param5?: string,
        param6?: string,
        param7?: string,
        param8?: string,
        param9?: number,
        param10?: string,
        institutionLOB?: string,
        param11?: string,
        param12?: string,
        param13?: string,
        param14?: string,
        param16?: string,
        param17?: number,
        param18?: string,
        param19?: string,
        param20?: string,
        param22?: string,
        param23?: string,
        param24?: string,
        param25?: string,
        param26?: string,
        param27?: string,
        param28?: string,
        param29?: string,
        param30?: string,
        param31?: string,
        param32?: string,
        param33?: string,
        param34?: string,
        param35?: string,
        param36?: string,
        param37?: string,
        param38?: string,
        param39?: string,
        param15?: boolean,
        param21?: string,
    };
}
export const ParamsMapping = {
    TransferType: "TransferType",
    InstitutionAddress: "InstitutionAddress",
    Param16: "Param16",
    InstitutionProvince: "InstitutionProvince"
}

export interface RegisteredTypes {
    Name: String,
    TypeId: String
}
export interface NonRegisteredTypes {
    Name: String,
    TypeId: String
}

export enum AccountTypeMapping {
    RRSP = "RRSP",
    RRIF = "RRIF"
}
export enum InvestmentType {
    cash = "cash",
    investments = "investments",
}

export enum OtherInstitutionName {
    simpliiEN = "Simplii Financial",
    simpliiFR = "Financière Simplii"
}
