import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { AccountDetailData } from './account-detail-page-data';

@Injectable({ providedIn: 'root' })
export class AccountDetailStore extends PageStore<AccountDetailData>  {

    constructor() {
        super(new AccountDetailData());
    }

}
