import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor(private cookieService: CookieService) { }

  public cookieStoreRetrieve(name: string): string {
    return this.cookieService.get(name);
  }

  public cookieStoreRetrieveAll(): {} {
    return this.cookieService.getAll();
  }

  public cookieStorePersist(name: string, value: string, domain?: string): void {
    this.cookieService.set(name, value, 365, '/', domain, true);
  }

  public deletecookieStorePersist(name: string, path?: string, domain?: string): void {
    this.cookieService.delete(name, path, domain);
  }

  public deletecookiespecificStorePersist(name: string): void {
    this.cookieService.delete(name, "/", ".cibc.com");
    this.cookieService.delete(name, "/", ".onlinebrokerage.cibc.com");
  }

  public deleteAllCookies(): void {
    this.cookieService.deleteAll();
  }

  public fileStoreRetrieve(key: string): Observable<any> {
    return of({});
  }

  public fileStorePersist(key: string, value: string): Observable<any> {
    // writeFileSync()
    return of({});
  }

  public sessionStorageClear() {
    return sessionStorage.clear();
  }

  public sessionStoreRetrieve(key: string): string {
    return sessionStorage.getItem(key);
  }

  public sessionStorePersist(key: string, value: any): void {
    sessionStorage.setItem(key, value);
  }

  public removeSessionStoreValue(key: string): void {
    return sessionStorage.removeItem(key);
  }

  public localStoreRetrieve(key: string): string {
    let value = '';
    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch {
      value = '';
    }
    return value;
  }

  public localStorePersist(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public removeLocalStoreValue(key: string): void {
    localStorage.removeItem(key);
  }
}
