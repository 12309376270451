import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../shared/models/page-store';
import { QuickTradeOptionsLandingData } from './quick-trade-options-landing-data';

@Injectable({ providedIn: 'root' })
export class QuickTradeOptionsLandingStore extends PageStore<QuickTradeOptionsLandingData> {

    constructor() {
        super(new QuickTradeOptionsLandingData());
    }

}
