import { Injectable } from '@angular/core';
import { SetUpTradeFillAlertsLandingData } from './setup-trade-fill-alerts-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SetUpTradeFillAlertsLandingStore extends PageStore<SetUpTradeFillAlertsLandingData> {

    constructor() {
        super(new SetUpTradeFillAlertsLandingData());
    }

}
