<div class="tabContainer" [ngClass]="(!content.length? 'borderBottom ':'') + tabPageClass()">
  <div class="notabWidth" *ngIf="styling != 'simple'"></div>
  <div>
    <div *ngIf="tabArray.length > 1" role="tablist" class="tabList">
      <button *ngFor="let tab of tabArray; let i = index" type="button" role="tab" id="tab_control_newMessageBtn-{{i}}"
        [ngClass]="(i == selectedTabValue ? 'active-tab' : 'inactive-tab')  + ' tab-padding-' + tabPadding"
        [attr.aria-selected]="i == selectedTabValue" [attr.id]="tab.name" [attr.aria-controls]="tab.name + '-tab'"
        (click)="clickTab(i)" (keydown)="keyDown($event, i)"
        [attr.tabindex]="i == selectedTabValue ? null : -1">{{tab.text}}
        <span class='new-content-red-box' *ngIf="tab.new">{{newMessage}}</span>
      </button>
    </div>
    <div class="tabPadding tabList" *ngIf="tabArray.length == 1" role="tablist">
      <button *ngFor="let tab of tabArray; let i = index" type="button" role="tab" id="tab_control_newTextBtn-{{i}}"
        [ngClass]="i == selectedTabValue ? 'active-tab' : 'inactive-tab'" [attr.aria-selected]="i == selectedTabValue"
        [attr.id]="tab.name" [attr.aria-controls]="tab.name + '-tab'" (click)="clickTab(i)"
        (keydown)="keyDown($event, i)" [attr.tabindex]="i == selectedTabValue ? null : -1">{{tab.text}}</button>
    </div>
  </div>
</div>
<ng-content></ng-content>