import { Injectable } from '@angular/core';
import { BuyStructNotesVerifyData } from './buy-struct-notes-verify-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class BuyStructNotesVerifyStore extends PageStore<BuyStructNotesVerifyData> {

  constructor() {
    super(new BuyStructNotesVerifyData());
  }
}
