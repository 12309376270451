import { RouteGroup } from '../shared/models/route-config';

export const AgreementsAndDisclosuresConfig: RouteGroup = {

    '/txn/agreementanddisclosures': {
        name: 'agreementanddisclosures',
        service: '',
        store: 'AgreementAndDisclosuresStore',
        resolveContent: 'agreementanddisclosures',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [],
        trackingData: {
            name: "agreements-and-disclosures",
            nameGA: "Agreements and Disclosures",
            events: { pageView: true },
        }
    }
};
