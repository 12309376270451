import { BaseData } from 'src/app/shared/models/base-data';

export class ChangeSignonPassConfirmData extends BaseData {
    constructor() {
        super();
        this.input = {
            currentUserID: null,
            newUserID: null,
            signOnPassword: null
        };
    }
}

export interface ChangeSignonPassConfirmInputs {
    currentUserID: string;
    newUserID: string;
    signOnPassword: string;
}