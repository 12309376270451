import { Injectable } from '@angular/core';
import { CreateSignonPassData } from './create-signon-pass-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({providedIn:'root'})
export class CreateSignonPassStore extends PageStore<CreateSignonPassData> {

    constructor () {
        super(new CreateSignonPassData());
    }
    
}
