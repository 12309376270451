import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SearchReq } from '../data/buy-bonds-search-data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class BuyBondsSearchService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  getBonds(data) {
    const req: SearchReq = {};
    req.Refresh = data.Refresh;
    req.FixedIncomeType = data.FixedIncomeType;
    req.FaceValue = data.FaceValue;
    req.TermRange = data.TermRange;
    req.YieldRange = data.YieldRange;
    req.Sector = data.Sector;
    req.CreditRating = data.CreditRating;
    req.CouponRange = data.CouponRange;
    req.PaymentFrequency = data.PaymentFrequency;
    req.Currency = data.Currency;
    req.PaginationOption = data.PaginationOption;
    req.SortOption = data.SortOption;
    return this.httpService.post(gatewayConfig.APIServices.searchBonds.url,
      req
    );
  }

  getDetails(req) {
    return this.httpService.post(gatewayConfig.APIServices.getInstrumentDetail.url,
      req
    );
  }
}
