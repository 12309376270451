import { Injectable } from '@angular/core';
import { OptionsCentralData } from './options-central-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class OptionsCentralStore extends PageStore<OptionsCentralData> {

    constructor() {
        super(new OptionsCentralData());
    }

}
