import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpService } from '../services/http.service';
import { AppStore } from 'src/app/shared/models/app-store';

@Pipe({
  name: 'srOnly',
  pure: true
})
export class SROnlyPipe implements PipeTransform {

  srOnlyString: string;

  constructor(
    private sanitizer: DomSanitizer,
    public httpService: HttpService,
    public appStore: AppStore,
  ) {

  }

  transform(value: string, hasNumber?: boolean): any {
    if (hasNumber) {
      const newstring = this.spaceNumbers(value);
      if (newstring === undefined) {
        return undefined;
      } else {
        this.srOnlyString = newstring;
      }
      return this.formatHtml(this.srOnlyString, value);
    }
  }

  spaceNumbers(value) {
    const reg = /[0-9](?=[0-9])/g;
    if (value === undefined) {
      return undefined;
    } else if (value.search(reg) != -1) {
      return value.replace(reg, '$& ');
    } else {
      return value;
    }
  }

  formatHtml(accessContent: string, val: string) {
    return `<span class="sr-only"> ${accessContent} </span><span aria-hidden="true">${val}</span>`;
  }

}
