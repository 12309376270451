import { RouteGroup } from '../shared/models/route-config';

export const SiteMapConfig: RouteGroup = {

    '/txn/sitemap': {
        name: 'sitemap',
        service: '',
        store: 'SiteMapStore',
        resolveContent: 'sitemap',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [],
        trackingData: {
            name: "site-map",
            nameGA: "Site Map",
            events: { pageView: true },
        }
    }
};
