import { Injectable } from '@angular/core';
import { CreateSignonTradingPassData } from './create-signon-trading-pass-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({providedIn:'root'})
export class CreateSignonTradingPassStore extends PageStore<CreateSignonTradingPassData> {

    constructor () {
        super(new CreateSignonTradingPassData());
    }
    
}
