import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { BuyingPowerReq, QuoteReq, ChainReq, AccountHoldingsReq, SettlementAccountsReq, TradeMLSLandingInputs } from '../data/trade-MLS-landing-data';
import { TradeMLSLandingStore } from '../data/trade-MLS-landing-store';
import { isDefined } from 'src/app/shared/services/utils.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})
export class TradeMLSLandingService implements PageService {

    constructor(private httpService: HttpService) { }

    prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        if (TradeMLSLandingStore.savedInfo) {
            let info: TradeMLSLandingInputs = TradeMLSLandingStore.savedInfo.inputs;
            console.log(info);
            let accNum = TradeMLSLandingStore.savedInfo.account;

            if (info.tradePositionType == "O") {
                let ret;
                ret = [input[0], input[3], input[4], input[5], input[6], input[7]];
                ret[1] = this.prepareChainDetails(ret[1], info.symbolLeg1, info.market);
                ret[2] = this.prepareQuick(ret[2], info.symbolLeg1, info.market, 'EQUITY');
                ret[4] = this.prepareQuickleg1(ret[4], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                ret[5] = this.prepareChainDetailsForLeg(ret[5], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl1);

                if (TradeMLSLandingStore.savedInfo.leg2Type == 'option') {
                    ret = [input[0], input[3], input[4], input[5], input[6], input[7], input[8]];
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol2);
                    ret[6] = this.prepareChainDetailsForLeg(ret[6], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl2);
                } else {
                    ret[3] = this.prepareQuickleg2(ret[3], info.symbolLeg1, info.market, 'EQUITY', info.actionl2);
                }
                return ret;
            }
            else if (info.tradePositionType == "C") {
                let ret;
                if ((TradeMLSLandingStore.savedInfo.optionStrategy == 'buyWrite' || TradeMLSLandingStore.savedInfo.optionStrategy == 'protected') && !TradeMLSLandingStore.savedInfo.meCloseTradeLeg1) {
                    
                    ret = [input[0], input[1], input[2], input[5], input[6]];
                    ret[1] = this.prepareHoldings(ret[1], accNum.AccountNumber, info.action);
                    ret[2] = this.prepareSett(ret[2], accNum.AccountNumber, info.action, TradeMLSLandingStore.savedInfo.settleAmountCurrency);
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                    ret[4] = this.prepareQuickleg2(ret[4], info.symbolLeg1, info.market, 'EQUITY', info.actionl2);
                
                }
                else if ((TradeMLSLandingStore.savedInfo.optionStrategy == 'buyWrite' || TradeMLSLandingStore.savedInfo.optionStrategy == 'protected') && TradeMLSLandingStore.savedInfo.meCloseTradeLeg1) {
                    
                    ret = [input[0], input[1], input[2], input[5], input[6], input[3], input[7]];
                    ret[1] = this.prepareHoldings(ret[1], accNum.AccountNumber, info.action);
                    ret[2] = this.prepareSett(ret[2], accNum.AccountNumber, info.action, TradeMLSLandingStore.savedInfo.settleAmountCurrency);
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                    ret[4] = this.prepareQuickleg2(ret[4], info.symbolLeg1, info.market, 'EQUITY', info.actionl2);
                    ret[5] = this.prepareChainDetails(ret[5], info.symbolLeg1, info.market);
                    ret[6] = this.prepareChainDetailsForLeg(ret[6], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl1);
                
                }
                else if ((!TradeMLSLandingStore.savedInfo.meCloseTradeLeg1 && !TradeMLSLandingStore.savedInfo.meCloseTradeLeg2)) {
                    
                    if (TradeMLSLandingStore.savedInfo.optionStrategy == 'spread') {
                        ret = [input[0], input[1], input[2], input[5], input[6], input[9]];
                        ret[5] = this.prepareHoldings(ret[5], accNum.AccountNumber, info.actionl2);
                    }
                    else {
                        ret = [input[0], input[1], input[2], input[5], input[6]];
                    }
                    ret[1] = this.prepareHoldings(ret[1], accNum.AccountNumber, info.action);
                    ret[2] = this.prepareSett(ret[2], accNum.AccountNumber, info.action, TradeMLSLandingStore.savedInfo.settleAmountCurrency);
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                    ret[4] = this.prepareQuickleg1(ret[4], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol2);
                
                }
                else if (TradeMLSLandingStore.savedInfo.meCloseTradeLeg1 && TradeMLSLandingStore.savedInfo.meCloseTradeLeg2) {
                    
                    if (TradeMLSLandingStore.savedInfo.optionStrategy == 'spread') {
                        ret = [input[0], input[3], input[2], input[5], input[6], input[1], input[7], input[8], input[9]];
                        ret[8] = this.prepareHoldings(ret[8], accNum.AccountNumber, info.actionl2);
                    }
                    else {
                        ret = [input[0], input[3], input[2], input[5], input[6], input[1], input[7], input[8]];
                    }
                    ret[1] = this.prepareChainDetails(ret[1], info.symbolLeg1, info.market);
                    ret[2] = this.prepareSett(ret[2], accNum.AccountNumber, info.action, TradeMLSLandingStore.savedInfo.settleAmountCurrency);
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                    ret[4] = this.prepareQuickleg1(ret[4], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol2);
                    ret[5] = this.prepareHoldings(ret[5], accNum.AccountNumber, info.action);
                    ret[6] = this.prepareChainDetailsForLeg(ret[6], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl1);
                    ret[7] = this.prepareChainDetailsForLeg(ret[7], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl2);
                
                }
                else if (TradeMLSLandingStore.savedInfo.meCloseTradeLeg1 && !TradeMLSLandingStore.savedInfo.meCloseTradeLeg2) {
                    
                    if (TradeMLSLandingStore.savedInfo.optionStrategy == 'spread') {
                        ret = [input[0], input[3], input[2], input[5], input[6], input[9], input[7], input[1]];
                        ret[7] = this.prepareHoldings(ret[7], accNum.AccountNumber, info.action);
                    }
                    else {
                        ret = [input[0], input[3], input[2], input[5], input[6], input[9], input[7]];
                    }
                    ret[1] = this.prepareChainDetails(ret[1], info.symbolLeg1, info.market);
                    ret[2] = this.prepareSett(ret[2], accNum.AccountNumber, info.action, TradeMLSLandingStore.savedInfo.settleAmountCurrency);
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                    ret[4] = this.prepareQuickleg1(ret[4], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol2);
                    ret[5] = this.prepareHoldings(ret[5], accNum.AccountNumber, info.actionl2);
                    ret[6] = this.prepareChainDetailsForLeg(ret[6], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl1);
                
                }
                else if (!TradeMLSLandingStore.savedInfo.meCloseTradeLeg1 && TradeMLSLandingStore.savedInfo.meCloseTradeLeg2) {
                    
                    if (TradeMLSLandingStore.savedInfo.optionStrategy == 'spread') {
                        ret = [input[0], input[3], input[2], input[5], input[6], input[9], input[8], input[1]];
                        ret[7] = this.prepareHoldings(ret[7], accNum.AccountNumber, info.actionl2);
                    }
                    else {
                        ret = [input[0], input[3], input[2], input[5], input[6], input[9], input[8]];
                    }
                    ret[1] = this.prepareChainDetails(ret[1], info.symbolLeg1, info.market);
                    ret[2] = this.prepareSett(ret[2], accNum.AccountNumber, info.action, TradeMLSLandingStore.savedInfo.settleAmountCurrency);
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                    ret[4] = this.prepareQuickleg1(ret[4], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol2);
                    ret[5] = this.prepareHoldings(ret[5], accNum.AccountNumber, info.action);
                    ret[6] = this.prepareChainDetailsForLeg(ret[6], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl2);
                
                }
                return ret;
            }
            else if (info.tradePositionType == "R") {
                let ret;
                
                if (TradeMLSLandingStore.savedInfo.meCloseTradeLeg1) {
                    ret = [input[0], input[3], input[5], input[6], input[1], input[7], input[8]];
                    ret[1] = this.prepareChainDetails(ret[1], info.symbolLeg1, info.market);
                    ret[2] = this.prepareQuickleg1(ret[2], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol2);
                    ret[4] = this.prepareHoldings(ret[4], accNum.AccountNumber, info.action);
                    ret[5] = this.prepareChainDetailsForLeg(ret[5], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl1);
                    ret[6] = this.prepareChainDetailsForLeg(ret[6], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl2);
                    
                    return ret;
                }
                else {
                    ret = [input[0], input[3], input[5], input[6], input[9], input[2], input[8]];
                    ret[1] = this.prepareChainDetails(ret[1], info.symbolLeg1, info.market);
                    ret[2] = this.prepareQuickleg1(ret[2], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol1);
                    ret[3] = this.prepareQuickleg1(ret[3], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.optionInfol2);
                    ret[4] = this.prepareHoldings(ret[4], accNum.AccountNumber, info.action);
                    ret[5] = this.prepareSett(ret[5], accNum.AccountNumber, info.action, TradeMLSLandingStore.savedInfo.settleAmountCurrency);
                    ret[6] = this.prepareChainDetailsForLeg(ret[6], info.symbolLeg1, info.market, TradeMLSLandingStore.savedInfo.reqDateKeyl2);
                    return ret;
                }
            
            }
        } else if (TradeMLSLandingStore.crossFlow && !isDefined(TradeMLSLandingStore.crossFlow.navigatedFrom) && isDefined(TradeMLSLandingStore.crossFlow.optionInfo)) {
            let accNo = TradeMLSLandingStore.crossFlow.accountNumber;
            let optionInfo = TradeMLSLandingStore.crossFlow.optionInfo;
            let action = optionInfo.OptionType == 'C' ? 'BUYTOCLOSE' : 'SELLTOCLOSE';
            let ret = [input[0], input[1]];
            ret[1] = this.prepareHoldings(ret[1], accNo, action);
            return ret;
        } else {
            let ret = [input[0]];
            return ret;
        }
    }

    prepareHoldings(input, accNum, action) {
        let ret = input.input;

        ret.AccountNumber = accNum;
        ret.Action = action;
        ret.Clean = false;
        ret.Refresh = false;
        ret.SearchOptions = {
            NumberOfResult: 100,
            SearchFilter: "",
            StartRow: 0
        }

        input.input = ret;
        console.log(input);
        return input;
    }


    prepareSett(input, accNum, action, curr?) {
        let ret = input.input;

        ret.AccountNumber = accNum;
        ret.Action = action;
        if (curr) {
            ret.AccountCurrency = curr;
        }

        input.input = ret;
        return input;
    }

    prepareChainDetails(input, symbol, market) {
        let ret = input.input;

        ret.SymbolName = symbol;
        ret.Market = market;

        input.input = ret;
        return input;
    }

    prepareChainDetailsForLeg(input, symbol, market, date) {
        let ret = input.input;

        ret.SymbolName = symbol;
        ret.Market = market;
        ret.ExpiryDate = date;

        input.input = ret;
        return input;
    }

    prepareQuick(input, symbol, market, optionInfo) {
        let ret = input.input;

        ret.Symbols[0].SymbolName = symbol;
        ret.Symbols[0].Market = market;
        ret.Symbols[0].SymbolType = optionInfo;

        input.input = ret;
        return input;
    }

    prepareQuickleg1(input, symbol, market, optionInfo) {
        let ret = input.input;

        ret.Symbols[0].SymbolName = symbol;
        ret.Symbols[0].Market = market;
        ret.Symbols[0].OptionInfo = optionInfo;

        input.input = ret;
        return input;
    }

    prepareQuickleg2(input, symbol, market, optionType, orderType) {
        let ret = input.input;
        console.log(ret);
        ret.Symbols[0].SymbolName = symbol,
            ret.Symbols[0].Market = market,
            ret.Symbols[0].SymbolType = optionType,
            ret.OrderType = orderType.toUpperCase();
        input.input = ret;

        return input;
    }

    getAccountDetails(req) {
        return this.httpService.post(gatewayConfig.APIServices.multilegInit.url, req);
    }

    getBuyingPower(data) {
        let req: BuyingPowerReq = new BuyingPowerReq();
        req.AccountNumber = data.AccountNumber;
        return this.httpService.post(gatewayConfig.APIServices.buyingPowerRetrieve.url,
            req, { params: { skiploading: 'true' } }
        );
    }

    getBalanceReq(data) {
        return this.httpService.post(gatewayConfig.APIServices.getAccountBalance.url, data,
            { params: { skiploading: 'true' } });
    }

    getQuote(data) {
        let req: QuoteReq = new QuoteReq();
        req.Symbols = [
            data
        ];
        return this.httpService.post(gatewayConfig.APIServices.quoteQuick.url,
            req, { params: { skiploading: 'true' } }
        );
    }
    
    getChain(data) {
        let req: ChainReq = new ChainReq();
        req = data;
        return this.httpService.post(gatewayConfig.APIServices.optionsChainDetail.url,
            req, { params: { skiploading: 'true' } }
        );
    }

    getAccountHoldings(data, isEquity: boolean) {
        let req: AccountHoldingsReq = new AccountHoldingsReq();
        req.Action = data.Action;
        req.AccountNumber = data.AccountNumber;
        req.Clean = data.Clean;
        req.Refresh = data.Refresh;
        req.SearchOptions = data.SearchOptions;
        if (isEquity) {
            req.FiType = "E";
        }
        else {
            req.FiType = "O";
        }
        return this.httpService.post(gatewayConfig.APIServices.getOrderHoldings.url,
            req, { params: { skiploading: 'true' } }
        );
    }

    getSettlementAccounts(data) {
        let req: SettlementAccountsReq = new SettlementAccountsReq();
        req.Action = data.Action;
        req.AccountNumber = data.AccountNumber;
        req.AccountCurrency = data.AccountCurrency;
        req.Type = "OPTIONS";
        return this.httpService.post(gatewayConfig.APIServices.optionsSettlementAccounts.url,
            req, { params: { skiploading: 'true' } }
        );
    }
}
