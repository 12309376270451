import { BaseData } from 'src/app/shared/models/base-data';

export class ChangeSignonPassData extends BaseData {

    constructor() {
        super();
        this.input = { newPassword: '', confirmPassword: '' };
    }
}
export interface ChangeSignOnPass {
    newPassword: string;
    confirmPassword: string;
}


export class ChangeSignOnPassReq {
    NewPassword: string;
    ConfirmNewPassword: string;

}

