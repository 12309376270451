import { Injectable } from '@angular/core';
import { BuyGicLandingData } from './buy-gic-landing-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class BuyGicLandingStore extends PageStore<BuyGicLandingData> {

  constructor() {
    super(new BuyGicLandingData());
  }
}
