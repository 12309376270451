import { Injectable, OnDestroy } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { AppStore } from 'src/app/shared/models/app-store';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { typeOf } from 'src/app/config/type-mapper';
import { routeConfig } from 'src/app/config/router-mapping';
import { isDefined } from 'src/app/shared/services/utils.service';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {

  online$: Observable<boolean>;
  subscription: any;

  constructor(
    private router: Router,
    private appStore: AppStore
  ) {

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.appStore.state.state.refresh = !router.navigated;
        this.appStore.state.state.backOrForwardNav = (event.restoredState) ? true : false;
        console.log('Browser Refereshed -> ' + this.appStore.state.state.refresh);
        console.log('Browser Forward/Back Pressed -> ', this.appStore.state.state.backOrForwardNav);
      } else if (event instanceof NavigationEnd) {
        this.appStore.midTransURL = undefined;
        if (location.hash === '#/error404') {
          this.appStore.state.state.currentUrl = '/error404';
        } else if (location.hash === '#/error500') {
          this.appStore.state.state.currentUrl = '/error500';
        } else {
          this.appStore.state.state.currentUrl = event.url;
        }
        // if (isDefined(window.KAMPYLE_ONSITE_SDK)) {
        //   setTimeout(() => {
        //     //window.KAMPYLE_ONSITE_SDK.updatePageView();
        //   })
        // }else {
        //   // add a listener for the event neb_OnsiteLoaded which will occur once all the
        //   // Digital code has been executed by the browser
        //   window.addEventListener('neb_OnsiteLoaded', this.medalliaLoaded());
        // }
        console.log('Landed in Url [', this.appStore.state.state.currentUrl, ']');
      }
    });

    // Detects No Internet Connection 
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.online$.subscribe(value => {
      if (!value) {
        this.appStore.connectionStatus = false;
        if (this.appStore.isApp()) {
          this.router.navigate(['/wrapper-maintenance']);
          this.appStore.state.state.currentUrl = '/wrapper-maintenance';
        }
        // else {
        //   alert('Offline, Click OK to Continue');
        // }
      } else {
        this.appStore.connectionStatus = true;
        console.log('Online');
      }
    });

    const refreshObj = JSON.parse(window.sessionStorage.getItem('refreshTime'));
    window.sessionStorage.removeItem('refreshTime');
    if (refreshObj) {
      this.appStore.refresh = refreshObj.time;
    } else {
      this.appStore.refresh = undefined;
    }

    let url = window.sessionStorage.getItem('refreshURL');
    // TODO: We need better solution to look for store without Query string for now removing params - For SSO Handler
    if (isDefined(url) && url !== null) {
      url = url.split('?')[0];
    }

    if (url && !(url === '' || url === '/' || url === '/forceupgrade' || url === '/_app/info' || url === '/sso' || url === '/error404' || url === '/error500' || url === '/wrapper-maintenance' || url === '/ssoazure/signon')) {
      typeOf(routeConfig(url).store)?.loadFromSessionStorage();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // medalliaLoaded() {
  //   if(window.KAMPYLE_ONSITE_SDK) {
  //     // load the form and store status (true/false) in neb_status
  //     var neb_status = window.KAMPYLE_ONSITE_SDK.loadForm(this.commonService.feedbackButtonFormId);
  //     if (neb_status === true){
  //       this.observableSubcscriptionService.feedbackAvailable.next(true);
  //     }
    
  //   }
  // }
}
