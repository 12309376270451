import { RouteGroup } from "../shared/models/route-config";

export const TradeMLSConfig: RouteGroup = {
    "/txn/optionsCentral/MultiLeg/landing": {
        name: "TradeMLSLanding",
        service: "TradeMLSLandingService",
        store: "TradeMLSLandingStore",
        resolveContent: "tradeMLSlanding",
        policy: {
            navFrom: {
                url: ["*"],
                fallBackUrl: "",
            },
            navTo: {
                url: ["*"],
                fallBackUrl: "",
            },
        },
        restService: [
            {
                id: "init",
                restUrl: "multilegInit",
                get request(): any {
                  return {
                    IncludeBalance: true,
                  };
                },
              },
              {
                id: "holdings",
                restUrl: "getOrderHoldings",
                optional: true,
                get request(): any {
                  return {
                    FiType: "O"
                  };
                },
              },
              {
                id: "settlmentAccounts",
                restUrl: "optionsSettlementAccounts",
                optional: true,
                get request(): any {
                  return {
                    Type: "OPTIONS",
                  };
                },
              },
              {
                id: "chainDetail",
                restUrl: "optionsChainDetail",
                optional: true,
                get request(): any {
                  return {};
                },
              },
              {
                id: "quickQuote",
                restUrl: "quoteQuick",
                optional: true,
                get request(): any {
                  return {
                    Symbols: [
                      {
                        SymbolType: "OPTION",
                      },
                    ],
                  };
                },
              },
              {
                id: "quickQuoteleg1",
                restUrl: "quoteQuick",
                optional: true,
                get request(): any {
                  return {
                    Symbols: [
                      {
                        SymbolType: "OPTION",
                      },
                    ],
                  };
                },
              },
              {
                id: "quickQuoteleg2",
                restUrl: "quoteQuick",
                optional: true,
                get request(): any {
                  return {
                    Symbols: [
                      {
                        SymbolType: "OPTION",
                      },
                    ],
                  };
                },
              },
              {
                id: "chainDetailleg1",
                restUrl: "optionsChainDetail",
                optional: true,
                get request(): any {
                  return {};
                },
              },
              {
                id: "chainDetailleg2",
                restUrl: "optionsChainDetail",
                optional: true,
                get request(): any {
                  return {};
                },
              },
              {
                id: "holdingsleg2",
                restUrl: "getOrderHoldings",
                optional: true,
                get request(): any {
                  return {
                    FiType: "O"
                  };
                },
              },
        ],
        trackingData: {
            hierarchy: "trading,trade-options,multi-leg",
            name: "details",
            nameGA: "Trade Multi-leg Options",
            formInfo: { name: "obr-trade-options-multi-leg", stepName: "details" },
            events: { formStep: true, formView: true, pageView: true,},
        },
    },
    "/txn/optionsCentral/MultiLeg/verify": {
        name: "TradeMLSVerify",
        service: "TradeMLSVerifyService",
        store: "TradeMLSVerifyStore",
        resolveContent: "tradeMLSverification",
        policy: {
            navFrom: {
                url: ["*"],
                fallBackUrl: "",
            },
            navTo: {
                url: ["*"],
                fallBackUrl: "",
            },
        },
        restService: [
            {
                id: "verify",
                restUrl: "multilegVerify",
                get request(): any {
                  return {};
                },
            },
        ],
        trackingData: {
            hierarchy: "trading,trade-options,multi-leg",
            name: "Verification",
            nameGA: "Trade Multi-leg Options - Verification",
            formInfo: { name: "obr-trade-options-multi-leg", stepName: "Verification" },
            events: { formStep: true, pageView: true },
        },
    }

};
