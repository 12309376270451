import { Injectable } from '@angular/core';
import { ChangeSignOnPasswordLandingData } from './change-signon-password-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeSignOnPasswordLandingStore extends PageStore<ChangeSignOnPasswordLandingData> {

    constructor() {
        super(new ChangeSignOnPasswordLandingData());
    }

}
