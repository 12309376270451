import { NgModule } from '@angular/core';
import { SafePipe } from 'src/app/core/pipes/safe.pipe';
import { ChangeFormatterPipe } from 'src/app/core/pipes/change-formatter.pipe';
import { CurrencyFormatterPipe } from 'src/app/core/pipes/currency-formatter.pipe';
import { phoneNumFormatPipe } from 'src/app/core/pipes/phoneNum-formatter.pipe';
import { TimeDifferencePipe } from '../core/pipes/time-difference.pipe';
import { RemoveDollarPipe } from '../core/pipes/remove-dollar.pipe';
import { PaginationPipe } from '../core/pipes/pagination-slice.pipe';

@NgModule({
  declarations: [
    CurrencyFormatterPipe,
    ChangeFormatterPipe,
    SafePipe,
    phoneNumFormatPipe,
    TimeDifferencePipe,
    RemoveDollarPipe,
    PaginationPipe
  ],
  imports: [
  ],
  exports: [
    CurrencyFormatterPipe,
    ChangeFormatterPipe,
    SafePipe,
    phoneNumFormatPipe,
    TimeDifferencePipe,
    RemoveDollarPipe,
    PaginationPipe
  ]
})
export class PipeSharedModule { }
