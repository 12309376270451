import { ContentType } from './global-content-store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ContentStore implements ContentType {
    name: any;
    lob: any;
    lang: any;
    text?: any;
    error?: any;
    list?: any;
    dropdown?: any;
}
