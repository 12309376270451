import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { ExchangeCurrencyLandingStore } from '../data/exchange-currency-landing-store';
import { HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import {ToAccountListReq} from "../../../cashtransfer/landing/data/cashtransfer-landing-data";
import {
  ToCancelFxOrderReq,
  ToCreateFxOrderReq, ToFxInitReq,
  ToFxRateReq,
  ToGetAccountReq,
  ToGetFromToAccountReq
} from "../data/exchange-currency-landing-data";



declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class ExchangeCurrencyLandingService implements PageService {

  constructor(private appStore: AppStore, private httpService: HttpService, public http: HttpClient) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  getFxRate(data) {
    return this.httpService.post(gatewayConfig.APIServices.getFxRate.url, data, { params: { skiploading: 'true', avoidSessionExtension: true } });
  }

  createFxOrder(data) {
    return this.httpService.post(gatewayConfig.APIServices.createFxOrder.url, data);
  }

  getCancelFxOrder(data) {
    const req: ToCancelFxOrderReq = new ToCancelFxOrderReq();
    req.OrderId = data.OrderId;
    return this.httpService.post(gatewayConfig.APIServices.cancelFxOrder.url, req);
  }

  getFxOrderByAccount(data) {
    return this.httpService.post(gatewayConfig.APIServices.getFXOrdersByAccount.url, data);
  }

  getFxOrderFromToAccount(data) {
    const req: ToGetFromToAccountReq = new ToGetFromToAccountReq();
    req.FromAccountNumber = data.FromAccountNumber;
    req.ToAccountNumber = data.ToAccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.getFxFromToAccount.url, req);
  }

  businessHours(){
    return this.httpService.post(gatewayConfig.APIServices.businessHours.url, {});
  }

  duplicateOrder(req){
    return this.httpService.post(gatewayConfig.APIServices.duplicateFxOrder.url, req);
  }
}
