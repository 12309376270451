import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PersistenceService } from 'src/app/core/services/persistence.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  getCount: any;
  resetExtendSessionTimer: boolean;

  constructor(private persistanceService: PersistenceService) { }

  // set counter count to session storage
  setClickCounter(count) {
    this.persistanceService.sessionStorePersist('sessionExtensionCounter', count);
  }

  // get counter count from session storage

  getClickCounter() {
    this.getCount = this.persistanceService.sessionStoreRetrieve('sessionExtensionCounter');
    if (this.getCount === null || NaN || undefined) {
      this.getCount = 0;
    }
    return Number(this.getCount);
  }
}
