import { BaseData } from 'src/app/shared/models/base-data';
import { InFlowSymbolData } from 'src/app/modules/txn/tradeOptions/landing/data/trade-options-landing-data';

export class NasdaqAgreementData extends BaseData {

    constructor() {
        super();
        this.input = {
            AgreementName: undefined,
            UserData: {
                Subscriber: {
                    Name: '',
                    Address: '',
                    Title: '0'
                },
                Individual: true,
                NonUS: true,
                SubscriberSignature: '',
                Pro: undefined,
                AcceptAgreement: undefined,
                nonUSSubscriberGroup: undefined
            }
        };
    }
}

export interface NasdaqAgreementInputs {
    AgreementName: string;
    UserData?: UserData;
}
export interface UserData {
    Subscriber: Subscriber;
    SubscriberCopyTo?: string;
    Pro: boolean;
    Individual: boolean;
    NonUS: boolean;
    SubscriberSignature: string;
    nonUSSubscriberGroup?: boolean;
    AcceptAgreement: boolean;
}

export interface Subscriber {
    Name: string;
    Address: string;
    Title: string;
    Phone?: string;
    Fax?: string;
    Email?: string;
}



