import { Injectable } from '@angular/core';
import { ChangeTradingPasswordLandingData } from './change-trading-password-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeTradingPasswordLandingStore extends PageStore<ChangeTradingPasswordLandingData> {

    constructor() {
        super(new ChangeTradingPasswordLandingData());
    }

}
