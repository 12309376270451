import { Directive, HostListener, Renderer2, ElementRef, Input, OnChanges, HostBinding } from '@angular/core';
import { isDefined } from 'src/app/shared/services/utils.service';

@Directive({
  selector: '[selectDefaultDirective]'
})
export class SelectDefaultDirective implements OnChanges {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  @Input() selectDefaultDirective: any;
  // @HostBinding('class')
  // className = '';

  ngOnChanges() {
    if (this.selectDefaultDirective) {
      // this.className = "has-field-error";
      this.renderer.addClass(this.el.nativeElement, "not-chosen");
    } else {
      // this.className = "";
      this.renderer.removeClass(this.el.nativeElement, "not-chosen");
    }
  }
}