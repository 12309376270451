import { Component, OnInit, Output, EventEmitter, OnDestroy, HostListener, Input, ViewChild, ElementRef } from '@angular/core';
import { isDefined } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.scss']
})

export class StickyHeaderComponent implements OnInit {

  @Input() group: string;
  @Input() adjustedSmall: boolean = true;
  @Input() adjustedBig: boolean = true;

  fn: any;
  stick: boolean = false;

  ready: boolean = false;

  constructor() { }

  @ViewChild('stickContainer') elem: ElementRef;

  ngOnInit() {
    setTimeout(() => {
      this.ready = true;
    }, 10);
    // this.elem.nativeElement.dataset.stick = false;
  }
}
