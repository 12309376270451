import { BaseData } from 'src/app/shared/models/base-data';

export class EventsCalendarData extends BaseData {

    constructor() {
        super();
        this.input = {
        };
    }
}

export interface EventsCalendarInputs {
}

