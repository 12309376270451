<ng-container *ngIf="lob != 'ie'; else IEHeader">
  <div *ngIf="!breakPoint" class="grid-container"
    [ngClass]="{'grid-container-preheader' : preHeader,  'header-background-color' : globalRefresh}">
    <app-skip-to-main-content *ngIf="!preHeader && !isApp" class="A14 skip"></app-skip-to-main-content>

    <!--TODO shared module should have routes to have routerlink in html  -->
    <button *ngIf="!showChevron && !isSSO" (click)="navigateToMyAccounts()" id="cibcLogo" class="cibcLogo">
      <img *ngIf="cibcLogoWithDesc && lob != 'gen'" src="assets/images/logos/cibc_{{lob}}_{{lang}}_rev_mobile.svg"
        [alt]="globalContent.text.logo" class="cibcmobile-img">
      <!-- image without description -->
      <img *ngIf="!cibcLogoWithDesc || lob == 'gen'" class="cibcmobile-img" src="assets/images/logos/cibc_reverse.svg"
        [alt]="globalContent.text.logo">
    </button>
    <div *ngIf="isSSO && isInApp" class="cibcLogo">
      <img class="cibcmobile-img" src="assets/images/logos/cibc_reverse.svg" [alt]="globalContent.text.logo">
    </div>

    <a *ngIf="showChevron" href="javascript:void(0);" (click)="navigateBack()" id="header-chevron"
      class="icon-Chevron-Left color01" [attr.aria-label]="globalContent.text.back">
    </a>

    <a *ngIf="isSSO && !isInApp" href="javascript:void(0);" (click)="goToOnlineBanking()" id="header-chevron"
      class="icon-Chevron-Left ssoChevron color01" [attr.aria-label]="globalContent.text.back">
    </a>


    <div class="thirdLevelNavigation">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>

    <div
      *ngIf="preHeader && (_routePermissionService.enableToggleLocaleButton || (_routePermissionService.enablePreSignoff && !isInApp))"
      class="pre-header-buttons">
      <ng-container *ngIf="_routePermissionService.enableToggleLocaleButton">
        <button class="toggleLocale" (click)="toggleLocale();" *ngIf="!isApp">{{globalContent.text.languageSwitch}}
        </button>
      </ng-container>
      <ng-container *ngIf="(_routePermissionService.enablePreSignoff && !isInApp)">
        <button class="signoff color01" (click)="signOff()">
          {{globalContent.text.signOff}}
        </button>
      </ng-container>
    </div>

    <div *ngIf="enableLogoClick" class="hamburgerIcon" id="hamburger-icon">
      <button class="Pseudo-Button icon-Hamburger hamburgerFont" attr.aria-label="{{globalContent.text.openMenu}}"
        id="hamburger-menu" (click)="Menu()">
      </button>
    </div>
  </div>
</ng-container>
<ng-template #IEHeader>
  <div *ngIf="!breakPoint" class="ie-grid-container" [ngClass]="{ 'grid-container-preheader': preHeader, 'dco-grid-container': isSourceDCO, 'grid-container': !isSourceDCO }">
    <app-skip-to-main-content *ngIf="!preHeader && !isApp" class="A14 skip"></app-skip-to-main-content>

    <a *ngIf="showChevron" href="javascript:void(0);" (click)="navigateBack()" id="header-chevron"
      class="icon-Chevron-Left ie-header-chevron" [attr.aria-label]="globalContent.text.back">
    </a>
    <a *ngIf="isSSO && !isInApp" href="javascript:void(0);" (click)="goToOnlineBanking()" id="header-chevron"
      class="icon-Chevron-Left ie-header-chevron" [attr.aria-label]="globalContent.text.back">
    </a>

    <div *ngIf="!preHeader" class="ie-small-predictive-search-container" [ngClass]="{
      'show-chevron': showChevron,
      'full-width': predictiveState != EXPAN.CONTRACTED
    }">
      <label id="headerPredictiveSearchLabel" class="sr-only">{{globalContent.text.predictiveLabel}}</label>
      <div class="headerPredictiveSearchwrapper ie-header-predictiveSearch">
        <app-predictive-search [placeholder]="globalContent.text.predictivePlaceholder"
          [labelledbyAttr]="'headerPredictiveSearchLabel'" [describedbyAttr]="null" [contents]="globalContent"
          pagename="IESmallHeader" (selectedSymbol)="selectSymbol($event)" [query]="predictiveQuery" limit='5'
          [styling]="'white'" (recieveExpansionState)="changePredictiveState($event)"
          [closeSearch]="closeSearch"></app-predictive-search>
      </div>
    </div>

    <div *ngIf="!showChevron && !isSSO && preHeader" id="cibcLogo" class="ie-cibcLogo-left">
      <img
        [src]="cibcLogoWithDesc ? 'assets/images/logos/cibc_ie_' + lang + '_mobile.svg' : 'assets/images/logos/CIBC_CR_2C_RGB.svg'"
        [alt]="globalContent.text.logo" class="cibcmobile-img">
    </div>
    <div *ngIf="isHome" id="cibcLogo" class="ie-cibcLogo-home">
    <img *ngIf="cibcLogoWithDesc  && (!PEEnabled || isSignedOn === 'false')" src="assets/images/logos/cibc_ie_{{lang}}_mobile.svg"
      [alt]="globalContent.text.logo" class="cibcmobile-img">
    <img *ngIf="cibcLogoWithDesc  && (PEEnabled && isSignedOn === 'true') && lang === 'en'" src="assets/images/logos/Premium Edge_REV_en.svg"
    [alt]="globalContent.text.logo" class="cibcmobile-img">
    <img *ngIf="cibcLogoWithDesc && (PEEnabled && isSignedOn === 'true') && lang === 'fr'" src="assets/images/logos/Premium Edge_REV_FRE.svg"
    [alt]="globalContent.text.logo" class="cibcmobile-img">
    </div>

    <div class="thirdLevelNavigation ie-thirdLevelNavigation">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>

    <a *ngIf="isHelpButton" class="ie-signon-help-link icon-Question" href="javascript:void(0);"
      (click)="openAEMHelp(globalContent.text.signonHelpLinkUrl)" [attr.aria-label]="globalContent.text.signonHelpLinkAria">
    </a>

    <div *ngIf="preHeader && _routePermissionService.enablePreSignoff && !isInApp" class="pre-header-buttons">
      <button class="signoff ie-signoff" (click)="signOff()">
        {{globalContent.text.signOff}}
      </button>
    </div>

    <div *ngIf="enableLogoClick && isHome" class="news">
      <label id="headerNewsHover">
        {{globalContent.text.whatsNew}}
        <span class="sr-only">{{globalContent.text.whatsNewAria}} {{unreadNews ? 'unread stuff' : ''}}</span>
        <span class="headerNewsHoverArrow" aria-hidden="true"></span>
      </label>
      <a class="icon-Megaphone news-icon" [ngClass]="PEEnabled ? 'news-icon-white':''" (click)="openNews()" href="javascript:void(0)"
        aria-labelledby="headerNewsHover" id="headerNewsLink">
        <div *ngIf="unreadNews" class="unread-news-dot"></div>
      </a>
    </div>

    <div *ngIf="enableLogoClick" class="hamburgerIcon grey-hamburgerIcon" [ngClass]="PEEnabled? 'white-hamburgerIcon':''" id="hamburger-icon">
      <button class="Pseudo-Button icon-Hamburger hamburgerFont" attr.aria-label="{{globalContent.text.openMenu}}"
        id="hamburger-menu" (click)="Menu()">
      </button>
    </div>
  </div>
  <app-global-refresh *ngIf="globalRefresh" [type]="globalRefresh" [name]="'header'"></app-global-refresh>
</ng-template>

<div class="grid-container" [ngClass]="{'dco-grid-container': isSourceDCO , 'grid-container-preheader' : preHeader, 'grid-container-IE': lob === 'ie' && !preHeader, 'ie-preheader': (lob == 'ie' && displayIEModernHeader(this.appStore.state.state.currentUrl) && !isSSO), 'ie-header': lob == 'ie'}" *ngIf="breakPoint">
  <app-skip-to-main-content *ngIf="!preHeader && !isApp" class="skip"></app-skip-to-main-content>

  <div class="cibcLogoWrapper">
    <a href="javascript:void(0);" (click)="navigateToMyAccounts()" id="cibcLogo" class="cibcLogo">
      <img *ngIf="lob != 'gen' && (!PEEnabled || isSignedOn === 'false')" [ngClass]="{'cibc-img': lob != 'ie'}" src="assets/images/logos/cibc_{{lob}}_{{lang}}.svg"
        [alt]="globalContent.text.logo">
      <span *ngIf="lob == 'ie' && displayIEModernHeader(this.appStore.state.state.currentUrl)" class="sr-only">{{globalContent.text.IElogoHdnTxt}}</span>
      <img *ngIf="lob == 'gen'" class="cibc-img" src="assets/images/logos/CIBC_CR_2C_RGB.svg"
        [alt]="globalContent.text.logo">
      <img *ngIf="lob != 'gen' && PEEnabled && isSignedOn === 'true'" class="cibc-img" src="assets/images/logos/Premium Edge_REV_{{lang}}.svg"
      [alt]="globalContent.text.logo">   
    </a>
  </div>

  <div>
    <div class="headerPredictiveSearchContainer" *ngIf="!preHeader && !isAnyApp && !isHelp">
      <label id="headerPredictiveSearchLabel"
        [ngClass]="lob == 'ie' ? 'sr-only' : ''">{{globalContent.text.predictiveLabel}}</label>
      <div class="headerPredictiveSearchwrapper" [ngClass]="lob == 'ie' ? 'ie-adjust' : ''">
        <app-predictive-search [placeholder]="globalContent.text.predictivePlaceholder"
          [labelledbyAttr]="'headerPredictiveSearchLabel'" [describedbyAttr]="null" [contents]="globalContent"
          pagename="Header" (selectedSymbol)="selectSymbol($event)" (selectedExtra)="selectExtra($event)"
          [query]="predictiveQuery" limit='5'></app-predictive-search>
      </div>
    </div>

    <div class="paddingTop10 notificationMastHead" *ngIf="!preHeader && lob != 'ie'">
      <div class="printLink" *ngIf="!isApp && appStore.globalAlert.edocs">
        <a routerLink="/txn/edocuments" href="javascript:void(0)">
          <span class="icon-new-eDdoc hoverPointer edocsPositionTop" alt="" aria-hidden="true"></span>
          <span class="A12 color02 paddingLeft6 textPosition">{{globalContent.text.eDocs}}</span>
        </a>
        <span class="divider eDocumentsHeader">|</span>
      </div>
      <div class="printLink">
        <a (click)="print()" href="javascript:void(0)">
          <span class="icon-Print hoverPointer printPosition" alt="" aria-hidden="true"></span>
          <span class="A12 color02 paddingLeft6 textPosition">
            {{globalContent.text.print}}</span>
        </a>
        <span class="divider eDocumentsHeader">|</span>
      </div>
      <div *ngIf='isPermission("IRHLP")' class="helpLink">
        <a class="align-items" (click)="openHelp()" href="javascript:void(0)">
          <span class="icon-Question-Thin hoverPointer" alt="" aria-hidden="true"></span>
          <span class="A12 color02 paddingLeft6 positionTop">{{globalContent.text.help}}</span>
        </a>
      </div>
    </div>

    <div *ngIf="lob == 'ie' && !isSourceDCO && (this.appStore.state.state.currentUrl) && isToggleLocaleEnable()">
      <button class="ieToggleLocale" (click)="toggleLocale();" *webOnly
        [attr.lang]="appStore.lang == 'en' ? 'fr' : 'en'">{{globalContent.text.languageSwitch}}
      </button>
    </div>

    <div *ngIf="lob == 'wg' && this.appStore.state.state.currentUrl == '/splash'">
      <a class="myAcc-link" href="javascript:void(0);" (click)="goToMyAccountPage()">
        <span class="myAcc-txt">{{globalContent.text.skipForNow}}</span>
        <span class="icon-Chevron-Right"></span>
      </a>
    </div>
  </div>
</div>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>