<div class="cibc-account-block" [ngClass]="blockClasses()">
  <button id="acc_card_buyPowerHeader" *ngIf="selectable && displayHeader && mode !== 'BuyingPowerSubBalances'" type="button"
    class="account color03" (click)="select(index)" [ngClass]="{'disabledFX': account.disabled, 'icon-Chevron-Right': !account.disabled}" [disabled]="account.disabled">
    <div>
      <span class="account-number color02" [ngClass]="{'color03': account.disabled}">{{globalContent.accountNickname(account)}}
        <span [innerHTML]="account.AccountNumber | srOnly:true"></span>
        <span *ngIf="mode=='CashTransferFrom' || mode=='CashTransferTo' || mode=='ExchangeCurrency'">
          {{globalContent.getCurrencyContent(account.Currency)}}</span>
      </span>
      <div
        *ngIf="mode=='Trade' || mode=='OrderStatus' || mode=='CashTransferFrom' || mode=='CashTransferTo' || mode=='Changerips' || mode=='ExchangeCurrency'"
        class="account-name color03" [ngClass]="{'color03': account.disabled}" [innerHTML]="account.Fullname | srOnly:true"></div>
    </div>
  </button>
  <div id="acc_card_buyPower_accBalance" *ngIf="!selectable && displayHeader && mode !== 'BuyingPowerSubBalances'" class="account">
    <div>
      <span *ngIf="mode!=='Changerips'" tabindex="-1" [attr.id]="titleId"
        class="account-number color02">{{globalContent.accountNickname(account)}}
        <span [innerHTML]="account.AccountNumber | srOnly:true"></span>
      </span>
      <div *ngIf="mode==='Changerips'">
        <span tabindex="-1" [attr.id]="titleId" class="account-number color02">{{account.AccountNumber}}
          {{globalContent.list.accountType[account.AccountType]}}
        </span>
        <div class="account-name color03" [innerHTML]="globalContent.accountNickname(account)"></div>
      </div>
      <div
        *ngIf="mode=='Trade' || mode=='OrderStatus' || mode=='CashTransferFrom' || mode=='CashTransferTo' || mode==='Changerips' || mode=='ExchangeCurrency'"
        class="account-name color03" [innerHTML]="account.Fullname | srOnly:true"></div>
    </div>
  </div>
  <div *ngIf="((!selectable || showBalance) && (mode=='Trade' || mode=='OrderStatus' || mode==='Changerips' || mode=='BuyingPowerSubBalances' || (mode=='CashTransferFrom' && account.AccountType != 'BNK' ) || 
    (mode=='CashTransferTo' && account.AccountType != 'BNK' ) || (mode=='ExchangeCurrency' && !account.disabled)))" class="modal-subaccount-block">
    <div *ngIf="!loading && !balanceLoading && mode !='ExchangeCurrency'">
      <table *ngIf="mode!='BuyingPowerSubBalances'">
        <tbody [ngClass]="fontMode == 'small'? 'smallfont' : ''">
          <ng-container  *ngIf="mode !== 'BuyingPowerSubBalances'">
            <tr id="acc_card_buyPowersubBalances-{{j}}" [ngClass]="(balance.AccountType == 'MRL' && displayMargin) ? 'margin-show' : 'subaccount'"
              *ngFor="let balance of account.AccountBalances let j = index">
              <th scope="row" class="subaccount-title color03" *ngIf="balance.AccountType != 'MRL' || displayMargin">
                <span>
                  {{globalContent.getBalanceContent(balance.AccountType)}}
                  <span
                    [innerHtml]='"("+globalContent.getCurrencyContent(balance.Currency)+")"+colonStr | currencyFormatter:balance.Currency:"accessibilityOnly"'></span>
                </span>
              </th>
              <td class="subaccount-value color02"
                *ngIf="balance.Amount && (balance.AccountType != 'MRL' || displayMargin)">
                <span [innerHTML]="balance.Amount | currencyFormatter:balance.Currency:'format-default'"></span>
              </td>
              <td class="spinner-container cashbalance-spinner" *ngIf="!balance.Amount && balance.AccountType != 'MRL'">
                <app-general-spinner></app-general-spinner>
              </td>
            </tr>
          </ng-container>
          <!-- <tr *ngIf="mode=='CashTransferFrom' || mode=='CashTransferTo'">
          <td colspan="2">
            <dl class="margin-container" *ngFor="let balance of account.AccountBalances let j = index" >
              <div class="margin" *ngIf="balance.AccountType == 'MRL'">
                <dt class="margin-title">
                  <div class="margin-title-text">
                    {{content.text.marginText}} ({{globalContent.getCurrencyContent(balance.Currency)}})
                  </div>
                </dt>
                <dd class="margin-value">
                  <span [innerHTML]="balance.Amount | currencyFormatter:balance.Currency:'format-default'"></span>
                </dd>
              </div>
            </dl>
          </td>
        </tr> -->
          <tr
            *ngIf="displayBuyingPower && content && account.BuyingPowerValueType && mode !== 'BuyingPowerSubBalances'">
            <td colspan="2">
              <dl class="buyingpower-container" [ngClass]="!selectable ? 'info-section' : ''">
                <div class="buyingpower" *ngIf="!selectable && mode != 'CashTransferFrom' && mode != 'CashTransferTo'">
                  <dt class="est-buyingpower-title-text">
                    {{content.text.estimatedBuyingPower}}
                  </dt>
                  <dd class="est-buyingpower-tooltip">
                    <!-- <app-tooltip *ngIf="content" [id]="'estimatedBuyingPowerToolTip'"
                    [tooltipContent]="estimatedBuyingPowerToolTip" openIconName="icon-Info-Circle"
                    class="estimatedToolTip" mode="modal"> -->
                    <app-tooltip *ngIf="content" [id]="'buyingPowerToolTip_1a'+uniqueId"
                      [tooltipContent]="toolTipContent" openIconName="icon-Info-Circle" mode="modal">

                      <div
                        *ngIf="((account.BuyingPowerValueType!='COMBINED') && (account.BuyingPowerValueType!='SHOW_CAD'&& account.BuyingPowerValueType!='SHOW_CAD_AND_USD'))">
                        <div *ngIf="isBuyingPowerIncrease">
                          <div>{{content.text.toolTipIntroContainer2}}</div>
                          <br>
                          <div>{{content.text.buyingPowerHelpIncreasePara2}}</div>
                          <br>
                          <div>{{content.text.toolTipCloseContainer4}}</div>
                          <br>
                          <div>
                            {{content.text.toolTipCloseContainer2}}
                          </div>
                          <br>
                          <div>
                            {{content.text.toolTipCloseContainer3}}
                          </div>
                        </div>

                        <div *ngIf="!isBuyingPowerIncrease">
                          <div>{{content.text.buyingPowerHelpDecreasePara1}}</div>
                          <br>
                          <div>{{content.text.buyingPowerHelpDecreasePara2}}</div>
                          <br>
                          <div>{{content.text.buyingPowerHelpDecreasePara3}}</div>
                          <br>
                          <div>
                            {{content.text.toolTipCloseContainer2}}
                          </div>
                          <br>
                          <div>
                            {{content.text.toolTipCloseContainer3}}
                          </div>
                        </div>
                      </div>

                      <!-- for registered account -->

                      <div
                        *ngIf="((account.BuyingPowerValueType!='COMBINED') && (account.BuyingPowerValueType=='SHOW_CAD'||account.BuyingPowerValueType=='SHOW_CAD_AND_USD'))"
                        class="tooltip-lightbox">
                        <!-- <div class="tooltip-lightbox"> -->
                        <div>{{content.text.toolTipIntroContainer3New}}</div>
                        <br>
                        <div>{{content.text.toolTipSubheading1New}}</div>
                        <div>
                          <ul class='tooltipList'>
                            <li>{{content.list.toolTipNewList.cashBalances}}</li>
                            <li>{{content.list.toolTipNewList.marginableLoanValueIfHeld}}</li>
                            <li>{{content.list.toolTipNewList.pendingCashTransfersOut}}</li>
                            <li>{{content.list.toolTipNewList.openOrdersDecreaseBuyingPower}}</li>
                            <li>{{content.list.toolTipNewList.filledOrdersBeforeASettlement}}</li>
                          </ul>
                        </div>
                        <br>
                        <div>{{content.text.toolTipNewContainerAllAccountTypes1}}</div>
                        <br>
                        <div *ngIf="this.appStore.lob == 'ie'">{{content.text.toolTipNewContainerAllAccountTypes2}}
                        </div>
                        <br>
                        <div *ngIf="this.appStore.lob == 'ie'">{{content.text.learnMore}}
                          <a class="underline" href="javascript:void(0);" id="acc_card_buyPower_link2"
                            (click)="openWindow(content.text.buyingPowerLink)">{{content.text.buyingPowerText}}</a>
                          <span class='sr-only'>{{content.text.newWindowText}}</span>.
                        </div>
                        <div *ngIf="this.appStore.lob != 'ie'">{{content.text.toolTipCloseContainer3}}</div>
                        <!-- </div> -->
                      </div>


                      <!-- For non registered account -->
                      <div
                        *ngIf="((account.BuyingPowerValueType=='COMBINED') && (mode === 'Trade' || mode=='Changerips' || mode === 'CashTransferFrom' || mode === 'CashTransferTo'))"
                        class="tooltip-lightbox">
                        <!-- <div class="tooltip-lightbox"
                          *ngIf="(mode === 'Trade' || mode === 'CashTransferFrom' || mode === 'CashTransferTo')"> -->
                        <div>{{content.text.toolTipIntroContainer3New}}</div>
                        <br>
                        <div>{{content.text.toolTipSubheading1New}}</div>
                        <div>
                          <ul class='tooltipList'>
                            <li>{{content.list.toolTipNewList.cashBalances}}</li>
                            <li>{{content.list.toolTipNewList.marginableLoanValueIfHeld}}</li>
                            <li>{{content.list.toolTipNewList.pendingCashTransfersOut}}</li>
                            <li>{{content.list.toolTipNewList.openOrdersDecreaseBuyingPower}}</li>
                            <li>{{content.list.toolTipNewList.filledOrdersBeforeASettlement}}</li>
                          </ul>
                        </div>
                        <br>
                        <ng-container *ngIf="this.appStore.lob == 'ie'">
                          <div>{{content.text.toolTipNewContainerAllAccountTypes1}}</div>
                          <br>
                          <div>{{content.text.toolTipNewContainerAllAccountTypes2}}</div>
                          <br>
                          <div>{{content.text.learnMore}}
                            <a class="underline" href="javascript:void(0);" id="acc_card_buyPowerText_link"
                              (click)="openWindow(content.text.buyingPowerLink)">{{content.text.buyingPowerText}}</a>
                            <span class='sr-only'>{{content.text.newWindowText}}</span>.
                          </div>
                          <br>
                          <div>
                            <a class="underline" href="javascript:void(0);"
                              (click)="openWindow(content.text.getMoreLink)">{{content.text.getMore}}</a>
                            <span class='sr-only'>{{content.text.newWindowText}}</span>
                            {{content.text.marginRequirements}}
                          </div>
                        </ng-container>
                        <ng-container *ngIf="this.appStore.lob != 'ie'">
                          <div>{{content.text.toolTipCloseContainer4}}</div>
                        </ng-container>

                        <!-- </div> -->
                      </div>


                    </app-tooltip>
                  </dd>
                </div>
                <div class="buyingpower" *ngIf="account.BuyingPowerValueType=='COMBINED'">
                  <dt class="buyingpower-title">
                    <div class="buyingpower-title-text">
                      {{content.text.combinedBuyingPower}}
                      <span
                        [innerHTML]='bracketVal(content.text.canadianDollarAbbreviation)+colonStr | currencyFormatter:"CAD":"accessibilityOnly"'>
                      </span>
                      <!-- dont dispaly tool tip on combinedBuyingPower sub header -->
                      <app-tooltip *ngIf="(displayLink && (mode != 'Trade'  ))" [id]="'buyingPowerToolTip_1'+uniqueId"
                        [tooltipContent]="toolTipContent" openIconName="icon-Info-Circle" mode="modal">
                        <div class="tooltip-lightbox" *ngIf="mode === 'accountHoldings'">
                          <div>{{content.text.toolTipIntroContainer1}}</div>
                          <br>
                          <div>{{content.text.toolTipSubheading1}}</div>
                          <div>
                            <ul class='tooltipList'>
                              <li>{{content.list.toolTipList1.cashBalances}}</li>
                              <li>{{content.list.toolTipList1.marginableLoanValue}}</li>
                              <li>{{content.list.toolTipList1.pendingCashTransfersOut}}</li>
                              <li>{{content.list.toolTipList1.openOrdersDecreaseBuyingPower}}</li>
                              <li>{{content.list.toolTipList1.filledOrdersPriorSettlement}}</li>
                              <li>{{content.list.toolTipList1.buyingPowerCrossGuaranteed}}</li>
                            </ul>
                          </div>
                          <br>
                          <div>{{content.text.toolTipCloseContainer1}}</div>
                        </div>
                        <!-- <div class="tooltip-lightbox"
                        *ngIf="(mode === 'Trade' || mode === 'CashTransferFrom' || mode === 'CashTransferTo')"> -->
                        <div class="tooltip-lightbox"
                          *ngIf="(mode === 'CashTransferFrom' || mode === 'CashTransferTo')">
                          <div>{{content.text.toolTipIntroContainer3}}</div>
                          <br>
                          <div>{{content.text.toolTipSubheading1}}</div>
                          <div>
                            <ul class='tooltipList'>
                              <li>{{content.list.toolTipList3.cashBalances}}</li>
                              <li>{{content.list.toolTipList3.marginableLoanValueIfHeld}}</li>
                              <li>{{content.list.toolTipList3.pendingCashTransfersOut}}</li>
                              <li>{{content.list.toolTipList3.openOrdersDecreaseBuyingPower}}</li>
                              <li>{{content.list.toolTipList3.filledOrdersPriorSettlement}}</li>
                              <li>{{content.list.toolTipList3.buyingPowerCrossGuaranteed}}</li>
                            </ul>
                          </div>
                          <br>
                          <div>{{content.text.toolTipCloseContainer4}}</div>
                        </div>
                        <!-- Investment increase decrease : step2 Verification page-->
                        <div class="tooltip-lightbox" *ngIf="mode === 'TradeVerification'">
                          <div *ngIf="isBuyingPowerIncrease">
                            <div>{{content.text.toolTipIntroContainer2}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpIncreasePara2}}</div>
                            <br>
                            <div>{{content.text.toolTipCloseContainer4}}</div>
                          </div>
                          <div *ngIf="!isBuyingPowerIncrease">
                            <div>{{content.text.buyingPowerHelpDecreasePara1}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpDecreasePara2}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpDecreasePara3}}</div>
                            <div>{{content.text.toolTipCloseContainer4}}</div>
                          </div>
                        </div>
                      </app-tooltip>
                    </div>
                    <div class="buyingpower-tooltip">

                    </div>
                  </dt>
                  <dd class="buyingpower-value">
                    <span>{{ returnValue(account.ConsolidatedBuyingPower) }}</span>
                    <span class="spinner-container" *ngIf="loading || balanceLoading">
                      <app-general-spinner></app-general-spinner>
                    </span>
                  </dd>
                </div>
                <!-- Registed increase decrease will come here:scroll down -->
                <div class="buyingpower"
                  *ngIf="(account.BuyingPowerValueType=='SHOW_CAD'||account.BuyingPowerValueType=='SHOW_CAD_AND_USD')">
                  <dt class="buyingpower-title">
                    <div class="buyingpower-title-text">
                      {{content.text.buyingPower}}
                      <span
                        [innerHTML]='bracketVal(content.text.canadianDollarAbbreviation)+colonStr | currencyFormatter:"CAD":"accessibilityOnly"'>
                      </span>
                    </div>
                    <div class="buyingpower-tooltip">
                      <app-tooltip *ngIf="(displayLink && (mode != 'Trade'))" [id]="' buyingPowerToolTip_2'+uniqueId"
                        [tooltipContent]="toolTipContent" openIconName="icon-Info-Circle" mode="modal">
                        <div class="tooltip-lightbox" *ngIf="mode === 'accountHoldings'">
                          <div>{{content.text.toolTipIntroContainer1}}</div>
                          <br>
                          <div>{{content.text.toolTipSubheading1}}</div>
                          <div>
                            <ul class='tooltipList'>
                              <li>{{content.list.toolTipList1.cashBalances}}</li>
                              <li>{{content.list.toolTipList1.marginableLoanValue}}</li>
                              <li>{{content.list.toolTipList1.pendingCashTransfersOut}}</li>
                              <li>{{content.list.toolTipList1.openOrdersDecreaseBuyingPower}}</li>
                              <li>{{content.list.toolTipList1.filledOrdersPriorSettlement}}</li>
                              <li>{{content.list.toolTipList1.buyingPowerCrossGuaranteed}}</li>
                            </ul>
                          </div>
                          <br>
                          <div>{{content.text.toolTipCloseContainer1}}</div>
                        </div>
                        <!-- <div class="tooltip-lightbox"
                        *ngIf="(mode == 'Trade' || mode == 'CashTransferFrom' || mode == 'CashTransferTo')"> -->
                        <div class="tooltip-lightbox" *ngIf="(mode == 'CashTransferFrom' || mode == 'CashTransferTo')">
                          <div>{{content.text.toolTipIntroContainer2}}</div>
                          <br>
                          <div>{{content.text.toolTipSubheading1}}</div>
                          <div>
                            <ul class='tooltipList'>
                              <li>{{content.list.toolTipList2.cashBalances}}</li>
                              <li>{{content.list.toolTipList2.pendingCashTransfersOut}}</li>
                              <li>{{content.list.toolTipList2.openOrdersDecreaseBuyingPower}}</li>
                              <li>{{content.list.toolTipList2.filledOrdersPriorSettlement}}</li>
                            </ul>
                          </div>
                          <br>
                          <div>{{content.text.toolTipCloseContainer2}}</div>
                          <div>{{content.text.toolTipCloseContainer3}}</div>
                        </div>
                        <!-- Registed increase decrease : step2 Verification page-->
                        <div class="tooltip-lightbox" *ngIf="mode === 'TradeVerification'">
                          <div *ngIf="isBuyingPowerIncrease">
                            <div>{{content.text.toolTipIntroContainer2}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpIncreasePara2}}</div>
                            <br>
                            <div>{{content.text.toolTipCloseContainer4}}</div>
                          </div>
                          <div *ngIf="!isBuyingPowerIncrease">
                            <div>{{content.text.buyingPowerHelpDecreasePara1}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpDecreasePara2}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpDecreasePara3}}</div>
                            <div>{{content.text.toolTipCloseContainer4}}</div>
                          </div>
                        </div>
                      </app-tooltip>
                    </div>
                  </dt>
                  <dd class="buyingpower-value">
                    <span>{{ returnValue(account.BuyingPowerCAD) }}</span>
                    <span class="spinner-container" *ngIf="loading || balanceLoading">
                      <app-general-spinner></app-general-spinner>
                    </span>
                  </dd>
                </div>
                <!-- This is for usd -->
                <div id="acc_card_buyPowerTitle_usd" class="buyingpower" *ngIf="account.BuyingPowerValueType=='SHOW_CAD_AND_USD'">
                  <dt class="buyingpower-title">
                    <div class="buyingpower-title-text">
                      {{content.text.buyingPower}}
                      <span [innerHTML]='bracketVal(content.text.USDollarAbbreviation)+colonStr |
                currencyFormatter:"USD":"accessibilityOnly"'>
                      </span>
                    </div>
                    <div id="acc_card_buyPower_tooltipTitle3" class="buyingpower-tooltip">
                      <app-tooltip *ngIf="(displayLink && (mode != 'Trade'))" [id]="'buyingPowerToolTip_3'+uniqueId"
                        [tooltipContent]="toolTipContent" openIconName="icon-Info-Circle" mode="modal">
                        <div class="tooltip-lightbox" *ngIf="mode === 'accountHoldings'">
                          <div>{{content.text.toolTipIntroContainer1}}</div>
                          <br>
                          <div>{{content.text.toolTipSubheading1}}</div>
                          <div>
                            <ul class='tooltipList'>
                              <li>{{content.list.toolTipList1.cashBalances}}</li>
                              <li>{{content.list.toolTipList1.marginableLoanValue}}</li>
                              <li>{{content.list.toolTipList1.pendingCashTransfersOut}}</li>
                              <li>{{content.list.toolTipList1.openOrdersDecreaseBuyingPower}}</li>
                              <li>{{content.list.toolTipList1.filledOrdersPriorSettlement}}</li>
                              <li>{{content.list.toolTipList1.buyingPowerCrossGuaranteed}}</li>
                            </ul>
                          </div>
                          <br>
                          <div>{{content.text.toolTipCloseContainer1}}</div>
                        </div>
                        <!-- <div class="tooltip-lightbox"
                        *ngIf="(mode == 'Trade' || mode == 'CashTransferFrom' || mode == 'CashTransferTo')"> -->
                        <div class="tooltip-lightbox" *ngIf="(mode == 'CashTransferFrom' || mode == 'CashTransferTo')">
                          <div>{{content.text.toolTipIntroContainer2}}</div>
                          <br>
                          <div>{{content.text.toolTipSubheading1}}</div>
                          <div>
                            <ul class='tooltipList'>
                              <li>{{content.list.toolTipList2.cashBalances}}</li>
                              <li>{{content.list.toolTipList2.pendingCashTransfersOut}}</li>
                              <li>{{content.list.toolTipList2.openOrdersDecreaseBuyingPower}}</li>
                              <li>{{content.list.toolTipList2.filledOrdersPriorSettlement}}</li>
                            </ul>
                          </div>
                          <br>
                          <div>{{content.text.toolTipCloseContainer2}}</div>
                          <div>{{content.text.toolTipCloseContainer3}}</div>
                        </div>
                        <div class="tooltip-lightbox" *ngIf="mode === 'TradeVerification'">
                          <div *ngIf="isBuyingPowerIncrease">
                            <div>{{content.text.toolTipIntroContainer2}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpIncreasePara2}}</div>
                            <br>
                            <div>{{content.text.toolTipCloseContainer4}}</div>
                          </div>
                          <div *ngIf="!isBuyingPowerIncrease">
                            <div>{{content.text.buyingPowerHelpDecreasePara1}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpDecreasePara2}}</div>
                            <br>
                            <div>{{content.text.buyingPowerHelpDecreasePara3}}</div>
                            <div>{{content.text.toolTipCloseContainer4}}</div>
                          </div>
                        </div>
                      </app-tooltip>
                    </div>
                  </dt>
                  <dd class="buyingpower-value">
                    <span>{{ returnValue(account.BuyingPowerUSD) }}</span>
                    <span class="spinner-container" *ngIf="loading || balanceLoading">
                      <app-general-spinner></app-general-spinner>
                    </span>
                  </dd>
                </div>
              </dl>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-container *ngIf="mode == 'BuyingPowerSubBalances'">
        <div id="acc_card_buyPowerTitle2" class="new-buyingpower-title" *ngIf="account.BuyingPowerValueType=='COMBINED'">
          <div tabindex="-1" [attr.id]="titleId" class="new-buyingpower-title-text">
            {{content.text.combinedBuyingPower}}
          </div>
          <div class="new-buyingpower-tooltip">
            <app-tooltip *ngIf="content" [id]="'buyingPowerToolTip_1a'+uniqueId" [tooltipContent]="toolTipContent"
              openIconName="icon-Info-Circle" mode="modal" [largeHitBox]="true">
              <div class="tooltip-lightbox">
                <div>{{content.list.newBuyingPowerTooltip.l1}}</div>
                <br />
                <div>{{content.list.newBuyingPowerTooltip.l2}}</div>
                <ul class="tooltipList">
                  <li>{{content.list.newBuyingPowerTooltip.l2_1}}</li>
                  <li>{{content.list.newBuyingPowerTooltip.l2_2}}</li>
                  <li>{{content.list.newBuyingPowerTooltip.l2_3}}</li>
                  <li>{{content.list.newBuyingPowerTooltip.l2_4}}</li>
                  <li>{{content.list.newBuyingPowerTooltip.l2_5}}</li>
                </ul>
                <br />
                <div>{{content.list.newBuyingPowerTooltip.l3}}</div>
                <br />
                <div>{{content.list.newBuyingPowerTooltip.l4}}</div>
                <br />
                <div>
                  <a id="acc_card_buyPower_tooltipTitle2" class="underline" href="javascript:void(0);"
                    (click)="openWindow(content.list.newBuyingPowerTooltip.l5_url)">
                    <span>{{content.list.newBuyingPowerTooltip.l5}}</span>
                    <span class='sr-only'>{{content.text.newWindowText}}</span>
                  </a>.
                </div>
                <br />
                <div>
                  <a class="underline" href="javascript:void(0);"
                  (click)="openWindow(content.list.newBuyingPowerTooltip.l6_url)">
                  <span>{{content.list.newBuyingPowerTooltip.l6}}</span>
                  <span class='sr-only'>{{content.text.newWindowText}}</span>
                </a>
                <span>{{content.list.newBuyingPowerTooltip.l6_2}}</span>
                </div>
              </div>
            </app-tooltip>
          </div>
        </div>
        <div id="acc_card_buyPowerTitle" class="new-buyingpower-title" *ngIf="account.BuyingPowerValueType!='COMBINED'">
          <div tabindex="-1" [attr.id]="titleId" class="new-buyingpower-title-text">
            {{content.text.estimatedBuyingPower}}
          </div>
          <div class="new-buyingpower-tooltip">
            <app-tooltip *ngIf="content" [id]="'buyingPowerToolTip_1a'+uniqueId" [tooltipContent]="toolTipContent"
              openIconName="icon-Info-Circle" mode="modal" [largeHitBox]="true">
              <div class="tooltip-lightbox">
                <div>{{content.list.newBuyingPowerTooltip.l1}}</div>
                <br />
                <div>{{content.list.newBuyingPowerTooltip.l2}}</div>
                <ul class="tooltipList">
                  <li>{{content.list.newBuyingPowerTooltip.l2_1}}</li>
                  <li>{{content.list.newBuyingPowerTooltip.l2_2}}</li>
                  <li>{{content.list.newBuyingPowerTooltip.l2_3}}</li>
                  <li>{{content.list.newBuyingPowerTooltip.l2_4}}</li>
                  <li>{{content.list.newBuyingPowerTooltip.l2_5}}</li>
                </ul>
                <br />
                <div>{{content.list.newBuyingPowerTooltip.l3}}</div>
                <br />
                <div>{{content.list.newBuyingPowerTooltip.l4}}</div>
                <br />
                <div>
                  <a id="acc_card_buyPower_tootlipLink" class="underline" href="javascript:void(0);"
                    (click)="openWindow(content.list.newBuyingPowerTooltip.l5_url)">
                    <span>{{content.list.newBuyingPowerTooltip.l5}}</span>
                    <span class='sr-only'>{{content.text.newWindowText}}</span>
                  </a>.
                </div>
              </div>
            </app-tooltip>
          </div>
        </div>
        <span class="spinner-container" *ngIf="loading || balanceLoading">
          <app-general-spinner></app-general-spinner>
        </span>
        <div *ngIf="!loading && !balanceLoading">
          <div id="acc_card_new_buyPowerAmt" class="new-buyingpower-amount" *ngIf="account.BuyingPowerValueType=='COMBINED'">
            <span *ngIf="account.ConsolidatedBuyingPower"
              [innerHtml]="account.ConsolidatedBuyingPower | currencyFormatter:'CAD':'format-currency-sr'"></span>
            <span *ngIf="!account.ConsolidatedBuyingPower">{{content.text.buyingPowerCannotDisplay}}</span>
          </div>
          <div id="acc_card_new_buyPowerAmt2" class="new-buyingpower-amount"
            *ngIf="account.BuyingPowerValueType=='SHOW_CAD'||account.BuyingPowerValueType=='SHOW_CAD_AND_USD'">
            <span *ngIf="account.BuyingPowerCAD"
              [innerHtml]="account.BuyingPowerCAD | currencyFormatter:'CAD':'format-currency-sr'"></span>
            <span *ngIf="!account.BuyingPowerCAD">{{content.text.buyingPowerCannotDisplay}}</span>
          </div>
        </div>
        <div id="acc_card_new_buyPowerAmt3" class="new-buyingpower-amount"
          *ngIf="!loading && !balanceLoading && account.BuyingPowerValueType=='SHOW_CAD_AND_USD'">
          <span *ngIf="account.BuyingPowerUSD"
            [innerHtml]="account.BuyingPowerUSD | currencyFormatter:'USD':'format-currency-sr'"></span>
          <span *ngIf="!account.BuyingPowerUSD">{{content.text.buyingPowerCannotDisplay}}</span>
        </div>
        <ng-container *ngIf="cashToggle">
          <div class="new-cash-title">
            {{content.text.cashBalances}}
          </div>
          <ng-container *ngIf="cashIsUnavailable(); else cashUnavailable">
            <div id="acc_card_cashIsUnavailable-{{j}}" [ngClass]="(balance.AccountType == 'MRL' && displayMargin) ? 'margin-show' : 'subaccount'"
              *ngFor="let balance of account.AccountBalances let j = index">
              <div class="new-subaccount-value"
                *ngIf="balance.Amount && (balance.AccountType != 'MRL' || displayMargin)">
                <span [innerHTML]="balance.Amount | currencyFormatter:balance.Currency:'format-currency-sr'"></span>
              </div>
            </div>
          </ng-container>
          <ng-template #cashUnavailable>
            <div class="cash-unavailable">{{content.text.cashUnavailable}}</div>
          </ng-template>
        </ng-container>
      </ng-container>
      <button id="acc_card_toggle_balanceBtn" *ngIf="mode == 'BuyingPowerSubBalances' && content && !loading && !balanceLoading" type="button"
        class="new-balance-button" (click)="toggleCash()" [attr.aria-expanded]="cashToggle?true:false">
        {{cashToggle ? content.text.hideBalances : content.text.showBalances}}
        <span class="new-balance-button-icon" [ngClass]="cashToggle ? 'icon-Chevron-Up' : 'icon-Chevron-Down'"></span>
      </button>
      <div id="acc_card_timestamp" class="buyingpower-timestamp" *ngIf="content && !loading && !balanceLoading && displayTimeStamp">
        {{content.text.asOf}}
        <span *ngIf="account.TimeStamp">
          {{account.TimeStamp}}
        </span>
      </div>
    </div>
    <div id="acc_card_animations" *ngIf="mode=='BuyingPowerSubBalances' && (loading || balanceLoading)">
      <div class="loading-skeleton-title skeleton-animation">
        <div></div>
      </div>
      <div class="loading-skeleton-value skeleton-animation">
        <div></div>
      </div>
      <div *ngIf="account.BuyingPowerValueType=='SHOW_CAD_AND_USD'" class="loading-skeleton-value skeleton-animation">
        <div></div>
      </div>
      <div class="loading-skeleton-asOf skeleton-animation"></div>
    </div>
    <div *ngIf="mode=='ExchangeCurrency' && !account.disabled">
      <table>
        <tbody [ngClass]="fontMode == 'small'? 'smallfont' : ''">
            <tr [ngClass]="(balance.AccountType == 'MRL' && displayMargin) ? 'margin-show' : 'subaccount'" *ngFor="let balance of account.AccountBalances let j = index">
              <td *ngIf="content" class="A12 color03">{{content.text.cashAvailable}} <span>(<span aria-hidden="true">{{content.list.currencyDenotationSymbol[balance.Currency]}}</span>
                <span class="sr-only" *ngIf="balance.Currency === 'USD'">{{content.text.USDollarAccessibleText}}</span>
                <span class="sr-only" *ngIf="balance.Currency === 'CAD'">{{content.text.canadianDollarAccessibleText}}</span>)
              </span>
              </td>
              <td class="subaccount-value A12 color02 width-fx" *ngIf="balance.Amount === 0 || balance.Amount && (balance.AccountType != 'MRL' || displayMargin)">
                <span [innerHTML]="currOrDash(balance, balance.Amount)"></span>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
