import { _User } from 'src/app/shared/models/user';

export class AppError {
    constructor(public errcode: ErrorConstant, trace?: any) { }
    get code(): ErrorConstant {
        return this.errcode;
    }
}

export enum ErrorConstant {
    TE100 = 'Force Upgrade',
    TE101 = 'Force Upgrade Service Error',
    SESSIONTIMEOUT = 'UIUSRSC0034',
    QUOTEEAAERROR = 'UIBUSBR0010',
    TRADEDISABLED = 'UIBUSSC0003',
    OPTIONTRADEDISABLED ='BUSOP0002',
    PASSWORDSUSPENDED = 'MBUSRSC0173'
}
