<div role="radiogroup" class="connectedRadioContainer"
  [ngClass]="(dynamicMode ? 'dynamic' :'static') + (!dynamicMode ? ' total-'+contentArray.length : '')"
  [attr.aria-labelledby]="labelledbyID" [attr.aria-label]="label" id="connected-radio-{{uniqueName}}" [inputErrorDirective]="error">
  <div class="connectedRadioSizer">
    <div class="selected-background" id="indicator-{{uniqueName}}">
    </div>
    <div *ngFor="let content of contentArray; let i = index" class="connectedRadio"
      [ngClass]="selectedValue == content.value || selectedValue === i ? 'selected' : ''">
      <input type="radio" id="connectedRadio-{{uniqueName}}-{{i}}" [(ngModel)]="selectedValue"
        value="{{content.value ? content.value : i}}" [attr.name]="'connectedRadio-'+uniqueName"
        (change)="handleChange($event, i)" [attr.disabled]="disabledArray && disabledArray[i] ? 'true' : null"
        (focus)="focusEvent($event, i)">
      <label for="connectedRadio-{{uniqueName}}-{{i}}"
      [ngStyle]="!dynamicMode && position == i && contentArray[position].color ? {'color': contentArray[position].color}: null">
      <img *ngIf="content.label == 'CA'" class='currencyExchangeFlag' alt='' aria-hidden="true"
      src="assets/images/default/flags/countryFlags/icon-flag-CDN.svg"/>
      <img *ngIf="content.label == 'US'" class='currencyExchangeFlag' alt='' aria-hidden="true"
      src="assets/images/default/flags/countryFlags/icon-flag-USD.svg"/>
        <span>{{content.label}}</span>
      </label>
    </div>
  </div>
</div>