import { BaseData } from 'src/app/shared/models/base-data';

export class ExchangeCurrencyLandingData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1,
      toCurrency: -1,
      fromCurrency: -1,
      exchangeAmount: undefined,
      receiveAmount: undefined,
      includeConsent: undefined
    };
  }
}

export interface ExchangeCurrencyLandingInputs {
  includeConsent: any;
  toCurrency: number;
  fromCurrency: number;
  accountIndex: number;
  exchangeAmount: string;
  receiveAmount: string;
}

export class ToFxInitReq {
  IncludeBalance: boolean;
}
export class ToFxRateReq {
  CurrencyFrom: string;
  CurrencyTo: string;
  AmountFrom: number;
  AmountTo: number;
}
export class ToCreateFxOrderReq {
  FxRateId: string;
  AccountNumber: any;
}
export class ToCancelFxOrderReq {
  OrderId: string;
}
export class ToGetAccountReq {
  AccountNumber: string;
}
export class ToGetFromToAccountReq {
  FromAccountNumber: string;
  ToAccountNumber: string;
}
