import { BaseData } from 'src/app/shared/models/base-data';

export class TouchIDLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            biometricState: 'off',
        };
    }
}

export interface TouchIDLandingInputs {
    biometricState: string;
}