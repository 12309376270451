import { Injectable } from '@angular/core';
import { SSOSetupData } from './sso-setup.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SSOSetupStore extends PageStore<SSOSetupData> {

    constructor() {
        super(new SSOSetupData());
    }

}
