import { Injectable, Output, EventEmitter } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { ErrorConstant } from '../models/app-error';
import { SpinnerService } from '../components/spinner/service/spinner.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");


@Injectable({
  providedIn: 'root',
})
export class AgreementsGuard  {
  @Output() errorCallback: EventEmitter<any> = new EventEmitter<any>();
  response: any;

  constructor(private router: Router, private httpService: HttpService,
    private spinnerService: SpinnerService) { }

  canActivate(): Promise<boolean | UrlTree> | boolean {
    const req: any = {};
    this.spinnerService.setFocusElement('');
    return new Promise((resolve) => {
      this.httpService.post(gatewayConfig.APIServices.getWatchlistNames.url, req).subscribe(res => {
        if (res) {
          resolve(true);
        }
      }, (error) => {
        if (ErrorConstant.QUOTEEAAERROR === error?.error?.Exception?.ErrorCode) {
          this.router.navigate(['/txn/userPreferences/exchangeAgreements/landing']);
          resolve(false);
        } else {
          resolve(false);
          throw error;
        }
      },
        () => {
          resolve(false);
        });
    });
  }
}
