import { FormSharedModule } from 'src/app/shared/form-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { DisclaimersComponent } from 'src/app/shared/components/disclaimers/disclaimers.component';
import { TooltipComponent } from 'src/app/shared/components/tooltip/tooltip.component';
import { GeneralSpinnerComponent } from 'src/app/shared/components/general-spinner/general-spinner.component';
import { TabControlComponent } from 'src/app/shared/components/tab-control/tab-control.component';
import { SortSelectorModalComponent } from 'src/app/shared/components/sort-selector-modal/sort-selector-modal.component';
import { PredictiveSearchComponent } from 'src/app/shared/components/predictiveSearch/predictive-search.component';
import { PipeSharedModule } from './pipe-shared.module';
import { DropdownListComponent } from './components/dropdown-list/dropdown-list.component';
import { ConnectedRadioComponent } from './components/connected-radio/connected-radio.component';
import { GlobalRefreshComponent } from './components/global-refresh/global-refresh.component';
import { PasswordTooltipComponent } from './components/password-tooltip/password-tooltip.component';
import { TableDisplayComponent } from './components/table-display/table-display.component';

@NgModule({
  declarations: [
    DisclaimersComponent,
    TooltipComponent,
    GeneralSpinnerComponent,
    TabControlComponent,
    SortSelectorModalComponent,
    PredictiveSearchComponent,
    DropdownListComponent,
    ConnectedRadioComponent,
    GlobalRefreshComponent,
    PasswordTooltipComponent,
    TableDisplayComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FormSharedModule,
    PipeSharedModule
  ],
  exports: [
    DisclaimersComponent,
    TooltipComponent,
    GeneralSpinnerComponent,
    TabControlComponent,
    SortSelectorModalComponent,
    PredictiveSearchComponent,
    DropdownListComponent,
    ConnectedRadioComponent,
    GlobalRefreshComponent,
    PasswordTooltipComponent,
    TableDisplayComponent
  ]
})
export class WidgetSharedModule { }
