import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
//import { PageService } from '../../../../../shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
//import { AppStore } from '../../../../../shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
//import { HttpService } from '../../../../../core/services/http.service'
import {SellGicConfirmStore} from '../data/sell-gic-confirm-store';



@Injectable({
  providedIn: 'root'
})
export class SellGicConfirmService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if(SellGicConfirmStore.inFlow.Action){
      let ser = input[0];
      ser.input = SellGicConfirmStore.inFlow;
      return input;
    }
    return [];
  }
  
}
