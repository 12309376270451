import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PageService } from '../../../../../../shared/services/page-service';
import { CancelMutualFundPlanConfirmStore } from "../data/cancel-mutual-fund-plan-confirm-store";


@Injectable({
    providedIn: 'root'
})
export class CancelMutualFundPlanConfirmService implements PageService {

    constructor() { }


    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        if (CancelMutualFundPlanConfirmStore.params) {
            const ser = input[0];
            const req = ser.input;
            if (CancelMutualFundPlanConfirmStore.params.TradingPassword) {
                req.TradingPassword = CancelMutualFundPlanConfirmStore.params.TradingPassword;
                req.SaveTradingPassword = CancelMutualFundPlanConfirmStore.params.SaveTradingPassword;
            }
            return input;
        }

        return [];
    }

}