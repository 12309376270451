import { Injectable } from '@angular/core';
import { ManageTrustedContactPersonData } from './manage-trusted-contact-person-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ManageTrustedContactPersonConfirmStore extends PageStore<ManageTrustedContactPersonData> {

    constructor() {
        super(new ManageTrustedContactPersonData());
    }

}

