import { Inject, Injectable, OnInit } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RoutePermissionService {

    toggleLocaleAvailable = false;
    togglePreSignoffAvailable = false;
    toggleBuildInfo = false;

    constructor(
    ) {
    }


    get enableToggleLocaleButton() {
        return this.toggleLocaleAvailable;
    }

    set enableToggleLocaleButton(isEnable: boolean) {
        this.toggleLocaleAvailable = isEnable;
    }

    get enablePreSignoff() {
        return this.togglePreSignoffAvailable;
    }

    set enablePreSignoff(isEnable: boolean) {
        this.togglePreSignoffAvailable = isEnable;
    }

    get enableBuildInfo() {
        return this.toggleBuildInfo;
    }

    set enableBuildInfo(isEnable: boolean) {
        this.toggleBuildInfo = isEnable;
    }

}
