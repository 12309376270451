import { BaseData } from 'src/app/shared/models/base-data';

export class SellGicLandingData extends BaseData {
  constructor() {
    super();
    this.input = {
      sellGicAmount: "",
      accountIndex: -1
    }  
  }
}

export interface SellGicLandingInputs {
  sellGicAmount: string;
  accountIndex: number;
}

export interface SettlementAccount {
  SettlementType: string; 
  Currency: string;
  AccountType?: string;
}

export interface SettlementAccountData {
  SettlementAccounts: SettlementAccount;
}

export interface Warning {
  Message: string;
  Code: string;
}

export interface GIC {  
  Term?: string,
  MaturityDate: string,
  InterestRateOrEffectiveYield: string,
  IssuerDescription: string,
  AccruedInterest: string,
  SettlementAmount: string,
  YieldToRedemption: string,
  SettlementDate: string,
  InterestRateForYear1?: string,
  InterestRateForYear2?: string,
  InterestRateForYear3?: string,
  InterestRateForYear4?: string,
  InterestRateForYear5?: string,
  InterestPaymentFrequency: string
}

export interface AccountBalance {
  AccountType: string;
  Currency: string;
  Amount: number;
}

export interface Account {  
  AccountNumber: string,
  AccountTypeDesc: string,
  AccountOwnerName?: any,
  Fullname: string,
  AccountFriendlyName?: string,
  AccountBalances: AccountBalance[],
  IncludeShort?: boolean,
  BuyingPowerValueType?: string
}