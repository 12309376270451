import { BaseData } from 'src/app/shared/models/base-data';

export class BuyStructNotesEntryData extends BaseData {
  constructor() {
    super();
    this.input = {
      accountIndex: -1
    };
  }
}

export interface BuyStructNotesEntryInputs {
    action: string;
    settlementIndex: number;
    accountIndex: number;
    quantity: string;
}

export interface AmountToolTip {
  openBtnLabel: string;
  cancel: string;
  content: string;
  btnHiddenText: string;
}
