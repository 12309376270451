<section id="accountSelectorDD{{mode}}{{id}}" class="A16 color02 accountSelector">
  <button *ngIf="(mode == 'quicktrade' || mode == 'quicktradeOptions')" id="ddButton{{mode}}{{id}}" type="button" class="selector-button"
    [inputErrorDirective]="error" aria-haspopup="listbox" (click)="setDropDownState(!open)"
    [ngClass]="{'cpic-button': lob ==='cpic', 'regularDropdown-selector-button': regularDropdown, 'soft': styling == 'quickTrade', 'open': open}"
    [attr.aria-describedby]="describedbyAttr">
    <i class="icon-Chevron-Down icon-pos color02" [ngClass]="{'open': open}"></i>
    <span class="choose-one-opt-1" *ngIf="chooseOne">{{pageContent.text.chooseAccount}}</span>
    <img *ngIf="!chooseOne && selectedAccountNumber && selectedAccountNumber.Currency" class='currencyExchangeFlag'
      alt='' aria-hidden="true"
      src="assets/images/default/flags/countryFlags/icon-flag-{{selectedAccountNumber.Currency}}.svg" />
    <span class="choose-one-opt-2" *ngIf="!chooseOne">{{accountDisplayName}}</span>
  </button>
  <div id="results{{mode}}{{id}}" class="results" *ngIf="open" [inputErrorDirective]="error"
    [ngClass]="{'cpic-button': lob ==='cpic', 'cpicResults': (lob ==='wg' || lob === 'cpic'), 'dropdown-full-width': dropdownFullWidth, 'soft': styling == 'quickTrade'}">
    <ul role="listbox" aria-label="accountSelector">
      <li class="group" id="ASDD{{account.Id}}{{mode}}{{id}}" *ngFor="let account of singleAccountList; let i= index;"
        (click)="accountSelected(account)" role="option" tabindex="0" (keyup)="onKeyUp($event, account)"
        (keydown)="onKeyDown($event)" (blur)="onBlur($event)" (mouseenter)="onMouseEnter($event)"
        [attr.disabled]="(mode == 'quicktrade' || mode == 'quicktradeOptions') && !account.IsSubAccount"
        [ngClass]="{'disabled': (mode == 'quicktrade' || mode == 'quicktradeOptions') && !account.IsSubAccount}">
        <div id="accountGroup_id" *ngIf="!account.IsSubAccount && lob !=='cpic' && (mode == 'quicktrade' || mode == 'quicktradeOptions')"
          [ngClass]="{'account': lob !== 'wg', 'accountWG': lob == 'wg'}" [class.border-top]="i !== 0">
          {{account.accountGroup}}
        </div>
        <div *ngIf=" account.IsSubAccount && (lob==='ie' || lob==='iis' || lob==='cfpi' ) && (mode == 'quicktrade' || mode == 'quicktradeOptions')"
         id="subAccount_id" class="subAccount color02">
          <span class="sr-only" id="sr-text" *ngIf="account.srText">{{account.srText}}</span>
          <img class='currencyExchangeFlag' alt='' aria-hidden="true" *ngIf="account.Currency"
            src="assets/images/default/flags/countryFlags/icon-flag-{{account.Currency}}.svg" />
          <span class="subAccountGrow">{{account.accountDetail}}</span>
          <span *ngIf="account.Id == selectedAccountNumber.Id" class="icon-Checkmark-Thick"
            [attr.aria-label]="pageContent.text.accountSelected"></span>
        </div>
      </li>
    </ul>
  </div>
</section>