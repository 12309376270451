import { Injectable } from '@angular/core';
import { TradeOptionsConfirmData } from './trade-options-confirm-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TradeOptionsConfirmStore extends PageStore<TradeOptionsConfirmData> {

    constructor() {
        super(new TradeOptionsConfirmData());
    }

}
