import { Injectable } from '@angular/core';
import { UpdatePasswordLandingData } from './update-password-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class UpdatePasswordLandingStore extends PageStore<UpdatePasswordLandingData> {

    constructor() {
        super(new UpdatePasswordLandingData());
    }

}
