import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { EDocumentsRequest, PdfStatementRequest } from '../data/edocuments-data';
import { EdocumentsStore } from '../data/edocuments-store';
import { CommonService } from 'src/app/shared/services/common.service';

declare var require: any;
const gatewayConfig = require('../../../../config/gateway-config.json');

@Injectable({
  providedIn: 'root'
})
export class EdocumentsService implements PageService {
  constructor(private appStore: AppStore, private httpService: HttpService, private commonService: CommonService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ret = [input[0]];
    ret[0] = this.prepareApp(ret[0]);
    return input;
  }

  prepareApp(input) {
    const ret = input.input;
    if (this.appStore.isApp()) {
      ret.WrapperApp = true;
    }
    else {
      ret.WrapperApp = false;
    }
    input.input = ret;
    return input;
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  eDocsStatements(request: EDocumentsRequest, serviceName: string) {
    return this.httpService.post(gatewayConfig.APIServices[serviceName].url, request);
  }

  eDocsPdfStatement(request: PdfStatementRequest) {
    return this.httpService.post(gatewayConfig.APIServices.downloadDocumentPdf.url, request);
  }

  getPDFBlob(url) {
    return this.httpService.getBlob(url);
  }

  updateMasterNotification(request) {
    const url = gatewayConfig.APIServices.eDocUpdateMasterNotification.url;
    return this.httpService.post(url, request, { params: { skiploading: 'true' } });
  }
}
