import { BaseData } from 'src/app/shared/models/base-data';

export class QuotesResearchTechnicalInsightsData extends BaseData {

  constructor() {
    super();
  }
}

export class QRNotifyUserActivityData {
  ModuleName: string;
}