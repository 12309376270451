import { BaseData } from 'src/app/shared/models/base-data';

export class EdocumentsData extends BaseData {
    constructor() {
        super();
        this.input = {
            selectedDocumentIndex: 0
        }
    }
}
export interface EdocumentsInputs {
    accountIndex: number;
    selectedDocumentIndex: number;
}

export enum rangeType {
    CUSTOM = 'CUSTOM',
    DAYS_45 = 'DAYS_45',
    Months_3 = 'Months_3',
    Months_1 = 'Months_1'
}

export interface EDocumentsRequest {
    AccountNumber?: string;
    SearchCriteria?: Search;
}

export interface Search {
    FilterBy?: Filter[];
    GroupBy?: Group[];
    OrderBy?: Group[];
}

export interface Group {
    Column : string;
    Ordering : string;
}

//the order in array defines the order for tab in component
export const DocType = ["eStatements",
    "tradeConfirmations",
    "quarterlyReports",
    "taxPackages"]

export interface DocumentTabular {
    name: string;
    text: string;
}

export interface EdocsResponse {
    Notifications?: NotificationInfo[];
    SearchResults?: SearchResult;
    AsOfDate?: string;
    DateRange?: DateInfo;
}

export interface SearchResult {
    DateRange?: DateInfo;
    Documents?: DocumentsInfo[];

}

export interface DateInfo {
    RangeType?: rangeType;
    FromDate?: string;
    ToDate?: string;
}

export interface DocumentsInfo {
    AccountNumber: string,
    New?: boolean;
    ReportDate?: Date;
    ReportToken?: string;
    ReportYear?: string;

}
export interface Filter {
    Column: string,
    Value: any
}

export interface NotificationInfo {
    EDocType: string;
    HasNewEDocs: boolean;
    PaperMailingAccounts?: string[];
    NoOfNewEDocs?: object;
}

export interface PdfStatementRequest {
    ReportToken: string;
}

export interface accountListResponse {
    AccountList?: AccountList[];
    SelectedAccount: selectedAccount;

}

export interface selectedAccount {
    selectedAccount: PrimaryAccount;
}

export interface PrimaryAccount {
    PrimaryAccount: string;
}
export interface AccountList {
    AccountNumber: string;
    AccountTypeDesc: string;
}

export interface Years {
    year: number;
    documents: DocumentsInfo[];
}

export enum ordering {
    Ascending = 'A',
    Descending = 'D'
}

export enum columnSorting {
REPORTYEAR = 'ReportYear',
REPORTDATE = 'ReportDate',
REPORTQUARTER = 'ReportQuarter'
}

export const globalCalendar = {
    minDateYearInput: -7,
    minDateMonthInput: -7,
    maxDateYearInput: 0,
    maxDateMonthInput: 0,
    minSetYear: -7,
    minSetMonth: 0,
    minSetDay: 1,
    maxSetYear: 0,
    maxSetMonth: 11,
    maxSetDay: 31
}

export enum EDocType {
    MTH = 'MTH',
    TAX = 'TAX',
    TCF = "TCF",
    QPR = "QPR"
}

