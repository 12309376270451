import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({
  name: 'removeDollar',
  pure: true
})
export class RemoveDollarPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, type: string): any {
    if (value !== null && value !== undefined) {
      if (type == 'html') {
        // return value;
        let val = value['changingThisBreaksApplicationSecurity']?.replace('$', '');
        return this.sanitizer.bypassSecurityTrustHtml(val);
      } else {
        return value.replace('$', '');
      }
    } else {
      return value;
    }
  }
}