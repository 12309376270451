import { Injectable } from '@angular/core';
import { TouchIDConfirmData } from './touchid-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TouchIDConfirmStore extends PageStore<TouchIDConfirmData> {

    constructor() {
        super(new TouchIDConfirmData());
    }

}
