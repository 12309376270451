import { Injectable } from '@angular/core';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { SellFixedIncomeStore } from '../data/sell-fixed-income-store';

declare var require: any;
const gatewayConfig = require('../../../../../config/gateway-config.json');

@Injectable({
  providedIn: 'root'
})
export class SellFixedIncomeService implements PageService {


  constructor(private httpService: HttpService) { }

  prepareInput(input: any): any {
    if (SellFixedIncomeStore.inFlow){
      let ser = input[0];
      let param = {
        AccountList: true,
        SelectedTab: "T",
        PaginationOption: SellFixedIncomeStore.inFlow.PaginationOption,
        Refresh: true,
        ActionType: "SEL",
        Initialised: SellFixedIncomeStore.inFlow.isCancelClicked? true: false
      };
      ser.input = param;
      return input;
    }
    else {
      return input;
    }
  }

  getAccountHolding(req){
    return this.httpService.post(gatewayConfig.APIServices.fixedIncomeHoldings.url, req);
  }

  getBondDetail(req){
    return this.httpService.post(gatewayConfig.APIServices.getInstrumentDetail.url, req);
  }
}
