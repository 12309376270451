import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SearchReq } from '../data/fixed-income-data';

declare var require: any;
const gatewayConfig = require('../../../../config/gateway-config.json');


@Injectable({
  providedIn: 'root'
})
export class FixedIncomeService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  getBondsQuickPicks(data : SearchReq) {

    const req: SearchReq = {};

    // default values
    req.Refresh = true;
    req.PaginationOption = {
      Offset: 0,
      Limit: 50
    }

    // leave SortOption to default
    // req.SortOption = data.SortOption;

    // inputs
    req.QuickPicksCategory = data.QuickPicksCategory;
    req.FaceValue = data.FaceValue;
    
    // use gateway config JSON
    return this.httpService.post(gatewayConfig.APIServices.searchBonds.url, req);
  }


}
