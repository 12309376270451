import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { QuickTradeOptionsConfirmData } from './quick-trade-options-confirm-data';

@Injectable({ providedIn: 'root' })
export class QuickTradeOptionsConfirmStore extends PageStore<QuickTradeOptionsConfirmData> {

    constructor() {
        super(new QuickTradeOptionsConfirmData());
    }

}
