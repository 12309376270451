<div class="page-step-indicator-flex-row">
<div id="page_step_indicator_id" class="page-step-indicator-label">
    {{label}}
<span *ngIf='isDynamicForm' class="sr-only">{{globalContent.text.dynamicForm}}</span>
  </div>
<ul *ngIf="isDesktop()" class="page-step-indicator-container" aria-hidden="true">
  <li id="page_step_indicator_container-{{i}}" class="page-step-indicator-item" [ngClass]="positionPicker(i)" *ngFor="let j of totalcollection; let i = index">
    <div *ngIf="i > 0" class="page-step-indicator-line"></div>
    <div class="page-step-indicator-dot">{{i+1}}</div>
  </li>
  </ul>
</div>