import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { MutualFundsVerifyStore } from '../data/mutual-funds-verify-store';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");


@Injectable({
  providedIn: 'root'
})
export class MutualFundsVerifyService implements PageService {


  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ser = input[0];
    ser.input = MutualFundsVerifyStore.params;
    return input;
  }

  getFundFacts() {
    return this.httpService.post(gatewayConfig.APIServices.getMutualFundFact.url,
      {}, { params: { skiploading: 'true' } }
    );
  }
}
