import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../../shared/models/page-store';
import { BuyGICConfirmData } from './buy-gic-confirm-data';

@Injectable({ providedIn: 'root' })
export class BuyGICConfirmStore extends PageStore<BuyGICConfirmData> {

    constructor() {
        super(new BuyGICConfirmData());
    }

}
