import { BaseData } from 'src/app/shared/models/base-data';

export class OnlineRegisterUsernameData extends BaseData {
    constructor() {
        super();
        this.input = { 
          userId: '',
          newUserId: ''
        };
    }
}

export interface UsernameVerifyInputs {
  userId: string;
  newUserId: string;
}