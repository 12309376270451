import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { IposAndNewIssuesData } from './ipos-and-newissues-data';

@Injectable({ providedIn: 'root' })
export class IposAndNewIssuesStore extends PageStore<IposAndNewIssuesData> {

    constructor() {
        super(new IposAndNewIssuesData());
    }

}