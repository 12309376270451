import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class OptionCentreGuard  {

    constructor(
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
        console.log('check option centre allowed', (window as any).showOptionCentre);
        return (window as any).showOptionCentre;
    }
}
