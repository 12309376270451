import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { OlbSSOIncomingReq } from '../data/sso-routing-handler.data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class SSORoutingHandlerService implements PageService {


  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    return input;
  }

  olbSSOIncomingReq(data) {
    const req: OlbSSOIncomingReq = new OlbSSOIncomingReq();
    req.Ssolocale = data.Ssolocale;
    req.CustomerType = data.CustomerType;
    req.SsoUnAvailable = data.SsoUnAvailable;
    return this.httpService.post(gatewayConfig.APIServices.SSOIncoming.url, req);
  }

  olbSSOSignoffReq() {
    return this.httpService.get(gatewayConfig.APIServices.SSOSignoff.url);
  }

}
