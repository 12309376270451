<ng-container *ngIf="!isNew(mode)">
  <section *ngIf="contents && (hasError || quote || loading)" class="quote-border quote-top-border">
    <section *ngIf="hasError && !loading">
      <section class="quote-title-section">
        <span [attr.id]="titleId" tabIndex="-1" class='quote-component-title color02'>{{contents.text.title}}</span>
      </section>
      <section [ngClass]="EAAErrorFlag ? 'quote-content-error-section EAA' : 'quote-content-error-section'">
        <div class="quote-error-container">
          <div *ngIf="!EAAErrorFlag" aria-hidden="true" class="quote-error-image icon-Error-Warning-Fill color10"></div>
          <div class="quote-error-text color11" [innerHtml]="errorDisplay"></div>
        </div>
      </section>
    </section>
    <section *ngIf="quote && !hasError && !loading">
      <section class="quote-title-section" [ngClass]="isWhite(mode) ? 'white-top' : ''">
        <span [attr.id]="titleId" tabIndex="-1" class='quote-component-title color02'>{{contents.text.title}}</span>
        <span class='quote-component-asof color03' [ngClass]="refreshEnabled ? 'refresh' : ''">{{contents.text.asOf}} {{valueOrDash(quote.LastTradeDate)}}
        </span>
        <button id="symbol_quote_refreshBtn" *ngIf="refreshEnabled" class="underlinelink" class="quote-refresh-button icon-Reload color10" type='button' attr.aria-label="{{contents.text.refreshQuotes}}"
          (click)="refresh()" [ngClass]="isWhite(mode) ? 'size-20' : ''">
        </button>
      </section>
      <section class="quote-content-section">
        <section *ngIf="isTopDisplay(mode)" class="quote-row-top">
          <section class="paddingBottom5">
            <img class="quote-flag" height="13" width="20" aria-hidden="true" alt="" [src]='getFlag(quote.Symbol.Market)' />
            <span class="quote-symbol-name color02">{{globalContent.getSymbolContent(quote.Symbol.SymbolName,quote.Symbol.Market)}}</span>
          </section>
          <section class="quote-company-name color03">{{quote.CompanyName?.toUpperCase()}}</section>
        </section>
        <section *ngIf="isDisplay(mode)" class="quote-row" [ngClass]="isTopDisplay(mode) ? 'quote-row-border' : 'no-top'">
          <section *ngIf="isExpiryTypeUndefined(mode)">
            <section [ngClass]="isDynamic(mode) ? 'quote-info-section-1' : ''">
              <section class="quote-market-price">{{valueOrDashContent(quote.LastPrice)}}</section>
              <section class="paddingBottom5 quote-price-change">
                <span [innerHtml]="valueOrDashContent(quote.PriceChange) | changeFormatter:quote.ChangeIndicator"></span>
                <span class="quote-price-change-divider color04" aria-hidden="true">|</span>
                <span [innerHtml]="valueOrDash(quote.PriceChangePercent) | changeFormatter:quote.ChangeIndicator"></span>
              </section>
              <section class="paddingBottom5 quote-volume color03" *ngIf="!isMutualFund()">{{contents.text.volume}}
                <span class="A12b color02"> {{addCommas(valueOrDashData(quote.Volume))}}</span>
              </section>
            </section>
            <section [ngClass]="isDynamic(mode) ? 'quote-info-section-2' : ''">
              <section class="col-50">
                <section class=" quote-bid-ask-label color03">{{contents.text.bid}}</section>
                <section *ngIf="!isMutualFund()" class="paddingBottom5 quote-bid-ask-value color02">
                  {{valueOrDashContent(quote.BidPrice)}}
                </section>
                <section *ngIf="!isMutualFund()">
                  <span class="quote-bid-ask-label color03">{{contents.text.shares}}
                    <span class="quote-bid-ask-value-2 color02"> {{addCommas(valueOrDashLots(quote.BidSize,quote.LotSize))}}</span>
                  </span>
                </section>
                <section *ngIf="isMutualFund()">
                  <span>{{dash}}</span>
                </section>
              </section>
              <section class="col-50 quote-borderleft-col paddingLeft10">
                <section class="quote-bid-ask-label color03">{{contents.text.ask}}</section>
                <section *ngIf="!isMutualFund()" class="paddingBottom5 quote-bid-ask-value color02">
                  {{valueOrDashContent(quote.AskPrice)}}
                </section>
                <section *ngIf="!isMutualFund()">
                  <span class="quote-bid-ask-label color03">{{contents.text.shares}}
                    <span class="quote-bid-ask-value-2 color02"> {{addCommas(valueOrDashLots(quote.AskSize, quote.LotSize))}}</span>
                  </span>
                </section>
                <section *ngIf="isMutualFund()">
                  <span>{{dash}}</span>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section *ngIf="isDisplayAll(mode)" class="quote-row quote-row-border">
          <section class="paddingBottom5">
            <span class="quote-high-low-label color03">{{contents.text.open}} </span>
            <span class="quote-high-low-value color02">{{valueOrDashContent(quote.Open)}}</span>
          </section>

          <section class="col-50">
            <section class="paddingBottom5">
              <div class="quote-high-low-label color03">{{contents.text.dayHigh}} </div>
              <div class="quote-high-low-value color02">{{valueOrDashContent(quote.High)}}</div>
            </section>
            <section>
              <div class="quote-high-low-label color03">{{contents.text.dayLow}} </div>
              <div class="quote-high-low-value color02">{{valueOrDashContent(quote.Low)}}</div>
            </section>
          </section>

          <section class="col-50 quote-borderleft-col">
            <section class="paddingBottom5">
              <div class="quote-high-low-label color03">{{contents.text.week52High}} </div>
              <div class="quote-high-low-value color02">{{valueOrDashContent(quote.Week52High)}}</div>
            </section>
            <section>
              <div class="quote-high-low-label color03">{{contents.text.week52Low}} </div>
              <div class="quote-high-low-value color02">{{valueOrDashContent(quote.Week52Low)}}</div>
            </section>
          </section>
        </section>
        <section *ngIf="mode == 'full-small'" class="quote-show-moreless-row">
          <button id="symbol_quote_toggleBtn" type="button" (click)="toggleSize()" class="quote-show-moreless-button" aria-label="Get details" [attr.aria-expanded]="isExpanded?true:false">
            <span *ngIf="isExpanded" class="icon-Chevron-Down twist"></span>
            <span *ngIf="!isExpanded" class="icon-Chevron-Down"></span>
          </button>
        </section>
      </section>
    </section>

    <section *ngIf="loading">
      <section class="quote-title-section">
        <span [attr.id]="titleId" tabIndex="-1" class='quote-component-title color02'>{{contents.text.title}}</span>
      </section>
      <div class="loading-area">
        <div class="loading-spinner">
          <app-general-spinner></app-general-spinner>
        </div>
      </div>
    </section>
  </section>
</ng-container>
<ng-container *ngIf="isNew(mode)">
  <div *ngIf="contents && (hasError || quote)" class="new-quote-border" [ngClass]="fromQuickTrade ? 'quick-trade-style' : ''">
    <div *ngIf="hasError && !loading">
      <div [attr.id]="titleId" tabindex="-1" *ngIf="EAAErrorFlag" class="new-EAA-error-text" [ngClass]="fromQuickTrade ? 'quick-trade-text' : ''" [innerHtml]="errorDisplay" (click)="hideTrdaeDrawer($event)"></div>
      <div *ngIf="!EAAErrorFlag">
        <div aria-hidden="true" class="new-error-icon icon-Error-Warning-Fill color10"></div>
        <div [attr.id]="titleId" tabindex="-1" class="new-error-text" [innerHtml]="errorDisplay"></div>
      </div>
    </div>
    <div *ngIf="quote && !loading && !hasError">
      <div [attr.id]="titleId" tabindex="-1" class="new-symbol-title">
        <img class="new-symbol-flag" height="13" width="20" aria-hidden="true" alt="" [src]='getFlag(quote.Symbol.Market)' />
        <span class="new-symbol-name" [ngClass]="quote.Symbol.SymbolLongName.length > 48 ? 'new-symbol-small-name' : ''">{{quote.Symbol.SymbolLongName}}</span>
      </div>
      <div class="new-symbol-price-change">
        <div class="new-symbol-price">{{valueOrDashContent(quote.LastPrice)}}</div>
        <div class="new-symbol-change" [ngClass]="{ backgroundUp : quote.ChangeIndicator == 'U', backgroundDown : quote.ChangeIndicator == 'D' }">
          <span [innerHtml]="(valueOrDashContent(quote.PriceChange) + ' (' + valueOrDash(quote.PriceChangePercent) + ')') | changeFormatter:quote.ChangeIndicator:'wedge'"></span>
        </div>
      </div>
      <div class="new-symbol-data-row">
        <div class="new-symbol-data-half">
          <div class="new-symbol-data-title">{{contents.text.bidN}}&nbsp;/&nbsp;{{contents.text.sharesN}}</div>
          <div class="new-symbol-data-value">{{valueOrDashContent(quote.BidPrice)}}&nbsp;/&nbsp;{{addCommas(valueOrDashLots(quote.BidSize,quote.LotSize))}}</div>
        </div>
        <div class="new-symbol-data-half">
          <div class="new-symbol-data-title">{{contents.text.askN}}&nbsp;/&nbsp;{{contents.text.sharesN}}</div>
          <div class="new-symbol-data-value">{{valueOrDashContent(quote.AskPrice)}}&nbsp;/&nbsp;{{addCommas(valueOrDashLots(quote.AskSize,quote.LotSize))}}</div>
        </div>
      </div>
      <div class="new-symbol-data-row">
        <div class="new-symbol-data-half">
          <div class="new-symbol-data-title">{{contents.text.volumeN}}</div>
          <div class="new-symbol-data-value">{{addCommas(valueOrDashData(quote.Volume))}}</div>
        </div>
        <div class="new-symbol-data-half">
          <div class="new-symbol-data-title">{{contents.text.openN}}</div>
          <div class="new-symbol-data-value">{{valueOrDashContent(quote.Open)}}</div>
        </div>
      </div>
      <ng-container *ngIf="isNewDisplayBottom(mode)">
        <div class="new-symbol-data-row">
          <div class="new-symbol-data-title">{{contents.text.dayRange}}</div>
          <div class="new-symbol-data-value">{{valueOrDashContent(quote.Low)}}&nbsp;-&nbsp;{{valueOrDashContent(quote.High)}}</div>
        </div>
        <div class="new-symbol-data-row">
          <div class="new-symbol-data-title">{{contents.text.week52Range}}</div>
          <div class="new-symbol-data-value">{{valueOrDashContent(quote.Week52Low)}}&nbsp;-&nbsp;{{valueOrDashContent(quote.Week52High)}}</div>
        </div>
      </ng-container>
      <button *ngIf="isNewExpandable(mode)" id="symbol_quote_expandBtn" class="new-expand-button" type="button" (click)="toggleSize()" [attr.aria-expanded]="isExpanded?true:false">
        {{isExpanded ? contents.text.hideStats : contents.text.showStats}}
        <span class="new-expand-button-icon" [ngClass]="isExpanded ? 'icon-Chevron-Up' : 'icon-Chevron-Down'"></span>
      </button>
      <div class="new-as-of-line">
        <button *ngIf="refreshEnabled" [attr.id]="'new-refresh-button-' + titleId" class="new-quote-refresh-button icon-Reload2"
          type='button' attr.aria-label="{{contents.text.refreshQuotes}}" (click)="refresh()"></button>
        <span class='new-as-of-text' [ngClass]="refreshEnabled ? 'buttoned' : ''">{{contents.text.asOf}} {{valueOrDash(quote.LastTradeDate)}}</span>
      </div>
    </div>
    <div id="symbol_quote_animation" *ngIf="loading">
      <div class="new-skeleton-name skeleton-animation"></div>
      <div class="new-skeleton-price skeleton-animation"></div>
      <div class="new-skeleton-row">
        <div class="new-skeleton-value-title halved skeleton-animation">
          <div></div>
          <div></div>
        </div>
        <div class="new-skeleton-value halved skeleton-animation">
          <div></div>
        </div>
      </div>
      <div class="new-skeleton-row">
        <div class="new-skeleton-value-title halved skeleton-animation">
          <div></div>
          <div></div>
        </div>
        <div class="new-skeleton-value halved skeleton-animation">
          <div></div>
        </div>
      </div>
      <div class="new-skeleton-row" *ngIf="isNewDisplayBottom(mode)">
        <div class="new-skeleton-value-title skeleton-animation">
          <div></div>
        </div>
        <div class="new-skeleton-value skeleton-animation"></div>
      </div>
      <div class="new-skeleton-row" *ngIf="isNewDisplayBottom(mode)">
        <div class="new-skeleton-value-title skeleton-animation">
          <div></div>
        </div>
        <div class="new-skeleton-value skeleton-animation"></div>
      </div>
      <div class="new-skeleton-as-of skeleton-animation" *ngIf="isNewDisplayBottom(mode)"></div>
    </div>
  </div>
</ng-container>