import { BaseData } from '../../../../../../shared/models/base-data';

export class CancelMutualFundPlanConfirmData extends BaseData {
    constructor() {
        super();

    }

}

export class CancelMutualFundPlanConfirmResp {
    OrderID?: string;
    OrderReceivedOn?: string;
    Account?: Account;
    NextOrderDate?: string;
    PayFromAccountNumber?: string;
    ValidInfo?: ValidInfo
}

export interface Account {
    AccountNumber?: string;
    AccountType?: string,
    AccountFriendlyName?: string;
    RegistrationInfo?: RegistrationInfo;
}

export interface RegistrationInfo {
    RegType?: string;
}

export interface ValidInfo {
    PlanFrequency?: number;
    StartDate?: string;
    Action?: string;
    Amount?: number;
    EndDate?: string;
    SuspendDate?: string;
    UnSuspendDate?: string;
    PayFromAccountNumber?: string;
}

export const No_API_Data = {
    dash: '-',
    registered: 'REG',
    type: 'brkrg',
    buy: 'Buy',
    achat: 'Achat'
}


