import { BaseData } from 'src/app/shared/models/base-data';

export class SellStructNotesVerifyData extends BaseData {
  constructor() {
    super();
    this.input = {
      tradingPassword: '',
      savePassword: false,
      fundFactsAgreementCheckIndicator: false
    };
  }
}

export interface SellStructNotesVerifyInputs {
  accountIndex: number;
  tradingPassword: string;
  savePassword: boolean;
  fundFactsAgreementCheckIndicator: boolean;
}
