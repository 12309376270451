import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class AccountSelectorModalSevice {
  constructor(private httpService: HttpService) { }

  getBuyingPowerList(data) {
    return this.httpService.post(gatewayConfig.APIServices.buyingPowerRetrieveList.url,
      data, { params: { skiploading: 'true' } }
    );
  }
}
