import { BaseData } from 'src/app/shared/models/base-data';

export class RRSPWithdrawalLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountNumber: null,
            email: null,
        };
    }
}

export interface RRSPWithdrawalLandingInputs {
    residentToggle: string;
    province: string;
    fromAccountNumber: string;
    toAccountNumber: string;
    rrspType: string;
    grossAmount: number;
    netAmount: number;
    transitNumber: string;
    accountNumber: string;
    email: string;
}

export interface RRSPSubmitRequest {
    WebFormName: string;
    Parameters: {
        param1: string,
        param2: string,
        param3?: string,
        param4: string,
        param5?: string,
        param6?: string,
        param7?: string,
        param8: string,
        param9: string
    };
}

export enum ParamsMapping {
    Param1 = '',
    Param2 = 'rrspType',
    Param3 = 'grossAmount',
    Param4 = 'email',
    Param5 = 'email',
    Param6 = 'email',
    Param7 = 'email',
    Param8 = 'email',
    Param9 = 'province'
}
