// replaced dyamically during build by jenkins

export interface BuildInfo {
    id: string;
    num: string;
    branch: string;
    tag: string;
}

export const buildInfo: BuildInfo = {
    id: '182537',
    num: '20250408.1',
    branch: 'ticker_tape_event_calendar',
    tag: '1.0.368-snapshot-sit-both'
};

