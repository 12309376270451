import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { AppStore } from 'src/app/shared/models/app-store';
import { routeConfig } from 'src/app/config/router-mapping';
import { Location } from '@angular/common';
import { typeOf } from 'src/app/config/type-mapper';
import { isDefined } from 'src/app/shared/services/utils.service';
import { CommonService } from 'src/app/shared/services/common.service';
// import { State, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class StateTransitionGuard  {

  constructor(
    private appstore: AppStore,
    private router: Router,
    private location: Location,
    private _commonService: CommonService
    // private storeNg: Store<any>,
    // private stateNg: State<any>,
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (isDefined(this.appstore.state.state.refreshURL)) {
      this.appstore.state.state.refreshURL = undefined;
    }

    let allowedFrom = true;
    let allowedTo = true;
    // let ngState = this.stateNg;
    // let ngStore = this.storeNg;
    // if (this.store.state.state.backOrForwardNav || this.store.state.state.refresh) {
    // Only do checks if we are navigating via the browser or refreshing
    let stateurl = state.url;
    let currentUrl = this.router.url;
    if (isDefined(this.appstore.midTransURL)) {
      currentUrl = this.appstore.midTransURL;
      this.appstore.midTransURL = undefined;
    }
    // TODO: We need better solution to look for store without Query string for now removing params - For SSO Handler
    if (isDefined(stateurl) && stateurl !== null) {
      stateurl = stateurl.split('?')[0];
    }
    // TODO: We need better solution to look for store without Query string for now removing params - For SSO Handler
    if (isDefined(currentUrl) && currentUrl !== null) {
      currentUrl = currentUrl.split('?')[0];
    }
    if (currentUrl && !this.appstore.state.state.refresh) {
      const fromConfig = routeConfig(currentUrl);
      const toConfig = routeConfig(stateurl);
      console.log('Navigativng from [', currentUrl, '] -> [', stateurl, ']');
      if (toConfig.policy && fromConfig.policy) {
        allowedTo = false;
        allowedFrom = false;
      } else if (fromConfig.policy) {
        allowedTo = false;
      } else if (toConfig.policy) {
        allowedFrom = false;
      }
      if (!allowedFrom && toConfig.policy.navFrom.url[0] !== '*') {
        for (const value of toConfig.policy.navFrom.url) {
          if (currentUrl.startsWith(value)) {
            allowedFrom = true;
          }
        }
        console.log('Navigation Restricted: ' + allowedFrom);
        // window.alert('Navigation Restricted: ' + allowedFrom);
      } else {
        allowedFrom = true;
      }
      if (!allowedTo && fromConfig.policy.navTo.url[0] !== '*') {
        for (const value of fromConfig.policy.navTo.url) {
          if (stateurl.startsWith(value)) {
            allowedTo = true;
          }
        }
        console.log('Navigation Restricted: ' + allowedTo);
        // window.alert('Navigation Restricted: ' + allowedTo);
      } else {
        allowedTo = true;
      }

      if (!typeOf(routeConfig(stateurl).store).paramsAvailable()) {
        console.log('Necessary Parameters Not Available For [' + stateurl + ']');
        allowedTo = false;
      }

      if (!(allowedFrom && allowedTo)) {
        this.appstore.isWrappedBack = false;
        if (isDefined(toConfig.policy.fallbackRoute)) {
          setTimeout(() => {
            this.router.navigate([toConfig.policy.fallbackRoute]);
          }, 100);
        } else {
          this.appstore.midTransURL = state.url;
          let temp = this.appstore.midTransURL;
          setTimeout(() => {
            if (this.appstore.midTransURL == temp) {
              this.appstore.midTransURL = undefined;
            }
          }, 100);
          this.location.back();
        }
      }
    } else {
      if (!this.appstore.refresh) {
        const toConfig = routeConfig(stateurl);
        if (toConfig.policy && toConfig.policy.navFrom.url[0] !== '*') {
          allowedFrom = false;
        }
      } else {
        const url = window.sessionStorage.getItem('refreshURL');
        this.appstore.state.state.refreshURL = url;
        window.sessionStorage.removeItem('refreshURL');

        if (!url || !url.startsWith(stateurl)) {
          allowedFrom = false;
        }
        /* To handle special case for QnR in IE11, Edge legacy browser - 
        * when user clicks back button from QnR, browser does a refresh before before navigating to previous page in history
        * So you originally had a back event which turned into refresh event with navigation 
        * Also, Angular router looses all routing history
        * Applying a patch for now. A better solution in future that addresses the root cause is much appreciated!!
        */
        if (url.startsWith('/txn/quotesResearch') && currentUrl === '/') {
          console.log("************* StateTransitionGuard QnR special case");
          allowedFrom = true;
        }
      }

      if (!typeOf(routeConfig(stateurl).store).paramsAvailable()) {
        console.log('Necessary Parameters Not Available For [' + stateurl + ']');
        allowedTo = false;
      }

      if (!(allowedFrom && allowedTo)) {
        this.appstore.isWrappedBack = true;
        this._commonService.clearPostSignOnSession();
        this.router.navigate(['/home']);
      }
    }
    // }
    console.log('Allowed To and From: ' + allowedTo + ' & ' + allowedFrom);

    const splitCurr = currentUrl.split('/');
    const splitState = stateurl.split('/');

    if (splitCurr[1] === 'txn' && splitState[1] === 'txn') {
      if (splitCurr.length === splitState.length) {
        this.appstore.crossFlow = false;
        if (splitCurr.length === 3) {
          this.appstore.crossFlow = true;
          this.clearStoreSavedInfo(stateurl);
        }
        for (let i = 2; i < splitCurr.length - 1; i++) {
          if (splitCurr[i] !== splitState[i]) {
            this.appstore.crossFlow = true;
            this.clearStoreSavedInfo(stateurl);
          }
        }
      } else if (splitCurr.length > splitState.length) {
        this.appstore.crossFlow = false;
        for (let i = 2; i < splitState.length; i++) {
          if (splitCurr[i] !== splitState[i]) {
            this.appstore.crossFlow = true;
            this.clearStoreSavedInfo(stateurl);
          }
        }
      } else {
        this.appstore.crossFlow = false;
        for (let i = 2; i < splitCurr.length; i++) {
          if (splitCurr[i] !== splitState[i]) {
            this.appstore.crossFlow = true;
            this.clearStoreSavedInfo(stateurl);
          }
        }
      }
    } else {
      this.appstore.crossFlow = false;
    }

    // window.alert('Allowed To and From: ' + allowedTo + ' & ' + allowedFrom);
    this.appstore.isWrappedBack = false;
    return allowedFrom && allowedTo;
  }

  clearStoreSavedInfo(stateurl) {
    typeOf(routeConfig(stateurl).store).savedInfo = undefined;
  }

}
