import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { AppStore } from '../../models/app-store';
import { Lob } from 'src/app/shared/models/lob.enum';

@Component({
    selector: 'app-account-selector-dropdown',
    templateUrl: './account-selector-dropdown.component.html',
    styleUrls: ['./account-selector-dropdown.component.scss']
})
export class AccountSelectorDropdownComponent implements OnInit, OnChanges {

    open = false;
    accountList: any[];
    accountDisplayName: string;
    subAccountDisplayName: string;
    lob: string;
    selectedAccountNumber: any;
    displayAccountNumber: string;
    account: any;
    subAccount: any;
    pageContent: any;
    singleAccountList: any[];
    highlightedElement: any;
    accountDisplayCurrency: any;
    firstFocusableEl: any;
    lastFocusableEl: any;
    dropdownWidth: number;

    @Input() paramSelectedAccountNumber: any;
    @Input() paramPageContent: any;
    @Input() paramAccountList: any;
    @Input() hideCombinedText: boolean = false;
    @Input() regularDropdown = false;
    @Input() hideSubAccounts = false;
    @Input() onlySubAccounts = false;
    @Input() dropdownFullWidth = false;
    @Input() chooseOne = false;
    @Input() style = 'normal';
    @Input() error = undefined;
    @Input() errorId = '';
    @Input() idVal = undefined;
    @Input() fixedResults = false;

    @Output() selectedAccount: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setDropDownState(false);
    }

    constructor(
        public globalcontent: GlobalContentStore,
        private appStore: AppStore
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.paramAccountList && !changes.paramAccountList.firstChange) {
            this.accountList = this.paramAccountList;
            this.singleAccountList = [];
            this.createSingleList();
            if(!this.chooseOne) {
                if (this.paramSelectedAccountNumber && this.paramSelectedAccountNumber.AccountNumber && this.paramSelectedAccountNumber.AccountNumber !== '') {
                    this.selectedAccountNumber = this.fetchAccountFromSingleList(this.paramSelectedAccountNumber);
                } else {
                    this.selectedAccountNumber = this.singleAccountList[0];
                }
                this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
            } else {
                this.accountDisplayName = this.globalcontent.text.chooseOne;
            }
            document.addEventListener('visibilitychange', (e: Event) => { this.setDropDownState(false); });
        }
        if (changes.paramSelectedAccountNumber && !changes.paramSelectedAccountNumber.firstChange) {
            if (this.paramSelectedAccountNumber && this.paramSelectedAccountNumber.AccountNumber && this.paramSelectedAccountNumber.AccountNumber !== '') {
                this.selectedAccountNumber = this.fetchAccountFromSingleList(this.paramSelectedAccountNumber);
            } else {
                this.selectedAccountNumber = this.singleAccountList[0];
            }
            this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
        }
    }

    ngOnInit() {
        this.lob = this.appStore.lob;
        this.pageContent = this.paramPageContent;
        this.accountList = this.paramAccountList;
        this.singleAccountList = [];
        this.createSingleList();
        if(!this.chooseOne) {
            if (this.paramSelectedAccountNumber && this.paramSelectedAccountNumber.AccountNumber && this.paramSelectedAccountNumber.AccountNumber !== '') {
                this.selectedAccountNumber = this.fetchAccountFromSingleList(this.paramSelectedAccountNumber);
            } else {
                this.selectedAccountNumber = this.singleAccountList[0];
            }
            this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
        } else {
            this.accountDisplayName = this.globalcontent.text.chooseOne;
        }
        document.addEventListener('visibilitychange', (e: Event) => { this.setDropDownState(false); });
    }
    setFocus(targetElementId) {
        if (targetElementId) {
            const targetElement = document.getElementById(targetElementId);
            window.requestAnimationFrame(() => {
                if (targetElement) {
                    targetElement.focus();
                }
            });
        }
    }

    setDropDownState(state) {
        if (state) {
            if (this.selectedAccountNumber) {
                setTimeout(() => {
                    this.setFocus('ASDD' + this.selectedAccountNumber.Id);
                    this.highlightedElement = document.getElementById('ASDD' + this.selectedAccountNumber.Id);
                }, 200);
            } else {
                setTimeout(() => {
                    this.setFocus('ASDD' + this.singleAccountList[0].Id);
                    this.highlightedElement = document.getElementById('ASDD' + this.singleAccountList[0].Id);
                }, 200);
            }
        } else {
            if (this.open) {
                this.setFocus('ddButton');
            }
        }
        let el = document.getElementById("accountSelectorDD");
        if (el) {
            this.dropdownWidth = el.clientWidth;
        }
        this.open = state;
    }
    onMouseEnter(e) {
        this.setFocus(e.currentTarget.id);
    }
    handleBlur(e) {
        if (e.relatedTarget) {
            return !e.relatedTarget || (e.relatedTarget && e.relatedTarget.id.indexOf('ASDD') !== 0);
        } else {
            return !document.activeElement || (document.activeElement && document.activeElement.id.indexOf('ASDD') !== 0);
        }
    }
    onBlur(e) {
        if (this.handleBlur(e)) {
            this.setDropDownState(false);
            e.preventDefault();
        }
    }
    onKeyDown(e, pAccount) {
        if (e.keyCode === 9) {
            this.setDropDownState(false);
            e.preventDefault();
        }
    }
    onKeyUp(e, pAccount) {
        if (e.keyCode === 38) {
            if (this.highlightedElement !== this.firstFocusableEl && this.highlightedElement.previousElementSibling) {
                this.highlightedElement.previousElementSibling.focus();
                this.highlightedElement = this.highlightedElement.previousElementSibling;
            } else {
                this.highlightedElement.focus();

            }
        } else if (e.keyCode === 40) {
            if (this.highlightedElement !== this.lastFocusableEl && this.highlightedElement.nextElementSibling) {
                this.highlightedElement = this.highlightedElement.nextElementSibling;
                this.highlightedElement.focus();
            } else {
                this.highlightedElement.focus();
            }
        } else if (e.keyCode === 13) {
            this.selectedAccountNumber = this.fetchAccountFromSingleListByID(Number(this.highlightedElement.id.split('ASDD')[1]));
            this.selectedAccount.emit(this.selectedAccountNumber);
            this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
            this.setDropDownState(false);
        } else if (e.keyCode === 27) {
            this.setDropDownState(false);
            e.preventDefault();
        }
    }
    accountSelected(pAccount) {
        this.setDropDownState(false);
        this.selectedAccountNumber = pAccount;
        this.generateDisplayName(this.selectedAccountNumber.Currency, this.selectedAccountNumber);
        this.selectedAccount.emit(this.selectedAccountNumber);
    }
    createSingleList() {
        let id = 0;
        this.accountList.forEach(val => {
            if (!this.onlySubAccounts) {
                this.singleAccountList.push({
                    Id: id,
                    AccountNumber: val.AccountNumber ? val.AccountNumber : '',
                    AccountTypeDesc: val.AccountTypeDesc ? val.AccountTypeDesc : '',
                    AccountFriendlyName: val.AccountFriendlyName ? val.AccountFriendlyName : '',
                    AccountName: val.AccountName ? val.AccountName : '',
                    HypothecationInd: val.HypothecationInd ? ' (' + this.pageContent.text.hypo + ') ' : ' ',
                    IsSubAccount: false,
                    spousal: val.Spousal ? val.Spousal : '' ,
                    registered: val.Registered ? val.Registered : ''
                });
                id++;
            }
            if (val.Subaccounts && !this.hideSubAccounts) {
                val.Subaccounts.forEach(obj => {
                    if (Lob.WG === this.lob) {
                        this.singleAccountList.push({
                            Id: id,
                            AccountFriendlyName: val.AccountFriendlyName ? val.AccountFriendlyName : this.getAccountTypeDesc(val),
                            AccountNumber: val.AccountNumber ? val.AccountNumber : '',
                            AccountType: obj.AccountType ? obj.AccountType : '',
                            Currency: obj.Currency ? obj.Currency : '',
                            SubAccountNumber: obj.SubAccountNumber ? obj.SubAccountNumber : '',
                            SubAccountName: obj.SubAccountName ? obj.SubAccountName : '',
                            IsSubAccount: true
                        });
                    } else if (Lob.CPIC === this.lob) {
                        this.singleAccountList.push({
                            Id: id,
                            AccountFriendlyName: val.AccountFriendlyName ? val.AccountFriendlyName : '',
                            AccountNumber: val.AccountNumber ? val.AccountNumber : '',
                            Currency: obj.Currency ? obj.Currency : '',
                            SubAccountNumber: obj.SubAccountNumber ? obj.SubAccountNumber : '',
                            SubAccountFriendlyName: obj.AccountFriendlyName ? obj.AccountFriendlyName : '',
                            IsSubAccount: true
                        });
                    } else {
                        this.singleAccountList.push({
                            Id: id,
                            AccountNumber: val.AccountNumber ? val.AccountNumber : '',
                            AccountType: obj.AccountType ? obj.AccountType : '',
                            Currency: obj.Currency ? obj.Currency : '',
                            IsSubAccount: true,
                            spousal: val.Spousal ? val.Spousal : '',
                            registered: val.Registered ? val.Registered : ''
                        });
                    }
                    id++;
                });
            }
        });
    }
    fetchAccountFromSingleListByID(id) {
        let account;
        this.singleAccountList.forEach(val => {
            if (!account && val.Id === id) {
                account = val;
            }
        });
        return account;
    }
    fetchAccountFromSingleList(pAccount) {
        let account;
        this.singleAccountList.forEach(val => {
            if (Lob.CPIC !== this.lob) {
                if (!account) {
                    if (pAccount.Currency) {
                        if (Lob.WG === this.lob) {
                            if (pAccount.AccountNumber === val.AccountNumber && pAccount.Currency === val.Currency && pAccount.AccountType === val.AccountType && pAccount.SubAccountNumber === val.SubAccountNumber) {
                                account = val;
                            }
                        } else {
                            if (pAccount.AccountNumber === val.AccountNumber && pAccount.Currency === val.Currency && pAccount.AccountType === val.AccountType) {
                                account = val;
                            }
                        }
                    } else {
                        if (pAccount.AccountNumber === val.AccountNumber) {
                            account = val;
                        }
                    }
                }
            } else {
                if (!account) {
                    if (pAccount.Currency) {
                        if (pAccount.AccountNumber === val.AccountNumber && pAccount.Currency === val.Currency && pAccount.SubAccountNumber === val.SubAccountNumber) {
                            account = val;
                        }
                    } else {
                        if (pAccount.AccountNumber === val.AccountNumber) {
                            account = val;
                        }
                    }
                }
            }
        });
        return account;
    }
    generateDisplayName(isSubAccount, account) {
        if (Lob.IE === this.lob || Lob.IIS === this.lob || Lob.CFPI === this.lob) {
            if (isSubAccount) {
                this.accountDisplayName = this.getAccountType(account) + ' (' + account.AccountNumber + ') - ';
                this.accountDisplayCurrency = account.Currency;
            } else if (!this.hideCombinedText) {
                this.accountDisplayName = ((account.AccountFriendlyName) ? account.AccountFriendlyName : this.getAccountTypeDesc(account)) + account.HypothecationInd + '(' + account.AccountNumber + ') - ' + this.pageContent.text.combined;
            } else {
                this.accountDisplayName = ((account.AccountFriendlyName) ? account.AccountFriendlyName : this.getAccountTypeDesc(account)) + account.HypothecationInd + '(' + account.AccountNumber + ')';
            }
        } else if (Lob.WG === this.lob) {
            let accountName;
            if (account.AccountFriendlyName) {
                accountName = account.AccountFriendlyName;
            } else if (account.AccountType) {
                accountName = this.getAccountType(account);
            } else {
                accountName = this.getAccountTypeDesc(account);
            }
            if (!this.hideCombinedText) {
                this.accountDisplayName = accountName + ' (' + account.AccountNumber + ')' + (!account.SubAccountNumber ? ' - ' + this.pageContent.text.combined : '');
            }
            else {
                this.accountDisplayName = accountName + ' (' + account.AccountNumber + ')';
            }
            this.subAccountDisplayName = '';
            if (isSubAccount) {
                this.subAccountDisplayName = '(' + account.SubAccountNumber + ') ' + this.getAccountType(account) + ' - ';
                this.accountDisplayCurrency = account.Currency;
            }
        } else {
            this.accountDisplayName = account.AccountFriendlyName; // + ' - ' + account.AccountNumber;
            this.subAccountDisplayName = '';
            if (isSubAccount) {
                this.subAccountDisplayName = account.SubAccountFriendlyName;
            }
        }
    }

    getAccountTypeDesc(account) {
        return this.globalcontent.list.accountType[account.AccountTypeDesc] ? this.globalcontent.list.accountType[account.AccountTypeDesc] : '';
    }
    getAccountType(account) {
        return this.globalcontent.list.accountType[account.AccountType] ? this.globalcontent.list.accountType[account.AccountType] : '';
    }
}