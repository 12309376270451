import { Injectable } from '@angular/core';
import { Observable, empty, forkJoin, observable, ObservableInput } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Lob } from 'src/app/shared/models/lob.enum';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { routeConfig } from 'src/app/config/router-mapping';



@Injectable({
  providedIn: 'root'
})
export class WrapperMaintenanceService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    // input.forEach((v: any, key: string) => {
    //   const value = v.input;
    //   value.AdsContentReq.LOB = ((this.appStore.state.user.lob === Lob.GEN) ? Lob.GEN : this.appStore.state.user.lob).toUpperCase();
    //   value.AdsContentReq.Locale = this.appStore.state.user.lang;
    //   value.AdsContentReq.Generic = this.appStore.state.user.lob === Lob.GEN;
    //   // (this.appStore.state.user.lob === Lob.GEN)?true:false;
    // });
    return undefined;
  }

}
