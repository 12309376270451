import { BaseData } from '../../../../../../shared/models/base-data';

export class CancelCashPlanConfirmData extends BaseData {
    constructor() {
        super();

    }

}

export class CancelCashPlanConfirmResp {
    OrderID?: string;
    OrderReceivedOn?: string;
    Account?: Account;
    NextOrderDate?: string;
    PayFromAccountNumber?: string;
    ValidInfo?: ValidInfo
}

export interface Account {
    AccountNumber?: string;
    AccountType?: string;
    AccountFriendlyName?: string;
    RegistrationInfo?: registrationInfo;
}

export interface registrationInfo {
    RegType: string;
}

export interface ValidInfo {
    PlanFrequency?: number;
    StartDate?: string;
    Action?: string;
    Amount?: number;
    EndDate?: string;
    SuspendDate?: string;
    UnSuspendDate?: string;
    PayFromAccountNumber?: string;
}

export const No_API_Data = {
    dash: '-',
    registered: 'REG',
    dollar: '$',
    type: 'brkrg',
    buy: 'Buy',
    achat: 'Achat'
}


