import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
// import { AccountHoldingsStore } from '../data/account-holdings-store';
import { NavParamService } from 'src/app/core/services/nav-param.service';


@Injectable({
  providedIn: 'root'
})
export class PredictiveSearchService implements PageService {

  constructor(
    private httpService: HttpService,
    private navParam: NavParamService
  ) { }

  prepareInput(
    input: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): void {
    const ser = input[0];
    const req = ser.input;
    // req.AccountHoldingsReq.AccountNumber.AccountId = this.navParam.navData.accountNumber;
  }

  search(req, url) {

    return this.httpService.post(url, req, { params: { skiploading: 'true' } });
  }

}
