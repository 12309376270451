import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { BuyGicLandingStore } from '../data/buy-gic-landing-store';
import { BUY_GIC_CONSTANT } from '../../buy-gic.constant';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class BuyGicLandingService implements PageService {


  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (BuyGicLandingStore.crossFlow && BuyGicLandingStore.crossFlow.productType === BUY_GIC_CONSTANT.MLGIC) {
      const ret = [input[1]];
      return ret;
    } else if (BuyGicLandingStore.inFlow) {
      if (BuyGicLandingStore.inFlow.selectedTab == 0) {
        const ret = [input[0]];
        return ret;
      } else if (BuyGicLandingStore.inFlow.selectedTab == 1) {
        const ret = [input[1]];
        return ret;
      } else {
        const ret = [input[2]];
        return ret;
      }
    } else {
      const ret = [input[0]];
      return ret;
    }
  }

  getThirdPartyGic(req) {
    return this.httpService.post(gatewayConfig.APIServices.GICThirdPartSearch.url,
      req,
    );
  }

  getCibcGic() {
    return this.httpService.post(gatewayConfig.APIServices.getGICSearch.url, {});
  }

  getNotes(req) {
    return this.httpService.post(gatewayConfig.APIServices.notesOfferings.url,
      req
    );
  }
}
