import { BaseData } from '../../../../shared/models/base-data';

export class AboutMyInvestmentCounsellorData extends BaseData {
  constructor() {
    super();
  }
}

export interface mycounsellorResponse {
  PortfolioId?: String;
  Counsellors?: CounsellorData[];

}
export interface CounsellorData {
  FirstName?: String;
  LastName?: String;
  Address1?: String;
  City?: String;
  Province?: String;
  PostalCode?: String;
  PhoneNumber?: String;
  FaxPhoneNumber?: String;
  EmailAddress?: String;
  Name?: String;
}

