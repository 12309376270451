<div [attr.id]="Id" [attr.role]="alert ? 'alert' : null">
  <div *ngIf="error" class="field-level-error-container"
    [ngClass]="{'error-container-right' : rightAlign, 'compact' : compact, 'error-container-ie': appStore.lob == 'ie'}">
    <div aria-hidden="true" class="field-level-error-image icon-Error-Warning-Fill color10"></div>
    <span class="field-level-error-text color10">
      <div>
        <span *ngIf="error.messageSR" class="sr-only">{{error.messageSR}}</span>
        <span [attr.aria-hidden]="error.messageSR ? true : null" [innerHtml]="error.message"></span>
      </div>
      <div *ngIf="error.displayCode" class="errorCode">
        {{ '{' }}{{globalContent.text.result}} #{{error.displayCode}}{{ '}' }}</div>
    </span>
  </div>
</div>