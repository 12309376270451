import { RouteGroup } from '../shared/models/route-config';

export const RegularInvestmentPlanConfig: RouteGroup = {

    '/txn/regularinvestmentplan': {
        name: 'regularinvestmentplan',
        service: 'RegularInvestmentPlanService',
        servicePath: 'txn/regular-investment-plan/service/regular-investment-plan',
        store: 'RegularInvestmentPlanStore',
        resolveContent: 'regularinvestmentplan',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlan',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans',
            name: 'view-plans',
            nameGA: 'Regular Investment Plans - View Plans',
            events: { pageView: true }
        },
        brazeID: "viewed_regular_investment_plans"
    },
    '/txn/regularinvestmentplan/details': {
        name: 'regularinvestmentplandetails',
        service: 'RegularInvestmentPlanDetailsService',
        servicePath: 'txn/regular-investment-plan/details/service/regular-investment-plan-details',
        store: 'RegularInvestmentPlanDetailsStore',
        resolveContent: 'regularinvestmentplandetails',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanDetails',
                get request(): any {
                    return {
                    };
                }
            }]
    },
    '/txn/regularinvestmentplan/createcashplan/landing': {
        name: 'createcashplanlanding',
        service: 'CreateCashPlanLandingService',
        servicePath: 'txn/regular-investment-plan/create-cash-plan/landing/service/create-cash-plan-landing',
        store: 'CreateCashPlanLandingStore',
        resolveContent: 'createcashplanlanding',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanCashNewInit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, create-cash-plan',
            name: 'details',
            nameGA: 'Create Cash Plan',
            formInfo: { name: 'obr-create-cash-plan', stepName: 'details' },
            events: { formStep: true, pageView: true, formView: true }
        },
        brazeID: "viewed_create_cash_plan_details"
    },
    '/txn/regularinvestmentplan/createcashplan/verify': {
        name: 'createcashplanverify',
        service: 'CreateCashPlanVerifyService',
        servicePath: 'txn/regular-investment-plan/create-cash-plan/verify/service/create-cash-plan-verify',
        store: 'CreateCashPlanVerifyStore',
        resolveContent: 'createcashplanverify',
        policy: {
            navFrom: {
                url: ["/txn/regularinvestmentplan/createcashplan/landing"],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            },
            fallbackRoute: "/txn/regularinvestmentplan/createcashplan/landing",
        },
        restService: [
            {
                id: 'validate',
                restUrl: 'regularInvestmentPlanCashNewValidate',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, create-cash-plan',
            name: 'verification',
            nameGA: 'Create Cash Plan - Verification',
            formInfo: { name: 'obr-create-cash-plan', stepName: 'verification' },
            events: { formStep: true, pageView: true }
        }
    },
    '/txn/regularinvestmentplan/createcashplan/confirm': {
        name: 'createcashplanconfirm',
        service: 'CreateCashPlanConfirmService',
        servicePath: 'txn/regular-investment-plan/create-cash-plan/confirm/service/create-cash-plan-confirm',
        store: 'CreateCashPlanConfirmStore',
        resolveContent: 'createcashplanconfirm',
        policy: {
            navFrom: {
                url: ['/txn/regularinvestmentplan/createcashplan/verify'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            },
            fallbackRoute: "/txn/regularinvestmentplan/createcashplan/landing",
        },
        restService: [
            {
                id: 'submit',
                restUrl: 'regularInvestmentPlanCashNewSubmit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, create-cash-plan',
            name: 'confirmation',
            nameGA: 'Create Cash Plan - Confirmation',
            formInfo: { name: 'obr-create-cash-plan', stepName: 'confirmation' },
            events: { formStep: true, formSubmit: true, pageView: true, transactionComplete: true },
            transaction: {
                mainField: "inFlow",
                fieldNames: {
                    type: "cash",
                    amount: "orderReviewInfo.ValidateInfo.Amount",
                    serviceFee: "",
                    frequency: "omnitureParams.frequency",
                    from: "",
                    to: "orderReviewInfo.Account.AccountTypeDesc",
                    currency: "omnitureParams.currency",
                    fromAccountType: "omnitureParams.acctType",
                    toAccountType: "omnitureParams.toAccountType",
                    currencyPayFrom: "omnitureParams.currency",
                    currencyPayTo: "omnitureParams.currency",
                    institutionalNameFrom: "omnitureParams.institutionName",
                    institutionalNameTo: "orderReviewInfo.Account.BankAccountInfo.InstitutionName"
                },
            },
        }
    },
    '/txn/regularinvestmentplan/cancelcashplan/landing': {
        name: 'cancelcashplandetail',
        service: 'CancelCashPlanLandingService',
        servicePath: 'txn/regular-investment-plan/cancel-cash-plan/landing/service/cancel-cash-plan-landing',
        store: 'CancelCashPlanLandingStore',
        resolveContent: 'cancelcashplanlanding',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanCashCancelInit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, cancel-cash-plan',
            name: 'verification',
            nameGA: 'Cancel Cash Plan - Verification',
            formInfo: { name: 'obr-cancel-cash-plan', stepName: 'verification' },
            events: { formStep: true, pageView: true, formView: true }
        },
        brazeID: "viewed_cancel_cash_plan_verify"
    },
    '/txn/regularinvestmentplan/cancelcashplan/confirm': {
        name: 'cancelcashplanconfirm',
        service: 'CancelCashPlanConfirmService',
        servicePath: 'txn/regular-investment-plan/cancel-cash-plan/confirm/service/cancel-cash-plan-confirm',
        store: 'CancelCashPlanConfirmStore',
        resolveContent: 'cancelcashplanconfirm',
        policy: {
            navFrom: {
                url: ['/txn/regularinvestmentplan/cancelcashplan/landing'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanCashCancelSubmit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, cancel-cash-plan',
            name: 'confirmation',
            nameGA: 'Cancel Cash Plan - Confirmation',
            formInfo: { name: 'obr-cancel-cash-plan', stepName: 'confirmation' },
            events: { formStep: true, pageView: true, formSubmit: true }
        }
    },
    '/txn/regularinvestmentplan/createmutualfund/landing': {
        name: 'createmutualfundlanding',
        service: 'CreateMutualFundLandingService',
        servicePath: 'txn/regular-investment-plan/create-mutual-fund-plan/landing/service/create-mutual-fund-landing',
        store: 'CreateMutualFundLandingStore',
        resolveContent: 'createmutualfundlanding',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanMFNewInit',
                get request(): any {
                    return {};
                }
            },
            {
                id: 'holdings',
                restUrl: 'getOrderHoldings',
                optional: true,
                get request(): any {
                    return {
                        FiType: "M"
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, create-mutual-fund-plan',
            name: 'details',
            nameGA: 'Create Mutual Fund Plan',
            formInfo: { name: 'obr-create-mutual-fund-plan', stepName: 'details' },
            events: { formStep: true, pageView: true, formView: true }
        },
        brazeID: "viewed_create_mutual_funds_plan_details"
    },
    '/txn/regularinvestmentplan/createmutualfund/verify': {
        name: 'createmutualfundverify',
        service: 'CreateMutualFundVerifyService',
        servicePath: 'txn/regular-investment-plan/create-mutual-fund-plan/verify/service/create-mutual-fund-verify',
        store: 'CreateMutualFundVerifyStore',
        resolveContent: 'createmutualfundverify',
        policy: {
            navFrom: {
                url: ["/txn/regularinvestmentplan/createmutualfund/landing"],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            },
            fallbackRoute: "/txn/regularinvestmentplan/createmutualfund/landing",
        },
        restService: [
            {
                id: 'validate',
                restUrl: 'regularInvestmentPlanMFNewValidate',
                get request(): any {
                    return {
                    };
                }
            },
            {
                id: 'Quote',
                restUrl: 'getMutualFundQuote',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, create-mutual-fund-plan',
            name: 'verification',
            nameGA: 'Create Mutual Fund Plan - Verification',
            formInfo: { name: 'obr-create-mutual-fund-plan', stepName: 'verification' },
            events: { formStep: true, pageView: true }
        }
    },
    '/txn/regularinvestmentplan/createmutualfund/confirm': {
        name: 'createmutualfundconfirm',
        service: 'CreateMutualFundConfirmService',
        servicePath: 'txn/regular-investment-plan/create-mutual-fund-plan/confirm/service/create-mutual-fund-confirm',
        store: 'CreateMutualFundConfirmStore',
        resolveContent: 'createmutualfundconfirm',
        policy: {
            navFrom: {
                url: ['/txn/regularinvestmentplan/createmutualfund/verify'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            },
            fallbackRoute: "/txn/regularinvestmentplan/createmutualfund/landing",
        },
        restService: [
            {
                id: 'submit',
                restUrl: 'regularInvestmentPlanMFNewSubmit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, create-mutual-fund-plan',
            name: 'confirmation',
            nameGA: 'Create Mutual Fund Plan - Confirmation',
            formInfo: { name: 'obr-create-mutual-fund-plan', stepName: 'confirmation' },
            events: { formStep: true, formSubmit: true, pageView: true, tradeSubmit: true },
            transaction: {
                mainField: "inFlow",
                fieldNames: {
                    action: "omnitureParams.action",
                    type: "mutual-funds",
                    symbol: "orderReviewInfo.Symbol.SymbolName",
                    market: "",
                    exchange: "",
                    quote: "omnitureParams.quote",
                    quantity: "omnitureParams.quantity",
                    dividend: "omnitureParams.dividend",
                    priceType: "",
                    expiry: "",
                    account: "omnitureParams.account",
                    serviceFee: "",
                    value: "orderReviewInfo.Amount",
                    currency: "omnitureParams.currency",
                    foreignExchange: "omnitureParams.foreignexchange",
                }
            }
        }
    },
    '/txn/regularinvestmentplan/cancelmutualfundplan/landing': {
        name: 'cancelmutualfundplanlanding',
        service: 'CancelMutualFundPlanLandingService',
        servicePath: 'txn/regular-investment-plan/cancel-mutual-fund-plan/landing/service/cancel-mutual-fund-plan-landing',
        store: 'CancelMutualFundPlanLandingStore',
        resolveContent: 'cancelmutualfundplanlanding',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanMFCancelInit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, cancel-mutual-fund-plan',
            name: 'verification',
            nameGA: 'Cancel Mutual Fund Plan - Verification',
            formInfo: { name: 'obr-cancel-mutual-fund-plan', stepName: 'verification' },
            events: { formStep: true, pageView: true, formView: true }
        }
    },
    '/txn/regularinvestmentplan/cancelmutualfundplan/confirm': {
        name: 'cancelmutualfundplanconfirm',
        service: 'CancelMutualFundPlanConfirmService',
        servicePath: 'txn/regular-investment-plan/cancel-mutual-fund-plan/confirm/service/cancel-mutual-fund-plan-confirm',
        store: 'CancelMutualFundPlanConfirmStore',
        resolveContent: 'cancelmutualfundplanconfirm',
        policy: {
            navFrom: {
                url: ['/txn/regularinvestmentplan/cancelmutualfundplan/landing'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanMFCancelSubmit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, cancel-mutual-fund-plan',
            name: 'confirmation',
            nameGA: 'Cancel Mutual Fund Plan - Confirmation',
            formInfo: { name: 'obr-cancel-mutual-fund-plan', stepName: 'confirmation' },
            events: { formStep: true, pageView: true, formSubmit: true }
        }
    },
    '/txn/regularinvestmentplan/changecashplan/landing': {
        name: 'changecashplanlanding',
        service: 'ChangeCashPlanLandingService',
        servicePath: 'txn/regular-investment-plan/change-cash-plan/landing/service/change-cash-plan-landing',
        store: 'ChangeCashPlanLandingStore',
        resolveContent: 'changecashplanlanding',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanCashUpdateInit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, change-cash-plan',
            name: 'details',
            nameGA: 'Change Cash Plan',
            formInfo: { name: 'obr-change-cash-plan', stepName: 'details' },
            events: { formStep: true, pageView: true, formView: true }
        }
    }, '/txn/regularinvestmentplan/changecashplan/verify': {
        name: 'changecashplanverify',
        service: 'ChangeCashPlanVerifyService',
        servicePath: 'txn/regular-investment-plan/change-cash-plan/verify/service/change-cash-plan-verify',
        store: 'ChangeCashPlanVerifyStore',
        resolveContent: 'changecashplanverify',
        policy: {
            navFrom: {
                url: ["/txn/regularinvestmentplan/changecashplan/landing"],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            },
            fallbackRoute: "/txn/regularinvestmentplan/changecashplan/landing",
        },
        restService: [
            {
                id: 'validate',
                restUrl: 'regularInvestmentPlanCashUpdateValidate',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, change-cash-plan',
            name: 'verification',
            nameGA: 'Change Cash Plan - Verification',
            formInfo: { name: 'obr-change-cash-plan', stepName: 'verification' },
            events: { formStep: true, pageView: true }
        },
        brazeID: "viewed_change_cash_funds_plan_verify"
    },
    '/txn/regularinvestmentplan/changecashplan/confirm': {
        name: 'changecashplanconfirm',
        service: 'ChangeCashPlanConfirmService',
        servicePath: 'txn/regular-investment-plan/change-cash-plan/confirm/service/change-cash-plan-confirm',
        store: 'ChangeCashPlanConfirmStore',
        resolveContent: 'changecashplanconfirm',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanCashUpdateSubmit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, change-cash-plan',
            name: 'confirmation',
            nameGA: 'Change Cash Plan - Confirmation',
            formInfo: { name: 'obr-change-cash-plan', stepName: 'confirmation' },
            events: { formStep: true, pageView: true, formView: true }
        },
        brazeID: "action_change_cash_plan_confirmed"
    },
    '/txn/regularinvestmentplan/changemutualfundplan/landing': {
        name: 'changemutualfundplanlanding',
        service: 'ChangeMutualFundPlanLandingService',
        servicePath: 'txn/regular-investment-plan/change-mutual-fund-plan/landing/service/change-mutual-fund-plan-landing',
        store: 'ChangeMutualFundPlanLandingStore',
        resolveContent: 'changemutualfundplanlanding',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [
            {
                id: 'init',
                restUrl: 'regularInvestmentPlanMFUpdateInit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, change-mutual-fund-plan',
            name: 'details',
            nameGA: 'Change Mutual Fund Plan',
            formInfo: { name: 'obr-change-mutual-fund-plan', stepName: 'details' },
            events: { formStep: true, pageView: true, formView: true }
        },
        brazeID: "viewed_change_mutual_funds_plan_details"
    },
    '/txn/regularinvestmentplan/changemutualfundplan/verify': {
        name: 'changemutualfundverify',
        service: 'ChangeMutualFundVerifyService',
        servicePath: 'txn/regular-investment-plan/change-mutual-fund-plan/verify/service/change-mutual-fund-verify',
        store: 'ChangeMutualFundVerifyStore',
        resolveContent: 'changemutualfundverify',
        policy: {
            navFrom: {
                url: ["/txn/regularinvestmentplan/changemutualfundplan/landing"],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            },
            fallbackRoute: "/txn/regularinvestmentplan/changemutualfundplan/landing",
        },
        restService: [
            {
                id: 'validate',
                restUrl: 'regularInvestmentPlanMFUpdateValidate',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, change-mutual-fund-plan',
            name: 'verification',
            nameGA: 'Change Mutual Fund Plan - Verification',
            formInfo: { name: 'obr-change-mutual-fund-plan', stepName: 'verification' },
            events: { formStep: true, pageView: true }
        }
    },
    '/txn/regularinvestmentplan/changemutualfundplan/confirm': {
        name: 'changemutualfundconfirm',
        service: 'ChangeMutualFundConfirmService',
        servicePath: 'txn/regular-investment-plan/change-mutual-fund-plan/confirm/service/change-mutual-fund-confirm',
        store: 'ChangeMutualFundConfirmStore',
        resolveContent: 'changemutualfundconfirm',
        policy: {
            navFrom: {
                url: ['/txn/regularinvestmentplan/changemutualfundplan/verify'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            },
            fallbackRoute: "/txn/regularinvestmentplan/changemutualfundplan/landing",
        },
        restService: [
            {
                id: 'submit',
                restUrl: 'regularInvestmentPlanMFUpdateSubmit',
                get request(): any {
                    return {
                    };
                }
            }
        ],
        trackingData: {
            hierarchy: 'trading, regular-investment-plans, change-mutual-fund-plan',
            name: 'confirmation',
            nameGA: 'Change Mutual Fund Plan - Confirmation',
            formInfo: { name: 'obr-change-mutual-fund-plan', stepName: 'confirmation' },
            events: { formStep: true, formSubmit: true, pageView: true, tradeSubmit: true },
            transaction: {
                mainField: "inFlow",
                fieldNames: {
                    action: "omnitureParams.action",
                    type: "mutual-funds",
                    symbol: "orderReviewInfo.Symbol.SymbolName",
                    market: "",
                    exchange: "",
                    quote: "omnitureParams.quote",
                    quantity: "omnitureParams.quantity",
                    dividend: "omnitureParams.dividend",
                    priceType: "",
                    expiry: "",
                    account: "omnitureParams.account",
                    serviceFee: "",
                    value: "orderReviewInfo.Amount",
                    currency: "omnitureParams.currency",
                    foreignExchange: "omnitureParams.foreignexchange",
                }
            }
        }
    }

};
