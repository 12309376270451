import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PasswordCriteriaDirective } from '../../directive/password-criteria-directive';
import { GlobalContentStore } from '../../store/global-content-store';
import { Subscription } from 'rxjs';
import { AppStore } from '../../models/app-store';
import { CommonService } from '../../services/common.service';

declare let require: any;

@Component({
    selector: '[appPasswordIndicators]',
    templateUrl: './password-indicators.component.html',
    styleUrls: ['./password-indicators.component.scss']
})
export class PasswordIndicatorsComponent implements OnInit, OnChanges, OnDestroy {
    
    passwordMatch: boolean;
    passwordMatchSection:boolean;
    subscriptions = new Subscription();
    @Input() public passwordStrength: string;
    @Input() viewPasswordMatchSection;
    @ViewChild('strengthMatchTplRef', { static: true }) strengthMatchTplRef;

    constructor(public globalcontent: GlobalContentStore,
        public appStore: AppStore,
        private viewContainer: ViewContainerRef,
        private cdr: ChangeDetectorRef,
        public pwdDirective: PasswordCriteriaDirective,
        private element: ElementRef,
        private commonService: CommonService) {
    }

    ngOnChanges(change) {
        if(change && change.viewPasswordMatchSection) { 
            this.passwordMatchSection = change.viewPasswordMatchSection.currentValue;
        }
        if(change && change.passwordStrength && change.passwordStrength.currentValue
            && this.element && this.element.nativeElement && this.element.nativeElement.value) {
                this.passwordMatch = change.passwordStrength.currentValue == this.element.nativeElement.value;
                this.passwordMatchSection = true;
        }else if((change && change.passwordStrength && !change.passwordStrength.currentValue)
        && (this.element && this.element.nativeElement && !this.element.nativeElement.value)) {
            this.passwordMatchSection = false;
        }else if((change && change.passwordStrength && change.passwordStrength.currentValue)
        && (this.element && this.element.nativeElement && !this.element.nativeElement.value)){
            //this.passwordMatch = change.passwordStrength.currentValue == this.element.nativeElement.value;
            this.passwordMatchSection = false;
        }else if((change && change.passwordStrength && !change.passwordStrength.currentValue)
        && (this.element && this.element.nativeElement && this.element.nativeElement.value)){
            this.passwordMatch = change.passwordStrength.currentValue == this.element.nativeElement.value;
            this.passwordMatchSection = false;
        }
    }

    ngOnInit() {
        this.subscriptions.add(this.pwdDirective.passwordMatchSection.subscribe(pwdMatchesSection =>{
            this.passwordMatchSection = pwdMatchesSection
        }));
        this.subscriptions.add(this.pwdDirective.passwordMatches.subscribe(passwordMatches => {
            this.passwordMatch = passwordMatches
        }));
        this.renderTemplate();
    };

    renderTemplate() {
        this.viewContainer.createEmbeddedView(this.strengthMatchTplRef);
        this.cdr.markForCheck();
    }
    ngOnDestroy(){
        this.subscriptions.unsubscribe();
    }

    isDesktop() {
        return this.commonService.isDesktop();
    }

}