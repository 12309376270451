import { BaseData } from 'src/app/shared/models/base-data';

export class OnlineRegisterPasswordData extends BaseData {
    constructor() {
        super();
        this.input = { 
          password: '',
          reEnteredPassword: ''
        };
    }
}

export interface PasswordVerifyInputs {
  password: string;
  reEnteredPassword: string;
}