import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import {SidebarItemComponent} from './sidebar-item.component';
import { ChatSharedModule } from 'src/app/shared/chat-shared.module';

@NgModule({
    declarations: [SidebarItemComponent],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        ChatSharedModule,
        SharedModule
    ],
    exports: [
        SidebarItemComponent
    ]
})
export class SidebarItemModule { }
