import { Injectable } from '@angular/core';
import { ConnectCIBCOnlineLandingData } from './connect-cibc-online-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ConnectCIBCOnlineLandingStore extends PageStore<ConnectCIBCOnlineLandingData> {

    constructor() {
        super(new ConnectCIBCOnlineLandingData());
    }

}
