import { Injectable } from '@angular/core';
import { SplashData } from './splash-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SplashStore extends PageStore<SplashData> {

    constructor() {
        super(new SplashData());
    }
}
