// Import the core angular services.
import { HostListener, Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { GlobalContentStore } from '../store/global-content-store';
import { AppStore } from '../models/app-store';
import { Subscription } from 'rxjs/internal/Subscription';
import { ObservableSubscriptionService } from '../services/observable-subscription.service';

@Directive({
  selector: '[buttonDisable]',
})

export class ButtonDisableDirective implements OnInit {
  // Allow decimal numbers. The \. is only allowed once to occur
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home

  disabledButton: any = undefined;
  enableButtonSub: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private globalContent: GlobalContentStore,
    private appStore: AppStore,
    private subscriptionService: ObservableSubscriptionService
  ) {
  }

  ngOnInit() {
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    setTimeout(() => {
      this.el.nativeElement.blur();
      this.el.nativeElement.setAttribute('disabled', true);
    }, 1);
    this.enableButtonSub = this.subscriptionService.enableButtonEvent$.subscribe((ev) => {
      console.log("enable button " + ev);
      this.el.nativeElement.removeAttribute('disabled');
      this.enableButtonSub.unsubscribe();
    });
  }
}
