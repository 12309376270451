import { Injectable } from '@angular/core';
import { AppVersions } from './../../config/app-versions';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PluginService {

  MAJOR_SHIFT: number = 1000 * 1000;
  MINOR_SHIFT = 1000;
  TINY_SHIFT = 1;

  public resolvePluginVersion(reqVersion: string): string {
    const versionsAsNum: Array<number> = AppVersions.map(v => this.stringVerAsNum(v));
    // descending sort. asdf
    versionsAsNum.sort((a: number, b: number) => a - b);
    const versionToMatch: number = this.stringVerAsNum(reqVersion);
    const pluginVersionToUse = versionsAsNum.reduce(
      (prev: number, curr: number) => {
        if (versionToMatch === curr) {
          return curr;
        } else if (versionToMatch < curr) {
          return prev;
        } else { // versionToMatch > curr
          return curr;
        }
      },
      0
    );
    return this.numVerAsString(pluginVersionToUse);
  }

  /**
   * Converts a version number such as "3.2.1" into 321
   */
  private stringVerAsNum(version: string): number {
    let versionSplit = version.split('.');
    if (versionSplit.length < 4) {
      const rest = Array(4 - versionSplit.length);
      _.fill(rest, '0');
      versionSplit = versionSplit.concat(rest);
    }
    const [major, minor, tiny] = versionSplit.map((x) => parseInt(x));
    return major * this.MAJOR_SHIFT + minor * this.MINOR_SHIFT + tiny * this.TINY_SHIFT;
  }

  /**
   * Converts a version number such as 321 into "3.2.1"
   */
  private numVerAsString(version: number): string {
    let verAsString: string;
    // 987654321 => 987.654321 => "987"
    const major: number = Math.floor(version / this.MAJOR_SHIFT);
    verAsString = major.toString();

    // 987654321 => 654321 => 654.321 => "654"
    const minor: number = Math.floor((version - (major * this.MAJOR_SHIFT)) / this.MINOR_SHIFT);
    verAsString += '.' + minor.toString();

    // 987654321 => 321 => "321"
    const tiny: number = (version - (major * this.MAJOR_SHIFT) - (minor * this.MINOR_SHIFT));
    verAsString += '.' + tiny.toString();
    return verAsString;
  }

}
