import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { HelpTopQuestionRequest, HelpAskQuestionRequest, HelpDetailRequest, TypeID } from '../data/help-data';

declare var require: any;

const gatewayConfig = require('../../../../config/gateway-config.json');


@Injectable({
  providedIn: 'root'
})
export class HelpService implements PageService {

  constructor(
    private appStore: AppStore,
    private httpService: HttpService
  ) {

  }

  prepareInput(
    input: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    return input;
  }

  setQuestion(question: string) {
    const req: HelpAskQuestionRequest = { Question: question };
    return this.httpService.post(gatewayConfig.APIServices.helpSave.url, req);
  }

  getAnswer(question: string) {
    const req: HelpAskQuestionRequest = { Question: question };
    return this.httpService.post(gatewayConfig.APIServices.helpAsk.url, req);
  }

  getHelpDetail(questionId: number, typeID: TypeID) {
    const req: HelpDetailRequest = { ResponseId: questionId, TypeId: typeID };
    return this.httpService.post(gatewayConfig.APIServices.helpDetail.url, req);
  }

  getTopQuestions(numberRequested: number) {
    const req: HelpTopQuestionRequest = { NumberOfQuestions: numberRequested };
    return this.httpService.post(gatewayConfig.APIServices.helpTopQuestions.url, req);
  }

  getAdvisorData() {
    return this.httpService.post(gatewayConfig.APIServices.getAdvisor.url, {});
  }

}
