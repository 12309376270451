import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccountSharedModule } from './account-shared.module';
import { SymbolQuoteModule } from './components/symbol-quote/symbol-quote.module';
import { DateSelectorSharedModule } from './date-selector-shared.module';
import { FlowWidgetSharedModule } from './flow-widget-shared.module';
import { FormSharedModule } from './form-shared.module';
import { PipeSharedModule } from './pipe-shared.module';
import { SharedModule } from './shared.module';
import { WidgetSharedModule } from './widget-shared.module';
import { OtcvSharedModule } from 'src/app/shared/otvc-shared.module';
import { CurrencyFormatterPipe } from '../core/pipes/currency-formatter.pipe';
import { QuickTradeStocksComponent } from './components/quick-trade-stocks/quick-trade-stocks.component';
import { QuickTradeStocksLandingComponent } from './components/quick-trade-stocks/landing/quick-trade-stocks-landing.component';
import { QuickTradeStocksConfirmComponent } from './components/quick-trade-stocks/confirm/quick-trade-stocks-confirm.component';
import { QuickTradeStocksVerifyComponent } from './components/quick-trade-stocks/verify/quick-trade-stocks-verify.component';

@NgModule({
  declarations: [
    QuickTradeStocksComponent,
    QuickTradeStocksLandingComponent,
    QuickTradeStocksConfirmComponent,
    QuickTradeStocksVerifyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    PipeSharedModule,
    AccountSharedModule,
    FormSharedModule,
    WidgetSharedModule,
    FlowWidgetSharedModule,
    SymbolQuoteModule,
    DateSelectorSharedModule,
    OtcvSharedModule
  ],
  providers: [
    CurrencyFormatterPipe
  ],
  exports: [
    QuickTradeStocksComponent,
    QuickTradeStocksLandingComponent,
    QuickTradeStocksConfirmComponent,
    QuickTradeStocksVerifyComponent
  ]
})
export class QuickTradeSharedModule { }
