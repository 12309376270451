import { RouteGroup } from '../shared/models/route-config';

export const StructNotesConfig: RouteGroup = {
  '/txn/buy-structured-notes/selection': {
    name: 'BuyStructNotesSelection',
    service: 'BuyStructNotesSelectionService',
    servicePath: 'txn/structured-notes/buy-structured-notes/selection/services/buy-struct-notes-selection',
    store: 'BuyStructNotesSelectionStore',
    resolveContent: 'buystructnotesselection',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: '',
      },
      navTo: {
        url: ['*'],
        fallBackUrl: '',
      },
    },
    restService: [
      {
        id: 'init',
        restUrl: 'notesOfferings',
        get request(): any {
          return {
            ProductType: 'PAR',
          };
        },
      },
      {
        id: "ppn",
        restUrl: "notesOfferings",
        get request(): any {
          return {
            ProductType: "PPN",
          };
        },
      }
    ],
    trackingData: {
      hierarchy: "trading,structured-notes",
      name: "pars",
      nameGA: "Buy Structured Notes - pars",
      events: { pageView: true },
      isSubmitDeferred: true
    },
    brazeID: "viewed_buy_structured_notes"
  },
  '/txn/buy-structured-notes/entry': {
    name: 'BuyStructNotesEntry',
    service: 'BuyStructNotesEntryService',
    servicePath: 'txn/structured-notes/buy-structured-notes/entry/services/buy-struct-notes-entry',
    store: 'BuyStructNotesEntryStore',
    resolveContent: 'buystructnotesentry',
    policy: {
      navFrom: {
        url: [
          '/txn/buy-structured-notes/selection',
          '/txn/buy-structured-notes/verify',
          '/txn/fixed-income/buy-gic/landing'
        ],
        fallBackUrl: '',
      },
      navTo: {
        url: ['*'],
        fallBackUrl: '',
      },
      fallbackRoute: '/txn/buy-structured-notes/selection',
    },
    restService: [{
      id: 'init',
      restUrl: 'notesInit',
      get request(): any {
        return {};
      },
    }],
    brazeID: "viewed_buy_structured_notes_details"
  },
  '/txn/buy-structured-notes/verify': {
    name: 'BuyStructNotesVerify',
    service: 'BuyStructNotesVerifyService',
    servicePath: 'txn/structured-notes/buy-structured-notes/verify/services/buy-struct-notes-verify',
    store: 'BuyStructNotesVerifyStore',
    resolveContent: 'buystructnotesverify',
    policy: {
      navFrom: {
        url: ['/txn/buy-structured-notes/entry'],
        fallBackUrl: '',
      },
      navTo: {
        url: ['*'],
        fallBackUrl: '',
      },
      fallbackRoute: '/txn/buy-structured-notes/selection'
    },
    restService: [{
      id: 'init',
      restUrl: 'notesVerify',
      get request(): any {
        return {};
      },
    }]
  },
  '/txn/buy-structured-notes/confirm': {
    name: 'BuyStructNotesConfirm',
    service: 'BuyStructNotesConfirmService',
    servicePath: 'txn/structured-notes/buy-structured-notes/confirm/services/buy-struct-notes-confirm',
    store: 'BuyStructNotesConfirmStore',
    resolveContent: 'buystructnotesconfirm',
    policy: {
      navFrom: {
        url: ['/txn/buy-structured-notes/verify'],
        fallBackUrl: '',
      },
      navTo: {
        url: ['*'],
        fallBackUrl: '',
      },
      fallbackRoute: '/txn/buy-structured-notes/selection'
    },
    restService: [{
      id: 'init',
      restUrl: 'notesSubmit',
      get request(): any {
        return {};
      },
    }],
  },
  '/txn/sell-structured-notes/landing': {
    name: 'SellStructNotesLanding',
    service: 'SellStructNotesLandingService',
    servicePath: 'txn/structured-notes/sell-structured-notes/landing/services/sell-struct-notes-landing',
    store: 'SellStructNotesLandingStore',
    resolveContent: 'sellstructnoteslanding',
    policy: {
      navFrom: {
        url: ['/txn/buy-structured-notes/selection',
          '/txn/accounts/accountholdings',
          '/txn/sell-structured-notes/verify',
          '/txn/sell-structured-notes/confirm'],
        fallBackUrl: '',
      },
      navTo: {
        url: ['*'],
        fallBackUrl: '',
      },
    },
    restService: [
      {
        id: 'init',
        restUrl: 'notesInit',
        get request(): any {
          return {
          };
        }
      },
      {
        id: 'holdings',
        restUrl: 'notesHoldings',
        optional: true,
        get request(): any {
          return {};
        }
      },
      {
        id: 'settlement',
        restUrl: 'notesSettlementAccounts',
        optional: true,
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: 'trading,sell-structured-notes',
      name: 'details',
      nameGA: 'Sell Structured Notes - Details',
      formInfo: { name: 'obr-sell-structured-notes', stepName: 'details' },
      events: { formStep: true, pageView: true, formView: true },
    },
    brazeID: "viewed_sell_structured_notes_details"
  },
  '/txn/sell-structured-notes/verify': {
    name: 'SellStructNotesVerify',
    service: 'SellStructNotesVerifyService',
    servicePath: 'txn/structured-notes/sell-structured-notes/verify/services/sell-struct-notes-verify',
    store: 'SellStructNotesVerifyStore',
    resolveContent: 'sellstructnotesverify',
    policy: {
      navFrom: {
        url: ['/txn/sell-structured-notes/landing'],
        fallBackUrl: '',
      },
      navTo: {
        url: ['*'],
        fallBackUrl: '',
      },
      fallbackRoute: '/txn/sell-structured-notes/landing'
    },
    restService: [
      {
        id: 'verify',
        restUrl: 'notesVerify',
        get request(): any {
          return {
          };
        }
      }
    ],
  },
  '/txn/sell-structured-notes/confirm': {
    name: 'SellStructNotesConfirm',
    service: 'SellStructNotesConfirmService',
    servicePath: 'txn/structured-notes/sell-structured-notes/confirm/services/sell-struct-notes-confirm',
    store: 'SellStructNotesConfirmStore',
    resolveContent: 'sellstructnotesconfirm',
    policy: {
      navFrom: {
        url: ['/txn/sell-structured-notes/verify'],
        fallBackUrl: '',
      },
      navTo: {
        url: ['*'],
        fallBackUrl: '',
      },
      fallbackRoute: '/txn/sell-structured-notes/landing'
    },
    restService: [
      {
        id: "confirm",
        restUrl: "notesSubmit",
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: 'trading,sell-structured-notes,{pars|ppns}',
      name: 'confirmation',
      nameGA: 'Sell Structured Notes - Confirmation',
      formInfo: { name: 'obr-sell-{pars|ppns}', stepName: 'confirmation' },
      events: { formStep: true, pageView: true, tradeSubmit: true, formSubmit: true },
      transaction: {
        mainField: 'inFlow',
        fieldNames: {
          action: 'omnitureParam.Action',
          type: 'omnitureParam.Type',
          symbol: 'omnitureParam.Symbol',
          market: 'na',
          exchange: 'na',
          quote: 'omnitureParam.Quote',
          quantity: 'omnitureParam.Quantity',
          dividend: 'na',
          priceType: 'na',
          expiry: 'na',
          serviceFee: 'omnitureParam.ServiceFee',
          value: 'omnitureParam.Value',
          currency: 'omnitureParam.Currency',
          account: 'omnitureParam.Account',
          foreignExchange: 'omnitureParam.ForeignExchange',
        }
      },
      isSubmitDeferred: true
    }
  },
};
