import { Injectable } from '@angular/core';
import { CurrencyTransferConfirmData } from './currency-transfer-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class CurrencyTransferConfirmStore extends PageStore<CurrencyTransferConfirmData> {

    constructor() {
        super(new CurrencyTransferConfirmData());
    }

}
