import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})

export class ContactInfoUpdateLandingService implements PageService {

    constructor(private httpService: HttpService) { }

    prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return input;
    }

    validateAddress(params) {
        return this.httpService.post(gatewayConfig.APIServices.addressVerify.url, params);
    }

    submitAddressWebForm(params) {
        return this.httpService.post(gatewayConfig.APIServices.addressSubmit.url, params);
    }

    refreshAddressUpdate(params) {
        return this.httpService.post(gatewayConfig.APIServices.webFormInit.url, params);
    }

}