import { Injectable } from '@angular/core';
import { RemoveBrokerageAccountsConfirmData } from './remove-brokerage-accounts-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class RemoveBrokerageAccountsConfirmStore extends PageStore<RemoveBrokerageAccountsConfirmData> {

    constructor() {
        super(new RemoveBrokerageAccountsConfirmData());
    }

}
