import { BaseData } from 'src/app/shared/models/base-data';

export class FixedIncomeData extends BaseData {
  constructor() {
    super();
    this.input = {
      FaceValue: null,
    };
  }
}

export interface FixedIncomeInputs {
  FaceValue: number;
}


export interface SearchReq {
  Refresh?: boolean;
  FaceValue?: number;
  QuickPicksCategory?: string;
  PaginationOption?: {
    Offset: number,
    Limit: number
  };
  SortOption?: {
    SortColumn: string,
    SortType: string
  };
}

export interface CodeValue {
  CodeValue: string;
}

export interface  ParValue {
  Data: number;
  Content: string;
}

export interface Yield {
  YieldType: CodeValue;
}

export interface Yields extends Array<Yield> {}

export interface Bond {
  FiInstrumentToken: string;
  CusipId: string;
  Issuer: string;
  MaturityDate: string;
  IssuerSector: CodeValue;
  FiType: CodeValue;
  SecurityDescription: string;
  ParValue: ParValue;
  Yield: Yields;
  CouponRate: string;
  CouponPaymentFrequency: CodeValue;
  Price: string;
  Currency: Currency;
  NextPaymentDate: string;
  AccruedInterestAmount: string;
}

export interface SettlementInfo {
  SettlementAmount: ParValue;
  SettlementDate: string;
}

export interface SettlementAccount {
  SettlementType: string;
  Currency: Currency;
  AccountType?: string;
  AccountNumber?: number;
}

export interface BondOrderData {
  TransferYield: string;
  TransferPrice: string;
  ExchangeRate: number;
  TotalCommissionAmount: string;
  StandardCommissionRate: string;
  RevisedCommissionRate: string;
  MinimumCommissionRate: string;
  MaximumCommissionRate: string;
  AllowCommissionChangeFlag: string;
  SettlementInfo: SettlementInfo;
}

export interface  PayFrom {
  SettlementType: string;
  Currency: Currency;
  AccountType: string;
}

export interface AccountInfo {
  AccountNumber: string;
  AccountTypeDesc: string;
  Fullname: string;
  AccountFriendlyName: string;
  IncludeShort: boolean;
}

export interface OrderReviewInfo {
  Account: AccountInfo;
  Bond: Bond;
  BondOrderData?: BondOrderData;
  PayFrom?: PayFrom;
  EstimatedCost?: EstimatedCost;
}

export interface GICOrderReviewInfo {
  PayFrom: PayFrom;
  MaturityDate: string;
  IssuerDescription: string;
  Amount: number;
  Description: string;
  Account: AccountInfo;
  SettlementDate?: string;
  InterestRateOrEffectiveYield?: string;
  InterestPaymentFrequency?: string;
  Term?: number;
}

export interface EstimatedCost {
  EstimatedOrderValue: string;
  EstimatedCommission: string;
  EstimatedTotalCost: string;
}

export interface Warning {
  Message: string;
  Code: string;
}

export interface BondOrderInit {
  OrderReviewInfo: OrderReviewInfo;
  EstimatedCost?: EstimatedCost;
  AccountList?: any;
  Warnings?: Warning[];
}

export interface AccountOwnerName {
  FirstName: string;
  LastName: string;
}

export interface AccountBalance {
  AccountType: string; // TODO change to enum
  Currency: string; // TODO change to currency enum
  Amount: number;
}

enum BuyingPowerValueType {
  COMBINED = 'COMBINED',
  SHOW_CAD_AND_USD = 'SHOW_CAD_AND_USD'
}

export interface AccountDetail {
  AccountType: string;
  AccountNumber: number;
  AccountTypeDesc: string;
  AccountOwnerName: AccountOwnerName;
  Fullname: string;
  AccountFriendlyName: string;
  AccountBalances: AccountBalance[];
  IncludeShort: string;
  BuyingPowerValueType: BuyingPowerValueType;
}

export interface SettlementAccountsReq {
  AccountNumber: number;
  Action: string;
  AccountCurrency?: string;
  Type?: string;
}

export interface CalculateByReq {
  AccountNumber: number;
  SettlementAccount?: SettlementAccount;
  ReCalculateAmount?: number;
  ReCalculateAmountToInvest?: number;
}

export interface UpdateQuantityReq {
  AccountNumber: number;
  quantityType: string;
  quantity: number;
}

export enum ActionType {
  BUY = 'BUY'
}

export enum CalculateByType {
  FACE_VALUE = 'FACEVALUE',
  AMOUNT_TO_INVEST = 'AMOUNTTOINVEST'
}

export interface MarketValue {
  market: string;
  quantity: number;
}

export enum Currency {
  CAD = 'CAD',
  USD = 'USD'
}


