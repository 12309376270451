import { Injectable } from '@angular/core';
import { TradeOptionsVerifyData } from './trade-options-verify-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TradeOptionsVerifyStore extends PageStore<TradeOptionsVerifyData> {

    constructor() {
        super(new TradeOptionsVerifyData());
    }

}
