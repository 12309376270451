import { Component, OnInit, OnDestroy, Renderer2, Inject, AfterViewInit } from '@angular/core';
import { AppStore } from 'src/app/shared/models/app-store';
import { HomeService } from './services/home.service';
import { ContentStore } from '../../../shared/store/content-store';
import { GlobalContentStore } from 'src/app/shared/store/global-content-store';
import { RegisterOnlineStore } from '../register-online/landing/data/register-online-store';
import { GenericStore } from 'src/app/shared/store/generic-store';
import { Router } from '@angular/router';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { RoutePermissionService } from './../../../shared/services/route-permission.service';
import { ObservableSubscriptionService } from '../../../shared/services/observable-subscription.service';
import { ContentService } from '../../../core/services/content.service';
import { of, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Lang, Lob } from '../../../shared/models/lob.enum';
import { IOmnitureAdsData } from 'src/app/shared/models/omniture.types';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { OmniturePageDetail } from "src/app/shared/models/route-config";
declare let cordova: any;


declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: []
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  data: any;
  // content:any;
  subscriptions: Subscription[] = [];
  isLobChangeHomeContentReload: boolean;
  contentImgId: any;
  contentImage: any;
  PEEnabled: boolean = false;

  constructor(
    public store: GenericStore,
    private router: Router,
    public appStore: AppStore,
    public homeService: HomeService,
    public content: ContentStore,
    public globalcontent: GlobalContentStore,
    public persistenceService: PersistenceService,
    public commonService: CommonService,
    private routePermissionService: RoutePermissionService,
    private subscriptionService: ObservableSubscriptionService,
    private contentService: ContentService,
    private _commonService: CommonService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private trackingService: TrackingService

  ) { }

  ngOnInit() {
    this.data = this.store.state.data;
    if (this.appStore.state.user.lob == Lob.IE) {
      this.PEEnabled = this.appStore.state.user.premiumUser
    }
    // this.content=this.store.state.content;
    // console.log(this.homeService);
    console.log(JSON.stringify(this.appStore.state.user));
    this.appStore.hideSplashScreen();
    this.routePermissionService.enableToggleLocaleButton = true;
    this.isLobChangeHomeContentReload = false;
    this.contentImgId = new Date().getTime();
    this.subscriptions.push(this.subscriptionService.stringLocale.subscribe((localeData) => {
      if (localeData) {
        this.reloadContent();
      }
    }));

    this.subscriptions.push(this.subscriptionService.stringLob.subscribe((lobData) => {
      if (lobData) {
        this.reloadContent();
      }
    }));

    this.subscriptions.push(this.subscriptionService.otLoaded.subscribe(loaded => {
      console.log("OneTrustOBR ot loaded " + loaded);
      if(loaded) {
        this.omnitureTagging();
      }
    }));
  }

  private reloadContent() {
    this.isLobChangeHomeContentReload = true;
    this.subscriptions.push(this.contentService.fetchContent('home').subscribe((homeContent) => {
      if (homeContent) {
        this.content = homeContent;
        this.isLobChangeHomeContentReload = false;
        if (this.appStore.lang.toLowerCase() == "en") {
          window.location.replace(window.location.href.replace("/fr/", "/en/"));
        } else {
          window.location.replace(window.location.href.replace("/en/", "/fr/"));
        }
      }
    }, (err) => {
      this.isLobChangeHomeContentReload = false;
      throw err;
    }));
    // For very first time, app init service will load the global content. This is to reload for any subsequent lob or locale changes
    this.contentService.fetchGlobalContent();
  }

  addStore() {
    console.log(JSON.stringify(this.appStore.state.user));
  }

  goToSignOn() {
    this.router.navigate(['/signon']);
  }

  

  getContentImageAndId(image) {
    this.contentImage = image + '?v=' + this.contentImgId;
    return this.contentImage;
  }
  goToCPICSignOn() {
    this.subscriptionService.passLob(Lob.CPIC);
    this.router.navigate(['/signon']);
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }

  registerOnline(lob?: Lob) {
    //this.subscriptionService.passLob(lob ? lob : this.appStore.lob);
    if (lob) {
      RegisterOnlineStore.crossFlow = { lob: lob };
    }
    this.commonService.callNonAPI("registerOnline/landing");
  }

  navigate(path) {
    this.router.navigate([path]);
  }

  newWindow(urlObj, omnitureTagging?: boolean) {

    if (omnitureTagging) {
      let data: IOmnitureAdsData = this.getAdsData();
      this.adsOmnitureTagging(data, false, true);
    }

    let url = undefined;
    let isWrapperApp = this.appStore.isApp('app');
    if (!urlObj || urlObj === undefined) {
      url = this.createDCOurl();
    }
    else {
      url = urlObj.url;
    }
    if (url && url !== '') {
      console.log('Opening new url = [' + url + ']');
      if (isWrapperApp) {
        console.log('Opening new url = [' + url + '] in new window');
        window.open(url, '_system', 'location=yes');
      }
      else {
        console.log('Opening new url = [' + url + '] in same window');
        window.location.href = url;
      }
    }
  }

  openWindowUrl(url) {
    window.open(url, '_system', 'location=yes, resizable=yes, scrollbars=yes');
  }

  createDCOurl() {

    //var currentLang = ele.scope().$root.lang;
    //let currentLang = //  this.persistenceService.cookieStoreRetrieve('MBI_LANG');
    let currentLang = this.appStore.lang;
    // var newIndex = (currentLang ==='fr') ? 'index-fr.html':'';
    let newIndex = ''; // Fixed the DCO Decouple - defect #6

    let newPathName = this.stripIndex(window.location.pathname);

    let org = window.location.origin;

    if (!org) {
      org = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    let newURL =
      org +  /* https://m.uat1.onlinebrokerage.cibc.com*/
      newPathName +  /* / or /www/ */
      newIndex + /* either french index or blank (which will default to index.html) */
      'dco/#/accountOpenIELogin/' +
      currentLang;
    return newURL;
  }

  stripIndex(pathName) {
    let lastForwardSlash = pathName.lastIndexOf('/');
    return pathName.substring(0, lastForwardSlash + 1);
  }

  newDynLinkWindow(params) {
    var newIndex = (params.LANG === 'fr') ? 'index-fr.html' : '';
    var newPathName = this.stripIndex(location.pathname);

    var newURL =
      location.origin +  /* https://m.uat1.onlinebrokerage.cibc.com*/
      newPathName +  /* / or /www/ */
      //newIndex + /* either french index or blank (which will default to index.html) */
      '#/home/' + params.LANG + '/' + params.LOB;

    // add sitePref param
    newURL += '?sitePref=fullSite';

    window.open(newURL, '_system', 'location=yes');
  }

  ngAfterViewInit() {
    if (this._commonService.isNotchPhone()) {
      if (this.document.getElementById('wrapper-signon-button')) {
        this.renderer.addClass(this.document.getElementById('wrapper-signon-button'), 'wrapper-signon-button-ios');
      }
    }
    if(!this.appStore.isApp()) {
      this.omnitureTagging();
    }
  }

  ngOnDestroy(): void {
    this.routePermissionService.enableToggleLocaleButton = false;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  omnitureTagging() {
    if ((window as any).TrackingEnabled) {
      let ads = [];
      if (Lob.IE === this.appStore.lob) {
        ads.push(this.getAdsData());
      }
      of(this.trackingService.tagPageActionWithAds(ads, true, false));
    }
  }

  adsOmnitureTagging(data: IOmnitureAdsData, isImpression: boolean, isClick: boolean) {
    if ((window as any).TrackingEnabled) {
      of(this.trackingService.tagOmnitureAdsAction(data, isImpression, isClick));
    }
  }

  getAdsData() {
    let data: IOmnitureAdsData = {
      trackingCode: "",
      location: "",
      type: ""
    };
    if (this.appStore.lang === Lang.EN) {
      data['trackingCode'] = "M710:55";
    } else {
      data['trackingCode'] = "M710:56";
    }
    data['location'] = "home-page-body";
    data['type'] = "mass-ad";

    return data;
  }

  openAccount() {
    if(this.appStore.isApp()) {
      window.open(this.content.text.accountOpenLink, '_system');
    } else {
       window.open(this.content.text.accountOpenLink, '_blank');
    }

  }

  oneTrust() {
    this.commonService.openOneTrustUI();
  }

}
