import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRouteResolve } from './strategies/app-route-resolve';
import { AppStore } from '../shared/models/app-store';
import { ContentService } from './services/content.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from './interceptors/app-http-interceptor';
import { FormsModule } from '@angular/forms';
import { SpinnerService } from './components/spinner/service/spinner.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CookieService } from 'ngx-cookie-service';
import { AppErrorHandler } from '../core/components/error/app-error-handler';
import { PersistenceService } from './services/persistence.service';
import { HamburgerMenuModule } from './components/hamburger-menu/hamburger-menu.module';
import { NavigationModule } from './components/navigation/navigation.module';
import { GlobalHeaderModule } from './components/global-header/global-header.module';
import { GlobalFooterModule } from './components/global-footer/global-footer.module';
import { SiteStore } from '../shared/models/site-store';
import { HttpService } from './services/http.service';
import { GlobalSignOffService } from 'src/app/core/services/global-signoff.service';
import { SidebarLauncherModule } from './components/sidebar-launcher/sidebar-launcher.module';

@NgModule({
  declarations: [
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers: [
    AppRouteResolve,
    AppStore,
    SiteStore,
    ContentService,
    CookieService,
    SpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
      deps: [SpinnerService, AppStore, PersistenceService, HttpService, GlobalSignOffService]
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    }
  ],
  exports: [
    SpinnerComponent,
    HamburgerMenuModule,
    NavigationModule,
    GlobalHeaderModule,
    GlobalFooterModule,
    SidebarLauncherModule
  ]
})
export class CoreModule { }
