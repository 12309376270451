<div [ngClass]="">
  <!-- <site-header *ngIf="isDataAvailable" [headerObj]='headerObj' (backfunction)="cancel()"></site-header> -->

  <!-- <ion-content *ngIf="isDataAvailable" id="container-postSignOn" [ngClass]="isDataAvailable ? 'has-header-' + lob : ''"
    class="" attr.aria-hidden="{{hidePage}}"> -->
  <section class='mainContent noPadding postSignOn-Content'>
    <section class="container-selectionContent">
      <div class="field">
        <div class="card account-block item-divider">
          <ul tappable class="account-selector totalFooter"
            [ngClass]="{'active': countryObjectParam && country.CountryCode == countryObjectParam.CountryCode}"
            (click)="selectCountry(country)" *ngFor="let country of countryListParam let j = index" id="select_country_list-{{j}}">
            <li tabindex="0">
              <!-- <span class="account S14" role="link">{{country.Desc[lang.toLowerCase()]}}</span> -->
              <span class="account S14" role="link">{{country.Desc['en']}}</span>
              <span class="account S14 " role="link">(+{{country.DialCode}})</span>
              <i class="fa fa-angle-right rightIcon" aria-hidden="true"></i>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
  <!-- </ion-content> -->
</div>