import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { MutualFundsConfirmStore } from '../data/mutual-funds-confirm-store';

@Injectable({
  providedIn: 'root'
})
export class MutualFundsConfirmService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (MutualFundsConfirmStore.params) {
      const ser = input[0];
      const req = ser.input;
      req.TradingPassword = MutualFundsConfirmStore.params.TradingPassword;
      req.SaveTradingPassword = MutualFundsConfirmStore.params.SaveTradingPassword;
      req.FundFactsAgreement = MutualFundsConfirmStore.params.FundFactsAgreement;
      req.ConsentToProceed = MutualFundsConfirmStore.params.ConsentToProceed;
      req.Token = MutualFundsConfirmStore.params.Token;
      return input;
    }
    return [];
  }
}
