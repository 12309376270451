import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from '../../../../../../src/app/shared/services/page-service';
import { AppStore } from '../../../../../../src/app/shared/models/app-store';
import { HttpService } from '../../../../../../src/app/core/services/http.service';



@Injectable({
  providedIn: 'root'
})
export class PdfErrorService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return undefined;
  }

}
