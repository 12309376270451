import { BaseData } from 'src/app/shared/models/base-data';

export class QuotesResearchData extends BaseData {

    constructor() {
        super();
    }
}

export class QRNotifyUserActivityData {
    ModuleName: string;
}