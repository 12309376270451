import { NgModule } from '@angular/core';
import { GenesysPostSignonChatComponent } from './components/chat/genesys/postsignon/genesys-postsignon-chat.component';
import { PipeSharedModule } from './pipe-shared.module';
import { IframeDragDirective } from './directive/iframe-drag-directive'
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    GenesysPostSignonChatComponent,
    IframeDragDirective
  ],
  imports: [
    CommonModule,
    PipeSharedModule
  ],
  exports: [
    GenesysPostSignonChatComponent
  ]
})
export class ChatSharedModule { }
