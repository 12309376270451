import { Injectable, Inject } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { _User } from '../../../models/user';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { ObservableSubscriptionService } from 'src/app/shared/services/observable-subscription.service';
import { GlobalSignOffService } from '../../../../core/services/global-signoff.service';
import { AppStore } from 'src/app/shared/models/app-store';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { isDefined } from 'src/app/shared/services/utils.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({ providedIn: 'root' })
export class ModalService {
  error: any;
  focusableEls: any;
  eachOpenModalModifiedELs: any = [];
  currentModal: string;
  private currentZIndexValue = 902;
  private modals: any[] = [];
  focusableElementQuery;
  initialWindowWidth: number;
  isCallFromModalOpen: boolean;
  private modalLog: string[] = [];
  public trappedQT: boolean = false;

  constructor(
    private httpService: HttpService,
    private commonService: CommonService,
    private globalSignOffService: GlobalSignOffService,
    private subscriptionService: ObservableSubscriptionService,
    private appStore: AppStore,
    private persistenceService: PersistenceService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.modalLog = [];
    let self = this;
    // setInterval(() => {
    //   if (self.document.body.style.overflow == 'hidden' && self.modalLog.length > 0) {
    //     let isNormal = false;

    //     let modals = self.document.getElementsByTagName('app-modal');
    //     for (let i = 0; i < modals.length; i++) {
    //       let item: any = modals.item(i);
    //       if (item.style.display != 'none') {
    //         isNormal = true;
    //       }
    //     }

    //     if (self.document.getElementsByTagName('app-hamburger-menu').length > 0) {
    //       isNormal = true;
    //     }

    //     const detectMobileIOS = this.getMobileOperatingSystem();
    //     if (this.trappedQT && (detectMobileIOS === 'Android' || detectMobileIOS === 'iOS') && this.commonService.isDesktop()) {
    //       isNormal = true;
    //     }

    //     let headerSearch: any = self.document.getElementsByClassName('header-grey-cover');
    //     if (headerSearch.length > 0) {
    //       isNormal = true;
    //     }

    //     if (!isNormal) {
    //       let stack: string = "";
    //       for (let i = 0; i < self.modalLog.length; i++) {
    //         stack += self.modalLog[i];
    //       }

    //       stack = (stack).replace(/-/g, '__');

    //       let payload = {
    //         LogType: "CLIENT",
    //         Message: "SCROLLBAR HIDDEN",
    //         PageName: (window.location.href).replace("https", "htps"),
    //         Stack: stack
    //       }
    //       self.httpService.post(gatewayConfig.APIServices.logException.url, payload, { params: { skiploading: 'true' } }).subscribe(
    //         (data: any) => {
    //           console.log("------- Logged Error -------");
    //         },
    //         (err) => {
    //         });
    //       self.modalLog = [];
    //     }
    //   }
    // }, (window as any).ScrollbarLoggingDelay ? (window as any).ScrollbarLoggingDelay : 500);
  }

  addLog(log: string) {
    this.modalLog.push(log);
    if (this.modalLog.length > 20) {
      this.modalLog.shift();
    }
  }

  getZIndexValue() {
    return this.currentZIndexValue;
  }

  setZIndexValue(zIndex: number) {
    this.currentZIndexValue = zIndex;
  }
  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // close before removal
    const modal: any = this.modals.filter((x) => x.id === id)[0];
    if (this.currentModal && modal) {
      this.close(id, null, true);
    }
    // remove modal from array of active modals
    this.modals = this.modals.filter((x) => x.id !== id);
    if (modal) {
      this.document.body.removeChild(modal.element);
    }
  }

  open(id: string, targetElement?: string) {
    this.addLog(Date.now().toString() + ": Open : " + id + "::");
    let parentModal;
    if (this.currentModal) {
      parentModal = this.modals.filter(
        (x) => x.id === this.currentModal
      )[0];
      if (parentModal) {
        parentModal.ariaModalAttribute = false;
      }
    }
    // skip opening if the modal is already open (safeguard against the user somehow double tapping an open button)
    if (parentModal && id == parentModal.id && parentModal.el.nativeElement.style.display != 'none') {
      this.addLog(Date.now().toString() + ": Already Open : " + id + "::");
      return;
    }
    this.initialWindowWidth = window.innerWidth;
    this.document.body.style.overflow = 'hidden';
    const os = this.getMobileOperatingSystem();
    this.focusableElementQuery =
      'a[href]:not([disabled]),' +
      'button:not([disabled]),textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="password"]:not([disabled]),' +
      'input[type="radio"]:not([disabled]), input[type="number"]:not([disabled]),' +
      'input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="tel"]:not([disabled])';
    if (os === 'Android' || os === 'iOS') {
      // to fix background scrolling in devices
      document.body.style.position = 'fixed';
    }
    // open modal specified by id
    const modal: any = this.modals.filter((x) => x.id === id)[0];
    modal.ariaModalAttribute = true;
    this.currentZIndexValue = this.currentZIndexValue + 2;
    modal.open();

    const focusObservable$ = this.setFocus(targetElement);
    // if parent modal exist
    const subscriber = focusObservable$.subscribe(() => {
      if (parentModal) {
        this.addLog(Date.now().toString() + ": Open Parent : " + parentModal.id + " : " + id + "::");
        parentModal.el.nativeElement.ariaHidden = true;
        this.subscriptionService.modalTrapFocus(this.currentModal);
        // if parent modal doesn't exist
      } else {
        this.addLog(Date.now().toString() + ": Open No Parent::");
        // this.updateTabIndex('-1');
        const desktopContainer = this.document.getElementById(
          'page-container'
        );
        desktopContainer
          ? desktopContainer.setAttribute('aria-hidden', 'true')
          : '';
        this.subscriptionService.modalTrapFocus('page-container');
      }
      /* creating an array to keep track of all focusable
elements in current modal and the parentModal if exist */
      this.eachOpenModalModifiedELs[id] = {
        focusableElements: [],
        parentModal: this.currentModal,
      };
      // set TabIndex -1 behind the modals
      this.setTabIndex(id, this.currentModal, true);
      subscriber.unsubscribe();
    });
  }

  setTabIndex(id, previousModalName: string, isCallFromModalOpen: boolean) {
    this.isCallFromModalOpen = isCallFromModalOpen;
    // Call from resize event then additional focusable elements should be added in first modals array
    if (!isCallFromModalOpen) {
      for (const keys in this.eachOpenModalModifiedELs) {
        if (
          this.eachOpenModalModifiedELs.hasOwnProperty(keys) &&
          !this.eachOpenModalModifiedELs[keys].parentModal
        ) {
          id = keys;
        }
      }
    }

    if (this.currentModal || isCallFromModalOpen) {
      this.commonService.setTabIndex(
        id,
        previousModalName,
        this.eachOpenModalModifiedELs,
        this.updateCurrentModalId,
        'app-modal',
        this
      );
    }
  }

  // passing callback function in tabIndex
  updateCurrentModalId(currentThis, id: string) {
    currentThis.currentModal = currentThis.isCallFromModalOpen
      ? id
      : currentThis.currentModal;
  }

  close(id: string, targetElement?: string, destroyed: boolean = false) {
    this.addLog(Date.now().toString() + ": Close : " + destroyed + " : " + id + "::");
    const os = this.getMobileOperatingSystem();
    if ((os === 'Android' || os === 'iOS' ) && !document.body.classList.contains('featurehighlight-open')) {
      // to fix background scrolling in devices
      document.body.style.position = 'initial';
    }
    // close modal specified by id
    const modal: any = this.modals.filter((x) => x.id === id)[0];
    if (modal) {
      this.addLog(Date.now().toString() + ": Close Exists::");
      modal.ariaModalAttribute = false;
      // this.updateTabIndex('0');
      if (this.eachOpenModalModifiedELs[id]) {
        this.addLog(Date.now().toString() + ": Close Open::");
        if (this.eachOpenModalModifiedELs[id].parentModal) {
          const parentModal: any = this.modals.filter(
            (x) =>
              x.id ===
              this.eachOpenModalModifiedELs[id].parentModal
          )[0];
          // if the parentmodal isn't actually still open, or somehow the parent matches the child, then do a normal close rather than parent close
          if (isDefined(parentModal) && isDefined(parentModal.el) && isDefined(parentModal.el.nativeElement) && 
            parentModal.el.nativeElement.style.display != 'none' && parentModal.id != id) {
            this.addLog(Date.now().toString() + ": Close Parent : " + parentModal.id + " : " + id + "::");
            if (this.eachOpenModalModifiedELs[id].focusableElements) {
              parentModal.ariaModalAttribute = true;
              parentModal.el.nativeElement.ariaHidden = false;
            }
            parentModal.resetBackButtonFn();
            this.subscriptionService.modalUnTrapFocus(id);
          } else {
            if (isDefined(parentModal)) {
              this.addLog(Date.now().toString() + ": Close Parent Mistake : " + parentModal.id + "::");
            } else {
              this.addLog(Date.now().toString() + ": Close Parent Mistake : null parent::");
            }
            this.eachOpenModalModifiedELs[id].parentModal = null;
            this.closeNonParent();
          }
        } else {
          this.closeNonParent();
        }
        this.commonService.undoTrapFocus(
          this.eachOpenModalModifiedELs[id].focusableElements,
          id
        );
        if (
          this.eachOpenModalModifiedELs &&
          this.eachOpenModalModifiedELs[id]
        ) {
          this.currentModal = this.eachOpenModalModifiedELs[
            id
          ].parentModal;
          delete this.eachOpenModalModifiedELs[id];
        }
        this.currentZIndexValue = this.currentZIndexValue - 2;
        modal.close();
        /* this.document.getElementById('modal-background-color').style.zIndex = this.currentZIndexValue - 1 + '';*/
        const subscription = this.setFocus(
          targetElement
        ).subscribe(() => subscription.unsubscribe());
      }
    }
  }

  closeNonParent() {
    const desktopContainer = this.document.getElementById(
      'page-container'
    );
    if (this.commonService.isHamburgerOpen) {
      this.addLog(Date.now().toString() + ": Close Modal Hamburger::");
      // removing aria attribute for hamburger to handle
      desktopContainer
        ? desktopContainer.removeAttribute('aria-hidden')
        : '';
    } else {
      this.addLog(Date.now().toString() + ": Close Fin::");
      desktopContainer
        ? desktopContainer.setAttribute(
          'aria-hidden',
          'false'
        )
        : '';
      this.subscriptionService.modalUnTrapFocus(
        'page-container'
      );
      if (!document.body.classList.contains('featurehighlight-open')) {
        this.document.body.style.overflow = 'auto';
        setTimeout(() => {
          this.document.body.style.overflow = 'auto';
        }, 200);
      }
      }
  }

  // removing tabIndex -1
  undoTrapFocus(currentEls: any, id?: string) {
    // eslint-disable-next-line guard-for-in
    for (const property in currentEls) {
      if (currentEls[property] instanceof HTMLElement) {
        currentEls[property].removeAttribute('tabindex');
      }
    }
    if (
      this.eachOpenModalModifiedELs &&
      this.eachOpenModalModifiedELs[id]
    ) {
      this.currentModal = this.eachOpenModalModifiedELs[id].parentModal;
      delete this.eachOpenModalModifiedELs[id];
    }
  }

  // Api call to extend session for another 10 minutes.
  extendSession() {
    let req: any = new Object();
    req = {};
    this.httpService.post(gatewayConfig.APIServices.getSiteStateExtendSession.url, req).subscribe(
      (res) => { },
      (err) => {
        throw err;
      }
    );
    this.close('extendsession');
  }

  // Navigate to sign off page which will an Api call for sign off
  modalSignOff(sessionTimeout?: boolean) {
    this.close('extendsession');
    this.globalSignOffService.signOff(sessionTimeout);
  }

  updateTabIndex(val) {
    const all = this.document.getElementsByTagName('*');
    for (let i = 0, max = all.length; i < max; i++) {
      if (
        all[i].getAttribute('tabindex') === '0' ||
        all[i].getAttribute('tabindex') === '-1'
      ) {
        all[i].setAttribute('tabindex', val);
      }
      // if (all[i].getAttribute('role') == 'link' || all[i].getAttribute('role') == 'Abstract' ) {
      //   console.log(all[i].id);
      //   all[i].setAttribute('role', val=='0'?'link':'Abstract');
      // }
    }
  }

  setFocus(targetElementId) {
    const focusObservanle$ = new Observable((observer) => {
      if (targetElementId) {
        const targetElement = this.document.getElementById(
          targetElementId
        );
        window.requestAnimationFrame(() => {
          if (targetElement) {
            targetElement.focus();
          } else {
            console.log(targetElementId);
          }
          observer.next('');
        });
      }
    });
    return focusObservanle$;
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    return 'unknown';
  }
}
