import { Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { AppStore } from 'src/app/shared/models/app-store';
import { isDefined } from 'src/app/shared/services/utils.service';

export let preTxnPermissionGuard = function checkPermission(...permissions: string[]): Type<{
    canActivate: CanActivateFn;
}> {
    @Injectable({ providedIn: 'root' })
    class RoutePermissionGuardPreTxn  {

        constructor(
            private appstore: AppStore,
            private router: Router
        ) { }

        canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
            | Promise<boolean>
            | boolean {
            let ret = this.isPermission(permissions)
            console.log(`checking access for ${permissions.join(', ')}. Access allowed? : ${ret}`);
            return ret;
        }

        isPermission(name) {
            let refresh = this.appstore.state.state.refreshURL;
            let currentURL = window.location.hash.substring(1, window.location.hash.length);
            if (this.appstore.state.state.refresh && isDefined(refresh) && refresh === currentURL) {
                return true;
            }

            //this is when you opening the url eg: braodcast,changetemppassword in new tab/bookmark
            if (this.appstore.state.state.refresh && refresh === undefined) {
                this.router.navigate(['/home']);
                return false;
            }

            if (isDefined(this.appstore.preTxnPagePermission)
                && this.appstore.preTxnPagePermission !== null
                && this.appstore.preTxnPagePermission === name[0]) {
                return true;
            } else {
                return false;
            }
        }
    }
    return RoutePermissionGuardPreTxn;
};

