import { BaseData } from "src/app/shared/models/base-data";


export class W8BenVerifyData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountOwnerName: '',
            citizenshipCountry: '',
            permanentAddress: '',
            useDifferentMailingAddressCheckbox: false,
            isCanadianMailingAddress: '',
            mailingAddress: '',
            city: '',
            mailingAddressCountry: '',
            unit: '',
            province: '',
            postalCode: '',
            beneficialOwnerCountry: '',
            certifyCheckbox: false
        }
    }

}

export interface W8BenVerifyFormInputs {
    accountOwnerName: string;
    citizenshipCountry: string;
    permanentAddress: string;
    useDifferentMailingAddressCheckbox: boolean;
    mailingAddress: string;
    cityOrProvince: string;
    mailingAddressCountry: string;
    beneficialOwnerCountry: string;
    certifyCheckbox: boolean;
    isCanadianMailingAddress: boolean;
    city: string,
    unit: string,
    province: string,
    postalCode: string
}

export const CONSTANTS = {
    CANADA: 'Canada',
    VERIFY: 'VERIFY',
    SUBMIT: 'SUBMIT'
}

export interface W8BenVerifyForm {
    WebFormName: string,
    Stage: string,
    Parameters?: {
        param1?: string, //FirstName
        param2?: string, //LastName
        param3?: string, //citizenshipCountry
        param4?: string, //permanent addressLine1
        param5?: string, //permanent address city or Province
        param6?: string, //permanent address postal code
        param7?: string, //permanent address country
        param8?: string, //mailing address line1
        param9?: string, // mailing address city or province
        param10?: string, // mailing address postal code
        param11?: string, // mailing address country
        param12?: string, // sin number
        param13?: string, // Date of birth
        param14?: string, // phonenumber
        param15?: string // Account Number list
    }
}

export const ParamsMapping = {
    Param1: "Param1",
    Param2: "Param2",
    Param3: "Param3",
    Param4: "Param4",
    Param5: "Param5",
    Param6: "Param6",
    Param7: "Param7",
    Param8: "Param8",
    Param9: "Param9",
    Param10: "Param10",
    Param11: "Param11",
    Param12: "Param12",
    Param13: "Param13",
    Param14: "Param14",
    Param15: "Param15",
    Param16: "Param16"
}

