import { Injectable } from '@angular/core';
import { UserPreferencesData } from './userPreferences-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class UserPreferencesStore extends PageStore<UserPreferencesData> {

    constructor() {
        super(new UserPreferencesData());
    }

}
