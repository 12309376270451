import { RouteGroup } from '../shared/models/route-config';

export const userPreferences_config: RouteGroup = {

  '/txn/userPreferences': {
    name: 'UserPreferences',
    service: 'UserPreferencesService',
    store: 'UserPreferencesStore',
    resolveContent: 'userpreferences',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      name: "preferences",
      nameGA: "Preferences",
      events: { pageView: true },
    },
    brazeID: "viewed_user_preferences"
  },
  '/txn/userPreferences/userIDChange/landing': {
    name: 'ChangeUserIDLanding',
    service: 'ChangeUserIDLandingService',
    store: 'ChangeUserIDLandingStore',
    resolveContent: 'changeuseridlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'getCurrentUserId',
        restUrl: 'getCurrentUserId',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, security, change-user-id",
      name: "details",
      nameGA: "Change User ID",
      formInfo: {
        name: "obr-change-user-id",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  '/txn/userPreferences/userIDChange/confirm': {
    name: 'ChangeUserIDConfirm',
    service: 'ChangeUserIDConfirmService',
    store: 'ChangeUserIDConfirmStore',
    resolveContent: 'changeuseridconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/userIDChange/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'updateUserId',
        restUrl: 'updateUserId',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, security, change-user-id",
      name: "confirmation",
      nameGA: "Preferences - Change User ID Confirmation",
      formInfo: {
        name: "obr-change-user-id",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    },
    brazeID: "action_change_user_id_confirmed"
  },
  '/txn/userPreferences/changeSignOnPassword/landing': {
    name: 'ChangeSignOnPasswordLanding',
    service: 'ChangeSignOnPasswordLandingService',
    store: 'ChangeSignOnPasswordLandingStore',
    resolveContent: 'changesignonpasswordlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "preferences, security, change-sign-on-password",
      name: "details",
      nameGA: "Preferences - Change Sign On Password",
      formInfo: {
        name: "obr-change-sign-on-password",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  '/txn/userPreferences/changeSignOnPassword/confirm': {
    name: 'ChangeSignOnPasswordConfirm',
    service: 'ChangeSignOnPasswordConfirmService',
    store: 'ChangeSignOnPasswordConfirmStore',
    resolveContent: 'changesignonpasswordconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/changeSignOnPassword/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
    ],
    trackingData: {
      hierarchy: "preferences, security, change-sign-on-password",
      name: "confirmation",
      nameGA: "Preferences - Change Sign On Password Confirmation",
      formInfo: {
        name: "obr-change-sign-on-password",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    },
    brazeID: "action_change_sign_on_password_confirmed"
  },
  '/txn/userPreferences/changeTradingPassword/landing': {
    name: 'ChangeTradingPasswordLanding',
    service: 'ChangeTradingPasswordLandingService',
    store: 'ChangeTradingPasswordLandingStore',
    resolveContent: 'changetradingpasswordlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "preferences, security, change-trading-password",
      name: "details",
      nameGA: "Preferences - Change Trading Password",
      formInfo: {
        name: "obr-change-trading-password",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    },
    brazeID: "viewed_change_trading_password_details"
  },
  '/txn/userPreferences/changeTradingPassword/confirm': {
    name: 'ChangeTradingPasswordConfirm',
    service: 'ChangeTradingPasswordConfirmService',
    store: 'ChangeTradingPasswordConfirmStore',
    resolveContent: 'changetradingpasswordconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/changeTradingPassword/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'updateTradingPassword',
        restUrl: 'updateTradingPassword',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, security, change-trading-password",
      name: "confirmation",
      nameGA: "Preferences - Change Trading Password Confirmation",
      formInfo: {
        name: "obr-change-trading-password",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    },
    brazeID: "action_change_trading_password_confirmed"
  },
  '/txn/userPreferences/forgotTradingPassword/landing': {
    name: 'ForgotTradingPasswordLanding',
    service: 'ForgotTradingPasswordLandingService',
    store: 'ForgotTradingPasswordLandingStore',
    resolveContent: 'forgottradingpasswordlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "preferences, security, forgot-trading-password",
      name: "details",
      nameGA: "Preferences - Forgot Trading Password",
      formInfo: {
        name: "obr-forgot-trading-password",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    },
    brazeID: "viewed_forgot_trading_password_details"
  },
  '/txn/userPreferences/forgotTradingPassword/confirm': {
    name: 'ForgotTradingPasswordConfirm',
    service: 'ForgotTradingPasswordConfirmService',
    store: 'ForgotTradingPasswordConfirmStore',
    resolveContent: 'forgottradingpasswordconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/forgotTradingPassword/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'updateForgotTradingPassword',
        restUrl: 'updateForgotTradingPassword',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, security, forgot-trading-password",
      name: "confirmation",
      nameGA: "Preferences - Forgot Trading Password Confirmation",
      formInfo: {
        name: "obr-forgot-trading-password",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    },
    brazeID: "action_new_trading_password_confirmed"
  },

  //  Forced Migration Update Password Requirement-Pending
  // '/txn/userPreferences/updatePassword/landing': {
  //   name: 'UpdatePasswordLanding',
  //   service: 'UpdatePasswordLandingService',
  //   store: 'UpdatePasswordLandingStore',
  //   resolveContent: 'updatePasswordLanding',
  //   policy: {
  //     navFrom: {
  //       url: ['*'],
  //       fallBackUrl: ''
  //     },
  //     navTo: {
  //       url: ['*'],
  //       fallBackUrl: ''
  //     }
  //   },
  //   restService: [],
  //   trackingData: {
  //     hierarchy: "preferences, security, update-password",
  //     name: "details",
  //     formInfo: {
  //       name: "obr-update-password",
  //       stepName: "details",
  //     },
  //     events: { formStep: true, formView: true, pageView: true },
  //   }
  // }, 
  // '/txn/userPreferences/updatePassword/confirm': {
  //   name: 'UpdatePasswordConfirm',
  //   service: 'UpdatePasswordConfirmService',
  //   store: 'UpdatePasswordConfirmStore',
  //   resolveContent: 'updatePasswordConfirm',
  //   policy: {
  //     navFrom: {
  //       url: ['/txn/userPreferences/updatePassword/landing'],
  //       fallBackUrl: ''
  //     },
  //     navTo: {
  //       url: ['*'],
  //       fallBackUrl: ''
  //     }
  //   },
  //   restService: [
  //   ],
  //   trackingData: {
  //     hierarchy: "preferences, security, update-password",
  //     name: "confirmation",
  //     formInfo: {
  //       name: "obr-update-password",
  //       stepName: "confirmation",
  //     },
  //     events: { formStep: true, formSubmit: true, pageView: true },
  //   }
  // },
  '/txn/userPreferences/setUpTradeFillAlert/landing': {
    name: 'SetUpTradeFillAlertsLanding',
    service: 'SetUpTradeFillAlertsLandingService',
    store: 'SetUpTradeFillAlertsLandingStore',
    resolveContent: 'setuptradefillalertslanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'getSavedTradedAlerts',
        restUrl: 'getSavedTradedAlerts',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, alerts, manage-trade-fill-alerts",
      name: "details",
      nameGA: "Preferences - Manage Trade Fill Alerts",
      formInfo: {
        name: "obr-manage-trade-fill-alerts",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  '/txn/userPreferences/setUpTradeFillAlert/confirm': {
    name: 'SetUpTradeFillAlertsConfirm',
    service: 'SetUpTradeFillAlertsConfirmService',
    store: 'SetUpTradeFillAlertsConfirmStore',
    resolveContent: 'setuptradefillalertsconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/setUpTradeFillAlert/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'savedTradedAlerts',
        restUrl: 'saveTradeAlerts',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, alerts, manage-trade-fill-alerts",
      name: "confirmation",
      nameGA: "Preferences - Manage Trade Fill Alerts Confirmation",
      formInfo: {
        name: "obr-manage-trade-fill-alerts",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    },
    brazeID: "action_manage_trade_fill_alerts_confirmed"
  },
  '/txn/userPreferences/exchangeAgreements/landing': {
    name: 'ExchangeAgreementsLanding',
    service: 'ExchangeAgreementsLandingService',
    store: 'ExchangeAgreementsLandingStore',
    resolveContent: 'exchangeAgreementsLanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'ExchangeAgreementsSummary',
        restUrl: 'exchangeAgreementsInit',
        get request(): any {
          return {};
        }
      },
    ],
    trackingData: {
      hierarchy: "preferences",
      name: "exchange-agreements",
      nameGA: "Preferences - Exchange Agreements",
      events: { pageView: true },
    }
  },
  '/txn/userPreferences/cegAgreement': {
    name: 'CegAgreement',
    service: 'CegAgreementService',
    store: 'CegAgreementStore',
    resolveContent: 'cegAgreement',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
    ],
    trackingData: {
      hierarchy: "preferences, exchange-agreements, ceg-agreement",
      name: "details",
      nameGA: "Preferences - CEG Agreement",
      formInfo: {
        name: "obr-ceg-agreement",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  '/txn/userPreferences/nyAgreement': {
    name: 'NyAgreement',
    service: 'NyAgreementService',
    store: 'NyAgreementStore',
    resolveContent: 'nyagreement',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'UserData',
        restUrl: 'exchangeAgreementsUserData',
        optional: true,
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, exchange-agreements, nyse-agreement",
      name: "details",
      nameGA: "Preferences - NY Agreement",
      formInfo: {
        name: "obr-nyse-agreement",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  '/txn/userPreferences/nasdaqAgreement': {
    name: 'NasdaqAgreement',
    service: 'NasdaqAgreementService',
    store: 'NasdaqAgreementStore',
    resolveContent: 'nasdaqagreement',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'Title',
        restUrl: 'dataList',
        optional: true,
        get request(): any {
          return {
            DataType: 'dropdown',
            DataField: 'titles'
          };
        }
      },
      {
        id: 'UserData',
        restUrl: 'exchangeAgreementsUserData',
        optional: true,
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, exchange-agreements, nasdaq-agreement",
      name: "details",
      nameGA: "Preferences - NASDAQ Agreement",
      formInfo: {
        name: "obr-nasdaq-agreement",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  '/txn/userPreferences/opraAgreement': {
    name: 'OpraAgreement',
    service: 'OpraAgreementService',
    store: 'OpraAgreementStore',
    resolveContent: 'opraagreement',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'UserData',
        restUrl: 'exchangeAgreementsUserData',
        optional: true,
        get request(): any {
          return {
          };
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, exchange-agreements, opra-agreement",
      name: "details",
      nameGA: "Preferences - OPRA Agreement",
      formInfo: {
        name: "obr-opra-agreement",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },

  '/txn/userPreferences/relateBrokerageAccounts/landing': {
    name: 'RelateBrokerageAccountsLanding',
    service: 'RelateBrokerageAccountsLandingService',
    store: 'RelateBrokerageAccountsLandingStore',
    resolveContent: 'relatebrokerageaccountslanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'getUserBrokerageAccounts',
        restUrl: 'getUserBrokerageAccounts',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, account, link-brokerage-accounts",
      name: "details",
      nameGA: "Preferences - Link Brokerage Accounts",
      formInfo: {
        name: "obr-link-brokerage-accounts",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    },
    brazeID: "viewed_link_brokerage_accounts_details"
  },
  '/txn/userPreferences/relateBrokerageAccounts/confirm': {
    name: 'RelateBrokerageAccountsConfirm',
    service: 'RelateBrokerageAccountsConfirmService',
    store: 'RelateBrokerageAccountsConfirmStore',
    resolveContent: 'relatebrokerageaccountsconfirm',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'relateUserBrokerageAccounts',
        restUrl: 'relateUserBrokerageAccounts',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, account,  link-brokerage-accounts",
      name: "confirmation",
      nameGA: "Preferences - Link Brokerage Accounts Confirmation",
      formInfo: {
        name: "obr-link-brokerage-accounts",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    }
  },
  '/txn/userPreferences/removeBrokerageAccounts/landing': {
    name: 'RemoveBrokerageAccountsLanding',
    service: 'RemoveBrokerageAccountsLandingService',
    store: 'RemoveBrokerageAccountsLandingStore',
    resolveContent: 'removebrokerageaccountslanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'getUserBrokerageAccounts',
        restUrl: 'getUserBrokerageAccounts',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, account, unlink-brokerage-accounts",
      name: "details",
      nameGA: "Preferences - Remove Linked Brokerage Accounts",
      formInfo: {
        name: "obr-unlink-brokerage-accounts",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  '/txn/userPreferences/removeBrokerageAccounts/verify': {
    name: 'RemoveBrokerageAccountsVerify',
    service: 'RemoveBrokerageAccountsVerifyService',
    store: 'RemoveBrokerageAccountsVerifyStore',
    resolveContent: 'removebrokerageaccountsverify',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/removeBrokerageAccounts/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'removeUserBrokerageAccounts',
        restUrl: 'verifyRemoveUserBrokerageAccounts',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, account, unlink-brokerage-accounts",
      name: "verification",
      nameGA: "Preferences - Remove Linked Brokerage Accounts Verification",
      formInfo: {
        name: "obr-unlink-brokerage-accounts",
        stepName: "verification",
      },
      events: { formStep: true, pageView: true },
    }
  },
  '/txn/userPreferences/removeBrokerageAccounts/confirm': {
    name: 'RemoveBrokerageAccountsConfirm',
    service: 'RemoveBrokerageAccountsConfirmService',
    store: 'RemoveBrokerageAccountsConfirmStore',
    resolveContent: 'removebrokerageaccountsconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/removeBrokerageAccounts/verify'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'removeUserBrokerageAccounts',
        restUrl: 'removeUserBrokerageAccounts',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, account,  unlink-brokerage-accounts",
      name: "confirmation",
      nameGA: "Preferences - Remove Linked Brokerage Accounts Confirmation",
      formInfo: {
        name: "obr-unlink-brokerage-accounts",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    }
  },
  '/txn/userPreferences/changeMailingOptions/landing': {
    name: 'ChangeMailingOptionsLanding',
    service: 'ChangeMailingOptionsLandingService',
    store: 'ChangeMailingOptionsLandingStore',
    resolveContent: 'changemailingoptionslanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'getMailingOptions',
        restUrl: 'getMailingOption',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, documents, change-mailing-options",
      name: "details",
      nameGA: "Preferences - Change Mailing Options",
      formInfo: {
        name: "obr-change-mailing-options",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    },
    brazeID: "viewed_change_mailing_options_details"
  },
  '/txn/userPreferences/changeMailingOptions/verify': {
    name: 'ChangeMailingOptionsVerify',
    service: 'ChangeMailingOptionsVerifyService',
    store: 'ChangeMailingOptionsVerifyStore',
    resolveContent: 'changemailingoptionsverify',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/changeMailingOptions/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/txn/userPreferences/changeMailingOptions/landing'
    },
    restService: [
      {
        id: 'verifyMailingOptions',
        restUrl: 'verifyMailingOptions',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, documents, change-mailing-options",
      name: "verification",
      nameGA: "Preferences - Change Mailing Options Verification",
      formInfo: {
        name: "obr-change-mailing-options",
        stepName: "verification",
      },
      events: { formStep: true, pageView: true },
    },
    brazeID: "viewed_change_mailing_options_verify"
  },
  '/txn/userPreferences/changeMailingOptions/confirm': {
    name: 'ChangeMailingOptionsConfirm',
    service: 'ChangeMailingOptionsConfirmService',
    store: 'ChangeMailingOptionsConfirmStore',
    resolveContent: 'changemailingoptionsconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/changeMailingOptions/verify'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'updateMailingOptions',
        restUrl: 'updateMailingOptions',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, documents, change-mailing-options",
      name: "confirmation",
      nameGA: "Preferences - Change Mailing Options Confirmation",
      formInfo: {
        name: "obr-change-mailing-options",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    },
    brazeID: "action_change_mailing_options_confirmed"
  },
  '/txn/userPreferences/connectCIBCOnline/landing': {
    name: 'ConnectCIBCOnlineLanding',
    service: 'ConnectCIBCOnlineLandingService',
    store: 'ConnectCIBCOnlineLandingStore',
    resolveContent: 'removeconnectCIBConlinelanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "preferences,account",
      name: "manage-banking-connection",
      nameGA: "Preferences - Manage Banking Connection",
      events: { pageView: true },
    }
  },
  '/txn/userPreferences/connectCIBCOnline/verify': {
    name: 'ConnectCIBCOnlineVerify',
    service: 'ConnectCIBCOnlineVerifyService',
    store: 'ConnectCIBCOnlineVerifyStore',
    resolveContent: 'removeCIBConlineverify',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/connectCIBCOnline/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "preferences,account,remove-banking-connection",
      name: "verification",
      nameGA: "Preferences - Manage Banking Connection Verification",
      formInfo: {
        name: "obr-remove-banking-connection",
        stepName: "verification",
      },
      events: { formStep: true, formView: true, pageView: true },
    },
    brazeID: "viewed_remove_banking_connection_verify"
  },
  '/txn/userPreferences/connectCIBCOnline/confirm': {
    name: 'ConnectCIBCOnlineConfirm',
    service: 'ConnectCIBCOnlineConfirmService',
    store: 'ConnectCIBCOnlineConfirmStore',
    resolveContent: 'removeCIBConlineconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/connectCIBCOnline/verify'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      hierarchy: "preferences,account,remove-banking-connection",
      name: "confirmation",
      nameGA: "Preferences - Manage Banking Connection Confirmation",
      formInfo: {
        name: "obr-remove-banking-connection",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    },
    brazeID: "action_remove_banking_connection_confirmed"
  },
  '/txn/userPreferences/nicknameAccounts/landing': {
    name: 'NicknameAccountssLanding',
    service: 'NicknameAccountsLandingService',
    store: 'NicknameAccountsLandingStore',
    resolveContent: 'nicknameaccountslanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'getUserAccountNickNames',
        restUrl: 'getUserAccountNickNames',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences,account,manage-account-nicknames",
      name: "details",
      nameGA: "Preferences - Manage Account Nicknames",
      formInfo: {
        name: "obr-manage-account-nicknames",
        stepName: "details",
      },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  '/txn/userPreferences/nicknameAccounts/confirm': {
    name: 'NicknameAccountsConfirm',
    service: 'NicknameAccountsConfirmService',
    store: 'NicknameAccountsConfirmStore',
    resolveContent: 'nicknameaccountsconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/nicknameAccounts/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'setUserAccountNickNames',
        restUrl: 'setUserAccountNickNames',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      hierarchy: "preferences, account, manage-account-nicknames",
      name: "confirmation",
      nameGA: "Preferences - Manage Account Nicknames Confirmation",
      formInfo: {
        name: "obr-manage-account-nicknames",
        stepName: "confirmation",
      },
      events: { formStep: true, formSubmit: true, pageView: true },
    },
    brazeID: "action_change_nicknames_confirmed"
  },
  '/txn/userPreferences/touchid/landing': {
    name: 'TouchIDLanding',
    service: 'TouchIDLandingService',
    store: 'TouchIDLandingStore',
    resolveContent: 'touchidlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      name: "touch-id",
      nameGA: "Preferences - Touch ID",
      events: { pageView: true },
    },
  },
  '/txn/userPreferences/touchid/confirm': {
    name: 'TouchIDConfirm',
    service: 'TouchIDConfirmService',
    store: 'TouchIDConfirmStore',
    resolveContent: 'touchidconfirm',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'biometricInit',
        restUrl: 'biometricInit',
        get request(): any {
          return {};
        }
      }
    ],
    trackingData: {
      name: "touch-id-confirmation",
      nameGA: "Preferences - Touch ID Confirmation",
      events: { pageView: true },
    },
  },
  '/txn/userPreferences/link-bank-accounts/landing': {
    name: 'linkbankaccountslanding',
    service: 'UserPreferencesService',
    store: 'LinkBankAccountsLandingStore',
    resolveContent: 'linkbankaccountslanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [{
      id: 'init',
      restUrl: 'webFormInit',
      get request(): any {
        return {
          WebFormName: "bankingInfoUpdate",
        };
      }
    }],
    brazeID: "viewed_link_bank_accounts",
    trackingData: {
      name: "details",
      nameGA: "Preferences - Link a bank account",
      hierarchy: "link-bank-account",
      events: { pageView: true }
    }
  },
  '/txn/userPreferences/manage-trusted-contact-person/landing': {
    name: 'trustedcontactpersonlanding',
    service: 'UserPreferencesService',
    store: 'ManangeTrustedContactPersonLandingStore',
    resolveContent: 'trustedcontactpersonlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'manageTcpInit',
        //restUrl: 'webFormInit',
        get request(): any {
          return {
            //WebFormName: "trustedContactPerson"
          };
        }
      }
    ],
    trackingData: {
      nameGA: "Preferences - Trusted Contact Person",
      name: "details",
      events: { pageView: true }
    }
  },
  // '/txn/userPreferences/manage-trusted-contact-person/confirm': {
  //   name: 'trustedcontactpersonconfirm',
  //   service: 'UserPreferencesService',
  //   store: 'ManageTrustedContactPersonConfirmStore',
  //   resolveContent: 'trustedcontactpersonconfirm',
  //   policy: {
  //     navFrom: {
  //       url: ['/txn/userPreferences/manage-trusted-contact-person/landing'],
  //       fallBackUrl: ''
  //     },
  //     navTo: {
  //       url: ['*'],
  //       fallBackUrl: ''
  //     }
  //   },
  //   restService: []
  // },
  '/txn/userPreferences/link-bank-accounts/confirm': {
    name: 'linkbankaccountsconfirm',
    service: 'UserPreferencesService',
    store: 'LinkBankAccountsConfirmStore',
    resolveContent: 'linkbankaccountsconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/link-bank-accounts/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      name: "confirmation",
      nameGA: "Preferences - Trusted Contact Person Confirmation",
      events: { pageView: true },
    },
  },
  '/txn/userPreferences/enable-US-markets-trading/landing': {
    name: 'enableUSmarkettradinglanding',
    service: 'UserPreferencesService',
    store: 'EnableUSMarketTradingLandingStore',
    resolveContent: 'enableUSmarkettradinglanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [{
      id: 'init',
      restUrl: 'webFormInit',
      get request(): any {
        return {
          WebFormName: "secureEmailCollection",
        };
      }
    }],
    trackingData: {
      name: "Preferences - Enable US Markets Trading",
      events: { pageView: true },
    },
  },
  '/txn/userPreferences/enable-US-markets-trading/confirm': {
    name: 'enableUSmarkettradingconfirm',
    service: 'UserPreferencesService',
    store: 'EnableUSMarketTradingConfirmStore',
    resolveContent: 'enableUSmarkettradingconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/enable-US-markets-trading/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      nameGA: "Preferences - Enable US Markets Trading Confirmation",
      name: "confirmation",
      events: { pageView: true },
    },
  },
  '/txn/userPreferences/contact-info-update/landing': {
    name: 'contactinfoupdatelanding',
    service: 'ContactInfoUpdateLandingService',
    store: 'ContactInfoUpdateLandingStore',
    resolveContent: 'contactinfoupdatelanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [{
      id: 'init',
      restUrl: 'webFormInit',
      get request(): any {
        return {
          WebFormName: 'contactInfoUpdate',
        };
      }
    }],
    trackingData: {
      name: "update-address",
      nameGA: "Preferences - Update Address",
      events: { pageView: true },
    },
  },
  '/txn/userPreferences/contact-info-update/confirm': {
    name: 'contactinfoupdateconfirm',
    service: 'UserPreferencesService',
    store: 'ContactInfoUpdateConfirmStore',
    resolveContent: 'contactinfoupdateconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/contact-info-update/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/txn/userPreferences/contact-info-update/landing'
    },
    restService: [],
    trackingData: {
      name: "confirmation",
      nameGA: "Preferences - Update Address Confirmation",
      events: { pageView: true },
    },
  },
  '/txn/userPreferences/rrsp-withdrawal/landing': {
    name: 'rrspwithdrawallanding',
    service: 'UserPreferencesService',
    store: 'RRSPWithdrawalLandingStore',
    resolveContent: 'rrspwithdrawallanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [{
      id: 'init',
      restUrl: 'webFormInit',
      get request(): any {
        return {
          WebFormName: "rrspWithdrawalRequest",
        };
      }
    }],
    trackingData: {
      name: "details",
      nameGA: "RRSP Withdrawal",
      formInfo: { name: "rrsp-withdrawal", stepName: "details" },
      events: { pageView: true, formStep: true, formView: true }
    },
    brazeID: "viewed_rrsp_withdrawal"
  },
  '/txn/userPreferences/rrsp-withdrawal/confirm': {
    name: 'rrspwithdrawalconfirm',
    service: 'UserPreferencesService',
    store: 'RRSPWithdrawalConfirmStore',
    resolveContent: 'rrspwithdrawalconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/rrsp-withdrawal/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [],
    trackingData: {
      name: "confirmation",
      nameGA: "RRSP Withdrawal",
      formInfo: { name: "rrsp-withdrawal", stepName: "confirmation" },
      events: { pageView: true, formStep: true, formView: true }
    },
  },
  '/txn/userPreferences/currency-transfer/landing': {
    name: 'currencytransferlanding',
    service: 'UserPreferencesService',
    store: 'CurrencyTransferLandingStore',
    resolveContent: 'currencytransferlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [{
      id: 'init',
      restUrl: 'webFormInit',
      get request(): any {
        return {
          WebFormName: "equityTransfer",
        };
      }
    }],
    trackingData: {
      name: "details",
      nameGA: "Sub-Account Transfers",
      formInfo: { name: "sub-accounts-transfer-accounts", stepName: "details" },
      events: { pageView: true, formStep: true, formView: true }
    },
    brazeID: "viewed_sub_account_transfer_accounts"
  },
  '/txn/userPreferences/currency-transfer/confirm': {
    name: 'currencytransferconfirm',
    service: 'UserPreferencesService',
    store: 'CurrencyTransferConfirmStore',
    resolveContent: 'currencytransferconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/currency-transfer/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    trackingData: {
      name: "confirmation",
      nameGA: "Sub-Account Transfers - Confirmation",
      formInfo: { name: "sub-accounts-transfer-accounts", stepName: "confirmation" },
      events: { pageView: true, formStep: true, formView: true }
    },
    restService: []
  },
  '/txn/userPreferences/transfer-forms/landing': {
    name: 'transferFormsLanding',
    service: 'UserPreferencesService',
    store: 'TransferFormsLandingStore',
    resolveContent: 'transferformslanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [{
      id: 'init',
      restUrl: 'webFormInit',
      get request(): any {
        return {
          WebFormName: "transferIn",
        };
      }
    }],
    trackingData: {
      nameGA: "Transfer Accounts",
      name: "transfer-accounts",
      formInfo: { name: "transfer-accounts", stepName: "details" },
      events: { pageView: true, formStep: true, formView: true }
    },
    brazeID: "viewed_transfer_accounts_details"
  },
  '/txn/userPreferences/transfer-forms/verify': {
    name: 'transferFormsVerify',
    service: 'UserPreferencesService',
    store: 'TransferFormsVerifyStore',
    resolveContent: 'transferformsverify',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/transfer-forms/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: "/txn/userPreferences/transfer-forms/landing",
    },
    restService: [
    ],
    trackingData: {
      name: "verification",
      nameGA: "Transfer Accounts - Verification",
      formInfo: { name: "transfer-accounts", stepName: "verification" },
      events: { pageView: true, formStep: true, formView: true }
    },
  },
  '/txn/userPreferences/transfer-forms/confirm': {
    name: 'transferFormsConfirm',
    service: 'UserPreferencesService',
    store: 'TransferFormsConfirmStore',
    resolveContent: 'transferformsconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/transfer-forms/verify'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: "/txn/userPreferences/transfer-forms/landing",
    },
    restService: [],
    trackingData: {
      name: "confirmation",
      nameGA: "Transfer Accounts - Confirmation",
      formInfo: { name: "transfer-accounts", stepName: "confirmation" },
      events: { pageView: true, formStep: true, formView: true }
    },
  },
  '/txn/userPreferences/drips-forms/landing': {
    name: 'dripsFormLanding',
    service: 'UserPreferencesService',
    store: 'DripsFormLandingStore',
    resolveContent: 'dripsformlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'webFormInit',
        get request(): any {
          return {
            WebFormName: "dividendReinvestmentPlan"
          };
        }
      }
    ],
    trackingData: {
      name: "manage-dividends",
      nameGA: "Preferences - Manage Dividends",
      events: { pageView: true }
    },
  },
  '/txn/userPreferences/drips-forms/confirm': {
    name: 'dripsFormConfirm',
    service: 'UserPreferencesService',
    store: 'DripsFormConfirmStore',
    resolveContent: 'dripsformconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/drips-forms/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: "/txn/userPreferences/drips-forms/landing",
    },
    restService: [],
    trackingData: {
      name: "confirmation",
      nameGA: "Preferences - Manage Dividends Confirmation",
      events: { pageView: true }
    },
  },

  '/txn/userPreferences/enable-margin-and-options-trading/landing': {
    name: 'enablemarginandoptionstradinglanding',
    service: 'UserPreferencesService',
    store: 'EnableMarginAndOptionsTradingLandingStore',
    resolveContent: 'enablemarginandoptionstradinglanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [
      {
        id: 'init',
        restUrl: 'webFormInit',
        get request(): any {
          return {
            WebFormName: 'enableMarginOptionsTrading',
          };
        }
      }
    ],
    trackingData: {
      name: "margin-options",
      nameGA: "Preferences - Apply for Margin and Options Trading",
      hierarchy: "preferences",
      formInfo: { name: "margin-options", stepName: "trading-privileges-details" },
      events: { pageView: true, formSubmit: true, formStep: true, formView: true },
    },
    brazeID: "viewed_enable_margins_options"
  },
  '/txn/userPreferences/enable-margin-and-options-trading/confirm': {
    name: 'enablemarginandoptionstradingconfirm',
    service: 'UserPreferencesService',
    store: 'EnableMarginAndOptionsTradingConfirmStore',
    resolveContent: 'enablemarginandoptionstradingconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/enable-margin-and-options-trading/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
    },
    restService: [],
    trackingData: {
      name: "margin-options",
      nameGA: "Preferences - Apply for Margin and Options Trading Confirmation",
      hierarchy: "preferences",
      formInfo: { name: "margin-options", stepName: "confirmation" },
      events: { pageView: true, formStep: true, formSubmit: true, }
    }
  },


  '/txn/userPreferences/renew-US-trading/landing': {
    name: 'w8Benlanding',
    service: 'UserPreferencesService',
    store: 'W8BenLandingStore',
    resolveContent: 'w8Benlanding',
    policy: {
      navFrom: {
        url: ['*'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      }
    },
    restService: [{
      id: 'init',
      restUrl: 'webFormInit',
      get request(): any {
        return {
          WebFormName: "w8benDigitization",
        };
      }
    }],
    trackingData:{
      name: "account-selection",
      nameGA: "Preferences - Renew US Trading",
      hierarchy: "preferences,renew-us-trading",
      formInfo: {
        name: "renew-us-trading",
        stepName: "account-selection"
      },
      events: {
        pageView: true,
        formStep: true,
        formView: true,
        formSubmit: false
      }
    }
  },
  '/txn/userPreferences/renew-US-trading/verify': {
    name: 'w8Benverify',
    service: 'UserPreferencesService',
    store: 'W8BenVerifyStore',
    resolveContent: 'w8Benverify',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/renew-US-trading/landing'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/txn/userPreferences/renew-US-trading/landing'
    },
    restService: [],
    trackingData:{
      name: "verify-details",
      nameGA: "Preferences - Renew US Trading Verify Details",
      hierarchy: "preferences,renew-us-trading",
      formInfo: {
        name: "renew-us-trading",
        stepName: "verify-details"
      },
      events: {
        pageView: true,
        formStep: true,
        formView: false,
        formSubmit: false
      }
    }
  },
  '/txn/userPreferences/renew-US-trading/confirm': {
    name: 'w8Benconfirm',
    service: 'UserPreferencesService',
    store: 'W8BenConfirmStore',
    resolveContent: 'w8Benconfirm',
    policy: {
      navFrom: {
        url: ['/txn/userPreferences/renew-US-trading/verify'],
        fallBackUrl: ''
      },
      navTo: {
        url: ['*'],
        fallBackUrl: ''
      },
      fallbackRoute: '/txn/userPreferences/renew-US-trading/landing'
    },
    restService: [],
    trackingData:{
      name: "sign-in-your-documents",
      nameGA: "Preferences - Renew US Trading Confirmation",
      hierarchy: "preferences,renew-us-trading",
      formInfo: {
        name: "renew-us-trading",
        stepName: "sign-in-your-documents"
      },
      events: {
        pageView: true,
        formStep: true,
        formView: false,
        formSubmit: false
      }
    }
  }

};
