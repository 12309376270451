import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { PerformanceReportingData } from './performance-reporting-data';

@Injectable({ providedIn: 'root' })
export class PerformanceReportingStore extends PageStore<PerformanceReportingData>  {

    constructor() {
        super(new PerformanceReportingData());
    }
}
