import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppStore } from 'src/app/shared/models/app-store';
import { CommonService } from '../../shared/services/common.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(
    private router: Router,
    private commonService: CommonService,
    private appStore: AppStore
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.commonService.isUserSignedOn()) {
      return true;
    }
    if (this.appStore.isApp("inapp")) {
      window.location.href = "cibcbanking://relaybacksso";
    } else {
      this.router.navigate(['/signon']);
    }
    return false;
  }
}
