import { BaseData } from 'src/app/shared/models/base-data';

export class CurrencyTransferLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountNumber: null,
            email: null,
        };
    }
}

export interface CurrencyTransferLandingInputs {
    accountNumber: string;
    accountType: string;
    symbol1: string;
    symbol2: string;
    symbol3: string;
    symbol4: string;
    symbol5: string;
    market1: string;
    market2: string;
    market3: string;
    market4: string;
    market5: string;
    securityDesc1: string;
    securityDesc2: string;
    securityDesc3: string;
    securityDesc4: string;
    securityDesc5: string;
    quantity1: string;
    quantity2: string;
    quantity3: string;
    quantity4: string;
    quantity5: string;
    email: string;
    fromCurrency: string;
    toCurrency: string;
}

export interface CurrencyTransferSubmitRequest {
    WebFormName: string;
    Parameters: {
        param1: string,
        param2: string,
        param3: string,
        param4?: string,
        param5?: string,
        param6?: string,
        param7?: string,
        param8: string,
        param9: string,
        param10: string
    };
}

export enum ParamsMapping {
    Param1 = '',
    Param2 = 'email',
    Param3 = 'symbol0',
    Param4 = 'symbol1',
    Param5 = 'symbol2',
    Param6 = 'symbol3',
    Param7 = 'symbol4',
    Param8 = 'email',
    Param9 = 'email',
    Param10 = 'email'
}
