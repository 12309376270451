import { BaseData } from 'src/app/shared/models/base-data';

export class EnableUSMarketTradingLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            accountNumber: null,
            email: null,
        };
    }
}

export interface EnableUSMarketTradingLandingInputs {
    accountNumber: string;
    email: string;
}

export enum ParamsMapping {
    Param1 = '',
    Param2 = 'email',
    Param3 = ''
}
