import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../../shared/models/page-store';
import {SellBondVerifyData} from './sell-bond-verify-data';

@Injectable({ providedIn: 'root' })
export class SellBondVerifyStore extends PageStore<SellBondVerifyData> {

    constructor() {
        super(new SellBondVerifyData());
    }

}
