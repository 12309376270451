import { BaseData } from 'src/app/shared/models/base-data';

export class HelpData extends BaseData {
  constructor() {
    super();
    // this.input = {
    // };
  }
}

export enum TypeID {
  MANUAL,
  RELATED,
  SUGGESTED,
  TOP
}

export interface HelpInputs {
  helpQuestion: string;
}

export interface HelpInit {
  Question: string;
}

export interface HelpTopQuestionRequest {
  NumberOfQuestions: number;
}

export interface HelpAskQuestionRequest {
  Question: string;
}

export interface HelpDetailRequest {
  ResponseId: number;
  TypeId: number;
}

export interface HelpAnswer {
  Body: string;
  Id: number;
  Title: string;
  InquiryId: string;
  Relateds?: QuestionsList[];
  Suggesteds?: QuestionsList[];
}

export interface QuestionsList {
  Id: number;
  Title: string;
}

export interface HelpFormModel {
  helpQuestion: string;
}
