import { Injectable } from '@angular/core';
import { TouchIDLandingData } from './touchid-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class TouchIDLandingStore extends PageStore<TouchIDLandingData> {

    constructor() {
        super(new TouchIDLandingData());
    }

}
