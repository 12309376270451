import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { AppStore } from '../../models/app-store';
import { Location } from '@angular/common';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})

export class ConfirmationComponent implements OnInit {

  @Input() content;
  @Input() pageIndicator: TemplateRef<any>;
  @Input() orderConfirmInfo;
  @Input() type: string;
  @Input() passwordPage;
  @Input() mode;

  constructor(
    public appStore: AppStore,
    private commonService: CommonService
  ) { }

  ngOnInit() {
  }

  isDesktop() {
    
    if(this.mode == 'quickOptions'){
      return false;
    }else{
      return this.commonService.isDesktop();
    }
  }

  isPreTxn() {
    return window.location.hash.indexOf("/txn/") == -1;
  }

}
