import { RouteGroup } from '../shared/models/route-config';

export const NewsAndNotesConfig: RouteGroup = {

    '/txn/newsandnotes': {
        name: 'newsandnotes',
        service: '',
        store: 'NewsAndNotesStore',
        resolveContent: 'newsandnotes',
        policy: {
            navFrom: {
                url: ['*'],
                fallBackUrl: ''
            },
            navTo: {
                url: ['*'],
                fallBackUrl: ''
            }
        },
        restService: [],
        trackingData: {
            hierarchy: "accounts",
            name: "news-notes",
            nameGA: "News and Notes",
            events: { pageView: true }
        },
        brazeID: "viewed_news_and_notes"
    }
};
