import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
//import { PageService } from '../../../../../shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
//import { AppStore } from '../../../../../shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
//import { HttpService } from '../../../../../core/services/http.service'
import {SellGicLandingStore} from '../data/sell-gic-landing-store';
import {SettlementAccount} from '../data/sell-gic-landing-data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");


@Injectable({
  providedIn: 'root'
})
export class SellGicLandingService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    
    const action = SellGicLandingStore.inFlow.Action;
    const accNum = SellGicLandingStore.inFlow.AccountNumber;
    const curr = SellGicLandingStore.inFlow.Currency;

    let ser = input;
    ser[0].input = SellGicLandingStore.inFlow;
    ser[1].input.Action = action;
    ser[1].input.AccountNumber = accNum;
    ser[1].input.AccountCurrency = curr;

    return input;
  }

  getNewAmount(data){
    return this.httpService.post( gatewayConfig.APIServices.sellGICInit.url,
      data
    );
  }
}
