import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { PerformanceReportingStore } from '../data/performance-reporting-store';
import { NavParamService } from 'src/app/core/services/nav-param.service';
import { reportRequest } from '../data/performance-reporting-data';
declare let require: any;
const gatewayConfig = require('../../../../config/gateway-config.json');


@Injectable({
  providedIn: 'root'
})
export class PerformanceReportingService implements PageService {

  constructor(private store: PerformanceReportingStore, private httpService: HttpService, private navParam: NavParamService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ret = [input[0]];
    ret[0] = this.prepareApp(ret[0]);
    return input;
  }

  prepareApp(input) {
    let req = input.input;
    if ((window as any).enableAccPerformanceCalendar) {
      req = {
        ReportType: 'CM',
        SelectedTab: 'RR',
        ReportDateRange: {
          ReportDateRangeType: 'Y'
        },
        FirstTimeInd: true
      };
    } else {
      req = {
        ReportType: 'CM',
        SelectedTab: 'RR',
        ReportDateRange: {
          ReportDateRangeType: 'MX'
        },
        FirstTimeInd: true
      };
    }
    input.input = req;
    return req;
  }

  getReport(_req) {
    const req: reportRequest = _req;
    return this.httpService.post(gatewayConfig.APIServices.perfomanceReporting.url, req);
  }
}
