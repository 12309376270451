import { Injectable } from '@angular/core';
import { RegisterOnlineData } from './register-online-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class RegisterOnlineStore extends PageStore<RegisterOnlineData> {

    constructor() {
        super(new RegisterOnlineData());
    }

}
