import { Injectable } from '@angular/core';
import { ChangeSignOnPasswordConfirmData } from './change-signon-password-confirm.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class ChangeSignOnPasswordConfirmStore extends PageStore<ChangeSignOnPasswordConfirmData> {

    constructor() {
        super(new ChangeSignOnPasswordConfirmData());
    }

}
