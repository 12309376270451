<ng-container *ngIf="isNew(mode)">
  <div *ngIf="contents && (hasError || underlyingQuote)" class="new-quote-border" id="option-chain-{{pagename}}"
    [ngClass]="{'quick-trade-style-option': fromQuickTrade, 'no-border': pagename === 'OptionCentral'}">
    <div *ngIf="hasError && !loading">
      <div [attr.id]="titleId" tabindex="-1" *ngIf="EAAErrorFlag" class="new-EAA-error-text"
        [ngClass]="fromQuickTrade ? 'quick-trade-text' : ''" [innerHtml]="errorDisplay"
        (click)="hideTrdaeDrawer($event)"></div>
      <div *ngIf="!EAAErrorFlag">
        <div aria-hidden="true" class="new-error-icon icon-Error-Warning-Fill color10"></div>
        <div [attr.id]="titleId" tabindex="-1" class="new-error-text" [innerHtml]="errorDisplay"></div>
      </div>
    </div>

     <div *ngIf="venueError" [ngClass]="EAAErrorFlag ? 'venue-quote-error EAA' : 'venue-quote-error'">
                      <div *ngIf="!EAAErrorFlag" aria-hidden="true"
                        class="venue-quote-error-image icon-Error-Warning-Fill color10"></div>
                      <div class="venue-quote-error-message underline-inner-links" [innerHtml]="venueErrorMessage"></div>
                    </div>
    <div *ngIf="underlyingQuote != null && underlyingQuote != undefined && !loading && !hasError || venueError">
      <form #optionChainForm="ngForm">

        <div [attr.id]="titleId" tabindex="-1" class="new-symbol-title" *ngIf="!dateChangeLoading || venueError">
          <img class="new-symbol-flag" height="13" width="20" aria-hidden="true" alt=""
            [src]="getFlag(underlyingQuote.Symbol.Market)" />
          <span class="new-symbol-name" [ngClass]="
          underlyingQuote.Symbol.SymbolLongName.length > 48
              ? 'new-symbol-small-name'
              : ''
          ">{{optionQuote ?optionQuote.UnderlyingSymbolQuote.CompanyName : underlyingQuote.Symbol.SymbolLongName  }}</span>
        </div>
        <div *ngIf="(underlyingQuote && !dateChangeLoading) || venueError" class="new-symbol-price-change" [ngClass]="{'option-central-symbol': pagename === 'OptionCentral'}">
          <div>
            <div class="new-symbol-price">
              <span *ngIf="underlyingQuote.Symbol.Market === 'CA'"
                [innerHtml]="valueOrDash(quotePrice) |  currencyFormatter:'CAD':'format-currency-sr' | removeDollar:'html'"></span>
              <span *ngIf="underlyingQuote.Symbol.Market !== 'CA'"
                [innerHtml]="valueOrDash(quotePrice) |  currencyFormatter:'USD':'format-currency-sr' | removeDollar:'html'"></span>
            </div>
            <div class="new-symbol-change" [ngClass]="{
              backgroundUp: underlyingQuote.ChangeIndicator == 'U',
              backgroundDown: underlyingQuote.ChangeIndicator == 'D'
            }">
              <span [innerHtml]="
                valueOrDashData(underlyingQuote.PriceChange) +
                  ' (' +
                  valueOrDash(underlyingQuote.PriceChangePercent) +
                  ')' | changeFormatter : underlyingQuote.ChangeIndicator : 'wedge'
              "></span>
            </div>

            <span *ngIf="pagename !== 'OptionCentral' && isDesktop()" class="timestamp">
              <span class="timestamp-refresh">
                <button class="new-quote-refresh-button-qt icon-Reload2" type='button'
                  [attr.aria-label]="contents.text.refresh" (click)="refreshAll()"></button>
                <span class="lastUpdated">{{ contents.text.asOf }} 
                  &nbsp;{{timestamp}}&nbsp;{{ contents.text.et }}
                </span>
              </span>
            </span>
          </div>
          <div *ngIf="pagename === 'OptionCentral' && isDesktop()" class="timestamp-refresh">
            <app-global-refresh type="refresh" [name]="'OptionCentral'"></app-global-refresh>
          </div>
        </div>
        <div *ngIf="(!underlyingQuote && !dateChangeLoading)" class="new-symbol-price-change">
          <div class="new-symbol-price">
            <span *ngIf="optionQuote.Symbol.Market === 'CA'"
              [innerHtml]="valueOrDash(quotePrice) |  currencyFormatter:'CAD':'format-currency-sr' | removeDollar:'html'"></span>
            <span *ngIf="optionQuote.Symbol.Market !== 'CA'"
              [innerHtml]="valueOrDash(quotePrice) |  currencyFormatter:'USD':'format-currency-sr' | removeDollar:'html'"></span>
          </div>
          <div class="new-symbol-change" [ngClass]="{
            backgroundUp: optionQuote.UnderlyingSymbolQuote.ChangeIndicator == 'U',
            backgroundDown: optionQuote.UnderlyingSymbolQuote.ChangeIndicator == 'D'
          }">
            <span [innerHtml]="
              valueOrDashData(optionQuote.UnderlyingSymbolQuote.PriceChange) +
                ' (' +
                valueOrDash(optionQuote.UnderlyingSymbolQuote.PriceChangePercent) +
                ')' | changeFormatter : optionQuote.UnderlyingSymbolQuote.ChangeIndicator : 'wedge'
            "></span>
          </div>
        </div>

        <div *ngIf="!isDesktop() && !dateChangeLoading && pagename !== 'OptionCentral'" class="marginBottom10" [hidden]="(quoteError || optionQuoteError || venueError || orderType === 'CLOSE' )">
          <app-connected-radio [contentArray]="tradeChainManualEntryArray" [pagename]="fromQuickTrade ? 'TradeQuickOptions':'TradeOptions'"
            [(selectedValue)]="inputs.toggleChart" [uniqueName]="fromQuickTrade ? 'optionChainTypeQuickToggle'+uniqueName :'optionChainTypeToggle'+uniqueName"
            (change)="changeToggleChart(inputs.toggleChart, true)" [(selectedValue)]="inputs.toggleChart"
            [labelledbyID]="null"
            [error]="formError.chainToggleRadio">
          </app-connected-radio>
          <input type="hidden" name="chainToggleRadio" [(ngModel)]="inputs.toggleChart">
        </div>

        <div [hidden]="!(inputs.toggleChart === 'CHAIN')" *ngIf="orderType !== 'CLOSE' || manuallySearchAndClose">
          <div [ngClass]="isDesktop() ? 'col col-33 paddingRight8 marginBottom20' : 'col col-50 paddingRight8'">
            <label class="field-label-optChain" [for]="'strike-date-input-0' + pagename">
              {{ contents.text.optionExpiry }}
            </label>

            <div class="select-item field-input" [ngClass]="{'field-input-qt': !this.isDesktop()}">
              <select [(ngModel)]="inputs.optionStrikeDateIndex" name="optionStrikeDateIndex" [id]="'strike-date-input-0' + pagename"
                #optionStrikeDateIndex="ngModel" aria-describedby="strike-date-error"
                (change)="onExpiryStrikeDate($event)"   [disabled]="(quoteError || optionQuoteError || venueError)"
                [inputErrorDirective]="formError.optionStrikeDateIndex" class="custom-date-style soft">
                <option [selected]="inputs.optionStrikeDateIndex === i" [value]="expiryDate"
                  *ngFor="let expiryDate of optionStrikeDateList; let i = index">
                  {{formatDate( expiryDate, "MMM DD, YYYY", "DD MMMM YYYY")}}
                </option>
              </select>
              <span class="select-icon icon-Chevron-Down color03" aria-hidden="true">
              </span>
              <app-field-level-error [error]="formError.optionStrikeDateIndex" [Id]="'strike-date-error'">
              </app-field-level-error>
            </div>
          </div>
          <div *ngIf="isDesktop()" class="col col-33 paddingRight8 marginBottom20">
            <label class="field-label-optChain" for="strike-shown-input">
              {{ contents.text.strikesShown }}
            </label>
            <div class="select-item field-input" [ngClass]="{'field-input-qt': !this.isDesktop()}">
              <select [(ngModel)]="inputs.strikeShown" name="strikeShown" id="strike-shown-input"
                #strikeShown="ngModel" aria-describedby="strike-shown-error" class="custom-date-style soft"
                 (change)="onExpiryStrikeShown(inputs.strikeShown)"  [disabled]="(quoteError || optionQuoteError || venueError)"
                [inputErrorDirective]="formError.strikeShown">
                <option [selected]="inputs.strikeShown === i" [value]="strikeShown.value" 
                  *ngFor="let strikeShown of contents.list.strikeShown; let i = index">
                  {{strikeShown.name}}
                </option>
              </select>
              <span class="select-icon icon-Chevron-Down color03" aria-hidden="true">
              </span>
              <app-field-level-error [error]="formError.strikeShown" [Id]="'strike-shown-error'">
              </app-field-level-error>
            </div>
          </div>
          <div  [ngClass]="isDesktop() ? 'col col-33 marginBottom20' : 'col col-50'" style="vertical-align: bottom;">
            <label class="field-label-optChain" [for]="'strikeOption' + pagename">
              <span>{{ contents.text.show }}</span>
            </label>

            <div class="select-item field-input" [ngClass]="{'field-input-qt': !this.isDesktop()}">
              <select [id]="'strikeOption' + pagename" name="strikeOption" #strikeOption="ngModel" [(ngModel)]="inputs.strikeOption"
                [selectDefaultDirective]="inputs.strikeOption === 'Choose One'" [disabled]="(quoteError || optionQuoteError || venueError)"
                (change)="getOptionShow(inputs.strikeOption)" class="custom-date-style soft">
                <option [selected]="inputs.strikeOption === i" 
                  *ngFor="let strike of contents.list.strikeOption; let i = index" [value]="strike.value">
                  {{ strike.name }}
                </option>
              </select>
              <span class="select-icon icon-Chevron-Down color03" aria-hidden="true"></span>
            </div>
          </div>
          

          <!-- <fieldset class="toggle-design call-put-custom " > -->
            <div  class="divTableOption marginBottom20" [hidden]="(quoteError || optionQuoteError || venueError)">
              <div class="divTableBodyOption" [ngClass]="{'divTableBodyOptionQuick': fromQuickTrade}">                   
                    <app-connected-radio [contentArray]="tradeOptionsPositionArray" [pagename]="fromQuickTrade ? 'TradeQuickOptions':'TradeOptions'"
                      [(selectedValue)]="inputs.optionType" [uniqueName]="fromQuickTrade ? 'optionChainQuickToggle'+uniqueName :'optionChainToggle'+uniqueName"
                      (change)="changeActionMode(inputs.optionType, true)" [disabled]="(quoteError || optionQuoteError || venueError)"
                      [labelledbyID]="null"
                      [error]="formError.optionChainRadio">
                    </app-connected-radio>
                    <input type="hidden" name="optionChainRadio" [(ngModel)]="inputs.optionType">
              </div>
            </div>
          <!-- </fieldset> -->

          <!-- <div  [ngClass]="(isDesktop() && inputs.optionType === 'BOTH') ? 'option-chart-text marginBottom0' : 'option-chart-text marginBottom20'">
            <span [innerHTML]="contents.text.chartSuggestionText"></span>
          </div> -->

          <!-- <div *ngIf="inputs.optionType === 'BOTH' && inputs.optionStrikeDateIndex !==-1"
            class="table-both-header-row marginBottom8" >
            <span class="call-heading-text" [innerHTML]="contents.text.callText"></span>
            <span class="puts-heading-text" [innerHTML]="contents.text.putText"></span>
          </div> -->
          <div class="container-for-gradient" *ngIf="(quoteError || optionQuoteError || venueError)">
            <div  class="table-container" id="chain-table-{{pagename}}" tabindex="0">
          <table role="table" #table>

            <thead class="table-header-row">
              <tr role="row">
                <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.bidC}}</th>
                <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.askC}}</th>
                <th role="columnheader" scope="col" *ngIf="isDesktop()" class="bid-ask-last-text">{{contents.text.lastC}}</th>
                <th role="columnheader" scope="col" class="strike-text">{{contents.text.strike}}</th>
                <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.bidC}}</th>
                <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.askC}}</th>
                <th role="columnheader" scope="col" *ngIf="isDesktop()" class="bid-ask-last-text">{{contents.text.lastC}}</th>
              </tr>
            </thead>
            <tbody>
                <tr role="row" class="table-row-data" *ngFor="let item of optionQuoteErrorList; let i = index;">
                  <td role="gridcell" class="both-put-bid-price-data no-text-underline">
                    <span>{{noContentValue}}</span>
                  </td>
                  <td role="gridcell" class="bid-price-data no-text-underline">
                  <span>{{noContentValue}}</span>
                  </td>
                  <td role="gridcell" *ngIf="isDesktop()"  class="bid-price-data no-text-underline">
                  <span>{{noContentValue}}</span>
                  </td>
                  <td role="gridcell" class="strike-price-data no-text-underline">
                    <span>{{noContentValue}}</span>
                  </td>
                  <td role="gridcell" class="bid-price-data no-text-underline">
              <span>{{noContentValue}}</span>
                  </td>
                  <td role="gridcell" class="bid-price-data no-text-underline">
              <span>{{noContentValue}}</span>
                  </td>
                  <td role="gridcell" *ngIf="isDesktop()" class="both-put-ask-price-data no-text-underline">
                <span>{{noContentValue}}</span>
                  </td>
                </tr>
             

            </tbody>
          </table>
        </div>
        <div class="table-shadow" *ngIf="inputs.optionType === 'BOTH' && inputs.optionStrikeDateIndex !==-1"></div>
      </div>

          <div class="container-for-gradient" *ngIf="!dateChangeLoading && !(quoteError || optionQuoteError || venueError)">
            <div *ngIf="inputs.optionType === 'BOTH' && inputs.optionStrikeDateIndex !==-1" class="table-container" id="chain-table-{{pagename}}" tabindex="0" (scroll)="onTableScroll($event)">
              <table role="table"#table>

                <thead class="table-header-row">
                  <tr role="row">
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.bidC}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.askC}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="bid-ask-last-text">{{contents.text.lastC}}</th>
                    <th role="columnheader" scope="col" class="strike-text">{{contents.text.strike}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.bidC}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.askC}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="bid-ask-last-text">{{contents.text.lastC}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="inputs.strikeOption === 'ALL'">
                    <tr role="row" class="table-row-data" *ngFor="let call of optionStrikePriceCallList; let i = index;">
                      <td role="gridcell" id="bothCallBid-all-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothCallBid')?'strike-text' 
                      :i < endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'bothCallBid'?'strike-text' :i == endIndex ? 'in-the-money-bid-call-text' : 'both-put-bid-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.bid?.price !== null ? selectRow(i,'bothCallBid') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothCallAsk-all-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.ask?.price !== null ) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothCallAsk')?'strike-text' 
                      :i < endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'bothCallAsk'?'strike-text' :i == endIndex ? 'in-the-money-call-text' : 'ask-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.ask?.price !== null ? selectRow(i,'bothCallAsk') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="bothCallLast-all-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothCallLast')?'strike-text' 
                      :i < endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'bothCallLast'?'strike-text' :i == endIndex ? 'in-the-money-call-text' : 'ask-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.lastTrade?.last !== null ? selectRow(i,'bothCallLast') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothStrike') ?'strike-text': i == endIndex ? 'strike-price-data-border' :'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'bothStrike')" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceList[i]) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell" id="bothPutBid-all-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && optionStrikePriceCallList[i].put?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutBid')?'strike-text' 
                  :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'bothPutBid'?'strike-text' :i == endIndex ? 'in-the-money-put-text' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="optionStrikePriceCallList[i].put?.quote?.bid?.price !== null ? selectRow(i,'bothPutBid') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutAsk-all-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && optionStrikePriceCallList[i].put?.quote?.ask?.price != null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutAsk')?'strike-text' 
                  :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'bothPutAsk'?'strike-text' :i == endIndex ? 'in-the-money-put-text' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="optionStrikePriceCallList[i].put?.quote?.ask?.price != null ? selectRow(i,'bothPutAsk') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutLast-all-id-{{i}}" *ngIf="isDesktop()" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && optionStrikePriceCallList[i].put?.quote?.lastTrade?.last) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothAskLast')?'strike-text' 
                    :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'bothAskLast'?'strike-text' :i == endIndex ?  'in-the-money-put-text' : 'both-put-ask-price-data'">
                        <a href="javascript:void(0);" (click)="optionStrikePriceCallList[i].put?.quote?.lastTrade?.last !== null ? selectRow(i,'bothPutLast') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="inputs.strikeOption === 'INTHEMONEY'">
                    <tr role="row" id="bothCallBid-intheMoney-id-{{i}}" class="table-row-data" *ngFor="let call of optionStrikePriceCallList; let i = index;">
                      <td role="gridcell" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i < endIndex &&  call.call?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothCallBid')?'strike-text' 
                    :i < endIndex-1? 'in-the-money-text': i == endIndex-1 ? 'in-the-money-text' : i == endIndex ? 'no-price-data-border' : i > endIndex ? 'no-price-data' :'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i < endIndex &&  call.call?.quote?.bid?.price !== null ? selectRow(i,'bothCallBid'): null " 
                          [innerHtml]='i >= endIndex ? noContentValue : forFrenchFormat(valueOrDashTable(call.call?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothCallAsk-intheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i < endIndex && call.call?.quote?.ask?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothCallAsk')?'strike-text' 
                    :i < endIndex-1? 'in-the-money-text': i == endIndex-1 ? 'in-the-money-text' : i == endIndex ? 'no-price-data-border' :  i > endIndex ? 'no-price-data' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i < endIndex && call.call?.quote?.ask?.price !== null ? selectRow(i,'bothCallAsk') : null" 
                          [innerHtml]='i >= endIndex ? noContentValue : forFrenchFormat(valueOrDashTable(call.call?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothCallLast-intheMoney-id-{{i}}" *ngIf="isDesktop()" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i < endIndex && call.call?.quote?.lastTrade?.last !== null)? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothCallLast')?'strike-text' 
                    :i < endIndex-1? 'in-the-money-text': i == endIndex-1 ? 'in-the-money-text' : i == endIndex ? 'no-price-data-border' :   i > endIndex ? 'no-price-data' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i < endIndex && call.call?.quote?.lastTrade?.last !== null ? selectRow(i,'bothCallLast') : null" 
                          [innerHtml]='i >= endIndex ? noContentValue :forFrenchFormat(valueOrDashTable(call.call?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothStrike') ?'strike-text':  i == endIndex ? 'strike-price-data-border' :'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'bothStrike')" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceList[i]) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell" id="bothPutBid-intheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i >= endIndex && optionStrikePriceCallList[i].put?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutBid')?'strike-text' 
                :i > endIndex? 'in-the-money-text' :i == endIndex ? 'in-the-money-put-text' :  i < endIndex ? 'no-price-data' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i >= endIndex && optionStrikePriceCallList[i].put?.quote?.bid?.price !== null ? selectRow(i,'bothPutBid') : null" 
                          [innerHtml]='i < endIndex ? noContentValue :  forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutAsk-intheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i >= endIndex && optionStrikePriceCallList[i].put?.quote?.ask?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutAsk')?'strike-text' 
                :i > endIndex? 'in-the-money-text' :i == endIndex ? 'in-the-money-put-text' :  i < endIndex ? 'no-price-data' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i >= endIndex && optionStrikePriceCallList[i].put?.quote?.ask?.price !== null ? selectRow(i,'bothPutAsk'): null"
                          [innerHtml]='i < endIndex ? noContentValue :  forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutLast-intheMoney-id-{{i}}" *ngIf="isDesktop()" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i >= endIndex && optionStrikePriceCallList[i].put?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutLast')?'strike-text' 
                    :i > endIndex? 'in-the-money-text': i == endIndex ? 'in-the-money-put-text' : i < endIndex ? 'no-price-data' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i >= endIndex && optionStrikePriceCallList[i].put?.quote?.lastTrade?.last !== null ?  selectRow(i,'bothPutLast') : null" 
                          [innerHtml]='i < endIndex ? noContentValue :forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="inputs.strikeOption === 'OUTTHEMONEY'">
                    <tr role="row" class="table-row-data" *ngFor="let call of optionStrikePriceCallList; let i = index;">
                      <td role="gridcell" id="bothCallBid-outtheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i >= endIndex && call.call?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothCallBid')?'strike-text' 
                      :i > endIndex ? 'both-put-bid-price-data' :  i == endIndex ?  'ask-price-data-border-left' : i < endIndex ? 'no-price-data' :'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i >= endIndex && call.call?.quote?.bid?.price !== null ? selectRow(i,'bothCallBid') : null" 
                          [innerHtml]='i < endIndex ? noContentValue : forFrenchFormat(valueOrDashTable(call.call?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothCallAsk-outtheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i >= endIndex && call.call?.quote?.ask?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothCallAsk')?'strike-text' 
                      :i > endIndex ?  'ask-price-data' : i == endIndex ?  'ask-price-data-border':i < endIndex ? 'no-price-data' :'bid-price-data' ">
                        <a href="javascript:void(0);" (click)="i >= endIndex && call.call?.quote?.ask?.price !== null ? selectRow(i,'bothCallAsk'): null" 
                          [innerHtml]='i < endIndex ? noContentValue : forFrenchFormat(valueOrDashTable(call.call?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothCallLast-outtheMoney-id-{{i}}"  *ngIf="isDesktop()" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i >= endIndex && call.call?.quote?.lastTrade?.last !== null)  ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothCallLast')?'strike-text' 
                      :i > endIndex ?  'ask-price-data' : i == endIndex ?  'ask-price-data-border':  i < endIndex ? 'no-price-data' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i >= endIndex && call.call?.quote?.lastTrade?.last !== null? selectRow(i,'bothCallLast') : null" 
                          [innerHtml]='i < endIndex ? noContentValue :forFrenchFormat(valueOrDashTable(call.call?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothStrike') ?'strike-text':  i == endIndex ? 'strike-price-data-border' : 'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'bothStrike')" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call?.strikePrice) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell" id="bothPutBid-outtheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i < endIndex && optionStrikePriceCallList[i].put?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothPutBid')?'strike-text' 
                  :i < endIndex? 'bid-price-data'  :i == endIndex ? 'no-price-data-border' : i > endIndex ? 'no-price-data':'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i < endIndex && optionStrikePriceCallList[i].put?.quote?.bid?.price !== null ? selectRow(i,'bothPutBid') : null" 
                          [innerHtml]='i >= endIndex ? noContentValue : forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutAsk-outtheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i < endIndex && optionStrikePriceCallList[i].put?.quote?.ask?.price !==null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothPutAsk')?'strike-text' 
                  :i < endIndex? 'bid-price-data'  :i == endIndex ? 'no-price-data-border' :  i > endIndex ? 'no-price-data' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i < endIndex && optionStrikePriceCallList[i].put?.quote?.ask?.price !==null ? selectRow(i,'bothPutAsk') : null"
                          [innerHtml]='i >= endIndex ? noContentValue : forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutLast-outtheMoney-id-{{i}}" *ngIf="isDesktop()" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && i < endIndex && optionStrikePriceCallList[i].put?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutLast')?'strike-text' 
                        :i < endIndex? 'both-put-ask-price-data'  :i == endIndex ? 'no-price-data-border' :  i > endIndex ? 'no-price-data' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="i < endIndex && optionStrikePriceCallList[i].put?.quote?.lastTrade?.last !== null ? selectRow(i,'bothPutLast') : null"
                          [innerHtml]='i >= endIndex ? noContentValue :forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="inputs.strikeOption === 'NEARTHEMONEY'">
                    <tr role="row" class="table-row-data" *ngFor="let call of strikePriceCallNearTheMoney; let i = index;">
                      <td role="gridcell" id="bothCallBid-neartheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothCallBid')?'strike-text' 
                  :i >  strikePriceCallNearTheMoney.length-5 ? 'both-put-bid-price-data' : i == strikePriceCallNearTheMoney.length-5 ? 'out-the-money-put-text-border' :'in-the-money-text'">
                        <a href="javascript:void(0);"
                          [innerHtml]='forFrenchFormat( valueOrDashTable(call.call?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")' (click)="call.call?.quote?.bid?.price !== null ? selectRow(i,'bothCallBid') : null"></a>
                      </td>
                      <td role="gridcell" id="bothCallAsk-neartheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.ask?.price) ? 'underline' : 'none' }" 
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothCallAsk')?'strike-text' 
                    :i >  strikePriceCallNearTheMoney.length-5 ? 'ask-price-data': i == strikePriceCallNearTheMoney.length-5 ? 'out-the-money-put-text' :'in-the-money-text'">
                        <a href="javascript:void(0);"
                          [innerHtml]='forFrenchFormat( valueOrDashTable(call.call?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")' (click)="call.call?.quote?.ask?.price !== null ? selectRow(i,'bothCallAsk') : null"></a>
                      </td>
                      <td role="gridcell" id="bothCallLast-neartheMoney-id-{{i}}" *ngIf="isDesktop()" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.lastTrade?.last) ? 'underline' : 'none' }" 
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothCallLast')?'strike-text' 
                      :i >  strikePriceCallNearTheMoney.length-5 ? 'ask-price-data': i == strikePriceCallNearTheMoney.length-5 ? 'out-the-money-put-text' :'in-the-money-text'">
                        <a href="javascript:void(0);"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")' (click)="call.call?.quote?.lastTrade?.last !== null ? selectRow(i,'bothCallLast') : null"></a>
                      </td>
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'bothStrike') ?'strike-text':  i == strikePriceCallNearTheMoney.length-5 ? 'strike-price-data-border' :'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'bothStrike')" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call?.strikePrice) | currencyFormatter:strikePrice:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutBid-neartheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.put?.quote?.bid?.price !== null) ? 'underline' : 'none' }" 
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutBid')?'strike-text' 
                    :i >  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text': i == strikePriceCallNearTheMoney.length-5 ? 'in-the-money-put-text' :'bid-price-data'">
                        <a href="javascript:void(0);" (click)="call.put?.quote?.bid?.price !== null ? selectRow(i,'bothPutBid') : null"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.put?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutAsk-neartheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.put?.quote?.ask?.price !== null) ? 'underline' : 'none' }" 
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutAsk')?'strike-text' 
                    :i >  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text': i == strikePriceCallNearTheMoney.length-5 ? 'in-the-money-put-text'  : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="call.put?.quote?.ask?.price !== null ? selectRow(i,'bothPutAsk') : null"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.put?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="bothPutLast-neartheMoney-id-{{i}}" *ngIf="isDesktop()" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && optionStrikePriceCallList[i].put?.quote?.lastTrade?.last) ? 'underline' : 'none' }" 
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'bothPutLast')?'strike-text' 
                      :i >  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text': i == strikePriceCallNearTheMoney.length-5 ? 'in-the-money-put-text'  :'both-put-ask-price-data'">
                        <a href="javascript:void(0);" (click)="optionStrikePriceCallList[i].put?.quote?.lastTrade?.last !== null ? selectRow(i,'bothPutLast') : null"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceCallList[i].put?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="table-shadow" *ngIf="inputs.optionType === 'BOTH' && inputs.optionStrikeDateIndex !==-1"></div>
          </div>

          <div class="container-for-gradient" *ngIf="!dateChangeLoading && !(quoteError || optionQuoteError || venueError)">
            <div *ngIf="inputs.optionType === 'CALLS' && inputs.optionStrikeDateIndex !==-1" class="table-container" id="chain-table-{{pagename}}" tabindex="0" (scroll)="onTableScroll($event)">
              <table role="table" #table>
                <thead class="table-header-row">
                  <tr role="row">
                    <th role="columnheader" scope="col" class="strike-text">{{contents.text.strike}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.bidC}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.askC}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.lastC}}</th>
                    <th role="columnheader" scope="col" [ngClass]="isDesktop()? 'open-interest-text border-left-grey' : 'open-interest-text'">{{contents.text.openInterestC}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.impliedVolatility}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.delta}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.gamma}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.theta}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.vega}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.rho}}</th>
                  </tr>
                </thead>
                <tbody>

                  <ng-container *ngIf="inputs.strikeOption === 'ALL' ">
                    <tr role="row" class="table-row-data" *ngFor="let call of optionStrikePriceCallList; let i = index;">
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callStrike') ?'strike-text': i == endIndex? 'strike-price-data-border' :'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'callStrike')"  [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceList[i]) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell" id="callBid-all-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callBid')?'strike-text' 
                        :i < endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'callBid'?'strike-text' :i == endIndex ? 'in-the-money-border' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.bid?.price !== null ? selectRow(i,'callBid') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="callAsk-all-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain'  && call.call?.quote?.ask?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callAsk' && i < endIndex)|| (i === selectedIndex && selectedColumn === 'callAsk')?'strike-text' 
                        :i < endIndex? 'in-the-money-text': i === selectedIndex && selectedColumn === 'callAsk'?'strike-text':i == endIndex ? 'in-the-money-border': 'ask-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.ask?.price !== null ? selectRow(i,'callAsk') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="callLast-all-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"  
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callLast' && i < endIndex)|| (i === selectedIndex && selectedColumn === 'callLast')?'strike-text'
                         :i < endIndex? 'in-the-money-text':i === selectedIndex && selectedColumn === 'callLast'? 'strike-text':i == endIndex ? 'in-the-money-border': 'last-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.lastTrade?.last !== null ? selectRow(i,'callLast') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="callOI-all-id-{{i}}"
                        [ngClass]="i > endIndex ?  'open-interest-price-data' :  i == endIndex ? 'open-int-in-the-money-price-border' : 'open-interest-in-the-money-price'">
                        <span [innerHtml]='forFrenchFormat(openInterestValueFormat(call.call?.quote?.openInterest))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callIV-all-id-{{i}}"
                        [ngClass]="i > endIndex ?  'open-interest-price-data' :  i == endIndex ? 'open-int-in-the-money-price-border' : 'open-interest-in-the-money-price'">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.impliedVolatility) | currencyFormatter:impliedVolatility:"format-percent")'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callDelta-all-id-{{i}}"
                        [ngClass]="i > endIndex ?  'open-interest-price-data' :  i == endIndex ? 'open-int-in-the-money-price-border' : 'open-interest-in-the-money-price'">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.delta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callGamma-all-id-{{i}}"
                        [ngClass]="i > endIndex ?  'open-interest-price-data' :  i == endIndex ? 'open-int-in-the-money-price-border' : 'open-interest-in-the-money-price'">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.gamma))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callTheta-all-id-{{i}}"
                        [ngClass]="i > endIndex ?  'open-interest-price-data' :  i == endIndex ? 'open-int-in-the-money-price-border' : 'open-interest-in-the-money-price'">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.theta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callVega-all-id-{{i}}"
                        [ngClass]="i > endIndex ?  'open-interest-price-data' :  i == endIndex ? 'open-int-in-the-money-price-border' : 'open-interest-in-the-money-price'">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.vega))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callRho-all-id-{{i}}"
                        [ngClass]="i > endIndex ?  'open-interest-price-data' :  i == endIndex ? 'open-int-in-the-money-price-border' : 'open-interest-in-the-money-price'">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.rho))'></span>
                      </td>
                    </tr>
                  </ng-container>

                  <ng-container *ngIf="inputs.strikeOption === 'INTHEMONEY'">
                    <tr role="row" class="table-row-data" *ngFor="let call of strikePriceCallInTheMoney; let i = index;">
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callStrike') ?'strike-text': 'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'callStrike')"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceList[i]) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell"  id="callBid-inTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.bid?.price !== null) ? 'underline' : 'none' }" 
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'callBid')?'strike-text' 
                      : 'in-the-money-text'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.bid?.price !== null ? selectRow(i,'callBid') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell"  id="callAsk-inTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.ask?.price !== null) ? 'underline' : 'none' }" 
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'callAsk')?'strike-text' 
                      : 'in-the-money-text'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.ask?.price !== null ? selectRow(i,'callAsk') : null"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell"  id="callLast-inTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }" 
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'callLast')?'strike-text':'in-the-money-text'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.lastTrade?.last !== null ? selectRow(i,'callLast') : null"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td role="gridcell" [ngClass]="'open-interest-in-the-money-price'" id="callOI-inTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(openInterestValueFormat(call.call?.quote?.openInterest))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callIV-inTheMoney-id-{{i}}" [ngClass]="'open-interest-in-the-money-price'">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.impliedVolatility) | currencyFormatter:impliedVolatility:"format-percent")'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callDelta-inTheMoney-id-{{i}}" [ngClass]="'open-interest-in-the-money-price'">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.delta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callGamma-inTheMoney-id-{{i}}" [ngClass]="'open-interest-in-the-money-price'">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.gamma))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callTheta-inTheMoney-id-{{i}}" [ngClass]="'open-interest-in-the-money-price'">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.theta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callVega-inTheMoney-id-{{i}}" [ngClass]="'open-interest-in-the-money-price'">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.vega))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callRho-inTheMoney-id-{{i}}" [ngClass]="'open-interest-in-the-money-price'">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.rho))'></span>
                      </td>
                    </tr>
                  </ng-container>

                  <ng-container *ngIf="inputs.strikeOption === 'OUTTHEMONEY'">
                    <tr role="row" class="table-row-data" *ngFor="let call of strikePriceCallOutTheMoney; let i = index;">
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callStrike') ?'strike-text': 'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'callStrike')" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call?.strikePrice) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell"  id="callBid-outTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callBid')?'strike-text' 
                       : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.bid?.price !== null ? selectRow(i,'callBid') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell"  id="callAsk-outTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.ask?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callAsk')?'strike-text' 
                      : 'ask-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.ask?.price !== null ? selectRow(i,'callAsk') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell"  id="callLast-outTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callLast')?'strike-text'
                       : 'last-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.lastTrade?.last !== null ? selectRow(i,'callLast') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td role="gridcell" [ngClass]="'open-interest-price-data'" id="callOI-outTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(openInterestValueFormat(call.call?.quote?.openInterest))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-price-data'" id="callIV-outTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.impliedVolatility) | currencyFormatter:impliedVolatility:"format-percent")'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-price-data'" id="callDelta-outTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.delta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-price-data'" id="callGamma-outTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.gamma))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-price-data'" id="callTheta-outTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.theta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-price-data'" id="callVega-outTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.vega))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-price-data'" id="callRho-outTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.rho))'></span>
                      </td>
                    </tr>
                  </ng-container>

                  <ng-container *ngIf="inputs.strikeOption === 'NEARTHEMONEY'">
                    <tr role="row" class="table-row-data" *ngFor="let call of strikePriceCallNearTheMoney; let i = index;">
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callStrike') ?'strike-text': i ==  strikePriceCallNearTheMoney.length-5? 'strike-price-data-border' : 'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'callStrike')" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call?.strikePrice) | currencyFormatter:strikePrice:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="callBid-nearTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'callBid')?'strike-text' 
                      :i <  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text' :  i ==  strikePriceCallNearTheMoney.length-5 ? 'out-the-money-put-text' :'bid-price-data' ">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.bid?.price !== null ? selectRow(i,'callBid') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="callAsk-nearTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.ask?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'callAsk')?'strike-text' 
                      :i <  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text':  i ==  strikePriceCallNearTheMoney.length-5 ? 'out-the-money-put-text' :'ask-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.ask?.price !== null ? selectRow(i,'callAsk') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="callLast-nearTheMoney-id-{{i}}" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && call.call?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'callLast')?'strike-text'
                       :i <  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text'  :  i ==  strikePriceCallNearTheMoney.length-5 ? 'out-the-money-put-text' :'last-price-data'">
                        <a href="javascript:void(0);" (click)="call.call?.quote?.lastTrade?.last !== null ? selectRow(i,'callLast') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="callOI-nearTheMoney-id-{{i}}"
                        [ngClass]="i <  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price': i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-out-the-money-price-border' : 'open-interest-price-data' ">
                        <span
                          [innerHtml]='forFrenchFormat(openInterestValueFormat(call.call?.quote?.openInterest))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callIV-nearTheMoney-id-{{i}}"
                      [ngClass]="i <  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price': i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-out-the-money-price-border' : 'open-interest-price-data' ">
                      <span
                      [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.impliedVolatility)  | currencyFormatter:lastTrade:"format-percent")'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()"  id="callDelta-nearTheMoney-id-{{i}}"
                      [ngClass]="i <  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price': i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-out-the-money-price-border' : 'open-interest-price-data' ">
                      <span
                      [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.delta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callGamma-nearTheMoney-id-{{i}}"
                      [ngClass]="i <  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price': i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-out-the-money-price-border' : 'open-interest-price-data' ">
                      <span
                      [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.gamma))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()"  id="callTheta-nearTheMoney-id-{{i}}"
                      [ngClass]="i <  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price': i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-out-the-money-price-border' : 'open-interest-price-data' ">
                      <span
                      [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.theta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callVega-nearTheMoney-id-{{i}}"
                      [ngClass]="i <  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price': i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-out-the-money-price-border' : 'open-interest-price-data' ">
                      <span
                      [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.vega))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="callRho-nearTheMoney-id-{{i}}"
                      [ngClass]="i <  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price': i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-out-the-money-price-border' : 'open-interest-price-data' ">
                      <span
                      [innerHtml]='forFrenchFormat(valueOrDashTable(call.call?.greeks?.rho))'></span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="table-shadow" *ngIf="inputs.optionType === 'CALLS' && inputs.optionStrikeDateIndex !==-1"></div>
          </div>

          <div class="container-for-gradient" *ngIf="!dateChangeLoading && !(quoteError || optionQuoteError || venueError)">
            <div *ngIf="inputs.optionType === 'PUTS' && inputs.optionStrikeDateIndex !==-1" class="table-container" id="chain-table-{{pagename}}" tabindex="0" (scroll)="onTableScroll($event)">
              <table role="table">
                <thead class="table-header-row">
                  <tr role="row">
                    <th role="columnheader" scope="col" class="strike-text">{{contents.text.strike}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.bidC}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.askC}}</th>
                    <th role="columnheader" scope="col" class="bid-ask-last-text">{{contents.text.lastC}}</th>
                    <th role="columnheader" scope="col" [ngClass]="isDesktop()? 'open-interest-text border-left-grey' : 'open-interest-text'">{{contents.text.openInterestC}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.impliedVolatility}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.delta}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.gamma}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.theta}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.vega}}</th>
                    <th role="columnheader" scope="col" *ngIf="isDesktop()" class="open-interest-text">{{contents.text.rho}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="inputs.strikeOption === 'ALL'">
                    <tr role="row" class="table-row-data" *ngFor="let put of optionStrikePricePutList; let i = index">
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putStrike') ?'strike-text': i == endIndex ? 'strike-price-data-border' :'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'putStrike')" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(optionStrikePriceList[i]) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell"  id="putBid-all-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putBid') ?'strike-text' 
              :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'putBid'?'strike-text' :i == endIndex ? 'in-the-money-put-text' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.bid?.price !== null ? selectRow(i,'putBid') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                        [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.bid?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell"  id="putAsk-all-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.ask?.price !== null ) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putAsk')?'strike-text' 
              :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'putAsk'?'strike-text' :i == endIndex ? 'in-the-money-put-text' : 'ask-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.ask?.price !== null ? selectRow(i,'putAsk'): null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell"  id="putLast-all-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'putLast')?'strike-text' 
              :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'putLast'?'strike-text' :i == endIndex ? 'in-the-money-put-text' : 'ask-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.lastTrade?.last !== null ? selectRow(i,'putLast') : null" [tabIndex]="  i-endIndex > -6 || (endIndex < 20)|| parentMode =='desktopChain' ? 0: 1"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="putOI-all-id-{{i}}"
                        [ngClass]="i > endIndex ? 'open-interest-in-the-money-price' :  i == endIndex ? 'open-interest-in-the-money-price-border' :  'open-interest-price-data' ">
                        <span [innerHtml]='forFrenchFormat(openInterestValueFormat(put.put?.quote?.openInterest))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putIV-all-id-{{i}}"
                        [ngClass]="i > endIndex ? 'open-interest-in-the-money-price' :  i == endIndex ? 'open-interest-in-the-money-price-border' :  'open-interest-price-data' ">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.impliedVolatility) | currencyFormatter:impliedVolatility:"format-percent")'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putDelta-all-id-{{i}}"
                        [ngClass]="i > endIndex ? 'open-interest-in-the-money-price' :  i == endIndex ? 'open-interest-in-the-money-price-border' :  'open-interest-price-data' ">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.delta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putGamma-all-id-{{i}}"
                        [ngClass]="i > endIndex ? 'open-interest-in-the-money-price' :  i == endIndex ? 'open-interest-in-the-money-price-border' :  'open-interest-price-data' ">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.gamma))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putTheta-all-id-{{i}}"
                        [ngClass]="i > endIndex ? 'open-interest-in-the-money-price' :  i == endIndex ? 'open-interest-in-the-money-price-border' :  'open-interest-price-data' ">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.theta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putVega-all-id-{{i}}"
                        [ngClass]="i > endIndex ? 'open-interest-in-the-money-price' :  i == endIndex ? 'open-interest-in-the-money-price-border' :  'open-interest-price-data' ">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.vega))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putRho-all-id-{{i}}"
                        [ngClass]="i > endIndex ? 'open-interest-in-the-money-price' :  i == endIndex ? 'open-interest-in-the-money-price-border' :  'open-interest-price-data' ">
                        <span [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.rho))'></span>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="inputs.strikeOption === 'INTHEMONEY'">
                    <tr role="row" class="table-row-data" *ngFor="let put of strikePricePutInTheMoney; let i = index">
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putStrike') ?'strike-text': 'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'putStrike')" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.strikePrice) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell"  id="putBid-inTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putBid')?'strike-text' 
                      :'in-the-money-text'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.bid?.price !== null ? selectRow(i,'putBid') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell"   id="putAsk-inTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.ask?.price  !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putAsk')?'strike-text' 
                      :'in-the-money-text'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.ask?.price  !== null ? selectRow(i,'putAsk') : null"
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell"   id="putLast-inTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putLast')?'strike-text' 
                      :'in-the-money-text'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.lastTrade?.last !== null ? selectRow(i,'putLast') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td role="gridcell" [ngClass]="'open-interest-in-the-money-price'"  id="putOI-inTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(openInterestValueFormat(put.put?.quote?.openInterest))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-in-the-money-price'"  id="putIV-inTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.impliedVolatility) | currencyFormatter:impliedVolatility:"format-percent")'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-in-the-money-price'"  id="putDelta-inTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.delta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-in-the-money-price'"  id="putGamma-inTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.gamma))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-in-the-money-price'"  id="putTheta-inTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.theta))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-in-the-money-price'"  id="putVega-inTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.vega))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" [ngClass]="'open-interest-in-the-money-price'"  id="putRho-inTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.rho))'></span>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="inputs.strikeOption === 'OUTTHEMONEY'">
                    <tr scope="row" role="rowheader" class="table-row-data" *ngFor="let put of strikePricePutOutTheMoney; let i = index">
                      <td role="gridcell" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putStrike') ?'strike-text': 'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'putStrike')" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.strikePrice) | currencyFormatter:strikePrice:"format-dash")' [id]="valueOrDashTable(optionStrikePriceList[i])"></a>
                      </td>
                      <td role="gridcell"  id="putBid-outTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'putBid')?'strike-text' 
                  :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'putBid'?'strike-text' :i == endIndex ? 'in-the-money-put-text' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.bid?.price !== null ? selectRow(i,'putBid') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell"  id="putAsk-outTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.ask?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'putAsk')?'strike-text' 
                  :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'putAsk'?'strike-text' :i == endIndex ? 'in-the-money-put-text' : 'ask-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.ask?.price !== null ? selectRow(i,'putAsk') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell"  id="putLast-outTheMoney-id-{{i}}"
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'putLast')?'strike-text' 
                  :i > endIndex? 'in-the-money-text':  i === selectedIndex && selectedColumn === 'putLast'?'strike-text' :i == endIndex ? 'in-the-money-put-text' : 'ask-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.lastTrade?.last !== null ? selectRow(i,'putLast') : null" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td role="gridcell" [ngClass]="i < endIndex ? 'open-interest-price-data': 'open-interest-in-the-money-price' " id="putOI-outTheMoney-id-{{i}}">
                        <span
                          [innerHtml]='forFrenchFormat(openInterestValueFormat(put.put?.quote?.openInterest))'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putIV-outTheMoney-id-{{i}}" [ngClass]="i < endIndex ? 'open-interest-price-data': 'open-interest-in-the-money-price' ">
                        <span 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.impliedVolatility) | currencyFormatter:impliedVolatility:"format-percent")'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putDelta-outTheMoney-id-{{i}}" [ngClass]="i < endIndex ? 'open-interest-price-data': 'open-interest-in-the-money-price' ">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.delta)) '></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putGamma-outTheMoney-id-{{i}}" [ngClass]="i < endIndex ? 'open-interest-price-data': 'open-interest-in-the-money-price' ">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.gamma) )'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putTheta-outTheMoney-id-{{i}}" [ngClass]="i < endIndex ? 'open-interest-price-data': 'open-interest-in-the-money-price' ">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.theta) )'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putVega-outTheMoney-id-{{i}}" [ngClass]="i < endIndex ? 'open-interest-price-data': 'open-interest-in-the-money-price' ">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.vega) )'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()" id="putRho-outTheMoney-id-{{i}}" [ngClass]="i < endIndex ? 'open-interest-price-data': 'open-interest-in-the-money-price' ">
                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.rho) )'></span>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="inputs.strikeOption === 'NEARTHEMONEY'">
                    <tr role="row" class="table-row-data" *ngFor="let put of strikePriceCallNearTheMoney; let i = index">
                      <td scope="row" role="rowheader" 
                        [ngStyle]="{'text-decoration': parentMode =='desktopChain' ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putStrike') ?'strike-text':  i ==  strikePriceCallNearTheMoney.length-5 ? 'strike-price-data-border' :'strike-price-data'">
                        <a href="javascript:void(0);" (click)="selectRow(i,'putStrike')" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.strikePrice) | currencyFormatter:strikePrice:"format-dash")'></a>
                      </td>
                      <td role="gridcell" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.bid?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'putBid')?'strike-text' 
                      :i >  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text': i ==  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-put-text' : 'bid-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.bid?.price !== null ? selectRow(i,'putBid') : null" id="putBid-nearTheMoney-id-{{i}}" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.bid?.price) | currencyFormatter:bid:"format-dash")'></a>
                      </td>
                      <td role="gridcell" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.ask?.price !== null) ? 'underline' : 'none' }"
                        [ngClass]=" (i === selectedIndex && selectedColumn === 'putAsk')?'strike-text' 
                      :i >  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text':  i ==  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-put-text' :'ask-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.ask?.price !== null ? selectRow(i,'putAsk') : null" id="putAsk-nearTheMoney-id-{{i}}" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.ask?.price) | currencyFormatter:ask:"format-dash")'></a>
                      </td>
                      <td role="gridcell" 
                        [ngStyle]="{'text-decoration': (parentMode =='desktopChain' && put.put?.quote?.lastTrade?.last !== null) ? 'underline' : 'none' }"
                        [ngClass]="(i === selectedIndex && selectedColumn === 'putLast')?'strike-text' 
                      :i >  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-text':  i ==  strikePriceCallNearTheMoney.length-5 ? 'in-the-money-put-text' :'last-price-data'">
                        <a href="javascript:void(0);" (click)="put.put?.quote?.lastTrade?.last !== null ? selectRow(i,'putLast') : null" id="putLast-nearTheMoney-id-{{i}}" 
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.quote?.lastTrade?.last) | currencyFormatter:lastTrade:"format-dash")'></a>
                      </td>
                      <td role="gridcell" id="putOI-nearTheMoney-id-{{i}}"
                        [ngClass]="i >  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price':  i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price-border' :'open-interest-price-data' ">
                        <span
                          [innerHtml]='forFrenchFormat(openInterestValueFormat(put.put?.quote?.openInterest)) '></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()"  id="putIV-nearTheMoney-id-{{i}}"
                      [ngClass]="i >  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price':  i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price-border' :'open-interest-price-data' ">                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.impliedVolatility)  | currencyFormatter:lastTrade:"format-percent")'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()"  id="putDelta-nearTheMoney-id-{{i}}"
                      [ngClass]="i >  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price':  i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price-border' :'open-interest-price-data' ">                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.delta)) '></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()"  id="putGamma-nearTheMoney-id-{{i}}"
                      [ngClass]="i >  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price':  i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price-border' :'open-interest-price-data' ">                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.gamma) )'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()"  id="putTheta-nearTheMoney-id-{{i}}"
                      [ngClass]="i >  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price':  i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price-border' :'open-interest-price-data' ">                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.theta) )'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()"  id="putVega-nearTheMoney-id-{{i}}"
                      [ngClass]="i >  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price':  i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price-border' :'open-interest-price-data' ">                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.vega) )'></span>
                      </td>
                      <td role="gridcell" *ngIf="isDesktop()"  id="putRho-nearTheMoney-id-{{i}}"
                      [ngClass]="i >  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price':  i ==  strikePriceCallNearTheMoney.length-5 ? 'open-interest-in-the-money-price-border' :'open-interest-price-data' ">                        <span
                          [innerHtml]='forFrenchFormat(valueOrDashTable(put.put?.greeks?.rho) )'></span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="table-shadow"  *ngIf="inputs.optionType === 'PUTS' && inputs.optionStrikeDateIndex !==-1"></div>
          </div>
          
            <div *ngIf="!isDesktop() && pagename !== 'OptionCentral'" class="new-as-of-line">
              <span class="new-as-of-text">{{ contents.text.message }}</span>
            </div>
            
              <label *ngIf="!dateChangeLoading && pagename !== 'OptionCentral'" class="glossary-tooltip" ref="javascript:void(0);">
                <span class="options-glossary">{{ contents.text.optionsGlossary }}</span>
                <app-tooltip *ngIf="this.appStore.lob == LOB.IE" [id]="'glossaryTooltip'"
                  [tooltipContent]="glossaryTooltip" openIconName="icon-Question-Thin" targetEle="{{titleId}}"
                  attr.aria-label="{{ contents.text.optionsGlossarySmall }}"
                  mode="modal-new" (click)="tooltipClicked(contents.text.optionsGlossarySmall)" (closeModal)="resetOptionTooltip()">
                  <div class="tooltip-lightbox">
                    <div class="option-tooltip-body">
                      <img aria-hidden="true" src="assets\images\icons\grey-circle-icons\Graduation.svg" alt="" />
                      <div class="option-tooltip-title">
                        {{ contents.text.optionTooltipTitle }}
                      </div>
                      <div class="option-tooltip-main-content">
                        <div class="spacer-bar">
                        <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(0)"
                          type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[0] }}">
                          <span [attr.id]="'icon-bar-0'"></span>
                          {{ contents.list.optionTooltipContent[0].header }}
                            <span [attr.id]="'option-tooltip-drawer-chevron-optChain-0'"
                            class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                          </span>
                        </button>
                        </div>
                        <div *ngIf="optionTooltipExpandedOptChain[0]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[0].content }}
                        </div>
                        <!-- Put -->
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(1)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[1] }}">
                            <span [attr.id]="'icon-bar-1'"></span>
                            {{ contents.list.optionTooltipContent[1].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-1'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[1]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[1].content }}
                        </div>
      
                        <!-- Bid -->
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(2)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[2] }}">
                            <span [attr.id]="'icon-bar-2'"></span>
                            {{ contents.list.optionTooltipContent[2].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-2'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[2]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[2].content }}
                        </div>
      
                        <!-- ASK -->
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(3)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[3] }}">
                            <span [attr.id]="'icon-bar-3'"></span>
                            {{ contents.list.optionTooltipContent[3].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-3'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[3]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[3].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(4)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[4] }}">
                            <span [attr.id]="'icon-bar-4'"></span>
                            {{ contents.list.optionTooltipContent[4].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-4'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[4]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[4].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(5)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[5] }}">
                            <span [attr.id]="'icon-bar-5'"></span>
                            {{ contents.list.optionTooltipContent[5].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-5'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[5]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[5].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(6)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[6] }}">
                            <span [attr.id]="'icon-bar-6'"></span>
                            {{ contents.list.optionTooltipContent[6].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-6'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[6]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[6].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(7)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[7] }}">
                            <span [attr.id]="'icon-bar-7'"></span>
                            {{ contents.list.optionTooltipContent[7].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-7'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[7]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[7].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(8)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[8] }}">
                            <span [attr.id]="'icon-bar-8'"></span>
                            {{ contents.list.optionTooltipContent[8].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-8'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[8]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[8].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(9)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[9] }}">
                            <span [attr.id]="'icon-bar-9'"></span>
                            {{ contents.list.optionTooltipContent[9].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-9'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                      <div *ngIf="optionTooltipExpandedOptChain[9]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                        {{ contents.list.optionTooltipContent[9].content }}
                      </div>
      
                      <div class="spacer-bar">
                        <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(10)"
                          type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[10] }}">
                          <span [attr.id]="'icon-bar-10'"></span>
                          {{ contents.list.optionTooltipContent[10].header }}
                            <span [attr.id]="'option-tooltip-drawer-chevron-optChain-10'"
                            class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                          </span>
                        </button>
                        </div>
                        <div *ngIf="optionTooltipExpandedOptChain[10]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[10].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(11)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[11] }}">
                            <span [attr.id]="'icon-bar-11'"></span>
                            {{ contents.list.optionTooltipContent[11].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-11'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[11]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[11].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(12)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[12] }}">
                            <span [attr.id]="'icon-bar-12'"></span>
                            {{ contents.list.optionTooltipContent[12].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-12'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[12]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[12].content }}
                        </div>
      
                        <div class="spacer-bar">
                          <button class="option-tooltip-drawer-header" (click)="optionsTooltipExpandCollapseOptChain(13)"
                            type="button" attr.aria-expanded="{{ optionTooltipExpandedOptChain[13] }}">
                            <span [attr.id]="'icon-bar-13'"></span>
                            {{ contents.list.optionTooltipContent[13].header }}
                              <span [attr.id]="'option-tooltip-drawer-chevron-optChain-13'"
                              class="animatedChevron icon-Chevron-Up pointDown" aria-hidden="true">
                            </span>
                          </button>
                          </div>
                        <div *ngIf="optionTooltipExpandedOptChain[13]" [@glossaryAnimation]="'open'" class="option-tooltip-drawer-content">
                          {{ contents.list.optionTooltipContent[13].content }}
                        </div>
                      </div>
                      <div class="option-tooltip-footer">
                        <span>{{ contents.text.optionTooltipFooter }} </span>
                        <a class="underline" href="javascript:void(0);" (click)="
                  openWindow(contents.text.optionTooltipFooterLinkURL)
                ">
                          <span>{{
                            contents.text.optionTooltipFooterLinkText
                            }}</span>
                          <span class="sr-only">{{
                            contents.text.newWindowText
                            }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </app-tooltip> 
                <span class="sr-only">{{ contents.text.newWindowText }}</span>
              </label> 
              <div *ngIf="!isDesktop() && pagename !== 'OptionCentral'" class="glossary-timeStamp" aria-hidden="true"></div>
              <span *ngIf="isDesktop() && pagename !== 'OptionCentral'" class="new-as-of-line" style="position:fixed; right:20px;">
                <span class="new-as-of-text">{{ contents.text.message }}</span>
              </span>
            
        </div>
        <div [hidden]="!(inputs.toggleChart === 'MANUALENTRY')">
          <span [hidden]="orderType === 'CLOSE' && !manuallySearchAndClose">
            <div class="col col-100">
              <label class="field-label-optChain" [for]="'strike-date-input-1'+ pagename">
                <span>{{ contents.text.optionExpiry }}</span>
              </label>
              <!-- <div class="field-input field-border" *ngIf="dateChangeLoading">
                <div class="field-spinner">
                  <app-general-spinner></app-general-spinner>
                </div>
              </div> -->
              <div class="select-item field-input" [ngClass]="{'field-input-qt': !this.isDesktop()}">
                <select [(ngModel)]="inputs.optionStrikeDateIndex" name="optionStrikeDateIndex" [id]="'strike-date-input-1' + pagename"
                  #optionStrikeDateIndex="ngModel" aria-describedby="strike-date-error"
                  [inputErrorDirective]="formError.optionStrikeDateIndex" class="custom-date-style soft"
                  [selectDefaultDirective]="inputs.optionStrikeDateIndex === 0" [disabled]="(quoteError || optionQuoteError || venueError)"
                  (change)="updateManualStrikeDate($event)" >
                  <option [selected]="inputs.optionStrikeDateIndex === i" [value]="expiryDate"
                    *ngFor="let expiryDate of optionStrikeDateList; let i = index">
                    {{formatDate( expiryDate, "MMM DD, YYYY", "DD MMMM YYYY")}}
                  </option>
                </select>
                <span class="select-icon icon-Chevron-Down color03" aria-hidden="true">
                </span>
                <app-field-level-error [error]="formError.optionStrikeDateIndex" [Id]="'strike-date-error'">
                </app-field-level-error>
              </div>
            </div>

            <div class="col col-50 paddingRight8">
              <label class="field-label-optChain" for="strike-price-input">
                <span>{{ contents.text.strikePrice }}</span>
              </label>

              <div class="select-item field-input" [ngClass]="{'field-input-qt': !this.isDesktop()}">
                <select [(ngModel)]="inputs.optionStrikePriceIndex" name="optionStrikePriceIndex"
                  id="strike-price-input" #optionStrikePriceIndex="ngModel" aria-describedby="strike-price-error"
                  [inputErrorDirective]="formError.optionStrikePriceIndex" class="custom-date-style soft"
                  [selectDefaultDirective]="inputs.optionStrikePriceIndex == -1" [disabled]="(quoteError || optionQuoteError || venueError)"
                  (change)="updateManualStrikePrice($event)">
                  <option [selected]="inputs.optionStrikePriceIndex === -1" [ngValue]="-1" class="default-option">
                    {{ contents.text.chooseOne }}
                  </option>
                  <option [selected]="inputs.optionStrikePriceIndex === i" [value]="strikePrice"
                      *ngFor="let strikePrice of optionStrikePriceList; let i = index">
                      {{forFrenchFormat( strikePrice.toFixed(2) | currencyFormatter:strikePrice.Currency:"format-dash") }}
                    </option>
                </select>
                <span class="select-icon icon-Chevron-Down color03" aria-hidden="true">
                </span>
                <app-field-level-error [error]="formError.optionStrikePriceIndex" [Id]="'strike-price-error'">
                </app-field-level-error>
              </div>
            </div>
            <div class="col col-50">
              <label class="field-label-optChain" for="manualOptionType">
                <span>{{ contents.text.optionType }}</span>
              </label>
              <div class="select-item field-input" [ngClass]="{'field-input-qt': !this.isDesktop()}">
                <select id="manualOptionType" name="manualOptionType" #manualOptionType="ngModel" class="custom-date-style soft"
                  [(ngModel)]="inputs.manualOptionType" aria-describedby="strike-option-type-error"  
                  [selectDefaultDirective]="inputs.manualOptionType === ''" [disabled]="(quoteError || optionQuoteError || venueError)"
                  (change)="updateManualOptionType($event)" [inputErrorDirective]="formError.manualOptionType">
                  <option value='' class="default-option">{{contents.text.chooseOne}}</option>
                  <option value='CALLS' >{{contents.list.optionType[0].name}}</option>
                  <option value='PUTS' >{{contents.list.optionType[1].name}}</option>
                </select>
                <span class="select-icon icon-Chevron-Down color03" aria-hidden="true"></span>
                <app-field-level-error [error]="formError.manualOptionType" [Id]="'strike-option-type-error'">
                </app-field-level-error>
              </div>
            </div>
          </span>
        </div>
        <div *ngIf="pagename === 'OptionCentral'" class="option-chain-info">
          <div *ngIf="!isDesktop()" class="option-chain-delay">
            {{contents.text.optionChainDelay}}
          </div>
          <div>
            <a href="javascript:void(0);" (click)="optionGlossaryClick()" aria-label="{{contents.text.optionGlossary + contents.text.optionGlossaryHdnTxt}}" class="options-central-glossary">
              {{contents.text.optionGlossary}}
              <span class="icon-Question-Thin A16"></span>
            </a>
          </div>
          <div *ngIf="isDesktop()" class="option-chain-delay">
            {{contents.text.optionChainDelay}}
          </div>
        </div>
          <div *ngIf="isAllManualInputsGiven() && (!isDesktop() || pagename === 'OptionCentral')">
            <div class="action-conatiner" [attr.id]="'optionQuoteInfo-' + pagename">
              <span *ngIf=" orderType === 'BUYTOOPEN'" class="action-text-buy">
                {{contents.text.buyToOpen}}</span>
              <span *ngIf=" orderType ===  'SELLTOOPEN'" class="action-text-sell"> {{contents.text.sellToOpen}}</span>
                <span *ngIf="selectedOptionHolding?.Action === 'BUYTOCLOSE' || orderTypeClose === 'BUYTOCLOSE'"
                  class="action-text-buy">{{contents.text.buyToClose}}</span>
                <span *ngIf="selectedOptionHolding?.Action === 'SELLTOCLOSE' || orderTypeClose === 'SELLTOCLOSE'" class="action-text-sell">
                  {{contents.text.sellToClose}}</span>
            </div>
            <div class="symbol-expiry-strike-container" *ngIf="selectedManualStrikePrice">
              <div [ngClass]="( appStore.lang == 'FR' || appStore.lang == 'fr')? 'new-symbol-ticker-fr' : 'new-symbol-ticker'"
>{{ optionQuote ? optionQuote.UnderlyingSymbolQuote.Symbol.SymbolName : underlyingQuote.Symbol.SymbolName }}</div>
              <div [ngClass]="( appStore.lang == 'FR' || appStore.lang == 'fr')? 'new-symbol-ticker-fr' : 'new-symbol-ticker'">{{formatDate( selectedExpiryDate, "MMM DD, YYYY", "DD MMMM YYYY") }}</div>
              <div [ngClass]="( appStore.lang == 'FR' || appStore.lang == 'fr')? 'new-symbol-ticker-fr' : 'new-symbol-ticker'"
                [innerHtml]='forFrenchFormat(roundOfPrice(selectedManualStrikePrice) | currencyFormatter:selectedManualStrikePrice?.Currency:"format-dash")'>
              </div>
              <div *ngIf="inputs.manualOptionType === 'CALLS'" [ngClass]="( appStore.lang == 'FR' || appStore.lang == 'fr')? 'new-symbol-ticker-fr' : 'new-symbol-ticker'">
                {{contents.list.optionType[0].name}}
              </div>
              <div *ngIf="inputs.manualOptionType === 'PUTS'" [ngClass]="( appStore.lang == 'FR' || appStore.lang == 'fr')? 'new-symbol-ticker-fr' : 'new-symbol-ticker'">
                {{contents.list.optionType[1].name}}
              </div>
            </div>
            <div class="new-symbol-data-row">
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">
                  {{ forFrenchFormat(contents.text.bidN) }}/{{ forFrenchFormat(contents.text.contracts) }}
                </div>
                <div class="new-symbol-data-value">
                  {{forFrenchFormat(optionQuote?.BidPrice.Data)}}/{{
                    forFrenchFormat(valueOrDashContent(optionQuote?.BidSize))
                  }}
                </div>
              </div>
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">
                  {{ forFrenchFormat(contents.text.askN) }}/{{ forFrenchFormat(contents.text.contracts) }}
                </div>
                <div class="new-symbol-data-value">
                  {{forFrenchFormat(optionQuote?.AskPrice.Data)}}/{{
                    forFrenchFormat(valueOrDashContent(optionQuote?.AskSize))
                  }}
                </div>
              </div>
            </div>

            <div class="new-symbol-data-row">
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">{{ contents.text.volumeN }}</div>
                <div class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashContent(optionQuote?.Volume))}}
                </div>
              </div>
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">
                  {{ contents.text.openInterestN }}
                </div>
                <div  class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashContent(optionQuote?.OpenInterest)) }}
                </div>
              </div>
            </div>
          </div>
          <ng-container class="collapse" *ngIf="(!isDesktop() || this.pagename === 'OptionCentral') && isNewDisplayBottom(mode) && isAllManualInputsGiven()">
            <div class="new-symbol-data-row">
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">{{ forFrenchFormat(contents.text.lastN) }}</div>
                <div class="new-symbol-data-value">
                  {{ forFrenchFormat(optionQuote?.LastPrice.Data)}}&nbsp;
                  <span [ngClass]="{ colorUp: optionQuote?.PriceChange.Data > 0 , colorDown: optionQuote?.PriceChange.Data <= 0 }" [innerHtml]="forFrenchFormat( optionQuote?.PriceChange.Data) "></span>
                </div>
              </div>
             
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">
                  {{ forFrenchFormat(contents.text.impliedVolatilityN) }}
                </div>
                <div *ngIf="inputs.manualOptionType === 'CALLS'" class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashTable(selectedCallIndex!.greeks.impliedVolatility)) }}
                </div>
                <div *ngIf="inputs.manualOptionType === 'PUTS'" class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashTable(selectedPutIndex!.greeks.impliedVolatility))}}
                </div>
              </div>
            </div>

            <div class="new-symbol-data-row">
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">{{ forFrenchFormat(contents.text.delta) }}</div>
                <div *ngIf="inputs.manualOptionType === 'CALLS'" class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashQuote(selectedCallIndex!.greeks.delta)) }}
                </div>
                <div *ngIf="inputs.manualOptionType === 'PUTS'" class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashQuote(selectedPutIndex!.greeks.delta)) }}
                </div>
              </div>
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">{{ contents.text.gamma }}</div>
                <div *ngIf="inputs.manualOptionType === 'CALLS'" class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashQuote(selectedCallIndex!.greeks.gamma))}}
                </div>
                <div *ngIf="inputs.manualOptionType === 'PUTS'" class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashQuote(selectedPutIndex!.greeks.gamma)) }}
                </div>
              </div>
            </div>
            <div class="new-symbol-data-row">
              <div class="new-symbol-data-half">
                <div class="new-symbol-data-title">{{ contents.text.theta }}</div>
                <div *ngIf="inputs.manualOptionType === 'CALLS'" class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashQuote(selectedCallIndex!.greeks.theta)) }}
                </div>

                <div *ngIf="inputs.manualOptionType === 'PUTS'" class="new-symbol-data-value">
                  {{ forFrenchFormat(valueOrDashQuote(selectedCallIndex!.greeks.theta)) }}
                </div>
              </div>
            </div>
          </ng-container>
          <span *ngIf="(!isDesktop() || pagename === 'OptionCentral') && isAllManualInputsGiven()">
            <div *ngIf="pagename !== 'OptionCentral'" class="new-as-of-line">
              <span class="new-as-of-text">{{ contents.text.message }}</span>
            </div>

            <div class="expand-collapse-row" [ngClass]="{'left-pos': pagename === 'OptionCentral'}">
              <button *ngIf="isExpanded" id="hidestats-btn-id" class="new-expand-button" type="button" (click)="toggleSize()"
                [attr.aria-expanded]="isExpanded">
                {{ pagename === 'OptionCentral'? contents.text.OptionCentralHideStats : contents.text.hideStats}}
                <span class="up-triangle"></span>
              </button>

              <button *ngIf="!isExpanded" id="showstats-btn-id" class="new-expand-button" type="button" (click)="toggleSize()"
                [attr.aria-expanded]="!isExpanded">
                {{ pagename === 'OptionCentral'? contents.text.OptionCentralShowStats : contents.text.showStats}}
                <span class="down-triangle"></span>
              </button>
            </div>
          </span>
      </form>
    </div>
  </div>
    <div class="loading-animation-row">
    <div *ngIf="markitAPILoading">
      <!-- <div class="chart-group toggle-design marginTop16">
        <span class="nondivendIndexCell bid-ask-title toggle-container" >
          <input id="toggle-radio-1" type="radio" class="option-chain-button" [(ngModel)]="inputs.toggleChart"
                #toggleMode="ngModel" name="toggleMode" 
                [selectDefaultDirective]="true" value="CHAIN" />
          <label for="toggle-radio-1" class="option-chain-button">
            <span class="chart-checkmark-chain" aria-hidden="true"></span>
            <span>{{ contents.text.chain }}</span>
          </label>
          
          <label for="toggle-radio-2" class="option-manualEntry-button">
            <span class="chart-checkmark-marketEntry" aria-hidden="true"></span>
            <span>{{ contents.text.manualEntry }}</span>
          </label>
        </span>
      </div> -->

      <div  class="loading-animation new-skeleton-value-title marginTop10">
        <div class="shimmer-half"></div>
      </div>
      <div  class="loading-animation new-skeleton-value-title marginTop16"></div>
      <div  class="option-chart-text marginBottom20 marginTop16">
        <span [innerHTML]="contents.text.chartSuggestionText"></span>
      </div>
    </div>
      <div *ngIf="dateChangeLoading">
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
        <div class="loading-animation new-skeleton-value-title marginTop10">
          <div class="shimmer-one-fifth"></div>
          <div class="shimmer-two-fifth"></div>
          <div class="shimmer-three-fifth"></div>
          <div class="shimmer-four-fifth"></div>
        </div>
      </div>
    </div>
</ng-container>