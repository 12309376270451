import { RouteGroup } from "../shared/models/route-config";

export const ExchangeCurrencyConfig: RouteGroup = {
  "/txn/exchangeCurrencyNew/landing": {
    name: "ExchangeCurrencyLanding",
    service: "ExchangeCurrencyLandingService",
    store: "ExchangeCurrencyLandingStore",
    resolveContent: "exchangecurrencylanding",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      }, 
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "fxInit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      name: "details",
      nameGA: "Exchange Currency",
      formInfo: { name: "exchange-currency", stepName: "details" },
      events: { formView: true, pageView: true, formStep: true },
    },
  }
};
