import { Injectable } from '@angular/core';
import { CegAgreementData } from './ceg-agreement.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class CegAgreementStore extends PageStore<CegAgreementData> {

    constructor() {
        super(new CegAgreementData());
    }

}
