import { Injectable } from '@angular/core';
import { OpraAgreementData } from './opra-agreement.data';
import { PageStore } from 'src/app/shared/models/page-store';
@Injectable({ providedIn: 'root' })
export class OpraAgreementStore extends PageStore<OpraAgreementData> {

    constructor() {
        super(new OpraAgreementData());
    }

}
