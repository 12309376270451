import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HamburgerMenuModule } from 'src/app/core/components/hamburger-menu/hamburger-menu.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    HamburgerMenuModule,
    SharedModule
  ],
  providers: []
})

export class HomeModule {
}
