import { BuyBondsConfirmData } from './buy-bonds-confirm-data';
import { Injectable } from '@angular/core';
import { PageStore } from '../../../../../../shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class BuyBondsConfirmStore extends PageStore<BuyBondsConfirmData> {

    constructor() {
        super(new BuyBondsConfirmData());
    }

}
