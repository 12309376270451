import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { CancelCashPlanConfirmData } from './cancel-cash-plan-confirm-data';

@Injectable({ providedIn: 'root' })
export class CancelCashPlanConfirmStore extends PageStore<CancelCashPlanConfirmData>  {

    constructor() {
        super(new CancelCashPlanConfirmData());
    }

}