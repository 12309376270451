import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { AccountDetailsData } from './account-details-page-data';

@Injectable({ providedIn: 'root' })
export class AccountDetailsStore extends PageStore<AccountDetailsData>  {

    constructor() {
        super(new AccountDetailsData());
    }

}
