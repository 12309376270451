import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");


@Injectable({
  providedIn: 'root'
})
export class QuotesWatchListService implements PageService {
  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const ser = input[0];
    return input;
  }

  getRecentQuotes() {
    return this.httpService.post(gatewayConfig.APIServices.getWatchlistRecent.url, {});
  }

  createWatchList(name: string) {
    return this.httpService.post(gatewayConfig.APIServices.getWatchlistNew.url, { "Name": name });
  }

  renameWatchList(name: string, newName: string) {
    return this.httpService.post(gatewayConfig.APIServices.getWatchlistRename.url, { "Name": name, "NewName": newName });
  }

  getQuotesList(name: string) {
    return this.httpService.post(gatewayConfig.APIServices.getWatchlistSymbols.url, { "Name": name });
  }

  addRecentQuote(symbol) {
    const request = {
      "Symbols": [
        {
          "SymbolName": symbol.SymbolName,
          "Market": symbol.Market
        }
      ]
    }
    return this.httpService.post(gatewayConfig.APIServices.getWatchlistRecentAdd.url, request);
  }

  addQuote(name: string, symbol: any) {
    const request = {
      "Symbols": [
        {
          "SymbolName": symbol.SymbolName,
          "Market": symbol.Market
        }
      ],
      "Name": name
    }

    return this.httpService.post(gatewayConfig.APIServices.getWatchlistSymbolsAdd.url, request);

  }

  deleteQuotes(name: string, quotes: any[]) {
    let request = {
      "Symbols": [],
      "Name": name
    };
    for (let i = 0; i < quotes.length; i++) {
      request.Symbols.push({
        "SymbolName": quotes[i].Symbol.SymbolName,
        "Market": quotes[i].Symbol.Market
      });
    }

    return this.httpService.post(gatewayConfig.APIServices.getWatchlistSymbolsRemove.url, request);

  }
}
