import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpService } from 'src/app/core/services/http.service';
import { PageService } from 'src/app/shared/services/page-service';

@Injectable({
    providedIn: 'root'
})
export class AccountDetailService implements PageService {

    constructor(private httpService: HttpService) { }

    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return input;
    }
}