import { Injectable } from '@angular/core';
import { OrderStatusData } from './order-status-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class OrderStatusStore extends PageStore<OrderStatusData> {

    constructor() {
        super(new OrderStatusData());
    }

}
