import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { isDefined } from '../../services/utils.service';


@Component({
  selector: 'app-table-display',
  templateUrl: './table-display.component.html',
  styleUrls: ['./table-display.component.scss']
})
export class TableDisplayComponent implements OnInit, OnChanges {
  @Input() tableMeta: any[] = [];
  @Input() tableData: any[] = [];
  @Input() displayHeader: boolean = false;
  @Input() isStickeyHeader: boolean = false;
  @Input() optionalColTemplate: TemplateRef<any>;
  @Input() style: any;
  @Input() pageContent: any;
  @Input() isDateChanged: any;
  @Input() uniqueId: any;
  @Input() isPagination: boolean;
  @Input() recordsEachPage: number;
  @Input() lang: any;
  @Input() isMissingInput: boolean;

  @Output() symbolClickedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() sortClickedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() tradeEvent: EventEmitter<string> = new EventEmitter<string>();
  toggledColumn: string | null = null;
  totalDataSize: any;
  startIdx: any;
  endIndx: any;
  curPage = 1;
  totalPage: number;
  tableHeaders: any;
  dataHeaders: any;
  filterableColumns: any;
  constructor() { }
  ngOnInit(): void {
    console.log(this.tableMeta)

  }
  ngOnChanges() {
    if (this.isPagination && this.recordsEachPage) {
      if (this.tableData && this.tableData.length == 0) {
        this.curPage = 1;
      }
      this.calculateInternalPage(this.tableData);
    }
  }
  //Altered this method on HTML beacuse before passed symbol was undefined..now i changed to passing the entire row from html and passing only symbol 
  symbolClicked(symbol) {
    this.symbolClickedEvent.emit(symbol);
  }

  trade(symbol) {
    console.log(symbol);
    this.tradeEvent.emit(symbol);
  }

  sortColumn(col: string) {
    if (this.toggledColumn === col) {
      this.toggledColumn = null;
    } else {
      this.toggledColumn = col;
    }
    this.sortClickedEvent.emit(col);
  }

  calculateInternalPage(data) {
    if (data !== undefined && data !== null && data.length > 0) {
      this.totalDataSize = data.length;
      this.startIdx = ((this.curPage - 1) * this.recordsEachPage);
      this.endIndx = (this.curPage) * this.recordsEachPage;
      if (this.endIndx > this.totalDataSize) {
        this.endIndx = this.totalDataSize;
      }
      this.totalPage = Math.floor(this.totalDataSize / this.recordsEachPage);
      if (this.totalDataSize % this.recordsEachPage !== 0) {
        this.totalPage++;
      }

      if (this.curPage > this.totalPage) {
        this.curPage = 1;
        this.startIdx = ((this.curPage - 1) * this.recordsEachPage) + 1;
        this.endIndx = (this.curPage) * this.recordsEachPage;
        if (this.endIndx > this.totalDataSize) {
          this.endIndx = this.totalDataSize;
        }
      }
    }
  }

  goToNextPg() {
    if (this.curPage !== this.totalPage) {
      this.curPage++;
    }
    this.calculateInternalPage(this.tableData);
  }

  goToPrevPg() {
    if (this.curPage !== 1) {
      this.curPage--;
    }
    this.calculateInternalPage(this.tableData);
  }
  valueOrDash(value) {
    if (isDefined(value)) {
      return value;
    }
    return '-';
  }

  isDefined(value) {
    return isDefined(value);
  }
}
