import { BaseData } from 'src/app/shared/models/base-data';
import { InnerSubscriber } from 'rxjs/internal/InnerSubscriber';
import { Subscribable } from 'rxjs';

export class NyAgreementData extends BaseData {
  constructor() {
    super();
    this.input = {
      AgreementName: undefined,
      UserData: {
        EntityUse: undefined,
        RegisteredSecCFTC: undefined,
        RegisteredSecAgency: undefined,
        QualifiedForSecCFTC: undefined,
        InvestmentAdvicePersonal: undefined,
        AssetMgr: undefined,
        CapitalUse: undefined,
        TradingForEntity: undefined,
        ShareProfit: undefined,
        ExchangeBenefits: undefined,
        AcceptAgreement: undefined,
        AcceptPersonalAndEmploymentInfo: undefined,
        AcceptReadTermsSection: undefined,
        PersonalUse: undefined,
        Pro: undefined,
        Subscriber: {
          Name: '',
          Address: '',
          EmploymentInfo: {
            Occupation: '',
            Employer: '',
            EmploymentFunction: '',
            Position: '',
          },
        },
      },
    };
  }
}

export interface NyAgreementInputs {
  AgreementName: string;
  UserData: UserData;
}
export interface UserData {
  Subscriber: Subscriber;
  EntityUse: boolean;
  RegisteredSecCFTC: boolean;
  RegisteredSecAgency: boolean;
  QualifiedForSecCFTC: boolean;
  InvestmentAdvicePersonal: boolean;
  AssetMgr: boolean;
  CapitalUse: boolean;
  TradingForEntity: boolean;
  ShareProfit: boolean;
  ExchangeBenefits: boolean;
  AcceptAgreement: boolean;
  AcceptPersonalAndEmploymentInfo: boolean;
  AcceptReadTermsSection: boolean;
  PersonalUse: boolean;
  Pro: boolean;
}

export interface Subscriber {
  EmploymentInfo: EmploymentInfo;
  Name: string;
  Address: string;
}

export interface EmploymentInfo {
  Occupation: string;
  Employer: string;
  EmploymentFunction: string;
  Position: string;
}
