import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStore } from '../models/app-store';
import { IOmnitureAdsData, IOmnitureDownloadData, IOmnitureErrorData, IOmnitureFormData, IOmnitureInteractionData, IOmniturePageData, IOmnitureSiteSearch, IOmnitureTradeItems, IOmnitureTransactionData, IOmnitureTransactionID } from '../models/omniture.types';
import { GlobalContentStore } from '../store/global-content-store';

declare var gtag:any;

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class GtagService {

  google_tracking_id: any;
  userIdSet = false;

  userLob = {
    "iis": "CIBC Imperial Investor Service",
    "cpic": "CIBC Private Investment Counsel",
    "gen": "CIBC",
    "ie": "CIBC Investor's Edge",
    "wg": "CIBC Wood Gundy"
  }
  constructor(protected http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute, 
    private store: AppStore, private globalContent: GlobalContentStore) {
    if ((window as any).googleTrackingId) {
        this.google_tracking_id = (window as any).googleTrackingId;
    }
  }
  googleAnalyticsHeadScripts(uid) {
    if (this.google_tracking_id) {
      if (uid == null || uid == '') {
        uid = "NA";
      }
      var pageView = false;
      const head = document.getElementsByTagName('head')[0];
  
      const googleAnalyticsFirstScript = document.createElement('script');
      googleAnalyticsFirstScript.async = true;
      googleAnalyticsFirstScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.google_tracking_id;
  
      const googleAnalyticsSecondScript = document.createElement('script');
  
      if (window.location.href.indexOf("utm_medium=email") !== -1) {
        pageView = true;
      } else {
        pageView = false;
      }
      
      googleAnalyticsSecondScript.innerHTML = '    window.dataLayer = window.dataLayer || [];\n' +
        '    function gtag(){dataLayer.push(arguments);}\n' +
        '    gtag(\'js\', new Date());\n' +
        '\n' +
        'gtag(\'config\', \'' + this.google_tracking_id + '\',{\'send_page_view\':' + pageView + '});' +
        'gtag(\'set\',{\'dimension1\' : \'' + uid + '\'});';
  
      head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
      head.insertBefore(googleAnalyticsFirstScript, head.firstChild);

      this.setUserId();
      // Send only manual, to avoid duplication
      this.defaultPageViewDisable()
    }
  }
  setUserId() {
    if (this.store.omnitureId && !this.userIdSet) {
      this.userIdSet = true;
      gtag('set', 'user_id', decodeURIComponent(this.store.omnitureId));
    }
  }
  defaultPageViewDisable() {
    if (this.store.isApp()) {
      gtag('config', this.google_tracking_id, {
        send_page_view: false,
        platform: "mobile-app",
        allow_idfa: "false",
        allow_android_id: "false"
      });
    } else {
      gtag('config', this.google_tracking_id, {
          send_page_view: false,
          allow_idfa: "false",
          allow_android_id: "false"
      });
    }

  }
  
  googleAnalytics(url, uid) {
    if (this.google_tracking_id) {
      gtag('set', { 'dimension1': uid });
      gtag('config', this.google_tracking_id, { 'page_path': url });
    }
  }

  tradedTransaction(trade: IOmnitureTradeItems) {
    console.log("track obj 1", trade);
    let tradeItem = trade;
    tradeItem["item_name"] = trade.action;
    tradeItem["price"] = trade.value;
    tradeItem["item_category"] = trade.account;
    tradeItem["item_variant"] = trade.action;
    let tradeObj = {
      value: trade.value,
      currency: trade.currency ? trade.currency.toUpperCase(): '',
      items: [
        tradeItem
      ] 
    }
    console.log("track obj 2", tradeObj);
    gtag('event', 'purchase', tradeObj);
  }

  formViewed(formData: IOmnitureFormData) {
    gtag('event', 'form_start', { 
      'form_id': formData.uniqueID,
      'form_name': formData.name,
      'form_destination': formData.stepName,
     });
  }

  tabView(title, globalContent, router) {
    gtag('event', 'tab_view', {
      page_title: title + " | " + globalContent.text.title,
      page_location: window.location.href,
      page_path: window.location.href.split("#")[1],
      send_to: this.google_tracking_id
    });
  }

  setPageDetails(content, globalContent, router) {
    if (content && content.text && content.text.pageHeader) { 
        let details = {
          page_title: content.text.pageHeader + " | " + globalContent.text.title, 
          page_location: window.location.href, 
          page_path: router.url
        }
      console.log("router.url2", details);
    } 
  }

  viewedPages(pageDetails: IOmniturePageData) {
    // console.log("pageDetails", pageDetails.name + '|' + this);
    let title = '';
    if (this.userLob[this.store.lob]) {
      if (pageDetails?.nameGA) {
        title = pageDetails.nameGA + ' | ' +this.userLob[this.store.lob]
      } else {
        title = pageDetails.name + ' | ' +this.userLob[this.store.lob]
      }
    } else {
      if (pageDetails?.nameGA) {
        title = pageDetails.nameGA;
      } else {
        title = pageDetails.name;
      }
    }
    console.log("pageDetails", title);

    if (this.google_tracking_id) {
      if (pageDetails) {
        gtag('event', 'page_view', {
          page_title: title,
          page_location: pageDetails?.url,
          page_referrer: pageDetails?.referrer,
          page_path: pageDetails.url?.split("#")[1],
          send_to: this.google_tracking_id
        });
      }
    }
  }

  clickEvent(clickObj: IOmnitureInteractionData) {
      this.setUserId()
      let obj = clickObj.name;
      if (clickObj.name) {
        let interactionName = obj.split(' ').join('-').toLowerCase();
        interactionName = interactionName.split('---').join('-').toLowerCase();
        console.log("clickObj", obj);
        gtag('event', 'site_interaction', {
          name: interactionName
        });
      }
  }

  searchEvent(fileInfoObj: IOmnitureSiteSearch) {
    let sendObj = {
      'search_term': fileInfoObj.term,
      'search_results': fileInfoObj.results,
      'search_category': fileInfoObj.categories,
      'search_location': fileInfoObj.currentPage,
      'search_filters': fileInfoObj.filters,
    }
    gtag('event', 'search', sendObj);
  }

  downloadFileEvent(fileInfoObj: IOmnitureDownloadData) {
    gtag('event', 'download', {
      file_name: fileInfoObj.filename,
      file_type: fileInfoObj.filetype
    });
  }

  errorEvent(errorObj: IOmnitureErrorData) {
    if (errorObj["type"] ) {
      errorObj["errorType"] = errorObj["type"];
      delete errorObj["type"]
    }
    gtag('event', 'errors', errorObj);
  }

  advertisingEvent(adsObj: IOmnitureAdsData) {
    gtag('event', 'ad_impression', {
      ad_unit: adsObj.trackingCode,
      ad_source: adsObj.location,
      ad_format: adsObj.type,
    });
  }

  adsClick(adsObj: IOmnitureAdsData) {
    gtag('event', 'ads_click', {
      ad_unit: adsObj.trackingCode,
      ad_source: adsObj.location,
      ad_format: adsObj.type,
    });
  }

  transactionDone(transaction: IOmnitureTransactionID) {
    console.log("track obj 1", transaction);
    let tradeItem = {};
    tradeItem["transaction_id"] = transaction.ID
    transaction.items.forEach(data => {
      tradeItem["value"] = data["amount"];
      tradeItem["frequency"] = data['frequency'];
      tradeItem["from-holding"] = data['from']["holding"];
      tradeItem["to-holding"] = data['to']["holding"];
      tradeItem["from-currency"] = data['from']["currency"];
      tradeItem["to-currency"] = data['to']["currency"];
      tradeItem["serviceFee"] = data['frequency'];
    });
    console.log("track obj 1", tradeItem);
    gtag('event', 'transaction', tradeItem);    

    // let tradeItem = transaction;
    // tradeItem["item_name"] = transaction.action;
    // tradeItem["price"] = transaction.value;
    // tradeItem["item_category"] = transaction.account;
    // tradeItem["item_variant"] = transaction.action;
    // let tradeObj = {
    //   value: transaction.value,
    //   currency: transaction.currency ? transaction.currency.toUpperCase(): '',
    //   items: [
    //     tradeItem
    //   ] 
    // }
    // console.log("track obj 2", tradeObj);
  }

  disableAutoCall(bool) {
    if (this.google_tracking_id) {
      window['ga-disable-'+''+this.google_tracking_id] = bool;    
    }
  }

  sendDataToGoogleAnalytics() {
    if ((window as any).TrackingEnabled) {

      this.setUserId();

      // Site Interaction 
      if (window.digitalData.events['siteInteraction'] == true) {
        if (window.digitalData?.interaction['name']) {
          this.clickEvent(window.digitalData.interaction)
        }
      }
      // Error
      if (window.digitalData.events['error'] == true) {
        if (window.digitalData?.errors) {
            window.digitalData.errors.forEach(element => {
              this.errorEvent(element)
            });
        }
      }
      // Download File
      if (window.digitalData.events['download'] == true) {
        if (window.digitalData?.download) {
          this.downloadFileEvent(window.digitalData?.download)
        }
      }
      // Search
      if (window.digitalData.events['siteSearch'] == true) {
        if (window.digitalData?.siteSearch) {
          this.searchEvent(window.digitalData?.siteSearch)
        }
      }
      // Ads
      if (window.digitalData.events['adImpression'] == true) {
        if (window.digitalData?.advertising) {
            window.digitalData.advertising.forEach(element => {
              this.advertisingEvent(element)
            });
        }
      }
      // Ads Clicked - Commenting - same parameters available in adImpression
      if (window.digitalData.events['adClick'] == true) {
        if (window.digitalData?.advertising) {
            window.digitalData.advertising.forEach(element => {
              this.adsClick(element)
            });
        }
      }

      if (window.digitalData.events['pageView'] == true) {
        if (window.digitalData?.page) {
          console.log("page name detected =>>", window.digitalData.page.name, );
          this.viewedPages(window.digitalData?.page);
        }
      }
      if (window.digitalData.events['tradeSubmit'] == true) {
        if (window.digitalData?.trade) {
          this.tradedTransaction(window.digitalData?.trade);
        }
      }
      if (window.digitalData.events['formStep'] == true || window.digitalData.events['formView'] == true) {
        if (window.digitalData?.form) {
          this.formViewed(window.digitalData?.form);
        }
      }
      if (window.digitalData.events['transactionComplete'] == true) {
        if (window.digitalData?.transaction) {
          this.transactionDone(window.digitalData?.transaction);
        }
      }
    }
  }
}
