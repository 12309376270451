import { RouteGroup } from "../shared/models/route-config";

export const TradeOptionsConfig: RouteGroup = {
  "/txn/optionsCentral": {
    name: "OptionsCentral",
    service: "OptionsCentralService",
    store: "OptionsCentralStore",
    resolveContent: "optionscentral",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
    ],
    trackingData: {
      hierarchy: "trading,options",
      name: "option-central",
      nameGA: "Options Central",
      events: { pageView: true },
    },
  },
  "/txn/optionsCentral/singleLeg/landing": {
    name: "TradeOptionsLanding",
    service: "TradeOptionsLandingService",
    store: "TradeOptionsLandingStore",
    resolveContent: "tradeoptionslanding",
    policy: {
      navFrom: {
        url: ["*"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "optionsInit",
        get request(): any {
          return {
            IncludeBalance: true,
          };
        },
      },
      {
        id: "load",
        restUrl: "holdingsNewLoad",
        get request(): any {
          return {};
        },
      },
      {
        id: "settlmentAccounts",
        restUrl: "optionsSettlementAccounts",
        optional: true,
        get request(): any {
          return {
            Type: "OPTIONS",
          };
        },
      },
      {
        id: "chainDetail",
        restUrl: "optionsChainDetail",
        optional: true,
        get request(): any {
          return {};
        },
      },
      {
        id: "quickQuote",
        restUrl: "quoteQuick",
        optional: true,
        get request(): any {
          return {
            Symbols: [
              {
                SymbolType: "OPTION",
              },
            ],
          };
        },
      },
      {
        id: "holdings",
        restUrl: "getOrderHoldings",
        optional: true,
        get request(): any {
          return {
            FiType: "O"
          };
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,trade-options",
      name: "details",
      nameGA: "Trade Options",
      formInfo: { name: "obr-trade-options", stepName: "details" },
      events: { formStep: true, formView: true, pageView: true },
    }
  },
  "/txn/optionsCentral/singleLeg/verify": {
    name: "TradeOptionsVerify",
    service: "TradeOptionsVerifyService",
    store: "TradeOptionsVerifyStore",
    resolveContent: "tradeoptionsverification",
    policy: {
      navFrom: {
        url: ["/txn/optionsCentral/singleLeg/landing"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "init",
        restUrl: "optionsVerify",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,trade-options",
      name: "verification",
      nameGA: "Trade Options - Verification",
      formInfo: { name: "obr-trade-options", stepName: "verification" },
      events: { formStep: true, pageView: true },
    }
  },
  "/txn/optionsCentral/singleLeg/confirm": {
    name: "TradeOptionsConfirm",
    service: "TradeOptionsConfirmService",
    store: "TradeOptionsConfirmStore",
    resolveContent: "tradeoptionsconfirmation",
    policy: {
      navFrom: {
        url: ["/txn/optionsCentral/singleLeg/verify"],
        fallBackUrl: "",
      },
      navTo: {
        url: ["*"],
        fallBackUrl: "",
      },
    },
    restService: [
      {
        id: "submit",
        restUrl: "optionsSubmit",
        get request(): any {
          return {};
        },
      },
    ],
    trackingData: {
      hierarchy: "trading,trade-options",
      name: "confirmation",
      nameGA: "Trade Options - Confirmation",
      formInfo: { name: "obr-trade-options", stepName: "confirmation" },
      events: { formStep: true, formSubmit: true, pageView: true, tradeSubmit: true },
      transaction: {
        mainField: "inFlow",
        fieldNames: {
          action: "orderReviewInfo.Activity.OrderType",
          type: "options",
          symbol: "orderReviewInfo.Symbol.SymbolName",
          market: "orderReviewInfo.Symbol.Market",
          exchange: "quote.UnderlyingSymbolQuote.MarketId",
          quote: "quoteValue",
          quantity: "quantity",
          dividend: "na",
          priceType: "orderReviewInfo.Price.Type",
          expiry: "orderReviewInfo.Expiry.ExpiryType",
          account: "orderReviewInfo.Account.AccountTypeDesc",
          serviceFee: "estimatedCost.EstimatedCommission",
          value: "estimatedCost.EstimatedOrderValue",
          currency: "orderReviewInfo.Symbol.Market",
          foreignExchange: "foreignExchange",
        },
      },
    },
  },
};
