import { Injectable } from '@angular/core';
import { CashtransferVerifyData } from './cashtransfer-verify-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class CashtransferVerifyStore extends PageStore<CashtransferVerifyData> {

    constructor() {
        super(new CashtransferVerifyData());
    }

}
