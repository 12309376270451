import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { SettlementAccountsReq, AccountHoldingsReq, BuyingPowerReq } from 'src/app/modules/txn/tradeStocks/landing/data/trade-stocks-landing-data';
import { QuickTradeStocksLandingInputs } from '../data/quick-trade-stocks-landing-data';
import { QuickTradeStocksLandingStore } from '../data/quick-trade-stocks-landing-store';

declare let require: any;
const gatewayConfig = require('../../../../../config/gateway-config.json');

@Injectable({
  providedIn: 'root'
})
export class QuickTradeStocksLandingService implements PageService {

  constructor(private appStore: AppStore, private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (QuickTradeStocksLandingStore.savedInfo) {
      const info: QuickTradeStocksLandingInputs = QuickTradeStocksLandingStore.savedInfo.inputs;
      const accNum = QuickTradeStocksLandingStore.savedInfo.accountNumber;
      const curr = QuickTradeStocksLandingStore.savedInfo.accountCurrency;
      if (info.action === 'BUY' || info.action === 'SHORT') {
        const ret = [input[0], input[2], input[3]];
        ret[1] = this.prepareSett(ret[1], accNum, info.action, curr);
        ret[2] = this.prepareQuick(ret[2], info.symbol, info.market);

        return ret;
      } else if (info.action === 'SELL' || info.action === 'BUYTOCOVER') {
        const ret = input;
        ret[1] = this.prepareHoldings(ret[1], accNum, info.action);
        ret[2] = this.prepareSett(ret[2], accNum, info.action, curr);
        ret[3] = this.prepareQuick(ret[3], info.symbol, info.market);

        return ret;
      } else {
        const ret = [input[0]];
        return ret;
      }
    } else if (QuickTradeStocksLandingStore.crossFlow) {
      const crossFlow = QuickTradeStocksLandingStore.crossFlow; // {action?, accountNumber, symbol, market}
      if (crossFlow.action) {
        if (crossFlow.action === 'BUY' || crossFlow.action === 'SHORT') {
          const ret = [input[0], input[3]];
          ret[1] = this.prepareQuick(ret[1], crossFlow.symbol, crossFlow.market);

          return ret;
        } else if (crossFlow.action === 'SELL') {
          const ret = [input[0], input[1], input[3]];
          ret[1] = this.prepareHoldings(ret[1], crossFlow.accountNumber, 'SELL');
          ret[2] = this.prepareQuick(ret[2], crossFlow.symbol, crossFlow.market);

          return ret;
        } else if (crossFlow.action === 'BUYTOCOVER') {
          const ret = [input[0], input[1], input[3]];
          ret[1] = this.prepareHoldings(ret[1], crossFlow.accountNumber, 'BUYTOCOVER');
          ret[2] = this.prepareQuick(ret[2], crossFlow.symbol, crossFlow.market);

          return ret;
        } else {
          const ret = [input[0]];
          return ret;
        }
      } else {
        const ret = [input[0], input[3]];
        ret[1] = this.prepareQuick(ret[1], crossFlow.symbol, crossFlow.market);

        return ret;
      }
    } else {
      const ret = [input[0]];
      return ret;
    }
  }

  prepareHoldings(input, accNum, action) {
    const ret = input.input;

    ret.AccountNumber = accNum;
    ret.Action = action;

    input.input = ret;
    return input;
  }

  prepareSett(input, accNum, action, curr) {
    const ret = input.input;

    ret.AccountNumber = accNum;
    ret.Action = action;
    if (curr) {
      ret.AccountCurrency = curr;
    }

    input.input = ret;
    return input;
  }

  prepareQuick(input, symbol, market) {
    const ret = input.input;

    ret.Symbols[0].SymbolName = symbol;
    ret.Symbols[0].Market = market;

    input.input = ret;
    return input;
  }

  getSettlementAccounts(data) {
    const req: SettlementAccountsReq = new SettlementAccountsReq();
    req.Action = data.Action;
    req.AccountNumber = data.AccountNumber;
    req.AccountCurrency = data.AccountCurrency;
    req.Type = data.Type;
    return this.httpService.post(gatewayConfig.APIServices.stocksSettlementAccounts.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getAccountHoldings(data) {
    const req: AccountHoldingsReq = new AccountHoldingsReq();
    req.Action = data.Action;
    req.AccountNumber = data.AccountNumber;
    req.Clean = data.Clean;
    req.Refresh = data.Refresh;
    req.SearchOptions = data.SearchOptions;
    req.FiType = 'E';
    return this.httpService.post(gatewayConfig.APIServices.getOrderHoldings.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getBuyingPower(data) {
    const req: BuyingPowerReq = new BuyingPowerReq();
    req.AccountNumber = data.AccountNumber;
    return this.httpService.post(gatewayConfig.APIServices.buyingPowerRetrieve.url,
      req, { params: { skiploading: 'true' } }
    );
  }

  getBalanceReqMyAccounts(data) {
    return this.httpService.post('/v2/accounts/myAccounts', data, { params: { skiploading: 'true' } });
  }
  getBalanceReq(data) {
    return this.httpService.post(gatewayConfig.APIServices.getAccountBalance.url, data ,
      { params: { skiploading: 'true' } });
  }

  getFxRate(data){
    return this.httpService.post(gatewayConfig.APIServices.foreignExchange.url,
      data, { params: { skiploading: 'true' } }
    );
  }

}
