import { BaseData } from 'src/app/shared/models/base-data';

export class SellGicVerifyData extends BaseData {
  constructor() {
    super();
    this.input = {
      tradingPassword: "",
      savePassword: false
    }  
  }
}

export interface SellGicVerifyInputs {
  tradingPassword: string;
  savePassword: boolean;
}

export interface GIC {
  Account: any,
  Amount: string,
  IssuerDescription: string,
  Term: string,
  InterestRateOrEffectiveYield:string,
  InterestPaymentFrequency: string,
  MaturityDate: string,
  SettlementDate: string,
  PayFrom: any,
  AccruedInterest: string,
  SettlementAmount: string
}