import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { AppStore } from 'src/app/shared/models/app-store';
import { HttpService } from 'src/app/core/services/http.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class ConnectCIBCOnlineLandingService implements PageService {


  constructor(private appStore: AppStore, private httpService: HttpService, private persistentService: PersistenceService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  getConnectOnlineURL() {
    const currentURL = window.location.hash.slice(1);
    this.persistentService.sessionStorePersist('LastVisitedURLBeforeOLB', currentURL);
    return this.httpService.post(gatewayConfig.APIServices.goToBanking.url, {});
  }

}
