import { BaseData } from 'src/app/shared/models/base-data';

export class SetUpTradeFillAlertsLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            email: null
        };
    }
}

export interface SetUpTradeFillAlertsLandingInputs {
    email: string;
}
