import { Injectable } from '@angular/core';
import { QuotesResearchStrategyBuilderData } from './quotes-research-strategy-builder-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class QuotesResearchStrategyBuilderStore extends PageStore<QuotesResearchStrategyBuilderData> {

  constructor() {
    super(new QuotesResearchStrategyBuilderData());
  }
}
