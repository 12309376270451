import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { SearchReq, FundFactReq } from '../data/mutual-funds-search-data';

declare let require: any;
const gatewayConfig = require("src/app/config/gateway-config.json");

@Injectable({
  providedIn: 'root'
})
export class MutualFundsSearchService implements PageService {


  constructor(private httpService: HttpService) { }

  prepareInput(input: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return input;
  }

  getFunds(data) {
    const req: SearchReq = {};
    req.Refresh = data.Refresh;
    req.SymbolName = data.SymbolName;
    req.PaginationOption = data.PaginationOption;
    req.SortOption = data.SortOption;
    return this.httpService.post(gatewayConfig.APIServices.getMutualFundSearch.url,
      req
    );
  }

  getFundFacts(data) {
    const req: FundFactReq = { SymbolName: data.SymbolName };
    return this.httpService.post(gatewayConfig.APIServices.getMutualFundSearchFundFact.url,
      req, { params: { skiploading: 'true' } }
    );
  }
}
