import { BaseData } from 'src/app/shared/models/base-data';

export class ChangeTradingPasswordLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            inputtedCurrentTradingPassword: null,
            newTradingPassword: null,
            reEnteredTradingPassword: null
        };
    }
}

export interface ChangeTradingPasswordLandingInputs {
    inputtedCurrentTradingPassword: string;
    newTradingPassword: string;
    reEnteredTradingPassword: string
}