import { BaseData } from '../../../../../../src/app/shared/models/base-data';

export class EletronicAccessAgreementData extends BaseData {
    constructor() {
        super();
        this.input = {
            AgreementName: undefined,
            UserData: {
            }
        };
    }
}

export interface ElectronicAccessAgreementInputs {
    AgreementName: string;
    UserData?: UserData;
}

export interface UserData {
    AcceptAgreement?: boolean;
}



