import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { OnlineRegisterMailingOptionsData } from './onlineregister-mailing-options-data';

@Injectable({ providedIn: 'root' })
export class OnlineRegisterMailingOptionsStore extends PageStore<OnlineRegisterMailingOptionsData> {

    constructor() {
        super(new OnlineRegisterMailingOptionsData());
    }

}
