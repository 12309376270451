import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { AgreementAndDisclosuresData } from './agreements-and-disclosures-data';

@Injectable({ providedIn: 'root' })
export class AgreementAndDisclosuresStore extends PageStore<AgreementAndDisclosuresData> {

    constructor() {
        super(new AgreementAndDisclosuresData());
    }

}
