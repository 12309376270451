import { BaseData } from 'src/app/shared/models/base-data';

export class ExchangeAgreementsLandingData extends BaseData {
    constructor() {
        super();
        this.input = {
            UserData: {
                Subscriber: {
                    Address: '',
                    Name: ''
                },
                Pro: undefined
            }
        };
    }
}

export interface ExchangeAgreementsLandingInputs {
    UserData: UserData;
}
export interface UserData {
    Subscriber: Subscriber;
    Pro: boolean;
}

export interface Subscriber {
    Name: string;
    Address?: string;
}
