import {
    OnInit,
    Directive,
    HostListener,
    ElementRef,
    AfterViewChecked,
    ChangeDetectorRef
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { AppStore } from '../models/app-store';
import { Lang } from '../models/lob.enum';

@Directive({
    selector: '[appCurrencyMask]'
})
export class CurrencyMaskDirective {
    inputRef: HTMLInputElement;

    constructor(elementRef: ElementRef, private control: NgControl, private cdRef: ChangeDetectorRef,
        private appStore:AppStore) {
        this.inputRef = elementRef.nativeElement;
    }

    // ngAfterViewChecked() {
    //     if (this.inputRef.value != '') {
    //         //window.requestAnimationFrame(() => {
    //         //this.setValue(this.inputRef, this.inputRef.value);
    //         //})

    //         //this.cdRef.detectChanges();
    //     }

    // }

    @HostListener('focus', ['$event'])
    focus(event) {
        //console.log("focus")
        const target = event.target;
        if(this.appStore.lang == Lang.EN) {
            const value = target.value.replaceAll(',', '');
            this.control.control.setValue(value);
        }else {
            const value = target.value.replace(/\s+/g, '');
            this.control.control.setValue(value);
        }

    }
    @HostListener('blur', ['$event'])
    blur(event) {
        //console.log("blur")
        const target = event.target;
        if(this.appStore.lang == Lang.EN) {
            const value = target.value.replaceAll(',', '');
            const result = new Intl.NumberFormat('en-CA', { minimumFractionDigits: 2}).format(
                value
            );
            this.control.control.setValue(result);
        }else {
            const value = target.value.replace(/\s+/g, '').replaceAll(',', '.');
            const result = new Intl.NumberFormat('fr-CA', { minimumFractionDigits: 2}).format(
                value
            );
            this.control.control.setValue(result);
        }

        
    }
    // setValue(target, newValue, hook?) {
    //     if (newValue != '') {
    //         console.log("setValue");
    //         const options = { minimumFractionDigits: 2 }
    //         const value = newValue.replaceAll(',', '');
    //         const result = new Intl.NumberFormat('en-US', options).format(
    //             value
    //         );

    //         //target.value = result;
    //     }
    // }
}
