import { Injectable } from '@angular/core';
import { RemoveBrokerageAccountsLandingData } from './remove-brokerage-accounts-landing.data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class RemoveBrokerageAccountsLandingStore extends PageStore<RemoveBrokerageAccountsLandingData> {

    constructor() {
        super(new RemoveBrokerageAccountsLandingData());
    }

}
