import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { of, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';


import { GlobalContentStore } from '../../store/global-content-store';
import { AppStore } from '../../models/app-store';
import { CommonService } from '../../services/common.service';
import { ObservableSubscriptionService } from '../../services/observable-subscription.service';
import { TrackingService } from '../../services/tracking.service';
import { PersistenceService } from 'src/app/core/services/persistence.service';
import { ModalService } from '../modal/services/modal.service';
import { OmniturePageDetail } from '../../models/route-config';

@Component({
    selector: 'app-quick-trade-options',
    templateUrl: './quick-trade-options.component.html',
    styleUrls: ['./quick-trade-options.component.scss'],
    animations: [
      trigger('sidebarTrigger', [
        // To define animations based on trigger actions
        state('open', style({ transform: 'translateX(0%)' })),
        state('close', style({ transform: 'translateX(130%)' })),
        transition('open => close', [
          animate('200ms ease-in')
        ]),
        transition('close => open', [
          animate('250ms ease-out')
        ]),
        state('openmobile', style({ transform: 'translate(-50%, 0%)' })),
        state('closemobile', style({ transform: 'translate(-50%, 130%)' })),
        transition('openmobile => closemobile', [
          animate('200ms ease-in')
        ]),
        transition('closemobile => openmobile', [
          animate('250ms ease-out')
        ])
      ])
    ]
  })
export class QuickTradeOptionsComponent implements OnInit{
  showStep = 1;
  openQuickOption = false;
  renderQuickTrade;
  firstOpen: boolean = true;
  desktopContainer: HTMLElement;
  headerContainer: HTMLElement;
  footerContainer: HTMLElement;
  pageHeader: HTMLElement;
  pagefooter: HTMLElement;
  chatButton: HTMLElement;
  thirdLevelNavigation: HTMLElement;
  thirdLevelNavDropdown: HTMLElement;
  globalNav: HTMLElement;
  pageTitle: HTMLElement;
  focusableElments: { overlayContent: { focusableElements: any[] } };
  subscriptions = new Subscription();
    constructor(
    public globalContent: GlobalContentStore,
    private appStore: AppStore,
    private commonService: CommonService,
    public subscriptionService: ObservableSubscriptionService,
    private trackingService: TrackingService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private persistenceService: PersistenceService,
    private modalService: ModalService
    ){}
    @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.openQuickOption && document.body.clientWidth < 900) {
      this.moveChatIconBack();
      this.Menu();
    }
  }
    ngOnInit() {
      this.retrieveStore();

      this.subscriptions.add(this.subscriptionService.quickOptionsCrossFlow.subscribe(() => {
        if (this.showStep != 1) {
          this.showStep = 1;
          this.omnitureTagging();
        }
        if (!this.openQuickOption) {
          this.Menu(true);
        }
      }));
  
      this.subscriptions.add(this.subscriptionService.quickOptionsPosition.subscribe(pos => {
        this.showStep = pos;
        this.omnitureTagging();
      })
      );
      this.subscriptions.add(this.subscriptionService.quickOptionsOpenOrCloseDrawer.subscribe(data => {
        if (!data) {
          this.Menu();
        } else if (data == 'mobile') {
          this.Menu(true);
        }
  
      })
      );

      this.renderQuickTrade = true;
    }
    Menu(fromHtml?) {
      let config: OmniturePageDetail = this.getOmnitureConfig();
      if (!this.renderQuickTrade) {
        this.renderQuickTrade = true;
        this.trapFocus();
        this.sendOmnitureInteractionData(config.name + ':quick-trade:expand', 'O');
        this.sendOmnitureInteractionData(config.name + ':quick-trade-options:expand', 'G');
        setTimeout(() => {
          this.openQuickOption = !this.openQuickOption;
          this.persistenceService.sessionStorePersist('quickOptionsOpenOrCloseDrawer', this.openQuickOption);
          if (fromHtml && this.openQuickOption) {
            this.moveChatIcon();
            this.subscriptionService.quickOptionsOpenOrCloseDrawer.next(true);
          }
        }, 300);
        this.omnitureTagging();
      } else {
        this.openQuickOption = !this.openQuickOption;
        this.persistenceService.sessionStorePersist('quickOptionsOpenOrCloseDrawer', this.openQuickOption);
        if (fromHtml && this.openQuickOption) {
          this.moveChatIcon();
          this.subscriptionService.quickOptionsOpenOrCloseDrawer.next(true);
        }
  
        if (this.openQuickOption) {
          this.sendOmnitureInteractionData(config.name + ':quick-trade:expand', 'O');
          this.sendOmnitureInteractionData(config.name + ':quick-trade-options:expand', 'G');
          this.omnitureTagging();
          this.trapFocus();
          if (this.firstOpen && this.showStep == 1) {
            this.firstOpen = false;
            this.commonService.logBraze("viewed_trade_options_details", { platform: "quickTrade"} );
          }
        } else {
          this.sendOmnitureInteractionData(config.name + ':quick-trade:collapse', 'O');
          this.sendOmnitureInteractionData(config.name + ':quick-trade-options:collapse', 'G');
          this.undoTrapFocus();
        }
      }
  
      if (!this.openQuickOption) {
        this.closeFunction();
        this.setAttributeTabIndex();
      } else {
        this.removeAttributeTabIndex();
      }
    }

    getOmnitureConfig() {
      let config: OmniturePageDetail;
      config = {
        name: this.showStep == 1 ? 'details' : this.showStep == 2 ? 'verification' : 'confirmation',
        url: this.showStep == 1 ? '/txn/quick-trade-options/landing' :
          this.showStep == 2 ? '/txn/quick-trade-options/verify' : '/txn/quick-trade-options/confirm',
        data: {
          name: this.showStep == 1 ? 'details' : this.showStep == 2 ? 'verification' : 'confirmation',
          nameGA: this.showStep == 1 ? 'Quick Trade Options - details' : this.showStep == 2 ? 'Quick Trade Options - verification' : 'Quick Trade Options - confirmation',
          hierarchy: 'quick-trade,trading,trade-options',
          formInfo: {
            name: 'obr-trade-options',
            stepName: this.showStep == 1 ? 'details' : this.showStep == 2 ? 'verification' : 'confirmation',
          },
          events: { pageView: true, formSubmit: true, formStep: true, tradeSubmit: true },
        },
      };
  
      return config;
    }

    sendOmnitureInteractionData(value?: string, omniOrGA?: string) {
      if ((window as any).TrackingEnabled) {
        of(this.trackingService.tagInteraction(value, false, true, false, true, omniOrGA));
      }
    }

    omnitureTagging() {
      if ((window as any).TrackingEnabled) {
        const config: OmniturePageDetail = this.getOmnitureConfig();
        of(this.trackingService.tagPageActionManually(config));
      }
    }

    retrieveStore() {
      const drawPos = this.persistenceService.sessionStoreRetrieve('quickOptionsPosition');
      if (drawPos) {
        this.showStep = parseInt(drawPos);
        this.subscriptionService.setQuickOptionsPosition(parseInt(drawPos));
      }
      this.renderQuickTrade = true;
      setTimeout(() => {
        const openClose = this.persistenceService.sessionStoreRetrieve('quickOptionsOpenOrCloseDrawer');
        if (openClose) {
          this.openQuickOption = (openClose === 'true');
          // set trade drawer open/close value in session
          this.persistenceService.sessionStorePersist('quickOptionsOpenOrCloseDrawer', this.openQuickOption);
        }
        if (this.openQuickOption) {
          this.trapFocus();
          setTimeout(() => {
            this.moveChatIcon();
          }, 1000);
        }
      }, 300);
  
    }

    trapFocus() {
      // only for IOS, Safari
      const detectMobileIOS = this.getMobileOperatingSystem();
      if ((detectMobileIOS === 'Android' || detectMobileIOS === 'iOS') && this.isDesktop()) {
        this.document.body.style.overflow = 'hidden';
        this.modalService.addLog(Date.now().toString() + " : QT Open::");
        const closeButton = this.document.getElementById('submitTradeDrawer');
        if (closeButton) {
          closeButton.addEventListener('blur', function () {
            setTimeout(() => {
              document.getElementById('closeBtnOptions').focus();
            }, 50);
          });
        }
        const logoQT = this.document.getElementById('closeBtnBack');
        if (logoQT) {
          logoQT.addEventListener('blur', function () {
            setTimeout(() => {
              document.getElementById('closeBtnBack').focus();
            }, 50);
          });
        }
  
        this.trapFocusForHamburgerElements();
        this.setHamburgerAriaAttribute();
  
        if (this.commonService.isNotchPhone()) {
          if (this.document.getElementById('overlay-content')) {
            this.renderer.addClass(this.document.getElementById('overlay-content'), 'overlay-content-ios');
            this.renderer.addClass(this.document.getElementById('overlay-footer'), 'overlay-footer-ios');
          }
        }
  
      }
    }

    setAttributeTabIndex() {
      const focusableElementQuery =
        'a[href]:not([disabled]),' +
        'button:not([disabled]),textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="password"]:not([disabled]),' +
        'input[type="radio"]:not([disabled]), input[type="number"]:not([disabled]),' +
        'input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="tel"]:not([disabled])';
      const elementsQuickTrade = this.document.getElementsByClassName('sidebar-qt-options');
      if (elementsQuickTrade && elementsQuickTrade.length) {
        const focusableEls = elementsQuickTrade[0].querySelectorAll(focusableElementQuery);
        for (let i = 0; i < focusableEls.length; i++) {
          focusableEls[i].setAttribute('tabindex', '-1');
        }
      }
    }

    undoTrapFocus() {
      const detectMobileIOS = this.getMobileOperatingSystem();
      if ((detectMobileIOS === 'Android' || detectMobileIOS === 'iOS') && this.isDesktop()) {
        const id = 'overlayContent';
        // untrap focus for hamburger
        this.commonService.undoTrapFocus(this.focusableElments[id].focusableElements, id);
        this.document.body.style.overflow = 'auto';
        this.modalService.addLog(Date.now().toString() + " : QT Close::");
        this.unsetHamBurgerAriaAttribute();
      }
    }

    removeAttributeTabIndex() {
      const focusableElementQuery =
        'a[href]:not([disabled]),' +
        'button:not([disabled]),textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="password"]:not([disabled]),' +
        'input[type="radio"]:not([disabled]), input[type="number"]:not([disabled]),' +
        'input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="tel"]:not([disabled])';
      const elementsQuickTrade = this.document.getElementsByClassName('sidebar-qt-options');
      if (elementsQuickTrade && elementsQuickTrade.length) {
        const focusableEls = elementsQuickTrade[0].querySelectorAll(focusableElementQuery);
        for (let i = 0; i < focusableEls.length; i++) {
          focusableEls[i].removeAttribute('tabindex');
        }
      }
    }

    unsetHamBurgerAriaAttribute() {
      this.desktopContainer ? this.desktopContainer.setAttribute('aria-hidden', 'false') : '';
      this.headerContainer ? this.headerContainer.setAttribute('aria-hidden', 'false') : '';
      this.footerContainer ? this.footerContainer.setAttribute('aria-hidden', 'false') : '';
      this.pageHeader ? this.pageHeader.setAttribute('aria-hidden', 'false') : '';
      this.thirdLevelNavDropdown ? this.thirdLevelNavDropdown.setAttribute('aria-hidden', 'false') : '';
      this.pagefooter ? this.pagefooter.setAttribute('aria-hidden', 'false') : '';
      this.globalNav ? this.globalNav.setAttribute('aria-hidden', 'false') : '';
      this.chatButton ? this.chatButton.setAttribute('aria-hidden', 'false') : '';
      this.thirdLevelNavigation ? this.thirdLevelNavigation.setAttribute('aria-hidden', 'false') : '';
      this.pageTitle ? this.pageTitle.setAttribute('aria-hidden', 'false') : '';
    }
  
    closeFunction() {
      let targetEl = 'toggleOptionQuickTrade';
      
      const focusElement = document.getElementById(targetEl);
      if (focusElement) {
        setTimeout(() => {
          focusElement.focus();
        }, 200);
      }
    }

    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    }

    trapFocusForHamburgerElements() {
      this.focusableElments = { 'overlayContent': { focusableElements: [] } };
      let selector = '';
      if (this.showStep == 1) {
        selector = 'app-quick-trade-options-landing';
      } else if (this.showStep == 2) {
        selector = 'app-quick-trade-options-verify';
      } else {
        selector = 'app-quick-trade-options-confirm';
      }
      this.commonService.setTabIndex('overlayContent', '', this.focusableElments, '', selector, '');
    }

    setHamburgerAriaAttribute() {
      this.desktopContainer = this.document.getElementById('page-content-container');
      this.headerContainer = this.document.getElementById('global-header');
      this.pageHeader = this.document.getElementById('page-header');
      this.globalNav = this.document.getElementById('global-nav');
      this.chatButton = this.document.querySelector('.genesys-container');
      this.thirdLevelNavigation = this.document.querySelector('#third-level-nav-dropdown option');
      this.thirdLevelNavDropdown = this.document.getElementById('third-level-nav-dropdown');
      this.pagefooter = this.document.getElementById('page-footer-wrapper');
      this.pageTitle = this.document.getElementById('focusElement');
      this.desktopContainer ? this.desktopContainer.setAttribute('aria-hidden', 'true') : '';
      this.headerContainer ? this.headerContainer.setAttribute('aria-hidden', 'true') : '';
      this.pageHeader ? this.pageHeader.setAttribute('aria-hidden', 'true') : '';
      this.thirdLevelNavDropdown ? this.thirdLevelNavDropdown.setAttribute('aria-hidden', 'true') : '';
      this.thirdLevelNavDropdown ? this.thirdLevelNavDropdown.setAttribute('tabindex', '-1') : '';
      this.globalNav ? this.globalNav.setAttribute('aria-hidden', 'true') : '';
      this.chatButton ? this.chatButton.setAttribute('aria-hidden', 'true') : '';
      this.pagefooter ? this.pagefooter.setAttribute('aria-hidden', 'true') : '';
      this.thirdLevelNavigation ? this.thirdLevelNavigation.setAttribute('aria-hidden', 'true') : '';
      this.pageTitle ? this.pageTitle.setAttribute('aria-hidden', 'true') : '';
    }

    // move bold chat to left when trade drawer open
  moveChatIcon() {
    // let chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.addClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.addClass(kampyleButton, 'move-kampyle');
    // }
  }

  isDesktop() {
    return this.commonService.isDesktop();
  }
  closeDrawer() {
    this.moveChatIconBack();
    this.subscriptionService.quickOptionsOpenOrCloseDrawer.next();
  }

   //move chat icon to right
   moveChatIconBack() {
    // let chatIconDivContainer = document.getElementsByClassName('genesys-container')[0];
    // if (chatIconDivContainer) {
    //   this.renderer.removeClass(chatIconDivContainer, 'move-chat');
    // }
    // let kampyleButton = document.getElementsByClassName("kampyle_vertical_button")[0];
    // if (kampyleButton) {
    //   this.renderer.removeClass(kampyleButton, 'move-kampyle');
    // }
  }

  backClick() {
    this.subscriptionService.setQuickOptionsPosition(1);
  }
  
  dataLoaded(e) {
    this.removeAttributeTabIndex();
    this.setFocus('closeBtn');
  }

  setFocus(id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.focus();
      }
    }, 200);
  }
  
}