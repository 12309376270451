import { Injectable } from '@angular/core';
import { SellStructNotesConfirmData } from './sell-struct-notes-confirm-data';
import { PageStore } from 'src/app/shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class SellStructNotesConfirmStore extends PageStore<SellStructNotesConfirmData> {

  constructor() {
    super(new SellStructNotesConfirmData());
  }
}
