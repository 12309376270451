<ng-container *ngIf="content.name == 'quicktradestocksconfirmation'">
  <div class="overlay-content" id="{{'overlayContent' + uniqueName}}">
    <div class="overlay-body" tabindex="0">
      <div class="spacer"></div>
      <div class="image-center">
        <div class="container">
          <object class="new-confirm-modal-image" aria-hidden="true" type="image/svg+xml"
            data="assets/images/airplane.svg" alt="confirmation page iamge" aria-label="confirmation page iamge">
          </object>
          <div class="order-placed" id="confirmModalHeader-small-qt" tabindex="-1">{{content.text.orderPlaced}}</div>
          <div class="order-summary" [ngClass]="orderReviewInfo.Activity.OrderType == 'BUY'|| orderReviewInfo.Activity.OrderType == '' || orderReviewInfo.Activity.OrderType == 'BUYTOCOVER' ? 'buy-color' : 'sell-color'">
            <span id="quick_trade_cnf_orderInfo">
              <span>{{getSummaryActionContent(orderReviewInfo.Activity.OrderType)}}</span>
              {{orderReviewInfo.Activity.Quantity.Content}}
              {{content.text.sharesOf}}
              <img class="underlyingquote-flag" height="12" width="18" aria-hidden="true" alt=""
                [src]="getFlag(orderReviewInfo.Symbol.Market)" />
              <span>{{orderReviewInfo.Symbol.SymbolName}}</span>
              <span>:{{orderReviewInfo.Symbol.Market}}</span>
            </span>
            <span [attr.aria-label]="content.text.atSignSR"> {{content.text.atSign}} </span>
            <span *ngIf="orderReviewInfo.Price.Type == 'MKT'">{{content.text.summaryPriceMarket}}</span>
            <span id="quick_trade_cnf_summaryLimit" *ngIf="orderReviewInfo.Price.Type=='LIMIT'">
              <span>{{content.text.summaryPriceLimit}} </span>
              <span [innerHtml]="orderReviewInfo.Price.LimitAmount.Data |
              currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
              <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
            </span>
            <span id="quick_trade_cnf_summaryPrice" *ngIf="orderReviewInfo.Price.Type=='STOP'">
              <span>{{content.text.summaryPriceStop}} </span>
              <span [innerHtml]="orderReviewInfo.Price.Amount.Data |
              currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
              <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
              <span> {{content.text.summaryPriceStopLimit}} </span>
              <span [innerHtml]="orderReviewInfo.Price.LimitAmount.Data |
              currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
              <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
            </span>
            <span id="quick_trade_cnf_trailingStop" *ngIf="orderReviewInfo.Price.Type=='TRAILINGSTOPLMTDOLLAR'">
              <span>{{content.text.summaryTrailingStopTriggerDelta}} </span>
              <span [innerHtml]="orderReviewInfo.Price.TrailingDelta.Data |
              currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
              <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
              <span> {{content.text.summaryTrailingStopLimitOffset}} </span>
              <span [innerHtml]="orderReviewInfo.Price.LimitOffset.Data |
              currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
              <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
            </span>
            <span id="quick_trade_cnf_trailingValues" *ngIf="orderReviewInfo.Price.Type=='TRAILINGSTOPLMTPERCENTAGE'">
              <span>{{content.text.summaryTrailingStopTriggerDelta}} </span>
              <span>{{orderReviewInfo.Price.TrailingDelta.Content}}</span>
              <span> {{content.text.summaryTrailingStopLimitOffset}} </span>
              <span [innerHtml]="orderReviewInfo.Price.LimitOffset.Data |
              currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'"></span>
              <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
            </span>.
          </div>
        </div>
      </div>

      <div class="content-order">
        <div class="container">
          <div class="divTable">
            <div class="divTableBody">
              <div class="divTableRow">
                <div class="divTableCell title">{{content.text.expiry}}</div>
                <div class="divTableCell desc">
                  {{globalContent.getNewExpiryContent(orderReviewInfo.Expiry.ExpiryType,
                  orderReviewInfo.Expiry.ExpiryDate,
                  "MMMM D, YYYY",'D
                  MMMM YYYY',appStore.lang, true)}}</div>
              </div>
              <div id="quick_trade_cnf_received" class="divTableRow">
                <div class="divTableCell title">{{content.text.received}}</div>
                <div class="divTableCell desc">{{orderConfirmInfo.OrderTimestamp}}</div>
              </div>
              <div id="quick_trade_cnf_estimatedProceeds" class="divTableRow">
                <div class="divTableCell title">{{orderReviewInfo.Activity.OrderType==="BUY" ||
                  orderReviewInfo.Activity.OrderType =="BUYTOCOVER" ? content.text.estimatedCost :
                  content.text.estimatedProceeds}}</div>
                <div id="quick_trade_cnf_estimatedCost" *ngIf="estimatedCostValues && estimatedCostValues.EstimatedTotalCost" class="divTableCell desc">
                  <span
                    [innerHtml]="estimatedCostValues.EstimatedTotalCost.replace(' $', '').replace('$', '') | currencyFormatter:orderReviewInfo.Symbol.Currency:'format-symboless-sr'">
                  </span>
                  <span aria-hidden="true"> {{orderReviewInfo.Symbol.Currency}}D</span>
                  <div id="quick_trade_cnf_tradeCommission" class="description">
                    <div *ngIf="estimatedCostValues.EstimatedOrderValue">{{content.text.tradeValue}}
                      {{estimatedCostValues.EstimatedOrderValue}}</div>
                    <div *ngIf="estimatedCostValues.EstimatedCommission">{{content.text.commission}}
                      {{estimatedCostValues.EstimatedCommission}}</div>
                    <div *ngIf="estimatedCostValues.EstimatedSecFee">
                      {{content.text.secFeeColon}} {{estimatedCostValues.EstimatedSecFee}}
                    </div>
                    <div *ngIf="estimatedCostValues.EstimatedFTTFee" class="description">
                      {{content.text.FTTFeeColon}} {{estimatedCostValues.EstimatedFTTFee}}
                    </div>
                  </div>
                </div>
                <div *ngIf="!estimatedCostValues || !estimatedCostValues.EstimatedTotalCost" class="divTableCell desc">-
                </div>
              </div>
              <div id="quick_trade_cnf_tracking" class="divTableRow">
                <div class="divTableCell title" style="width: 40%;">{{content.text.trackingNumber}}</div>
                <div class="divTableCell desc">{{orderConfirmInfo.OrderId}}</div>
              </div>
              <div id="quick_trade_cnf_account" class="divTableRow">
                <div class="divTableCell title">{{content.text.account}}</div>
                <div class="divTableCell desc">{{globalContent.accountNickname(orderReviewInfo.Account)}}
                  <span [innerHTML]="orderReviewInfo.Account.AccountNumber | srOnly:true"></span> - <span
                    *ngIf='orderReviewInfo.Activity.OrderType == "SELL" || orderReviewInfo.Activity.OrderType == "SHORT"'>{{orderReviewInfo?.PayTo.Currency}}</span>
                  <span
                    *ngIf='orderReviewInfo.Activity.OrderType == "BUY" || orderReviewInfo.Activity.OrderType == "BUYTOCOVER"'>{{orderReviewInfo?.PayFrom.Currency}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="spacer"></div>
    </div>
    <div class="bottom-button">
      <div class="container">
        <div class="button-background">
          <button id="quick_trade_cnf_viewBtn" class="view_order button secondary " type="button"
            (click)="goToOrderStatus()">{{content.text.viewOrderStatusButton}}</button>
          <button class="place_order button primary " type="button" (click)="newOrder()"
            id="submitTradeDrawer">{{content.text.placeNewOrderButton}}</button>
        </div>
      </div>
    </div>
  </div>

</ng-container>