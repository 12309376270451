import { Injectable } from '@angular/core';
import { PageStore } from 'src/app/shared/models/page-store';
import { ChangeMutualFundPlanLandingData } from './change-mutual-fund-plan-landing-data';

@Injectable({ providedIn: 'root' })
export class ChangeMutualFundPlanLandingStore extends PageStore<ChangeMutualFundPlanLandingData>  {

    constructor() {
        super(new ChangeMutualFundPlanLandingData());
    }

}