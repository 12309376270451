import { Injectable } from '@angular/core';
import { OptionCentreData } from './option-centre-data';
import { PageStore } from '../../../../shared/models/page-store';

@Injectable({ providedIn: 'root' })
export class OptionCentreStore extends PageStore<OptionCentreData> {

    constructor() {
        super(new OptionCentreData());
    }
}

export interface OptionCentreInputs {
    market?: string;
}