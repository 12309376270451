import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormSharedModule } from 'src/app/shared/form-shared.module';
import { DatePickerModule } from 'src/app/shared/components/date-picker/date-picker.module';
import { DateSelectorComponent } from 'src/app/shared/components/dateSelector/date-selector.component';

@NgModule({
  declarations: [
    DateSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FormSharedModule,
    DatePickerModule
  ],
  exports: [
    DateSelectorComponent
  ]
})
export class DateSelectorSharedModule { }
