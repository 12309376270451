import { BaseData } from 'src/app/shared/models/base-data';
import { BUY_GIC_CONSTANT } from '../../buy-gic.constant';

export class BuyGicLandingData extends BaseData {
  readonly CONSTANTS = BUY_GIC_CONSTANT;
  constructor() {
    super();
    this.input = {
      interestPaid: this.CONSTANTS.ANN
    };
  }
}

export interface BuyGicLandingInputs {
  interestPaid: string
}


export interface buyGicList {
  CIBCM_LT: [
    {
      Amount: number,
      Currency: string,
      InterestForYear1?: string,
      InterestForYear2?: string,
      InterestForYear3?: string,
      InterestForYear4?: string,
      InterestForYear5?: string,
      InterestPaymentFrequency: string,
      InterestRateToken1?: string;
      InterestRateToken2?: string,
      InterestRateToken3?: string,
      InterestRateToken4?: string,
      InterestRateToken5?: string,
      OwnerCode: string,
      OwnerProductTypeDescCode: string,
      ProductType: string,
    }
  ];
  CIBCT_LT: [{
    Amount: number,
    Currency: string,
    InterestForYear1?: string;
    InterestForYear2?: string,
    InterestForYear3?: string,
    InterestForYear4?: string,
    InterestForYear5?: string,
    InterestPaymentFrequency: string,
    InterestRateToken1?: string;
    InterestRateToken2?: string,
    InterestRateToken3?: string,
    InterestRateToken4?: string,
    InterestRateToken5?: string,
    OwnerCode: any,
    OwnerProductTypeDescCode: string,
    ProductType: string,
  }
  ];
  CIBC_CE: [
    {
      Amount: number,
      Currency: string,
      EffectiveYield: string,
      InitiateBuyToken: string,
      InterestForYear1?: string;
      InterestForYear2?: string,
      InterestForYear3?: string,
      InterestForYear4?: string,
      InterestForYear5?: string,
      InterestPaymentFrequency: string,
      OwnerCode: string,
      OwnerProductTypeDescCode: string,
      ProductType: string,
      Term: string
    }
  ];
  CIBC_FLX: [
    {
      Amount: number,
      Currency: string,
      EffectiveYield: string,
      InitiateBuyToken: string,
      InterestPaymentFrequency: string,
      OwnerCode: string,
      OwnerProductTypeDescCode: string,
      ProductType: string,
    }
  ];
  CIBC_NCE: [
    {
      Amount: number,
      Currency: string,
      EffectiveYield: string,
      InitiateBuyToken: string,
      InterestForYear1?: string;
      InterestForYear2?: string,
      InterestForYear3?: string,
      InterestForYear4?: string,
      InterestForYear5?: string,
      InterestPaymentFrequency: string,
      OwnerCode: string,
      OwnerProductTypeDescCode: string,
      ProductType: string,
      Term: string
    }
  ];
}

export interface buyThirdPartyGicList {

  Amount: number;
  Currency: string;
  InterestForYear1?: string;
  InterestForYear2?: string;
  InterestForYear3?: string;
  InterestForYear4?: string;
  InterestForYear5?: string;
  InterestPaymentFrequency: string;
  InterestRateToken1?: string;
  InterestRateToken2?: string;
  InterestRateToken3?: string;
  InterestRateToken4?: string;
  InterestRateToken5?: string;
  OwnerCode: any;
  ProductDescription: string;
}

export interface columnArray {
  index: number;
  asc: boolean;
  names: string[];
  text: string[];
}

export interface sortArray {
  index: number;
  asc: boolean;
  names: string[];
  text: string[];
}

export interface columnArrayFlexible {
  text: string[];
}

export interface columnArrayRate {
  text: string[];
}

export interface columnArrayLongTerm {
  text: string[];
}

export interface detailData {
  OwnerCode: string;
  h1Text: string;
  body: string;
  term: string;
  interestFrequency: string;
  redemption: any;
  productType: string;
}

export interface mlGicColumnArray {
  index: number;
  asc: boolean;
  names: string[];
  text: string[];
  sorting: string[];
  srText: string[];
}

export interface mlGicSortArray {
  index: number;
  asc: boolean;
  names: string[];
  text: string[];
}

export interface BuyStructNotesList {
  AvailableUntil: string;
  CblCode: string;
  Currency: string;
  Dealid: string;
  MarketingDocSize: string;
  NoteName: string;
  NoteWebsiteURL: string;
  OfferingDocSize: string;
  ProductTypeDesc: string;
  Term: string;
}

export interface NotesReq {
  ProductType?: string;
  SortOption?: {
    SortColumn: string,
    SortType: string
  }
}

export interface PdfReq {
  DealId: string;
  DocumentType: string;
}

export interface BuyReq {
  CblCode: string;
}

