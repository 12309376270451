<div class="globalHeader{{currentLob}}" [ngClass]="{'globalHeaderBackground': currentLob != 'IE'}">
  <div class="globalHeaderWrapper" [ngClass]="{'ieGlobalHeaderWrapper': currentLob == 'IE', 'A12': currentLob != 'IE'}">
    <div id="investingHeader_id" [ngClass]="{'investingHeader': currentLob != 'IE', 'ieInvestingHeader': currentLob == 'IE'}">
      <a href="javascript:void(0);">{{globalContent.text.investing}}</a>
    </div>
    <div id="bankingHeader_id" [ngClass]="{'bankingHeader': currentLob != 'IE', 'ieBankingHeader': currentLob == 'IE'}" style="margin-right: auto;">
      <a [ngClass]="{'color01': currentLob != 'IE', 'ieOnlineBankingLink': currentLob == 'IE'}" href="javascript:void(0);" (click)="goToOnlineBanking()">{{globalContent.text.banking}}</a>
    </div>
    <div class="" *ngIf="currentLob =='WG'">
      <a id="aboutMyAdvisor_id" class="color01" href="javascript:void(0);"
        (click)="goToAdvisorPage()">{{globalContent.text.aboutMyAdvisor}}</a>
      <span aria-hidden="true" class="icon-Out-of-App"></span>
    </div>
    <div *ngIf="currentLob =='IE' && !isAnyApp && isPermission('MKTLN')" class="marketLens">
      <!-- <a class="color01" href="javascript:void(0);" (click)="goToMarketLens()">
        {{globalContent.text.marketLens}}<span class="sr-only">{{globalContent.text.openModalSrOnly}}</span>
        </a> -->
        <a id="marketLens_id" class="color02 globalHeaderAlignment marketlens-link" href="javascript:void(0);"
          (click)="goToMarketLens()" id="marketLensLink">{{globalContent.text.marketLens}}<span class="sr-only">{{globalContent.text.openModalSrOnly}}</span></a>
        <!-- <span class="globalHeaderDivider globalHeaderAlignment"></span>
      <a class="color01 globalHeaderAlignment" href="{{globalContent.text.marketLensPDF}}" target="_system">temp</a>
      <span aria-hidden="true" class="icon-PDF globalHeaderPDF globalHeaderAlignment"></span> -->
      <span aria-hidden="true" class="divider">|</span>
      <a id="guide_id" class="color02 paddingLeft0 marketlens-link"href="javascript:void(0);"
            (click)="openWindow(globalContent.text.marketLensGuideHref); tagmarketlensGuideInteraction();">{{globalContent.text.guide}}<span class="sr-only"> {{globalContent.text.guideSRText}}</span>
            <!-- <span aria-hidden="true" class="icon-PDF iconSize16 pdfIcon color01"></span> -->
      </a>

    </div>
    <div *ngIf="currentLob == 'IE'" id="headerNews_id" class="news">
      <label id="headerNewsHover">
        {{globalContent.text.whatsNew}}
        <span class="sr-only">{{globalContent.text.whatsNewAria}} {{unreadNews ? 'unread stuff' : ''}}</span>
        <span class="headerNewsHoverArrow" aria-hidden="true"></span>
      </label>
      <a class="icon-Megaphone ieHeaderNews" (click)="openNews()" href="javascript:void(0)"
        aria-labelledby="headerNewsHover" id="headerNewsLink">
        <div *ngIf="unreadNews" class="unread-news-dot"></div>
      </a>
    </div>
    <div *ngIf="currentLob == 'IE'" class="help">
      <label id="headerHelpHover">
        {{globalContent.text.help}}
        <span class="sr-only">{{globalContent.text.newWindowText}}</span>
        <span class="headerHelpHoverArrow" aria-hidden="true"></span>
      </label>
      <a id="headerHelp_id" class="icon-Question-Thin" (click)="openHelp()" href="javascript:void(0)"
        aria-labelledby="headerHelpHover" [ngClass]="{'help-icon': currentLob != 'IE', 'ie-help-icon': currentLob == 'IE'}" ></a>
    </div>
    <div *ngIf="currentLob != 'IE'" id="siteMap_id" class="siteMap">
      <a class="color01" routerLink="/txn/sitemap">{{globalContent.text.siteMap}}</a>
    </div>
    <div *ngIf="currentLob != 'IE'"  id="contactUsHeader_id" class="contactUsHeader">
      <a *ngIf="currentLob !='CPIC'" class="color01" routerLink="/txn/contactus">{{globalContent.text.contactUs}}</a>
      <a *ngIf="currentLob =='CPIC'" class="color01"
        routerLink="/txn/aboutmyinvestmentcounsellor">{{globalContent.text.aboutMyInvestmentCounsellor}}</a>
    </div>

    <!-- <div *ngIf='isPermission("IRHLP")' class="helpLink">
            <a class="icon-Question-Thin color01" [attr.aria-label]="globalContent.text.help" target="_blank"
                routerLink='/txn/help'></a>
        </div> -->
    <div *ngIf="currentLob == 'IE'" class="header-profile-container" (focusout)="focusOutProfileMenu($event)"
      (focusin)="cancelFocusOut()" (mouseover)="openProfileMenu(true)" (mouseout)="closeProfileMenu()">
 <!--     <div class="new-flag">{{globalContent.text.new}}</div>-->
      <div class="header-profile-hover-container">
        <button id="header-profile-button" [attr.aria-haspopup]="true" [attr.aria-expanded]="showProfileMenu"
          (click)="clickProfileMenu()" 
          attr.aria-contols="header-profile-menu" (keydown)="keyProfileMenu($event)">
          <div class="sr-only">{{globalContent.text.profile}}{{globalContent.text.profileAccessibility}}</div>
          <div class="icon-Profile" [ngClass]="{'ie-icon-color': currentLob == 'IE'}" ></div>
          <div class="icon-Chevron-Down" [ngClass]="{'ie-icon-color': currentLob == 'IE'}"></div>
        </button>
        <!-- <label id="header-profile-hover">
          {{globalContent.text.profile}}
          <span class="header-profile-hover-arrow" aria-hidden="true"></span>
        </label> -->
      </div>
      <div class="header-profile-menu-container" [attr.style]="'display: ' + (showProfileMenu ? 'block' : 'none')">
        <span class="header-profile-menu-hover-arrow" aria-hidden="true"></span>
        <ul *ngIf="userMode === 'PILOT'" attr.aria-labelledby="header-profile-hover" id="header-profile-menu" role="menu">
          <li *ngFor="let profileMenuItem of globalContent.list.profileMenu; let i = index" role="menuitem">
            <a id="header-profile-menu-item-{{i}}" href="javascript:void(0);"
              (click)="clickProfileMenuItem(profileMenuItem)" [ngClass]="profileMenuIndex == i ? 'selected' : ''"
              (mouseenter)="hoverProfileMenuItem($event, i)" [attr.tabIndex]="profileMenuIndex == i ? 0 : -1"
              (keydown)="keyProfileMenuItem($event)">
              <span *ngIf="profileMenuItem.icon == 'plus'" class="icon-Add" attr.aria-hidden="true"></span>
              <span>{{profileMenuItem.text}}</span>
              <span class="sr-only">{{profileMenuItem.accessibility}}</span>
            </a>
          </li>
        </ul>
        <ul *ngIf="userMode === 'PROD'" attr.aria-labelledby="header-profile-hover" id="header-profile-menu" role="menu">
          <li *ngFor="let profileMenuItem of globalContent.list.profileMenuOld; let i = index" role="menuitem">
            <a id="header-profile-menu-item-{{i}}" href="javascript:void(0);"
              (click)="clickProfileMenuItem(profileMenuItem)" [ngClass]="profileMenuIndex == i ? 'selected' : ''"
              (mouseenter)="hoverProfileMenuItem($event, i)" [attr.tabIndex]="profileMenuIndex == i ? 0 : -1"
              (keydown)="keyProfileMenuItem($event)">
              <span *ngIf="profileMenuItem.icon == 'plus'" class="icon-Add" attr.aria-hidden="true"></span>
              <span>{{profileMenuItem.text}}</span>
              <span class="sr-only">{{profileMenuItem.accessibility}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <button id="signOff_button_id" [ngClass]="{'headerSignOff color01': currentLob != 'IE', 'ie-headerSignOff': currentLob == 'IE'}" (click)="signOff()">
      {{globalContent.text.signOff}}
    </button>
  </div>
</div>



<app-modal [attr.id]="'marketlensTredingPwdModal'" [id]="'marketlensTredingPwdModal'"
  [header]="'marketlensFocus'" style='display: none;' (keydown.esc)="closeMTPModal()">
  <div class="market-lense-modal-body"  aria-controls="mlpApp">
    <div class="market-lense-modal-content" id="marketlensFocus"  tabindex="-1">
      <div class="marketlensHeader">
        <span>{{globalContent.text.tradingPasswordModalHeader}}</span>
      </div>
      <app-trading-password-form [globalContent]="globalContent" [otvcUniqueId]="uniqueId" (close)="closeMTPModal()">
      </app-trading-password-form>
    </div>
  </div>
</app-modal>

<app-modal [attr.id]="'dcoAppGlobalHeader'" [id]="'dcoAppGlobalHeader'" [header]="'dcoAppModalHeaderGlobal'"
  style='display: none;' (keydown.esc)="closeDCOModal()" targetElement="header-profile-button">
  <div class="cibc-account-selector-modal-body" aria-controls="dcoApp">
    <div class="cibc-account-selector-modal-content">
      <div class="removeHeader allignCentre" id="dcoAppModalHeaderGlobal" tabindex="-1">
        {{globalContent?.text?.dcoAPPModalHeader}}
      </div>
      <div class="marginTop30">
        <section [ngClass]="isDesktop()?'float-right':'page-bottom-left-button-group'">
          <button type="submit" id="primary_continue_button_id" class='button primary marginBottom10-small' (click)="submitDCOApp()">
            {{globalContent?.text?.continueLabel}}
          </button>
        </section>
        <section [ngClass]="isDesktop()?'':'page-bottom-right-button-group'">
          <button type="button" id="secondary_cancel_button_id" class='button secondary' (click)="closeDCOModal()">
            {{globalContent?.text?.cancelLabel}}
          </button>
        </section>
      </div>
    </div>
  </div>
</app-modal>