import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnDestroy, SimpleChanges } from '@angular/core';
import { isDefined } from '../../services/utils.service';
import { AppStore } from '../../models/app-store';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {

  totalcollection: number[];
  @Input() total: number;
  @Input() current: number;
  @Input() carouselContent: any;
  @Input() margin: number;
  @Input() carouselStyle: string = "normal";
  @Input() isBar: boolean = false;
  @Input() barMargin: number = 0;
  @Input() hoverArrowOutset: string = "50%";
  @Input() public id: string;
  @Input() displayLeftRightBtn: boolean = true;

  @Output() focusToSlideCard: EventEmitter<any> = new EventEmitter<any>();

  carousel: any;
  carouselWrapper: any;
  carouselResize: any;
  carouselBarNode:any;
  positionDiff: any;
  isDragging: boolean = false;
  prevLeft: any;
  currentLeft: number = 0;
  currentWidth: number = 0;
  barWidth: number = 0;
  barLeft: number = 0;
  barPrevX: number = 0;
  barPrevY: number = 0;
  barPrevLeft: number = 0;
  isBarDragStart: boolean = false;
  showBar: boolean = false;
  wheelTimeout: number = 0;
  wheelDirection:string;
  btnDisable: boolean = false;
  resizeTimeout: any;
  resizeObserver = new ResizeObserver((entries) => {
    requestAnimationFrame(() => {
      this.currentWidth = Math.min(document.documentElement.clientWidth, this.carouselResize.parentElement.parentElement.clientWidth);
      let allDivs =  this.carousel.children;
      let calculateScrollWidth = 0;
      if (allDivs.length >= 1) {
        calculateScrollWidth += (allDivs.length - 1) * this.margin;
      }
      for(let i = 0; i < allDivs.length; i++) {
        calculateScrollWidth += allDivs[i].clientWidth;
      }
      if (calculateScrollWidth < this.currentWidth) {
        calculateScrollWidth = this.currentWidth;
      }
      if (calculateScrollWidth > 0) {
        this.barWidth = (this.currentWidth / calculateScrollWidth) * (this.currentWidth - (this.barMargin * 2));
        this.showBar = this.currentWidth > 0 && (this.currentWidth / calculateScrollWidth) < 1;
      }

      if (this.current == 1) {
        this.currentLeft = 0;
      } else if (this.current == this.total) {
        this.currentLeft = calculateScrollWidth - this.currentWidth;
      } else {
        let div = this.carousel.children[this.current - 1];
        this.currentLeft = div.offsetLeft + (div.clientWidth / 2) - (this.currentWidth / 2);
      }
      if (calculateScrollWidth> 0) {
        this.barLeft = (this.currentLeft / (calculateScrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
      }
    });
  });

  constructor(
    private appStore: AppStore
  ) {
  }

  focusOnCard = (x) => {
    let self = this;
    return (e) => {
      self.selectedItem(x);
    }
  }

  dragStartBar = (e) => {
    this.isBarDragStart = true;
    e.stopPropagation();
    e.preventDefault();
    if (isDefined(e.touches)) {
      this.barPrevX = e.touches[0].pageX;
      this.barPrevY = e.touches[0].pageY;
      this.barPrevLeft = this.barLeft;
    }
  }

  draggingBar = (e) => {
    if (!this.isBarDragStart) {
      return;
    }
    if (isDefined(e.movementY) && Math.abs(e.movementY) > Math.abs(e.movementX) && !this.isDragging) {
      this.isBarDragStart = false;
    } else if (isDefined(e.touches) && Math.abs(e.touches[0].pageY - this.barPrevY) > Math.abs(e.touches[0].pageX - this.barPrevX) && !this.isDragging) {
      this.isBarDragStart = false;
    } else {
      e.stopPropagation();
      if (!isDefined(e.touches)) {
        e.preventDefault();
      }
      this.isDragging = true;
      this.carousel.classList.add("dragging");
      if (isDefined(e.movementX)) {
        this.barLeft += e.movementX;
      } else if (isDefined(e.touches)){
        this.barLeft = this.barPrevLeft + e.touches[0].pageX - this.barPrevX;
      }
      this.currentLeft = (this.barLeft / (this.currentWidth - (this.barMargin * 2) - this.barWidth)) * (this.carousel.scrollWidth - this.currentWidth);
    }
  }

  dragStopBar = (e) => {
    if (this.isBarDragStart) {
      e.stopPropagation();
      e.preventDefault();
      this.isBarDragStart = false;
      this.carousel.classList.remove("dragging");
      if (!this.isDragging) return;
      this.isDragging = false;
      // this.slideOnce();
      this.findClosest();
    }
  }

  scrollWheel = (e) => {
    if (this.isBar) {
      if (isDefined(this.wheelDirection)) {
        if (this.wheelDirection == 'X') {
          this.scrollWheelX(e);
        } else {
          this.scrollWheelY();
        }
      } else {
        if (e.shiftKey) {
          this.wheelDirection = "X";
          this.scrollWheelX(e);
        } else if (Math.abs(e.wheelDeltaX) > Math.abs(e.wheelDeltaY) && Math.abs(e.wheelDeltaX) > 2) {
          this.wheelDirection = "X";
          this.scrollWheelX(e);
        } else if (Math.abs(e.wheelDeltaY) > Math.abs(e.wheelDeltaX) && Math.abs(e.wheelDeltaY) > 2) {
          this.wheelDirection = "Y";
          this.scrollWheelY();
        }
      }
    }
  }

  scrollWheelY() {
    this.wheelTimeout = Date.now();
    setTimeout(() => {
      if (this.wheelTimeout + 200 < Date.now() && this.wheelTimeout != 0) {
        this.wheelTimeout = 0;
        this.wheelDirection = undefined;
        this.findClosest();
      }
    }, 210);
  }

  scrollWheelX(e) {
    e.stopPropagation();
    e.preventDefault();
    if (e.wheelDeltaX != 0 && Math.abs(e.wheelDeltaX) > 2) {
      let amount = e.wheelDeltaX;
      if (amount > 50) {
        amount = 50;
      } else if (amount < -50) {
        amount = -50
      }
      this.currentLeft = this.currentLeft + amount;
      if (this.currentLeft < 0) {
        this.currentLeft = 0;
        this.barLeft = (this.currentLeft / (this.carousel.scrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
        return;
      } else if (this.currentLeft > this.carousel.scrollWidth - this.carouselWrapper.clientWidth) {
        this.currentLeft = this.carousel.scrollWidth - this.carouselWrapper.clientWidth;
        this.barLeft = (this.currentLeft / (this.carousel.scrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
        return;
      }
      this.barLeft = (this.currentLeft / (this.carousel.scrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
      this.carousel.classList.add("dragging");
      this.wheelTimeout = Date.now();
      setTimeout(() => {
        if (this.wheelTimeout + 200 < Date.now() && this.wheelTimeout != 0) {
          this.carousel.classList.remove("dragging");
          this.wheelTimeout = 0;
          this.wheelDirection = undefined;
          this.findClosest();
        }
      }, 210);
    } else if (e.wheelDeltaY != 0 && e.shiftKey && Math.abs(e.wheelDeltaY) > 2) {
      let amount = e.wheelDeltaY;
      if (amount > 50) {
        amount = 50;
      } else if (amount < -50) {
        amount = -50
      }
      let cur = this.currentLeft;
      this.currentLeft = this.currentLeft + amount;
      if (this.currentLeft < 0) {
        this.currentLeft = 0;
        this.barLeft = (this.currentLeft / (this.carousel.scrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
        return;
      } else if (this.currentLeft > this.carousel.scrollWidth - this.carouselWrapper.clientWidth) {
        this.currentLeft = this.carousel.scrollWidth - this.carouselWrapper.clientWidth;
        this.barLeft = (this.currentLeft / (this.carousel.scrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
        return;
      }
      this.barLeft = (this.currentLeft / (this.carousel.scrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
      this.carousel.classList.add("dragging");
      this.wheelTimeout = Date.now();
      setTimeout(() => {
        if (this.wheelTimeout + 200 < Date.now() && this.wheelTimeout != 0) {
          this.carousel.classList.remove("dragging");
          this.wheelTimeout = 0;
          this.wheelDirection = undefined;
          this.findClosest();
        }
      }, 210);
    } else if (this.wheelTimeout == 0) {
      this.wheelDirection = undefined;
    }
  }

  ngOnInit() {
    this.totalcollection = Array(this.total).fill(0);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.carouselResize);
    document.removeEventListener("mousemove", this.draggingBar);
    document.removeEventListener("mouseup", this.dragStopBar);
    document.removeEventListener("mouseleave", this.dragStopBar);
    document.removeEventListener("touchmove", this.draggingBar);
    document.removeEventListener("touchend", this.dragStopBar);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.total) {
      requestAnimationFrame(() => {
        this.barWidth = (this.currentWidth / this.carousel.scrollWidth) * this.currentWidth;
        this.showBar = this.currentWidth > 0 && (this.currentWidth / this.carousel.scrollWidth) < 1;

        if (this.current > changes.total.currentValue) {
          this.selectedItem(changes.total.currentValue);
        } else {
          this.selectedItem(this.current);
        }
        if(this.isBar) {
          let cards = this.carousel.children;
          let self = this;
          for (let i = 0; i < cards.length; i++) {
            let cardFocusEl = cards[i].querySelectorAll("a");
            if (cardFocusEl.length == 1 && !cardFocusEl[0].dataset.focusListenerSet) {
              cardFocusEl[0].dataset.focusListenerSet = true;
              cardFocusEl[0].addEventListener("focus", self.focusOnCard(i + 1));
            }
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    let isDragStart = false, prevPageX, prevPageY;
    this.carousel = document.querySelector('.carousel_' + this.id);
    this.carouselWrapper = document.getElementById('carousel_wrapper_' + this.id);
    this.carouselResize = document.getElementById('carousel_resize_' + this.id);
    this.carouselBarNode = document.getElementById('carousel_bar_node_' + this.id);
    this.currentWidth = Math.min(document.documentElement.clientWidth, this.carouselWrapper.parentElement.parentElement.clientWidth);
    this.barWidth = (this.currentWidth / this.carousel.scrollWidth) * this.currentWidth;
    this.showBar = this.currentWidth > 0 && (this.currentWidth / this.carousel.scrollWidth) < 1;
    const dragStart = (e) => {
      isDragStart = true;
      if (isDefined(e.pageX)) {
        prevPageX = e.pageX;
        prevPageY = e.pageY;
      } else if (isDefined(e.touches[0])) {
        prevPageX = e.touches[0].pageX;
        prevPageY = e.touches[0].pageY;
      }
      this.prevLeft = this.currentLeft;
    }
    const dragging = (e) => {
      if (!isDragStart) {
        return;
      }
      if (Math.abs((e.pageY || e.touches[0].pageY) - prevPageY) > Math.abs((e.pageX || e.touches[0].pageX) - prevPageX) && !this.isDragging) {
        isDragStart = false;
      } else {
        e.stopPropagation();
        e.preventDefault();
        this.isDragging = true;
        this.carousel.classList.add("dragging");
        this.positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
        this.currentLeft = this.prevLeft - this.positionDiff;
        this.barLeft = (this.currentLeft / (this.carousel.scrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
      }
    }

    const dragStop = (e) => {
      if (isDragStart) {
        isDragStart = false;
        this.carousel.classList.remove("dragging");
        if (!this.isDragging) return;
        this.isDragging = false;
        // this.slideOnce();
        this.slideMulti();
      }
    }

    requestAnimationFrame(() => {
      this.resizeObserver.observe(this.carouselResize);
    })

    this.carouselWrapper.addEventListener("mousedown", dragStart);
    this.carouselWrapper.addEventListener("mousemove", dragging);
    this.carouselWrapper.addEventListener("mouseup", dragStop);
    this.carouselWrapper.addEventListener("mouseleave", dragStop);

    this.carouselWrapper.addEventListener("touchstart", dragStart);
    this.carouselWrapper.addEventListener("touchmove", dragging);
    this.carouselWrapper.addEventListener("touchend", dragStop);

    this.carouselWrapper.addEventListener("wheel", this.scrollWheel);

    this.carouselBarNode.addEventListener("mousedown", this.dragStartBar);
    document.addEventListener("mousemove", this.draggingBar);
    document.addEventListener("mouseup", this.dragStopBar);
    document.addEventListener("mouseleave", this.dragStopBar);

    this.carouselBarNode.addEventListener("touchstart", this.dragStartBar);
    document.addEventListener("touchmove", this.draggingBar);
    document.addEventListener("touchend", this.dragStopBar);

    if(this.isBar) {
      let cards = this.carousel.children;
      let self = this;
      for (let i = 0; i < cards.length; i++) {
        let cardFocusEl = cards[i].querySelectorAll("a");
        if (cardFocusEl.length == 1 && !cardFocusEl[0].dataset.focusListenerSet) {
          cardFocusEl[0].dataset.focusListenerSet = true;
          cardFocusEl[0].addEventListener("focus", self.focusOnCard(i + 1));
        }
      }
    }

    if (!this.isBar){
      this.hideAccessbilityOtherCard();
      this.hideAccessbilityChevron();
    }
  }



  indicatorClicked(i) {
    if (!this.btnDisable) {
      this.btnDisable = true;

      // Fixed wrapper app defect - (focus) does not trigger
      if (this.appStore.isApp()) {
        this.selectedItem(i);
      }
    }
  }

  leftClicked() {
    if (this.current > 1 && !this.btnDisable) {
      this.btnDisable = true;
      this.selectedItem(this.current - 1);
    }
  }

  rightClicked() {
    if (this.current < this.total && !this.btnDisable) {
      this.btnDisable = true;
      this.selectedItem(this.current + 1);
    }
  }

  // Allow 1 card to be moved per drag
  slideOnce() {
    this.positionDiff = Math.abs(this.positionDiff);
    let firstDivWidth = this.carousel.querySelectorAll("div")[0].clientWidth + this.margin;
    let target = this.current;
    if (this.currentLeft > this.prevLeft && this.current < this.total) {
      if (this.positionDiff > firstDivWidth / 3) {
        target = Math.min(this.current + 1, this.total)
      }
    } else if (this.currentLeft < this.prevLeft && this.current > 1) {
      if (this.positionDiff > firstDivWidth / 3) {
        target = Math.max(this.current - 1, 1)
      }
    }
    this.selectedItem(target);
  }

  // Allow multiple cards to be moved per drag
  slideMulti() {
    this.positionDiff = Math.abs(this.positionDiff);
    let firstDivWidth = this.carousel.querySelectorAll("div")[0].clientWidth + this.margin;
    let target = this.current;
    if (this.currentLeft > this.prevLeft && this.current < this.total) {
      if (this.positionDiff > firstDivWidth / 3) {
        let slidesMoveCounts = Math.ceil((this.positionDiff - firstDivWidth / 3) / firstDivWidth);
        target = Math.min(this.current + slidesMoveCounts, this.total)
      }
    } else if (this.currentLeft < this.prevLeft && this.current > 1) {
      if (this.positionDiff > firstDivWidth / 3) {
        let slidesMoveCounts = Math.ceil((this.positionDiff - firstDivWidth / 3) / firstDivWidth);
        target = Math.max(this.current - slidesMoveCounts, 1)
      }
    }
    this.selectedItem(target);
  }

  findClosest() {
    if (this.barLeft < 10) {
      this.selectedItem(1);
    } else if (this.currentWidth - this.barWidth - this.barLeft < 10) {
      this.selectedItem(this.total);
    } else {
      let firstDivWidth = this.carousel.querySelectorAll("div")[0].clientWidth;
      let middlePos = this.currentWidth / 2;
      if (firstDivWidth - this.currentLeft > middlePos || this.total <= 1) {
        this.selectedItem(1);
      } else {
        let closest = Math.floor((middlePos - (firstDivWidth - this.currentLeft)) / (this.carousel.querySelectorAll("div")[1].clientWidth + this.margin)) + 2;
        this.selectedItem(closest);
      }
    }
  }

  selectedItem(value) {
    setTimeout(() => {
      this.btnDisable = false;
    }, 250);
    this.current = value;
    if (this.current == 1) {
      this.currentLeft = 0;
    } else if (this.current == this.total) {
      this.currentLeft = this.carousel.scrollWidth - this.carouselWrapper.clientWidth;
    } else {
      let div = this.carousel.children[this.current - 1];
      this.currentLeft = div.offsetLeft + (div.clientWidth / 2) - (this.carouselWrapper.clientWidth / 2)
    }
    if (this.currentLeft < 0) {
      this.currentLeft = 0;
    } else if (this.currentLeft > this.carousel.scrollWidth - this.carouselWrapper.clientWidth) {
      this.currentLeft = this.carousel.scrollWidth - this.carouselWrapper.clientWidth;
    }
    this.barLeft = (this.currentLeft / (this.carousel.scrollWidth - this.currentWidth)) * (this.currentWidth - (this.barMargin * 2) - this.barWidth);
    if (!this.isBar){
      this.hideAccessbilityOtherCard();
      this.hideAccessbilityChevron();
    }
  }

  keyboardNav(event) {
    let newFocus = this.current;
    // left
    if (event.keyCode == 37) {
      if (this.current > 1) {
        // this.selectedItem(this.current - 1);
        newFocus -= 1;
      }
      else {
        newFocus = this.total;
        // this.selectedItem(this.total);
      }
      let el = document.getElementById("carousel-circle-" + this.id + '-' + newFocus);
      if (el) {
        el.focus();
      }
      event.preventDefault();
    }
    // right
    if (event.keyCode == 39) {
      if (this.current < this.total) {
        // this.selectedItem(this.current + 1);
        newFocus += 1;
      }
      else {
        newFocus = 1;
        // this.selectedItem(1);
      }
      let el = document.getElementById("carousel-circle-" + this.id + '-' + newFocus);
      if (el) {
        el.focus();
      }
      event.preventDefault();
    }
  }

  hideAccessbilityOtherCard() {
    for (let i = 1; i < this.total + 1; i++) {
      if (i != this.current) {
        const slideContainer = document.getElementsByClassName(this.id + "-SlideCard-" + i)[0];
        if (slideContainer) {
          (slideContainer as any as HTMLElement).setAttribute('aria-hidden', 'true');
        }
      }
      else {
        const slideContainer = document.getElementsByClassName(this.id + "-SlideCard-" + i)[0];
        if (slideContainer) {
          (slideContainer as any as HTMLElement).setAttribute('aria-hidden', 'false');
        }
      }
    }
  }

  hideAccessbilityChevron() {
    const leftChevron = document.getElementsByClassName("leftChevron_" + this.id)[0];
    const rightChevron = document.getElementsByClassName("RightChevron_" + this.id)[0];
    if (leftChevron && rightChevron) {
      (leftChevron as any as HTMLElement).setAttribute('aria-hidden', 'false');
      (rightChevron as any as HTMLElement).setAttribute('aria-hidden', 'false');
    }
    if (1 == this.current) {
      if (leftChevron) {
        (leftChevron as any as HTMLElement).setAttribute('aria-hidden', 'true');
      }
    } else if (this.current == this.total) {
      if (rightChevron) {
        (rightChevron as any as HTMLElement).setAttribute('aria-hidden', 'true');
      }
    }
  }

  focusBacktoCard(id) {
    this.focusToSlideCard.emit(id);
  }
}
