import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PageService } from 'src/app/shared/services/page-service';
import { HttpService } from 'src/app/core/services/http.service';
import { AccountDetailsRequest } from '../data/account-details-page-data';
declare let require: any;
const gatewayConfig = require("../../../../../config/gateway-config.json");

@Injectable({
    providedIn: 'root'
})
export class AccountDetailsService implements PageService {

    constructor(private httpService: HttpService) { }

    prepareInput(input: Map<string, any>, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return input;
    }

    selectedAccountReq(accountRequest: AccountDetailsRequest) {
        const req: AccountDetailsRequest = accountRequest;
        req.IncludeAccountSelector = true;
    
        
        if (accountRequest.SelectedSubAccount)  {
            req.SelectedSubAccount = accountRequest.SelectedSubAccount;
        }else{
            req.SelectedAccount = accountRequest.SelectedAccount;         
        }
        
        return this.httpService.post(gatewayConfig.APIServices.accountDetails.url,
            req
        );
    }

    getTcpInfo(req) {
        return this.httpService.post(gatewayConfig.APIServices.tcpInfo.url,
            req
        );
    }

}
